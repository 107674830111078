import { CircleWidget, WidgetType } from "../../proto/CircleWidget";
import React from "react";
import CircleWidgetMediumCell from "./CircleWidgetMediumCell";
import CircleWidgetBannerCell from "./CircleWidgetBannerCell";
import CircleWidgetLargeCell from "./CircleWidgetLargeCell";
import { CircleChildPageProps } from "./CircleChildPageProps";
import CircleWidgetActiveMembersCell from "./CircleWidgetActiveMembersCell";
import CircleWidgetLivePartiesCell from "./CircleWidgetLivePartiesCell";
import { I18n } from "../../hooks/useI18n";

export interface CircleWidgetsCellProps {
  widget: CircleWidget;
  circleId: bigint;
  folderId: bigint;
  i18n: I18n;
}

function CircleWidgetCell({
  widget,
  circleId,
  folderId,
  i18n,
}: CircleWidgetsCellProps) {
  if (widget.widgetType === WidgetType.ACTIVE_MEMBER) {
    return (
      <CircleWidgetActiveMembersCell
        widget={widget}
        circleId={circleId}
        folderId={folderId}
        i18n={i18n}
      />
    );
  } else if (widget.widgetType === WidgetType.LIVE_PARTIES) {
    return (
      <CircleWidgetLivePartiesCell
        widget={widget}
        circleId={circleId}
        folderId={folderId}
        i18n={i18n}
      />
    );
  } else if (widget.widgetType === WidgetType.BANNER) {
    return (
      <CircleWidgetBannerCell
        widget={widget}
        circleId={circleId}
        folderId={folderId}
        i18n={i18n}
      />
    );
  } else if (widget.widgetType === WidgetType.LARGE) {
    return (
      <CircleWidgetLargeCell
        widget={widget}
        circleId={circleId}
        folderId={folderId}
        i18n={i18n}
      />
    );
  } else if (widget.widgetType === WidgetType.MEDIUM) {
    return (
      <CircleWidgetMediumCell
        widget={widget}
        circleId={circleId}
        folderId={folderId}
        i18n={i18n}
      />
    );
  } else {
    return <></>;
  }
}

export default CircleWidgetCell;
