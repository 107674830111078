import { HStack, VStack } from "../../../components/VStack";
import { Image } from "../../../components/Image";
import IcWarning from "../../../res/images/icon_warning.svg";
import { Divider } from "../../post/PostCell";
import React from "react";
import styled, { css } from "styled-components";
import { useI18n } from "../../../hooks/useI18n";
import { NFT } from "../../../proto/NFT";
import { Label } from "./Label";
import { SpeedTitle } from "../../../components/SpeedTitle";
import IcAccess from "../imgs/icon_benefits_access.png";
import IcReward from "../imgs/icon_benefits_rewards.png";
import IcEarn from "../imgs/icon_benefits_earn.png";
import { CircleFolder } from "../../../proto/CircleFolder";
import { CurrencyIcon } from "../../../components/views/CurrencyIcon";
import { formatMoney } from "../../../proto/Currency";
import IcFolder from "../imgs/icon_folder.svg";

const BenefitItem = css`
  padding: 18px 0;
  align-items: start;
  gap: 10px;
`;

const BenefitIcon = styled.img`
  width: 44px;
  height: 44px;
  padding: 8px;
  background: rgba(255, 255, 255, 0.06);
  border-radius: 4px;
`;

const AccessFolder = css`
  background: rgba(255, 255, 255, 0.06);
  border-radius: 4px;
  padding: 4px;
  font-size: 10px;
  margin: 4px 4px 4px 0;

  & > img {
    width: 16px;
    height: 16px;
  }
`;

export function CircleMembershipCardBenefitsView(props: { nft: NFT }) {
  const i18n = useI18n();
  const card = props.nft.circleMembershipCard;

  return (
    <>
      <SpeedTitle>{i18n.clover_benefits()}</SpeedTitle>
      <HStack mixin={[BenefitItem]}>
        <Image src={IcAccess} styledImg={BenefitIcon} />
        <VStack style={{ gap: 8, flex: 1 }}>
          <Label $fontSize={16}>
            {i18n.clover_access_exclusive_membership_content()}
          </Label>
          <div
            style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
          >
            {card?.accessibleFolders?.map((folder) => (
              <HStack mixin={[AccessFolder]}>
                <Image src={IcFolder} />
                <>{folder.name}</>
              </HStack>
            ))}
          </div>
          <HStack style={{ gap: 4 }}>
            <Image src={IcWarning} />
            <Label $fontSize={12} style={{ opacity: 0.6 }}>
              {i18n.clover_be_member_to_access()}
            </Label>
          </HStack>
        </VStack>
      </HStack>
      <Divider />
      <HStack mixin={BenefitItem} style={{ alignItems: "center" }}>
        <Image src={IcReward} styledImg={BenefitIcon} />
        <VStack style={{ gap: 8 }}>
          <Label $fontSize={16}>{i18n.clover_free_token_rewards()}</Label>
          <HStack style={{ gap: 4 }}>
            <CurrencyIcon size={12} currency={card?.currency} />
            <Label $fontSize={10} style={{ color: "#0dffcc" }}>
              {formatMoney("medium", card?.currency)}
            </Label>
            <Label $fontSize={10}>{card?.currency?.symbol}</Label>
          </HStack>
          <HStack style={{ gap: 4 }}>
            <Image src={IcWarning} />
            <Label $fontSize={12} style={{ opacity: 0.6 }}>
              {i18n.clover_redeem_free_token_tip()}
            </Label>
          </HStack>
        </VStack>
      </HStack>
      <Divider />
      <HStack mixin={BenefitItem} style={{ alignItems: "center" }}>
        <Image src={IcEarn} styledImg={BenefitIcon} />
        <Label $fontSize={16}>{i18n.clover_earn_extra_circles_tokens()}</Label>
      </HStack>
    </>
  );
}

function BenefitFolderView(props: { folder: CircleFolder }) {}
