import { z } from "zod";
import { ZodType } from "zod/lib/types";
import { Attachment } from "./Attachment";
import { zStatic } from "../../utils/zodUtils";

export const kCurrentVersion = 4;

export const TextStyle = z.object({
  bold: z.boolean().optional(),
  italic: z.boolean().optional(),
  strikethrough: z.boolean().optional(),
  underline: z.boolean().optional(),
  foregroundColor: z.string().optional(),
  backgroundColor: z.string().optional(),
});

export type TextStyle = zStatic<typeof TextStyle>;

export const ParagraphStyle = z.object({
  style: z
    .union([
      z.literal("h0"),
      z.literal("h1"),
      z.literal("h2"),
      z.literal("h3"),
      z.literal("quote"),
      z.literal("unordered_list"),
    ])
    .optional(),
  alignment: z
    .union([z.literal("left"), z.literal("center"), z.literal("right")])
    .optional(),
});

export type ParagraphStyle = zStatic<typeof ParagraphStyle>;

export function RichSpan<DS extends ZodType<any, any, any>>(dataSchema: DS) {
  return z.object({
    start: z.number().optional(),
    end: z.number(),
    data: dataSchema,
  });
}

export type RichSpan<T> = {
  start?: number;
  end: number;
  data: T;
};

export const RichFormat = z.object({
  version: z.number().default(kCurrentVersion).optional(),
  textSpans: z.array(RichSpan(TextStyle)).optional(),
  paragraphSpans: z.array(RichSpan(ParagraphStyle)).optional(),
  attachmentSpans: z.array(RichSpan(Attachment)).optional(),
});

export type RichFormat = zStatic<typeof RichFormat>;

export function clampRichSpan<T>(
  richSpan: RichSpan<T>,
  clampStart: number,
  clampEnd: number,
  resetStart: boolean,
) {
  const newStart = Math.max(richSpan.start ?? 0, clampStart);
  const newEnd = Math.min(richSpan.end, clampEnd);

  if (newEnd > newStart) {
    const adjustedStart = resetStart ? newStart - clampStart : newStart;
    const adjustedEnd = newEnd - clampStart;
    return { start: adjustedStart, end: adjustedEnd, data: richSpan.data };
  } else {
    return undefined;
  }
}

export function clampRichSpanArray<T>(
  richSpans: RichSpan<T>[],
  clampStart: number,
  clampEnd: number,
  resetStart: boolean,
): RichSpan<T>[] {
  const newRichSpans: RichSpan<T>[] = [];
  for (const richSpan of richSpans) {
    const newRichSpan = clampRichSpan(
      richSpan,
      clampStart,
      clampEnd,
      resetStart,
    );
    if (newRichSpan) {
      newRichSpans.push(newRichSpan);
    }
  }

  return newRichSpans;
}

export function clampRichFormat(
  richFormat: RichFormat,
  clampStart: number,
  clampEnd: number,
  resetStart: boolean,
): RichFormat {
  return {
    version: richFormat.version,
    textSpans: richFormat.textSpans
      ? clampRichSpanArray(
          richFormat.textSpans,
          clampStart,
          clampEnd,
          resetStart,
        )
      : undefined,
    paragraphSpans: richFormat.paragraphSpans
      ? clampRichSpanArray(
          richFormat.paragraphSpans,
          clampStart,
          clampEnd,
          resetStart,
        )
      : undefined,
    attachmentSpans: richFormat.attachmentSpans
      ? clampRichSpanArray(
          richFormat.attachmentSpans,
          clampStart,
          clampEnd,
          resetStart,
        )
      : undefined,
  };
}
