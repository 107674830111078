import { ModalController } from "../../../components/Modal";
import { CapabilityContent, NFT } from "../../../proto/NFT";
import { useI18n } from "../../../hooks/useI18n";
import { BSMenu } from "../../../components/BSMenu";
import GridLayout from "../../../components/GridLayout";
import styled from "styled-components";
import { HStack, VStack } from "../../../components/VStack";
import { useMemo } from "react";
import { Image } from "../../../components/Image";
import { Label } from "../components/Label";
import { shrink_on_pressed } from "../../../components/CommonStyles";
import { Spacing } from "../../../components/Spacing";
import { LinkButton } from "../../../components/Buttons";
import { useHopper } from "../../../hooks/useHopper";
import IcAI from "../imgs/ic_capability_ai.svg";
import IcCharacter from "../imgs/ic_capability_character.svg";
import IcFrame from "../imgs/ic_capability_profile_frame.svg";
import IcBubble from "../imgs/ic_capability_bubble.svg";
import IcStoryboard from "../imgs/ic_capability_storyboard.svg";
import IcUnknown from "../imgs/ic_capability_unknown.svg";

const CapabilityCard = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  overflow: clip;
`;

const CapabilityName = styled.div`
  font-size: 12px;
`;

const PreviewHolder = styled.div`
  position: relative;
  padding-top: 100%;
`;

const CapabilityPreview = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

function CapabilityCell(props: {
  capability: CapabilityContent;
  onClick: () => void;
}) {
  const i18n = useI18n();
  const [icon, preview, name] = useMemo(() => {
    if (props.capability.profileFrame) {
      return [
        IcFrame,
        props.capability.profileFrame.preview,
        props.capability.profileFrame.name,
      ];
    } else if (props.capability.storyboard) {
      return [
        IcStoryboard,
        props.capability.storyboard.preview,
        props.capability.storyboard.name,
      ];
    } else if (props.capability.chatBubble) {
      return [
        IcBubble,
        props.capability.chatBubble.preview,
        props.capability.chatBubble.name,
      ];
    } else if (props.capability.chatAi) {
      return [
        IcAI,
        props.capability.chatAi.preview,
        props.capability.chatAi.name,
      ];
    } else if (props.capability.character) {
      return [
        IcCharacter,
        props.capability.character.avatar,
        props.capability.character.characterName,
      ];
    }
    return [IcUnknown, undefined, i18n.web3_ugc_unknown_capability()];
  }, [props.capability]);

  return (
    <VStack
      style={{ gap: 8 }}
      mixin={[shrink_on_pressed]}
      onClick={props.onClick}
    >
      <CapabilityCard>
        <HStack style={{ gap: 5, justifyContent: "center", minHeight: 35 }}>
          <Image src={icon} style={{ width: 14, height: 14 }} />
          <Label $fontSize={10}>Character</Label>
        </HStack>
        <PreviewHolder>
          <Image src={[preview, 100]} styledImg={CapabilityPreview} />
        </PreviewHolder>
      </CapabilityCard>
      <CapabilityName>{name}</CapabilityName>
      <Spacing height={15} />
    </VStack>
  );
}

export function NFTCapabilityModal(props: {
  modal: ModalController;
  nft: NFT;
}) {
  const i18n = useI18n();
  const hopper = useHopper();

  const sortedCapability =
    props.nft.capabilityContents
      ?.sort((l, r) => (l.type < r.type ? 1 : 0))
      .flatMap((ca) => ca.contents ?? []) ?? [];

  const onClickCapability = (capability: CapabilityContent) => {
    // TODO: Jerry - add use logic
  };

  return (
    <BSMenu
      modal={props.modal}
      title={i18n.aug23_impr2_collectible_capabilities()}
    >
      <Label $fontSize={12} style={{ textAlign: "center" }}>
        {i18n.aug23_impr2_nft_use_desc()}
      </Label>
      <Spacing height={40} />
      <GridLayout
        spanCount={3}
        items={sortedCapability.map((capability) => {
          return (
            <CapabilityCell
              capability={capability}
              onClick={() => {
                console.log("click capability");
                onClickCapability(capability);
              }}
            />
          );
        })}
      ></GridLayout>
      <LinkButton
        $underline={true}
        onClick={() => hopper.push(`nft-circle-card/${props.nft.nftId}/info`)}
      >
        {i18n.aug23_impr2_view_info()}
      </LinkButton>
    </BSMenu>
  );
}
