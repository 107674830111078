import { Page } from "../../components/Page";
import { DemoCell } from "./DemoCell";
import { NavEnd, NavItem, NavMiddle } from "../../components/NavBar";
import { z } from "zod";
import {
  createResultToken,
  useLayerProduceResult,
  useV2ConsumeResult,
  useV2ProduceResult,
} from "../../hooks/useResult";
import { useHopper } from "../../hooks/useHopper";
import { JSONUtil } from "../../utils/JSONUtil";
import { PageFooter } from "../../components/PageHeaderFooter";
import { RegularButton } from "../../components/Buttons";
import { RowCenterButton } from "../../components/CommonStyles";
import React from "react";
import { andLog } from "../../components/handleError";

const SameLayerResult = createResultToken(z.string(), "SameLayerResult");

const DiffLayerSinglePageResult = createResultToken(
  z.number(),
  "DiffLayerSinglePageResult",
);

const DiffLayerMultiPageResult = createResultToken(
  z.object({
    count: z.number().default(0),
    value: z.string().optional(),
  }),
  "DiffLayerMultiPageResult",
);

export function ResultProducerSameLayerPage() {
  const result = useV2ProduceResult(SameLayerResult);
  const hopper = useHopper();
  return (
    <Page pageData={undefined}>
      <NavMiddle>Same Layer Producer</NavMiddle>
      <NavEnd>
        {NavItem.done("Submit", () => {
          result.fill("DONE string").then(() => hopper.back());
        })}
      </NavEnd>
    </Page>
  );
}

export function ResultProducerDifferentLayerSinglePage() {
  const result = useLayerProduceResult(DiffLayerSinglePageResult);
  const hopper = useHopper();
  return (
    <Page pageData={undefined}>
      <NavMiddle>Different Final Layer</NavMiddle>
      <NavEnd>
        {NavItem.done("Submit", () => {
          result.fill(1001).then(() => hopper.dismissLayer());
        })}
      </NavEnd>
    </Page>
  );
}

export function ResultProducerIntermediatePage() {
  const result = useLayerProduceResult(DiffLayerMultiPageResult);
  const hopper = useHopper();
  return (
    <Page pageData={undefined}>
      <NavMiddle>Intermediate</NavMiddle>
      <DemoCell
        onClick={() => {
          result
            .fill((prev) => ({
              ...prev,
              count: prev ? prev.count + 1 : 0,
            }))
            .then(() => hopper.push("result-producer-intermediate-demo"));
        }}
      >
        Go Next
      </DemoCell>
      <DemoCell onClick={() => hopper.push("result-producer-final-demo")}>
        Go Final
      </DemoCell>
    </Page>
  );
}

export function ResultProducerFinalPage() {
  const result = useLayerProduceResult(DiffLayerMultiPageResult);
  const hopper = useHopper();
  return (
    <Page pageData={undefined}>
      <NavMiddle>Different Final Layer</NavMiddle>
      <NavEnd>
        {NavItem.done("Submit", () => {
          result
            .fill((prev) => ({
              count: prev?.count ?? 0,
              value: "DONE",
            }))
            .then(() => hopper.dismissLayer());
        })}
      </NavEnd>
    </Page>
  );
}

export function ResultHookDemoPage() {
  const sameLayerResult = useV2ConsumeResult(SameLayerResult);
  const diffLayerResult = useV2ConsumeResult(DiffLayerSinglePageResult);
  const diffLayerMultiPageResult = useV2ConsumeResult(DiffLayerMultiPageResult);
  const hopper = useHopper();

  async function resetAll() {
    await sameLayerResult.clear();
    await diffLayerResult.clear();
    await diffLayerMultiPageResult.clear();
  }

  return (
    <Page pageData={undefined}>
      <NavMiddle>Result Consumer</NavMiddle>
      <DemoCell onClick={() => hopper.push("result-producer-same-layer-demo")}>
        {`Push: ${sameLayerResult.result}`}
      </DemoCell>
      <DemoCell
        onClick={() =>
          hopper.layer("result-producer-different-layer-single-demo")
        }
      >
        {`Layer: ${diffLayerResult.result}`}
      </DemoCell>
      <DemoCell
        onClick={() => hopper.layer("result-producer-intermediate-demo")}
      >
        {`Layer MultiPage: ${JSONUtil.stringify(diffLayerMultiPageResult.result)}`}
      </DemoCell>

      <PageFooter obscuredZoneKey={"DemoPageFoot"}>
        <RegularButton
          $baseBgColor={"var(--color-bg)"}
          style={{
            ...RowCenterButton,
            marginTop: 8,
          }}
          onClick={() => {
            resetAll().catch(andLog);
          }}
        >
          Reset
        </RegularButton>
      </PageFooter>
    </Page>
  );
}
