import styled from "styled-components";
import React, { CSSProperties } from "react";
import { JSONUtil } from "../utils/JSONUtil";
import { HStackMixin } from "./VStack";

export const FilterTab = styled.div<{ selected: boolean }>`
  padding: 5px 16px;
  border-style: inset;
  border-width: 1px;
  border-radius: 4px;
  font-size: 12px;
  font-style: normal;
  text-align: center;
  ${(p) => getButtonStyle(p.selected)}
`;
export const getButtonStyle = (selected: boolean) => ({
  color: selected ? "#94FFDE" : "rgba(255, 255, 255, 0.6)",
  borderColor: selected ? "#0DFFCC" : "transparent",
  backgroundColor: selected
    ? "rgba(0, 175, 163, 0.14)"
    : "rgba(255, 255, 255, 0.08)",
});

const Container = styled.div`
  ${HStackMixin};
  overflow-x: auto;
  white-space: nowrap;
  gap: 11px;
  scrollbar-width: none;
  flex-shrink: 0;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export function HFilter<C extends {}>(props: {
  readonly candidates: { readonly value: C; readonly title: string }[];
  readonly onSelect: (c: C) => void;
  readonly selected?: C;
  readonly style?: Pick<
    CSSProperties,
    | "marginTop"
    | "marginBottom"
    | "margin"
    | "marginLeft"
    | "marginRight"
    | "paddingLeft"
    | "paddingRight"
    | "paddingTop"
    | "paddingBottom"
    | "padding"
    | "gap"
    | "justifyContent"
  >;
}) {
  return (
    <Container style={props.style}>
      {props.candidates.map((c) => (
        <FilterTab
          key={JSONUtil.stringify(c.value)}
          selected={c.value === props.selected}
          onClick={() => props.onSelect(c.value)}
        >
          {c.title}
        </FilterTab>
      ))}
    </Container>
  );
}
