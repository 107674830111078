import styled from "styled-components";
import React, { forwardRef, ReactNode } from "react";

export interface TabLayoutProps {
  chosenTabIndex: number;
  setChosenTabIndex: (index: number) => void;
  themeColor?: string;
  tabsCount: number;
  getTitle: (index: number) => ReactNode;
  allowMultipleLines?: boolean;
  equalDistribution?: boolean;
}

const ScrollableContainer = styled.div<{ $allowMultipleLines: boolean }>`
  position: sticky;
  top: 0;
  z-index: 999; // 确保 Tab 不会被其他元素遮挡
  overflow-x: auto; // 允许水平滚动
  padding-inline-start: 12px;
  padding-inline-end: 12px;
  display: flex;
  align-items: center;
  height: ${({ $allowMultipleLines }) =>
    $allowMultipleLines ? "auto" : "40px"};
  white-space: ${({ $allowMultipleLines }) =>
    $allowMultipleLines ? "wrap" : "nowrap"};
  flex-wrap: ${({ $allowMultipleLines }) =>
    $allowMultipleLines ? "wrap" : "nowrap"};
  flex-direction: row;

  /* 隐藏滚动条，兼容各浏览器 */
  -ms-overflow-style: none; /* IE 和 Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari 和 Opera */
  }
`;

const Tab = styled.div<{ $isActive: boolean }>`
  display: inline-block; // 使其水平排列
  transform: ${({ $isActive }) =>
    $isActive ? "scale(1)" : "scale(0.9)"}; // 文字放大
  color: ${({ $isActive }) =>
    $isActive ? "#FFFFFF" : "rgba(255,255,255,0.8)"};
  font-weight: ${({ $isActive }) => ($isActive ? 700 : 500)};
  font-size: 17px; // 调整字体大小
  transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1); // 使用自定义插值器
  outline: none; // 去除聚焦时的默认轮廓
  user-select: none; // 禁止用户选择文本
`;

const TabLayout = forwardRef(function (
  {
    chosenTabIndex,
    setChosenTabIndex,
    themeColor,
    tabsCount,
    getTitle,
    allowMultipleLines = false,
    equalDistribution = false,
  }: TabLayoutProps,
  outerRef: React.ForwardedRef<HTMLDivElement>,
) {
  const tabs = Array.from({ length: tabsCount }, (_, pageIndex) => pageIndex);

  return (
    <ScrollableContainer
      ref={outerRef}
      $allowMultipleLines={allowMultipleLines}
    >
      {tabs.map((index) => (
        <TabItemContainer
          key={index}
          style={equalDistribution ? { flex: 1 } : {}}
        >
          <Tab
            onClick={() => {
              setChosenTabIndex(index);
            }}
            $isActive={index === chosenTabIndex}
          >
            {getTitle(index)}
          </Tab>

          {index === chosenTabIndex && <Indicator color={themeColor} />}
        </TabItemContainer>
      ))}
    </ScrollableContainer>
  );
});

const Line = styled.div`
  width: 1px;
  height: 11px;
  margin: auto 0;
  flex-shrink: 0;
  background: rgba(255, 255, 255, 0.25);
`;

const Indicator = styled.div<{ color?: string }>`
  width: 22px;
  position: absolute;
  bottom: 0;
  opacity: ${({ color }) => (color ? "0.6" : "0.8")};
  height: 3px;
  border-radius: 3px;
  background-color: ${({ color }) =>
    color || "white"}; // 使用传入的颜色或默认颜色
`;

const TabItemContainer = styled.div`
  display: flex;
  position: relative;
  padding-inline-start: 10px;
  padding-inline-end: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center; // 添加这一行
`;

export default TabLayout;
