import { z } from "zod";
import { zBigInt, zStatic } from "../../utils/zodUtils";

export namespace attached {
  export const Link = z.object({
    style: z.union([z.literal("block"), z.literal("inline")]).optional(),
    customTitle: z.string().optional(),
    url: z.string().optional(),
    title: z.string().optional(),
    mediaRefId: zBigInt.optional(),
  });

  export const Poll = z.object({
    pollRefId: zBigInt.default(BigInt(0)),
  });

  export const Media = z.object({
    mediaRefId: zBigInt.default(BigInt(0)),
    link: z.string().optional(),
  });

  export const Mention = z.object({
    uid: zBigInt,
    roleId: zBigInt.optional(),
    roleNameLength: z.number().optional(),
  });
}

export const Attachment = z.object({
  type: z.union([
    z.literal("media"),
    z.literal("link"),
    z.literal("poll"),
    z.literal("mention"),
    z.literal("hashTag"), //local only
  ]),
  link: attached.Link.optional(),
  media: attached.Media.optional(),
  poll: attached.Poll.optional(),
  mention: attached.Mention.optional(),
});

export type Attachment = zStatic<typeof Attachment>;

export function isBlockAttachment(attachment: Attachment) {
  switch (attachment.type) {
    case "media":
    case "poll":
      return true;
    case "link":
      return attachment.link?.style === "block" || !attachment.link?.style;
    default:
      return false;
  }
}
