import { Page } from "../../../components/Page";
import { useBigIntParam } from "../../../hooks/useTypedParam";

function CircleAnnouncementEditPage() {
  const circleId = useBigIntParam("circleId");
  const announcementId = useBigIntParam("announcementId");

  return <Page pageData={undefined}></Page>;
}

export default CircleAnnouncementEditPage;
