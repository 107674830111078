import { Page } from "../../components/Page";
import { NavMiddle } from "../../components/NavBar";
import { useI18n } from "../../hooks/useI18n";

function HomeMarketPage() {
  const i18n = useI18n();
  return (
    <Page pageData={undefined}>
      <NavMiddle>{i18n.web3_v0_nft_mall()}</NavMiddle>
    </Page>
  );
}

export default HomeMarketPage;
