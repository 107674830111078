import styled from "styled-components";

export const Label = styled.div<{
  $fontSize?: number;
}>`
  flex-shrink: 1;
  text-overflow: ellipsis;
  word-break: break-word;
  font-size: ${(p) => p.$fontSize ?? 14}px;
`;

export const OneLineLabel = styled.div<{
  $fontSize?: number;
}>`
  flex-shrink: 1;
  text-overflow: ellipsis;
  word-break: break-all;
  font-size: ${(p) => p.$fontSize ?? 14}px;

  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
`;
