import { useHopper } from "./useHopper";
import { andLog, useErrorHandler } from "../components/handleError";
import { useWebHost } from "./useBridge";
import { useCallback } from "react";

import { ASSOCIATED_DOMAIN_NAKED } from "../config/config";
import { useBackend } from "../service/APIService";
import { deepLinkToWebLink } from "../routes";
import { useReactRoutes } from "../service/ReactRoutesService";

export function isInternalLink(url: URL) {
  return (
    url.host.includes(ASSOCIATED_DOMAIN_NAKED) && url.pathname.startsWith("/s/")
  );
}

export function useOpenLink() {
  const webHost = useWebHost();
  const hopper = useHopper();
  const backend = useBackend();
  const handleError = useErrorHandler();
  const routes = useReactRoutes();

  return useCallback(
    async (urlString: string) => {
      const url = new URL(urlString);
      if (isInternalLink(url)) {
        try {
          const deeplink = await backend.parseShareLink(urlString).run();
          const weblink = routes.deepLinkToWebLink(deeplink.path || "");
          hopper.push(weblink || "");
        } catch (e) {
          handleError(e);
        }
      } else {
        webHost
          .openInWebBrowser(
            (urlString.startsWith("http") ? "" : "https://") + urlString,
          )
          .catch(andLog);
      }
    },
    [webHost, hopper],
  );
}
