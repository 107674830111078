import { z } from "zod";
import { zEnum, zStatic } from "../utils/zodUtils";

export enum VisitLinkRuleType {
  Unknown = 0,
  Black = 1,
  White = 2,
  PopularUnknown = 3,
}

export const VisitLinkResp = z.object({
  ruleType: zEnum(VisitLinkRuleType).default(VisitLinkRuleType.Unknown),
  link: z.string().optional(),
  pageTitle: z.string().optional(),
  contentHead: z.string().optional(),
  contentBody: z.string().optional(),
  visitButtonText: z.string().optional(),
});

export type VisitLinkResp = zStatic<typeof VisitLinkResp>;
