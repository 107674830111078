import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { andLog } from "../components/handleError";
import { flattenStateId, StateId } from "./StateId";
import {
  evalInitializer,
  Initializer,
  isSetActionFunction,
} from "./Initializer";
import { decodeTPS, encodeTPS } from "../bridge/Bridge";
import { getUniqueId } from "../utils/UniqueId";
import { MapKey, useMap, useSetValueForMap } from "./useMap";
import { useLayerId } from "../appshell/LayerBoundary";
import { useReactRouterLocationKey } from "../appshell/RouterLocation";

type HopStateOrAbsent<T> = { state: T } | "absent";

type SetStateTask = {
  key: string;
  promise: Promise<void>;
};

export class HopHost {
  constructor(
    readonly hopId: string,
    public readonly hopCreatedTime: number = new Date().getTime(),
    private readonly previouslySavedStates: {
      key: string;
      value: string;
    }[] = [],
    private readonly setStateForKey: (
      value: string,
      key: string,
    ) => Promise<void> = async (value: string, key: string) => {},
    private readonly removeStateForKey: (key: string) => Promise<void> = async (
      key: string,
    ) => {},
  ) {}

  private readonly accurateStates = new Map<string, HopStateOrAbsent<any>>();
  private readonly pendingStateTasks = new Map<string, SetStateTask>();

  getAccurateState<T>(key: string): HopStateOrAbsent<T> {
    const updated = this.accurateStates.get(key);
    if (updated) {
      return updated as HopStateOrAbsent<T>;
    }

    const tps = this.previouslySavedStates.find((e) => e.key === key)?.value;
    let accurateState;
    if (tps) {
      const decoded = decodeTPS(tps);
      accurateState = { state: decoded };
    } else {
      accurateState = "absent" as const;
    }

    this.accurateStates.set(key, accurateState);
    return accurateState;
  }

  getState<T>(key: string): T | undefined {
    const accurateState = this.getAccurateState<T>(key);
    if (accurateState === "absent") {
      return undefined;
    } else {
      return accurateState.state;
    }
  }

  setStateValue<T>(value: T, key: string) {
    const taskId = getUniqueId();
    this.accurateStates.set(key, {
      state: value,
    });
    const promise = this.setStateForKey(encodeTPS(value), key);
    this.pendingStateTasks.set(taskId, { key: key, promise: promise });

    promise.catch(andLog).finally(() => {
      this.pendingStateTasks.delete(taskId);
    });

    return promise;
  }

  removeStateValue(key: string): Promise<void> {
    return this.removeStateForKey(key);
  }

  allSetStateTasksSettled() {
    return Promise.allSettled(
      Array.from(this.pendingStateTasks.values()).map((t) => t.promise),
    );
  }
}

export interface HopHostContext {
  hopHostForId: (pageId: string) => HopHost;
}

export const HopHostContext = React.createContext<HopHostContext>({
  hopHostForId: (pageId: string) => new HopHost("default"),
});

export function useHopHost() {
  const key = useReactRouterLocationKey();
  const provider = useContext(HopHostContext);
  const layerId = useLayerId();
  return provider.hopHostForId(`${layerId}-${key}`);
}

export function useHopState<S>(
  id: StateId,
  initialState: Initializer<S>,
): [S, Dispatch<SetStateAction<S>>, () => void];

export function useHopState<S>(
  id: StateId | undefined,
  initialState: Initializer<S>,
): [S, Dispatch<SetStateAction<S>>, () => void] | undefined;

export function useHopState<S>(
  id: StateId,
): [S | undefined, Dispatch<SetStateAction<S | undefined>>, () => void];

export function useHopState<S>(
  id: StateId | undefined,
):
  | [S | undefined, Dispatch<SetStateAction<S | undefined>>, () => void]
  | undefined;

export function useHopState<S>(
  id: StateId | undefined,
  initialState?: Initializer<S | undefined>,
):
  | [S | undefined, Dispatch<SetStateAction<S | undefined>>, () => void]
  | undefined {
  const store = useHopHost();
  const stateId = useMemo(() => (id ? flattenStateId(id) : undefined), [id]);

  const [getState, setState] = useMap<string, S>((key) => {
    if (stateId === undefined) {
      return undefined;
    }

    const savedState = store.getAccurateState<S | undefined>(stateId);
    if (savedState === "absent") {
      return evalInitializer(initialState);
    } else {
      return savedState.state;
    }
  });
  const state = useMemo(() => {
    if (stateId === undefined) return undefined;
    return getState(stateId);
  }, [getState, stateId]);

  const setter = useCallback(
    (setStateAction: SetStateAction<S | undefined>) => {
      if (stateId === undefined) return undefined;
      if (isSetActionFunction(setStateAction)) {
        setState(stateId, (prev) => {
          const next = setStateAction(prev);
          store.setStateValue(next, stateId).catch(andLog);
          return next;
        });
      } else {
        store.setStateValue(setStateAction, stateId).catch(andLog);
        setState(stateId, setStateAction);
      }
    },
    [setState, store, stateId],
  );

  if (stateId === undefined) {
    return undefined;
  } else {
    return [state, setter, () => store.removeStateValue(stateId)];
  }
}

export function useHopMap<K extends MapKey, T>(
  id: StateId,
): readonly [
  ReadonlyMap<K, T>,
  (key: K, action: React.SetStateAction<T | undefined>) => void,
];
export function useHopMap<K extends MapKey, T>(
  id: StateId | undefined,
):
  | readonly [
      ReadonlyMap<K, T>,
      (key: K, action: React.SetStateAction<T | undefined>) => void,
    ]
  | undefined;
export function useHopMap<K extends MapKey, T>(id: StateId | undefined) {
  const [entries, setEntries] = useHopState<[K, T][]>(id, []) ?? [
    undefined,
    undefined,
  ];

  const setMap = useCallback(
    (action: SetStateAction<ReadonlyMap<K, T>>) => {
      if (setEntries) {
        setEntries((prev) =>
          Array.from(
            (isSetActionFunction(action)
              ? action(new Map(prev))
              : action
            ).entries(),
          ),
        );
      }
    },
    [setEntries],
  );

  const map: ReadonlyMap<K, T> = useMemo(() => new Map(entries), [entries]);

  const setValue = useSetValueForMap(setMap);

  if (entries === undefined) return undefined;
  return [map, setValue] as const;
}

export function useHopId() {
  return useHopHost().hopId;
}
