import { LocalMedia, MediaScene } from "../bridge/LocalMedia";
import { Media } from "../proto/Media";
import { ZodType } from "zod/lib/types";
import { assert } from "../utils/asserts";
import { urlAppendQuery } from "../utils/UrlUtil";
import { JSONUtil } from "../utils/JSONUtil";
import { base64ToTyped } from "../utils/Blob";
import { fromError } from "zod-validation-error";
import { zStatic } from "../utils/zodUtils";
import { StateId } from "../hooks/StateId";

export type BackendClient = {
  get: (url: string) => Promise<string>;
  delete: (url: string, body: string) => Promise<string>;
  post: (url: string, body: string) => Promise<string>;
  sendLocalMedia: (
    localMedia: LocalMedia,
    scene: MediaScene,
    progressListener: (uploaded: bigint, total: bigint) => void,
  ) => Promise<Media>;
};

export abstract class EndPointLike<T> {
  abstract getId(): StateId;

  abstract run(params?: object): Promise<T>;

  map<Y>(f: (t: T) => Y): EndPointLike<Y> {
    return this.mapAsync((t) => Promise.resolve(f(t)));
  }

  mapAsync<Y>(f: (t: T) => Promise<Y>): EndPointLike<Y> {
    return new EndPointMapper(this, f);
  }

  intercept(f: (t: T) => void): EndPointLike<T> {
    return this.map<T>((r) => {
      f(r);
      return r;
    });
  }

  interceptAsync(f: (t: T) => Promise<void>): EndPointLike<T> {
    return this.mapAsync<T>(async (r) => {
      await f(r);
      return r;
    });
  }
}

export class EndPointMapper<S, T> extends EndPointLike<T> {
  constructor(
    private readonly s: EndPointLike<S>,
    private readonly transformer: (s: S) => Promise<T>,
  ) {
    super();
  }

  getId(): StateId {
    return this.s.getId();
  }

  run(params?: object): Promise<T> {
    return this.s.run(params).then(this.transformer);
  }
}

export function isEndPointLike(obj: any): obj is EndPointLike<any> {
  return typeof obj === "object" && "getId" in obj && "run" in obj;
}

export class EndPoint<S extends ZodType<any, any, any>> extends EndPointLike<
  zStatic<S>
> {
  constructor(
    private readonly client: BackendClient,
    private readonly responseSchema: S,
    readonly method: "GET" | "POST" | "DELETE",
    readonly url: string,
    readonly query: { [x: string]: any } | undefined,
    readonly body: { [x: string]: any } | undefined,
  ) {
    super();
    assert(url.startsWith("/"));
    assert(!url.endsWith("/"));
    assert(
      !url.includes("?"),
      `url should not contains query. try passing in the query parameter. ${url}`,
    );
  }

  getId() {
    return [this.method, this.url, this.query, this.body] as const;
  }

  async run(params?: object): Promise<zStatic<S>> {
    const totalQuery = {
      ...this.query,
      ...params,
    };
    const totalUrl = urlAppendQuery(this.url, totalQuery);
    console.log(this.method, totalUrl);
    let data: string;
    switch (this.method) {
      case "GET":
        data = await this.client.get(totalUrl);
        break;
      case "DELETE":
        data = await this.client.delete(
          totalUrl,
          this.body ? JSONUtil.stringify(this.body) : "",
        );
        break;
      case "POST":
        data = await this.client.post(
          totalUrl,
          this.body ? JSONUtil.stringify(this.body) : "",
        );
        break;
    }
    try {
      console.log("OK", totalUrl);
      return base64ToTyped(data, this.responseSchema);
    } catch (e) {
      throw fromError(e);
    }
  }
}
