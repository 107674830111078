import { string, z } from "zod";
import { zStatic } from "../utils/zodUtils";
import { Media } from "./Media";

export const Background = z.object({
  backgroundColor: string().optional(),
  textColor: string().optional(),
  borderColor: string().optional(),
  solidColor: string().optional(),
  backgroundImage: Media.optional(),
});
export type Background = zStatic<typeof Background>;
