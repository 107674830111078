import { WebHost } from "../bridge/WebHost";
import { NativePage } from "../bridge/NativePage";
import React from "react";

export interface AppShellContext {
  webHost: WebHost;
  nativePage: NativePage;
}

export const AppShellContext = React.createContext<AppShellContext>({
  webHost: {} as WebHost,
  nativePage: {
    freezeNavBar: async (id: string, back: () => void) => {},
    unfreezeNavBar: async (id: string) => {},
  } as NativePage,
});
