import { z } from "zod";
import { zBigInt, zStatic } from "../utils/zodUtils";
import { NFT } from "./NFT";

export const PurchaseMembershipCardInfo = z.object({
  cardId: zBigInt,
  count: z.number().default(0),
  maturityProgress: z.number().optional(),
});

export type PurchaseMembershipCardInfo = zStatic<
  typeof PurchaseMembershipCardInfo
>;
