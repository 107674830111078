import { CenterModal, ModalController } from "../../../components/Modal";
import { Image } from "../../../components/Image";
import icSuccess from "../../../res/images/icon_transaction_done.png";
import icFailed from "../../../res/images/ic_alert.png";
import { AbsImage } from "../../../components/AbsImage";
import icClose from "../../../res/images/ic_close.svg";
import styled from "styled-components";
import { StoreOrderStatus } from "../../../proto/Order";
import { useI18n } from "../../../hooks/useI18n";
import { RegularButton } from "../../../components/Buttons";
import { useContext, useState } from "react";
import { ModalContext } from "../../../components/ModalContext";
import { useMyUid } from "../../../service/AuthSessionService";
import { useSWR } from "../../../hooks/swr/useSWR";
import { useBackend } from "../../../service/APIService";
import { TransactionResp } from "../../../proto/TransactionResp";
import { useNativePage } from "../../../hooks/useBridge";
import { ObjectType } from "../../../proto/ObjectSpec";
import { andLog } from "../../../components/handleError";
import { HStack, VSpace } from "../../../components/VStack";
import { CheckBox } from "../../user/UserProfilePage";
import { StoreOrderItem } from "../../../proto/Store";

export enum TransactionResult {
  SUCCESS = 0,
  FAILED = 1,
  CANCEL = 2,
}

export enum RequestType {
  DIAMOND_SWAP_COIN = 1,
  TRANSFER_STORE_TO_DEFAULT = 2,
  TRANSFER_NFT = 3,
  WITHDRAW = 4,
  PURCHASE = 5,
  PLACE_ORDER = 6,
  MINT = 7,
  GIFT_BOX = 8,
  GIFT_BOX_FROM_NFT = 9,
  GIFT_MORE = 10,
  QUOTA = 11,
  CASH_TRANSFER_OUT = 12,
  CharacterQuota = 13,
  PriorityMatchPass = 14,
  ThPartyPay = 15,
  ClaimCampaignAirdrop = 16,
  RecallCampaignAirdrop = 17,
  CampaignCashTransferOut = 18,
  ClaimCircleRewards = 19,
  PURCHASE_PROPS = 20,
  SOL_BUY_GEMS = 21,
  CIRCLE_TRADE = 22,
}

export function TransactionResultModal(props: {
  modal: ModalController;
  state: number;
  title: string;
  showDescription?: boolean;
  showAddTreasureCheckBox?: boolean;
  orderItemList?: StoreOrderItem[];
}) {
  const myUid = useMyUid();
  const backend = useBackend();
  const user = useSWR(backend.getUser(myUid));
  const i18n = useI18n();
  const nativePage = useNativePage();

  const [isChecked, setIsChecked] = useState(false);

  const onClickOK = () => {
    const storeOrderItem = props.orderItemList?.at(0);
    const nftId = storeOrderItem?.extensions?.nftId;
    const showMyTreasures = user.content?.extensions?.showMyTreasures;
    if (!isChecked) {
    } else if (!showMyTreasures) {
      nativePage
        .alertAreYouSure(
          i18n.profile_setting2_add_treasure_double_check(),
          i18n.activate(),
          i18n.app_rating_not_now(),
        )
        .then((res) => {
          if (res && nftId) {
            addTreasure(nftId).catch(andLog);
          }
        });
    } else {
      if (nftId) {
        addTreasure(nftId).catch(andLog);
      }
    }
    props.modal.close();
  };

  const addTreasure = async (nftId: bigint) => {
    const object = { objectId: nftId, objectType: ObjectType.NFT };
    try {
      await backend.addUserTreasure([object]).run();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <CenterModal modal={props.modal}>
      <Image
        src={props.state === TransactionResult.SUCCESS ? icSuccess : icFailed}
        style={{
          marginTop: -30,
          width: "70%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      />
      <VSpace height={30} />
      <Title>{props.title}</Title>
      {props.showDescription && (
        <Content>
          {i18n.thrparty_pay_payment_successfully_description()}
        </Content>
      )}
      {props.showAddTreasureCheckBox && (
        <HStack>
          <ShowNFTBar>{i18n.web3_insuf_display_it_on_profile()}</ShowNFTBar>
          <CheckBox
            checked={isChecked}
            onClick={() => setIsChecked(!isChecked)}
          />
        </HStack>
      )}
      <RegularButton onClick={onClickOK}>{i18n.ok()}</RegularButton>
    </CenterModal>
  );
}

const Title = styled.div`
  width: 100%;
  color: white;
  font-size: 16px;
  font-weight: 500;
  margin-top: -70px;
  text-align: center;
`;

const Content = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  margin-top: 14px;
  margin-bottom: 14px;
  color: #ffffff99;
  word-break: break-word;
  text-align: center;
`;

const ShowNFTBar = styled.div`
  color: #ffffff99;
  font-size: 12px;
  font-weight: 500;
  flex: 1;
`;
