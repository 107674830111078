import { z } from "zod";
import { Currency } from "./Currency";
import { User } from "./User";
import { zBigInt, zStatic } from "../utils/zodUtils";
import { PaymentItemExtensions, Store, StoreOrderItem } from "./Store";
import { Coupon } from "./Coupon";

export enum UserTaskType {
  Unknown = 0,
  UserTaskTypeActivateWallet = 1,
  UserTaskTypePurchaseMerch = 2,
  UserTaskTypePolishProfile = 3,
  UserTaskTypeInviteFriends = 4,
  UserTaskTypeMatch = 5,
  UserTaskTypeWidget = 6,
  UserTaskTypeCreateNFT = 7,
  UserTaskTypeShareNFT = 8,
  CreateProtoNFT = 9,
  CheckIn = 10,
  InviteFriendsV2 = 11,
  EnableWidgetV2 = 12,
  EnableCompanion = 13,
  Match = 14,
}

export const PaymentItem = z.object({
  type: z.number().optional(),
  currencyType: z.number().optional(),
  amount: z.string().optional(),
  extensions: PaymentItemExtensions.optional(),
  couponId: zBigInt.optional(),
  coupon: Coupon.optional(),
});

export type PaymentItem = zStatic<typeof PaymentItem>;

export const StoreOrder = z.object({
  orderId: zBigInt,
  orderType: z.number().optional(),
  status: z.number().optional(),
  orderItemList: z.array(StoreOrderItem).optional(),
  orderAmountList: z.array(PaymentItem).optional(),
  paymentItemList: z.array(PaymentItem).optional(),
  serviceFeeList: z.array(Currency).optional(),
  paidTime: z.number().optional(),
  createdTime: z.number().optional(),
  buyer: User.optional(),
  store: Store.optional(),
  storeId: zBigInt.optional(),
  creatorFee: Currency.optional(),
  seller: User.optional(),
  sellerUid: zBigInt.optional(),
});

export type StoreOrder = zStatic<typeof StoreOrder>;

export enum StoreOrderType {
  PURCHASE_ORDER_HISTORY = 1,
  SALES_HISTORY = 2,
  CREATOR_FEE_HISTORY = 3,
}

export enum StoreOrderStatus {
  CREATED = 1,
  COMPLETED = 2,
  FAILED = 3,
}
