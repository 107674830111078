import { Page } from "../../components/Page";
import React from "react";
import Markdown from "markdown-to-jsx";
import styled from "styled-components";
import { useSWR } from "../../hooks/swr/useSWR";
import YAML from "yaml";
import { ensureInternalLink } from "../common/InternalLink";

const NumH1 = styled.h1`
  counter-reset: h2;

  &:before {
    counter-increment: h1;
    content: counter(h1) " ";
  }
`;

const NumH2 = styled.h2`
  counter-reset: h3;

  &:before {
    counter-increment: h2;
    content: counter(h1) "." counter(h2) " ";
  }
`;

const NumH3 = styled.h3`
  counter-reset: h4;

  &:before {
    counter-increment: h3;
    content: counter(h1) "." counter(h2) "." counter(h3) " ";
  }
`;

const NumH4 = styled.h3`
  counter-reset: h5;

  &:before {
    counter-increment: h4;
    content: counter(h1) "." counter(h2) "." counter(h3) "." counter(h4) " ";
  }
`;

const HelpP = styled.p`
  margin: 12px 0;
`;

const HelpUl = styled.ul`
  margin: 8px 16px;
`;

const HelpA = styled.a`
  color: #00ff94;
`;

const HelpPageTitle = styled.div`
  font-size: 48px;
  font-weight: 700;
  margin: 48px auto;
`;

const extractYamlMeta = (
  markdown: string,
): { title: string | undefined } | undefined => {
  // Regular expression to extract YAML front matter
  const regex = /^---\s*[\r\n]+([\s\S]*?)[\r\n]+---/m;
  const match = markdown.match(regex);

  if (match && match[1]) {
    const rawYaml = match[1]; // Extracted raw YAML string
    try {
      // Parse the YAML string into an object
      const parsedYaml = YAML.parse(rawYaml);
      return {
        title: parsedYaml["title"],
      };
    } catch (error) {
      console.error("Error parsing YAML:", error);
      return undefined;
    }
  }

  return undefined;
};

function useMarkdownHelpSWR(name: string) {
  return useSWR(["MarkdownHelp", name], async () => {
    const path = await import(`../../res/md/${name}.md`);
    const resp = await fetch(path.default);
    const md = await resp.text();
    const meta = extractYamlMeta(md);
    return { md: ensureInternalLink(md), meta: meta };
  });
}

export default function MarkdownHelpPage(props: { name: string }) {
  const helpSWR = useMarkdownHelpSWR(props.name);
  return (
    <Page
      pageData={helpSWR}
      maxContentWidth={1000}
      scrollStyle={{
        counterReset: "h1",
        userSelect: "text",
        msUserSelect: "text",
      }}
    >
      {helpSWR.content && (
        <HelpPageTitle>{helpSWR.content.meta?.title}</HelpPageTitle>
      )}
      {helpSWR.content && (
        <Markdown
          options={{
            overrides: {
              h1: NumH1,
              h2: NumH2,
              h3: NumH3,
              h4: NumH4,
              p: HelpP,
              ul: HelpUl,
              ol: { component: "ol", props: { type: "a" } },
              a: HelpA,
            },
          }}
        >
          {helpSWR.content.md}
        </Markdown>
      )}
    </Page>
  );
}
