import { Page } from "../../components/Page";
import { useI18n } from "../../hooks/useI18n";
import { CenterChild } from "../../components/VStack";

export function ComingSoonPage() {
  const i18n = useI18n();
  return (
    <Page pageData={undefined}>
      <CenterChild style={{ height: "100%" }}>
        <div>{i18n.clover_coming_soon()}</div>
      </CenterChild>
    </Page>
  );
}
