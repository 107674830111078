import { Page } from "../../components/Page";
import { Image } from "../../components/Image";
import { useHopState } from "../../hooks/useHopState";
import { Media } from "../../proto/Media";
import { isLocalMedia, LocalMedia } from "../../bridge/LocalMedia";
import React from "react";
import { useBackend } from "../../service/APIService";
import { useLoadState } from "../../hooks/LoadState";
import { andLog, useErrorHandler } from "../../components/handleError";
import { useNativePage } from "../../hooks/useBridge";
import { FilePicker } from "../../components/FilePicker";
import { PageFooter } from "../../components/PageHeaderFooter";
import { RegularButton } from "../../components/Buttons";
import { Spin } from "../../components/Spin";
import { RowCenterButton } from "../../components/CommonStyles";

export function MediaPickerDemoPage() {
  const [pendingMedia, setPendingMedia] = useHopState<
    Media | LocalMedia | undefined
  >("pendingMedia");

  const backend = useBackend();

  const handleError = useErrorHandler();
  const uploadTask = useLoadState();
  const nativePage = useNativePage();

  async function upload() {
    const localMedia = pendingMedia;
    if (localMedia) {
      if (isLocalMedia(localMedia)) {
        const r = await uploadTask.run(async () => {
          const media = await backend.sendLocalMedia(
            localMedia,
            "nft_thumbnail",
            (a, b) => {},
          );
          setPendingMedia(media);
        });
        if (!r.success) {
          handleError(r.error);
        }
      } else {
        nativePage.infoHud("uploaded before").catch(andLog);
      }
    } else {
      nativePage.alertNotice("Please select an image", "OK").catch(andLog);
    }
  }

  return (
    <Page pageData={undefined}>
      <div style={{ marginLeft: "auto", marginRight: "auto" }}>
        <FilePicker accept={"image/*"} onPick={setPendingMedia}>
          <Image
            style={{ border: "solid 1px white" }}
            src={[pendingMedia, 200]}
            width={200}
            height={200}
          />
        </FilePicker>
      </div>

      <PageFooter obscuredZoneKey={"SendNext"}>
        <RegularButton
          $baseBgColor={"var(--color-bg)"}
          style={{
            ...RowCenterButton,
            marginTop: 8,
          }}
          onClick={() => upload().catch(andLog)}
        >
          <Spin state={uploadTask.state}>{"Submit"}</Spin>
        </RegularButton>
      </PageFooter>
    </Page>
  );
}
