import { UserCellProps } from "./CommonUserCell";
import { useI18n } from "../../hooks/useI18n";
import { useBackend } from "../../service/APIService";
import { useNativePage } from "../../hooks/useBridge";
import { VStack } from "../../components/VStack";
import { UserIconView } from "../../components/views/UserAvatarView";
import { UserNameView } from "../../components/views/UserNameView";
import styled, { css } from "styled-components";
import React from "react";
import { visitorActiveTime } from "./UserProfilePage";
import { useHopper } from "../../hooks/useHopper";

function FriendUserCell(props: UserCellProps) {
  const i18n = useI18n();
  const backend = useBackend();
  const nativePage = useNativePage();
  const hopper = useHopper();
  return (
    <VStack
      style={{ alignItems: "center", gap: 4 }}
      onClick={() => hopper.push(`user/${props.user.uid}`)}
    >
      <UserIconView user={props.user} iconSize={60} />
      <UserNameView user={props.user} nameStyle={ActiveName} />
      <ActiveTime>{visitorActiveTime(props.user, i18n)}</ActiveTime>
    </VStack>
  );
}
const ActiveName = css`
  font-size: 14px;
`;

const ActiveTime = styled.div`
  color: #0dffaf;
  font-size: 11px;
  font-weight: 500;
`;
export default FriendUserCell;
