import SectionHeader from "../../components/SectionHeader";
import { Spacing } from "../../components/Spacing";
import UserHScrollItem from "../../components/UserHScrollItem";
import React from "react";
import { useI18n } from "../../hooks/useI18n";
import { CircleWidgetsCellProps } from "./CircleWidgetCell";
import { useSWRArray } from "../../hooks/swr/useSWRArray";
import { useHopper } from "../../hooks/useHopper";
import styled from "styled-components";
import { useBackend } from "../../service/APIService";

function CircleWidgetActiveMembersCell(props: CircleWidgetsCellProps) {
  const i18n = useI18n();
  const backend = useBackend();
  const activeMembersSWR = useSWRArray(
    backend.getCircleActiveMembers(props.circleId),
  );

  const hopper = useHopper();

  const handleEvent = (
    e:
      | React.WheelEvent<HTMLDivElement>
      | React.TouchEvent<HTMLDivElement>
      | React.MouseEvent<HTMLDivElement>,
  ) => {
    const { scrollLeft, scrollWidth, clientWidth } = e.currentTarget;
    const atLeftEdge = scrollLeft === 0;
    const atRightEdge = scrollLeft + clientWidth >= scrollWidth;

    if (!atLeftEdge && !atRightEdge) {
      e.stopPropagation();
    }
  };

  return (
    <>
      <Spacing height={24} />
      <SectionHeader
        title={props.widget.title ?? i18n.active_members()}
        onClickMore={() => {
          hopper.push(`circle/${props.circleId}/active-members`);
        }}
      />
      <Spacing height={16} />
      {activeMembersSWR.content && (
        <HScrollLayout
          onWheel={handleEvent}
          onTouchMove={handleEvent}
          onTouchStart={handleEvent}
          onMouseDown={handleEvent}
        >
          {activeMembersSWR.content.map((user) => (
            <UserHScrollItem
              key={user.uid}
              user={user}
              showMembershipLevel={true}
              onGoProfile={() => {
                hopper.push(`user/${user.uid}`);
              }}
            />
          ))}
        </HScrollLayout>
      )}
    </>
  );
}

const HScrollLayout = styled.div`
  display: flex; // 使用 flexbox 布局
  overflow-x: auto; // 支持水平滚动
  -webkit-overflow-scrolling: touch; // 适用于移动设备的平滑滚动
  padding-bottom: 8px; // 可以添加一些底部填充来避免内容紧贴边缘
  gap: 6px;
  margin-inline-start: 0px;
  margin-inline-end: -20px;
  padding-inline-end: 20px;

  /* 隐藏滚动条，兼容各浏览器 */
  -ms-overflow-style: none; /* IE 和 Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari 和 Opera */
  }
`;

export default CircleWidgetActiveMembersCell;
