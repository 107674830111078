import { Page } from "../../../components/Page";
import { NavMiddle } from "../../../components/NavBar";
import { useI18n } from "../../../hooks/useI18n";
import { useBackend } from "../../../service/APIService";
import {
  useBigIntParam,
  useEnumSearchParam,
} from "../../../hooks/useTypedParam";
import { useSWR } from "../../../hooks/swr/useSWR";
import { useNativePage } from "../../../hooks/useBridge";
import { useHopper } from "../../../hooks/useHopper";
import PaymentServiceFee, {
  DEFAULT_PRIORITY_FEE,
  DEFAULT_SLIPPAGE,
} from "../../wallet/payment/PaymentServiceFee";
import PaymentAccount from "../../wallet/payment/PaymentAccount";
import PaymentFooter from "../../wallet/payment/PaymentFooter";
import { BottomSheet, closeModal, useModal } from "../../../components/Modal";
import { useTransactionBody } from "../../wallet/send/SendTokenPage";
import { HStack, VSpace } from "../../../components/VStack";
import { CellDivider, ContentGroup } from "../../../components/ContentGroup";
import { Image } from "../../../components/Image";
import styled from "styled-components";
import React, { useEffect, useMemo } from "react";
import { TxSheet, usePerformTx } from "../../wallet/payment/TransactionDialog";
import { andLog, Spinner } from "../../../components/handleError";
import { useRegularAccount } from "../../wallet/useRegularAccount";
import { useAssetAccount } from "../../wallet/useAssetAccount";
import { GasFeeTxType } from "../../../proto/Wallet";
import { copyObject } from "../../../utils/copyObject";
import { ZeroGems, ZeroSOL } from "../../wallet/swap/SwapPage";
import {
  Currency,
  CurrencyType,
  formatMoney,
  getCurrencyIcon,
  getCurrencyName,
} from "../../../proto/Currency";
import BigNumber from "bignumber.js";
import { useHopId, useHopState } from "../../../hooks/useHopState";
import { jsonifyBigNumber } from "../../../utils/CurrencyAmount";
import { CardPaymentPreview } from "./CardPaymentPreview";
import { isValidCircleToken } from "../../../proto/CircleFin";
import { buyTokenAAAA } from "../../wallet/swap/TradeGroup";
import { OrderType } from "../../wallet/order/OrderDetailPage";
import { isLiteApp } from "../../../bridge/ExternalAppShellData";
import CurrencyTransactionView from "../../wallet/payment/CurrencyTransactionView";
import { TransactionBody } from "../../wallet/payment/TransactionBody";

function CircleCardPurchasePage() {
  const i18n = useI18n();
  const backend = useBackend();
  const circleId = useBigIntParam("circleId");
  const currencyType = useEnumSearchParam("currencyType");
  const nftId = useBigIntParam("nftId");
  const [isUnitPriceFilled, setUnitPriceFilled] = useHopState(
    "unitPriceFilled",
    false,
  );

  const editingBody = useTransactionBody();
  const circleSWR = useSWR(backend.getCircle(circleId));
  const nftSWR = useSWR(backend.getMembershipCardNFT(nftId));
  const unitPriceFromServer = useMemo(() => {
    return nftSWR.content?.circleMembershipCard?.priceList?.find(
      (currency) =>
        currency.currencyType ===
        (currencyType ?? (isLiteApp() ? CurrencyType.GEMS : CurrencyType.SOL)),
    );
  }, [nftSWR.content, currencyType]);

  const [unitPriceCurrency, setUnitPriceCurrency] =
    useHopState<Currency>("unitCurrency");

  const tokenSWR = useSWR(backend.getCircleToken(circleId));
  const gemsSWR = useSWR(backend.getLivePricingData());

  const hopId = useHopId();
  const tokenPool = useSWR(
    isValidCircleToken(tokenSWR.content) ? `token-poll-${hopId}` : undefined,
    () =>
      backend.getTokenPool(
        isValidCircleToken(tokenSWR.content)
          ? tokenSWR.content.projectId
          : BigInt(0),
      ),
  );

  const onCountChanged = async (count: number) => {
    const pool = tokenPool?.content;
    if (pool) {
      const amount = BigNumber(
        nftSWR?.content?.circleMembershipCard?.currency?.amount ?? "0",
      ).multipliedBy(count);
      const solAmount = buyTokenAAAA(pool, amount.toFixed(0));
      const gemsAmount = solAmount.multipliedBy(
        jsonifyBigNumber(BigNumber(gemsSWR.content?.gemsAmount ?? "0"), 9),
      );
      const paymentCurrency = copyObject<Currency>(
        unitPriceFromServer ?? (isLiteApp() ? ZeroGems : ZeroSOL),
        {
          decimals: currencyType === CurrencyType.GEMS ? 18 : 9,
          amount:
            currencyType === CurrencyType.GEMS
              ? gemsAmount.toFixed(0)
              : solAmount.toFixed(0),
        },
      );
      if (count === 1) {
        setUnitPriceCurrency(paymentCurrency);
      }
      await editingBody.fill((prevData) => ({
        ...prevData,
        cardCount: count,
        decimals: paymentCurrency?.decimals,
        currency: paymentCurrency,
        amount: paymentCurrency?.amount,
        currencyType: paymentCurrency?.currencyType,
        slippage: DEFAULT_SLIPPAGE,
        priorityFee: DEFAULT_PRIORITY_FEE,
      }));
    }
  };

  const nativePage = useNativePage();
  const hopper = useHopper();
  const paymentModal = useModal("payment-modal");
  const regularAccount = useRegularAccount();
  const walletAccountId = regularAccount?.walletAccountId;
  const assetAccount = useAssetAccount();
  const gemsAssetAccount = useAssetAccount(false);
  const currencyListSWR = useSWR(
    walletAccountId ? backend.getCurrencyList(walletAccountId) : undefined,
  );

  useEffect(() => {
    if (
      tokenPool?.content &&
      unitPriceFromServer &&
      !isUnitPriceFilled &&
      editingBody.content
    ) {
      setUnitPriceFilled(true);
      onCountChanged(1).catch(andLog);
    }
  }, [
    tokenPool?.content,
    isUnitPriceFilled,
    editingBody.content,
    unitPriceFromServer,
  ]);

  const paymentTargetCurrency = useMemo(() => {
    return (
      editingBody.content.payWithGems ??
      currencyListSWR?.content?.list?.find(
        (currencyHolder) =>
          currencyHolder.currency.currencyType ===
          editingBody.content.currencyType,
      )?.currency
    );
  }, [
    currencyListSWR?.content,
    editingBody.content.currencyType,
    editingBody.content.payWithGems,
  ]);

  const assetAccountId = assetAccount?.accountId ?? BigInt(0);
  const gemsAssetAccountId = gemsAssetAccount?.accountId ?? BigInt(0);
  const payAccountId =
    editingBody.content.payWithGems ||
    editingBody.content.currency?.currencyType === CurrencyType.GEMS
      ? gemsAssetAccountId
      : assetAccountId;

  const performTx = usePerformTx(
    async () => {
      const body = editingBody.content;
      const paymentCurrency = body.payWithGems ?? body.currency;
      const gasFeeCurrency = body.payWithGems
        ? body.gemsGasFeeCurrency
        : body.gasFeeCurrency;
      const updatedBody = copyObject<TransactionBody>(body, {
        cardId: nftId,
        payAssetAccountId: payAccountId,
        fromAccountId: payAccountId,
        fromAssetAccountId: payAccountId,
        amount: paymentCurrency?.amount,
        currencyId: paymentCurrency?.currencyId,
        currencyType: paymentCurrency?.currencyType,
        gasFeeAmount: gasFeeCurrency?.amount,
        gasFeeCurrencyId: gasFeeCurrency?.currencyId,
        gasFeeCurrencyType: gasFeeCurrency?.currencyType,
        currency: undefined,
        gemsGasFeeCurrency: undefined,
        payWithGems: undefined,
        gasFeeCurrency: undefined,
      });
      const resp = await backend.performPurchaseCircleCard(updatedBody).run();
      return resp.orderId;
    },
    async (orderId: bigint | undefined) => {
      await closeModal(paymentModal);
      await editingBody.clear();
      if (orderId) {
        hopper.dismissLayerAndPush(`wallet/order/${orderId}`, {
          orderType: OrderType.OtherOrder,
          showDialog: true,
          title: i18n.web3_insuf_purchase_completed(),
        });
      }
    },
  );

  const paymentCheck = async () => {
    const count = Number(editingBody.content.cardCount ?? 0);
    if (count <= 0 || count > 100) {
      nativePage.infoHud(i18n.mars_enter_valid_amound()).catch(andLog);
      return false;
    }

    if (
      BigNumber(editingBody.content.totalPrice ?? "0").isGreaterThan(
        BigNumber(paymentTargetCurrency?.amount ?? "0"),
      )
    ) {
      nativePage.infoHud(i18n.clover_dont_have_enough_balance()).catch(andLog);
      return false;
    }

    return true;
  };

  return (
    <Page pageData={[tokenPool, circleSWR, nftSWR, currencyListSWR]}>
      <NavMiddle>{i18n.clover_purchase_order()}</NavMiddle>
      <ContentGroup>
        <CardPaymentPreview card={nftSWR.content} circle={circleSWR.content} />

        <VSpace height={20} />
        <CellDivider />
        <VSpace height={20} />

        <HStack style={{ justifyContent: "space-between" }}>
          <UnitPriceLabel>
            {i18n.web3_ugc_unit_price_with_colon()}
          </UnitPriceLabel>
          <HStack style={{ gap: 4 }}>
            <Image
              src={getCurrencyIcon(unitPriceCurrency)}
              width={16}
              height={16}
            />
            <UnitPriceValue>
              {formatMoney("medium", unitPriceCurrency)}
            </UnitPriceValue>
            {unitPriceCurrency ? (
              <UnitPriceValue>
                {getCurrencyName(unitPriceCurrency, i18n)}
              </UnitPriceValue>
            ) : (
              <Spinner style={{ width: 16, height: 16 }} />
            )}
          </HStack>
        </HStack>
      </ContentGroup>
      <VSpace height={20} />
      <PaymentServiceFee
        editingBody={editingBody}
        gasFeeTxType={GasFeeTxType.Swap}
        showTotalAmount={true}
        showQuantity={true}
        maxCount={100}
        unitPriceCurrency={unitPriceCurrency}
        onCountChanged={onCountChanged}
      />
      <VSpace height={20} />
      <PaymentAccount
        highPriorityCurrencyId={unitPriceCurrency?.currencyId}
        fallbackCurrency={copyObject<Currency>(unitPriceCurrency ?? ZeroSOL, {
          amount: "0",
        })}
      />
      {unitPriceCurrency && (
        <PaymentFooter
          editingBody={editingBody}
          nextButtonText={i18n.clover_place_order()}
          paymentModal={paymentModal}
          paymentCheck={paymentCheck}
        />
      )}

      <TxSheet modal={paymentModal} performTx={performTx}>
        <CurrencyTransactionView body={editingBody.content} />
      </TxSheet>
    </Page>
  );
}

const UnitPriceLabel = styled.div`
  color: var(--color-text00);
  font-weight: 300;
  font-size: 14px;
`;

const UnitPriceValue = styled.div`
  color: var(--color-text00);
  font-weight: 400;
  font-size: 14px;
`;

export default CircleCardPurchasePage;
