import { Page } from "../../../components/Page";
import { NavEnd, NavItem } from "../../../components/NavBar";
import { AuthTitleLabel } from "../../auth/InvitationCodePage";
import { VSpace } from "../../../components/VStack";
import { RegularInputGroup } from "../../../components/Input";
import { StringValidator } from "../../../utils/StringValidator";
import { RegularLargeButton } from "../../../components/Buttons";
import { Spin } from "../../../components/Spin";
import React, { useMemo, useState } from "react";
import { CheckBoxWithLabel } from "../../auth/SetPasswordPage";
import { useI18n } from "../../../hooks/useI18n";
import { useHopper } from "../../../hooks/useHopper";
import { useLoadState } from "../../../hooks/LoadState";
import { useBackend } from "../../../service/APIService";
import {
  useEnumSearchParam,
  useStringSearchParam,
} from "../../../hooks/useTypedParam";
import { AuthType } from "../../../proto/Auth";
import { useSWR } from "../../../hooks/swr/useSWR";
import { useErrorHandler } from "../../../components/handleError";
import { useAuthSessionService } from "../../../service/AuthSessionService";

export function CreateNewPasswordPage() {
  const i18n = useI18n();
  const hopper = useHopper();
  const loadTask = useLoadState();
  const backend = useBackend();
  const handleError = useErrorHandler();

  const authSessionService = useAuthSessionService();
  const accountSWR = useSWR(backend.account(), {
    reloadPolicy: "refetchOnCacheMiss",
  });

  const appleAuthorizationCode = useStringSearchParam("appleAuthorizationCode");
  const googleCode = useStringSearchParam("googleCode");
  const authType = useEnumSearchParam<AuthType>("authType");

  const [newPassword, setNewPassword] = useState("");
  const buttonDisabled = useMemo(() => {
    return !(
      StringValidator.containsNumbers(newPassword) &&
      StringValidator.containsLetters(newPassword) &&
      StringValidator.containsPunctuationMarks(newPassword) &&
      StringValidator.isPwdMinCharacters(newPassword)
    );
  }, [newPassword]);

  async function handleSubmit() {
    const result = await loadTask.run(async () => {
      const auth = await backend
        .createThirdPartyPassword({
          appleAuthorizationCode: appleAuthorizationCode,
          googleCode: googleCode,
          authType: authType,
          newPassword: newPassword,
          googleRedirectUrl: window.location.origin,
        })
        .run();
      await authSessionService.add(
        {
          uid: auth.account.uid,
          sid: auth.sId,
          ptoken: auth.secret,
        },
        true,
      );
      await accountSWR.fill(auth.account);
    });
    if (!result.success) {
      handleError(result.error);
    } else {
      hopper.dismissLayer();
    }
  }

  return (
    <Page pageData={undefined}>
      <NavEnd>
        {NavItem.text(i18n.help(), () => hopper.push("feedback"))}
      </NavEnd>
      <AuthTitleLabel>{i18n.auth_create_password()}</AuthTitleLabel>
      <VSpace height={20} />
      <RegularInputGroup
        type={"password"}
        updateValue={setNewPassword}
        placeholder={i18n.new_password()}
      />
      <VSpace height={10} />
      <CheckBoxWithLabel
        text={i18n.auth_impr_password_requirement_1()}
        isChecked={StringValidator.containsNumbers(newPassword)}
      ></CheckBoxWithLabel>
      <CheckBoxWithLabel
        text={i18n.auth_impr_password_requirement_2()}
        isChecked={StringValidator.containsLetters(newPassword)}
      ></CheckBoxWithLabel>
      <CheckBoxWithLabel
        text={i18n.auth_impr_password_requirement_3()}
        isChecked={StringValidator.containsPunctuationMarks(newPassword)}
      ></CheckBoxWithLabel>
      <CheckBoxWithLabel
        text={i18n.auth_impr_password_requirement_4()}
        isChecked={StringValidator.isPwdMinCharacters(newPassword)}
      ></CheckBoxWithLabel>
      <RegularLargeButton
        disabled={buttonDisabled}
        style={{ height: "54px", margin: "27px 50px 0" }}
        onClick={handleSubmit}
      >
        <Spin state={loadTask.state}>{i18n.done()}</Spin>
      </RegularLargeButton>
    </Page>
  );
}
