import { Page } from "../../../components/Page";
import { useSWRArray } from "../../../hooks/swr/useSWRArray";
import { useBackend } from "../../../service/APIService";
import React, { useEffect, useRef, useState } from "react";
import { NavEnd, NavItem, NavMiddle } from "../../../components/NavBar";
import { useI18n } from "../../../hooks/useI18n";
import styled from "styled-components";
import { useModal } from "../../../components/Modal";
import { BSMenu, BSMenuItem } from "../../../components/BSMenu";
import { useCreateCircleResult } from "./CreateCirclePage";
import { NavButtonType } from "../../../bridge/NavButtonDesc";
import { andLog } from "../../../components/handleError";
import { useHopper } from "../../../hooks/useHopper";
import {
  createResultToken,
  useV2ConsumeResult,
  useV2ProduceResult,
} from "../../../hooks/useResult";
import { string, z } from "zod";
import { BackgroundPickerResult } from "../../common/BackgroundPickerPage";
import { useNumberParam } from "../../../hooks/useTypedParam";
import { useNativePage } from "../../../hooks/useBridge";
import {
  EditTagsPickerResult,
  getColorByHash,
  hashCode,
} from "./TagsPickerResult";

export function EditTagsPage() {
  const [searchTag, setSearchTag] = useState<string>("");

  const tagMaxCount = useNumberParam("tagMaxCount");
  const backend = useBackend();
  const i18n = useI18n();
  const tagsSWR = useSWRArray(backend.getSuggestTags(searchTag));

  // 创建一个引用来控制 TagInput
  const inputRef = useRef<HTMLInputElement | null>(null);

  const editingCircle = useCreateCircleResult();
  // 页面加载时自动聚焦输入框
  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const tagsProduceResult = useV2ProduceResult(EditTagsPickerResult);
  const nativePage = useNativePage();

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && searchTag.trim()) {
      const willAppendTag = searchTag.trim();
      const tags = tagsProduceResult.result?.tags ?? [];
      if (!tags.includes(willAppendTag)) {
        tags.splice(tags.length, 0, willAppendTag);
        tagsProduceResult.fill({ tags: tags }).catch(andLog);
      }
      setSearchTag(""); // 清空当前输入框
    }
  };

  const tagRemoveModal = useModal("tagRemoveModal");
  const clickTagName = useRef<string | undefined>();
  const hopper = useHopper();
  const saveTags = () => {
    hopper.back();
  };

  return (
    <Page pageData={undefined}>
      <NavMiddle>{i18n.edit_tags()}</NavMiddle>
      <NavEnd>{NavItem.image(NavButtonType.Check, saveTags)}</NavEnd>
      <TagCollection>
        {(tagsProduceResult.result?.tags ?? []).map((tag) => {
          const color = getColorByHash(hashCode(tag));
          return (
            <Tag
              key={tag}
              style={{
                backgroundColor: color.backgroundColor,
                borderColor: color.textColor,
                color: color.textColor,
              }}
              onClick={() => {
                clickTagName.current = tag;
                tagRemoveModal.open();
              }}
            >
              #{tag}
            </Tag>
          );
        })}
        {(tagsProduceResult.result?.tags.length ?? 0) < tagMaxCount && (
          <TagInputWrapper>
            <TagInputFirst>#</TagInputFirst>
            <TagInput
              ref={inputRef}
              value={searchTag}
              onChange={(e) => setSearchTag(e.target.value)}
              onKeyDown={handleKeyPress}
              placeholder={i18n.new_tag()}
            />
          </TagInputWrapper>
        )}
      </TagCollection>
      {(tagsProduceResult.result?.tags.length ?? 0) < tagMaxCount &&
        tagsSWR.content.map((info) => (
          <TagCell
            key={info}
            onClick={() => {
              const tags = tagsProduceResult.result?.tags ?? [];
              if (!tags.includes(info)) {
                tags.splice(tags.length, 0, info);
                tagsProduceResult.fill({ tags: tags }).catch(andLog);
              }
              setSearchTag("");
            }}
          >
            {info}
          </TagCell>
        ))}

      <BSMenu modal={tagRemoveModal}>
        <BSMenuItem
          titleStyle={{ color: "rgba(239, 53, 55, 1)" }}
          title={i18n.action_remove()}
          onClick={() => {
            const tags = tagsProduceResult.result?.tags ?? [];
            const newTags = tags.filter((tag) => tag !== clickTagName.current);
            tagsProduceResult.fill({ tags: newTags }).catch(andLog);
          }}
        />
        <BSMenuItem
          title={i18n.cancel()}
          onClick={() => {
            tagRemoveModal.close();
          }}
        />
      </BSMenu>
    </Page>
  );
}

const TagInputWrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 15px;
  border-style: dashed;
  border-width: 1px;
  border-color: rgba(255, 255, 255, 0.5);
`;

const TagCell = styled.div`
  font-size: 14px;
  font-weight: 300;
  color: #ffffff;
  height: 44px;
  display: flex;
  align-items: center;
`;

export const Tag = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  background-color: #3a3a3a;
  padding: 5px 10px;
  border-style: solid;
  border-width: 1px;
  border-radius: 20px;
`;

const TagInputFirst = styled.div`
  font-weight: 700;
  font-size: 12px;
  padding-inline-start: 9px;
  color: rgba(255, 255, 255, 0.5);
`;

const TagInput = styled.input`
  font-weight: 700;
  font-size: 12px;
  height: 23px;
  margin-inline-start: -2px;
  color: rgba(255, 255, 255, 0.5);
  background-color: transparent;
`;

export const TagCollection = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 15px 0px;
  margin-bottom: 6px;
  border-radius: 10px;
`;
