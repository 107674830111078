import { z } from "zod";
import { Media } from "./Media";
import { zStatic } from "../utils/zodUtils";

export const ThirdPartyPayment = z.object({
  name: z.string(),
  type: z.number(),
  icon: Media,
  isPopular: z.boolean().optional(),
});

export type ThirdPartyPayment = zStatic<typeof ThirdPartyPayment>;
