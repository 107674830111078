import { useI18n } from "../../hooks/useI18n";
import { Page } from "../../components/Page";
import React, { useState } from "react";
import { RegularInputGroup } from "../../components/Input";
import { VSpace } from "../../components/VStack";
import { RegularLargeButton } from "../../components/Buttons";
import { useHopper } from "../../hooks/useHopper";
import { AuthBody, AuthType } from "../../proto/Auth";
import { andLog, useErrorHandler } from "../../components/handleError";
import { useLoadState } from "../../hooks/LoadState";
import { Spin } from "../../components/Spin";
import { NavEnd, NavItem, NavMiddle } from "../../components/NavBar";
import { SetPasswordType } from "./SetPasswordPage";
import { useBackend } from "../../service/APIService";
import { useAuthSessionService } from "../../service/AuthSessionService";
import styled from "styled-components";
import { isRejectable } from "../../bridge/Rejectable";
import { APIErrorCode } from "../../proto/APIErrorRespBody";
import { useNativePage } from "../../hooks/useBridge";

export function EmailLogInPage() {
  const i18n = useI18n();
  const backend = useBackend();
  const hopper = useHopper();
  const handleError = useErrorHandler();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const authSessionService = useAuthSessionService();
  const logInTask = useLoadState();

  const nativePage = useNativePage();

  async function doLogIn(
    authBody: AuthBody,
    requestToBeReactivated: boolean = false,
  ) {
    const r = await logInTask.run(async () => {
      const auth = await backend
        .logIn({
          ...authBody,
          ignoresDisabled: true,
          requestToBeReactivated: requestToBeReactivated,
        })
        .run();
      await authSessionService.add(
        {
          uid: auth.account.uid,
          sid: auth.sId,
          ptoken: auth.secret,
        },
        true,
      );
    });

    if (!r.success) {
      const e = r.error;
      if (
        isRejectable(e) &&
        e.apiCode === APIErrorCode.APICodeAccountDeletedCanBeReactivated &&
        !requestToBeReactivated
      ) {
        const reactivate = await nativePage.alertYesOrCancel(
          i18n.account_reactivate_message(),
          i18n.reactivate(),
          i18n.cancel(),
        );
        if (reactivate) {
          await doLogIn(authBody, true);
        }
      } else {
        handleError(e);
      }
    }
  }

  function logIn() {
    if (email && password) {
      doLogIn({
        authType: AuthType.Email,
        email: email,
        password: password,
      }).catch(andLog);
    }
  }

  return (
    <Page pageData={undefined}>
      <NavMiddle>{i18n.auth_log_in()}</NavMiddle>
      <NavEnd>
        {NavItem.text(i18n.help(), () => hopper.push("feedback"))}
      </NavEnd>
      <RegularInputGroup
        type="email"
        value={email}
        updateValue={setEmail}
        placeholder={i18n.email()}
      />
      <VSpace height={20} />
      <RegularInputGroup
        type={"password"}
        value={password}
        updateValue={setPassword}
        placeholder={i18n.password()}
      />
      <VSpace height={40} />
      <RegularLargeButton onClick={logIn}>
        <Spin state={logInTask.state}>{i18n.auth_log_in()}</Spin>
      </RegularLargeButton>
      <ForgotPasswordLabel
        onClick={() =>
          hopper.layer("verify-account", {
            authType: AuthType.Email,
            setPasswordType: SetPasswordType.CreateANew,
          })
        }
      >
        {i18n.auth_forgot_password()}
      </ForgotPasswordLabel>
    </Page>
  );
}

export const ForgotPasswordLabel = styled.div`
  color: var(--color-text10);
  font-weight: 400;
  font-size: 12px;
  text-decoration: underline;
  text-align: center;
  margin-top: 20px;
`;
