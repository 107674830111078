import {
  Chat,
  ChatListCategory,
  getChatDisplayName,
  isChatThreadParty,
  isJoinedChat,
} from "../../proto/Chat";
import { useI18n } from "../../hooks/useI18n";
import GridLayout from "../../components/GridLayout";
import { VStack } from "../../components/VStack";
import styled from "styled-components";
import React, { useEffect, useRef, useState } from "react";
import {
  ChatListType,
  GeneralChatListPage,
} from "../chat/list/GeneralChatListPage";
import ChatThreadIcon from "../chat/components/ChatThreadIcon";
import SearchLayout from "../../components/SearchLayout";
import { useSWROffsetList } from "../../hooks/swr/useSWRList";
import { useMyUid } from "../../service/AuthSessionService";
import { useBackend } from "../../service/APIService";
import {
  ViewPagerPage,
  ViewPagerTabLayout,
} from "../../components/ViewPagerPage";
import { subpages } from "../../components/Subpage";
import { alpha_on_pressed } from "../../components/CommonStyles";
import { useHopper } from "../../hooks/useHopper";
import { useWS, WSListener } from "../../service/WebSocketService";
import { WSMessage } from "../../proto/WSMessage";
import { ChatMessage, ChatMessageType } from "../../proto/ChatMessage";
import { andLog } from "../../components/handleError";
import { ObjectPool } from "../chat/utils/ObjectPool";
import { NavMiddle } from "../../components/NavBar";

const PCText = styled.div`
  overflow: hidden;
  line-clamp: 1;
  text-overflow: ellipsis;
  word-break: break-all;

  display: -webkit-box;

  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

function PinnedChat(props: { chat: Chat }) {
  const myUid = useMyUid();
  const hopper = useHopper();

  return (
    <VStack
      style={{
        width: "100%",
        overflow: "hidden",
        flexGrow: 0,
      }}
      mixin={[alpha_on_pressed]}
      onClick={() => {
        hopper.modal("nyi");
        // hopper.push(`chat/${props.chat.threadId}`);
      }}
    >
      <ChatThreadIcon chat={props.chat} />
      <PCText>{getChatDisplayName(props.chat, myUid)}</PCText>
    </VStack>
  );
}

function HomeMyChatsPage() {
  const backend = useBackend();
  const i18n = useI18n();
  const hopper = useHopper();
  const { addListener, removeListener } = useWS();

  const pinnedChats = useSWROffsetList(
    backend.getJoinedThreads(ChatListCategory.pinned),
    { maxSize: 100, pageSize: 100 },
  );

  const entries: [ChatListType, string][] = [
    [ChatListType.private, i18n.party_on_tab_private()],
    [ChatListType.party, i18n.parties()],
    [ChatListType.invite, i18n.party_on_invites()],
    [ChatListType.atMention, `@${i18n.party_on_mentions()}`],
    [ChatListType.managed, i18n.managed()],
  ];

  const threadPool = useRef(
    new ObjectPool((oId) => backend.getThreadInfo(oId, false).run()),
  );
  const newMessageBuffer = useRef<Map<bigint, ChatMessage>>(new Map());
  const [extraPrivateThreads, setExtraPrivateThreads] = useState<Chat[]>([]);
  const [extraPartyThreads, setExtraPartyThreads] = useState<Chat[]>([]);
  const [extraInviteThreads, setExtraInviteThreads] = useState<Chat[]>([]);

  function extraThreadsFor(type: ChatListType) {
    switch (type) {
      case ChatListType.private:
        return extraPrivateThreads;
      case ChatListType.party:
        return extraPartyThreads;
      case ChatListType.invite:
        return extraInviteThreads;
      default:
        return [];
    }
  }

  const cacheNewMsg = (msg: ChatMessage) => {
    if (!msg.threadId) {
      return;
    }
    newMessageBuffer.current.set(msg.threadId, msg);
  };

  const handleNewMsg = async (msg: ChatMessage) => {
    if (!msg.threadId) {
      return;
    }
    const threadId = msg.threadId;

    if (msg.asSummary !== true && msg.type !== ChatMessageType.SmallNote) {
      return;
    }

    const threadInPool = threadPool.current.getCachedObject(threadId);
    if (!threadInPool) {
      const latestThreadInfo = await backend
        .getThreadInfo(threadId, false)
        .run();
      threadPool.current.cacheObject(threadId, latestThreadInfo);
      if (!isJoinedChat(latestThreadInfo)) {
        setExtraInviteThreads([...extraInviteThreads, latestThreadInfo]);
      } else if (isChatThreadParty(latestThreadInfo)) {
        setExtraPrivateThreads([...extraPrivateThreads, latestThreadInfo]);
      } else {
        setExtraPartyThreads([...extraPartyThreads, latestThreadInfo]);
      }
      // TODO: append to @mention & managed
      return;
    }
    (threadInPool as any).latestMessage = msg;
    (threadInPool as any).latestMessageId = msg.messageId;
    threadPool.current.cacheObject(threadId, threadInPool);
  };

  const cleanBuffer = () => {
    newMessageBuffer.current.forEach((msg) => {
      handleNewMsg(msg).catch(andLog);
    });
    newMessageBuffer.current.clear();
  };

  useEffect(() => {
    const listener: WSListener = {
      onMessage(msg: WSMessage): void {
        if (!msg.msg) {
          return;
        }
        cacheNewMsg(msg.msg);
      },
      onAck(msg: WSMessage): void {},
      onFailureSend(msg: WSMessage): void {},
      uniqueKey: `thread-list`,
    };
    addListener(listener);
    return () => {
      removeListener(listener);
    };
  }, [addListener, removeListener]);

  useEffect(() => {
    const handler = window.setInterval(cleanBuffer, 200);
    return () => {
      clearInterval(handler);
    };
  }, [cleanBuffer]);

  useEffect(() => {
    if (!pinnedChats.content) return;
    pinnedChats.content.list.forEach((chat: Chat) => {
      if (chat.threadId) {
        threadPool.current.cacheObjectIfNotExist(chat.threadId, chat);
      }
    });
  }, [pinnedChats]);

  return (
    <ViewPagerPage
      subpages={subpages(entries, (e) => (
        <GeneralChatListPage
          type={e}
          threadPool={threadPool.current}
          extraThreads={extraThreadsFor(e)}
        />
      ))}
    >
      <NavMiddle>{i18n.chat_my_chat_list()}</NavMiddle>
      <SearchLayout
        onClick={() => {
          hopper.push("search-joined-chats");
        }}
      />
      <GridLayout
        spanCount={5}
        items={(pinnedChats.content?.list ?? []).map((chat) => (
          <PinnedChat
            chat={threadPool.current.getCachedObject(chat.threadId) ?? chat}
            key={chat.threadId}
          />
        ))}
        style={{ margin: `0px var(--page-h-inset)` }}
      />
      <ViewPagerTabLayout />
    </ViewPagerPage>
  );
}

export default HomeMyChatsPage;
