import { Page } from "../../components/Page";
import { NavMiddle } from "../../components/NavBar";
import { useI18n } from "../../hooks/useI18n";
import { HStack, VStack } from "../../components/VStack";
import styled from "styled-components";
import iconGEMS from "../../../src/res/images/icon_gems.svg";
import chevron from "../../../src/res/images/icon_section_header_show_more.svg";
import { ButtonColor, RegularButton } from "../../components/Buttons";
import { useSWR } from "../../hooks/swr/useSWR";
import { useBackend } from "../../service/APIService";
import { useBigIntParam } from "../../hooks/useTypedParam";
import { CurrencyType, formatMoney } from "../../proto/Currency";
import { useHopper } from "../../hooks/useHopper";
import { useWalletSWR } from "./useWallet";
import { getRandomId } from "../../utils/randomId";

function ShopAccountPage() {
  const i18n = useI18n();
  const backend = useBackend();
  // const walletAccountSWR = useSWR(backend.getWalletAccount(walletAccountId));
  const [wallet, walletSWR] = useWalletSWR();
  const hopper = useHopper();
  return (
    <Page pageData={walletSWR}>
      <NavMiddle>{i18n.web3_v0_store_account()}</NavMiddle>
      <VStack style={{ marginTop: 20 }}>
        <GemFrame
          onClick={() =>
            hopper.push(
              `wallet/${wallet?.storeAccount?.accountId}/token/${CurrencyType.GEMS}`,
              {
                from: "store",
                currencyId: walletSWR.content?.storeAccount?.currencyList?.find(
                  (currency) => currency.currencyType === CurrencyType.GEMS,
                )?.balance?.currencyId,
              },
            )
          }
        >
          <GemIcon />
          <GemName>{i18n.web3_v0_diamond()}</GemName>
          <GemAmount>
            {formatMoney(
              "medium",
              walletSWR.content?.storeAccount?.currencyList?.find(
                (currency) => currency.currencyType === CurrencyType.GEMS,
              )?.balance,
            ) || 0}
          </GemAmount>
          <Chevron />
        </GemFrame>
        <HStack
          style={{
            justifyContent: "space-between",
            padding: "14px 0",
            borderBottom: "0.5px solid rgba(255, 255, 255, 0.1)",
          }}
        >
          <InfoText>{i18n.clover_available_for_transfer()}</InfoText>
          <InfoText
            style={{
              color: "white",
              display: "flex",
              alignItems: "center",
              gap: 4,
            }}
          >
            <GemIcon style={{ width: 18, height: 18 }} />
            {formatMoney(
              "medium",
              walletSWR.content?.storeAccount?.currencyList?.find(
                (currency) => currency.currencyType === CurrencyType.GEMS,
              )?.availableCurrency,
            ) || 0}
          </InfoText>
        </HStack>
        <InfoText style={{ fontSize: 11, margin: "12px 0" }}>
          {i18n.clover_transfer_intro()}
        </InfoText>
        <RegularButton
          onClick={() => {
            hopper.layer(`shop-transfer`);
          }}
          style={{ margin: "54px 12px 0" }}
          $baseColor={ButtonColor.greenish}
        >
          {i18n.transfer()}
        </RegularButton>
      </VStack>
    </Page>
  );
}

const InfoText = styled.div`
  font-weight: 300;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.4);
`;
const Chevron = styled.div`
  background-image: url("${chevron}");
  width: 20px;
  background-size: 100%;
  background-repeat: no-repeat;
  height: 20px;
`;

const GemName = styled.div`
  font-size: 18px;
  color: #ffffff;
  margin: 0 12px;
  flex: 1;
`;

const GemIcon = styled.div`
  width: 32px;
  height: 32px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: url("${iconGEMS}");
`;

const GemFrame = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const GemAmount = styled.div`
  font-weight: 500;
  font-size: 20px;
  color: #ffffff;
  margin: 0 8px;
`;

export default ShopAccountPage;
