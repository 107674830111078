import { useI18n } from "../../hooks/useI18n";
import { Page } from "../../components/Page";
import { useEffect, useRef, useState } from "react";
import { HStack, VSpace } from "../../components/VStack";
import { useHopper } from "../../hooks/useHopper";
import { AuthBody, AuthType, SecurityPurpose } from "../../proto/Auth";
import {
  useEnumSearchParam,
  useStringSearchParam,
} from "../../hooks/useTypedParam";
import { RegularLargeButton } from "../../components/Buttons";
import { Spin } from "../../components/Spin";
import { LoadFailed, useLoadState } from "../../hooks/LoadState";
import CodeInput from "../../components/CodeInput";
import { getErrorMsg } from "../../bridge/Rejectable";
import { NavEnd, NavItem } from "../../components/NavBar";
import { AuthSubtitleLabel, AuthTitleLabel } from "./InvitationCodePage";
import { SetPasswordType } from "./SetPasswordPage";
import { useBackend } from "../../service/APIService";
import { andLog } from "../../components/handleError";
import { StringValidator } from "../../utils/StringValidator";
import { AssociateType } from "../settings/accountSettings/AssociateEmailOrPhonePage";
import { useCountDown } from "../../hooks/useCountDown";
import { useHopState } from "../../hooks/useHopState";
import styled from "styled-components";

export function VerificationCodePage() {
  const i18n = useI18n();
  const backend = useBackend();
  const hopper = useHopper();
  const email = useStringSearchParam("email");
  const phoneNumber = useStringSearchParam("phoneNumber");
  const preEmail = useStringSearchParam("preEmail");
  const newEmail = useStringSearchParam("newEmail");
  const prePhoneNumber = useStringSearchParam("prePhoneNumber");
  const newPhoneNumber = useStringSearchParam("newPhoneNumber");
  const authType = useEnumSearchParam<AuthType>("authType", AuthType.Email);
  const setPasswordType =
    useEnumSearchParam<SetPasswordType>("setPasswordType");
  const associateType = useEnumSearchParam<AssociateType>("associateType");

  const purpose = useEnumSearchParam<SecurityPurpose>(
    "purpose",
    SecurityPurpose.ChangePassword,
  );
  const invitationCode = useStringSearchParam("invitationCode");
  const countryCode = useStringSearchParam("countryCode");
  const birthday = useStringSearchParam("birthday");
  const preSecurityCode = useStringSearchParam("preSecurityCode");

  const [errorInfo, setErrorInfo] = useState<string | undefined>();
  const loadTask = useLoadState();
  const loadNewCodeTask = useLoadState();

  const [curAuthType, setCurAuthType] = useState<AuthType>(authType);

  const [canNextInputCode, setCanNextInputCode] = useState<string | null>(null);

  async function handleVerificationCodeChange(value: string) {
    setErrorInfo(undefined);
    const auth = {
      securityCode: value,
      authType: authType,
      purpose: purpose,
      invitationCode: invitationCode,
      countryCode: countryCode,
      birthday: birthday,
      email: email || preEmail,
      phoneNumber: phoneNumber || prePhoneNumber,
      preSecurityCode: preSecurityCode,
    };
    let pushToAccountSettings: boolean = false;
    const result = await loadTask.run(async () => {
      switch (purpose) {
        case SecurityPurpose.UpdateEmail:
          switch (associateType) {
            case AssociateType.Add:
              await backend.checkSecurityCode(auth).run();
              await backend
                .updateEmail({
                  ...auth,
                  newEmail: email,
                  newSecurityCode: value,
                })
                .run();
              break;
            case AssociateType.Change:
              await backend.checkSecurityCode(auth).run();
              if (preSecurityCode) {
                await backend
                  .updateEmail({
                    authType: authType,
                    purpose: purpose,
                    preEmail: preEmail,
                    newEmail: newEmail,
                    preSecurityCode: preSecurityCode,
                    newSecurityCode: value,
                  })
                  .run();
                pushToAccountSettings = true;
              }
              break;
          }
          break;
        case SecurityPurpose.UpdatePhoneNumber:
          switch (associateType) {
            case AssociateType.Add:
              await backend.checkSecurityCode(auth).run();
              await backend
                .updatePhoneNumber({
                  ...auth,
                  newPhoneNumber: phoneNumber,
                  newSecurityCode: value,
                })
                .run();
              break;
            case AssociateType.Change:
              await backend.checkSecurityCode(auth).run();
              if (preSecurityCode) {
                await backend
                  .updatePhoneNumber({
                    authType: authType,
                    purpose: purpose,
                    prePhoneNumber: prePhoneNumber,
                    newPhoneNumber: newPhoneNumber,
                    preSecurityCode: preSecurityCode,
                    newSecurityCode: value,
                  })
                  .run();
                pushToAccountSettings = true;
              }
              break;
          }
          break;
        default:
          await backend.checkSecurityCode(auth).run();
          break;
      }
    });
    if (!result.success) {
      setCanNextInputCode(null);
      setErrorInfo(getErrorMsg(result.error));
    } else {
      let path = "";
      let query = {};
      switch (purpose) {
        case SecurityPurpose.Register:
          path = "create-password";
          query = {
            ...auth,
            setPasswordType: setPasswordType,
          };
          hopper.push(path, query);
          break;
        case SecurityPurpose.ChangePassword:
          path = "change-password";
          query = {
            email: email,
            phoneNumber: phoneNumber,
            purpose: purpose,
            authType: authType,
            securityCode: value,
            setPasswordType: setPasswordType,
          };
          hopper.push(path, query);
          break;
        case SecurityPurpose.UpdateEmail:
          switch (associateType) {
            case AssociateType.Add:
              hopper.push("account-settings");
              break;
            case AssociateType.Change:
              if (pushToAccountSettings) {
                hopper.push("account-settings");
              } else {
                hopper.push("update-email-confirm", {
                  ...auth,
                  preEmail: preEmail,
                  newEmail: email,
                  preSecurityCode: value,
                  associateType: associateType,
                });
              }
              break;
          }
          break;
        case SecurityPurpose.UpdatePhoneNumber:
          switch (associateType) {
            case AssociateType.Add:
              hopper.push("account-settings");
              break;
            case AssociateType.Change:
              if (pushToAccountSettings) {
                hopper.push("account-settings");
              } else {
                hopper.push("update-phone-number-confirm", {
                  ...auth,
                  prePhoneNumber: prePhoneNumber,
                  newPhoneNumber: phoneNumber,
                  preSecurityCode: value,
                  associateType: associateType,
                });
              }
              break;
          }
          break;
        default:
          return;
      }
    }
  }

  const [hasGotCodeInQuiet, setHasGotCodeInQuiet] = useHopState<boolean>(true);
  let canGetCodeRemainingSeconds = 60;
  const newCodeCountdown = useCountDown(() => {
    if (hasGotCodeInQuiet) {
      if (canGetCodeRemainingSeconds < 0) {
        setHasGotCodeInQuiet(false);
        return undefined;
      } else {
        return canGetCodeRemainingSeconds--;
      }
    } else {
      return undefined;
    }
  }, [hasGotCodeInQuiet]);

  async function getNewCode() {
    setErrorInfo(undefined);
    const result = await loadNewCodeTask.run(async () => {
      await backend
        .requestSecurityCode({
          email: email || preEmail,
          phoneNumber: phoneNumber || prePhoneNumber,
          purpose: purpose,
          authType: authType,
        })
        .run();
    });
    if (!result.success) {
      setErrorInfo(getErrorMsg(result.error));
    } else {
      setHasGotCodeInQuiet(true);
      canGetCodeRemainingSeconds = 60;
    }
  }

  const toggleAuthType = () => {
    setCurAuthType(
      curAuthType === AuthType.Email ? AuthType.Phone : AuthType.Email,
    );
  };

  const onNextClick = () => {
    console.log("onNextClick");
    if (canNextInputCode) {
      console.log("onNextClick", canNextInputCode);
      handleVerificationCodeChange(canNextInputCode).catch(andLog);
    }
  };

  const hasRun = useRef(false);
  useEffect(() => {
    if (hasRun.current) return;
    hasRun.current = true;
    console.log("useEffect");
    getNewCode().catch(andLog);
  }, []);

  return (
    <Page pageData={undefined}>
      <NavEnd>
        {NavItem.text(i18n.help(), () => hopper.push("feedback"))}
      </NavEnd>
      <AuthTitleLabel>{i18n.auth_enter_confirmation_code()}</AuthTitleLabel>
      <HStack
        style={{
          justifyContent: "center",
          gap: "4px",
        }}
      >
        <AuthSubtitleLabel>{i18n.onboarding_sent_code()}</AuthSubtitleLabel>
        <div
          style={{
            color: "var(--color-text00)",
            fontSize: "14px",
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          {email || phoneNumber}
        </div>
      </HStack>
      <VSpace height={20} />
      {[
        SecurityPurpose.AddPaymentPassword,
        SecurityPurpose.UpdatePaymentPassword,
        SecurityPurpose.ResetPaymentPassword,
      ].includes(purpose) && (
        <div
          style={{
            textAlign: "center",
            justifyContent: "center",
            color: "blue",
          }}
          onClick={toggleAuthType}
        >
          {curAuthType === AuthType.Email
            ? i18n.web3_v0_use_phone_number_instead()
            : i18n.web3_v0_use_email_instead()}{" "}
        </div>
      )}
      <VSpace height={45} />
      <CodeInput
        onChange={(value) => {
          setCanNextInputCode(value);
          handleVerificationCodeChange(value).catch(andLog);
        }}
      />
      <VSpace height={16} />
      {errorInfo && <ErrorInfoLabel>{errorInfo}</ErrorInfoLabel>}
      <VSpace height={30} />
      <GetNewCodeLabel
        style={{
          color:
            newCodeCountdown === undefined
              ? "var(--color-text00)"
              : "var(--color-text10)",
          textDecoration:
            newCodeCountdown === undefined ? "underline" : undefined,
        }}
        onClick={() => {
          if (newCodeCountdown === undefined) {
            getNewCode().catch(andLog);
          }
        }}
      >
        <Spin state={loadNewCodeTask.state}>
          {newCodeCountdown === undefined
            ? i18n.get_new_code()
            : i18n
                .plural(newCodeCountdown)
                .code_retry_in_number_seconds(newCodeCountdown)}
        </Spin>
      </GetNewCodeLabel>
      <VSpace height={16} />
      <RegularLargeButton
        disabled={!StringValidator.isValidConfirmCode(canNextInputCode || "")}
        style={{ height: "54px", margin: "0 50px" }}
        onClick={onNextClick}
      >
        <Spin state={loadTask.state}>{i18n.next()}</Spin>
      </RegularLargeButton>
    </Page>
  );
}

const ErrorInfoLabel = styled.div`
  color: #ef3537;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  min-height: 16px;
`;

const GetNewCodeLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  text-decoration: underline;
`;
