import { FC, useMemo, useState } from "react";
import styled from "styled-components";

import type { Media } from "../../proto/Media";
import { JSONUtil } from "../../utils/JSONUtil";
import { isLocalMedia, type LocalMedia } from "../../bridge/LocalMedia";
import {
  useBigIntSearchParam,
  useEnumSearchParam,
  useStringSearchParam,
} from "../../hooks/useTypedParam";
import { useI18n } from "../../hooks/useI18n";
import { useNativePage } from "../../hooks/useBridge";
import { useLoadState } from "../../hooks/LoadState";
import { useHopper } from "../../hooks/useHopper";
import { useBackend } from "../../service/APIService";
import { useGlobalSpinner } from "../../utils/globalSpinner";
import { andLog, useErrorHandler } from "../../components/handleError";
import { FlagExtension, FlagTypeEnum } from "../../proto/Flag";
import { ObjectType } from "../../proto/ObjectSpec";
import { HStack } from "../../components/VStack";
import { Page } from "../../components/Page";
import { NavEnd, NavItem, NavMiddle } from "../../components/NavBar";
import { Image } from "../../components/Image";
import { TextArea } from "../../components/Input";
import { FilePicker } from "../../components/FilePicker";

import arrowRight from "../../res/images/icon_section_header_show_more.svg";
import addIcon from "../../res/images/ic_add.svg";
import { cell_on_pressed } from "../../components/CommonStyles";

const ReasonItem = styled(HStack)`
  width: 100%;
  height: 70px;
  justify-content: space-between;
  padding: 0 20px;
  ${cell_on_pressed}
`;

const PictureList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  padding: 0 16px;
`;

const AddPicture = styled.div<{ selected: boolean }>`
  width: 100%;
  aspect-ratio: 1;
  background: rgba(255, 255, 255, 0.08);
  border-width: 1px;
  border-style: dashed;
  border-color: ${({ selected }) => (selected ? "transparent" : "rgba(255, 255, 255, 0.4)")};
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

type IReasonFile = Media | LocalMedia | undefined;

const FlagPage: FC = () => {
  const objectId = useBigIntSearchParam("objectId");
  const objectType = useEnumSearchParam("objectType") || ObjectType.NONE;
  const extensions = useStringSearchParam("extensions");
  const i18n = useI18n();
  const hopper = useHopper();
  const nativePage = useNativePage();
  const backend = useBackend();
  const globalSpinner = useGlobalSpinner();
  const uploadTask = useLoadState();
  const handleError = useErrorHandler();

  const [flagType, setFlagType] = useState(FlagTypeEnum.NO_SELECTED);

  const [message, setMessage] = useState<string>("");
  const [pendingMedia, setPendingMedia] = useState<IReasonFile>();

  const reasonList = useMemo(() => {
    return [
      {
        value: FlagTypeEnum.SUICIDE_OR_SELF_HARM,
        label: i18n.suicide_or_self_harm(),
      },
      {
        value: FlagTypeEnum.ILLEGAL_ACTIVITIES,
        label: i18n.illegal_activities(),
      },
      {
        value: FlagTypeEnum.VIOLENT_AND_GRAPHIC_CONTENT,
        label: i18n.violent_and_graphic_content(),
      },
      {
        value: FlagTypeEnum.PORNOGRAPHY_AND_NUDITY,
        label: i18n.pornography_and_nudity(),
      },
      { value: FlagTypeEnum.HATE_SPEECH, label: i18n.hate_speech() },
      {
        value: FlagTypeEnum.BULLYING_OR_HARASSMENT,
        label: i18n.bullying_or_harassment(),
      },
      { value: FlagTypeEnum.SPAM_OR_TROLING, label: i18n.spam_or_trolling() },
      { value: FlagTypeEnum.OTHERS, label: i18n.others() },
    ];
  }, [i18n]);

  const handleSubmit = () => {
    console.log(message, pendingMedia);
    if (!message) {
      nativePage
        .infoHud(i18n.winter_2022_impr_please_fill_the_content())
        .catch(andLog);
      return;
    }
    globalSpinner(async () => {
      let currentMedia = pendingMedia;
      if (currentMedia && isLocalMedia(currentMedia)) {
        const r = await uploadTask.run(async () => {
          const media = await backend.sendLocalMedia(
            currentMedia as LocalMedia,
            "nft_thumbnail",
            (a, b) => {}
          );
          return media;
        });
        if (!r.success) {
          handleError(r.error);
          nativePage
            .alertNotice("uploadedMedia undefined", i18n.ok())
            .catch(andLog);
          return;
        }
        currentMedia = r.result;
      }
      await backend
        .createFlag({
          objectId,
          objectType,
          msg: message,
          flagType,
          ...(currentMedia ? { mediaList: [currentMedia] } : {}),
          ...(extensions ? JSONUtil.parse(extensions, FlagExtension) : {}),
        })
        .run();
      hopper.dismissLayer();
    }).catch(andLog);
  };

  return (
    <Page pageData={undefined} scrollPaddingDisabled>
      <NavMiddle>
        {flagType !== FlagTypeEnum.NO_SELECTED
          ? i18n.let_us_know_here()
          : i18n.whats_wrong()}
      </NavMiddle>
      {flagType !== FlagTypeEnum.NO_SELECTED && (
        <NavEnd>{NavItem.done("Submit", handleSubmit)}</NavEnd>
      )}
      {flagType !== FlagTypeEnum.NO_SELECTED ? (
        <>
          <TextArea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder={i18n.flag_reason_placeholder()}
            rows={6}
          />
          <PictureList>
            <FilePicker accept={"image/*"} onPick={setPendingMedia}>
              <AddPicture selected={!!pendingMedia}>
                <Image
                  width={!!pendingMedia ? "100%" : "50%"}
                  height={!!pendingMedia ? "100%" : "50%"}
                  src={!!pendingMedia ? [pendingMedia, "best"] : addIcon}
                />
              </AddPicture>
            </FilePicker>
          </PictureList>
        </>
      ) : (
        <>
          {reasonList.map((item) => (
            <ReasonItem
              key={item.value}
              onClick={() => setFlagType(item.value)}
            >
              <span>{item.label}</span>
              <Image
                src={arrowRight}
                width={20}
                height={20}
                style={{ marginInlineStart: 6 }}
              />
            </ReasonItem>
          ))}
        </>
      )}
    </Page>
  );
};

export default FlagPage;