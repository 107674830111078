import { Currency } from "./Currency";
import { User } from "./User";
import { Circle } from "./Circle";
import { Media } from "./Media";
import { zBigInt, zEnum, zStatic } from "../utils/zodUtils";
import { z } from "zod";
import { ZeroSOL } from "../pages/wallet/swap/SwapPage";
import { TradingStatistics } from "./TokenProject";
import { NFT, NFTStatus } from "./NFT";
import { CircleTokenStatus } from "./CircleTokenStatus";

export enum QuoteChangeType {
  Inc = 1,
  // Dec = 2,
}

export const QuoteChange = z.object({
  type: zEnum(QuoteChangeType),
  duration: z.number(),
  fluctuation: z.number(),
});

export const DexPoolInfo = z.object({
  lpBurnedRatio: z.number().default(0),
});

const CircleTokenExtensions = z.object({
  mintAssetOrderId: zBigInt.default(0),
});

export const CircleToken = z.object({
  //Half new
  currencyId: zBigInt.default(0),
  decimals: z.number().default(9),

  chainType: z.number().default(0),

  // commentsCount: z.number().optional(),
  // creator: User.optional(),
  // description: z.string().optional(),
  //Old version
  currency: Currency.default(ZeroSOL),
  circleInfo: Circle.optional(),
  valuations: z.array(Currency).default([]),
  quoteChange: QuoteChange.default({
    type: QuoteChangeType.Inc,
    duration: 0,
    fluctuation: 0,
  }),
  //pair address depend on status
  dexPoolAddress: z.string().optional(),
  curveAddress: z.string().optional(),
  // followedByMeStatus: z.number().optional(),
  // followersCount: z.number().optional(),
  holdersCount: z.number().default(0),
  image: Media.optional(),
  // imageUrl: z.string().optional(),
  // lastCommentTime: z.number().optional(),
  name: z.string().default(""),
  // objectStatus: z.string(),
  // ordersCount: z.number().optional(),
  projectId: zBigInt.default(0),
  projectSource: z.number(),
  projectLaunchedPlatform: z.number().default(1),
  // repliesCount: z.number().optional(),
  status: zEnum(CircleTokenStatus).optional(),
  // telegramLink: z.string().optional(),
  ticker: z.string(),
  tokenAddress: z.string().default(""),
  totalSupply: z.string(),
  // twitterLink: z.string().optional(),
  // website: z.string().optional(),
  dexPoolInfo: DexPoolInfo.optional(),
  tradingStatistics: TradingStatistics.optional(),
  totalValueLocked: z.string().default(""),
  extensions: CircleTokenExtensions.optional(),
});

export type CircleToken = zStatic<typeof CircleToken>;

export enum ProjectSource {
  ToDaMoon = 1,
  PumpFun = 2,
  Stable = 3,
  Clover = 4,
}

export enum ProjectLaunchedPlatform {
  Raydium = 1,
}

export function isValidCircleToken(
  circleToken: CircleToken | {} | undefined,
): circleToken is CircleToken {
  return (
    typeof circleToken === "object" &&
    circleToken !== undefined &&
    "projectId" in circleToken &&
    !!(circleToken as CircleToken).projectId
  );
}

export const CircleNFT = z.object({
  nft: NFT,
  distributedQuantity: z.number(),
});

export type CircleNFT = zStatic<typeof CircleNFT>;

export function isValidCircleNFT(
  nft: CircleNFT | {} | undefined,
): nft is CircleNFT {
  return (
    nft !== undefined &&
    "nft" in nft &&
    !!nft["nft"] &&
    nft.nft.nftStatus === NFTStatus.Minted
  );
}

export const CircleNFTHolder = z.object({
  rank: z.number().optional(),
  nft: NFT.optional(),
  user: User,
});

export type CircleNFTHolder = zStatic<typeof CircleNFTHolder>;

export const ExternalUser = z.object({
  icon: Media,
  address: z.string(),
});

export const CircleTokenHolder = z.object({
  circleMemberInfo: User.optional(),
  currency: Currency,
  rank: z.number(),
  externalUser: ExternalUser.optional(),
});

export type CircleTokenHolder = zStatic<typeof CircleTokenHolder>;

export enum CircleTokenQueryType {
  All = 1,
  CircleMember = 2,
}

// export enum CircleNFTQueryType {
//   All = 1,
//   // CircleMember = 2,
// }

export enum CircleTokenDistributionType {
  InitialLaunch = 1,
  Community = 2,
  DexLp = 3,
}

export enum CircleContributionDistributionType {
  SocialActivities = 1,
  Content = 2,
  Financial = 3,
}

export const CircleTokenDistribution = z.object({
  type: zEnum(CircleTokenDistributionType),
  percentage: z.number(),
});

export type CircleTokenDistribution = zStatic<typeof CircleTokenDistribution>;

export const CircleContributionDistribution = z.object({
  type: zEnum(CircleContributionDistributionType),
  percentage: z.number(),
});

export type CircleContributionDistribution = zStatic<
  typeof CircleContributionDistribution
>;

export enum CircleTokenTransactionType {
  Buy = 1,
  Sell = 2,
}

export const CircleTokenTransaction = z.object({
  transactionId: zBigInt,
  user: User,
  type: zEnum(CircleTokenTransactionType),
  tokenCurrency: Currency,
  price: Currency,
});

export type CircleTokenTransaction = zStatic<typeof CircleTokenTransaction>;

export const CircleUnClaimedRewards = z.object({
  currencyList: z.array(Currency).optional(),
  nft: NFT.optional(),
  canClaim: z.boolean(),
  sopRewardDays: z.number(),
  sendRewardDays: z.number(),
  canClaimedTime: z.number(),
});

export const CircleClaimedRewardRecord = z.object({
  currency: Currency,
  createdTime: z.number(),
});

export type CircleClaimedRewardRecord = zStatic<
  typeof CircleClaimedRewardRecord
>;
