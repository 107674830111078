import { Page } from "../../components/Page";
import CircleMenuFolderCell from "./CircleMenuFolderCell";
import { useSWR } from "../../hooks/swr/useSWR";
import { useHopper } from "../../hooks/useHopper";
import { useI18n } from "../../hooks/useI18n";
import { useBigIntParam } from "../../hooks/useTypedParam";
import CircleMenuDividerCell from "./CircleMenuDividerCell";
import iconSettings from "../../res/images/icon_circle_settings.svg";
import iconShare from "../../res/images/icon_share.svg";
import iconAllMembers from "../../res/images/icon_all_members.svg";
import iconExplore from "../../res/images/icon_circle_folder_explore.png";
import iconChats from "../../res/images/icon_circle_folder_chats.png";
import iconPosts from "../../res/images/icon_circle_folder_posts.png";
import styled from "styled-components";
import { HStack, HStackMixin, Spring, VStack } from "../../components/VStack";
import { Image } from "../../components/Image";
import React, { useMemo } from "react";
import SettingCell from "../../components/SettingCell";
import { ObjectType } from "../../proto/ObjectSpec";
import { CircleLegacyFolderName } from "../../proto/CircleLegacyFolderName";
import CircleMenuIconCell from "../../proto/CircleMenuIconCell";
import { onClickCircleFolder } from "./settings/CircleFolderSettingsPage";
import CircleBackgroundFrame from "./CircleBackgroundFrame";
import { SingleLineUnspecifiedWidth } from "../../components/CommonViews";
import { useMyUid } from "../../service/AuthSessionService";
import { useBackend } from "../../service/APIService";
import iconSearch from "../../res/images/ic_abar_search.svg";
import { SearchSource } from "../search/SearchPage";
import { useCircleHasAdminReminder } from "./compose/CreateCirclePage";
import { andLog, useErrorHandler } from "../../components/handleError";
import iconStats from "../../res/images/ic_stats.svg";
import circleAdminIcon from "../../res/images/ic_circle_admin.svg";
import jumpIcon from "../../res/images/ic_right_jump.svg";
import { useLoadState } from "../../hooks/LoadState";
import { resolvePath } from "../../components/CircleWidgetBanner";
import { CircleMembershipLevelIcon } from "./membership/MembershipLevelPicker";
import MemberTitle from "../../components/MemberTitle";
import { useNativePage } from "../../hooks/useBridge";
import icLogo from "../../res/images/ic_circle_clover_logo.png";
import { CircleFolderItem } from "../../proto/CircleFolderItem";
import { NavMiddle, useHasNavBar } from "../../components/NavBar";
import { usePageSpec } from "../common/usePageSpec";
import icHome from "../../res/images/ic_home.svg";
import icInfo from "../../res/images/ic_info.svg";
import CircleCardsPreviewCell, {
  CardStyle,
} from "./card/CircleCardsPreviewCell";

export interface CircleSideMenuProps {
  circleId: bigint;
}

function CircleSideMenuPage() {
  const backend = useBackend();
  // const circleSWR = useSWR(backend.getCircle(props.circleId));
  const circleId = useBigIntParam("circleId");
  const circleSWR = useSWR(backend.getCircle(circleId));
  const i18n = useI18n();
  const hopper = useHopper();
  const myUid = useMyUid();
  const nativePage = useNativePage();

  const linkTarget = useLoadState();
  const handleError = useErrorHandler();

  async function openInternalLink(link: string) {
    const r = await linkTarget.run(async () => {
      const linkModel = await backend.linkPathFrom(link).run();
      linkModel.path && resolvePath(linkModel.path, hopper);
    });

    if (!r.success) {
      handleError(r.error);
    } else {
      // hopper.back();
    }
  }

  const isAdmin = useMemo(() => {
    return (
      circleSWR.content?.adminIdList.includes(myUid) ||
      circleSWR.content?.coAdminIdList.includes(myUid)
    );
  }, [circleSWR.content]);

  const circleHasAdminReminder = useCircleHasAdminReminder(circleId);

  const goCircleCards = () => {
    hopper.push(`circle/${circleId}/my-circle-membership`);
  };

  const onClickFolderItem = async (folderItem: CircleFolderItem) => {
    if (folderItem.folder?.isViewable) {
      if (folderItem.objectType === ObjectType.LINK) {
        folderItem.link && openInternalLink(folderItem.link);
      } else {
        onClickCircleFolder(hopper, folderItem);
      }
    } else {
      try {
        const res = await nativePage.alertAreYouSure(
          i18n.clover_folder_content_limit_tip(
            folderItem.folder?.requiredMembershipLevel || 0,
          ),
          i18n.nov_impr_button_upgrade(),
          i18n.cancel(),
        );
        if (res) {
          hopper.push(`circle/${circleId}/card-picker`);
        }
      } catch (e) {
        handleError(e);
      }
    }
  };

  const pageSpec = usePageSpec();
  const hasNavBar = useHasNavBar();
  return (
    <Page
      pageData={circleSWR}
      scrollPaddingDisabled={true}
      underlay={
        <CircleBackgroundFrame
          circleBackground={
            circleSWR.content?.menuBackground ||
            circleSWR.content?.circleBackground
          }
        />
      }
    >
      <NavMiddle>{i18n.circle()}</NavMiddle>
      {circleSWR.content && (
        <>
          <HStack
            style={{
              gap: 8,
              marginTop: hasNavBar ? 0 : 18,
              padding: "0 var(--page-h-inset)",
            }}
          >
            <ImageContainer
              onClick={() => {
                if (pageSpec === "wide") {
                  hopper.push(`circle/${circleId}`);
                } else {
                  hopper.back();
                }
              }}
            >
              <CircleButton src={icHome} />
            </ImageContainer>
            <ImageContainer
              onClick={() => hopper.push(`circle/${circleId}/detail-info`)}
            >
              <CircleButton src={icInfo} />
            </ImageContainer>
            <Spring />
            <ImageContainer
              onClick={() => {
                const hopperParam = [
                  "search",
                  {
                    searchSource: SearchSource.InCircleAll,
                    circleId: circleId,
                  },
                ] as const;
                if (pageSpec === "wide") hopper.popOver(...hopperParam);
                else hopper.push(...hopperParam);
              }}
            >
              <CircleButton
                src={iconSearch}
                style={{ width: 30, height: 30 }}
              />
            </ImageContainer>
          </HStack>
          <HStack
            style={{
              gap: 12,
              marginBottom: 24,
              paddingInlineStart: 20,
              paddingInlineEnd: 20,
              marginTop: 20,
            }}
          >
            <Image
              src={[circleSWR.content.circleIcon, "best"]}
              width={50}
              height={50}
              style={{
                borderRadius: 6,
                border: `1px solid ${circleSWR.content.themeColor}`,
              }}
            />
            <CircleHeaderName>{circleSWR.content.name}</CircleHeaderName>
          </HStack>

          {isAdmin ? (
            <CircleMembershipAdminFrame>
              <VStack style={{ gap: 8, flex: 1 }} onClick={goCircleCards}>
                <AdminTitle>{i18n.clover_circle_admin()}</AdminTitle>
                <HStack style={{ minHeight: 28 }}>
                  {circleSWR.content.currentMemberInfo
                    ?.circleMembershipCard && (
                    <CircleCardsPreviewCell
                      cardStyle={CardStyle.small}
                      card={
                        circleSWR.content.currentMemberInfo
                          ?.circleMembershipCard
                      }
                      width={50}
                    />
                  )}

                  <Image src={jumpIcon} width={20} height={20} />
                </HStack>
              </VStack>
              <MidLine />
              <VStack
                style={{ gap: 8, flex: 1, padding: "0 12px" }}
                onClick={() => {
                  circleHasAdminReminder
                    .fill(() => {
                      return false;
                    })
                    .catch(andLog);
                  hopper.push(`circle/${circleId}/admin-portal`);
                }}
              >
                <AdminTitle>{i18n.jun1_text_admin_portal()}</AdminTitle>
                <HStack>
                  <Image src={circleAdminIcon} width={36} height={36} />
                  <Image src={jumpIcon} width={20} height={20} />
                </HStack>
              </VStack>
            </CircleMembershipAdminFrame>
          ) : (
            <CircleMembershipFrame onClick={goCircleCards}>
              <HStack style={{ gap: 8 }}>
                <CircleMembershipLevelIcon
                  level={
                    circleSWR.content.currentMemberInfo?.circleMembershipCard
                      ?.membershipLevel || 0
                  }
                  width={28}
                  style={{ display: "grid", alignItems: "center" }}
                />
                <FrameTitle>{i18n.circle_fin_membership()}</FrameTitle>
              </HStack>

              <MemberTitleFrame>
                {(circleSWR.content.currentMemberInfo?.circleMembershipCard
                  ?.membershipLevel || 0) > 0 && (
                  <CircleCardsPreviewCell
                    card={
                      circleSWR.content.currentMemberInfo?.circleMembershipCard
                    }
                    cardStyle={CardStyle.small}
                    width={50}
                  />
                )}
                {(circleSWR.content.currentMemberInfo?.memberTitleList
                  ?.length || 0) > 0 && (
                  <>
                    <TitleContainer>
                      {circleSWR.content.currentMemberInfo?.memberTitleList?.map(
                        (memberTitle) => (
                          <MemberTitle
                            memberTitle={memberTitle}
                            style={{ maxWidth: 64 }}
                          />
                        ),
                      )}
                    </TitleContainer>
                    <Image src={jumpIcon} width={20} height={20} />
                  </>
                )}
              </MemberTitleFrame>
            </CircleMembershipFrame>
          )}
        </>
      )}

      <CircleMenuDividerCell title={i18n.circle2_1_circle_folders()} />
      {circleSWR.content &&
        circleSWR.content.menu?.folderItems?.map((folderItem, index) => {
          if (folderItem.objectType === ObjectType.SECTION_HEADER) {
            return (
              <CircleMenuDividerCell title={folderItem.objectName ?? ""} />
            );
          } else {
            let iconResource = "";
            if (folderItem.objectName === CircleLegacyFolderName.Explore) {
              iconResource = iconExplore;
            } else if (folderItem.objectName === CircleLegacyFolderName.Posts) {
              iconResource = iconPosts;
            } else if (folderItem.objectName === CircleLegacyFolderName.Chats) {
              iconResource = iconChats;
            }
            return (
              <CircleMenuFolderCell
                icon={
                  iconResource.length > 0 ? (
                    <Image src={iconResource} width={38} height={38} />
                  ) : (
                    <CircleMenuIconCell
                      themeColor={circleSWR.content?.themeColor}
                      folderItem={folderItem}
                    />
                  )
                }
                title={folderItem.objectName}
                description={folderItem.folder?.description}
                onClick={() => onClickFolderItem(folderItem)}
                folder={folderItem.folder}
              />
            );
          }
        })}

      <CircleMenuDividerCell title={i18n.general()} />

      <SettingCell
        onClick={() => {
          hopper.push(`circle/${circleId}/members`);
        }}
        startIcon={iconAllMembers}
        title={i18n.circle_all_members()}
      />

      <SettingCell
        startIcon={iconStats}
        title={i18n.clover_circle_stats()}
        onClick={() => {
          hopper.push(`circle/${circleId}/circle-stats`);
        }}
      />

      <SettingCell
        startIcon={iconShare}
        title={i18n.circle_perm_invite_and_share()}
        onClick={() => {
          hopper.modal("nyi");
        }}
      />

      <SettingCell
        startIcon={iconSettings}
        title={i18n.circle_settings()}
        showDivider={isAdmin}
        onClick={() => {
          hopper.push(`circle/${circleId}/setting`);
        }}
      />

      {isAdmin && (
        <>
          <SettingCell
            startIcon={iconSettings}
            showStartIconRedDot={circleHasAdminReminder.content}
            title={i18n.jun1_text_admin_portal()}
            onClick={() => {
              circleHasAdminReminder
                .fill(() => {
                  return false;
                })
                .catch(andLog);
              hopper.push(`circle/${circleId}/admin-portal`);
            }}
          />
        </>
      )}
      <LogoImage
        src={icLogo}
        onClick={() => hopper.present(`circle/${circleId}/detail-info`)}
      />
    </Page>
  );
}

const MidLine = styled.div`
  height: 39px;
  width: 1px;
  background: rgba(255, 255, 255, 0.1);
  margin: auto 2px;
`;

const AdminTitle = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
`;

const CircleCard = styled.div`
  width: 50px;
  height: 28px;
  background: red;
`;

const TitleContainer = styled.div`
  flex: 1;
  margin: 0 4px;
  gap: 4px;
  ${HStackMixin}
`;

const MemberTitleFrame = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FrameTitle = styled.div`
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
  font-weight: 400;
`;

const MemberLevel = styled.img`
  color: #ffffff;
  font-size: 10px;
  font-weight: 900;
`;

const CircleMembershipFrame = styled.div`
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  gap: 8px;
  padding: 6px 8px;
  display: flex;
  margin: 7px 10px;
  flex-direction: column;
`;

const CircleMembershipAdminFrame = styled.div`
  display: flex;
  flex-direction: row;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 6px 8px;
  margin: 7px 10px;
`;

const CircleHeaderName = styled.div`
  font-size: 18px;
  font-weight: 600;
  flex: 1;
  ${SingleLineUnspecifiedWidth};
  color: var(--color-text00);
`;

const LogoImage = styled.img`
  width: 31px;
  height: 31px;
  margin: 32px 0;
  align-self: center;
`;

const ImageContainer = styled.div`
  width: 38px;
  height: 38px;
  background: #00000033;
  border: 0.5px solid #ffffff33;
  border-radius: 5px;
  display: grid;
  align-items: center;
  justify-content: center;
`;

const CircleButton = styled.img`
  width: 24px;
  height: 24px;
`;

export default CircleSideMenuPage;
