import React, {
  CSSProperties,
  PropsWithChildren,
  ReactNode,
  useContext,
} from "react";
import { HStackMixin, Spring, VStack } from "./VStack";
import styled from "styled-components";
import { BottomSheet, BottomSheetProps } from "./Modal";
import itemCheck from "../res/images/menu_item_check.svg";
import {
  cell_on_pressed,
  full_page_width_cell,
  hPaddingWithPageInset,
} from "./CommonStyles";
import { ModalContext } from "./ModalContext";
import { SingleLineUnspecifiedWidth } from "./CommonViews";
import { Media } from "../proto/Media";
import { Image, MediaSizeMode } from "./Image";
import { AbsImage } from "./AbsImage";
import close from "../res/images/ic_modal_close.svg";
import { removeOptional } from "../utils/typeUtils";
import { useObscuredZoneForKey } from "../hooks/useObscuredZones";
import { usePageSpec } from "../pages/common/usePageSpec";

export const MenuItemTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  text-align: start;
  color: var(--color-text00);
  ${SingleLineUnspecifiedWidth}
`;

export const MenuItemSubtitle = styled.div`
  font-size: 12px;
  font-weight: 500;
  text-align: start;
  color: var(--color-text10);
  word-break: break-word;
`;

export const MenuItem = styled.div<{ disabled?: boolean }>`
  ${HStackMixin};
  padding: 24px 0;
  ${full_page_width_cell};
  gap: 12px;
  opacity: ${(p) => (p.disabled ? 0.2 : 1)};
  ${(p) => (p.disabled ? "" : cell_on_pressed)}
`;

export const BSMenuTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  padding-bottom: 18px;
  padding-top: 10px;

  color: var(--color-text00);
`;

type BSMenuItemCellProps = {
  disabled?: boolean;
  dontCloseOnClick?: boolean; // default to yes
  onClick?: () => void;
};

export function BSMenuItemCell(props: PropsWithChildren<BSMenuItemCellProps>) {
  const modalContext = useContext(ModalContext);

  return (
    <MenuItem
      disabled={props.disabled}
      onClick={() => {
        if (props.disabled) return;
        if (props.dontCloseOnClick) {
          if (props.onClick) {
            props.onClick();
          }
        } else {
          modalContext.modal.close(props.onClick);
        }
      }}
    >
      {props.children}
    </MenuItem>
  );
}

export function BSMenuItem(
  props: {
    icon?: readonly [Media | undefined, MediaSizeMode] | string | undefined;
    iconStyle?: Pick<
      CSSProperties,
      "width" | "height" | "borderRadius" | "objectFit"
    >;
    titleStyle?: Pick<CSSProperties, "fontSize" | "fontWeight" | "color">;
    trailingItem?: ReactNode;
    title: string | undefined;
    subtitle?: string;
    selected?: boolean;
  } & BSMenuItemCellProps,
) {
  return (
    <BSMenuItemCell {...props}>
      {props.icon && (
        <Image
          src={props.icon}
          width={24}
          height={24}
          style={{ objectFit: "contain", ...props.iconStyle }}
        />
      )}
      <VStack
        style={{ flexGrow: 1, flexShrink: 2, gap: 4, overflow: "hidden" }}
      >
        <MenuItemTitle style={props.titleStyle}>{props.title}</MenuItemTitle>
        {props.subtitle && (
          <MenuItemSubtitle>{props.subtitle}</MenuItemSubtitle>
        )}
      </VStack>
      <Spring />
      {props.trailingItem &&
        (typeof props.trailingItem === "string" ? (
          <MenuItemTitle>{props.trailingItem}</MenuItemTitle>
        ) : (
          props.trailingItem
        ))}
      {props.selected && <Image src={itemCheck}></Image>}
    </BSMenuItemCell>
  );
}

export const BSMenuScrollable = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: stretch;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;

  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
`;

export function BSMenu(
  props: PropsWithChildren<{ title?: string } & BottomSheetProps>,
) {
  const pageSpec = usePageSpec();
  const [obscuredZone] = useObscuredZoneForKey("Browser", "root");
  const showCloseButton = props.hideCloseButton === false;

  return (
    <BottomSheet modal={props.modal} onClickBg={props.onClickBg}>
      {!!props.title && <BSMenuTitle>{props.title}</BSMenuTitle>}
      {showCloseButton && (
        <AbsImage
          src={close}
          style={{ top: 12, right: 12 }}
          onClick={removeOptional(props.modal.close)}
        />
      )}
      <BSMenuScrollable
        style={{
          paddingBottom: pageSpec !== "wide" ? obscuredZone.bottom + 18 : 0,
          ...hPaddingWithPageInset,
          width: "100%",
          maxHeight: `calc(80vh - ${obscuredZone.top}px)`,
          gap: props.itemsGap,
        }}
      >
        {props.children}
      </BSMenuScrollable>
    </BottomSheet>
  );
}
