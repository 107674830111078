import { useBigIntParam } from "../../../hooks/useTypedParam";
import { useBackend } from "../../../service/APIService";
import { useI18n } from "../../../hooks/useI18n";
import { useHopper } from "../../../hooks/useHopper";
import { useSWR } from "../../../hooks/swr/useSWR";
import styled from "styled-components";
import { NFTHolderView } from "./NFTCircleCardDisplayPage";
import React, { useMemo } from "react";
import { Page } from "../../../components/Page";
import { HStack, VSpace, VStack } from "../../../components/VStack";
import { NFTDescriptionView } from "../components/NFTDescriptionView";
import { NFTCardRewardInfoView } from "../components/NFTCardRewardInfoView";
import { NFTCardMaturityView } from "../components/NFTCardMaturityView";
import { RegularButton } from "../../../components/Buttons";
import { CircleMembershipCardBenefitsView } from "../components/CircleMembershipCardBenefitsView";
import CircleCardsPreviewCell, {
  CardStyle,
} from "../../circle/card/CircleCardsPreviewCell";
import IcCard from "../../../res/images/ic_transaction_membership_card.png";
import { Image } from "../../../components/Image";
import { useMyUid } from "../../../service/AuthSessionService";
import { andLog, useErrorHandler } from "../../../components/handleError";
import { useNativePage } from "../../../hooks/useBridge";
import { NavEnd, NavItem } from "../../../components/NavBar";
import { NavButtonType } from "../../../bridge/NavButtonDesc";
import { useModal } from "../../../components/Modal";
import { BSMenu, BSMenuItem } from "../../../components/BSMenu";
import { useGlobalSpinner } from "../../../utils/globalSpinner";
import { copyTextToClipboard } from "../../../utils/CopyText";

const MainIcon = styled.div`
  width: 74px;
  height: 74px;

  display: flex;
  flex-direction: row;
  padding-inline-start: 2px;
  padding-inline-end: 2px;
  justify-content: center;
  align-items: center;
`;

export function NFTCircleCardInfoPage() {
  const nftId = useBigIntParam("cardId");
  const backend = useBackend();
  const i18n = useI18n();
  const hopper = useHopper();
  const globalSpinner = useGlobalSpinner();
  const handleError = useErrorHandler();

  const moreModal = useModal("nft-card-info-more");

  const nftInfo = useSWR(backend.getMembershipCardNFT(nftId));
  const circleInfo = useSWR(
    nftInfo.content?.circleMembershipCard?.circleId
      ? backend.getCircle(nftInfo.content.circleMembershipCard.circleId)
      : undefined,
  );

  const myUid = useMyUid();
  const isCircleAdmin = useMemo(() => {
    return circleInfo?.content?.adminIdList?.includes(myUid);
  }, [circleInfo?.content]);
  const nativePage = useNativePage();

  const isTemplate = useMemo(() => {
    return nftInfo.content?.circleMembershipCard?.isTemplate;
  }, [nftInfo]);

  const onClickMore = () => {
    moreModal.open();
  };

  const onClickCopyLink = async () => {
    try {
      await globalSpinner(async () => {
        const link = await backend
          .getShareLink(`nft-circle-card/${nftId}`)
          .run();
        if (link.shareLink) {
          await copyTextToClipboard(link.shareLink);
        }
      });
      await nativePage.successHud(i18n.copied());
    } catch (e) {
      handleError(e);
    }
  };

  return (
    <Page pageData={[nftInfo, circleInfo]}>
      {nftInfo.content !== undefined && (
        <>
          <NavEnd>{NavItem.image(NavButtonType.More, onClickMore)}</NavEnd>
          <HStack style={{ padding: "12px 0", gap: 12 }}>
            <MainIcon>
              <CircleCardsPreviewCell
                card={nftInfo.content.circleMembershipCard}
                cardStyle={CardStyle.small}
              />
            </MainIcon>
            <VStack
              style={{
                gap: 8,
                minHeight: 58,
                overflow: "hidden",
                flexShrink: 1,
                flexGrow: 1,
              }}
            >
              <HStack style={{ gap: 8 }}>
                <Image src={IcCard} style={{ width: 24, height: 17 }} />
                <div>{nftInfo.content.name}</div>
              </HStack>
              <NFTHolderView
                holder={circleInfo?.content}
                style={{ padding: "6px 0" }}
              />
            </VStack>
          </HStack>
          <NFTDescriptionView nft={nftInfo.content} />
          <NFTCardRewardInfoView nft={nftInfo.content} />
          {!isTemplate && (
            <>
              <NFTCardMaturityView
                nft={nftInfo.content}
                updateTime={nftInfo.meta.updatedAt}
              />
              <RegularButton
                onClick={() => {
                  if (nftInfo.content !== undefined) {
                    if (
                      nftInfo.content.circleMembershipCard?.membershipLevel ===
                        999 &&
                      isCircleAdmin
                    ) {
                      nativePage
                        .infoHud(i18n.clover_redeem_admin_tip())
                        .catch(andLog);
                    } else {
                      hopper.layer(
                        `nft-circle-card/${nftInfo.content.nftId}/redeem`,
                        {
                          circleId: nftInfo.content.nftId,
                        },
                      );
                    }
                  }
                }}
                style={{ marginTop: 32, margin: "32px 60px 16px" }}
              >
                {(nftInfo.content.circleMembershipCard?.maturityProgress ?? 0) <
                100
                  ? i18n.clover_redeem_early()
                  : i18n.web3_redeem_redeem()}
              </RegularButton>
              <div style={{ textAlign: "center", fontSize: 10, opacity: 0.6 }}>
                {i18n.clover_redeem_tip()}
              </div>
            </>
          )}
          <VSpace height={20}></VSpace>
          <CircleMembershipCardBenefitsView nft={nftInfo.content} />
        </>
      )}
      <BSMenu modal={moreModal}>
        <BSMenuItem title={i18n.share_copy_link()} onClick={onClickCopyLink} />
      </BSMenu>
    </Page>
  );
}
