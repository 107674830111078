import { Page } from "../../../components/Page";
import { useSWRArray } from "../../../hooks/swr/useSWRArray";
import { useBackend } from "../../../service/APIService";
import styled from "styled-components";
import { useI18n } from "../../../hooks/useI18n";
import { SolWay } from "../../../proto/SolRecommend";
import { VStack } from "../../../components/VStack";
import { Image } from "../../../components/Image";
import chevron from "../../../res/images/icon_section_header_show_more.svg";
import React from "react";
import { useWebHost } from "../../../hooks/useBridge";
import { andLog } from "../../../components/handleError";

export function SolServicesPage() {
  const backend = useBackend();
  const i18n = useI18n();
  const webHost = useWebHost();
  const recommendListSWR = useSWRArray(backend.getSolRecommendList());

  const onClickLink = (link: string) => {
    webHost.openInWebBrowser(link).catch(andLog);
  };
  return (
    <Page pageData={recommendListSWR}>
      <PageTitle>{i18n.clover_how_do_i_get_sol()}</PageTitle>
      <PageSubTitle>{i18n.clover_get_sol_from_the_services()}</PageSubTitle>
      {recommendListSWR.content.filter((ele) => ele.type === SolWay.Platform)
        .length > 0 && (
        <>
          <SectionTitle>{i18n.clover_buy_sol_directly()}</SectionTitle>
          <VStack style={{ gap: 12 }}>
            {recommendListSWR.content
              .filter((ele) => ele.type === SolWay.Platform)
              .map((way) => (
                <ServicesContainer onClick={() => onClickLink(way.url)}>
                  <Image src={[way.image, 32]} width={32} height={32} />
                  <ServicesName>{way.name}</ServicesName>
                  <Image
                    src={chevron}
                    width={20}
                    height={20}
                    style={{ marginInlineStart: 12 }}
                  />
                </ServicesContainer>
              ))}
          </VStack>
        </>
      )}
      {recommendListSWR.content.filter((ele) => ele.type === SolWay.Exchange)
        .length > 0 && (
        <>
          <SectionTitle>{i18n.others()}</SectionTitle>
          <VStack style={{ gap: 12 }}>
            {recommendListSWR.content
              .filter((ele) => ele.type === SolWay.Exchange)
              .map((way) => (
                <ServicesContainer onClick={() => onClickLink(way.url)}>
                  <Image src={[way.image, 32]} width={32} height={32} />
                  <ServicesName>{way.name}</ServicesName>
                  <Image
                    src={chevron}
                    width={20}
                    height={20}
                    style={{ marginInlineStart: 12 }}
                  />
                </ServicesContainer>
              ))}
          </VStack>
        </>
      )}
    </Page>
  );
}

const PageTitle = styled.div`
  color: #ffffff;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
`;

const PageSubTitle = styled.div`
  margin-top: 12px;
  color: rgba(255, 255, 255, 0.4);
  font-size: 14px;
  font-weight: 500;
  text-align: center;
`;

const SectionTitle = styled.div`
  font-weight: 700;
  color: #ffffff;
  font-size: 14px;
  margin: 32px 0 12px;
`;

const ServicesContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  min-height: 64px;
  padding: 0 12px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.06);
`;

const ServicesName = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: white;
  margin: auto 8px;
  flex: 1;
`;
