import { HStack, Spring, VSpace, VStack } from "../../../components/VStack";
import { StoreOrderItem } from "../../../proto/Store";
import { Image } from "../../../components/Image";
import {
  formatMoney,
  getCurrencyAmountFromString,
  getCurrencyIcon,
  getCurrencyIconByCurrencyType,
} from "../../../proto/Currency";
import {
  AssetOrder,
  AssetOrderStatus,
  MintServiceTemplate,
} from "../../../proto/AssetOrder";
import styled from "styled-components";
import { OverFlowTextStyle } from "../../../components/Text";
import { CellDivider, ContentGroup } from "../../../components/ContentGroup";
import { OrderInfoContent, OrderInfoLabel } from "./GiftOrderDetail";
import { useI18n } from "../../../hooks/useI18n";
import { HighLightLabel } from "./PurchaseOrderDetail";
import { StatusBar } from "./GiftOrderListPage";
import React, { useState } from "react";
import { formatDateWithOptions } from "../../../utils/DateTimeUtils";
import { RegularLargeButton } from "../../../components/Buttons";
import { useBackend } from "../../../service/APIService";
import { useNativePage } from "../../../hooks/useBridge";

export function MintOrderDetail(props: { assetOrder: AssetOrder }) {
  const i18n = useI18n();
  const backend = useBackend();
  const nativePage = useNativePage();

  const [status, setStatus] = useState(props.assetOrder.orderStatus);

  function getStatus() {
    switch (status) {
      case AssetOrderStatus.Done:
        return { text: i18n.web3_v0_completed(), color: "#00FF94" };
      case AssetOrderStatus.Pending:
      case AssetOrderStatus.ChainDone:
        return { text: i18n.web3_v0_pending(), color: "#FF7335" };
      case AssetOrderStatus.ToBeClaimed:
        return { text: i18n.web3_ugc_ready_to_claim(), color: "#FF7335" };
      case AssetOrderStatus.Failed:
        return { text: i18n.circle_anmt_failed(), color: "#EF3537FF" };
      case AssetOrderStatus.Rejected:
        return { text: i18n.circle_anmt_rejected(), color: "#EF3537FF" };
      case AssetOrderStatus.Canceled:
        return { text: i18n.circle_anmt_cancelled(), color: "#EF3537FF" };
      default:
        return { text: "", color: "FFFFFF66" };
    }
  }

  async function onClaimClick() {
    try {
      await backend.claimMintOrder(props.assetOrder.orderId).run();
      await nativePage.successHud(i18n.done());
      setStatus(AssetOrderStatus.Done);
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <VStack style={{ width: "100%", gap: 20 }}>
      <OrderNFTContainer mintServiceInfo={props.assetOrder.mintServiceInfo} />
      {props.assetOrder.mintServiceInfo?.optionService && (
        <ContentGroup style={{ width: "100%", gap: 20 }}>
          <HStack>
            <OrderInfoLabel>
              {i18n.zed_eco_chain_expedited_minting_speed()}
            </OrderInfoLabel>
            <Spring />
            <HStack style={{ gap: 4 }}>
              <Image
                src={getCurrencyIcon(
                  props.assetOrder.mintServiceInfo?.optionService?.price,
                )}
                style={{ width: 18, height: 18 }}
              />
              <OrderInfoContent>
                {formatMoney(
                  "long",
                  props.assetOrder.mintServiceInfo?.optionService?.price,
                )}
              </OrderInfoContent>
            </HStack>
          </HStack>
        </ContentGroup>
      )}
      <ContentGroup style={{ width: "100%", gap: 20 }}>
        <HStack>
          <OrderInfoLabel>{i18n.web3_mint_coupon_total_price()}</OrderInfoLabel>
          <Spring />
          <HStack style={{ gap: 4 }}>
            <Image
              src={getCurrencyIcon(props.assetOrder?.mintServiceInfo?.currency)}
              style={{ width: 18, height: 18 }}
            />
            <OrderInfoContent>
              {formatMoney("long", props.assetOrder?.mintServiceInfo?.currency)}
            </OrderInfoContent>
          </HStack>
        </HStack>
        <CellDivider />
        <HStack>
          <HighLightLabel>{i18n.web3_mint_coupon_total_paid()}</HighLightLabel>
          <Spring />
          <HStack style={{ gap: 4 }}>
            <Image
              src={getCurrencyIcon(props.assetOrder?.mintServiceInfo?.currency)}
              style={{ width: 18, height: 18 }}
            />
            <HighlightAmount>
              {formatMoney("long", props.assetOrder?.mintServiceInfo?.currency)}
            </HighlightAmount>
          </HStack>
        </HStack>
      </ContentGroup>

      <ContentGroup style={{ width: "100%", gap: 20 }}>
        <HStack>
          <OrderInfoLabel>{i18n.status()}</OrderInfoLabel>
          <Spring />
          <StatusBar
            statusColor={getStatus().color}
            statusText={getStatus().text}
          />
        </HStack>
        <CellDivider />
        <HStack>
          <OrderInfoLabel>{i18n.web3_v0_nft()}</OrderInfoLabel>
          <Spring />
          <HStack style={{ gap: 4 }}>
            <Image
              src={[
                props.assetOrder.mintServiceInfo?.nft?.entity?.preview,
                "best",
              ]}
              style={{ width: 18, height: 18, borderRadius: 4 }}
            />
            <VStack style={{ alignItems: "start" }}>
              <CollectibleName>
                {props.assetOrder.mintServiceInfo?.nft?.name}
              </CollectibleName>
              <CollectibleCount>
                ×{props.assetOrder.mintServiceInfo?.nftAmount}
              </CollectibleCount>
            </VStack>
          </HStack>
        </HStack>
        <CellDivider />
        <HStack>
          <OrderInfoLabel>{i18n.web3_v0_created_time()}</OrderInfoLabel>
          <Spring />
          <OrderInfoContent>
            {formatDateWithOptions(props.assetOrder.createdTime || 0, {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })}
          </OrderInfoContent>
        </HStack>
        {props.assetOrder.claimedTime && (
          <>
            <CellDivider />
            <HStack>
              <OrderInfoLabel>{i18n.web3_ugc_claimed_time()}</OrderInfoLabel>
              <Spring />
              <OrderInfoContent>
                {formatDateWithOptions(props.assetOrder.claimedTime || 0, {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </OrderInfoContent>
            </HStack>
          </>
        )}
        <CellDivider />
        <HStack>
          <OrderInfoLabel>{i18n.web3_v0_transaction_order_id()}</OrderInfoLabel>
          <Spring />
          <OrderInfoContent>
            {props.assetOrder.orderId.toString()}
          </OrderInfoContent>
        </HStack>
      </ContentGroup>
      {status === AssetOrderStatus.ToBeClaimed && (
        <RegularLargeButton
          style={{
            marginLeft: 62,
            marginRight: 62,
            fontSize: 16,
            minHeight: 48,
            marginTop: 12,
          }}
          onClick={onClaimClick}
        >
          {i18n.april_impr_check_minting_process()}
        </RegularLargeButton>
      )}
    </VStack>
  );
}

function OrderNFTContainer(props: { mintServiceInfo?: MintServiceTemplate }) {
  return (
    <HStack
      style={{
        width: "100%",
        padding: 8,
        backgroundColor: "#FFFFFF0F",
        borderRadius: 6,
        gap: 12,
      }}
    >
      <Image
        src={[
          props.mintServiceInfo?.nft?.entity?.preview,
          { width: 74, height: 74 },
        ]}
        width={74}
        height={74}
        style={{ borderRadius: 4 }}
      />
      <VStack style={{ flex: 1, gap: 8 }}>
        <Name>{props.mintServiceInfo?.nft?.name}</Name>
        <NFTAmount>×{props.mintServiceInfo?.nftAmount}</NFTAmount>
        <HStack style={{ width: "100%" }}>
          <Image
            src={getCurrencyIcon(props.mintServiceInfo?.price)}
            style={{ width: 18, height: 18 }}
          />
          <CurrencyAmountText>
            {formatMoney("long", props.mintServiceInfo?.price)}
          </CurrencyAmountText>
        </HStack>
      </VStack>
    </HStack>
  );
}

const Name = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #ffffff66;
  width: 100%;
`;

const NFTAmount = styled.div`
  font-size: 11px;
  font-weight: 400;
  color: #ffffff99;
`;

const CurrencyAmountText = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: white;
`;

const HighlightAmount = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: white;
`;

const CollectibleName = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: white;
`;

const CollectibleCount = styled.div`
  font-size: 11px;
  font-weight: 400;
  color: #ffffffcc;
`;
