import {
  useBigIntParam,
  useBooleanSearchParam,
} from "../../hooks/useTypedParam";
import { useNativePage } from "../../hooks/useBridge";
import { useI18n } from "../../hooks/useI18n";
import { useBackend } from "../../service/APIService";
import { CircleTokenHolder, CircleTokenQueryType } from "../../proto/CircleFin";
import React from "react";
import { andLog } from "../../components/handleError";
import { useChange } from "../../hooks/ExtHooks";
import { useSWRList } from "../../hooks/swr/useSWRList";
import styled from "styled-components";
import { HStack, HStackMixin, Spring, VStack } from "../../components/VStack";
import {
  accent_font,
  cell_bottom_divider,
  cell_on_pressed,
  full_page_width_cell,
  hPaddingWithPageInset,
} from "../../components/CommonStyles";
import { UserAvatarView } from "../../components/views/UserAvatarView";
import { formatMoney } from "../../proto/Currency";
import { UserNameView } from "../../components/views/UserNameView";
import MemberTitle from "../../components/MemberTitle";
import { Page } from "../../components/Page";
import { PageFooter, PageHeader } from "../../components/PageHeaderFooter";
import { HFilter } from "../../components/FilterTab";
import { SingleLineUnspecifiedWidth } from "../../components/CommonViews";
import { Image } from "../../components/Image";
import { useHopState } from "../../hooks/useHopState";
import { useObscuredZoneForKey } from "../../hooks/useObscuredZones";
import { NavMiddle } from "../../components/NavBar";
import { useHopper } from "../../hooks/useHopper";
import { useBroadcast } from "../../hooks/useBroadcast";

export function CircleTokenHoldersPage() {
  const [obscuringZone] = useObscuredZoneForKey("Browser");
  const circleId = useBigIntParam("circleId");
  const nativePage = useNativePage();
  const i18n = useI18n();
  const backend = useBackend();
  const isCirclePoint = useBooleanSearchParam("isCirclePoint", true);
  const [selectedTab, setSelectedTab] = useHopState<CircleTokenQueryType>(
    "selectedTab",
    CircleTokenQueryType.All,
  );

  useChange(selectedTab, (prev, current) => {
    if (prev !== current) {
      tokenHoldersSWR.load().catch(andLog);
    }
  });

  const tokenHoldersSWR = useSWRList(
    ["circle-token-holders", circleId, selectedTab],
    (pageParam) =>
      backend.getCircleTokenHolders(circleId, selectedTab, pageParam).run(),
    { maxSize: 100 },
  );
  useBroadcast(
    "ON_REMOVE_FROM_CIRCLE",
    () => {
      tokenHoldersSWR.load().catch(andLog);
    },
    [tokenHoldersSWR],
  );

  const hopper = useHopper();

  return (
    <Page pageData={tokenHoldersSWR}>
      <>
        {!isCirclePoint && (
          <>
            <NavMiddle>{i18n.circle_fin_token_holders()}</NavMiddle>
            <PageHeader>
              <HFilter<CircleTokenQueryType>
                style={{
                  marginTop: 10,
                  marginBottom: 8,
                  ...hPaddingWithPageInset,
                }}
                selected={selectedTab}
                candidates={[
                  {
                    value: CircleTokenQueryType.All,
                    title: i18n.circle_fin_all_holders(),
                  },
                  {
                    value: CircleTokenQueryType.CircleMember,
                    title: i18n.circle_all_members(),
                  },
                ]}
                onSelect={setSelectedTab}
              />
            </PageHeader>
          </>
        )}
      </>

      {tokenHoldersSWR.content &&
        tokenHoldersSWR.content.list.map((circleTokenHolder, index) => (
          <RankCell
            key={
              circleTokenHolder.circleMemberInfo?.uid ??
              circleTokenHolder.externalUser?.address
            }
            index={index}
            circleId={circleId}
            circleTokenHolder={circleTokenHolder}
          />
        ))}
      {tokenHoldersSWR.content?.currentUserHolder?.circleMemberInfo && (
        <PageFooter
          obscuredZoneKey={"TokenHoldersBottom"}
          safeBottomDisabled={true}
        >
          <BottomFrame
            onClick={() => {
              if (
                tokenHoldersSWR.content?.currentUserHolder?.circleMemberInfo
                  ?.uid
              ) {
                hopper.modal(
                  `circle/${circleId}/user-card/${
                    tokenHoldersSWR.content?.currentUserHolder?.circleMemberInfo
                      ?.uid
                  }`,
                );
              }
            }}
            style={{
              gap: 5,
              paddingBottom: obscuringZone.bottom + 12,
              paddingTop: 12,
            }}
          >
            <VStack
              style={{
                marginRight: 6,
                marginTop: 6,
                gap: 4,
                flexShrink: 0,
              }}
            >
              <YourRank style={{ marginLeft: 6 }}>
                {i18n.circle_fin_your_rank()}
              </YourRank>
              <Rank
                style={{ marginLeft: 6 }}
              >{`${tokenHoldersSWR.content.currentUserHolder.rank && tokenHoldersSWR.content.currentUserHolder.rank > 200 ? "-" : `#${tokenHoldersSWR.content.currentUserHolder.rank}`}`}</Rank>
            </VStack>
            <UserAvatarView
              user={tokenHoldersSWR.content.currentUserHolder.circleMemberInfo}
              iconSize={48}
              stopPropagation={true}
              onClick={() => {
                if (
                  tokenHoldersSWR.content.currentUserHolder?.circleMemberInfo
                ) {
                  hopper.modal(
                    `circle/${circleId}/user-card/${tokenHoldersSWR.content.currentUserHolder?.circleMemberInfo.uid}`,
                  );
                }
              }}
            />
            <VStack
              style={{
                alignItems: "start",
                marginLeft: 8,
                gap: 4,
                marginTop: 5,
                marginRight: 4,
                flexShrink: 1,
              }}
            >
              <UserNameView
                user={
                  tokenHoldersSWR.content.currentUserHolder.circleMemberInfo
                }
              />
              <SocialId>{`@${tokenHoldersSWR.content.currentUserHolder.circleMemberInfo.socialId}`}</SocialId>
              {tokenHoldersSWR.content.currentUserHolder.circleMemberInfo
                .circleMemberTitleList && (
                <MemberTitle
                  memberTitle={
                    tokenHoldersSWR.content.currentUserHolder.circleMemberInfo
                      .circleMemberTitleList[0]
                  }
                />
              )}
            </VStack>
            <Spring />
            {tokenHoldersSWR.content.currentUserHolder.currency.icon && (
              <Image
                width={20}
                height={20}
                style={{ borderRadius: "4px" }}
                src={[
                  tokenHoldersSWR.content.currentUserHolder.currency.icon,
                  20,
                ]}
              />
            )}
            <CurrencyAmount style={{ marginLeft: 4, marginRight: 20 }}>
              {formatMoney(
                "short",
                tokenHoldersSWR.content.currentUserHolder.currency,
              )}
            </CurrencyAmount>
          </BottomFrame>
        </PageFooter>
      )}
    </Page>
  );
}

function RankCell(props: {
  circleTokenHolder: CircleTokenHolder;
  circleId: bigint;
  index: number;
}) {
  const user = props.circleTokenHolder.circleMemberInfo;
  const externalUser = props.circleTokenHolder.externalUser;
  const nativePage = useNativePage();
  const hopper = useHopper();
  return (
    <HStack
      onClick={() => {
        if (user) {
          hopper.modal(`circle/${props.circleId}/user-card/${user.uid}`);
        }
      }}
      mixin={[cell_bottom_divider, cell_on_pressed, full_page_width_cell]}
    >
      <Rank
        style={{ marginTop: 32, marginBottom: 32 }}
      >{`#${props.index + 1}`}</Rank>
      {user && (
        <UserAvatarView
          user={user}
          iconSize={48}
          stopPropagation={true}
          onClick={() => {
            if (user) {
              hopper.modal(`circle/${props.circleId}/user-card/${user.uid}`);
            }
          }}
        />
      )}
      {externalUser && (
        <Image
          width={48}
          height={48}
          src={[externalUser.icon, 48]}
          alt={"reveal"}
        />
      )}
      {user && (
        <VStack
          style={{
            alignItems: "start",
            marginLeft: 8,
            marginRight: 16,
            flexShrink: 1,
          }}
        >
          <UserNameView user={user} />
          {user.socialId && <SocialId>{`@${user.socialId}`}</SocialId>}
          {user.circleMemberTitleList && (
            <MemberTitle memberTitle={user.circleMemberTitleList[0]} />
          )}
        </VStack>
      )}
      {externalUser && (
        <ExternalAddress style={{ marginLeft: 8 }}>
          {externalUser.address}
        </ExternalAddress>
      )}

      <Spring />
      {props.circleTokenHolder.currency.icon && (
        <Image
          width={20}
          height={20}
          needBg={true}
          style={{ borderRadius: "4px", flexShrink: 0 }}
          src={[props.circleTokenHolder.currency.icon, 20]}
        />
      )}
      <CurrencyAmount style={{ marginLeft: 4 }}>
        {formatMoney("short", props.circleTokenHolder.currency)}
      </CurrencyAmount>
    </HStack>
  );
}

function Tab(props: { selected: boolean; title: string; onClick: () => void }) {
  if (props.selected) {
    return (
      <SelectedFrame onClick={props.onClick}>
        <SelectedTab>{props.title}</SelectedTab>
      </SelectedFrame>
    );
  } else {
    return (
      <UnselectedFrame onClick={props.onClick}>
        <UnselectedTab>{props.title}</UnselectedTab>
      </UnselectedFrame>
    );
  }
}

const ExternalAddress = styled.div`
  color: var(--color-text00);
  font-weight: 400;
  font-size: 16px;
  ${SingleLineUnspecifiedWidth}
`;

const Rank = styled.div`
  color: #fff;
  margin-left: var(--page-h-inset-neg);
  width: 60px;
  text-align: center;
  flex-shrink: 0;
  ${accent_font(16)};
`;

const SocialId = styled.div`
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  opacity: 0.8;
  ${SingleLineUnspecifiedWidth}
`;

const CurrencyAmount = styled.div`
  color: #fafafa;
  text-align: right;
  font-size: 14px;
  font-weight: 700;
  flex-shrink: 0;
`;

const BottomFrame = styled.div`
  ${HStackMixin};
  border-radius: 12px 12px 0 0;
  background: rgb(51, 51, 51);
`;
const YourRank = styled.div`
  color: #fff;
  font-size: 12px;
  font-weight: 300;
  opacity: 0.4;
  flex-shrink: 0;
`;

const SelectedTab = styled.div`
  color: #94ffde;
  margin: 5px 16px 5px 16px;
  text-shadow: 0 0 8px rgba(0, 168, 140, 0.8);
  font-size: 12px;
  font-weight: 700;
`;
const SelectedFrame = styled.div`
  border-radius: 4px;
  border: 0.5px solid #0dffcc;
  background: rgba(0, 175, 163, 0.14);
`;

const UnselectedFrame = styled.div`
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.08);
`;

const UnselectedTab = styled.div`
  color: #fff;
  margin: 5px 16px 5px 16px;
  font-size: 12px;
  font-weight: 500;
  opacity: 0.6;
`;
