import styled, { css } from "styled-components";
import { I18nReactElement } from "../hooks/useI18n";
import { CSSProperties } from "react";
import { PowerMarkdown } from "./PowerMarkdown";

export const InstructionFrame = styled.div`
  border-radius: 12px;
  border: 0.5px solid rgba(255, 255, 255, 0.2);
  padding: 16px;
`;

export const InstructionText = css`
  color: var(--color-text00);
  font-size: 12px;
  font-weight: 300;
  line-height: 150%; /* 21.6px */
  letter-spacing: -0.2px;
  opacity: 0.6;
`;

export function Instruction(props: {
  children: I18nReactElement | string;
  frameStyle?: Pick<CSSProperties, "margin" | "marginTop" | "marginBottom">;
}) {
  return (
    <InstructionFrame style={props.frameStyle}>
      <PowerMarkdown textStyle={InstructionText}>
        {props.children}
      </PowerMarkdown>
    </InstructionFrame>
  );
}
