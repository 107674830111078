import { z } from "zod";
import { User } from "./User";
import { zStatic } from "../utils/zodUtils";
import { CircleMemberTitle } from "./CircleMemberTitle";

export const CircleMemberGroupPreview = z.object({
  membershipLevel: z.number(),
  memberCount: z.number(),
  memberList: User.array(),
  memberTitle: CircleMemberTitle.optional(),
});
export type CircleMemberGroupPreview = zStatic<typeof CircleMemberGroupPreview>;
