import { z } from "zod";
import { zStatic } from "../utils/zodUtils";
import { ZodType } from "zod/lib/types";

export const Pagination = z.object({
  nextPageToken: z.string().optional(),
  total: z.number().optional(),
});
export type Pagination = zStatic<typeof Pagination>;

export function PagedList<E extends ZodType<any, any, any>>(elementSchema: E) {
  return z.object({
    list: z.array(elementSchema).default([]),
    pagination: Pagination.default({}),
  });
}

export type PagedList<T> = {
  list: T[];
  pagination: Pagination;
};

export type PagedElement<P> = P extends PagedList<infer E> ? E : never;

export const PageParam = z.object({
  pageToken: z.string().nullable(),
  size: z.number(),
});

export type PageParam = zStatic<typeof PageParam>;

export function OffsetPagedList<E extends ZodType<any, any, any>>(
  elementSchema: E,
) {
  return z.object({
    list: z.array(elementSchema),
    isEnd: z.boolean().default(false),
  });
}

export type OffsetPagedList<T> = {
  list: T[];
  isEnd: boolean;
};
