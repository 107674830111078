import { z } from "zod";
import { zBigInt, zEnum, zStatic } from "../utils/zodUtils";
import { Bubble } from "./Bubble";
import { FollowStatus } from "./UserEnums";

export enum ChatMemberStatus {
  Active = 1,
  InviteSend = 2,
  Leave = 3,
  AccountDeleted = 4,
  BlockedByHost = 5,
  NotJoinedChat = 6,
  RemovedByManager = 7,
  MatchSuccess = 8,
  MatchJoined = 9,
  MatchAgree = 10,
  MatchLeave = 11,
}

export enum ChatAlert {
  On = 1,
  Off = 2,
}

export const ChatMemberExtension = z.object({
  bubbleColor: z.string().optional(),
  lastReadAnnouncementId: zBigInt.optional(),
  lastReadProminentAnnouncementTime: z.number().optional(),
  pinnedTime: z.number().optional(),
});

export const ChatMember = z.object({
  isPinned: z.boolean().optional(),
  alertOption: zEnum(ChatAlert).optional(),
  lastReadMessageId: zBigInt.optional(),
  isSenderAllowed: z.boolean().optional(),
  chatMemberStatusV2: zEnum(ChatMemberStatus).optional(),
  chatBubble: Bubble.optional(),
  adminFollowStatus: zEnum(FollowStatus).optional(),
  extensions: ChatMemberExtension.optional(),
});

export type ChatMember = zStatic<typeof ChatMember>;
