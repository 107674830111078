import { Page } from "../../../components/Page";
import { useBigIntParam } from "../../../hooks/useTypedParam";
import { useBackend } from "../../../service/APIService";
import { useI18n } from "../../../hooks/useI18n";
import { useErrorHandler } from "../../../components/handleError";
import { useModal } from "../../../components/Modal";
import { useSWR } from "../../../hooks/swr/useSWR";
import React, { useMemo } from "react";
import { useNativePage } from "../../../hooks/useBridge";
import { NavEnd, NavItem } from "../../../components/NavBar";
import { NavButtonType } from "../../../bridge/NavButtonDesc";
import { HStack, Spring, VStack } from "../../../components/VStack";
import { Image } from "../../../components/Image";
import IcProto from "../imgs/ic_nft_proto.png";
import IcLegacy from "../imgs/ic_nft_legacy.png";
import { NFTHolderView } from "./NFTCircleCardDisplayPage";
import { BSMenu, BSMenuItem } from "../../../components/BSMenu";
import { useGlobalSpinner } from "../../../utils/globalSpinner";
import { NFTStatus } from "../../../proto/NFT";
import { SpeedTitle } from "../../../components/SpeedTitle";
import { NFTDescriptionView } from "../components/NFTDescriptionView";
import { formatDateWithOptions } from "../../../utils/DateTimeUtils";
import styled, { css } from "styled-components";
import { copyTextToClipboard } from "../../../utils/CopyText";

const InfoMixin = css`
  padding: 15px 0;
`;

const InfoTitle = styled.div`
  color: white;
  opacity: 0.8;
  font-weight: 400;
`;

const InfoDetail = styled.div`
  color: white;
  font-weight: 700;
`;

export function NFTInfoPage() {
  const nftId = useBigIntParam("nftId");
  const backend = useBackend();
  const i18n = useI18n();
  const nativePage = useNativePage();
  const globalSpinner = useGlobalSpinner();
  const handleError = useErrorHandler();

  const moreModal = useModal("nft-info-more");
  const nftInfo = useSWR(backend.getNFT(nftId));

  const isMinted = useMemo(() => {
    return nftInfo.content?.nftStatus === NFTStatus.Minted;
  }, [nftInfo.content]);

  const onClickMore = () => {
    moreModal.open();
  };

  const onClickCopyLink = async () => {
    try {
      await globalSpinner(async () => {
        const link = await backend.getShareLink(`nft/${nftId}`).run();
        if (link.shareLink) {
          await copyTextToClipboard(link.shareLink);
        }
      });
      await nativePage.successHud(i18n.copied());
    } catch (e) {
      handleError(e);
    }
  };

  return (
    <Page pageData={[nftInfo]}>
      {nftInfo.content !== undefined && (
        <>
          <NavEnd>{NavItem.image(NavButtonType.More, onClickMore)}</NavEnd>
          <HStack style={{ padding: "12px 0", gap: 12 }}>
            <Image
              src={[nftInfo.content.entity?.preview, 72]}
              style={{ width: 72, height: 72, borderRadius: 10 }}
            />
            <VStack
              style={{
                gap: 8,
                minHeight: 58,
                overflow: "hidden",
                flexShrink: 1,
                flexGrow: 1,
              }}
            >
              <HStack style={{ gap: 8 }}>
                <Image
                  src={
                    nftInfo.content.nftStatus === NFTStatus.Minting
                      ? IcLegacy
                      : IcProto
                  }
                  style={{ width: 24, height: 17 }}
                />
                <div>{nftInfo.content.name}</div>
              </HStack>
              <NFTHolderView
                holder={nftInfo.content.author}
                style={{ padding: "6px 0" }}
              />
            </VStack>
          </HStack>
          <NFTDescriptionView nft={nftInfo.content} />
          <SpeedTitle style={{ marginTop: 15 }}>
            {i18n.web3_v0_details()}
          </SpeedTitle>
          {!!nftInfo.content.version && (
            <HStack mixin={InfoMixin}>
              <InfoTitle>{i18n.col_edition_pub_edition()}</InfoTitle>
              <Spring />
              <InfoDetail>
                {i18n.col_edition_edition(nftInfo.content.version)}
              </InfoDetail>
            </HStack>
          )}
          {!!nftInfo.content.latestVersion && (
            <HStack mixin={InfoMixin}>
              <InfoTitle>{i18n.col_edition_latest_edition()}</InfoTitle>
              <Spring />
              <InfoDetail>
                {i18n.col_edition_edition(nftInfo.content.latestVersion)}
              </InfoDetail>
            </HStack>
          )}
          {/*<HStack>*/}
          {/*  <>{i18n.web3_nftinfo_minted_time()}</>*/}
          {/*  <Spring />*/}
          {/*  <></>*/}
          {/*</HStack>*/}
          {!isMinted && (
            <HStack mixin={InfoMixin}>
              <InfoTitle>{i18n.web3_ugc_nft_stage()}</InfoTitle>
              <Spring />
              <InfoDetail>{i18n.web3_ugc_proto()}</InfoDetail>
            </HStack>
          )}
          {/*<HStack>*/}
          {/*  <>{i18n.web3_v0_total_quantity()}</>*/}
          {/*  <Spring />*/}
          {/*  <></>*/}
          {/*</HStack>*/}
          {/*{isMinted && (*/}
          {/*  <HStack>*/}
          {/*    <>{i18n.web3_v0_contract()}</>*/}
          {/*    <Spring />*/}
          {/*    <></>*/}
          {/*  </HStack>*/}
          {/*)}*/}
          {/*<HStack>*/}
          {/*  <>{i18n.web3_v0_token_id()}</>*/}
          {/*  <Spring />*/}
          {/*  <></>*/}
          {/*</HStack>*/}
          {/*<HStack>*/}
          {/*  <>{i18n.web3_v0_token_standard()}</>*/}
          {/*  <Spring />*/}
          {/*  <></>*/}
          {/*</HStack>*/}
          {/*<HStack>*/}
          {/*  <>{i18n.web3_v0_blockchain()}</>*/}
          {/*  <Spring />*/}
          {/*  <></>*/}
          {/*</HStack>*/}
          {/*<HStack>*/}
          {/*  <>{i18n.web3_royalty_creator_fee()}</>*/}
          {/*  <Spring />*/}
          {/*  <></>*/}
          {/*</HStack>*/}
          <HStack>
            <InfoTitle>{i18n.web3_nftinfo_created_time()}</InfoTitle>
            <Spring />
            <InfoDetail>
              {formatDateWithOptions(nftInfo.content.createdTime || 0, {
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })}
            </InfoDetail>
          </HStack>
        </>
      )}
      <BSMenu modal={moreModal}>
        <BSMenuItem title={i18n.share_copy_link()} onClick={onClickCopyLink} />
      </BSMenu>
    </Page>
  );
}
