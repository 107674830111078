import { CellDivider, ContentGroup } from "../../../components/ContentGroup";
import { HStack, VSpace, VStack } from "../../../components/VStack";
import { Image } from "../../../components/Image";
import {
  Currency,
  CurrencyId,
  CurrencyType,
  formatMoney,
  getCurrencyIcon,
  getCurrencyIconByCurrencyType,
  getCurrencyName,
  getCurrencyNameByCurrencyType,
} from "../../../proto/Currency";
import { CheckBox } from "../../user/UserProfilePage";
import iconGEMS from "../../../res/images/icon_gems.svg";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useI18n } from "../../../hooks/useI18n";
import iconSOL from "../../../res/images/icon_sol.svg";
import { LocalRepoResult } from "../../../hooks/swr/useLocalRepo";
import { TransactionBody } from "./TransactionBody";
import { useSWR } from "../../../hooks/swr/useSWR";
import { useBackend } from "../../../service/APIService";
import { AccountType } from "../../../proto/AccountType";
import { ChainType, getRuntimeChainType } from "../../../proto/ChainType";
import { GasFeeTxType } from "../../../proto/Wallet";
import { andLog } from "../../../components/handleError";
import { useTransactionBody } from "../send/SendTokenPage";
import { useRegularAccount } from "../useRegularAccount";
import { ZeroGems, ZeroSOL } from "../swap/SwapPage";
import BigNumber from "bignumber.js";
import { useNativePage } from "../../../hooks/useBridge";
import { copyObject } from "../../../utils/copyObject";
import PaymentQuantity from "./PaymentQuantity";
import { useMergedCurrency } from "../swap/useMergedCurrency";
import { JSONUtil } from "../../../utils/JSONUtil";
import { jsonifyBigNumber } from "../../../utils/CurrencyAmount";
import { CreateTokenPresets } from "./CreateTokenPresets";
import { isValidCircleToken } from "../../../proto/CircleFin";

export interface PaymentServiceFeeProps {
  editingBody: ReturnType<typeof useTransactionBody>;
  gasFeeTxType: GasFeeTxType;
  unitPriceCurrency?: Currency;
  payWithSol?: Currency;
  totalPriceLabel?: string;
  payWithLabel?: string;
  maxCount?: number;
  showTotalAmount?: boolean;
  showQuantity?: boolean;
  isFree?: boolean;
  presets?: CreateTokenPresets;
  onCountChanged?: (count: number) => Promise<void>;
}

export const DEFAULT_PRIORITY_FEE = "1000000";
export const DEFAULT_SLIPPAGE = "10000000";

function PaymentServiceFee(props: PaymentServiceFeeProps) {
  const i18n = useI18n();
  const editingBody = props.editingBody;
  const isMergedOrder = Boolean(props.presets);
  const backend = useBackend();

  const fullConsumedSolCurrency = useMemo(() => {
    const preset = props.presets;
    if (preset) {
      const solAmount = BigNumber(preset.deployFeeSol.amount).plus(
        BigNumber(preset.purchaseFeeSol.amount),
      );
      return copyObject<Currency>(preset.deployFeeSol, {
        amount: String(solAmount),
      });
    }
  }, [props.presets]);

  const fullConsumedGemsCurrency = useMemo(() => {
    const preset = props.presets;
    if (preset) {
      const gemsAmount = BigNumber(preset.deployFeeGems.amount).plus(
        BigNumber(preset.purchaseFeeGems.amount),
      );
      return copyObject<Currency>(preset.deployFeeGems, {
        amount: String(gemsAmount),
      });
    }
  }, [props.presets]);

  const walletSWR = useSWR(backend.getWallet());
  const regularAccount = useRegularAccount();
  const nativePage = useNativePage();

  const walletAccountId = regularAccount?.walletAccountId;

  const currencyListSWR = useSWR(
    walletAccountId ? backend.getCurrencyList(walletAccountId) : undefined,
  );

  const gasFeeSWR = useSWR(
    backend.getGasFee(
      "10000000",
      editingBody.content.toChainAddress ?? "",
      getRuntimeChainType(),
      CurrencyType.SOL,
      BigInt(5),
      props.gasFeeTxType,
    ),
  );
  const gemsSWR = useSWR(backend.getLivePricingData());
  const mergedCurrency = useMergedCurrency(
    editingBody,
    props.showTotalAmount || Boolean(props.payWithSol),
  );

  const payWithGems = useMemo(() => {
    if (props.unitPriceCurrency?.currencyType === CurrencyType.SOL) {
      const mergedAmount = mergedCurrency?.amount;
      const removeSOLGas = BigNumber(mergedAmount ?? "0").minus(
        BigNumber(gasFeeSWR.content?.gasFee?.amount ?? "0"),
      );
      const gemsAmountNoGas = BigNumber(removeSOLGas ?? "0").multipliedBy(
        BigNumber(gemsSWR.content?.gemsAmount ?? "0"),
      );
      const gemsAmountNoGasWithZero = jsonifyBigNumber(gemsAmountNoGas, 9);
      const finalAmount = BigNumber(gemsAmountNoGasWithZero).plus(
        BigNumber(gasFeeSWR.content?.cashPointGasFee?.amount ?? "0"),
      );
      return copyObject<Currency>(
        gasFeeSWR.content?.cashPointGasFee ?? ZeroGems,
        { amount: finalAmount.toFixed(0) },
      );
    }
  }, [gemsSWR.content, mergedCurrency]);

  const recalculateGemsCount = async () => {
    if (editingBody.content.payWithGems) {
      await editingBody.fill((prevData) => ({
        ...prevData,
        totalPrice: payWithGems?.amount,
        payWithGems: payWithGems,
      }));
    }
  };

  useEffect(() => {
    console.log(`fsaasdsa2 ${editingBody.content.cardCount}`);
    recalculateGemsCount().catch(andLog);
  }, [editingBody.content.cardCount]);

  const onClickTogglePayment = async () => {
    if (
      props.payWithSol &&
      props.unitPriceCurrency &&
      editingBody.content.payWithSOL
    ) {
      await editingBody.fill((prevData) => ({
        ...prevData,
        payWithSOL: undefined,
        currency: props.unitPriceCurrency,
        currencyType: CurrencyType.SOL_TOKENS,
        currencyId: props.unitPriceCurrency?.currencyId,
        amount: props.unitPriceCurrency?.amount ?? "0",
        totalPrice: props.unitPriceCurrency?.amount,
      }));
    } else {
      await editingBody.fill((prevData) => ({
        ...prevData,
        payWithSOL: props.payWithSol,
        currency: props.payWithSol,
        currencyType: CurrencyType.SOL,
        currencyId: props.payWithSol?.currencyId,
        amount: props.payWithSol?.amount ?? "0",
        totalPrice: props.payWithSol?.amount,
      }));
    }
  };

  const onClickServiceFeeCurrency = async () => {
    if (props.unitPriceCurrency?.currencyType === CurrencyType.SOL) {
      console.log(`sadasds2 aaa ${payWithGems?.amount}`);
      console.log(`sadasds2 bbb ${mergedCurrency?.amount}`);
      if (editingBody.content.currencyType === CurrencyType.SOL) {
        await editingBody.fill((prevData) => ({
          ...prevData,
          totalPrice: payWithGems?.amount,
          currencyType: CurrencyType.GEMS,
          paymentCurrencyType: CurrencyType.GEMS,
          currencyId: BigInt(CurrencyId.GEMS.toString()),
          payWithGems: payWithGems,
          gemsGasFeeCurrencyType:
            gasFeeSWR.content?.cashPointGasFee?.currencyType,
          gemsGasFeeAmount: gasFeeSWR.content?.cashPointGasFee?.amount,
          gemsGasFeeCurrencyId: gasFeeSWR.content?.cashPointGasFee?.currencyId,
          gemsGasFeeCurrency: gasFeeSWR.content?.cashPointGasFee,
        }));
      } else {
        await editingBody.fill((prevData) => ({
          ...prevData,
          totalPrice: mergedCurrency?.amount,
          currencyType: CurrencyType.SOL,
          paymentCurrencyType: CurrencyType.SOL,
          currencyId: BigInt(CurrencyId.SOL.toString()),
          payWithGems: undefined,
        }));
      }
    } else if (isMergedOrder) {
      const sol = fullConsumedSolCurrency;
      const gems = fullConsumedGemsCurrency;
      if (sol && gems) {
        if (props.editingBody.content.currencyType === CurrencyType.GEMS) {
          // if (
          //   BigNumber(sol?.amount ?? "0").isGreaterThan(
          //     BigNumber(solCurrency?.amount),
          //   )
          // ) {
          //   nativePage
          //     .infoHud(i18n.clover_dont_have_enough_balance())
          //     .catch(andLog);
          //   return;
          // }
          await editingBody.fill((prevData) => ({
            ...prevData,
            amount: sol.amount,
            decimals: sol.decimals,
            currencyId: sol.currencyId,
            currencyType: sol.currencyType,
            paymentCurrencyType: sol.currencyType,
            currency: sol,
          }));
        } else {
          if (
            BigNumber(gems?.amount ?? "0").isGreaterThan(
              BigNumber(gemsCurrency?.amount),
            )
          ) {
            nativePage
              .infoHud(i18n.clover_dont_have_enough_balance())
              .catch(andLog);
            return;
          }
          await editingBody.fill((prevData) => ({
            ...prevData,
            amount: gems.amount,
            decimals: gems.decimals,
            currencyId: gems.currencyId,
            currencyType: gems.currencyType,
            paymentCurrencyType: gems.currencyType,
            currency: gems,
          }));
        }
      }
    } else {
      if (editingBody.content.gasFeeCurrencyType === CurrencyType.GEMS) {
        // if (
        //   BigNumber(gasFeeSWR.content?.gasFee?.amount ?? "0").isGreaterThan(
        //     BigNumber(solCurrency?.amount),
        //   )
        // ) {
        //   nativePage
        //     .infoHud(i18n.clover_dont_have_enough_balance())
        //     .catch(andLog);
        //   return;
        // }
        await editingBody.fill((prevData) => ({
          ...prevData,
          gasFeeCurrencyType: gasFeeSWR.content?.gasFee?.currencyType,
          gasFeeAmount: gasFeeSWR.content?.gasFee?.amount,
          gasFeeCurrencyId: gasFeeSWR.content?.gasFee?.currencyId,
          gasFeeCurrency: gasFeeSWR.content?.gasFee,
        }));
      } else {
        if (
          BigNumber(
            gasFeeSWR.content?.cashPointGasFee?.amount ?? "0",
          ).isGreaterThan(BigNumber(gemsCurrency?.amount))
        ) {
          nativePage
            .infoHud(i18n.clover_dont_have_enough_balance())
            .catch(andLog);
          return;
        }
        await editingBody.fill((prevData) => ({
          ...prevData,
          gasFeeCurrencyType: gasFeeSWR.content?.cashPointGasFee?.currencyType,
          gasFeeAmount: gasFeeSWR.content?.cashPointGasFee?.amount,
          gasFeeCurrencyId: gasFeeSWR.content?.cashPointGasFee?.currencyId,
          gasFeeCurrency: gasFeeSWR.content?.cashPointGasFee,
        }));
      }
    }
  };

  const [selectedGasFeeCurrency, setGasFeeCurrency] = useState<Currency>();
  const isOnlyPayWithGems =
    props.unitPriceCurrency?.currencyType === CurrencyType.GEMS;
  useEffect(() => {
    if (isMergedOrder) {
    } else {
      if (
        isOnlyPayWithGems &&
        gasFeeSWR.content &&
        editingBody.content &&
        editingBody.content.gasFeeCurrencyType !== CurrencyType.GEMS
      ) {
        console.log(`sadadsds aaaa`);
        editingBody
          .fill((prevData) => ({
            ...prevData,
            gasFeeCurrencyType:
              gasFeeSWR.content?.cashPointGasFee?.currencyType,
            gasFeeAmount: gasFeeSWR.content?.cashPointGasFee?.amount,
            gasFeeCurrencyId: gasFeeSWR.content?.cashPointGasFee?.currencyId,
            gasFeeCurrency: gasFeeSWR.content?.cashPointGasFee,
          }))
          .catch(andLog);
      } else {
        if (
          gasFeeSWR.content &&
          editingBody.content &&
          editingBody.content.gasFeeCurrency === undefined
        ) {
          editingBody
            .fill((prevData) => ({
              ...prevData,
              gasFeeCurrencyType: gasFeeSWR.content?.gasFee?.currencyType,
              gasFeeAmount: gasFeeSWR.content?.gasFee?.amount,
              gasFeeCurrencyId: gasFeeSWR.content?.gasFee?.currencyId,
              gasFeeCurrency: gasFeeSWR.content?.gasFee,
            }))
            .catch(andLog);
        }
      }
    }
  }, [gasFeeSWR.content, editingBody.content]);

  useEffect(() => {
    if (selectedGasFeeCurrency === undefined) {
      const ret = currencyListSWR?.content?.list?.find(
        (item) => item.currency.currencyId === BigInt(CurrencyId.SOL),
      )?.currency;
      if (ret) {
        setGasFeeCurrency(ret);
      }
    }
  }, [currencyListSWR?.content, selectedGasFeeCurrency]);

  const gemsCurrency = useMemo(() => {
    return (
      currencyListSWR?.content?.list?.find(
        (item) => item.currency.currencyType === CurrencyType.GEMS,
      )?.currency ?? ZeroGems
    );
  }, [currencyListSWR?.content]);

  const solCurrency = useMemo(() => {
    return (
      currencyListSWR?.content?.list?.find(
        (item) => item.currency.currencyType === CurrencyType.SOL,
      )?.currency ?? ZeroSOL
    );
  }, [currencyListSWR?.content]);

  const onCountChanged = async (count: number) => {
    const unitPriceCurrency = props.unitPriceCurrency ?? ZeroSOL;
    const paymentAmount = BigNumber(unitPriceCurrency.amount).multipliedBy(
      count,
    );
    const paymentCurrency = copyObject<Currency>(unitPriceCurrency, {
      amount: paymentAmount.toString(),
    });
    await editingBody.fill((prevData) => ({
      ...prevData,
      cardCount: count,
      currency: paymentCurrency,
      amount: paymentAmount.toString(),
    }));
  };

  const serviceFeeCurrency = useMemo(() => {
    if (props.presets) {
      return props.presets.deployFeeSol;
    }

    if (editingBody.content.displayServiceFee && !props.payWithSol) {
      return copyObject<Currency>(
        editingBody.content.gasFeeCurrency ?? ZeroSOL,
        {
          amount: editingBody.content.displayServiceFee,
        },
      );
    }

    if (isOnlyPayWithGems) {
      return gasFeeSWR.content?.cashPointGasFee;
    }

    return gasFeeSWR.content?.gasFee;
  }, [editingBody.content, gasFeeSWR.content]);

  return (
    <ContentGroup omitTopPadding={Boolean(props.showQuantity)}>
      {props.showQuantity && (
        <>
          <VSpace height={10} />
          <PaymentQuantity
            count={editingBody.content.cardCount ?? 1}
            title={i18n.clover_number_of_cards()}
            maxCount={props.maxCount}
            onCountChanged={props.onCountChanged ?? onCountChanged}
          />
          <VSpace height={10} />

          <CellDivider />

          <VSpace height={20} />

          <HStack style={{ justifyContent: "space-between" }}>
            <ServiceFeeLabel>
              {props.totalPriceLabel ?? i18n.web3_mint_coupon_total_price()}
            </ServiceFeeLabel>

            <HStack style={{ gap: 4, justifyContent: "center" }}>
              <Image
                src={getCurrencyIcon(editingBody.content.currency)}
                width={16}
                height={16}
                style={{ borderRadius: 4 }}
              />

              <BalanceValueMini>
                {formatMoney("medium", editingBody.content.currency)}
              </BalanceValueMini>

              <BalanceValueMini>
                {getCurrencyName(editingBody.content.currency, i18n)}
              </BalanceValueMini>
            </HStack>
          </HStack>
          <VSpace height={20} />
        </>
      )}
      {props.presets && (
        <HStack
          style={{
            justifyContent: "space-between",
            alignItems: "start",
            marginBottom: 20,
          }}
        >
          <ServiceFeeLabel>{i18n.clover_card_price()}</ServiceFeeLabel>

          <VStack style={{ alignItems: "end", gap: 6 }}>
            {editingBody.content.isFree && (
              <FreeLabel>{i18n.web3_mint_coupon_free()}</FreeLabel>
            )}
            <HStack style={{ gap: 4, justifyContent: "center" }}>
              <Image
                src={getCurrencyIcon(props.presets.purchaseFeeSol)}
                width={16}
                height={16}
                style={{ borderRadius: 4 }}
              />

              <BalanceValueMini
                style={
                  editingBody.content.isFree
                    ? {
                        textDecoration: "line-through",
                        color: "rgba(255, 255, 255, 0.4)",
                      }
                    : {}
                }
              >
                {formatMoney("medium", props.presets.purchaseFeeSol)}
              </BalanceValueMini>

              <BalanceValueMini
                style={
                  editingBody.content.isFree
                    ? {
                        textDecoration: "line-through",
                        color: "rgba(255, 255, 255, 0.4)",
                      }
                    : {}
                }
              >
                {getCurrencyName(props.presets.purchaseFeeSol, i18n)}
              </BalanceValueMini>
            </HStack>
          </VStack>
        </HStack>
      )}

      {props.payWithSol && props.unitPriceCurrency && (
        <>
          <HStack
            style={{ justifyContent: "space-between", alignItems: "start" }}
          >
            <ServiceFeeLabel>{i18n.clover_stake_amount()}</ServiceFeeLabel>

            <VStack style={{ alignItems: "end", gap: 6 }}>
              <HStack style={{ gap: 4, justifyContent: "center" }}>
                <Image
                  src={getCurrencyIcon(props.unitPriceCurrency)}
                  width={16}
                  height={16}
                  style={{ borderRadius: 4 }}
                />

                <BalanceValueMini>
                  {formatMoney("medium", props.unitPriceCurrency)}
                </BalanceValueMini>

                <BalanceValueMini>
                  {getCurrencyName(props.unitPriceCurrency, i18n)}
                </BalanceValueMini>
              </HStack>
            </VStack>
          </HStack>
          <HStack
            style={{ gap: 12, marginTop: 20 }}
            onClick={() => {
              onClickTogglePayment().catch(andLog);
            }}
          >
            <CheckBox checked={Boolean(editingBody.content.payWithSOL)} />

            <VStack style={{ gap: 4 }}>
              <HStack style={{ gap: 4 }}>
                <PayWith>{i18n.clover_purchase_token_with()}</PayWith>
                <Image
                  src={iconSOL}
                  width={16}
                  height={16}
                  style={{ borderRadius: 4 }}
                />
                <PayWith>{formatMoney("medium", props.payWithSol)}</PayWith>
                <PayWith>{getCurrencyName(props.payWithSol, i18n)}</PayWith>
              </HStack>

              {solCurrency && (
                <HStack style={{ gap: 4 }}>
                  <Available>{i18n.web3_v0_available()}</Available>
                  <Image
                    src={iconGEMS}
                    width={10}
                    height={10}
                    style={{ borderRadius: 2 }}
                  />
                  <Available>{formatMoney("medium", solCurrency)}</Available>
                  <Available>{getCurrencyName(solCurrency, i18n)}</Available>
                </HStack>
              )}
            </VStack>
          </HStack>
          <VSpace height={20} />
        </>
      )}

      <HStack style={{ justifyContent: "space-between", alignItems: "start" }}>
        <ServiceFeeLabel>
          {props.presets
            ? i18n.clover_token_creation_fee()
            : i18n.web3_v0_service_fee()}
        </ServiceFeeLabel>

        <VStack style={{ alignItems: "end", gap: 6 }}>
          {editingBody.content.isFree && (
            <FreeLabel>{i18n.web3_mint_coupon_free()}</FreeLabel>
          )}
          <HStack style={{ gap: 4, justifyContent: "center" }}>
            <Image
              src={getCurrencyIcon(serviceFeeCurrency)}
              width={16}
              height={16}
              style={{ borderRadius: 4 }}
            />

            <BalanceValueMini
              style={
                editingBody.content.isFree
                  ? {
                      textDecoration: "line-through",
                      color: "rgba(255, 255, 255, 0.4)",
                    }
                  : {}
              }
            >
              {formatMoney("medium", serviceFeeCurrency)}
            </BalanceValueMini>

            <BalanceValueMini
              style={
                editingBody.content.isFree
                  ? {
                      textDecoration: "line-through",
                      color: "rgba(255, 255, 255, 0.4)",
                    }
                  : {}
              }
            >
              {getCurrencyName(serviceFeeCurrency, i18n)}
            </BalanceValueMini>
          </HStack>
        </VStack>
      </HStack>

      {!props.isFree && (
        <>
          <VSpace height={20} />
          <CellDivider />
        </>
      )}

      {props.showTotalAmount && mergedCurrency && (
        <>
          <VSpace height={20} />
          <HStack
            style={{ justifyContent: "space-between", alignItems: "start" }}
          >
            <ServiceFeeLabel>{i18n.web3_v0_total_amount()}</ServiceFeeLabel>

            <HStack style={{ gap: 4, justifyContent: "center" }}>
              <Image
                src={getCurrencyIcon(mergedCurrency)}
                width={16}
                height={16}
                style={{ borderRadius: 4 }}
              />

              <BalanceValueMini>
                {formatMoney("medium", mergedCurrency)}
              </BalanceValueMini>

              <BalanceValueMini>
                {getCurrencyName(mergedCurrency, i18n)}
              </BalanceValueMini>
            </HStack>
          </HStack>
        </>
      )}

      {!isOnlyPayWithGems && !props.isFree && (
        <HStack
          style={{ gap: 12, marginTop: 20 }}
          onClick={() => {
            onClickServiceFeeCurrency().catch(andLog);
          }}
        >
          {isMergedOrder ? (
            <CheckBox
              checked={editingBody.content.currencyType === CurrencyType.GEMS}
            />
          ) : (
            <CheckBox
              checked={
                editingBody.content.gasFeeCurrencyType === CurrencyType.GEMS ||
                Boolean(editingBody.content.payWithGems)
              }
            />
          )}

          <VStack style={{ gap: 4 }}>
            <HStack style={{ gap: 4 }}>
              <PayWith>{props.payWithLabel ?? i18n.clover_pay_with()}</PayWith>
              <Image
                src={iconGEMS}
                width={16}
                height={16}
                style={{ borderRadius: 4 }}
              />
              <PayWith>
                {formatMoney(
                  "medium",
                  payWithGems ??
                    fullConsumedGemsCurrency ??
                    gasFeeSWR.content?.cashPointGasFee,
                )}
              </PayWith>
              <PayWith>{getCurrencyName(gemsCurrency, i18n)}</PayWith>
            </HStack>

            {gemsCurrency && (
              <HStack style={{ gap: 4 }}>
                <Available>{i18n.web3_v0_available()}</Available>
                <Image
                  src={iconGEMS}
                  width={10}
                  height={10}
                  style={{ borderRadius: 2 }}
                />
                <Available>{formatMoney("medium", gemsCurrency)}</Available>
                <Available>{getCurrencyName(gemsCurrency, i18n)}</Available>
              </HStack>
            )}
          </VStack>
        </HStack>
      )}
    </ContentGroup>
  );
}

const FreeLabel = styled.div`
  color: #ffaf02;
  font-weight: 400;
  font-size: 14px;
`;

const PayWith = styled.div`
  color: rgba(255, 255, 255, 0.8);
  font-weight: 400;
  font-size: 14px;
`;

const Available = styled.div`
  color: rgba(255, 255, 255, 0.48);
  font-weight: 300;
  font-size: 10px;
`;

const BalanceValueMini = styled.div`
  color: var(--color-text00);
  font-weight: 400;
  font-size: 14px;
`;

const AvailableCurrencyAmount = styled.div`
  color: var(--color-text00);
  font-size: 10px;
  font-weight: 300;
`;

const AvailableCurrencyName = styled.div`
  color: var(--color-text20);
  font-size: 10px;
  font-weight: 300;
`;

const AvailableLabel = styled.div`
  color: var(--color-text20);
  font-size: 10px;
  font-weight: 300;
`;

const ServiceFeeLabel = styled.div`
  color: var(--color-text00);
  font-weight: 400;
  font-size: 14px;
`;

const CostCount = styled.div`
  color: var(--color-text00);
  font-weight: 500;
  font-size: 18px;
`;

export default PaymentServiceFee;
