import React from "react";
import {
  ButtonColor,
  LinkButton,
  PlaceholderButton,
  RegularButton,
  RegularLargeButton,
  StrongButton,
  StrongLargeButton,
  WeakButton,
} from "../../components/Buttons";
import { VSpace } from "../../components/VStack";
import ic_add from "../../res/images/ic_add.svg";
import { MonoLabel } from "./DemoCell";
import { Page } from "../../components/Page";
import { Image } from "../../components/Image";

export function ButtonDemoPage() {
  return (
    <Page pageData={undefined}>
      <MonoLabel>LinkButton</MonoLabel>
      <VSpace height={16} />
      <LinkButton>With Underline</LinkButton>
      <VSpace height={16} />
      <LinkButton $underline={false}>Without Underline</LinkButton>

      <VSpace height={32} />

      <MonoLabel>StrongButton</MonoLabel>
      <VSpace height={16} />
      <StrongButton>Default</StrongButton>
      <VSpace height={16} />
      <StrongButton disabled={true}>Default Disabled</StrongButton>

      <VSpace height={64} />
      <MonoLabel>StrongLargeButton</MonoLabel>
      <VSpace height={16} />
      <StrongLargeButton>
        <Image src={ic_add} alt={"reveal"} />
        Default
      </StrongLargeButton>
      <VSpace height={16} />
      <StrongLargeButton disabled={true}>Default Disabled</StrongLargeButton>

      <VSpace height={64} />
      <MonoLabel>RegularButton</MonoLabel>
      <VSpace height={16} />
      <RegularButton>Default</RegularButton>
      <VSpace height={16} />
      <RegularButton disabled={true}>Default Disabled</RegularButton>

      <VSpace height={32} />
      <RegularButton $baseColor={ButtonColor.greenish}>Greenish</RegularButton>
      <VSpace height={16} />
      <RegularButton $baseColor={ButtonColor.greenish} disabled={true}>
        Greenish Disabled
      </RegularButton>

      <VSpace height={32} />
      <RegularButton $baseColor={ButtonColor.bluish}>
        <Image src={ic_add} alt={"reveal"} />
        Bluish
      </RegularButton>
      <VSpace height={16} />
      <RegularButton $baseColor={ButtonColor.bluish} disabled={true}>
        Bluish Disabled
      </RegularButton>

      <VSpace height={64} />
      <MonoLabel>RegularLargeButton</MonoLabel>
      <VSpace height={16} />
      <RegularLargeButton>
        <Image src={ic_add} alt={"reveal"} />
        Default
      </RegularLargeButton>
      <VSpace height={16} />
      <RegularLargeButton disabled={true}>Default Disabled</RegularLargeButton>

      <VSpace height={64} />
      <MonoLabel>WeakButton</MonoLabel>
      <VSpace height={16} />
      <WeakButton>Default</WeakButton>
      <VSpace height={16} />
      <WeakButton disabled={true}>Default Disabled</WeakButton>

      <VSpace height={32} />
      <WeakButton $baseColor={ButtonColor.destructive}>
        <Image src={ic_add} alt={"reveal"} />
        Destructive
      </WeakButton>
      <VSpace height={16} />
      <WeakButton $baseColor={ButtonColor.destructive} disabled={true}>
        Destructive Disabled
      </WeakButton>

      <VSpace height={64} />
      <MonoLabel>PlaceholderButton</MonoLabel>
      <VSpace height={16} />
      <PlaceholderButton>
        <Image src={ic_add} alt={"reveal"} />
        Add Button
      </PlaceholderButton>
    </Page>
  );
}
