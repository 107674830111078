import { HStack, Spring, VSpace, VStack } from "../../../components/VStack";
import { Image } from "../../../components/Image";
import {
  formatMoney,
  getCurrencyAmountFromString,
  getCurrencyIcon,
  getCurrencyIconByCurrencyType,
  getCurrencyName,
  getCurrencyNameByCurrencyType,
} from "../../../proto/Currency";
import { MainCurrencyAmount, MainCurrencyName } from "./TopUpOrderDetail";
import { StatusBar } from "./GiftOrderListPage";
import React from "react";
import { useI18n } from "../../../hooks/useI18n";
import {
  AssetOrder,
  AssetOrderStatus,
  TxCategory,
} from "../../../proto/AssetOrder";
import { CellDivider, ContentGroup } from "../../../components/ContentGroup";
import { OrderInfoContent, OrderInfoLabel } from "./GiftOrderDetail";
import { formatDateWithOptions } from "../../../utils/DateTimeUtils";
import { TokenTransactionType } from "../../../proto/DexSwapInfo";

export function TradeOrderDetail(props: { assetOrder: AssetOrder }) {
  const i18n = useI18n();

  function getStatus() {
    switch (props.assetOrder.orderStatus) {
      case AssetOrderStatus.Done:
        return { text: i18n.web3_v0_completed(), color: "#00FF94" };
      case AssetOrderStatus.Pending:
      case AssetOrderStatus.Submitted: {
        return { text: i18n.web3_v0_pending(), color: "#34A6FFFF" };
      }
      case AssetOrderStatus.Failed: {
        return { text: i18n.circle_anmt_failed(), color: "#EF3537FF" };
      }
      case AssetOrderStatus.Rejected: {
        return { text: i18n.circle_anmt_rejected(), color: "#EF3537FF" };
      }
      default:
        return { text: "", color: "#FFFFFF66" };
    }
  }

  const isBuying =
    props.assetOrder.dexSwapInfo?.tokenTransactionType ===
    TokenTransactionType.BUY;

  const mainCurrency = isBuying
    ? props.assetOrder.txList?.find((tx) => tx.txCategory === TxCategory.GOT)
        ?.currency
    : props.assetOrder.txList?.find((tx) => tx.txCategory === TxCategory.SENT)
        ?.currency;

  return (
    <VStack style={{ width: "100%", alignItems: "center" }}>
      <HStack style={{ gap: 7, marginTop: 26 }}>
        <Image
          src={getCurrencyIcon(mainCurrency)}
          style={{ width: 36, height: 36, borderRadius: 4 }}
        />
        <HStack style={{ gap: 7, alignItems: "baseline" }}>
          <MainCurrencyAmount>
            {formatMoney("long", mainCurrency)}
          </MainCurrencyAmount>
          <MainCurrencyName>
            {getCurrencyName(mainCurrency, i18n)}
          </MainCurrencyName>
        </HStack>
      </HStack>
      <VSpace height={8} />
      <StatusBar
        statusColor={getStatus().color}
        statusText={getStatus().text}
      />
      <ContentGroup style={{ width: "100%", gap: 20, marginTop: 32 }}>
        <HStack>
          <OrderInfoLabel>{i18n.web3_v0_order_type()}</OrderInfoLabel>
          <Spring />
          <OrderInfoContent>
            {isBuying ? i18n.web3_v0_buy() : i18n.circle_fin_sell()}
          </OrderInfoContent>
        </HStack>
        <CellDivider />
        <HStack>
          <OrderInfoLabel>{i18n.clover_network_fee()}</OrderInfoLabel>
          <Spring />
          <OrderInfoContent>
            {props.assetOrder.dexSwapInfo?.networkFee
              ? `${formatMoney(
                  "long",
                  props.assetOrder.dexSwapInfo?.networkFee,
                )} ${props.assetOrder.dexSwapInfo?.networkFee?.currencyCode}`
              : "-"}
          </OrderInfoContent>
        </HStack>
        <CellDivider />
        <HStack>
          <OrderInfoLabel>
            {isBuying
              ? i18n.clover_total_deduction()
              : i18n.clover_total_received()}
          </OrderInfoLabel>
          <Spring />
          <VStack style={{ alignItems: "end" }}>
            {isBuying
              ? props.assetOrder.totalDeductionList?.map((currency) => (
                  <OrderInfoContent>
                    {`${formatMoney("long", currency)} ${currency?.currencyCode}`}
                  </OrderInfoContent>
                ))
              : props.assetOrder?.txList
                  ?.filter((log) => log.txCategory === TxCategory.GOT)
                  .map((log) => (
                    <OrderInfoContent>
                      {`${formatMoney("long", log.currency)} ${log.currency?.currencyCode}`}
                    </OrderInfoContent>
                  ))}
          </VStack>
        </HStack>
        <CellDivider />
        <HStack>
          <OrderInfoLabel>{i18n.web3_v0_created_time()}</OrderInfoLabel>
          <Spring />
          <OrderInfoContent>
            {formatDateWithOptions(props.assetOrder.createdTime || 0, {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })}
          </OrderInfoContent>
        </HStack>
        <CellDivider />
        <HStack>
          <OrderInfoLabel>{i18n.web3_v0_transaction_order_id()}</OrderInfoLabel>
          <Spring />
          <OrderInfoContent>
            {props.assetOrder.orderId.toString()}
          </OrderInfoContent>
        </HStack>
      </ContentGroup>
    </VStack>
  );
}
