import { DemoCell } from "./DemoCell";
import { Page } from "../../components/Page";
import { useHopper } from "../../hooks/useHopper";

export function HelpMDDemo() {
  const hopper = useHopper();
  return (
    <Page pageData={undefined}>
      {[
        "terms-of-service",
        "privacy-policy",
        "help-center",
        "copyright-policy",
        "user-guidelines",
        "suicide-prevention-resources",
      ].map((n) => (
        <DemoCell key={n} onClick={() => hopper.push(n)}>
          {n}
        </DemoCell>
      ))}
    </Page>
  );
}
