import { z } from "zod";
import { Media } from "./Media";
import { zStatic } from "../utils/zodUtils";

export const ProfileFrame = z.object({
  name: z.string().optional(),
  preview: Media.optional(),
  res: Media.optional(),
  customizedPreview: z.boolean().optional(),
});

export type ProfileFrame = zStatic<typeof ProfileFrame>;
