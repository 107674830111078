import { Page } from "../../../components/Page";
import { useBigIntParam } from "../../../hooks/useTypedParam";
import { useSWR } from "../../../hooks/swr/useSWR";
import { useI18n } from "../../../hooks/useI18n";
import SettingCell from "../../../components/SettingCell";
import { Image } from "../../../components/Image";
import CircleThemeColorPreview from "./CircleThemeColorPreview";
import CircleBackgroundFrame from "../CircleBackgroundFrame";
import React from "react";
import { useHopper } from "../../../hooks/useHopper";
import { useBackend } from "../../../service/APIService";
import { NavMiddle } from "../../../components/NavBar";

function CircleAppearancePage() {
  const circleId = useBigIntParam("circleId");
  const backend = useBackend();
  const circleSWR = useSWR(backend.getCircle(circleId));
  const i18n = useI18n();
  const hopper = useHopper();
  return (
    <Page
      pageData={circleSWR}
      scrollPaddingDisabled={true}
      underlay={
        <CircleBackgroundFrame
          circleBackground={circleSWR.content?.circleBackground}
        />
      }
    >
      <NavMiddle>{i18n.circle2_1_appearance()}</NavMiddle>
      <SettingCell
        title={i18n.cover_image()}
        endValue={
          <Image
            style={{
              borderRadius: 4,
              border: "1px solid rgba(255, 255, 255, 0.4)",
            }}
            src={[circleSWR.content?.circleIcon, 44]}
            width={30}
            height={44}
          />
        }
      />

      <SettingCell
        title={i18n.background_image()}
        onClick={() => {
          hopper.push(`background-picker`);
        }}
        endValue={
          circleSWR.content?.circleBackground?.backgroundImage ? (
            <Image
              style={{
                borderRadius: 4,
                border: "1px solid rgba(255, 255, 255, 0.4)",
              }}
              src={[
                circleSWR.content?.circleBackground?.backgroundImage,
                "best",
              ]}
              width={30}
              height={44}
            />
          ) : (
            <CircleThemeColorPreview width={30} height={30} />
          )
        }
      />

      <SettingCell
        title={i18n.circle2_1_home_tab_background()}
        onClick={() => {
          hopper.push(`background-picker`);
        }}
        endValue={
          circleSWR.content?.homeTabBackground?.backgroundImage ? (
            <Image
              style={{
                borderRadius: 4,
                border: "1px solid rgba(255, 255, 255, 0.4)",
              }}
              src={[
                circleSWR.content?.homeTabBackground?.backgroundImage,
                "best",
              ]}
              width={30}
              height={44}
            />
          ) : (
            <CircleThemeColorPreview width={30} height={30} />
          )
        }
      />

      <SettingCell
        title={i18n.circle2_1_menu_background()}
        onClick={() => {
          hopper.push(`background-picker`);
        }}
        endValue={
          circleSWR.content?.menuBackground?.backgroundImage ? (
            <Image
              style={{
                borderRadius: 4,
                border: "1px solid rgba(255, 255, 255, 0.4)",
              }}
              src={[circleSWR.content?.menuBackground.backgroundImage, "best"]}
              width={30}
              height={44}
            />
          ) : (
            <CircleThemeColorPreview width={30} height={30} />
          )
        }
      />

      <SettingCell
        title={i18n.circle2_1_theme_color()}
        endValue={
          <CircleThemeColorPreview
            width={30}
            height={30}
            themeColor={circleSWR.content?.themeColor}
          />
        }
      />
    </Page>
  );
}

export default CircleAppearancePage;
