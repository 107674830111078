import React, { useEffect } from "react";
import deniedVisitIcon from "../../res/images/denied-visit-icon.svg";
import warnVisitIcon from "../../res/images/warn-visit-icon.svg";
import "./visitLink.css";
import { useBackend } from "../../service/APIService";
import {
  useBigIntSearchParam,
  useEnumSearchParam,
  useStringSearchParam,
} from "../../hooks/useTypedParam";
import { VisitLinkRuleType } from "../../proto/VisitLinkResp";
import { ObjectType } from "../../proto/ObjectSpec";
import { Page } from "../../components/Page";
import { ButtonColor, RegularButton } from "../../components/Buttons";
import { useSWR } from "../../hooks/swr/useSWR";

export function VisitLink() {
  const link = useStringSearchParam("link", "");
  const parentId = useBigIntSearchParam("oid");
  const parentType = useEnumSearchParam<ObjectType>("otype");

  const backend = useBackend();
  const verifyLinkSWR = useSWR(backend.verifyLink(link, parentId, parentType), {
    ignoreCache: true,
  });
  const resp = verifyLinkSWR.content;

  useEffect(() => {
    if (resp && resp.link) {
      if (
        resp.ruleType === VisitLinkRuleType.White ||
        resp.ruleType === VisitLinkRuleType.PopularUnknown
      ) {
        window.location.href = resp.link;
      } else {
        document.title = resp.pageTitle ?? "-";
      }
    }
  }, [verifyLinkSWR.content]);

  return (
    <Page pageData={verifyLinkSWR} background={"#0A001A"}>
      {resp?.ruleType === VisitLinkRuleType.Unknown && resp.link && (
        <WarnVisitPage
          link={resp.link}
          contentHead={resp.contentHead}
          visitButtonText={resp.visitButtonText}
        ></WarnVisitPage>
      )}
      {resp?.ruleType === VisitLinkRuleType.Black && (
        <DeniedVisitPage
          contentHead={resp.contentHead}
          contentBody={resp.contentBody}
        ></DeniedVisitPage>
      )}
    </Page>
  );
}

export function DeniedVisitPage(props: {
  contentHead: string | undefined;
  contentBody: string | undefined;
}) {
  const { contentHead, contentBody } = props;
  return (
    <div>
      <div className="icon" style={{ textAlign: "center" }}>
        <img src={deniedVisitIcon}></img>
      </div>
      <div className="deniedVisitContentTitle">{contentHead}</div>
      <div className="deniedVisitContentBody">{contentBody}</div>
    </div>
  );
}

export function WarnVisitPage(props: {
  link: string;
  contentHead: string | undefined;
  visitButtonText: string | undefined;
}) {
  const { link, contentHead, visitButtonText } = props;
  let displayLink = link;
  if (link?.length > 60) {
    displayLink = link.slice(0, 60) + "...";
  }
  return (
    <div>
      <div className="icon" style={{ textAlign: "center" }}>
        <img src={warnVisitIcon}></img>
      </div>
      <div className="warnVisitHeadText">{contentHead}</div>
      <div className="warnVisitContentBodyText">{displayLink}</div>
      <RegularButton
        style={{ margin: "0 auto", minWidth: 160 }}
        onClick={() => (window.location.href = link)}
        $baseColor={ButtonColor.greenish}
      >
        {visitButtonText}
      </RegularButton>
    </div>
  );
}
