import React, { useMemo } from "react";
import { DemoCell, MonoLabel } from "./DemoCell";
import { useHopMap, useHopState } from "../../hooks/useHopState";
import { button_on_pressed } from "../../components/CommonStyles";
import styled from "styled-components";
import { HStack, VSpace, VStack } from "../../components/VStack";
import { JSONUtil } from "../../utils/JSONUtil";
import { useHopper } from "../../hooks/useHopper";
import { Page } from "../../components/Page";
import { NavEnd, NavItem } from "../../components/NavBar";
import { useSWR } from "../../hooks/swr/useSWR";
import { getRandomId } from "../../utils/randomId";

const StateDisplay = styled.div`
  min-height: 20px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  padding: 12px;
  color: var(--color-text00);
  font-size: 16px;
  font-weight: 400;
`;

const DebugButton = styled.button`
  background: transparent;
  border-radius: 4px;
  border-color: rgba(255, 255, 255, 0.3);
  border-width: 1px;
  border-style: solid;
  padding: 8px 16px;

  color: var(--color-text00);
  font-size: 16px;
  font-weight: 400;

  ${button_on_pressed}
`;

function StringStateCase(props: { value: string }) {
  const [state, setState, clearWebHostState] = useHopState<string>(
    ["StringStateCase", props.value],
    props.value,
  );
  return (
    <VStack style={{ gap: 8, alignItems: "stretch" }}>
      <MonoLabel>{`useWebHostState<string> ${props.value}`}</MonoLabel>
      <StateDisplay>{JSONUtil.stringify(state)}</StateDisplay>
      <HStack style={{ justifyContent: "space-between" }}>
        <DebugButton onClick={() => setState((prev) => prev + props.value)}>
          Repeat
        </DebugButton>
        <DebugButton onClick={() => setState(props.value)}>Reset</DebugButton>
        <DebugButton onClick={() => clearWebHostState()}>Clear</DebugButton>
      </HStack>
    </VStack>
  );
}

function StringOrUndefinedStateCase(props: { value: string }) {
  const [state, setState, clearWebHostState] = useHopState<string>([
    "StringOrUndefinedStateCase",
    props.value,
  ]);
  return (
    <VStack style={{ gap: 8, alignItems: "stretch" }}>
      <MonoLabel>{`useWebHostState<string|undefined> ${props.value}`}</MonoLabel>
      <StateDisplay>{JSONUtil.stringify(state)}</StateDisplay>
      <HStack style={{ justifyContent: "space-between" }}>
        <DebugButton
          onClick={() =>
            setState((prev) => (prev ? prev + props.value : props.value))
          }
        >
          Repeat
        </DebugButton>
        <DebugButton onClick={() => setState(undefined)}>Reset</DebugButton>
        <DebugButton onClick={() => clearWebHostState()}>Clear</DebugButton>
      </HStack>
    </VStack>
  );
}

function StringOrNullStateCase(props: { value: string }) {
  const [state, setState, clearWebHostState] = useHopState<string | null>(
    ["StringOrNullStateCase", props.value],
    null,
  );
  return (
    <VStack style={{ gap: 8, alignItems: "stretch" }}>
      <MonoLabel>{`useWebHostState<string|null> ${props.value}`}</MonoLabel>
      <StateDisplay>{JSONUtil.stringify(state)}</StateDisplay>
      <HStack style={{ justifyContent: "space-between" }}>
        <DebugButton
          onClick={() =>
            setState((prev) => (prev ? prev + props.value : props.value))
          }
        >
          Repeat
        </DebugButton>
        <DebugButton onClick={() => setState(null)}>Reset</DebugButton>
        <DebugButton onClick={() => clearWebHostState()}>Clear</DebugButton>
      </HStack>
    </VStack>
  );
}

export function HopStateDemoPage() {
  const hopper = useHopper();

  const showFirst = useMemo(() => {
    return Math.round(Math.random()) % 2 === 0;
  }, []);

  return (
    <Page pageData={undefined}>
      <NavEnd>
        {NavItem.done("Navigate Away", () => hopper.push("notfound"))}
      </NavEnd>

      <StringStateCase value={"hello"} />

      <VSpace height={40} />
      {showFirst && <StringStateCase value={"a"} />}
      {!showFirst && <StringStateCase value={"b"} />}

      <VSpace height={40} />
      {showFirst && <StringOrUndefinedStateCase value={"c"} />}
      {!showFirst && <StringOrUndefinedStateCase value={"d"} />}

      <VSpace height={40} />
      {showFirst && <StringOrNullStateCase value={"e"} />}
      {!showFirst && <StringOrNullStateCase value={"f"} />}
    </Page>
  );
}

export function HopMapDemoPage() {
  const hopper = useHopper();

  const [isFollowedByMe, setIsFollowedByMe] = useHopMap<bigint, boolean>(
    "isFollowedByMe",
  );
  const usersSWR = useSWR("usersSWR", async () => [
    { uid: BigInt(getRandomId()) },
    { uid: BigInt(getRandomId()) },
    { uid: BigInt(getRandomId()) },
    { uid: BigInt(getRandomId()) },
    { uid: BigInt(getRandomId()) },
    { uid: BigInt(getRandomId()) },
  ]);

  return (
    <Page pageData={usersSWR}>
      <NavEnd>
        {NavItem.done("Navigate Away", () => hopper.push("notfound"))}
      </NavEnd>

      {usersSWR.content?.map((user) => (
        <DemoCell
          key={user.uid}
          onClick={() => {
            setIsFollowedByMe(user.uid, (prev) => !prev);
          }}
        >{`${user.uid}: ${isFollowedByMe.get(user.uid) ?? false}`}</DemoCell>
      ))}
    </Page>
  );
}
