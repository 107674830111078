import { useBigIntParam } from "../../../hooks/useTypedParam";
import { useNativePage } from "../../../hooks/useBridge";
import { useSWR } from "../../../hooks/swr/useSWR";
import { useI18n } from "../../../hooks/useI18n";
import { useLoadState } from "../../../hooks/LoadState";
import React, { useState } from "react";
import { andLog, useErrorHandler } from "../../../components/handleError";
import { RegularInputGroup } from "../../../components/Input";
import { Page } from "../../../components/Page";
import { Spacing } from "../../../components/Spacing";
import { RegularButton } from "../../../components/Buttons";
import { Spin } from "../../../components/Spin";
import { Circle } from "../../../proto/Circle";
import { useHopper } from "../../../hooks/useHopper";
import { useBackend } from "../../../service/APIService";
import { NavMiddle } from "../../../components/NavBar";

interface EditCirclePageProps {
  fieldName: keyof Circle;
  maxLength: number;
  title: () => string;
  initialValue: (circle: Circle) => string;
}

export namespace CircleInfoMaxLength {
  export const SocialId = 25;
  export const Name = 50;
  export const CardName = 50;
  export const Passcode = 6;
  export const Question = 200;
  export const Tagline = 200;
  export const WelcomeMessage = 200;
}

function EditCirclePage({
  fieldName,
  maxLength,
  title,
  initialValue,
}: EditCirclePageProps) {
  const circleId = useBigIntParam("circleId");
  const backend = useBackend();
  const circleSWR = useSWR(backend.getCircle(circleId));
  const circle = circleSWR.content;
  const i18n = useI18n();
  const handleError = useErrorHandler();
  const nativePage = useNativePage();

  const hopper = useHopper();

  const updateCircleTask = useLoadState();
  const [input, setInput] = useState<string>(
    circle ? initialValue(circle) : "",
  );

  const updateCircle = async () => {
    const r = await updateCircleTask.run(async () => {
      const circle = await backend
        .updateCircle(circleId, { [fieldName]: input ?? "" })
        .run();
      await circleSWR.fill(circle);
      hopper.back();
    });

    if (!r.success) {
      handleError(r.error);
    }
  };

  return (
    <Page pageData={circleSWR}>
      <NavMiddle>{title()}</NavMiddle>
      <RegularInputGroup
        value={input}
        updateValue={setInput}
        maxLength={maxLength}
      />
      <Spacing height={100} />
      <RegularButton
        onClick={() => {
          updateCircle().catch(andLog);
        }}
      >
        <Spin state={updateCircleTask.state}>{i18n.save()}</Spin>
      </RegularButton>
    </Page>
  );
}

export function EditCircleNamePage() {
  const i18n = useI18n();
  return (
    <EditCirclePage
      fieldName="name"
      maxLength={CircleInfoMaxLength.Name}
      title={() => i18n.circle_name()}
      initialValue={(circle) => circle.name}
    />
  );
}

export function EditCircleTaglinePage() {
  const i18n = useI18n();
  return (
    <EditCirclePage
      fieldName="tagline"
      maxLength={CircleInfoMaxLength.Tagline}
      title={() => i18n.tagline()}
      initialValue={(circle) => circle.tagline ?? ""}
    />
  );
}

export function EditCircleWelcomeMessagePage() {
  const i18n = useI18n();
  return (
    <EditCirclePage
      fieldName="welcomeMessage"
      maxLength={CircleInfoMaxLength.WelcomeMessage}
      title={() => i18n.welcome_message()}
      initialValue={(circle) => circle.welcomeMessage ?? ""}
    />
  );
}

export function EditCircleSocialIdPage() {
  const i18n = useI18n();
  return (
    <EditCirclePage
      fieldName="socialId"
      maxLength={CircleInfoMaxLength.SocialId}
      title={() => i18n.edit()}
      initialValue={(circle) => circle.socialId ?? ""}
    />
  );
}
