import styled, { css } from "styled-components";
import { accent_font } from "./CommonStyles";

const SpeedTitleCommon = css`
  position: relative;
  color: var(--color-text00);
  box-sizing: border-box;
  padding-bottom: 4px;

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    width: 80px;
    left: auto;
    height: 1px;
    background: linear-gradient(to right, #ffffff, rgba(255, 255, 255, 0));
  }
`;

export const SpeedTitle = styled.div`
  ${SpeedTitleCommon};
  ${accent_font(14)};
  color: var(--color-text00);
  text-transform: uppercase;
`;

export const SpeedSubtitle = styled.div`
  ${SpeedTitleCommon};
  font-size: 16px;
  font-weight: 400;
  color: var(--color-text10);
`;

export const SpeedTitleNote = styled.div`
  color: #fff;
  font-size: 12px;
  font-weight: 300;
  opacity: 0.5;

  margin-top: 6px;
`;
