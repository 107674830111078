import { Page } from "../../components/Page";
import { CircleChildPageProps } from "./CircleChildPageProps";
import { useSWR } from "../../hooks/swr/useSWR";
import { useI18n } from "../../hooks/useI18n";
import { useSWRArray } from "../../hooks/swr/useSWRArray";
import StyledPostsFlow from "./StyledPostsFlow";
import {
  useBigIntParam,
  useBigIntSearchParam,
} from "../../hooks/useTypedParam";
import { useBackend } from "../../service/APIService";
import styled from "styled-components";
import { NavMiddle } from "../../components/NavBar";
import React from "react";
import { useMemoryRepoMap } from "../../hooks/swr/useLocalRepo";
import { zBigInt } from "../../utils/zodUtils";
import { z } from "zod";
import { Reaction } from "../../proto/Reaction";
import { andLog } from "../../components/handleError";
import { useReactionRecord } from "../../hooks/useReactionRecord";

const FolderTitleLabel = styled.div`
  color: #ffffff;
  font-size: 24px;
  font-weight: bold;
`;

const FolderDescriptionLabel = styled.div`
  color: rgba(255, 255, 255, 0.8);
  font-size: 13px;
`;
function CirclePostsPage(props: CircleChildPageProps) {
  const backend = useBackend();
  const i18n = useI18n();
  const circleId = useBigIntParam("circleId");
  const pFolderId = useBigIntSearchParam("folderId", BigInt(0));
  const folderId = props.folderId ?? pFolderId;
  const circleSWR = useSWR(backend.getCircle(circleId));
  const reactionRepo = useReactionRecord();
  const postsSWR = useSWRArray(
    backend.getCircleFolderItems(circleId, folderId).intercept((it) => {
      it.list.forEach((item) => {
        if (item.blog?.blogId) {
          reactionRepo
            .setValue(item.blog?.blogId, item.blog?.reactionCountList)
            .catch(andLog);
        }
      });
    }),
  );

  const circleFolderSWR = useSWR(backend.getCircleFolder(circleId, folderId));

  const folderName = () => {
    const folderName = circleFolderSWR.content?.name;
    if (!props.folderId && folderName) {
      return folderName;
    }
    return "";
  };

  return (
    <Page pageData={[circleSWR, postsSWR]}>
      <NavMiddle>{folderName()}</NavMiddle>
      {(props.showTitle ?? true) && (
        <FolderTitleLabel>{circleFolderSWR.content?.name}</FolderTitleLabel>
      )}
      {(props.showTitle ?? true) && (
        <FolderDescriptionLabel>
          {circleFolderSWR.content?.description}
        </FolderDescriptionLabel>
      )}
      {postsSWR.content && (
        <StyledPostsFlow
          circleId={circleId}
          showMembershipLevel={true}
          posts={postsSWR.content.map((item) => item.blog!)}
          folderLayoutType={circleFolderSWR.content?.folderLayout}
        />
      )}
    </Page>
  );
}

export default CirclePostsPage;
