import React, { useCallback, useContext, useMemo } from "react";

export type ObscuredZone = {
  readonly top: number;
  readonly bottom: number;
};

export type ObscuredZoneKey =
  | "Browser"
  | "HomeTab"
  | "NavBar"
  | "WideTopBar"
  | "DemoPageFoot"
  | "ChatInputBar"
  | "CommentInputBar"
  | "CreateCircleBottom"
  | "SendNext"
  | "CircleJoinLogsBottom"
  | "CircleBottom"
  | "InterestsBottom"
  | "TokenHoldersBottom"
  | "TokenDesignBottom"
  | "TokenDeployBottom"
  | "NFTBottom"
  | "EditorToolBar";

export interface ObscuredZoneContext {
  id: string | undefined;
  zones: ReadonlyMap<ObscuredZoneKey, ObscuredZone>;
  setZone: (key: ObscuredZoneKey, zone: ObscuredZone | undefined) => void;
  parent: ObscuredZoneContext | undefined;
}

const kDefaultContext = {
  id: "default",
  zones: new Map(),
  setZone: (key: string, zone: ObscuredZone | undefined) => {},
  parent: undefined,
};

export const ObscuredZoneContext =
  React.createContext<ObscuredZoneContext>(kDefaultContext);

export function useObscuredZones(
  id?: string,
): [
  ObscuredZone,
  (key: ObscuredZoneKey, zone: ObscuredZone | undefined) => void,
  ReadonlyMap<ObscuredZoneKey, ObscuredZone>,
] {
  const directContext = useContext(ObscuredZoneContext);
  const context = useMemo(() => {
    if (id === undefined) {
      return directContext;
    } else {
      let itr: ObscuredZoneContext | undefined = directContext;
      while (itr) {
        if (itr.id === id) {
          return itr;
        }
        itr = itr.parent;
      }

      return kDefaultContext;
    }
  }, [directContext, id]);

  return useMemo(() => {
    return [
      Array.from(context.zones.values()).reduce(
        (prev, curr) => {
          return {
            top: Math.max(prev.top, curr.top),
            bottom: Math.max(prev.bottom, curr.bottom),
          };
        },
        { top: 0, bottom: 0 },
      ),
      context.setZone,
      context.zones,
    ];
  }, [context.zones, context.setZone]);
}

export function useObscuredZoneForKey(key: ObscuredZoneKey, id?: string) {
  const [_, setZone, zones] = useObscuredZones(id);
  const setter = useCallback(() => {
    return (zone: ObscuredZone) => {
      setZone(key, zone);
    };
  }, [setZone]);
  return [zones.get(key) ?? { bottom: 0, top: 0 }, setter] as const;
}
