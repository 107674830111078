import { HStack, VStack } from "../../../components/VStack";
import {
  CurrencyId,
  CurrencyType,
  getCurrencyNameByCurrencyType,
} from "../../../proto/Currency";
import { Image } from "../../../components/Image";
import styled from "styled-components";
import { useI18n } from "../../../hooks/useI18n";
import iconGems from "../../../res/images/icon_gems.svg";
import { cell_on_pressed } from "../../../components/CommonStyles";
import iconSOL from "../../../res/images/icon_sol.svg";
import { isLiteApp } from "../../../bridge/ExternalAppShellData";

export interface SelectPaymentMethodProps {
  onChosen: (currencyId: number, currencyType: CurrencyType) => void;
}

function SelectPaymentMethodModal(props: SelectPaymentMethodProps) {
  const i18n = useI18n();
  return (
    <VStack>
      {!isLiteApp() && (
        <Container
          style={{ height: 88 }}
          onClick={() => {
            props.onChosen(CurrencyId.SOL, CurrencyType.SOL);
          }}
        >
          <Image src={iconSOL} width={48} height={48} />
          <CurrencyName>
            {getCurrencyNameByCurrencyType(CurrencyType.SOL, i18n)}
          </CurrencyName>
        </Container>
      )}

      <Container
        style={{ height: 88 }}
        onClick={() => {
          props.onChosen(CurrencyId.GEMS, CurrencyType.GEMS);
        }}
      >
        <Image src={iconGems} width={48} height={48} />
        <CurrencyName>
          {getCurrencyNameByCurrencyType(CurrencyType.GEMS, i18n)}
        </CurrencyName>
      </Container>
    </VStack>
  );
}

const Container = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: row;
  align-items: center;
  padding-inline-start: 20px;
  padding-inline-end: 20px;
  ${cell_on_pressed};
  height: 88px;
`;

const CurrencyName = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: var(--color-text00);
`;

export default SelectPaymentMethodModal;
