import { TagObjectProps } from "./TagUserPage";
import { useBackend } from "../../service/APIService";
import { useSWRList } from "../../hooks/swr/useSWRList";
import { Page } from "../../components/Page";
import { tagPageBackground } from "./TagsPage";
import { PostCell } from "../post/PostCell";
import * as React from "react";
import { ObjectType } from "../../proto/ObjectSpec";
import { Post } from "../../proto/Post";
import { useMemoryRepoMap } from "../../hooks/swr/useLocalRepo";
import { zBigInt } from "../../utils/zodUtils";
import { z } from "zod";
import { Reaction } from "../../proto/Reaction";
import { andLog } from "../../components/handleError";
import { useReactionRecord } from "../../hooks/useReactionRecord";

export function TagPostPage({ tagId, tagName }: TagObjectProps) {
  const backend = useBackend();
  const reactionRepo = useReactionRecord();
  const postsSWR = useSWRList(
    backend
      .tagObjects(ObjectType.BLOG, Post, tagId, tagName)
      .intercept((it) => {
        it.list.forEach((post) => {
          reactionRepo
            .setValue(post.blogId, post.reactionCountList)
            .catch(andLog);
        });
      }),
  );

  return (
    <Page underlay={tagPageBackground} pageData={postsSWR}>
      {postsSWR.content &&
        postsSWR.content.list.map((item) => (
          <PostCell post={item} key={item.blogId}></PostCell>
        ))}
    </Page>
  );
}
