import { Attachment, isBlockAttachment } from "./Attachment";
import React, { CSSProperties, ReactNode } from "react";
import { ParagraphStyle, RichSpan } from "./RichFormat";
import { splitZDoc, ZDocProps } from "./ZDocCommon";
import { ZDocText } from "./ZDocText";
import { ZDocInlineAttachment } from "./ZDocInlineAttachment";
import { flattenStateId } from "../../hooks/StateId";
import twitter from "twitter-text";

function getDefaultParagraph(
  paragraphStyle: ParagraphStyle | undefined,
  isBlank: boolean,
) {
  let elementType: React.ElementType;
  switch (paragraphStyle?.style) {
    case "h0":
      elementType = "h1";
      break;
    case "h1":
      elementType = "h1";
      break;
    case "h2":
      elementType = "h2";
      break;
    case "h3":
      elementType = "h3";
      break;
    case "quote":
      elementType = "blockquote";
      break;
    default:
      elementType = "p";
  }

  let props: Object | undefined;

  const marginBlock = isBlank
    ? { marginBlockStart: "0.5em", marginBlockEnd: "0.5em" }
    : { marginBlock: 0 };

  const commonStyles: CSSProperties = {
    ...marginBlock,
    userSelect: "text",
    msUserSelect: "text",
    marginTop: 0,
    marginBottom: 0,
  };

  switch (paragraphStyle?.alignment) {
    case "left":
      props = { style: { textAlign: "left", ...commonStyles } };
      break;
    case "center":
      props = { style: { textAlign: "center", ...commonStyles } };
      break;
    case "right":
      props = { style: { textAlign: "right", ...commonStyles } };
      break;
    default:
      props = { style: { textAlign: "left", ...commonStyles } };
  }

  return {
    elementType,
    props,
  };
}

export function ZDocParagraph(props: ZDocProps) {
  const richFormat = props.richFormat;

  const getParagraph =
    props.options?.overrides?.paragraph ?? getDefaultParagraph;

  const paragraphStyle = richFormat?.paragraphSpans?.at(0)?.data;
  const paragraph = getParagraph(
    paragraphStyle,
    props.content.trim().length === 0,
  );

  const inlineAttachmentSpans = richFormat?.attachmentSpans?.filter(
    (a) => !isBlockAttachment(a.data),
  );

  const fulfilledInlineAttachmentSpans = fulfilInlineAttachment(
    props.content,
    inlineAttachmentSpans,
  );

  const nodes: ReactNode[] = [];
  splitZDoc(
    props.richFormat,
    props.content,
    fulfilledInlineAttachmentSpans,
    (rf, content) => {
      nodes.push(
        <ZDocText
          key={flattenStateId([rf, content, nodes.length])}
          content={content}
          richFormat={rf}
          options={props.options}
          paragraphStyle={paragraphStyle}
        />,
      );
    },
    (attachment, rf, content) => {
      nodes.push(
        <ZDocInlineAttachment
          key={flattenStateId([rf, content, attachment, nodes.length])}
          attachment={attachment}
          richFormat={rf}
          content={content}
          options={props.options}
        />,
      );
    },
  );

  return (
    <paragraph.elementType {...paragraph.props}>{nodes}</paragraph.elementType>
  );
}

function fulfilInlineAttachment(
  content: string,
  inlineAttachmentSpans?: RichSpan<Attachment>[],
) {
  function existOverLap(a: [number, number], b: [number, number]) {
    const startA = a.at(0) || 0;
    const endA = a.at(1) || 0;
    const startB = b.at(0) || 0;
    const endB = b.at(1) || 0;
    return (
      (startA >= startB && startA <= endB) ||
      (endA >= startB && endA <= endB) ||
      (startB >= startA && startB <= endA) ||
      (endB >= startA && endB <= endA)
    );
  }

  const implicitUrlWithIndices = twitter.extractUrlsWithIndices(content).filter(
    (item) =>
      //drop the urls overlapping with original spans
      !inlineAttachmentSpans?.find((span) =>
        existOverLap(item.indices, [span.start || 0, span.end]),
      ),
  );

  const implicitHashTagsWithIndices = twitter
    .extractHashtagsWithIndices(content)
    .filter(
      (item) =>
        //drop the hashTag overlapping with urls
        !implicitUrlWithIndices.find((urlWithIndices) =>
          existOverLap(urlWithIndices.indices, item.indices),
        ),
    );

  //construct link span
  const implicitUrlSpans: RichSpan<Attachment>[] = implicitUrlWithIndices.map(
    (item) => {
      return {
        start: item.indices.at(0),
        end: item.indices.at(1) || 0,
        data: {
          type: "link",
          link: {
            style: "inline",
            url: item.url,
          },
        },
      };
    },
  );

  //construct hashTag span
  const implicitHashTagSpans: RichSpan<Attachment>[] =
    implicitHashTagsWithIndices.map((item) => {
      return {
        start: item.indices.at(0),
        end: item.indices.at(1) || 0,
        data: {
          type: "hashTag",
        },
      };
    });

  const mergedSpans = [
    ...(inlineAttachmentSpans || []),
    ...implicitUrlSpans,
    ...implicitHashTagSpans,
  ];

  return mergedSpans.sort((a, b) => (a.start || 0) - b.end);
}
