import React, { ReactNode } from "react";

export interface PageDecoration {
  setHeader: (header: ReactNode | undefined) => void;
  setFooter: (footer: ReactNode | undefined) => void;
}

export const PageDecoration = React.createContext<PageDecoration>({
  setHeader: (header: ReactNode | undefined) => {},
  setFooter: (footer: ReactNode | undefined) => {},
});
