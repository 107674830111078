import {Media} from "../../proto/Media";
import {Poll} from "../../proto/Poll";
import React, {ReactNode} from "react";
import {isBlockAttachment} from "./Attachment";
import {VSpace} from "../VStack";
import {splitZDoc, ZDocProps} from "./ZDocCommon";
import {ZDocChunk} from "./ZDocChunk";
import {ZDocBlockAttachment} from "./ZDocBlockAttachment";
import {flattenStateId} from "../../hooks/StateId";

/*********************************

 - ZDoc
 --- ZDocBlockAttachment
 --- ZDocChunk
 ----- ZDocParagraph
 ------- ZDocText
 --------- ZDocAtom
 ------- ZDocInlineAttachment

 **********************************/

export function ZDoc(
  props: {
    content: string | undefined;
    mediaList: Media[] | undefined;
    pollList: Poll[] | undefined;
    onViewMedia: (media: Media) => void;
  } & Omit<ZDocProps, "content">,
) {
  const richFormat = props.richFormat;
  const content = props.content;

  if (!content) return <></>;

  const nodes: ReactNode[] = [];

  const blockAttachmentSpans = richFormat?.attachmentSpans?.filter((a) =>
    isBlockAttachment(a.data),
  );

  splitZDoc(
    richFormat,
    content,
    blockAttachmentSpans,
    (rf, c) => {
      // Only remove the last ONE '\n'
      const contentPiece = (() => {
        return c.endsWith("\n") ? c.slice(0, -1) : c;
      })();

      if (nodes.length > 0) {
        nodes.push(<VSpace height={3} key={nodes.length} />);
      }

      nodes.push(
        <ZDocChunk
          content={contentPiece}
          richFormat={rf}
          key={flattenStateId([rf, contentPiece, nodes.length])}
          options={props.options}
        />,
      );
    },
    (attachment, rf, c) => {
      if (nodes.length > 0) {
        nodes.push(<VSpace height={12} key={nodes.length} />);
      }

      nodes.push(
        <ZDocBlockAttachment
          key={flattenStateId([attachment, nodes.length])}
          attachment={attachment}
          mediaList={props.mediaList}
          pollList={props.pollList}
          onViewMedia={props.onViewMedia}
        />,
      );
    },
    (span) => span.end + 1,
  );

  return <>{nodes}</>;
}
