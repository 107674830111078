import styled from "styled-components";
import { HStack, VStack } from "../../../components/VStack";
import React, { useEffect, useRef, useState } from "react";
import { Image } from "../../../components/Image";
import icVoice from "../images/ic_voice.svg";
import icMedia from "../images/ic_media.svg";
import icSticker from "../images/ic_sticker.svg";
import icActivity from "../images/ic_activity.svg";
import icDice from "../images/ic_dice.svg";
import icMore from "../images/ic_more.svg";
import { useObscuredZones } from "../../../hooks/useObscuredZones";
import DicePicker from "./DicePickerPage";
import icSendEnable from "../images/ic_send_enable.svg";
import icSendDisable from "../images/ic_send_disable.svg";
import {
  button_on_pressed,
  FullPageWidthCell,
} from "../../../components/CommonStyles";
import { useBackend } from "../../../service/APIService";
import { useSWR } from "../../../hooks/swr/useSWR";
import { Sticker, StickerType } from "../../../proto/Reaction";
import { StickerPanel } from "../../reaction/StickerPanel";
import icCustomizedEmoji from "../../../res/images/ic_customized_sticker.png";
import icStaticEmoji from "../../../res/images/ic_static_sticker.png";
import icAnimatedEmoji from "../../../res/images/ic_animated_sticker.webp";
import { SelectedReactionType, StickerNav } from "../../reaction/StickerPicker";
import { Swiper, SwiperClass, SwiperRef, SwiperSlide } from "swiper/react";
import GridLayout from "../../../components/GridLayout";
import { Dice } from "../../../proto/Dice";
import { Backend } from "../../../service/Backend";

const MsgInput = styled.textarea`
  background-color: rgba(255, 255, 255, 0.15);
  height: 18px;
  flex-grow: 1;
  border-radius: 8px;
  color: white;
  font-size: 14px;
  font-weight: 400;

  margin: 10px 0;
  padding: 10px 5px;
  max-height: 80px;

  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  resize: none;
`;

const SendBtn = styled.button`
  border: none;
  padding: 0;

  background-color: transparent;
  width: 36px;
  height: 36px;

  margin-bottom: 10px;

  ${button_on_pressed}
`;

const Cursor = styled.div`
  background-color: white;
  width: 10px;
  height: 4px;
  border-radius: 2px;
  position: absolute;
  bottom: 0;
  transform: translateX(-50%);
`;

const ActionHolder = styled.div`
  width: 100%;
  min-height: 200px;
`;

function Record() {
  return <ActionHolder></ActionHolder>;
}

function StickerPicker(props: {
  onChoose: (sticker: Sticker) => void;
  backend: Backend;
}) {
  const swiperRef = useRef<SwiperRef>(null);
  const [index, setIndex] = useState(0);

  return (
    <ActionHolder>
      <Swiper
        loop={false}
        ref={swiperRef}
        initialSlide={index}
        onSlideChange={(swiper: SwiperClass) => {
          setIndex(swiper.activeIndex);
        }}
      >
        <SwiperSlide>
          <StickerPanel
            key={`sticker-panel-${StickerType.Customized}`}
            type={StickerType.Customized}
            onChoose={props.onChoose}
            style={{ padding: 0, maxHeight: "224px" }}
            backend={props.backend}
          />
        </SwiperSlide>
        <SwiperSlide>
          <StickerPanel
            key={`sticker-panel-${StickerType.Static}`}
            type={StickerType.Static}
            onChoose={props.onChoose}
            style={{ padding: 0, maxHeight: "224px" }}
            backend={props.backend}
          />
        </SwiperSlide>
        <SwiperSlide>
          <StickerPanel
            key={`sticker-panel-${StickerType.Animation}`}
            type={StickerType.Animation}
            onChoose={props.onChoose}
            style={{ padding: 0, maxHeight: "224px" }}
            backend={props.backend}
          />
        </SwiperSlide>
      </Swiper>
      <div
        style={{
          width: "100%",
          height: "76px",
          background: "#09101d",
          display: "flex",
          ...FullPageWidthCell,
        }}
      >
        <SelectedReactionType
          onClick={() => {
            if (index !== 0) {
              setIndex(0);
              swiperRef.current?.swiper.slideTo(0);
            }
          }}
          hasBeenSelected={index === 0}
        >
          <img width={24} height={24} src={icCustomizedEmoji} />
        </SelectedReactionType>

        <SelectedReactionType
          onClick={() => {
            if (index !== 1) {
              setIndex(1);
              swiperRef.current?.swiper.slideTo(1);
            }
          }}
          hasBeenSelected={index === 1}
        >
          <img width={24} height={24} src={icStaticEmoji} />
        </SelectedReactionType>

        <SelectedReactionType
          onClick={() => {
            if (index !== 2) {
              setIndex(2);
              swiperRef.current?.swiper.slideTo(2);
            }
          }}
          hasBeenSelected={index === 2}
        >
          <img width={30} height={30} src={icAnimatedEmoji} />
        </SelectedReactionType>
      </div>
    </ActionHolder>
  );
}

function ActivityPicker() {
  return <ActionHolder></ActionHolder>;
}

function MorePicker() {
  return <ActionHolder></ActionHolder>;
}

export default function ChatInputBar(props: {
  onSend: (text: string) => void;
  onSticker: (sticker: Sticker) => void;
  onDice: (dice: Dice) => void;
}) {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const backend = useBackend();

  useEffect(() => {
    const inputHandler = (e: Event) => {
      if (inputRef.current) {
        inputRef.current.style.height = "18px";
        inputRef.current.style.height =
          (e.target as HTMLTextAreaElement).scrollHeight - 20 + "px";
      }
    };

    window.addEventListener("input", inputHandler);
    return () => {
      window.removeEventListener("input", inputHandler);
    };
  }, [inputRef]);

  const [zone] = useObscuredZones();
  const [selectedAction, setSelectedAction] = useState<number | undefined>();
  const voiceClicked = () => {
    setSelectedAction(0);
  };
  const mediaClicked = () => {
    setSelectedAction(undefined);
  };
  const stickerClicked = () => {
    setSelectedAction(2);
  };
  const activityClicked = () => {
    setSelectedAction(3);
  };
  const diceClicked = () => {
    setSelectedAction(4);
  };
  const moreClicked = () => {
    setSelectedAction(5);
  };

  const [inputText, setInputText] = useState<string>("");

  return (
    <VStack
      style={{
        width: "100%",
        padding: "0 var(--page-h-inset)",
        backgroundColor: "rgba(30, 23, 47, 1)",
      }}
    >
      <HStack style={{ width: "100%", gap: "12px", alignItems: "end" }}>
        <MsgInput
          ref={inputRef}
          rows={5}
          onInput={(e) => {
            setInputText((e.target as HTMLTextAreaElement).value);
          }}
        ></MsgInput>
        <SendBtn
          style={{
            backgroundImage: `url(${inputText.length > 0 ? icSendEnable : icSendDisable})`,
          }}
          onClick={() => {
            props.onSend(inputText);
            (inputRef.current as HTMLTextAreaElement).value = "";
          }}
        />
      </HStack>
      <HStack style={{ gap: "20px", paddingBottom: "10px" }}>
        <Image src={icVoice} onClick={voiceClicked} />
        <Image src={icMedia} onClick={mediaClicked} />
        <Image src={icSticker} onClick={stickerClicked} />
        <Image src={icActivity} onClick={activityClicked} />
        <Image src={icDice} onClick={diceClicked} />
        <Image src={icMore} onClick={moreClicked} />
        {selectedAction !== undefined && (
          <Cursor style={{ left: `${15 + 50 * selectedAction}px` }}></Cursor>
        )}
      </HStack>
      <div style={{ maxHeight: "300px" }}>
        {selectedAction === 0 && <Record></Record>}
        {selectedAction === 2 && (
          <StickerPicker
            backend={backend}
            onChoose={(sticker) => {
              props.onSticker(sticker);
            }}
          />
        )}
        {selectedAction === 3 && <ActivityPicker />}
        {selectedAction === 4 && (
          <DicePicker onSelectDice={(dice) => props.onDice(dice)} />
        )}
        {selectedAction === 5 && <MorePicker />}
      </div>
    </VStack>
  );
}
