import ChatThreadIcon from "./ChatThreadIcon";
import { HStack, VStack } from "../../../components/VStack";
import { Chat, getChatDisplayName } from "../../../proto/Chat";
import { useMyUid } from "../../../service/AuthSessionService";
import { getUserActiveInfo, isUserActiveInOneDay } from "../../../proto/User";
import { ChatThreadType } from "../../../proto/ChatThreadType";
import { useI18n } from "../../../hooks/useI18n";
import { UserIconView } from "../../../components/views/UserAvatarView";
import { RegularSmallButton } from "../../../components/Buttons";
import { FollowStatus } from "../../../proto/UserEnums";

export default function ChatNavBar(props: { chat: Chat }) {
  const myUid = useMyUid();
  const i18n = useI18n();
  if (
    props.chat.type === ChatThreadType.public ||
    props.chat.type === ChatThreadType.group
  ) {
    const host = props.chat.host;
    return (
      <HStack style={{ width: "100%", gap: 10 }}>
        <div style={{ width: 44 }}>
          <ChatThreadIcon chat={props.chat} />
        </div>
        <VStack>
          <div
            style={{
              fontSize: 14,
              fontWeight: "bold",
            }}
          >
            {getChatDisplayName(props.chat, myUid)}
          </div>
          <HStack
            style={{
              gap: "5px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <UserIconView user={props.chat.host} iconSize={18} />
            <div style={{ fontSize: 12 }}>{host?.nickname}</div>
            {host?.followedByMeStatusV2 === FollowStatus.notFollowed && (
              <RegularSmallButton
                $baseColor={"#00E6FF"}
                style={{
                  minHeight: 17,
                  fontSize: 8,
                  color: "#00E6FF",
                  borderRadius: 2,
                  padding: "0 5px",
                }}
              >
                {i18n.follow()}
              </RegularSmallButton>
            )}
          </HStack>
        </VStack>
      </HStack>
    );
  } else if (
    props.chat.type === ChatThreadType.chatAi ||
    props.chat.type === ChatThreadType.character
  ) {
    return (
      <HStack style={{ width: "100%", gap: 10 }}>
        <div style={{ width: 44 }}>
          <ChatThreadIcon chat={props.chat} />
        </div>
        <VStack>
          <div
            style={{
              fontSize: 20,
              fontWeight: "bold",
            }}
          >
            {getChatDisplayName(props.chat, myUid)}
          </div>
        </VStack>
      </HStack>
    );
  } else {
    const other = props.chat.membersSummary?.find((user) => user.uid !== myUid);
    const activeInfo = getUserActiveInfo(i18n, other);
    const recentActive = isUserActiveInOneDay(other);
    return (
      <HStack style={{ width: "100%", gap: 10 }}>
        <div style={{ width: 44 }}>
          <ChatThreadIcon chat={props.chat} />
        </div>
        <VStack>
          <div
            style={{
              fontSize: 20,
              fontWeight: "bold",
            }}
          >
            {getChatDisplayName(props.chat, myUid)}
          </div>
          {activeInfo && (
            <div
              style={{
                fontSize: 12,
                color: recentActive ? "#0DFFAF" : "#18946B",
              }}
            >
              {}
            </div>
          )}
        </VStack>
      </HStack>
    );
  }
}
