import { Reaction, Sticker } from "../../proto/Reaction";
import { HStack, HStackMixin } from "../../components/VStack";
import iconAddReaction from "../../res/images/icon_add_reaction.svg";
import styled from "styled-components";
import React, { useEffect, useMemo, useState } from "react";
import { useNativePage } from "../../hooks/useBridge";
import { useHopper } from "../../hooks/useHopper";
import iconRedHeart from "../../res/images/icon_red_heart.png";
import { getBestRes } from "../../proto/Media";
import iconHeart from "../../res/images/ic_heart.svg";
import { andLog, useErrorHandler } from "../../components/handleError";
import { useBackend } from "../../service/APIService";
import { StickerPicker } from "./StickerPicker";
import { ModalController, useModal } from "../../components/Modal";
import iconComment from "../../res/images/ic_comment.svg";
import { QuickComment } from "../post/QuickComment";
import { useMemoryRepoMap } from "../../hooks/swr/useLocalRepo";
import { zBigInt } from "../../utils/zodUtils";
import { z } from "zod";
import { BareComment } from "../../proto/Comment";
import { useReactionRecord } from "../../hooks/useReactionRecord";

export function ReactionListView(props: {
  reactions?: Reaction[];
  objectId: bigint;
  objectType: number;
  enable?: boolean;
  isSingleLine?: boolean;
  showCommentButton?: boolean;
  disableFullWidth?: boolean;
  modal?: ModalController;
  quickComment?: () => void;
}) {
  const likeReaction = props.reactions?.find(
    (r) => r.stickerId === Like_STICKER_ID,
  ) || {
    stickerId: Like_STICKER_ID,
    reacted: false,
    count: 0,
    createdTime: 0,
  };
  const backend = useBackend();
  const defaultModal = useModal("sticker-picker");
  const modal = props.modal ? props.modal : defaultModal;
  const hopper = useHopper();
  const handleError = useErrorHandler();

  const reactionRepo = useReactionRecord();

  const reactionList = [...(reactionRepo.map?.get(props.objectId) || [])]?.sort(
    (a, b) => b.createdTime - a.createdTime,
  );

  async function handleAddReaction(sticker: Sticker) {
    const existReaction = reactionList?.find(
      (r) => r.stickerId === sticker.stickerId,
    );
    const newList = [...(reactionList || [])];
    if (existReaction) {
      const index = newList?.indexOf(existReaction) ?? 0;
      if (!existReaction.reacted) {
        newList?.splice(index, 1, {
          sticker: sticker,
          count: existReaction.count + 1,
          reacted: true,
          stickerId: sticker.stickerId,
          createdTime: existReaction.createdTime,
        });
      } else {
        return;
      }
    } else {
      newList?.splice(0, 0, {
        sticker: sticker,
        count: 1,
        reacted: true,
        stickerId: sticker.stickerId,
        createdTime: Number.MAX_VALUE,
      });
    }
    reactionRepo.setValue(props.objectId, newList).catch(andLog);
    try {
      await backend
        .addReaction(props.objectId, props.objectType, sticker.stickerId)
        .run();
    } catch (e) {
      const recoverList = [...(reactionList || [])];
      if (existReaction) {
        const index =
          recoverList?.findIndex(
            (reaction) => reaction.stickerId === sticker.stickerId,
          ) ?? 0;
        if (!existReaction.reacted) {
          recoverList?.splice(index, 1, {
            sticker: sticker,
            count: existReaction.count - 1,
            reacted: false,
            stickerId: sticker.stickerId,
            createdTime: existReaction.createdTime,
          });
        }
      } else {
        recoverList?.splice(0, 1);
      }
      handleError(e);
    }
  }

  function removeFromReactionList(stickerId: bigint) {
    if (reactionList) {
      const index = reactionList?.findIndex((r) => r.stickerId === stickerId);
      const newList = [...reactionList];
      newList.splice(index, 1);
      reactionRepo.setValue(props.objectId, newList).catch(andLog);
    }
  }

  const initLikeReaction: Reaction = {
    stickerId: Like_STICKER_ID,
    reacted: false,
    count: 0,
    createdTime: 0,
    sticker: undefined,
  };

  async function onItemClick(stickerId: bigint) {
    const newList = [...(reactionList || [])];
    const index = newList.findIndex((r) => {
      return r.stickerId === stickerId;
    });
    if (index < 0 && stickerId !== Like_STICKER_ID) return;
    const currentRecord =
      reactionList?.find((r) => r.stickerId === stickerId) || initLikeReaction;
    if (!currentRecord) return;
    const originalReacted = currentRecord?.reacted;
    try {
      if (originalReacted) {
        if (currentRecord.count - 1 === 0) {
          removeFromReactionList(stickerId);
        } else {
          await minusReaction(newList, currentRecord, index);
        }
        await backend
          .deleteReaction(props.objectId, props.objectType, stickerId)
          .run();
      } else {
        await plusReaction(newList, currentRecord, index);
        await backend
          .addReaction(props.objectId, props.objectType, stickerId)
          .run();
      }
    } catch (e) {
      if (originalReacted) {
        plusReaction(newList, currentRecord, index).catch(andLog);
      } else {
        minusReaction(newList, currentRecord, index).catch(andLog);
      }
      handleError(e);
    }
  }

  async function plusReaction(
    newList: Reaction[],
    currentRecord: Reaction,
    index: number,
  ) {
    newList.splice(
      index,
      1,
      Object.assign({}, currentRecord, {
        reacted: true,
        count: currentRecord.count + 1,
      }),
    );
    await reactionRepo.setValue(props.objectId, newList);
  }

  async function minusReaction(
    newList: Reaction[],
    currentRecord: Reaction,
    index: number,
  ) {
    newList.splice(
      index,
      1,
      Object.assign({}, currentRecord, {
        reacted: false,
        count: currentRecord.count - 1,
      }),
    );
    await reactionRepo.setValue(props.objectId, newList);
  }

  return (
    <HStack
      style={{
        width: props.disableFullWidth ? undefined : "100%",
        flexWrap: "wrap",
        gap: "5px",
        minHeight: 24,
        padding: "8px 0",
        boxSizing: "border-box",
      }}
    >
      <ReactionItemView
        key={Like_STICKER_ID}
        reaction={likeReaction}
        remove={(stickerId) => removeFromReactionList(stickerId)}
        objectId={props.objectId}
        objectType={props.objectType}
        enable={props.enable}
        onItemClick={onItemClick}
      />
      {reactionList
        ?.filter((r) => r.stickerId !== Like_STICKER_ID)
        .map((e) => (
          <ReactionItemView
            key={e.stickerId}
            reaction={e}
            remove={(stickerId) => removeFromReactionList(stickerId)}
            objectId={props.objectId}
            objectType={props.objectType}
            enable={props.enable}
            onItemClick={onItemClick}
          />
        ))}
      <StickerContainer
        onClick={(event) => {
          event.stopPropagation();
          if (props.enable) {
            modal.open();
          } else {
            hopper.modal("nyi");
          }
        }}
      >
        <img width={20} height={20} src={iconAddReaction} />
      </StickerContainer>
      {props.showCommentButton && (
        <StickerContainer
          onClick={(event) => {
            event.stopPropagation();
            if (props.quickComment) {
              props.quickComment();
            }
          }}
        >
          <img width={20} height={20} src={iconComment} />
        </StickerContainer>
      )}

      <StickerPicker
        modal={modal}
        onChoose={handleAddReaction}
        backend={backend}
      />
    </HStack>
  );
}

const ReactionNumberLabel = styled.div`
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
`;

type ReactionItemProps = {
  reaction: Reaction;
  remove: (stickerId: bigint) => void;
  objectId: bigint;
  objectType: number;
  enable?: boolean;
  onItemClick: (stickerId: bigint) => void;
};

const Like_STICKER_ID = BigInt("65956773102028339");

export function ReactionItemView(props: ReactionItemProps) {
  const hopper = useHopper();
  const reactionRepo = useReactionRecord();

  const reactionList = reactionRepo.map.get(props.objectId);

  const currentRecord = reactionList?.find(
    (r) => r.stickerId === props.reaction.stickerId,
  );

  return (
    <StickerContainer
      key={props.reaction?.stickerId}
      onClick={(event) => {
        event.stopPropagation();
        if (props.enable) {
          props.onItemClick(props.reaction.stickerId);
        } else {
          hopper.modal("nyi");
        }
      }}
      style={{ backgroundColor: currentRecord?.reacted ? "white" : undefined }}
    >
      <ReactionImage
        width={16}
        height={16}
        src={
          props.reaction?.stickerId === Like_STICKER_ID
            ? currentRecord?.reacted
              ? iconRedHeart
              : iconHeart
            : props.reaction?.sticker?.media
              ? getBestRes(props.reaction?.sticker?.media)?.url
              : undefined
        }
      />
      {(currentRecord?.count || 0) > 0 && (
        <ReactionNumberLabel
          style={{ color: currentRecord?.reacted ? "black" : "white" }}
        >
          {currentRecord?.count}
        </ReactionNumberLabel>
      )}
    </StickerContainer>
  );
}

const StickerContainer = styled.div`
  ${HStackMixin};
  gap: 4px;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 20px;
  height: 24px;
  padding: 0 6px;
`;

const ReactionImage = styled.img`
  width: 16px;
  height: 16px;
`;
