import { TagObjectProps } from "./TagUserPage";
import { useHopper } from "../../hooks/useHopper";
import { useBackend } from "../../service/APIService";
import { useI18n } from "../../hooks/useI18n";
import { useSWRList } from "../../hooks/swr/useSWRList";
import { ObjectType } from "../../proto/ObjectSpec";
import { Chat } from "../../proto/Chat";
import { Page } from "../../components/Page";
import { tagPageBackground } from "./TagsPage";
import * as React from "react";
import { User } from "../../proto/User";
import { Circle } from "../../proto/Circle";
import { Post } from "../../proto/Post";
import { PostCell } from "../post/PostCell";
import { FullPageWidthCell } from "../../components/CommonStyles";
import UserHScrollItem from "../../components/UserHScrollItem";
import { HStack, VSpace, VStack } from "../../components/VStack";
import CircleGridCell from "../circle/CircleGridCell";
import GridLayout from "../../components/GridLayout";
import { PartyGridCell } from "./TagPartyPage";
import { SpeedTitle } from "../../components/SpeedTitle";
import icMore from "../../res/images/icon_more_gray.svg";
import { Image } from "../../components/Image";
import { UserName } from "../../components/views/UserNameView";
import { usePageParent } from "../../components/PageCompositionBoundary";
import { andLog } from "../../components/handleError";
import { useMemoryRepoMap } from "../../hooks/swr/useLocalRepo";
import { zBigInt } from "../../utils/zodUtils";
import { z } from "zod";
import { Reaction } from "../../proto/Reaction";
import { useReactionRecord } from "../../hooks/useReactionRecord";

export function TagDiscoverPage({ tagId, tagName }: TagObjectProps) {
  const hopper = useHopper();
  const backend = useBackend();
  const i18n = useI18n();

  const reactionRepo = useReactionRecord();

  const usersSWR = useSWRList(
    backend.tagObjects(ObjectType.USER, User, tagId, tagName),
  );
  const circlesSWR = useSWRList(
    backend.tagObjects(ObjectType.CIRCLE, Circle, tagId, tagName),
  );
  const chatsSWR = useSWRList(
    backend.tagObjects(ObjectType.CHAT, Chat, tagId, tagName),
  );
  const postsSWR = useSWRList(
    backend
      .tagObjects(ObjectType.BLOG, Post, tagId, tagName)
      .intercept((it) => {
        it.list.forEach((post) => {
          reactionRepo
            .setValue(post.blogId, post.reactionCountList)
            .catch(andLog);
        });
      }),
  );

  const pageParent = usePageParent();

  const displayUserCount = 20;

  {
    /*TODO:display filter check?*/
  }
  return (
    <Page
      underlay={tagPageBackground}
      pageData={[usersSWR, circlesSWR, chatsSWR, postsSWR]}
    >
      <VSpace height={20}></VSpace>
      {usersSWR.content && usersSWR.content.list.length > 0 && (
        <>
          <SpeedTitle>{i18n.users()}</SpeedTitle>
          <HStack
            style={{
              overflowX: "scroll",
              scrollbarWidth: "none" as "none",
              msOverflowStyle: "none" as "none",
              paddingInlineStart: 12,
              gap: 10,
              ...FullPageWidthCell,
              marginTop: 10,
              marginBottom: 20,
            }}
          >
            {usersSWR.content &&
              usersSWR.content.list.slice(0, displayUserCount).map((user) => (
                <UserHScrollItem
                  key={user.uid}
                  user={user}
                  onGoProfile={() => {
                    hopper.push(`user/${user.uid}`);
                  }}
                />
              ))}
            {usersSWR.content &&
              usersSWR.content.list.length > displayUserCount && (
                <UserMore
                  onClick={() => {
                    pageParent.setPrimarySubpageWithId(
                      ObjectType.USER,
                      "smooth",
                    );
                  }}
                ></UserMore>
              )}
          </HStack>
        </>
      )}

      {circlesSWR.content && circlesSWR.content.list.length > 0 && (
        <>
          <SpeedTitle>{i18n.circles()}</SpeedTitle>
          <HStack
            style={{
              overflowX: "scroll",
              scrollbarWidth: "none" as "none",
              msOverflowStyle: "none" as "none",
              paddingInlineStart: 12,
              gap: 10,
              ...FullPageWidthCell,
              marginTop: 10,
              marginBottom: 20,
            }}
          >
            {circlesSWR.content &&
              circlesSWR.content.list
                .slice(0, displayUserCount)
                .map((circle) => (
                  <div style={{ width: "20%" }}>
                    <CircleGridCell
                      circleId={circle.circleId}
                      circle={circle}
                      key={circle.circleId}
                      onClick={() => {
                        hopper.push(`circle/${circle.circleId}`);
                      }}
                    />
                  </div>
                ))}
            {circlesSWR.content &&
              circlesSWR.content.list.length > displayUserCount && (
                <CircleMore
                  onClick={() => {
                    pageParent.setPrimarySubpageWithId(
                      ObjectType.CIRCLE,
                      "smooth",
                    );
                  }}
                ></CircleMore>
              )}
          </HStack>
        </>
      )}

      {chatsSWR.content?.list.length > 0 && (
        <>
          <SpeedTitle>{i18n.parties()}</SpeedTitle>
          <GridLayout
            spanCount={2}
            items={chatsSWR.content.list.slice(0, 4).map((item, index) => {
              return <PartyGridCell chat={item} key={item.threadId} />;
            })}
          ></GridLayout>
        </>
      )}

      {postsSWR.content && postsSWR.content.list.length > 0 && (
        <>
          <SpeedTitle>{i18n.posts()}</SpeedTitle>
          {postsSWR.content.list.map((item) => (
            <PostCell post={item} key={item.blogId} />
          ))}
        </>
      )}
    </Page>
  );
}

interface UserMoreProps {
  onClick: () => void;
}

function UserMore({ onClick }: UserMoreProps) {
  const i18n = useI18n();
  return (
    <VStack
      style={{
        alignItems: "center",
      }}
      onClick={onClick}
    >
      <Image
        style={{
          width: 50,
          height: 50,
          borderRadius: 25,
          background: "var(--color-cell-divider)",
        }}
        src={icMore}
      ></Image>
      <UserName style={{ marginTop: 4 }}>{i18n.more()}</UserName>
    </VStack>
  );
}

function CircleMore({ onClick }: UserMoreProps) {
  const i18n = useI18n();
  return (
    <VStack
      style={{
        borderRadius: 8,
        background: "var(--color-cell-divider)",
        alignItems: "center",
        width: "20%",
        height: "106px",
        justifyContent: "center",
      }}
      onClick={onClick}
    >
      <Image src={icMore} width={40} height={40}></Image>
      <UserName style={{ marginTop: 4 }}>{i18n.more()}</UserName>
    </VStack>
  );
}
