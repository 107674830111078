import { Page } from "../../../components/Page";
import {
  useBigIntParam,
  useNumberSearchParam,
} from "../../../hooks/useTypedParam";
import { useSWR } from "../../../hooks/swr/useSWR";
import { useI18n } from "../../../hooks/useI18n";
import { ObjectType } from "../../../proto/ObjectSpec";
import CircleBackgroundFrame from "../CircleBackgroundFrame";
import React from "react";
import { useHopper } from "../../../hooks/useHopper";
import { useBackend } from "../../../service/APIService";
import { NavTitle } from "../../../components/NavBar";

function CircleBlockedObjectsPage() {
  const circleId = useBigIntParam("circleId");
  const backend = useBackend();
  const objectType = useNumberSearchParam("objectType");

  const pageTileStr = () => {
    if (objectType === ObjectType.CHAT) {
      return i18n.circle2_1_blocked_chats();
    } else if (objectType === ObjectType.BLOG) {
      return i18n.circle2_1_blocked_posts();
    }
    return i18n.blocked_members();
  };

  const circleSWR = useSWR(backend.getCircle(circleId));
  const i18n = useI18n();
  const hopper = useHopper();
  return (
    <Page
      pageData={circleSWR}
      underlay={
        <CircleBackgroundFrame
          circleBackground={circleSWR.content?.circleBackground}
        />
      }
    >
      <NavTitle>{pageTileStr()}</NavTitle>
    </Page>
  );
}

export default CircleBlockedObjectsPage;
