import { Page } from "../../components/Page";
import { DemoCell } from "./DemoCell";
import { useHopper } from "../../hooks/useHopper";
import { useLayerId } from "../../appshell/LayerBoundary";
import { useHopId } from "../../hooks/useHopState";
import { NavMiddle } from "../../components/NavBar";

export function HopperLayerDemoPage() {
  const hopper = useHopper();
  const layerId = useLayerId();
  const hopId = useHopId();
  return (
    <Page pageData={undefined}>
      <NavMiddle>My Title</NavMiddle>

      <DemoCell>{`Layer Id: ${layerId}`}</DemoCell>
      <DemoCell>{`Hop Id: ${hopId}`}</DemoCell>

      <DemoCell
        onClick={() => {
          hopper.push("hopper-layer-demo");
        }}
      >
        Push at the same layer
      </DemoCell>

      <DemoCell
        onClick={() => {
          hopper.dismissLayerAndPush("debug-home");
        }}
      >
        Close and Push
      </DemoCell>
    </Page>
  );
}
