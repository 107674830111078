import { z } from "zod";
import { zBigInt, zStatic } from "../utils/zodUtils";

export const ThirdPartyPurchaseResponse = z.object({
  link: z.string().optional(),
  orderId: zBigInt.optional(),
});

export type ThirdPartyPurchaseResponse = zStatic<
  typeof ThirdPartyPurchaseResponse
>;
