import {
  AssetOrder,
  AssetOrderStatus,
  TxCategory,
} from "../../../proto/AssetOrder";
import { useI18n } from "../../../hooks/useI18n";
import { HStack, Spring, VSpace, VStack } from "../../../components/VStack";
import { Image } from "../../../components/Image";
import {
  formatMoney,
  getCurrencyAmountFromString,
  getCurrencyIcon,
  getCurrencyIconByCurrencyType,
  getCurrencyName,
  getCurrencyNameByCurrencyType,
} from "../../../proto/Currency";
import { MainCurrencyAmount, MainCurrencyName } from "./TopUpOrderDetail";
import { StatusBar } from "./GiftOrderListPage";
import React from "react";
import { CellDivider, ContentGroup } from "../../../components/ContentGroup";
import { OrderInfoContent, OrderInfoLabel } from "./GiftOrderDetail";
import { formatDateWithOptions } from "../../../utils/DateTimeUtils";
import { getOrderFormattedDateTime } from "./CreateNewTokenDetail";
import { useHopper } from "../../../hooks/useHopper";

export function TransferAdminEarningsOrderDetail(props: {
  assetOrder: AssetOrder;
}) {
  const i18n = useI18n();
  const hopper = useHopper();
  function getStatus() {
    switch (props.assetOrder.orderStatus) {
      case AssetOrderStatus.Done:
        return { text: i18n.web3_v0_completed(), color: "#00FF94" };
      case AssetOrderStatus.Pending:
      case AssetOrderStatus.Submitted: {
        return { text: i18n.web3_v0_pending(), color: "#34A6FFFF" };
      }
      case AssetOrderStatus.Failed: {
        return { text: i18n.circle_anmt_failed(), color: "#EF3537FF" };
      }
      case AssetOrderStatus.Rejected: {
        return { text: i18n.circle_anmt_rejected(), color: "#EF3537FF" };
      }
      default:
        return { text: "", color: "#FFFFFF66" };
    }
  }

  const currency = props.assetOrder.txList?.find(
    (tx) => tx.txCategory === TxCategory.SENT,
  )?.currency;

  const serviceFee = props.assetOrder.gasFee;

  return (
    <VStack style={{ width: "100%", alignItems: "center" }}>
      <HStack style={{ gap: 7, marginTop: 47 }}>
        <Image
          src={getCurrencyIcon(currency)}
          style={{ width: 36, height: 36, borderRadius: 4 }}
        />
        <HStack style={{ gap: 7, alignItems: "baseline" }}>
          <MainCurrencyAmount>
            {formatMoney("long", currency)}
          </MainCurrencyAmount>
          <MainCurrencyName>{getCurrencyName(currency, i18n)}</MainCurrencyName>
        </HStack>
      </HStack>
      <VSpace height={8} />
      <StatusBar
        statusColor={getStatus().color}
        statusText={getStatus().text}
      />
      <ContentGroup style={{ width: "100%", gap: 20, marginTop: 32 }}>
        <HStack>
          <OrderInfoLabel>{i18n.web3_v0_service_fee()}</OrderInfoLabel>
          <Spring />
          <HStack style={{ gap: 4 }}>
            <Image
              src={getCurrencyIcon(serviceFee)}
              style={{ width: 18, height: 18 }}
            />
            <OrderInfoContent>
              {formatMoney("long", serviceFee)}
            </OrderInfoContent>
          </HStack>
        </HStack>
        <CellDivider />
        <HStack>
          <OrderInfoLabel>{i18n.circle()}</OrderInfoLabel>
          <Spring />
          <HStack
            style={{ gap: 4 }}
            onClick={() =>
              hopper.push(`circle/${props.assetOrder?.circle?.circleId}`)
            }
          >
            <Image
              src={[props.assetOrder.circle?.icon, { width: 18, height: 18 }]}
              style={{ width: 18, height: 18, borderRadius: 2 }}
            />
            <OrderInfoContent>{props.assetOrder.circle?.name}</OrderInfoContent>
          </HStack>
        </HStack>
        <CellDivider />
        <HStack>
          <OrderInfoLabel>{i18n.web3_v0_created_time()}</OrderInfoLabel>
          <Spring />
          <OrderInfoContent>
            {getOrderFormattedDateTime(props.assetOrder.createdTime || 0)}
          </OrderInfoContent>
        </HStack>
        <CellDivider />
        <HStack>
          <OrderInfoLabel>{i18n.web3_v0_transaction_order_id()}</OrderInfoLabel>
          <Spring />
          <OrderInfoContent>
            {props.assetOrder.orderId.toString()}
          </OrderInfoContent>
        </HStack>
      </ContentGroup>
    </VStack>
  );
}
