import { CircleBackground } from "../../proto/CircleBackground";
import { Image } from "../../components/Image";
import React from "react";
import styled from "styled-components";
// import { usePageSpec } from "../common/usePageSpec";
import { LocalMedia } from "../../bridge/LocalMedia";

export interface CircleBackgroundFrameProps {
  circleBackground?: CircleBackground | LocalMedia;
}

export const FrameContainer = styled.div`
  position: relative;
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); // 黑色遮罩
`;

function CircleBackgroundFrame({
  circleBackground,
}: CircleBackgroundFrameProps) {
  // const pageSpec = usePageSpec();

  const getSrc = () => {
    if (circleBackground) {
      if ("backgroundImage" in circleBackground) {
        return circleBackground.backgroundImage;
      } else {
        return circleBackground;
      }
    }

    return undefined;
  };

  return (
    <FrameContainer
      style={{
        height: "100%",
      }}
    >
      <Image
        src={[getSrc(), "best"]}
        style={{ width: "100%", height: "100%" }}
      />
      <Overlay />
    </FrameContainer>
  );
}

export default CircleBackgroundFrame;
