import { Page } from "../../../components/Page";
import styled from "styled-components";
import { useI18n } from "../../../hooks/useI18n";
import { CircleInfoMaxLength } from "../../circle/compose/EditCirclePage";
import { RegularInputGroup } from "../../../components/Input";
import React, { useMemo } from "react";
import { useEditingBigNumber } from "../../../hooks/useEditingBigNumber";
import iconGEMS from "../../../res/images/icon_gems.svg";
import { HStack } from "../../../components/VStack";
import {
  alpha_on_pressed,
  RowCenterButton,
} from "../../../components/CommonStyles";
import downIcon from "../../../res/images/chevorn_down.svg";
import { RegularButton } from "../../../components/Buttons";
import { PageFooter } from "../../../components/PageHeaderFooter";
import { useWalletSWR } from "../useWallet";
import {
  CurrencyType,
  formatMoney,
  getCurrencyAmount,
} from "../../../proto/Currency";
import { useHopper } from "../../../hooks/useHopper";
import { NavMiddle } from "../../../components/NavBar";
import { useTransactionBody } from "./SendTokenPage";
import { andLog } from "../../../components/handleError";
import { jsonifyBigNumber } from "../../../utils/CurrencyAmount";
import BigNumber from "bignumber.js";
import { useStringSearchParam } from "../../../hooks/useTypedParam";
import { useNativePage } from "../../../hooks/useBridge";

function ShopTransferPage() {
  const i18n = useI18n();
  const [wallet, walletSWR] = useWalletSWR();
  const [amountInput, setAmountInput] = useEditingBigNumber(`transfer-shop}`);
  const hooper = useHopper();
  const nativePage = useNativePage();
  const editingBody = useTransactionBody();
  const targetCurrency = useMemo(() => {
    return wallet?.storeAccount?.currencyList?.find(
      (cur) => cur.currencyType === CurrencyType.GEMS,
    )?.availableCurrency;
  }, [wallet]);
  const next = async () => {
    if (amountInput === "0") {
      nativePage
        .infoHud(i18n.web3_v0_impr2_number_bigger_than_zero())
        .catch(andLog);
      return;
    }
    if (!amountInput || amountInput.length === 0) {
      nativePage.infoHud(i18n.web3_v0_fill_all_fields()).catch(andLog);
      return;
    }
    const serverAmount = new BigNumber(
      jsonifyBigNumber(
        BigNumber(amountInput ?? "0"),
        targetCurrency?.decimals || 9,
      ),
    );
    if (
      serverAmount.isGreaterThan(
        BigNumber(
          wallet?.storeAccount?.currencyList?.find(
            (cur) => cur.currencyType === CurrencyType.GEMS,
          )?.availableCurrency?.amount || 0,
        ),
      )
    ) {
      const gems = i18n.web3_v0_diamond();
      nativePage.infoHud(i18n.web3_v0_amount_too_large(gems)).catch(andLog);
      return;
    }
    await editingBody
      .fill((prevData) => ({
        ...prevData,
        currencyId: BigInt(2),
        currency: targetCurrency,
        amount: jsonifyBigNumber(BigNumber(amountInput ?? "0"), 18),
        currencyType: CurrencyType.GEMS,
        decimals: targetCurrency?.decimals ?? 18,
      }))
      .catch(andLog);

    hooper.push(`shop-transfer-summary`);
  };
  return (
    <Page pageData={walletSWR}>
      <NavMiddle>{i18n.transfer()}</NavMiddle>
      <Title>{i18n.web3_v0_amount()}</Title>
      <RegularInputGroup
        placeholder={i18n.web3_v0_amount()}
        value={amountInput}
        updateValue={setAmountInput}
        type={"number"}
        startReactNode={
          <HStack>
            <GemsIcon />
            <VLine />
          </HStack>
        }
        endReactNode={
          <Max
            onClick={() => {
              setAmountInput(
                String(
                  getCurrencyAmount(
                    wallet?.storeAccount?.currencyList?.find(
                      (cur) => cur.currencyType === CurrencyType.GEMS,
                    )?.availableCurrency,
                  ),
                ),
              );
            }}
          >
            {i18n.web3_v0_max()}
          </Max>
        }
      />
      <HStack style={{ marginTop: 6 }}>
        <AvailableTitle>{i18n.web3_v0_available()}</AvailableTitle>
        <GemsIcon
          style={{ width: 16, height: 16, margin: "auto 5px auto 10px" }}
        />
        <AvailableAmount>
          {formatMoney(
            "medium",
            wallet?.storeAccount?.currencyList?.find(
              (cur) => cur.currencyType === CurrencyType.GEMS,
            )?.availableCurrency,
          )}
        </AvailableAmount>
      </HStack>

      <Title style={{ marginTop: 32 }}>
        {i18n.march_impr_2023_destination_account()}
      </Title>
      <AccountFrame>
        <AccountName>{i18n.arthur_wallet_built_in_account()}</AccountName>
        <DownIcon />
      </AccountFrame>

      <PageFooter obscuredZoneKey={"SendNext"}>
        <RegularButton
          $baseBgColor={"var(--color-bg)"}
          onClick={next}
          style={{
            ...RowCenterButton,
            marginTop: 8,
          }}
        >
          {i18n.next()}
        </RegularButton>
      </PageFooter>
    </Page>
  );
}

const DownIcon = styled.div`
  width: 26px;
  height: 26px;
  margin: auto 8px;
  background-image: url("${downIcon}");
`;

const AccountName = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  margin: 14px 12px;
`;

const AccountFrame = styled.div`
  width: 100%;
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  justify-content: space-between;
`;

const AvailableAmount = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: #ffffff;
`;

const AvailableTitle = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.4);
`;

export const GemsIcon = styled.div`
  width: 24px;
  height: 24px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: url("${iconGEMS}");
  margin: auto 8px;
`;

const VLine = styled.div`
  height: 13px;
  width: 0.5px;
  margin: auto 0;
  background: rgba(255, 255, 255, 0.2);
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
`;

const Max = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: #00c4ff;
  padding: 15px 12px;
  ${alpha_on_pressed};
`;

export default ShopTransferPage;
