import { z } from "zod";
import { zBigInt, zStatic } from "../utils/zodUtils";
import { Media } from "./Media";
import { NFT } from "./NFT";

export const MerchItem = z.object({
  objectId: zBigInt,
  objectType: z.number(),
  amount: z.string().default("0"),
  nft: NFT.optional(),
});

export type MerchItem = zStatic<typeof MerchItem>;
