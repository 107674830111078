import { HStack, VSpace, VStack } from "../../../components/VStack";
import { Image } from "../../../components/Image";
import {
  formatMoney,
  getCurrencyIcon,
  getCurrencyIconByCurrencyType,
  getCurrencyName,
  getCurrencyNameByCurrencyType,
} from "../../../proto/Currency";
import { ButtonColor, RegularButton } from "../../../components/Buttons";
import { PageFooter } from "../../../components/PageHeaderFooter";
import React, { useMemo } from "react";
import styled from "styled-components";
import { TransactionBody } from "./TransactionBody";
import { LocalRepoResult } from "../../../hooks/swr/useLocalRepo";
import { useI18n } from "../../../hooks/useI18n";
import { ModalController } from "../../../components/Modal";
import { useTransactionBody } from "../send/SendTokenPage";
import BigNumber from "bignumber.js";
import { copyObject } from "../../../utils/copyObject";

export interface PaymentFooterProps {
  editingBody: ReturnType<typeof useTransactionBody>;
  nextButtonText?: string;
  paymentModal: ModalController;
  paymentCheck?: () => Promise<boolean>;
}

function PaymentFooter(props: PaymentFooterProps) {
  const i18n = useI18n();
  const editingBody = props.editingBody;
  const paymentModal = props.paymentModal;

  const next = async () => {
    const shouldOpenModal = props.paymentCheck
      ? await props.paymentCheck()
      : true;
    if (shouldOpenModal) paymentModal.open();
  };

  const paymentAmount = editingBody.content.amount ?? "0";
  const paymentCurrency = useMemo(() => {
    if (editingBody.content.isFree) {
      return copyObject(editingBody.content.currency, {
        amount: "0",
      });
    } else {
      return BigNumber(paymentAmount).isGreaterThan(BigNumber("0"))
        ? copyObject(editingBody.content.currency, {
            amount: paymentAmount,
          })
        : undefined;
    }
  }, [editingBody]);

  const shouldMergeCurrency = useMemo(() => {
    const { CFM135B, gasFeeCurrency, currencyType, totalPrice } =
      editingBody.content;

    const isSameCurrencyType =
      gasFeeCurrency?.currencyType &&
      gasFeeCurrency.currencyType === currencyType;

    if (CFM135B) {
      return isSameCurrencyType;
    }

    const hasValidTotalPrice = BigNumber(totalPrice ?? "0").isGreaterThan(
      BigNumber("0"),
    );

    return hasValidTotalPrice || isSameCurrencyType;
  }, [editingBody.content]);

  const gasAmount = editingBody.content.gasFeeCurrency?.amount ?? "0";
  const amount = BigNumber(paymentAmount).plus(BigNumber(gasAmount));
  const mergedCurrency =
    editingBody.content.payWithGems ??
    copyObject(editingBody.content.currency, {
      amount: editingBody.content.totalPrice ?? amount?.toFixed(0),
    });

  return (
    <PageFooter obscuredZoneKey={"SendNext"}>
      <VSpace height={80} />
      <BottomOverlay />
      <HStack
        style={{
          justifyContent: "space-between",
          paddingInlineStart: 20,
          paddingInlineEnd: 20,
        }}
      >
        {shouldMergeCurrency && mergedCurrency ? (
          <VStack style={{ gap: 4 }}>
            <HStack style={{ gap: 4 }}>
              <Image
                src={getCurrencyIcon(mergedCurrency)}
                style={{ borderRadius: 4 }}
                width={24}
                height={24}
              />
              <CostCount>{formatMoney("medium", mergedCurrency)}</CostCount>
              <CostCurrencyName>
                {getCurrencyName(mergedCurrency, i18n)}
              </CostCurrencyName>
            </HStack>

            <TotalDueLabel>{i18n.web3_v0_total_due()}</TotalDueLabel>
          </VStack>
        ) : (
          <VStack style={{ gap: 4 }}>
            {paymentCurrency && (
              <HStack style={{ gap: 4 }}>
                <Image
                  src={getCurrencyIcon(paymentCurrency)}
                  style={{ borderRadius: 4 }}
                  width={24}
                  height={24}
                />
                <CostCount>{formatMoney("medium", paymentCurrency)}</CostCount>
                <CostCurrencyName>
                  {getCurrencyName(paymentCurrency, i18n)}
                </CostCurrencyName>
              </HStack>
            )}

            {editingBody.content.gasFeeCurrency && (
              <HStack style={{ gap: 4 }}>
                <Image
                  src={getCurrencyIcon(editingBody.content.gasFeeCurrency)}
                  style={{ borderRadius: 4 }}
                  width={24}
                  height={24}
                />
                <CostCount>
                  {formatMoney("medium", editingBody.content.gasFeeCurrency)}
                </CostCount>
                <CostCurrencyName>
                  {getCurrencyName(editingBody.content.gasFeeCurrency, i18n)}
                </CostCurrencyName>
              </HStack>
            )}

            <TotalDueLabel>{i18n.web3_v0_total_due()}</TotalDueLabel>
          </VStack>
        )}

        <RegularButton
          style={{ minWidth: 100 }}
          onClick={next}
          $baseColor={ButtonColor.greenish}
          $baseBgColor={"var(--color-bg)"}
        >
          {props.nextButtonText ?? i18n.send()}
        </RegularButton>
      </HStack>
    </PageFooter>
  );
}

const BottomOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgb(0, 0, 0)
  ); /* 顶部黑色到底部透明的渐变 */
  pointer-events: none; /* 让渐变层不阻挡点击事件 */
`;

const TotalDueLabel = styled.div`
  color: var(--color-text20);
  font-weight: 400;
  font-size: 12px;
`;

const CostCount = styled.div`
  color: var(--color-text00);
  font-weight: 500;
  font-size: 18px;
`;

const CostCurrencyName = styled.div`
  color: rgba(255, 255, 255, 0.6);
  font-weight: 400;
  font-size: 14px;
`;

export default PaymentFooter;
