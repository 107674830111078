import { useSWR } from "../../hooks/swr/useSWR";
import {
  useBigIntParam,
  useOptionalBigIntParam,
} from "../../hooks/useTypedParam";
import { useBackend } from "../../service/APIService";
import { useSWRArray } from "../../hooks/swr/useSWRArray";
import { CircleGridType } from "./CircleGridPage";

export function useCircleSWR() {
  const circleId = useBigIntParam("circleId");
  const backend = useBackend();
  const circleSWR = useSWR(backend.getCircle(circleId));
  return [circleId, circleSWR] as const;
}

export function useOptionalCircleSWR() {
  const circleId = useOptionalBigIntParam("circleId");
  const backend = useBackend();
  const circleSWR = useSWR(circleId ? backend.getCircle(circleId) : undefined);
  return [circleId, circleSWR] as const;
}

export function useCirclesSWR(type: CircleGridType) {
  const backend = useBackend();
  return useSWRArray(backend.getCircles(type));
}

export function useTokenSWR(circleId: bigint) {
  const backend = useBackend();
  return useSWR(backend.getCircleToken(circleId));
}
