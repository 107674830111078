import { Page } from "../../../components/Page";
import { useBigIntParam } from "../../../hooks/useTypedParam";
import { useBackend } from "../../../service/APIService";
import { useI18n } from "../../../hooks/useI18n";
import { useHopper } from "../../../hooks/useHopper";
import { useSWR } from "../../../hooks/swr/useSWR";
import { NFTDisplayView } from "../components/NFTDisplayView";
import { NFTDisplayInfoView } from "../components/NFTDisplayInfoView";
import { NavEnd, NavItem } from "../../../components/NavBar";
import { NavButtonType } from "../../../bridge/NavButtonDesc";
import { BSMenu, BSMenuItem } from "../../../components/BSMenu";
import { useModal } from "../../../components/Modal";
import { useGlobalSpinner } from "../../../utils/globalSpinner";
import { useNativePage } from "../../../hooks/useBridge";
import { useErrorHandler } from "../../../components/handleError";
import { copyTextToClipboard } from "../../../utils/CopyText";

export function NFTDisplayPage() {
  const nftId = useBigIntParam("nftId");
  const backend = useBackend();
  const i18n = useI18n();
  const hopper = useHopper();
  const globalSpinner = useGlobalSpinner();
  const moreModal = useModal("nft-more-modal");
  const nativePage = useNativePage();
  const handleError = useErrorHandler();

  const nftInfo = useSWR(backend.getNFT(nftId));

  const onClickCopyLink = async () => {
    try {
      await globalSpinner(async () => {
        const link = await backend
          .getShareLink(`nft-circle-card/${nftId}`)
          .run();
        if (link.shareLink) {
          await copyTextToClipboard(link.shareLink);
        }
      });
      await nativePage.successHud(i18n.copied());
    } catch (e) {
      handleError(e);
    }
  };

  return (
    <Page pageData={nftInfo} safeTopDisabled={true}>
      <NavEnd>
        {NavItem.image(NavButtonType.More, () => moreModal.open())}
      </NavEnd>
      {nftInfo.content && <NFTDisplayView nft={nftInfo.content} />}
      {nftInfo.content && (
        <NFTDisplayInfoView nft={nftInfo.content} circle={undefined} />
      )}

      <BSMenu modal={moreModal}>
        <BSMenuItem
          title={i18n.info()}
          onClick={() => hopper.push(`nft/${nftId}/info`)}
        />
        <BSMenuItem title={i18n.share_copy_link()} onClick={onClickCopyLink} />
      </BSMenu>
    </Page>
  );
}
