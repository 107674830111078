import { Page } from "../../../components/Page";
import { useI18n } from "../../../hooks/useI18n";
import SettingCell from "../../../components/SettingCell";
import { useSWR } from "../../../hooks/swr/useSWR";
import TabCellSeparator from "../../../components/TabCellSeparator";
import { HStack, Spring, VStack } from "../../../components/VStack";
import { ButtonColor, WeakButton } from "../../../components/Buttons";
import React, { useEffect } from "react";
import { Spacing } from "../../../components/Spacing";
import CircleBackgroundFrame from "../CircleBackgroundFrame";
import { useHopper } from "../../../hooks/useHopper";
import { useBackend } from "../../../service/APIService";
import { AlertButton, WhiteAlert } from "../../../components/WhiteAlert";
import { useModal } from "../../../components/Modal";
import { useLoadState } from "../../../hooks/LoadState";
import { andLog, useErrorHandler } from "../../../components/handleError";
import { useCircleHasAdminReminder } from "../compose/CreateCirclePage";
import styled, { css } from "styled-components";
import { Image } from "../../../components/Image";
import { isCircleAdmin, isCircleAdminOrCoAdmin } from "../../../proto/Circle";
import { useMyUid } from "../../../service/AuthSessionService";
import { useNativePage } from "../../../hooks/useBridge";
import { NavMiddle } from "../../../components/NavBar";
import { useCircleSWR } from "../useCircleSWR";
import { ContentGroup } from "../../../components/ContentGroup";
import chevron from "../../../res/images/icon_section_header_show_more.svg";
import { SingleLineUnspecifiedWidth } from "../../../components/CommonViews";
import { isValidCircleToken } from "../../../proto/CircleFin";
import CircleCardsPreviewCell, {
  CardStyle,
} from "../card/CircleCardsPreviewCell";
import circleCard from "../../../res/images/circle_card.svg";
import { cell_on_pressed } from "../../../components/CommonStyles";

function CircleAdminPortalPage() {
  const backend = useBackend();
  const deleteAlertModal = useModal("deleteAlertModal");
  const [circleId, circleSWR] = useCircleSWR();
  const i18n = useI18n();
  const hopper = useHopper();

  const deleteCircleTask = useLoadState();
  const handleError = useErrorHandler();

  const myUidStr = useMyUid().toString();

  async function deleteCircle() {
    const r = await deleteCircleTask.run(async () => {
      const circle = await backend.deleteCircle(circleId).run();
    });

    deleteAlertModal.close();
    if (!r.success) {
      handleError(r.error);
    } else {
      hopper.back();
    }
  }

  const circleHasAdminReminder = useCircleHasAdminReminder(circleId);

  useEffect(() => {
    circleHasAdminReminder
      .fill(() => {
        return false;
      })
      .catch(andLog);
  }, []);

  const tokenSWR = useSWR(backend.getCircleToken(circleId));

  const myUid = useMyUid();
  const nativePage = useNativePage();
  const circleTokenAndMembershipOnClick = () => {
    if (!circleSWR.content) return;
    if (!isCircleAdminOrCoAdmin(circleSWR.content, myUid)) {
      return;
    }
    if (circleSWR.content.tokenProjectId === undefined) {
      if ((circleSWR.content.coAdminIdList ?? []).indexOf(myUid) !== -1) {
        nativePage
          .alertNotice(
            i18n.clover_only_circle_admin_can_activate_membership_levels(),
            i18n.ok(),
          )
          .catch(andLog);
        return;
      }
    }
    hopper.push(`circle/${circleId}/token-overview`);
  };

  const cardsSWR = useSWR(backend.getCircleCardsList(circleId));

  return (
    <Page
      pageData={[circleSWR, tokenSWR]}
      scrollPaddingDisabled={true}
      underlay={
        <CircleBackgroundFrame
          circleBackground={circleSWR.content?.circleBackground}
        />
      }
    >
      <NavMiddle>{i18n.jun1_text_admin_portal()}</NavMiddle>
      <HStack
        style={{
          margin: "20px var(--page-h-inset) 0",
          gap: 12,
          alignItems: "flex-start",
        }}
      >
        <Image
          src={[circleSWR.content?.circleIcon, 64]}
          style={{
            borderRadius: 12,
            border: `1px solid #54ECEB`,
          }}
          width={64}
          height={64}
        />
        <VStack
          mixin={css`
            gap: 8px;
            width: calc(100% - 64px - 12px);
          `}
        >
          <CircleName>{circleSWR.content?.name}</CircleName>
          <CircleID>
            {i18n.circle2_impr_zid(`c/${circleSWR.content?.socialId || "-"}`)}
          </CircleID>
        </VStack>
      </HStack>
      <ContentGroup
        mixin={css`
          margin: 20px var(--page-h-inset);
          padding: 8px 8px 20px;
          border-radius: 6px;
          background: rgba(255, 255, 255, 0.05);
          ${cell_on_pressed}
        `}
        onClick={circleTokenAndMembershipOnClick}
      >
        <>
          <HStack>
            <CircleTokenAndMembershipLabel>
              {i18n.clover_circle_token_and_membership()}
            </CircleTokenAndMembershipLabel>
            <Spring />
            <Image
              src={chevron}
              width={20}
              height={20}
              style={{ marginInlineStart: 6 }}
            />
          </HStack>
          {circleSWR.content?.tokenProjectId === undefined && (
            <HStack
              mixin={css`
                margin-top: 12px;
                height: 44px;
              `}
            >
              <Image
                alt={"circleCard"}
                src={circleCard}
                width={36}
                height={36}
                style={{ marginInlineStart: 6 }}
              />
              <ActivateMembershipLevelLabel>
                {i18n.clover_activate_membership_levels()}
              </ActivateMembershipLevelLabel>
            </HStack>
          )}
          {circleSWR.content?.tokenProjectId &&
            isValidCircleToken(tokenSWR.content) && (
              <HStack
                mixin={css`
                  margin-top: 12px;
                  height: 44px;
                  gap: 8px;
                `}
              >
                <Image
                  src={[tokenSWR.content.image, 36]}
                  style={{ borderRadius: 3 }}
                  width={36}
                  height={36}
                />
                <VStack style={{ gap: 4, height: 36 }}>
                  <TokenName>{tokenSWR.content.name}</TokenName>
                  <Ticker>{tokenSWR.content.ticker}</Ticker>
                </VStack>
                <Spring />
                <VStack style={{ alignItems: "end" }}>
                  <HStack>
                    {cardsSWR.content?.list.slice(0, 3).map((item, index) => (
                      <CircleCardsPreviewCell
                        rootStyle={{
                          marginInlineStart: index === 0 ? "0" : "-40px",
                        }}
                        cardStyle={CardStyle.verySmall}
                        card={item.circleMembershipCard}
                        width={50}
                      />
                    ))}
                    {circleSWR.content.currentMemberInfo
                      ?.circleMembershipCard && (
                      <CircleCardsPreviewCell
                        rootStyle={{
                          marginInlineStart: "-40px",
                        }}
                        cardStyle={CardStyle.verySmall}
                        card={
                          circleSWR.content.currentMemberInfo
                            ?.circleMembershipCard
                        }
                        width={50}
                      />
                    )}
                  </HStack>
                  <MembershipLevelLabel>
                    {i18n
                      .plural(
                        cardsSWR.content?.list
                          ? cardsSWR.content?.list.length
                          : 0,
                      )
                      .clover_membership_level_some(
                        cardsSWR.content?.list
                          ? cardsSWR.content?.list.length
                          : 0,
                      )}
                  </MembershipLevelLabel>
                </VStack>
              </HStack>
            )}
        </>
      </ContentGroup>
      <TabCellSeparator title={i18n.general()} />

      <SettingCell
        title={i18n.circle2_1_circle_info()}
        onClick={() => {
          hopper.modal("nyi");
          // hopper.push(`circle/${circleId}/info-settings`);
        }}
      />

      <SettingCell
        title={i18n.circle2_1_appearance()}
        onClick={() => {
          hopper.modal("nyi");
          // hopper.push(`circle/${circleId}/appearance`);
        }}
      />

      <SettingCell
        title={i18n.circle2_1_permission()}
        onClick={() => {
          if (circleSWR.content && isCircleAdmin(circleSWR.content, myUid)) {
            hopper.push(`circle/${circleId}/permission-and-privacy`);
          } else {
            nativePage
              .infoHud(i18n.circle2_1_only_admin_can_manage_info())
              .catch(andLog);
          }
        }}
      />

      <SettingCell
        title={i18n.circle_anmt_push_notification_task()}
        onClick={() => {
          hopper.modal("nyi");
        }}
      />

      <SettingCell
        title={i18n.circle_dc_data_center()}
        showDivider={false}
        onClick={() => {
          hopper.modal("nyi");
          // hopper.push(`circle/${circleId}/data-center`);
        }}
      />

      <TabCellSeparator title={i18n.circle2_1_contents()} />
      <SettingCell
        title={i18n.circle2_1_home_layout()}
        onClick={() => {
          hopper.modal("nyi");
          // hopper.push(`circle/${circleId}/home-layout`);
        }}
      />

      <SettingCell
        title={i18n.circle2_1_circle_folders()}
        onClick={() => {
          hopper.modal("nyi");
          // hopper.push(`circle/${circleId}/folder-settings`);
        }}
      />

      <SettingCell
        title={i18n.clover_circle_rooms()}
        onClick={() => {
          hopper.modal("nyi");
          // hopper.push(`circle/${circleId}/room-settings`);
        }}
      />

      <SettingCell
        title={i18n.circle2_1_blocked_contents()}
        showDivider={false}
        onClick={() => {
          hopper.modal("nyi");
          // hopper.push(`circle/${circleId}/blocked-contents`);
        }}
      />

      <TabCellSeparator title={i18n.members()} />
      <SettingCell
        title={i18n.circle_all_members()}
        onClick={() => {
          hopper.modal("nyi");
        }}
      />

      <SettingCell
        title={i18n.member_title_circle_titles()}
        onClick={() => {
          hopper.push(`circle/${circleId}/member-titles`);
        }}
      />

      <SettingCell
        title={i18n.member_title_circle_members_page_layout()}
        onClick={() => {
          hopper.modal("nyi");
          // hopper.push(`circle/${circleId}/visible-titles`);
        }}
      />

      <SettingCell
        title={i18n.join_requests()}
        onClick={() => {
          hopper.modal("nyi");
          // hopper.push(`circle/${circleId}/join-requests`);
        }}
      />

      <SettingCell
        title={i18n.blocked_members()}
        showDivider={false}
        onClick={() => {
          hopper.modal("nyi");
          // hopper.push(`circle/${circleId}/blocked-objects`, {
          //   objectType: ObjectType.USER,
          // });
        }}
      />

      <TabCellSeparator title={i18n.circle2_1_management_team()} />
      {circleSWR.content?.adminIdList
        ?.map((id) => String(id))
        .includes(myUidStr) && (
        <SettingCell
          title={i18n.clover_admin_earnings()}
          onClick={() => {
            hopper.push(`circle/${circleId}/admin-earnings`);
          }}
        />
      )}
      {circleSWR.content?.adminIdList
        .map((it) => String(it))
        .includes(myUidStr) && (
        <SettingCell
          title={i18n.manage_co_admin()}
          onClick={() => {
            hopper.modal("nyi");
            // hopper.push(`circle/${circleId}/co-admins`);
          }}
        />
      )}

      {circleSWR.content?.adminIdList
        .map((it) => String(it))
        .includes(myUidStr) && (
        <SettingCell
          title={i18n.transfer_admin()}
          onClick={() => {
            hopper.modal("nyi");
          }}
        />
      )}

      <SettingCell
        title={i18n.feb_2023_impr_management_operation_records()}
        showDivider={false}
        onClick={() => {
          hopper.modal("nyi");
          // hopper.push(`circle/${circleId}/operation-records`);
        }}
      />
      <Spacing height={30} />
      {circleSWR.content?.adminIdList
        .map((it) => String(it))
        .includes(myUidStr) && (
        <VStack style={{ marginInlineStart: 50, marginInlineEnd: 50 }}>
          <WeakButton
            $baseColor={ButtonColor.destructive}
            onClick={() => {
              hopper.modal("nyi");
              // deleteAlertModal.open();
            }}
          >
            {i18n.circle_setting_delete()}
          </WeakButton>
        </VStack>
      )}
      <Spacing height={30} />

      <WhiteAlert
        modal={deleteAlertModal}
        title={i18n.circle_delete_confirmation()}
      >
        <AlertButton style={{ color: "red" }} onClick={() => deleteCircle()}>
          {i18n.delete()}
        </AlertButton>
        <AlertButton onClick={() => deleteAlertModal.close()}>
          {i18n.cancel()}
        </AlertButton>
      </WhiteAlert>
    </Page>
  );
}

const ActivateMembershipLevelLabel = styled.div`
  color: #ffaf02;
  font-size: 14px;
  font-weight: 400;
  margin-inline-start: 4px;
`;

const CircleTokenAndMembershipLabel = styled.div`
  color: var(--color-text00);
  font-size: 12px;
  font-weight: 400;
`;

const CircleName = styled.div`
  font-weight: 500;
  color: var(--color-text00);

  ${SingleLineUnspecifiedWidth};
  font-size: 20px;
`;

const CircleID = styled.div`
  font-weight: 400;
  color: var(--color-text00);
  font-size: 12px;
`;

const TokenName = styled.div`
  color: var(--color-text00);
  font-weight: 500;
  font-size: 14px;
`;

const Ticker = styled.div`
  color: var(--color-text00);
  font-weight: 700;
  background-color: rgba(255, 255, 255, 0.06);
  border-radius: 2px;
  padding: 2px;
  font-size: 10px;
  margin: 0 auto 0 0;
`;

export const MembershipLevelLabel = styled.div`
  font-weight: 300;
  color: rgba(255, 255, 255, 0.8);
  font-size: 10px;
`;

export default CircleAdminPortalPage;
