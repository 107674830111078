import styled, { css } from "styled-components";
import { HStack, Spring, VStack } from "../../../components/VStack";
import { Image } from "../../../components/Image";
import { CircleMembershipLevelIcon } from "../../circle/membership/MembershipLevelPicker";
import { NFTHolderView } from "../pages/NFTCircleCardDisplayPage";
import BgHolder from "../imgs/bg_nft_holder.svg";
import BgHeader from "../imgs/bg_nft_title.png";
import IcInfo from "../../../res/images/ic_info.svg";
import { alpha_on_pressed } from "../../../components/CommonStyles";
import { useObscuredZones } from "../../../hooks/useObscuredZones";
import { Spacing } from "../../../components/Spacing";
import { NFT, NFTStatus } from "../../../proto/NFT";
import { useHopper } from "../../../hooks/useHopper";
import { Circle } from "../../../proto/Circle";
import IcCard from "../../../res/images/ic_transaction_membership_card.png";
import IcProto from "../imgs/ic_nft_proto.png";
import IcLegacy from "../imgs/ic_nft_legacy.png";
import { Label } from "./Label";
import { useI18n } from "../../../hooks/useI18n";

const Container = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

const Holder = css`
  position: relative;
  align-items: center;
  height: 28px;
  margin: 15px var(--page-h-inset);
  padding: 6px 15px;

  border-width: 15px;
  border-style: solid;
  border-image-source: url("${BgHolder}");
  border-image-slice: 0 160 0 28 fill;
`;

const TitleBackground = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  height: 66px;
`;

const NFTTitle = styled.div`
  background: linear-gradient(
    to bottom,
    rgb(255, 255, 255),
    rgba(255, 255, 255, 0.5),
    rgb(153, 153, 153)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  font-size: 21px;
  font-weight: 500;
`;

const InfoImg = styled.img`
  width: 24px;
  background-color: rgba(255, 255, 255, 0.12);
  padding: 6px;
  border-radius: 8px;
  ${alpha_on_pressed};
`;

const ReactionAndComment = css`
  position: absolute;
  width: 62px;
  height: 166px;
  right: 0;
  bottom: 28vh;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;

  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
`;

const ReactionIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  text-align: center;
`;

const ReactionCount = styled.div`
  font-size: 12px;
`;

const ChatControl = styled.div`
  position: absolute;
  right: 0;
  width: 62px;
  height: 62px;
  bottom: calc(28vh + 176px);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background: #d1ffd3;
`;

export function NFTDisplayInfoView(props: {
  nft: NFT;
  circle: Circle | undefined;
}) {
  const [obscuredZone] = useObscuredZones();
  const hopper = useHopper();
  const i18n = useI18n();

  const isCircleMembershipCard = props.nft.circleMembershipCard !== undefined;
  const isProto = props.nft.nftStatus !== NFTStatus.Minted;

  const typeIcon = isCircleMembershipCard
    ? IcCard
    : isProto
      ? IcProto
      : IcLegacy;

  const info = isCircleMembershipCard
    ? ""
    : isProto
      ? i18n.jul23_impr2_prototype()
      : i18n.col_edition_edition(props.nft.version ?? 1);

  const onClickInfo = () => {
    if (isCircleMembershipCard) {
      hopper.push(`nft-circle-card/${props.nft.nftId}/info`);
    } else {
      hopper.push(`nft/${props.nft.nftId}/info`);
    }
  };

  const onClickReaction = () => {};
  const onClickComment = () => {};

  return (
    <Container>
      <Spacing height={obscuredZone.top} />
      <HStack
        style={{
          padding: "6px var(--page-h-inset)",
          position: "relative",
          marginTop: "10px",
          minHeight: 66,
        }}
      >
        <Image src={BgHeader} styledImg={TitleBackground} />
        <HStack style={{ alignItems: "start", gap: 8 }}>
          <Image
            src={typeIcon}
            style={{ width: 24, height: 17, paddingTop: 6 }}
          />
          <VStack style={{ alignItems: "start" }}>
            <NFTTitle>{props.nft.name ?? "-"}</NFTTitle>
            {isCircleMembershipCard ? (
              <CircleMembershipLevelIcon
                level={props.nft.membershipLevel ?? 1}
                width={34}
                style={{ marginLeft: -2, height: 24 }}
              />
            ) : (
              <Label $fontSize={12}>{info}</Label>
            )}
          </VStack>
        </HStack>
        <Spring></Spring>
        <Image src={IcInfo} styledImg={InfoImg} onClick={onClickInfo} />
      </HStack>
      <NFTHolderView
        holder={isCircleMembershipCard ? props.circle : props.nft.author}
        style={{ padding: "6px var(--page-h-inset)", marginTop: 12 }}
      />
      {/*<VStack mixin={ReactionAndComment} style={{ gap: 15, padding: "12px 0" }}>*/}
      {/*  <VStack*/}
      {/*    mixin={alpha_on_pressed}*/}
      {/*    style={{ alignItems: "center" }}*/}
      {/*    onClick={onClickReaction}*/}
      {/*  >*/}
      {/*    <ReactionIcon>❤️</ReactionIcon>*/}
      {/*    <ReactionCount>0</ReactionCount>*/}
      {/*  </VStack>*/}
      {/*  <VStack*/}
      {/*    mixin={alpha_on_pressed}*/}
      {/*    style={{ alignItems: "center" }}*/}
      {/*    onClick={onClickComment}*/}
      {/*  >*/}
      {/*    <ReactionIcon>💬</ReactionIcon>*/}
      {/*    <ReactionCount>0</ReactionCount>*/}
      {/*  </VStack>*/}
      {/*</VStack>*/}
      {/*<ChatControl>*/}
      {/*  <Image src={undefined} style={{ width: 30 }} />*/}
      {/*</ChatControl>*/}
    </Container>
  );
}
