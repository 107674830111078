import { useMemo } from "react";
import styled, { css } from "styled-components";

import { CurrencyType, formatMoney } from "../../../proto/Currency";
import { useBigIntParam } from "../../../hooks/useTypedParam";
import { useI18n } from "../../../hooks/useI18n";
import { useBackend } from "../../../service/APIService";
import { useSWR } from "../../../hooks/swr/useSWR";
import { useLoadState } from "../../../hooks/LoadState";
import { useHopper } from "../../../hooks/useHopper";
import { useErrorHandler } from "../../../components/handleError";
import { Page } from "../../../components/Page";
import GridLayout from "../../../components/GridLayout";
import { NavMiddle } from "../../../components/NavBar";
import { ButtonColor, RegularButton } from "../../../components/Buttons";
import { HStack } from "../../../components/VStack";
import { CenterModal, useModal } from "../../../components/Modal";
import { Spin } from "../../../components/Spin";
import GridTokenCell from "../GridTokenCell";
import { OrderType } from "../order/OrderDetailPage";

const ModalBody = styled.div`
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  line-height: 22px;
  color: #ffffff;
  padding: 0 10px;
`;

const CenterModalMixin = css`
  padding: 30px;
`;

const GemsValue = styled.span`
  color: #0dffcc;
`;

const LegacyTokenPage = () => {
  const walletAccountId = useBigIntParam("walletAccountId");
  const modal = useModal("modal");
  const i18n = useI18n();
  const backend = useBackend();
  const hopper = useHopper();
  const handleError = useErrorHandler();

  const convertTask = useLoadState();

  const currencyListSWR = useSWR(backend.getCurrencyList(walletAccountId), {
    reloadPolicy: "alwaysRefetch",
  });

  const gemsAmoutSWR = useSWR(backend.getGemsAmount(walletAccountId));

  // console.log(a)

  const [legacyTokens, coinAmount, diamondAmout] = useMemo(() => {
    let coinAmount = "0";
    let diamondAmout = "0";
    const filterTokens = currencyListSWR.content?.list?.filter(
      (currencyHolder) => {
        const currencyType = currencyHolder.currency.currencyType;
        if ([CurrencyType.COIN, CurrencyType.DIAMOND].includes(currencyType)) {
          if (currencyHolder.currency.amount !== "0") {
            if (currencyType === CurrencyType.COIN) {
              coinAmount = formatMoney("medium", currencyHolder.currency);
            } else {
              diamondAmout = formatMoney("medium", currencyHolder.currency);
            }
          }
          return true;
        }
        return false;
      }
    );
    return [filterTokens, coinAmount, diamondAmout];
  }, [currencyListSWR.content]);

  const gemsAmount = useMemo(() => {
    return formatMoney("medium", gemsAmoutSWR.content);
  }, [gemsAmoutSWR.content]);

  const convert = async () => {
    const r = await convertTask.run(async () => {
      const data = await backend.convertGems(walletAccountId).run();
      modal.close();
      hopper.dismissLayerAndPush(`wallet/order/${data.orderId}`, {
        orderType: OrderType.OtherOrder,
        showDialog: true,
        title: i18n.clover_conversion_successful(),
      });
    });

    if (!r.success) {
      handleError(r.error);
    }
  };

  return (
    <Page pageData={[currencyListSWR]}>
      <NavMiddle>{i18n.clover_legacy_tokens()}</NavMiddle>
      {legacyTokens && (
        <GridLayout
          style={{ paddingTop: 14 }}
          spanCount={2}
          items={legacyTokens.map((currencyHolder) => (
            <GridTokenCell format="medium" currency={currencyHolder.currency} />
          ))}
        />
      )}
      <HStack style={{ marginTop: 24, justifyContent: "center" }}>
        <RegularButton
          style={{ width: 250 }}
          $baseColor={ButtonColor.greenish}
          onClick={() => {
            modal.open();
          }}
        >
          {i18n.clover_convert_to_gems()}
        </RegularButton>
      </HStack>
      <HStack style={{ marginTop: 24, justifyContent: "center" }}></HStack>
      <CenterModal modal={modal} hideCloseBtn mixin={CenterModalMixin}>
        <ModalBody>
          {coinAmount !== "0" && diamondAmout != "0"
            ? i18n.clover_convert_to_gems_desc(
                coinAmount,
                diamondAmout,
                <GemsValue>{`${gemsAmount} Gems`}</GemsValue>
              )
            : i18n.clover_convert_to_gems_desc_only_one(
                coinAmount !== "0"
                  ? `${coinAmount} ${i18n.clover_coins()}`
                  : `${diamondAmout} ${i18n.clover_diamonds()}`,
                <GemsValue>{`${gemsAmount} Gems`}</GemsValue>
              )}
        </ModalBody>
        <HStack style={{ gap: 12 }}>
          <RegularButton style={{ flex: 1 }} onClick={() => modal.close()}>
            {i18n.cancel()}
          </RegularButton>
          <RegularButton
            $baseColor={ButtonColor.greenish}
            style={{ flex: 1 }}
            onClick={convert}
          >
            <Spin state={convertTask.state}>{i18n.web3_v0_swap()}</Spin>
          </RegularButton>
        </HStack>
      </CenterModal>
    </Page>
  );
};

export default LegacyTokenPage;
