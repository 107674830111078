import { Page } from "../../components/Page";
import {
  useBigIntSearchParam,
  useNumberSearchParam,
} from "../../hooks/useTypedParam";
import { useSWR } from "../../hooks/swr/useSWR";
import { useBackend } from "../../service/APIService";
import { VStack } from "../../components/VStack";
import { UserAvatarView } from "../../components/views/UserAvatarView";
import styled from "styled-components";
import { RegularInputGroup } from "../../components/Input";
import React, { useEffect, useState } from "react";
import { useI18n } from "../../hooks/useI18n";
import { NavEnd, NavItem, NavMiddle, NavStart } from "../../components/NavBar";
import { NavButtonType } from "../../bridge/NavButtonDesc";
import { useHopper } from "../../hooks/useHopper";
import { andLog } from "../../components/handleError";
import { useGlobalSpinner } from "../../utils/globalSpinner";

function EditRemarkPage() {
  const backend = useBackend();
  const globalSpinner = useGlobalSpinner();

  const [input, setInput] = useState("");
  const i18n = useI18n();
  const hopper = useHopper();
  const oid = useBigIntSearchParam("oid", BigInt(0));
  const oType = useNumberSearchParam("otype");
  const userSWR = useSWR(backend.getUser(oid));
  const onEditRemark = async () => {};
  useEffect(() => {
    if (userSWR.content) {
      setInput(userSWR.content.remark ?? "");
    }
  }, [userSWR.content?.remark]);
  return (
    <Page pageData={userSWR}>
      {userSWR.content && (
        <VStack>
          <div style={{ alignItems: "center", margin: "auto" }}>
            <UserAvatarView user={userSWR.content} iconSize={80} />
          </div>
          <UserName>{userSWR.content.nickname}</UserName>
          <UseThis
            onClick={() => {
              setInput(userSWR.content?.nickname ?? "");
            }}
          >
            {i18n.add_remark_use_this()}
          </UseThis>
          <RegularInputGroup
            value={input}
            updateValue={setInput}
            maxLength={50}
          />
          <Hint>{i18n.add_remark_desc()}</Hint>
        </VStack>
      )}
      <NavStart>
        {NavItem.image(NavButtonType.Cross, () => {
          hopper.back();
        })}
      </NavStart>

      <NavMiddle>{i18n.web3_v0_edit_remark()}</NavMiddle>

      <NavEnd>
        {NavItem.image(NavButtonType.Check, () => {
          globalSpinner(async () => {
            await backend
              .updateRemark({
                objectId: oid,
                objectType: oType,
                remark: input,
              })
              .run();
            hopper.back();
          }).catch(andLog);
        })}
      </NavEnd>
    </Page>
  );
}

const UserName = styled.div`
  color: #ffffff;
  margin: 20px auto 0;
`;

const UseThis = styled.div`
  color: rgba(255, 255, 255, 0.8);
  text-decoration: underline;
  font-size: 9px;
  margin: 8px auto 20px;
`;

const Hint = styled.div`
  font-size: 9px;
  margin-top: 10px;
`;

export default EditRemarkPage;
