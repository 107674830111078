export class AssertionError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = "AssertionError";
  }
}

export function assert(
  condition: boolean,
  message = "Unmet precondition",
): asserts condition {
  if (!condition) {
    throw new AssertionError(message);
  }
}

export function isDefined<T>(value: T): value is NonNullable<T> {
  return value != null;
}

export function assertNonNullish<T>(
  value: T,
  message = "Value must not be null or undefined",
): NonNullable<T> {
  assert(isDefined(value), message);
  return value;
}

export function error(message?: string): never;
export function error(
  errorType: new (...args: any[]) => Error,
  message?: string,
): never;

export function error(
  errorType?: string | (new (...args: any[]) => Error),
  message?: string,
): never {
  throw errorType == null || typeof errorType === "string"
    ? new AssertionError(errorType)
    : new errorType(message);
}

export function unreachable(message = "Reached an unreachable case"): never {
  throw new AssertionError(message);
}
