import { Page } from "../../../components/Page";
import styled from "styled-components";
import { Image } from "../../../components/Image";
import { HStack, VStack } from "../../../components/VStack";
import chevron from "../../../res/images/icon_section_header_show_more.svg";
import { NavMiddle } from "../../../components/NavBar";
import { useSWR } from "../../../hooks/swr/useSWR";
import { useI18n } from "../../../hooks/useI18n";
import { useBackend } from "../../../service/APIService";
import { useBigIntSearchParam } from "../../../hooks/useTypedParam";
import { formatMoney } from "../../../proto/Currency";
import BigNumber from "bignumber.js";
import { useHopper } from "../../../hooks/useHopper";
import GridLayout from "../../../components/GridLayout";
import { useMemo } from "react";
import { DefaultEmptyView } from "../../../components/LoadStateView";
import CircleCardsPreviewCell, {
  CardStyle,
} from "../../circle/card/CircleCardsPreviewCell";
import { getOrderFormattedDateTime } from "../order/CreateNewTokenDetail";
import { formatDate } from "../../../utils/DateTimeUtils";
import icLaunch from "../../../res/images/ic_stage_launched.svg";
import icIncubation from "../../../res/images/ic_incubation.svg";

function AssetDetailPage() {
  const i18n = useI18n();
  const backend = useBackend();
  const walletAccountId = useBigIntSearchParam("walletAccountId", BigInt(0));
  const currencyId = useBigIntSearchParam("currencyId", BigInt(0));
  const walletSWR = useSWR(backend.getWallet());
  const currencyListSWR = useSWR(backend.getCurrencyList(walletAccountId));
  const currencySWR = useSWR(
    backend.getWalletCurrencyInfo(walletAccountId, currencyId),
  );
  const hitCircle = useMemo(() => {
    return currencySWR.content?.circleList?.at(0);
  }, [currencySWR.content]);
  // const smallCards = useMemo(() => {
  //   return currencySWR.content?.membershipCardList?.map((nft) => {
  //     return <MiniCircleCard nft={nft} cardHeight={60} circle={hitCircle} />;
  //   });
  // }, [currencySWR.content, hitCircle]);
  const hopper = useHopper();
  const smallCards = useMemo(() => {
    return currencySWR.content?.membershipCardList?.map((nft) => {
      return (
        <CardsFrame
          onClick={() => {
            hopper.push(`nft-circle-card/${nft.nftId}`);
          }}
        >
          <TopContainer>
            <CircleCardsPreviewCell
              nft={nft}
              cardStyle={CardStyle.medium}
              circle={hitCircle}
              rootStyle={{
                width: "calc(100% - 12px)",
                flexGrow: undefined,
                margin: "auto",
              }}
            />
          </TopContainer>
          <BottomContainer>
            <HStack style={{ margin: "6px 4px 0", gap: 4 }}>
              <Amount>
                {formatMoney("short", BigNumber(nft.tokenAmount))}
              </Amount>
              <TickerName>{currencySWR.content?.currency?.symbol}</TickerName>
            </HStack>
            <HStack
              style={{
                margin: "8px 4px 0",
                gap: 4,
                alignItems: "start",
                flexWrap: "wrap",
                width: "calc(100% - 8px)",
              }}
            >
              <InfoTitle>{i18n.clover_maturation()}</InfoTitle>
              <Maturation>
                {`${nft.circleMembershipCard?.maturityProgress}%`}
              </Maturation>
            </HStack>
            <HStack style={{ margin: 4, alignItems: "start" }}>
              <InfoTitle style={{ width: "100%" }}>
                {i18n.clover_added_on(
                  formatDate(
                    (nft.circleMembershipCard?.createdTime || 0) * 1000,
                  ),
                )}
              </InfoTitle>
            </HStack>
          </BottomContainer>
        </CardsFrame>
      );
    });
  }, [currencySWR.content, hitCircle, hopper]);

  const currencyType = currencySWR.content?.currency?.currencyType;
  return (
    <Page pageData={[currencyListSWR, currencySWR, walletSWR]}>
      <AssetFrame
        onClick={() => {
          hopper.push(`wallet/${walletAccountId}/token/${currencyType}`, {
            currencyId,
          });
        }}
      >
        <Asset style={{ alignItems: "start" }}>
          <Image
            src={[
              currencySWR.content?.currency?.icon,
              { width: 27, height: 27 },
            ]}
            style={{ width: 27, height: 27, borderRadius: 5 }}
          />
          <VStack style={{ gap: 4 }}>
            <HStack style={{ gap: 2 }}>
              {currencySWR.content?.currency?.name}
              <object
                data={
                  !!currencySWR.content?.currency?.poolId
                    ? icLaunch
                    : icIncubation
                }
                style={{
                  width: 18,
                  height: 18,
                }}
              />
            </HStack>

            {!!currencySWR.content?.currency?.unitUSDPrice && (
              <MCContainer>
                MC
                <MCValue>
                  {`$ ${formatMoney("medium", currencySWR.content?.currency?.unitUSDPrice ?? BigNumber(0))}`}
                </MCValue>
              </MCContainer>
            )}
          </VStack>
        </Asset>
        <VStack style={{ alignItems: "end" }}>
          <HStack style={{ gap: 4 }}>
            <Asset>
              {formatMoney("medium", currencySWR.content?.currency) || 0}
            </Asset>
            <TickerName>{currencySWR.content?.currency?.symbol}</TickerName>
            <Image src={chevron} style={{ width: 20, height: 20 }} />
          </HStack>
          <USDPrice>
            {`$${formatMoney(
              "medium",
              BigNumber(currencySWR.content?.currency?.usdPrice || 0),
            )} USD`}
          </USDPrice>
        </VStack>
      </AssetFrame>
      <MembershipCardFrame>
        <Asset style={{ flexShrink: 1 }}>
          {i18n.clover_my_membership_cards()}
        </Asset>
        <VStack style={{ alignItems: "end" }}>
          <Redeemable>{i18n.clover_redeemable_amount()}</Redeemable>
          <HStack style={{ gap: 4 }}>
            <Asset>
              {formatMoney(
                "medium",
                currencySWR.content?.membershipCardCurrency,
              ) || 0}
            </Asset>
            <TickerName>{currencySWR.content?.currency?.symbol}</TickerName>

            {/*<Image src={chevron} style={{ width: 20, height: 20 }} />*/}
          </HStack>
          <USDPrice>{`$${formatMoney("medium", BigNumber(currencySWR.content?.membershipCardCurrency?.usdPrice || 0))} USD`}</USDPrice>
        </VStack>
      </MembershipCardFrame>
      {!!currencySWR.content?.membershipCardList ? (
        <>{smallCards && <GridLayout spanCount={3} items={smallCards} />}</>
      ) : (
        <DefaultEmptyView title={i18n.clover_no_membership_card()} />
      )}

      <NavMiddle>
        <VStack style={{ alignItems: "center" }}>
          <div>{currencySWR.content?.currency?.name}</div>
          <div>{i18n.clover_solana_chain()}</div>
        </VStack>
      </NavMiddle>
    </Page>
  );
}

const Maturation = styled.div`
  color: #ffffff;
  font-size: 10px;
  font-weight: 500;
`;

const InfoTitle = styled.div`
  font-size: 10px;
  word-break: break-word;
  text-align: start;
  color: rgba(255, 255, 255, 0.6);
`;

const Amount = styled.div`
  color: white;
  font-size: 14px;
  font-weight: 500;
`;

const BottomContainer = styled.div`
  background: rgba(255, 255, 255, 0.06);
  border-radius: 0 0 6px 6px;
  min-height: 67px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TopContainer = styled.div`
  width: 100%;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CardsFrame = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.2);
`;

const TickerName = styled.div`
  color: #ffffff;
  padding: 2px;
  font-size: 10px;
  font-weight: 700;
  max-width: 41px;
  text-overflow: ellipsis;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.06);
  border-radius: 2px;
`;

const MCValue = styled.div`
  color: white;
  font-size: 12px;
  font-weight: 700;
`;

const MCContainer = styled.div`
  color: rgba(255, 255, 255, 0.6);
  font-size: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

const CardFrame = styled.div`
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.2);
`;

const MemberCardContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;

  > * {
    flex-basis: calc(33.33% - 10px); // 每行3个，减去10px是为了考虑间距
    margin: 5px; // 可选，增加元素间的间距
  }
`;

const Redeemable = styled.div`
  font-size: 10px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
`;

const MembershipCardFrame = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  justify-content: space-between;
`;

const USDPrice = styled.div`
  font-size: 10px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
`;

const Asset = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  font-size: 18px;
  font-weight: 500;
`;

const AssetFrame = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

export default AssetDetailPage;
