import { Chat } from "../../../proto/Chat";
import { Backend } from "../../../service/Backend";

export interface ObjectPoolSubscriber<T> {
  onObjectUpdated: (value: T) => void;
  uniqueId: string;
}

export class ObjectPool<T> {
  _objectMap = new Map<bigint, T>();
  _objectSubscribersMap = new Map<bigint, ObjectPoolSubscriber<T>[]>();

  constructor(private objectFetcher: (oId: bigint) => Promise<T>) {}

  hasObject(objectId: bigint): boolean {
    return this._objectMap.has(objectId);
  }

  cacheObject(objectId: bigint, value: T): void {
    this._objectMap.set(objectId, value);
    this._objectSubscribersMap.get(objectId)?.forEach((subscriber) => {
      subscriber.onObjectUpdated({ ...value });
    });
  }

  cacheObjectIfNotExist(objectId: bigint, value: T): void {
    if (!this.hasObject(objectId)) {
      this.cacheObject(objectId, { ...value });
    }
  }

  async getObject(objectId: bigint) {
    if (this.hasObject(objectId)) {
      return this._objectMap.get(objectId);
    }
    const object = await this.objectFetcher(objectId);
    this._objectMap.set(objectId, object);
    return object;
  }

  getCachedObject(objectId: bigint) {
    return this._objectMap.get(objectId);
  }

  addSubscribe(subscriber: ObjectPoolSubscriber<T>, objectId: bigint) {
    const subscribers = this._objectSubscribersMap.get(objectId) ?? [];
    subscribers.push(subscriber);
    this._objectSubscribersMap.set(objectId, subscribers);
  }

  removeSubscribe(subscriber: ObjectPoolSubscriber<T>, objectId: bigint) {
    const subscribers = this._objectSubscribersMap.get(objectId) ?? [];
    this._objectSubscribersMap.set(
      objectId,
      subscribers.filter((sub) => sub.uniqueId !== subscriber.uniqueId),
    );
  }
}
