import { Page } from "../../../components/Page";
import { useBigIntParam } from "../../../hooks/useTypedParam";
import { useBackend } from "../../../service/APIService";
import { useI18n } from "../../../hooks/useI18n";
import { useHopper } from "../../../hooks/useHopper";
import { useSWR } from "../../../hooks/swr/useSWR";
import { useNativePage, useWebHost } from "../../../hooks/useBridge";
import { NavMiddle, NavTitle } from "../../../components/NavBar";
import styled from "styled-components";
import {
  ButtonColor,
  PlaceholderButton,
  RegularButton,
} from "../../../components/Buttons";
import { Image } from "../../../components/Image";
import ic_add from "../../../res/images/icon_mini_link.svg";
import React, { useEffect, useMemo, useState } from "react";
import { HStack, VSpace, VStack } from "../../../components/VStack";
import {
  createResultToken,
  useV2ConsumeResult,
} from "../../../hooks/useResult";
import { useHopId, useHopState } from "../../../hooks/useHopState";
import { useMyUid } from "../../../service/AuthSessionService";
import { EditingCardCell } from "./EditingCardCell";
import { PageFooter } from "../../../components/PageHeaderFooter";
import { RowCenterButton } from "../../../components/CommonStyles";
import { andLog, useErrorHandler } from "../../../components/handleError";
import { TokenSnippetCell } from "./TokenSnippetCell";
import { BindCircleTokenIntroduceModal } from "../BindCircleTokenIntroduceModal";
import { useModal } from "../../../components/Modal";
import SettingCell from "../../../components/SettingCell";
import icInfoYellow from "../../../res/images/ic_info_yellow.svg";
import { BSMenu, BSMenuItem } from "../../../components/BSMenu";
import {
  CircleTokenDeployStatus,
  FreeLabel,
} from "../CircleTokenCreateIntroducePage";
import icEmptyState from "../../../res/images/empty_state.svg";
import { EmptyState, EmptyTitle } from "../../post/PostDetailPage";
import { useTransactionBody } from "../../wallet/send/SendTokenPage";
import { CurrencyType } from "../../../proto/Currency";
import { zBigInt } from "../../../utils/zodUtils";
import {
  Circle,
  CircleTokenLinkType,
  isCircleAdmin,
  isValidCircle,
} from "../../../proto/Circle";
import { TokenDesignType } from "../TokenDesignPage";
import CircleTokenDeployStatusContent from "../CircleTokenDeployStatusContent";
import { isValidCircleToken } from "../../../proto/CircleFin";
import { OrderType } from "../../wallet/order/OrderDetailPage";
import pageBg from "../../../res/images/token_deploy_status_bg.png";
import { CircleLinkStatus } from "../../../proto/CircleLinkStatus";
import { useGlobalSpinner } from "../../../utils/globalSpinner";
import { delay } from "../../../utils/ensureDur";

export const TokenPickedResult = createResultToken(
  zBigInt,
  "TokenPickedResult",
);

export function CircleTokenOverviewPage() {
  const circleId = useBigIntParam("circleId");
  const backend = useBackend();
  const i18n = useI18n();
  const hopper = useHopper();
  const circleSWR = useSWR(backend.getCircle(circleId));

  const hopId = useHopId();
  const myUid = useMyUid();

  const tokenPicked = useV2ConsumeResult(TokenPickedResult);

  const circleTokenSWR = useSWR(
    tokenPicked.result
      ? backend.getTokenProject(tokenPicked.result)
      : undefined,
  );

  const [initialFill, setInitialFill] = useHopState("initialFill", false);
  useEffect(() => {
    const token = circleSWR.content?.tokenProject;
    if (token && !initialFill) {
      setInitialFill(true);
      tokenPicked.fill(token.projectId).catch(andLog);
    }
  }, [circleSWR.content, initialFill]);

  const cardsSWR = useSWR(backend.getCircleCardsList(circleId));

  const isCircleTokenActive =
    circleSWR.content?.tokenLinkStatus === CircleLinkStatus.Complete;

  const isTokenExisting =
    circleSWR.content?.extensions?.tokenLinkType ===
      CircleTokenLinkType.Create ||
    circleSWR.content?.extensions?.tokenLinkType === CircleTokenLinkType.Bind;

  const adminCardSWR = useSWR(
    isTokenExisting ? backend.getAdminMembershipCard(circleId) : undefined,
  );

  const nativePage = useNativePage();
  const [hasModalCircleTokenIntroduce, setHasModalCircleTokenIntroduce] =
    useHopState<boolean>("hasModalCircleTokenIntroduce", false);
  useEffect(() => {
    if (circleSWR.content && circleSWR.content.tokenProjectId === undefined) {
      if (!hasModalCircleTokenIntroduce) {
        setHasModalCircleTokenIntroduce(true);
        bindCircleTokenIntroduceModal.open();
      }
    }
  }, [circleSWR.content]);

  const bindCircleTokenIntroduceModal = useModal(
    "bindCircleTokenIntroduceModal",
  );
  const circleTokenCreationMenu = useModal("circleTokenCreationMenu");
  const circleTokenEditOrRemoveMenu = useModal("circleTokenEditOrRemoveMenu");

  const presetSWR = useSWR(backend.createTokenPresets(circleId));
  const globalSpinner = useGlobalSpinner();
  const removeToken = async () => {
    const chooseYes = await nativePage.alertYesOrCancel(
      i18n.clover_remove_token_confirm_tips(),
      i18n.yes(),
      i18n.cancel(),
    );
    if (chooseYes) {
      await globalSpinner(async () => {
        await backend.deleteTokenProject(circleId).run();
        await circleSWR.load();
        await cardsSWR.load();
        await tokenPicked.clear();
        setInitialFill(false);
      });
    }
  };

  const { fill } = useTransactionBody();

  const activateAdminCard = async () => {
    await fill((prev) => {
      return {
        ...prev,
        circleId: circleId,
        paymentCurrencyType: CurrencyType.SOL,
      };
    });
    if (
      circleSWR.content?.extensions?.tokenLinkType ===
      CircleTokenLinkType.Create
    ) {
      hopper.modal(`circle/${circleId}/purchase-info`);
    } else if (
      circleSWR.content?.extensions?.tokenLinkType === CircleTokenLinkType.Bind
    ) {
      hopper.modal(`circle/${circleId}/stake-info`, {
        cardId: adminCardSWR?.content?.circleMembershipCard?.cardId,
      });
    }
  };

  // Animation related
  function circleTokenDeployStatus(
    circle: Circle | {} | undefined,
  ): CircleTokenDeployStatus {
    if (isValidCircle(circle)) {
      console.log("circle.tokenLinkStatus", circle.tokenLinkStatus);
      switch (circle.tokenLinkStatus) {
        case CircleLinkStatus.Pending:
          return CircleTokenDeployStatus.Unbound;
        case CircleLinkStatus.InProgress:
          return CircleTokenDeployStatus.Deploying;
        case CircleLinkStatus.Complete:
          return CircleTokenDeployStatus.Deployed;
        case CircleLinkStatus.Failed:
        default:
          return CircleTokenDeployStatus.Unbound;
      }
    } else {
      return CircleTokenDeployStatus.Unbound;
    }
  }
  const deployStatus = circleTokenDeployStatus(
    circleTokenSWR?.content?.tokenProject,
  );
  const needShowDeployStatus = (() => {
    switch (deployStatus) {
      case CircleTokenDeployStatus.Unbound:
        return false;
      case CircleTokenDeployStatus.Deploying:
        return true;
      case CircleTokenDeployStatus.Deployed:
        return (
          (circleSWR.content && circleSWR.content?.coAdminIdList) ??
          []
        ).indexOf(myUid) !== -1
          ? false
          : circleSWR.content?.extensions?.showTokenCreatedAnim === true &&
              isCircleAdmin(circleSWR.content, myUid);
    }
  })();
  const [manualDismissDeployStatusPage, setManualDismissDeployStatusPage] =
    useHopState<boolean>("manualDismissDeployStatusPage", false);
  const webHost = useWebHost();

  return (
    <Page
      pageData={[circleSWR, cardsSWR, circleTokenSWR, adminCardSWR]}
      background={
        needShowDeployStatus && !manualDismissDeployStatusPage
          ? `url("${pageBg}") center top/cover no-repeat`
          : undefined
      }
    >
      <NavMiddle>
        <VStack style={{ alignItems: "center" }}>
          <TitleLabel>{i18n.clover_circle_membership_and_token()}</TitleLabel>
          <HStack>
            {circleSWR.content?.tokenLinkStatus ===
              CircleLinkStatus.Complete && (
              <ActiveLabel>{i18n.chat_read_only_active()}</ActiveLabel>
            )}
            {circleSWR.content?.tokenLinkStatus !==
              CircleLinkStatus.Complete && (
              <InactiveLabel>{i18n.chat_read_only_inactive()}</InactiveLabel>
            )}
          </HStack>
        </VStack>
      </NavMiddle>
      {needShowDeployStatus && !manualDismissDeployStatusPage && (
        <CircleTokenDeployStatusContent
          circleId={circleId}
          deployStatus={deployStatus}
          viewDetailClick={() => {
            if (
              isValidCircleToken(circleTokenSWR?.content?.tokenProject) &&
              circleTokenSWR?.content?.tokenProject.extensions?.mintAssetOrderId
            ) {
              hopper.push(
                `wallet/order/${circleTokenSWR?.content?.tokenProject.extensions?.mintAssetOrderId}`,
                { orderType: OrderType.OtherOrder },
              );
            }
          }}
          doneClick={() => {
            setManualDismissDeployStatusPage(true);
            backend
              .markTokenCreatedAnimShown(circleId)
              .run()
              .then(() => {
                circleSWR.load().catch(andLog);
                circleTokenSWR?.load().catch(andLog);
                webHost.broadcast("ON_CIRCLE_TOKEN_CREATED", {}).catch(andLog);
              })
              .catch(andLog);
          }}
        ></CircleTokenDeployStatusContent>
      )}
      {(!needShowDeployStatus || manualDismissDeployStatusPage) && (
        <>
          <VSpace height={10} />
          <Label>{i18n.clover_circle_token()}</Label>
          {circleTokenSWR?.content?.tokenProject && (
            <TokenSnippetCell
              tokenProject={circleTokenSWR?.content?.tokenProject}
              showEditIcon={!isCircleTokenActive}
              onPick={() => {
                if (isCircleTokenActive) {
                  hopper.push(`circle/${circleId}/token-info`);
                } else {
                  circleTokenEditOrRemoveMenu.open();
                }
              }}
            />
          )}
          {!Boolean(circleTokenSWR?.content?.tokenProject) && (
            <PlaceholderButton
              onClick={() => {
                circleTokenCreationMenu.open();
              }}
              style={{ height: 86, color: "var(--text00)", marginTop: 12 }}
            >
              <Image src={ic_add} alt={"reveal"} />
              {i18n.clover_bind_circle_token()}
            </PlaceholderButton>
          )}

          <VSpace height={32} />
          <Label>{i18n.clover_clean_membership_levels()}</Label>
          {adminCardSWR?.content && (
            <EditingCardCell
              circle={circleSWR.content}
              nft={adminCardSWR?.content}
              onClickCard={() => {}}
            />
          )}
          {cardsSWR.content?.list?.map((nft) => (
            <EditingCardCell
              circle={circleSWR.content}
              nft={nft}
              onClickCard={() => {
                hopper.push(
                  `circle/${circleId}/card-overview/${nft.circleMembershipCard?.cardId}`,
                );
              }}
            />
          ))}
          {(cardsSWR.content?.list?.length || 0) === 0 && (
            <VStack style={{ width: "100%", alignItems: "center" }}>
              <VSpace height={80} />
              <EmptyState src={icEmptyState} />
              <EmptyTitle>{i18n.clover_bind_circle_token_desc()}</EmptyTitle>
            </VStack>
          )}
          <PageFooter obscuredZoneKey={"TokenDeployBottom"}>
            {!isCircleTokenActive && (
              <RegularButton
                style={{
                  ...RowCenterButton,
                  marginTop: 8,
                }}
                disabled={!Boolean(tokenPicked.result)}
                $baseColor={ButtonColor.greenish}
                $baseBgColor={"var(--color-bg)"}
                onClick={activateAdminCard}
              >
                {i18n.web3_v0_activate()}
              </RegularButton>
            )}
          </PageFooter>
        </>
      )}

      <BindCircleTokenIntroduceModal
        modal={bindCircleTokenIntroduceModal}
        bind={() => {
          bindCircleTokenIntroduceModal.close();
          circleTokenCreationMenu.open();
        }}
      />
      <BSMenu
        title={i18n.clover_bind_circle_token()}
        modal={circleTokenCreationMenu}
      >
        <SettingCell
          style={{ paddingLeft: 0, paddingRight: 0, height: 80 }}
          title={i18n.clover_create_a_new_token()}
          showAccessoryImage={false}
          secondaryValue={
            presetSWR.content?.isFree ? (
              <HStack>
                <Image
                  src={icInfoYellow}
                  alt={"icInfoYellow"}
                  width={14}
                ></Image>
                <FreeLabel>{i18n.clover_free_for_a_limited_time()}</FreeLabel>
              </HStack>
            ) : undefined
          }
          onClick={() => {
            circleTokenCreationMenu.close();
            hopper.push(`circle/${circleId}/token-design`);
          }}
        ></SettingCell>
        <BSMenuItem
          title={i18n.clover_select_an_existing_token()}
          onClick={() => {
            hopper.present(`circle/${circleId}/token-picker`);
          }}
        ></BSMenuItem>
      </BSMenu>

      <BSMenu modal={circleTokenEditOrRemoveMenu}>
        <BSMenuItem
          title={i18n.edit()}
          onClick={() => {
            hopper.push(`circle/${circleId}/token-design`, {
              tokenDesignType: TokenDesignType.Edit,
              tokenProjectId: circleTokenSWR?.content?.tokenProject.projectId,
            });
          }}
        ></BSMenuItem>
        <BSMenuItem
          title={i18n.action_remove()}
          onClick={removeToken}
        ></BSMenuItem>
      </BSMenu>
    </Page>
  );
}

const Label = styled.div`
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  font-weight: 400;
`;

const TitleLabel = styled.div`
  color: var(--color-text00);
  font-size: 18px;
  font-weight: 700;
`;

const ActiveLabel = styled.div`
  color: #00ff94;
  font-size: 12px;
  border-radius: 4px;
  padding: 4px 8px;
  background-color: rgba(0, 0, 0, 0.3);
  font-weight: 400;
`;

const InactiveLabel = styled.div`
  color: rgba(255, 255, 255, 0.4);
  font-size: 12px;
  border-radius: 4px;
  padding: 4px 8px;
  background-color: rgba(0, 0, 0, 0.3);
  font-weight: 400;
`;
