import { useI18n } from "../hooks/useI18n";
import { VStack } from "./VStack";
import { StrongButton } from "./Buttons";
import { Image } from "./Image";
import icDownload from "../res/images/ic_download.svg";
import icGoogle from "../res/images/ic_google.svg";
import icApple from "../res/images/ic_apple.svg";
import React from "react";
import styled from "styled-components";
import { useWebHost } from "../hooks/useBridge";

const Icon = styled.img`
  width: 20px !important;
  height: 20px !important;
`;

const DownloadLink = styled.a`
  text-decoration: none;
`;

export function DownloadApps() {
  const webHost = useWebHost();
  const i18n = useI18n();
  return (
    <VStack style={{ gap: 21 }}>
      <DownloadLink
        href={"https://sys.clover.space/downloads/app-prod.apk"}
        download={"app-prod.apk"}
        target={"_blank"}
      >
        <StrongButton style={{ fontSize: "16px", width: "100%", flexGrow: 1 }}>
          <Image src={icDownload} styledImg={Icon} />
          {i18n.clover_download_apk()}
        </StrongButton>
      </DownloadLink>
      <DownloadLink
        href={
          "https://play.google.com/store/apps/details?id=com.projz.z.android"
        }
        target={"_blank"}
      >
        <StrongButton style={{ fontSize: "16px", width: "100%", flexGrow: 1 }}>
          <Image src={icGoogle} styledImg={Icon} />
          {i18n.clover_google_play()}
        </StrongButton>
      </DownloadLink>
      <DownloadLink
        href={"https://apps.apple.com/app/clover-space/id1528320292"}
      >
        <StrongButton style={{ fontSize: "16px", width: "100%", flexGrow: 1 }}>
          <Image src={icApple} styledImg={Icon} />
          {i18n.clover_app_store()}
        </StrongButton>
      </DownloadLink>
    </VStack>
  );
}
