import { Page } from "../../components/Page";
import solIcon from "../../res/images/ic_sol.svg";
import styled from "styled-components";
import { useWalletSWR } from "./useWallet";
import { getQRCodeData, QRCode } from "../../components/QRCode";
import copyIcon from "../../res/images/picker_copy.svg";
import { alpha_on_pressed } from "../../components/CommonStyles";
import { RegularButton } from "../../components/Buttons";
import { useI18n } from "../../hooks/useI18n";
import { toPng } from "html-to-image";
import React, { useMemo, useRef } from "react";
import { ChainType } from "../../proto/ChainType";
import { copyTextToClipboard } from "../../utils/CopyText";
import { andLog } from "../../components/handleError";
import { useNativePage, useWebHost } from "../../hooks/useBridge";
import parseDataUrl from "parse-data-url";
import { getOS } from "../../utils/deviceModel";
import { HStack } from "../../components/VStack";
import { Image } from "../../components/Image";
import questionMark from "../../res/images/ic_sol_question_mark.svg";
import chevron from "../../res/images/icon_section_header_show_more.svg";
import { useHopper } from "../../hooks/useHopper";
import { isLiteApp } from "../../bridge/ExternalAppShellData";
import { useSWR } from "../../hooks/swr/useSWR";
import { BoolNumber } from "../../proto/Wallet";
import { useBackend } from "../../service/APIService";
function generateDataUrl(parsed: parseDataUrl.DataUrl): string {
  const encoding = parsed.base64 ? "base64" : "binary"; // 判断是否是 base64 编码
  return `data:${parsed.mediaType};${encoding},${parsed.data}`;
}
function ReceiveAssetsPage() {
  const [wallet, walletSWR] = useWalletSWR();
  const address = wallet?.walletAccountList?.[0]?.assetAccountList?.find(
    (asset) =>
      asset.chainType === ChainType.Solana ||
      asset.chainType === ChainType.SolanaTestnet,
  )?.address;
  const backend = useBackend();
  const i18n = useI18n();
  const nativePage = useNativePage();
  const hopper = useHopper();
  const webHost = useWebHost();

  // const cardRef = useRef<HTMLDivElement>(null);
  // const handleSave = () => {
  //   if (cardRef.current) {
  //     toPng(cardRef.current)
  //       .then((dataUrl) => {
  //         const link = document.createElement("a");
  //         link.href = dataUrl;
  //         link.download = "qrcode.png";
  //         link.click();
  //       })
  //       .catch((err) => {
  //         console.error("Oops, something went wrong!", err);
  //       });
  //   }
  // };
  async function saveQRCode(address: string) {
    try {
      const dataUrl = await getQRCodeData(address, 200, "image/jpeg");
      const info = parseDataUrl(dataUrl);

      if (info !== false && info.base64) {
        console.log(`@_@ same ? =`, generateDataUrl(info) === dataUrl);
        await webHost.saveImage(
          info.data,
          info.contentType,
          true,
          `${address}-${new Date().getTime()}`,
          dataUrl,
        );
        nativePage.infoHud(i18n.image_saved()).catch(andLog);
      } else {
        nativePage.warnHud(i18n.failed_to_save_image()).catch(andLog);
      }
      // const link = document.createElement("a");
      // link.href = dataUrl;
      // link.download = "qrcode.png";
      // link.click();
      nativePage.infoHud(i18n.image_saved()).catch(andLog);
    } catch (e) {
      nativePage.warnHud(i18n.failed_to_save_image()).catch(andLog);
    }
  }
  return (
    <Page pageData={[walletSWR]}>
      <SolIcon />
      <Title>{i18n.clover_receive_assets()}</Title>
      <Title style={{ fontWeight: 500, fontSize: 18, marginTop: 12 }}>
        {i18n.clover_from_solana_mainnet()}
      </Title>
      {address && (
        <div style={{ margin: "0 auto" }}>
          <QRCode value={address} width={200} />
        </div>
      )}
      <AddressFrame
        onClick={() => {
          if (address) {
            copyTextToClipboard(address)
              .then(() => {
                nativePage.successHud(i18n.copied()).catch(andLog);
              })
              .catch(andLog);
          }
        }}
      >
        <AddressText>{address || ""}</AddressText>
        <PickerIcon />
      </AddressFrame>
      <RegularButton
        onClick={() => {
          if (address) {
            saveQRCode(address);
          }
        }}
        style={{ margin: "84px 42px" }}
      >
        {i18n.web3_v0_save_as_image()}
      </RegularButton>

      {!isLiteApp() || wallet?.thirdPartyIapStatus === BoolNumber.On ? (
        <HStack
          onClick={() => {
            hopper.push("get-sol");
          }}
          style={{ margin: "-64px auto 0", gap: 6 }}
        >
          <Image src={questionMark} width={16} height={16} />
          <GetSol>{i18n.clover_how_do_i_get_sol()}</GetSol>
          <Image src={chevron} width={12} height={12} />
        </HStack>
      ) : null}
    </Page>
  );
}

const GetSol = styled.div`
  color: white;
  font-size: 12px;
  font-weight: 400;
`;

const PickerIcon = styled.div`
  width: 20px;
  height: 20px;
  background-image: url("${copyIcon}");
  ${alpha_on_pressed};
  margin: auto 15px auto 0;
`;
const AddressText = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  margin: 15px;
  flex: 1;
  word-break: break-word;
`;

const AddressFrame = styled.div`
  width: 100%;
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.08);
  display: flex;
  flex-direction: row;
`;
const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  color: #ffffff;
  text-align: center;
`;

const SolIcon = styled.div`
  width: 60px;
  height: 60px;
  background-image: url("${solIcon}");
  background-repeat: no-repeat;
  background-size: 100%;
  margin: 25px auto 20px;
`;
export default ReceiveAssetsPage;
