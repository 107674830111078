import { useI18n, useIsRTL } from "../../../hooks/useI18n";
import { useBackend } from "../../../service/APIService";
import { useHopper } from "../../../hooks/useHopper";
import { useNativePage } from "../../../hooks/useBridge";
import React from "react";
import { useLoadState } from "../../../hooks/LoadState";
import {
  useEnumSearchParam,
  useStringSearchParam,
} from "../../../hooks/useTypedParam";
import { AuthType, SecurityPurpose } from "../../../proto/Auth";
import { Page } from "../../../components/Page";
import { NavEnd, NavItem } from "../../../components/NavBar";
import { AuthTitleLabel } from "../../auth/InvitationCodePage";
import { PhoneNumberInput, RegularInputGroup } from "../../../components/Input";
import { RegularLargeButton } from "../../../components/Buttons";
import { Spin } from "../../../components/Spin";
import { PowerMarkdown } from "../../../components/PowerMarkdown";
import { service_privacy_markdown_style } from "../../auth/AuthHomePage";
import { BSMenu, BSMenuItem } from "../../../components/BSMenu";
import { allRegions, Region } from "../../../proto/Region";
import { useModal } from "../../../components/Modal";
import { AssociateType } from "./AssociateEmailOrPhonePage";
import { useErrorHandler } from "../../../components/handleError";

export function EnterEmailOrPhoneNumberConfirmPage() {
  const i18n = useI18n();
  const backend = useBackend();
  const hopper = useHopper();
  const nativePage = useNativePage();
  const loadTask = useLoadState();
  const handleError = useErrorHandler();
  const authType = useEnumSearchParam<AuthType>("authType", AuthType.Email);
  const [inputValue, setInputValue] = React.useState("");
  const associateType = useEnumSearchParam<AssociateType>("associateType");
  const preEmail = useStringSearchParam("preEmail");
  const prePhoneNumber = useStringSearchParam("prePhoneNumber");
  const preSecurityCode = useStringSearchParam("preSecurityCode");
  const newSecurityCode = useStringSearchParam("newSecurityCode");
  const purpose = useEnumSearchParam<SecurityPurpose>("purpose");

  async function onNextClick() {
    const inputEmail = authType === AuthType.Email ? inputValue : undefined;
    const inputPhoneNumber =
      authType === AuthType.Phone
        ? region?.phoneCodeWithMark + inputValue
        : undefined;
    const result = await loadTask.run(async () => {
      const authBody = {
        authType: authType,
        purpose: purpose,
        email: inputEmail,
        phoneNumber: inputPhoneNumber,
      };
      await backend.registerCheck(authBody).run();
    });
    if (!result.success) {
      handleError(result.error);
    } else {
      hopper.push("verification-code", {
        email: inputEmail,
        phoneNumber: inputPhoneNumber,
        authType: authType,
        associateType: associateType,
        purpose: purpose,
        preEmail: preEmail,
        newEmail: inputEmail,
        prePhoneNumber: prePhoneNumber,
        newPhoneNumber: inputPhoneNumber,
        preSecurityCode: preSecurityCode,
        newSecurityCode: newSecurityCode,
      });
    }
  }

  const title = (() => {
    switch (authType) {
      case AuthType.Email:
        return i18n.onboarding_input_email();
      case AuthType.Phone:
        return i18n.onboarding_input_phone_number();
      default:
        return "-";
    }
  })();

  const placeholder = (() => {
    switch (authType) {
      case AuthType.Email:
        return i18n.email();
      case AuthType.Phone:
        return i18n.auth_phone_number();
      default:
        return "-";
    }
  })();

  const isRTL = useIsRTL();
  const regionMenu = useModal("regionMenu");
  const locale = new Intl.Locale(navigator.language);
  const [region, setRegion] = React.useState<Region | null>(
    Region.from(locale.region || "US"),
  );

  return (
    <Page pageData={undefined}>
      <NavEnd>
        {NavItem.text(i18n.help(), () => hopper.push("feedback"))}
      </NavEnd>
      <AuthTitleLabel>{title}</AuthTitleLabel>
      {authType === AuthType.Email && (
        <RegularInputGroup
          placeholder={placeholder}
          value={inputValue}
          updateValue={setInputValue}
        />
      )}
      {authType === AuthType.Phone && (
        <>
          <PhoneNumberInput
            placeholder={placeholder}
            value={inputValue}
            updateValue={setInputValue}
            regionCode={region?.phoneCodeWithMark}
            regionCodeOnClick={() => regionMenu.open()}
          />
          <PowerMarkdown
            style={{ marginTop: 20 }}
            textStyle={service_privacy_markdown_style}
          >
            {i18n.clover_enter_phone_number_continue_tips()}
          </PowerMarkdown>
        </>
      )}
      <RegularLargeButton
        disabled={!(inputValue && inputValue.length > 0)}
        style={{ margin: "40px 50px 0" }}
        onClick={onNextClick}
      >
        <Spin state={loadTask.state}>{i18n.next()}</Spin>
      </RegularLargeButton>
      <BSMenu modal={regionMenu}>
        {allRegions.map((region) => (
          <BSMenuItem
            title={isRTL ? region.rtlDes : region.des}
            trailingItem={region.phoneCodeWithMark}
            onClick={() => {
              regionMenu.close();
              setRegion(region);
            }}
          ></BSMenuItem>
        ))}
      </BSMenu>
    </Page>
  );
}
