import { Page } from "../../components/Page";
import { CircleChildPageProps } from "./CircleChildPageProps";
import { useSWR } from "../../hooks/swr/useSWR";
import SectionHeader from "../../components/SectionHeader";
import { useI18n } from "../../hooks/useI18n";
import { Spacing } from "../../components/Spacing";
import { useSWRArray } from "../../hooks/swr/useSWRArray";
import React from "react";
import { PostCell } from "../post/PostCell";
import CircleWidgetCell from "./CircleWidgetCell";
import {
  useBigIntParam,
  useBigIntSearchParam,
} from "../../hooks/useTypedParam";
import { useBackend } from "../../service/APIService";
import styled from "styled-components";
import { NavMiddle } from "../../components/NavBar";
import { useMemoryRepoMap } from "../../hooks/swr/useLocalRepo";
import { zBigInt } from "../../utils/zodUtils";
import { z } from "zod";
import { Reaction } from "../../proto/Reaction";
import { andLog } from "../../components/handleError";
import { useReactionRecord } from "../../hooks/useReactionRecord";

const FolderTitleLabel = styled.div`
  color: #ffffff;
  font-size: 24px;
  font-weight: bold;
`;

const FolderDescriptionLabel = styled.div`
  color: rgba(255, 255, 255, 0.8);
  font-size: 13px;
`;

function CircleExplorePage(props: CircleChildPageProps) {
  const backend = useBackend();
  const i18n = useI18n();
  const circleId = useBigIntParam("circleId");
  const pFolderId = useBigIntSearchParam("folderId", BigInt(0));
  const folderId = props.folderId ?? pFolderId;

  const circleSWR = useSWR(backend.getCircle(circleId));

  const circleFolderSWR = useSWR(backend.getCircleFolder(circleId, folderId));
  const reactionRepo = useReactionRecord();
  const popularPostsSWR = useSWRArray(
    backend.getCirclePopularPosts(circleId).intercept((it) => {
      it.list.forEach((post) => {
        reactionRepo
          .setValue(post.blogId, post.reactionCountList)
          .catch(andLog);
      });
    }),
  );
  const folderName = () => {
    const folderName = circleFolderSWR.content?.name;
    if (!props.folderId && folderName) {
      return folderName;
    }
    return "";
  };
  return (
    <Page pageData={[circleSWR, popularPostsSWR, circleFolderSWR]}>
      <NavMiddle>{folderName()}</NavMiddle>
      {(props.showTitle ?? true) && (
        <FolderTitleLabel>{circleFolderSWR.content?.name}</FolderTitleLabel>
      )}
      {(props.showTitle ?? true) && (
        <FolderDescriptionLabel>
          {circleFolderSWR.content?.description}
        </FolderDescriptionLabel>
      )}
      {circleFolderSWR.content?.folderWidgets
        ?.filter((item) => item.status != 9)
        .map((widget) => (
          <CircleWidgetCell
            key={widget.widgetId}
            circleId={circleId}
            folderId={folderId}
            widget={widget}
            i18n={i18n}
          />
        ))}
      <Spacing height={24} />
      <SectionHeader title={i18n.posts()} />
      {popularPostsSWR.content &&
        popularPostsSWR.content.map((post) => (
          <PostCell
            post={post}
            showMembershipLevel={true}
            circleId={circleId}
          />
        ))}
    </Page>
  );
}

export default CircleExplorePage;
