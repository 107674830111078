import { useI18n } from "../../hooks/useI18n";
import { Page } from "../../components/Page";
import { useNativePage, useWebHost } from "../../hooks/useBridge";
import { useHopper } from "../../hooks/useHopper";
import React from "react";
import { ButtonColor, RegularButton } from "../../components/Buttons";
import { VSpace } from "../../components/VStack";
import { useGlobalSpinner } from "../../utils/globalSpinner";
import { andLog } from "../../components/handleError";
import SettingCell from "../../components/SettingCell";
import TabCellSeparator from "../../components/TabCellSeparator";
import {
  useAuthSessionService,
  useMyUid,
} from "../../service/AuthSessionService";
import { NavMiddle } from "../../components/NavBar";
import { useBackend } from "../../service/APIService";
import { useSWR } from "../../hooks/swr/useSWR";
import { useNSFW } from "../../hooks/useNSFW";

export function SettingsPage() {
  const i18n = useI18n();
  const hopper = useHopper();
  const webHost = useWebHost();
  const nativePage = useNativePage();
  const backend = useBackend();
  const globalSpinner = useGlobalSpinner();

  const myUid = useMyUid();
  const userSWR = useSWR(backend.getUser(myUid));

  const authSessionService = useAuthSessionService();

  const nsfw = useNSFW();

  const onLogOutClick = async () => {
    const logOut = await nativePage
      .alertAreYouSure(
        i18n.log_out_confirmation(),
        i18n.auth_log_out(),
        i18n.cancel(),
      )
      .catch(andLog);
    if (logOut) {
      globalSpinner(async () => {
        await backend.logOut().run();
        await authSessionService.remove(myUid);
      }).catch(andLog);
    }
  };

  async function updateShowNSFWContent(checked: boolean) {
    if (userSWR.content?.extensions) {
      try {
        await backend
          .updateProfilePrivacy(myUid, {
            ...userSWR.content?.extensions,
            showNSFWContent: checked,
          })
          .run();
        if (!checked) {
          await nsfw.clear();
        }
      } catch (e) {
        console.error(e);
      }
    }
  }

  return (
    <Page pageData={undefined} scrollPaddingDisabled={true}>
      <NavMiddle>{i18n.settings()}</NavMiddle>
      <TabCellSeparator title={i18n.general()}></TabCellSeparator>
      <SettingCell
        title={i18n.account()}
        onClick={() => {
          hopper.push("account-settings");
        }}
      ></SettingCell>
      <SettingCell
        title={i18n.web3_v0_security_center()}
        onClick={() => {
          hopper.push("security-center");
        }}
      ></SettingCell>
      {/*<SettingCell*/}
      {/*  title={i18n.may23_impr_safety_and_privacy()}*/}
      {/*  onClick={() => {*/}
      {/*    hopper.push("safety-privacy");*/}
      {/*  }}*/}
      {/*></SettingCell>*/}
      {/*<SettingCell*/}
      {/*  title={i18n.pretty_bubble_chat_bubble_style()}*/}
      {/*  onClick={() => {}}*/}
      {/*></SettingCell>*/}
      {/*<SettingCell*/}
      {/*  title={i18n.nsfw_filter_show_nsfw_content()}*/}
      {/*  showAccessoryImage={false}*/}
      {/*  endValue={*/}
      {/*    <Switch*/}
      {/*      checked={userSWR.content?.extensions?.showNSFWContent}*/}
      {/*      onChange={(checked) => updateShowNSFWContent(checked)}*/}
      {/*    />*/}
      {/*  }*/}
      {/*  onClick={() => {}}*/}
      {/*></SettingCell>*/}
      {/*<SettingCell*/}
      {/*  title={i18n.tap_return_action()}*/}
      {/*  showAccessoryImage={false}*/}
      {/*  endValue={<Switch />}*/}
      {/*  onClick={() => {}}*/}
      {/*></SettingCell>*/}
      <TabCellSeparator title={i18n.more()}></TabCellSeparator>
      <SettingCell
        title={i18n.user_guidelines()}
        onClick={() => {
          hopper.push("user-guidelines");
        }}
      ></SettingCell>
      <SettingCell
        title={i18n.terms_of_service_in_settings()}
        onClick={() => {
          hopper.push("terms-of-service");
        }}
      ></SettingCell>
      <SettingCell
        title={i18n.privacy_policy_in_settings()}
        onClick={() => {
          hopper.push("privacy-policy");
        }}
      ></SettingCell>
      <VSpace height={40}></VSpace>
      <RegularButton
        style={{ marginLeft: 50, marginRight: 50 }}
        onClick={onLogOutClick}
        $baseColor={ButtonColor.destructive}
      >
        {i18n.auth_log_out()}
      </RegularButton>
    </Page>
  );
}
