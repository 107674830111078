import { Page } from "../../../components/Page";
import { NavMiddle } from "../../../components/NavBar";
import { useI18n } from "../../../hooks/useI18n";
import {
  useBigIntParam,
  useStringSearchParam,
} from "../../../hooks/useTypedParam";
import { useTransactionBody } from "./SendTokenPage";
import { BottomSheet, closeModal, useModal } from "../../../components/Modal";
import { TxSheet, usePerformTx } from "../payment/TransactionDialog";
import React, { useMemo } from "react";
import { useBackend } from "../../../service/APIService";
import styled from "styled-components";
import {
  CurrencyType,
  formatMoney,
  getCurrencyIcon,
  getCurrencyName,
} from "../../../proto/Currency";
import { useHopper } from "../../../hooks/useHopper";
import { HStack, VSpace, VStack } from "../../../components/VStack";
import { Image } from "../../../components/Image";
import iconSOL from "../../../res/images/icon_sol.svg";
import { CellDivider, ContentGroup } from "../../../components/ContentGroup";
import iconSendSummary from "../../../res/images/ic_send_summary.svg";
import { andLog } from "../../../components/handleError";
import PaymentFooter from "../payment/PaymentFooter";
import PaymentAccount from "../payment/PaymentAccount";
import PaymentServiceFee from "../payment/PaymentServiceFee";
import { GasFeeTxType } from "../../../proto/Wallet";
import { useAssetAccount } from "../useAssetAccount";
import { JSONUtil } from "../../../utils/JSONUtil";
import { useSWR } from "../../../hooks/swr/useSWR";
import BigNumber from "bignumber.js";
import { useNativePage } from "../../../hooks/useBridge";
import { OrderType } from "../order/OrderDetailPage";
import { ZeroSOL } from "../swap/SwapPage";
import CurrencyTransactionView from "../payment/CurrencyTransactionView";

function SendTokenSummaryPage() {
  const i18n = useI18n();
  const assetAccountId = useBigIntParam("assetAccountId");
  const editingBody = useTransactionBody();

  const backend = useBackend();
  const hopper = useHopper();
  const paymentModal = useModal("payment-modal");
  const walletAccountId = useBigIntParam("walletAccountId");
  const currencyListSWR = useSWR(backend.getCurrencyList(walletAccountId));
  const nativePage = useNativePage();
  const gasFeeAvailableCurrency = useMemo(() => {
    return currencyListSWR.content?.list?.find(
      (item) =>
        item.currency.currencyType === editingBody.content.gasFeeCurrencyType,
    )?.currency;
  }, [currencyListSWR.content, editingBody.content]);
  const solCurrency = useMemo(() => {
    return (
      currencyListSWR?.content?.list?.find(
        (item) => item.currency.currencyType === CurrencyType.SOL,
      )?.currency ?? ZeroSOL
    );
  }, [currencyListSWR?.content]);

  const currencyId = useMemo(() => {
    return editingBody.content?.currencyId;
  }, [editingBody.content]);
  const targetCurrencySWR = useSWR(
    currencyId
      ? backend.getWalletCurrencyInfo(walletAccountId, currencyId)
      : undefined,
  );

  const paymentCheck = async () => {
    if (editingBody.content.gasFeeCurrencyType === CurrencyType.GEMS) {
      if (
        BigNumber(
          editingBody.content.gasFeeCurrency?.amount ?? "0",
        ).isGreaterThan(BigNumber(gasFeeAvailableCurrency?.amount ?? "0"))
      ) {
        nativePage
          .infoHud(i18n.clover_dont_have_enough_balance())
          .catch(andLog);
        return false;
      }
    }

    if (editingBody.content.gasFeeCurrencyType === CurrencyType.SOL) {
      if (editingBody.content.currencyType === CurrencyType.SOL) {
        const gas = BigNumber(
          editingBody.content.gasFeeCurrency?.amount ?? "0",
        );
        const amountWithGas = BigNumber(editingBody.content.amount).plus(gas);
        if (
          amountWithGas.isGreaterThan(BigNumber(solCurrency?.amount ?? "0"))
        ) {
          nativePage
            .infoHud(i18n.clover_dont_have_enough_balance())
            .catch(andLog);
          return false;
        }
      } else {
        if (
          BigNumber(
            editingBody.content.gasFeeCurrency?.amount ?? "0",
          ).isGreaterThan(BigNumber(solCurrency?.amount ?? "0"))
        ) {
          nativePage
            .infoHud(i18n.clover_dont_have_enough_balance())
            .catch(andLog);
          return false;
        }
      }
    }

    return true;
  };

  const performTx = usePerformTx(
    async () => {
      const resp = await backend
        .performTransfer(assetAccountId, editingBody.content)
        .run();
      return resp.orderId;
    },
    async (orderId: bigint | undefined) => {
      await closeModal(paymentModal);
      if (orderId) {
        targetCurrencySWR?.load();
        currencyListSWR.load().catch(andLog);
        hopper.dismissLayerAndPush(`wallet/order/${orderId}`, {
          orderType: OrderType.OtherOrder,
          showDialog: true,
          title: i18n.clover_sent_successfully(),
        });
      }
    },
  );

  return (
    <Page pageData={currencyListSWR}>
      <NavMiddle>{i18n.web3_v0_summary()}</NavMiddle>

      <ContentGroup>
        <HStack style={{ gap: 6 }}>
          <Image width={20} height={20} src={iconSendSummary} />
          <SendInfoLabel>{i18n.clover_item_sent_info()}</SendInfoLabel>
        </HStack>

        <CellDivider style={{ marginTop: 12, marginBottom: 12 }} />

        <HStack style={{ gap: 12, alignItems: "start" }}>
          <Image
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.2)",
              borderRadius: 6,
              padding: 15,
            }}
            src={getCurrencyIcon(editingBody.content.currency)}
            width={44}
            height={44}
          />

          <VStack style={{ gap: 8 }}>
            <TickerName>
              {getCurrencyName(editingBody.content.currency, i18n)}
            </TickerName>

            <HStack style={{ gap: 4 }}>
              <Image src={iconSOL} width={16} height={16} />
              <ChainName>{i18n.clover_solana_chain()}</ChainName>
            </HStack>
          </VStack>
        </HStack>

        <HStack style={{ justifyContent: "space-between", marginTop: 12 }}>
          <Label>{i18n.web3_v0_quantity()}</Label>
          <QuantityValue>
            {formatMoney("medium", {
              currencyId: BigInt(0),
              amount: editingBody.content.amount,
              decimals: editingBody.content.decimals ?? 9,
              currencyType: editingBody.content.currencyType,
            })}
          </QuantityValue>
        </HStack>

        <HStack
          style={{
            justifyContent: "space-between",
            marginTop: 12,
            alignItems: "start",
            gap: "8px",
          }}
        >
          <Label>{i18n.march_impr_2023_destination()}</Label>
          <AddressValue>{editingBody.content.toChainAddress}</AddressValue>
        </HStack>
      </ContentGroup>

      <VSpace height={20} />
      <PaymentServiceFee
        editingBody={editingBody}
        gasFeeTxType={GasFeeTxType.Transfer}
      />

      <VSpace height={20} />
      <PaymentAccount />

      <PaymentFooter
        editingBody={editingBody}
        paymentModal={paymentModal}
        paymentCheck={paymentCheck}
      />
      <TxSheet modal={paymentModal} performTx={performTx}>
        <CurrencyTransactionView body={editingBody.content} />
      </TxSheet>
    </Page>
  );
}

const ChainName = styled.div`
  color: var(--color-text20);
  font-size: 11px;
  font-weight: 400;
`;

const TickerName = styled.div`
  color: var(--color-text00);
  font-weight: 700;
  font-size: 14px;
`;

const SendInfoLabel = styled.div`
  color: rgba(255, 255, 255, 0.6);
  font-weight: 400;
  font-size: 12px;
`;

const Label = styled.div`
  color: rgba(255, 255, 255, 0.6);
  font-weight: 400;
  font-size: 12px;
  white-space: nowrap; /* 确保 Label 完整显示，不换行 */
`;

const AddressValue = styled.div`
  color: var(--color-text00);
  font-weight: 400;
  text-align: right;
  font-size: 12px;
  min-width: 0;
  flex: 1; /* 占据剩余的空间 */
  word-wrap: break-word; /* 允许内容在需要时换行 */
  overflow-wrap: break-word; /* 确保内容在长单词时也能换行 */
`;

const QuantityValue = styled.div`
  color: var(--color-text00);
  font-weight: 500;
  font-size: 18px;
`;

export default SendTokenSummaryPage;
