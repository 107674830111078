import { HStack, Spring, VSpace, VStack } from "../../../components/VStack";
import styled from "styled-components";
import {
  AssetOrder,
  AssetOrderStatus,
  TxCategory,
} from "../../../proto/AssetOrder";
import { useI18n } from "../../../hooks/useI18n";
import {
  formatMoney,
  getCurrencyIcon,
  getCurrencyName,
} from "../../../proto/Currency";
import { Image } from "../../../components/Image";
import { CellDivider, ContentGroup } from "../../../components/ContentGroup";
import { StatusBar } from "./GiftOrderListPage";
import { OrderInfoContent, OrderInfoLabel } from "./GiftOrderDetail";
import { formatDateWithOptions } from "../../../utils/DateTimeUtils";

export function TopUpOrderDetail(props: { assetOrder: AssetOrder }) {
  const i18n = useI18n();
  const gotCurrency = props.assetOrder.purchaseInfo?.product?.amount;

  function getStatus() {
    if (props.assetOrder.orderStatus === AssetOrderStatus.Done) {
      return { text: i18n.web3_v0_completed(), color: "#00FF94" };
    } else if (props.assetOrder.orderStatus === AssetOrderStatus.Pending) {
      return { text: i18n.web3_v0_pending(), color: "#34A6FFFF" };
    } else if (props.assetOrder.orderStatus === AssetOrderStatus.Failed) {
      return { text: i18n.circle_anmt_failed(), color: "#EF3537FF" };
    } else if (props.assetOrder.orderStatus === AssetOrderStatus.Rejected) {
      return { text: i18n.circle_anmt_rejected(), color: "#EF3537FF" };
    } else return { text: "", color: "#FFFFFF66" };
  }

  return (
    <VStack style={{ width: "100%", alignItems: "center" }}>
      <HStack style={{ gap: 7, marginTop: 40 }}>
        <Image
          src={getCurrencyIcon(gotCurrency)}
          style={{ width: 36, height: 36 }}
        />
        <HStack style={{ gap: 7, alignItems: "baseline" }}>
          <MainCurrencyAmount>
            {formatMoney("long", gotCurrency)}
          </MainCurrencyAmount>
          <MainCurrencyName>
            {getCurrencyName(gotCurrency, i18n)}
          </MainCurrencyName>
        </HStack>
      </HStack>
      <VSpace height={8} />
      <StatusBar
        statusColor={getStatus().color}
        statusText={getStatus().text}
      />

      <ContentGroup style={{ marginTop: 32, width: "100%", gap: 20 }}>
        <HStack>
          <OrderInfoLabel>{i18n.web3_v0_order_type()}</OrderInfoLabel>
          <Spring />
          <OrderInfoContent>{i18n.web3_hongbao_top_up()}</OrderInfoContent>
        </HStack>
        <CellDivider />
        <HStack>
          <OrderInfoLabel>{i18n.description()}</OrderInfoLabel>
          <Spring />
          <OrderInfoContent>
            {i18n.web3_hongbao_top_up_asset(
              getCurrencyName(gotCurrency, i18n) || "",
            )}
          </OrderInfoContent>
        </HStack>
        <CellDivider />
        <HStack>
          <OrderInfoLabel>{i18n.web3_v0_created_time()}</OrderInfoLabel>
          <Spring />
          <OrderInfoContent>
            {formatDateWithOptions(props.assetOrder.createdTime || 0, {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })}
          </OrderInfoContent>
        </HStack>
        <CellDivider />
        <HStack>
          <OrderInfoLabel>{i18n.web3_v0_source()}</OrderInfoLabel>
          <Spring />
          <OrderInfoContent>{i18n.web3_v0_default_account()}</OrderInfoContent>
        </HStack>
        <CellDivider />
        <HStack>
          <OrderInfoLabel>{i18n.web3_v0_transaction_order_id()}</OrderInfoLabel>
          <Spring />
          <OrderInfoContent>
            {props.assetOrder.orderId.toString()}
          </OrderInfoContent>
        </HStack>
      </ContentGroup>
    </VStack>
  );
}

export const MainCurrencyAmount = styled.div`
  font-size: 28px;
  font-weight: 700;
  color: white;
`;

export const MainCurrencyName = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #ffffff99;
`;
