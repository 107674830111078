import styled from "styled-components";
import {
  CSSProperties,
  MouseEvent,
  TouchEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { PropsFrom } from "../utils/typeUtils";
import { VStack } from "./VStack";
import { useMeasure } from "react-use";
import { useWebHost } from "../hooks/useBridge";
import { assert } from "../utils/asserts";
import { andLog } from "./handleError";
import { range } from "../utils/List";
import { useHopper } from "../hooks/useHopper";

const ThumbRadius = 24;
const TrackHeight = 1;
const MilestoneRadius = 2;

const Mark = styled.div`
  background-color: #acacac;
`;

const Track = styled.div`
  background-color: rgba(255, 255, 255, 0.2);
  width: 100%;
`;

const Progress = styled.div`
  height: 100%;
  width: 0;
  background-color: #00ff94;
`;

const Layer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

const ThumbVisual = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: var(--color-text00);
  transition: transform 0.2s ease-in-out;
`;

const Thumb = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  touch-action: none; // 禁止默认的触摸行为
`;

function isTouchEvent<T extends HTMLElement>(
  event: TouchEvent<T> | MouseEvent<T>,
): event is TouchEvent<T> {
  return "touches" in event;
}

function getClientX(
  event: TouchEvent<HTMLDivElement> | MouseEvent<HTMLDivElement>,
) {
  if (isTouchEvent(event)) {
    return event.touches[0].clientX;
  } else {
    return event.clientX;
  }
}

function SliderBar(props: {
  value?: number;
  min: number;
  max: number;
  numberOfMarks: number;
  onValueChange: (value: number) => void;
}) {
  const hopper = useHopper();
  const webHost = useWebHost();
  assert(props.max > props.min);

  const [value, setValue] = useState(props.value ?? props.min);

  const [canvasRef, canvasRect] = useMeasure<HTMLDivElement>();

  const percentage = useMemo(() => {
    return (value - props.min) / (props.max - props.min);
  }, [value]);

  const offset = useMemo(() => {
    return (canvasRect.width - ThumbRadius * 2) * percentage;
  }, [percentage, canvasRect]);

  const markValues = useMemo(() => {
    assert(props.max > props.min);
    if (props.numberOfMarks <= 0) return [];
    if (props.numberOfMarks === 1) return [0];
    const stride = (props.max - props.min) / (props.numberOfMarks - 1);
    return range(0, props.numberOfMarks - 1).map((i) => props.min + stride * i);
  }, [props.min, props.max, props.numberOfMarks]);

  const thumbRef = useRef<HTMLDivElement>(null);
  const dragSession = useRef<{ initialX: number; initialOffset: number }>();
  const dragEndDate = useRef<number>();

  useEffect(() => {
    if (dragSession.current === undefined) {
      setValue(
        Math.max(props.min, Math.min(props.max, props.value ?? props.min)),
      );
    }
  }, [props.value, props.min, props.max]);

  function onDragStart(
    event: TouchEvent<HTMLDivElement> | MouseEvent<HTMLDivElement>,
  ) {
    const target = event.currentTarget;
    const parent = target.parentElement;
    if (parent) {
      dragSession.current = {
        initialX: getClientX(event),
        initialOffset:
          target.getBoundingClientRect().x - parent.getBoundingClientRect().x,
      };
      if (thumbRef.current) {
        thumbRef.current.style.transform = `scale(1.5) perspective(0px)`;
        thumbRef.current.style.boxShadow = `0px 0px 10px white`;
      }
    }
  }

  function onDragMove(
    event: TouchEvent<HTMLDivElement> | MouseEvent<HTMLDivElement>,
  ) {
    const target = event.currentTarget;
    const parent = target.parentElement;
    if (parent && dragSession.current) {
      const dx = getClientX(event) - dragSession.current.initialX;
      const totalWidth =
        parent.getBoundingClientRect().width -
        target.getBoundingClientRect().width;
      const offset = Math.min(
        totalWidth,
        Math.max(0, dx + dragSession.current.initialOffset),
      );

      const percentage = offset / totalWidth;
      const theValue = Math.round(
        (props.max - props.min) * percentage + props.min,
      );

      setValue((prev) => {
        if (theValue !== prev) {
          const smaller = Math.min(prev, theValue);
          const larger = Math.max(prev, theValue);
          markValues
            .filter((m) => m >= smaller && m <= larger)
            .forEach(() => {
              webHost.haptic("light").catch(andLog);
            });
        }
        return theValue;
      });

      props.onValueChange(theValue);
    }
  }

  function onDragEnd(
    event: TouchEvent<HTMLDivElement> | MouseEvent<HTMLDivElement>,
  ) {
    dragSession.current = undefined;
    if (thumbRef.current) {
      thumbRef.current.style.transform = ``;
      thumbRef.current.style.boxShadow = ``;
    }
    dragEndDate.current = Date.now();
  }

  return (
    <div
      style={{
        position: "relative",
        height: ThumbRadius * 2,
      }}
    >
      <Layer
        style={{
          left: ThumbRadius,
          right: ThumbRadius,
        }}
      >
        <Track style={{ height: TrackHeight }}>
          <Progress style={{ width: `${percentage * 100}%` }} />
        </Track>
      </Layer>
      <Layer
        style={{
          left: ThumbRadius - MilestoneRadius,
          right: ThumbRadius - MilestoneRadius,
          justifyContent: "space-between",
        }}
      >
        {markValues.map((v, i) => (
          <Mark
            key={i}
            style={{
              width: MilestoneRadius * 2,
              height: MilestoneRadius * 2,
              borderRadius: MilestoneRadius,
            }}
          />
        ))}
      </Layer>
      <Layer
        ref={canvasRef}
        style={{
          justifyContent: "start",
        }}
        onClick={(e) => {
          if (
            dragEndDate.current !== undefined &&
            Date.now() - dragEndDate.current < 100
          ) {
            return;
          }
          const rect = e.currentTarget.getBoundingClientRect();
          const totalWidth = rect.width - ThumbRadius * 2;
          const offset = Math.min(
            totalWidth,
            Math.max(0, e.clientX - rect.x - ThumbRadius),
          );
          const percentage = offset / totalWidth;
          const theValue = Math.round(
            (props.max - props.min) * percentage + props.min,
          );
          setValue(theValue);
          props.onValueChange(theValue);
        }}
      >
        <Thumb
          onMouseDownCapture={onDragStart}
          onMouseMoveCapture={onDragMove}
          onMouseUpCapture={onDragEnd}
          onTouchStartCapture={onDragStart}
          onTouchMoveCapture={onDragMove}
          onTouchEndCapture={onDragEnd}
          onTouchCancelCapture={onDragEnd}
          style={{
            flexShrink: 0,
            width: ThumbRadius * 2,
            height: ThumbRadius * 2,
            transform: `translateX(${offset}px)`,
          }}
        >
          <ThumbVisual ref={thumbRef} />
        </Thumb>
      </Layer>
    </div>
  );
}

const Label = styled.div`
  width: 0;
  display: flex;

  flex-direction: row;
  justify-content: center;
  align-items: center;

  overflow: visible;
  color: var(--color-text00);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
`;

const LabelsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export function Slider(
  props: PropsFrom<typeof SliderBar> & {
    labels?: string[];
    style?: Pick<CSSProperties, "width" | "padding" | "margin" | "maxWidth">;
  },
) {
  return (
    <VStack style={{ alignItems: "stretch", ...props.style }}>
      <SliderBar {...props} />
      {props.labels !== undefined && (
        <LabelsContainer
          style={{
            marginTop: -8,
            marginLeft: ThumbRadius,
            marginRight: ThumbRadius,
            justifyContent: "space-between",
          }}
        >
          {props.labels.map((mark, i) => (
            <Label key={i}>{mark}</Label>
          ))}
        </LabelsContainer>
      )}
    </VStack>
  );
}
