import React, { PropsWithChildren, ReactElement, useState } from "react";
import { ModalController } from "./Modal";
import { useHopper } from "../hooks/useHopper";

export interface ModalContext {
  modal: ModalController;
}

export const ModalContext = React.createContext<ModalContext>({
  modal: {} as ModalController,
});

export interface ModalBoundaryContext {
  addModal: (modal: ReactElement, controller: ModalController) => void;
  removeModal: (modal: ReactElement, controller: ModalController) => void;
}

export const ModalBoundaryContext = React.createContext<ModalBoundaryContext>({
  addModal: (modal: ReactElement) => void {},
  removeModal: (modal: ReactElement) => void {},
});

export function ModalBoundary(props: PropsWithChildren<{}>) {
  const [modals, setModals] = useState<readonly ReactElement[]>([]);
  const hopper = useHopper();
  return (
    <ModalBoundaryContext.Provider
      value={{
        addModal: (modal: ReactElement, controller: ModalController) => {
          setModals((prev) => {
            return [...prev, modal];
          });

          hopper.addModalController(controller);
        },
        removeModal: (modal: ReactElement, controller: ModalController) => {
          setModals((prev) => {
            return prev.filter((m) => m !== modal);
          });

          hopper.removeModalController(controller);
        },
      }}
    >
      {props.children}
      {modals}
    </ModalBoundaryContext.Provider>
  );
}
