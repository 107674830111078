import { PropsWithChildren, useMemo } from "react";
import { isInExternalAppShell } from "../bridge/ExternalAppShellData";
import {
  mediaMenuProvider,
  useBridgeResultConsumer,
} from "../hooks/useBridgeResult";
import { MediaMenuConfig, MediaMenuResult } from "../bridge/NativePage";
import { isRejectable } from "../bridge/Rejectable";
import { RegularImage } from "../hooks/useSingleMediaEdit";
import { LocalMedia } from "../bridge/LocalMedia";

type FilePickerProps = {
  id?: string;
  accept?: string;
  onPick: (localMedia: LocalMedia) => void;
  mediaMenuConfig?: MediaMenuConfig;
};

export function WebFilePicker(props: PropsWithChildren<FilePickerProps>) {
  return (
    <>
      <label htmlFor={props.id || "filePicker"}>{props.children}</label>
      <input
        id={props.id || "filePicker"}
        accept={props.accept}
        type={"file"}
        style={{ display: "none" }}
        onChange={(event) => {
          if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];

            props.onPick(file);
          }
        }}
      />
    </>
  );
}

export function ExternalFilePicker(props: PropsWithChildren<FilePickerProps>) {
  const showMenu = useBridgeResultConsumer<MediaMenuResult>(
    mediaMenuProvider(
      props.mediaMenuConfig || {
        mimeTypes: RegularImage,
        maxCount: 1,
        enableCapture: false,
      },
    ),
    (r) => {
      if (r && !isRejectable(r)) {
        switch (r.type) {
          case "picked":
            const media = r.mediaList[0]!!;
            props.onPick(media);
            break;
          case "deleted":
            break;
        }
      }
      return true;
    },
    [props.onPick],
  );

  return <div onClick={() => showMenu()}>{props.children}</div>;
}

export function FilePicker(props: PropsWithChildren<FilePickerProps>) {
  const FP = useMemo(() => {
    if (isInExternalAppShell()) return ExternalFilePicker;
    else return WebFilePicker;
  }, []);
  return <FP {...props} />;
}
