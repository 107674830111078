import {
  arrayBufferToHex,
  concatArrayBuffers,
  hexToArrayBuffer,
  utf8ToArrayBuffer,
} from "../utils/Blob";
import jsSHA from "jssha";
import { genUUID } from "../utils/uuid";
import { cora } from "../config/config";

function sha1(buffer: ArrayBuffer): ArrayBuffer {
  const hasher = new jsSHA("SHA-1", "ARRAYBUFFER");
  hasher.update(buffer);
  return hasher.getHash("ARRAYBUFFER");
}

export async function hashDeviceId(did: string) {
  const hashedDid = sha1(utf8ToArrayBuffer(did));

  const payload = concatArrayBuffers(hexToArrayBuffer(cora.didV), hashedDid);
  const hashedKey = sha1(hexToArrayBuffer(cora.did));

  const sig = sha1(concatArrayBuffers(payload, hashedKey));

  return arrayBufferToHex(concatArrayBuffers(payload, sig));
}

export function getDeviceId() {
  const did = localStorage.getItem("deviceId");
  if (did) return did;
  const newDid = genUUID();
  localStorage.setItem("deviceId", newDid);
  return newDid;
}
