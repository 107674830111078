import { Page } from "../../components/Page";
import styled from "styled-components";
import { useSWR } from "../../hooks/swr/useSWR";
import { useHopper } from "../../hooks/useHopper";
import { UserAvatarView } from "../../components/views/UserAvatarView";
import { UserNameView } from "../../components/views/UserNameView";
import SettingCell from "../../components/SettingCell";
import solIcon from "../../res/images/ic_sol.svg";
import { useHopState } from "../../hooks/useHopState";
import { useMyUid } from "../../service/AuthSessionService";
import { useI18n } from "../../hooks/useI18n";
import { Image } from "../../components/Image";
import React, { useMemo } from "react";
import { andLog } from "../../components/handleError";
import { useBackend } from "../../service/APIService";
import { usePageSpec } from "../common/usePageSpec";
import qrCode from "../../res/images/qr_code.svg";
import icBell from "../../res/images/ic_bell.svg";
import icUnreadBell from "../../res/images/icon_bell_unread.svg";
import iconDiscover from "../../res/images/ic_discover.svg";
import iconMyCircles from "../../res/images/ic_my_circles.svg";
import iconMyChats from "../../res/images/ic_my_chats.svg";
import iconDiscoverSelected from "../../res/images/ic_discover_selected.png";
import iconMyCirclesSelected from "../../res/images/ic_my_circles_selected.png";
import iconMyChatsSelected from "../../res/images/ic_my_chats_selected.png";
import { useGlobalSpinner } from "../../utils/globalSpinner";
import { FollowType } from "../../proto/UserEnums";
import { linear_gradient_border } from "../../components/vscroll/SVGUtils";
import colorSetAlpha from "color-alpha";
import { GemsIcon } from "../wallet/send/ShopTransferPage";
import { CurrencyType, formatMoney } from "../../proto/Currency";
import receivedIcon from "../../res/images/gift_received.svg";
import { AccountType } from "../../proto/AccountType";
import { isSpongeKit } from "../../utils/isSpongeKit";
import { useWalletSWR } from "../wallet/useWallet";
import iconTopUp from "../../res/images/ic_top_up_success.svg";

export function UserCenterPage() {
  const myUid = useMyUid();
  const backend = useBackend();
  const hopper = useHopper();
  const userSWR = useSWR([`user-${myUid}`], () => backend.getUser(myUid).run());
  const i18n = useI18n();
  const pageSpec = usePageSpec();
  const globalSpinner = useGlobalSpinner();

  // const walletSWR = useSWR(backend.getWallet());
  const [wallet, walletSWR] = useWalletSWR();
  const checkInSWR = useSWR(backend.getCheckInLogList());
  const [selectedTab, setSelectedTab] = useHopState<number>("homeTab", 0);

  const alertCounts = useSWR(backend.getAlertCounts());
  const hasAlerts =
    (alertCounts.content?.activitiesAlertCount || 0) +
      (alertCounts.content?.followersAlertCount || 0) +
      (alertCounts.content?.likesAlertCount || 0) +
      (alertCounts.content?.postCount || 0) +
      (alertCounts.content?.circleAlertCount || 0) >
    0;

  async function onCheckIn() {
    try {
      globalSpinner(async () => {
        const checkInInfo = await backend.checkIn().run();
        await checkInSWR.load();
      }).catch(andLog);
    } catch (e) {}
  }

  const thirdCurrency = useMemo(() => {
    const otherTokens = walletSWR.content?.walletAccountList
      ?.find((wa) => wa.accountType === AccountType.BuiltIn)
      ?.currencyList?.filter((currency) => {
        return (
          currency.currencyType !== CurrencyType.COIN &&
          currency.currencyType !== CurrencyType.REWARDED_COIN &&
          currency.currencyType !== CurrencyType.DIAMOND &&
          currency.currencyType !== CurrencyType.GEMS &&
          currency.currencyType !== CurrencyType.SOL
        );
      });

    if (otherTokens && otherTokens.length > 0) {
      return otherTokens.reduce((max, currency) => {
        return Number(currency.usdPrice) > Number(max.usdPrice)
          ? currency
          : max;
      });
    }
  }, [walletSWR.content]);

  return (
    <Page pageData={[userSWR, walletSWR, checkInSWR]}>
      {userSWR.content ? (
        <>
          {pageSpec === "wide" ? null : (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                style={{
                  position: "relative",
                  backgroundImage: `url(${hasAlerts ? icUnreadBell : icBell})`,
                  width: 34,
                  height: 34,
                }}
                onClick={() => {
                  hopper.push(`alert-center`);
                }}
              />
              <div
                onClick={() => {
                  hopper.modal("nyi");
                }}
                style={{
                  backgroundImage: `url(${qrCode})`,
                  width: 34,
                  height: 34,
                }}
              />
            </div>
          )}

          <div
            style={{
              marginTop: 15,
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              gap: 20,
            }}
            onClick={() => {
              hopper.push(`user/${myUid}`);
            }}
          >
            <UserAvatarView
              user={userSWR.content}
              iconSize={pageSpec === "wide" ? 50 : 88}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <UserNameView user={userSWR.content} />
              <SocialId>{`@${userSWR.content.socialId}`}</SocialId>
            </div>
          </div>
          {pageSpec === "wide" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: 30,
                paddingBottom: 11,
                borderBottom: "1px solid rgba(255, 255, 255)",
              }}
            >
              <TabFrame
                selected={selectedTab === 0}
                onClick={() => {
                  setSelectedTab(0);
                  hopper.push("home-discover");
                }}
              >
                <Image
                  src={selectedTab === 0 ? iconDiscoverSelected : iconDiscover}
                  width={44}
                  height={44}
                />
                <div>{i18n.discover()}</div>
              </TabFrame>
              <TabFrame
                selected={selectedTab === 1}
                onClick={() => {
                  setSelectedTab(1);
                  hopper.push("my-circles");
                }}
              >
                <Image
                  src={
                    selectedTab === 1 ? iconMyCirclesSelected : iconMyCircles
                  }
                  width={44}
                  height={44}
                />
                <div>{i18n.my_circles()}</div>
              </TabFrame>
              <TabFrame
                selected={selectedTab === 2}
                onClick={() => {
                  setSelectedTab(2);
                  hopper.push("my-chats");
                }}
              >
                <Image
                  width={44}
                  height={44}
                  src={selectedTab === 2 ? iconMyChatsSelected : iconMyChats}
                />
                <div>{i18n.chat_my_chat_list()}</div>
              </TabFrame>
            </div>
          ) : null}

          <div
            style={{
              width: "calc(100% + 36px)",
              margin: "30px -18px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <CountCell
              onClick={() => {
                hopper.push(`social`, { type: FollowType.FRIEND });
              }}
            >
              <CellNum>{userSWR.content.friendsCount ?? 0}</CellNum>
              <CellTitle>{i18n.friends()}</CellTitle>
            </CountCell>
            <Line />
            <CountCell
              onClick={() => {
                hopper.push(`social`, { type: FollowType.FOLLOWER });
              }}
            >
              <CellNum>{userSWR.content.fansCount ?? 0}</CellNum>
              <CellTitle>{i18n.followers()}</CellTitle>
            </CountCell>
            <Line />
            <CountCell
              onClick={() => {
                hopper.push(`social`, { type: FollowType.FOLLOWING });
              }}
            >
              <CellNum>{userSWR.content.followingCount ?? 0}</CellNum>
              <CellTitle>{i18n.following()}</CellTitle>
            </CountCell>
            <Line />
            <CountCell
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                hopper.push(`recent-visitor/${myUid}`);
              }}
            >
              <CellNum>
                {userSWR.content.userVisitorInfo?.totalViewCount ?? 0}
              </CellNum>
              <CellTitle>{i18n.visitor_impr_visits()}</CellTitle>
            </CountCell>
          </div>

          <WalletFrame
            onClick={() => {
              hopper.push(`wallet`);
            }}
          >
            {(walletSWR.content?.latestTopUpOrder &&
              walletSWR.content.unreadTopUpOrderCount > 0) ||
            (walletSWR.content?.latestTransferOrder &&
              walletSWR.content?.unreadTransferOrderCount > 0) ? (
              <GiftReceived>
                <Image
                  src={
                    walletSWR.content.unreadTopUpOrderCount > 0
                      ? iconTopUp
                      : receivedIcon
                  }
                  style={{ width: 16, height: 16 }}
                />
                {walletSWR.content.unreadTopUpOrderCount > 0
                  ? i18n.web3_hongbao_top_up_succeeded()
                  : i18n.web3_v0_gift_received()}
              </GiftReceived>
            ) : null}

            <MyWalletTitle>{i18n.web3_v0_my_wallet()}</MyWalletTitle>
            <TokenCell>
              <TokenName>
                <GemsIcon style={{ width: 16, height: 16, margin: 0 }} />
                {i18n.web3_v0_diamonds()}
              </TokenName>
              <CurrencyAmount>
                {formatMoney(
                  "short",
                  walletSWR.content?.walletAccountList?.[0]?.currencyList?.find(
                    (cur) => cur.currencyType === CurrencyType.GEMS,
                  ),
                ) || 0}
              </CurrencyAmount>
            </TokenCell>
            <TokenCell>
              <TokenName>
                <Image
                  src={solIcon}
                  style={{ width: 16, height: 16, margin: 0 }}
                />
                SOL
              </TokenName>
              <CurrencyAmount>
                {formatMoney(
                  "short",
                  walletSWR.content?.walletAccountList?.[0]?.currencyList?.find(
                    (cur) => cur.currencyType === CurrencyType.SOL,
                  ),
                ) || 0}
              </CurrencyAmount>
            </TokenCell>

            {!!thirdCurrency ? (
              <TokenCell>
                <TokenName>
                  <Image
                    src={[thirdCurrency.icon, { width: 16, height: 16 }]}
                    style={{
                      width: 16,
                      height: 16,
                      margin: 0,
                      borderRadius: 2,
                    }}
                  />
                  {thirdCurrency.symbol}
                </TokenName>
                <CurrencyAmount>
                  {formatMoney("short", thirdCurrency) || 0}
                </CurrencyAmount>
              </TokenCell>
            ) : null}
          </WalletFrame>

          <SettingCell
            title={i18n.settings()}
            onClick={() => hopper.push("settings")}
          />
          {pageSpec !== "wide" && (
            <SettingCell
              title={i18n.clover_get_app()}
              onClick={() => hopper.modal(`nyi`, { getApp: true })}
            />
          )}
          {isSpongeKit() && (
            <SettingCell
              title={"Demo"}
              onClick={() => hopper.push("debug-home")}
            />
          )}
        </>
      ) : null}
    </Page>
  );
}

const GiftReceived = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  padding: 3px;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 11px;
  width: fit-content;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 0 12px 0 4px;
`;

const CurrencyAmount = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
`;

const MyWalletTitle = styled.div`
  color: white;
  font-size: 14px;
  font-weight: 700;
`;

const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  margin: 15px auto;
`;

const InactiveFrame = styled.div`
  width: fit-content;
  height: 13px;
  padding: 3px 9px;
  position: absolute;
  top: 0;
  right: 0;
  color: #2e2748;
  text-align: center;
  font-size: 10px;
  font-weight: 600;
  background: #d7d7d7;
  border-radius: 0 8px 0 8px;
`;

const CheckInTitle = styled.div`
  display: flex;
  flex-direction: row;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  justify-content: space-between;
`;

const CheckInFrame = styled.div`
  border-radius: 8px;
  stroke-width: 0.5px;
  padding: 10px;
  width: calc(100% - 20px);
  min-height: 197px;
  position: relative;
  margin-top: 18px;
  display: flex;
  flex-direction: column;
`;

const PrimeTitle = styled.div`
  color: rgba(60, 60, 60, 0.5);
  font-size: 16px;
  font-weight: 700;
`;

const PrimeDesc = styled.div`
  color: rgba(60, 60, 60, 0.5);
  text-align: center;
  font-size: 12px;
  width: 100%;
  font-weight: 400;
`;

const PrimeFrame = styled.div<{ active?: boolean }>`
  border-radius: 8px;
  background: #aaa;
  backdrop-filter: blur(2px);
  padding: 10px;
  width: calc(100% - 20px);
  min-height: 78px;
  position: relative;
  margin-top: 18px;
  display: flex;
  flex-direction: column;
`;

const TabFrame = styled.div<{ selected: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  width: calc(100% - 16px);
  height: 28px;
  padding: 8px;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  background: ${(p) =>
    p.selected ? `rgba(255, 255, 255, 0.1)` : `transparent`};
`;

const TokenName = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 6px;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
`;

const TokenCell = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

const WalletFrame = styled.div`
  width: calc(100% - 16px);
  display: flex;
  position: relative;
  border-radius: 6px;
  padding: 10px 14px;
  gap: 11px;
  flex-direction: column;
  background: transparent
    linear-gradient(
      -30deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0.03) 55%,
      rgba(255, 255, 255, 0.1) 100%
    );
  border-image: ${linear_gradient_border(
    12,
    1,
    -30,
    [colorSetAlpha("white", 0.5), 0],
    [colorSetAlpha("white", 0.1), 0.4],
    [colorSetAlpha("white", 0.1), 0.6],
    [colorSetAlpha("white", 0.5), 1],
  )};
`;
const Line = styled.div`
  width: 1px;
  height: 11px;
  margin: auto 0;
  flex-shrink: 0;
  background: rgba(255, 255, 255, 0.25);
`;

const CellNum = styled.div`
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
`;

const CellTitle = styled.div`
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  font-size: 12px;
  font-weight: 400;
`;

const CountCell = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1 0 0;
  gap: 6px;
`;
const SocialId = styled.div`
  color: #fff;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
`;

const ZCard = styled.div`
  width: 100%;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 210px;
  background: blueviolet;
`;
