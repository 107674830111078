import { Page } from "../../../components/Page";
import { useBigIntParam } from "../../../hooks/useTypedParam";
import { useBackend } from "../../../service/APIService";
import { useSWR } from "../../../hooks/swr/useSWR";
import { NavMiddle } from "../../../components/NavBar";
import { useI18n } from "../../../hooks/useI18n";
import { FullPageWidthCellNoPadding } from "../../../components/CommonStyles";
import SettingCell from "../../../components/SettingCell";
import Switch from "../../settings/components/Switch";
import { ButtonColor, RegularButton } from "../../../components/Buttons";
import { ChatSettingsSectionTitle } from "../../common/ChatBubblePicker";
import { useHopper } from "../../../hooks/useHopper";
import { isChatThreadCircleRoom } from "../../../proto/Chat";

function ChatSettingsPage() {
  const threadId = useBigIntParam("threadId");
  const backend = useBackend();
  const i18n = useI18n();
  const hopper = useHopper();

  const thread = useSWR(["thread-info", threadId], () =>
    backend.getThreadInfo(threadId, true).run()
  );

  return (
    <Page pageData={thread}>
      <NavMiddle>{i18n.jun1_text_admin_portal()}</NavMiddle>
      <ChatSettingsSectionTitle>{i18n.general()}</ChatSettingsSectionTitle>
      <SettingCell
        title={i18n.party_on_party_info()}
        style={FullPageWidthCellNoPadding}
        onClick={() => hopper.push(`chat/${threadId}/settings/info`)}
      />
      <SettingCell
        title={i18n.circle2_1_appearance()}
        style={FullPageWidthCellNoPadding}
        onClick={() => hopper.push(`chat/${threadId}/settings/appearance`)}
      />
      {thread.content !== undefined &&
        !isChatThreadCircleRoom(thread.content) && (
          <>
            <SettingCell
              title={i18n.circle2_1_permission()}
              style={FullPageWidthCellNoPadding}
              onClick={() =>
                hopper.push(`chat/${threadId}/settings/permissions`)
              }
            />
            <SettingCell
              title={i18n.privacy()}
              style={FullPageWidthCellNoPadding}
              onClick={() => hopper.push(`chat/${threadId}/settings/privacy`)}
            />
          </>
        )}
      <SettingCell
        title={i18n.party_off_impr_auto_offline()}
        secondaryValue={i18n.party_on_your_party_will_be_offline()}
        endValue={<Switch />}
        style={FullPageWidthCellNoPadding}
        showAccessoryImage={false}
      />
      <ChatSettingsSectionTitle>{i18n.members()}</ChatSettingsSectionTitle>
      <SettingCell
        title={i18n.all_members_in_live_chat()}
        style={FullPageWidthCellNoPadding}
      />
      <SettingCell
        title={i18n.blocked_members()}
        style={FullPageWidthCellNoPadding}
      />
      {thread.content !== undefined &&
        !isChatThreadCircleRoom(thread.content) && (
          <>
            <ChatSettingsSectionTitle>
              {i18n.circle2_1_management_team()}
            </ChatSettingsSectionTitle>
            <SettingCell
              title={i18n.manage_co_admin()}
              style={FullPageWidthCellNoPadding}
            />
            <SettingCell
              title={i18n.transfer_admin()}
              style={FullPageWidthCellNoPadding}
            />
            <SettingCell
              title={i18n.feb_2023_impr_management_operation_records()}
              style={FullPageWidthCellNoPadding}
            />
            <RegularButton
              $baseColor={ButtonColor.destructive}
              style={{ marginTop: "30px" }}
            >
              {i18n.party_on_delete_this_party()}
            </RegularButton>
          </>
        )}
    </Page>
  );
}

export default ChatSettingsPage;
