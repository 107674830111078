import styled from "styled-components";
import { GemsIcon } from "../wallet/send/ShopTransferPage";
import { Wallet } from "../../proto/Wallet";
import solIcon from "../../res/images/ic_sol.svg";
import { CurrencyType, formatMoney } from "../../proto/Currency";
export interface IWalletBar {
  wallet?: Wallet;
  onClick: () => void;
}

function WalletActionBar(props: IWalletBar) {
  return (
    <Frame onClick={props.onClick}>
      <GemsIcon style={{ width: 16, height: 16, margin: 0 }} />
      <Amount>
        {formatMoney(
          "short",
          props.wallet?.walletAccountList?.[0]?.currencyList?.find(
            (cur) => cur.currencyType === CurrencyType.GEMS,
          ),
        ) || 0}
      </Amount>
      <SolIcon />
      <Amount>
        {formatMoney(
          "short",
          props.wallet?.walletAccountList?.[0]?.currencyList?.find(
            (cur) => cur.currencyType === CurrencyType.SOL,
          ),
        ) || 0}
      </Amount>
    </Frame>
  );
}

const SolIcon = styled.div`
  width: 16px;
  height: 16px;
  background-size: 100%;
  background-repeat: no-repeat;
  margin-inline-start: 4px;
  background-image: url("${solIcon}");
`;

const Amount = styled.div`
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
`;

const Frame = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
  padding: 6px 8px;
  border-radius: 6px;
  background: rgba(45, 45, 45, 0.7);
`;

export default WalletActionBar;
