import { Dice } from "../../../proto/Dice";
import { useSWR } from "../../../hooks/swr/useSWR";
import { Page } from "../../../components/Page";
import { VStack } from "../../../components/VStack";
import { shrink_on_pressed } from "../../../components/CommonStyles";
import { Image } from "../../../components/Image";
import { useBackend } from "../../../service/APIService";
import GridLayout from "../../../components/GridLayout";

export default function DicePicker(props: {
  onSelectDice: (dice: Dice) => void;
}) {
  const backend = useBackend();
  const dices = useSWR(backend.getDices());

  return (
    <>
      {dices.content?.diceList && (
        <GridLayout
          spanCount={3}
          items={dices.content.diceList.map((dice) => (
            <>
              <VStack
                key={dice.diceId}
                style={{ alignItems: "center", fontSize: "13px" }}
                onClick={() => props.onSelectDice(dice)}
                mixin={shrink_on_pressed}
              >
                <Image
                  src={[dice.icon, "smallest"]}
                  key={dice.diceId}
                  style={{ objectFit: "cover", maxWidth: "80%" }}
                />
                {dice.name}
              </VStack>
            </>
          ))}
        ></GridLayout>
      )}
    </>
  );
}
