import { z } from "zod";
import { zEnum, zStatic } from "../utils/zodUtils";
import { Media } from "./Media";

export enum DisplayModeEnum {
  FILL,
  FIT
}

export const CircleBackground = z.object({
  displayMode: zEnum(DisplayModeEnum),
  backgroundImage: Media.optional(),
});
export type CircleBackground = zStatic<typeof CircleBackground>;
