import { EventName } from "../bridge/EventName";
import { DependencyList, useEffect, useId } from "react";
import { useNativePage } from "./useBridge";
import { andLog } from "../components/handleError";

export function useOnMorePaymentBroadcast<T extends object = {}>(
  listener: (param: T) => void,
  deps: DependencyList,
) {
  const nativePage = useNativePage();
  const eventName = "ON_MORE_PAYMENT";
  const id = "ON_MORE_MENU";

  useEffect(() => {
    nativePage
      .listenToBroadcast(id, eventName, (param) => {
        console.log("Broadcast Received", {
          eventName: eventName,
          param: param,
        });
        listener(param as T);
      })
      .catch(andLog);

    return () => {
      nativePage.unlistenToBroadcast(id, eventName).catch(andLog);
    };
  }, deps);
}
