import { HSpace, HStack } from "./VStack";
import styled from "styled-components";
import { InfoLabel, RoundCornerWhite08R6 } from "./CommonViews";
import { useManualLayout } from "../hooks/useManualLayout";
import { CSSProperties, HTMLInputTypeAttribute } from "react";
import {
  getCurrentDecimalSeparator,
  getCurrentGroupSeparator,
} from "../utils/NumberI18n";

const InputContainer = styled.div`
  position: relative;
  flex-grow: 1;

  overflow: clip;
`;

const Input = styled.input<{
  cssProperties?: CSSProperties;
  placeholderStyle?: {
    fontSize?: string;
    fontWeight?: string;
    fontFamily?: string;
    color?: string;
  };
}>`
  height: 100%;

  box-sizing: border-box;
  border: none;
  font-size: ${(props) => props.cssProperties?.fontSize || "22px"};
  font-weight: ${(props) => props.cssProperties?.fontWeight || 500};
  font-family: ${(props) =>
    props.cssProperties?.fontFamily || "DIN, sans-serif"};
  -webkit-tap-highlight-color: transparent;
  padding: 0;
  margin: 0;

  text-align: end;
  background: transparent;
  color: ${(props) => props.cssProperties?.color || "var(--color-text00)"};

  &::placeholder {
    ${(props) =>
      props.placeholderStyle &&
      `
      font-size: ${props.placeholderStyle.fontSize};
      font-weight: ${props.placeholderStyle.fontWeight};
      font-family: ${props.placeholderStyle.fontFamily};
      color: ${props.placeholderStyle.color};
    `}
  }
`;

export function NumberInput(props: {
  title?: string;
  value?: string;
  type?: HTMLInputTypeAttribute;
  placeholder?: string;
  decimal: boolean;
  textAlign?: CanvasTextAlign;
  onTextChange: (value: string) => void;
  cssProperties?: CSSProperties;
  placeholderStyle?: {
    fontSize?: string;
    fontWeight?: string;
    fontFamily?: string;
    color?: string;
  };
}) {
  const [containerRef, toLayout] = useManualLayout<
    HTMLDivElement,
    HTMLInputElement,
    "width"
  >(["width"], (cause, element) => {
    element.style.width = `${cause.width}px`;
  });

  return (
    <RoundCornerWhite08R6 style={props.cssProperties}>
      <HStack style={{ width: "100%" }}>
        {props.title && (
          <>
            <InfoLabel>{props.title}</InfoLabel>
            <HSpace width={8} />
          </>
        )}

        <InputContainer ref={containerRef}>
          <Input
            type={props?.type ?? "text"}
            inputMode={props.decimal ? "decimal" : "numeric"}
            placeholder={props.placeholder}
            value={props.value ?? ""}
            style={{ width: "100%", textAlign: props.textAlign }}
            ref={(e) => toLayout(e)}
            cssProperties={props.cssProperties}
            placeholderStyle={props.placeholderStyle}
            onChange={(e) => {
              let value = e.target.value;
              const decimalSep = getCurrentDecimalSeparator();
              const groupSep = getCurrentGroupSeparator();
              value.split(groupSep).join("");
              const decimalSepCount = value.split(decimalSep).length - 1;
              if (value.startsWith(decimalSep)) {
                value = "0" + value;
              }
              if (decimalSepCount > 1) {
                let index = value.indexOf(decimalSep);
                value = value.split(decimalSep).join("");
                value = [
                  value.slice(0, index),
                  decimalSep,
                  value.slice(index),
                ].join("");
              }

              if (decimalSepCount == 0 && value.startsWith("0")) {
                value = value.replace(/^0+/, "");
                if (value.length == 0) {
                  value = "0";
                }
              }
              props.onTextChange(value);
            }}
          />
        </InputContainer>
      </HStack>
    </RoundCornerWhite08R6>
  );
}
