import { Page } from "../../../components/Page";
import { useI18n } from "../../../hooks/useI18n";
import { useEditingBigNumber } from "../../../hooks/useEditingBigNumber";
import { useBigIntParam } from "../../../hooks/useTypedParam";
import { useNativePage } from "../../../hooks/useBridge";
import { useTransactionBody } from "../../wallet/send/SendTokenPage";
import { NavMiddle } from "../../../components/NavBar";
import { RegularInputGroup } from "../../../components/Input";
import { HStack } from "../../../components/VStack";
import {
  CurrencyType,
  formatMoney,
  getCurrencyAmount,
  getCurrencyIcon,
} from "../../../proto/Currency";
import { PageFooter } from "../../../components/PageHeaderFooter";
import { RegularButton } from "../../../components/Buttons";
import {
  alpha_on_pressed,
  RowCenterButton,
} from "../../../components/CommonStyles";
import React from "react";
import styled from "styled-components";
import downIcon from "../../../res/images/chevorn_down.svg";
import { useSWR } from "../../../hooks/swr/useSWR";
import { useHopper } from "../../../hooks/useHopper";
import { useBackend } from "../../../service/APIService";
import { Image } from "../../../components/Image";
import { andLog } from "../../../components/handleError";
import BigNumber from "bignumber.js";
import { jsonifyBigNumber } from "../../../utils/CurrencyAmount";
import { useRegularAccount } from "../../wallet/useRegularAccount";
import { useAssetAccount } from "../../wallet/useAssetAccount";
import {
  DEFAULT_PRIORITY_FEE,
  DEFAULT_SLIPPAGE,
} from "../../wallet/payment/PaymentServiceFee";

function AdminEarningTransferPage() {
  const i18n = useI18n();
  const [amountInput, setAmountInput] =
    useEditingBigNumber(`transfer-earning}`);
  const nativePage = useNativePage();
  const editingBody = useTransactionBody({}, "admin-earning-transfer");
  const hopper = useHopper();
  const backend = useBackend();
  const circleId = useBigIntParam("circleId");
  const adminEarningSWR = useSWR(backend.getAdminEarningInfo(circleId));
  const gemsAssetAccount = useAssetAccount(false);
  const solAssetAccount = useAssetAccount(true);
  const next = async () => {
    if (amountInput === "0") {
      nativePage
        .infoHud(i18n.web3_v0_impr2_number_bigger_than_zero())
        .catch(andLog);
      return;
    }
    if (!amountInput || amountInput.length === 0) {
      nativePage.infoHud(i18n.web3_v0_fill_all_fields()).catch(andLog);
      return;
    }
    const serverAmount = new BigNumber(
      jsonifyBigNumber(
        BigNumber(amountInput ?? "0"),
        adminEarningSWR.content?.currency?.decimals || 18,
      ),
    );
    if (
      serverAmount.isGreaterThan(
        BigNumber(adminEarningSWR.content?.currency?.amount || 0),
      ) ||
      !adminEarningSWR.content?.currency
    ) {
      nativePage.infoHud(i18n.clover_dont_have_enough_balance()).catch(andLog);
      return;
    }
    const gasType = editingBody.content.gasFeeCurrencyType;
    const payAccountId =
      gasType === CurrencyType.GEMS
        ? gemsAssetAccount?.accountId
        : solAssetAccount?.accountId;
    await editingBody.fill((preData) => ({
      ...preData,
      currencyId: adminEarningSWR.content?.currency?.currencyId,
      currency: adminEarningSWR.content?.currency,
      amount: jsonifyBigNumber(
        BigNumber(amountInput ?? "0"),
        adminEarningSWR.content?.currency?.decimals || 18,
      ),
      currencyType:
        adminEarningSWR.content?.currency?.currencyType || CurrencyType.GEMS,
      decimals: adminEarningSWR.content?.currency?.decimals ?? 18,
      toAccountId: solAssetAccount?.accountId,
      slippage: DEFAULT_SLIPPAGE,
      priorityFee: DEFAULT_PRIORITY_FEE,
      payAssetAccountId: payAccountId,
      fromAccountId: payAccountId,
      fromAssetAccountId: payAccountId,
    }));

    hopper.layer(`circle/${circleId}/transfer-earning-summary`);
  };
  return (
    <Page alwaysShowsContent pageData={adminEarningSWR}>
      <NavMiddle>{i18n.transfer()}</NavMiddle>
      <Title>{i18n.web3_v0_amount()}</Title>
      <RegularInputGroup
        placeholder={i18n.web3_v0_amount()}
        value={amountInput}
        updateValue={setAmountInput}
        type={"number"}
        startReactNode={
          <HStack>
            <Image
              src={getCurrencyIcon(adminEarningSWR.content?.currency)}
              style={{
                width: 24,
                height: 24,
                marginLeft: 12,
                marginRight: 8,
                borderRadius: 4,
              }}
            />
            <VLine />
          </HStack>
        }
        endReactNode={
          <Max
            onClick={() => {
              setAmountInput(
                String(getCurrencyAmount(adminEarningSWR.content?.currency)),
              );
            }}
          >
            {i18n.web3_v0_max()}
          </Max>
        }
      />
      <HStack style={{ marginTop: 6 }}>
        <AvailableTitle>{i18n.web3_v0_available()}</AvailableTitle>
        <Image
          src={getCurrencyIcon(adminEarningSWR.content?.currency)}
          style={{
            width: 16,
            height: 16,
            margin: "auto 5px auto 10px",
            borderRadius: 2,
          }}
        />
        <AvailableAmount>
          {`${formatMoney("medium", adminEarningSWR.content?.currency)} ${adminEarningSWR.content?.currency?.symbol ?? ""}`}
        </AvailableAmount>
      </HStack>

      <Title style={{ marginTop: 32 }}>
        {i18n.march_impr_2023_destination_account()}
      </Title>
      <AccountFrame>
        <AccountName>{i18n.arthur_wallet_built_in_account()}</AccountName>
        <DownIcon />
      </AccountFrame>

      <PageFooter obscuredZoneKey={"SendNext"}>
        <RegularButton
          $baseBgColor={"var(--color-bg)"}
          onClick={next}
          style={{
            ...RowCenterButton,
            marginTop: 8,
          }}
        >
          {i18n.next()}
        </RegularButton>
      </PageFooter>
    </Page>
  );
}

const DownIcon = styled.div`
  width: 26px;
  height: 26px;
  margin: auto 8px;
  background-image: url("${downIcon}");
`;

const AccountName = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  margin: 14px 12px;
`;

const AccountFrame = styled.div`
  width: 100%;
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  justify-content: space-between;
`;

const AvailableAmount = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: #ffffff;
`;

const AvailableTitle = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.4);
`;

const VLine = styled.div`
  height: 13px;
  width: 0.5px;
  margin: auto 0;
  background: rgba(255, 255, 255, 0.2);
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
`;

const Max = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: #00c4ff;
  padding: 15px 12px;
  ${alpha_on_pressed};
`;

export default AdminEarningTransferPage;
