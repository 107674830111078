import { useLayoutEffect, useMemo, useState } from "react";

export function useResizeObserver() {
  const [element, ref] = useState<Element | null>(null);
  const [result, setResult] = useState<ResizeObserverEntry>();

  const observer = useMemo(
    () =>
      new ResizeObserver((entries) => {
        if (entries[0]) {
          setResult(entries[0]);
        }
      }),
    [setResult],
  );

  useLayoutEffect(() => {
    if (!element) return;
    observer.observe(element);
    return () => {
      observer.disconnect();
    };
  }, [element]);

  return [ref, result] as const;
}

export function useBorderBoxSize() {
  const [ref, entry] = useResizeObserver();
  const size = entry?.borderBoxSize[0];
  return [
    ref,
    size
      ? {
          width: size.inlineSize,
          height: size.blockSize,
        }
      : undefined,
  ] as const;
}
