import styled from "styled-components";

interface ColoredLabelProps {
  backgroundColor?: string;
  color?: string;
}

const ColoredLabel = styled.div<ColoredLabelProps>`
  font-weight: 400;
  font-size: 8px;
  text-align: center;
  padding: 1px 2px;
  align-items: center;
  color: ${({ color }) => color || "var(--color-text00)"};
  background-color: ${({ backgroundColor }) => backgroundColor || "green"};
  border-radius: 4px;
`;

export default ColoredLabel;
