import { Page } from "../../../components/Page";
import {
  useBigIntParam,
  useBooleanSearchParam,
} from "../../../hooks/useTypedParam";
import { useSWRArray } from "../../../hooks/swr/useSWRArray";
import { useBackend } from "../../../service/APIService";
import { CircleRole, User } from "../../../proto/User";
import { HStack, Spring, VStack } from "../../../components/VStack";
import { UserIconView } from "../../../components/views/UserAvatarView";
import { CircleMembershipLevelIcon } from "../membership/MembershipLevelPicker";
import { Image } from "../../../components/Image";
import IcAdd from "./imgs/ic_add_round.svg";
import {
  alpha_on_pressed,
  cell_bottom_full_divider,
} from "../../../components/CommonStyles";
import { NavMiddle } from "../../../components/NavBar";
import { useI18n } from "../../../hooks/useI18n";
import { useProduceResult } from "../../../hooks/useResult";
import { useHopper } from "../../../hooks/useHopper";
import { andLog } from "../../../components/handleError";
import { UserName } from "../../../components/views/UserNameView";
import { ObjectStatus } from "../../../proto/ObjectStatus";

function UserCell(props: { user: User; onPick: () => void }) {
  return (
    <HStack
      style={{ height: 80, gap: 14, overflow: "hidden" }}
      mixin={[cell_bottom_full_divider, alpha_on_pressed]}
      onClick={props.onPick}
    >
      <UserIconView user={props.user} iconSize={45} />
      <VStack style={{ flexShrink: 1 }}>
        <HStack
          style={{ overflow: "hidden", flexShrink: 1, alignItems: "center" }}
        >
          <UserName>{props.user.nickname}</UserName>
          <CircleMembershipLevelIcon
            level={props.user.circleMembershipLevel ?? 0}
            width={28}
          />
        </HStack>
        <div>{props.user.socialId}</div>
      </VStack>
      <Spring />
      <Image src={IcAdd} style={{ width: 24, height: 24 }} />
    </HStack>
  );
}

export function CircleMemberPicker() {
  const circleId = useBigIntParam("circleId");
  const showAdmin = useBooleanSearchParam("showAdmin") ?? true;
  const hopper = useHopper();
  const i18n = useI18n();
  const backend = useBackend();
  const members = useSWRArray(
    circleId ? backend.getCircleMembers(circleId) : undefined,
  );

  const produce = useProduceResult<User>("CircleMemberPicker");

  return (
    <Page pageData={members}>
      <NavMiddle>{i18n.clover_select_members()}</NavMiddle>
      {members?.content
        .filter((user) => {
          if (!showAdmin && user.circleRole === CircleRole.Admin) {
            return false;
          }
          return !(
            user.status === ObjectStatus.DELETED ||
            user.status === ObjectStatus.DISABLED
          );
        })
        .map((user) => (
          <UserCell
            key={user.uid}
            user={user}
            onPick={() =>
              produce
                .fill(user)
                .then(() => hopper.back())
                .catch(andLog)
            }
          />
        ))}
    </Page>
  );
}
