import styled from "styled-components";
import weibo from "../../res/images/ic_weibo.svg";
import twitch from "../../res/images/ic_twitch.svg";
import discord from "../../res/images/ic_discord.svg";
import twitter from "../../res/images/ic_twitter.svg";
import instagram from "../../res/images/ic_instagram.svg";
import tiktok from "../../res/images/ic_tiktok.svg";
import youtube from "../../res/images/ic_youtube.svg";
import reddit from "../../res/images/ic_reddit.svg";
import { ExtSocialLink, SocialPlatformType } from "../../proto/SocialLinksResp";
import { useHopper } from "../../hooks/useHopper";

export interface SocialProps {
  extSocial?: ExtSocialLink;
}

function ExtSocialCell(props: SocialProps) {
  const hopper = useHopper();
  const getSocialIcon = (): string => {
    switch (props.extSocial?.platformType) {
      case SocialPlatformType.Weibo:
        return weibo;
      case SocialPlatformType.Twitch:
        return twitch;
      case SocialPlatformType.YouTube:
        return youtube;
      case SocialPlatformType.Discord:
        return discord;
      case SocialPlatformType.Twitter:
        return twitter;
      case SocialPlatformType.Instagram:
        return instagram;
      case SocialPlatformType.TikTok:
        return tiktok;
      case SocialPlatformType.Reddit:
        return reddit;
      default:
        return "";
    }
  };
  return (
    <SocialFrame
      $icon={getSocialIcon()}
      onClick={() => {
        const link = props.extSocial?.link;

        // 如果链接已经包含协议，就直接使用；否则添加默认的 https://
        const url =
          link?.startsWith("http://") || link?.startsWith("https://")
            ? new URL(link)
            : new URL(`https://${link}`);
        console.log("@_@ open link,", url.toString());
        window.open(url.toString());
      }}
    />
  );
}

export default ExtSocialCell;
const SocialFrame = styled.div<{ $icon: string }>`
  width: 30px;
  height: 30px;
  background-image: ${(p) => `url(${p.$icon})`};
`;
