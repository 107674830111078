import { Page } from "../../components/Page";
import {
  useEnumSearchParam,
  useStringSearchParam,
} from "../../hooks/useTypedParam";
import { AuthBody, AuthType } from "../../proto/Auth";
import { NavEnd, NavItem } from "../../components/NavBar";
import { AuthTitleLabel } from "./InvitationCodePage";
import React, { useRef, useState } from "react";
import { useI18n } from "../../hooks/useI18n";
import { useHopper } from "../../hooks/useHopper";
import { VSpace } from "../../components/VStack";
import { RegularInputGroup } from "../../components/Input";
import { RegularButton } from "../../components/Buttons";
import { useLoadState } from "../../hooks/LoadState";
import { Spin } from "../../components/Spin";
import { useBackend } from "../../service/APIService";
import { useErrorHandler } from "../../components/handleError";
import { useSWR } from "../../hooks/swr/useSWR";

export enum OAuthType {
  Bind = 1,
  Unbind = 2,
}

export function ThirdPartAccountConfirmPasswordPage() {
  const type = useEnumSearchParam<OAuthType>("type");
  const appleCode = useStringSearchParam("appleAuthorizationCode");
  const googleCode = useStringSearchParam("googleCode");
  const authType = useEnumSearchParam<AuthType>("authType");

  const loadState = useLoadState();

  const i18n = useI18n();
  const hopper = useHopper();
  const backend = useBackend();
  const handleError = useErrorHandler();
  const account = useSWR(backend.account(), {
    reloadPolicy: "refetchOnCacheMiss",
  });

  const [confirmEnabled, setConfirmEnabled] = useState(false);
  const passwordRef = useRef<string>("");

  const onEditPassword = (newValue: string) => {
    setConfirmEnabled(newValue.length >= 6);
    passwordRef.current = newValue;
  };

  async function onClickConfirm() {
    if (loadState.state?.kind === "loading") {
      return;
    }
    const authInfo: AuthBody = {
      appleAuthorizationCode: appleCode,
      googleCode: googleCode,
      authType: authType,
      googleRedirectUrl: window.location.origin,
      password: passwordRef.current,
    };
    const result = await loadState.run(async () => {
      switch (type) {
        case OAuthType.Bind:
          await backend.authBind(authInfo).run();
          break;
        case OAuthType.Unbind:
          await backend.authUnbind(authInfo).run();
          break;
      }
      await account.load();
    });
    if (result.error) {
      handleError(result.error);
    } else {
      hopper.dismissLayer();
    }
  }

  return (
    <Page pageData={undefined}>
      <NavEnd>
        {NavItem.text(i18n.help(), () => hopper.push("feedback"))}
      </NavEnd>
      <AuthTitleLabel>{i18n.confirm_your_password()}</AuthTitleLabel>
      <VSpace height={20} />
      <RegularInputGroup
        type={"password"}
        updateValue={onEditPassword}
        placeholder={i18n.password()}
      />
      <VSpace height={20} />
      <RegularButton disabled={!confirmEnabled} onClick={onClickConfirm}>
        <Spin state={loadState.state}>{i18n.confirm()}</Spin>
      </RegularButton>
    </Page>
  );
}
