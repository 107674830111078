import { ensureRepo, ensureSubscription, RepoDataSource } from "./SWRRepo";
import { flattenStateId, StateId } from "../StateId";
import { LoadState } from "../LoadState";
import { useMyUid } from "../../service/AuthSessionService";
import {
  SetStateAction,
  useCallback,
  useEffect,
  useId,
  useMemo,
  useState,
} from "react";
import { omit } from "../../utils/pick";
import {
  evalInitializer,
  Initializer,
  isSetActionFunction,
  SetStateActionOptionalPrev,
} from "../Initializer";
import { useRepoStoreBuilder } from "../../service/RepoStoreService";
import { MapKey, useSetValueForMap } from "../useMap";
import { ZodType } from "zod/lib/types";
import { zStatic } from "../../utils/zodUtils";
import { z } from "zod";

type LocalRepoData<T> = {
  content: T;
  source: RepoDataSource;
  updatedAt: number;
};

export type LocalRepoResult<T extends {}> = {
  contentId: StateId;
  content: T;
  meta: { source: RepoDataSource; updatedAt: number };
  loadState: LoadState | undefined;
  fill: (contentOrSetContent: SetStateAction<T>) => Promise<T>;
  clear: () => Promise<void>;
};

type LocalRepoOptionalResult<T extends {}> = {
  contentId: StateId;
  content: T | undefined;
  meta: { source: RepoDataSource; updatedAt: number };
  loadState: LoadState | undefined;
  fill: (
    contentOrSetContent: SetStateActionOptionalPrev<T>,
  ) => Promise<T | undefined>;
  clear: () => Promise<void>;
};

export function useLocalRepo<T extends {}>(
  contentId: StateId,
): LocalRepoOptionalResult<T>;
export function useLocalRepo<T extends {}>(
  contentId: StateId | undefined,
): LocalRepoOptionalResult<T> | undefined;
export function useLocalRepo<T extends {}>(
  contentId: StateId,
  initialContent: Initializer<T>,
): LocalRepoResult<T>;
export function useLocalRepo<T extends {}>(
  contentId: StateId | undefined,
  initialContent: Initializer<T>,
): LocalRepoResult<T> | undefined;
export function useLocalRepo<T extends {}>(
  contentId: StateId | undefined,
  initialContent?: Initializer<T | undefined>,
): LocalRepoOptionalResult<T> | undefined {
  return useRepo<T>(false, contentId, initialContent);
}

export function useMemoryRepo<T extends {}>(
  contentId: StateId,
): LocalRepoOptionalResult<T>;
export function useMemoryRepo<T extends {}>(
  contentId: StateId | undefined,
): LocalRepoOptionalResult<T> | undefined;
export function useMemoryRepo<T extends {}>(
  contentId: StateId,
  initialContent: Initializer<T>,
): LocalRepoResult<T>;
export function useMemoryRepo<T extends {}>(
  contentId: StateId | undefined,
  initialContent: Initializer<T>,
): LocalRepoResult<T> | undefined;
export function useMemoryRepo<T extends {}>(
  contentId: StateId | undefined,
  initialContent?: Initializer<T | undefined>,
): LocalRepoOptionalResult<T> | undefined {
  return useRepo<T>(true, contentId, initialContent);
}

function useRepo<T extends {}>(
  memoryOnly: boolean,
  contentId: StateId | undefined,
  initialContent?: Initializer<T | undefined>,
): LocalRepoOptionalResult<T> | undefined {
  const myUid = useMyUid();

  const repoId = contentId
    ? "LocalRepo." + flattenStateId([contentId, myUid])
    : undefined;

  const subscriberId = useId();

  const storeBuilder = useRepoStoreBuilder();

  const repo = repoId
    ? ensureRepo<T, undefined>(
        repoId,
        memoryOnly
          ? storeBuilder.createMemoryRepoStore(repoId)
          : storeBuilder.createLocalRepoStore(repoId),
        undefined,
      )
    : undefined;

  const [data, setData] = useState<LocalRepoData<T | undefined>>({
    content: evalInitializer(initialContent),
    source: "init",
    updatedAt: new Date().getTime(),
  });

  const [loadState, setLoadState] = useState<LoadState>();

  useEffect(() => {
    if (repoId && repo) {
      ensureSubscription(subscriberId, repo, "neverRefetch", {
        onDataDelete() {
          setData({
            content: evalInitializer(initialContent),
            source: "init",
            updatedAt: new Date().getTime(),
          });
        },
        onDataChange(data: LocalRepoData<T>, fromCache: boolean) {
          setData(data);
        },
        onLoadStateChange(loadState: LoadState | undefined) {
          setLoadState(loadState);
        },
      });
    }
    return () => {
      repo?.unsubscribe(subscriberId);
    };
  }, [repo, subscriberId, setData, setLoadState]);

  const fill = useCallback(
    async (contentOrSetContent: SetStateActionOptionalPrev<T>) => {
      if (isSetActionFunction(contentOrSetContent)) {
        const result = await repo?.fill((prev) => {
          const next = contentOrSetContent(
            prev !== undefined ? prev.content : evalInitializer(initialContent),
          );
          return {
            content: next,
            cursor: undefined,
            hasMore: false,
          };
        });
        return result?.content;
      } else {
        const result = await repo?.fill({
          content: contentOrSetContent,
          cursor: undefined,
          hasMore: false,
        });
        return result?.content;
      }
    },
    [repo],
  );

  if (repo && contentId) {
    return {
      contentId: contentId,
      content: data.content,
      meta: omit(data, ["content"]),
      loadState,
      fill,
      clear: async () => {
        await repo.clear();
      },
    };
  } else {
    return undefined;
  }
}

type LocalRepoMapResult<K extends MapKey, T extends {}> = {
  map: ReadonlyMap<K, T>;
  setValue: (key: K, action: SetStateAction<T | undefined>) => Promise<void>;
  loadState: LoadState | undefined;
  meta: { source: RepoDataSource; updatedAt: number };
  contentId: StateId;
  clear: () => Promise<void>;
};

export function useMemoryRepoMap<
  KS extends ZodType<MapKey, any, any>,
  S extends ZodType<any, any, any>,
>(
  keySchema: KS,
  valueSchema: S,
  contentId: StateId,
): LocalRepoMapResult<zStatic<KS>, zStatic<S>>;
export function useMemoryRepoMap<
  KS extends ZodType<MapKey, any, any>,
  S extends ZodType<any, any, any>,
>(
  keySchema: KS,
  valueSchema: S,
  contentId: StateId | undefined,
): LocalRepoMapResult<zStatic<KS>, zStatic<S>> | undefined;
export function useMemoryRepoMap<
  KS extends ZodType<MapKey, any, any>,
  S extends ZodType<any, any, any>,
>(
  keySchema: KS,
  valueSchema: S,
  contentId: StateId | undefined,
): LocalRepoMapResult<zStatic<KS>, zStatic<S>> | undefined {
  return useRepoMap(true, keySchema, valueSchema, contentId);
}

export function useLocalRepoMap<
  KS extends ZodType<MapKey, any, any>,
  S extends ZodType<any, any, any>,
>(
  keySchema: KS,
  valueSchema: S,
  contentId: StateId,
): LocalRepoMapResult<zStatic<KS>, zStatic<S>>;
export function useLocalRepoMap<
  KS extends ZodType<MapKey, any, any>,
  S extends ZodType<any, any, any>,
>(
  keySchema: KS,
  valueSchema: S,
  contentId: StateId | undefined,
): LocalRepoMapResult<zStatic<KS>, zStatic<S>> | undefined;
export function useLocalRepoMap<
  KS extends ZodType<MapKey, any, any>,
  S extends ZodType<any, any, any>,
>(
  keySchema: KS,
  valueSchema: S,
  contentId: StateId | undefined,
): LocalRepoMapResult<zStatic<KS>, zStatic<S>> | undefined {
  return useRepoMap(false, keySchema, valueSchema, contentId);
}

function useRepoMap<
  KS extends ZodType<MapKey, any, any>,
  S extends ZodType<any, any, any>,
>(
  memoryOnly: boolean,
  keySchema: KS,
  valueSchema: S,
  contentId: StateId | undefined,
): LocalRepoMapResult<zStatic<KS>, zStatic<S>> | undefined {
  const repoRes = useRepo<[zStatic<KS>, zStatic<S>][]>(
    memoryOnly,
    contentId,
    [],
  );

  const setMap = useCallback(
    async (action: SetStateAction<ReadonlyMap<zStatic<KS>, zStatic<S>>>) => {
      if (repoRes?.fill) {
        repoRes?.fill((prev) =>
          Array.from(
            (isSetActionFunction(action)
              ? action(new Map(prev))
              : action
            ).entries(),
          ),
        );
      }
    },
    [repoRes?.fill],
  );

  const map: ReadonlyMap<zStatic<KS>, zStatic<S>> = useMemo(() => {
    if (repoRes?.content) {
      return new Map(
        repoRes.content.map(
          (entry) =>
            [
              keySchema.parse(entry[0]) as zStatic<KS>,
              valueSchema.parse(entry[1]) as zStatic<S>,
            ] as const,
        ),
      );
    } else {
      return new Map([]);
    }
  }, [repoRes?.content]);

  const setValue = useSetValueForMap(setMap);

  if (repoRes) {
    return {
      contentId: repoRes.contentId,
      map: map,
      meta: repoRes.meta,
      loadState: repoRes.loadState,
      setValue: setValue,
      clear: repoRes.clear,
    };
  } else {
    return undefined;
  }
}

type LocalRepoSetResult<K extends MapKey> = {
  all: ReadonlySet<K>;
  addValue: (key: K) => Promise<void>;
  removeValue: (key: K) => Promise<void>;
  loadState: LoadState | undefined;
  meta: { source: RepoDataSource; updatedAt: number };
  contentId: StateId;
  clear: () => Promise<void>;
};

function useRepoSet<KS extends ZodType<MapKey, any, any>>(
  memoryOnly: boolean,
  keySchema: KS,
  contentId: StateId | undefined,
): LocalRepoSetResult<zStatic<KS>> | undefined {
  const repoMap = useRepoMap(memoryOnly, keySchema, z.boolean(), contentId);

  const all = useMemo(() => new Set(repoMap?.map.keys()), [repoMap?.map]);
  const addValue = useCallback(
    async (key: zStatic<KS>) => {
      await repoMap?.setValue(key, true);
    },
    [repoMap?.setValue],
  );
  const removeValue = useCallback(
    async (key: zStatic<KS>) => {
      await repoMap?.setValue(key, undefined);
    },
    [repoMap?.setValue],
  );

  if (repoMap) {
    return {
      contentId: repoMap.contentId,
      all: all,
      meta: repoMap.meta,
      loadState: repoMap.loadState,
      addValue: addValue,
      removeValue: removeValue,
      clear: repoMap.clear,
    };
  } else {
    return undefined;
  }
}

export function useMemoryRepoSet<KS extends ZodType<MapKey, any, any>>(
  keySchema: KS,
  contentId: StateId,
): LocalRepoSetResult<zStatic<KS>>;
export function useMemoryRepoSet<KS extends ZodType<MapKey, any, any>>(
  keySchema: KS,
  contentId: StateId | undefined,
): LocalRepoSetResult<zStatic<KS>> | undefined;
export function useMemoryRepoSet<KS extends ZodType<MapKey, any, any>>(
  keySchema: KS,
  contentId: StateId | undefined,
): LocalRepoSetResult<zStatic<KS>> | undefined {
  return useRepoSet(true, keySchema, contentId);
}

export function useLocalRepoSet<KS extends ZodType<MapKey, any, any>>(
  keySchema: KS,
  contentId: StateId,
): LocalRepoSetResult<zStatic<KS>>;
export function useLocalRepoSet<KS extends ZodType<MapKey, any, any>>(
  keySchema: KS,
  contentId: StateId | undefined,
): LocalRepoSetResult<zStatic<KS>> | undefined;
export function useLocalRepoSet<KS extends ZodType<MapKey, any, any>>(
  keySchema: KS,
  contentId: StateId | undefined,
): LocalRepoSetResult<zStatic<KS>> | undefined {
  return useRepoSet(false, keySchema, contentId);
}
