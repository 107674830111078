import { Params } from "react-router-dom";
import { createContext, PropsWithChildren, useContext } from "react";

interface Context {
  params: Readonly<Params> | undefined;
}

const Context = createContext<Context>({
  params: undefined,
});

export function InjectParams(
  props: PropsWithChildren<{ params: Readonly<Params> }>,
) {
  return (
    <Context.Provider value={{ params: props.params }}>
      {props.children}
    </Context.Provider>
  );
}

export function useInjectParams() {
  return useContext(Context).params;
}
