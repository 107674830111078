import { z } from "zod";
import { zBigInt, zStatic } from "../utils/zodUtils";
import { AccountCurrency } from "./AccountCurrency";
import { AccountNFT } from "./AccountNFT";

export const AssetAccount = z.object({
  accountId: zBigInt,
  chainType: z.number().optional(),
  accountType: z.number(),
  address: z.string().default(""),
  currencyList: z.array(AccountCurrency).default([]),
  nftCount: z.number().optional(),
  nftList: z.array(AccountNFT).default([]),
});

export type AssetAccount = zStatic<typeof AssetAccount>;
