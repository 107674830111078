import { Page } from "../../../components/Page";
import { NavMiddle } from "../../../components/NavBar";
import { useI18n } from "../../../hooks/useI18n";
import styled from "styled-components";
import { HStack, Spring, VSpace, VStack } from "../../../components/VStack";
import { RegularInputGroup } from "../../../components/Input";
import React, { useMemo } from "react";
import iconJump from "../../../res/images/icon_jump.svg";
import { useEditingBigNumber } from "../../../hooks/useEditingBigNumber";
import {
  useBigIntParam,
  useBigIntSearchParam,
} from "../../../hooks/useTypedParam";
import { useBackend } from "../../../service/APIService";
import { useTransactionBody } from "../send/SendTokenPage";
import { Image } from "../../../components/Image";
import { PlaceholderButton, RegularButton } from "../../../components/Buttons";
import { RowCenterButton } from "../../../components/CommonStyles";
import { PageFooter } from "../../../components/PageHeaderFooter";
import { BottomSheet, closeModal, useModal } from "../../../components/Modal";
import { UserPickerPurpose, UserPickerResult } from "../../user/UserPickerPage";
import { useHopper } from "../../../hooks/useHopper";
import { useMyUid } from "../../../service/AuthSessionService";
import { TxSheet, usePerformTx } from "../payment/TransactionDialog";
import { andLog } from "../../../components/handleError";
import { ObjectType } from "../../../proto/ObjectSpec";
import { useSWR } from "../../../hooks/swr/useSWR";
import {
  CurrencyType,
  formatMoney,
  getCurrencyName,
} from "../../../proto/Currency";
import { jsonifyBigNumber } from "../../../utils/CurrencyAmount";
import BigNumber from "bignumber.js";
import ic_add from "../../../res/images/ic_add.svg";
import icDelete from "../../../res/images/icon_delete_red.svg";
import banner from "../../../res/images/banner_create_coin_gift.png";
import { ContentGroup } from "../../../components/ContentGroup";
import { NFTPickerResult } from "../NFTPickerPage";
import { useConsumeResult } from "../../../hooks/useResult";
import { SingleLineUnspecifiedWidth } from "../../../components/CommonViews";
import { useHopId, useHopState } from "../../../hooks/useHopState";
import { useNativePage } from "../../../hooks/useBridge";
import { OrderType } from "../order/OrderDetailPage";
import { useLayerId } from "../../../appshell/LayerBoundary";
import { isValidCircleToken } from "../../../proto/CircleFin";
import { dicClickTopUp } from "../dicClickTopUp";
import { TopUpMethodMenu } from "../topup/TopUpMethodMenu";
import { useWalletSWR } from "../useWallet";
import { BoolNumber } from "../../../proto/Wallet";
import { useBroadcast } from "../../../hooks/useBroadcast";
import { useOnMorePaymentBroadcast } from "../../../hooks/useOnMorePaymentBroadcast";
import { isLiteApp } from "../../../bridge/ExternalAppShellData";
import CurrencyTransactionView from "../payment/CurrencyTransactionView";
import NFTGiftBoxPaymentView from "./NFTGiftBoxPaymentView";
import { GiftBox, GiftBoxDistributeType } from "../../../proto/GiftBox";

const GIFT_MESSAGE_MAX_LENGTH = 200;

function SendGiftPage() {
  const i18n = useI18n();
  const backend = useBackend();
  const walletAccountId = useBigIntParam("walletAccountId");
  const assetAccountId = useBigIntParam("assetAccountId");
  const paymentModal = useModal("payment-modal");
  const currencyId = useBigIntSearchParam("currencyId");

  const currencyListSWR = useSWR(backend.getCurrencyList(walletAccountId));
  const sendTargetCurrency = useMemo(() => {
    return currencyListSWR.content?.list?.find(
      (item) => String(item.currency.currencyId) === String(currencyId),
    )?.currency;
  }, [currencyListSWR.content, currencyId]);
  const [wallet] = useWalletSWR();
  const methods = useSWR(backend.getSupportedThirdPartyPaymentMethods());

  const supportThirdParty = useMemo(() => {
    return (
      (!isLiteApp() || wallet?.thirdPartyIapStatus === BoolNumber.On) &&
      (methods.content?.list?.length ?? 0) > 0
    );
  }, [wallet, methods.content]);
  const topupMenu = useModal("top-up-menu");
  const layerId = useLayerId();
  const editingBody = useTransactionBody();

  const [amountInput, setAmountInput] = useEditingBigNumber(`send-token`);

  const currencyType = useBigIntSearchParam("currencyType");
  const isNFT = currencyType === BigInt(CurrencyType.NFT);

  const [messageInput, setMessageInput] = useHopState<string>(`gift-message`);
  const hopId = useHopId();
  const myUid = useMyUid();
  const userPicked = useConsumeResult<UserPickerResult>("UserPicker", [
    "Pick-Gift-User",
    hopId,
    myUid,
  ]);

  const nftPicked = useConsumeResult<NFTPickerResult>("NFTPicker", [
    "Send-Gift",
    hopId,
    myUid,
  ]);

  const targetObjectId = useMemo(() => {
    return userPicked.result?.uid?.at(0);
  }, [userPicked.result]);

  const sendNFTId = useMemo(() => {
    return nftPicked.result?.nftIds?.at(0);
  }, [nftPicked.result]);

  const nftSWR = useSWR(sendNFTId ? backend.getNFT(sendNFTId) : undefined);
  const nativePage = useNativePage();
  const next = async () => {
    const message =
      (messageInput?.length ?? 0) > 0
        ? messageInput ?? ""
        : i18n.web3_hongbao_best_wishes();
    if (isNFT) {
      const nft = nftSWR?.content;
      if (!nft) {
        nativePage.infoHud(i18n.web3_hongbao_select_an_nft()).catch(andLog);
        return;
      }

      const amount = amountInput ?? "";
      if (amount.length === 0 || amount === "0") {
        nativePage.infoHud(i18n.web3_hongbao_fill_amount()).catch(andLog);
        return;
      }

      if (Number(amount) > Number(nftSWR?.content?.availableBalance)) {
        nativePage.infoHud(i18n.web3_hongbao_not_enough_nfts()).catch(andLog);
        return;
      }

      await editingBody.fill((prevData) => ({
        ...prevData,
        assetId: sendNFTId,
        nft: nft,
        amount: amount,
        targetObjectType: ObjectType.USER,
        targetObjectId: targetObjectId,
        title: message,
      }));
    } else {
      if (!sendTargetCurrency) {
        return;
      }

      const amount = amountInput ?? "";
      if (amount.length === 0 || amount === "0") {
        nativePage.infoHud(i18n.web3_hongbao_fill_amount()).catch(andLog);
        return;
      }

      if (
        BigNumber(
          jsonifyBigNumber(BigNumber(amount), sendTargetCurrency.decimals),
        ).isGreaterThan(BigNumber(sendTargetCurrency.amount))
      ) {
        nativePage
          .infoHud(i18n.clover_dont_have_enough_balance())
          .catch(andLog);
        return;
      }

      await editingBody.fill((prevData) => ({
        ...prevData,
        amount: jsonifyBigNumber(
          BigNumber(amountInput ?? "0"),
          sendTargetCurrency?.decimals ?? 18,
        ),
        targetObjectType: ObjectType.USER,
        targetObjectId: targetObjectId,
        title: message,
        currencyId: sendTargetCurrency.currencyId,
        currencyType: sendTargetCurrency.currencyType,
        currency: sendTargetCurrency,
      }));
    }
    if (message.length > 200) {
      nativePage
        .infoHud(i18n.web3_hongbao_message_exceeded_max_limit())
        .catch(andLog);
      return;
    }

    if (targetObjectId === undefined) {
      nativePage.infoHud(i18n.web3_hongbao_need_select_user()).catch(andLog);
      return;
    }

    paymentModal.open();
  };

  const sendNFTGift = async () => {
    const localBox: Partial<GiftBox> = {
      assetId: editingBody.content.assetId,
      toObjectId: editingBody.content.targetObjectId,
      toObjectType: ObjectType.USER,
      currencyType: CurrencyType.NFT,
      amount: editingBody.content.amount,
      maxClaimedCount: 2,
      distributeMode: GiftBoxDistributeType.NFT,
      title: editingBody.content.title,
    };
    const remoteBox = await backend.sendGiftBox(localBox).run();
    return remoteBox.extensions?.mainTransferOrderId;
  };

  const sendTokenGift = async () => {
    const localBox: Partial<GiftBox> = {
      toObjectId: editingBody.content.targetObjectId,
      toObjectType: ObjectType.USER,
      currencyType: editingBody.content.currencyType,
      amount: editingBody.content.amount,
      maxClaimedCount: 2,
      distributeMode: GiftBoxDistributeType.Evenly,
      title: editingBody.content.title,
    };
    const remoteBox = await backend.sendGiftBox(localBox).run();
    return remoteBox.extensions?.mainTransferOrderId;
  };

  const targetUserSWR = useSWR(
    targetObjectId ? backend.getUser(targetObjectId) : undefined,
  );

  const hopper = useHopper();

  const pickNFT = () => {
    hopper.requestPresent(
      `wallet/${walletAccountId}/nft-picker`,
      nftPicked.consumerId,
    );
  };
  useOnMorePaymentBroadcast(() => {
    topupMenu.open();
  }, [topupMenu]);

  const performTx = usePerformTx(
    isNFT ? sendNFTGift : sendTokenGift,
    async (orderId: bigint | undefined) => {
      await closeModal(paymentModal);
      await editingBody.clear();
      if (orderId) {
        hopper.dismissLayerAndPush(`wallet/order/${orderId}`, {
          orderType: OrderType.GiftOrder,
        });
      }
    },
  );

  return (
    <Page
      pageData={[nftSWR, currencyListSWR, targetUserSWR, methods]}
      underlay={
        <div
          style={{
            width: "100%",
            position: "absolute",
            top: -20,
          }}
        >
          <Image
            src={banner}
            style={{
              width: "100%",
            }}
          />
        </div>
      }
    >
      <NavMiddle>{i18n.web3_hongbao_gift_box()}</NavMiddle>
      <VSpace height={50} />

      {!isNFT && (
        <>
          <SectionTitle>{i18n.web3_v0_amount()}</SectionTitle>
          <RegularInputGroup
            placeholder={i18n.web3_v0_amount()}
            autoComplete={"off"}
            type={"number"}
            value={amountInput}
            updateValue={setAmountInput}
          />
          {sendTargetCurrency && (
            <HStack style={{ marginTop: 10, gap: 5 }}>
              <AvailableLabel>{i18n.web3_v0_available()}</AvailableLabel>
              <Image
                src={[sendTargetCurrency.icon, "smallest"]}
                width={16}
                height={16}
              />
              <AvailableCurrency>
                {formatMoney("medium", sendTargetCurrency)}
              </AvailableCurrency>
              <AvailableCurrency>
                {getCurrencyName(sendTargetCurrency, i18n)}
              </AvailableCurrency>
              <UnderlinedWalletText
                onClick={() => {
                  dicClickTopUp(
                    hopper,
                    supportThirdParty ? topupMenu : undefined,
                  );
                }}
              >
                {i18n.web3_hongbao_top_up()}
              </UnderlinedWalletText>
            </HStack>
          )}
        </>
      )}

      {isNFT && (
        <>
          <SectionTitle>{i18n.web3_hongbao_select_the_nft()}</SectionTitle>
          <VSpace height={12} />
          {nftSWR?.content ? (
            <ContentGroup
              style={{ gap: 12, flexDirection: "row" }}
              onClick={pickNFT}
            >
              <Image
                src={[nftSWR.content?.entity?.preview, "smallest"]}
                width={70}
                style={{ borderRadius: 4 }}
                height={70}
              />
              <VStack style={{ gap: 10 }}>
                <NFTName>{nftSWR.content.name}</NFTName>
                <HStack>
                  <NFTInfo>{i18n.jul23_impr2_edition_impr(1)}</NFTInfo>
                  <VerticalDivider />
                  <NFTInfo>{i18n.web3_v0_available()}</NFTInfo>
                  <NFTInfo>{nftSWR.content.availableBalance}</NFTInfo>
                </HStack>
              </VStack>
              <Spring />
              <VStack style={{ justifyContent: "center" }}>
                <Image
                  src={icDelete}
                  width={24}
                  height={24}
                  stopPropagation={true}
                  onClick={() => {
                    nftPicked.clear().catch(andLog);
                  }}
                />
              </VStack>
            </ContentGroup>
          ) : (
            <PlaceholderButton
              style={{ height: 86, borderRadius: 4 }}
              onClick={pickNFT}
            >
              <Image src={ic_add} alt={"reveal"} />
              {i18n.web3_v0_add_nft()}
            </PlaceholderButton>
          )}

          <VSpace height={32} />
          <SectionTitle>
            {i18n.web3_hongbao_how_many_nfts_put_in()}
          </SectionTitle>
          <RegularInputGroup
            autoComplete={"off"}
            type={"number"}
            placeholder={i18n.web3_v0_amount()}
            value={amountInput}
            updateValue={setAmountInput}
          />
        </>
      )}

      <VSpace height={32} />
      <SectionTitle>{i18n.web3_hongbao_say_something_with_gift()}</SectionTitle>
      <RegularInputGroup
        autoComplete={"off"}
        placeholder={i18n.web3_hongbao_best_wishes()}
        value={messageInput}
        updateValue={setMessageInput}
        maxLength={GIFT_MESSAGE_MAX_LENGTH}
      />
      <VSpace height={32} />
      <SectionTitle>{i18n.web3_v0_gift_to()}</SectionTitle>
      <TargetUserBg
        onClick={() => {
          hopper.requestPresent(`users-picker`, userPicked.consumerId, {
            purpose: UserPickerPurpose.SendGift,
          });
        }}
      >
        {targetUserSWR?.content ? (
          <ChosenFriend>
            <Image
              src={[targetUserSWR?.content?.icon, "smallest"]}
              width={24}
              height={24}
              style={{ borderRadius: "50%" }}
            />
            <TargetUserName>{targetUserSWR.content?.nickname}</TargetUserName>
          </ChosenFriend>
        ) : (
          <FriendNotSet>{i18n.web3_v0_choose_a_friend()}</FriendNotSet>
        )}

        <Image src={iconJump} width={26} height={26} />
      </TargetUserBg>

      <PageFooter obscuredZoneKey={"SendNext"}>
        <RegularButton
          $baseBgColor={"var(--color-bg)"}
          onClick={next}
          style={{
            ...RowCenterButton,
            marginTop: 8,
          }}
        >
          {i18n.web3_hongbao_send_gift()}
        </RegularButton>
      </PageFooter>

      <TxSheet modal={paymentModal} performTx={performTx}>
        {isNFT ? (
          <NFTGiftBoxPaymentView body={editingBody.content} />
        ) : (
          <CurrencyTransactionView body={editingBody.content} />
        )}
      </TxSheet>
      {supportThirdParty && <TopUpMethodMenu modal={topupMenu} />}
    </Page>
  );
}

const TargetUserName = styled.div`
  flex: 1;
  color: var(--color-text00);
  ${SingleLineUnspecifiedWidth};
  font-weight: 400;
  font-size: 14px;
`;

const SectionTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: var(--color-text10);
`;

const TargetUserBg = styled.div`
  display: flex;
  flex-direction: row;
  height: 50px;
  justify-content: space-between;
  margin-top: 12px;
  padding-inline-start: 12px;
  padding-inline-end: 8px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.4);
`;

const UnderlinedWalletText = styled.span`
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  color: #28c5f3;
  text-decoration-color: #28c5f3;
  text-decoration: underline;
`;

const VerticalDivider = styled.div`
  height: 10px;
  margin-inline-start: 8px;
  margin-inline-end: 8px;
  width: 1px;
  background-color: 1px solid rgba(255, 255, 255, 0.1);
`;

const NFTName = styled.div`
  color: var(--color-text00);
  font-size: 14px;
  font-weight: 400;
`;

const NFTInfo = styled.div`
  color: var(--color-text00);
  font-size: 10px;
  font-weight: 400;
`;

const AvailableCurrency = styled.div`
  color: var(--color-text00);
  font-size: 12px;
  font-weight: 500;
`;

const AvailableLabel = styled.div`
  color: var(--color-text20);
  font-size: 12px;
  margin-inline-end: 5px;
  font-weight: 400;
`;

const FriendNotSet = styled.div`
  color: var(--color-text20);
  font-weight: 500;
  font-size: 14px;
`;

const ChosenFriend = styled.div`
  color: var(--color-text00);
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
`;

export default SendGiftPage;
