import styled from "styled-components";
import { Image } from "../../components/Image";
import { HStack } from "../../components/VStack";
import { UserPropsPackage } from "../../proto/UserPropsPackage";
import { useI18n } from "../../hooks/useI18n";
import { formatMoney, getCurrencyIcon } from "../../proto/Currency";
import { cell_on_pressed } from "../../components/CommonStyles";
import { andLog } from "../../components/handleError";
import { TopUpType } from "./TopUpType";
import { copyObject } from "../../utils/copyObject";
import BigNumber from "bignumber.js";
import { number } from "zod";

export interface IPackage {
  package: UserPropsPackage;
  topUpType: TopUpType;
  solUSDPrice?: string;
  onClick?: (pack: UserPropsPackage) => Promise<void>;
}

function TopUpCell(props: IPackage) {
  const i18n = useI18n();
  const amount = BigNumber(props.package.price?.amount ?? 0)
    .div(BigNumber(props.solUSDPrice ?? 1))
    .toFixed(0);
  const usdPrice = copyObject(props.package.price, { amount: amount });
  const language = navigator.language;
  const formatCurrency = () => {
    console.log("curreny", props.package.price?.currencyCode);

    const str = BigNumber(props.package.price?.amount ?? 0)
      .div(BigNumber(`1${"0".repeat(props.package.price?.decimals ?? 1)}`))
      .toFixed(3, BigNumber.ROUND_DOWN);

    console.log("amount", str);
    return new Intl.NumberFormat(language, {
      style: `currency`,
      currency: props.package.price?.currencyCode,
    }).format(Number(str));
  };

  return (
    <CellFrame
      onClick={() => {
        if (props.onClick) {
          props.onClick(props.package).catch(andLog);
        }
      }}
    >
      {!!props.package.discountRate ? (
        <OffLabel>
          {i18n.web3_v0_discount_percentage(props.package.discountRate ?? 0)}
        </OffLabel>
      ) : null}
      <Image
        src={[props.package.icon, { width: 60, height: 60 }]}
        style={{
          width: 60,
          height: 60,
          marginTop: 20,
          background: "transparent",
        }}
      />
      {props.topUpType === TopUpType.MATCH_CARD && (
        <UnitsAmount>
          {i18n
            .plural(props.package.units ?? 0)
            .aigc_quota_units(props.package.units ?? 0)}
        </UnitsAmount>
      )}
      {(props.topUpType === TopUpType.SOL_BUY_GEMS ||
        props.topUpType === TopUpType.THIRD_PARTY) && (
        <UnitsAmount>{formatMoney("medium", props.package.amount)}</UnitsAmount>
      )}
      <BottomFrame>
        {props.topUpType === TopUpType.THIRD_PARTY ? (
          <Price>{formatCurrency()}</Price>
        ) : (
          <HStack style={{ gap: 4 }}>
            <Image
              src={getCurrencyIcon(props.package.price)}
              style={{ width: 18, height: 18, margin: 0 }}
            />
            <Price>
              {props.topUpType === TopUpType.SOL_BUY_GEMS
                ? formatMoney("medium", usdPrice)
                : formatMoney("medium", props.package.price)}
            </Price>
          </HStack>
        )}

        {!!props.package.originalPrice &&
        formatMoney("medium", props.package.originalPrice) !== "0" ? (
          <OriginalPrice>
            {formatMoney("medium", props.package.originalPrice)}
          </OriginalPrice>
        ) : null}
      </BottomFrame>
    </CellFrame>
  );
}

const OffLabel = styled.div`
  border-radius: 0 6px 0 6px;
  background: #d000ff;
  font-size: 10px;
  font-weight: 700;
  color: #ffffff;
  padding: 4px 12px;
  position: absolute;
  right: 0;
`;

const OriginalPrice = styled.div`
  text-decoration: line-through;
  font-weight: 500;
  font-size: 10px;
  color: #0dffcc;
`;

const Price = styled.div`
  font-size: 13px;
  font-weight: 700;
  color: #0dffcc;
`;

const UnitsAmount = styled.div`
  font-weight: 700;
  font-size: 20px;
  color: #ffffff;
  margin-top: 4px;
`;

const CellFrame = styled.div`
  min-width: 74px;
  display: flex;
  flex-direction: column;
  position: relative;
  ${cell_on_pressed};
  background: rgba(255, 255, 255, 0.08);
  border-radius: 6px;
  backdrop-filter: blur(10px);
  align-items: center;
  height: 172px;
`;

const BottomFrame = styled.div`
  height: 38px;
  width: 100%;
  position: absolute;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 0 0 6px 6px;
  display: flex;
  flex-direction: column;
  bottom: 0;
  gap: 2;
`;

export default TopUpCell;
