import { useI18n } from "../../../hooks/useI18n";
import { useBackend } from "../../../service/APIService";
import { useHopper } from "../../../hooks/useHopper";
import { useNativePage } from "../../../hooks/useBridge";
import React, { useState } from "react";
import { Page } from "../../../components/Page";
import {
  NavEnd,
  NavItem,
  NavMiddle,
  NavStart,
} from "../../../components/NavBar";
import { RegularInputGroup } from "../../../components/Input";
import { NavButtonType } from "../../../bridge/NavButtonDesc";
import { andLog, useErrorHandler } from "../../../components/handleError";
import { PowerMarkdown } from "../../../components/PowerMarkdown";
import { useStringSearchParam } from "../../../hooks/useTypedParam";
import { User } from "../../../proto/User";
import { useMyUid } from "../../../service/AuthSessionService";
import { css } from "styled-components";
import { useGlobalSpinner } from "../../../utils/globalSpinner";

export function EditSocialIdPage() {
  const i18n = useI18n();
  const backend = useBackend();
  const hopper = useHopper();
  const nativePage = useNativePage();
  const zid = useStringSearchParam("zid");
  const [currentZid, setCurrentZid] = useState(zid);
  const myUid = useMyUid();
  const handleError = useErrorHandler();
  const globalSpinner = useGlobalSpinner();

  const isZIdValid = (() => {
    const trimmed = currentZid?.trim();
    return !!(trimmed?.length && 3 < trimmed?.length && trimmed?.length < 25);
  })();

  async function onCheckClick() {
    if (!isZIdValid) {
      nativePage
        .alertNotice(
          i18n.text_limit_number_of_characters_not_follow(),
          i18n.ok(),
        )
        .catch(andLog);
      return;
    }
    globalSpinner(async () => {
      const user: User = {
        uid: myUid,
        socialId: currentZid,
      };
      await backend.updateProfile(user).run();
      hopper.back();
    }).catch(andLog);
  }

  const backWithCheck = async () => {
    if (zid === currentZid) {
      hopper.back();
      return;
    }
    try {
      const quit = await nativePage.alertAreYouSure(
        i18n.compose_confirmation(),
        i18n.quit(),
        i18n.oct12_text_continue_editing(),
      );
      if (quit) {
        hopper.back();
      }
    } catch (e) {
      handleError(e);
    }
  };
  return (
    <Page pageData={undefined}>
      <NavStart>{NavItem.image(NavButtonType.Cross, backWithCheck)}</NavStart>
      <NavMiddle>{i18n.user_edit_social_id()}</NavMiddle>
      <NavEnd>{NavItem.image(NavButtonType.Check, onCheckClick)}</NavEnd>
      <RegularInputGroup
        value={currentZid}
        updateValue={setCurrentZid}
        maxLength={25}
      />
      <PowerMarkdown textStyle={DescStyle}>
        {i18n.user_social_id_rule()}
      </PowerMarkdown>
    </Page>
  );
}

export const DescStyle = css`
  color: var(--color-text00);
  font-size: 12px;
  font-weight: 300;
  line-height: 150%;
  letter-spacing: -0.2px;
  opacity: 0.6;
`;
