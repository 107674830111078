import { Rejectable } from "./Rejectable";
import { anyToBase64, base64ToTyped, base64ToUnknown } from "../utils/Blob";
import { JSONUtil } from "../utils/JSONUtil";
import { getRandomId } from "../utils/randomId";
import { z } from "zod";
import { zStatic } from "../utils/zodUtils";

/* --------------------

Data value transferred between JS and Native environment is in the format called `type prefixed string` (TPS for short).
Its format is pretty simple. For instance, an integer number 1 is represented as `number:1`, and a string "foo" is represented as `string:foo` etc. Below the is types we support and their counter-part in different environments.

| prefix    | value                        | js        | swift                                                      | kotlin                    |
| --------- | ---------------------------- | --------- | ---------------------------------------------------------- | ------------------------- |
| number    | digits                       | number    | Int32, Float, Double                                       | Int, Float, Double        |
| string    | the string content           | string    | String                                                     | String                    |
| boolean   | true or false                | boolean   | Bool                                                       | Boolean                   |
| bigint    | digits                       | bigint    | Int, UInt, Int64, UInt64,                                  | Long                      |
| object    | json -> utf8 bytes -> base64 | object    | Codable `struct` or plain Dictionary with JSObject wrapper | `data class` or plain Map |
| function  | function id                  | function  | JSFunction                                                 | JSFunction                |
| undefined | no value                     | undefined | Void                                                       | JSVoid                    |
| null      | no value                     | null      | null                                                       | null                      |

For the sake of simplicity, we only support one level of callbacks, that is, you can't pass function param to a function param, and you can't return a function. Nullability, default parameter are not supported either.

Due to some R8 bug, we can't use Unit for undefined in kotlin, so JSVoid is created.

---------------------- */

export const JSCallNative = z.object({
  action: z.string(),
  id: z.number(),
  params: z.array(z.string()),
});

export const NativeReturn = z.object({
  id: z.number(),
  action: z.string(),
  result: z.string().optional(),
  error: Rejectable.optional(),
});

export const NativeCallback = z.object({
  id: z.number(),
  action: z.string(),
  params: z.array(z.string()),
});

export interface InnerWindow {
  onNativeReturn(r: string): void;

  onNativeCallback(r: string): void;
}

export function returnToJS(window: Window, r: zStatic<typeof NativeReturn>) {
  const base64 = anyToBase64(r);
  (window as unknown as InnerWindow).onNativeReturn(base64);
}

export function callbackToJS(
  window: Window,
  r: zStatic<typeof NativeCallback>,
) {
  const base64 = anyToBase64(r);
  (window as unknown as InnerWindow).onNativeCallback(base64);
}

type ReturnPromiseHandler = {
  resolve: (value: any) => void;
  reject: (reason: any) => void;
};

export interface BridgeLike {
  callNative(
    target: string,
    action: string,
    ...args: string[]
  ): Promise<string>;

  registerCallback(callback: any): number;
}

function bridgeReturn(
  window: any,
  onReturn: (v: zStatic<typeof NativeReturn>) => void,
) {
  window.onNativeReturn = (fromNative: any) => {
    onReturn(base64ToTyped(fromNative, NativeReturn));
  };
}

function bridgeCallback(
  window: any,
  onCallback: (v: zStatic<typeof NativeCallback>) => void,
) {
  window.onNativeCallback = (fromNative: any) => {
    onCallback(base64ToTyped(fromNative, NativeCallback));
  };
}

function getMessageReceiver(
  window: any,
  hook: string,
): { postMessage: (msg: string) => void } | undefined {
  if (hook in window) {
    return window[hook];
  } else if (
    window.webkit &&
    window.webkit.messageHandlers &&
    hook in window.webkit.messageHandlers
  ) {
    return window.webkit.messageHandlers[hook];
  }
}

export class BridgeError extends Error {
  constructor(hook: string, action: string) {
    super(
      `Cannot find '${hook}' hook for action '${action}'. Please make sure this web app is running in a native web view with hooks correctly set up.`,
    );
    this.name = "BridgeError"; // set the name property
  }
}

function postMessage(
  window: any,
  hook: string,
  toNative: zStatic<typeof JSCallNative>,
) {
  const messageReceiver = getMessageReceiver(window, hook);
  if (messageReceiver) {
    messageReceiver.postMessage(JSONUtil.stringify(toNative));
  } else {
    console.error(
      "postMessage",
      `Cannot find ${hook} hook, action: ${toNative.action}`,
    );
    throw new BridgeError(hook, toNative.action);
  }
}

export class Bridge {
  static instance: Bridge;

  public static getInstance(): Bridge {
    if (!Bridge.instance) {
      Bridge.instance = new Bridge(window);
    }

    return Bridge.instance;
  }

  private constructor(readonly window: Window) {
    bridgeReturn(window, (toJS: zStatic<typeof NativeReturn>) => {
      const handler = this.keyedReturnHandlers.get(toJS.id);
      if (handler) {
        if (toJS.error) {
          handler.reject(toJS.error);
        } else {
          handler.resolve(toJS.result);
        }
      } else {
        console.error("bridge return handler missing: ", {
          action: toJS.action,
          id: toJS.id,
          keyedReturnHandlers: this.keyedReturnHandlers,
        });
      }
    });

    bridgeCallback(window, (toJS: zStatic<typeof NativeCallback>) => {
      const handler = this.keyedCallbacks.get(toJS.id);
      if (handler) {
        try {
          handler(...toJS.params.map((p) => decodeTPS(p)));
        } catch (e) {
          console.error("callback failed", e);
        }
      } else {
        console.error("bridge callback missing: ", {
          sourceAction: toJS.action,
          callbackId: toJS.id,
          keyedCallbacks: this.keyedCallbacks,
        });
      }
    });
  }

  private readonly keyedReturnHandlers = new Map<
    number,
    ReturnPromiseHandler
  >();
  private readonly keyedCallbacks = new Map<number, any>();

  public callNative(
    hook: string,
    action: string,
    ...args: string[]
  ): Promise<string> {
    const id = getRandomId();
    postMessage(this.window, hook, {
      action: action,
      id: id,
      params: args,
    });
    return new Promise<string>((resolve, reject) => {
      this.keyedReturnHandlers.set(id, { resolve: resolve, reject: reject });
    });
  }

  public registerCallback(callback: any): number {
    const id = getRandomId();
    this.keyedCallbacks.set(id, callback);
    return id;
  }

  public hasHook(hook: string) {
    return !!getMessageReceiver(this.window, hook);
  }
}

export type VFunction = {
  id: number;
  paramsCount: number;
  action: string;
};

function parseVFunction(value: string, fromAction: string): VFunction {
  const segs = value.split(":");
  return {
    id: parseInt(segs[0]),
    paramsCount: parseInt(segs[1]),
    action: fromAction,
  };
}

export function decodeTPS(prefixed: string, fromAction?: string): any {
  const prefix = prefixed.split(":")[0];
  const result = prefixed.slice(prefix.length + 1);
  switch (prefix) {
    case "bigint":
      return BigInt(result);
    case "number":
      return +result;
    case "boolean":
      return result === "true";
    case "string":
      return result;
    case "object":
      return base64ToUnknown(result);
    case "function":
      return parseVFunction(result, fromAction ?? "unknown");
    case "undefined":
      return undefined;
    case "null":
      return null;
    default:
      return undefined;
  }
}

export function encodeTPS(value: any, bridge?: BridgeLike): string {
  if (value === null) return "null:";

  const argType = typeof value;
  const prefix = argType + ":";
  switch (argType) {
    case "function":
      if (bridge) {
        const id = bridge.registerCallback(value);
        return prefix + id.toString() + ":" + value.length.toString();
      } else {
        console.error("Unsupported arg type", typeof value);
        return "unknown:" + `${value}`;
      }
    case "bigint":
    case "number":
    case "boolean":
    case "string":
      return prefix + value.toString();
    case "object":
      return prefix + anyToBase64(value);
    case "undefined":
      return prefix;
    default:
      console.error("Unsupported arg type", typeof value);
      return "unknown:" + `${value}`;
  }
}

export function getBridgeHook<T extends object>(
  bridge: BridgeLike,
  hook: string,
): T {
  return new Proxy(
    { bridge: bridge, hook: hook },
    {
      get(
        proxied: { bridge: BridgeLike; hook: string },
        p: string | symbol,
        receiver: any,
      ): any {
        const method = (proxied as any)[p];
        if (method && typeof method === "function") {
          return async (...args: any[]) => {
            return await method(...args);
          };
        } else {
          return async (...args: any[]) => {
            const strArgs = args.map((arg) => encodeTPS(arg, bridge));

            const prefixed = await proxied.bridge.callNative(
              proxied.hook,
              p.toString(),
              ...strArgs,
            );
            return decodeTPS(prefixed);
          };
        }
      },
    },
  ) as T;
}
