import React from "react";
import { useHopper } from "../../hooks/useHopper";
import { DemoCell } from "./DemoCell";
import { Page } from "../../components/Page";

export function HooksDemoPage() {
  const hopper = useHopper();

  return (
    <Page pageData={undefined}>
      <DemoCell onClick={() => hopper.push(`hop-state-demo`)}>
        useHopState
      </DemoCell>
      <DemoCell onClick={() => hopper.push(`hop-map-demo`)}>useHopMap</DemoCell>

      <DemoCell onClick={() => hopper.push(`count-down-demo`)}>
        useCountDown
      </DemoCell>

      <DemoCell onClick={() => hopper.push(`local-repo-demo`)}>
        useLocalRepo
      </DemoCell>
      <DemoCell onClick={() => hopper.push(`memory-repo-map-demo`)}>
        Memory Repo Map
      </DemoCell>
      <DemoCell onClick={() => hopper.push(`local-repo-map-demo`)}>
        Local Repo Map
      </DemoCell>

      <DemoCell onClick={() => hopper.push(`result-hook-demo`)}>
        useResult
      </DemoCell>
      <DemoCell onClick={() => hopper.push(`os-native-result-hook-demo`)}>
        useResult (OS Native)
      </DemoCell>
    </Page>
  );
}
