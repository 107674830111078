import styled, { css, RuleSet } from "styled-components";

export const VStackMixin = css`
  display: flex;
  position: relative;
  flex-direction: column;

  justify-content: start;
  align-items: stretch;
  box-sizing: border-box;
`;
export const DebugBorder = css`
  border-style: solid;
  border-color: black;
  border-width: 1px;
`;

export const HStackMixin = css`
  display: flex;
  position: relative;
  flex-direction: row;

  // align left and center
  justify-content: start;
  align-items: center;
  box-sizing: border-box;
`;

export const CenterFillBg = css`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;
export const CenterFitBg = css`
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
`;

export const VStack = styled.div<{ mixin?: RuleSet<Object> }>`
  ${VStackMixin};
  ${(p) => p.mixin}
`;
export const HStack = styled.div<{ mixin?: RuleSet<Object> }>`
  ${HStackMixin};
  ${(p) => p.mixin}
`;

export const CenterChild = styled.div<{ mixin?: RuleSet<Object> }>`
  ${HStackMixin};
  justify-content: center;
  ${(p) => p.mixin}
`;

export const VSpace = styled.div<{ height: string | number }>`
  width: 100%;
  min-width: 1px;
  height: ${(p) => (typeof p.height === "string" ? p.height : `${p.height}px`)};
  flex-shrink: 0;
`;
export const HSpace = styled.div<{ width: string | number }>`
  height: 100%;
  min-height: 1px;
  width: ${(p) => (typeof p.width === "string" ? p.width : `${p.width}px`)};
  flex-shrink: 0;
`;
export const Spring = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
`;
