import { Media } from "../proto/Media";
import { Background } from "../proto/Background";

export type BridgeLocalMedia = {
  url: string;
  id: string;
};

export type LocalMedia = BridgeLocalMedia | Blob;

function isMedia(media: LocalMedia | Media | Background): media is Media {
  return (media as any).baseUrl !== undefined;
}

export function isBackground(
  media: LocalMedia | Media | Background,
): media is Background {
  return (
    (media as any).backgroundImage !== undefined ||
    (media as any).backgroundColor !== undefined
  );
}

export function isLocalMedia(
  media: LocalMedia | Media | Background,
): media is LocalMedia {
  return !isMedia(media) && !isBackground(media);
}

export type MediaScene =
  | "campaign_cover"
  | "nft_thumbnail"
  | "circle_cover"
  | "circle_icon"
  | "content_image"
  | "content_video"
  | "content_audio";

export type Size = {
  width: number;
  height: number;
};
