import { z } from "zod";
import { zBigInt, zStatic } from "../utils/zodUtils";
import { Currency } from "./Currency";
import { NFT } from "./NFT";

export const AccountNFT = z.object({
  accountId: zBigInt,
  nftId: zBigInt,
  nftMetaInfo: NFT,
  createdTime: z.number(),
  currencyType: z.number(),
  balance: z.string().default("0"),
  availableBalance: z.string().default("0"),
  availableCurrency: Currency.optional(),
});

export type AccountNFT = zStatic<typeof AccountNFT>;
