import { Page } from "../../../components/Page";
import { useBigIntParam } from "../../../hooks/useTypedParam";
import { useSWR } from "../../../hooks/swr/useSWR";
import { useI18n } from "../../../hooks/useI18n";
import SettingCell from "../../../components/SettingCell";
import { ObjectType } from "../../../proto/ObjectSpec";
import CircleBackgroundFrame from "../CircleBackgroundFrame";
import React from "react";
import { useHopper } from "../../../hooks/useHopper";
import { useBackend } from "../../../service/APIService";
import { NavMiddle } from "../../../components/NavBar";

function CircleBlockedContentsPage() {
  const circleId = useBigIntParam("circleId");
  const backend = useBackend();
  const circleSWR = useSWR(backend.getCircle(circleId));
  const i18n = useI18n();
  const hopper = useHopper();
  return (
    <Page
      pageData={circleSWR}
      scrollPaddingDisabled={true}
      underlay={
        <CircleBackgroundFrame
          circleBackground={circleSWR.content?.circleBackground}
        />
      }
    >
      <NavMiddle>{i18n.circle2_1_blocked_contents()}</NavMiddle>
      <SettingCell
        title={i18n.circle2_1_blocked_posts()}
        onClick={() => {
          hopper.push(`circle/${circleId}/blocked-objects`, {
            objectType: ObjectType.BLOG,
          });
        }}
      />
      <SettingCell
        title={i18n.circle2_1_blocked_chats()}
        showDivider={false}
        onClick={() => {
          hopper.push(`circle/${circleId}/blocked-objects`, {
            objectType: ObjectType.CHAT,
          });
        }}
      />
    </Page>
  );
}

export default CircleBlockedContentsPage;
