import { z } from "zod";
import { zStatic } from "../utils/zodUtils";

export const SubscriptionSettings = z.object({
  receiveUserUpdate: z.boolean().optional(),
  receiveNewFollower: z.boolean().optional(),
  receiveWallComment: z.boolean().optional(),
  receiveWallOnlyMyComment: z.boolean().optional(),
  receiveCircleUpdate: z.boolean().optional(),
  receivePostLike: z.boolean().optional(),
  receivePostComment: z.boolean().optional(),
  receivePostOnlyMyComment: z.boolean().optional(),
  notReceiveNewVisitor: z.boolean().optional(),
  notReceiveCircleAnnouncementPush: z.boolean().optional(),
  notReceiveStoreTransactionNotification: z.boolean().optional(),
});

export type SubscriptionSettings = zStatic<typeof SubscriptionSettings>;
