import { Page } from "../../components/Page";
import { DB } from "../../utils/DB";
import { useEffect, useRef } from "react";

export interface ViewPagerChildProps {
  index: number;
}

function ViewPagerChildPage(props: ViewPagerChildProps) {
  // 创建一个长度为50的数组，并生成从0到49的索引

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // 每次进入页面时，将滚动位置重置到顶部
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = 0;
    }
  }, [props.index]);

  const items = Array.from({ length: 50 }, (_, index) => index);
  return (
    <Page pageData={undefined} scrollPaddingDisabled={true}>
      <div style={{ fontWeight: 800, fontSize: 36, textAlign: "center" }}>
        {props.index}
      </div>
      {/* 内容部分 */}
      {items.map((item) => (
        <div style={{ fontWeight: 400, fontSize: 16, height: 70 }} key={item}>
          {item}
        </div>
      ))}
    </Page>
  );
}

export default ViewPagerChildPage;
