import SectionHeader from "../../components/SectionHeader";
import { Spacing } from "../../components/Spacing";
import React from "react";
import { useI18n } from "../../hooks/useI18n";
import GridLayout from "../../components/GridLayout";
import ChatGridCell from "../../components/ChatGridCell";
import styled from "styled-components";
import { useSWRArray } from "../../hooks/swr/useSWRArray";
import { CircleWidgetsCellProps } from "./CircleWidgetCell";
import { useBackend } from "../../service/APIService";
import { SpeedSubtitle } from "../../components/SpeedTitle";

function CircleWidgetLivePartiesCell(props: CircleWidgetsCellProps) {
  const i18n = useI18n();
  const backend = useBackend();
  const livePartiesSWR = useSWRArray(
    backend.getCircleLiveParites(props.circleId),
  );
  return (
    <>
      <Spacing height={24} />
      <SpeedSubtitle style={{ fontSize: 14, color: "white" }}>
        {props.widget.title ?? i18n.live_parties()}
      </SpeedSubtitle>
      {livePartiesSWR.content.length > 0 && (
        <GridLayout
          spanCount={2}
          items={livePartiesSWR.content.map((chat) => {
            return <ChatGridCell key={chat?.threadId} chat={chat} />;
          })}
        />
      )}

      {livePartiesSWR.content.length === 0 && (
        <>
          <Spacing height={54} />
          <EmptyContent>{i18n.party_off_impr_no_live_parties()}</EmptyContent>
          <Spacing height={30} />
        </>
      )}
    </>
  );
}

const EmptyContent = styled.div`
  font-weight: 400;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  font-size: 13px;
`;

export default CircleWidgetLivePartiesCell;
