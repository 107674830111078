import { z } from "zod";
import { zBigInt, zStatic } from "../utils/zodUtils";

export const GiftBoxExtensions = z.object({
  threadId: zBigInt.optional(),
  messageId: zBigInt.optional(),
  /**
   * giftBox 绑定的transfer order
   */
  mainTransferOrderId: zBigInt.optional(),
  latestGiftBoxShareLocation: z.number().optional(),
});

export type GiftBoxExtensions = zStatic<typeof GiftBoxExtensions>;
