import React, { useEffect, useMemo } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, createMemoryRouter } from "react-router-dom";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "./components/common.css";
import styled, { StyleSheetManager } from "styled-components";
import { shouldForwardProp } from "./utils/shouldForwardProp";
import BigNumber from "bignumber.js";
import { initI18n, useCurrentLanguage } from "./hooks/useI18n";
import { getNumberFormatConfig } from "./utils/NumberI18n";
import { allRoutes, deepLinkToWebLink, webLinkToDeepLink } from "./routes";
import { AppShell } from "./appshell/AppShell";
import {
  getExternalAppShellData,
  isInExternalAppShell,
  isLiteApp,
} from "./bridge/ExternalAppShellData";
import { ExternalAppShell } from "./appshell/ExternalAppShell";
import { isSpongeKit } from "./utils/isSpongeKit";
import { detect } from "detect-browser";
import { isOnHomeScreen } from "./utils/isOnHomeScreen";
import { ReactRoutesService } from "./service/ReactRoutesService";
import { BUILD_COMMIT, BUILD_NUMBER } from "./gen/build";

const router = createBrowserRouter(allRoutes);

const root = ReactDOM.createRoot(
  document.getElementById("react-root") as HTMLElement,
);

const DebugLabel = styled.div<{ $top: number }>`
  top: ${(p) => p.$top}px;
  color: black;
  position: absolute;
  left: 8px;
  font-size: 11px;
  background-color: beige;
`;

function createMemRouter(startUrl: string) {
  return createMemoryRouter(allRoutes, { initialEntries: [startUrl] });
}

function Main() {
  const lang = useCurrentLanguage();
  useEffect(() => {
    const config = getNumberFormatConfig(lang);

    BigNumber.config({
      FORMAT: {
        // decimal separator
        decimalSeparator: config.decimalSeparator,
        // grouping separator of the integer part
        groupSeparator: config.groupSeparator,
        // primary grouping size of the integer part
        groupSize: config.groupSize,
        // secondary grouping size of the integer part
        secondaryGroupSize: config.secondaryGroupSize,
      },
    });
  }, [lang]);

  const externalAppShellData = getExternalAppShellData();

  const debugInfo = useMemo(() => {
    const infos: string[] = [];
    infos.push(`${BUILD_NUMBER}`);
    infos.push(`${BUILD_COMMIT.slice(0, 6)}`);
    infos.push(`${window.location.host}`);
    infos.push(`@${window.devicePixelRatio}x`);
    infos.push(`${detect()?.os}`);
    infos.push(`${detect()?.name}`);
    infos.push(`full version = ${!isLiteApp()}`);
    if (isOnHomeScreen()) {
      infos.push("H");
    }
    return infos.join(" | ");
  }, []);

  return (
    <React.StrictMode>
      <StyleSheetManager shouldForwardProp={shouldForwardProp}>
        <ReactRoutesService
          baseRouter={router}
          createMemoryRouter={createMemRouter}
          webLinkToDeepLink={webLinkToDeepLink}
          deepLinkToWebLink={deepLinkToWebLink}
        >
          {externalAppShellData ? (
            <ExternalAppShell data={externalAppShellData} />
          ) : (
            <AppShell />
          )}
        </ReactRoutesService>

        {isSpongeKit() && (
          <DebugLabel $top={isInExternalAppShell() ? 30 : 2}>
            {debugInfo}
          </DebugLabel>
        )}
      </StyleSheetManager>
    </React.StrictMode>
  );
}

initI18n().then(() => root.render(<Main />));

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
