import { NFT } from "../../../proto/NFT";
import { HStack, Spring, VStack } from "../../../components/VStack";
import styled from "styled-components";
import CircleCardsPreviewCell, {
  CardStyle,
} from "../card/CircleCardsPreviewCell";
import { Circle } from "../../../proto/Circle";
import { Image } from "../../../components/Image";
import IcEdit from "../../../res/images/ic_edit.svg";
import icSolScan from "../../../res/images/ic_sol_scan.png";
import { CircleCardStatus } from "../../../proto/CircleCardStatus";
import { useI18n } from "../../../hooks/useI18n";
import { formatMoney, getCurrencyName } from "../../../proto/Currency";
import React from "react";
import { SingleLineUnspecifiedWidth } from "../../../components/CommonViews";

export interface EditingCardCellProps {
  circle?: Circle;
  onClickCard: () => void;
  nft: NFT;
}

export function EditingCardCell(props: EditingCardCellProps) {
  const i18n = useI18n();
  const isDisabled =
    props.nft.circleMembershipCard?.cardStatus === CircleCardStatus.Disabled;
  const isAdminCard = props.nft?.circleMembershipCard?.membershipLevel === 999;
  return (
    <HStack
      onClick={props.onClickCard}
      style={{
        height: 82,
        borderBottom: "0.5px solid rgba(255, 255, 255, 0.1)",
      }}
    >
      <HStack
        style={{
          opacity: isDisabled ? 0.3 : 1,
          gap: 8,
          flex: 1,
        }}
      >
        {isAdminCard && <LVName>{i18n.admin()}</LVName>}
        {!isAdminCard && (
          <LVName>{`Lv.${props.nft?.circleMembershipCard?.membershipLevel ?? 0}`}</LVName>
        )}

        <CircleCardsPreviewCell
          nft={props.nft}
          cardStyle={CardStyle.small}
          width={76}
        />
        <VStack style={{ gap: 8, flex: 1 }}>
          <Name>
            {isAdminCard
              ? i18n.clover_admin_membership_card()
              : props.nft.circleMembershipCard?.name}
          </Name>
          <HStack style={{ gap: 4 }}>
            <Image
              src={[props.circle?.tokenProject?.image, "smallest"]}
              style={{ borderRadius: 1 }}
              width={16}
              height={16}
            />
            <TokenAmount>
              {formatMoney("medium", props.nft.circleMembershipCard?.currency)}
            </TokenAmount>
            <TokenAmount>
              {getCurrencyName(props.nft.circleMembershipCard?.currency)}
            </TokenAmount>
          </HStack>
        </VStack>
      </HStack>
      <VStack style={{ gap: 8, alignItems: "end" }}>
        {!isAdminCard && <Image src={IcEdit} width={14} height={14} />}
        {isDisabled && <DisableLabel>{i18n.disabled()}</DisableLabel>}
      </VStack>
    </HStack>
  );
}

const DisableLabel = styled.div`
  color: #b7b7b7;
  font-size: 12px;
  border-radius: 4px;
  padding: 4px 8px;
  background-color: rgba(179, 179, 179, 0.1);
  font-weight: 500;
`;

const LVName = styled.div`
  color: var(--color-text00);
  min-width: 40px;
  text-align: center;
  font-weight: 400;
  font-size: 10px;
`;

const Name = styled.div`
  color: var(--color-text00);
  margin-inline-end: 6px;
  font-weight: 500;
  ${SingleLineUnspecifiedWidth};
  font-size: 14px;
`;

const TokenAmount = styled.div`
  color: var(--color-text00);
  font-weight: 400;
  font-size: 14px;
`;
