import React, { ReactNode } from "react";
import { useI18n } from "../../hooks/useI18n";
import UserListPage from "./UserListPage";
import styled from "styled-components";
import { useSWR } from "../../hooks/swr/useSWR";
import { useMyUid } from "../../service/AuthSessionService";
import { useBackend } from "../../service/APIService";
import {
  ViewPagerPage,
  ViewPagerTabLayout,
} from "../../components/ViewPagerPage";
import { subpages } from "../../components/Subpage";
import { FollowType } from "../../proto/UserEnums";
import { HStack, VStack } from "../../components/VStack";
import SearchLayout from "../../components/SearchLayout";
import { Page } from "../../components/Page";
import { SearchSource } from "../search/SearchPage";
import { useHopper } from "../../hooks/useHopper";
import { NavMiddle } from "../../components/NavBar";
import { StateId } from "../../hooks/StateId";
import { useStringSearchParam } from "../../hooks/useTypedParam";

function SocialViewPagerPage() {
  const i18n = useI18n();
  const myUid = useMyUid();
  const backend = useBackend();
  const userSWR = useSWR([`user-${myUid}`], () => backend.getUser(myUid).run());
  const hopper = useHopper();
  const initPage = useStringSearchParam("type");
  const entries: [FollowType, ReactNode][] = [
    [
      FollowType.FRIEND,
      <SocialTab>
        <div>{userSWR.content?.friendsCount ?? 0}</div>
        <div>{i18n.friends()}</div>
      </SocialTab>,
    ],
    [
      FollowType.FOLLOWER,
      <SocialTab>
        <div>{userSWR.content?.fansCount ?? 0}</div>
        <div>{i18n.followers()}</div>
      </SocialTab>,
    ],
    [
      FollowType.FOLLOWING,
      <SocialTab>
        <div>{userSWR.content?.followingCount ?? 0}</div>
        <div>{i18n.following()}</div>
      </SocialTab>,
    ],
  ];

  return (
    <ViewPagerPage
      pageData={userSWR}
      subpages={subpages(entries, (e) => (
        <UserListPage type={e} />
      ))}
      initialPageId={initPage}
    >
      <div style={{ margin: "0 18px 20px" }}>
        <SearchLayout
          onClick={() => {
            hopper.push("search-my-friends");
          }}
        />
      </div>
      <ViewPagerTabLayout equalDistribution={true} />
      <NavMiddle>{i18n.my_friends()}</NavMiddle>
    </ViewPagerPage>
  );
}

const SocialTab = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const SocialHeadFrame = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const CellNum = styled.div`
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
`;

const CellTitle = styled.div`
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  font-size: 12px;
  font-weight: 400;
`;

const CountCell = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1 0 0;
  gap: 6px;
`;

const Line = styled.div`
  width: 1px;
  height: 11px;
  margin: auto 0;
  flex-shrink: 0;
  background: rgba(255, 255, 255, 0.25);
`;

export default SocialViewPagerPage;
