import React from "react";
import { NavBarBoundary } from "../components/NavBar";
import { RouterProvider } from "react-router-dom";
import { useHopper } from "../hooks/useHopper";
import { useReactRoutes } from "../service/ReactRoutesService";

export function NarrowAppShell() {
  const hopper = useHopper();
  const routes = useReactRoutes();
  return (
    <NavBarBoundary canDismiss={false} onBackward={() => hopper.back()}>
      <RouterProvider router={routes.baseRouter} />
    </NavBarBoundary>
  );
}
