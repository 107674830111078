import React, {
  PropsWithChildren,
  ReactElement,
  useContext,
  useEffect,
} from "react";
import { ObscuredZoneKey } from "../hooks/useObscuredZones";
import { useObscuringBar } from "../hooks/useObscuringBar";
import { PageDecoration } from "./PageDecoration";

export function PageHeader(props: { children?: ReactElement }) {
  const pageContext = useContext(PageDecoration);

  useEffect(() => {
    if (props.children) pageContext.setHeader(props.children);
  }, [props.children]);

  return <></>;
}

function PageFooterOverlay(
  props: PropsWithChildren<{
    obscuredZoneKey: ObscuredZoneKey;
    safeBottomDisabled: boolean | undefined;
  }>,
) {
  const [ref, padding] = useObscuringBar(props.obscuredZoneKey, "bottom");

  return (
    <div
      ref={ref}
      style={{
        position: "absolute",
        bottom: 0,
        width: "100%",
        paddingBottom: props.safeBottomDisabled ? 0 : padding + 28,
      }}
    >
      {props.children}
    </div>
  );
}

export function PageFooter(
  props: PropsWithChildren<{
    obscuredZoneKey: ObscuredZoneKey;
    safeBottomDisabled?: boolean;
  }>,
) {
  const pageContext = useContext(PageDecoration);

  useEffect(() => {
    pageContext.setFooter(
      <PageFooterOverlay
        obscuredZoneKey={props.obscuredZoneKey}
        safeBottomDisabled={props.safeBottomDisabled}
      >
        {props.children}
      </PageFooterOverlay>,
    );
  }, [props.children]);

  return <></>;
}
