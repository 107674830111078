import React, { PropsWithChildren, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { NavBarContext } from "../components/NavBar";

interface Context {
  locationKey: string;
}

const Context = React.createContext<Context>({
  locationKey: "not-in-react-router-context",
});

export function useReactRouterLocationKey() {
  return useContext(Context).locationKey;
}

export function RouterLocation(props: PropsWithChildren<{}>) {
  const location = useLocation();
  const context = useContext(NavBarContext);

  useEffect(() => {
    context.setCanGoBack(location.key !== "default");
  }, [location.key]);
  return (
    <Context.Provider value={{ locationKey: location.key }}>
      {props.children}
    </Context.Provider>
  );
}
