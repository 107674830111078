import { Page } from "../../../components/Page";
import { useBigIntParam } from "../../../hooks/useTypedParam";
import { useSWR } from "../../../hooks/swr/useSWR";
import { useI18n } from "../../../hooks/useI18n";
import TabCellSeparator from "../../../components/TabCellSeparator";
import SettingCell from "../../../components/SettingCell";
import { PlaceholderButton } from "../../../components/Buttons";
import { Image } from "../../../components/Image";
import ic_add from "../../../res/images/ic_add.svg";
import React from "react";
import { Spacing } from "../../../components/Spacing";
import styled from "styled-components";
import CircleBackgroundFrame from "../CircleBackgroundFrame";
import { useHopper } from "../../../hooks/useHopper";
import { useBackend } from "../../../service/APIService";
import { NavMiddle } from "../../../components/NavBar";

function CircleHomeLayoutPage() {
  const circleId = useBigIntParam("circleId");
  const backend = useBackend();
  const circleSWR = useSWR(backend.getCircle(circleId));
  const i18n = useI18n();
  const hopper = useHopper();
  return (
    <Page
      pageData={circleSWR}
      scrollPaddingDisabled={true}
      underlay={
        <CircleBackgroundFrame
          circleBackground={circleSWR.content?.circleBackground}
        />
      }
    >
      <NavMiddle>i18n.circle2_1_home_layout()</NavMiddle>
      <TabCellSeparator title={i18n.circle2_1_circle_home_tab_list()} />
      {circleSWR.content?.homeLayout?.tabList?.map((item, index) => {
        return (
          <SettingCell
            title={item.name}
            showAccessoryImage={false}
            showDivider={
              index !==
              (circleSWR.content?.homeLayout?.tabList?.length ?? 0) - 1
            }
            endValue={
              index === circleSWR.content?.homeLayout?.startIndex ? (
                <StartTabLabel>{i18n.start()}</StartTabLabel>
              ) : (
                <></>
              )
            }
          />
        );
      })}
      <Spacing height={30} />
      <PlaceholderButton style={{ marginInlineStart: 30, marginInlineEnd: 30 }}>
        <Image src={ic_add} alt={"reveal"} />
        {i18n.circle2_1_add_new_tab()}
      </PlaceholderButton>
      <Spacing height={30} />
    </Page>
  );
}

const StartTabLabel = styled.div`
  font-weight: 400;
  font-size: 10px;
  text-align: center;
  padding: 2px 6px;
  color: var(--color-text00);
  background-color: green;
  border-radius: 4px;
`;

export default CircleHomeLayoutPage;
