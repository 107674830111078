import {
  Animation,
  ModalContainer,
  ModalController,
  ModalDimmer,
  requireModalController,
} from "./Modal";
import { ModalContext } from "./ModalContext";
import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import { BounceIn, None, ShrinkFadeOut } from "./Keyframes";
import { HStackMixin, VStack } from "./VStack";

const AlertBg = styled.div<{ $animation: Animation }>`
  background: rgba(255, 255, 255, 0.9);

  position: relative;
  width: 80%;
  max-width: 500px;
  height: auto;
  max-height: 80%;

  border-radius: 18px;
  border: 0.6px solid rgba(0, 0, 0, 0.3);
  transition: margin-bottom 0.2s ease-in-out;
  animation: ${(p) =>
      p.$animation === Animation.None
        ? None
        : p.$animation === Animation.In
          ? BounceIn
          : ShrinkFadeOut}
    ${(p) => (p.$animation === Animation.In ? "0.3" : "0.15")}s linear 1
    forwards;
  animation-fill-mode: both;
  box-sizing: border-box;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: var(--color-text00r);
`;

const Msg = styled.div`
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: var(--color-text00r);
`;

export const AlertButton = styled.div`
  ${HStackMixin};
  min-height: 44px;
  color: #182e63;
  text-align: center;
  font-size: 17px;
  font-weight: 400;
  border-top: solid 1px rgba(128, 128, 128, 0.55);
  justify-content: center;

  &:active {
    background: rgba(0, 0, 0, 0.1);
  }
`;

export function WhiteAlert(
  props: PropsWithChildren<{
    icon?: string;
    title?: string;
    message?: string;
    modal: ModalController;
  }>,
) {
  requireModalController(props.modal);

  return props.modal.mounted ? (
    <ModalContainer id={props.modal.id} modal={props.modal}>
      <ModalContext.Provider value={{ modal: props.modal }}>
        <ModalDimmer
          $animation={props.modal.animation}
          $backgroundColor={"rgba(0, 0, 0, 0.4)"}
        />
        <AlertBg
          onClick={(e) => {
            e.stopPropagation();
          }}
          $animation={props.modal.animation}
          onAnimationEnd={props.modal.onAnimationEnd}
        >
          <VStack style={{ alignItems: "stretch" }}>
            <VStack style={{ alignItems: "stretch", padding: "12px" }}>
              {props.title && <Title>{props.title}</Title>}
              {props.message && <Msg>{props.message}</Msg>}
            </VStack>
            {props.children}
          </VStack>
        </AlertBg>
      </ModalContext.Provider>
    </ModalContainer>
  ) : (
    <></>
  );
}
