import { Page } from "../../../components/Page";
import { useBigIntParam } from "../../../hooks/useTypedParam";
import { useBackend } from "../../../service/APIService";
import { useSWRArray } from "../../../hooks/swr/useSWRArray";
import { JoinRequestListInfo } from "../JoinCircleRequest";
import { I18n, useI18n } from "../../../hooks/useI18n";
import { useHopper } from "../../../hooks/useHopper";
import React from "react";
import { HStack, Spring, VStack } from "../../../components/VStack";
import { UserAvatarView } from "../../../components/views/UserAvatarView";
import { UserNameView } from "../../../components/views/UserNameView";
import { timeIntervalString } from "../../../utils/DateTimeUtils";
import styled from "styled-components";
import { OverFlowTextStyle } from "../../../components/Text";
import { RegularButton } from "../../../components/Buttons";
import { PageFooter } from "../../../components/PageHeaderFooter";
import { useLoadState } from "../../../hooks/LoadState";
import { useNativePage } from "../../../hooks/useBridge";
import { useErrorHandler } from "../../../components/handleError";

export function CircleJoinManageRequestPage() {
  const circleId = useBigIntParam("circleId");
  const requestId = useBigIntParam("requestId");
  const backend = useBackend();
  const listSWR = useSWRArray(
    backend.getUserJoinRequestLogs(circleId, requestId),
  );

  const i18n = useI18n();
  const hopper = useHopper();
  const handleError = useErrorHandler();

  const joinLogTask = useLoadState();
  const nativePage = useNativePage();

  const ZidContent = styled.div`
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
    font-size: 12px;
    ${OverFlowTextStyle};
    -webkit-line-clamp: 1;
  `;

  async function acceptCircleJoin() {
    const r = await joinLogTask.run(async () => {
      const circle = await backend
        .handleCircleJoinRequest(circleId, requestId, 1, false)
        .run();
    });

    if (!r.success) {
      handleError(r.error);
    } else {
      hopper.back();
    }
  }

  async function rejectCircleJoin() {
    const r = await joinLogTask.run(async () => {
      const circle = await backend
        .handleCircleJoinRequest(circleId, requestId, 2, false)
        .run();
    });

    if (!r.success) {
      handleError(r.error);
    } else {
      hopper.back();
    }
  }

  return (
    <Page pageData={listSWR}>
      {listSWR.content.length > 0 && (
        <HStack
          style={{ width: "100%", gap: 14, display: "flex", marginBottom: 16 }}
        >
          <UserAvatarView
            user={listSWR.content[0].user}
            iconSize={45}
          ></UserAvatarView>
          <VStack style={{ flexShrink: 2, gap: 2 }}>
            <UserNameView user={listSWR.content[0].user}></UserNameView>
            <ZidContent>{"@" + listSWR.content[0].user?.socialId}</ZidContent>
          </VStack>
          <Spring />
        </HStack>
      )}
      {listSWR.content.map((info) => (
        <CircleJoinLogCell key={info.uid} info={info} i18n={i18n} />
      ))}

      <PageFooter obscuredZoneKey={"CircleJoinLogsBottom"}>
        <HStack style={{ marginInlineStart: 35, marginInlineEnd: 35 }}>
          <RegularButton
            style={{ width: "calc((100% - 20px) / 2)" }}
            onClick={() => {
              rejectCircleJoin().then();
            }}
          >
            {i18n.reject()}
          </RegularButton>
          <div style={{ width: 20 }}></div>
          <RegularButton
            style={{ width: "calc((100% - 20px) / 2)" }}
            onClick={() => {
              acceptCircleJoin().then();
            }}
          >
            {i18n.accept()}
          </RegularButton>
        </HStack>
      </PageFooter>
    </Page>
  );
}

export function CircleJoinLogCell(props: {
  info: JoinRequestListInfo;
  i18n: I18n;
}) {
  const TimeContent = styled.div`
    font-weight: 400;
    color: rgba(255, 255, 255, 0.4);
    font-size: 10px;
    ${OverFlowTextStyle};
    -webkit-line-clamp: 1;
  `;

  const AnswerContent = styled.div`
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    font-size: 15px;
    ${OverFlowTextStyle};
  `;

  const QuestionContent = styled.div`
    font-weight: 400;
    color: rgba(255, 255, 255, 0.7);
    font-size: 15px;
    ${OverFlowTextStyle};
  `;

  let time = props.info.createdTime ?? 0;
  let date = new Date(time * 1000);
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "rgba(255, 255, 255, 0.08)",
        borderRadius: 8,
      }}
    >
      <VStack
        style={{
          width: "calc(100% - 24px)",
          margin: 12,
          gap: 8,
        }}
      >
        {(props.info.question?.content?.length ?? 0) > 0 && (
          <HStack>
            <QuestionContent>{props.info.question?.content}</QuestionContent>
          </HStack>
        )}
        <TimeContent>{timeIntervalString(date, props.i18n)}</TimeContent>
        <AnswerContent>{props.info.answer}</AnswerContent>
      </VStack>
    </div>
  );
}
