import styled from "styled-components";
import {
  cell_on_pressed,
  full_page_width_cell,
} from "../../components/CommonStyles";

export const DemoCell = styled.div`
  color: var(--color-text00);
  font-size: 16px;
  padding: 20px 0;
  flex-shrink: 0;
  ${full_page_width_cell}
  ${cell_on_pressed}
`;

export const MonoLabel = styled.div`
  font-family: monospace;
  font-size: 16px;
  color: var(--color-text00);
`;

export const DemoNote = styled.div`
  font-size: 18px;
  color: var(--color-text00);
  margin: 32px 0 16px;
  padding-top: 4px;

  border-top: 1px solid var(--color-text20);
`;
