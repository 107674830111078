import { Page } from "../../components/Page";
import { useBackend } from "../../service/APIService";
import { useBigIntParam } from "../../hooks/useTypedParam";
import { NavEnd, NavItem, NavMiddle } from "../../components/NavBar";
import { useI18n } from "../../hooks/useI18n";
import { useSWR } from "../../hooks/swr/useSWR";
import { HStack, VStack } from "../../components/VStack";
import { useHopper } from "../../hooks/useHopper";
import { Image } from "../../components/Image";
import { andLog } from "../../components/handleError";
import React, { useState } from "react";
import styled from "styled-components";
import iconPicked from "../../res/images/ic_choosed.svg";
import iconUnPicked from "../../res/images/ic_unchoosed.svg";
import { NavButtonType } from "../../bridge/NavButtonDesc";
import { SingleLineUnspecifiedWidth } from "../../components/CommonViews";
import { useProduceResult } from "../../hooks/useResult";
import { NFT } from "../../proto/NFT";

export type NFTPickerResult = {
  nftIds: bigint[];
};

function NFTPickerPage() {
  const backend = useBackend();
  const walletAccountId = useBigIntParam("walletAccountId");
  const i18n = useI18n();
  const nftListSWR = useSWR(
    walletAccountId ? backend.getAccountNFTList(walletAccountId) : undefined,
  );

  const { fill } = useProduceResult<NFTPickerResult>("NFTPicker");

  const [chosenNFTId, setChosenNFTId] = useState<bigint>();
  const hopper = useHopper();
  const onSave = () => {
    if (chosenNFTId) {
      fill((prev) => {
        return {
          ...prev,
          nftIds: [chosenNFTId],
        };
      })
        .then(() => hopper.back())
        .catch(andLog);
    }
  };

  return (
    <Page pageData={nftListSWR}>
      <NavMiddle>{i18n.mars_select_collectibles()}</NavMiddle>
      <NavEnd>{NavItem.image(NavButtonType.Check, onSave)}</NavEnd>
      {nftListSWR?.content?.list.map((accountNFT) => (
        <NFTPickerCell
          key={accountNFT.nftId}
          nft={accountNFT.nftMetaInfo}
          chosenNFTId={chosenNFTId}
          onPick={() => {
            setChosenNFTId(accountNFT.nftId);
          }}
        />
      ))}
    </Page>
  );
}

interface NFTPickerProps {
  nft: NFT;
  chosenNFTId?: bigint;
  onPick: () => void;
}

function NFTPickerCell(props: NFTPickerProps) {
  const nft = props.nft;
  const i18n = useI18n();
  return (
    <Container onClick={props.onPick}>
      <HStack style={{ gap: 12, flex: 1, alignItems: "start" }}>
        <Image
          src={[nft.entity?.preview, "smallest"]}
          width={70}
          style={{ borderRadius: 4 }}
          height={70}
        />
        <VStack style={{ gap: 10, flex: 1 }}>
          <NFTName>{nft.name}</NFTName>
          <HStack>
            <NFTInfo>{i18n.jul23_impr2_edition_impr(1)}</NFTInfo>
            <VerticalDivider />
            <NFTInfo>{i18n.web3_v0_available()}</NFTInfo>
            <NFTInfo>{nft.availableBalance}</NFTInfo>
          </HStack>
        </VStack>
      </HStack>
      {props.chosenNFTId === props.nft.nftId ? (
        <Image src={iconPicked} width={24} height={24} />
      ) : (
        <Image src={iconUnPicked} width={24} height={24} />
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
  gap: 10px;
  justify-content: space-between;
`;

const VerticalDivider = styled.div`
  height: 10px;
  margin-inline-start: 8px;
  margin-inline-end: 8px;
  width: 1px;
  background-color: 1px solid rgba(255, 255, 255, 0.1);
`;

const NFTName = styled.div`
  color: var(--color-text00);
  flex: 1;
  ${SingleLineUnspecifiedWidth};
  font-size: 14px;
  font-weight: 400;
`;

const NFTInfo = styled.div`
  color: var(--color-text00);
  font-size: 10px;
  font-weight: 400;
`;

const AvailableCurrency = styled.div`
  color: var(--color-text00);
  font-size: 12px;
  font-weight: 500;
`;

const AvailableLabel = styled.div`
  color: var(--color-text20);
  font-size: 12px;
  margin-inline-end: 5px;
  font-weight: 400;
`;

export default NFTPickerPage;
