import { useEffect, useRef, useState } from "react";

export interface UseLoadScriptOptions {
  src: string;
  nonce?: string;
  onScriptLoadSuccess?: () => void;
  onScriptLoadError?: () => void;
}

export function useLoadScript(options: UseLoadScriptOptions): boolean {
  const { src, nonce, onScriptLoadSuccess, onScriptLoadError } = options;

  const [scriptLoadedSuccessfully, setScriptLoadedSuccessfully] =
    useState(false);

  const onScriptLoadSuccessRef = useRef(onScriptLoadSuccess);
  onScriptLoadSuccessRef.current = onScriptLoadSuccess;

  const onScriptLoadErrorRef = useRef(onScriptLoadError);
  onScriptLoadErrorRef.current = onScriptLoadError;

  useEffect(() => {
    const scriptTag = document.createElement("script");
    scriptTag.src = src;
    scriptTag.async = true;
    scriptTag.defer = true;
    if (nonce) {
      scriptTag.nonce = nonce;
    }
    scriptTag.onload = () => {
      setScriptLoadedSuccessfully(true);
      onScriptLoadSuccessRef.current?.();
    };
    scriptTag.onerror = (error: any) => {
      console.error("useLoadScript:", error);
      setScriptLoadedSuccessfully(false);
      onScriptLoadErrorRef.current?.();
    };

    document.body.appendChild(scriptTag);

    return () => {
      document.body.removeChild(scriptTag);
    };
  }, [src, nonce]);

  return scriptLoadedSuccessfully;
}
