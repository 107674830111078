import { useI18n } from "../../hooks/useI18n";
import {
  CenterModal,
  ModalController,
  ModalTitle,
} from "../../components/Modal";
import React from "react";
import styled, { css } from "styled-components";
import { removeOptional } from "../../utils/typeUtils";
import { PowerMarkdown } from "../../components/PowerMarkdown";
import { service_privacy_markdown_style } from "./AuthHomePage";
import { StrongButton } from "../../components/Buttons";

const Cancel = styled.button`
  border: none;
  background: none;
  color: var(--color-text00);
  font-size: 16px;
`;

export function ServiceAndPrivacyModal(props: {
  modal: ModalController;
  agree: () => void;
}) {
  const i18n = useI18n();
  return (
    <CenterModal modal={props.modal} mixin={ModalStyle} hideCloseBtn={true}>
      <ModalTitle style={{ marginTop: 32, fontSize: 18 }}>
        {i18n.auth_impr_pop_title()}
      </ModalTitle>
      <PowerMarkdown textStyle={service_privacy_markdown_style}>
        {i18n.moon_landing_terms_base_md()}
      </PowerMarkdown>
      <StrongButton
        onClick={() => {
          props.modal.close(() => props.agree());
        }}
      >
        {i18n.auth_impr_pop_agree()}
      </StrongButton>
      <Cancel onClick={removeOptional(props.modal.close)}>
        {i18n.cancel()}
      </Cancel>
    </CenterModal>
  );
}

const ModalStyle = css`
  padding: 30px 40px 32px;
`;
