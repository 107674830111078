import { Tag } from "../proto/Tag";
import React from "react";
import styled from "styled-components";
import { useI18n } from "../hooks/useI18n";
import { useHopper } from "../hooks/useHopper";

export interface TagsFlowLayoutProps {
  tags: Tag[];
  showAdd?: boolean;
}

const TagsFlowLayout: React.FC<TagsFlowLayoutProps> = ({ tags, showAdd }) => {
  const i18n = useI18n();
  return (
    <div style={FlexFlowStyles.tagsContainer}>
      {tags.map((tag) => (
        <TagCell key={tag.tagId} tag={tag} />
      ))}
      {showAdd && <AddATagBg>{`#${i18n.add_a_tag()}`}</AddATagBg>}
    </div>
  );
};

export interface TagCellProps {
  tag: Tag;
}

const TagCell: React.FC<TagCellProps> = ({ tag }) => {
  const hopper = useHopper();
  return (
    <TagCellBg
      style={{
        backgroundColor: tag.style?.backgroundColor,
        borderRadius: 20,
        border: `1px solid ${tag.style?.borderColor}`,
      }}
      onClick={() => {
        hopper.push(`tags/${tag.tagId}`, {
          tagName: tag.tagName,
          bgColor: tag.style?.textColor,
        });
      }}
    >
      <TagName
        style={{ color: tag.style?.textColor }}
      >{`#${tag.tagName}`}</TagName>
    </TagCellBg>
  );
};

const AddATagBg = styled.div`
  height: 30px;
  display: flex;
  justify-content: center;
  border-radius: 60px;
  padding-inline-start: 10px;
  font-size: 12px;
  font-weight: 600;
  padding-inline-end: 10px;
  color: rgba(255, 255, 255, 0.8);
  align-items: center;
  border: 1px dashed rgba(255, 255, 255, 0.6);
`;

const TagName = styled.div`
  font-weight: 700;
  color: var(--color-text00);
  font-size: 12px;
`;

export const FlexFlowStyles = {
  tagsContainer: {
    display: "flex",
    flexWrap: "wrap" as "wrap", // 确保一行显示不下时换行
    columnGap: "8px", // 横向间距（列间距）
    rowGap: "12px", // 纵向间距（行间距）
    paddingTop: "12px", // 确保第一行的顶部有 12px 间距
    paddingBottom: "12px", // 确保最后一行的底部有 12px 间距
  },
};

const TagCellBg = styled.div`
  padding: 8px 12px; // 可选：为每个tag cell增加内边距
  border-radius: 4px; // 可选：给tag cell设置圆角
  background-color: #e0e0e0; // 可选：设置背景颜色
  font-size: 14px; // 可选：调整字体大小

  transition: opacity 0.2s ease; // 平滑的透明度过渡
  &:active {
    opacity: 0.4; // 按下时透明度变为 0.4
  }
`;

export default TagsFlowLayout;
