import { Media } from "./Media";
import { User } from "./User";
import { zBigInt, zEnum, zStatic } from "../utils/zodUtils";
import { z } from "zod";
import { CircleMemberTitle } from "./CircleMemberTitle";
import { Tag } from "./Tag";
import { Category } from "./Category";
import { CircleBackground } from "./CircleBackground";
import { CircleMenu } from "./CircleMenu";
import {
  CircleJoinPermission,
  JoinRequestStatus,
  Question,
} from "../pages/circle/JoinCircleRequest";
import { RichFormat } from "../components/zdoc/RichFormat";
import { Currency } from "./Currency";
import { ObjectStatus } from "./ObjectStatus";
import { CircleMemberStatus } from "./CircleMemberStatus";
import { CurrentMemberInfo } from "./CurrentMemberInfo";
import { CircleMembershipCard } from "./CircleMembershipCard";
import { TokenProject } from "./TokenProject";
import { CircleLinkStatus } from "./CircleLinkStatus";
import { SmallNote } from "./SmallNote";

export enum VerifiedStatus {
  VERIFIED = 1,
  // NOT_VERIFIED = 2,
}

export const CurrentUpgradeRecord = z.object({
  upgradeTime: z.number().optional(),
  recordId: zBigInt,
});

export const CircleTabItem = z.object({
  name: z.string().optional(),
  originalName: z.string().optional(),
  link: z.string().optional(),
  objectType: z.number().optional(),
  objectId: zBigInt.default(BigInt(0)),
  linkIcon: Media.optional(),
});

export type CircleTabItem = zStatic<typeof CircleTabItem>;

export const CircleHomeLayout = z.object({
  tabList: CircleTabItem.array().optional(),
  startIndex: z.number().optional(),
});

export const CircleLevelInfo = z.object({
  name: z.string(),
  icon: Media,
});

export type CircleLevelInfo = zStatic<typeof CircleLevelInfo>;

export enum CircleTokenLinkType {
  Create = 1,
  Bind = 2,
}

export const CircleExtensions = z.object({
  questionId: zBigInt.optional(),
  question: Question.optional(),
  showTokenCreatedAnim: z.boolean().optional(),
  tokenLinkType: zEnum(CircleTokenLinkType).optional(),
});

export enum CircleJoinStep {
  /// CircleJoinStep = 1  是否显示在Pending Section
  showInMyCircles = 1,
  /// 批准通过但需要购买会员卡
  purchaseMembershipCard = 2,
  /// 是否需要显示加入成功动画  对应Active
  showJoinSuccessAnim = 3,
}

export const CircleJoinRequestExtensions = z.object({
  nextStep: zEnum(CircleJoinStep).optional(),
});

export const CircleJoinRequest = z.object({
  requestId: z.bigint().optional(),
  status: zEnum(JoinRequestStatus).optional(),
  extensions: CircleJoinRequestExtensions.optional(),
});

export enum CircleJoinConditionType {
  MembershipCard = 1,
}

export const CircleJoinCondition = z.object({
  conditionType: zEnum(CircleJoinConditionType).optional(),
});

export enum Discoverability {
  Public = 1,
  Private = 2,
}

export const Circle = z.object({
  circleId: zBigInt,
  icon: Media.optional(),
  name: z.string().optional().default(""),
  themeColor: z.string().optional(),
  cover: Media.optional(),
  circleIcon: Media.optional(),
  circleBackground: CircleBackground.optional(),
  menuBackground: CircleBackground.optional(),
  homeTabBackground: CircleBackground.optional(),
  adminIdList: z.array(zBigInt).optional().default([]),
  coAdminIdList: z.array(zBigInt).optional().default([]),
  socialId: z.string().default("?"),
  author: User.optional(),
  socialIdModified: z.number().default(0),
  homeLayout: CircleHomeLayout.optional(),
  membersCount: z.number().optional(),
  joinedStatus: zEnum(CircleMemberStatus).default(CircleMemberStatus.UNKNOWN),
  language: z.string(),
  category: Category.optional(),
  description: z.string().optional().default(""),
  announcementTitle: z.string().optional(),
  announcementStatus: z.number().optional(),
  createdTime: z.number(),
  tagline: z.string().optional(),
  welcomeMessage: z.string().optional(),
  menu: CircleMenu.optional(),
  memberPreview: User.array().optional(),
  circleLevelInfo: CircleLevelInfo.optional(),
  tagList: Tag.array().optional(),
  verifiedStatus: zEnum(VerifiedStatus),
  level: z.number().default(0),
  currentUpgradeRecord: CurrentUpgradeRecord.optional(),
  joinPermission: zEnum(CircleJoinPermission),
  extensions: CircleExtensions.optional(),
  richFormat: RichFormat.optional(),
  mediaList: z.array(Media).optional(),
  tokenInfo: Currency.optional(),
  tokenProject: TokenProject.optional(),
  bounceRate: z.number().default(0),
  status: zEnum(ObjectStatus).optional(),
  joinConditions: z.array(CircleJoinCondition).optional(),
  discoverability: zEnum(Discoverability).optional(),
  tokenLinkStatus: zEnum(CircleLinkStatus).optional(),
  joinRequest: CircleJoinRequest.optional(),
  joinPasscode: z.string().optional(),
  currentMemberInfo: CurrentMemberInfo.optional(),
  inviteLink: z.string().optional(),
  tokenProjectId: zBigInt.optional(),
  smallNoteList: z.array(SmallNote).optional(),
});

export type Circle = zStatic<typeof Circle>;

export function isCircleAdminOrCoAdmin(
  circle: Circle,
  userId: bigint,
): boolean {
  return (
    (circle.adminIdList ?? []).indexOf(userId) !== -1 ||
    (circle.coAdminIdList ?? []).indexOf(userId) !== -1
  );
}

export function isCircleAdmin(circle: Circle, userId: bigint): boolean {
  return (circle.adminIdList ?? []).indexOf(userId) !== -1;
}

export function isRequireCardToJoin(circle: Circle): boolean {
  return (
    circle.joinConditions?.some(
      (condition) =>
        condition.conditionType === CircleJoinConditionType.MembershipCard,
    ) ?? false
  );
}

export const Benefit = z.object({
  title: z.string(),
  description: z.string(),
  icon: Media,
});

export const CircleUpgradeDescription = z.object({
  circleMemberAllowed: z.number(),
  energyCollected: z.number(),
  newCircleLevelInfo: CircleLevelInfo,
  benefitList: z.array(Benefit),
});

export const CircleUpgradeNFTRecipient = z.object({
  user: User,
});

export type CircleUpgradeNFTRecipient = zStatic<
  typeof CircleUpgradeNFTRecipient
>;

export const CircleMemberCard = z.object({
  circleInfo: Circle,
  joinedTime: z.number(),
  memberTitleList: z.array(CircleMemberTitle).optional(),
  contribution: z.number().optional(),
  circleMembershipCard: CircleMembershipCard.optional(),
});

export type CircleMemberCard = zStatic<typeof CircleMemberCard>;

export const CircleTokenPool = z.object({
  state: z.number(),
  pooledToken: zBigInt,
  pooledSol: zBigInt,
  realToken: zBigInt,
  realSol: zBigInt,
  totalSupply: zBigInt,
});

export type CircleTokenPool = zStatic<typeof CircleTokenPool>;

export function isValidCircle(
  circle: Circle | {} | undefined,
): circle is Circle {
  return (
    typeof circle === "object" &&
    circle !== undefined &&
    "circleId" in circle &&
    !!(circle as Circle).circleId
  );
}
