import { Page } from "../../../components/Page";
import { NavMiddle } from "../../../components/NavBar";
import { useI18n } from "../../../hooks/useI18n";
import PaymentServiceFee, {
  DEFAULT_PRIORITY_FEE,
  DEFAULT_SLIPPAGE,
} from "../../wallet/payment/PaymentServiceFee";
import { useTransactionBody } from "../../wallet/send/SendTokenPage";
import PaymentAccount from "../../wallet/payment/PaymentAccount";
import PaymentFooter from "../../wallet/payment/PaymentFooter";
import { BottomSheet, closeModal, useModal } from "../../../components/Modal";
import iconWarning from "../../../res/images/icon_orange_warning.svg";
import {
  useBigIntParam,
  useBigIntSearchParam,
} from "../../../hooks/useTypedParam";
import {
  CenterChild,
  HStack,
  VSpace,
  VStack,
} from "../../../components/VStack";
import styled from "styled-components";
import { Image } from "../../../components/Image";
import { CellDivider, ContentGroup } from "../../../components/ContentGroup";
import React, { useEffect, useMemo } from "react";
import { useSWR } from "../../../hooks/swr/useSWR";
import { useBackend } from "../../../service/APIService";
import { useAssetAccount } from "../../wallet/useAssetAccount";
import { andLog } from "../../../components/handleError";
import { TxSheet, usePerformTx } from "../../wallet/payment/TransactionDialog";
import { useHopper } from "../../../hooks/useHopper";
import { GasFeeTxType } from "../../../proto/Wallet";
import { useRegularAccount } from "../../wallet/useRegularAccount";
import { useHopState } from "../../../hooks/useHopState";
import {
  CurrencyType,
  formatMoney,
  getCurrencyIcon,
  getCurrencyName,
} from "../../../proto/Currency";
import CircleCardsPreviewCell from "./CircleCardsPreviewCell";
import { CardPaymentPreview } from "./CardPaymentPreview";
import { OrderType } from "../../wallet/order/OrderDetailPage";
import { CardTransactionPreview } from "./CardTransactionPreview";
import { isValidCircleToken } from "../../../proto/CircleFin";
import { JSONUtil } from "../../../utils/JSONUtil";
import BigNumber from "bignumber.js";
import { useNativePage } from "../../../hooks/useBridge";
import CurrencyTransactionView from "../../wallet/payment/CurrencyTransactionView";
import { copyObject } from "../../../utils/copyObject";
import { TransactionBody } from "../../wallet/payment/TransactionBody";

function CircleCardRedeemPage() {
  const i18n = useI18n();
  const backend = useBackend();
  const paymentModal = useModal("payment-modal");
  const nftId = useBigIntParam("cardId");
  const assetAccount = useAssetAccount();

  const toChainAddress = useMemo(() => {
    return assetAccount?.address;
  }, [assetAccount]);

  const assetAccountId = assetAccount?.accountId ?? BigInt(0);
  const editingBody = useTransactionBody({
    toChainAddress: toChainAddress,
    toAccountId: assetAccountId,
  });
  const hopper = useHopper();
  const [isUnitPriceFilled, setUnitPriceFilled] = useHopState(
    "unitPriceFilled",
    false,
  );
  const nftSWR = useSWR(backend.getMembershipCardNFT(nftId));
  const circleId = useMemo(() => {
    return nftSWR.content?.circleMembershipCard?.circleId;
  }, [nftSWR.content]);
  const circleSWR = useSWR(circleId ? backend.getCircle(circleId) : undefined);
  const unitPriceCurrency = useMemo(() => {
    return nftSWR.content?.circleMembershipCard?.canRedeemCurrency;
  }, [nftSWR.content]);
  useEffect(() => {
    if (unitPriceCurrency && !isUnitPriceFilled && editingBody.content) {
      setUnitPriceFilled(true);
      editingBody
        .fill((prevData) => ({
          ...prevData,
          cardCount: 1,
          // currency: unitPriceCurrency,
          // decimals: unitPriceCurrency.decimals,
          // currencyType: unitPriceCurrency.currencyType,
          // amount: unitPriceCurrency.amount,
          slippage: DEFAULT_SLIPPAGE,
          priorityFee: DEFAULT_PRIORITY_FEE,
        }))
        .catch(andLog);
    }
  }, [isUnitPriceFilled, editingBody.content, unitPriceCurrency]);
  const regularAccount = useRegularAccount();
  const walletAccountId = regularAccount?.walletAccountId;
  const currencyListSWR = useSWR(
    walletAccountId ? backend.getCurrencyList(walletAccountId) : undefined,
  );

  const paymentTargetCurrency = useMemo(() => {
    return currencyListSWR?.content?.list?.find(
      (item) =>
        item.currency.currencyType === editingBody.content.gasFeeCurrencyType,
    )?.currency;
  }, [currencyListSWR?.content, editingBody.content]);
  const nativePage = useNativePage();

  const paymentCheck = async () => {
    if (
      BigNumber(editingBody.content.gasFeeAmount ?? "0").isGreaterThan(
        BigNumber(paymentTargetCurrency?.amount ?? "0"),
      )
    ) {
      nativePage.infoHud(i18n.clover_dont_have_enough_balance()).catch(andLog);
      return false;
    }

    return true;
  };

  const performTx = usePerformTx(
    async () => {
      const updatedBody = copyObject<TransactionBody>(editingBody.content, {
        cardId: nftId,
        payAssetAccountId: assetAccountId,
        fromAccountId: assetAccountId,
        toAccountId: assetAccountId,
        fromAssetAccountId: assetAccountId,
      });
      const resp = await backend.performRedeem(updatedBody).run();
      return resp.orderId;
    },
    async (orderId: bigint | undefined) => {
      await closeModal(paymentModal);
      await editingBody.clear();
      if (orderId) {
        hopper.dismissLayerAndPush(`wallet/order/${orderId}`, {
          orderType: OrderType.OtherOrder,
          showDialog: true,
          title: i18n.clover_redeem_successfully(),
        });
      }
    },
  );

  return (
    <Page pageData={nftSWR}>
      <NavMiddle>{i18n.web3_redeem_redeem()}</NavMiddle>
      <WarningLayout>
        <Image
          src={iconWarning}
          width={16}
          height={16}
          style={{ margin: "auto 0" }}
        />
        <WarningText>{i18n.clover_redeem_card_warning()}</WarningText>
      </WarningLayout>
      <VSpace height={18} />
      <ContentGroup>
        <MiniTitle>{i18n.clover_redeem_membership_card()}</MiniTitle>
        <VSpace height={12} />
        <CellDivider />
        <VSpace height={12} />
        <CardTransactionPreview
          card={nftSWR.content}
          circle={circleSWR?.content}
        />
        <VSpace height={12} />
        <HStack style={{ justifyContent: "space-between" }}>
          <RedeemAmountLabel>{i18n.clover_redeem_amount()}</RedeemAmountLabel>
          <HStack style={{ gap: 4 }}>
            <Image
              width={16}
              height={16}
              style={{ borderRadius: 2 }}
              src={getCurrencyIcon(unitPriceCurrency)}
            ></Image>
            <RedeemAmountValue>
              {formatMoney("medium", unitPriceCurrency)}
            </RedeemAmountValue>
            <RedeemAmountValue>
              {getCurrencyName(unitPriceCurrency)}
            </RedeemAmountValue>
          </HStack>
        </HStack>
      </ContentGroup>
      <VSpace height={20} />
      <PaymentServiceFee
        editingBody={editingBody}
        gasFeeTxType={GasFeeTxType.Swap}
      />
      <VSpace height={20} />
      <PaymentAccount />
      <VSpace height={20} />
      <PaymentFooter
        nextButtonText={i18n.web3_redeem_redeem()}
        editingBody={editingBody}
        paymentModal={paymentModal}
        paymentCheck={paymentCheck}
      />

      <TxSheet modal={paymentModal} performTx={performTx}>
        <CurrencyTransactionView body={editingBody.content} />
      </TxSheet>
    </Page>
  );
}

const WarningLayout = styled.div`
  border: 1px solid #ffb332;
  display: flex;
  gap: 4px;
  border-radius: 8px;
  flex-direction: row;
  padding: 6px 12px;
  background: rgba(255, 179, 50, 0.1);
`;

const WarningText = styled.div`
  color: #ffb332;
  flex: 1;
  font-weight: 400;
  font-size: 14px;
`;

const MiniTitle = styled.div`
  color: var(--color-text10);
  font-weight: 400;
  font-size: 12px;
`;

const RedeemAmountLabel = styled.div`
  color: var(--color-text10);
  font-weight: 400;
  font-size: 12px;
`;

const RedeemAmountValue = styled.div`
  color: var(--color-text00);
  font-weight: 500;
  font-size: 18px;
`;

const UnitPriceLabel = styled.div`
  color: var(--color-text10);
  font-weight: 300;
  font-size: 11px;
`;

export default CircleCardRedeemPage;
