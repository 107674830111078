import { useMemo } from "react";
import BigNumber from "bignumber.js";
import { copyObject } from "../../../utils/copyObject";
import { useTransactionBody } from "../send/SendTokenPage";
import { CurrencyType } from "../../../proto/Currency";
import { LivePricingData } from "../../../proto/GemPrice";
import { useSWR } from "../../../hooks/swr/useSWR";
import { useBackend } from "../../../service/APIService";
import { jsonifyBigNumber } from "../../../utils/CurrencyAmount";
import { andLog } from "../../../components/handleError";

export function useMergedCurrency(
  editingBody: ReturnType<typeof useTransactionBody>,
  showTotalAmount?: boolean,
) {
  const backend = useBackend();
  const gemsSWR = useSWR(backend.getLivePricingData());
  const livePriceData = gemsSWR.content;
  return useMemo(() => {
    const paymentCurrency = editingBody.content.currency;
    const gasAmount = editingBody.content.gasFeeAmount;
    if (showTotalAmount) {
      const totalPrice = BigNumber(paymentCurrency?.amount ?? "0");
      const v1 = totalPrice.multipliedBy(BigNumber("0.01"));
      const v2 = totalPrice
        .multipliedBy(BigNumber("1.01"))
        .multipliedBy(BigNumber("0.01"));
      const serviceFeeAmount = BigNumber(gasAmount ?? "0")
        .plus(v1)
        .plus(v2);
      let feeWithPriorityFee: BigNumber;
      if (editingBody.content.currency?.currencyType === CurrencyType.SOL) {
        const v3 = BigNumber("1000000");
        feeWithPriorityFee = serviceFeeAmount.plus(v3);
      } else {
        const v3 = BigNumber("1000000").multipliedBy(
          jsonifyBigNumber(BigNumber(gemsSWR.content?.gemsAmount ?? "0"), 9),
        );
        feeWithPriorityFee = serviceFeeAmount.plus(v3);
      }
      const finalPaymentAmount = totalPrice.plus(feeWithPriorityFee);
      const amount = finalPaymentAmount.toFixed(0);
      if (
        !editingBody.content.payWithGems &&
        editingBody.content.totalPrice !== amount
      ) {
        editingBody
          .fill((prevData) => ({
            ...prevData,
            totalPrice: amount,
            displayServiceFee: feeWithPriorityFee.toFixed(0),
          }))
          .catch(andLog);
      }
      return copyObject(paymentCurrency, {
        amount: amount,
      });
    } else {
      const amount = BigNumber(paymentCurrency?.amount ?? "0").plus(
        BigNumber(gasAmount ?? "0"),
      );
      return copyObject(paymentCurrency, {
        amount: amount.toFixed(0),
      });
    }
  }, [editingBody.content, livePriceData]);
}
