import { JSONUtil } from "../utils/JSONUtil";
import { z } from "zod";
import { zStatic } from "../utils/zodUtils";
import { isSpongeKit } from "../utils/isSpongeKit";

export const Rejectable = z.object({
  name: z.string(),
  message: z.string(),
  apiCode: z.number().optional(),
  debugMessage: z.string().optional(),
  redirectUrl: z.string().optional(),
});

export const isRejectable = (
  value: any,
): value is zStatic<typeof Rejectable> => {
  return !!(value.name && value.message);
};

export function getErrorMsg(e: unknown): string {
  if (e instanceof Error) {
    return e.message;
  } else if (isRejectable(e)) {
    if (isSpongeKit() && e.debugMessage) {
      return `${e.message} [SpongeKit Only] ${e.debugMessage}`;
    } else {
      return e.message;
    }
  } else {
    return JSONUtil.stringify(e);
  }
}
