import { styled } from "styled-components";
import React, {
  createContext,
  PropsWithChildren,
  TouchEvent,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { MaterialRefreshControl } from "./vscroll/MaterialRefreshControl";
import { andLog } from "./handleError";
import {
  isAnyRefreshTargetLoading,
  RefreshTarget,
  refreshTargetLoadAll,
} from "./vscroll/RefreshTarget";
import { useSharedState } from "../hooks/useSharedState";

export const Container = styled.div`
  display: flex;
  position: absolute;
  justify-content: stretch;
  align-items: stretch;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
`;

interface Context {
  addRefreshTarget(refreshTarget: RefreshTarget, id: string): void;

  removeRefreshTarget(id: string): void;
}

const Context = createContext<Context>({
  addRefreshTarget: (refreshTarget: RefreshTarget, id: string) => {},
  removeRefreshTarget: (id: string) => {},
});

function hasScrolledUp(event: TouchEvent<HTMLDivElement>) {
  if (event.target instanceof Element) {
    let element: Element | null = event.target;
    while (element !== event.currentTarget.parentElement && element) {
      if (element.scrollTop > 2) {
        // a page (My Circles/Managed) always has 1.9 scrollTop.
        return true;
      }

      element = element.parentElement;
    }
  }
  return false;
}

export function PullToRefresh(props: PropsWithChildren<{}>) {
  const [targets, setTargets] = useState<readonly [string, RefreshTarget][]>(
    [],
  );

  const initialY = useRef<number | null>(null);

  const pullDistance = useSharedState<null | number>(null);

  const [refreshThresholdPassed, setRefreshThresholdPassed] = useState(false);

  function onTouchStart(event: TouchEvent<HTMLDivElement>) {
    if (targets.length === 0) return;

    if (hasScrolledUp(event)) return;

    initialY.current = event.touches[0].clientY;
  }

  function onTouchMove(event: TouchEvent<HTMLDivElement>) {
    if (initialY.current) {
      const dy = event.touches[0].clientY - initialY.current;
      pullDistance.set(dy);
    }
  }

  function onTouchEnd(event: TouchEvent<HTMLDivElement>) {
    if (initialY.current) {
      initialY.current = null;
      if (refreshThresholdPassed) {
        pullDistance.set(null);
      } else {
        pullDistance.set(0);
      }
    }
  }

  const refreshTargets = useMemo(() => targets.map((t) => t[1]), [targets]);

  async function doRefresh() {
    await refreshTargetLoadAll(refreshTargets);
  }

  return (
    <Container
      onTouchStartCapture={onTouchStart}
      onTouchMoveCapture={onTouchMove}
      onTouchEndCapture={onTouchEnd}
    >
      <Context.Provider
        value={{
          addRefreshTarget(refreshTarget: RefreshTarget, id: string) {
            setTargets((prev) => {
              if (prev.map((p) => p[0]).includes(id)) return prev;
              else return [...prev, [id, refreshTarget]];
            });
          },
          removeRefreshTarget(id: string) {
            setTargets((prev) => prev.filter((t) => t[0] !== id));
          },
        }}
      >
        {props.children}
      </Context.Provider>
      {
        <MaterialRefreshControl
          isLarge={false}
          isLoading={isAnyRefreshTargetLoading(refreshTargets)}
          pullDistance={pullDistance}
          onThresholdPassed={setRefreshThresholdPassed}
          onStartRefresh={() => {
            doRefresh().catch(andLog);
          }}
        />
      }
    </Container>
  );
}

export function usePullToRefresh() {
  return useContext(Context);
}
