import React, {
  createContext,
  CSSProperties,
  forwardRef,
  PropsWithChildren,
  TouchEvent,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  isAnyRefreshTargetLoading,
  RefreshTarget,
  refreshTargetLoadAll,
} from "./RefreshTarget";
import { getOS } from "../../utils/deviceModel";
import { CircleRefreshControl } from "./CircleRefreshControl";
import { useSavedScroll } from "../../hooks/useSavedScroll";
import styled, { RuleSet } from "styled-components";
import { useImeTranslate } from "../../hooks/useImeTranslate";
import { andLog } from "../handleError";
import { useWebHost } from "../../hooks/useBridge";
import { StateId } from "../../hooks/StateId";

export const VScrollable = styled.div<{ mixin?: RuleSet<Object> }>`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: stretch;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;

  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */

  ${(p) => p.mixin}
`;

export type VScrollStyle = Pick<
  CSSProperties,
  | "padding"
  | "paddingBottom"
  | "width"
  | "maxHeight"
  | "paddingLeft"
  | "paddingRight"
  | "gap"
  | "clipPath"
  | "counterReset"
  | "userSelect"
  | "msUserSelect"
>;

export type VScrollProps = {
  contentId: StateId | undefined;
  style?: VScrollStyle;
  refreshControlType?: "material" | "material_large" | "classic_circle";
  refreshTarget?: RefreshTarget | RefreshTarget[];
  loadMoreTarget?: OnScrollTarget | OnScrollTarget[];
  onScroll?: (event: React.UIEvent<HTMLElement>) => void;
};

export type LoadMoreObject = {
  readonly loadMore: () => void;
};

export type OnScrollCallback = (event: React.UIEvent<HTMLElement>) => void;

export type OnScrollTarget = LoadMoreObject | OnScrollCallback;

export function isLoadMoreObject(
  refresh: OnScrollTarget,
): refresh is LoadMoreObject {
  return (refresh as any).loadMore;
}

function doOnScrollAll(
  target: OnScrollTarget | OnScrollTarget[],
  event: React.UIEvent<HTMLElement>,
) {
  if (Array.isArray(target)) {
    target.forEach((t) => doOnScroll(t, event));
  } else {
    doOnScroll(target, event);
  }
}

function doOnScroll(target: OnScrollTarget, event: React.UIEvent<HTMLElement>) {
  if (isLoadMoreObject(target)) {
    const eventTarget = event.currentTarget;
    if (
      eventTarget.scrollHeight -
        (eventTarget.scrollTop + eventTarget.clientHeight) <
      eventTarget.clientHeight / 2
    ) {
      target.loadMore();
    }
  } else {
    target(event);
  }
}

interface Context {
  id: StateId | undefined;
  canScrollUp: boolean;
  canScrollDown: boolean;
}

const Context = createContext<Context>({
  id: undefined,
  canScrollUp: false,
  canScrollDown: false,
});

function useParentVScroll() {
  return useContext(Context);
}

export const VScroll = forwardRef(function (
  props: PropsWithChildren<VScrollProps>,
  outerRef: React.ForwardedRef<HTMLDivElement>,
) {
  const savedScroll = useSavedScroll(props.contentId, false, (e) => {
    updateCanScroll(e);
  });
  const webHost = useWebHost();

  const [canScrollUp, setCanScrollUp] = useState(true);
  const [canScrollDown, setCanScrollDown] = useState(true);

  function updateCanScroll(element: Element | null) {
    if (element) {
      setCanScrollUp(
        Math.ceil(element.scrollTop) <
          element.scrollHeight - element.clientHeight,
      );
      setCanScrollDown(element.scrollTop > 0);
    } else {
      setCanScrollUp(false);
      setCanScrollUp(false);
    }
  }

  const refreshControlStyle = useMemo(
    () =>
      props.refreshControlType ??
      (getOS() === "iOS" ? "classic_circle" : "material"),
    [props.refreshControlType],
  );

  const [pullDistance, setPullDistance] = useState<null | number>(null);

  const [refreshThresholdPassed, setRefreshThresholdPassed] = useState(false);

  function onScroll(event: React.UIEvent<HTMLElement>) {
    savedScroll.onScroll(event);

    if (props.loadMoreTarget) {
      doOnScrollAll(props.loadMoreTarget, event);
    }
    if (props.onScroll) {
      props.onScroll(event);
    }

    updateCanScroll(event.currentTarget);
  }

  function updateRoot(element: HTMLDivElement | null) {
    savedScroll.scrollRef(element);

    if (typeof outerRef == "function") {
      outerRef(element);
    } else {
      if (outerRef) {
        outerRef.current = element;
      }
    }
  }

  const initialY = useRef<number | null>(null);

  function onTouchStart(event: TouchEvent<HTMLDivElement>) {
    if (!props.refreshTarget) return;

    initialY.current = event.touches[0].clientY;
  }

  function onTouchMove(event: TouchEvent<HTMLDivElement>) {
    if (event.currentTarget.scrollTop > 0) return;

    if (initialY.current) {
      const dy = event.touches[0].clientY - initialY.current;
      setPullDistance(dy);
    }
  }

  function onTouchEnd(event: TouchEvent<HTMLDivElement>) {
    if (initialY.current) {
      initialY.current = null;
      if (refreshThresholdPassed) {
        setPullDistance(null);
      } else {
        setPullDistance(0);
      }
    }
  }

  async function doRefresh() {
    webHost.haptic("light").catch(andLog); // don't await
    if (props.refreshTarget) {
      await refreshTargetLoadAll(props.refreshTarget);
    }
  }

  const [translateStyle, setFocusState] = useImeTranslate();

  const parent = useParentVScroll();

  return (
    <VScrollable
      style={{
        ...props.style,
        overscrollBehavior: props.refreshTarget ? "none" : "auto",
        ...translateStyle,
        overflowY:
          (pullDistance !== null && pullDistance > 0) || parent.canScrollUp
            ? "hidden"
            : "auto",
      }}
      onScroll={onScroll}
      ref={updateRoot}
      onTouchStartCapture={onTouchStart}
      onTouchMoveCapture={onTouchMove}
      onTouchEndCapture={onTouchEnd}
      onFocus={(e) =>
        setFocusState({
          isFocus: true,
          focusedElement: e.target,
        })
      }
      onBlur={(e) =>
        setFocusState({
          isFocus: false,
          focusedElement: e.target,
        })
      }
    >
      {props.refreshTarget && refreshControlStyle === "classic_circle" && (
        <CircleRefreshControl
          pullDistance={pullDistance}
          isLoading={isAnyRefreshTargetLoading(props.refreshTarget)}
          onThresholdPassed={setRefreshThresholdPassed}
          onStartRefresh={() => {
            doRefresh().catch(andLog);
          }}
        />
      )}

      <Context.Provider
        value={{
          id: props.contentId,
          canScrollUp: canScrollUp,
          canScrollDown: false,
        }}
      >
        {props.children}
      </Context.Provider>
    </VScrollable>
  );
});
