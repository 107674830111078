import React, { useState } from "react";
import { DemoCell } from "./DemoCell";
import {
  BottomSheet,
  CenterModal,
  ModalDesc,
  ModalTitle,
  useModal,
  useModalWithItsOwnPage,
} from "../../components/Modal";
import { BSMenu, BSMenuItem } from "../../components/BSMenu";
import { useNativePage } from "../../hooks/useBridge";
import { useHopper } from "../../hooks/useHopper";
import { andLog } from "../../components/handleError";
import { VStack } from "../../components/VStack";
import { RegularButton } from "../../components/Buttons";
import {
  modalProvider,
  useBridgeResultConsumer,
  useBridgeResultProvider,
} from "../../hooks/useBridgeResult";
import { Page } from "../../components/Page";
import { RegularInputGroup } from "../../components/Input";
import { useObscuredZoneForKey } from "../../hooks/useObscuredZones";

import icCopy from "../../res/images/ic_copy_address.svg";
import iconGems from "../../res/images/icon_gems.svg";
import { NavMiddle } from "../../components/NavBar";

export function DemoBridgeModalPage() {
  const modal = useModalWithItsOwnPage();
  const hopper = useHopper();

  const provide = useBridgeResultProvider<string>();

  return (
    <CenterModal modal={modal}>
      <ModalTitle>Bridge Modal</ModalTitle>
      <ModalDesc>
        {
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc imperdiet, dolor non pulvinar fermentum, erat augue condimentum leo, non cursus velit nisl ac felis. Nullam quis vestibulum tellus, nec commodo orci. Proin odio ante, sodales quis pellentesque sit amet, aliquam sit amet ex. Sed a velit dignissim, aliquet nibh ut, rutrum mauris. Donec id augue et lectus tristique lacinia dignissim quis leo. Donec eget dignissim mi. Mauris pulvinar placerat dolor sit amet vulputate. Aliquam erat volutpat. Sed vel lacus congue, congue metus et, gravida erat."
        }
      </ModalDesc>
      <RegularButton onClick={() => hopper.push("debug-home")}>
        Open
      </RegularButton>
      <RegularButton onClick={() => provide("hello")}>
        Provide Result
      </RegularButton>
    </CenterModal>
  );
}

export function DemoBridgeBottomSheetPage() {
  const bottomSheet = useModalWithItsOwnPage();
  const hopper = useHopper();
  const [obscuredZone] = useObscuredZoneForKey("Browser", "root");

  const modal = useModal("modal234");
  return (
    <>
      <BottomSheet modal={bottomSheet}>
        <VStack
          style={{
            padding: `20px 20px ${40 + obscuredZone.bottom}px`,
            gap: 18,
          }}
        >
          <ModalTitle>This is a Bottom Sheet</ModalTitle>
          <ModalDesc>
            {
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc imperdiet, dolor non pulvinar fermentum, erat augue condimentum leo, non cursus velit nisl ac felis. Nullam quis vestibulum tellus, nec commodo orci. Proin odio ante, sodales quis pellentesque sit amet, aliquam sit amet ex. Sed a velit dignissim, aliquet nibh ut, rutrum mauris. Donec id augue et lectus tristique lacinia dignissim quis leo. Donec eget dignissim mi. Mauris pulvinar placerat dolor sit amet vulputate. Aliquam erat volutpat. Sed vel lacus congue, congue metus et, gravida erat."
            }
          </ModalDesc>
          <RegularButton onClick={() => modal.open()}>open</RegularButton>
        </VStack>
      </BottomSheet>
      <CenterModal modal={modal}>ABC</CenterModal>
    </>
  );
}

export function ModalDemoPage() {
  const nativePage = useNativePage();
  const hopper = useHopper();

  const bottomSheet = useModal("bottomSheet");
  const modal = useModal("modal");
  const bsMenu = useModal("bsMenu");
  const modalWithInput = useModal("modalWithInput");

  const showBridgeModal = useBridgeResultConsumer<string>(
    modalProvider("demo-bridge-modal"),
    (r) => {
      nativePage.alertNotice(`Got result ${r}`, "OK").catch(andLog);
      return true;
    },
    [],
  );

  const [input, setInput] = useState("");
  const [obscuredZone] = useObscuredZoneForKey("Browser", "root");

  const designNFT = useModal("designNFT");
  return (
    <Page pageData={undefined}>
      <NavMiddle>Modal Demo</NavMiddle>
      <DemoCell onClick={() => bottomSheet.open()}>Bottom Sheet</DemoCell>
      <DemoCell onClick={() => modal.open()}>Modal</DemoCell>
      <DemoCell onClick={() => modalWithInput.open()}>
        Modal with Input
      </DemoCell>
      <DemoCell onClick={() => bsMenu.open()}>
        BSMenu
        <CenterModal modal={modal}>
          <ModalTitle>
            This is a Modal Dialog, you can declare it anywhere.
          </ModalTitle>
          <ModalDesc>
            {
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc imperdiet, dolor non pulvinar fermentum, erat augue condimentum leo, non cursus velit nisl ac felis. Nullam quis vestibulum tellus, nec commodo orci. Proin odio ante, sodales quis pellentesque sit amet, aliquam sit amet ex. Sed a velit dignissim, aliquet nibh ut, rutrum mauris. Donec id augue et lectus tristique lacinia dignissim quis leo. Donec eget dignissim mi. Mauris pulvinar placerat dolor sit amet vulputate. Aliquam erat volutpat. Sed vel lacus congue, congue metus et, gravida erat."
            }
          </ModalDesc>
          <RegularButton onClick={() => bsMenu.open()}>
            Prompt Sheet
          </RegularButton>
        </CenterModal>
      </DemoCell>
      <DemoCell onClick={() => showBridgeModal()}>Bridge Modal</DemoCell>
      <DemoCell onClick={() => designNFT.open()}>Input Modal</DemoCell>
      <DemoCell onClick={() => hopper.modal("demo-bridge-bottom-sheet")}>
        Bridge Bottom Sheet
      </DemoCell>
      <DemoCell onClick={() => hopper.startSide("modal-demo")}>
        Bridge Start Sheet
      </DemoCell>

      <DemoCell
        onClick={() =>
          nativePage
            .alertNotice(
              "Alert Title TitleTitle TitleTitleTitleTitleTitleTitleTitle TitleTitle TitleTitle ",
              "OK",
            )
            .catch(andLog)
        }
      >
        Alert
      </DemoCell>

      <DemoCell
        onClick={() =>
          nativePage
            .alertAreYouSure(
              "Alert Title TitleTitle TitleTitleTitleTitleTitleTitleTitle TitleTitle TitleTitle ",
              "Go ahead",
              "Give up",
            )
            .then((f) => nativePage.infoHud(`sure? ${f}`).catch(andLog))
            .catch(andLog)
        }
      >
        Are You Sure
      </DemoCell>

      <DemoCell onClick={() => hopper.layer("my-chats")}>
        My Chats Layer
      </DemoCell>

      <BottomSheet modal={bottomSheet}>
        <VStack
          style={{
            padding: `20px 20px ${40 + obscuredZone.bottom}px`,
            gap: 18,
          }}
        >
          <ModalTitle>This is a Bottom Sheet</ModalTitle>
          <ModalDesc>
            {
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc imperdiet, dolor non pulvinar fermentum, erat augue condimentum leo, non cursus velit nisl ac felis. Nullam quis vestibulum tellus, nec commodo orci. Proin odio ante, sodales quis pellentesque sit amet, aliquam sit amet ex. Sed a velit dignissim, aliquet nibh ut, rutrum mauris. Donec id augue et lectus tristique lacinia dignissim quis leo. Donec eget dignissim mi. Mauris pulvinar placerat dolor sit amet vulputate. Aliquam erat volutpat. Sed vel lacus congue, congue metus et, gravida erat."
            }
          </ModalDesc>
        </VStack>
      </BottomSheet>
      <CenterModal modal={modalWithInput}>
        <ModalTitle>This is a Modal Dialog with Text Input</ModalTitle>
        <ModalDesc>
          {
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc imperdiet, dolor non pulvinar fermentum, erat augue condimentum leo, non cursus velit nisl ac felis. Nullam quis vestibulum tellus, nec commodo orci. Proin odio ante, sodales quis pellentesque sit amet, aliquam sit amet ex. Sed a velit dignissim, aliquet nibh ut, rutrum mauris. Donec id augue et lectus tristique lacinia dignissim quis leo. Donec eget dignissim mi. Mauris pulvinar placerat dolor sit amet vulputate. Aliquam erat volutpat. Sed vel lacus congue, congue metus et, gravida erat."
          }
        </ModalDesc>
        <RegularInputGroup
          value={input}
          updateValue={setInput}
          label="Label"
          maxLength={10}
        />
      </CenterModal>
      <BSMenu modal={bsMenu} title={"The Title"}>
        <BSMenuItem title={"Menu 0"} selected={true} />
        <BSMenuItem
          title={"Menu 1"}
          onClick={() => {
            nativePage.successHud("Menu 1 clicked").catch(andLog);
          }}
        />
        <BSMenuItem
          title={"Menu 2"}
          subtitle={
            "Anyone can join this Circle without holding a Membership Card. Anyone can join this Circle without holding a Membership Card."
          }
          selected={true}
          onClick={() => {
            nativePage.successHud("Menu 2 clicked").catch(andLog);
          }}
        />
        <BSMenuItem
          icon={iconGems}
          iconStyle={{ width: 48, height: 48 }}
          title={"Buff"}
          subtitle={
            "Anyone can join this Circle without holding a Membership Card. Anyone can join this Circle without holding a Membership Card."
          }
          trailingItem={
            <img src={icCopy} alt={"copy address"} width={20} height={20} />
          }
          onClick={() => {
            nativePage.successHud("Buff clicked").catch(andLog);
          }}
        />
        <BSMenuItem
          title={"dontCloseOnClick"}
          trailingItem={"Trailing Title"}
          dontCloseOnClick={true}
          onClick={() => {
            nativePage.successHud("dontCloseOnClick Clicked").catch(andLog);
          }}
        />
      </BSMenu>
    </Page>
  );
}
