import { z } from "zod";
import { zBigInt, zStatic } from "../utils/zodUtils";
import { Media } from "./Media";
import { Currency } from "./Currency";
import { CircleFolder } from "./CircleFolder";

export const CircleMembershipCard = z.object({
  cardId: zBigInt.default(0),
  circleId: zBigInt.default(0),
  membershipLevel: z.number().default(0),
  cardStatus: z.number().default(0),
  name: z.string().default(""),
  background: Media.optional(),
  currency: Currency.optional(),
  priceList: z.array(Currency).optional(),
  earningCurrency: Currency.optional(),
  canRedeemCurrency: Currency.optional(),
  maturityPeriod: z.number().optional(),
  maturityProgress: z.number().optional(),
  tokenAmount: z.string().optional(),
  createdTime: z.number().optional(),
  isTemplate: z.boolean().default(false),
  accessibleFolders: z.array(CircleFolder).optional(),
});

export type CircleMembershipCard = zStatic<typeof CircleMembershipCard>;
