import { isAudio, isImage, isVideo, Media } from "../../proto/Media";
import { Poll } from "../../proto/Poll";
import { Attachment } from "./Attachment";
import React from "react";
import {
  AudioFlow,
  SingleImage,
  SingleVideo,
} from "../../pages/post/PostMediaContainer";
import { useWebHost } from "../../hooks/useBridge";
import { PollCell } from "../../pages/post/PollCell";
import { LinkCell } from "../../pages/post/PostDetailPage";
import { useHopper } from "../../hooks/useHopper";
import { useOpenLink } from "../../hooks/useOpenLink";
import { andLog } from "../handleError";

export function ZDocBlockAttachment(props: {
  mediaList: Media[] | undefined;
  pollList: Poll[] | undefined;
  attachment: Attachment;
  onViewMedia: (media: Media) => void;
}) {
  const openLink = useOpenLink();

  if (props.attachment.type === "media") {
    const media = props.mediaList?.find(
      (media) => media.mediaId === props.attachment.media?.mediaRefId,
    );
    if (props.mediaList && media) {
      if (isImage(media)) {
        return (
          <SingleImage
            media={media}
            onViewMedia={() => {
              if (props.attachment.media?.link) {
                openLink(props.attachment.media.link).catch(andLog);
              } else {
                props.onViewMedia(media);
              }
            }}
          />
        );
      } else if (isVideo(media)) {
        return (
          <SingleVideo
            media={media}
            onViewMedia={() => props.onViewMedia(media)}
          />
        );
      } else if (isAudio(media)) {
        return <AudioFlow media={media} />;
      }
    }
  } else if (props.attachment.type === "poll") {
    const poll = props.pollList?.find(
      (poll) => poll.pollId === props.attachment.poll?.pollRefId,
    );
    if (props.pollList && poll) {
      return <PollCell poll={poll} />;
    }
  } else if (props.attachment.type === "link") {
    return (
      <LinkCell
        title={
          props.attachment.link?.customTitle ?? props.attachment.link?.title
        }
        url={props.attachment.link?.url}
      />
    );
  }
  return null;
}
