import { css } from "styled-components";
import { RegularInputGroup } from "../../components/Input";
import { h_margin_with_page_inset } from "../../components/CommonStyles";
import { useState } from "react";
import { Page } from "../../components/Page";

export function InputDemoPage() {
  const [input, setInput] = useState("");
  return (
    <Page pageData={undefined}>
      <RegularInputGroup
        value={input}
        updateValue={setInput}
        label="Label"
        maxLength={10}
        style={css`
          ${h_margin_with_page_inset}
        `}
      />
    </Page>
  );
}
