import { CenterModal, useModalWithItsOwnPage } from "../../../components/Modal";
import { useI18n } from "../../../hooks/useI18n";
import { useHopper } from "../../../hooks/useHopper";
import {
  useBigIntParam,
  useBigIntSearchParam,
} from "../../../hooks/useTypedParam";
import { PowerMarkdown } from "../../../components/PowerMarkdown";
import React, { useMemo } from "react";
import { RegularButton } from "../../../components/Buttons";
import styled, { css } from "styled-components";
import { CenterChild, HStack, VSpace } from "../../../components/VStack";
import { useSWR } from "../../../hooks/swr/useSWR";
import { useBackend } from "../../../service/APIService";
import CircleCardsPreviewCell, { CardStyle } from "./CircleCardsPreviewCell";
import {
  formatMoney,
  getCurrencyIcon,
  getCurrencyName,
} from "../../../proto/Currency";
import { Image } from "../../../components/Image";

export function StakeInfoModal() {
  const modal = useModalWithItsOwnPage();
  const i18n = useI18n();
  const hopper = useHopper();
  const circleId = useBigIntParam("circleId");
  const backend = useBackend();
  const circleSWR = useSWR(backend.getCircle(circleId));
  const cardId = useBigIntSearchParam("cardId") ?? BigInt("0");
  const nftSWR = useSWR(backend.getMembershipCardNFT(cardId));

  const currencyId = useMemo(() => {
    return nftSWR.content?.circleMembershipCard?.currency?.currencyId;
  }, [nftSWR.content]);
  const circleToken = useSWR(
    currencyId ? backend.getTokenProject(currencyId) : undefined,
  );
  return (
    <CenterModal modal={modal} mixin={ModalStyle}>
      <CenterChild>
        <Title>{i18n.clover_stake_info()}</Title>
      </CenterChild>
      {nftSWR.content && (
        <CircleCard>
          <CircleCardsPreviewCell
            card={nftSWR.content.circleMembershipCard}
            circle={circleSWR.content}
            cardStyle={CardStyle.large}
            width={180}
          />
        </CircleCard>
      )}
      <CenterChild style={{ flexDirection: "column" }}>
        <CardName>{nftSWR.content?.name ?? "Card Name"}</CardName>
        <VSpace height={10} />
        <HStack style={{ gap: 6 }}>
          <StakeQuantityLabel>
            {i18n.clover_stake_token_quantity()}
          </StakeQuantityLabel>
        </HStack>
        <HStack style={{ gap: 6 }}>
          <Image
            src={getCurrencyIcon(circleToken?.content?.tokenProject?.currency)}
            width={20}
            height={20}
            style={{ borderRadius: 4 }}
          />
          <StakeQuantityValue>
            {formatMoney(
              "medium",
              nftSWR.content?.circleMembershipCard?.currency,
            )}
          </StakeQuantityValue>
          <StakeQuantityValue>
            {getCurrencyName(nftSWR.content?.circleMembershipCard?.currency)}
          </StakeQuantityValue>
        </HStack>
      </CenterChild>

      <PowerMarkdown textStyle={markdown_style}>
        {i18n.clover_stake_info_instruction()}
      </PowerMarkdown>
      <VSpace height={10} />
      <RegularButton
        style={{ marginLeft: 25, marginRight: 25 }}
        onClick={() => {
          modal.close(() => {
            const chosenCard = nftSWR.content;
            if (chosenCard) {
              if (chosenCard.circleMembershipCard?.membershipLevel === 999) {
                hopper.layer(
                  `circle/${circleId}/card/${chosenCard.nftId}/admin-card-stake`,
                );
              } else {
                hopper.layer(
                  `circle/${circleId}/card/${chosenCard.nftId}/stake`,
                );
              }
            }
          });
        }}
      >
        {i18n.clover_button_stake()}
      </RegularButton>
    </CenterModal>
  );
}

const CircleCard = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

const CardName = styled.div`
  color: var(--color-text00);
  font-weight: 500;
  font-size: 11;
`;

const Title = styled.div`
  color: var(--color-text00);
  font-weight: 600;
  font-size: 18;
`;

const StakeQuantityLabel = styled.div`
  color: var(--color-text00);
  font-weight: 500;
  font-size: 14;
`;

const StakeQuantityValue = styled.div`
  color: var(--color-text00);
  font-weight: 600;
  font-size: 14;
`;

const markdown_style = css`
  color: rgba(255, 255, 255, 0.6);
  font-weight: 400;
  font-size: 12px;
  margin-top: 16px;
`;

const ModalStyle = css`
  padding: 30px 22px 25px;
  gap: 10px;
`;
