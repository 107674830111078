import { z } from "zod";
import { Media } from "./Media";
import BigNumber from "bignumber.js";
import { zBigInt, zBigNumber, zEnum, zStatic } from "../utils/zodUtils";
import { ChainType } from "./ChainType";
import { Currency } from "./Currency";
import { CircleTokenStatus } from "./CircleTokenStatus";

export const MoonUser = z.object({
  uid: zBigInt,
  nickname: z.string().default("-"),
  icon: Media.optional(),
});

export type MoonUser = zStatic<typeof MoonUser>;

export const TradingStatistics = z.object({
  marketCap: z.string().default("0"),
  marketCap1Hip: z.number().default(0),

  // liquidityUsd?:                       string;
  /**
   * 五分钟内市值变化
   */
  marketCap5MIP: z.number().default(0),
  /**
   * 价格（单位是sol）
   */
  priceQuote: z.string().default("0"),
  priceUsd: z.string().default("0"),
  // sellableTokenAmount?: string;
  // soldTokensSolAmount?: string;
  // totalVolume?:         string;
  // totalVolumeBuys?:     string;
  // totalVolumeSells?:    string;
});

export const ShareToEarnUserStats = z.object({
  statsId: zBigInt,
  totalEarned: z.string().default("0"),
  user: MoonUser.optional(),
});

export const TokenProject = z.object({
  projectId: zBigInt,
  ticker: z.string(),
  name: z.string(),
  tokenAddress: z.string().optional(),
  image: Media.optional(),
  chainType: zEnum(ChainType).default(ChainType.Solana),
  enableShareToEarn: z.boolean().default(false),
  availableShareToEarnReward: z.string().default("0"),
  tradingStatistics: TradingStatistics.optional(),
  currency: Currency.optional(),
  topTenUserEarnRewards: z.array(ShareToEarnUserStats).default([]),
  totalSupply: z.string().default("0"),
  status: zEnum(CircleTokenStatus).default(CircleTokenStatus.Pending),
});

export type TokenProject = zStatic<typeof TokenProject>;

export const TokenProjectHot = z.object({
  project: TokenProject.optional(),
  url: z.string().default(""),
  amount: z.string().default("0"),
});

export type TokenProjectHot = zStatic<typeof TokenProjectHot>;
