import { AuthSession } from "./AuthSession";
import {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useSyncExternalStore,
} from "react";
import { DB, Store } from "../utils/DB";
import { dbDelete, dbGet, dbGetAll, dbPut } from "../utils/dbPromise";
import { z } from "zod";
import { Bridge, getBridgeHook } from "../bridge/Bridge";

interface Context {
  myUid: bigint | undefined;
  add: (authSession: AuthSession, selects: boolean) => Promise<void>;

  remove: (uid: bigint) => Promise<void>;
  get: (uid: bigint) => Promise<AuthSession | undefined>;
  getAll: () => Promise<AuthSession[]>;
}

const Context = createContext<Context>({
  myUid: undefined,
  add: async (authSession: AuthSession) => {},
  remove: async (uid: bigint) => {},
  get: async (uid: bigint) => {
    return undefined;
  },
  getAll: async () => {
    return [];
  },
});

// don't export it
function getCurrentUid() {
  const str = localStorage.getItem("currentUid");
  return str ? BigInt(str) : undefined;
}

function getIsCloverUser() {
  const str = localStorage.getItem("isCloverUser");
  return str === "true";
}

const AuthSessionEntry = z.object({
  session: AuthSession,
  uidStr: z.string().min(1),
  updatedAt: z.number(),
});

async function addAuthSession(authSession: AuthSession, selects: boolean) {
  const db = await DB.get();
  await dbPut(db, Store.Session, {
    session: authSession,
    uidStr: authSession.uid.toString(),
    updatedAt: new Date().getTime(),
  });

  if (selects) {
    localStorage.setItem("currentUid", authSession.uid.toString());
    window.dispatchEvent(new CustomEvent("currentUidChange"));
  }
}

async function removeAuthSession(uid: bigint) {
  let uidStr = uid.toString();
  const db = await DB.get();
  await dbDelete(db, Store.Session, [uidStr]);
  if (localStorage.getItem("currentUid") === uidStr) {
    localStorage.removeItem("currentUid");
    window.dispatchEvent(new CustomEvent("currentUidChange"));
  }
}

async function getAuthSession(uid: bigint) {
  const db = await DB.get();
  const entry = AuthSessionEntry.optional().parse(
    (await dbGet(db, Store.Session, [uid.toString()])) ?? undefined,
  );
  return entry?.session;
}

async function getAllAuthSessions() {
  const db = await DB.get();
  const entries = z
    .array(AuthSessionEntry)
    .parse(await dbGetAll(db, Store.Session));
  return entries.map((e) => e.session);
}

export function AuthSessionService(props: PropsWithChildren<{}>) {
  const myUid = useSyncExternalStore<bigint | undefined>(
    (listener: () => void) => {
      window.addEventListener("currentUidChange", listener);
      return () =>
        void window.removeEventListener("currentUidChange", listener);
    },
    getCurrentUid,
  );

  const isCloverUser = useSyncExternalStore<boolean | undefined>(
    (listener: () => void) => {
      window.addEventListener("currentUidChange", listener);
      return () =>
        void window.removeEventListener("currentUidChange", listener);
    },
    getIsCloverUser,
  );

  return (
    <Context.Provider
      value={{
        myUid: myUid,
        add: addAuthSession,
        remove: removeAuthSession,
        get: getAuthSession,
        getAll: getAllAuthSessions,
      }}
    >
      {props.children}
    </Context.Provider>
  );
}

export function useAuthSessionService() {
  return useContext(Context);
}

export function useMyUid() {
  return useAuthSessionService().myUid ?? BigInt(0);
}

export function ExternalAuthSessionService(
  props: PropsWithChildren<{
    myUid: bigint | undefined;
  }>,
) {
  const webHost = useMemo(() => {
    return getBridgeHook<{
      addAuthSession: (
        authSession: AuthSession,
        selects: boolean,
      ) => Promise<void>;

      removeAuthSession: (uid: bigint) => Promise<void>;
      getAuthSession: (uid: bigint) => Promise<AuthSession | undefined>;
      getAllAuthSessions: () => Promise<AuthSession[]>;
    }>(Bridge.getInstance(), "webHost");
  }, []);

  return (
    <Context.Provider
      value={{
        myUid: props.myUid,
        add: webHost.addAuthSession,
        remove: webHost.removeAuthSession,
        get: webHost.getAuthSession,
        getAll: webHost.getAllAuthSessions,
      }}
    >
      {props.children}
    </Context.Provider>
  );
}
