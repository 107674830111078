import { z } from "zod";
import { zEnum, zStatic } from "../utils/zodUtils";
import { WalletBannerType } from "../pages/wallet/WalletBannerCell";
import { Currency } from "./Currency";

export const RewardedInfo = z.object({
  advancedRewardedInfo: Currency.optional(),
});

export type RewardedInfo = zStatic<typeof RewardedInfo>;
