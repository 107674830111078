import { HStackMixin, VStack } from "../../components/VStack";
import { UserAvatarView } from "../../components/views/UserAvatarView";
import { Alert, AlertGroup, AlertType } from "../../proto/Alert";
import React from "react";
import { Divider } from "../post/PostCell";
import { useHopper } from "../../hooks/useHopper";
import { UserNameView } from "../../components/views/UserNameView";
import styled, { RuleSet } from "styled-components";
import { useI18n } from "../../hooks/useI18n";
import { ObjectType } from "../../proto/ObjectSpec";
import { useMyUid } from "../../service/AuthSessionService";
import { StringUtil } from "../../utils/StringUtil";
import { Image } from "../../components/Image";
import { Circle } from "../../proto/Circle";
import { Property } from "csstype";
import { OverFlowTextStyle } from "../../components/Text";

export type AlertProps = {
  alert: Alert;
};

export function AlertDetailCell(props: AlertProps) {
  const hopper = useHopper();
  const i18n = useI18n();
  const myUid = useMyUid();
  const alert = props.alert;

  function onSuperClick() {
    switch (alert.groupId) {
      case AlertGroup.Activity:
        // TODO: Comments preview page
        break;
      case AlertGroup.Follow:
        hopper.push(`user/${alert.operator?.uid}`);
        break;
      case AlertGroup.Like:
      case AlertGroup.Post:
      case AlertGroup.Circle:
        if (alert.objectId) {
          hopper.push(`post/${alert.objectId}`);
        }
        break;
      default:
        break;
    }
  }

  function onReferenceTextViewClick() {
    let objectId;
    let objectType;
    if (
      alert.parentType === ObjectType.NFT &&
      (alert.type === AlertType.Like || alert.type === AlertType.Comment) &&
      alert.objectType === AlertType.Comment
    ) {
      objectId = alert?.objectId;
      objectType = alert?.objectType;
    } else if (
      alert.type === AlertType.Comment ||
      (alert.type === AlertType.Like &&
        alert.objectType === AlertType.Comment) ||
      alert.type === AlertType.CommentPinned ||
      alert.type == AlertType.PollFinished
    ) {
      objectId = alert.parentId;
      objectType = alert.parentType;
    } else {
      objectId = alert.objectId;
      objectType = alert.objectType;
    }
    if (objectId && objectType) {
      switch (objectType) {
        case ObjectType.BLOG:
        case ObjectType.CHAT_ANNOUNCEMENT:
        case ObjectType.CIRCLE_ANNOUNCEMENT:
          hopper.push(`post/${objectId}`);
          break;
        case ObjectType.USER:
          hopper.push(`user/${objectId}`);
          break;
        case ObjectType.CHAT:
          //TODO:StevenQ push thread objectId
          break;
        case ObjectType.NFT:
          // TODO:StevenQ Comments preview page objectId
          break;
        case ObjectType.COMMENT:
          if (alert.parentType === ObjectType.NFT) {
            const nftId = alert.parentId;
            if (nftId) {
            } // TODO:StevenQ NFT info page
            break;
          } else {
            // TODO:StevenQ Comments preview page objectId
            break;
          }
        default:
          break;
      }
    }
  }

  const subtitle = (() => {
    const objectText = alert.objectText;
    const trimmedObjectText = StringUtil.alertTrimming(objectText);
    const trimmedStickerName = StringUtil.alertTrimming(
      alert.extensions?.stickerName,
    );
    switch (alert.type) {
      case AlertType.Like:
        switch (alert.objectType) {
          case ObjectType.BLOG:
            return i18n.alert_reaction_post(trimmedStickerName);
          case ObjectType.COMMENT:
            if (alert.extensions?.replyToUid === myUid) {
              return i18n.col_rating_someone_reply_to_you(trimmedStickerName);
            }
            return i18n.alert_reaction_comment(trimmedStickerName);
          case ObjectType.CHAT_ANNOUNCEMENT:
          case ObjectType.CIRCLE_ANNOUNCEMENT:
            return i18n.alert_reaction_thread_annoucement(trimmedStickerName);
          case ObjectType.NFT:
            return i18n.col_rating_reaction_nft(trimmedStickerName);
          default:
            return i18n.unknown_message_type();
        }
      case AlertType.Comment:
        if (alert.extensions?.replyToUid === myUid) {
          return i18n.col_rating_someone_reply_to_you(trimmedObjectText);
        }
        return i18n.alert_comment_post(trimmedObjectText);
      case AlertType.Followed:
        return i18n.alert_follow();
      case AlertType.Pinned:
      case AlertType.SelfPostPinned:
      case AlertType.SelfChatPinned:
        if (alert.circleFolder?.name) {
          return i18n.circle2_1_pinned_your_content_in_folder(
            StringUtil.alertTrimming(alert.circleFolder?.name),
          );
        } else {
          if (alert.objectType === ObjectType.BLOG) {
            return i18n.circle2_1_pinned_your_content_in_folder(
              i18n.circle2_1_latest_posts(),
            );
          } else {
            return i18n.circle2_1_pinned_your_content_in_folder(
              i18n.circle2_1_latest_chats(),
            );
          }
        }
      case AlertType.OtherPostPinned:
      case AlertType.OtherChatPinned:
        if (alert.circleFolder?.name) {
          return i18n.circle2_1_pinned_a_new_content_in_folder(
            StringUtil.alertTrimming(alert.circleFolder?.name),
          );
        } else {
          if (alert.objectType === ObjectType.BLOG) {
            return i18n.circle2_1_pinned_a_new_content_in_folder(
              i18n.circle2_1_latest_posts(),
            );
          } else {
            return i18n.circle2_1_pinned_a_new_content_in_folder(
              i18n.circle2_1_latest_chats(),
            );
          }
        }
      case AlertType.NewPost:
        return i18n.alert_user_new_post();
      case AlertType.PollVoted:
        return i18n.poll_voted_in_your_poll();
      case AlertType.CommentPinned:
        if (alert.objectType === ObjectType.BLOG) {
          return i18n.comment_impr_pinned_wall(trimmedObjectText);
        } else {
          return i18n.comment_impr_pinned_wall(trimmedObjectText);
        }
      case AlertType.PollFinished:
        return alert.operatorUid === alert.targetUid
          ? i18n.poll_impr_poll_finish_alert_center_self()
          : i18n.poll_impr_poll_finish_alert_center_other();
      case AlertType.CircleTitle:
        return i18n.member_title_alert();
      default:
        return i18n.unknown_message_type();
    }
  })();

  const readableReferenceString = (() => {
    const trimmedObjectText = StringUtil.alertTrimming(alert.objectText);
    const trimmedParentText = StringUtil.alertTrimming(alert.parentText);
    switch (alert.type) {
      case AlertType.Like:
        return trimmedObjectText;
      case AlertType.CommentPinned:
        return trimmedParentText;
      case AlertType.Comment:
      case AlertType.PollFinished:
        return trimmedParentText;
      case AlertType.Followed:
      case AlertType.CircleTitle:
        return undefined;
      case AlertType.Pinned:
      case AlertType.OtherPostPinned:
      case AlertType.SelfPostPinned:
      case AlertType.NewPost:
      case AlertType.PollVoted:
      case AlertType.OtherChatPinned:
      case AlertType.SelfChatPinned:
        return trimmedObjectText;
      default:
        return undefined;
    }
  })();

  const showUserInfo = (() => {
    return alert.groupId !== AlertGroup.Circle;
  })();

  const showCircleInfo = (() => {
    return alert.groupId === AlertGroup.Circle;
  })();

  return (
    <VStack
      style={{
        paddingTop: 25,
        width: "100%",
      }}
      onClick={onSuperClick}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          alignItems: "start",
          justifyContent: "space-between",
        }}
      >
        <ObjectInfoContainer
          style={{ alignItems: "start" }}
          onClick={(event) => {
            event.stopPropagation();
            if (showUserInfo && alert.operator?.uid) {
              hopper.push(`user/${alert.operator?.uid}`);
            } else if (showCircleInfo && alert.circleInfo?.circleId) {
              hopper.push(`circle/${alert.circleInfo?.circleId}`);
            }
          }}
        >
          {showUserInfo && (
            <UserAvatarView user={alert.operator} iconSize={36} />
          )}
          {showCircleInfo && (
            <CircleIconView
              circle={alert.circleInfo}
              iconSize={36}
              onClick={() => {
                if (alert.circleInfo?.circleId) {
                  hopper.push(`circle/${alert.circleInfo?.circleId}`);
                }
              }}
            />
          )}
          <VStack style={{ marginLeft: "10px", maxWidth: "80%" }}>
            {showUserInfo && alert.operator && (
              <UserNameView user={alert.operator} />
            )}
            {showCircleInfo && (
              <CircleName style={{ pointerEvents: "none" }}>
                {alert.circleInfo?.name}
              </CircleName>
            )}
            <SubtitleLabel>{subtitle}</SubtitleLabel>
          </VStack>
        </ObjectInfoContainer>
        <TimeLabel>
          {Intl.DateTimeFormat(navigator.language, {
            dateStyle: "medium",
            timeStyle: "short",
          }).format(alert.createdTime * 1000)}
        </TimeLabel>
      </div>
      {readableReferenceString && (
        <ReferenceTextContainerView style={{ background: "#FFFFFF05" }}>
          <ReferenceTextView onClick={onReferenceTextViewClick}>
            {readableReferenceString}
          </ReferenceTextView>
        </ReferenceTextContainerView>
      )}
      <Divider style={{ marginLeft: 46, marginTop: 20 }} />
    </VStack>
  );
}

const TimeLabel = styled.div`
  margin-inline-start: auto;
  margin-inline-end: 0;
  font-weight: 300;
  font-size: 12px;
  white-space: nowrap;
  flex-shrink: 0;
  color: "#FFFFFF99";
`;

const SubtitleLabel = styled.div`
  font-weight: 300;
  font-size: 12px;
  color: "#FFFFFF10";
`;

const ReferenceTextContainerView = styled.div`
  border-radius: 6px;
  margin-left: 46px;
  margin-top: 12px;
`;

const ReferenceTextView = styled.div`
  font-weight: 300;
  font-size: 12px;
  margin: 8px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  line-height: 1.2;
  max-height: calc(1.2em * 2); /* 添加 padding 的高度 */
`;

type CircleIconProps<TLength = (string & {}) | 0> = {
  circle: Circle | undefined;
  iconSize: number;
  borderRadius?: number;
  border?: Property.Border<TLength>;
  onClick?: () => void;
};

export function CircleIconView(props: CircleIconProps) {
  return (
    <Image
      src={[props.circle?.circleIcon, props.iconSize]}
      style={{
        borderRadius: props.borderRadius || 6,
        border: props.border || `1px solid ${props.circle?.themeColor}`,
      }}
      width={props.iconSize}
      height={props.iconSize}
      onClick={props.onClick}
    />
  );
}

export const ObjectInfoContainer = styled.div`
  flex-shrink: 1;
  ${HStackMixin};
  transition: opacity 0.2s ease;
  &:active {
    opacity: 0.4;
  }
`;

const CircleName = styled.div<{ mixin?: RuleSet<Object> }>`
  font-weight: 500;
  font-size: 16px;
  flex-shrink: 1;
  ${OverFlowTextStyle};
  -webkit-line-clamp: 1;
  ${(p) => p.mixin}
`;
