import { CenterChild, VStack } from "../../components/VStack";
import { Image } from "../../components/Image";
import styled from "styled-components";

export interface AccountHeaderOperationProps {
  icon: string;
  title: string;
  onClick: () => void;
}

function AccountHeaderOperation(props: AccountHeaderOperationProps) {
  return (
    <Container onClick={props.onClick}>
      <Image src={props.icon} width={37} height={37} />
      <Title>{props.title}</Title>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  font-size: 11px;
  font-weight: 400;
  color: var(--color-text00);
`;

export default AccountHeaderOperation;
