import styled from "styled-components";
import { UserMood } from "../../proto/User";
import { Image } from "../../components/Image";
import { useI18n } from "../../hooks/useI18n";
import onlineDot from "../../res/images/ic_online_dot.svg";
import offlineDot from "../../res/images/ic_offline_dot.svg";
import { UserMoodType } from "../../proto/UserEnums";

export interface MoodProps {
  selectedMood?: UserMood;
  mood?: UserMood;
  hardcodeMod?: "online" | "offline";
  onClick?: () => void;
}

function MoodCell(props: MoodProps) {
  const i18n = useI18n();
  const selected: boolean =
    (props.selectedMood?.type === UserMoodType.ONLINE &&
      props.hardcodeMod === "online" &&
      !props.selectedMood?.doNotDisturbDuration) ||
    (props.selectedMood?.type === UserMoodType.OFFLINE &&
      props.hardcodeMod === "offline" &&
      !props.selectedMood?.doNotDisturbDuration) ||
    ((props.selectedMood?.text !== undefined ||
      props.selectedMood?.stickerId !== undefined) &&
      props.selectedMood?.text === props.mood?.text &&
      props.selectedMood?.stickerId === props.mood?.stickerId);

  return (
    <MoodFrame $selected={selected} onClick={props.onClick}>
      <HeadFrame>
        {props.mood ? (
          <Image
            src={[props.mood.sticker?.media, { width: 20, height: 20 }]}
            width={20}
            height={20}
          />
        ) : (
          <Image
            width={9}
            height={9}
            src={props.hardcodeMod === "online" ? onlineDot : offlineDot}
          />
        )}
      </HeadFrame>
      <MoodTitle $selected={selected}>
        {props.mood
          ? props.mood?.text || ""
          : props.hardcodeMod === "online"
            ? i18n.online()
            : i18n.offline()}
      </MoodTitle>
    </MoodFrame>
  );
}

const MoodTitle = styled.div<{ $selected: boolean }>`
  flex: 1;
  margin: auto 11px;
  font-size: 14px;
  color: ${(p) =>
    p.$selected ? `rgb(148, 255, 222)` : `rgba(255, 255, 255, 0.6)`};
  font-weight: ${(p) => (p.$selected ? `500` : `400`)};
`;

const MoodFrame = styled.div<{ $selected: boolean }>`
  display: flex;
  flex-direction: row;
  height: 50px;
  align-items: center;
  background: ${(p) =>
    p.$selected ? `rgba(0, 175, 163, 0.14)` : `rgba(255, 255, 255, 0.05)`};
  border-radius: 6px;
  border: ${(p) =>
    p.$selected ? "0.5px solid rgba(13, 255, 204, 1)" : `nones`};
`;

export const HeadFrame = styled.div`
  width: 40px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 6px 0 0 6px;
`;

export default MoodCell;
