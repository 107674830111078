import { useEffect, useMemo, useState } from "react";
import { ScrollSizeObserver } from "./ScrollSizeObserver";

export type ScrollSize = {
  scrollHeight: number;
  scrollWidth: number;
  clientHeight: number;
  clientWidth: number;
  scrollTop: number;
  scrollLeft: number;
};

export function getScrollSize(e: Element): ScrollSize {
  return {
    scrollHeight: Math.floor(e.scrollHeight),
    scrollWidth: Math.floor(e.scrollWidth),
    clientHeight: Math.floor(e.clientHeight),
    clientWidth: Math.floor(e.clientWidth),
    scrollTop: Math.floor(e.scrollTop),
    scrollLeft: Math.floor(e.scrollLeft),
  };
}

export function useScrollSizeObserver(onChange: (scroller: Element) => void) {
  const [scroller, setScroller] = useState<Element | null>(null);
  const observer = useMemo(
    () =>
      new ScrollSizeObserver((entries) =>
        entries.forEach((e) => {
          if (e.target === scroller) {
            onChange(e.target);
          }
        }),
      ),
    [scroller, onChange],
  );

  useEffect(() => {
    if (!scroller) return;
    observer.observe(scroller);
    return () => {
      observer.disconnect();
    };
  }, [scroller, observer]);

  return setScroller;
}
