import { z } from "zod";
import { zBigInt, zStatic } from "../utils/zodUtils";
import { RichFormat } from "../components/zdoc/RichFormat";

export const Resource = z.object({
  width: z.number(),
  height: z.number(),
  url: z.string(),
  duration: z.number().optional(),
});
export type Resource = zStatic<typeof Resource>;

export const BareMedia = z.object({
  mediaId: zBigInt.default(0),
  baseUrl: z.string().default("-"),
  resourceList: z.array(Resource).default([
    {
      width: 1,
      height: 1,
      url: "-",
    },
  ]),
  nsfw: z.boolean().default(false),
});
export type BareMedia = zStatic<typeof BareMedia>;

export const Media = BareMedia.merge(
  z.object({
    cover: BareMedia.optional(),
  }),
);

export type Media = zStatic<typeof Media>;

export function isValidMedia(media?: Media | null): boolean {
  if (media === undefined || media === null) {
    return false;
  }

  if ((media.resourceList ?? []).length === 0) {
    return false;
  }

  if ((media.baseUrl ?? "").length === 0) {
    return false;
  }

  return true;
}

export function getMediaDuration(media?: Media | null): number {
  if (media?.resourceList && media.resourceList.length > 0) {
    return (media.resourceList[0].duration ?? 0) / 1000;
  } else {
    return 0;
  }
}

export function getBestRes(media: Media | BareMedia): Resource {
  return sortResList(media.resourceList, false)[0];
}

export function getSmallestRes(media: Media | BareMedia): Resource {
  return sortResList(media.resourceList, true)[0];
}

export type Size = {
  width: number;
  height: number;
};

export function getFittestRes(
  media: Media | BareMedia,
  fitSize: Size,
): Resource {
  let bestResource: Resource | undefined = undefined;
  let scale = window.devicePixelRatio;
  for (let resource of media.resourceList) {
    if (
      resource.width * resource.height >
      fitSize.width * fitSize.height * scale * scale
    ) {
      if (bestResource) {
        if (
          resource.width * resource.height <
          bestResource.width * bestResource.height
        ) {
          bestResource = resource;
        }
      } else {
        bestResource = resource;
      }
    }
  }
  return bestResource ?? getBestRes(media);
}

function sortResList(resourceList: Resource[], decrease: boolean): Resource[] {
  let value = decrease ? -1 : 1;
  return resourceList.sort((a, b) => {
    if (a.height > b.height) {
      return -1 * value;
    } else if (a.height < b.height) {
      return value;
    } else {
      return 0;
    }
  });
}

export function isVideo(media?: Media): boolean {
  const extension = media?.baseUrl?.split(".")?.pop()?.toLowerCase();
  return extension == "mp4";
}

export function isImage(media: Media): boolean {
  const extension = media?.baseUrl?.split(".")?.pop()?.toLowerCase();
  return (
    extension == "jpg" ||
    extension == "jpeg" ||
    extension == "png" ||
    extension == "gif" ||
    extension == "tiff" ||
    extension == "webp"
  );
}

export function isAudio(media: Media): boolean {
  const extension = media?.baseUrl?.split(".")?.pop()?.toLowerCase();
  return (
    extension == "mp3" ||
    extension == "aac" ||
    extension == "ogg" ||
    extension == "m4a"
  );
}

export function getOrderedFilteredMediaList(
  mediaList?: Media[],
  richFormat?: RichFormat,
) {
  if (richFormat) {
    return richFormat.attachmentSpans
      ?.filter((span) => span.data.type === "media")
      .map((span) =>
        mediaList?.find(
          (media) => span.data.media?.mediaRefId === media.mediaId,
        ),
      )
      .filter((media): media is Media => media !== undefined)
      .filter((media) => isImage(media) || isVideo(media));
  } else {
    return mediaList?.filter((media) => isImage(media) || isVideo(media));
  }
}
