import chevron from "../../src/res/images/icon_section_header_show_more.svg";
import { Image } from "./Image";
import { Spring } from "./VStack";
import styled from "styled-components";
import { cell_on_pressed } from "./CommonStyles";
import React, { CSSProperties } from "react";
import { SingleLineUnspecifiedWidth } from "./CommonViews";
import { Property } from "csstype";
import { AbsRedDot, RelativeContainer } from "../pages/circle/CircleHomePage";

export interface SettingCellProps<TLength = (string & {}) | 0> {
  title?: string;
  primaryTitleMarginLeft?: Property.MarginLeft<TLength>;
  secondaryValue?: string | React.ReactNode;
  secondaryTitleMarginLeft?: Property.MarginLeft<TLength>;
  startIcon?: string;
  showStartIconRedDot?: boolean;
  style?: CSSProperties;
  showAccessoryImage?: boolean;
  showDivider?: boolean;
  titleValue?: React.ReactNode;
  endValue?: React.ReactNode;
  onClick?: () => void;
}

function SettingCell(props: SettingCellProps) {
  return (
    <SettingCellBackground
      style={{
        ...props.style,
        borderBottom:
          props.showDivider === false
            ? "none"
            : "1px solid rgba(255, 255, 255, 0.1)",
      }}
      onClick={props.onClick}
      key={props.title}
    >
      {props.startIcon && (
        <RelativeContainer style={{ width: 30, height: 30 }}>
          <Image
            src={props.startIcon}
            width={30}
            height={30}
            style={{ marginInlineEnd: 6 }}
          />
          {props.showStartIconRedDot && (
            <AbsRedDot style={{ top: 3, right: 3 }} />
          )}
        </RelativeContainer>
      )}
      <div style={{ alignItems: "start", gap: 4, flexShrink: 1 }}>
        {props.titleValue ?? (
          <SettingPrimaryTitle
            style={{ marginLeft: props.primaryTitleMarginLeft }}
          >
            {props.title}
          </SettingPrimaryTitle>
        )}
        {props.secondaryValue &&
          (typeof props.secondaryValue === "string" ? (
            <SettingSecondaryTitle
              style={{ marginLeft: props.secondaryTitleMarginLeft }}
            >
              {props.secondaryValue}
            </SettingSecondaryTitle>
          ) : (
            props.secondaryValue
          ))}
      </div>
      <ValueLayout>
        <Spring />
        <div
          style={{
            flexShrink: 100, // 确保 endValue 可以收缩
          }}
        >
          {props.endValue}
        </div>
        {props.showAccessoryImage !== false && (
          <Image
            src={chevron}
            width={20}
            height={20}
            style={{ marginInlineStart: 6 }}
          />
        )}
      </ValueLayout>
    </SettingCellBackground>
  );
}

export const SettingCellBackground = styled.div`
  color: var(--color-text00);
  font-size: 16px;
  display: flex;
  flex-direction: row;
  min-height: 70px;
  align-items: center;
  padding-inline-start: 20px;
  padding-inline-end: 20px;
  flex-shrink: 0;
  ${cell_on_pressed}
`;

const ValueLayout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  margin-inline-start: 12px;
`;

const SettingPrimaryTitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  ${SingleLineUnspecifiedWidth};
  color: var(--color-text00);
`;

const SettingSecondaryTitle = styled.div`
  font-size: 11px;
  font-weight: 400;
  color: var(--color-text10);
`;

export const SettingEndValueText = styled.div`
  font-size: 15px;
  font-weight: 500;
  ${SingleLineUnspecifiedWidth};
  color: var(--color-text00);
`;

export default SettingCell;
