import React from "react";
import { useHopper } from "../../hooks/useHopper";
import { DemoCell } from "./DemoCell";
import { Page, staticPageData } from "../../components/Page";

export function ComponentsDemoPage() {
  const hopper = useHopper();

  return (
    <Page pageData={staticPageData("componentsDemo")}>
      <DemoCell onClick={() => hopper.push(`slider-demo`)}>Slider</DemoCell>

      <DemoCell onClick={() => hopper.push(`haptic-demo`)}>Haptic</DemoCell>

      <DemoCell onClick={() => hopper.push(`button-demo`)}>Button</DemoCell>
      <DemoCell onClick={() => hopper.push(`imageview-demo`)}>Image</DemoCell>

      <DemoCell onClick={() => hopper.push(`modal-demo`)}>Modal</DemoCell>

      <DemoCell onClick={() => hopper.push("input-demo")}>Input</DemoCell>
      <DemoCell onClick={() => hopper.push("chart-demo")}>Chart</DemoCell>
      <DemoCell onClick={() => hopper.push("content-group-demo")}>
        ContentGroup
      </DemoCell>
      <DemoCell onClick={() => hopper.push("speed-title-demo")}>
        SpeedTitle
      </DemoCell>
      <DemoCell onClick={() => hopper.push("pressed-effect-demo")}>
        Pressed Effect
      </DemoCell>
      <DemoCell onClick={() => hopper.push("instruction-demo")}>
        Instruction
      </DemoCell>
      <DemoCell onClick={() => hopper.push("power-markdown-demo")}>
        Power Markdown
      </DemoCell>
      <DemoCell onClick={() => hopper.push("filter-tab-demo")}>
        Filter Tab
      </DemoCell>
      <DemoCell onClick={() => hopper.push("auto-fit-text-demo")}>
        Auto Fit Text
      </DemoCell>
      <DemoCell onClick={() => hopper.push("media-picker-demo")}>
        Media Picker
      </DemoCell>
    </Page>
  );
}
