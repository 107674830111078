import { Page } from "../../components/Page";
import { NavEnd, NavItem, NavMiddle, NavStart } from "../../components/NavBar";
import { NavButtonType } from "../../bridge/NavButtonDesc";
import React from "react";
import { useI18n } from "../../hooks/useI18n";
import { useHopper } from "../../hooks/useHopper";
import styled, { css } from "styled-components";
import { HeadFrame } from "./MoodCell";
import { Image } from "../../components/Image";
import addSticker from "../../res/images/add_sticker.svg";
import { Input } from "../../components/Input";
import { useHopState } from "../../hooks/useHopState";
import { StickerPicker } from "../reaction/StickerPicker";
import { useModal } from "../../components/Modal";
import { Sticker } from "../../proto/Reaction";
import { useBackend } from "../../service/APIService";
import { useSWR } from "../../hooks/swr/useSWR";
import { andLog } from "../../components/handleError";
import { useGlobalSpinner } from "../../utils/globalSpinner";

function EditStatusPage() {
  const i18n = useI18n();
  const hopper = useHopper();
  const onBack = () => {
    hopper.back();
  };
  const backend = useBackend();
  const moodsSWR = useSWR(backend.getMyMoodsList());
  const [name, setName] = useHopState<string>("statusName");
  const modal = useModal("sticker-picker");
  const globalSpinner = useGlobalSpinner();

  const onCheckClick = async () => {
    globalSpinner(async () => {
      await backend
        .createMood({
          stickerId: pickedSticker?.stickerId,
          text: name,
        })
        .run();
      await moodsSWR.load();
      hopper.back();
    }).catch(andLog);
  };
  const [pickedSticker, setPickedSticker] =
    useHopState<Sticker>("pickedSticker");

  return (
    <Page pageData={undefined}>
      <NavStart>{NavItem.image(NavButtonType.Cross, onBack)}</NavStart>
      <NavMiddle>{i18n.edit_status()}</NavMiddle>
      <NavEnd>{NavItem.image(NavButtonType.Check, onCheckClick)}</NavEnd>

      <EditFrame>
        <HeadFrame style={{ width: 70, height: 70 }}>
          <Image
            src={
              !!pickedSticker
                ? [pickedSticker.media, { width: 50, height: 50 }]
                : addSticker
            }
            width={50}
            height={50}
            onClick={() => {
              modal.open();
            }}
          />
        </HeadFrame>
        <Input
          style={{
            height: 70,
          }}
          value={name}
          placeholder={`${i18n.add_status()}`}
          onChange={(e) => setName(e.target.value)}
          mixin={css`
            flex-grow: 1;
          `}
        />
        <CountText>
          <span
            style={
              (name?.length ?? 0) < 50
                ? { color: "white" }
                : { color: `rgb(255, 79, 79)` }
            }
          >
            {name?.length ?? 0}
          </span>
          /50
        </CountText>
      </EditFrame>
      <StickerPicker
        modal={modal}
        onChoose={(s) => {
          setPickedSticker(s);
        }}
        backend={backend}
      />
    </Page>
  );
}

const CountText = styled.div`
  position: absolute;
  bottom: 7px;
  right: 8px;
  font-size: 10px;
  color: rgba(255, 255, 255, 0.4);
`;

const EditFrame = styled.div`
  height: 70px;
  display: flex;
  flex-direction: row;
  border: 1px dashed rgba(255, 255, 255, 0.3); /* 虚线边框 */
  border-radius: 6px; /* 可选，增加圆角 */
  background: rgba(255, 255, 255, 0.05);
  position: relative;
`;

export default EditStatusPage;
