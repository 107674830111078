import { useI18n, useIsRTL } from "../../hooks/useI18n";
import { Page } from "../../components/Page";
import React, { useState } from "react";
import { PhoneNumberInput, RegularInputGroup } from "../../components/Input";
import { VSpace } from "../../components/VStack";
import { useHopper } from "../../hooks/useHopper";
import { AuthType, SecurityPurpose } from "../../proto/Auth";
import {
  useEnumSearchParam,
  useNumberSearchParam,
} from "../../hooks/useTypedParam";
import { RegularLargeButton } from "../../components/Buttons";
import { Spin } from "../../components/Spin";
import { useLoadState } from "../../hooks/LoadState";
import { NavEnd, NavItem, NavMiddle } from "../../components/NavBar";
import { SetPasswordType } from "./SetPasswordPage";
import { useBackend } from "../../service/APIService";
import { AuthTitleLabel } from "./InvitationCodePage";
import { useModal } from "../../components/Modal";
import { allRegions, Region } from "../../proto/Region";
import { BSMenu, BSMenuItem } from "../../components/BSMenu";
import { useErrorHandler } from "../../components/handleError";

export function VerifyYourAccountPage() {
  const i18n = useI18n();
  const backend = useBackend();
  const handleError = useErrorHandler();
  const hopper = useHopper();
  const authType =
    getAuthTypeByNumber(useNumberSearchParam("authType") || 1) ||
    AuthType.Email;

  const loadTask = useLoadState();

  const setPasswordType =
    useEnumSearchParam<SetPasswordType>("setPasswordType");

  function getAuthTypeByNumber(value: number): AuthType | undefined {
    if (Object.values(AuthType).includes(value)) {
      return value as AuthType;
    }
    return undefined;
  }

  const getPlaceholder = (authType: AuthType): string => {
    switch (authType) {
      case AuthType.Email:
        return i18n.email();
      case AuthType.Phone:
        return i18n.auth_phone_number();
      default:
        return "";
    }
  };
  const placeholder = getPlaceholder(authType);

  const [inputValue, setInputValue] = useState("");

  async function verifyAccount() {
    const phoneNumberWithMark = region?.phoneCodeWithMark + inputValue;
    const authBody = {
      email: authType === AuthType.Email ? inputValue : undefined,
      phoneNumber:
        authType === AuthType.Phone ? phoneNumberWithMark : undefined,
      purpose: SecurityPurpose.ChangePassword,
      authType: authType,
    };
    const result = await loadTask.run(async () => {
      await backend.checkIdentity(authBody).run();
    });
    if (!result.success) {
      handleError(result.error);
    } else {
      hopper.push("verification-code", {
        ...authBody,
        setPasswordType: setPasswordType,
      });
    }
  }

  const isRTL = useIsRTL();
  const regionMenu = useModal("regionMenu");
  const locale = new Intl.Locale(navigator.language);
  const [region, setRegion] = React.useState<Region | null>(
    Region.from(locale.region || "US"),
  );
  return (
    <Page pageData={undefined}>
      <NavMiddle>{i18n.auth_reset_password()}</NavMiddle>
      <NavEnd>
        {NavItem.text(i18n.help(), () => hopper.push("feedback"))}
      </NavEnd>
      <AuthTitleLabel>{i18n.auth_verify_account()}</AuthTitleLabel>
      <VSpace height={20} />
      {authType === AuthType.Phone && (
        <PhoneNumberInput
          placeholder={placeholder}
          value={inputValue}
          updateValue={setInputValue}
          regionCode={region?.phoneCodeWithMark}
          regionCodeOnClick={() => regionMenu.open()}
        />
      )}
      {authType === AuthType.Email && (
        <RegularInputGroup
          value={inputValue}
          updateValue={setInputValue}
          placeholder={placeholder}
        />
      )}
      <RegularLargeButton
        style={{ height: "54px", margin: "40px 50px 0" }}
        onClick={() => verifyAccount()}
      >
        <Spin state={loadTask.state}>{i18n.next()}</Spin>
      </RegularLargeButton>
      <BSMenu modal={regionMenu}>
        {allRegions.map((region) => (
          <BSMenuItem
            title={isRTL ? region.rtlDes : region.des}
            trailingItem={region.phoneCodeWithMark}
            onClick={() => {
              regionMenu.close();
              setRegion(region);
            }}
          ></BSMenuItem>
        ))}
      </BSMenu>
    </Page>
  );
}
