import { Page } from "../../components/Page";
import { useBigIntParam } from "../../hooks/useTypedParam";
import { useSWRArray } from "../../hooks/swr/useSWRArray";
import { useBackend } from "../../service/APIService";
import { ObjectType } from "../../proto/ObjectSpec";
import { CommentCell, CommentInputBar } from "../comment/Comment";
import React, { useState } from "react";
import { BareComment } from "../../proto/Comment";
import { FullPageWidthCellNoPadding } from "../../components/CommonStyles";
import styled from "styled-components";
import { useI18n } from "../../hooks/useI18n";
import { useSWR } from "../../hooks/swr/useSWR";
import UserBackgroundFrame from "./UserBackgroundFrame";
import { useHopper } from "../../hooks/useHopper";
import { useModal } from "../../components/Modal";
import { BSMenu, BSMenuItem } from "../../components/BSMenu";
import { copyTextToClipboard } from "../../utils/CopyText";
import { useGlobalSpinner } from "../../utils/globalSpinner";
import { useNativePage } from "../../hooks/useBridge";
import { andLog, useErrorHandler } from "../../components/handleError";
import { useMyUid } from "../../service/AuthSessionService";
import { PageFooter } from "../../components/PageHeaderFooter";
import { useMemoryRepoMap } from "../../hooks/swr/useLocalRepo";
import { zBigInt } from "../../utils/zodUtils";
import { z } from "zod";
import { Reaction } from "../../proto/Reaction";
import { useReactionRecord } from "../../hooks/useReactionRecord";

function WallListPage() {
  const uid = useBigIntParam("uid");
  const backend = useBackend();
  const hopper = useHopper();
  const reactionRepo = useReactionRecord();
  const commentSWR = useSWRArray(
    backend.getComments(uid, ObjectType.USER, 0).intercept((it) => {
      it.list.forEach((comment) => {
        reactionRepo
          .setValue(comment.commentId, comment.reactionCountList)
          .catch(andLog);
      });
    }),
  );
  const [replyToComment, setReplyToComment] = useState<BareComment>();
  const i18n = useI18n();
  const userSWR = useSWR(backend.getUser(uid));

  const commentMenu = useModal("comment-menu");
  const [clickedComment, setClickedComment] = useState<BareComment>();
  const globalSpinner = useGlobalSpinner();
  const nativePage = useNativePage();
  const handleError = useErrorHandler();
  const myUid = useMyUid();

  const onCopy = async () => {
    try {
      await globalSpinner(async () => {
        const content = clickedComment?.content;
        if (content) {
          await copyTextToClipboard(content);
        }
      });
      await nativePage.successHud(i18n.copied());
    } catch (e) {
      handleError(e);
    }
  };

  const deleteComment = (commentId: bigint) => {
    globalSpinner(async () => {
      await backend.deleteComment(commentId).run();
    }).then(() => commentSWR.load());
  };

  return (
    <Page
      underlay={
        <UserBackgroundFrame
          background={userSWR.content?.background}
          backgroundColor={userSWR.content?.backgroundColor}
        />
      }
      pageData={[commentSWR, userSWR]}
    >
      <CommentsHeader style={{ ...FullPageWidthCellNoPadding }}>
        {i18n.comments_num(userSWR.content?.commentsCount ?? 0)}
      </CommentsHeader>
      {commentSWR.content?.map((comment) => (
        <CommentCell
          key={`comment-cell-${comment.commentId}`}
          comment={comment}
          onClickReply={(comment) => {
            setReplyToComment(Object.assign({}, comment));
          }}
          onClick={(c) => {
            setClickedComment(c);
            commentMenu.open();
          }}
        />
      ))}
      <BSMenu modal={commentMenu}>
        <BSMenuItem
          title={i18n.reply()}
          onClick={() => setReplyToComment(Object.assign({}, clickedComment))}
        />
        <BSMenuItem title={i18n.copy()} onClick={onCopy} />
        {clickedComment?.author.uid !== myUid && (
          <BSMenuItem
            title={i18n.flag()}
            onClick={() =>
              hopper.layer("flag", {
                objectId: clickedComment?.commentId,
                objectType: ObjectType.COMMENT,
              })
            }
          />
        )}
        {clickedComment?.author.uid === myUid && (
          <BSMenuItem
            title={i18n.delete()}
            titleStyle={{ color: "#FF3E65" }}
            onClick={() => {
              deleteComment(clickedComment?.commentId);
            }}
          />
        )}
      </BSMenu>
      <PageFooter obscuredZoneKey={"CommentInputBar"} safeBottomDisabled={true}>
        <CommentInputBar
          parentId={uid}
          parentType={ObjectType.USER}
          onSendComment={() => commentSWR.load()}
          replyToComment={replyToComment}
          hideCount={true}
        />
      </PageFooter>
    </Page>
  );
}

const CommentsHeader = styled.div`
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  color: #ffffffcc;
  background-color: #ffffff14;
  padding: 9px 20px;
  margin-bottom: 18px;
`;
export default WallListPage;
