import styled from "styled-components";
import { Image } from "../../../components/Image";
import { NFTStatus } from "../../../proto/NFT";
import iconLock from "../../../res/images/ic_nft_lock.svg";
import { AccountNFT } from "../../../proto/AccountNFT";
import { useI18n } from "../../../hooks/useI18n";
import { two_line_text } from "../../../components/CommonStyles";
import { useHopper } from "../../../hooks/useHopper";

export interface PropsNFT {
  nft: AccountNFT;
}

function NFTCell(props: PropsNFT) {
  const i18n = useI18n();
  const hopper = useHopper();
  return (
    <CellFrame
      onClick={() => {
        hopper.push(`nft/${props.nft.nftId}`);
      }}
    >
      <CellHead>
        <div
          style={{
            display: "flex",
            position: "absolute",
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            borderRadius: 12,
            border: `1px solid rgba(255, 255, 255, 0.1)`,
          }}
        >
          <Image
            src={[props.nft.nftMetaInfo?.entity?.preview, "best"]}
            style={{
              width: "100%",
              height: "100%",
              borderRadius: 12,
              objectFit: "cover",
            }}
          />
        </div>
        <LockFrame>
          <Image
            src={iconLock}
            style={{ width: 16, height: 16, margin: "auto" }}
          />
        </LockFrame>
        {props.nft.nftMetaInfo.nftStatus === NFTStatus.Pending ||
        props.nft.nftMetaInfo.nftStatus === NFTStatus.Minting ? (
          <NFTProto>{i18n.jul23_impr2_all_caps_proto()}</NFTProto>
        ) : null}

        <NFTCount>{`×${props.nft.availableBalance}`}</NFTCount>
      </CellHead>
      <NFTName>{props.nft.nftMetaInfo.name}</NFTName>
    </CellFrame>
  );
}

export default NFTCell;

const NFTName = styled.div`
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  margin: 0 auto;
  overflow: hidden;
  width: 70px;
  line-height: 1.2em; /* Adjust line-height as needed */
  height: calc(1.2em * 2); /* Keeps height for two lines */
  ${two_line_text}
`;

const CellHead = styled.div`
  position: relative;
  min-width: 70px;
  aspect-ratio: 1;
`;

const LockFrame = styled.div`
  width: 24px;
  position: absolute;
  height: 20px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 12px 0 6px 0;
  display: flex;
`;

const NFTProto = styled.div`
  font-size: 8px !important;
  font-weight: 400;
  color: #ffffff;
  position: absolute;
  top: 6px;
  right: 6px;
  -webkit-text-size-adjust: none;
`;

const CellFrame = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
`;

const NFTCount = styled.div`
  border-radius: 12px 0 12px 0;
  background: rgba(0, 0, 0, 0.6);
  padding: 4px 10px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  bottom: 0;
  right: 0;
`;
