import { SearchProps } from "./SearchPage";
import { useHopper } from "../../hooks/useHopper";
import { useBackend } from "../../service/APIService";
import { Page } from "../../components/Page";
import {
  SocialId,
  UserCellProps,
  UserFrame,
  UserInfoFrame,
} from "../user/CommonUserCell";
import { UserAvatarView } from "../../components/views/UserAvatarView";
import { UserNameView } from "../../components/views/UserNameView";
import * as React from "react";
import { useEffect } from "react";
import { andLog } from "../../components/handleError";
import TabCellSeparator from "../../components/TabCellSeparator";
import { useI18n } from "../../hooks/useI18n";
import { useSWRArray } from "../../hooks/swr/useSWRArray";

export function SearchUserPage({ word, circleId }: SearchProps) {
  const hopper = useHopper();
  const backend = useBackend();
  const i18n = useI18n();
  const usersSWR = useSWRArray(["SearchUserPage", word], () =>
    backend.searchUsers(word, circleId).run(),
  );

  useEffect(() => {
    usersSWR.load().catch(andLog);
  }, [word]);
  return (
    <Page pageData={usersSWR} scrollPaddingDisabled={true}>
      {usersSWR.content && (
        <TabCellSeparator title={i18n.matched_by_keywords()}></TabCellSeparator>
      )}
      {usersSWR.content &&
        usersSWR.content.map((user) => (
          <SearchUserCell
            key={user.uid.toString()}
            user={user}
            onUpdate={() => usersSWR.load()}
          />
        ))}
    </Page>
  );
}

export function SearchUserCell(props: UserCellProps) {
  const hopper = useHopper();
  return (
    <UserFrame
      padding={"10px var(--page-h-inset) 10px var(--page-h-inset)"}
      borderLeftOffset="70px"
      onClick={() => {
        hopper?.push(`user/${props.user.uid}`);
      }}
    >
      <UserAvatarView user={props.user} iconSize={40} />
      <UserInfoFrame
        style={{
          maxWidth: "calc(100% - var(--page-h-inset) * 3 - 40px)",
          justifyContent: "center",
        }}
      >
        <UserNameView user={props.user} />
        <SocialId>{`@${props.user.socialId}`}</SocialId>
      </UserInfoFrame>
    </UserFrame>
  );
}
