import { Spring } from "../../components/VStack";
import React from "react";
import {
  Animation,
  Drawer,
  ModalController,
  requireModalController,
} from "../../components/Modal";
import styled from "styled-components";
import { PostCell } from "./PostCell";
import { Post } from "../../proto/Post";
import { CommentInputBar } from "../comment/Comment";
import { ObjectType } from "../../proto/ObjectSpec";
import { None, SlideDown, SlideUp } from "../../components/Keyframes";
import { hPaddingWithPageInset } from "../../components/CommonStyles";
import { NativePage } from "../../bridge/NativePage";
import { DefaultPagePg } from "../../components/Page";

export function QuickComment(props: {
  modal: ModalController;
  post: Post;
  parentType: ObjectType;
}) {
  requireModalController(props.modal);
  return (
    <Drawer {...props} isHorizontal={false}>
      <Spring />
      <QuickCommentBg
        onClick={(e) => {
          e.stopPropagation();
        }}
        $animation={props.modal.animation}
        onAnimationEnd={props.modal.onAnimationEnd}
      >
        <PostCell
          post={props.post}
          hPadding={hPaddingWithPageInset}
          hideCommentButton={true}
        />
        <CommentInputBar
          parentId={props.post.blogId}
          parentType={props.parentType}
          onSendComment={() => {}}
          hideCount={true}
        />
      </QuickCommentBg>
    </Drawer>
  );
}

const QuickCommentBg = styled.div<{ $animation: Animation }>`
  position: relative;
  width: 100%;
  height: auto;

  animation: ${(p) =>
      p.$animation === Animation.None
        ? None
        : p.$animation === Animation.In
          ? SlideUp
          : SlideDown}
    0.3s ease-out 1 forwards;

  animation-fill-mode: both;
  overflow: hidden;
`;
