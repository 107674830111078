import { useBorderBoxSize } from "./useResizeObserver";
import { useLayoutEffect, useMemo } from "react";
import { ObscuredZoneKey, useObscuredZones } from "./useObscuredZones";

export function useObscuringBar(
  key: ObscuredZoneKey,
  position: "top" | "bottom",
) {
  const [_, setObscuredZone, zones] = useObscuredZones();

  const [barRef, size] = useBorderBoxSize();

  useLayoutEffect(() => {
    if (size?.height !== undefined) {
      const zone = { top: 0, bottom: 0 };
      zone[position] = size.height;
      setObscuredZone(key, zone);
    }
    return () => {
      setObscuredZone(key, undefined);
    };
  }, [setObscuredZone, size?.height]);

  const padding = useMemo(() => {
    const clone = new Map(zones);
    clone.delete(key);
    return Array.from(clone.values()).reduce((prev, curr) => {
      return Math.max(prev, curr[position]);
    }, 0);
  }, [zones]);

  return [barRef, padding, zones] as const;
}
