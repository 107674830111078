import { z } from "zod";
import { zBigInt, zStatic } from "../utils/zodUtils";
import { Currency } from "./Currency";
import { Media } from "./Media";

export const UserPropsPackage = z.object({
  packageId: zBigInt.default(0),
  productGUID: zBigInt.default(0),
  productId: z.string().optional(),
  quota: z.number().optional(),
  units: z.number().optional(),
  propsType: z.number().optional(),
  price: Currency.optional(),
  originalPrice: Currency.optional(),
  amount: Currency.optional(),
  discountRate: z.number().optional(),
  icon: Media.optional(),
  title: z.string().optional(),
});

export type UserPropsPackage = zStatic<typeof UserPropsPackage>;
