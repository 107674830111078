import { z } from "zod";
import { Currency } from "./Currency";
import { zStatic } from "../utils/zodUtils";

export const GiftBoxServiceFee = z.object({
  sendServiceFee: Currency.optional(),
  returnServiceFee: Currency.optional(),
  useRewardCoin: z.boolean().optional(),
});

export type GiftBoxServiceFee = zStatic<typeof GiftBoxServiceFee>;
