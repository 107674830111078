import BigNumber from "bignumber.js";

export function jsonifyBigNumber(bigNumber: BigNumber, decimal: number): string;
export function jsonifyBigNumber(
  bigNumber: BigNumber | undefined,
  decimal: number,
): string | undefined {
  if (bigNumber === undefined) return undefined;
  return new BigNumber(bigNumber).times(`1${"0".repeat(decimal)}`).toFixed();
}
