// Object.fromEntries doesn't work with older version of Chrome:  Android device and Chrome version 71.0.3578.99
// https://stackoverflow.com/questions/61265733/alternative-to-object-fromentries
function createObjFromEntries(entries: [string, any][]) {
  const newObj = {};
  for (const [key, val] of entries) {
    // @ts-ignore
    newObj[key] = val;
  }
  return newObj;
}

export function pick<T extends object, K extends keyof T>(
  obj: T,
  keys: K[],
): Pick<T, K> {
  return createObjFromEntries(
    Object.entries(obj).filter(([k, _]) => keys.includes(k as K)),
  ) as Pick<T, K>;
}

export function omit<T extends object, K extends keyof T>(
  obj: T,
  keys: K[],
): Omit<T, K> {
  return createObjFromEntries(
    Object.entries(obj).filter(([k, _]) => !keys.includes(k as K)),
  ) as Omit<T, K>;
}

export function pruneNulls(value: any) {
  if (typeof value !== "object") return;
  Object.keys(value).forEach((key) => {
    const child = value[key];
    if (child === null) {
      delete value[key];
    } else {
      pruneNulls(child);
    }
  });
}

const bigintKeys = new Set([
  "uid",
  "objectId",
  "blogId",
  "circleId",
  "threadId",
  "currencyId",
]);

export function coerceBigInt(value: any) {
  if (value === undefined || value === null || typeof value !== "object")
    return;
  Object.keys(value).forEach((key) => {
    const child = value[key];
    if (typeof child === "number" && bigintKeys.has(key)) {
      value[key] = BigInt(child);
    } else {
      coerceBigInt(child);
    }
  });
}
