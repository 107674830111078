import { HStack } from "../../../components/VStack";
import { Image } from "../../../components/Image";
import iconQuantityMinus from "../../../res/images/icon_quantity_minus.svg";
import iconQuantityPlus from "../../../res/images/icon_quantity_plus.svg";
import styled from "styled-components";
import { useI18n } from "../../../hooks/useI18n";
import { useHopState } from "../../../hooks/useHopState";
import { andLog } from "../../../components/handleError";

export interface PaymentQuantityProps {
  count: number;
  maxCount?: number;
  onCountChanged: (count: number) => Promise<void>;
  title: string;
}

function PaymentQuantity(props: PaymentQuantityProps) {
  const i18n = useI18n();
  const [currentCount, setCurrentCount] = useHopState<number>(
    "initialCount",
    props.count,
  );

  const [inputText, setInputText] = useHopState<string>(
    "quantityInput",
    String(props.count),
  );

  const plus = async () => {
    if (!props.maxCount || currentCount < props.maxCount) {
      const value = currentCount + 1;
      setCurrentCount(value);
      setInputText(String(value));
      props.onCountChanged(value).catch(andLog);
    }
  };

  const minus = async () => {
    if (currentCount >= 1) {
      const value = currentCount - 1;
      setCurrentCount(value);
      setInputText(String(value));
      props.onCountChanged(value).catch(andLog);
    }
  };

  return (
    <HStack style={{ height: 48, justifyContent: "space-between" }}>
      <QuantityLabel>{props.title}</QuantityLabel>
      <HStack>
        <Image onClick={minus} src={iconQuantityMinus} width={24} height={24} />
        <QuantityValue
          value={inputText}
          type={"number"}
          onChange={(e) => {
            const value = Number(e.target.value);
            if (
              value === undefined ||
              value.toString() === "NaN" ||
              value < Number(1)
            ) {
              setCurrentCount(0);
              setInputText(String(0));
              props.onCountChanged(0).catch(andLog);
            } else if (value < Number(1000)) {
              setCurrentCount(value);
              setInputText(String(value));
              props.onCountChanged(value).catch(andLog);
              console.log(
                `fsaasw2 ${value}, ${e.target.value}, ${currentCount}`,
              );
            }
          }}
        ></QuantityValue>
        <Image onClick={plus} src={iconQuantityPlus} width={24} height={24} />
      </HStack>
    </HStack>
  );
}

const QuantityLabel = styled.div`
  color: var(--color-text00);
  font-weight: 300;
  font-size: 14px;
`;

const QuantityValue = styled.input`
  color: var(--color-text00);
  width: 60px;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  background-color: transparent; /* 设置背景透明 */
  border: none; /* 如果不需要边框也可以移除 */
`;

export default PaymentQuantity;
