import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";

export enum LoadStateKind {
  loading = "loading",
  loaded = "loaded",
  loadFailed = "loadFailed",
}

export type Loading = {
  kind: LoadStateKind.loading;
  reason?: string;
};

export type Loaded = {
  kind: LoadStateKind.loaded;
};

export type LoadFailed = {
  kind: LoadStateKind.loadFailed;
  error: unknown;
};

export type LoadState = Loading | Loaded | LoadFailed;

// export type LoadStateMachine<R> = {
//   value?: LoadState;
//   start: (reason?: string) => void;
//   succeed: () => void;
//   fail: (e: any) => void;
//   run: (f: () => Promise<R>, reason?: string, done?: (r: R) => void) => void;
// };

export class LoadStateMachine {
  constructor(
    public readonly state: LoadState | undefined,
    private readonly setState: Dispatch<SetStateAction<LoadState | undefined>>,
  ) {}

  start(reason?: string) {
    this.setState({ kind: LoadStateKind.loading, reason: reason });
  }

  succeed() {
    this.setState({ kind: LoadStateKind.loaded });
  }

  fail(e: any) {
    this.setState({ kind: LoadStateKind.loadFailed, error: e });
  }

  async run<R>(f: () => Promise<R>, reason?: string) {
    this.start(reason);
    try {
      const r = await f();
      this.succeed();
      return {
        success: true as const,
        result: r,
      };
    } catch (e) {
      this.fail(e);
      return {
        success: false as const,
        error: e,
      };
    }
  }
}

export function useLoadState() {
  const [loadState, setLoadState] = useState<LoadState>();
  return useMemo(() => {
    return new LoadStateMachine(loadState, setLoadState);
  }, [loadState, setLoadState]);
}

export function useLoadTask<R>(task: (() => Promise<R>) | undefined) {
  const loadState = useLoadState();
  const [result, setResult] = useState<R>();
  useEffect(() => {
    if (task) {
      loadState.run(task).then((r) => {
        if (r.success) {
          setResult(r.result);
        } else {
          setResult(undefined);
        }
      });
    }
    return () => {
      setResult(undefined);
    };
  }, [task]);

  return [result, loadState.state] as const;
}

export function AllDoneWithErrorFirst(
  loadStates: (LoadState | undefined)[],
  isError?: (error: unknown) => boolean,
): LoadState | undefined {
  const failed = loadStates.find(
    (ls) =>
      ls?.kind === LoadStateKind.loadFailed && (!isError || isError(ls.error)),
  );
  if (failed !== undefined) return failed;

  const loading = loadStates.find((ls) => ls?.kind === LoadStateKind.loading);
  if (loading !== undefined) return loading;

  const notStarted = loadStates.findIndex((ls) => ls === undefined);
  if (notStarted !== -1) {
    return undefined;
  }

  return loadStates[0];
}

export function AllDoneWithLoadingFirst(
  loadStates: (LoadState | undefined)[],
  isError?: (error: unknown) => boolean,
): LoadState | undefined {
  const loading = loadStates.find((ls) => ls?.kind === LoadStateKind.loading);
  if (loading !== undefined) return loading;

  const failed = loadStates.find(
    (ls) =>
      ls?.kind === LoadStateKind.loadFailed && (!isError || isError(ls.error)),
  );
  if (failed !== undefined) return failed;

  const notStarted = loadStates.findIndex((ls) => ls === undefined);
  if (notStarted !== -1) {
    return undefined;
  }

  return loadStates[0];
}
