import { string, z } from "zod";
import { zBigInt, zStatic } from "../utils/zodUtils";
import { Media } from "./Media";
import { User } from "./User";

export const BackgroundMedia = z.object({
  media: Media.optional(),
  title: z.string().optional(),
  from: z.number().optional(),
  description: z.string().optional(),
  author: User.optional(),
  activityTitle: z.string().optional(),
});
export type Background = zStatic<typeof BackgroundMedia>;
