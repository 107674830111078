import {
  HSpace,
  HStack,
  HStackMixin,
  Spring,
  VStack,
} from "../../../components/VStack";
import { Image } from "../../../components/Image";
import icSol from "../../../res/images/ic_sol.svg";
import {
  Currency,
  CurrencyId,
  CurrencyType,
  formatMoney,
  getCurrencyAmount,
  getCurrencyNameByCurrencyType,
} from "../../../proto/Currency";
import { CircleToken, isValidCircleToken } from "../../../proto/CircleFin";
import {
  ContentGroup,
  GroupCellDivider,
} from "../../../components/ContentGroup";
import { andLog } from "../../../components/handleError";
import icBuy from "../../../res/images/ic_buy.svg";
import icReset from "../../../res/images/icon_reset.svg";
import { NumberInput } from "../../../components/NumberInput";
import BigNumber from "bignumber.js";
import { CurrencyIcon } from "../../../components/views/CurrencyIcon";
import { RegularButton } from "../../../components/Buttons";
import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import CssFilterConverter from "css-filter-converter";
import { useNativePage, useWebHost } from "../../../hooks/useBridge";
import { useI18n } from "../../../hooks/useI18n";
import { delay } from "../../../utils/ensureDur";
import { useWalletSWR } from "../useWallet";
import { ModalController, useModal } from "../../../components/Modal";
import { useHopState } from "../../../hooks/useHopState";
import { jsonifyBigNumber } from "../../../utils/CurrencyAmount";
import { ZeroSOL } from "./SwapPage";
import { WalletAccount } from "../../../proto/Wallet";
import { useEditingBigNumber } from "../../../hooks/useEditingBigNumber";
import { useChange } from "../../../hooks/ExtHooks";
import { CircleTokenPool } from "../../../proto/Circle";
import { ChainType } from "../../../proto/ChainType";
import {
  PercentageChangeLabel,
  TokenPriceLabel,
} from "../../circle/CircleDetailInfoPage";
import iconSol from "../../../res/images/icon_sol.svg";
import {
  circleTokenFluctuationImage,
  marketCap1HipBackgroundColor,
  marketCap1HipColor,
  marketCap1HipString,
} from "../../circle/CircleStatsPage";
import { useTransactionBody } from "../send/SendTokenPage";
import { CircleTokenTradeAdvanceModal } from "../../circle/card/CircleTokenTradeAdvanceModal";
import { useHopper } from "../../../hooks/useHopper";
import { usePageSpec } from "../../common/usePageSpec";
import { CircleTokenStatus } from "../../../proto/CircleTokenStatus";

export interface TradeGroupProps {
  content?: CircleToken;
  token?: Currency;
  dollar?: Currency;
  tradeCurrency?: Currency;
  walletAccount?: WalletAccount;
  tokenPool?: CircleTokenPool;
  circleId?: bigint;
  editingBody: ReturnType<typeof useTransactionBody>;
  paymentModal: ModalController;
}

function TradeGroup(props: TradeGroupProps) {
  const token = props.token;
  const content = props.content;
  const tokenPool = props.tokenPool;
  const walletAccount = props.walletAccount;
  const editingBody = props.editingBody;
  const circleId = props.circleId;
  const webHost = useWebHost();
  const i18n = useI18n();
  const nativePage = useNativePage();
  const [wallet, walletSWR] = useWalletSWR();

  const [slippage, setSlippage] = useHopState<BigNumber>(
    "dexSwapCustomSlippage",
    BigNumber(5),
  );

  const hopper = useHopper();

  const [customPriorityFee, setCustomPriorityFee] = useHopState<Currency>(
    "dexSwapCustomPriorityFee",
    {
      ...ZeroSOL,
      amount: jsonifyBigNumber(BigNumber("0.003"), 9),
    },
  );

  const [receiveCount, setReceiveCount] = useHopState<Currency>(
    "dexSwapReceiveCount",
  );

  const [tradeCountText, setTradeCountText, tradeValue, setTradeValue] =
    useEditingBigNumber("circleTokenTradeCount");

  const [isBuy, setIsBuy] = useHopState<boolean>("dexIsBuy", true);

  const tradeCurrency: Currency | undefined = useMemo(() => {
    if (!isValidCircleToken(content)) {
      return;
    }
    const currentValue = tradeValue ?? BigNumber(0);
    if (isBuy) {
      return {
        ...ZeroSOL,
        amount: jsonifyBigNumber(currentValue, 9),
      };
    } else {
      const currencyAmount = jsonifyBigNumber(
        currentValue,
        content?.currency?.decimals,
      );
      return {
        ...content?.currency,
        amount: currencyAmount,
      };
    }
  }, [content, tradeValue]);

  const isDexPooled = useMemo(() => {
    return (
      isValidCircleToken(content) &&
      content.status === CircleTokenStatus.DexPooled
    );
  }, [content]);
  // const isDexPooled = true;

  const startTradeToken = async () => {
    if (
      !tradeValue ||
      !tradeValue.isGreaterThan(BigNumber(0)) ||
      !tradeCurrency ||
      !isValidCircleToken(content)
    ) {
      console.log("asdadsdw2 aa");
      return;
    }

    if (!walletAccount) {
      console.log("asdadsdw2 bb");
      return;
    }

    const assetAccount = walletAccount.assetAccountList.find((a) => {
      return (
        a.chainType === ChainType.Solana ||
        a.chainType === ChainType.SolanaTestnet
      );
    });

    if (!assetAccount) {
      console.log("asdadsdw2 cc");
      return;
    }

    if (!receiveCount) {
      console.log("asdadsdw2 dd");
      return;
    }

    const assetCurrency = assetAccount.currencyList.find(
      (c) =>
        (isBuy && c.currencyType === tradeCurrency.currencyType) ||
        (!isBuy && c.currencyId === tradeCurrency.currencyId),
    );
    console.log(`asdadsdw2 isBuy ${isBuy}`);
    if (!isBuy && !Boolean(assetCurrency)) {
      nativePage
        .infoHud(i18n.web3_insuf_bal_insufficient_balance())
        .catch(andLog);
      console.log("asdadsdw2 oo");
      return;
    }

    if (
      assetCurrency !== undefined &&
      getCurrencyAmount(assetCurrency.availableCurrency).comparedTo(
        tradeValue,
      ) < 0
    ) {
      nativePage
        .infoHud(
          i18n.circle_fin_insufficient_balance(
            formatMoney("medium", assetCurrency.availableCurrency) ?? "-",
            assetCurrency.availableCurrency?.symbol ?? "-",
          ),
        )
        .catch(andLog);
      console.log("asdadsdw2 ee");
      return;
    }

    if (isBuy) {
      await editingBody.fill((prevData) => ({
        ...prevData,
        swapCurrencyType: CurrencyType.SOL_TOKENS,
        swapCurrencyId: token?.currencyId,
        currencyType: CurrencyType.SOL,
        currencyId: BigInt(CurrencyId.SOL),
        swapAmount: receiveCount?.amount,
        slippage: jsonifyBigNumber(slippage, 7),
        currency: tradeCurrency,
        decimals: 9,
        priorityFee: customPriorityFee.amount,
        priorityFeeCurrencyType: CurrencyType.SOL,
        amount: jsonifyBigNumber(BigNumber(tradeValue), 9),
      }));
    } else {
      await editingBody.fill((prevData) => ({
        ...prevData,
        swapCurrencyType: CurrencyType.SOL,
        swapCurrencyId: BigInt(CurrencyId.SOL.toString()),
        swapAmount: receiveCount.amount,
        currencyId: content.projectId,
        decimals: 6,
        currencyType: CurrencyType.SOL_TOKENS,
        currency: tradeCurrency,
        slippage: jsonifyBigNumber(slippage, 7),
        priorityFee: customPriorityFee.amount,
        priorityFeeCurrencyType: CurrencyType.SOL,
        amount: jsonifyBigNumber(BigNumber(tradeValue), 6),
      }));
    }

    props.paymentModal.open();
  };

  useEffect(() => {
    console.log(`asdadsdw2 ${tradeValue}, ${tradeCountText}`);
  }, [tradeValue, tradeCountText]);

  useChange(tradeValue, () => {
    if (!isValidCircleToken(content)) {
      return;
    }
    if (tradeValue === undefined) {
      setReceiveCount(undefined);
      return;
    }
    if (tokenPool === undefined) {
      return;
    }
    if (isBuy) {
      const tokenCount = buyToken(tokenPool, jsonifyBigNumber(tradeValue, 9));
      setReceiveCount({
        ...content?.currency,
        amount: tokenCount.toFixed(),
      });
    } else {
      const solCount = sellToken(
        tokenPool,
        jsonifyBigNumber(tradeValue, content?.currency?.decimals),
      );
      setReceiveCount({
        ...ZeroSOL,
        amount: solCount.toFixed(),
      });
    }
  });

  const buyValue = [0.1, 1, 5, 10];
  const sellValue = [0.25, 0.5, 0.75, 1];

  const pageSpec = usePageSpec();

  const clickQuickInput = (index: number) => {
    if (!isValidCircleToken(content) || !walletAccount) {
      return;
    }
    if (isBuy) {
      const value = BigNumber(buyValue[index]);
      console.log("value: ", value.toFixed());
      setTradeValue(value);
    } else {
      const currency = getCurrencyFromWalletAccount(
        walletAccount,
        content?.currency?.currencyId,
      );
      if (currency) {
        const baseAmount = getCurrencyAmount(currency);
        const sellAmount = baseAmount.multipliedBy(sellValue[index]);
        const finalValue = BigNumber(sellAmount.toFixed(currency.decimals));
        setTradeValue(finalValue);
      } else {
        setTradeValue(BigNumber(0));
      }
    }
  };

  const advanceModal = useModal("advanceModal");

  const openAdvanceSettings = async () => {
    if (!walletSWR.content) {
      const spinner = await nativePage.showSpinner();
      try {
        await walletSWR.load();
        await delay(100);
        await nativePage.endSpinner(spinner);
        advanceModal.open();
      } catch (e) {
        nativePage.endSpinner(spinner).catch(andLog);
        // handleError(nativePage, i18n, e);
      }
    } else {
      advanceModal.open();
    }
  };

  return (
    <ContentGroup style={{ marginTop: 20 }}>
      <HStack>
        <Image
          src={[token?.icon, 24]}
          style={{ width: 24, height: 24, borderRadius: 4 }}
        />
        <HSpace width={2} />
        <Image
          src={icSol}
          style={{ width: 24, height: 24, borderRadius: 12 }}
        />
        <HSpace width={6} />
        <div
          style={{
            fontSize: 16,
            fontWeight: 500,
          }}
        >
          {token?.currencyCode}/SOL
        </div>
      </HStack>
      <HStack style={{ gap: 8, marginTop: 10 }}>
        <TokenPriceLabel style={{ fontSize: 22 }}>
          {isValidCircleToken(content) &&
            `$${formatMoney("medium", BigNumber(content.tradingStatistics?.priceUsd || "0"))}`}
        </TokenPriceLabel>
        <PercentageChangeLabel
          style={{
            fontSize: 12,
            color: marketCap1HipColor(content),
            background: marketCap1HipBackgroundColor(content),
          }}
        >
          <Image
            src={circleTokenFluctuationImage(content)}
            alt={"PercentageChangeDirection"}
            width={16}
            height={16}
          ></Image>
          {marketCap1HipString(content)}
        </PercentageChangeLabel>
      </HStack>
      <HStack style={{ marginTop: 4, gap: 4 }}>
        <Image src={iconSol} alt={"iconSol"} width={16} height={16}></Image>
        <TokenPrice>
          {isValidCircleToken(content) &&
            `${formatMoney("medium", BigNumber(content.tradingStatistics?.priceQuote || "0"))} ` +
              (getCurrencyNameByCurrencyType(CurrencyType.SOL, i18n) || "")}
        </TokenPrice>
      </HStack>

      <GroupCellDivider
        style={{
          marginTop: "16px",
        }}
      />

      <HStack style={{ marginTop: 20 }}>
        <BuyButton
          selected={isBuy}
          style={{ height: 40, flexGrow: 1 }}
          onClick={() => {
            if (isBuy) {
              return;
            }
            setIsBuy(true);
            setTradeValue(undefined);
            webHost.haptic("light").catch(andLog);
          }}
        >
          <Spring />
          <Image src={icBuy} />
          <HSpace width={8} />
          <div>{i18n.web3_v0_buy()}</div>
          <Spring />
        </BuyButton>
        <SellButton
          selected={!isBuy}
          style={{ height: 40, flexGrow: 1 }}
          onClick={() => {
            if (!isBuy) {
              return;
            }
            setIsBuy(false);
            setTradeValue(undefined);
            webHost.haptic("light").catch(andLog);
          }}
        >
          <Spring />
          <Image src={icBuy} style={{ transform: "rotate(0.5turn)" }} />
          <HSpace width={8} />
          <div>{i18n.circle_fin_sell()}</div>
          <Spring />
        </SellButton>
      </HStack>

      <FrameLayout>
        <VStack
          style={{
            opacity: isDexPooled || !isBuy ? 1 : 0,
            pointerEvents: isBuy && !isDexPooled ? "none" : "auto", // 根据条件禁用点击事件
          }}
        >
          <HStack style={{ marginTop: 16 }}>
            <HStack
              style={{
                padding: "6px 8px 6px 8px",
                fontWeight: 400,
                fontSize: 12,
                backgroundColor: "rgba(255, 255, 255, 0.06)",
                borderRadius: 6,
              }}
              onClick={() => setTradeCountText(undefined)}
            >
              <Image src={icReset} />
              <HSpace width={4} />
              {i18n.circle2_1_reset()}
            </HStack>
            <Spring />
            <div
              style={{
                color: "#34A6FF",
                fontWeight: 400,
                fontSize: 12,
              }}
              onClick={() => openAdvanceSettings().catch(andLog)}
            >
              {i18n.circle_fin_advanced_settings()}
            </div>
          </HStack>
          <HStack
            key={isBuy ? "buy" : "sell"}
            style={{
              marginTop: 8,
              backgroundColor: "rgba(255, 255, 255, 0.06)",
              borderRadius: 6,
            }}
          >
            <HSpace width={14} />
            <NumberInput
              decimal={true}
              placeholder={"0.00"}
              value={tradeCountText}
              textAlign={"start"}
              cssProperties={{
                padding: 0,
                background: "none",
                flexGrow: 10,
              }}
              onTextChange={(text) => {
                if (text.length === 0) {
                  setTradeCountText(undefined);
                  return;
                }
                const value = BigNumber(text);
                const rightValue = value.decimalPlaces(
                  props.tradeCurrency?.decimals ?? 8,
                  BigNumber.ROUND_DOWN,
                );
                if (value.comparedTo(rightValue) === 0) {
                  setTradeCountText(text);
                } else {
                  setTradeCountText(rightValue.toFixed());
                }
              }}
            />
            <Image
              src={isBuy ? icSol : [token?.icon, 24]}
              style={{ width: 24, height: 24, borderRadius: 12 }}
            />
            <HSpace width={5} />
            <div
              style={{
                color: "white",
                fontWeight: 500,
                fontSize: 16,
              }}
            >
              {isBuy ? "SOL" : token?.currencyCode}
            </div>
            <HSpace width={14} style={{ height: 4 }} />
          </HStack>
          <StyledFlex>
            {[0, 1, 2, 3].map((i) => {
              return (
                <div key={i} onClick={() => clickQuickInput(i)}>
                  {isBuy ? `${buyValue[i]} SOL` : `${sellValue[i] * 100}%`}
                </div>
              );
            })}
          </StyledFlex>

          <HStack
            style={{
              fontSize: 12,
              fontWeight: 300,
            }}
          >
            <div
              style={{
                marginRight: 6,
                color: "rgba(255, 255, 255, 0.6)",
              }}
            >
              {i18n.circle_fin_you_will_receive()}
            </div>
            <Image
              src={isBuy ? [token?.icon, 16] : icSol}
              style={{ width: 16, height: 16, borderRadius: 2 }}
            />
            <HSpace width={4} />
            {receiveCount ? formatMoney("long", receiveCount) : "0"}
            <HSpace width={4} />
            <div>{isBuy ? token?.currencyCode : "SOL"}</div>
          </HStack>

          <HStack
            style={{
              fontSize: 12,
              fontWeight: 300,
              marginTop: 16,
            }}
          >
            <div
              style={{
                marginRight: 6,
                color: "rgba(255, 255, 255, 0.6)",
              }}
            >
              {i18n.circle_fin_priority_fee_with_colon()}
            </div>
            <CurrencyIcon
              currency={customPriorityFee}
              size={16}
              style={{ borderRadius: 2 }}
            />
            <HSpace width={4} />
            {formatMoney("long", customPriorityFee)}
            <HSpace width={4} />
            <div>{customPriorityFee.currencyCode}</div>
          </HStack>

          <RegularButton
            onClick={() => {
              startTradeToken().catch(andLog);
            }}
            style={{
              marginTop: 16,
              width: "100%",
              paddingTop: 14,
              paddingBottom: 14,
              fontSize: 18,
              fontWeight: 500,
            }}
          >
            {i18n.circle_fin_place_trade()}
          </RegularButton>
        </VStack>

        {isBuy && !isDexPooled && (
          <VStack
            style={{
              position: "absolute",
              gap: 24,
              zIndex: 10,
              width: pageSpec === "narrow" ? "calc(100% - 40px)" : undefined,
              transform: "translate(-50%, -50%)",
              top: "50%",
              left: "50%",
            }}
            onClick={(ev) => {
              ev.stopPropagation();
            }}
          >
            <CantTradeDesc>
              {i18n.clover_this_token_is_in_incubation()}
            </CantTradeDesc>
            <RegularButton
              onClick={() => {
                if (circleId) {
                  hopper.push(`circle/${circleId}/card-picker`);
                }
              }}
              style={{
                marginTop: 16,
                width: "100%",
                paddingTop: 14,
                paddingBottom: 14,
                fontSize: 16,
                fontWeight: 400,
              }}
            >
              {i18n.clover_view_membership_cards()}
            </RegularButton>
          </VStack>
        )}
      </FrameLayout>

      {walletAccount && (
        <CircleTokenTradeAdvanceModal
          modal={advanceModal}
          wallet={walletAccount}
          priorityFee={customPriorityFee}
          onSave={(settings) => {
            setSlippage(settings.slippage);
            setCustomPriorityFee(settings.priorityFee);
          }}
          slippage={slippage}
        />
      )}
    </ContentGroup>
  );
}

const FrameLayout = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const CantTradeDesc = styled.div`
  color: var(--color-text10);
  text-align: center;
  font-weight: 300;
  font-size: 14px;
`;

function getCurrencyFromWalletAccount(
  account: WalletAccount,
  currencyId: BigInt,
) {
  for (let assetAccount of account.assetAccountList) {
    for (let currency of assetAccount.currencyList) {
      if (currency.currencyId === currencyId) {
        return currency.availableCurrency;
      }
    }
  }
}

const BuyButton = styled.div<{ selected: boolean }>`
  clip-path: polygon(0 0, 100% 0, 95% 100%, 0% 100%);
  background-color: ${(p) => {
    return p.selected ? "rgba(0, 255, 148, 0.06)" : "rgba(255, 255, 255, 0.06)";
  }};
  border-radius: 20px 0 0 20px;
  display: flex;
  flex-direction: row;
  align-items: center;

  & > img {
    filter: ${(p) => {
      if (p.selected) {
        return CssFilterConverter.hexToFilter("#00FF94").color + "";
      } else {
        return CssFilterConverter.hexToFilter("#D7D7D7").color + "";
      }
    }};
  }

  & > div {
    color: ${(p) => {
      return p.selected ? "rgba(0, 255, 148, 1)" : "rgba(255, 255, 255, 0.6)";
    }};
    font-size: 16px;
    font-weight: 400;
  }
`;

const SellButton = styled.div<{ selected: boolean }>`
  clip-path: polygon(5% 0, 100% 0, 100% 100%, 0% 100%);
  background-color: ${(p) => {
    return p.selected ? "rgba(239, 53, 55, 0.06)" : "rgba(255, 255, 255, 0.06)";
  }};
  border-radius: 0 20px 20px 0;
  display: flex;
  flex-direction: row;
  align-items: center;

  & > img {
    filter: ${(p) => {
      if (p.selected) {
        return CssFilterConverter.hexToFilter("#EF3537").color + "";
      } else {
        return CssFilterConverter.hexToFilter("#D7D7D7").color + "";
      }
    }};
  }

  & > div {
    color: ${(p) => {
      return p.selected ? "rgba(239, 53, 55, 1)" : "rgba(255, 255, 255, 0.6)";
    }};
  }
`;

const VolatilityTitle = styled.div`
  font-size: 12px;
  font-weight: 500;
`;

const VolatilityView = styled.div<{ isInc: boolean }>`
  ${HStackMixin};
  border: 1px solid transparent;
  border-radius: 6px;
  padding: 4px;
  background-color: ${(p) =>
    p.isInc ? "rgba(0, 255, 148, 0.1)" : "rgba(239, 53, 55, 0.1)"};
  color: ${(p) => (p.isInc ? "#00ff94" : "#ef3537")};
`;

const StyledFlex = styled.div`
  display: flex;
  gap: 8px;
  margin: 8px 0 16px;

  div {
    flex: 1;
    font-weight: 500;
    text-align: center;
    font-size: 14px;
    padding: 8px 0;
    background: rgba(65, 65, 65, 0.8);
    border-radius: 6px;
  }
`;

const TokenPrice = styled.div`
  font-weight: 300;
  font-size: 14px;
  color: #0dffcc;
`;

const ValueTitle = styled.div`
  color: #0dffcc;
  font-size: 28px;
  font-weight: 700;
`;

function buyToken(pool: CircleTokenPool, solAmount: string) {
  const sol = BigNumber(String(pool.pooledSol));
  const token = BigNumber(String(pool.pooledToken));
  const amount = BigNumber(solAmount);
  if (sol.isZero() && amount.isZero()) {
    return BigNumber(0);
  }
  const receive = amount.multipliedBy(token).dividedBy(sol.plus(amount));
  return receive.integerValue(BigNumber.ROUND_FLOOR);
}

function sellToken(pool: CircleTokenPool, tokenAmount: string) {
  const sol = BigNumber(String(pool.pooledSol));
  const token = BigNumber(String(pool.pooledToken));
  const amount = BigNumber(tokenAmount);
  if (token?.isZero() && amount.isZero()) {
    return BigNumber(0);
  }
  const receive = amount.multipliedBy(sol).dividedBy(token?.plus(amount));
  return receive.integerValue(BigNumber.ROUND_FLOOR);
}

// 计算要获得 amountA 个 TokenA 时，需要支付多少 TokenB
export function buyTokenAAAA(
  pool: CircleTokenPool,
  tokenAmount: string,
): BigNumber {
  const A = new BigNumber(String(pool.pooledToken));
  const B = new BigNumber(String(pool.pooledSol));
  const amount = new BigNumber(tokenAmount);

  if (amount.isGreaterThan(A)) {
    throw new Error("Requested TokenA amount exceeds pool size");
  }

  const cost = amount.multipliedBy(B).dividedBy(A.minus(amount));
  return cost.integerValue(BigNumber.ROUND_FLOOR).plus(1);
}

export default TradeGroup;
