import React from "react";
import { Page } from "../../../components/Page";
import { NavEnd, NavItem, NavMiddle } from "../../../components/NavBar";
import { useI18n } from "../../../hooks/useI18n";
import { useHopper } from "../../../hooks/useHopper";
import { PowerMarkdown } from "../../../components/PowerMarkdown";
import styled, { css } from "styled-components";
import { RegularButton } from "../../../components/Buttons";
import { useNativePage } from "../../../hooks/useBridge";
import { andLog, useErrorHandler } from "../../../components/handleError";
import { useBackend } from "../../../service/APIService";
import { useStringSearchParam } from "../../../hooks/useTypedParam";
import { useGlobalSpinner } from "../../../utils/globalSpinner";

export function DeleteAccountDeclarationPage() {
  const i18n = useI18n();
  const hopper = useHopper();
  const nativePage = useNativePage();
  const backend = useBackend();
  const nickname = useStringSearchParam("nickname") || "";
  const handleError = useErrorHandler();
  const globalSpinner = useGlobalSpinner();
  const onContinueClick = async () => {
    try {
      const sure = await nativePage.alertAreYouSure(
        i18n.delete_account_confirmation(),
        i18n.delete(),
        i18n.cancel(),
      );
      if (sure) {
        globalSpinner(async () => {
          await backend.checkDeletePreconditions().run();
          //TODO:apple/google etc associated account check
          hopper.push("delete-account-confirm");
        }).catch(andLog);
      }
    } catch (e) {
      handleError(e);
    }
  };

  return (
    <Page pageData={undefined}>
      <NavMiddle>{i18n.auth_delete_account()}</NavMiddle>
      <NavEnd>
        {NavItem.text(i18n.help(), () => hopper.push("feedback"))}
      </NavEnd>
      <TitleLabel>{i18n.delete_account_title(nickname)}</TitleLabel>
      <PowerMarkdown textStyle={DescStyle}>
        {i18n.delete_account_description()}
      </PowerMarkdown>
      <RegularButton
        style={{ marginLeft: 40, marginRight: 40, marginTop: 50 }}
        onClick={onContinueClick}
      >
        {i18n.continue_str()}
      </RegularButton>
    </Page>
  );
}

const TitleLabel = styled.div`
  color: var(--color-text00);
  font-size: 14px;
  font-weight: 500;
`;

const DescStyle = css`
  color: var(--color-text00);
  font-size: 12px;
  font-weight: 300;
  line-height: 150%;
  letter-spacing: -0.2px;
  margin-top: 20px;
`;
