import styled from "styled-components";
import { Currency, CurrencyType, formatMoney } from "../../../proto/Currency";
import { ChainType, getRuntimeChainType } from "../../../proto/ChainType";
import { RowCenterButton } from "../../../components/CommonStyles";
import { isValidCircleToken } from "../../../proto/CircleFin";
import icDropDown from "../../../res/images/ic_drop_down.svg";
import { I18n, useI18n } from "../../../hooks/useI18n";
import { HSpace, HStack, Spring, VSpace } from "../../../components/VStack";
import { GasFeeTxType, WalletAccount } from "../../../proto/Wallet";
import { useNativePage, useWebHost } from "../../../hooks/useBridge";
import { useBackend } from "../../../service/APIService";
import { useWalletSWR } from "../useWallet";
import { BottomSheet, closeModal, useModal } from "../../../components/Modal";
import React, { useEffect, useMemo } from "react";
import { Page } from "../../../components/Page";
import BigNumber from "bignumber.js";
import { jsonifyBigNumber } from "../../../utils/CurrencyAmount";
import { Image } from "../../../components/Image";
import { RegularButton } from "../../../components/Buttons";
import {
  useBigIntParam,
  useBigIntSearchParam,
} from "../../../hooks/useTypedParam";
import { useSWR } from "../../../hooks/swr/useSWR";
import { useHopState } from "../../../hooks/useHopState";
import { TxSheet, usePerformTx } from "../payment/TransactionDialog";
import { useTransactionBody } from "../send/SendTokenPage";
import { useHopper } from "../../../hooks/useHopper";
import { PageFooter } from "../../../components/PageHeaderFooter";
import TradeGroup from "./TradeGroup";
import { AccountType } from "../../../proto/AccountType";
import { OrderType } from "../order/OrderDetailPage";
import { useRegularAccount } from "../useRegularAccount";
import PaymentAccount from "../payment/PaymentAccount";
import { copyObject } from "../../../utils/copyObject";
import { useMergedCurrency } from "./useMergedCurrency";
import { JSONUtil } from "../../../utils/JSONUtil";
import CurrencyTransactionView from "../payment/CurrencyTransactionView";
import { CircleMembershipCard } from "../../../proto/CircleMembershipCard";

export const ZeroSOL: Currency = {
  currencyCode: "SOL",
  currencyId: BigInt(5),
  amount: "0",
  name: "SOL",
  symbol: "SOL",
  decimals: 9,
  currencyType: CurrencyType.SOL,
  chainType: ChainType.Solana,
};

export const ZeroGems: Currency = {
  currencyCode: "Gems",
  currencyId: BigInt(2),
  amount: "0",
  name: "Gems",
  symbol: "Gems",
  decimals: 18,
  currencyType: CurrencyType.GEMS,
  chainType: ChainType.Solana,
};

const CARD_JSON =
  "{\n" +
  '            "background": {\n' +
  '                "baseUrl": "https://sys.clover.space/3500/1861362659758817280-v1-r1005x567-s0x0.png",\n' +
  '                "mediaId": 1861362659758817280,\n' +
  '                "resourceList": [\n' +
  "                    {\n" +
  '                        "height": 567,\n' +
  '                        "url": "https://sys.clover.space/3500/1861362659758817280-v1-r1005x567-s1005x567.png",\n' +
  '                        "width": 1005\n' +
  "                    },\n" +
  "                    {\n" +
  '                        "height": 384,\n' +
  '                        "url": "https://sys.clover.space/3500/1861362659758817280-v1-r1005x567-s512x288.png",\n' +
  '                        "width": 682\n' +
  "                    }\n" +
  "                ]\n" +
  "            },\n" +
  '            "cardId": 1866388829838917632,\n' +
  '            "cardStatus": 1,\n' +
  '            "circleId": 1866373184450326528,\n' +
  '            "createdTime": 1733816747,\n' +
  '            "maturityPeriod": 0,\n' +
  '            "membershipLevel": 999,\n' +
  '            "tokenAddress": "2EhoVwriwW1RoKNmZ779QyhWqr1AjN6dKqSdkPjRpsUR",\n' +
  '            "tokenAmount": "10000000000000",\n' +
  '            "tokenProjectId": 1866376654628712448\n' +
  "        }";

export const AdminCard: CircleMembershipCard = JSONUtil.parse(
  CARD_JSON,
  CircleMembershipCard,
);

const WalletContent = styled.div`
  background-color: rgba(255, 255, 255, 0.06);

  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  padding: 16px 8px 16px 16px;

  div {
    font-size: 14px;
    font-weight: 500;
    color: white;
  }
`;

function CurrencyView(props: { currency: Currency }) {
  return (
    <HStack>
      <Image
        src={[props.currency.icon, 20]}
        style={{ width: 20, height: 20 }}
      />
      <HSpace width={4} />
      <div style={{ fontWeight: 500, fontSize: 12 }}>
        {formatMoney("short", props.currency)}
      </div>
    </HStack>
  );
}

function SelectedWalletAccountView(props: {
  walletAccount: WalletAccount;
  showCurrencyId?: bigint;
  onClick: () => void;
}) {
  const i18n = useI18n();
  const currencies: Currency[] = [];

  for (let asset of props.walletAccount.assetAccountList) {
    for (let currency of asset.currencyList) {
      if (currency.currencyType === CurrencyType.SOL) {
        if (currency.availableCurrency) {
          currencies.push(currency.availableCurrency);
        }
      } else if (currency.currencyId === props.showCurrencyId) {
        if (currency.availableCurrency) {
          currencies.push(currency.availableCurrency);
        }
      }
    }
  }

  currencies.sort((c1, c2) => {
    return c1.currencyType > c2.currencyType ? 1 : -1;
  });

  return (
    <WalletContent onClick={props.onClick}>
      <Image
        src={[props.walletAccount.icon, 24]}
        style={{ width: 24, height: 24, borderRadius: 12 }}
      />
      <HSpace width={8} />
      <div>{getWalletAccountName(props.walletAccount, i18n)}</div>
      <Spring />
      {currencies.map((currency) => {
        return (
          <>
            <CurrencyView currency={currency} />
            <HSpace width={6} />
          </>
        );
      })}

      <HSpace width={6} />
      <div
        style={{
          width: 1,
          height: 13,
          backgroundColor: "rgba(255, 255, 255, 0.1)",
        }}
      />
      <HSpace width={8} />
      <Image src={icDropDown} />
    </WalletContent>
  );
}

export function SwapPage() {
  const currencyId = useBigIntSearchParam("currencyId") ?? BigInt(0);
  const nativePage = useNativePage();
  const backend = useBackend();
  const i18n = useI18n();
  const webHost = useWebHost();

  const walletAccountId = useBigIntParam("walletAccountId");
  const assetAccountId = useBigIntParam("assetAccountId");

  const currencyListSWR = useSWR(backend.getCurrencyList(walletAccountId));

  const [wallet, walletSWR] = useWalletSWR();

  const tokenSWR = useSWR(backend.getTokenProject(currencyId));

  const tokenPool = useSWR(
    isValidCircleToken(tokenSWR.content?.tokenProject)
      ? [tokenSWR.content?.tokenProject?.currency.currencyId, "token-poll"]
      : undefined,
    () =>
      backend.getTokenPool(
        tokenSWR.content?.tokenProject
          ? tokenSWR.content?.tokenProject?.projectId
          : BigInt(0),
      ),
  );

  const accountMenu = useModal("accountMenu");

  const walletAccount = useMemo(() => {
    return wallet?.walletAccountList.find((account) => {
      return account.accountType === AccountType.BuiltIn;
    });
  }, [wallet]);

  const editingBody = useTransactionBody();

  const hopper = useHopper();
  const gasFeeSWR = useSWR(
    backend.getGasFee(
      editingBody.content.amount ?? "0",
      editingBody.content.toChainAddress ?? "",
      getRuntimeChainType(),
      CurrencyType.SOL,
      BigInt(5),
      GasFeeTxType.Swap,
    ),
  );

  const mergedCurrency = useMergedCurrency(editingBody, true);

  useEffect(() => {
    if (
      gasFeeSWR.content &&
      editingBody.content &&
      editingBody.content.gasFeeCurrency === undefined
    ) {
      editingBody.fill((prevData) => ({
        ...prevData,
        gasFeeCurrency: gasFeeSWR.content?.gasFee,
      }));
    }
  }, [gasFeeSWR.content, editingBody.content]);

  const paymentModal = useModal("payment-modal");

  const performTx = usePerformTx(
    async () => {
      const resp = await backend
        .performSwap(assetAccountId, editingBody.content)
        .run();
      return resp.orderId;
    },
    async (orderId: bigint | undefined) => {
      await closeModal(paymentModal);
      await editingBody.clear();
      if (orderId) {
        hopper.dismissLayerAndPush(`wallet/order/${orderId}`, {
          orderType: OrderType.OtherOrder,
          showDialog: true,
          title: i18n.clover_trade_successful(),
        });
      }
    },
  );

  return (
    <Page
      pageData={[tokenSWR, walletSWR, tokenPool, currencyListSWR]}
      loadStateConfig={{ emptyText: "i18n.circle_fin_no_transactions_yet()" }}
      underlay={
        <div
          style={{
            width: "100%",
            position: "absolute",
            top: -20,
            maskImage:
              "linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.2),  rgba(0, 0, 0, 0), rgba(0, 0, 0, 0))",
            opacity: 0.7,
          }}
        >
          <Image
            src={
              isValidCircleToken(tokenSWR.content?.tokenProject)
                ? [tokenSWR.content?.tokenProject?.currency.icon, "best"]
                : undefined
            }
            style={{
              width: "100%",
            }}
          />
        </div>
      }
    >
      <PaymentAccount
        highPriorityCurrencyId={tokenSWR.content?.tokenProject?.projectId}
        withSolBalance={true}
        fallbackCurrency={tokenSWR.content?.tokenProject?.currency}
      />
      {tokenSWR.content &&
        isValidCircleToken(tokenSWR.content?.tokenProject) && (
          <TradeGroup
            paymentModal={paymentModal}
            walletAccount={walletAccount}
            content={tokenSWR.content?.tokenProject}
            token={tokenSWR.content?.tokenProject?.currency}
            tradeCurrency={tokenSWR.content?.tokenProject?.currency}
            circleId={tokenSWR.content?.relatedCircleId}
            editingBody={editingBody}
            tokenPool={tokenPool?.content}
          />
        )}

      <VSpace height={200} />

      <TxSheet modal={paymentModal} performTx={performTx}>
        <CurrencyTransactionView body={editingBody.content} />
      </TxSheet>
    </Page>
  );
}

export function getWalletAccountName(
  walletAccount: WalletAccount | null | undefined,
  i18n: I18n,
) {
  if (walletAccount?.accountType === AccountType.BuiltIn) {
    return i18n.arthur_wallet_built_in_account();
  } else {
    return walletAccount?.name;
  }
}
