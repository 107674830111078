import {
  Animation,
  BottomSheet,
  Drawer,
  genBorder,
  ModalController,
  ModalControllerImpl,
  PopOverBg,
  requireModalController,
} from "../../components/Modal";
import { HStack, Spring, VStack, VStackMixin } from "../../components/VStack";
import icCircleCreate from "../../res/images/ic_circle_create.png";
import icPostCreate from "../../res/images/ic_post_create.png";
import icChatCreate from "../../res/images/ic_chat_create.png";
import icCollectableCreate from "../../res/images/ic_collectible_create.png";
import bgCircleCreate from "../../res/images/bg_circle_create.png";
import styled, { keyframes } from "styled-components";
import { usePageSpec } from "../common/usePageSpec";
import React, { useEffect, useRef } from "react";
import {
  FadeOut,
  None,
  ShrinkFadeOut,
  SlideDown,
  SlideUp,
} from "../../components/Keyframes";
import { useI18n } from "../../hooks/useI18n";
import {
  accent_font,
  alpha_on_pressed,
  button_on_pressed,
  cell_on_pressed,
} from "../../components/CommonStyles";
import { genBg } from "../../components/Buttons";
import { useObscuredZoneForKey } from "../../hooks/useObscuredZones";
import { useHopper } from "../../hooks/useHopper";
import icCompose from "../../res/images/ic_compose_selected.png";

export function CreateMenu(props: { modal: ModalController }) {
  requireModalController(props.modal);
  const pageSpec = usePageSpec();
  const i18n = useI18n();
  const [obscuringZone] = useObscuredZoneForKey("Browser");
  const hopper = useHopper();

  return (
    <Drawer {...props} isHorizontal={false}>
      <Spring />

      <CreateMenuBg
        onClick={(e) => {
          e.stopPropagation();
        }}
        $animation={props.modal.animation}
        onAnimationEnd={props.modal.onAnimationEnd}
        style={{ marginBottom: obscuringZone.bottom + 90 }}
      >
        <VStack style={{ margin: "0 20px" }}>
          <CircleCreate
            onClick={() => {
              props.modal.close(() => hopper.layer("create-circle-basic"));
            }}
          >
            <HStack
              style={{
                position: "absolute",
                padding: "12px 22px 0 8px",
                alignItems: "start",
                gap: 8,
              }}
            >
              <CreateIcon src={icCircleCreate} />
              <VStack style={{ flex: 1, marginTop: 6, gap: 4 }}>
                <CircleTitle>{i18n.circle()}</CircleTitle>
                <CircleCreateText>
                  {i18n.oct_impr_circle_detail()}
                </CircleCreateText>
              </VStack>
            </HStack>
          </CircleCreate>
          <HStack
            style={{
              width: "100%",
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              marginTop: 12,
              columnGap: 10,
            }}
          >
            <OtherCreateContainer
              onClick={() => {
                props.modal.close(() => hopper.layer("content-editor"));
              }}
            >
              <CreateIcon src={icPostCreate} />
              <OtherCreateTitle>{i18n.post()}</OtherCreateTitle>
            </OtherCreateContainer>
            <OtherCreateContainer
              onClick={() => {
                props.modal.close(() => hopper.modal("nyi"));
              }}
            >
              <CreateIcon src={icChatCreate} />
              <OtherCreateTitle>{i18n.chat()}</OtherCreateTitle>
            </OtherCreateContainer>
            <OtherCreateContainer
              onClick={() => {
                props.modal.close(() => hopper.modal("nyi"));
              }}
            >
              <CreateIcon src={icCollectableCreate} />
              <OtherCreateTitle>{i18n.web3_v0_nft()}</OtherCreateTitle>
            </OtherCreateContainer>
          </HStack>
        </VStack>
      </CreateMenuBg>
      <ComposeImg
        src={icCompose}
        style={{ bottom: obscuringZone.bottom + 8 }}
        $animation={props.modal.animation}
      />
    </Drawer>
  );
}

const CreateMenuBg = styled.div<{ $animation: Animation }>`
  width: 100%;
  height: auto;
  background: transparent;
  padding: 0 20px;
  animation: ${(p) =>
      p.$animation === Animation.None
        ? None
        : p.$animation === Animation.In
          ? SlideUp
          : FadeOut}
    0.3s ease-out 1 forwards;

  animation-fill-mode: both;
  overflow: hidden;
`;

const CircleCreate = styled.div`
  position: relative;
  padding-bottom: calc(118 / 335 * 100%);
  width: 100%;
  background: url(${bgCircleCreate}) no-repeat;
  background-size: 100%;
  ${alpha_on_pressed};
`;

const CreateIcon = styled.img`
  width: 50px;
  height: 50px;
`;

const CircleTitle = styled.span`
  ${accent_font(16)};
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  text-shadow: 0 0 12px white;
`;

const CircleCreateText = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: #ffffff66;
  word-break: break-all;
`;

const OtherCreateContainer = styled.div`
  background: ${genBg("white")} #2d2d2db2;
  border-image: ${genBorder("white", 12)};
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  padding: 12px 0 22px;
  gap: 13px;
  ${alpha_on_pressed};
`;

const OtherCreateTitle = styled.span`
  font-size: 10px;
  font-weight: 700;
  color: white;
  ${accent_font(10)};
  text-transform: uppercase;
`;

const ComposeImg = styled.img<{ $animation: Animation }>`
  width: 44px;
  height: 44px;
  position: absolute;
  animation: ${(p) =>
      p.$animation === Animation.None
        ? None
        : p.$animation === Animation.In
          ? RotateAnimation
          : RotateRecoverAnimation}
    0.2s ease-out forwards;
`;

const RotateAnimation = keyframes`
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(-45deg)
  }
`;

const RotateRecoverAnimation = keyframes`
    0% {
        transform: rotate(-45deg);
    }
    90% {
        transform: rotate(0);
    }
    100% {
        opacity: 0;
    }
`;
