// Referenced iOS clover_pwa code, powered by GPT-4o
// 字符串检查工具
export const StringValidator = {
  containsNumbers(str: string): boolean {
    // 使用 Array.some 遍历字符串，检查是否包含数字
    return str.split("").some((char) => char >= "0" && char <= "9");
  },

  containsLetters(str: string): boolean {
    // 使用 Array.some 检查是否包含字母（包括大小写）
    return str
      .split("")
      .some(
        (char) => (char >= "a" && char <= "z") || (char >= "A" && char <= "Z"),
      );
  },

  containsPunctuationMarks(str: string): boolean {
    const punctuation = "-_!\"#$%&'()*+,./:;<=>?@[\\]^`{|}~";
    // 使用 Array.some 检查是否包含标点符号
    return str.split("").some((char) => punctuation.includes(char));
  },

  isMinLength(str: string, length: number): boolean {
    return str.length >= length;
  },

  isPwdMinCharacters(str: string): boolean {
    return this.isMinLength(str, 8);
  },

  isValidPassword(str: string): boolean {
    return this.isMinLength(str, 6); // 密码长度大于等于6
  },

  isValidNewPassword(str: string): boolean {
    return this.isMinLength(str, 1); // 新密码长度大于等于1
  },

  isValidInvitationCode(str: string): boolean {
    return this.isMinLength(str, 6); // 邀请码长度大于等于6
  },

  isValidConfirmCode(str: string): boolean {
    return this.isMinLength(str, 6); // 确认码长度大于等于6
  },

  isAllDigits(str: string): boolean {
    // 遍历字符串，确保每个字符都是数字
    return str.split("").every((char) => char >= "0" && char <= "9");
  },
};
