import styled from "styled-components";

export interface CircleMenuDividerProps {
  title: string;
}

function CircleMenuDividerCell(props: CircleMenuDividerProps) {
  return (
    <div
      style={{
        display: "flex",
        paddingInlineStart: 20,
        height: 34,
        alignItems: "center",
        backgroundColor: "rgba(0,0,0,0.2)",
      }}
    >
      <Title>{props.title}</Title>
    </div>
  );
}

const Title = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: var(--color-text10);
`;

export default CircleMenuDividerCell;
