import { useMeasure } from "react-use";
import { useEffect, useRef } from "react";
import { UseMeasureRect, UseMeasureRef } from "react-use/lib/useMeasure";
import { pick } from "../utils/pick";

export function useManualLayout<
  C extends HTMLElement,
  E extends HTMLElement,
  P extends keyof UseMeasureRect,
>(
  keys: P[],
  layout: (cause: Pick<UseMeasureRect, P>, element: E) => void,
): [UseMeasureRef<C>, (e: E | null) => void] {
  const [causeRef, causeLayout] = useMeasure<C>();
  const elementRef = useRef<E>();
  const causeLayoutSub = pick(causeLayout, keys);
  useEffect(() => {
    if (elementRef.current) {
      layout(causeLayoutSub, elementRef.current);
    }
  }, Object.values(causeLayoutSub));

  return [
    causeRef,
    (e: E | null) => {
      if (e) {
        elementRef.current = e;
        layout(causeLayoutSub, e);
      }
    },
  ];
}
