import { Page } from "../../../components/Page";
import iconAllMembers from "../../../res/images/icon_all_members.svg";
import chevron from "../../../res/images/icon_section_header_show_more.svg";
import { NavMiddle, NavTitle } from "../../../components/NavBar";
import { useI18n } from "../../../hooks/useI18n";
import { useBackend } from "../../../service/APIService";
import { useSWRArray } from "../../../hooks/swr/useSWRArray";
import { useBigIntParam } from "../../../hooks/useTypedParam";
import { CommonUserCell } from "../../user/CommonUserCell";
import { PageHeader } from "../../../components/PageHeaderFooter";
import { HStack } from "../../../components/VStack";
import { css } from "styled-components";
import { Image } from "../../../components/Image";
import { useHopper } from "../../../hooks/useHopper";
import { alpha_on_pressed } from "../../../components/CommonStyles";
import { useBroadcast } from "../../../hooks/useBroadcast";
import { andLog } from "../../../components/handleError";

export function CircleActiveMembersPage() {
  const circleId = useBigIntParam("circleId");
  const backend = useBackend();
  const i18n = useI18n();
  const hopper = useHopper();
  const activeMembersSWR = useSWRArray(
    backend.getCircleActiveMembers(circleId),
  );
  useBroadcast(
    "ON_CIRCLE_TITLE_MEMBER_UPDATE",
    () => {
      activeMembersSWR.load().catch(andLog);
    },
    [activeMembersSWR],
  );
  const content = activeMembersSWR.content;
  return (
    <Page pageData={activeMembersSWR}>
      <NavMiddle>{i18n.active_members()}</NavMiddle>
      <PageHeader>
        <BlueSection
          onClick={() => {
            hopper.push(`circle/${circleId}/members`);
          }}
        />
      </PageHeader>
      {content &&
        content.map((e) => {
          return (
            <CommonUserCell
              showMembershipLevel={true}
              user={e}
              onUpdate={activeMembersSWR.load}
            />
          );
        })}
    </Page>
  );
}

function BlueSection(props: { onClick: () => void }) {
  const i18n = useI18n();
  return (
    <HStack
      onClick={() => props.onClick()}
      mixin={css`
        ${alpha_on_pressed};
        padding: 10px 16px 10px 16px;
        background: #34a6ff;
      `}
    >
      <Image src={iconAllMembers} width={26} height={26} />
      {i18n.circle_all_members()}
      <Image src={chevron} width={16} height={16} />
    </HStack>
  );
}
