import styled, { css } from "styled-components";
import { I18n, useI18n } from "../../hooks/useI18n";
import { HStack, VStack } from "../../components/VStack";
import { Image } from "../../components/Image";
import iconClose from "../../res/images/ic_close.svg";
import { TransferOrder } from "../../proto/TransferOrder";
import iconGift from "../../res/images/icon_wallet_gift.svg";
import { andLog } from "../../components/handleError";
import { timeIntervalString } from "../../utils/DateTimeUtils";

export enum WalletBannerType {
  TopUp = 1,
  Transfer = 2,
  UserMint = 3,
}

export interface WalletBannerCellProps {
  order: TransferOrder;
  count: number;
  type: WalletBannerType;
  onClickBanner: (type: WalletBannerType) => Promise<void>;
}

interface BannerBackgroundProps {
  type: WalletBannerType;
}

function getBannerCellTitle(i18n: I18n, type: WalletBannerType) {
  if (type === WalletBannerType.Transfer) {
    return i18n.web3_v0_gift_received();
  } else if (type === WalletBannerType.TopUp) {
    return i18n.web3_hongbao_top_up();
  } else if (type === WalletBannerType.UserMint) {
    return i18n.web3_ugc_minting_completed();
  }
  return;
}

function WalletBannerCell(props: WalletBannerCellProps) {
  const i18n = useI18n();
  const title = getBannerCellTitle(i18n, props.type);
  const isGift = props.type === WalletBannerType.Transfer;
  const date = new Date(props.order.createdTime * 1000);
  return (
    <BannerBackground
      type={props.type}
      onClick={() => {
        props.onClickBanner(props.type).catch(andLog);
      }}
    >
      <VStack>
        <Time>{timeIntervalString(date, i18n)}</Time>
        <HStack
          style={{
            gap: 2,
            marginTop: 10,
            marginBottom: 10,
            marginInlineStart: isGift ? 12 : 18,
          }}
        >
          {isGift && <Image src={iconGift} width={24} height={24} />}
          <Operation>{title}</Operation>
        </HStack>
      </VStack>

      <VStack style={{ alignItems: "end" }}>
        <Image
          src={iconClose}
          width={24}
          height={24}
          style={{ marginInlineEnd: 6, marginTop: 6 }}
        />
        <Count>{props.count}</Count>
      </VStack>
    </BannerBackground>
  );
}

const Time = styled.div`
  color: var(--color-text10);
  font-weight: 400;
  margin-top: 14px;
  margin-inline-start: 18px;
  font-size: 12px;
`;

const Count = styled.div`
  color: #ef3537;
  font-weight: 700;
  padding: 6px 8px;
  background-color: #ffffff;
  border-radius: 4px;
  margin-top: 4px;
  margin-inline-end: 14px;
  margin-bottom: 10px;
  font-size: 14px;
`;

const Operation = styled.div`
  color: var(--color-text00);
  font-weight: 700;
  font-size: 12px;
`;

const BannerBackground = styled.div<BannerBackgroundProps>`
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  margin-bottom: 12px;

  justify-content: space-between;
  ${({ type }) => {
    switch (type) {
      case WalletBannerType.Transfer:
        return css`
          background-color: #1c1139;
        `;
      case WalletBannerType.UserMint:
        return css`
          background-color: #072338;
        `;
      case WalletBannerType.TopUp:
        return css`
          background-color: #072338;
        `;
      default:
        return css`
          background-color: #1c1139;
        `; // 默认样式
    }
  }}
  ${({ type }) => {
    switch (type) {
      case WalletBannerType.Transfer:
        return css`
          border: 1px solid #8850ff;
        `;
      case WalletBannerType.UserMint:
        return css`
          border: 1px solid #00a5ff;
        `;
      case WalletBannerType.TopUp:
        return css`
          border: 1px solid #00a5ff;
        `;
      default:
        return css`
          border: 1px solid #8850ff;
        `; // 默认样式
    }
  }}
`;

export default WalletBannerCell;
