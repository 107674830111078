import { z } from "zod";
import { zBigInt, zEnum, zStatic } from "../utils/zodUtils";
import { ObjectType } from "./ObjectSpec";

export const LinkModel = z.object({
  objectId: zBigInt.optional(),
  objectType: zEnum(ObjectType).optional(),
  path: z.string().optional(),
  shareLink: z.string().optional(),
  inviteToken: z.string().optional(),
});

export type LinkModel = zStatic<typeof LinkModel>;
