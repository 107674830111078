import { useMemoryRepo } from "./swr/useLocalRepo";
import { useConsumerId, useReferId } from "./useHopper";
import { StateId } from "./StateId";
import { LoadState } from "./LoadState";
import { RepoDataSource } from "./swr/SWRRepo";
import { SetStateActionOptionalPrev } from "./Initializer";
import { ZodType } from "zod/lib/types";
import { useLayerRefer } from "../appshell/LayerBoundary";
import { assert } from "../utils/asserts";
import { zStatic } from "../utils/zodUtils";
import { useHopId } from "./useHopState";

export type ResultProducer =
  | "UserPicker"
  | "NFTPicker"
  | "TokenDesign"
  | "SendToken"
  | "SwapToken"
  | "SendGift"
  | "LaunchToken"
  | "PurchaseCircleCard"
  | "RedeemCircleCard"
  | "ShopTransfer"
  | "EarningTransfer"
  | "CircleMemberPicker"
  | "CircleTitlePicker"
  | "BindToken"
  | "CircleMembershipLevelPicker";

export function useProduceResult<T extends {}>(producer: ResultProducer) {
  const consumerId = useConsumerId();
  const repo = useMemoryRepo<T>([producer, consumerId]);
  return {
    result: repo.content,
    loadState: repo.loadState,
    meta: repo.meta,
    fill: repo.fill,
    clear: repo.clear,
  };
}

export function useConsumeResult<T extends {}>(
  producer: ResultProducer,
  consumerId: StateId,
): {
  result: T | undefined;
  loadState: LoadState | undefined;
  meta: { source: RepoDataSource; updatedAt: number };
  consumerId: StateId;
  fill: (contentOrSetContent: SetStateActionOptionalPrev<T>) => Promise<T>;
  clear: () => Promise<T>;
};
export function useConsumeResult<T extends {}>(
  producer: ResultProducer,
  consumerId: StateId | undefined,
):
  | {
      result: T | undefined;
      loadState: LoadState | undefined;
      meta: { source: RepoDataSource; updatedAt: number };
      consumerId: StateId;
      fill: (contentOrSetContent: SetStateActionOptionalPrev<T>) => Promise<T>;
      clear: () => Promise<T>;
    }
  | undefined;
export function useConsumeResult<T extends {}>(
  producer: ResultProducer,
  consumerId: StateId | undefined,
) {
  const repo = useMemoryRepo<T>(
    consumerId ? [producer, consumerId] : undefined,
  );
  if (repo === undefined || consumerId === undefined) return undefined;

  return {
    result: repo.content,
    loadState: repo.loadState,
    meta: repo.meta,
    consumerId: consumerId,
    fill: repo.fill,
    clear: repo.clear,
  };
}

export function createResultToken<S extends ZodType<any, any, any>>(
  schema: S,
  id: string,
) {
  return {
    schema: schema,
    id: id,
  };
}

export function useLayerProduceResult<S extends ZodType<any, any, any>>(
  token: ReturnType<typeof createResultToken<S>>,
) {
  const layerRefer = useLayerRefer();
  assert(layerRefer !== undefined);
  const repoId = [layerRefer.hopId, token.id];
  const repo = useMemoryRepo<zStatic<S>>(repoId);

  return {
    result: repo.content
      ? (token.schema.parse(repo.content) as zStatic<S>)
      : undefined,
    loadState: repo.loadState,
    meta: repo.meta,
    fill: repo.fill,
    clear: repo.clear,
  };
}

export function useV2ProduceResult<S extends ZodType<any, any, any>>(
  token: ReturnType<typeof createResultToken<S>>,
) {
  const referId = useReferId();
  assert(referId !== undefined);
  const repoId = [referId, token.id];
  const repo = useMemoryRepo<zStatic<S>>(repoId);

  return {
    result: repo.content
      ? (token.schema.parse(repo.content) as zStatic<S>)
      : undefined,
    loadState: repo.loadState,
    meta: repo.meta,
    fill: repo.fill,
    clear: repo.clear,
  };
}

export function useV2ConsumeResult<S extends ZodType<any, any, any>>(
  token: ReturnType<typeof createResultToken<S>>,
) {
  const hopId = useHopId();
  const repoId = [hopId, token.id];
  const repo = useMemoryRepo<zStatic<S>>(repoId);

  return {
    result: repo.content
      ? (token.schema.parse(repo.content) as zStatic<S>)
      : undefined,
    loadState: repo.loadState,
    meta: repo.meta,
    fill: repo.fill,
    clear: repo.clear,
  };
}
