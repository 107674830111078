import { HStack, Spring, VStack } from "../../../components/VStack";
import { StoreOrder, StoreOrderStatus } from "../../../proto/Order";
import { OrderNFTContainer } from "./PurchaseOrderDetail";
import { CellDivider, ContentGroup } from "../../../components/ContentGroup";
import { OrderInfoContent, OrderInfoLabel } from "./GiftOrderDetail";
import { Image } from "../../../components/Image";
import {
  formatMoney,
  getCurrencyAmountFromString,
  getCurrencyIcon,
  getCurrencyIconByCurrencyType,
} from "../../../proto/Currency";
import { useI18n } from "../../../hooks/useI18n";
import { StatusBar } from "./GiftOrderListPage";
import { formatDateWithOptions } from "../../../utils/DateTimeUtils";

export function SalesOrderDetail(props: { storeOrder: StoreOrder }) {
  const i18n = useI18n();
  const orderItem = props.storeOrder.orderItemList?.at(0);
  const orderItemAmount = props.storeOrder.orderAmountList?.at(0);
  const serviceFee = props.storeOrder.serviceFeeList?.at(0);

  function getStatus() {
    if (props.storeOrder.status === StoreOrderStatus.COMPLETED) {
      return { text: i18n.web3_v0_completed(), color: "#00FF94" };
    } else if (props.storeOrder.status === StoreOrderStatus.FAILED) {
      return { text: i18n.circle_anmt_failed(), color: "#EF3537FF" };
    } else return { text: "", color: "#FFFFFF66" };
  }

  const isCreatorFee = props.storeOrder.creatorFee !== undefined;

  return (
    <VStack style={{ width: "100%", gap: 20 }}>
      {orderItem && <OrderNFTContainer orderItem={orderItem} />}
      <ContentGroup style={{ width: "100%" }}>
        <HStack>
          <OrderInfoLabel>{i18n.web3_mint_coupon_total_price()}</OrderInfoLabel>
          <Spring />
          <HStack style={{ gap: 4 }}>
            <Image
              src={getCurrencyIconByCurrencyType(orderItemAmount?.currencyType)}
              style={{ width: 18, height: 18 }}
            />
            <OrderInfoContent>
              {formatMoney(
                "long",
                getCurrencyAmountFromString(orderItemAmount?.amount || "0"),
              )}
            </OrderInfoContent>
          </HStack>
        </HStack>
      </ContentGroup>

      <ContentGroup style={{ width: "100%", gap: 20 }}>
        <HStack>
          <OrderInfoLabel>{i18n.status()}</OrderInfoLabel>
          <Spring />
          <StatusBar
            statusColor={getStatus().color}
            statusText={getStatus().text}
          />
        </HStack>
        <CellDivider />
        <HStack>
          <OrderInfoLabel>
            {isCreatorFee ? i18n.web3_royalty_seller() : i18n.web3_v0_buyer()}
          </OrderInfoLabel>
          <Spring />
          <HStack style={{ gap: 4 }}>
            <Image
              src={[
                isCreatorFee
                  ? props.storeOrder.seller?.icon
                  : props.storeOrder.buyer?.icon,
                "best",
              ]}
              style={{ width: 18, height: 18, borderRadius: 4 }}
            />
            <OrderInfoContent>
              {isCreatorFee
                ? props.storeOrder.seller?.nickname
                : props.storeOrder.buyer?.nickname}
            </OrderInfoContent>
          </HStack>
        </HStack>
        {!isCreatorFee && (
          <>
            <CellDivider />
            <HStack>
              <OrderInfoLabel>{i18n.web3_v0_service_fee()}</OrderInfoLabel>
              <Spring />
              <HStack style={{ gap: 4 }}>
                <Image
                  src={getCurrencyIcon(serviceFee)}
                  style={{ width: 18, height: 18 }}
                />
                <OrderInfoContent>
                  {formatMoney("long", serviceFee)}
                </OrderInfoContent>
              </HStack>
            </HStack>
          </>
        )}
        <CellDivider />
        <HStack>
          <OrderInfoLabel>{i18n.web3_v0_total_proceeds()}</OrderInfoLabel>
          <Spring />
          <HStack style={{ gap: 4 }}>
            <Image
              src={getCurrencyIcon(
                isCreatorFee ? props.storeOrder.creatorFee : serviceFee,
              )}
              style={{ width: 18, height: 18 }}
            />
            <OrderInfoContent>
              {isCreatorFee
                ? formatMoney("long", props.storeOrder.creatorFee)
                : formatMoney(
                    "long",
                    getCurrencyAmountFromString(
                      (
                        BigInt(orderItemAmount?.amount || "0") -
                        BigInt(serviceFee?.amount || "0") -
                        BigInt(props.storeOrder.creatorFee?.amount || "0")
                      ).toString(),
                    ),
                  )}
            </OrderInfoContent>
          </HStack>
        </HStack>
        <CellDivider />
        <HStack>
          <OrderInfoLabel>{i18n.web3_v0_completed_time()}</OrderInfoLabel>
          <Spring />
          <OrderInfoContent>
            {formatDateWithOptions(props.storeOrder.paidTime || 0, {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })}
          </OrderInfoContent>
        </HStack>
        <CellDivider />
        <HStack>
          <OrderInfoLabel>{i18n.account()}</OrderInfoLabel>
          <Spring />
          <OrderInfoContent>{i18n.web3_v0_store_account()}</OrderInfoContent>
        </HStack>
        <CellDivider />
        <HStack>
          <OrderInfoLabel>{i18n.web3_v0_sales_id()}</OrderInfoLabel>
          <Spring />
          <OrderInfoContent>
            {props.storeOrder.orderId.toString()}
          </OrderInfoContent>
        </HStack>
      </ContentGroup>
    </VStack>
  );
}
