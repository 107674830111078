import { z } from "zod";
import { zBigInt, zEnum, zStatic } from "../utils/zodUtils";
import { ObjectType } from "./ObjectSpec";
import { ChatThreadType } from "./ChatThreadType";
import { Media } from "./Media";

export enum FlagTypeEnum {
  NO_SELECTED = -1,
  UNKNOW,
  SUICIDE_OR_SELF_HARM,
  ILLEGAL_ACTIVITIES,
  VIOLENT_AND_GRAPHIC_CONTENT,
  PORNOGRAPHY_AND_NUDITY,
  HATE_SPEECH,
  BULLYING_OR_HARASSMENT,
  SPAM_OR_TROLING,
  OTHERS = 100,
}

export enum FlagMediaLocationEnum {
  UNLISTED,
  CONTENT_IMAGE,
  DESCRIPTION_IMAGE,
  COVER_IMAGE,
  POST_COVER,
  PROFILE_PICTURE,
  BIO_IMAGE,
  BIO,
}

export const FlagExtension = z.object({
  threadId: zBigInt.optional(),
  chatThreadType: zEnum(ChatThreadType).optional(),
  link: z.string().optional(),
  parentId: zBigInt.optional(),
  parentType: z.number().optional(),
  flagMedia: Media.optional(),
  flagMediaLocation: zEnum(FlagMediaLocationEnum).optional(),
});

export const FlagBody = z.object({
  objectId: zBigInt.optional(),
  objectType: zEnum(ObjectType).optional(),
  flagType: zEnum(FlagTypeEnum),
  msg: z.string().optional(),
  extensions: FlagExtension.optional(),
  mediaList: z.array(Media).optional(),
});

export type FlagBody = zStatic<typeof FlagBody>;
