import { createContext, PropsWithChildren, useContext } from "react";

interface Context {
  kills: boolean;
}

const Context = createContext<Context>({
  kills: false,
});

export function PageDefaultBgKiller(props: PropsWithChildren<{}>) {
  return (
    <Context.Provider value={{ kills: true }}>
      {props.children}
    </Context.Provider>
  );
}

export function usePageDefaultBgKilled() {
  return useContext(Context).kills;
}
