import styled, { css } from "styled-components";

export const InfoLabel = styled.div`
  flex-shrink: 0;
  font-size: 16px;
  font-weight: 400;
  text-align: start;
  display: grid;
  align-items: center;
  color: var(--color-text00);
`;

export const InfoValue = styled.div`
  font-size: 16px;
  font-weight: 400;
  text-align: start;
  display: grid;
  align-items: center;
  margin-right: 8px;
  color: var(--color-text00);
`;
export const SingleInfo = styled.div`
  font-size: 16px;
  font-weight: 400;
  text-align: start;
  display: inline-block;
  align-items: center;
  margin-right: 8px;
  color: var(--color-text00);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const RoundCornerWhite08R6 = styled.div`
  background: rgba(255, 255, 255, 0.08);
  border-radius: 6px;
  height: 50px;
  display: flex; /* 添加 display: flex 以使 align-items 生效 */
  padding: 6px 10px 6px 10px;
  align-items: center;
`;

export const StrokeWhite10R6 = styled.div`
  background: transparent; /* 背景色透明 */
  border: 1px solid rgba(255, 255, 255, 0.1); /* 边框颜色是white10% */
  border-radius: 6px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center; /* 让子元素垂直居中 */
  align-items: stretch;
  padding: 6px 10px; /* 调整 padding 以保持样式 */
  box-sizing: border-box;
`;

export const SingleLineUnspecifiedWidth = css`
  overflow: hidden;
  line-clamp: 1;
  text-overflow: ellipsis;
  word-break: break-all;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const TwoLineUnspecifiedWidth = css`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* 设置为 2 行 */
  text-overflow: ellipsis;
`;
