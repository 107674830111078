import { Page } from "../../../components/Page";
import { useSWRArray } from "../../../hooks/swr/useSWRArray";
import { useBackend } from "../../../service/APIService";
import {
  StoreOrder,
  StoreOrderStatus,
  StoreOrderType,
} from "../../../proto/Order";
import {
  OrderCellContainer,
  OrderCreatedTime,
  OrderDivider,
  OrderNameText,
  OrderSenderName,
  StatusBar,
} from "./GiftOrderListPage";
import { HStack, VStack } from "../../../components/VStack";
import { Image } from "../../../components/Image";
import { formatDateWithOptions } from "../../../utils/DateTimeUtils";
import { useI18n } from "../../../hooks/useI18n";
import {
  formatMoney,
  getCurrencyAmountFromString,
  getCurrencyIcon,
  getCurrencyIconByCurrencyType,
} from "../../../proto/Currency";
import styled from "styled-components";
import BigNumber from "bignumber.js";
import { NavMiddle } from "../../../components/NavBar";
import { useHopper } from "../../../hooks/useHopper";
import { OrderType } from "./OrderDetailPage";
import { CellDivider } from "../../../components/ContentGroup";
import { MerchType } from "../../../proto/Store";

export function MarketplaceOrderListPage() {
  const backend = useBackend();
  const i18n = useI18n();
  const storeOrderSWR = useSWRArray(
    backend.getMarketOrderList(StoreOrderType.PURCHASE_ORDER_HISTORY),
  );

  return (
    <Page pageData={storeOrderSWR}>
      {storeOrderSWR.content.map((storeOrder) => (
        <MarketPlaceOrderCell
          storeOrder={storeOrder}
          type={StoreOrderType.PURCHASE_ORDER_HISTORY}
        />
      ))}
      <NavMiddle>{i18n.web3_v0_order_history()}</NavMiddle>
    </Page>
  );
}

export function MarketPlaceOrderCell(props: {
  storeOrder: StoreOrder;
  type: number;
}) {
  const i18n = useI18n();
  const hopper = useHopper();

  function getStatus() {
    if (props.storeOrder.status === StoreOrderStatus.COMPLETED) {
      return { text: i18n.web3_v0_completed(), color: "#00FF94" };
    } else if (props.storeOrder.status === StoreOrderStatus.FAILED) {
      return { text: i18n.circle_anmt_failed(), color: "#EF3537FF" };
    } else return { text: "", color: "#FFFFFF66" };
  }

  const orderItem = props.storeOrder.orderItemList?.at(0);
  const orderItemAmount = props.storeOrder.orderAmountList?.at(0);
  const isFree =
    orderItem?.extensions?.merchType === MerchType.Free &&
    props.type !== StoreOrderType.CREATOR_FEE_HISTORY;
  return (
    <OrderCellContainer
      onClick={() =>
        hopper.push(`wallet/order/${props.storeOrder.orderId}`, {
          orderType: OrderType.StoreOrder,
        })
      }
    >
      <HStack style={{ gap: 6 }}>
        <Image
          src={[
            props.type === StoreOrderType.PURCHASE_ORDER_HISTORY
              ? props.storeOrder?.store?.icon
              : props.storeOrder.buyer?.icon,
            { width: 20, height: 20 },
          ]}
          style={{ borderRadius: "50%" }}
          width={20}
          height={20}
        />
        <OrderSenderName>
          {props.type === StoreOrderType.PURCHASE_ORDER_HISTORY
            ? props.storeOrder?.store?.name
            : props.storeOrder.buyer?.nickname}
        </OrderSenderName>
        {getStatus().text.length > 0 && (
          <StatusBar
            statusColor={getStatus().color}
            statusText={getStatus().text}
          />
        )}
      </HStack>
      <OrderDivider />
      <HStack style={{ gap: 12, alignItems: "start", width: "100%" }}>
        <Image
          src={[orderItem?.cover, { width: 74, height: 74 }]}
          style={{ borderRadius: 6 }}
          width={74}
          height={74}
        />
        <VStack style={{ flex: 1 }}>
          <OrderNameText>{orderItem?.title}</OrderNameText>
          <HStack style={{ flex: 1, marginTop: 8, gap: 4 }}>
            {!isFree && (
              <Image
                src={getCurrencyIconByCurrencyType(orderItem?.currencyType)}
                width={16}
                height={16}
              />
            )}
            <HStack style={{ alignItems: "baseline", gap: 4 }}>
              {!isFree && orderItem?.price && (
                <AmountText>
                  {formatMoney(
                    "medium",
                    getCurrencyAmountFromString(orderItem?.price),
                  )}
                </AmountText>
              )}
              {isFree && <AmountText>{i18n.may23_impr_free()}</AmountText>}
              <CountText>×{orderItem?.count}</CountText>
            </HStack>
          </HStack>
        </VStack>
      </HStack>
      <HStack style={{ width: "100%" }}>
        <TotalLabel>
          {props.type === StoreOrderType.CREATOR_FEE_HISTORY
            ? i18n.web3_royalty_creator_fee()
            : i18n.web3_v0_total_amount()}
        </TotalLabel>
        {isFree ? (
          <TotalCurrency>{i18n.may23_impr_free()}</TotalCurrency>
        ) : (
          <HStack style={{ gap: 4 }}>
            <Image
              src={getCurrencyIconByCurrencyType(
                props.type === StoreOrderType.CREATOR_FEE_HISTORY
                  ? props.storeOrder.creatorFee?.currencyType
                  : orderItem?.currencyType,
              )}
              width={20}
              height={20}
            />
            {orderItemAmount?.amount && (
              <TotalCurrency>
                {props.type === StoreOrderType.CREATOR_FEE_HISTORY
                  ? formatMoney("medium", props.storeOrder.creatorFee)
                  : formatMoney(
                      "medium",
                      getCurrencyAmountFromString(orderItemAmount.amount),
                    )}
              </TotalCurrency>
            )}
          </HStack>
        )}
      </HStack>
    </OrderCellContainer>
  );
}

const AmountText = styled.div`
  color: #ffffffcc;
  font-weight: 700;
  font-size: 16px;
`;

export const CountText = styled.div`
  font-size: 11px;
  font-weight: 400;
  color: #ffffffcc;
`;

export const TotalLabel = styled.div`
  font-size: 11px;
  font-weight: 400;
  color: white;
  flex: 1;
`;

export const TotalCurrency = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: white;
`;
