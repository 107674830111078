import { useI18n, useIsRTL } from "../../hooks/useI18n";
import { Page } from "../../components/Page";
import React, { useState } from "react";
import { PhoneNumberInput, RegularInputGroup } from "../../components/Input";
import { VSpace } from "../../components/VStack";
import { RegularLargeButton } from "../../components/Buttons";
import { useHopper } from "../../hooks/useHopper";
import { AuthBody, AuthType } from "../../proto/Auth";
import { andLog, useErrorHandler } from "../../components/handleError";
import { useLoadState } from "../../hooks/LoadState";
import { Spin } from "../../components/Spin";
import { NavEnd, NavItem, NavMiddle } from "../../components/NavBar";
import { SetPasswordType } from "./SetPasswordPage";
import { useBackend } from "../../service/APIService";
import { useAuthSessionService } from "../../service/AuthSessionService";
import { ForgotPasswordLabel } from "./EmailLogInPage";
import { BSMenu, BSMenuItem } from "../../components/BSMenu";
import { allRegions, Region } from "../../proto/Region";
import { useModal } from "../../components/Modal";
import { isRejectable } from "../../bridge/Rejectable";
import { APIErrorCode } from "../../proto/APIErrorRespBody";
import { useNativePage } from "../../hooks/useBridge";

export function PhoneNumberLogInPage() {
  const i18n = useI18n();
  const backend = useBackend();
  const hopper = useHopper();
  const handleError = useErrorHandler();
  const nativePage = useNativePage();

  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");

  const authSessionService = useAuthSessionService();

  const loadTask = useLoadState();

  async function doLogIn(
    authBody: AuthBody,
    requestToBeReactivated: boolean = false,
  ) {
    const r = await loadTask.run(async () => {
      const auth = await backend
        .logIn({
          ...authBody,
          ignoresDisabled: true,
          requestToBeReactivated: requestToBeReactivated,
        })
        .run();
      await authSessionService.add(
        {
          uid: auth.account.uid,
          sid: auth.sId,
          ptoken: auth.secret,
        },
        true,
      );
    });

    if (!r.success) {
      const e = r.error;
      if (
        isRejectable(e) &&
        e.apiCode === APIErrorCode.APICodeAccountDeletedCanBeReactivated &&
        !requestToBeReactivated
      ) {
        const reactivate = await nativePage.alertYesOrCancel(
          i18n.account_reactivate_message(),
          i18n.reactivate(),
          i18n.cancel(),
        );
        if (reactivate) {
          await doLogIn(authBody, true);
        }
      } else {
        handleError(e);
      }
    }
  }

  function logIn() {
    if (phoneNumber && password) {
      doLogIn({
        authType: AuthType.Phone,
        phoneNumber: region?.phoneCodeWithMark + phoneNumber,
        password: password,
      }).catch(andLog);
    }
  }

  const isRTL = useIsRTL();
  const regionMenu = useModal("regionMenu");
  const locale = new Intl.Locale(navigator.language);
  const [region, setRegion] = React.useState<Region | null>(
    Region.from(locale.region || "US"),
  );

  return (
    <Page pageData={undefined}>
      <NavMiddle>{i18n.auth_log_in()}</NavMiddle>
      <NavEnd>
        {NavItem.text(i18n.help(), () => hopper.push("feedback"))}
      </NavEnd>
      <PhoneNumberInput
        placeholder={i18n.auth_phone_number()}
        value={phoneNumber}
        updateValue={setPhoneNumber}
        regionCode={region?.phoneCodeWithMark}
        regionCodeOnClick={() => regionMenu.open()}
      />
      <VSpace height={20} />
      <RegularInputGroup
        type={"password"}
        value={password}
        placeholder={i18n.password()}
        updateValue={setPassword}
      />
      <VSpace height={40} />
      <RegularLargeButton disabled={!phoneNumber || !password} onClick={logIn}>
        <Spin state={loadTask.state}>{i18n.auth_log_in()}</Spin>
      </RegularLargeButton>
      <ForgotPasswordLabel
        onClick={() =>
          hopper.layer("verify-account", {
            authType: AuthType.Phone,
            setPasswordType: SetPasswordType.CreateANew,
          })
        }
      >
        {i18n.auth_forgot_password()}
      </ForgotPasswordLabel>
      <BSMenu modal={regionMenu}>
        {allRegions.map((region) => (
          <BSMenuItem
            title={isRTL ? region.rtlDes : region.des}
            trailingItem={region.phoneCodeWithMark}
            onClick={() => {
              regionMenu.close();
              setRegion(region);
            }}
          ></BSMenuItem>
        ))}
      </BSMenu>
    </Page>
  );
}
