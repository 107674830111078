import { z } from "zod";
import { zBigInt, zEnum, zStatic } from "../utils/zodUtils";
import { Media } from "./Media";
import { CircleMemberTitle } from "./CircleMemberTitle";
import { Tag } from "./Tag";
import { I18n } from "../hooks/useI18n";
import { Sticker } from "./Reaction";
import { SubscriptionSettings } from "./SubscriptionSettings";
import { UserVisitLog } from "./UserVisitLog";
import { ChatMemberStatus } from "./ChatMember";
import {
  FollowStatus,
  Gender,
  OnlineStatus,
  UserMoodType,
  ZodiacType,
} from "./UserEnums";
import { RichFormat } from "../components/zdoc/RichFormat";
import { ObjectStatus } from "./ObjectStatus";

export enum MarkType {}

// PARTNER = 1,

export enum CircleRole {
  Admin = 1,
  User = 2,
  CoAdmin = 3,
}

export enum MembershipStatus {
  Prime = 1,
  PrimePlus = 2,
  Off = 100,
}

export enum SpecialTitle {
  Official = 2,
}

export const UserExtensions = z.object({
  verifiedMark: zEnum(MarkType).optional(),
  genderModified: z.boolean().optional(),
  showNSFWContent: z.boolean().optional(),
  showMyTreasures: z.boolean().optional(),
  showRecentVisitor: z.boolean().optional(),
  isCloverUser: z.boolean().optional(),
  nicknameColor: z.string().optional(),
});

export type UserExtensions = zStatic<typeof UserExtensions>;

export const UserVisitorInfo = z.object({
  totalViewCount: z.number().optional(),
});

export function genderName(i18n: I18n, type: Gender) {
  switch (type) {
    case Gender.man:
      return i18n.male();
    case Gender.woman:
      return i18n.female();
    case Gender.secret:
      return i18n.non_binary();
  }
}

export const UserMood = z.object({
  text: z.string().optional(),
  stickerId: zBigInt.optional(),
  sticker: Sticker.optional(),
  onlineStatus: zEnum(OnlineStatus).optional(),
  offlineSetTime: z.number().optional(),
  doNotDisturbDuration: z.number().optional(),
  type: zEnum(UserMoodType).optional(),
});

export type UserMood = zStatic<typeof UserMood>;

export enum ChatInvitationStatusType {
  Everyone = 1,
  Following = 2,
  Friend = 3,
  NoOne = 4,
}

export const MyMoodsList = z.object({
  customMoods: z.array(UserMood),
  defaultMoods: z.array(UserMood),
});

export type MyMoodsList = zStatic<typeof MyMoodsList>;

export const User = z.object({
  uid: zBigInt,
  nickname: z.string().optional(),
  icon: Media.optional(),
  userMood: UserMood.optional(),
  background: Media.optional(),
  backgroundColor: z.string().optional(),
  socialId: z.string().optional(),
  circleContribution: z.number().optional(),
  level: z.number().optional(),
  experience: z.number().optional(),
  upgradeExperience: z.number().optional(),
  gender: zEnum(Gender).optional(),
  managerAbsencesDays: z.number().optional(),
  tagline: z.string().optional(),
  lastActiveTime: z.number().optional(),
  extensions: UserExtensions.optional(),
  circleContributionRank: z.number().optional(),
  circleMemberTitleList: z.array(CircleMemberTitle).optional(),
  maxLevel: z.number().optional(),
  activeTime: z.number().optional(),
  status: zEnum(ObjectStatus).optional(),
  createdTime: z.number().optional(),
  fansCount: z.number().optional(),
  followingCount: z.number().optional(),
  followedByMeStatusV2: z.number().optional(),
  tagList: Tag.array().optional(),
  bio: z.string().optional(),
  richFormat: RichFormat.optional(),
  mediaList: z.array(Media).optional(),
  school: z.string().optional(),
  friendsCount: z.number().optional(),
  userVisitorInfo: UserVisitorInfo.optional(),
  birthday: z.string().optional(),
  countryCode: z.string().optional(),
  blogsCount: z.number().optional(),
  voiceBio: Media.optional(),
  notification: SubscriptionSettings.optional(),
  remark: z.string().optional(),
  followMeStatus: z.number().optional(),
  userVisitLog: UserVisitLog.optional(),
  zodiacType: zEnum(ZodiacType).optional(),
  chatMemberStatus: zEnum(ChatMemberStatus).optional(),
  publicChatInvitationStatus: zEnum(ChatInvitationStatusType).optional(),
  privateChatInvitationStatus: zEnum(ChatInvitationStatusType).optional(),
  commentsCount: z.number().optional(),
  circleMembershipLevel: z.number().optional(),
  circleRole: zEnum(CircleRole).optional(),
  membershipStatus: zEnum(MembershipStatus).optional(),
  specialTitle: zEnum(SpecialTitle).optional(),
});

export type User = zStatic<typeof User>;

export const subscribeOrNot = (user: User) => {
  return (
    user.notification?.receiveNewFollower === true ||
    user.notification?.receiveUserUpdate === true ||
    user.notification?.receiveWallComment === true ||
    user.notification?.receiveWallOnlyMyComment === true
  );
};

export const ContributionRecord = z.object({
  user: User,
  contribution: z.number().catch(0),
  rank: z.number().optional(),
});

export type ContributionRecord = zStatic<typeof ContributionRecord>;

export function getUserActiveInfo(i18n: I18n, user?: User) {
  if (user?.lastActiveTime === undefined) {
    return undefined;
  }
  const now = new Date().getTime();
  const timeOffset = now / 1000 - user.lastActiveTime;
  if (timeOffset > 3 * 24 * 3600) {
    return undefined;
  }
  if (timeOffset > 24 * 3600) {
    const value = Math.floor((timeOffset / 24) * 3600);
    return i18n.plural(value).user_active_day(value);
  }
  if (timeOffset > 3600) {
    const value = Math.floor(timeOffset / 3600);
    return i18n.plural(value).user_active_hour(value);
  }
  if (timeOffset > 15 * 60) {
    const value = Math.floor(timeOffset / 900);
    return i18n.user_active_minutes_ago(value);
  }
  return i18n.user_active_now();
}

export function isUserActiveInOneDay(user?: User) {
  if (user?.lastActiveTime === undefined) {
    return false;
  }
  const now = new Date().getTime();
  const timeOffset = now / 1000 - user.lastActiveTime;
  return timeOffset < 24 * 3600;
}

export function isFriendWith(user: User) {
  return isMyFollower(user) && amIFollowing(user);
}

export function isMyFollower(user: User) {
  return (
    user.followMeStatus === FollowStatus.followed ||
    user.followMeStatus === FollowStatus.favorites
  );
}

export function amIFollowing(user: User) {
  return (
    user.followedByMeStatusV2 === FollowStatus.followed ||
    user.followedByMeStatusV2 === FollowStatus.favorites
  );
}

export function amIFavoriting(user: User) {
  return user.followedByMeStatusV2 === FollowStatus.favorites;
}

export function getUserDisplayName(user: User) {
  return user.nickname ?? "-";
}
