import { useBigIntParam } from "../../../hooks/useTypedParam";
import { useBackend } from "../../../service/APIService";
import { useSWR } from "../../../hooks/swr/useSWR";
import { Page } from "../../../components/Page";
import SettingCell from "../../../components/SettingCell";
import { useI18n } from "../../../hooks/useI18n";
import ChatBackgroundSmallCard from "../components/ChatBackgroundSmallCard";
import styled from "styled-components";
import { Image } from "../../../components/Image";
import { NavMiddle } from "../../../components/NavBar";

const CoverIcon = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.2);
`;

export default function ChatSettingsAppearancePage() {
  const threadId = useBigIntParam("threadId");
  const backend = useBackend();
  const i18n = useI18n();

  const thread = useSWR(["thread-info", threadId], () =>
    backend.getThreadInfo(threadId, false).run(),
  );
  return (
    <Page pageData={thread} scrollPaddingDisabled={true}>
      <NavMiddle>{i18n.circle2_1_appearance()}</NavMiddle>
      <SettingCell
        title={i18n.cover_image()}
        endValue={
          <Image src={[thread.content?.icon, 30]} styledImg={CoverIcon} />
        }
        onClick={() => {}}
      />
      <SettingCell
        title={i18n.background_image()}
        endValue={
          thread.content ? (
            <ChatBackgroundSmallCard chat={thread.content} />
          ) : (
            <></>
          )
        }
        onClick={() => {}}
      />
    </Page>
  );
}
