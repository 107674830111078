import { z } from "zod";
import { zBigInt, zStatic } from "../utils/zodUtils";
import { Media } from "./Media";
import { CircleToken } from "./CircleFin";
import { TokenProject } from "./TokenProject";

export const WrappedCircleToken = z.object({
  tokenProject: TokenProject,
  relatedCircleId: zBigInt,
});

export type WrappedCircleToken = zStatic<typeof WrappedCircleToken>;
