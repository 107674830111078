import { Page } from "../../components/Page";
import { NavEnd, NavItem } from "../../components/NavBar";
import React, { useState } from "react";
import { AuthTitleLabel } from "./InvitationCodePage";
import { useI18n } from "../../hooks/useI18n";
import { useHopper } from "../../hooks/useHopper";
import styled from "styled-components";
import { Image } from "../../components/Image";
import IcCamera from "../../res/images/icon_camera.svg";
import IcEdit from "../../res/images/ic_edit.svg";
import { alpha_on_pressed } from "../../components/CommonStyles";
import { FilePicker } from "../../components/FilePicker";
import { useHopState } from "../../hooks/useHopState";
import { Media } from "../../proto/Media";
import { isLocalMedia, LocalMedia } from "../../bridge/LocalMedia";
import { Spin } from "../../components/Spin";
import { RegularLargeButton } from "../../components/Buttons";
import { useLoadState } from "../../hooks/LoadState";
import { useBackend } from "../../service/APIService";
import { useErrorHandler } from "../../components/handleError";
import { useStringSearchParam } from "../../hooks/useTypedParam";
import { useMemoryRepo } from "../../hooks/swr/useLocalRepo";
import { AuthBody } from "../../proto/Auth";

const ProfilePlaceHolder = styled.div`
  position: relative;
  border: 1px dashed rgba(255, 255, 255, 0.2);
  background-color: rgba(255, 255, 255, 0.08);
  width: 114px;
  height: 114px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 57px;

  display: flex;
  align-items: center;
  justify-content: center;

  ${alpha_on_pressed};
`;

const EditHolder = styled.div`
  position: absolute;
  right: -2px;
  bottom: -2px;
  background-color: #707070;
  border-radius: 50%;
  width: 36px;
  height: 36px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const PickedIcon = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
`;

export function UploadProfileImagePage() {
  const i18n = useI18n();
  const hopper = useHopper();
  const backend = useBackend();
  const handleError = useErrorHandler();

  const flowId = useStringSearchParam("f");
  const authInfo = useMemoryRepo<AuthBody>(flowId ?? "", {});

  const [pendingMedia, setPendingMedia] = useHopState<
    Media | LocalMedia | undefined
  >("pending-auth-profile");

  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);
  const loadTask = useLoadState();

  async function onNextButtonClick() {
    let serverMedia: Media;
    if (pendingMedia) {
      if (isLocalMedia(pendingMedia)) {
        const r = await loadTask.run(async () => {
          serverMedia = await backend.sendLocalMedia(
            pendingMedia,
            "nft_thumbnail",
            (a, b) => {},
          );
          setPendingMedia(serverMedia);
        });
        if (!r.success) {
          handleError(r.error);
          return;
        }
      } else {
        serverMedia = pendingMedia;
      }

      await authInfo.fill((prev) => ({
        ...prev,
        icon: serverMedia,
      }));
      hopper.push("enter-nickname", { f: flowId });
    }
  }

  return (
    <Page pageData={undefined}>
      <NavEnd>
        {NavItem.text(i18n.help(), () => hopper.push("feedback"))}
      </NavEnd>
      <AuthTitleLabel>
        {i18n.signup_impr_upload_your_profile_image_hint()}
      </AuthTitleLabel>
      <FilePicker
        accept={"image/*"}
        onPick={(media) => {
          setPendingMedia(media);
          setNextButtonDisabled(false);
        }}
      >
        <ProfilePlaceHolder>
          {pendingMedia ? (
            <Image src={[pendingMedia, 114]} styledImg={PickedIcon} />
          ) : (
            <Image src={IcCamera} style={{ width: 58 }} />
          )}
          <EditHolder>
            <Image src={IcEdit} />
          </EditHolder>
        </ProfilePlaceHolder>
      </FilePicker>

      <RegularLargeButton
        disabled={nextButtonDisabled}
        style={{ height: "54px", margin: "90px 50px 0" }}
        onClick={onNextButtonClick}
      >
        <Spin state={loadTask.state}>{i18n.next()}</Spin>
      </RegularLargeButton>
    </Page>
  );
}
