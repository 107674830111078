import React from "react";
import { Image } from "../../components/Image";
import icSol from "../../res/images/ic_sol.svg";
import { Media } from "../../proto/Media";
import { HStack, VSpace } from "../../components/VStack";
import styled from "styled-components";
import { DemoNote } from "./DemoCell";
import { Page } from "../../components/Page";

const OverlayStyledImg = styled.img`
  border: solid 3px white;
  border-radius: 20px;
  width: 200px;
  height: 100px;
  box-sizing: border-box;
  object-fit: contain;
  background: rgba(255, 255, 255, 0.06);
`;

export function ImageViewDemoPage() {
  const media: Media = {
    mediaId: BigInt(0),
    baseUrl: "",
    resourceList: [
      {
        width: 200,
        height: 200,
        url: "http://um1.hypserspaces.com/2989/1802908384629932032-v2-r512x512-s512x512.webp",
      },
      {
        width: 68,
        height: 68,
        url: "http://um1.hypserspaces.com/2989/1802908384629932032-v2-r512x512-s68x68.webp",
      },
    ],
    nsfw: false,
  };

  return (
    <Page pageData={undefined}>
      <DemoNote>General</DemoNote>
      <HStack style={{ gap: 8 }}>
        <Image src={icSol} width={20} height={20} />
        <Image src={icSol} />
        <Image src={icSol + "1"} width={100} height={100} />
      </HStack>

      <DemoNote>Center in Stretch Parent</DemoNote>
      <Image
        src={[media, "best"]}
        width={220}
        height={120}
        style={{ margin: "0 auto" }}
      />

      <DemoNote>Custom styled-img</DemoNote>
      <Image src={[media, "best"]} styledImg={OverlayStyledImg} />

      <DemoNote>GIF</DemoNote>
      <Image
        width={300}
        height={200}
        src={
          "https://cm1.hypserspaces.com/2989/1803014884345798656-v2-r200x200-s200x200.webp"
        }
      />

      <DemoNote>object-fit (cover, contain, fill)</DemoNote>
      <HStack style={{ gap: 8 }}>
        <Image
          src={
            "https://files.smashing.media/articles/css-border-image-property/1-illustration-slice-width-outset-values.png"
          }
          style={{ width: 100, height: 100, borderRadius: 8 }}
        />

        <Image
          src={
            "https://files.smashing.media/articles/css-border-image-property/1-illustration-slice-width-outset-values.png"
          }
          style={{
            width: 100,
            height: 100,
            borderRadius: 8,
            objectFit: "contain",
          }}
        />

        <Image
          src={
            "https://files.smashing.media/articles/css-border-image-property/1-illustration-slice-width-outset-values.png"
          }
          style={{
            width: 100,
            height: 100,
            borderRadius: 8,
            objectFit: "fill",
          }}
        />
      </HStack>

      <DemoNote>MediaSizeMode</DemoNote>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          alignItems: "center",
          width: "100%",
          height: "auto",
          gap: 12,
        }}
      >
        <Image
          src={[media, 30]}
          style={{ objectFit: "cover", width: "100%" }}
        />
        <Image
          src={[media, 200]}
          style={{ objectFit: "cover", width: "100%", borderRadius: "50%" }}
        />
        <Image
          src={[media, "best"]}
          style={{ objectFit: "cover", width: "100%" }}
        />
        <Image
          src={[media, "smallest"]}
          style={{ objectFit: "cover", width: "100%" }}
        />
      </div>

      <VSpace height={12} />
    </Page>
  );
}
