interface LanguageCodes {
  languageNamesDict: {
    [key: string]: { [key: string]: string };
  };
  supportedLanguageCodes: Set<string>;
  localizedStringForCurrentAppLanguage(languageCode?: string): string | null;
  currentLanguageCodeInSupportList(): string;
}

const LanguageCodes: LanguageCodes = {
  languageNamesDict: {
    en: {
      ko: "영어",
      he: "אנגלית",
      id: "Inggris",
      "en-AU": "English",
      fi: "Englanti",
      tr: "İngilizce",
      nl: "Engels",
      hu: "Angol",
      "pt-BR": "Inglês",
      it: "Inglese",
      ru: "Английский",
      el: "Αγγλικά",
      ca: "Anglès",
      ar: "الإنجليزية",
      sv: "Engelska",
      hi: "अंग्रेज़ी",
      pl: "Angielski",
      da: "Engelsk",
      en: "English",
      "zh-Hant": "英文",
      nb: "Engelsk",
      "en-IN": "English",
      th: "อังกฤษ",
      ms: "Inggeris",
      cs: "Angličtina",
      sk: "Angličtina",
      "zh-Hans": "英语",
      de: "Englisch",
      "en-CA": "English",
      "es-MX": "Inglés",
      vi: "Tiếng Anh",
      "fr-CA": "Anglais",
      es: "Inglés",
      uk: "Англійська",
      fr: "Anglais",
      "zh-HK": "英文",
      ro: "Engleză",
      pt: "Inglês",
      "en-GB": "English",
      ja: "英語",
      hr: "Engleski",
      my: "အင်္ဂလိပ်",
    },
    fr: {
      ko: "프랑스어",
      he: "צרפתית",
      id: "Prancis",
      en: "French",
      fi: "Ranska",
      tr: "Fransızca",
      nl: "Frans",
      hu: "Francia",
      "pt-BR": "Francês",
      it: "Francese",
      ru: "Французский",
      el: "Γαλλικά",
      ca: "Francès",
      ar: "الفرنسية",
      sv: "Franska",
      hi: "फ़्रेंच",
      pl: "Francuski",
      da: "Fransk",
      "zh-Hant": "法文",
      nb: "Fransk",
      "en-IN": "French",
      th: "ฝรั่งเศส",
      ms: "Perancis",
      cs: "Francouzština",
      sk: "Francúzština",
      "zh-Hans": "法语",
      de: "Französisch",
      "en-CA": "French",
      "es-MX": "Francés",
      vi: "Tiếng Pháp",
      "fr-CA": "Français",
      es: "Francés",
      uk: "Французька",
      "zh-HK": "法文",
      ro: "Franceză",
      pt: "Francês",
      "en-GB": "French",
      ja: "フランス語",
      hr: "Francuski",
      my: "ပြင်သစ်",
    },
    es: {
      ko: "스페인어",
      he: "ספרדית",
      id: "Spanyol",
      en: "Spanish",
      fi: "Espanja",
      tr: "İspanyolca",
      nl: "Spaans",
      hu: "Spanyol",
      "pt-BR": "Espanhol",
      it: "Spagnolo",
      ru: "Испанский",
      el: "Ισπανικά",
      ca: "Espanyol",
      ar: "الإسبانية",
      sv: "Spanska",
      hi: "स्पेनी",
      pl: "Hiszpański",
      da: "Spansk",
      "zh-Hant": "西班牙文",
      nb: "Spansk",
      "en-IN": "Spanish",
      th: "สเปน",
      ms: "Sepanyol",
      cs: "Španělština",
      sk: "Španielčina",
      "zh-Hans": "西班牙语",
      de: "Spanisch",
      "en-CA": "Spanish",
      "es-MX": "Español",
      vi: "Tiếng Tây Ban Nha",
      "fr-CA": "Espagnol",
      es: "Español",
      uk: "Іспанська",
      "zh-HK": "西班牙文",
      ro: "Spaniolă",
      pt: "Espanhol",
      "en-GB": "Spanish",
      ja: "スペイン語",
      hr: "Španjolski",
      my: "စပိန်",
    },
    pt: {
      ko: "포르투갈어",
      he: "פורטוגזית",
      id: "Portugis",
      en: "Portuguese",
      fi: "Portugali",
      tr: "Portekizce",
      nl: "Portugees",
      hu: "Portugál",
      "pt-BR": "Português",
      it: "Portoghese",
      ru: "Португальский",
      el: "Πορτογαλικά",
      ca: "Portuguès",
      ar: "البرتغالية",
      sv: "Portugisiska",
      hi: "पुर्तगाली",
      pl: "Portugalski",
      da: "Portugisisk",
      "zh-Hant": "葡萄牙文",
      nb: "Portugisisk",
      "en-IN": "Portuguese",
      th: "โปรตุเกส",
      ms: "Portugis",
      cs: "Portugalština",
      sk: "Portugalčina",
      "zh-Hans": "葡萄牙语",
      de: "Portugiesisch",
      "en-CA": "Portuguese",
      "es-MX": "Portugués",
      vi: "Tiếng Bồ Đào Nha",
      "fr-CA": "Portugais",
      es: "Portugués",
      uk: "Портуґальська",
      "zh-HK": "葡萄牙文",
      ro: "Portugheză",
      pt: "Português",
      "en-GB": "Portuguese",
      ja: "ポルトガル語",
      hr: "Portugalski",
      my: "ပေါ်တူဂီ",
    },
    it: {
      ko: "이탈리아어",
      he: "איטלקית",
      id: "Italia",
      en: "Italian",
      fi: "Italia",
      tr: "İtalyanca",
      nl: "Italiaans",
      hu: "Olasz",
      "pt-BR": "Italiano",
      it: "Italiano",
      ru: "Итальянский",
      el: "Ιταλικά",
      ca: "Italià",
      ar: "الإيطالية",
      sv: "Italienska",
      hi: "इतालवी",
      pl: "Włoski",
      da: "Italiensk",
      "zh-Hant": "義大利文",
      nb: "Italiensk",
      "en-IN": "Italian",
      th: "อิตาลี",
      ms: "Itali",
      cs: "Italština",
      sk: "Taliančina",
      "zh-Hans": "意大利语",
      de: "Italienisch",
      "en-CA": "Italian",
      "es-MX": "Italiano",
      vi: "Tiếng Italy",
      "fr-CA": "Italien",
      es: "Italiano",
      uk: "Італійська",
      "zh-HK": "意大利文",
      ro: "Italiană",
      pt: "Italiano",
      "en-GB": "Italian",
      ja: "イタリア語",
      hr: "Talijanski",
      my: "အီတလီ",
    },
    de: {
      ko: "독일어",
      he: "גרמנית",
      id: "Jerman",
      "en-AU": "German",
      fi: "Saksa",
      tr: "Almanca",
      nl: "Duits",
      hu: "Német",
      "pt-BR": "Alemão",
      it: "Tedesco",
      ru: "Немецкий",
      el: "Γερμανικά",
      ca: "Alemany",
      ar: "الألمانية",
      sv: "Tyska",
      hi: "जर्मन",
      pl: "Niemiecki",
      da: "Tysk",
      en: "German",
      "zh-Hant": "德文",
      nb: "Tysk",
      "en-IN": "German",
      th: "เยอรมัน",
      ms: "Jerman",
      cs: "Němčina",
      sk: "Nemčina",
      "zh-Hans": "德语",
      de: "Deutsch",
      "en-CA": "German",
      "es-MX": "Alemán",
      vi: "Tiếng Đức",
      "fr-CA": "Allemand",
      es: "Alemán",
      uk: "Німецька",
      fr: "Allemand",
      "zh-HK": "德文",
      ro: "Germană",
      pt: "Alemão",
      "en-GB": "German",
      ja: "ドイツ語",
      hr: "Njemački",
      my: "ဂျာမန်",
    },
    "zh-Hans": {
      ko: "중국어(간체)",
      he: "סינית פשוטה",
      id: "Tionghoa (Aksara Sederhana)",
      "en-AU": "Chinese, Simplified",
      fi: "Yksinkertaistettu Kiina",
      tr: "Basitleştirilmiş Çince",
      nl: "Chinees (Vereenvoudigd)",
      hu: "Egyszerűsített Kínai",
      "pt-BR": "Chinês Simplificado",
      it: "Cinese Semplificato",
      ru: "Китайский (Упрощенный)",
      el: "Απλοποιημένα Κινεζικά",
      ca: "Xinès Simplificat",
      ar: "الصينية المبسطة",
      sv: "Förenklad Kinesiska",
      hi: "सरलीकृत चीनी",
      pl: "Chiński Uproszczony",
      da: "Forenklet Kinesisk",
      en: "Chinese, Simplified",
      "zh-Hant": "簡體中文",
      nb: "Forenklet Kinesisk",
      "en-IN": "Chinese, Simplified",
      th: "จีนประยุกต์",
      ms: "Cina Ringkas",
      cs: "Čínština (Zjednodušená)",
      sk: "Čínština (Zjednodušená)",
      "zh-Hans": "简体中文",
      de: "Chinesisch (Vereinfacht)",
      "en-CA": "Chinese, Simplified",
      "es-MX": "Chino Simplificado",
      vi: "Tiếng Trung (Giản Thể)",
      "fr-CA": "Chinois Simplifié",
      es: "Chino Simplificado",
      uk: "Китайська (Спрощене Письмо)",
      fr: "Chinois Simplifié",
      "zh-HK": "簡體中文",
      ro: "Chineză Simplificată",
      pt: "Chinês Simplificado",
      "en-GB": "Chinese, Simplified",
      ja: "簡体中国語",
      hr: "Kineski (Pojednostavljeni)",
      my: "တရုတ် (ရိုးရှင်း)",
    },
    "zh-Hant": {
      ko: "중국어(번체)",
      he: "סינית מסורתית",
      id: "Tionghoa (Aksara Tradisional)",
      "en-AU": "Chinese, Traditional",
      fi: "Perinteinen Kiina",
      tr: "Geleneksel Çince",
      nl: "Chinees (Traditioneel)",
      hu: "Hagyományos Kínai",
      "pt-BR": "Chinês Tradicional",
      it: "Cinese Tradizionale",
      ru: "Китайский (Традиционный)",
      el: "Παραδοσιακά Κινεζικά",
      ca: "Xinès Tradicional",
      ar: "الصينية التقليدية",
      sv: "Traditionell Kinesiska",
      hi: "पारंपरिक चीनी",
      pl: "Chiński Tradycyjny",
      da: "Traditionelt Kinesisk",
      en: "Chinese, Traditional",
      "zh-Hant": "繁體中文",
      nb: "Tradisjonell Kinesisk",
      "en-IN": "Chinese, Traditional",
      th: "จีนดั้งเดิม",
      ms: "Cina Tradisional",
      cs: "Čínština (Tradiční)",
      sk: "Čínština (Tradičná)",
      "zh-Hans": "繁体中文",
      de: "Chinesisch (Traditionell)",
      "en-CA": "Chinese, Traditional",
      "es-MX": "Chino Tradicional",
      vi: "Tiếng Trung (Phồn Thể)",
      "fr-CA": "Chinois Traditionnel",
      es: "Chino Tradicional",
      uk: "Китайська (Традиційне Письмо)",
      fr: "Chinois Traditionnel",
      "zh-HK": "繁體中文",
      ro: "Chineză Tradițională",
      pt: "Chinês Tradicional",
      "en-GB": "Chinese, Traditional",
      ja: "繁体中国語",
      hr: "Kineski (Tradicionalni)",
      my: "တရုတ် (ရိုးရာ)",
    },
    nl: {
      ko: "네덜란드어",
      he: "הולנדית",
      id: "Belanda",
      "en-AU": "Dutch",
      fi: "Hollanti",
      tr: "Felemenkçe",
      nl: "Nederlands",
      hu: "Holland",
      "pt-BR": "Holandês",
      it: "Olandese",
      ru: "Нидерландский",
      el: "Ολλανδικά",
      ca: "Neerlandès",
      ar: "الهولندية",
      sv: "Nederländska",
      hi: "डच",
      pl: "Niderlandzki",
      da: "Hollandsk",
      en: "Dutch",
      "zh-Hant": "荷蘭文",
      nb: "Nederlandsk",
      "en-IN": "Dutch",
      th: "ดัตช์",
      ms: "Belanda",
      cs: "Nizozemština",
      sk: "Holandčina",
      "zh-Hans": "荷兰语",
      de: "Niederländisch",
      "en-CA": "Dutch",
      "es-MX": "Neerlandés",
      vi: "Tiếng Hà Lan",
      "fr-CA": "Néerlandais",
      es: "Neerlandés",
      uk: "Нідерландська",
      fr: "Néerlandais",
      "zh-HK": "荷蘭文",
      ro: "Neerlandeză",
      pt: "Holandês",
      "en-GB": "Dutch",
      ja: "オランダ語",
      hr: "Nizozemski",
      my: "ဒတ်ခ်ျ",
    },
    ja: {
      ko: "일본어",
      he: "יפנית",
      id: "Jepang",
      "en-AU": "Japanese",
      fi: "Japani",
      tr: "Japonca",
      nl: "Japans",
      hu: "Japán",
      "pt-BR": "Japonês",
      it: "Giapponese",
      ru: "Японский",
      el: "Ιαπωνικά",
      ca: "Japonès",
      ar: "اليابانية",
      sv: "Japanska",
      hi: "जापानी",
      pl: "Japoński",
      da: "Japansk",
      en: "Japanese",
      "zh-Hant": "日文",
      nb: "Japansk",
      "en-IN": "Japanese",
      th: "ญี่ปุ่น",
      ms: "Jepun",
      cs: "Japonština",
      sk: "Japončina",
      "zh-Hans": "日语",
      de: "Japanisch",
      "en-CA": "Japanese",
      "es-MX": "Japonés",
      vi: "Tiếng Nhật",
      "fr-CA": "Japonais",
      es: "Japonés",
      uk: "Японська",
      fr: "Japonais",
      "zh-HK": "日文",
      ro: "Japoneză",
      pt: "Japonês",
      "en-GB": "Japanese",
      ja: "日本語",
      hr: "Japanski",
      my: "ဂျပန်",
    },
    ko: {
      ko: "한국어",
      he: "קוריאנית",
      id: "Korea",
      "en-AU": "Korean",
      fi: "Korea",
      tr: "Korece",
      nl: "Koreaans",
      hu: "Koreai",
      "pt-BR": "Coreano",
      it: "Coreano",
      ru: "Корейский",
      el: "Κορεατικά",
      ca: "Coreà",
      ar: "الكورية",
      sv: "Koreanska",
      hi: "कोरियाई",
      pl: "Koreański",
      da: "Koreansk",
      en: "Korean",
      "zh-Hant": "韓文",
      nb: "Koreansk",
      "en-IN": "Korean",
      th: "เกาหลี",
      ms: "Korea",
      cs: "Korejština",
      sk: "Kórejčina",
      "zh-Hans": "韩语",
      de: "Koreanisch",
      "en-CA": "Korean",
      "es-MX": "Coreano",
      vi: "Tiếng Hàn",
      "fr-CA": "Coréen",
      es: "Coreano",
      uk: "Корейська",
      fr: "Coréen",
      "zh-HK": "韓文",
      ro: "Coreeană",
      pt: "Coreano",
      "en-GB": "Korean",
      ja: "韓国語",
      hr: "Korejski",
      my: "ကိုရီးယား",
    },
    vi: {
      ko: "베트남어",
      he: "וייטנאמית",
      id: "Vietnam",
      "en-AU": "Vietnamese",
      fi: "Vietnam",
      tr: "Vietnamca",
      nl: "Vietnamees",
      hu: "Vietnámi",
      "pt-BR": "Vietnamita",
      it: "Vietnamita",
      ru: "Вьетнамский",
      el: "Βιετναμέζικα",
      ca: "Vietnamita",
      ar: "الفيتنامية",
      sv: "Vietnamesiska",
      hi: "वियतनामी",
      pl: "Wietnamski",
      da: "Vietnamesisk",
      en: "Vietnamese",
      "zh-Hant": "越南文",
      nb: "Vietnamesisk",
      "en-IN": "Vietnamese",
      th: "เวียดนาม",
      ms: "Vietnam",
      cs: "Vietnamština",
      sk: "Vietnamčina",
      "zh-Hans": "越南语",
      de: "Vietnamesisch",
      "en-CA": "Vietnamese",
      "es-MX": "Vietnamita",
      vi: "Tiếng Việt",
      "fr-CA": "Vietnamien",
      es: "Vietnamita",
      uk: "Вʼєтнамська",
      fr: "Vietnamien",
      "zh-HK": "越南文",
      ro: "Vietnameză",
      pt: "Vietnamita",
      "en-GB": "Vietnamese",
      ja: "ベトナム語",
      hr: "Vijetnamski",
      my: "ဗီယက်နမ်",
    },
    ru: {
      ko: "러시아어",
      he: "רוסית",
      id: "Rusia",
      "en-AU": "Russian",
      fi: "Venäjä",
      tr: "Rusça",
      nl: "Russisch",
      hu: "Orosz",
      "pt-BR": "Russo",
      it: "Russo",
      ru: "Русский",
      el: "Ρωσικά",
      ca: "Rus",
      ar: "الروسية",
      sv: "Ryska",
      hi: "रूसी",
      pl: "Rosyjski",
      da: "Russisk",
      en: "Russian",
      "zh-Hant": "俄文",
      nb: "Russisk",
      "en-IN": "Russian",
      th: "รัสเซีย",
      ms: "Rusia",
      cs: "Ruština",
      sk: "Ruština",
      "zh-Hans": "俄语",
      de: "Russisch",
      "en-CA": "Russian",
      "es-MX": "Ruso",
      vi: "Tiếng Nga",
      "fr-CA": "Russe",
      es: "Ruso",
      uk: "Російська",
      fr: "Russe",
      "zh-HK": "俄文",
      ro: "Rusă",
      pt: "Russo",
      "en-GB": "Russian",
      ja: "ロシア語",
      hr: "Ruski",
      my: "ရုရှ",
    },
    sv: {
      ko: "스웨덴어",
      he: "שוודית",
      id: "Swedia",
      "en-AU": "Swedish",
      fi: "Ruotsi",
      tr: "İsveççe",
      nl: "Zweeds",
      hu: "Svéd",
      "pt-BR": "Sueco",
      it: "Svedese",
      ru: "Шведский",
      el: "Σουηδικά",
      ca: "Suec",
      ar: "السويدية",
      sv: "Svenska",
      hi: "स्वीडिश",
      pl: "Szwedzki",
      da: "Svensk",
      en: "Swedish",
      "zh-Hant": "瑞典文",
      nb: "Svensk",
      "en-IN": "Swedish",
      th: "สวีเดน",
      ms: "Sweden",
      cs: "Švédština",
      sk: "Švédčina",
      "zh-Hans": "瑞典语",
      de: "Schwedisch",
      "en-CA": "Swedish",
      "es-MX": "Sueco",
      vi: "Tiếng Thụy Điển",
      "fr-CA": "Suédois",
      es: "Sueco",
      uk: "Шведська",
      fr: "Suédois",
      "zh-HK": "瑞典文",
      ro: "Suedeză",
      pt: "Sueco",
      "en-GB": "Swedish",
      ja: "スウェーデン語",
      hr: "Švedski",
      my: "ဆွီဒင်",
    },
    da: {
      ko: "덴마크어",
      he: "דנית",
      id: "Dansk",
      "en-AU": "Danish",
      fi: "Tanska",
      tr: "Danca",
      nl: "Deens",
      hu: "Dán",
      "pt-BR": "Dinamarquês",
      it: "Danese",
      ru: "Датский",
      el: "Δανικά",
      ca: "Danès",
      ar: "الدانمركية",
      sv: "Danska",
      hi: "डेनिश",
      pl: "Duński",
      da: "Dansk",
      en: "Danish",
      "zh-Hant": "丹麥文",
      nb: "Dansk",
      "en-IN": "Danish",
      th: "เดนมาร์ก",
      ms: "Denmark",
      cs: "Dánština",
      sk: "Dánčina",
      "zh-Hans": "丹麦语",
      de: "Dänisch",
      "en-CA": "Danish",
      "es-MX": "Danés",
      vi: "Tiếng Đan Mạch",
      "fr-CA": "Danois",
      es: "Danés",
      uk: "Данська",
      fr: "Danois",
      "zh-HK": "丹麥文",
      ro: "Daneză",
      pt: "Dinamarquês",
      "en-GB": "Danish",
      ja: "デンマーク語",
      hr: "Danski",
      my: "ဒိန်းမတ်",
    },
    fi: {
      ko: "핀란드어",
      he: "פינית",
      id: "Suomi",
      "en-AU": "Finnish",
      fi: "Suomi",
      tr: "Fince",
      nl: "Fins",
      hu: "Finn",
      "pt-BR": "Finlandês",
      it: "Finlandese",
      ru: "Финский",
      el: "Φινλανδικά",
      ca: "Finès",
      ar: "الفنلندية",
      sv: "Finska",
      hi: "फ़िनिश",
      pl: "Fiński",
      da: "Finsk",
      en: "Finnish",
      "zh-Hant": "芬蘭文",
      nb: "Finsk",
      "en-IN": "Finnish",
      th: "ฟินแลนด์",
      ms: "Finland",
      cs: "Finština",
      sk: "Fínčina",
      "zh-Hans": "芬兰语",
      de: "Finnisch",
      "en-CA": "Finnish",
      "es-MX": "Finés",
      vi: "Tiếng Phần Lan",
      "fr-CA": "Finnois",
      es: "Finés",
      uk: "Фінська",
      fr: "Finnois",
      "zh-HK": "芬蘭文",
      ro: "Finlandeză",
      pt: "Finlandês",
      "en-GB": "Finnish",
      ja: "フィンランド語",
      hr: "Finski",
      my: "ဖင်လန်",
    },
    nb: {
      ko: "노르웨이어(보크말)",
      he: "נורווגית ספרותית",
      id: "Bokmål Norsk",
      "en-AU": "Norwegian Bokmål",
      fi: "Norjan Bokmål",
      tr: "Norveççe Bokmål",
      nl: "Noors - Bokmål",
      hu: "Norvég (Bokmål)",
      "pt-BR": "Bokmål Norueguês",
      it: "Norvegese Bokmål",
      ru: "Норвежский Букмол",
      el: "Νορβηγικά Μποκμάλ",
      ca: "Noruec Bokmål",
      ar: "النرويجية بوكمال",
      sv: "Norskt Bokmål",
      hi: "नॉर्वेजियाई बोकमाल",
      pl: "Norweski (Bokmål)",
      da: "Norsk Bokmål",
      en: "Norwegian Bokmål",
      "zh-Hant": "巴克摩挪威文",
      nb: "Norsk Bokmål",
      "en-IN": "Norwegian Bokmål",
      th: "นอร์เวย์บุคมอล",
      ms: "Bokmål Norway",
      cs: "Norština (Bokmål)",
      sk: "Nórčina (Bokmal)",
      "zh-Hans": "书面挪威语",
      de: "Norwegisch Bokmål",
      "en-CA": "Norwegian Bokmål",
      "es-MX": "Noruego Bokmal",
      vi: "Tiếng Na Uy (Bokmål)",
      "fr-CA": "Norvégien Bokmål",
      es: "Noruego Bokmal",
      uk: "Норвезька (Букмол)",
      fr: "Norvégien Bokmål",
      "zh-HK": "巴克摩挪威文",
      ro: "Norvegiană Bokmål",
      pt: "Bokmål Norueguês",
      "en-GB": "Norwegian Bokmål",
      ja: "ノルウェー語（ブークモール）",
      hr: "Norveški Bokmål",
      my: "နော်ဝေ ဘွတ်ခ်မော်လ်",
    },
    tr: {
      ko: "터키어",
      he: "טורקית",
      id: "Turki",
      "en-AU": "Turkish",
      fi: "Turkki",
      tr: "Türkçe",
      nl: "Turks",
      hu: "Török",
      "pt-BR": "Turco",
      it: "Turco",
      ru: "Турецкий",
      el: "Τουρκικά",
      ca: "Turc",
      ar: "التركية",
      sv: "Turkiska",
      hi: "तुर्की",
      pl: "Turecki",
      da: "Tyrkisk",
      en: "Turkish",
      "zh-Hant": "土耳其文",
      nb: "Tyrkisk",
      "en-IN": "Turkish",
      th: "ตุรกี",
      ms: "Turki",
      cs: "Turečtina",
      sk: "Turečtina",
      "zh-Hans": "土耳其语",
      de: "Türkisch",
      "en-CA": "Turkish",
      "es-MX": "Turco",
      vi: "Tiếng Thổ Nhĩ Kỳ",
      "fr-CA": "Turc",
      es: "Turco",
      uk: "Турецька",
      fr: "Turc",
      "zh-HK": "土耳其文",
      ro: "Turcă",
      pt: "Turco",
      "en-GB": "Turkish",
      ja: "トルコ語",
      hr: "Turski",
      my: "တူရကီ",
    },
    el: {
      ko: "그리스어",
      he: "יוונית",
      id: "Yunani",
      "en-AU": "Greek",
      fi: "Kreikka",
      tr: "Yunanca",
      nl: "Grieks",
      hu: "Görög",
      "pt-BR": "Grego",
      it: "Greco",
      ru: "Греческий",
      el: "Ελληνικά",
      ca: "Grec",
      ar: "اليونانية",
      sv: "Grekiska",
      hi: "यूनानी",
      pl: "Grecki",
      da: "Græsk",
      en: "Greek",
      "zh-Hant": "希臘文",
      nb: "Gresk",
      "en-IN": "Greek",
      th: "กรีก",
      ms: "Greek",
      cs: "Řečtina",
      sk: "Gréčtina",
      "zh-Hans": "希腊语",
      de: "Griechisch",
      "en-CA": "Greek",
      "es-MX": "Griego",
      vi: "Tiếng Hy Lạp",
      "fr-CA": "Grec",
      es: "Griego",
      uk: "Грецька",
      fr: "Grec",
      "zh-HK": "希臘文",
      ro: "Greacă",
      pt: "Grego",
      "en-GB": "Greek",
      ja: "ギリシャ語",
      hr: "Grčki",
      my: "ဂရိ",
    },
    id: {
      ko: "인도네시아어",
      he: "אינדונזית",
      id: "Indonesia",
      "en-AU": "Indonesian",
      fi: "Indonesia",
      tr: "Endonezce",
      nl: "Indonesisch",
      hu: "Indonéz",
      "pt-BR": "Indonésio",
      it: "Indonesiano",
      ru: "Индонезийский",
      el: "Ινδονησιακά",
      ca: "Indonesi",
      ar: "الإندونيسية",
      sv: "Indonesiska",
      hi: "इंडोनीशियाई",
      pl: "Indonezyjski",
      da: "Indonesisk",
      en: "Indonesian",
      "zh-Hant": "印尼文",
      nb: "Indonesisk",
      "en-IN": "Indonesian",
      th: "อินโดนีเซีย",
      ms: "Indonesia",
      cs: "Indonéština",
      sk: "Indonézština",
      "zh-Hans": "印度尼西亚语",
      de: "Indonesisch",
      "en-CA": "Indonesian",
      "es-MX": "Indonesio",
      vi: "Tiếng Indonesia",
      "fr-CA": "Indonésien",
      es: "Indonesio",
      uk: "Індонезійська",
      fr: "Indonésien",
      "zh-HK": "印尼文",
      ro: "Indoneziană",
      pt: "Indonésio",
      "en-GB": "Indonesian",
      ja: "インドネシア語",
      hr: "Indonezijski",
      my: "အင်ဒိုနီးရှား",
    },
    ms: {
      ko: "말레이어",
      he: "מלאית",
      id: "Melayu",
      "en-AU": "Malay",
      fi: "Malaiji",
      tr: "Malayca",
      nl: "Maleis",
      hu: "Maláj",
      "pt-BR": "Malaio",
      it: "Malese",
      ru: "Малайский",
      el: "Μαλαισιανά",
      ca: "Malai",
      ar: "الماليزية",
      sv: "Malajiska",
      hi: "मलय",
      pl: "Malajski",
      da: "Malajisk",
      en: "Malay",
      "zh-Hant": "馬來文",
      nb: "Malayisk",
      "en-IN": "Malay",
      th: "มาเลย์",
      ms: "Bahasa Melayu",
      cs: "Malajština",
      sk: "Malajčina",
      "zh-Hans": "马来语",
      de: "Malaiisch",
      "en-CA": "Malay",
      "es-MX": "Malayo",
      vi: "Tiếng Mã Lai",
      "fr-CA": "Malais",
      es: "Malayo",
      uk: "Малайська",
      fr: "Malais",
      "zh-HK": "馬來文",
      ro: "Malaeză",
      pt: "Malaio",
      "en-GB": "Malay",
      ja: "マレー語",
      hr: "Malajski",
      my: "မလေး",
    },
    th: {
      ko: "태국어",
      he: "תאית",
      id: "Thai",
      "en-AU": "Thai",
      fi: "Thai",
      tr: "Tayca",
      nl: "Thai",
      hu: "Thai",
      "pt-BR": "Tailandês",
      it: "Thai",
      ru: "Тайский",
      el: "Ταϊλανδικά",
      ca: "Tai",
      ar: "التايلاندية",
      sv: "Thailändska",
      hi: "थाई",
      pl: "Tajski",
      da: "Thai",
      en: "Thai",
      "zh-Hant": "泰文",
      nb: "Thai",
      "en-IN": "Thai",
      th: "ไทย",
      ms: "Thai",
      cs: "Thajština",
      sk: "Thajčina",
      "zh-Hans": "泰语",
      de: "Thailändisch",
      "en-CA": "Thai",
      "es-MX": "Tailandês",
      vi: "Tiếng Thái",
      "fr-CA": "Thaï",
      es: "Tailandês",
      uk: "Тайська",
      fr: "Thaï",
      "zh-HK": "泰文",
      ro: "Thailandeză",
      pt: "Tailandês",
      "en-GB": "Thai",
      ja: "タイ語",
      hr: "Tajlandski",
      my: "ထိုင်း",
    },
    hi: {
      ko: "힌디어",
      he: "הינדי",
      id: "Hindi",
      "en-AU": "Hindi",
      fi: "Hindi",
      tr: "Hintçe",
      nl: "Hindi",
      hu: "Hindi",
      "pt-BR": "Híndi",
      it: "Hindi",
      ru: "Хинди",
      el: "Χίντι",
      ca: "Hindi",
      ar: "الهندية",
      sv: "Hindi",
      hi: "हिन्दी",
      pl: "Hindi",
      da: "Hindi",
      en: "Hindi",
      "zh-Hant": "印地文",
      nb: "Hindi",
      "en-IN": "Hindi",
      th: "ฮินดี",
      ms: "Hindi",
      cs: "Hindština",
      sk: "Hindčina",
      "zh-Hans": "印地语",
      de: "Hindi",
      "en-CA": "Hindi",
      "es-MX": "Hindi",
      vi: "Tiếng Hindi",
      "fr-CA": "Hindi",
      es: "Hindi",
      uk: "Гінді",
      fr: "Hindi",
      "zh-HK": "印地文",
      ro: "Hindi",
      pt: "Híndi",
      "en-GB": "Hindi",
      ja: "ヒンディー語",
      hr: "Hindski",
      my: "ဟိန်ဒူ",
    },
    hu: {
      ko: "헝가리어",
      he: "הונגרית",
      id: "Magyar",
      "en-AU": "Hungarian",
      fi: "Unkari",
      tr: "Macarca",
      nl: "Hongaars",
      hu: "Magyar",
      "pt-BR": "Húngaro",
      it: "Ungherese",
      ru: "Венгерский",
      el: "Ουγγρικά",
      ca: "Hongarès",
      ar: "الهنغارية",
      sv: "Ungerska",
      hi: "हंगेरियाई",
      pl: "Węgierski",
      da: "Ungarsk",
      en: "Hungarian",
      "zh-Hant": "匈牙利文",
      nb: "Ungarsk",
      "en-IN": "Hungarian",
      th: "ฮังการี",
      ms: "Hungary",
      cs: "Maďarština",
      sk: "Maďarčina",
      "zh-Hans": "匈牙利语",
      de: "Ungarisch",
      "en-CA": "Hungarian",
      "es-MX": "Húngaro",
      vi: "Tiếng Hungary",
      "fr-CA": "Hongrois",
      es: "Húngaro",
      uk: "Угорська",
      fr: "Hongrois",
      "zh-HK": "匈牙利文",
      ro: "Maghiară",
      pt: "Húngaro",
      "en-GB": "Hungarian",
      ja: "ハンガリー語",
      hr: "Mađarski",
      my: "ဟန်ဂေရီ",
    },
    pl: {
      ko: "폴란드어",
      he: "פולנית",
      id: "Polski",
      "en-AU": "Polish",
      fi: "Puola",
      tr: "Lehçe",
      nl: "Pools",
      hu: "Lengyel",
      "pt-BR": "Polonês",
      it: "Polacco",
      ru: "Польский",
      el: "Πολωνικά",
      ca: "Polonès",
      ar: "البولندية",
      sv: "Polska",
      hi: "पोलिश",
      pl: "Polski",
      da: "Polsk",
      en: "Polish",
      "zh-Hant": "波蘭文",
      nb: "Polsk",
      "en-IN": "Polish",
      th: "โปแลนด์",
      ms: "Poland",
      cs: "Polština",
      sk: "Poľština",
      "zh-Hans": "波兰语",
      de: "Polnisch",
      "en-CA": "Polish",
      "es-MX": "Polaco",
      vi: "Tiếng Ba Lan",
      "fr-CA": "Polonais",
      es: "Polaco",
      uk: "Польська",
      fr: "Polonais",
      "zh-HK": "波蘭文",
      ro: "Poloneză",
      pt: "Polonês",
      "en-GB": "Polish",
      ja: "ポーランド語",
      hr: "Poljski",
      my: "ပိုလန်",
    },
    cs: {
      ko: "체코어",
      he: "צ׳כית",
      id: "Cheska",
      "en-AU": "Czech",
      fi: "Tšekki",
      tr: "Çekçe",
      nl: "Tsjechisch",
      hu: "Cseh",
      "pt-BR": "Tcheco",
      it: "Ceco",
      ru: "Чешский",
      el: "Τσεχικά",
      ca: "Txec",
      ar: "التشيكية",
      sv: "Tjeckiska",
      hi: "चेक",
      pl: "Czeski",
      da: "Tjekkisk",
      en: "Czech",
      "zh-Hant": "捷克文",
      nb: "Tsjekkisk",
      "en-IN": "Czech",
      th: "เช็ก",
      ms: "Czech",
      cs: "Čeština",
      sk: "Čeština",
      "zh-Hans": "捷克语",
      de: "Tschechisch",
      "en-CA": "Czech",
      "es-MX": "Checo",
      vi: "Tiếng Séc",
      "fr-CA": "Tchèque",
      es: "Checo",
      uk: "Чеська",
      fr: "Tchèque",
      "zh-HK": "捷克文",
      ro: "Cehă",
      pt: "Tcheco",
      "en-GB": "Czech",
      ja: "チェコ語",
      hr: "Češki",
      my: "ချက်",
    },
    sk: {
      ko: "슬로바키아어",
      he: "סלובקית",
      id: "Slovak",
      "en-AU": "Slovak",
      fi: "Slovakki",
      tr: "Slovakça",
      nl: "Slowaaks",
      hu: "Szlovák",
      "pt-BR": "Eslovaco",
      it: "Slovacco",
      ru: "Словацкий",
      el: "Σλοβακικά",
      ca: "Eslovac",
      ar: "السلوفاكية",
      sv: "Slovakiska",
      hi: "स्लोवाक",
      pl: "Słowacki",
      da: "Slovakisk",
      en: "Slovak",
      "zh-Hant": "斯洛伐克文",
      nb: "Slovakisk",
      "en-IN": "Slovak",
      th: "สโลวัก",
      ms: "Slovak",
      cs: "Slovenština",
      sk: "Slovenčina",
      "zh-Hans": "斯洛伐克语",
      de: "Slowakisch",
      "en-CA": "Slovak",
      "es-MX": "Eslovaco",
      vi: "Tiếng Slovak",
      "fr-CA": "Slovaque",
      es: "Eslovaco",
      uk: "Словацька",
      fr: "Slovaque",
      "zh-HK": "斯洛伐克文",
      ro: "Slovacă",
      pt: "Eslovaco",
      "en-GB": "Slovak",
      ja: "スロバキア語",
      hr: "Slovački",
      my: "ဆလိုဗက်",
    },
    uk: {
      ko: "우크라이나어",
      he: "אוקראינית",
      id: "Ukraina",
      "en-AU": "Ukrainian",
      fi: "Ukraina",
      tr: "Ukraynaca",
      nl: "Oekraïens",
      hu: "Ukrán",
      "pt-BR": "Ucraniano",
      it: "Ucraino",
      ru: "Украинский",
      el: "Ουκρανικά",
      ca: "Ucraïnès",
      ar: "الأوكرانية",
      sv: "Ukrainska",
      hi: "यूक्रेनियाई",
      pl: "Ukraiński",
      da: "Ukrainsk",
      en: "Ukrainian",
      "zh-Hant": "烏克蘭文",
      nb: "Ukrainsk",
      "en-IN": "Ukrainian",
      th: "ยูเครน",
      ms: "Ukraine",
      cs: "Ukrajinština",
      sk: "Ukrajinčina",
      "zh-Hans": "乌克兰语",
      de: "Ukrainisch",
      "en-CA": "Ukrainian",
      "es-MX": "Ucraniano",
      vi: "Tiếng Ukraina",
      "fr-CA": "Ukrainien",
      es: "Ucraniano",
      uk: "Українська",
      fr: "Ukrainien",
      "zh-HK": "烏克蘭文",
      ro: "Ucraineană",
      pt: "Ucraniano",
      "en-GB": "Ukrainian",
      ja: "ウクライナ語",
      hr: "Ukrajinski",
      my: "ယူကရိန်း",
    },
    hr: {
      ko: "크로아티아어",
      he: "קרואטית",
      id: "Kroasia",
      "en-AU": "Croatian",
      fi: "Kroatia",
      tr: "Hırvatça",
      nl: "Kroatisch",
      hu: "Horvát",
      "pt-BR": "Croata",
      it: "Croato",
      ru: "Хорватский",
      el: "Κροατικά",
      ca: "Croat",
      ar: "الكرواتية",
      sv: "Kroatiska",
      hi: "क्रोएशियाई",
      pl: "Chorwacki",
      da: "Kroatisk",
      en: "Croatian",
      "zh-Hant": "克羅埃西亞文",
      nb: "Kroatisk",
      "en-IN": "Croatian",
      th: "โครเอเชีย",
      ms: "Croatia",
      cs: "Chorvatština",
      sk: "Chorvátčina",
      "zh-Hans": "克罗地亚语",
      de: "Kroatisch",
      "en-CA": "Croatian",
      "es-MX": "Croata",
      vi: "Tiếng Croatia",
      "fr-CA": "Croate",
      es: "Croata",
      uk: "Хорватська",
      fr: "Croate",
      "zh-HK": "克羅地亞文",
      ro: "Croată",
      pt: "Croata",
      "en-GB": "Croatian",
      ja: "クロアチア語",
      hr: "Hrvatski",
      my: "ခရိုအေးရှား",
    },
    ca: {
      ko: "카탈로니아어",
      he: "קטלאנית",
      id: "Katalan",
      "en-AU": "Catalan",
      fi: "Katalaani",
      tr: "Katalanca",
      nl: "Catalaans",
      hu: "Katalán",
      "pt-BR": "Catalão",
      it: "Catalano",
      ru: "Каталанский",
      el: "Καταλανικά",
      ca: "Català",
      ar: "الكتالانية",
      sv: "Katalanska",
      hi: "कातालान",
      pl: "Kataloński",
      da: "Catalansk",
      en: "Catalan",
      "zh-Hant": "加泰蘭文",
      nb: "Katalansk",
      "en-IN": "Catalan",
      th: "คาตาลัน",
      ms: "Catalonia",
      cs: "Katalánština",
      sk: "Katalánčina",
      "zh-Hans": "加泰罗尼亚语",
      de: "Katalanisch",
      "en-CA": "Catalan",
      "es-MX": "Catalán",
      vi: "Tiếng Catalan",
      "fr-CA": "Catalan",
      es: "Catalán",
      uk: "Каталонська",
      fr: "Catalan",
      "zh-HK": "加泰隆尼亞文",
      ro: "Catalană",
      pt: "Catalão",
      "en-GB": "Catalan",
      ja: "カタロニア語",
      hr: "Katalonski",
      my: "ကတ်တလန်",
    },
    ro: {
      ko: "루마니아어",
      he: "רומנית",
      id: "Rumania",
      "en-AU": "Romanian",
      fi: "Romania",
      tr: "Rumence",
      nl: "Roemeens",
      hu: "Román",
      "pt-BR": "Romeno",
      it: "Rumeno",
      ru: "Румынский",
      el: "Ρουμανικά",
      ca: "Romanès",
      ar: "الرومانية",
      sv: "Rumänska",
      hi: "रोमानियाई",
      pl: "Rumuński",
      da: "Rumænsk",
      en: "Romanian",
      "zh-Hant": "羅馬尼亞文",
      nb: "Rumensk",
      "en-IN": "Romanian",
      th: "โรมาเนีย",
      ms: "Romania",
      cs: "Rumunština",
      sk: "Rumunčina",
      "zh-Hans": "罗马尼亚语",
      de: "Rumänisch",
      "en-CA": "Romanian",
      "es-MX": "Rumano",
      vi: "Tiếng Romania",
      "fr-CA": "Roumain",
      es: "Rumano",
      uk: "Румунська",
      fr: "Roumain",
      "zh-HK": "羅馬尼亞文",
      ro: "Română",
      pt: "Romeno",
      "en-GB": "Romanian",
      ja: "ルーマニア語",
      hr: "Rumunjski",
      my: "ရိုမေနီယား",
    },
    he: {
      ko: "히브리어",
      he: "עברית",
      id: "Ibrani",
      "en-AU": "Hebrew",
      fi: "Heprea",
      tr: "İbranice",
      nl: "Hebreeuws",
      hu: "Héber",
      "pt-BR": "Hebraico",
      it: "Ebraico",
      ru: "Иврит",
      el: "Εβραϊκά",
      ca: "Hebreu",
      ar: "العبرية",
      sv: "Hebreiska",
      hi: "हिब्रू",
      pl: "Hebrajski",
      da: "Hebraisk",
      en: "Hebrew",
      "zh-Hant": "希伯來文",
      nb: "Hebraisk",
      "en-IN": "Hebrew",
      th: "ฮิบรู",
      ms: "Ibrani",
      cs: "Hebrejština",
      sk: "Hebrejčina",
      "zh-Hans": "希伯来语",
      de: "Hebräisch",
      "en-CA": "Hebrew",
      "es-MX": "Hebreo",
      vi: "Tiếng Do Thái",
      "fr-CA": "Hébreu",
      es: "Hebreo",
      uk: "Іврит",
      fr: "Hébreu",
      "zh-HK": "希伯來文",
      ro: "Ebraică",
      pt: "Hebraico",
      "en-GB": "Hebrew",
      ja: "ヘブライ語",
      hr: "Hebrejski",
      my: "ဟီးဘရူး",
    },
    ar: {
      ko: "아랍어",
      he: "ערבית",
      id: "Arab",
      "en-AU": "Arabic",
      fi: "Arabia",
      tr: "Arapça",
      nl: "Arabisch",
      hu: "Arab",
      "pt-BR": "Árabe",
      it: "Arabo",
      ru: "Арабский",
      el: "Αραβικά",
      ca: "Àrab",
      ar: "العربية",
      sv: "Arabiska",
      hi: "अरबी",
      pl: "Arabski",
      da: "Arabisk",
      en: "Arabic",
      "zh-Hant": "阿拉伯文",
      nb: "Arabisk",
      "en-IN": "Arabic",
      th: "อาหรับ",
      ms: "Arab",
      cs: "Arabština",
      sk: "Arabčina",
      "zh-Hans": "阿拉伯语",
      de: "Arabisch",
      "en-CA": "Arabic",
      "es-MX": "Árabe",
      vi: "Tiếng Ả Rập",
      "fr-CA": "Arabe",
      es: "Árabe",
      uk: "Арабська",
      fr: "Arabe",
      "zh-HK": "阿拉伯文",
      ro: "Arabă",
      pt: "Árabe",
      "en-GB": "Arabic",
      ja: "アラビア語",
      hr: "Arapski",
      my: "အာရဗီ",
    },
    my: {
      ko: "버마어",
      he: "בורמזית",
      id: "Burma",
      "en-AU": "Burmese",
      fi: "Burma",
      tr: "Birman Dili",
      nl: "Birmaans",
      hu: "Burmai",
      "pt-BR": "Birmanês",
      it: "Birmano",
      ru: "Бирманский",
      el: "Βιρμανικά",
      ca: "Birmà",
      ar: "البورمية",
      sv: "Burmesiska",
      hi: "बर्मीज़",
      pl: "Birmański",
      da: "Burmesisk",
      en: "Burmese",
      "zh-Hant": "緬甸文",
      nb: "Burmesisk",
      "en-IN": "Burmese",
      th: "พม่า",
      ms: "Burma",
      cs: "Barmština",
      sk: "Barmčina",
      "zh-Hans": "缅甸语",
      de: "Birmanisch",
      "en-CA": "Burmese",
      "es-MX": "Birmano",
      vi: "Tiếng Miến Điện",
      "fr-CA": "Birman",
      es: "Birmano",
      uk: "Бірманська",
      fr: "Birman",
      "zh-HK": "緬甸文",
      ro: "Birmană",
      pt: "Birmanês",
      "en-GB": "Burmese",
      ja: "ミャンマー語",
      hr: "Burmanski",
      my: "မြန်မာ",
    },
  },

  supportedLanguageCodes: new Set([
    "en",
    "fr",
    "es",
    "pt",
    "it",
    "de",
    "zh-Hans",
    "zh-Hant",
    "nl",
    "ja",
    "ko",
    "vi",
    "ru",
    "sv",
    "da",
    "fi",
    "nb",
    "tr",
    "el",
    "id",
    "ms",
    "th",
    "hi",
    "hu",
    "pl",
    "cs",
    "sk",
    "uk",
    "hr",
    "ca",
    "ro",
    "he",
    "ar",
    "my",
  ]),

  localizedStringForCurrentAppLanguage(languageCode?: string): string | null {
    if (!languageCode) return null;
    // console.log("1 languageCode", languageCode);
    const language = this.languageNamesDict[languageCode];
    const defaultLanguage =
      new Intl.DisplayNames([navigator.language], { type: "language" }).of(
        languageCode,
      ) || null;
    if (!language) {
      return defaultLanguage;
    }
    const appLanguage = navigator.language || "en";
    return language[appLanguage] || defaultLanguage;
  },

  currentLanguageCodeInSupportList(): string {
    const localizationCode = navigator.language;
    const [languageCode, scriptCode] = localizationCode.split("-");

    if (this.supportedLanguageCodes.has(languageCode)) {
      return languageCode;
    }
    if (
      scriptCode &&
      this.supportedLanguageCodes.has(`${languageCode}-${scriptCode}`)
    ) {
      return `${languageCode}-${scriptCode}`;
    }
    return Array.from(this.supportedLanguageCodes)[0];
  },
};

export default LanguageCodes;
