import { SearchBackground } from "./SearchPage";
import { useBackend } from "../../service/APIService";
import { useSWROffsetList } from "../../hooks/swr/useSWRList";
import * as React from "react";
import { useEffect } from "react";
import { andLog } from "../../components/handleError";
import { Page } from "../../components/Page";
import {
  ChatTitle,
  Sep,
  ThreadIconContainer,
} from "../chat/list/GeneralChatListPage";
import { HStack, Spring, VStack } from "../../components/VStack";
import { Image } from "../../components/Image";
import close from "../../res/images/ic_close.svg";
import iconSearch from "../../res/images/icon_search_layout_placeholder.svg";
import { Input } from "../../components/Input";
import { useHopper } from "../../hooks/useHopper";
import { useDebounceState } from "../../hooks/ExtHooks";
import { Chat, getChatDisplayName } from "../../proto/Chat";
import { useMyUid } from "../../service/AuthSessionService";
import { cell_on_pressed } from "../../components/CommonStyles";
import ChatThreadIcon from "../chat/components/ChatThreadIcon";
import { useHopState } from "../../hooks/useHopState";

export function SearchJoinedChatsPage() {
  const backend = useBackend();
  const hopper = useHopper();
  const [word, setWord] = useDebounceState<string>(300, "");
  const [inputWord, setInputWord] = useHopState<string>("inputWord");
  const chatsSWR = useSWROffsetList(backend.searchJoinedThreads(word || ""));

  useEffect(() => {
    if (word && word.length > 0) {
      chatsSWR.load().catch(andLog);
    }
  }, [word]);

  return (
    <Page pageData={undefined} scrollPaddingDisabled={true}>
      <HStack>
        <Image
          src={close}
          width={36}
          height={36}
          onClick={() => {
            hopper.back();
          }}
        ></Image>
        <SearchBackground>
          <Image
            alt={"search-icon"}
            src={iconSearch}
            width={24}
            height={24}
          ></Image>
          <Input
            value={inputWord}
            style={{
              height: 36,
              margin: "0 6px 0 0",
              padding: "0",
              width: "calc(100% - 30px)",
            }}
            onChange={(e) => {
              if (e.target.value) {
                setWord(e.target.value);
                setInputWord(e.target.value);
              } else {
                setInputWord("");
              }
            }}
          ></Input>
        </SearchBackground>
      </HStack>

      {word &&
        word.length > 0 &&
        inputWord &&
        inputWord.length > 0 &&
        chatsSWR.content &&
        chatsSWR.content.list.map((item) => (
          <JoinedChatsSearchResultCell chat={item} key={item.threadId} />
        ))}
    </Page>
  );
}

export function JoinedChatsSearchResultCell(props: { chat: Chat }) {
  const hopper = useHopper();
  const myUid = useMyUid();
  return (
    <>
      <HStack
        style={{ width: "100%", padding: "16px 0", gap: "10px" }}
        key={props.chat.threadId}
        mixin={[cell_on_pressed]}
        onClick={() => {
          hopper.modal("nyi");
          // hopper.push(`chat/${props.chat.threadId}`)
        }}
      >
        <ThreadIconContainer>
          <ChatThreadIcon chat={props.chat} />
        </ThreadIconContainer>
        <VStack style={{ flexGrow: 1, marginRight: "var(--page-h-inset)" }}>
          <HStack style={{ width: "100%", gap: "6px" }}>
            <ChatTitle>{getChatDisplayName(props.chat, myUid)}</ChatTitle>
            <Spring />
          </HStack>
        </VStack>
      </HStack>
      <Sep />
    </>
  );
}
