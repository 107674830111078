import { FC } from "react";

import { formatDateWithOptions } from "../../../utils/DateTimeUtils";
import { useI18n } from "../../../hooks/useI18n";
import { AssetOrderStatus, type AssetOrder } from "../../../proto/AssetOrder";
import {
  formatMoney,
  getCurrencyIcon,
  getCurrencyName,
} from "../../../proto/Currency";
import { HStack, Spring, VSpace, VStack } from "../../../components/VStack";
import { Image } from "../../../components/Image";
import { CellDivider, ContentGroup } from "../../../components/ContentGroup";
import { MainCurrencyAmount, MainCurrencyName } from "./TopUpOrderDetail";
import { StatusBar } from "./GiftOrderListPage";
import { OrderInfoContent, OrderInfoLabel } from "./GiftOrderDetail";

const ConvertToGemsDetail: FC<{ assetOrder: AssetOrder }> = ({
  assetOrder,
}) => {
  const i18n = useI18n();

  const getStatus = () => {
    switch (assetOrder.orderStatus) {
      case AssetOrderStatus.Done:
        return { text: i18n.web3_v0_completed(), color: "#00FF94" };
      case AssetOrderStatus.Pending:
      case AssetOrderStatus.Submitted: {
        return { text: i18n.web3_v0_pending(), color: "#34A6FFFF" };
      }
      case AssetOrderStatus.Failed: {
        return { text: i18n.circle_anmt_failed(), color: "#EF3537FF" };
      }
      case AssetOrderStatus.Rejected: {
        return { text: i18n.circle_anmt_rejected(), color: "#EF3537FF" };
      }
      default:
        return { text: "", color: "#FFFFFF66" };
    }
  };

  return (
    <VStack style={{ width: "100%", alignItems: "center" }}>
      <HStack style={{ gap: 7, marginTop: 38 }}>
        <HStack style={{ gap: 7, alignItems: "baseline" }}>
          <MainCurrencyAmount>
            {formatMoney("medium", assetOrder.currency)}
          </MainCurrencyAmount>
          <MainCurrencyName>
            {getCurrencyName(assetOrder.currency, i18n)}
          </MainCurrencyName>
        </HStack>
      </HStack>
      <VSpace height={8} />
      <StatusBar
        statusColor={getStatus().color}
        statusText={getStatus().text}
      />
      <ContentGroup style={{ marginTop: 32, width: "100%", gap: 20 }}>
        <HStack>
          <OrderInfoLabel>{i18n.web3_v0_order_type()}</OrderInfoLabel>
          <Spring />
          <OrderInfoContent>{i18n.web3_v0_swap()}</OrderInfoContent>
        </HStack>
        <CellDivider />
        <HStack style={{ alignItems: 'flex-start' }}>
          <OrderInfoLabel>{i18n.clover_total_deduction()}</OrderInfoLabel>
          <Spring />
          <VStack style={{ alignItems: "end" }}>
            {assetOrder.totalDeductionList?.map((currency) => (
              <HStack style={{ fontWeight: 500, fontSize: 14 }}>
                <Image
                  src={getCurrencyIcon(currency)}
                  style={{ width: 20, height: 20, gap: 4 }}
                />
                <span>
                {`${formatMoney("medium", currency)} ${getCurrencyName(currency, i18n)}`}</span>
              </HStack>
            ))}
          </VStack>
        </HStack>
        <CellDivider />
        <HStack>
          <OrderInfoLabel>{i18n.web3_v0_created_time()}</OrderInfoLabel>
          <Spring />
          <OrderInfoContent>
            {formatDateWithOptions(assetOrder.createdTime || 0, {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })}
          </OrderInfoContent>
        </HStack>
        <CellDivider />
        <HStack>
          <OrderInfoLabel>{i18n.web3_v0_transaction_order_id()}</OrderInfoLabel>
          <Spring />
          <OrderInfoContent>{assetOrder.orderId.toString()}</OrderInfoContent>
        </HStack>
      </ContentGroup>
    </VStack>
  );
};

export default ConvertToGemsDetail;
