import React from "react";
import { LoadStateKind } from "../../hooks/LoadState";
import { Page } from "../../components/Page";

export function NotFoundPage() {
  return (
    <Page
      pageData={{
        loadState: {
          kind: LoadStateKind.loadFailed,
          error: Error("Page Not Found"),
        },
        contentId: "notfound",
        hasContents: false,
        refresh: async (reason?: string) => {
          window.location.reload();
        },
      }}
      scrollDisabled={true}
    ></Page>
  );
}
