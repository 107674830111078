import { LocalMedia, MediaScene } from "../bridge/LocalMedia";
import { Media } from "../proto/Media";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from "react";
import axios, {
  AxiosError,
  AxiosInstance,
  InternalAxiosRequestConfig,
  isAxiosError,
} from "axios";
import { HttpHeaderKey, signRequest } from "./HttpHeaderUtil";
import { getDeviceId, hashDeviceId } from "./getDeviceId";
import { genUUID } from "../utils/uuid";
import { getAntiFraudDeviceId } from "./AntiFraud";
import { BASE_URL } from "../config/config";
import {
  arrayBufferToBase64,
  arrayBufferToObj,
  base64ToTyped,
  base64ToUnknown,
  utf8ToArrayBuffer,
} from "../utils/Blob";
import { APIErrorCode, APIErrorRespBody } from "../proto/APIErrorRespBody";
import { AuthSession } from "./AuthSession";
import { JSONUtil } from "../utils/JSONUtil";
import { AuthType } from "../proto/Auth";
import { isRejectable } from "../bridge/Rejectable";
import { useAuthSessionService } from "./AuthSessionService";
import { assert } from "../utils/asserts";
import { Bridge, getBridgeHook } from "../bridge/Bridge";
import { z } from "zod";
import { User } from "../proto/User";
import { urlAppendQuery } from "../utils/UrlUtil";
import { detect } from "detect-browser";

interface Context {
  get: (url: string) => Promise<string>;
  delete: (url: string, body: string) => Promise<string>;
  post: (url: string, body: string) => Promise<string>;
  sendLocalMedia: (
    localMedia: LocalMedia,
    scene: MediaScene,
    progressListener: (uploaded: bigint, total: bigint) => void,
  ) => Promise<Media>;
}

const Context = createContext<Context>({
  get: async (url: string) => "",
  delete: async (url: string, body: string) => "",
  post: async (url: string, body: string) => "",
  sendLocalMedia: async (
    localMedia: LocalMedia,
    scene: MediaScene,
    progressListener: (uploaded: bigint, total: bigint) => void,
  ) => {
    return {} as Media;
  },
});

async function composeHeader(
  request: InternalAxiosRequestConfig,
  getSession: () => Promise<AuthSession | undefined>,
) {
  const nativeSession = window.sessionStorage.getItem("native");
  if (nativeSession) {
    const headers = base64ToUnknown(nativeSession) as Record<string, any>;
    request.headers[HttpHeaderKey.SID] = headers[HttpHeaderKey.SID];
    request.headers[HttpHeaderKey.DEVICE_ID] = headers[HttpHeaderKey.DEVICE_ID];
    request.headers[HttpHeaderKey.COUNTRY_CODE] =
      headers[HttpHeaderKey.COUNTRY_CODE];
    request.headers[HttpHeaderKey.REQ_TIME] = new Date().getTime().toString();
    request.headers[HttpHeaderKey.TIME_ZONE] = -new Date()
      .getTimezoneOffset()
      .toString();
    request.headers[HttpHeaderKey.TIME_ZONE_ID] =
      Intl.DateTimeFormat().resolvedOptions().timeZone;
    request.headers[HttpHeaderKey.NONCE] = genUUID();
    request.headers[HttpHeaderKey.DEVICE_ID3] =
      headers[HttpHeaderKey.DEVICE_ID3];
    request.headers[HttpHeaderKey.APP_VERSION] =
      headers[HttpHeaderKey.APP_VERSION];
    request.headers["hjtrfs"] = headers["hjtrfs"];
    request.headers[HttpHeaderKey.FLAVOR] = headers[HttpHeaderKey.FLAVOR];
    return request;
  }

  request.headers[HttpHeaderKey.SID] = (await getSession())?.sid;
  request.headers[HttpHeaderKey.DEVICE_ID] = await hashDeviceId(getDeviceId());
  request.headers[HttpHeaderKey.COUNTRY_CODE] = Intl.DateTimeFormat()
    .resolvedOptions()
    .locale.split("-")[1];
  request.headers[HttpHeaderKey.REQ_TIME] = new Date().getTime().toString();

  // Negation: Since Kotlin's getOffset() provides a positive offset for time zones ahead of UTC, you need to negate the value returned by getTimezoneOffset() to align with that logic.
  request.headers[HttpHeaderKey.TIME_ZONE] = -new Date()
    .getTimezoneOffset()
    .toString();
  request.headers[HttpHeaderKey.TIME_ZONE_ID] =
    Intl.DateTimeFormat().resolvedOptions().timeZone;
  request.headers[HttpHeaderKey.NONCE] = genUUID();

  const deviceId3 = await getAntiFraudDeviceId();
  if (deviceId3.length <= 100) {
    request.headers[HttpHeaderKey.DEVICE_ID3] = deviceId3;
  }

  return signRequest(request);
}

function getOSType() {
  const os = detect()?.os?.toLowerCase();
  if (!os) return 0;
  if (os.includes("android")) {
    return 2;
  } else if (os.includes("mac")) {
    return 3;
  } else if (os.includes("ios")) {
    return 1;
  } else if (os.includes("windows")) {
    return 4;
  } else {
    return 0;
  }
}

const defaultConfig = {
  baseURL: BASE_URL,
  timeout: 10000,
  headers: {
    appType: "CloverApp",
    appVersion: "1000.0.1",
    deviceType: "1",
    osType: getOSType().toString(),
    appPlatform: "3",
  },
};

function handleError(error: any) {
  if (
    isAxiosError(error) &&
    error.response &&
    (error.response.status < 200 || error.response.status > 299)
  ) {
    try {
      const apiError = arrayBufferToObj(APIErrorRespBody, error.response.data);

      return Promise.reject({
        name: "APIError" as const,
        message: apiError.apiMsg,
        apiCode: apiError.apiCode,
        debugMessage: apiError.debugMsg,
        redirectUrl: apiError.redirectUrl,
        stack: error.stack,
      });
    } catch (e) {
      return Promise.reject(error);
    }
  } else {
    return Promise.reject(error);
  }
}

async function reauth(
  reauthClient: AxiosInstance,
  session: AuthSession,
  addSession: (session: AuthSession, selects: boolean) => Promise<void>,
  removeSession: (uid: bigint) => Promise<void>,
) {
  try {
    const response = await reauthClient.post(
      "/v1/auth/login",
      utf8ToArrayBuffer(
        JSONUtil.stringify({
          authType: AuthType.PToken,
          secret: session.ptoken,
        }),
      ),
      {
        responseType: "arraybuffer",
        headers: { "Content-Type": "application/json;charset=utf-8" },
      },
    );
    const auth = arrayBufferToObj(
      z.object({
        sId: z.string().min(1),
        userProfile: User.optional(),
      }),
      response.data,
    );

    const newSession = {
      sid: auth.sId,
      ptoken: session.ptoken,
      uid: session.uid,
    };
    await addSession(newSession, true);
  } catch (e) {
    if (isRejectable(e) && e.apiCode === APIErrorCode.APICodePTokenError) {
      await removeSession(session.uid);
    }
    throw e;
  }
}

type PromiseHolder = {
  value: Promise<void> | undefined;
};

async function reauthIfNeeded(
  reauthingTask: PromiseHolder,
  reauthClient: AxiosInstance,
  client: AxiosInstance,
  error: AxiosError,
  getSession: () => Promise<AuthSession | undefined>,
  addSession: (session: AuthSession, selects: boolean) => Promise<void>,
  removeSession: (uid: bigint) => Promise<void>,
) {
  const config = error.config;
  if (!config) return handleError(error);

  if ((config as any).__isRetryRequest) return handleError(error);

  const session = await getSession();
  if (session) {
    (config as any).__isRetryRequest = true;

    // avoid reauth multiple times at the same time
    if (!reauthingTask.value) {
      reauthingTask.value = reauth(
        reauthClient,
        session,
        addSession,
        removeSession,
      );
      try {
        await reauthingTask.value;
      } finally {
        reauthingTask.value = undefined;
      }
    } else {
      await reauthingTask.value;
    }

    return client(config);
  } else {
    return handleError(error);
  }
}

export function HttpClientService(props: PropsWithChildren<{}>) {
  const sessionService = useAuthSessionService();

  const getSession = useCallback(async () => {
    if (sessionService.myUid === undefined) {
      return undefined;
    }
    return await sessionService.get(sessionService.myUid);
  }, [sessionService.myUid, sessionService.get]);

  const client = useMemo(() => {
    const theClient = axios.create(defaultConfig);
    theClient.interceptors.request.use(
      (config) => composeHeader(config, getSession),
      (error) => Promise.reject(error),
    );

    const reauthClient = axios.create(defaultConfig);

    reauthClient.interceptors.request.use(
      (config) => composeHeader(config, getSession),
      (error) => Promise.reject(error),
    );
    reauthClient.interceptors.response.use((r) => r, handleError);

    const reauthingTask = {
      value: undefined,
    };

    theClient.interceptors.response.use(
      (r) => r,
      async (error) => {
        if (isAxiosError(error) && error.status === 401) {
          return await reauthIfNeeded(
            reauthingTask,
            reauthClient,
            theClient,
            error,
            getSession,
            sessionService.add,
            sessionService.remove,
          );
        } else {
          return handleError(error);
        }
      },
    );

    return theClient;
  }, [getSession, sessionService.add, sessionService.remove]);

  return (
    <Context.Provider
      value={{
        get: async (url: string) => {
          const response = await client.get(url, {
            responseType: "arraybuffer",
          });
          assert(response.data instanceof ArrayBuffer);
          const data = response.data;
          return arrayBufferToBase64(data);
        },
        delete: async (url: string, bodyStr: string) => {
          const response = await client.delete(url, {
            data: utf8ToArrayBuffer(bodyStr),
            responseType: "arraybuffer",
          });
          assert(response.data instanceof ArrayBuffer);
          const data = response.data;
          return arrayBufferToBase64(data);
        },
        post: async (url: string, bodyStr: string) => {
          const response = await client.post(url, utf8ToArrayBuffer(bodyStr), {
            responseType: "arraybuffer",
            headers: { "Content-Type": "application/json;charset=utf-8" },
          });
          assert(response.data instanceof ArrayBuffer);
          const data = response.data;
          return arrayBufferToBase64(data);
        },
        sendLocalMedia: async (
          localMedia: LocalMedia,
          scene: MediaScene,
          progressListener: (uploaded: bigint, total: bigint) => void,
        ) => {
          assert(localMedia instanceof Blob);
          const formData = new FormData();
          formData.append("media", localMedia);

          const url = urlAppendQuery("/v1/media/upload", {
            scene: scene,
          });
          const response = await client.post(url, formData, {
            responseType: "arraybuffer",
            headers: { "Content-Type": "multipart/form-data" },
          });

          const data = response.data;
          const base64 = arrayBufferToBase64(data);
          return base64ToTyped(base64, Media);
        },
      }}
    >
      {props.children}
    </Context.Provider>
  );
}

export function ExternalHttpClientService(props: PropsWithChildren<{}>) {
  const externalHttpClient = useMemo(() => {
    return getBridgeHook<{
      get: (url: string) => Promise<string>;
      delete: (url: string) => Promise<string>;
      post: (url: string, body: string) => Promise<string>;
      sendLocalMedia: (
        localMedia: LocalMedia,
        scene: MediaScene,
        progressListener: (uploaded: bigint, total: bigint) => void,
      ) => Promise<Media>;
    }>(Bridge.getInstance(), "backend");
  }, []);

  return (
    <Context.Provider value={externalHttpClient}>
      {props.children}
    </Context.Provider>
  );
}

export function useHttpClient() {
  return useContext(Context);
}
