import styled from "styled-components";
import chevron from "../../src/res/images/icon_section_header_show_more.svg";
import { Image } from "./Image";
import { HStack } from "./VStack";
import { SingleLineUnspecifiedWidth } from "./CommonViews";

export enum SectionHeaderEndIcon {
  Chevron = 1,
  Edit = 2,
}

export interface SectionHeaderProps {
  title: string;
  onClickMore?: () => void;
  endIcon?: SectionHeaderEndIcon;
}

function SectionHeader(props: SectionHeaderProps) {
  return (
    <HStack
      style={{
        justifyContent: "space-between",
        alignItems: "center",
      }}
      onClick={props.onClickMore}
    >
      <SectionHeaderTitle style={{ maxWidth: "100%" }}>
        {props.title}
      </SectionHeaderTitle>
      {props.endIcon === SectionHeaderEndIcon.Chevron && (
        <Image src={chevron} width={24} height={24} />
      )}
    </HStack>
  );
}

const SectionHeaderTitle = styled.div`
  font-weight: 800;
  color: var(--color-text00);
  ${SingleLineUnspecifiedWidth};
  font-size: 16px;
  text-overflow: ellipsis;
`;

export default SectionHeader;
