import { HStack } from "./VStack";
import { Image } from "./Image";
import indicatorGray from "../res/images/indicator_gray.svg";
import indicatorWhite from "../res/images/indicator_white.svg";

export interface ViewPagerIndicatorProps {
  pageSize: number;
  currentIndex: number;
}

function ViewPagerIndicator({
  pageSize,
  currentIndex,
}: ViewPagerIndicatorProps) {
  return (
    <HStack style={{ gap: 2 }}>
      {Array.from({ length: pageSize }).map((_, index) => (
        <Image
          key={index}
          src={index === currentIndex ? indicatorWhite : indicatorGray}
          width={20}
          height={1.6}
          alt={`Indicator ${index + 1}`}
        />
      ))}
    </HStack>
  );
}

export default ViewPagerIndicator;
