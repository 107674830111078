import { useI18n } from "../../../hooks/useI18n";
import React from "react";
import { Page } from "../../../components/Page";
import { SetPasswordType } from "../../auth/SetPasswordPage";
import SettingCell from "../../../components/SettingCell";
import { useHopper } from "../../../hooks/useHopper";
import { NavMiddle } from "../../../components/NavBar";
import { AuxPage } from "../../../components/AuxPage";

export function SecurityCenterPage() {
  const i18n = useI18n();
  const hopper = useHopper();

  return (
    <Page pageData={undefined} scrollPaddingDisabled={true}>
      <NavMiddle>{i18n.web3_v0_security_center()}</NavMiddle>
      <SettingCell
        title={i18n.web3_v0_log_in_password()}
        onClick={() => {
          hopper.push("change-password", {
            setPasswordType: SetPasswordType.Change,
          });
        }}
      />

      <AuxPage url={"aux-settings"} position={"start"} />
    </Page>
  );
}
