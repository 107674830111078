import { createContext, useContext, useLayoutEffect } from "react";

export type WideLayout = "threeColumn" | "twin" | "fullWidth";

interface WideLayoutContext {
  setWideLayout: (layout: WideLayout) => void;
}

export const WideLayoutContext = createContext<WideLayoutContext>({
  setWideLayout: () => {},
});

export function useWideLayout(layout: WideLayout) {
  const context = useContext(WideLayoutContext);
  useLayoutEffect(() => {
    context.setWideLayout(layout);
  }, [layout]);
}
