import React from "react";
import { ZDoc } from "../../../components/zdoc/ZDoc";
import { useI18n } from "../../../hooks/useI18n";
import { NFT } from "../../../proto/NFT";
import styled from "styled-components";
import { SpeedTitle } from "../../../components/SpeedTitle";

const NoContent = styled.div`
  margin-top: 12px;
  font-size: 14px;
  opacity: 0.4;
`;

export function NFTDescriptionView(props: { nft: NFT }) {
  const i18n = useI18n();
  return (
    <>
      <SpeedTitle>{i18n.description()}</SpeedTitle>
      {(props.nft.entity?.description?.length ?? 0) > 0 ? (
        <ZDoc
          content={props.nft.entity?.description}
          mediaList={undefined}
          pollList={undefined}
          onViewMedia={() => {}}
          richFormat={props.nft.entity?.richFormat}
        />
      ) : (
        <NoContent>{i18n.no_content_yet()}</NoContent>
      )}
    </>
  );
}
