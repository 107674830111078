import styled from "styled-components";

export interface ThemeColorPreviewProps {
  themeColor?: string;
  width: number;
  height: number;
}

const PreviewContainer = styled.div<ThemeColorPreviewProps>`
  position: relative; // 使伪元素相对定位
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background-color: ${({ themeColor }) => themeColor || "transparent"};

  // 添加红线
  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 2px; // 红线的宽度
    background-color: red; // 红线颜色
    width: 141%; // 斜线的宽度，稍微大于容器
    transform: rotate(-45deg); // 旋转45度
    transform-origin: top right; // 设置旋转的中心点
    opacity: ${({ themeColor }) =>
      themeColor ? 0 : 1}; // 仅在无 themeColor 时显示
  }
`;

function CircleThemeColorPreview({
  themeColor,
  width,
  height,
}: ThemeColorPreviewProps) {
  return (
    <PreviewContainer themeColor={themeColor} width={width} height={height} />
  );
}

export default CircleThemeColorPreview;
