import { useI18n } from "../../hooks/useI18n";
import { Page } from "../../components/Page";
import { useHopper } from "../../hooks/useHopper";
import React from "react";
import SettingCell from "../../components/SettingCell";
import { NavMiddle } from "../../components/NavBar";
import { useWebHost } from "../../hooks/useBridge";
import { andLog } from "../../components/handleError";

export function FeedBackPage() {
  const i18n = useI18n();
  const hopper = useHopper();
  const webHost = useWebHost();

  const supportEmail = "support@clover.space";
  const bugEmail = "bug@clover.space";
  const feedbackInfo = "";
  const handleSendMail = (
    title: string,
    body: string = feedbackInfo,
    mailto: string = supportEmail,
  ) => {
    const mailToLink = `mailto:${mailto}?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(body)}`;
    webHost.openInWebBrowser(mailToLink).catch(andLog);
  };

  return (
    <Page scrollPaddingDisabled={true} pageData={undefined}>
      <NavMiddle>{i18n.feedback()}</NavMiddle>
      <SettingCell
        title={i18n.help_center()}
        onClick={() => hopper.push("help-center")}
      ></SettingCell>
      <SettingCell
        title={i18n.suggestions()}
        onClick={() =>
          handleSendMail(
            "[SUGGESTION]",
            i18n.suggestion_content() + feedbackInfo,
          )
        }
      ></SettingCell>
      <SettingCell
        title={i18n.help_split_safety_concern()}
        onClick={() => handleSendMail("[SAFETY]")}
      ></SettingCell>
      <SettingCell
        title={i18n.help_split_appeal()}
        onClick={() => handleSendMail("[APPEAL]")}
      ></SettingCell>
      <SettingCell
        title={i18n.report_bugs()}
        onClick={() => handleSendMail("[REPORT-BUGS]", feedbackInfo, bugEmail)}
      ></SettingCell>
    </Page>
  );
}
