import { HStack, VStack } from "../../../components/VStack";
import {
  Currency,
  formatMoney,
  getCurrencyName,
} from "../../../proto/Currency";
import { Image } from "../../../components/Image";
import styled from "styled-components";
import { useI18n } from "../../../hooks/useI18n";
import chevron from "../../../res/images/icon_section_header_show_more.svg";
import {
  cell_on_pressed,
  full_page_width_cell,
} from "../../../components/CommonStyles";

export interface CurrencyCellProps {
  currency: Currency;
  onClick: () => void;
}

function CurrencyCell(props: CurrencyCellProps) {
  const i18n = useI18n();
  return (
    <HStack
      mixin={[cell_on_pressed, full_page_width_cell]}
      style={{
        justifyContent: "space-between",
        height: 70,
        borderBottom: "0.5px solid rgba(255, 255, 255, 0.16)",
      }}
      onClick={() => props.onClick()}
    >
      <HStack style={{ gap: 12, alignItems: "start" }}>
        <Image
          src={[props.currency.icon, "smallest"]}
          style={{ marginTop: 2 }}
          width={26}
          height={26}
        />
        <VStack style={{ gap: 6 }}>
          <TokenName>{getCurrencyName(props.currency, i18n)}</TokenName>
          <ChainName>{i18n.clover_solana_chain()}</ChainName>
        </VStack>
      </HStack>

      <HStack style={{ gap: 6 }}>
        <TokenAmount>{formatMoney("medium", props.currency)}</TokenAmount>
        <Image src={chevron} width={24} height={24} />
      </HStack>
    </HStack>
  );
}

const ChainName = styled.div`
  color: var(--color-text20);
  font-size: 12px;
  font-weight: 400;
`;

const TokenName = styled.div`
  color: var(--color-text00);
  font-size: 18px;
  font-weight: 400;
`;

const TokenAmount = styled.div`
  color: var(--color-text00);
  font-size: 22px;
  font-weight: 500;
`;

export default CurrencyCell;
