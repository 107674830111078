import styled, { css, RuleSet } from "styled-components";
import { HStack, VStack } from "./VStack";
import React, { HTMLInputTypeAttribute, useId, useMemo, useState } from "react";
import Grapheme from "grapheme-splitter";
import { Image } from "./Image";
import eyeOpen from "../../src/res/images/ic_eye_open.svg";
import eyeClosed from "../../src/res/images/ic_eye_closed.svg";
import { RelativeContainer } from "../pages/circle/CircleHomePage";

const Label = styled.label<{ mixin?: RuleSet<Object> }>`
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;

  opacity: 0.8;
  ${(p) => p.mixin}
`;

export const Input = styled.input<{ mixin?: RuleSet<Object> }>`
  display: flex;
  height: 50px;
  align-items: center;
  flex-shrink: 0;
  padding: 0 16px;
  align-self: stretch;
  background: none;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;

  cursor: text;
  border: none;
  ${(p) => p.mixin}

  /* 针对自动填充的样式 */
  &:-webkit-autofill {
    -webkit-text-fill-color: #fff !important; /* 自动填充文字颜色 */
    background-color: transparent !important; /* 背景透明 */
    box-shadow: 0 0 0 1000px transparent inset !important; /* 移除默认背景 */
    transition: background-color 9999s ease-out 0.5s; /* 非常长的过渡时间隐藏颜色改变 */
  }
`;

export const TextArea = styled.textarea<{ mixin?: RuleSet<Object> }>`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding: 12px 16px;
  align-self: stretch;
  background: none;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  cursor: text;
  border: none;
  resize: none;
  overflow-y: auto;
  outline: none;
  ${(p) => p.mixin};

  /* 隐藏滚动条 */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 和 Edge */

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari 和 Edge */
  }
`;

const CharCount = styled.div`
  font-size: 12px;
  flex-shrink: 0;
  margin-right: 16px;
`;

type CurrentCountProps = {
  $isOverLimit: boolean;
};

const CurrentCount = styled.span<CurrentCountProps>`
  color: ${(props) => (props.$isOverLimit ? "red" : "rgba(255,255,255,0.4)")};
`;

const MaxCount = styled.span`
  color: rgba(255, 255, 255, 0.4);
`;

const RegionCode = styled.div`
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-left: 20px;
  margin-right: 10px;
`;

export const Divider = styled.div`
  background: #fff;
  height: 10px;
  width: 1px;
`;

export function RegularInputGroup(props: {
  style?: RuleSet<Object>;
  placeholder?: string;
  value?: string;
  label?: string;
  autoComplete?: string;
  type?: HTMLInputTypeAttribute;
  startReactNode?: React.ReactNode;
  maxLength?: number;
  updateValue: (newValue: string) => void;
  endReactNode?: React.ReactNode;
  multilineRows?: number;
}) {
  const id = useId();
  const textLength = useMemo(() => {
    const TextLengthHelper = new Grapheme();
    return TextLengthHelper.splitGraphemes(props.value ?? "").length;
  }, [props.value]);
  const maxLength = props.maxLength;
  const [passwordVisible, setPasswordVisible] = useState(false);

  return (
    <VStack
      mixin={css`
        align-items: flex-start;
        ${props.style}
      `}
    >
      <Label htmlFor={id}>{props.label}</Label>

      <VStack
        mixin={css`
          border-radius: 6px;
          background: rgba(255, 255, 255, 0.08);
          align-self: stretch;
          margin-top: 12px;
        `}
      >
        <HStack>
          {props.startReactNode}
          {props.multilineRows && props.multilineRows > 1 ? (
            <TextArea
              autoComplete="off"
              autoCorrect="off"
              spellCheck={false}
              value={props.value}
              placeholder={props.placeholder}
              onChange={(e) => props.updateValue(e.target.value)}
              id={id}
              rows={props.multilineRows}
              maxLength={maxLength}
              mixin={css`
                flex-grow: 1;
              `}
            />
          ) : (
            <Input
              value={props.value}
              type={
                props.type === "password"
                  ? passwordVisible
                    ? "text"
                    : "password"
                  : props.type
              }
              autoComplete={props.autoComplete}
              placeholder={props.placeholder}
              onChange={(e) => props.updateValue(e.target.value)}
              id={id}
              mixin={css`
                flex-grow: 1;
              `}
            />
          )}
          {props.endReactNode}
          {props.type === "password" && (
            <Image
              style={{ flexShrink: "0", marginRight: "16px" }}
              src={passwordVisible ? eyeOpen : eyeClosed}
              alt={"PasswordToggleIcon"}
              width={24}
              height={24}
              onClick={() => {
                setPasswordVisible(!passwordVisible);
              }}
            />
          )}
          {maxLength !== undefined &&
            (props.multilineRows === undefined ||
              (props.multilineRows && props.multilineRows <= 1)) && (
              <CharCount>
                <CurrentCount $isOverLimit={textLength > maxLength}>
                  {textLength}
                </CurrentCount>
                <MaxCount>{"/" + maxLength}</MaxCount>
              </CharCount>
            )}
        </HStack>
        {maxLength !== undefined &&
          props.multilineRows &&
          props.multilineRows > 1 && (
            <RelativeContainer style={{ height: 24 }}>
              <CharCount style={{ position: "absolute", bottom: 2, right: 0 }}>
                <CurrentCount $isOverLimit={textLength > maxLength}>
                  {textLength}
                </CurrentCount>
                <MaxCount>{"/" + maxLength}</MaxCount>
              </CharCount>
            </RelativeContainer>
          )}
      </VStack>
    </VStack>
  );
}

export function PhoneNumberInput(props: {
  style?: RuleSet<Object>;
  placeholder?: string;
  value?: string;
  label?: string;
  type?: HTMLInputTypeAttribute;
  maxLength?: number;
  updateValue: (newValue: string) => void;
  regionCode?: string;
  regionCodeOnClick?: () => void;
}) {
  return (
    <RegularInputGroup
      style={props.style}
      placeholder={props.placeholder}
      value={props.value}
      label={props.label}
      type={props.type}
      startReactNode={
        <HStack>
          <RegionCode onClick={props.regionCodeOnClick}>
            {props.regionCode}
          </RegionCode>
          <Divider></Divider>
        </HStack>
      }
      maxLength={props.maxLength}
      updateValue={props.updateValue}
    ></RegularInputGroup>
  );
}
