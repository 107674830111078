import { Page } from "../../../components/Page";
import LV0 from "../../../res/images/icon_level_0.svg";
import LV1 from "../../../res/images/icon_level_1.svg";
import LV2 from "../../../res/images/icon_level_2.svg";
import LV3 from "../../../res/images/icon_level_3.svg";
import LV4 from "../../../res/images/icon_level_4.svg";
import LV5 from "../../../res/images/icon_level_5.svg";
import LV6 from "../../../res/images/icon_level_6.svg";
import LV7 from "../../../res/images/icon_level_7.svg";
import LV8 from "../../../res/images/icon_level_8.svg";
import LV9 from "../../../res/images/icon_level_9.svg";
import LV10 from "../../../res/images/icon_level_10.svg";
import LVAdmin from "../../../res/images/icon_level_admin.svg";
import IcSelected from "../../../res/images/icon_green_selected.svg";
import { Image } from "../../../components/Image";
import { CenterChild, HStack, Spring } from "../../../components/VStack";
import { CellDivider } from "../../../components/ContentGroup";
import {
  useEnumSearchParam,
  useNumberSearchParam,
} from "../../../hooks/useTypedParam";
import { NavMiddle } from "../../../components/NavBar";
import { useMemo } from "react";
import { alpha_on_pressed } from "../../../components/CommonStyles";
import { I18n, useI18n } from "../../../hooks/useI18n";
import { useProduceResult } from "../../../hooks/useResult";
import { useHopper } from "../../../hooks/useHopper";
import { andLog } from "../../../components/handleError";

export function CircleMembershipLevelIcon(props: {
  level: number;
  width: number | string;
  adminSize?: number | string;
  style?: React.CSSProperties;
  hideAdminLevel?: boolean;
}) {
  let icon: string | undefined = LV0;
  if (props.level === 1) {
    icon = LV1;
  } else if (props.level === 2) {
    icon = LV2;
  } else if (props.level === 3) {
    icon = LV3;
  } else if (props.level === 4) {
    icon = LV4;
  } else if (props.level === 5) {
    icon = LV5;
  } else if (props.level === 6) {
    icon = LV6;
  } else if (props.level === 7) {
    icon = LV7;
  } else if (props.level === 8) {
    icon = LV8;
  } else if (props.level === 9) {
    icon = LV9;
  } else if (props.level === 10) {
    icon = LV10;
  } else if (props.level === 999) {
    icon = props.hideAdminLevel ? undefined : LVAdmin;
  }

  if (props.level === 999 && props.hideAdminLevel) {
    return null;
  }
  return (
    <CenterChild style={{ position: "relative", ...(props.style || {}) }}>
      <object
        data={icon}
        style={{
          width:
            props.level === 999 && props.adminSize !== undefined
              ? props.adminSize
              : props.width,
          ...props.style,
        }}
      />
    </CenterChild>
  );
}

function MembershipLevelCell(props: {
  level: number;
  isSelected: boolean;
  onClick: () => void;
}) {
  const i18n = useI18n();
  return (
    <>
      <HStack
        style={{ height: "80px", gap: "8px" }}
        mixin={[alpha_on_pressed]}
        onClick={props.onClick}
      >
        <CircleMembershipLevelIcon level={props.level} width={48} />
        <div style={{ fontSize: "16px" }}>
          {i18n.clover_membership_level_value(`${props.level}`)}
        </div>
        <Spring />
        {props.isSelected ? <Image src={IcSelected} /> : undefined}
      </HStack>
      <CellDivider />
    </>
  );
}

export enum CircleMembershipLevelPickerType {
  ViewLimit = 1,
  SubmitLimit = 2,
}

function titleForMembershipLevelPicker(
  i18n: I18n,
  type?: CircleMembershipLevelPickerType,
) {
  if (type === CircleMembershipLevelPickerType.ViewLimit) {
    return i18n.clover_select_min_membership_level();
  }
  return "";
}

export function CircleMembershipLevelPicker() {
  const currentLevel = useNumberSearchParam("currentLevel");
  const type = useEnumSearchParam<CircleMembershipLevelPickerType>("type");

  const i18n = useI18n();
  const hopper = useHopper();
  const title = titleForMembershipLevelPicker(i18n, type);

  const produce = useProduceResult<number>("CircleMembershipLevelPicker");

  const items = useMemo(() => {
    return Array.from({ length: 10 }, (_, index) => (
      <MembershipLevelCell
        level={index + 1}
        isSelected={index + 1 === currentLevel}
        onClick={() => {
          produce
            .fill(index + 1)
            .then(() => hopper.back())
            .catch(andLog);
        }}
      />
    ));
  }, []);

  return (
    <Page pageData={undefined}>
      <NavMiddle>{title}</NavMiddle>
      {items}
    </Page>
  );
}
