import React, { ReactElement, useEffect, useState } from "react";
import CircleInfoPage from "./CircleInfoPage";
import CircleExplorePage from "./CircleExplorePage";
import CirclePostsPage from "./CirclePostsPage";
import CircleChatsPage from "./CircleChatsPage";
import { useI18n } from "../../hooks/useI18n";
import {
  useBigIntParam,
  useBooleanSearchParam,
} from "../../hooks/useTypedParam";
import { useNativePage } from "../../hooks/useBridge";
import { useHopper } from "../../hooks/useHopper";
import { useSWR } from "../../hooks/swr/useSWR";
import { Image } from "../../components/Image";
import iconAnnouncement from "../../res/images/icon_circle_announcement.svg";
import iconCompose from "../../res/images/icon_circle_compose.png";
import iconMenu from "../../res/images/icon_circle_menu.png";
import iconLock from "../../res/images/img_circle_join_lock.png";
import iconCollapseMenu from "../../res/images/icon_circle_collapse_menu.png";
import iconExpandMenu from "../../res/images/icon_circle_expand_menu.png";
import iconLeftStar from "../../res/images/icon_welcome_left_star.svg";
import iconRightStar from "../../res/images/icon_welcome_right_star.svg";
import iconChecked from "../../res/images/ic_checked.svg";
import iconUnchecked from "../../res/images/ic_unchecked.svg";
import styled, { css, keyframes } from "styled-components";
import CircleFolderPage from "./CircleFolderPage";
import { ObjectType } from "../../proto/ObjectSpec";
import CircleLinkPage from "./CircleLinkPage";
import { HStack, Spring, VStack } from "../../components/VStack";
import { CircleMemberStatus } from "../../proto/CircleMemberStatus";
import { ButtonColor, RegularButton } from "../../components/Buttons";
import { SingleLineUnspecifiedWidth } from "../../components/CommonViews";
import { useLoadState } from "../../hooks/LoadState";
import { andLog, useErrorHandler } from "../../components/handleError";
import {
  CircleJoinPermission,
  JoinCircleBody,
  JoinCircleMethod,
} from "./JoinCircleRequest";
import { Spin } from "../../components/Spin";
import { HSpacing } from "../../components/Spacing";
import { useMyUid } from "../../service/AuthSessionService";
import { useBackend } from "../../service/APIService";
import { useObscuredZoneForKey } from "../../hooks/useObscuredZones";
import { accent_font, RowCenterButton } from "../../components/CommonStyles";
import { useModal } from "../../components/Modal";
import { Divider } from "../../components/Input";
import {
  ViewPagerPage,
  ViewPagerTabLayout,
} from "../../components/ViewPagerPage";
import { subpages } from "../../components/Subpage";
import {
  CircleJoinConditionType,
  CircleTabItem,
  isCircleAdmin,
} from "../../proto/Circle";
import { PageFooter } from "../../components/PageHeaderFooter";
import { useCircleHasAdminReminder } from "./compose/CreateCirclePage";
import { StateId } from "../../hooks/StateId";
import { useLocalRepo } from "../../hooks/swr/useLocalRepo";
import { useHopState } from "../../hooks/useHopState";
import iconQRCode from "../../res/images/qr_code.svg";
import { formatMoney } from "../../proto/Currency";
import BigNumber from "bignumber.js";
import CircleDetailInfoPage, {
  getCircleTokenStageImage,
  InfoTitleLabel,
  MarketCapLabel,
  NoCircleTokenYetLabel,
  TokenValNoCircleTokenYetBackground,
  TokenValPercentageChangeLabel,
  TokenValRectangleBackground,
} from "./CircleDetailInfoPage";
import LanguageCodes from "../../proto/LanguageCodes";
import { isValidCircleToken } from "../../proto/CircleFin";
import {
  circleTokenFluctuationImage,
  marketCap1HipColor,
  marketCap1HipString,
} from "./CircleStatsPage";
import { ObjectStatus } from "../../proto/ObjectStatus";
import { CircleHasBeenDeletedCircle } from "./CircleHasBeenDeletedCircle";
import { NavEnd, NavItem, NavStart } from "../../components/NavBar";
import { NavButtonType } from "../../bridge/NavButtonDesc";
import { BSMenu, BSMenuItem } from "../../components/BSMenu";
import { isInExternalAppShell } from "../../bridge/ExternalAppShellData";
import { copyTextToClipboard } from "../../utils/CopyText";
import { useGlobalSpinner } from "../../utils/globalSpinner";
import { getBestRes } from "../../proto/Media";
import { useTokenSWR } from "./useCircleSWR";
import { usePageSpec } from "../common/usePageSpec";
import {
  CheckBoxWithLabel,
  CheckBoxWithLabelProps,
} from "../auth/SetPasswordPage";
import iconHourglass from "../../res/images/ic_hourglass.png";
import { CircleTokenStatus } from "../../proto/CircleTokenStatus";

function CircleHomePage() {
  const circleId = useBigIntParam("circleId");
  const backend = useBackend();
  const handleError = useErrorHandler();

  const circleSWR = useSWR(backend.getCircle(circleId));

  const [isCollapsed, setIsCollapsed] = useState(false);

  const [obscuredZone, setObscuredZone] = useObscuredZoneForKey("Browser");

  const handleCollapse = () => {
    setIsCollapsed((prev) => !prev);
  };

  const getTabPage = (tabItem: CircleTabItem): ReactElement => {
    let page: ReactElement;
    if (tabItem.originalName === "Info") {
      page = (
        <CircleInfoPage
          circleId={circleId}
          folderId={tabItem.objectId}
          showTitle={false}
        />
      );
    } else if (tabItem.originalName === "Explore") {
      page = (
        <CircleExplorePage
          circleId={circleId}
          folderId={tabItem.objectId}
          showTitle={false}
        />
      );
    } else if (tabItem.originalName === "Posts") {
      page = (
        <CirclePostsPage
          circleId={circleId}
          folderId={tabItem.objectId}
          showTitle={false}
        />
      );
    } else if (tabItem.originalName === "Chats") {
      page = (
        <CircleChatsPage
          circleId={circleId}
          folderId={tabItem.objectId}
          showTitle={false}
        />
      );
    } else if (tabItem.objectType === ObjectType.LINK) {
      page = (
        <CircleLinkPage
          circleId={circleId}
          folderId={tabItem.objectId}
          tab={tabItem}
        />
      );
    } else if (tabItem.objectType === ObjectType.FOLDER) {
      page = (
        <CircleFolderPage circleId={circleId} folderId={tabItem.objectId} />
      );
    } else {
      page = <></>;
    }
    return page;
  };

  const i18n = useI18n();
  const [isJoined, setJoined] = useState<boolean>();
  const [isAdminOrCoAdmin, setAdminOrCoAdmin] = useState<boolean>();
  const [isAdmin, setAdmin] = useState<boolean>();

  const myUid = useMyUid();

  useEffect(() => {
    const circle = circleSWR.content;
    if (circle) {
      const joined = circle.joinedStatus === CircleMemberStatus.JOINED;
      if (joined) {
        const selfUid = String(myUid);
        setAdminOrCoAdmin(
          circle.adminIdList.map((it) => String(it)).includes(selfUid) ||
            circle.coAdminIdList.map((it) => String(it)).includes(selfUid),
        );
        setAdmin(circle.adminIdList.map((it) => String(it)).includes(selfUid));
      } else {
      }
      setJoined(joined);
    } else {
      setJoined(undefined);
    }
  }, [circleSWR.content]);

  const joinCircleTask = useLoadState();
  const nativePage = useNativePage();

  async function joinCircle(body: JoinCircleBody) {
    const r = await joinCircleTask.run(async () => {
      const circle = await backend.joinCircle(circleId, body).run();
      await circleSWR.fill(circle);
    });

    if (!r.success) {
      handleError(r.error);
    }
  }

  const showWelcomeModal = useModal("showWelcomeModal");

  const [receivePostState, setReceivePostState] = useState(false);
  const [receiveAnnounceState, setReceiveAnnounceState] = useState(false);
  const [showCircleInPublic, setShowCircleInPublic] = useState(false);
  const [welcomeAsDefault, setWelcomeAsDefault] = useState(false);

  const globalSpinner = useGlobalSpinner();

  async function shareLink() {
    try {
      globalSpinner(async () => {
        const path = `circle/${circleId}`;
        const linkModel = await backend.getShareLink(path).run();
        if (linkModel.shareLink) {
          await copyTextToClipboard(linkModel.shareLink);
          await nativePage.successHud(i18n.copied());
        }
      }).catch(andLog);
    } catch (e) {
      handleError(e);
    }
  }

  async function refreshCircleInfo() {
    const res = await joinCircleTask.run(async () => {
      return await backend.getCircle(circleId).run();
    });
    if (res.success) {
      return res.result;
    }
  }

  const removeTarget = useLoadState();

  async function removeCard(requestId: bigint) {
    const r = await removeTarget.run(async () => {
      const res = await backend.removeCircleCard(requestId).run();
    });

    if (!r.success) {
      handleError(r.error);
    }
  }

  const saveNotification = async () => {
    const body = {
      objectId: circleId,
      objectType: ObjectType.CIRCLE,
      extensions: {
        receiveCircleUpdate: receivePostState,
        notReceiveCircleAnnouncementPush: !receiveAnnounceState,
      },
    };

    await backend.subscribeObject(body).run();
    await updateCircleInProfile();
  };

  const noCircleTokenYetClick = () => {
    if (circleSWR.content && isCircleAdmin(circleSWR.content, myUid)) {
      hopper.push(`circle/${circleId}/admin-portal`);
    } else {
      nativePage
        .alertNotice(i18n.clover_circle_no_token_yet(), i18n.ok())
        .catch(andLog);
    }
  };

  const updateCircleInProfile = async () => {
    await backend
      .setVisibleCircle(circleId, { invisible: !showCircleInPublic })
      .run();
  };

  const hopper = useHopper();

  const entries: [CircleTabItem, string][] | undefined =
    circleSWR.content?.homeLayout?.tabList?.map((tabItem) => [
      tabItem,
      tabItem.name || "",
    ]);

  const circleHasAdminReminder = useCircleHasAdminReminder(circleId);

  const alertGoToAdminPortalCount = useAlertGoToAdminPortalCountOneDay([
    circleId,
    new Date().toLocaleDateString(),
  ]);

  const [hasAlertGoToAdminPortal, setHasAlertGoToAdminPortal] = useHopState(
    "hasAlertGoToAdminPortal",
    false,
  );
  const maxCountAlertGoToAdminPortalOneDay = 3;
  const sevenDayInMillis = 7 * 24 * 60 * 60 * 1000;

  useEffect(() => {
    if (
      circleSWR.content?.createdTime &&
      isAdmin &&
      alertGoToAdminPortalCount.content < maxCountAlertGoToAdminPortalOneDay &&
      Date.now() - circleSWR.content?.createdTime * 1000 < sevenDayInMillis &&
      !hasAlertGoToAdminPortal
    ) {
      setHasAlertGoToAdminPortal(true);
      alertGoToAdminPortalCount
        .fill((prevCount) => {
          return prevCount + 1;
        })
        .catch(andLog);
      hopper.modal(`circle/${circleId}/go-admin-portal`);
    }
  }, [
    isAdmin,
    alertGoToAdminPortalCount.content,
    circleSWR.content?.createdTime,
  ]);

  useEffect(() => {
    if (circleSWR.content?.joinedStatus == CircleMemberStatus.JOINED) {
      const requestId = circleSWR.content.joinRequest?.requestId;
      if (requestId !== undefined) {
        removeCard(requestId).catch(andLog);
        showWelcomeModal.open();
      }
    }
  }, [circleSWR.content]);

  const navMenu = useModal("navMenu");
  const isBrowse = useBooleanSearchParam("isBrowse", false);
  const tokenSWR = useTokenSWR(circleId);
  const pageSpec = usePageSpec();

  const showLock =
    circleSWR.content &&
    (circleSWR.content.joinPermission !== CircleJoinPermission.OPEN ||
      circleSWR.content.joinConditions?.some(
        (condition) =>
          condition.conditionType === CircleJoinConditionType.MembershipCard,
      ));

  const contentBackground = circleSWR.content?.circleBackground
    ?.backgroundImage ? (
    <BackgroundContainer>
      <BackgroundImage
        src={
          getBestRes(circleSWR.content?.circleBackground.backgroundImage).url
        }
      />
      <Dimmer />
    </BackgroundContainer>
  ) : undefined;

  return circleSWR.content?.status === ObjectStatus.DELETED ? (
    <CircleHasBeenDeletedCircle></CircleHasBeenDeletedCircle>
  ) : !isJoined && !isBrowse ? (
    <CircleDetailInfoPage></CircleDetailInfoPage>
  ) : (
    <>
      {pageSpec !== "wide" && (
        <NavStart>
          {NavItem.image(NavButtonType.More, () => {
            hopper.endSide(`circle/${circleId}/menu`);
          })}
        </NavStart>
      )}
      <NavEnd>
        {NavItem.image(NavButtonType.Ellipsis, () => {
          navMenu.open();
        })}
        {NavItem.image(NavButtonType.Cross, () => {
          hopper.back();
        })}
      </NavEnd>
      <ViewPagerPage
        subpages={subpages<CircleTabItem>(entries ?? [], (tabItem) => {
          return getTabPage(tabItem);
        })}
        contentBackground={contentBackground}
      >
        <VerticalContainer>
          <HorizontalContainer>
            <HStack style={{ alignItems: "start", marginTop: 6 }}>
              <Image
                src={[circleSWR.content?.circleIcon, 100]}
                style={{
                  borderRadius: 6,
                  flexGrow: 1,
                  border: `1px solid ${circleSWR.content?.themeColor}`,
                }}
                width={100}
                height={100}
                onClick={() => {
                  // circleSWR.content?.circleIcon &&
                  //   nativePage.viewMedia([circleSWR.content?.circleIcon], 0);
                  hopper.present(`circle/${circleId}/detail-info`);
                }}
              />
            </HStack>
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                gap: 4,
              }}
            >
              <CircleName>{circleSWR.content?.name}</CircleName>
              <HStack style={{ gap: 4 }}>
                <Image
                  src={iconQRCode}
                  alt={"iconQRCode"}
                  width={20}
                  height={20}
                  onClick={() => {
                    hopper.modal(`circle/${circleId}/qr-code-share`);
                  }}
                />
                <CircleID>{`c/${circleSWR.content?.socialId || "-"}`}</CircleID>
                <Divider />
                <CircleLanguage>
                  {LanguageCodes.localizedStringForCurrentAppLanguage(
                    circleSWR.content?.language || "en",
                  )}
                </CircleLanguage>
              </HStack>
              {circleSWR.content?.memberPreview && (
                <HStack
                  style={{ marginInlineStart: 6, marginTop: 4 }}
                  onClick={() => {
                    hopper.push(`circle/${circleId}/members`);
                  }}
                >
                  {circleSWR.content.memberPreview.slice(0, 4).map((user) => (
                    <Image
                      src={[user.icon, "smallest"]}
                      width={20}
                      height={20}
                      style={{
                        borderRadius: "50%",
                        marginInlineStart: -6,
                        border: "0.5px solid white",
                      }}
                    />
                  ))}
                  <HSpacing width={6} />
                  <MembersCountText>
                    {i18n.ext_social_members(
                      (circleSWR.content?.membersCount ?? 0).toString(),
                    )}
                  </MembersCountText>
                </HStack>
              )}
            </div>
          </HorizontalContainer>

          {(!isValidCircleToken(tokenSWR.content) ||
            (isValidCircleToken(tokenSWR.content) &&
              tokenSWR.content.status === CircleTokenStatus.Pending)) && (
            <TokenValNoCircleTokenYetBackground
              onClick={noCircleTokenYetClick}
              style={{ marginTop: 12 }}
            >
              <Image
                src={iconHourglass}
                width={12}
                height={12}
                alt={"iconHourglass"}
              ></Image>
              <NoCircleTokenYetLabel>
                {i18n.clover_no_circle_token_yet()}
              </NoCircleTokenYetLabel>
            </TokenValNoCircleTokenYetBackground>
          )}

          {isValidCircleToken(tokenSWR.content) &&
            tokenSWR.content.status !== CircleTokenStatus.Pending && (
              <TokenValRectangleBackground
                style={{ width: "calc(100% - 18px)", marginTop: 12 }}
                onClick={() => {
                  hopper.push(`circle/${circleId}/token-info`);
                }}
              >
                <HStack>
                  <Image
                    alt={"tokenInfoIcon"}
                    src={[tokenSWR.content.image, "best"]}
                    width={24}
                    height={24}
                    style={{ borderRadius: "2px", marginInlineEnd: 4 }}
                  ></Image>
                  <TokenTickerLabel>
                    {isValidCircleToken(tokenSWR.content) &&
                      tokenSWR.content.ticker}
                  </TokenTickerLabel>
                  <Image
                    style={{ marginInlineStart: 4 }}
                    src={getCircleTokenStageImage(tokenSWR.content)}
                    width={12}
                    height={12}
                  ></Image>
                  <Spring />
                  <VStack style={{ alignItems: "end" }}>
                    <HStack style={{ gap: 4 }}>
                      <InfoTitleLabel>
                        {i18n.moon_ads_market_cap()}
                      </InfoTitleLabel>
                      <MarketCapLabel>
                        $
                        {isValidCircleToken(tokenSWR.content) &&
                          formatMoney(
                            "short",
                            BigNumber(
                              tokenSWR.content.tradingStatistics?.marketCap ||
                                "0",
                            ),
                          )}
                      </MarketCapLabel>
                    </HStack>
                    <TokenValPercentageChangeLabel
                      style={{
                        color: marketCap1HipColor(tokenSWR.content),
                      }}
                    >
                      <Image
                        src={circleTokenFluctuationImage(tokenSWR.content)}
                        alt={"PercentageChangeDirection"}
                        width={12}
                        height={12}
                      ></Image>
                      {marketCap1HipString(tokenSWR.content)}
                    </TokenValPercentageChangeLabel>
                  </VStack>
                </HStack>
              </TokenValRectangleBackground>
            )}

          <HorizontalContainer
            style={{
              marginTop: 12,
              gap: 6,
              maxWidth: "100%",
              overflow: "hidden",
            }}
          >
            <AnnouncementBackground
              onClick={() => hopper.push(`circle/${circleId}/announcement`)}
            >
              <Image src={iconAnnouncement} width={24} height={24} />
              <AnnouncementText style={{ maxWidth: "calc(100% - 30px)" }}>
                {circleSWR.content?.announcementTitle ??
                  (isAdminOrCoAdmin
                    ? i18n.add_an_announcement()
                    : i18n.no_announcement())}
              </AnnouncementText>
            </AnnouncementBackground>
          </HorizontalContainer>
        </VerticalContainer>
        <ViewPagerTabLayout />
        <PageFooter obscuredZoneKey={"CircleBottom"}>
          {isJoined !== undefined && circleSWR.content && !isJoined && (
            <RegularButton
              style={{
                ...RowCenterButton,
                marginTop: 8,
              }}
              $baseBgColor={"var(--color-bg)"}
              $baseColor={ButtonColor.greenish}
              onClick={() => {
                console.log("CircleHomePage🧧");
                refreshCircleInfo().then((circle) => {
                  if (!circle) return;
                  joinCircle({ joinMethod: JoinCircleMethod.OPEN }).catch(
                    andLog,
                  );
                });
              }}
            >
              {showLock && <Image src={iconLock} alt={"reveal"} />}
              <Spin state={joinCircleTask.state}>
                {i18n.clover_join_the_circle()}
              </Spin>
            </RegularButton>
          )}

          {isJoined && (
            <div
              style={{
                display: "flex",
                position: "relative",
                justifyContent: "center",
              }}
            >
              <HStackAnimated $collapsed={isCollapsed} style={{ gap: 28 }}>
                <Image
                  onClick={() => hopper.modal("nyi")}
                  src={iconCompose}
                  width={46}
                  height={46}
                  alt={"circle-compose"}
                />
                {pageSpec !== "wide" && (
                  <RelativeContainer
                    onClick={() => {
                      hopper.endSide(`circle/${circleId}/menu`);
                    }}
                  >
                    <Image
                      src={iconMenu}
                      width={46}
                      height={46}
                      alt={"circle-menu"}
                    />
                    {circleHasAdminReminder.content && <AbsRedDot />}
                  </RelativeContainer>
                )}
              </HStackAnimated>

              {isCollapsed ? (
                <Image
                  style={{ display: "flex", position: "absolute", right: 20 }}
                  src={iconExpandMenu}
                  width={46}
                  onClick={handleCollapse} // 点击触发折叠
                  height={46}
                  alt={"circle-collapse-menu"}
                />
              ) : (
                <Image
                  style={{ display: "flex", position: "absolute", right: 20 }}
                  src={iconCollapseMenu}
                  width={46}
                  onClick={handleCollapse} // 点击触发折叠
                  height={46}
                  alt={"circle-collapse-menu"}
                />
              )}
            </div>
          )}
        </PageFooter>
        <BSMenu modal={navMenu}>
          <HStack onClick={() => navMenu.close()}>
            <Image
              src={[circleSWR.content?.circleIcon, 30]}
              alt={"circleIcon"}
              style={{
                borderRadius: 4,
                marginRight: 6,
                border: `1px solid ${circleSWR.content?.themeColor}`,
              }}
              width={30}
              height={30}
            ></Image>
            <CircleBsMenuName>{circleSWR.content?.name}</CircleBsMenuName>
          </HStack>
          <BSMenuItem
            title={i18n.may22_impr_copy_link_to_this_circle()}
            onClick={() => {
              shareLink().catch(andLog);
            }}
          ></BSMenuItem>
          <BSMenuItem
            title={i18n.circle_perm_invite_and_share()}
            onClick={() => {
              hopper.modal(`circle/${circleId}/qr-code-portal`);
            }}
          ></BSMenuItem>
          <BSMenuItem
            title={i18n.may22_impr_flag_this_circle()}
            onClick={() => {
              hopper.layer("flag", {
                objectId: circleId,
                objectType: ObjectType.CIRCLE,
              });
            }}
          ></BSMenuItem>
          {isJoined === true && (
            <BSMenuItem
              title={i18n.circle_settings()}
              onClick={() => {
                hopper.push(`circle/${circleId}/setting`);
              }}
            ></BSMenuItem>
          )}

          {isAdminOrCoAdmin && (
            <BSMenuItem
              title={i18n.jun1_text_admin_portal()}
              onClick={() => {
                hopper.push(`circle/${circleId}/admin-portal`);
              }}
            ></BSMenuItem>
          )}
        </BSMenu>
        <BSMenu modal={showWelcomeModal}>
          <HStack style={{ gap: 8 }}>
            <Spring />
            <Image src={iconLeftStar} width={34} height={34} />
            <WelcomeTitle>{i18n.circle_perm_welcome()}</WelcomeTitle>
            <Image src={iconRightStar} width={28} height={38} />
            <Spring />
          </HStack>

          <div
            onClick={() => {
              setReceivePostState(!receivePostState);
            }}
          >
            <RectCheckBoxWithLabel
              text={i18n.receive_notifications_of_circle()}
              isChecked={receivePostState}
            />
          </div>

          <div
            onClick={() => {
              setReceiveAnnounceState(!receiveAnnounceState);
            }}
          >
            <RectCheckBoxWithLabel
              text={i18n.circle_anmt_receive_notification()}
              isChecked={receiveAnnounceState}
            />
          </div>

          <div
            onClick={() => {
              setShowCircleInPublic(!showCircleInPublic);
            }}
          >
            <RectCheckBoxWithLabel
              text={i18n.may22_impr_show_this_circle()}
              isChecked={showCircleInPublic}
            />
          </div>

          <RegularButton
            style={{
              marginTop: 20,
              marginBottom: 20,
              marginInlineStart: 30,
              marginInlineEnd: 30,
            }}
            onClick={() => {
              showWelcomeModal.close();
              saveNotification().catch(andLog);
            }}
          >
            {i18n.ok()}
          </RegularButton>

          <HStack
            onClick={() => {
              setWelcomeAsDefault(!welcomeAsDefault);
            }}
          >
            <Spring />
            <CheckBoxWithLabel
              text={i18n.keep_above_selection_as_default()}
              isChecked={welcomeAsDefault}
            />
            <Spring />
          </HStack>
        </BSMenu>
      </ViewPagerPage>
    </>
  );
}

export function useAlertGoToAdminPortalCountOneDay(sceneId: StateId) {
  return useLocalRepo<number>(["AlertGoToAdminPortalCount", sceneId], 0);
}

const WelcomeTitle = styled.div`
  ${accent_font(24)};
  color: #ffffff;
  text-shadow: 0px 0px 8px #00ff94;
`;

const slideIn = keyframes`
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

const slideOut = keyframes`
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(100px);
  }
`;

export const HStackAnimated = styled.div<{ $collapsed: boolean }>`
  display: flex;
  gap: 28px;
  transition: transform 0.3s ease;
  ${(props) =>
    props.$collapsed
      ? css`
          animation: ${slideOut} 0.2s forwards;
        `
      : css`
          animation: ${slideIn} 0.2s forwards;
        `}
`;

const AnnouncementBackground = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex: 1;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 8px;
  padding-inline-start: 8px;
  padding-inline-end: 8px;
  height: 32px;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden; //这个是设置隐藏的。还有其他的，例如scroll，是超出固定长度，底部显示滚动条的。
`;

const MembersCountText = styled.div`
  color: var(--color-text00);
  font-weight: 400;
  font-size: 12px;
`;

const AnnouncementText = styled.div`
  color: var(--color-text00);
  font-weight: 400;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const VerticalContainer = styled.div`
  display: flex;
  padding-inline-start: 20px;
  padding-bottom: 12px;
  padding-inline-end: 20px;
  flex-direction: column;
`;

const HorizontalContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const CircleName = styled.div`
  font-weight: 700;
  color: var(--color-text00);
  word-break: break-word;
  ${SingleLineUnspecifiedWidth};
  font-size: 20px;
`;

const CircleID = styled.div`
  font-weight: 400;
  color: var(--color-text00);
  font-size: 11px;
`;

const CircleLanguage = styled.div`
  font-weight: 400;
  color: var(--color-text00);
  font-size: 12px;
`;

const NameStyle = css`
  font-weight: 400;
  color: var(--color-text00);
  font-size: 11px;
  max-width: 60%;
`;

export const RelativeContainer = styled.div`
  position: relative;
`;

export const AbsRedDot = styled.div`
  position: absolute;
  top: 7px;
  right: 7px;
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background: red;
`;

const CircleBsMenuName = styled.div`
  font-size: 16px;
  font-weight: 500;
  text-align: start;
  color: var(--color-text00);
  width: calc(100% - 36px);
  ${SingleLineUnspecifiedWidth}
`;

const BackgroundContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Dimmer = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
`;

const TokenTickerLabel = styled.div`
  font-weight: 700;
  font-size: 10px;
  color: var(--color-text00);
  background: #ffffff0f;
  padding: 2px;
  border-radius: 2px;
`;

const RectCheckBoxWithLabel: React.FC<CheckBoxWithLabelProps> = ({
  text,
  isChecked,
}) => {
  return (
    <HStack
      style={{
        marginTop: 30,
        gap: 10,
        alignItems: "center",
      }}
    >
      {isChecked && <Image src={iconChecked} width={18} height={18} />}
      {!isChecked && <Image src={iconUnchecked} width={18} height={18} />}
      <div
        style={{
          width: "100%",
          whiteSpace: "normal",
          wordWrap: "break-word",
          overflowWrap: "break-word",
        }}
      >
        {text}
      </div>
    </HStack>
  );
};

export default CircleHomePage;
