import { CircleWidgetsCellProps } from "./CircleWidgetCell";
import ViewPager, { PagedItem } from "../../components/ViewPager";
import MediumWidget from "../../components/MediumWidget";
import { Spacing } from "../../components/Spacing";
import SectionHeader from "../../components/SectionHeader";
import React from "react";
import LargeWidget from "../../components/LargeWidget";

function CircleWidgetLargeCell({ widget, i18n }: CircleWidgetsCellProps) {
  const items =
    widget.widgetItemList?.map((widgetChildItem, index) => {
      const pagedItem: PagedItem = {
        index: index,
        title: widgetChildItem.title,
        page: <LargeWidget widgetItem={widgetChildItem} i18n={i18n} />,
      };
      return pagedItem;
    }) ?? [];
  return (
    <>
      <Spacing height={32} />
      {widget.extensions?.hideTitle !== true && (
        <>
          <SectionHeader title={widget.title ?? ""} />
          <Spacing height={6} />
        </>
      )}
      <div style={{ marginInlineEnd: -20, marginInlineStart: -20 }}>
        <ViewPager
          adapter={{ pagedItems: items }}
          showShoulder={false}
          slidesPerView={1.1} // 每次显示 1.2 个 slide
          centeredSlides={true} // 居中当前 slide
          spaceBetween={5} // slide 之间的间距
          showTabsLayout={false}
          preventInteractionOnTransition={true}
          nested={true}
        />
      </div>
    </>
  );
}

export default CircleWidgetLargeCell;
