import React, {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from "react";
import { RuleSet, styled } from "styled-components";
import { AbsImage } from "./AbsImage";
import close from "../res/images/ic_modal_close.svg";
import {
  BounceIn,
  FadeIn,
  FadeOut,
  None,
  ShrinkFadeOut,
  SlideDown,
  SlideInFromEnd,
  SlideInFromStart,
  SlideOutToEnd,
  SlideOutToStart,
  SlideUp,
} from "./Keyframes";
import { Spring } from "./VStack";
import svgToMiniDataURI from "mini-svg-data-uri";
import colorSetAlpha from "color-alpha";
import { linear_gradient_border } from "./vscroll/SVGUtils";
import { useNativePageOrNull } from "../hooks/useBridge";
import { andLog } from "./handleError";
import { flattenStateId, StateId } from "../hooks/StateId";
import { useHopState } from "../hooks/useHopState";
import { useExternalSafeAreaInsets } from "../hooks/useSafeAreaInsets";
import { useChange } from "../hooks/ExtHooks";
import { removeOptional } from "../utils/typeUtils";
import { ModalBoundaryContext, ModalContext } from "./ModalContext";
import { useHopper } from "../hooks/useHopper";
import { usePageSpec } from "../pages/common/usePageSpec";
import { useRenderDetection } from "../hooks/useRenderDetection";

const TopLightSVG = `
<svg xmlns="http://www.w3.org/2000/svg" width="309" height="38" viewBox="0 0 309 38" fill="none">
  <g filter="url(#filter0_f_613_4881)">
    <ellipse cx="154.447" cy="-40.472" rx="40.3607" ry="116.042" transform="rotate(-90 154.447 -40.472)" fill="white" fill-opacity="0.6"/>
  </g>
  <defs>
    <filter id="filter0_f_613_4881" x="0.504295" y="-118.732" width="307.885" height="156.521" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
      <feGaussianBlur stdDeviation="18.95" result="effect1_foregroundBlur_613_4881"/>
    </filter>
  </defs>
</svg>
`;

const BottomLightSVG = `
<svg xmlns="http://www.w3.org/2000/svg" width="266" height="44" viewBox="0 0 266 44" fill="none">
  <g filter="url(#filter0_f_2878_37183)">
    <ellipse cx="133" cy="44.5" rx="6.5" ry="95" transform="rotate(-90 133 44.5)" fill="white" fill-opacity="0.6"/>
  </g>
  <defs>
    <filter id="filter0_f_2878_37183" x="0.0999985" y="0.0999985" width="265.8" height="88.8" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
      <feGaussianBlur stdDeviation="18.95" result="effect1_foregroundBlur_2878_37183"/>
    </filter>
  </defs>
</svg>
`;

export const OverTheTop = styled.div<{ $isHorizontal?: boolean }>`
  display: flex;
  position: absolute;
  flex-direction: ${(p) => (p.$isHorizontal ? "row" : "column")};
  justify-content: center;
  align-items: center;
  z-index: 1000;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
`;

export function ModalContainer(props: {
  children: ReactElement<ModalProps>;
  id: StateId;
  isHorizontal?: boolean;
  modal: ModalController;
}) {
  const modalBoundaryContext = useContext(ModalBoundaryContext);

  useRenderDetection();

  useEffect(() => {
    const overTheTop = (
      <OverTheTop
        key={flattenStateId(props.id)}
        $isHorizontal={props.isHorizontal}
      >
        {props.children}
      </OverTheTop>
    );
    modalBoundaryContext.addModal(overTheTop, props.modal);
    return () => {
      modalBoundaryContext.removeModal(overTheTop, props.modal);
    };
  }, [props.children, props.id, props.isHorizontal, props.modal]);

  return <></>;
}

export enum Animation {
  None,
  In,
  Out,
}

export const ModalDimmer = styled.div<{
  $animation: Animation;
  $backgroundColor?: string;
}>`
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: ${(p) => p.$backgroundColor ?? "rgba(0, 0, 0, 0.8)"};
  overflow: hidden;

  animation: ${(p) =>
      p.$animation === Animation.None
        ? None
        : p.$animation === Animation.In
          ? FadeIn
          : FadeOut}
    0.2s linear 1 forwards;
  animation-fill-mode: both;
`;

export function genBorder(baseColor: string | undefined, radius?: number) {
  return linear_gradient_border(
    radius ?? 18,
    1,
    -30,
    [colorSetAlpha(baseColor ?? "white", 0.5), 0],
    [colorSetAlpha(baseColor ?? "white", 0.1), 0.4],
    [colorSetAlpha(baseColor ?? "white", 0.1), 0.6],
    [colorSetAlpha(baseColor ?? "white", 0.5), 1],
  );
}

export const CenterModalBg = styled.div<{
  $animation: Animation;
  $disableOverFlow?: Boolean;
}>`
  background:
    url("${svgToMiniDataURI(TopLightSVG)}") center top no-repeat,
    url("${svgToMiniDataURI(BottomLightSVG)}") center bottom no-repeat
      rgba(41, 41, 41, 0.96);

  position: relative;
  width: 80%;
  max-width: 500px;
  height: auto;
  max-height: 80%;

  border-radius: 18px;
  border-image: ${genBorder("white")};
  border-style: solid;
  border-width: 1px;
  transition: margin-bottom 0.2s ease-in-out;
  animation: ${(p) =>
      p.$animation === Animation.None
        ? None
        : p.$animation === Animation.In
          ? BounceIn
          : ShrinkFadeOut}
    ${(p) => (p.$animation === Animation.In ? "0.3" : "0.15")}s linear 1
    forwards;
  animation-fill-mode: both;
  box-sizing: border-box;
  overflow: ${(p) => (p.$disableOverFlow ? undefined : "hidden")};
`;

export const ModalTitle = styled.div<{ mixin?: RuleSet<Object> }>`
  font-size: 18px;
  font-weight: 600;
  color: var(--color-text00);
  text-align: center;
  word-break: break-word;
  ${(p) => p.mixin};
`;

export const ModalDesc = styled.div<{ mixin?: RuleSet<Object> }>`
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: var(--color-text00);

  white-space: pre-wrap;
  ${(p) => p.mixin};
`;

export const ModalContentContainer = styled.div<{ mixin?: RuleSet<Object> }>`
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  // as prent
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: stretch;
  overflow: auto;

  padding: 48px 30px 30px;
  gap: 21px;
  ${(p) => p.mixin};
`;

export type ModalProps = {
  modal: ModalController;
};

export function CenterModal(
  props: PropsWithChildren<
    {
      onClickBg?: () => void;
      onClickClose?: () => void;
      onBackPressed?: () => void;
      hideCloseBtn?: boolean;
      mixin?: RuleSet<Object>;
      disableOverFlow?: boolean;
    } & ModalProps
  >,
) {
  requireModalController(props.modal);

  const safeAreaInsets = useExternalSafeAreaInsets();

  useFreezeNavBar(props.modal, props.onBackPressed);

  return props.modal.mounted ? (
    <ModalContainer id={props.modal.id} modal={props.modal}>
      <ModalContext.Provider value={{ modal: props.modal }}>
        <ModalDimmer
          onClick={() => {
            // document.activeElement always returns <body></body> at this point
            if (safeAreaInsets.imeBottom === 0) {
              if (props.onClickBg) {
                props.onClickBg();
              }
            }
          }}
          $animation={props.modal.animation}
        />
        <CenterModalBg
          style={{
            marginBottom: safeAreaInsets.imeBottom,
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
          $animation={props.modal.animation}
          $disableOverFlow={props.disableOverFlow}
          onAnimationEnd={props.modal.onAnimationEnd}
        >
          <ModalContentContainer mixin={props.mixin}>
            {props.children}
          </ModalContentContainer>
          {props.hideCloseBtn !== true && (
            <AbsImage
              src={close}
              style={{ top: 12, right: 12 }}
              onClick={props.onClickClose ?? removeOptional(props.modal.close)}
            />
          )}
        </CenterModalBg>
      </ModalContext.Provider>
    </ModalContainer>
  ) : (
    <></>
  );
}

export const BottomSheetBg = styled.div<{ $animation: Animation }>`
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  padding-top: 18px;
  background: rgba(41, 41, 41, 1) url("${svgToMiniDataURI(TopLightSVG)}")
    no-repeat;
  background-position-x: center;

  position: relative;
  width: 100%;
  height: auto;

  animation: ${(p) =>
      p.$animation === Animation.None
        ? None
        : p.$animation === Animation.In
          ? SlideUp
          : SlideDown}
    0.3s ease-out 1 forwards;

  animation-fill-mode: both;
  overflow: hidden;
`;

export type ModalControllerImpl = {
  readonly onAnimationEnd: () => void;
  readonly animation: Animation;
  readonly id: StateId;
} & ModalController;

export interface ModalController {
  readonly mounted: boolean;
  readonly opened: boolean;
  readonly open: (completion?: () => void) => void;
  readonly close: (completion?: () => void) => void;
}

export class ModalRelay {
  private modal: ModalController | undefined;

  attach(modal: ModalController) {
    this.modal = modal;
  }

  open(completion?: () => void) {
    if (this.modal) this.modal.open(completion);
    else completion?.();
  }

  close(completion?: () => void) {
    if (this.modal) this.modal.close(completion);
    else completion?.();
  }
}

export function closeModal(modal: ModalController) {
  return new Promise<void>((resolve, reject) => {
    modal.close(() => resolve());
  });
}

export function closeAllModals(modals: ReadonlyArray<ModalController>) {
  return Promise.all(modals.map((m) => closeModal(m)));
}

export function useModal(id: StateId, initialValue?: boolean): ModalController {
  const [modalOpen, setModalOpen] = useHopState<boolean>(
    id,
    initialValue ?? false,
  );
  const [mounted, setMounted] = useState(initialValue ?? false);
  const hasEverClosed = useRef(false);
  const completionRef = useRef<() => void>();

  useEffect(() => {
    if (modalOpen) {
      setMounted(true);
      if (completionRef.current) {
        completionRef.current();
      }
    } else {
      if (!hasEverClosed.current) {
        hasEverClosed.current = true;
      }
    }
  }, [modalOpen, setMounted]);

  const setter = useCallback(
    (value: boolean, completion: (() => void) | undefined) => {
      setModalOpen((prev) => {
        if (value === prev) {
          if (completion) completion();
        } else {
          completionRef.current = completion;
        }
        return value;
      });
    },
    [setModalOpen],
  );

  return useMemo<ModalControllerImpl>(() => {
    return {
      id: id,
      opened: modalOpen,
      mounted: mounted,
      onAnimationEnd: () => {
        if (!modalOpen) {
          setMounted(false);
        }
        if (completionRef.current) {
          completionRef.current();
        }
      },
      animation: modalOpen
        ? hasEverClosed.current
          ? Animation.In
          : Animation.None
        : Animation.Out,
      close: (completion?: () => void) => {
        setter(false, completion);
      },
      open: (completion?: () => void) => {
        setter(true, completion);
      },
    };
  }, [mounted, modalOpen, id, setter]);
}

export function useFreezeNavBar(
  modal: ModalControllerImpl,
  onBack: (() => void) | undefined,
) {
  const modalId = useId();
  const nativePage = useNativePageOrNull();

  useEffect(() => {
    if (modal.mounted) {
      nativePage
        ?.freezeNavBar(modalId, () => {
          if (onBack) {
            onBack();
          } else {
            modal.close();
          }
        })
        .catch(andLog);
    } else {
      nativePage?.unfreezeNavBar(modalId).catch(andLog);
    }

    return () => {
      nativePage?.unfreezeNavBar(modalId).catch(andLog);
    };
  }, [modal.mounted]);
}

export type DrawerProps = {
  onClickBg?: () => void;
  onBackPressed?: () => void;
  isHorizontal: boolean;
} & ModalProps;

export type BottomSheetProps = Omit<DrawerProps, "isHorizontal"> & {
  hideCloseButton?: boolean;
  itemsGap?: number;
};

export function requireModalController(
  controller: ModalController,
): asserts controller is ModalControllerImpl {
  if (
    "mounted" in controller &&
    "animation" in controller &&
    "onAnimationEnd" in controller
  ) {
  } else {
    throw Error("Not a valid ModalController");
  }
}

export function Drawer(props: PropsWithChildren<DrawerProps>) {
  requireModalController(props.modal);

  useFreezeNavBar(props.modal, props.onBackPressed);
  return props.modal.mounted ? (
    <ModalContainer
      id={props.modal.id}
      isHorizontal={props.isHorizontal}
      modal={props.modal}
    >
      <ModalContext.Provider value={{ modal: props.modal }}>
        <ModalDimmer
          onClick={props.onClickBg ?? removeOptional(props.modal.close)}
          $animation={props.modal.animation}
        />
        {props.children}
      </ModalContext.Provider>
    </ModalContainer>
  ) : (
    <></>
  );
}

export const PopOverBg = styled.div<{ $animation: Animation }>`
  background:
    url("${svgToMiniDataURI(TopLightSVG)}") center top no-repeat,
    url("${svgToMiniDataURI(BottomLightSVG)}") center bottom no-repeat
      rgba(41, 41, 41, 0.96);

  padding-top: 18px;
  padding-bottom: 18px;
  position: relative;
  width: 80%;
  max-width: 500px;
  height: auto;
  max-height: 80%;

  border-radius: 18px;
  border-image: ${genBorder("white")};
  border-style: solid;
  border-width: 1px;
  transition: margin-bottom 0.2s ease-in-out;
  animation: ${(p) =>
      p.$animation === Animation.None
        ? None
        : p.$animation === Animation.In
          ? FadeIn
          : FadeOut}
    ${(p) => (p.$animation === Animation.In ? "0.3" : "0.15")}s linear 1
    forwards;
  animation-fill-mode: both;
  box-sizing: border-box;
`;

export function BottomSheet(props: PropsWithChildren<BottomSheetProps>) {
  requireModalController(props.modal);
  const pageSpec = usePageSpec();
  const Bg = pageSpec === "wide" ? PopOverBg : BottomSheetBg;
  return (
    <Drawer {...props} isHorizontal={false}>
      <Spring />
      <Bg
        onClick={(e) => {
          e.stopPropagation();
        }}
        $animation={props.modal.animation}
        onAnimationEnd={props.modal.onAnimationEnd}
      >
        {props.children}
      </Bg>
      {pageSpec === "wide" && <Spring />}
    </Drawer>
  );
}

const StartDrawerBg = styled.div<{ $animation: Animation }>`
  position: relative;
  width: 80%;
  height: 100%;

  animation: ${(p) =>
      p.$animation === Animation.None
        ? None
        : p.$animation === Animation.In
          ? SlideInFromStart
          : SlideOutToStart}
    0.3s ease-out 1 forwards;

  animation-fill-mode: both;
`;

export function StartSheet(
  props: PropsWithChildren<Omit<DrawerProps, "isHorizontal">>,
) {
  requireModalController(props.modal);
  return (
    <Drawer {...props} isHorizontal={true}>
      <StartDrawerBg
        onClick={(e) => {
          e.stopPropagation();
        }}
        $animation={props.modal.animation}
        onAnimationEnd={props.modal.onAnimationEnd}
      >
        {props.children}
      </StartDrawerBg>
      <Spring />
    </Drawer>
  );
}

const EndDrawerBg = styled.div<{ $animation: Animation }>`
  position: relative;
  width: 80%;
  height: 100%;

  animation: ${(p) =>
      p.$animation === Animation.None
        ? None
        : p.$animation === Animation.In
          ? SlideInFromEnd
          : SlideOutToEnd}
    0.3s ease-out 1 forwards;

  animation-fill-mode: both;
`;

export function EndSheet(
  props: PropsWithChildren<Omit<DrawerProps, "isHorizontal">>,
) {
  requireModalController(props.modal);
  return (
    <Drawer {...props} isHorizontal={true}>
      <Spring />
      <EndDrawerBg
        onClick={(e) => {
          e.stopPropagation();
        }}
        $animation={props.modal.animation}
        onAnimationEnd={props.modal.onAnimationEnd}
      >
        {props.children}
      </EndDrawerBg>
    </Drawer>
  );
}

export function useModalWithItsOwnPage() {
  const hopper = useHopper();

  const modal = useModal("useModalWithItsOwnPage", false);
  useEffect(() => {
    modal.open();
  }, []);

  useChange(modal.mounted, (prev, current) => {
    if (!current) {
      hopper.dismissModal();
    }
  });

  return modal;
}
