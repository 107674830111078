import {
  TransferOrder,
  TransferOrderStatus,
} from "../../../proto/TransferOrder";
import { useI18n } from "../../../hooks/useI18n";
import { useMyUid } from "../../../service/AuthSessionService";
import { useNativePage } from "../../../hooks/useBridge";
import { useBackend } from "../../../service/APIService";
import React, { useMemo, useState } from "react";
import { useCountDown } from "../../../hooks/useCountDown";
import { GiftBox, GiftBoxStatus } from "../../../proto/GiftBox";
import { ObjectType } from "../../../proto/ObjectSpec";
import {
  CurrencyType,
  formatMoney,
  getCurrencyAmountFromString,
  getCurrencyIcon,
  getCurrencyIconByCurrencyType,
  getCurrencyName,
  isCurrencyCash,
  isCurrencyProps,
  isCurrencyToken,
} from "../../../proto/Currency";
import { useSWRArray } from "../../../hooks/swr/useSWRArray";
import { formatDateWithOptions, toHHMMSS } from "../../../utils/DateTimeUtils";
import { getFittestRes } from "../../../proto/Media";
import { HStack, Spring, VSpace, VStack } from "../../../components/VStack";
import {
  UserAvatarView,
  UserIconView,
} from "../../../components/views/UserAvatarView";
import { UserNameView } from "../../../components/views/UserNameView";
import { Image } from "../../../components/Image";
import { ReactionListView } from "../../reaction/ReactionListView";
import { CellDivider, ContentGroup } from "../../../components/ContentGroup";
import { StatusBar } from "./GiftOrderListPage";
import { RegularButton } from "../../../components/Buttons";
import styled, { css } from "styled-components";
import { useHopper } from "../../../hooks/useHopper";
import { Spinner } from "../../../components/handleError";
import { SWRSingle } from "../../../hooks/swr/useSWR";
import { LoadStateKind } from "../../../hooks/LoadState";
import {
  getGiftBoxLocationSource,
  getGiftBoxLocationText,
} from "../../../proto/GiftBoxLocation";

export function GiftOrderDetail(props: { orderSWR: SWRSingle<TransferOrder> }) {
  const i18n = useI18n();
  const myUid = useMyUid();
  const nativePage = useNativePage();
  const backend = useBackend();
  const hopper = useHopper();
  const [manualStatus, setManualStatus] = useState<number | undefined>(
    undefined,
  );
  const [manualGiftBoxStatus, setManualGiftBoxStatus] = useState<
    number | undefined
  >(undefined);

  const [showSpinner, setShowSpinner] = useState(false);

  const transferOrder = props.orderSWR.content;

  const orderStatus = useMemo(() => {
    if (manualStatus !== undefined) {
      return manualStatus;
    } else {
      if (transferOrder?.orderStatus == TransferOrderStatus.PENDING) {
        const startTime = (transferOrder.minClaimedTime || 0) * 1000;
        const now = new Date().getTime();
        if (now > startTime) {
          const expiredTime = (transferOrder.expiredTimeV2 || 0) * 1000;
          if (now < expiredTime) {
            return TransferOrderStatus.PENDING;
          } else {
            return TransferOrderStatus.RETURN;
          }
        } else {
          return TransferOrderStatus.WAITING_TO_START;
        }
      } else {
        return transferOrder?.orderStatus;
      }
    }
  }, [transferOrder, manualStatus]);

  const remainingTimeToStart = useCountDown(() => {
    if (
      transferOrder?.minClaimedTime !== undefined &&
      orderStatus === TransferOrderStatus.WAITING_TO_START
    ) {
      return (
        (transferOrder?.minClaimedTime * 1000 - new Date().getTime()) / 1000
      );
    } else {
      return 0;
    }
  }, [transferOrder]);

  const remainingTimeToExpire = useCountDown(() => {
    if (
      transferOrder?.expiredTimeV2 !== undefined &&
      orderStatus === TransferOrderStatus.PENDING
    ) {
      return (transferOrder.expiredTimeV2 * 1000 - new Date().getTime()) / 1000;
    } else {
      return 0;
    }
  }, [transferOrder]);

  const giftBox: Partial<GiftBox> = useMemo(() => {
    if (transferOrder?.giftBox) {
      return transferOrder?.giftBox;
    } else {
      const isAlreadyClaimed = orderStatus == TransferOrderStatus.DONE;
      const claimedAmount = isAlreadyClaimed
        ? transferOrder?.currency?.amount
        : "0";
      const claimedCount = isAlreadyClaimed ? 1 : 0;
      const giftBoxStatus =
        orderStatus === TransferOrderStatus.DONE
          ? GiftBoxStatus.Done
          : orderStatus === TransferOrderStatus.PENDING
            ? GiftBoxStatus.Pending
            : orderStatus === TransferOrderStatus.RETURN
              ? GiftBoxStatus.Expired
              : 0;
      return {
        amount: transferOrder?.currency?.amount,
        claimedAmount: claimedAmount,
        expiredTime: transferOrder?.expiredTimeV2,
        claimedCount: claimedCount,
        createdTime: transferOrder?.createdTime,
        currencyType: transferOrder?.currencyType,
        giftBoxStatus: giftBoxStatus,
        maxClaimedCount: 1,
        toObjectType: ObjectType.USER,
        uid: transferOrder?.fromUid,
      };
    }
  }, [transferOrder]);

  const giftBoxStatus = useMemo(() => {
    if (manualGiftBoxStatus !== undefined) {
      return manualGiftBoxStatus;
    } else {
      switch (orderStatus) {
        case TransferOrderStatus.DONE:
          return GiftBoxStatus.Done;
        case TransferOrderStatus.PENDING:
        case TransferOrderStatus.WAITING_TO_START:
          return GiftBoxStatus.Pending;
        case TransferOrderStatus.RETURN: {
          if (giftBox.giftBoxStatus === GiftBoxStatus.Withdrawn) {
            return GiftBoxStatus.Withdrawn;
          } else {
            return GiftBoxStatus.Expired;
          }
        }
      }
    }
  }, [orderStatus, manualGiftBoxStatus]);

  function getStatus() {
    if (orderStatus === TransferOrderStatus.DONE) {
      return { text: i18n.web3_v0_completed(), color: "#00FF94" };
    } else if (orderStatus === TransferOrderStatus.PENDING) {
      return { text: i18n.web3_v0_pending(), color: "#00FF94" };
    } else if (orderStatus === TransferOrderStatus.WAITING_TO_START) {
      return { text: i18n.web3_v0_waiting_to_start(), color: "#00FF94" };
    } else if (orderStatus === TransferOrderStatus.RETURN) {
      if (giftBoxStatus === GiftBoxStatus.Withdrawn) {
        return {
          text: i18n.web3_hongbao_status_withdrawn(),
          color: "#FFFFFF66",
        };
      } else {
        return { text: i18n.web3_v0_expired(), color: "#FFFFFF66" };
      }
    } else {
      return { text: "", color: "#FFFFFF66" };
    }
  }

  async function withdrawnGiftBox() {
    if (giftBox?.boxId) {
      try {
        setShowSpinner(true);
        await backend.withdrawnGiftBox(giftBox?.boxId).run();
        await props.orderSWR.load();
        if (props.orderSWR.loadState?.kind === LoadStateKind.loadFailed) {
          setShowSpinner(false);
        }
      } catch (e) {
        console.error(e);
      }
    }
  }

  async function claimGiftBox() {
    if (giftBox?.boxId) {
      try {
        setShowSpinner(true);
        await backend.claimGiftBox(giftBox?.boxId).run();
        await props.orderSWR.load();
        if (props.orderSWR.loadState?.kind === LoadStateKind.loadFailed) {
          setShowSpinner(false);
        }
      } catch (e) {
        console.error(e);
      }
    }
  }

  const isGiftSender = transferOrder?.fromUser?.uid === myUid;
  const selfClaimed =
    transferOrder?.orderStatus === TransferOrderStatus.DONE &&
    transferOrder?.toUid === myUid;

  function shouldShowEmptyTip() {
    const flag =
      giftBox?.toObjectType !== ObjectType.USER
        ? true
        : giftBoxStatus === GiftBoxStatus.Done
          ? !isGiftSender
          : true;
    return !selfClaimed && giftBoxStatus !== GiftBoxStatus.Pending && flag;
  }

  function shouldShowGiftContent() {
    return (
      selfClaimed || (giftBoxStatus === GiftBoxStatus.Pending && !isGiftSender)
    );
  }

  function getWalletTip() {
    const wallet = (
      <UnderlinedWalletText onClick={() => hopper.push("wallet/")}>
        {i18n.web3_hongbao_wallet()}
      </UnderlinedWalletText>
    );
    const transferFeeRate =
      (transferOrder?.extensions?.transferFeeRate || 0) * 100;
    if ((transferOrder?.fromUid || 0) < 1000) {
      return (
        <CollectedIntoWalletTip>
          {i18n.web3_hongbao_gift_collected_into(wallet)}
        </CollectedIntoWalletTip>
      );
    } else if (
      transferOrder?.currency &&
      isCurrencyToken(transferOrder?.currency.currencyType)
    ) {
      if (transferFeeRate == 0) {
        return (
          <CollectedIntoWalletTip>
            {i18n.web3_hongbao_gift_deposited_into(wallet)}
          </CollectedIntoWalletTip>
        );
      } else {
        return (
          <CollectedIntoWalletTip>
            {i18n.web3_hongbao_transaction_fee_tips_into(
              `${transferFeeRate}%`,
              wallet,
            )}
          </CollectedIntoWalletTip>
        );
      }
    } else if (
      transferOrder?.currencyType === CurrencyType.COUPON ||
      transferOrder?.currencyType === CurrencyType.NFT
    ) {
      return (
        <CollectedIntoWalletTip>
          {i18n.web3_hongbao_gift_deposited_into(wallet)}
        </CollectedIntoWalletTip>
      );
    } else return <></>;
  }

  function giftBoxClaimStatusText() {
    if (giftBox?.currencyType === undefined) return;
    const isToken = isCurrencyToken(giftBox?.currencyType);
    const isCash = transferOrder?.currency?.isCash;
    const decimals = transferOrder?.currency?.decimals;
    /**
     * 被领取数量
     */
    const claimedAmount =
      isToken || isCash
        ? formatMoney(
            "long",
            getCurrencyAmountFromString(giftBox.claimedAmount || "0", decimals),
          )
        : giftBox.claimedAmount;

    const totalAmount =
      isToken || isCash
        ? formatMoney(
            "long",
            getCurrencyAmountFromString(giftBox.amount || "0", decimals),
          )
        : giftBox.amount;

    const maxClaimedCount = giftBox.maxClaimedCount || 0;
    return i18n
      .plural(maxClaimedCount)
      .web3_hongbao_unpacked_header(
        `${giftBox.claimedCount || 0}/${maxClaimedCount}`,
        `${claimedAmount}/${totalAmount}`,
        "",
      );
  }

  const claimedGiftSWR = useSWRArray(
    giftBox?.boxId !== undefined
      ? backend.getGiftBoxClaimedList(giftBox?.boxId)
      : undefined,
  );

  function shouldShowExpiredTime() {
    const myGiftBox = giftBox.uid === myUid;
    const date = new Date();
    const couldClaim =
      (transferOrder?.minClaimedTime || 0) * 1000 < date.getTime();
    const isDirectToUser = giftBox.toObjectType === ObjectType.USER;
    return (
      (couldClaim &&
        (myGiftBox || (isDirectToUser && giftBox.toObjectId === myUid)) &&
        (giftBoxStatus === GiftBoxStatus.Pending ||
          giftBoxStatus === GiftBoxStatus.Expired)) ||
      (!isDirectToUser && giftBoxStatus === GiftBoxStatus.Expired)
    );
  }

  function getExpiredTime() {
    if (orderStatus === TransferOrderStatus.PENDING) {
      return i18n.web3_v0_expiring_in(toHHMMSS(remainingTimeToExpire));
    } else if (
      orderStatus === TransferOrderStatus.RETURN &&
      giftBoxStatus === GiftBoxStatus.Expired
    ) {
      return formatDateWithOptions(transferOrder?.expiredTimeV2 || 0, {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    }
  }

  const showServiceFee =
    giftBox.currencyType === CurrencyType.NFT
      ? giftBox.serviceFee?.sendServiceFee
      : transferOrder?.extensions?.giftBoxServiceFee;

  const serviceFeeCurrencyType =
    giftBox.currencyType === CurrencyType.NFT
      ? giftBox.serviceFee?.sendServiceFee?.currencyType
      : transferOrder?.currencyType;

  function getServiceFeeCurrencyAmount() {
    if (giftBox.currencyType === CurrencyType.NFT) {
      return `${formatMoney("long", giftBox.serviceFee?.sendServiceFee)}`;
    } else {
      return `${formatMoney(
        "long",
        getCurrencyAmountFromString(
          transferOrder?.extensions?.giftBoxServiceFee || "0",
        ),
      )}`;
    }
  }

  const showReceived =
    transferOrder?.toUid === myUid && orderStatus === TransferOrderStatus.DONE;

  function getReceive() {
    const currencyType = giftBox.currencyType || transferOrder?.currencyType;
    if (isCurrencyCash(currencyType)) {
      return (
        <OrderInfoContent>{`${transferOrder?.currency?.symbol || "?"}${formatMoney("long", transferOrder?.currency)}`}</OrderInfoContent>
      );
    } else if (
      currencyType === CurrencyType.COUPON ||
      isCurrencyProps(currencyType)
    ) {
      return (
        <HStack style={{ gap: 4 }}>
          <StyledImg src={getCurrencyIcon(transferOrder?.currency)} />
          <OrderInfoContent>
            ×{transferOrder?.currency?.amount}
          </OrderInfoContent>
        </HStack>
      );
    } else if (isCurrencyToken(currencyType)) {
      return (
        <HStack style={{ gap: 4 }}>
          <StyledImg src={getCurrencyIcon(transferOrder?.currency)} />
          <OrderInfoContent>
            {formatMoney("long", transferOrder?.currency)}
          </OrderInfoContent>
        </HStack>
      );
    } else if (currencyType === CurrencyType.NFT) {
      return (
        <HStack style={{ gap: 4 }}>
          <Image
            src={[
              transferOrder?.nft?.entity?.preview,
              { width: 18, height: 18 },
            ]}
            width={18}
            height={18}
            style={{ borderRadius: 4 }}
          />
          <OrderInfoContent>
            ×{transferOrder?.currency?.amount}
          </OrderInfoContent>
        </HStack>
      );
    }
  }

  const showLocation =
    giftBox.uid === myUid && giftBox.extensions?.latestGiftBoxShareLocation;

  function getGiftLocation() {
    const location = giftBox.extensions?.latestGiftBoxShareLocation;
    return (
      <HStack style={{ gap: 4 }}>
        <StyledImg
          src={getGiftBoxLocationSource(location)}
          style={{ width: 22, height: 22 }}
        />
        <OrderInfoContent>
          {getGiftBoxLocationText(location, i18n)}
        </OrderInfoContent>
      </HStack>
    );
  }

  function getReturnCurrency() {
    if (giftBox.currencyType === CurrencyType.NFT) {
      const icon = transferOrder?.nft?.entity?.preview;
      if (!icon) {
        return;
      }
      return (
        <CurrencyContainer
          icon={
            getFittestRes(icon, {
              width: 18,
              height: 18,
            }).url
          }
          amount={transferOrder?.currency?.amount || "0"}
          isIconRounded={true}
          showMultiplicationSign={true}
        />
      );
    } else if (isCurrencyToken(giftBox.currencyType)) {
      const icon = getCurrencyIconByCurrencyType(giftBox.currencyType);
      if (!icon) {
        return;
      }
      return (
        <CurrencyContainer
          icon={icon}
          amount={formatMoney(
            "long",
            getCurrencyAmountFromString(giftBox.returnAmount || "0"),
          )}
        />
      );
    }
  }

  const shouldFormatMoney = !(
    transferOrder?.currencyType === CurrencyType.NFT ||
    transferOrder?.currencyType === CurrencyType.COUPON ||
    transferOrder?.currencyType === CurrencyType.PASSES
  );

  return (
    <VStack style={{ width: "100%", alignItems: "center" }}>
      <HStack style={{ width: "100%" }}>
        <GiftSenderLabel>{i18n.web3_hongbao_gift_from()}</GiftSenderLabel>
        <HStack
          style={{
            backgroundColor: "#00000051",
            padding: "6px 12px",
            borderRadius: 24,
            display: "inline-flex",
            verticalAlign: "middle",
            marginInlineStart: 8,
            gap: 6,
          }}
        >
          <UserIconView user={transferOrder?.fromUser} iconSize={20} />
          <UserNameView
            user={transferOrder?.fromUser}
            nameStyle={UserNameStyle}
            clickToProfile={true}
          />
        </HStack>
      </HStack>
      <GiftingMessage>
        {giftBox?.title || i18n.web3_hongbao_best_wishes()}
      </GiftingMessage>
      <Divider />
      {shouldShowEmptyTip() && (
        <ExpiredTip>
          {giftBoxStatus === GiftBoxStatus.Withdrawn
            ? i18n.hongbao_impr_the_gift_withdrawn()
            : giftBoxStatus === GiftBoxStatus.Expired
              ? i18n.web3_hongbao_the_gift_has_expired()
              : giftBoxStatus === GiftBoxStatus.Done
                ? i18n.web3_hongbao_no_gifts_left_in_the_box()
                : ""}
        </ExpiredTip>
      )}
      {shouldShowGiftContent() && shouldFormatMoney && (
        <HStack style={{ gap: 8, marginTop: 48 }}>
          <Image
            src={getCurrencyIcon(transferOrder?.currency)}
            width={26}
            height={26}
          />
          <HStack style={{ gap: 4 }}>
            <CurrencyAmount>
              {formatMoney("long", transferOrder?.currency)}
            </CurrencyAmount>
            <CurrencyName>
              {getCurrencyName(transferOrder?.currency, i18n)}
            </CurrencyName>
          </HStack>
        </HStack>
      )}
      {shouldShowGiftContent() &&
        transferOrder?.currencyType === CurrencyType.NFT && (
          <VStack style={{ alignItems: "center", marginTop: 48 }}>
            <Image
              src={[
                transferOrder?.nft?.entity?.preview,
                { width: 80, height: 80 },
              ]}
              style={{ borderRadius: 12, width: 80, height: 80 }}
            />
            <CurrencyAmount style={{ marginTop: 12 }}>
              X {transferOrder?.currency?.amount}
            </CurrencyAmount>
            <NFTName>{transferOrder?.nft?.name}</NFTName>
          </VStack>
        )}
      {shouldShowGiftContent() &&
        (transferOrder?.currencyType === CurrencyType.COUPON ||
          transferOrder?.currencyType === CurrencyType.PASSES) && (
          <HStack style={{ gap: 8, marginTop: 48 }}>
            <Image
              src={getCurrencyIcon(transferOrder?.currency)}
              style={{ width: 26, height: 26 }}
            />
            <CurrencyAmount style={{ marginTop: 12 }}>
              X {transferOrder?.currency?.amount}
            </CurrencyAmount>
          </HStack>
        )}
      {transferOrder?.toUid === myUid &&
        orderStatus === TransferOrderStatus.DONE &&
        getWalletTip()}
      {giftBox?.boxId !== undefined && (
        <>
          <VSpace height={24} />
          <ReactionListView
            reactions={giftBox.reactionCountList}
            objectId={giftBox?.boxId}
            objectType={ObjectType.GIFT_BOX}
            disableFullWidth={true}
            enable={true}
          />
        </>
      )}

      <DetailTitle style={{ marginTop: 48 }}>
        {giftBoxClaimStatusText()}
      </DetailTitle>
      <>
        <VSpace height={12} />
        {claimedGiftSWR?.content &&
          claimedGiftSWR?.content.map((order) => (
            <GiftClaimedCell
              key={`gift-claimed-cell-${order.orderId}`}
              transferOrder={order}
            />
          ))}
      </>

      <DetailTitle>{i18n.giftbox_impr_order_details()}</DetailTitle>
      <ContentGroup style={{ width: "100%", marginTop: 12, gap: 20 }}>
        <HStack>
          <OrderInfoLabel>{i18n.status()}</OrderInfoLabel>
          <Spring />
          <StatusBar
            statusColor={getStatus().color}
            statusText={getStatus().text}
          />
        </HStack>

        {shouldShowExpiredTime() && (
          <>
            <CellDivider />
            <HStack>
              <OrderInfoLabel>
                {i18n.web3_v0_transaction_expired_time()}
              </OrderInfoLabel>
              <Spring />
              <OrderInfoContent
                style={{
                  color: remainingTimeToExpire > 0 ? "#ff7335" : "#FFFFFF66",
                }}
              >
                {getExpiredTime()}
              </OrderInfoContent>
            </HStack>
          </>
        )}

        {giftBox.uid === myUid && transferOrder && (
          <>
            <CellDivider />
            <HStack>
              <OrderInfoLabel>
                {i18n.giftbox_impr_gift_content()}
              </OrderInfoLabel>
              <Spring />
              <GiftContent transferOrder={transferOrder} smallStyle={true} />
            </HStack>
          </>
        )}

        {giftBox.toObjectType == ObjectType.USER && isGiftSender && (
          <>
            <CellDivider />
            <HStack>
              <OrderInfoLabel>{i18n.web3_v0_gift_to()}</OrderInfoLabel>
              <Spring />
              <HStack style={{ gap: 4 }}>
                <UserIconView user={transferOrder?.toUser} iconSize={18} />
                <UserNameView
                  user={transferOrder?.toUser}
                  nameStyle={UserNameStyle}
                  clickToProfile={true}
                />
              </HStack>
            </HStack>
          </>
        )}

        {showServiceFee && (
          <>
            <CellDivider />
            <HStack>
              <OrderInfoLabel>{i18n.web3_v0_service_fee()}</OrderInfoLabel>
              <Spring />
              <HStack style={{ gap: 4 }}>
                <Image
                  src={getCurrencyIconByCurrencyType(serviceFeeCurrencyType)}
                  style={{ width: 18, height: 18 }}
                />
                <AmountText>{getServiceFeeCurrencyAmount()}</AmountText>
              </HStack>
            </HStack>
          </>
        )}

        {showReceived && (
          <>
            <CellDivider />
            <HStack>
              <OrderInfoLabel>{i18n.web3_hongbao_received()}</OrderInfoLabel>
              <Spring />
              {getReceive()}
            </HStack>
          </>
        )}

        {giftBox.returnAmount !== undefined &&
          (giftBoxStatus === GiftBoxStatus.Expired ||
            giftBoxStatus === GiftBoxStatus.Withdrawn) &&
          giftBox.uid === myUid && (
            <>
              <CellDivider />
              <HStack>
                <OrderInfoLabel>
                  {i18n.web3_hongbao_returned_amount()}
                </OrderInfoLabel>
                <Spring />
                {getReturnCurrency()}
              </HStack>
            </>
          )}

        {giftBox.serviceFee?.returnServiceFee && (
          <>
            <CellDivider />
            <HStack>
              <OrderInfoLabel>
                {i18n.gift_tax_returned_service_fee()}
              </OrderInfoLabel>
              <Spring />
              {giftBox.serviceFee?.returnServiceFee.icon && (
                <CurrencyContainer
                  icon={
                    getFittestRes(giftBox.serviceFee?.returnServiceFee.icon, {
                      width: 18,
                      height: 18,
                    }).url
                  }
                  amount={formatMoney(
                    "long",
                    giftBox.serviceFee.returnServiceFee,
                  )}
                />
              )}
            </HStack>
          </>
        )}

        {showLocation && (
          <>
            <CellDivider />
            <HStack>
              <OrderInfoLabel>{i18n.location()}</OrderInfoLabel>
              <Spring />
              {getGiftLocation()}
            </HStack>
          </>
        )}

        <CellDivider />
        <HStack>
          <OrderInfoLabel>
            {i18n.web3_v0_transaction_sent_time()}
          </OrderInfoLabel>
          <Spring />
          <OrderInfoContent>
            {formatDateWithOptions(transferOrder?.createdTime || 0, {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })}
          </OrderInfoContent>
        </HStack>

        <CellDivider />
        <HStack>
          <OrderInfoLabel>{i18n.web3_v0_transaction_order_id()}</OrderInfoLabel>
          <Spring />
          <OrderInfoContent>{String(transferOrder?.orderId)}</OrderInfoContent>
        </HStack>
      </ContentGroup>

      {orderStatus === TransferOrderStatus.PENDING &&
        transferOrder?.fromUser?.uid !== myUid && (
          <RegularButton
            style={{ minWidth: 251, marginTop: 56 }}
            onClick={claimGiftBox}
          >
            {showSpinner && <Spinner style={{ width: 20, height: 20 }} />}
            {!showSpinner && i18n.claim()}
          </RegularButton>
        )}

      {giftBoxStatus === GiftBoxStatus.Pending &&
        transferOrder?.fromUser?.uid === myUid && (
          <RegularButton
            style={{ minWidth: 251, marginTop: 56 }}
            onClick={withdrawnGiftBox}
          >
            {showSpinner && <Spinner style={{ width: 20, height: 20 }} />}
            {!showSpinner && i18n.giftbox_impr_recall()}
          </RegularButton>
        )}

      {remainingTimeToStart !== 0 && (
        <CountDownText>
          {i18n.web3_v0_starting_in(toHHMMSS(remainingTimeToStart))}
        </CountDownText>
      )}
    </VStack>
  );
}

function GiftClaimedCell(props: { transferOrder: TransferOrder }) {
  return (
    <ContentGroup style={{ width: "100%", padding: 12 }}>
      <HStack style={{ gap: 12 }}>
        <UserAvatarView user={props.transferOrder.toUser} iconSize={36} />
        <VStack style={{ flex: 1 }}>
          <UserNameView
            user={props.transferOrder.toUser}
            clickToProfile={true}
          />
          <ClaimedTimeText>
            {formatDateWithOptions(props.transferOrder?.claimedTime || 0, {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })}
          </ClaimedTimeText>
        </VStack>
        <GiftContent transferOrder={props.transferOrder} />
      </HStack>
    </ContentGroup>
  );
}

function GiftContent(props: {
  transferOrder: TransferOrder;
  smallStyle?: boolean;
}) {
  const shouldFormatMoney = !(
    props.transferOrder?.currencyType === CurrencyType.NFT ||
    props.transferOrder?.currencyType === CurrencyType.COUPON ||
    props.transferOrder?.currencyType === CurrencyType.PASSES
  );

  return (
    <>
      {props.transferOrder.currencyType === CurrencyType.NFT && (
        <HStack style={{ gap: 4 }}>
          <Image
            width={props.smallStyle ? 18 : 28}
            height={props.smallStyle ? 18 : 28}
            src={[
              props.transferOrder.nft?.entity?.preview,
              {
                width: props.smallStyle ? 18 : 28,
                height: props.smallStyle ? 18 : 28,
              },
            ]}
            style={{ borderRadius: 4 }}
          />
          {props.smallStyle ? (
            <AmountText> x {props.transferOrder.currency?.amount}</AmountText>
          ) : (
            <ClaimedAmountText>
              x {props.transferOrder.currency?.amount}
            </ClaimedAmountText>
          )}
        </HStack>
      )}
      {(props.transferOrder.currencyType === CurrencyType.COUPON ||
        props.transferOrder.currencyType === CurrencyType.PASSES) && (
        <HStack style={{ gap: 4 }}>
          <Image
            src={getCurrencyIcon(props.transferOrder.currency)}
            width={props.smallStyle ? 18 : 25}
            height={props.smallStyle ? 18 : 25}
          />
          {props.smallStyle ? (
            <AmountText> x {props.transferOrder.currency?.amount}</AmountText>
          ) : (
            <ClaimedAmountText>
              x {props.transferOrder.currency?.amount}
            </ClaimedAmountText>
          )}
        </HStack>
      )}
      {shouldFormatMoney && (
        <HStack style={{ gap: 4 }}>
          <Image
            src={getCurrencyIcon(props.transferOrder.currency)}
            width={props.smallStyle ? 18 : 25}
            height={props.smallStyle ? 18 : 25}
          />
          {props.smallStyle ? (
            <AmountText>
              {formatMoney("long", props.transferOrder.currency)}
            </AmountText>
          ) : (
            <ClaimedAmountText>
              {formatMoney("long", props.transferOrder.currency)}
            </ClaimedAmountText>
          )}
        </HStack>
      )}
    </>
  );
}

function CurrencyContainer(props: {
  icon: string;
  amount: string;
  isIconRounded?: boolean;
  showMultiplicationSign?: boolean;
}) {
  return (
    <HStack style={{ gap: 4 }}>
      <Image
        src={props.icon}
        style={{ width: 18, height: 18, borderRadius: 4 }}
      />
      <AmountText>
        {(props.showMultiplicationSign ? "×" : "") + props.amount}
      </AmountText>
    </HStack>
  );
}

const GiftSenderLabel = styled.div`
  font-weight: 300;
  font-size: 12px;
  color: #ffffff66;
`;

const UserNameStyle = css`
  max-width: 150px;
`;

const GiftingMessage = styled.div`
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  color: white;
  word-break: break-word;
  margin-top: 8px;
`;

const Divider = styled.div`
  width: 100%;
  height: 0.5px;
  background-color: #ffffff1e;
  margin-top: 20px;
`;

const ExpiredTip = styled.div`
  font-weight: 700;
  font-size: 18px;
  color: #ffffff66;
  margin-top: 48px;
`;

const CurrencyAmount = styled.div`
  font-weight: 700;
  font-size: 22px;
  color: white;
`;

const CurrencyName = styled.div`
  font-size: 11px;
  font-weight: 500;
  color: #ffffffcc;
`;

const NFTName = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: white;
  word-break: break-word;
  margin-top: 8px;
  margin-left: 60px;
  margin-right: 60px;
`;

const CollectedIntoWalletTip = styled.div`
  font-size: 12px;
  font-weight: 300;
  color: #ffffff99;
  margin-top: 20px;
`;

const UnderlinedWalletText = styled.span`
  font-weight: 300;
  font-size: 12px;
  color: #28c5f3;
  text-decoration-color: #28c5f3;
  text-decoration: underline;
`;

export const OrderInfoLabel = styled.div`
  font-weight: 300;
  font-size: 14px;
  color: #ffffff99;
`;

export const OrderInfoContent = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: white;
  word-break: break-word;
  max-width: 200px;
  text-align: end;
`;

const DetailTitle = styled.div`
  font-size: 12px;
  font-weight: 300;
  color: #ffffff99;
  width: 100%;
  margin-top: 12px;
`;

const CountDownText = styled.div`
  font-size: 11px;
  font-weight: 500;
  color: #ff7335;
  margin-top: 14px;
`;

const ClaimedTimeText = styled.div`
  font-size: 12px;
  font-weight: 300;
  color: #ffffff66;
  margin-top: 4px;
`;

const ClaimedAmountText = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #00ff94;
`;

const AmountText = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: white;
`;

const StyledImg = styled.img`
  width: 18px;
  height: 18px;
`;
