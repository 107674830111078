import { HStack, Spring, VStack } from "../../../components/VStack";
import { Image } from "../../../components/Image";
import {
  formatMoney,
  getCurrencyAmountFromString,
  getCurrencyIconByCurrencyType,
} from "../../../proto/Currency";
import styled from "styled-components";
import { OverFlowTextStyle } from "../../../components/Text";
import { StoreOrder, StoreOrderStatus } from "../../../proto/Order";
import { StoreOrderItem } from "../../../proto/Store";
import { CellDivider, ContentGroup } from "../../../components/ContentGroup";
import { OrderInfoContent, OrderInfoLabel } from "./GiftOrderDetail";
import { useI18n } from "../../../hooks/useI18n";
import { StatusBar } from "./GiftOrderListPage";
import { formatDateWithOptions } from "../../../utils/DateTimeUtils";
import { SingleOrderInfoContent } from "./CreateNewTokenDetail";

export function PurchaseOrderDetail(props: { storeOrder: StoreOrder }) {
  const i18n = useI18n();

  const orderItem = props.storeOrder.orderItemList?.at(0);
  const orderItemAmount = props.storeOrder.orderAmountList?.at(0);
  const totalPaid = props.storeOrder.paymentItemList?.at(0);

  function getStatus() {
    if (props.storeOrder.status === StoreOrderStatus.COMPLETED) {
      return { text: i18n.web3_v0_completed(), color: "#00FF94" };
    } else if (props.storeOrder.status === StoreOrderStatus.FAILED) {
      return { text: i18n.circle_anmt_failed(), color: "#EF3537FF" };
    } else return { text: "", color: "#FFFFFF66" };
  }

  return (
    <VStack style={{ width: "100%", gap: 20 }}>
      {orderItem && <OrderNFTContainer orderItem={orderItem} />}
      <ContentGroup style={{ width: "100%", gap: 20 }}>
        <HStack>
          <OrderInfoLabel>{i18n.web3_mint_coupon_total_price()}</OrderInfoLabel>
          <Spring />
          <HStack style={{ gap: 4 }}>
            <Image
              src={getCurrencyIconByCurrencyType(orderItemAmount?.currencyType)}
              style={{ width: 18, height: 18 }}
            />
            <OrderInfoContent>
              {formatMoney(
                "long",
                getCurrencyAmountFromString(orderItemAmount?.amount || "0"),
              )}
            </OrderInfoContent>
          </HStack>
        </HStack>
        <CellDivider />
        <HStack>
          <HighLightLabel>{i18n.web3_mint_coupon_total_paid()}</HighLightLabel>
          <Spring />
          <Image
            src={getCurrencyIconByCurrencyType(totalPaid?.currencyType)}
            style={{ width: 18, height: 18 }}
          />
          <OrderInfoContent>
            {formatMoney(
              "long",
              getCurrencyAmountFromString(totalPaid?.amount || "0"),
            )}
          </OrderInfoContent>
        </HStack>
      </ContentGroup>
      <ContentGroup style={{ width: "100%", gap: 20 }}>
        <HStack>
          <OrderInfoLabel>{i18n.status()}</OrderInfoLabel>
          <Spring />
          <StatusBar
            statusColor={getStatus().color}
            statusText={getStatus().text}
          />
        </HStack>
        <CellDivider />

        <HStack>
          <OrderInfoLabel>{i18n.web3_v0_the_store_name()}</OrderInfoLabel>
          <Spring />
          <HStack style={{ gap: 4 }}>
            <Image
              src={[props.storeOrder.store?.icon, "best"]}
              style={{ width: 18, height: 18, borderRadius: 4 }}
            />
            <SingleOrderInfoContent>
              {props.storeOrder.store?.name}
            </SingleOrderInfoContent>
          </HStack>
        </HStack>
        <CellDivider />

        <HStack>
          <OrderInfoLabel>{i18n.web3_v0_completed_time()}</OrderInfoLabel>
          <Spring />
          <OrderInfoContent>
            {formatDateWithOptions(props.storeOrder.paidTime || 0, {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })}
          </OrderInfoContent>
        </HStack>
        <CellDivider />

        <HStack>
          <OrderInfoLabel>{i18n.web3_v0_transaction_order_id()}</OrderInfoLabel>
          <Spring />
          <OrderInfoContent>
            {props.storeOrder.orderId.toString()}
          </OrderInfoContent>
        </HStack>
      </ContentGroup>
    </VStack>
  );
}

export function OrderNFTContainer(props: { orderItem: StoreOrderItem }) {
  return (
    <HStack
      style={{
        width: "100%",
        padding: 8,
        backgroundColor: "#FFFFFF0F",
        borderRadius: 6,
        gap: 12,
      }}
    >
      <Image
        src={[props.orderItem.cover, { width: 74, height: 74 }]}
        width={74}
        height={74}
        style={{ borderRadius: 4 }}
      />
      <VStack style={{ flex: 1, gap: 8 }}>
        <NFTName>{props.orderItem?.title}</NFTName>
        <NFTAmount>×{props.orderItem?.count}</NFTAmount>
        <HStack style={{ width: "100%" }}>
          <Image
            src={getCurrencyIconByCurrencyType(props.orderItem.currencyType)}
            style={{ width: 18, height: 18 }}
          />
          <CurrencyAmountText>
            {formatMoney(
              "long",
              getCurrencyAmountFromString(props.orderItem.price || "0"),
            )}
          </CurrencyAmountText>
        </HStack>
      </VStack>
    </HStack>
  );
}

const NFTName = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #ffffff66;
  width: 100%;
  ${OverFlowTextStyle};
  line-clamp: 2;
  -webkit-line-clamp: 2;
`;

const NFTAmount = styled.div`
  font-size: 11px;
  font-weight: 400;
  color: #ffffff99;
`;

const CurrencyAmountText = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: white;
`;

export const HighLightLabel = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: white;
`;
