import { Page } from "../../components/Page";
import { CircleGridProps } from "./CircleGridPage";
import { useHopper } from "../../hooks/useHopper";
import { useSWRArray } from "../../hooks/swr/useSWRArray";
import CircleSnippetCell from "./CircleSnippetCell";
import { Divider } from "../post/PostCell";
import { useBackend } from "../../service/APIService";
import { useBigIntSearchParam } from "../../hooks/useTypedParam";

function CircleSnippetListPage(props: CircleGridProps) {
  const hopper = useHopper();
  const onClickCircle = (circleId: bigint) => {
    hopper.push(`circle/${circleId}`);
  };
  const categoryId = useBigIntSearchParam("categoryId");

  const backend = useBackend();
  const circlesSWR = useSWRArray(
    backend.getCircles(props.circleGridType, categoryId),
  );
  return (
    <Page pageData={circlesSWR}>
      {circlesSWR.content.map((circle, index) => (
        <div key={circle.circleId}>
          <CircleSnippetCell
            circle={circle}
            onClick={() => onClickCircle(circle.circleId)}
          />
          {/* 添加底部分隔符，除了最后一个元素 */}
          {index < circlesSWR.content.length - 1 && <Divider />}
        </div>
      ))}
    </Page>
  );
}

export default CircleSnippetListPage;
