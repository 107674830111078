import styled from "styled-components";
import { CircleMemberCard } from "../../proto/Circle";
import { Image } from "../../components/Image";
import { VStack } from "../../components/VStack";
import MemberTitle from "../../components/MemberTitle";
import { useHopper } from "../../hooks/useHopper";
export interface MiniCardProps {
  card: CircleMemberCard;
}
function MiniCircleMemberCard(props: MiniCardProps) {
  const title = props.card.memberTitleList?.at(0);
  const hopper = useHopper();
  return (
    <Card
      themeColor={props.card.circleInfo.themeColor}
      onClick={() => {
        hopper.push(`circle/${props.card.circleInfo.circleId}`);
      }}
    >
      <Image
        width={35}
        height={35}
        style={{
          borderRadius: 2,
          border: `1px solid ${props.card.circleInfo.themeColor}`,
          margin: "auto 10px",
        }}
        src={[props.card.circleInfo.circleIcon, { width: 35, height: 35 }]}
      />
      <CircleInfo>
        <CircleName>{props.card.circleInfo.name}</CircleName>
        {title ? (
          <CircleTitle>
            <MemberTitle memberTitle={title} />
          </CircleTitle>
        ) : null}
      </CircleInfo>
    </Card>
  );
}

export default MiniCircleMemberCard;

const CircleName = styled.div`
  width: 86px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CircleInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: auto 0;
  gap: 2px;
`;

const Card = styled.div<{ themeColor?: string }>`
  height: 60px;
  width: 153px;
  border-radius: 8px 2px 8px 2px;
  display: flex;
  border: ${(p) => `1px solid ${p.themeColor}`};
  flex-direction: row;
  align-content: center;
  background: ${(p) =>
    `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), ${p.themeColor}`};
`;

const CircleTitle = styled.div`
  max-width: 77px;
  width: fit-content;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
