import { useI18n } from "../../../hooks/useI18n";
import {
  CenterModal,
  ModalController,
  ModalTitle,
} from "../../../components/Modal";
import { PowerMarkdown } from "../../../components/PowerMarkdown";
import { service_privacy_markdown_style } from "../../auth/AuthHomePage";
import { RegularButton } from "../../../components/Buttons";
import { removeOptional } from "../../../utils/typeUtils";
import React from "react";
import styled, { css } from "styled-components";

interface StakeNoticeDialogProps {
  modal: ModalController;
  onClickAgree: () => void;
}

function StakeNoticeDialog(props: StakeNoticeDialogProps) {
  const i18n = useI18n();
  return (
    <CenterModal modal={props.modal} mixin={ModalStyle} hideCloseBtn={true}>
      <ModalTitle style={{ marginTop: 32, fontSize: 18 }}>
        {i18n.auth_impr_pop_title()}
      </ModalTitle>
      <PowerMarkdown textStyle={service_privacy_markdown_style}>
        {i18n.clover_stake_agreement()}
      </PowerMarkdown>
      <RegularButton
        onClick={() => {
          props.onClickAgree();
          props.modal.close();
        }}
      >
        {i18n.confirm()}
      </RegularButton>
      <Cancel onClick={removeOptional(props.modal.close)}>
        {i18n.cancel()}
      </Cancel>
    </CenterModal>
  );
}

const ModalStyle = css`
  padding: 1px 20px 20px;
`;

const Cancel = styled.button`
  border: none;
  background: none;
  color: var(--color-text00);
  font-size: 16px;
`;

export default StakeNoticeDialog;
