import React from "react";
import styled from "styled-components";
import { Interest } from "../proto/Interest";

export interface InterestCellProps {
  interest: Interest;
  picked?: boolean;
  onPickedResult: () => void;
}

export const InterestCell: React.FC<InterestCellProps> = (
  props: InterestCellProps,
) => {
  return (
    <InterestCellBg
      style={{
        backgroundColor: "rgba(255,255,255,0.08)",
        borderRadius: 8,
        border: props.picked ? `0.5px solid white` : undefined,
      }}
      onClick={() => {
        props.onPickedResult();
      }}
    >
      <InterestName
        style={{
          color: props.picked ? "white" : "var(--color-text20)",
        }}
      >{`#${props.interest.insName}`}</InterestName>
    </InterestCellBg>
  );
};

const InterestName = styled.div`
  font-weight: 700;
  color: var(--color-text20);
  font-size: 12px;
`;

const InterestCellBg = styled.div`
  padding: 8px 12px; // 可选：为每个Interest cell增加内边距
  border-radius: 4px; // 可选：给Interest cell设置圆角
  background-color: #e0e0e0; // 可选：设置背景颜色
  font-size: 14px; // 可选：调整字体大小

  transition: opacity 0.2s ease; // 平滑的透明度过渡
  &:active {
    opacity: 0.4; // 按下时透明度变为 0.4
  }
`;
