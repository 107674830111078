import { Page } from "../../../components/Page";
import { useBigIntParam } from "../../../hooks/useTypedParam";
import { useSWR } from "../../../hooks/swr/useSWR";
import { useI18n } from "../../../hooks/useI18n";
import SettingCell from "../../../components/SettingCell";
import Switch from "../../settings/components/Switch";
import CircleBackgroundFrame from "../CircleBackgroundFrame";
import React from "react";
import { useHopper } from "../../../hooks/useHopper";
import { useBackend } from "../../../service/APIService";
import { NavMiddle } from "../../../components/NavBar";

function CircleNotificationSettingsPage() {
  const circleId = useBigIntParam("circleId");
  const backend = useBackend();
  const circleSWR = useSWR(backend.getCircle(circleId));
  const i18n = useI18n();
  const hopper = useHopper();
  return (
    <Page
      pageData={circleSWR}
      scrollPaddingDisabled={true}
      underlay={
        <CircleBackgroundFrame
          circleBackground={circleSWR.content?.circleBackground}
        />
      }
    >
      <NavMiddle>{i18n.notifications()}</NavMiddle>
      <SettingCell
        title={i18n.circle_anmt_circle_activities()}
        secondaryValue={i18n.receive_notifications_of_circle()}
        style={{ paddingTop: 10, paddingBottom: 10 }}
        showAccessoryImage={false}
        endValue={<Switch />}
      />
      <SettingCell
        title={i18n.jun1_text_circle_announcements()}
        secondaryValue={i18n.circle_anmt_receive_notification()}
        showAccessoryImage={false}
        showDivider={false}
        style={{ paddingTop: 10, paddingBottom: 10 }}
        endValue={<Switch />}
      />
    </Page>
  );
}

export default CircleNotificationSettingsPage;
