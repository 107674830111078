import { useI18n } from "../../../hooks/useI18n";
import { HStack, VStack } from "../../../components/VStack";
import { useBackend } from "../../../service/APIService";
import { CenterModal, ModalTitle, useModal } from "../../../components/Modal";
import { Page } from "../../../components/Page";
import { NavMiddle } from "../../../components/NavBar";
import { Divider } from "../../post/PostCell";
import { DropdownButton } from "../../../components/DropdownButton";
import { BSMenu, BSMenuItem } from "../../../components/BSMenu";
import {
  ContentSubTitle,
  ContentTitle,
} from "../folder/CircleFolderPermissionPage";
import { useCircleSWR } from "../useCircleSWR";
import {
  CircleJoinConditionType,
  Discoverability,
} from "../../../proto/Circle";
import { useGlobalSpinner } from "../../../utils/globalSpinner";
import { andLog } from "../../../components/handleError";
import { useNativePage } from "../../../hooks/useBridge";
import { useHopper } from "../../../hooks/useHopper";
import { CircleJoinPermission } from "../JoinCircleRequest";
import Switch from "../../settings/components/Switch";
import styled, { css } from "styled-components";
import { PlaceholderButton, RegularButton } from "../../../components/Buttons";
import { RegularInputGroup } from "../../../components/Input";
import React, { useState } from "react";
import { button_on_pressed } from "../../../components/CommonStyles";
import { RelativeContainer } from "../CircleHomePage";
import { StringValidator } from "../../../utils/StringValidator";
import { useCopyText } from "../../../utils/CopyText";
import icAnswer from "../../../res/images/ic_permission_answer.svg";
import icLock from "../../../res/images/ic_permission_lock.svg";
import { Image } from "../../../components/Image";

export function CirclePermissionAndPrivacyPage() {
  const [circleId, circleSWR] = useCircleSWR();
  const backend = useBackend();
  const i18n = useI18n();
  const globalSpinner = useGlobalSpinner();
  const nativePage = useNativePage();
  const hopper = useHopper();

  const currRequireCardToJoin =
    circleSWR.content?.joinConditions?.some(
      (condition) =>
        condition.conditionType === CircleJoinConditionType.MembershipCard,
    ) ?? false;

  const currDiscoverabilityIsPublic =
    (circleSWR.content?.discoverability ?? Discoverability.Public) ===
    Discoverability.Public;

  const requireMembershipCardModal = useModal("requireMembershipCardModal");
  const joinModal = useModal("joinModal");
  const discoverabilityModal = useModal("discoverabilityModal");
  const editOrDeleteModal = useModal("editOrDeleteModal");
  const onClickMembershipCardNoRequirement = () => {
    if (!currRequireCardToJoin) {
      return;
    }
    globalSpinner(() =>
      backend
        .updateCircle(circleId, { joinConditions: [] })
        .run()
        .then((circle) => circleSWR.fill(circle)),
    ).catch(andLog);
  };

  const onClickMembershipCardRequirement = async () => {
    if (currRequireCardToJoin) {
      return;
    }
    if (!circleSWR.content?.tokenProjectId) {
      const goToAdminPortal = await nativePage.alertYesOrCancel(
        i18n.clover_set_permission_after_creation(),
        i18n.clover_go_to_admin_portal(),
        i18n.cancel(),
      );
      if (goToAdminPortal) {
        hopper.push(`circle/${circleId}/admin-portal`);
      }
      return;
    }
    globalSpinner(() =>
      backend
        .updateCircle(circleId, {
          joinConditions: [
            { conditionType: CircleJoinConditionType.MembershipCard },
          ],
        })
        .run()
        .then((circle) => circleSWR.fill(circle)),
    ).catch(andLog);
  };

  const onClickJoinOpenMenu = async () => {
    console.log("onClickJoinOpenMenu");
    const currJoinPermission = circleSWR.content?.joinPermission;
    if (currJoinPermission === CircleJoinPermission.OPEN) {
      console.log("onClickJoinOpenMenu return", currJoinPermission);
      return;
    }
    if (circleSWR.content?.discoverability === Discoverability.Private) {
      const yes = await nativePage.alertYesOrCancel(
        i18n.circle_perm_change_permission_to_open(),
        i18n.yes(),
        i18n.cancel(),
      );
      if (yes) {
        let tempJoinPermission =
          currJoinPermission || CircleJoinPermission.LOCKED;
        tempJoinPermission |= CircleJoinPermission.OPEN;
        console.log("onClickJoinOpenMenu", tempJoinPermission);
        const toUpdateCircle = {
          circleId: circleId,
          joinPermission: CircleJoinPermission.OPEN,
          discoverability: Discoverability.Public,
        };
        globalSpinner(() =>
          backend
            .updateCircle(circleId, toUpdateCircle)
            .run()
            .then((circle) => circleSWR.fill(circle)),
        ).catch(andLog);
      }
    } else {
      let tempJoinPermission = currJoinPermission || CircleJoinPermission.OPEN;
      tempJoinPermission |= CircleJoinPermission.OPEN;
      console.log("onClickJoinOpenMenu", tempJoinPermission);
      const toUpdateCircle = {
        circleId: circleId,
        joinPermission: CircleJoinPermission.OPEN,
        discoverability: circleSWR.content?.discoverability,
      };

      globalSpinner(() =>
        backend
          .updateCircle(circleId, toUpdateCircle)
          .run()
          .then((circle) => circleSWR.fill(circle)),
      ).catch(andLog);
    }
  };

  const onClickJoinLockedMenu = async () => {
    const currJoinPermission =
      circleSWR.content?.joinPermission || CircleJoinPermission.OPEN;
    if (currJoinPermission !== CircleJoinPermission.OPEN) {
      console.log("onClickJoinLockedMenu return", currJoinPermission);
      return;
    }

    let tempJoinPermission =
      circleSWR.content?.joinPermission || CircleJoinPermission.LOCKED;
    tempJoinPermission &= ~CircleJoinPermission.OPEN;
    const toUpdateCircle = {
      circleId: circleId,
      joinPermission: tempJoinPermission,
      discoverability: circleSWR.content?.discoverability,
    };

    console.log("onClickJoinLockedMenu tempJoinPermission", tempJoinPermission);
    globalSpinner(() =>
      backend
        .updateCircle(circleId, toUpdateCircle)
        .run()
        .then((circle) => circleSWR.fill(circle)),
    ).catch(andLog);
  };

  const onClickDiscoverabilityPublicMenu = () => {
    if (currDiscoverabilityIsPublic) {
      return;
    }
    globalSpinner(() =>
      backend
        .updateCircle(circleId, { discoverability: Discoverability.Public })
        .run()
        .then((circle) => circleSWR.fill(circle)),
    ).catch(andLog);
  };

  const onClickDiscoverabilityPrivateMenu = async () => {
    if (!currDiscoverabilityIsPublic) {
      return;
    }

    const currJoinPermission =
      circleSWR.content?.joinPermission || CircleJoinPermission.LOCKED;
    if (
      (currJoinPermission & CircleJoinPermission.OPEN) ===
      CircleJoinPermission.OPEN
    ) {
      const yes = await nativePage.alertYesOrCancel(
        i18n.circle_perm_change_discoverability_to_private(),
        i18n.yes(),
        i18n.cancel(),
      );
      if (yes) {
        let tempJoinPermission = currJoinPermission;
        tempJoinPermission &= ~CircleJoinPermission.OPEN;
        const toUpdateCircle = {
          circleId: circleId,
          joinPermission: tempJoinPermission,
          discoverability: Discoverability.Private,
        };
        globalSpinner(() =>
          backend
            .updateCircle(circleId, toUpdateCircle)
            .run()
            .then((circle) => circleSWR.fill(circle)),
        ).catch(andLog);
      }
    } else {
      globalSpinner(() =>
        backend
          .updateCircle(circleId, {
            discoverability: Discoverability.Private,
          })
          .run()
          .then((circle) => circleSWR.fill(circle)),
      ).catch(andLog);
    }
  };

  const questionModal = useModal("questionModal");
  const passcodeModal = useModal("passcodeModal");

  function canAcceptJoinRequest() {
    return (
      ((circleSWR.content?.joinPermission || CircleJoinPermission.OPEN) &
        CircleJoinPermission.ACCEPT_JOIN_REQUEST) ===
      CircleJoinPermission.ACCEPT_JOIN_REQUEST
    );
  }

  const [currQuestion, setCurrQuestion] = useState<string | undefined>(
    circleSWR.content?.extensions?.question?.content,
  );

  function canUsePasscode() {
    return (
      ((circleSWR.content?.joinPermission || CircleJoinPermission.OPEN) &
        CircleJoinPermission.USE_PASSCODE) ===
      CircleJoinPermission.USE_PASSCODE
    );
  }

  function allowMemberInvite() {
    return (
      ((circleSWR.content?.joinPermission || CircleJoinPermission.OPEN) &
        CircleJoinPermission.ALLOW_MEMBER_INVITE) ===
      CircleJoinPermission.ALLOW_MEMBER_INVITE
    );
  }

  const onSwitchPermission = (
    checked: boolean,
    currentPermission: number,
    permissionType: number,
    condition: boolean,
    passcode?: string | undefined,
  ) => {
    let tempJoinPermission = currentPermission;
    console.log("onSwitchPermission", tempJoinPermission);
    if (checked) {
      if (condition) return;
      tempJoinPermission |= permissionType;
    } else {
      if (!condition) return;
      tempJoinPermission &= ~permissionType;
    }
    console.log(
      "onSwitchPermission checked permissionType tempJoinPermission",
      checked,
      permissionType,
      tempJoinPermission,
    );
    const toUpdateCircle = {
      circleId: circleId,
      joinPermission: tempJoinPermission,
      joinPasscode: passcode,
    };

    globalSpinner(() =>
      backend
        .updateCircle(circleId, toUpdateCircle)
        .run()
        .then((circle) => circleSWR.fill(circle)),
    ).catch(andLog);
  };

  const onSwitchAcceptJoinRequest = (checked: boolean) =>
    onSwitchPermission(
      checked,
      circleSWR.content?.joinPermission ||
        (checked ? CircleJoinPermission.LOCKED : CircleJoinPermission.OPEN),
      CircleJoinPermission.ACCEPT_JOIN_REQUEST,
      canAcceptJoinRequest(),
    );

  const onSwitchCanUsePasscode = (checked: boolean) => {
    if (checked) {
      if (canUsePasscode()) {
        return;
      } else {
        setNewPasscode(circleSWR.content?.joinPasscode || getRandomPasscode());
        passcodeModal.open();
        return;
      }
    }
    onSwitchPermission(
      checked,
      circleSWR.content?.joinPermission ||
        (checked ? CircleJoinPermission.LOCKED : CircleJoinPermission.OPEN),
      CircleJoinPermission.USE_PASSCODE,
      canUsePasscode(),
    );
  };

  const onSwitchAllowMemberInvite = (checked: boolean) =>
    onSwitchPermission(
      checked,
      circleSWR.content?.joinPermission ||
        (checked ? CircleJoinPermission.LOCKED : CircleJoinPermission.OPEN),
      CircleJoinPermission.ALLOW_MEMBER_INVITE,
      allowMemberInvite(),
    );

  function getRandomPasscode() {
    return `${Math.floor(Math.random() * 900_000) + 100_000}`;
  }

  const [newPasscode, setNewPasscode] = useState<string | undefined>();

  const maxQuestionLength = 200;
  const onSubmitQuestionClick = () => {
    if (currQuestion && currQuestion?.length > maxQuestionLength) {
      nativePage
        .infoHud(i18n.text_limit_number_of_characters_not_follow())
        .catch(andLog);
      return;
    }

    globalSpinner(() =>
      backend
        .updateCircleQuestion(circleId, currQuestion)
        .run()
        .then(() => {
          circleSWR.load().catch(andLog);
          questionModal.close();
        }),
    ).catch(andLog);
  };

  const deleteQuestion = () => {
    globalSpinner(() =>
      backend
        .updateCircleQuestion(circleId)
        .run()
        .then(() => {
          setCurrQuestion(undefined);
          //TODO:Steven local update circleSWR
          circleSWR.load().catch(andLog);
        }),
    ).catch(andLog);
  };

  const copyText = useCopyText();
  const onPasscodeCopied = () => {
    if (!circleSWR.content) {
      return;
    }
    if (
      !circleSWR.content.name ||
      !circleSWR.content.inviteLink ||
      !circleSWR.content.joinPasscode
    ) {
      return;
    }
    if (!circleSWR.content.socialId) {
      return;
    }

    const inviteMsg = `${i18n.circle_perm_invite_join_on_z(circleSWR.content.name, "Clover")}
    ${circleSWR.content.inviteLink}
    ${i18n.circle_perm_invite_circle_zid(circleSWR.content.socialId)}
    ${i18n.circle_perm_invite_passcode(circleSWR.content.joinPasscode)}`;
    copyText(inviteMsg).catch(andLog);
  };

  return (
    <Page pageData={circleSWR}>
      <NavMiddle>{i18n.mar_impr_permission()}</NavMiddle>
      <HStack
        style={{
          padding: "16px 0",
          alignItems: "start",
          gap: "8px",
        }}
      >
        <VStack style={{ width: "100%", gap: "8px" }}>
          <ContentTitle>
            {i18n.clover_membership_card_requirement()}
          </ContentTitle>
          <DropdownButton
            title={
              currRequireCardToJoin
                ? i18n.clover_require_membership_card_to_join()
                : i18n.clover_no_requirement_default()
            }
            subtitle={
              currRequireCardToJoin
                ? i18n.clover_only_users_with_card_can_join()
                : i18n.clover_anyone_can_join_without_card()
            }
            onClick={() => requireMembershipCardModal.open()}
          />
        </VStack>
      </HStack>
      <Divider />

      <HStack
        style={{
          padding: "16px 0",
          alignItems: "start",
          gap: "8px",
        }}
      >
        <VStack style={{ width: "100%", gap: "8px" }}>
          <ContentTitle>{i18n.circle_perm_join_permission()}</ContentTitle>
          <DropdownButton
            title={
              circleSWR.content?.joinPermission === CircleJoinPermission.OPEN
                ? i18n.clover_open_default()
                : i18n.circle_perm_locked()
            }
            subtitle={
              circleSWR.content?.joinPermission === CircleJoinPermission.OPEN
                ? i18n.clover_anyone_can_join_without_approval()
                : i18n.clover_only_selected_users_may_join()
            }
            onClick={() => joinModal.open()}
          >
            {circleSWR.content?.joinPermission !==
              CircleJoinPermission.OPEN && (
              <>
                <SubContent>
                  <HStack
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <VStack
                      style={{
                        flexShrink: 1,
                        gap: "8px",
                      }}
                    >
                      <ContentTitle>
                        {i18n.circle_perm_accept_join_requests()}
                      </ContentTitle>
                      <ContentSubTitle>
                        {i18n.circle_perm_allow_non_members()}
                      </ContentSubTitle>
                    </VStack>
                    <Switch
                      checked={canAcceptJoinRequest()}
                      onChange={onSwitchAcceptJoinRequest}
                    ></Switch>
                  </HStack>
                  {canAcceptJoinRequest() && (
                    <RelativeContainer style={{ width: "75%" }}>
                      <Image
                        style={{ position: "absolute", top: 0, left: 0 }}
                        src={icAnswer}
                        alt={"icAnswer"}
                        width={17}
                        height={17}
                      ></Image>
                      <PlaceholderButton
                        mixin={css`
                          font-size: 12px;
                          width: 100%;
                          display: inline-block;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          white-space: nowrap;
                          justify-content: ${circleSWR.content?.extensions
                            ?.question?.content &&
                          circleSWR.content?.extensions?.question?.content
                            .length > 0
                            ? "start"
                            : "center"};
                          color: ${circleSWR.content?.extensions?.question
                            ?.content &&
                          circleSWR.content?.extensions?.question?.content
                            .length > 0
                            ? "var(--color-text00)"
                            : "var(--color-text10)"};
                        `}
                        onClick={() => {
                          if (
                            !(
                              circleSWR.content?.extensions?.question
                                ?.content &&
                              circleSWR.content?.extensions?.question?.content
                                .length > 0
                            )
                          ) {
                            questionModal.open();
                          } else {
                            if (!currQuestion) {
                              setCurrQuestion(
                                circleSWR.content?.extensions?.question
                                  ?.content,
                              );
                            }
                            editOrDeleteModal.open();
                          }
                        }}
                      >
                        {circleSWR.content?.extensions?.question?.content &&
                        circleSWR.content?.extensions?.question?.content
                          .length > 0
                          ? circleSWR.content?.extensions?.question?.content
                          : i18n.clover_add_a_question()}
                      </PlaceholderButton>
                    </RelativeContainer>
                  )}
                </SubContent>
                <Divider
                  style={{ width: "calc(100% - 8px)", marginLeft: 20 }}
                />
                <SubContent>
                  <HStack
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <VStack
                      style={{
                        flexShrink: 1,
                        gap: "8px",
                        width: "100%",
                      }}
                    >
                      <ContentTitle>
                        {i18n.circle_perm_security_passcode()}
                      </ContentTitle>
                      <ContentSubTitle>
                        {i18n.circle_perm_users_enter_passcode_join()}
                      </ContentSubTitle>
                    </VStack>
                    <Switch
                      checked={canUsePasscode()}
                      onChange={onSwitchCanUsePasscode}
                    ></Switch>
                  </HStack>
                  {canUsePasscode() && (
                    <RelativeContainer style={{ width: "75%" }}>
                      <Image
                        style={{ position: "absolute", top: 0, left: 0 }}
                        src={icLock}
                        alt={"icLock"}
                        width={17}
                        height={17}
                      ></Image>
                      <PlaceholderButton
                        mixin={css`
                          font-size: 12px;
                          width: 100%;
                          justify-content: start;
                        `}
                        onClick={() => {
                          setNewPasscode(
                            circleSWR.content?.joinPasscode ||
                              getRandomPasscode(),
                          );
                          passcodeModal.open();
                        }}
                      >
                        {circleSWR.content?.joinPasscode}
                      </PlaceholderButton>
                      <CopyInviteButton onClick={onPasscodeCopied}>
                        {i18n.circle_perm_copy_invite()}
                      </CopyInviteButton>
                    </RelativeContainer>
                  )}
                </SubContent>
                <Divider
                  style={{ width: "calc(100% - 8px)", marginLeft: 20 }}
                />
                <SubContent>
                  <HStack
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <VStack style={{ flexShrink: 1, gap: "8px" }}>
                      <ContentTitle>
                        {i18n.clover_allow_existing_members_to_invite()}
                      </ContentTitle>
                      <ContentSubTitle>
                        {i18n.clover_users_can_join_via_invitations_from_existing()}
                      </ContentSubTitle>
                    </VStack>
                    <Switch
                      checked={allowMemberInvite()}
                      onChange={onSwitchAllowMemberInvite}
                    ></Switch>
                  </HStack>
                </SubContent>
              </>
            )}
          </DropdownButton>
        </VStack>
      </HStack>
      <Divider />

      <HStack
        style={{
          padding: "16px 0",
          alignItems: "start",
          gap: "8px",
        }}
      >
        <VStack style={{ width: "100%", gap: "8px" }}>
          <ContentTitle>{i18n.circle_perm_discoverability()}</ContentTitle>
          <DropdownButton
            title={
              currDiscoverabilityIsPublic
                ? i18n.clover_public_default()
                : i18n.web3_v0_unlisted()
            }
            subtitle={
              currDiscoverabilityIsPublic
                ? i18n.clover_circle_publicly_listed()
                : i18n.clover_discoverability_unlisted_desc()
            }
            onClick={() => discoverabilityModal.open()}
          />
        </VStack>
      </HStack>
      <Divider />

      <BSMenu
        modal={requireMembershipCardModal}
        title={i18n.clover_membership_card_requirement()}
      >
        <BSMenuItem
          title={i18n.clover_no_requirement_default()}
          subtitle={i18n.clover_anyone_can_join_without_card()}
          onClick={onClickMembershipCardNoRequirement}
          selected={!currRequireCardToJoin}
        />
        <BSMenuItem
          title={i18n.clover_require_membership_card_to_join()}
          subtitle={i18n.clover_only_users_with_card_can_join()}
          onClick={onClickMembershipCardRequirement}
          selected={currRequireCardToJoin}
        />
      </BSMenu>

      <BSMenu modal={joinModal} title={i18n.circle_perm_join_permission()}>
        <BSMenuItem
          title={i18n.clover_open_default()}
          subtitle={i18n.clover_circle_publicly_listed()}
          selected={
            circleSWR.content?.joinPermission === CircleJoinPermission.OPEN
          }
          onClick={onClickJoinOpenMenu}
        />
        <BSMenuItem
          title={i18n.circle_perm_locked()}
          subtitle={i18n.clover_only_selected_users_may_join()}
          selected={
            circleSWR.content?.joinPermission !== CircleJoinPermission.OPEN
          }
          onClick={onClickJoinLockedMenu}
        />
      </BSMenu>

      <BSMenu
        modal={discoverabilityModal}
        title={i18n.circle_perm_discoverability()}
      >
        <BSMenuItem
          title={i18n.clover_public_default()}
          subtitle={i18n.clover_discoverability_unlisted_desc()}
          onClick={onClickDiscoverabilityPublicMenu}
          selected={currDiscoverabilityIsPublic}
        />
        <BSMenuItem
          title={i18n.web3_v0_unlisted()}
          subtitle={i18n.clover_discoverability_unlisted_desc()}
          onClick={onClickDiscoverabilityPrivateMenu}
          selected={!currDiscoverabilityIsPublic}
        />
      </BSMenu>

      <BSMenu modal={editOrDeleteModal}>
        <BSMenuItem title={i18n.edit()} onClick={() => questionModal.open()} />
        <BSMenuItem title={i18n.delete()} onClick={deleteQuestion} />
      </BSMenu>

      <CenterModal
        modal={questionModal}
        onClickClose={() => {
          setCurrQuestion(undefined);
          questionModal.close();
        }}
      >
        <ModalTitle>{i18n.question()}</ModalTitle>
        <RegularInputGroup
          style={css`
            margin-left: -10px;
            margin-right: -10px;
          `}
          value={currQuestion}
          updateValue={setCurrQuestion}
          placeholder={i18n.question_placeholder()}
          maxLength={maxQuestionLength}
          multilineRows={3}
        />
        <RegularButton
          style={{ marginLeft: "21px", marginRight: "21px" }}
          onClick={onSubmitQuestionClick}
        >
          {i18n.submit()}
        </RegularButton>
      </CenterModal>

      <CenterModal modal={passcodeModal}>
        <ModalTitle>{i18n.circle_perm_set_passcode()}</ModalTitle>
        <RegularInputGroup
          style={css`
            margin-left: -10px;
            margin-right: -10px;
          `}
          value={newPasscode}
          type={"number"}
          updateValue={setNewPasscode}
          maxLength={6}
        />
        <RegularButton
          style={{ marginLeft: "21px", marginRight: "21px" }}
          onClick={() => {
            if (newPasscode && !StringValidator.isAllDigits(newPasscode)) {
              nativePage
                .infoHud(i18n.circle_perm_enter_valid_passcode())
                .catch(andLog);
              return;
            }
            passcodeModal.close();
            let tempJoinPermission =
              circleSWR.content?.joinPermission || CircleJoinPermission.LOCKED;
            tempJoinPermission |= CircleJoinPermission.USE_PASSCODE;
            const toUpdateCircle = {
              circleId: circleId,
              joinPermission: tempJoinPermission,
              joinPasscode: newPasscode,
            };

            globalSpinner(() =>
              backend
                .updateCircle(circleId, toUpdateCircle)
                .run()
                .then((circle) => circleSWR.fill(circle)),
            ).catch(andLog);
          }}
        >
          {i18n.save()}
        </RegularButton>
      </CenterModal>
    </Page>
  );
}

const SubContent = styled(HStack)`
  margin: 16px 12px 16px 20px;
  align-items: start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  z-index: 1;
`;

const CopyInviteButton = styled.button`
  position: absolute;
  background-color: #19bf88;
  border-radius: 6px;
  font-size: 10px;
  color: var(--color-text00);
  padding-left: 12px;
  padding-right: 12px;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  height: 27px;
  border-color: transparent;
  ${button_on_pressed}
`;

class CircleJoinPermissionManager {
  private permissions: CircleJoinPermission;

  constructor(
    initialPermissions: CircleJoinPermission = CircleJoinPermission.LOCKED,
  ) {
    this.permissions = initialPermissions;
  }

  // 检查是否包含某个权限
  has(permission: CircleJoinPermission): boolean {
    return (this.permissions & permission) === permission;
  }

  // 添加某个权限
  add(permission: CircleJoinPermission) {
    this.permissions |= permission;
  }

  // 移除某个权限
  remove(permission: CircleJoinPermission) {
    this.permissions &= ~permission;
  }

  // 获取当前权限状态
  getPermissions(): number {
    return this.permissions;
  }
}
