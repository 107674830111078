import { useMeasure } from "react-use";
import { useWebHost } from "../hooks/useBridge";
import useFontFaceObserver from "use-font-face-observer";
import { PropsWithChildren, useEffect } from "react";
import { andLog } from "./handleError";
import { useCurrentLanguage } from "../hooks/useI18n";
import { VStack } from "./VStack";
import { useHopper } from "../hooks/useHopper";

export function WidgetRoot(props: PropsWithChildren<{ onClick?: () => void }>) {
  const [widgetRef, widgetRect] = useMeasure<HTMLDivElement>();

  const hopper = useHopper();
  const webHost = useWebHost();
  const isFontListLoaded = useFontFaceObserver([
    {
      family: `DIN`,
    },
  ]);

  useEffect(() => {
    console.log(`onContentSizeChange ${widgetRect.height}`);
    if (isFontListLoaded)
      webHost
        .onContentSizeChange(widgetRect.width, widgetRect.height)
        .catch(andLog);
  }, [widgetRect.width, widgetRect.height, isFontListLoaded]);

  const language = useCurrentLanguage();

  return (
    <VStack
      style={{ position: "relative", alignItems: "stretch" }}
      lang={language}
      ref={widgetRef}
      onClick={props.onClick}
    >
      {isFontListLoaded && props.children}
    </VStack>
  );
}
