import { DependencyList, useCallback, useEffect, useId, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { decodeTPS, encodeTPS } from "../bridge/Bridge";
import { andLog } from "../components/handleError";
import { isRejectable, Rejectable } from "../bridge/Rejectable";
import { useBroadcast } from "./useBroadcast";
import {
  DatePickerConfig,
  MediaMenuConfig,
  NativePage,
} from "../bridge/NativePage";
import { I18n, useI18n } from "./useI18n";
import { useNativePage, useWebHost } from "./useBridge";
import { zStatic } from "../utils/zodUtils";
import { Hopper, useHopper } from "./useHopper";
import { UrlQuery } from "../utils/UrlUtil";

type BridgeBroadcastParam = {
  id: string;
  env?: string;
  value?: string; // TPS
  error?: string; // TPS
};

export function useBridgeResultProvider<R extends {}>() {
  const hopper = useHopper();
  const webHost = useWebHost();

  const [query] = useSearchParams();
  return useCallback(
    (r: R | zStatic<typeof Rejectable>) => {
      const callbackId = query.get("resultId") ?? "❌missing resultId";
      const callbackEnv = query.get("resultEnv") ?? undefined;

      console.log(callbackId, callbackEnv);
      webHost
        .broadcast("OnBridgeResult", {
          id: callbackId,
          env: callbackEnv,
          value: !isRejectable(r) ? (r ? encodeTPS(r) : undefined) : undefined,
          error: isRejectable(r) ? encodeTPS(r) : undefined,
        })
        .then(() => hopper.back())
        .catch(andLog);
    },
    [query, hopper],
  );
}

export function pushProvider<EnvT = undefined>(path: string) {
  return (
    hopper: Hopper,
    nativePage: NativePage,
    i18n: I18n,
    id: string,
    env: EnvT,
  ) => {
    hopper.openBridge(path, {
      mode: "push",
      resultId: id,
      resultEnv: encodeTPS(env),
    });
  };
}

export function modalProvider<EnvT = undefined>(path: string) {
  return (
    hopper: Hopper,
    nativePage: NativePage,
    i18n: I18n,
    id: string,
    env: EnvT,
  ) => {
    hopper.openBridge(path, {
      mode: "modal",
      resultId: id,
      resultEnv: encodeTPS(env),
    });
  };
}

export function presentProvider<EnvT = undefined>(
  path: string,
  query?: UrlQuery,
) {
  return (
    hopper: Hopper,
    nativePage: NativePage,
    i18n: I18n,
    id: string,
    env: EnvT,
  ) => {
    hopper.openBridge(
      path,
      {
        mode: "present",
        resultId: id,
        resultEnv: encodeTPS(env),
      },
      query,
    );
  };
}

export function mediaMenuProvider(config: MediaMenuConfig) {
  return (hopper: Hopper, nativePage: NativePage, i18n: I18n, id: string) => {
    nativePage.showMediaMenu(id, config).catch(andLog);
  };
}

export function datePickerProvider(config: DatePickerConfig) {
  return (hopper: Hopper, nativePage: NativePage, i18n: I18n, id: string) => {
    nativePage.pickDate(id, config).catch(andLog);
  };
}

// export function stickerPickerProvider() {
//   return (hopper: Hopper, nativePage: NativePage, i18n: I18n, id: string) => {
//     nativePage.pickSticker(id).catch(andLog);
//   };
// }

export function useBridgeResultConsumer<R extends {}, EnvT = undefined>(
  provider: (
    hopper: Hopper,
    nativePage: NativePage,
    i18n: I18n,
    id: string,
    env: EnvT,
  ) => void,
  onResult: (r: R | zStatic<typeof Rejectable>, env: EnvT) => boolean,
  deps: DependencyList,
): (env?: EnvT) => void {
  const hopper = useHopper();
  const nativePage = useNativePage();
  const i18n = useI18n();
  const requestId = useId();

  const [pendingResult, setPendingResult] = useState<{
    r: R | zStatic<typeof Rejectable>;
    env: EnvT;
  }>();

  useEffect(() => {
    if (pendingResult) {
      if (onResult(pendingResult.r, pendingResult.env)) {
        setPendingResult(undefined);
      }
    }
  }, [pendingResult, ...deps]);

  useBroadcast<BridgeBroadcastParam>(
    "OnBridgeResult",
    (param) => {
      if (param.id === requestId) {
        const error = param.error
          ? Rejectable.parse(decodeTPS(param.error))
          : undefined;
        const value = (param.value ? decodeTPS(param.value) : undefined) as R;
        const env = (param.env ? decodeTPS(param.env) : undefined) as EnvT;
        setPendingResult({
          r: error ?? value,
          env: env,
        });
      }
    },
    deps,
  );

  return (env?: EnvT) => {
    provider(hopper, nativePage, i18n, requestId, env as any);
  };
}
