import { getErrorMsg, isRejectable } from "../bridge/Rejectable";
import styled, { keyframes } from "styled-components";
import { useHopper } from "../hooks/useHopper";
import { useNativePage } from "../hooks/useBridge";
import { useI18n } from "../hooks/useI18n";

export function useErrorHandler() {
  const hopper = useHopper();
  const nativePage = useNativePage();
  const i18n = useI18n();

  return (error: any) => {
    andLog(error);
    if (error.apiCode === 1008) {
      const urlString = (error as any).redirectUrl as string;
      const url = new URL(urlString);
      const s = url.searchParams.get("s");
      hopper.push(`identify`, { s: s, c: "w" });
      return;
    }
    if (error.error === "popup_closed_by_user") {
      return;
    }
    if (isRejectable(error) && error.apiCode) {
      nativePage.alertNotice(getErrorMsg(error), i18n.ok()).catch(andLog);
    } else {
      nativePage.warnHud(getErrorMsg(error)).catch(andLog);
    }
  };
}

const Spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  border: 5px solid var(--color-text30);
  border-top: 5px solid var(--color-text00);
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  width: 30px;
  height: 30px;
  animation: ${Spin} 1s linear infinite;
`;

export function andLog(e: any) {
  console.error("Got error but ignore:", getErrorMsg(e));
  if (e instanceof Error) {
    console.log("stack", e.stack);
  }
}
