import { Page } from "../../../components/Page";
import { useBigIntParam } from "../../../hooks/useTypedParam";
import { useBackend } from "../../../service/APIService";
import { useI18n } from "../../../hooks/useI18n";
import { useHopper } from "../../../hooks/useHopper";
import { useSWR } from "../../../hooks/swr/useSWR";
import { useNativePage } from "../../../hooks/useBridge";
import { NavMiddle } from "../../../components/NavBar";
import CircleCardsPreviewCell, {
  CardStyle,
} from "../card/CircleCardsPreviewCell";
import styled from "styled-components";
import { CenterChild, HStack, VSpace } from "../../../components/VStack";
import SettingCell from "../../../components/SettingCell";
import Switch from "../../settings/components/Switch";
import { Image } from "../../../components/Image";
import { formatMoney } from "../../../proto/Currency";
import { useModal } from "../../../components/Modal";
import { TokenRequirementModal } from "./TokenRequirementModal";
import { useGlobalSpinner } from "../../../utils/globalSpinner";
import { CircleCardStatus } from "../../../proto/CircleCardStatus";
import { andLog } from "../../../components/handleError";
import { delay } from "../../../utils/ensureDur";
import { useHopState } from "../../../hooks/useHopState";
import { useEffect, useMemo } from "react";
import { jsonifyBigNumber } from "../../../utils/CurrencyAmount";
import BigNumber from "bignumber.js";
import { BSMenu, BSMenuItem } from "../../../components/BSMenu";

export function CircleCardOverviewPage() {
  const circleId = useBigIntParam("circleId");
  const cardId = useBigIntParam("cardId");
  const backend = useBackend();
  const cardSWR = useSWR(backend.getMembershipCard(circleId, cardId));
  const i18n = useI18n();
  const hopper = useHopper();
  const circleSWR = useSWR(backend.getCircle(circleId));
  const tokenProject = circleSWR.content?.tokenProject;
  const nativePage = useNativePage();
  const tokenAmountModal = useModal("tokenAmountModal");
  const globalSpinner = useGlobalSpinner();

  const cardsSWR = useSWR(backend.getCircleCardsList(circleId));

  const editAmount = async (tokenAmount: string) => {
    try {
      await globalSpinner(async () => {
        await backend
          .updateMembershipCard(circleId, cardId, {
            tokenAmount: jsonifyBigNumber(BigNumber(tokenAmount ?? "0"), 6),
          })
          .run();
        await delay(200);
        await cardSWR.load();
        await cardsSWR.load();
      });
    } catch (error) {
      console.log(error);
    }
  };

  const circleTokenCreationMenu = useModal("circleTokenCreationMenu");

  const isOnlyTwoEnabledCards = useMemo(() => {
    const cards = cardsSWR.content?.list;
    if (cards) {
      const enabledCards = cards.filter(
        (card) =>
          card.circleMembershipCard?.cardStatus === CircleCardStatus.Enable,
      );
      return enabledCards?.length === 2;
    }
    return false;
  }, [cardsSWR.content?.list]);

  const prevCard = useMemo(() => {
    const thisCard = cardSWR.content;
    const cards = cardsSWR.content?.list;
    if (thisCard && cards) {
      const currentIndex = cards.findIndex(
        (card) => card.circleMembershipCard?.cardId === thisCard.cardId,
      );
      if (currentIndex > 0) {
        return cards[currentIndex - 1];
      }
    }
  }, [cardSWR.content, cardSWR.content]);

  const nextCard = useMemo(() => {
    const thisCard = cardSWR.content;
    const cards = cardsSWR.content?.list;
    if (thisCard && cards) {
      const currentIndex = cards.findIndex(
        (card) => card.circleMembershipCard?.cardId === thisCard.cardId,
      );
      if (currentIndex >= 0) {
        return cards[currentIndex + 1];
      }
    }
  }, [cardSWR.content, cardSWR.content]);

  const switchEnable = async (value: boolean) => {
    try {
      if (!value && isOnlyTwoEnabledCards) {
        nativePage.infoHud(i18n.clover_cant_disable_card_alert()).catch(andLog);
        return;
      }

      if (
        prevCard &&
        value &&
        BigNumber(
          prevCard?.circleMembershipCard?.currency?.amount ?? "0",
        ).isGreaterThan(BigNumber(cardSWR.content?.currency?.amount ?? "0"))
      ) {
        nativePage
          .infoHud(i18n.clover_cant_enable_smaller_card_alert())
          .catch(andLog);
        return;
      }

      if (
        nextCard &&
        value &&
        BigNumber(cardSWR.content?.currency?.amount ?? "0").isGreaterThan(
          BigNumber(nextCard?.circleMembershipCard?.currency?.amount ?? "0"),
        )
      ) {
        nativePage
          .infoHud(i18n.clover_cant_enable_larger_card_alert())
          .catch(andLog);
        return;
      }

      await globalSpinner(async () => {
        await backend
          .updateMembershipCardStatus(circleId, cardId, {
            cardStatus: value
              ? CircleCardStatus.Enable
              : CircleCardStatus.Disabled,
          })
          .run();
        await delay(200);
        await cardSWR.load();
        await cardsSWR.load();
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Page pageData={[circleSWR, cardSWR]}>
      <NavMiddle>
        {`Lv.${cardSWR.content?.membershipLevel ?? 0} ${i18n.member_title_membership_card()}`}
      </NavMiddle>
      <HStack
        onClick={() => {
          circleTokenCreationMenu.open();
        }}
      >
        <CircleCardsPreviewCell
          card={cardSWR.content}
          cardStyle={CardStyle.large}
          circle={circleSWR.content}
          showEditIcon={true}
        />
      </HStack>
      <VSpace height={16} />
      <CenterChild>
        <PreviewLabel>{i18n.clover_membership_card_preview()}</PreviewLabel>
      </CenterChild>
      <VSpace height={32} />
      <SettingCell
        style={{ paddingInlineStart: 0, paddingInlineEnd: 0 }}
        title={i18n.clover_enable_this_level()}
        showAccessoryImage={false}
        endValue={
          <Switch
            checked={cardSWR.content?.cardStatus === CircleCardStatus.Enable}
            onChange={(b) => {
              switchEnable(b).catch(andLog);
            }}
          />
        }
      />
      <SettingCell
        onClick={() => {
          tokenAmountModal.open();
        }}
        style={{ paddingInlineStart: 0, paddingInlineEnd: 0 }}
        title={i18n.clover_token_requirement()}
        endValue={
          <HStack style={{ gap: 4 }}>
            <Image
              src={[circleSWR.content?.tokenProject?.image, "smallest"]}
              style={{ borderRadius: 2 }}
              width={20}
              height={20}
            />
            <TokenTickerValue>
              {`${formatMoney("medium", cardSWR.content?.currency)} ${circleSWR.content?.tokenProject?.ticker}`}
            </TokenTickerValue>
          </HStack>
        }
      />
      <TokenRequirementModal
        modal={tokenAmountModal}
        backend={backend}
        performEdit={editAmount}
        currency={cardSWR.content?.currency}
      />

      <BSMenu modal={circleTokenCreationMenu}>
        <BSMenuItem
          title={i18n.clover_edit_level_name()}
          onClick={() => {
            hopper.present(
              `circle/${circleId}/card-overview/${cardId}/edit-name`,
            );
          }}
        />
        <BSMenuItem title={i18n.clover_edit_card_face()} onClick={() => {}} />
      </BSMenu>
    </Page>
  );
}

const PreviewLabel = styled.div`
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  font-weight: 400;
`;

const TokenTickerValue = styled.div`
  color: var(--color-text00);
  font-size: 12px;
  font-weight: 400;
`;
