import { Category } from "../proto/Category";
import { CenterChild, VStack } from "./VStack";
import { Image } from "./Image";

export interface CategoryCellProps {
  category: Category;
  onClick: () => void;
}

function CategoryCell({ category, onClick }: CategoryCellProps) {
  return (
    <CenterChild
      style={{ gap: 8, marginBottom: 12, flexDirection: "column" }}
      onClick={onClick}
    >
      <Image
        src={[category.sticker?.media, "smallest"]}
        width={32}
        height={32}
      />
      <div style={{ fontWeight: 400, fontSize: 11 }}>{category.name}</div>
    </CenterChild>
  );
}

export default CategoryCell;
