import { z } from "zod";
import { Currency } from "./Currency";
import { zEnum, zStatic } from "../utils/zodUtils";

export enum LogType {
  Commission = 1,
  Claim = 2,
}

export const CircleCommissionLog = z.object({
  createdTime: z.number(),
  rewardCurrency: Currency,
  logType: zEnum(LogType),
});

export type CircleCommissionLog = zStatic<typeof CircleCommissionLog>;
