import { SetStateAction, useEffect, useId, useMemo, useState } from "react";
import { isSetActionFunction } from "./Initializer";

export class SharedState<T> {
  private readonly subscribers: Map<string, (value: T) => void> = new Map();
  value: T;

  constructor(init: T) {
    this.value = init;
  }

  subscribe(id: string, subscriber: (value: T) => void) {
    this.subscribers.set(id, subscriber);
    subscriber(this.value);
  }

  unsubscribe(id: string) {
    this.subscribers.delete(id);
  }

  get(): T {
    return this.value;
  }

  set(action: SetStateAction<T>) {
    if (isSetActionFunction(action)) {
      this.value = action(this.value);
    } else {
      this.value = action;
    }

    this.subscribers.forEach((s) => {
      s(this.value);
    });
  }
}

export function useSharedState<T>(init: T) {
  return useMemo(() => new SharedState(init), []);
}

export function useSharedStateRead<T>(coordinator: SharedState<T>) {
  const [state, setState] = useState<T>(coordinator.get());

  const id = useId();
  useEffect(() => {
    coordinator.subscribe(id, (v) => setState(v));
    return () => {
      coordinator.unsubscribe(id);
    };
  }, [coordinator, setState, id]);

  return state;
}
