import { Page } from "../../components/Page";
import { useBackend } from "../../service/APIService";
import { useSWR } from "../../hooks/swr/useSWR";
import { useMyUid } from "../../service/AuthSessionService";
import { NavMiddle } from "../../components/NavBar";
import { I18n, useI18n } from "../../hooks/useI18n";
import SettingCell from "../../components/SettingCell";
import { UserIconView } from "../../components/views/UserAvatarView";
import { HStack, VStack } from "../../components/VStack";
import { Image, MediaSizeMode } from "../../components/Image";
import { User, UserMood } from "../../proto/User";
import onlineDot from "../../res/images/ic_online_dot.svg";
import offlineDot from "../../res/images/ic_offline_dot.svg";
import { Media } from "../../proto/Media";
import styled from "styled-components";
import { SingleLineUnspecifiedWidth } from "../../components/CommonViews";
import { useHopper } from "../../hooks/useHopper";
import { StateId } from "../../hooks/StateId";
import { useLocalRepo } from "../../hooks/swr/useLocalRepo";
import { useEffect } from "react";
import { useNativePage } from "../../hooks/useBridge";
import { andLog, useErrorHandler } from "../../components/handleError";
import { BottomSheet, ModalTitle, useModal } from "../../components/Modal";
import MoodCell, { HeadFrame } from "./MoodCell";
import addIcon from "../../res/images/ic_add_mood.svg";
import disturbIcon from "../../res/images/ic_distrub.png";
import { UserMoodType, ZodiacType } from "../../proto/UserEnums";
import iconAries from "../../res/images/ic_aries.svg";
import iconTaurus from "../../res/images/ic_taurus.svg";
import iconGemini from "../../res/images/ic_gemini.svg";
import iconCancer from "../../res/images/ic_cancer.svg";
import iconLeo from "../../res/images/ic_leo.svg";
import iconVirgo from "../../res/images/ic_virgo.svg";
import iconLibra from "../../res/images/ic_libra.svg";
import iconScorpius from "../../res/images/ic_scorpius.svg";
import iconSagittarius from "../../res/images/ic_sagittarius.svg";
import iconCapricornus from "../../res/images/ic_capricornus.svg";
import iconAquarius from "../../res/images/ic_aquarius.svg";
import iconPisces from "../../res/images/ic_pisces.svg";
import { useGlobalSpinner } from "../../utils/globalSpinner";

export function getZodiacNameByType(i18n: I18n, type: ZodiacType) {
  switch (type) {
    case ZodiacType.Aries:
      return i18n.gender_constel_zodiac_aries();
    case ZodiacType.Taurus:
      return i18n.gender_constel_zodiac_taurus();
    case ZodiacType.Gemini:
      return i18n.gender_constel_zodiac_gemini();
    case ZodiacType.Cancer:
      return i18n.gender_constel_zodiac_cancer();
    case ZodiacType.Leo:
      return i18n.gender_constel_zodiac_leo();
    case ZodiacType.Virgo:
      return i18n.gender_constel_zodiac_virgo();
    case ZodiacType.Libra:
      return i18n.gender_constel_zodiac_libra();
    case ZodiacType.Scorpius:
      return i18n.gender_constel_zodiac_scorpius();
    case ZodiacType.Sagittarius:
      return i18n.gender_constel_zodiac_sagittarius();
    case ZodiacType.Capricornus:
      return i18n.jul22_text_zodiac_capricorns();
    case ZodiacType.Aquarius:
      return i18n.gender_constel_zodiac_aquarius();
    case ZodiacType.Pisces:
      return i18n.gender_constel_zodiac_pisces();
    default:
      return "";
  }
}

export function getZodiacIconByType(type: ZodiacType) {
  switch (type) {
    case ZodiacType.Aries:
      return iconAries;
    case ZodiacType.Taurus:
      return iconTaurus;
    case ZodiacType.Gemini:
      return iconGemini;
    case ZodiacType.Cancer:
      return iconCancer;
    case ZodiacType.Leo:
      return iconLeo;
    case ZodiacType.Virgo:
      return iconVirgo;
    case ZodiacType.Libra:
      return iconLibra;
    case ZodiacType.Scorpius:
      return iconScorpius;
    case ZodiacType.Sagittarius:
      return iconSagittarius;
    case ZodiacType.Capricornus:
      return iconCapricornus;
    case ZodiacType.Aquarius:
      return iconAquarius;
    case ZodiacType.Pisces:
      return iconPisces;
    default:
      return "";
  }
}

export function useUserEditResult(sceneId: StateId, uid: bigint) {
  return useLocalRepo<User>(["userEdit", sceneId], {
    uid,
  });
}

export function useUserMoodEditResult(sceneId: StateId, uid: bigint) {
  return useLocalRepo<UserMood>(["userEdit", sceneId], {});
}

export const getUserMood = (
  userMood?: UserMood,
): string | [Media | undefined, MediaSizeMode] => {
  switch (userMood?.type) {
    case UserMoodType.ONLINE:
      return onlineDot;
    case UserMoodType.OFFLINE:
      return offlineDot;
    case UserMoodType.CUSTOM:
      return [userMood?.sticker?.media, { width: 30, height: 30 }];
    case UserMoodType.DONOTDISTURB:
      return disturbIcon;
    default:
      return onlineDot;
  }
};

export const getUserMoodText = (
  mood: UserMood,
  i18n: I18n,
): string | undefined => {
  switch (mood.type) {
    case UserMoodType.ONLINE:
      return i18n.online();
    case UserMoodType.OFFLINE:
      return i18n.offline();
    case UserMoodType.CUSTOM:
      return mood.text;
    case UserMoodType.DONOTDISTURB:
      return i18n.do_not_disturb();
    default:
      return i18n.online();
  }
};

function EditMyPublicProfilePage() {
  const backend = useBackend();
  const i18n = useI18n();
  const uid = useMyUid();
  const userSWR = useSWR(backend.getUser(uid));
  const hopper = useHopper();
  const handleError = useErrorHandler();
  const sceneId = ["User", uid]; // you are editing a chatroom with threadId = 12345
  const userEdited = useUserEditResult(sceneId, uid);
  const nativePage = useNativePage();
  const statusSheet = useModal("statusSheet");
  const zodiacSheet = useModal("zodiacShteet");
  const moodsSWR = useSWR(backend.getMyMoodsList());
  const globalSpinner = useGlobalSpinner();

  useEffect(() => {
    updateUser().catch(andLog);
  }, [userEdited.content]);

  const updateUser = async () => {
    globalSpinner(async () => {
      await userSWR.load();
    }).catch(andLog);
  };

  const onSelected = async (userMood: UserMood) => {
    try {
      const selected =
        (userMood.type === UserMoodType.ONLINE &&
          userSWR.content?.userMood?.type === UserMoodType.ONLINE &&
          !userMood.doNotDisturbDuration) ||
        (userMood.type === UserMoodType.OFFLINE &&
          userSWR.content?.userMood?.type === UserMoodType.OFFLINE &&
          !userMood.doNotDisturbDuration) ||
        (userMood.stickerId === userSWR.content?.userMood?.stickerId &&
          userMood.text === userSWR.content?.userMood?.text &&
          !userMood.doNotDisturbDuration) ||
        (!!userMood.doNotDisturbDuration &&
          userMood.doNotDisturbDuration ===
            userSWR.content?.userMood?.doNotDisturbDuration);
      if (!selected) {
        await globalSpinner(async () => {
          await backend.updateProfile({ uid, userMood }).run();
          updateUser().catch(andLog);
          statusSheet.close();
        });
      }
    } catch (e) {
      handleError(e);
    }
  };

  return (
    <Page pageData={userSWR}>
      <NavMiddle>{i18n.better_profile_edit_my_public_profile()}</NavMiddle>
      {userSWR.content && (
        <>
          <SettingCell
            title={i18n.user_icon()}
            endValue={<UserIconView user={userSWR.content} iconSize={30} />}
          ></SettingCell>
          <SettingCell
            onClick={() =>
              hopper.requestPresent("edit-username", sceneId, {
                name: userSWR.content?.nickname,
              })
            }
            title={i18n.user_name()}
            endValue={userSWR.content?.nickname}
          />
          <SettingCell
            onClick={() =>
              hopper.present(`edit-social-id`, {
                zid: userSWR.content?.socialId,
              })
            }
            title={i18n.user_social_id()}
            endValue={userSWR.content?.socialId}
          />
          <SettingCell
            onClick={() => statusSheet.open()}
            title={i18n.status()}
            endValue={
              <>
                {userSWR.content?.userMood ? (
                  <HStack style={{ gap: 8 }}>
                    <Image
                      src={getUserMood(userSWR.content?.userMood)}
                      style={
                        userSWR.content.userMood.type === UserMoodType.CUSTOM ||
                        userSWR.content.userMood.type ===
                          UserMoodType.DONOTDISTURB
                          ? { width: 30, height: 30 }
                          : { width: 10, height: 10 }
                      }
                    />
                    <div>{getUserMoodText(userSWR.content.userMood, i18n)}</div>
                  </HStack>
                ) : null}
              </>
            }
          />
          <SettingCell
            onClick={() => zodiacSheet.open()}
            title={i18n.gender_constel_zodiac_title()}
            endValue={
              userSWR.content?.zodiacType
                ? getZodiacNameByType(i18n, userSWR.content.zodiacType)
                : null
            }
          />
          <SettingCell title={i18n.ext_social_social_links()} />
          <SettingCell title={i18n.tags()} />
          <SettingCell
            title={i18n.tagline()}
            endValue={<TagLine>{userSWR.content?.tagline}</TagLine>}
          />
          <SettingCell title={i18n.voice_bio()} />
          <SettingCell title={i18n.text_bio()} />
          <SettingCell
            title={i18n.user_school()}
            endValue={<TagLine>{userSWR.content?.school}</TagLine>}
          />
        </>
      )}
      <BottomSheet modal={statusSheet}>
        <VStack
          style={{
            padding: `20px 20px`,
          }}
        >
          <ModalTitle>{i18n.choose_your_status()}</ModalTitle>

          <Subtitle style={{ marginTop: 24 }}>{i18n.default_string()}</Subtitle>
          <GridLayout>
            <MoodCell
              key={`online`}
              hardcodeMod={"online"}
              selectedMood={userSWR.content?.userMood}
              onClick={() => {
                onSelected({ type: UserMoodType.ONLINE }).catch(andLog);
              }}
            />
            <MoodCell
              key={`offline`}
              hardcodeMod={"offline"}
              selectedMood={userSWR.content?.userMood}
              onClick={() => {
                onSelected({ type: UserMoodType.OFFLINE }).catch(andLog);
              }}
            />
            {moodsSWR.content?.defaultMoods?.map((userMood) => (
              <MoodCell
                key={`default-${userMood.stickerId}-${userMood.text}`}
                mood={userMood}
                selectedMood={userSWR.content?.userMood}
                onClick={() => {
                  onSelected({ ...userMood, type: UserMoodType.CUSTOM }).catch(
                    andLog,
                  );
                }}
              />
            ))}
          </GridLayout>
          <DisturbFrame
            $selected={!!userSWR.content?.userMood?.doNotDisturbDuration}
          >
            <DisturbHead>
              <Image
                src={disturbIcon}
                style={{ width: 22, height: 22, margin: "auto 15px" }}
              />
              <DisturbTitle>
                <DisturbName>{i18n.do_not_disturb()}</DisturbName>
                <DisturbName
                  style={{ fontSize: 11, color: `rgba(255, 255, 255, 0.4)` }}
                >
                  {i18n.feb24_impr_notis_will_be_muted_when_this_status()}
                </DisturbName>
              </DisturbTitle>
            </DisturbHead>
            <DisturbName style={{ margin: "10px 10px 4px" }}>
              {i18n.feb24_impr_duration()}
            </DisturbName>
            <HStack style={{ margin: "0 10px 12px", gap: 7, flexWrap: "wrap" }}>
              <TimeTab
                $selected={
                  userSWR.content?.userMood?.doNotDisturbDuration === 1800
                }
                onClick={() => {
                  onSelected({
                    type: UserMoodType.DONOTDISTURB,
                    doNotDisturbDuration: 1800,
                  }).catch(andLog);
                }}
              >
                30 min
              </TimeTab>
              <TimeTab
                $selected={
                  userSWR.content?.userMood?.doNotDisturbDuration === 3600
                }
                onClick={() => {
                  onSelected({
                    type: UserMoodType.DONOTDISTURB,
                    doNotDisturbDuration: 3600,
                  }).catch(andLog);
                }}
              >
                1 hr
              </TimeTab>
              <TimeTab
                $selected={
                  userSWR.content?.userMood?.doNotDisturbDuration === 7200
                }
                onClick={() => {
                  onSelected({
                    type: UserMoodType.DONOTDISTURB,
                    doNotDisturbDuration: 7200,
                  }).catch(andLog);
                }}
              >
                2 hr
              </TimeTab>
              <TimeTab
                $selected={
                  userSWR.content?.userMood?.doNotDisturbDuration === 86400
                }
                onClick={() => {
                  onSelected({
                    type: UserMoodType.DONOTDISTURB,
                    doNotDisturbDuration: 86400,
                  }).catch(andLog);
                }}
              >
                24 hr
              </TimeTab>
            </HStack>
          </DisturbFrame>

          <Subtitle style={{ marginTop: 35 }}>{i18n.customize()}</Subtitle>
          <GridLayout>
            {moodsSWR.content?.customMoods?.map((userMood) => (
              <MoodCell
                key={`custom-${userMood.stickerId}-${userMood.text}`}
                mood={userMood}
                selectedMood={userSWR.content?.userMood}
                onClick={() => {
                  onSelected({ ...userMood, type: UserMoodType.CUSTOM }).catch(
                    andLog,
                  );
                }}
              />
            ))}
            <AddFrame onClick={() => hopper.present(`edit-status`)}>
              <HeadFrame>
                <Image src={addIcon} width={30} height={30} />
              </HeadFrame>
              <AddTitle>{i18n.add_status()}</AddTitle>
            </AddFrame>
          </GridLayout>
        </VStack>
      </BottomSheet>
      <BottomSheet modal={zodiacSheet}>
        <VStack
          style={{
            padding: `20px 20px`,
          }}
        >
          <ModalTitle>Choose Your Zodiac</ModalTitle>
          {/*<GridLayout*/}
          {/*  style={{*/}
          {/*    gridTemplateColumns: "repeat(auto-fill, minmax(96px, 1fr))",*/}
          {/*  }}*/}
          {/*>*/}
          {/*  {Object.values(ZodiacType)*/}
          {/*    .filter((type) => typeof type === "number" && type !== 0)*/}
          {/*    .map((type) => (*/}
          {/*      <ZodiacCell $selected={userSWR.content?.zodiacType === type}>*/}
          {/*        <Image*/}
          {/*          src={getZodiacIconByType(type)}*/}
          {/*          style={{ width: 50, height: 50 }}*/}
          {/*        />*/}
          {/*        {getZodiacNameByType(i18n, type)}*/}
          {/*      </ZodiacCell>*/}
          {/*    ))}*/}
          {/*</GridLayout>*/}
        </VStack>
      </BottomSheet>
    </Page>
  );
}

const ZodiacCell = styled.div<{ $selected: boolean }>`
  width: 96px;
  height: 96px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: ${(p) =>
    p.$selected ? `rgba(148, 255, 222, 1)` : `rgba(255, 255, 255, 1)`};
  font-size: 12px;
  border-radius: 10px;
  background: ${(p) =>
    p.$selected ? `rgba(0, 175, 163, 0.14)` : `rgba(255, 255, 255, 0.05)`};
  align-items: center;
  justify-content: center;
  border: ${(p) => (p.$selected ? `1px solid rgba(148, 255, 222, 1)` : `none`)};
`;

const TimeTab = styled.div<{ $selected: boolean }>`
  width: 66px;
  height: 26px;
  display: flex;
  border-radius: 4px;
  color: ${(p) =>
    p.$selected ? `rgba(13, 255, 204, 1)` : `rgba(255, 255, 255, 0.6)`};
  font-size: 12px;
  align-items: center;
  justify-content: center;
  background: ${(p) =>
    p.$selected ? `rgba(0, 175, 163, 0.22)` : `rgba(255, 255, 255, 0.08)`};
`;

const DisturbName = styled.div`
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
`;

const DisturbTitle = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 10px 0;
`;

const DisturbHead = styled.div`
  border-radius: 6px 0 0 6px;
  background: rgba(255, 255, 255, 0.05);
  display: flex;
  flex-direction: row;
`;

const DisturbFrame = styled.div<{ $selected: boolean }>`
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.05);
  margin-top: 11px;
  background: ${(p) =>
    p.$selected ? `rgba(0, 175, 163, 0.14)` : `rgba(255, 255, 255, 0.05)`};
  border-radius: 6px;
  border: ${(p) =>
    p.$selected ? "0.5px solid rgba(13, 255, 204, 1)" : `nones`};
`;

const AddTitle = styled.div`
  font-size: 14px;
  color: rgba(255, 255, 255, 0.4);
  margin: auto 11px;
`;

const AddFrame = styled.div`
  height: 50px;
  display: flex;
  flex-direction: row;
  border: 1px dashed rgba(255, 255, 255, 0.3); /* 虚线边框 */
  border-radius: 6px; /* 可选，增加圆角 */
  background: rgba(255, 255, 255, 0.05);
`;

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(162px, 1fr));
  grid-gap: 11px;
`;

const Subtitle = styled.div`
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 18px;
`;
const TagLine = styled.div`
  flex: 1;
  ${SingleLineUnspecifiedWidth};
`;
export default EditMyPublicProfilePage;
