import styled from "styled-components";
import { HSpace, HStack, HStackMixin, Spring } from "./VStack";
import { NavButtonDesc, NavButtonType } from "../bridge/NavButtonDesc";
import { Image } from "./Image";

import icBack from "../res/images/ic_abar_back.svg";
import icCheck from "../res/images/ic_abar_check.svg";
import icCross from "../res/images/ic_abar_cross.svg";
import icInfo from "../res/images/ic_abar_info.svg";
import icManage from "../res/images/ic_abar_manage.svg";
import icPower from "../res/images/ic_abar_power.svg";
import icSearch from "../res/images/ic_abar_search.svg";
import icMore from "../res/images/ic_abar_more.svg";
import icEllipsis from "../res/images/ic_ellipsis.svg";

import { SingleLineUnspecifiedWidth } from "./CommonViews";
import React, {
  createContext,
  forwardRef,
  isValidElement,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { ButtonColor, RegularSmallButton } from "./Buttons";
import { useBorderBoxSize } from "../hooks/useResizeObserver";
import { useObscuringBar } from "../hooks/useObscuringBar";
import {
  SharedState,
  useSharedState,
  useSharedStateRead,
} from "../hooks/useSharedState";

const Bg = styled.div`
  ${HStackMixin};
  background: transparent;
  height: 64px;
  width: 100%;
  top: 0;
  box-sizing: border-box;
  position: absolute;
  align-items: center;
  justify-content: stretch;
  padding: 8px 18px;
`;

export type NavButtonItem = {
  button: NavButtonDesc;
  action: () => void;
};

export const NavItem = {
  image: (type: NavButtonType, action: () => void) => {
    return {
      button: {
        type: type,
      },
      action: action,
    };
  },

  text: (title: string, action: () => void) => {
    return {
      button: {
        type: NavButtonType.Text,
        title: title,
      },
      action: action,
    };
  },

  done: (title: string, action: () => void) => {
    return {
      button: {
        type: NavButtonType.DoneTitle,
        title: title,
      },
      action: action,
    };
  },
};

const ImgBtn = styled.img`
  width: 36px;
  height: 36px;
`;

function iconFromType(type: NavButtonType) {
  switch (type) {
    case NavButtonType.More:
      return icMore;
    case NavButtonType.Check:
      return icCheck;
    case NavButtonType.Cross:
      return icCross;
    case NavButtonType.Info:
      return icInfo;
    case NavButtonType.Manage:
      return icManage;
    case NavButtonType.Plus:
      return icCheck;
    case NavButtonType.Power:
      return icPower;
    case NavButtonType.Search:
      return icSearch;
    case NavButtonType.Ellipsis:
      return icEllipsis;
    case NavButtonType.Back:
      return icBack;
    default:
      return undefined;
  }
}

const TextButton = styled.button`
  border: none;
  background: none;
  color: var(--color-text00);
  font-size: 12px;
`;

function NavButton(props: { item: NavButtonItem }) {
  const icon = iconFromType(props.item.button.type);
  const onClick = useCallback(() => {
    props.item.action();
  }, [props.item]);

  return icon ? (
    <Image src={icon} styledImg={ImgBtn} onClick={onClick}></Image>
  ) : props.item.button.type === NavButtonType.DoneTitle ? (
    <RegularSmallButton $baseColor={ButtonColor.greenish} onClick={onClick}>
      {props.item.button.title}
    </RegularSmallButton>
  ) : (
    <TextButton onClick={onClick}>{props.item.button.title}</TextButton>
  );
}

export const NavTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: var(--color-text00);
  ${SingleLineUnspecifiedWidth}
`;

export type NavItem = NavButtonItem | ReactElement;

export const NavBar = forwardRef(function (
  props: {
    canDismiss: boolean;
    canGoBack: SharedState<boolean>;
    onBackward: (type: "back" | "dismiss") => void;
    middleItem: SharedState<ReactElement | string | undefined>;
    startItems: SharedState<NavItem[]>;
    endItems: SharedState<NavItem[]>;
    showsBackwardButtonEvenStartPresents: SharedState<boolean | undefined>;
  },
  outerRef: React.ForwardedRef<HTMLDivElement>,
) {
  const [startRef, startSize] = useBorderBoxSize();
  const [endRef, endSize] = useBorderBoxSize();

  const canGoBack = useSharedStateRead(props.canGoBack);
  const backwardType = useMemo(() => {
    return canGoBack ? "back" : props.canDismiss ? "dismiss" : undefined;
  }, [canGoBack, props.canDismiss]);

  const middleItem = useSharedStateRead(props.middleItem);
  const startItems = useSharedStateRead(props.startItems);
  const endItems = useSharedStateRead(props.endItems);
  const showsBackwardButtonEvenStartPresents = useSharedStateRead(
    props.showsBackwardButtonEvenStartPresents,
  );

  const extraSpaces = useMemo<[number, number] | undefined>(() => {
    if (middleItem === undefined) {
      return undefined;
    }
    if (startSize?.width !== undefined && endSize?.width !== undefined) {
      if (startSize.width > endSize.width) {
        return [0, startSize.width - endSize.width];
      } else {
        return [endSize.width - startSize.width, 0];
      }
    } else {
      return [0, 0];
    }
  }, [startSize?.width, endSize?.width]);

  return (
    <Bg ref={outerRef}>
      <HStack
        style={{
          justifyContent: "left",
          alignItems: "center",
          flexShrink: 1,
          overflow: "hidden",
          gap: 4,
        }}
        ref={startRef}
      >
        <>
          {backwardType &&
            (showsBackwardButtonEvenStartPresents ||
              startItems.length === 0) && (
              <Image
                key={"back"}
                src={backwardType === "back" ? icBack : icCross}
                onClick={() => props.onBackward(backwardType)}
                styledImg={ImgBtn}
              />
            )}
          {startItems.map((item, i) =>
            isValidElement(item) ? (
              item
            ) : (
              <NavButton item={item} key={item.button.type} />
            ),
          )}
        </>
      </HStack>
      {extraSpaces && <HSpace width={extraSpaces[0]}></HSpace>}
      {middleItem ? (
        <HStack
          style={{
            justifyContent: "center",
            alignItems: "center",
            flexShrink: 1,
            flexGrow: 1,
            flexDirection: "row-reverse",
          }}
        >
          {isValidElement(middleItem) ? (
            middleItem
          ) : (
            <NavTitle style={{ flexShrink: 1 }}>{middleItem}</NavTitle>
          )}
        </HStack>
      ) : (
        <Spring />
      )}
      {extraSpaces && <HSpace width={extraSpaces[1]}></HSpace>}
      <HStack
        style={{
          justifyContent: "right",
          alignItems: "center",
          flexShrink: 1,
          gap: 4,
        }}
        ref={endRef}
      >
        {endItems.map((item, i) =>
          isValidElement(item) ? (
            item
          ) : (
            <NavButton item={item} key={item.button.type} />
          ),
        )}
      </HStack>
    </Bg>
  );
});

export interface NavBarContext {
  hasNavBar: boolean;
  setCanGoBack: (canGoBack: boolean) => void;
  setStartItems: (
    items: NavItem[],
    showsBackEvenStartPresents: boolean | undefined,
  ) => void;
  setEndItems: (items: NavItem[]) => void;
  setMiddleItem: (item: string | ReactElement | undefined) => void;
}

export const NavBarContext = createContext<NavBarContext>({
  hasNavBar: false,
  setCanGoBack: (canGoBack: boolean) => {
    console.assert("NYI");
  },
  setStartItems: (
    items: NavItem[],
    showsBackEvenStartPresents: boolean | undefined,
  ) => {
    console.assert("NYI");
  },
  setEndItems: (items: NavItem[]) => {
    console.assert("NYI");
  },
  setMiddleItem: (item: string | ReactElement | undefined) => {
    console.assert("NYI");
  },
});

export function NavStart(props: {
  children?: NavItem | NavItem[] | undefined;
  showsBackEvenStartPresents?: boolean;
}) {
  const context = useContext(NavBarContext);
  useEffect(() => {
    context.setStartItems(
      props.children === undefined
        ? []
        : Array.isArray(props.children)
          ? props.children
          : [props.children],
      props.showsBackEvenStartPresents,
    );
    return () => {
      context.setStartItems([], undefined);
    };
  }, [props.children, props.showsBackEvenStartPresents]);

  return <></>;
}

export function useHasNavBar() {
  return useContext(NavBarContext).hasNavBar;
}

export function NavEnd(props: { children?: NavItem | NavItem[] | undefined }) {
  const context = useContext(NavBarContext);
  useEffect(() => {
    context.setEndItems(
      props.children === undefined
        ? []
        : Array.isArray(props.children)
          ? props.children
          : [props.children],
    );

    return () => {
      context.setEndItems([]);
    };
  }, [props.children]);
  return <></>;
}

export function NavMiddle(props: {
  children?: string | ReactElement | undefined;
}) {
  const context = useContext(NavBarContext);
  useEffect(() => {
    context.setMiddleItem(props.children);
    return () => {
      context.setMiddleItem(undefined);
    };
  }, [props.children]);
  return <></>;
}

export function NavBarBoundary(
  props: PropsWithChildren<{
    canDismiss: boolean;
    onBackward: (type: "back" | "dismiss") => void;
  }>,
) {
  const [barRef] = useObscuringBar("NavBar", "top");

  const canGoBack = useSharedState(false);
  const startItems = useSharedState<NavItem[]>([]);

  const showsBackwardButtonEvenStartPresents = useSharedState<
    boolean | undefined
  >(undefined);

  const middleItem = useSharedState<string | ReactElement | undefined>(
    undefined,
  );
  const endItems = useSharedState<NavItem[]>([]);

  return (
    <NavBarContext.Provider
      value={{
        hasNavBar: true,
        setCanGoBack: (v) => canGoBack.set(v),
        setEndItems: (v) => endItems.set(v),
        setStartItems: (items, shows) => {
          startItems.set(items);
          showsBackwardButtonEvenStartPresents.set(shows);
        },
        setMiddleItem: (v) => middleItem.set(v),
      }}
    >
      {props.children}
      <NavBar
        canDismiss={props.canDismiss}
        canGoBack={canGoBack}
        onBackward={props.onBackward}
        endItems={endItems}
        startItems={startItems}
        middleItem={middleItem}
        showsBackwardButtonEvenStartPresents={
          showsBackwardButtonEvenStartPresents
        }
        ref={barRef}
      />
    </NavBarContext.Provider>
  );
}

export function NavBarNoOpBoundary(props: PropsWithChildren<{}>) {
  return (
    <NavBarContext.Provider
      value={{
        hasNavBar: false,
        setCanGoBack: (f) => {},
        setEndItems: (items) => {},
        setStartItems: (items, shows) => {},
        setMiddleItem: (item) => {},
      }}
    >
      {props.children}
    </NavBarContext.Provider>
  );
}
