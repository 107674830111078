import {
  useParams as useReactRouterParams,
  useSearchParams,
} from "react-router-dom";
import { assert } from "../utils/asserts";
import BigNumber from "bignumber.js";
import { useInjectParams } from "./InjectParams";

function useParams() {
  const router = useReactRouterParams();
  const injected = useInjectParams();

  return injected === undefined ? router : injected;
}

export function useOptionalBigIntParam(key: string) {
  const params = useParams();
  const str = params[key];
  if (str === undefined) return undefined;
  return BigInt(str);
}

export function useBigIntParam(key: string) {
  const params = useParams();
  const str = params[key];
  assert(str !== undefined);
  return BigInt(str);
}

export function useNumberParam(key: string) {
  const params = useParams();
  const str = params[key];
  assert(str !== undefined);
  return Number(str);
}

export function useStringParam(key: string) {
  const params = useParams();
  const str = params[key];
  assert(str !== undefined);
  return str;
}

export function useBooleanParam(key: string) {
  const params = useParams();
  const str = params[key];
  assert(str !== undefined);
  return str.toLowerCase() !== "false" && str !== "0";
}

export function useNumberSearchParam(key: string): number | undefined;
export function useNumberSearchParam(key: string, defaultValue: number): number;
export function useNumberSearchParam(key: string, defaultValue?: number) {
  const [params] = useSearchParams();
  const param = params.get(key);
  if (param === null) return defaultValue;
  return Number(param);
}

export function useEnumSearchParam<T extends number>(
  key: string,
): T | undefined;
export function useEnumSearchParam<T extends number>(
  key: string,
  defaultValue: T,
): T;
export function useEnumSearchParam<T extends number>(
  key: string,
  defaultValue?: T,
) {
  const [params] = useSearchParams();
  const param = params.get(key);
  if (param === null) return defaultValue;
  return Number(param);
}

export function useBooleanSearchParam(key: string): boolean | undefined;
export function useBooleanSearchParam(
  key: string,
  defaultValue: boolean,
): boolean;
export function useBooleanSearchParam(key: string, defaultValue?: boolean) {
  const [params] = useSearchParams();
  const param = params.get(key);
  if (param === null) return defaultValue;
  return param.toLowerCase() !== "false" && param !== "0";
}

export function useStringSearchParam(key: string): string | undefined;
export function useStringSearchParam(key: string, defaultValue: string): string;
export function useStringSearchParam(key: string, defaultValue?: string) {
  const [params] = useSearchParams();
  const param = params.get(key);
  if (param === null) return defaultValue;
  return param;
}

export function useBigNumberSearchParam(key: string): BigNumber | undefined;
export function useBigNumberSearchParam(
  key: string,
  defaultValue: BigNumber,
): BigNumber;
export function useBigNumberSearchParam(key: string, defaultValue?: BigNumber) {
  const [params] = useSearchParams();
  const param = params.get(key);
  if (param === null) return defaultValue;
  return new BigNumber(param);
}

export function useBigIntSearchParam(key: string): bigint | undefined;
export function useBigIntSearchParam(key: string, defaultValue: bigint): bigint;
export function useBigIntSearchParam(key: string, defaultValue?: bigint) {
  const [params] = useSearchParams();
  const param = params.get(key);
  if (param === null) return defaultValue;
  return BigInt(param);
}
