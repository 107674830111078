import { StoreOrderItem } from "../../../proto/Store";
import { HStack, Spring, VStack } from "../../../components/VStack";
import { Image } from "../../../components/Image";
import {
  formatMoney,
  getCurrencyAmountFromString,
  getCurrencyIcon,
  getCurrencyIconByCurrencyType,
} from "../../../proto/Currency";
import {
  AssetOrder,
  AssetOrderStatus,
  PurchaseUserPropsPackageInfo,
} from "../../../proto/AssetOrder";
import styled from "styled-components";
import { OverFlowTextStyle } from "../../../components/Text";
import { CellDivider, ContentGroup } from "../../../components/ContentGroup";
import { OrderInfoContent, OrderInfoLabel } from "./GiftOrderDetail";
import { useI18n } from "../../../hooks/useI18n";
import { HighLightLabel } from "./PurchaseOrderDetail";
import { StatusBar } from "./GiftOrderListPage";
import { formatDateWithOptions } from "../../../utils/DateTimeUtils";
import iconPass from "../../../res/images/ic_pass.png";

export function PropsPurchaseOrderDetail(props: { assetOrder: AssetOrder }) {
  const i18n = useI18n();

  function getStatus() {
    if (props.assetOrder.orderStatus === AssetOrderStatus.Done) {
      return { text: i18n.web3_v0_completed(), color: "#00FF94" };
    } else if (props.assetOrder.orderStatus === AssetOrderStatus.Pending) {
      return { text: i18n.web3_v0_pending(), color: "#FF7335" };
    } else if (props.assetOrder.orderStatus === AssetOrderStatus.Failed) {
      return { text: i18n.circle_anmt_failed(), color: "#EF3537FF" };
    } else if (props.assetOrder.orderStatus === AssetOrderStatus.Rejected) {
      return { text: i18n.circle_anmt_rejected(), color: "#EF3537FF" };
    } else return { text: "", color: "#FFFFFF66" };
  }

  return (
    <VStack style={{ width: "100%", gap: 20 }}>
      <OrderPropsContainer
        purchaseQuotaPackageInfo={props.assetOrder.purchaseQuotaPackageInfo}
      />
      <ContentGroup style={{ width: "100%", gap: 20 }}>
        <HStack>
          <OrderInfoLabel>{i18n.web3_mint_coupon_total_price()}</OrderInfoLabel>
          <Spring />
          <HStack style={{ gap: 4 }}>
            <Image
              src={getCurrencyIcon(
                props.assetOrder.purchaseQuotaPackageInfo?.totalPrice,
              )}
              style={{ width: 18, height: 18 }}
            />
            <OrderInfoContent>
              {formatMoney(
                "long",
                props.assetOrder.purchaseQuotaPackageInfo?.totalPrice,
              )}
            </OrderInfoContent>
          </HStack>
        </HStack>
        <CellDivider />
        <HStack>
          <HighLightLabel>{i18n.web3_mint_coupon_total_paid()}</HighLightLabel>
          <Spring />
          <HStack style={{ gap: 4 }}>
            <Image
              src={getCurrencyIcon(
                props.assetOrder.purchaseQuotaPackageInfo?.totalPay,
              )}
              style={{ width: 18, height: 18 }}
            />
            <OrderInfoContent>
              {formatMoney(
                "long",
                props.assetOrder.purchaseQuotaPackageInfo?.totalPay,
              )}
            </OrderInfoContent>
          </HStack>
        </HStack>
      </ContentGroup>

      <ContentGroup style={{ width: "100%", gap: 20 }}>
        <HStack>
          <OrderInfoLabel>{i18n.status()}</OrderInfoLabel>
          <Spring />
          <StatusBar
            statusColor={getStatus().color}
            statusText={getStatus().text}
          />
        </HStack>
        <CellDivider />

        <HStack>
          <OrderInfoLabel>
            {i18n.fast_match_priority_match_pass()}
          </OrderInfoLabel>
          <Spring />
          <HStack style={{ gap: 4 }}>
            <Image
              src={iconPass}
              style={{ width: 20, height: 20, objectFit: "contain" }}
            />
            <OrderInfoContent>
              ×
              {(props.assetOrder.purchaseQuotaPackageInfo?.count || 0) *
                (props.assetOrder.purchaseQuotaPackageInfo?.packageInfo
                  ?.units || 0)}
            </OrderInfoContent>
          </HStack>
        </HStack>
        <CellDivider />

        <HStack>
          <OrderInfoLabel>{i18n.web3_v0_created_time()}</OrderInfoLabel>
          <Spring />
          <OrderInfoContent>
            {formatDateWithOptions(props.assetOrder.createdTime || 0, {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })}
          </OrderInfoContent>
        </HStack>
        <CellDivider />
        <HStack>
          <OrderInfoLabel>{i18n.web3_v0_transaction_order_id()}</OrderInfoLabel>
          <Spring />
          <OrderInfoContent>
            {props.assetOrder.orderId.toString()}
          </OrderInfoContent>
        </HStack>
      </ContentGroup>
    </VStack>
  );
}
function OrderPropsContainer(props: {
  purchaseQuotaPackageInfo?: PurchaseUserPropsPackageInfo;
}) {
  return (
    <HStack
      style={{
        width: "100%",
        padding: 8,
        backgroundColor: "#FFFFFF0F",
        borderRadius: 6,
        gap: 12,
      }}
    >
      <Image
        src={[
          props.purchaseQuotaPackageInfo?.packageInfo?.icon,
          { width: 74, height: 74 },
        ]}
        width={74}
        height={74}
        style={{ borderRadius: 4 }}
      />
      <VStack style={{ flex: 1, gap: 8 }}>
        <PropsName>
          {props.purchaseQuotaPackageInfo?.packageInfo?.title}
        </PropsName>
        <PropsAmount>×{props.purchaseQuotaPackageInfo?.count}</PropsAmount>
        <HStack style={{ width: "100%" }}>
          <Image
            src={getCurrencyIcon(
              props.purchaseQuotaPackageInfo?.packageInfo?.price,
            )}
            style={{ width: 18, height: 18 }}
          />
          <CurrencyAmountText>
            {formatMoney(
              "long",
              props.purchaseQuotaPackageInfo?.packageInfo?.price,
            )}
          </CurrencyAmountText>
        </HStack>
      </VStack>
    </HStack>
  );
}

export const PropsName = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #ffffff66;
  width: 100%;
  ${OverFlowTextStyle};
  line-clamp: 2;
  -webkit-line-clamp: 2;
`;

const PropsAmount = styled.div`
  font-size: 11px;
  font-weight: 400;
  color: #ffffff99;
`;

const CurrencyAmountText = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: white;
`;
