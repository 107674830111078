import styled from "styled-components";
import React, {
  forwardRef,
  PropsWithChildren,
  ReactElement,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Subpage, SubpageProps, usePageId } from "./Subpage";
import { useSavedScroll } from "../hooks/useSavedScroll";
import { Page } from "./Page";
import { useParentViewPager } from "./ViewPagerContext";
import { VStackMixin } from "./VStack";
import {
  useBorderBoxSize,
  useResizeObserver,
} from "../hooks/useResizeObserver";
import TabLayout from "./TabLayout";
import { PropsFrom } from "../utils/typeUtils";
import { StateId } from "../hooks/StateId";
import { usePageSpec } from "../pages/common/usePageSpec";
import { NavBarNoOpBoundary } from "./NavBar";

const Slider = styled.div`
  // as child
  width: 100%;
  height: 100%;
  flex-grow: 1;

  // as parent
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  flex-direction: row;
  align-items: stretch;

  // hide the scroll bar
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
`;

const HeaderFrame = styled.div`
  ${VStackMixin}
`;

const kHeaderId = "view-pager-header";

export const ViewPagerTabLayout = forwardRef(function (
  props: { ignoresStickyHeight?: boolean; equalDistribution?: boolean },
  outerRef: React.ForwardedRef<HTMLDivElement>,
) {
  const viewPager = useParentViewPager();
  const [tabLayoutRef, tabLayoutSize] = useResizeObserver();

  const stickyHeight = useMemo(() => {
    if (!tabLayoutSize) return 0;
    const header = tabLayoutSize.target.closest(`#${kHeaderId}`);
    if (!header) return 0;

    const tabLayoutRect = tabLayoutSize.target.getBoundingClientRect();
    const headerRect = header.getBoundingClientRect();

    return headerRect.y + headerRect.height - tabLayoutRect.y;
  }, [tabLayoutSize]);

  useLayoutEffect(() => {
    if (!props.ignoresStickyHeight) {
      viewPager.setHeaderStickyHeight(stickyHeight);
    }
  }, [stickyHeight]);

  return (
    <TabLayout
      ref={!props.ignoresStickyHeight ? tabLayoutRef : outerRef}
      chosenTabIndex={viewPager.subpageItems.findIndex(
        (p) => p.id === viewPager.primarySubpageId,
      )}
      tabsCount={viewPager.subpageItems.length}
      setChosenTabIndex={(index) => {
        viewPager.setPrimarySubpageWithId(
          viewPager.subpageItems[index].id,
          "smooth",
        );
      }}
      getTitle={(i) => viewPager.subpageItems[i].title ?? "-"}
      equalDistribution={props.equalDistribution}
    />
  );
});

export function ViewPagerPage(
  props: PropsWithChildren<{
    collapsingHeaderDisabled?: boolean;
    pageData?: PropsFrom<typeof Page>["pageData"];
    contentBackground?: ReactElement;
    subpages: ReadonlyArray<ReactElement<SubpageProps, typeof Subpage>>;
    initialPageId?: StateId;
  }>,
) {
  const { absolutePageId } = usePageId();
  const saveScroll = useSavedScroll(
    ["viewPagerSlider", absolutePageId],
    true,
    (slider) => {
      onScrollSizeChange(slider);
    },
  );
  const [currentPageId, setCurrentPageId] = useState<StateId>();

  const sliderRef = useRef<HTMLDivElement | null>(null);
  const hasScrollToInitialPageId = useRef(false);

  const [headerStickyHeight, setHeaderStickyHeight] = useState(0);

  const entries = useMemo(() => {
    return props.subpages.map((s) => {
      return { id: s.props.id, title: s.props.title };
    });
  }, [props.subpages]);

  function updateScrollLeftToPageId(
    pageId: StateId,
    behavior: ScrollBehavior | undefined,
  ) {
    if (!sliderRef.current) return false;

    const index = props.subpages.findIndex((s) => s.props.id === pageId);
    if (index === -1) return;

    sliderRef.current.scrollTo({
      left: index * sliderRef.current.clientWidth,
      behavior: behavior,
    });

    return true;
  }

  function onScrollSizeChange(scroller: Element) {
    if (scroller.clientWidth) {
      const index = Math.round(scroller.scrollLeft / scroller.clientWidth);
      if (index < entries.length) {
        setCurrentPageId(entries[index].id);
      }
    }

    setUpInitIfNotYet(scroller);
  }

  function setUpInitIfNotYet(scroller: Element) {
    if (scroller.clientWidth === 0) return;

    if (!hasScrollToInitialPageId.current) {
      if (props.initialPageId !== undefined) {
        setCurrentPageId(props.initialPageId);
        if (updateScrollLeftToPageId(props.initialPageId, undefined)) {
          hasScrollToInitialPageId.current = true;
        }
      } else {
        hasScrollToInitialPageId.current = true;
      }
    }
  }

  const [headerRef, headerSize] = useBorderBoxSize();

  const pageSpec = usePageSpec();
  const collapsingHeaderDisabled =
    props.collapsingHeaderDisabled === undefined
      ? pageSpec === "wide"
      : props.collapsingHeaderDisabled;

  return (
    <Page
      pageData={props.pageData}
      underlay={props.contentBackground}
      scrollPaddingDisabled={true}
      safeBottomDisabled={true}
      composition={{
        primarySubpageId: currentPageId,
        setPrimarySubpageWithId: (pageId, behavior) => {
          updateScrollLeftToPageId(pageId, behavior);
        },
        subpageItems: entries,
        subpagePropsOverrides: {
          safeTopDisabled: true,
          scrollDisabled: false,
          fullPageStateDisabled: true,
        },
        customComposition: { setHeaderStickyHeight: setHeaderStickyHeight },
      }}
    >
      <HeaderFrame ref={headerRef} id={kHeaderId}>
        {props.children}
      </HeaderFrame>
      <Slider
        style={{
          height: `calc(100% - ${collapsingHeaderDisabled ? headerSize?.height ?? 0 : headerStickyHeight}px)`,
        }}
        ref={(r) => {
          saveScroll.scrollRef(r);
          sliderRef.current = r;

          if (r) {
            setUpInitIfNotYet(r);
          }
        }}
        onScroll={(e) => {
          const target = e.currentTarget;
          onScrollSizeChange(target);
          saveScroll.onScroll(e);
        }}
      >
        <NavBarNoOpBoundary>{props.subpages}</NavBarNoOpBoundary>
      </Slider>
    </Page>
  );
}
