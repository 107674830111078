import { HStack, VSpace, VStack } from "../../../components/VStack";
import CircleCardsPreviewCell, { CardStyle } from "./CircleCardsPreviewCell";
import React from "react";
import { NFT } from "../../../proto/NFT";
import { Circle } from "../../../proto/Circle";
import styled from "styled-components";
import { useI18n } from "../../../hooks/useI18n";
import { formatPercentage } from "../../../utils/NumberI18n";

export interface CardTransactionPreviewProps {
  card?: NFT;
  circle?: Circle;
}

export function CardTransactionPreview(props: CardTransactionPreviewProps) {
  const i18n = useI18n();
  return (
    <HStack
      style={{
        gap: 10,
        alignItems: "start",
      }}
    >
      {props.card && (
        <CardContainer>
          <VStack>
            <VSpace height={17} />
            <CircleCardsPreviewCell
              card={props.card.circleMembershipCard}
              cardStyle={CardStyle.medium}
            />
          </VStack>
        </CardContainer>
      )}

      <VStack style={{ flex: 1, gap: 10, alignItems: "start" }}>
        <CardName>{props.card?.name ?? props?.circle?.name}</CardName>
        <HStack style={{ gap: 4 }}>
          <ProgressLabel>
            {i18n.clover_current_maturity_progress()}
          </ProgressLabel>
          <ProgressValue>
            {formatPercentage(
              Number(props.card?.circleMembershipCard?.maturityProgress) / 100,
              3,
            )}
          </ProgressValue>
        </HStack>
      </VStack>
    </HStack>
  );
}

const CardContainer = styled.div`
  width: 80px;
  padding: 4px;
  height: 80px;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.2);
`;

const CardName = styled.div`
  color: var(--color-text00);
  font-weight: 700;
  font-size: 14px;
`;

const ProgressLabel = styled.div`
  color: var(--color-text10);
  text-align: center;
  font-weight: 400;
  font-size: 11px;
`;

const ProgressValue = styled.div`
  color: var(--color-text00);
  text-align: center;
  font-weight: 400;
  font-size: 11px;
`;
