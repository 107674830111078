import { z } from "zod";
import { zBigInt, zStatic } from "../utils/zodUtils";
import { isImage, isVideo, Media } from "./Media";
import { User } from "./User";
import { Comment } from "./Comment";
import { Reaction } from "./Reaction";
import { Poll } from "./Poll";
import { RichFormat } from "../components/zdoc/RichFormat";
import { Chat } from "./Chat";

export const BareCircle = z.object({
  circleId: zBigInt,
  icon: Media.optional(),
  name: z.string().optional().default(""),
  themeColor: z.string().optional(),
  tagline: z.string().optional(),
  circleIcon: Media.optional(),
});

export type BareCircle = zStatic<typeof BareCircle>;

export const Post = z.object({
  blogId: zBigInt,
  createdTime: z.number(),
  editedTime: z.number().optional(),
  author: User.optional(),
  title: z.string().optional(),
  content: z.string().optional(),
  richFormat: RichFormat.optional(),
  mediaList: z.array(Media).optional(),
  cover: Media.optional(),
  appearedCommentList: z.array(Comment).optional(),
  reactionCountList: z.array(Reaction).optional(),
  commentsCount: z.number().default(0),
  pollList: z.array(Poll).optional(),
  circleList: z.array(BareCircle).optional(),
  background: Media.optional(),
  sharedThread: Chat.optional(),
  type: z.number().optional(),
  blogStatus: z.number().optional(),
});

export type Post = zStatic<typeof Post>;

export const PostAndAnnouncement = z.object({
  blog: Post.optional(),
  circleAnnouncement: Post.optional(),
});

export type PostAndAnnouncement = zStatic<typeof PostAndAnnouncement>;

export function getPostDisplayCover(post?: Post) {
  if (post?.cover) {
    return post.cover;
  }
  const firstImageOrVideo = post?.mediaList?.find(
    (media) => isImage(media) || isVideo(media),
  );
  if (firstImageOrVideo) {
    return firstImageOrVideo;
  }
}

export enum PostType {
  TEXT = 1,
  IMAGE = 2,
  VIDEO = 3,
  AUDIO = 4,
}
