export enum CircleTokenStatus {
  //部署中
  Pending = 1,
  //部署成功 Incubation
  Running = 2,
  //上所中 也算Incubation
  Finished = 3,
  //上所成功 Launched
  DexPooled = 4,
}
