import { NavEnd, NavItem, NavMiddle } from "../../components/NavBar";
import { useI18n } from "../../hooks/useI18n";
import { useBigIntParam, useEnumSearchParam } from "../../hooks/useTypedParam";
import iconSend from "../../res/images/ic_account_send.svg";
import iconReceive from "../../res/images/ic_account_receive.svg";
import iconSwap from "../../res/images/ic_wallet_swap.svg";
import iconTopUp from "../../res/images/ic_wallet_top_up.svg";
import GridLayout from "../../components/GridLayout";
import React, { useMemo } from "react";
import AccountHeaderOperation from "./AccountHeaderOperation";
import { useHopper } from "../../hooks/useHopper";
import { subpage } from "../../components/Subpage";
import {
  ViewPagerPage,
  ViewPagerTabLayout,
} from "../../components/ViewPagerPage";
import AccountTokensPage from "./account/AccountTokensPage";
import AccountCollectiblesPage from "./account/AccountCollectiblesPage";
import AccountPropsPage from "./account/AccountPropsPage";
import { NavButtonType } from "../../bridge/NavButtonDesc";
import { useModal } from "../../components/Modal";
import { BSMenu, BSMenuItem } from "../../components/BSMenu";
import { AccountType } from "../../proto/AccountType";
import {
  isInExternalAppShell,
  isLiteApp,
} from "../../bridge/ExternalAppShellData";
import { detect } from "detect-browser";
import { useNativePage } from "../../hooks/useBridge";
import { dicClickTopUp } from "./dicClickTopUp";
import { TopUpMethodMenu } from "./topup/TopUpMethodMenu";
import { useSWR } from "../../hooks/swr/useSWR";
import { useBackend } from "../../service/APIService";
import { MorePaymentModal } from "./topup/MorePaymentModal";
import { useBroadcast } from "../../hooks/useBroadcast";
import { andLog } from "../../components/handleError";
import { useWalletSWR } from "./useWallet";
import { BoolNumber } from "../../proto/Wallet";
import { useOnMorePaymentBroadcast } from "../../hooks/useOnMorePaymentBroadcast";

export enum AccountSubpages {
  TOKENS,
  COLLECTIBLES,
  PROPS,
}

function WalletAccountPage() {
  const i18n = useI18n();
  const walletAccountId = useBigIntParam("walletAccountId");
  const moreMenu = useModal("account-home-menu");
  const topupMenu = useModal("top-up");
  const [wallet] = useWalletSWR();
  const initPage = useEnumSearchParam("landingIndex") ?? 0;
  const backend = useBackend();
  const hopper = useHopper();
  const nativePage = useNativePage();
  const methods = useSWR(backend.getSupportedThirdPartyPaymentMethods());
  const supportThirdParty = useMemo(() => {
    return (
      (!isLiteApp() || wallet?.thirdPartyIapStatus === BoolNumber.On) &&
      (methods.content?.list?.length ?? 0) > 0
    );
  }, [wallet, methods.content]);

  const sendToken = () => {
    hopper.layer(`wallet/${walletAccountId}/select-token`, {
      purpose: "SendToken",
    });
  };

  const swap = () => {
    hopper.layer(`wallet/${walletAccountId}/select-token`, {
      purpose: "SwapToken",
    });
  };

  const items = [
    <AccountHeaderOperation
      icon={iconSend}
      title={i18n.send()}
      onClick={sendToken}
    />,
    <AccountHeaderOperation
      icon={iconReceive}
      title={i18n.clover_receive()}
      onClick={() => {
        hopper.push(`wallet/receive`);
      }}
    />,
    <AccountHeaderOperation
      icon={iconSwap}
      title={i18n.clover_swap()}
      onClick={swap}
    />,
    <AccountHeaderOperation
      icon={iconTopUp}
      title={i18n.web3_hongbao_top_up()}
      onClick={() => {
        dicClickTopUp(hopper, supportThirdParty ? topupMenu : undefined);
      }}
    />,
  ];

  const displayItems = isLiteApp()
    ? items.filter((_, index) => index !== 2)
    : items;

  useOnMorePaymentBroadcast(() => {
    topupMenu.open();
  }, [topupMenu]);
  return (
    <ViewPagerPage
      initialPageId={initPage}
      subpages={[
        subpage(AccountSubpages.TOKENS, <AccountTokensPage />, "Tokens"),
        subpage(
          AccountSubpages.COLLECTIBLES,
          <AccountCollectiblesPage />,
          i18n.web3_v0_collectibles(),
        ),
        subpage(AccountSubpages.PROPS, <AccountPropsPage />, "Props"),
      ]}
    >
      <NavMiddle>{i18n.arthur_wallet_built_in_account()}</NavMiddle>
      <NavEnd>
        {NavItem.image(NavButtonType.More, () => {
          moreMenu.open();
        })}
      </NavEnd>
      <GridLayout
        style={{
          paddingTop: 12,
          marginBottom: 20,
          paddingInlineStart: 20,
          paddingInlineEnd: 20,
        }}
        spanCount={displayItems.length}
        items={displayItems}
      />
      <ViewPagerTabLayout />
      <BSMenu modal={moreMenu}>
        <BSMenuItem
          title={i18n.web3_hongbao_top_up()}
          onClick={() => {
            dicClickTopUp(hopper, supportThirdParty ? topupMenu : undefined);
          }}
        />
        <BSMenuItem title={i18n.clover_send_tokens()} onClick={sendToken} />
        <BSMenuItem
          title={i18n.clover_receive()}
          onClick={() => {
            hopper.push(`wallet/receive`);
          }}
        />
        {!isLiteApp() && (
          <BSMenuItem title={i18n.clover_swap()} onClick={swap} />
        )}
        <BSMenuItem
          title={i18n.tx_impr_create_merch()}
          onClick={() => {
            hopper.push(`nft/create`);
          }}
        />
        {/*<BSMenuItem title={i18n.web3_mint_coupon_mint_nft()} />*/}
        <BSMenuItem
          title={i18n.web3_v0_transaction_history()}
          onClick={() => {
            hopper.push(`wallet/${walletAccountId}/transaction-history`, {
              accountType: AccountType.BuiltIn,
            });
          }}
        />
        {isInExternalAppShell() && detect()?.os === "iOS" && (
          <BSMenuItem
            title={i18n.march_impr_2023_restore_iap()}
            onClick={() => {
              nativePage.restorePurchase();
            }}
          />
        )}
      </BSMenu>
      {supportThirdParty && <TopUpMethodMenu modal={topupMenu} />}
    </ViewPagerPage>
  );
}

export default WalletAccountPage;
