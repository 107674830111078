import { SimpleListDemoPage } from "./SimpleListDemoPage";
import { subpage } from "../../components/Subpage";
import React from "react";
import { SimpleDataDemoPage } from "./SimpleDataDemoPage";
import {
  ViewPagerPage,
  ViewPagerTabLayout,
} from "../../components/ViewPagerPage";
import styled from "styled-components";
import { PageFooter } from "../../components/PageHeaderFooter";
import { RegularButton } from "../../components/Buttons";
import { RowCenterButton } from "../../components/CommonStyles";

const Header = styled.div`
  background: #00ff948a;
  width: 100%;
  height: 300px;
`;

export function ViewPager2DemoPage() {
  return (
    <ViewPagerPage
      initialPageId={"hij"}
      subpages={[
        subpage("abc", <SimpleListDemoPage />, "ABC"),
        subpage("efg", <SimpleDataDemoPage />, "EFG"),
        subpage("hij", <SimpleListDemoPage />, "HIJ"),
      ]}
    >
      <Header />
      <ViewPagerTabLayout />

      <PageFooter obscuredZoneKey={"DemoPageFoot"}>
        <RegularButton
          $baseBgColor={"var(--color-bg)"}
          style={{
            ...RowCenterButton,
            marginTop: 8,
          }}
        >
          Bottom Button
        </RegularButton>
      </PageFooter>
    </ViewPagerPage>
  );
}
