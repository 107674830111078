import { Page } from "../../components/Page";
import BottomNavigation from "../../components/BottomNavigation";
import React, { ReactElement } from "react";
import HomeMyCirclesPage from "../circle/HomeMyCirclesPage";
import HomeMarketPage from "../market/HomeMarketPage";
import { usePageSpec } from "../common/usePageSpec";
import { HomeDiscoverPage } from "./HomeDiscoverPage";
import { useNativePage } from "../../hooks/useBridge";
import { useHopper } from "../../hooks/useHopper";
import { useModal } from "../../components/Modal";
import { useHopState } from "../../hooks/useHopState";
import {
  useAuthSessionService,
  useMyUid,
} from "../../service/AuthSessionService";
import {
  NavBarNoOpBoundary,
  NavEnd,
  NavStart,
  NavTitle,
} from "../../components/NavBar";
import { useSWR } from "../../hooks/swr/useSWR";
import { useI18n } from "../../hooks/useI18n";
import { useBackend } from "../../service/APIService";
import { subpage } from "../../components/Subpage";
import { PageFooter } from "../../components/PageHeaderFooter";
import { useGlobalSpinner } from "../../utils/globalSpinner";
import WalletActionBar from "./WalletActionBar";
import { useWalletSWR } from "../wallet/useWallet";
import { User } from "../../proto/User";
import { UserAvatarView } from "../../components/views/UserAvatarView";
import { CreateMenu } from "./CreateMenu";
import HomeMyChatsPage from "./HomeMyChatsPage";

function HomeSubpage(props: {
  id: string;
  user: User | undefined;
  title: string;
  children: ReactElement;
}) {
  const hopper = useHopper();
  const pageSpec = usePageSpec();
  return (
    <>
      {pageSpec === "wide" ? (
        subpage(props.id, props.children)
      ) : (
        <>
          <NavBarNoOpBoundary>
            {subpage(props.id, props.children)}
          </NavBarNoOpBoundary>
          <NavStart>
            <UserAvatarView
              user={props.user}
              iconSize={36}
              onClick={() => hopper.startSide("user-center")}
            />
            <NavTitle>{props.title}</NavTitle>
          </NavStart>
        </>
      )}
    </>
  );
}

function HomeTabPage() {
  const nativePage = useNativePage();
  const hopper = useHopper();
  const backend = useBackend();
  const [chosenTab, setChosenTab] = useHopState<number>("chosenTab", 0);
  const pageSpec = usePageSpec();
  const globalSpinner = useGlobalSpinner();
  const powerMenu = useModal("powerMenu");
  const [wallet, walletSWR] = useWalletSWR();

  const myUid = useMyUid();
  const authSessionService = useAuthSessionService();
  const i18n = useI18n();
  const myUserSWR = useSWR(backend.getUser(myUid));
  const createMenu = useModal("create-menu");

  return (
    <Page
      pageData={undefined}
      scrollDisabled={true}
      safeTopDisabled={true}
      safeBottomDisabled={true}
    >
      {chosenTab === 0 && (
        <HomeSubpage
          id={"discover"}
          user={myUserSWR.content}
          title={i18n.discover()}
        >
          <HomeDiscoverPage />
        </HomeSubpage>
      )}
      {chosenTab === 1 && (
        <HomeSubpage
          id={"circle"}
          user={myUserSWR.content}
          title={i18n.my_circles()}
        >
          <HomeMyCirclesPage />
        </HomeSubpage>
      )}
      {chosenTab === 3 && (
        <HomeSubpage
          id={"my-chats"}
          user={myUserSWR.content}
          title={i18n.chat_my_chat_list()}
        >
          <HomeMyChatsPage />
        </HomeSubpage>
      )}
      {chosenTab === 4 && (
        <HomeSubpage
          id={"market"}
          user={myUserSWR.content}
          title={i18n.web3_v0_nft_mall()}
        >
          <HomeMarketPage />
        </HomeSubpage>
      )}

      <PageFooter obscuredZoneKey={"HomeTab"} safeBottomDisabled={true}>
        {pageSpec !== "wide" && (
          <BottomNavigation
            onTabChange={(index) => {
              if (index !== 2) {
                setChosenTab(index);
              } else {
                createMenu.open();
              }
            }}
          />
        )}
      </PageFooter>

      {pageSpec !== "wide" && (
        <NavEnd>
          <WalletActionBar
            key={"wallet"}
            wallet={walletSWR.content}
            onClick={() => hopper.push(`wallet`)}
          />
        </NavEnd>
      )}
      <CreateMenu modal={createMenu} />
    </Page>
  );
}

export enum BottomTabs {}

export default HomeTabPage;
