import { Page } from "../../components/Page";
import { DemoCell } from "./DemoCell";
import { useHopper } from "../../hooks/useHopper";
import {
  useLocalRepoMap,
  useMemoryRepoMap,
} from "../../hooks/swr/useLocalRepo";
import { zBigInt } from "../../utils/zodUtils";
import { z } from "zod";
import { useHopId } from "../../hooks/useHopState";
import { NavMiddle } from "../../components/NavBar";

const uids = [
  BigInt(Number.MAX_SAFE_INTEGER) + BigInt(101),
  BigInt(Number.MAX_SAFE_INTEGER) + BigInt(102),
  BigInt(Number.MAX_SAFE_INTEGER) + BigInt(103),
  BigInt(Number.MAX_SAFE_INTEGER) + BigInt(104),
  BigInt(Number.MAX_SAFE_INTEGER) + BigInt(105),
];

export function MemoryRepoMapDemoPage() {
  const hopper = useHopper();
  const hopId = useHopId();

  const followeds = useMemoryRepoMap(
    zBigInt,
    z.boolean(),
    "memory repo followeds",
  );

  return (
    <Page pageData={undefined}>
      <NavMiddle>{hopId}</NavMiddle>
      {uids.map((uid) => (
        <DemoCell
          key={uid}
          onClick={() => followeds.setValue(uid, (prev) => !prev)}
        >{`${uid}: ${followeds.map.get(uid)}`}</DemoCell>
      ))}
      <DemoCell
        key={"next"}
        onClick={() => hopper.push("memory-repo-map-demo")}
      >
        Next
      </DemoCell>
      <DemoCell key={"clear"} onClick={() => followeds.clear()}>
        Clear
      </DemoCell>
    </Page>
  );
}

export function LocalRepoMapDemoPage() {
  const hopper = useHopper();
  const hopId = useHopId();

  const followeds = useLocalRepoMap(
    zBigInt,
    z.boolean(),
    "local repo followeds",
  );

  return (
    <Page pageData={undefined}>
      <NavMiddle>{hopId}</NavMiddle>
      {uids.map((uid) => (
        <DemoCell
          key={uid}
          onClick={() => followeds.setValue(uid, (prev) => !prev)}
        >{`${uid}: ${followeds.map.get(uid)}`}</DemoCell>
      ))}
      <DemoCell key={"next"} onClick={() => hopper.push("local-repo-map-demo")}>
        Next
      </DemoCell>
      <DemoCell key={"clear"} onClick={() => followeds.clear()}>
        Clear
      </DemoCell>
    </Page>
  );
}
