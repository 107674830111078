import { useEnumSearchParam } from "../../../hooks/useTypedParam";
import { useWebHost } from "../../../hooks/useBridge";
import { useSWR } from "../../../hooks/swr/useSWR";
import { WalletBannerType } from "../WalletBannerCell";
import { andLog } from "../../../components/handleError";
import { useHopState } from "../../../hooks/useHopState";
import { useEffect } from "react";
import { useBackend } from "../../../service/APIService";

export function useMarkOrderAsRead() {
  const backend = useBackend();
  const markAsReadType = useEnumSearchParam<WalletBannerType>("markAsReadType");
  const webHost = useWebHost();
  const walletSWR = useSWR(backend.getWallet());
  const markAsReadSilently = async (type: WalletBannerType) => {
    try {
      await backend
        .markOrderAsRead({
          redPointType: type,
        })
        .run();
      await walletSWR.load();
      webHost.broadcast("ON_GIFT_READ", {}).catch(andLog);
    } catch (error) {
      console.log(error);
    }
  };

  const [isMarkAsReadDone, setMarkAsReadDone] = useHopState<boolean>(
    "isMarkAsReadDone",
    false,
  );
  useEffect(() => {
    if (markAsReadType) {
      if (!isMarkAsReadDone) {
        setMarkAsReadDone(true);
        markAsReadSilently(markAsReadType).catch(andLog);
      }
    }
  }, [isMarkAsReadDone, markAsReadType]);
}
