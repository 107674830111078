import React, { useEffect, useRef } from "react";
import { Comment } from "../../proto/Comment";
import styled, { keyframes } from "styled-components";
import { UserAvatarView } from "../../components/views/UserAvatarView";
import { I18n } from "../../hooks/useI18n";

interface ListScrollerProps {
  items: Comment[];
  i18n: I18n;
}

const ListScroller: React.FC<ListScrollerProps> = ({ items, i18n }) => {
  const listRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const list = listRef.current;
    if (list && items.length > 4) {
      const scrollInterval = setInterval(() => {
        if (list.scrollTop < list.scrollHeight / 2) {
          list.scrollTop += 1; // 每次移动1px
        } else {
          list.scrollTop = 0; // 滚动到底部时重置
        }
      }, 50);

      return () => clearInterval(scrollInterval);
    }
  }, [items]);
  const isScrollable = items.length > 4;
  const isVideo = (baseUrl?: string): boolean => {
    return baseUrl ? baseUrl.split(".").pop()?.toLowerCase() === "mp4" : false;
  };

  const wallPreview = (comment: Comment) => {
    if (!!comment.content) {
      return comment.content;
    } else {
      const media = comment.mediaList?.[0];
      if (!media) {
        return null;
      } else {
        if (isVideo(media.baseUrl)) {
          return i18n.chat_list_video();
        } else {
          return i18n.chat_list_image();
        }
      }
    }
  };
  return (
    <ScrollContainer ref={listRef}>
      <ListWrapper>
        {(isScrollable ? items.concat(items) : items).map((item, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "0 16px",
              alignItems: "center",
              width: "100%",
            }}
          >
            <UserAvatarView user={item.author} iconSize={32} />
            <MessageFrame>{wallPreview(item)}</MessageFrame>
          </div>
        ))}
      </ListWrapper>
    </ScrollContainer>
  );
};

export default ListScroller;

const MessageFrame = styled.div`
  border-radius: 0px 100px 100px 0px;
  background: rgba(255, 255, 255, 0.08);
  overflow: hidden;
  line-clamp: 1;
  text-overflow: ellipsis;
  word-break: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-left: -14px;
  height: 30px;
  align-items: center;
  align-content: center;
  padding: 0 26px 0 17px;
  max-width: calc(100% - 88px);
  white-space: nowrap; /* 防止文本换行 */
`;

const ScrollContainer = styled.div`
  flex: 1;
  background: transparent;
  overflow: hidden;
`;

const ListWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-shrink: 2;
  overflow: hidden;
  flex-direction: column;
  gap: 14px;
`;

const ListItem = styled.div`
  padding: 5px 0;
`;
