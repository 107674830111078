import { z } from "zod";
import { zEnum, zStatic } from "../utils/zodUtils";
import { Media } from "./Media";
export enum SolWay {
  Exchange = 1,
  Platform = 2,
}
export const SolRecommend = z.object({
  name: z.string(),
  url: z.string(),
  type: zEnum(SolWay),
  image: Media,
});

export type SolRecommend = zStatic<typeof SolRecommend>;
