import { string, z } from "zod";
import { zBigInt, zEnum, zStatic } from "../utils/zodUtils";
import { Media } from "./Media";
import { User } from "./User";
import { OffsetPagedList } from "./PagedList";
import { ChatMessage } from "./ChatMessage";
import { ChatThreadType } from "./ChatThreadType";
import { ChatMember, ChatMemberStatus } from "./ChatMember";
import { Category } from "./Category";
import { SmallNote } from "./SmallNote";
import { ObjectStatus } from "./ObjectStatus";

export enum ChatActivityType {
  FreeTalk = 1,
  RolePlay = 2,
  VoiceCall = 3,
  RolePlayV2 = 4,
  ScreenRoom = 5,
}

export enum ChatStartSource {
  Match = 1,
}

export const ChatExtensions = z.object({
  activityType: zEnum(ChatActivityType).optional(),
  parentCircleId: zBigInt.optional(),
  source: zEnum(ChatStartSource).optional(),
});

export type ChatExtensions = zStatic<typeof ChatExtensions>;

export const Chat = z.object({
  background: Media.optional(),
  backgroundColor: z.string().optional(),
  icon: Media.optional(),
  title: string().optional(),
  threadId: zBigInt,
  type: zEnum(ChatThreadType),
  membersSummary: z.array(User).optional(),
  invitedUids: z.array(zBigInt).optional(),
  inviteMessageContent: z.string().optional(),
  matchRate: z.number().optional(),
  latestMessage: ChatMessage.optional(),
  latestMessageId: zBigInt.optional(),
  extensions: ChatExtensions.optional(),
  host: User.optional(),
  createdTime: z.number().optional(),
  currentMemberInfo: ChatMember.optional(),
  category: Category.optional(),
  language: z.string().optional(),
  content: z.string().optional(),
  coHostUids: z.array(zBigInt).optional(),
  allMembersCount: z.number().optional(),
  uid: zBigInt.optional(),
  smallNoteList: z.array(SmallNote).optional(),
  mentionedBy: User.optional(),
  remark: z.string().optional(),
  status: zEnum(ObjectStatus).optional(),
});
export type Chat = zStatic<typeof Chat>;

export function getChatThreadDisplayIcon(chat: Chat, myUid?: bigint) {
  switch (chat.type) {
    case ChatThreadType.single:
    case ChatThreadType.match:
    case ChatThreadType.chatAi:
    case ChatThreadType.character:
      return chat.membersSummary?.find((user) => user.uid !== myUid)?.icon;
    default:
      return chat.icon;
  }
}

export function getChatDisplayName(chat: Chat, myUid: bigint) {
  switch (chat.type) {
    case ChatThreadType.single:
    case ChatThreadType.match:
    case ChatThreadType.chatAi:
    case ChatThreadType.character:
      return chat.membersSummary?.find((user) => user.uid !== myUid)?.nickname;
    default:
      if (chat.title && chat.title.length > 0) {
        return chat.title;
      }
      return chat.membersSummary?.map((user) => user.nickname).join(",");
  }
}

export function displayTitle(chat: Chat): string {
  return chat.title && chat.title.trim() !== ""
    ? chat.title
    : chat.membersSummary?.map((member) => member.nickname).join(",") || "";
}

export function isChatThreadParty(chat: Chat) {
  return chat.type === ChatThreadType.public;
}

export function isChatThreadGroup(chat: Chat) {
  return chat.type === ChatThreadType.group;
}

export function isChatThreadCircleRoom(chat: Chat) {
  return (chat.extensions?.parentCircleId ?? 0) !== 0;
}

export function isChatThreadSingle(chat: Chat) {
  return !isChatThreadParty(chat) && !isChatThreadGroup(chat);
}

export function isUserHostIn(chat: Chat, uid: bigint) {
  return chat.uid === uid;
}

export function isUserCoHostIn(chat: Chat, uid: bigint) {
  return chat.coHostUids && chat.coHostUids.includes(uid);
}

export function isUserHostOrCoHostIn(chat: Chat, uid: bigint) {
  if (chat.type === ChatThreadType.single) {
    return true;
  } else {
    return isUserHostIn(chat, uid) || isUserCoHostIn(chat, uid);
  }
}

export function isJoinedChat(chat: Chat) {
  const status = chat.currentMemberInfo?.chatMemberStatusV2;
  if (!status) {
    return false;
  }
  return (
    status === ChatMemberStatus.Active ||
    status === ChatMemberStatus.BlockedByHost ||
    status === ChatMemberStatus.RemovedByManager
  );
}

export enum ChatListCategory {
  all = "all",
  pinned = "pinned",
  private = "private",
  parties = "parties",
  invite = "invite",
  atMention = "atMention",
  manage = "manage",
}

export const ChatMessageCheckResult = z.object({
  threadId: zBigInt,
});

export const ChatListResponse = z
  .object({
    threadCheckList: z.array(ChatMessageCheckResult).optional(),
    beMentionedThreadIdList: z.array(zBigInt).optional(),
  })
  .merge(OffsetPagedList(Chat));

export type ChatListResponse = zStatic<typeof ChatListResponse>;
