import { HStack, Spring, VStack } from "../../../components/VStack";
import { Image } from "../../../components/Image";
import {
  formatMoney,
  getCurrencyIcon,
  getCurrencyIconByCurrencyType,
} from "../../../proto/Currency";
import {
  AssetOrder,
  AssetOrderStatus,
  TxCategory,
} from "../../../proto/AssetOrder";
import styled from "styled-components";
import { OverFlowTextStyle } from "../../../components/Text";
import { CellDivider, ContentGroup } from "../../../components/ContentGroup";
import { OrderInfoContent, OrderInfoLabel } from "./GiftOrderDetail";
import { useI18n } from "../../../hooks/useI18n";
import { HighLightLabel } from "./PurchaseOrderDetail";
import { ImageContainer, StatusBar } from "./GiftOrderListPage";
import { formatDateWithOptions } from "../../../utils/DateTimeUtils";
import { useHopper } from "../../../hooks/useHopper";
import CircleCardsPreviewCell, {
  CardStyle,
} from "../../circle/card/CircleCardsPreviewCell";

export function PurchaseMembershipCardDetail(props: {
  assetOrder: AssetOrder;
}) {
  const i18n = useI18n();
  const hopper = useHopper();

  function getStatus() {
    switch (props.assetOrder.orderStatus) {
      case AssetOrderStatus.Done:
        return { text: i18n.web3_v0_completed(), color: "#00FF94" };
      case AssetOrderStatus.Pending:
      case AssetOrderStatus.Submitted: {
        return { text: i18n.web3_v0_pending(), color: "#34A6FFFF" };
      }
      case AssetOrderStatus.Failed: {
        return { text: i18n.circle_anmt_failed(), color: "#EF3537FF" };
      }
      case AssetOrderStatus.Rejected: {
        return { text: i18n.circle_anmt_rejected(), color: "#EF3537FF" };
      }
      default:
        return { text: "", color: "#FFFFFF66" };
    }
  }

  const nft = props.assetOrder.nft;
  const totalPriceCurrency = props.assetOrder.txList?.find(
    (tx) => tx.txCategory === TxCategory.SENT,
  )?.currency;
  const totalPayCurrency = props.assetOrder.totalDeductionList?.at(0);
  const serviceFee = props.assetOrder.gasFee;

  return (
    <VStack style={{ width: "100%", gap: 20 }}>
      <HStack
        style={{
          width: "100%",
          padding: 8,
          backgroundColor: "#FFFFFF0F",
          borderRadius: 6,
          gap: 12,
        }}
      >
        <ImageContainer>
          <CircleCardsPreviewCell
            nft={nft}
            cardStyle={CardStyle.small}
            width={64}
          />
        </ImageContainer>

        <VStack style={{ flex: 1, gap: 8 }}>
          <OrderName>
            {i18n.clover_circle_membership_card(nft?.name || "")}
          </OrderName>
          <CardAmount>
            × {props.assetOrder.purchaseMembershipCardInfo?.count}
          </CardAmount>
          <HStack style={{ width: "100%", gap: 4 }}>
            <Image
              src={getCurrencyIconByCurrencyType(props.assetOrder.currencyType)}
              style={{ width: 18, height: 18 }}
            />
            <CurrencyAmountText>
              {formatMoney(
                "long",
                Object.assign({}, totalPriceCurrency, {
                  amount: (
                    BigInt(totalPriceCurrency?.amount || "0") /
                    BigInt(
                      props.assetOrder.purchaseMembershipCardInfo?.count || 1,
                    )
                  ).toString(),
                }),
              )}
            </CurrencyAmountText>
          </HStack>
        </VStack>
      </HStack>
      <ContentGroup style={{ width: "100%", gap: 20 }}>
        <HStack>
          <OrderInfoLabel>{i18n.web3_mint_coupon_total_price()}</OrderInfoLabel>
          <Spring />
          <HStack style={{ gap: 4 }}>
            <Image
              src={getCurrencyIcon(totalPriceCurrency)}
              style={{ width: 18, height: 18 }}
            />
            <OrderInfoContent>
              {formatMoney("long", totalPriceCurrency)}
            </OrderInfoContent>
          </HStack>
        </HStack>
        <CellDivider />
        <HStack>
          <HighLightLabel>{i18n.web3_mint_coupon_total_paid()}</HighLightLabel>
          <Spring />
          <HStack style={{ gap: 4 }}>
            <Image
              src={getCurrencyIcon(totalPayCurrency)}
              style={{ width: 18, height: 18 }}
            />
            <OrderInfoContent style={{ fontSize: 16 }}>
              {formatMoney("long", totalPayCurrency)}
            </OrderInfoContent>
          </HStack>
        </HStack>
      </ContentGroup>

      <ContentGroup style={{ width: "100%", gap: 20 }}>
        <HStack>
          <OrderInfoLabel>{i18n.status()}</OrderInfoLabel>
          <Spring />
          <StatusBar
            statusColor={getStatus().color}
            statusText={getStatus().text}
          />
        </HStack>
        <CellDivider />
        <HStack>
          <OrderInfoLabel>{i18n.web3_v0_service_fee()}</OrderInfoLabel>
          <Spring />
          <HStack style={{ gap: 4 }}>
            <Image
              src={getCurrencyIcon(serviceFee)}
              style={{ width: 18, height: 18 }}
            />
            <OrderInfoContent>
              {formatMoney("long", serviceFee)}
            </OrderInfoContent>
          </HStack>
        </HStack>
        <CellDivider />
        <HStack>
          <OrderInfoLabel>{i18n.circle()}</OrderInfoLabel>
          <Spring />
          <HStack
            style={{ gap: 4 }}
            onClick={() => hopper.push(`circle/${nft?.container?.circleId}`)}
          >
            <Image
              src={[nft?.container?.icon, { width: 18, height: 18 }]}
              style={{ width: 18, height: 18, borderRadius: 2 }}
            />
            <OrderInfoContent>{nft?.container?.name}</OrderInfoContent>
          </HStack>
        </HStack>
        <CellDivider />
        <HStack>
          <OrderInfoLabel>{i18n.web3_v0_created_time()}</OrderInfoLabel>
          <Spring />
          <OrderInfoContent>
            {formatDateWithOptions(props.assetOrder.createdTime || 0, {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })}
          </OrderInfoContent>
        </HStack>
        <CellDivider />
        <HStack>
          <OrderInfoLabel>{i18n.web3_v0_transaction_order_id()}</OrderInfoLabel>
          <Spring />
          <OrderInfoContent>
            {props.assetOrder.orderId.toString()}
          </OrderInfoContent>
        </HStack>
      </ContentGroup>
    </VStack>
  );
}

const OrderName = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #ffffff66;
  width: 100%;
  ${OverFlowTextStyle};
  line-clamp: 2;
  -webkit-line-clamp: 2;
`;

const CardAmount = styled.div`
  font-size: 11px;
  font-weight: 400;
  color: #ffffff99;
`;

const CurrencyAmountText = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: white;
`;
