import { z } from "zod";
import { zBigInt, zEnum, zStatic } from "../utils/zodUtils";
import { Media } from "./Media";
import { MerchType } from "./MerchType";
import { Currency } from "./Currency";
import { Category } from "./Category";
import { MerchItem } from "./MerchItem";
import { MerchStatus } from "./MerchStatus";
import { User } from "./User";
import { ObjectStatus } from "./ObjectStatus";

export const Merch = z.object({
  storeId: zBigInt.optional(),
  creatorUid: zBigInt.optional(),
  merchId: zBigInt,
  merchType: zEnum(MerchType),
  merchStatus: zEnum(MerchStatus),
  status: zEnum(ObjectStatus),
  unitPrice: z.string().default("0"),
  description: z.string().optional(),
  promotionalTitle: z.string().optional(),
  title: z.string().optional(),
  cover: Media.optional(),
  currencyType: z.number(),
  creator: User.optional(),
  currency: Currency,
  categoryId: zBigInt.optional(),
  category: Category.optional(),
  subCategoryId: zBigInt.optional(),
  createdTime: zBigInt,
  available: z.number().default(0),
  totalSold: z.number().default(0),
  maxAvailable: z.number().default(0),
  subCategory: Category.optional(),
  contentRegion: zBigInt.optional(),
  forList: z.boolean().default(false),
  directFromNFTAuthor: z.boolean().default(false),
  merchItemList: z.array(MerchItem).default([]),
});

export type Merch = zStatic<typeof Merch>;
