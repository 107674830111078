import { useI18n } from "../../../hooks/useI18n";
import {
  useEnumSearchParam,
  useNumberSearchParam,
} from "../../../hooks/useTypedParam";
import { SecurityPurpose } from "../../../proto/Auth";
import { useLoadState } from "../../../hooks/LoadState";
import { Page } from "../../../components/Page";
import { VSpace } from "../../../components/VStack";
import { RegularLargeButton } from "../../../components/Buttons";
import { Spin } from "../../../components/Spin";
import { useHopper } from "../../../hooks/useHopper";
import { useBackend } from "../../../service/APIService";

export function VerifyBeforeContinuePage() {
  const i18n = useI18n();
  const backend = useBackend();
  const hopper = useHopper();
  const purpose = useEnumSearchParam<SecurityPurpose>(
    "purpose",
    SecurityPurpose.UpdatePaymentPassword,
  );

  const loadTask = useLoadState();

  return (
    <Page pageData={undefined}>
      <div
        style={{
          color: "var(--color-text00)",
          fontWeight: 500,
          fontSize: "20px",
          textAlign: "center",
        }}
      >
        {i18n.newbie_process_forget_payment_passcode()}
      </div>
      <VSpace height={16} />
      <div
        style={{
          color: "rgba(255, 255, 255, 0.6)",
          fontSize: "14px",
          fontWeight: 500,
          textAlign: "center",
        }}
      >
        {i18n.reset_pp_verify_identity()}
      </div>
      <VSpace height={160} />
      <RegularLargeButton
        style={{ height: "54px", margin: "0 50px" }}
        onClick={() => {
          hopper.push("verification-code", {
            // TODO:StevenQ get real account email and phone
            // email: authType === AuthType.Email ? inputValue : undefined,
            // phoneNumber: authType === AuthType.Phone ? inputValue : undefined,
            purpose: SecurityPurpose.UpdatePaymentPassword,
            // authType: AuthType.Email,
          });
        }}
      >
        <Spin state={loadTask.state}>{i18n.web3_v0_verify()}</Spin>
      </RegularLargeButton>
    </Page>
  );
}
