import styled, { css, RuleSet } from "styled-components";
import { useI18n } from "../../../hooks/useI18n";
import { HStack, Spring } from "../../../components/VStack";
import { Image } from "../../../components/Image";
import { UserPropsType } from "../../../proto/Wallet";
import goForwardIcon from "../../../res/images/go_forward.svg";
import bg from "../../../res/images/bg_energy.png";

export interface EnergyProps {
  units: number;
  onClick: (userPropsType: UserPropsType) => void;
}

function EnergyPropsCell(props: EnergyProps) {
  const i18n = useI18n();

  return (
    <ContentCard onClick={() => props.onClick(UserPropsType.matchPass)}>
      <EnergyBg />
      <HStack
        style={{
          margin: "17px 11px",
          width: 140,
          justifyContent: "space-between",
        }}
      >
        <EnergyValueLabel>{props.units}</EnergyValueLabel>
        <ForwardIcon />
      </HStack>

      <EnergyTitleLabel>
        {i18n.lite_asset_priority_match_passes()}
      </EnergyTitleLabel>
    </ContentCard>
  );
}

export default EnergyPropsCell;

const ForwardIcon = styled.div`
  width: 24px;
  height: 24px;
  background-image: url("${goForwardIcon}");
`;

const ContentCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  border-radius: 6px;
  align-self: stretch;
  flex-grow: 1;
  width: 163px;
  height: 164px;
  border: 1px solid rgba(255, 255, 255, 0.06);
  background-color: rgba(255, 255, 255, 0.05);
`;

const EnergyTitleLabel = styled.div`
  color: rgba(255, 255, 255, 1);
  margin: 0 12px;
  font-size: 16px;
  font-weight: 500;
`;

const EnergyValueLabel = styled.div`
  font-size: 28px;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
`;

const EnergyBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-image: url("${bg}");
  background-repeat: no-repeat;
  background-size: 100% 100%;
`;
