import { Circle, CircleJoinStep } from "../../proto/Circle";
import React, { CSSProperties } from "react";
import styled from "styled-components";
import { Image } from "../../components/Image";
import { HStack, Spring, VStack } from "../../components/VStack";
import iconPendingTime from "../../res/images/icon_pending_time.svg";
import { JoinRequestStatus } from "./JoinCircleRequest";
import { useI18n } from "../../hooks/useI18n";
import { shrink_on_pressed } from "../../components/CommonStyles";
import { TokenProject } from "../../proto/TokenProject";
import { isValidCircleToken } from "../../proto/CircleFin";
import { getCircleTokenStageImage } from "./CircleDetailInfoPage";
import { formatMoney } from "../../proto/Currency";
import BigNumber from "bignumber.js";
import {
  circleTokenFluctuationImage,
  marketCap1HipColor,
  marketCap1HipString,
} from "./CircleStatsPage";
import iconHourglass from "../../res/images/ic_hourglass.png";

export interface CircleGridProps {
  circleId: bigint;
  circle: Circle;
  onClick: () => void;
}

export function CircleBottomTokenView(props: {
  circle: Circle;
  style?: CSSProperties;
}) {
  const token = props.circle.tokenProject;
  const i18n = useI18n();
  return (
    <div
      style={{
        ...props.style,
        minHeight: 57,
        height: 57,
        backgroundColor: isValidCircleToken(token)
          ? "rgba(141, 177, 242, 0.1)"
          : "rgba(255, 255, 255, 0.04)",
        borderEndEndRadius: 8,
        borderEndStartRadius: 8,
      }}
    >
      {isValidCircleToken(token) && (
        <div>
          <HStack style={{ display: "flex", paddingTop: 4 }}>
            <Image
              alt={"tokenInfoIcon"}
              src={[token?.image, "best"]}
              width={14}
              height={14}
              style={{
                borderRadius: "2px",
                marginInlineEnd: 4,
                marginInlineStart: 4,
              }}
            ></Image>
            <TokenTickerLabel>
              {isValidCircleToken(token) && token.ticker}
            </TokenTickerLabel>
            <Image
              style={{ marginInlineStart: 4 }}
              src={getCircleTokenStageImage(token)}
              width={12}
              height={12}
            ></Image>
            <Spring />
          </HStack>
          <HStack
            style={{
              gap: 4,
              display: "flex",
              alignItems: "center",
              marginTop: 4,
            }}
          >
            <div
              style={{
                fontSize: 8,
                marginInlineStart: 4,
                marginTop: 1,
                fontWeight: 400,
                display: "flex",
                alignItems: "center",
                height: "100%",
                color: "rgba(182, 182, 182, 1)",
              }}
            >
              {"MC"}
            </div>
            <MarketCapLabel>
              $
              {isValidCircleToken(token) &&
                formatMoney(
                  "short",
                  BigNumber(token.tradingStatistics?.marketCap || "0"),
                )}
            </MarketCapLabel>
          </HStack>
          <TokenValPercentageChangeLabel
            style={{
              color: marketCap1HipColor(token),
              marginInlineStart: 4,
              marginTop: 4,
            }}
          >
            {isValidCircleToken(token) &&
              token?.tradingStatistics?.marketCap1Hip !== 0 && (
                <Image
                  src={circleTokenFluctuationImage(token)}
                  alt={"PercentageChangeDirection"}
                  width={12}
                  height={12}
                ></Image>
              )}
            {marketCap1HipString(token)}
          </TokenValPercentageChangeLabel>
        </div>
      )}
      {!isValidCircleToken(token) && (
        <VStack
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Spring />
          <Image
            src={iconHourglass}
            width={12}
            height={12}
            alt={"iconHourglass"}
          ></Image>
          <NoCircleTokenYetLabel>
            {i18n.clover_no_circle_token_yet()}
          </NoCircleTokenYetLabel>
          <Spring />
        </VStack>
      )}
    </div>
  );
}

export function CircleGridWithTokenCell(props: CircleGridProps) {
  return (
    <div
      onClick={props.onClick}
      style={{
        borderRadius: 8,
        borderWidth: 0.4,
        borderStyle: "solid",
        borderColor: isValidCircleToken(props.circle.tokenProject)
          ? "rgba(141, 177, 242, 0.4)"
          : "rgba(255, 255, 255, 0.1)",
      }}
    >
      <CircleGridCell
        circleId={props.circleId}
        circle={props.circle}
        onClick={props.onClick}
      ></CircleGridCell>
      <CircleBottomTokenView circle={props.circle}></CircleBottomTokenView>
    </div>
  );
}

function CircleGridCell(props: CircleGridProps) {
  const i18n = useI18n();

  return (
    <CircleGridContainer onClick={props.onClick}>
      <Image
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          borderStartStartRadius: 8,
          borderStartEndRadius: 8,
          height: "100%",
        }}
        src={[props.circle.cover, "best"]}
      />
      <GradientOverlay />
      <CircleName>{props.circle.name}</CircleName>
      <Image
        src={[props.circle.circleIcon, "best"]}
        width={30}
        height={30}
        style={{
          borderRadius: 4,
          position: "absolute",
          right: 4,
          top: 4,
          border: `0.5px solid ${props.circle.themeColor}`,
        }}
      />
      {props.circle.joinRequest?.requestId !== undefined && (
        <div
          style={{
            backgroundColor: `rgba(0, 0, 0, 0.8)`,
            position: "absolute",
            left: 0,
            top: 0,
            borderStartStartRadius: 8,
            borderStartEndRadius: 8,
            width: "100%",
            height: "100%",
            zIndex: 3,
            display: "flex", // 添加 flex 布局
            alignItems: "center", // 垂直居中
            justifyContent: "center", // 水平居中
          }}
        >
          <VStack
            style={{
              width: "70%",
              height: "100%",
              alignItems: "center",
              gap: 4,
            }}
          >
            <Spring />
            <div
              style={{
                whiteSpace: "normal",
                overflowWrap: "break-word",
                textAlign: "center",
                width: "100%",
                color: "rgba(0, 226, 91, 1)",
                fontSize: 10,
              }}
            >
              {i18n.web3_v0_activate()}
            </div>
            <Spring />
          </VStack>
        </div>
      )}
    </CircleGridContainer>
  );
}

export function CirclePendingCell(props: {
  style?: React.CSSProperties;
  circle: Circle;
  onClick: () => void;
}) {
  const i18n = useI18n();

  const statusTitle = () => {
    const joinStatus = props.circle.joinRequest?.status;
    const joinStep = props.circle.joinRequest?.extensions?.nextStep;
    const smallNoteList = props.circle.smallNoteList ?? [];
    if (smallNoteList.length > 0) {
      return i18n.smallnote_unread_attention();
    }
    if (joinStep === CircleJoinStep.purchaseMembershipCard) {
      return i18n.clover_pending_membership_card();
    }
    switch (joinStatus) {
      case JoinRequestStatus.unprocess:
        return i18n.clover_pending_approval();
      case JoinRequestStatus.rejected:
        return i18n.circle_anmt_rejected();
      default:
        break;
    }
  };
  const showStatusIcon = () => {
    return (
      props.circle.joinRequest?.extensions?.nextStep ===
      CircleJoinStep.purchaseMembershipCard
    );
  };
  const statusTitleColor = () => {
    const joinStatus = props.circle.joinRequest?.status;
    if (joinStatus === JoinRequestStatus.rejected) {
      return `rgba(239, 53, 55, 1)`;
    }
    return `rgba(255, 175, 2, 1)`;
  };

  return (
    <CirclePendingContainer style={props.style} onClick={props.onClick}>
      <Image
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          borderRadius: 8,
          height: "100%",
        }}
        src={[props.circle.cover, "best"]}
      />
      <GradientOverlay />
      <CircleName>{props.circle.name}</CircleName>
      <Image
        src={[props.circle.circleIcon, "best"]}
        width={30}
        height={30}
        style={{
          borderRadius: 4,
          position: "absolute",
          right: 4,
          top: 4,
          border: `0.5px solid ${props.circle.themeColor}`,
        }}
      />
      <div
        style={{
          backgroundColor: `rgba(0, 0, 0, 0.8)`,
          position: "absolute",
          left: 0,
          top: 0,
          borderRadius: 8,
          width: "100%",
          height: "100%",
          zIndex: 3,
          display: "flex", // 添加 flex 布局
          alignItems: "center", // 垂直居中
          justifyContent: "center", // 水平居中
        }}
      >
        <VStack
          style={{ width: "70%", height: "100%", alignItems: "center", gap: 4 }}
        >
          <Spring />
          {showStatusIcon() && (
            <Image src={iconPendingTime} width={20} height={20} />
          )}
          <div
            style={{
              whiteSpace: "normal",
              overflowWrap: "break-word",
              textAlign: "center",
              width: "100%",
              color: statusTitleColor(),
              fontSize: 10,
            }}
          >
            {statusTitle()}
          </div>
          <Spring />
        </VStack>
      </div>
    </CirclePendingContainer>
  );
}

// 渐变覆盖层
const GradientOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px; // 渐变高度
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
  z-index: 1; // 确保在图片之上
`;

const CirclePendingContainer = styled.div`
  width: 28%; // 保证在 grid 中铺满父容器
  padding-bottom: 40%;
  position: relative; // 让内部元素相对定位
  overflow: hidden;
`;

const CircleGridContainer = styled.div`
  width: 100%; // 保证在 grid 中铺满父容器
  padding-bottom: 140%;
  position: relative; // 让内部元素相对定位
  overflow: hidden;
  ${shrink_on_pressed};
`;

// CircleName 组件在底部显示
const CircleName = styled.div`
  position: absolute;
  bottom: 10px; // 距离底部10px
  left: 0;
  right: 0;
  color: var(--color-text00); // 文字颜色
  font-size: 14px;
  font-weight: 500;
  text-align: center; // 居中对齐
  z-index: 2; // 确保在渐变层之上
`;

const TokenTickerLabel = styled.div`
  font-weight: 700;
  font-size: 10px;
  color: var(--color-text00);
  background: #ffffff0f;
  padding: 2px;
  border-radius: 2px;
`;

const MarketCapLabel = styled.div`
  font-weight: 500;
  font-size: 10px;
  color: var(--color-text00);
`;

const NoCircleTokenYetLabel = styled.div`
  font-weight: 400;
  font-size: 9px;
  width: 70%;
  text-align: center;
  color: rgba(255, 255, 255, 0.4);
`;

const TokenValPercentageChangeLabel = styled.div`
  font-weight: 400;
  font-size: 8px;
  color: #00ff94;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export default CircleGridCell;
