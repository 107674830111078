import { SHUMEI_PROXY } from "../config/config";

let isReady = false;

function startAntiFraudIfNeeded() {
  const theWindow = window as any;
  if (theWindow._smReadyFuncs !== undefined) {
    return;
  }

  theWindow._smReadyFuncs = [];
  theWindow.SMSdk = {
    onBoxDataReady: function (boxData: any) {},
    ready: function (fn: any) {
      fn && theWindow._smReadyFuncs.push(fn);
    },
  };

  // 1. 通用配置项
  theWindow._smConf = {
    organization: "BU0gJ0gB5TFcCfN329Vx", // 必填，组织标识，邮件中organization项
    appId: "spongekit", // 必填，应用标识，默认传值default，其他应用标识提前联系数美协助定义
    publicKey:
      "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCfLVymLDeknAn1mt+V1mZDffQ5ZGe1z1/J4l3Oy3UIQ+kigGEeSWgsdUO6TtgfKjOMuwr16T9ceFCWDrsrLaTP7Q8UOafwAHCV/8drFurr25g7PeGjIhpxd6Z3N1FMa4rIgrgQ9cx9xFp9OkdXsyagzdaGuK3xwWuu+NsXuCVv8QIDAQAB", // 必填，私钥标识，邮件中publicKey项
    staticHost: "static.portal101.cn", // 必填, 设置JS-SDK文件域名，建议填写static.portal101.cn
    protocol: "https", // 如果使用https，则设置，如不使用，则不设置这个字段
    apiHost: SHUMEI_PROXY,
  };

  const url = (function () {
    const isHttps = "https:" === document.location.protocol;
    const protocol = isHttps ? "https://" : "http://";
    const fpJsPath = "/dist/web/v3.0.0/fp.min.js";
    return protocol + theWindow._smConf.staticHost + fpJsPath;
  })();
  const sm = document.createElement("script");
  const s = document.getElementsByTagName("script")[0];
  sm.src = url;
  s.parentNode?.insertBefore(sm, s);
}

export function getAntiFraudDeviceId(): Promise<string> {
  startAntiFraudIfNeeded();
  const theWindow = window as any;
  if (isReady) {
    return Promise.resolve(theWindow.SMSdk.getDeviceId());
  } else {
    return new Promise((resolve, reject) => {
      theWindow.SMSdk.ready(() => {
        isReady = true;
        resolve(theWindow.SMSdk.getDeviceId());
      });
    });
  }
}
