import { Page } from "../../components/Page";
import {
  useBigIntParam,
  useStringSearchParam,
} from "../../hooks/useTypedParam";
import { useSWRArray } from "../../hooks/swr/useSWRArray";
import { useBackend } from "../../service/APIService";
import { CommonUserCell } from "./CommonUserCell";
import { NavMiddle } from "../../components/NavBar";
import { useI18n } from "../../hooks/useI18n";

function OtherSocialListPage() {
  const uid = useBigIntParam("uid");
  const type = useStringSearchParam("type");
  const backend = useBackend();
  const usersSWR = useSWRArray(backend.getSocialUsers(uid, type));
  const i18n = useI18n();

  return (
    <Page pageData={usersSWR}>
      <NavMiddle>
        {type === "follower" ? i18n.followers() : i18n.following()}
      </NavMiddle>
      {usersSWR.content.map((user) => (
        <CommonUserCell user={user} />
      ))}
    </Page>
  );
}
export default OtherSocialListPage;
