import { string, z } from "zod";
import { zBigInt, zStatic } from "../utils/zodUtils";
import { Media } from "./Media";
import { User } from "./User";
import { Background } from "./Background";

export const StoreExtensions = z.object({
  contentStatus: z.number().optional(),
  isShowDefaultIcon: z.boolean().optional(),
});

export type StoreExtensions = zStatic<typeof StoreExtensions>;

export const StoreLevelInfo = z.object({
  storeLevel: z.number().optional(),
  listedOriginalMerchCount: z.number().optional(),
  totalSales: z.number().optional(),
});

export type StoreLevelInfo = zStatic<typeof StoreLevelInfo>;

export const Notification = z.object({
  notReceiveStoreTransactionNotification: z.boolean().default(false),
});

export const Store = z.object({
  cover: Media.optional(),
  createdTime: z.number().optional(),
  icon: Media.optional(),
  name: z.string().optional(),
  owner: User.optional(),
  ownerId: zBigInt.optional(),
  ownerType: z.number().optional(),
  storeLevel: z.number().default(0),
  storeStatus: z.number().optional(),
  storeId: zBigInt.default(0),
  storeType: z.number().optional(),
  status: z.number().optional(),
  description: z.string().optional(),
  isDiamondSupported: z.boolean().optional(),
  background: Background.optional(),
  listedMerchCount: z.number().default(0),
  extensions: StoreExtensions.optional(),
  customizedShopfront: z.boolean().optional(),
  nextLevelInfo: StoreLevelInfo.optional(),
  listedOriginalMerchCount: z.number().optional(),
  totalSales: z.number().optional(),
  notification: Notification.optional(),
});

export type Store = zStatic<typeof Store>;

export const StoreOrderItemExtensions = z.object({
  nftId: zBigInt.optional(),
  merchType: z.number().optional(),
  nftVersion: z.number().optional(),
});

export type StoreOrderItemExtensions = zStatic<typeof StoreOrderItemExtensions>;

export enum MerchType {
  Normal = 1,
  Free = 2,
}

export const StoreOrderItem = z.object({
  merchId: zBigInt.optional(),
  count: z.number().optional(),
  cover: Media.optional(),
  title: z.string().optional(),
  price: z.string().optional(),
  currencyType: z.number().optional(),
  extensions: StoreOrderItemExtensions.optional(),
});

export type StoreOrderItem = zStatic<typeof StoreOrderItem>;

export const PaymentItemExtensions = z.object({
  toCoinAmount: z.string().optional(),
});

export type PaymentItemExtensions = zStatic<typeof PaymentItemExtensions>;
