import { getFittestRes, getSmallestRes, Media, Size } from "./Media";
import { I18n } from "../hooks/useI18n";
import sol from "../res/images/ic_sol.svg";
import BigNumber from "bignumber.js";
import { AlignUndefined } from "../utils/Nullable";
import { formatBigNumberImpl } from "../utils/NumberI18n";
import { z } from "zod";
import { zBigInt, zBigNumber, zEnum, zStatic } from "../utils/zodUtils";
import { ChainType } from "./ChainType";
import iconSOL from "../../src/res/images/icon_sol.svg";
import iconGEMS from "../../src/res/images/icon_gems.svg";
import iconUSDT from "../../src/res/images/icon_usdt.svg";
import iconUSDC from "../../src/res/images/icon_usdc.svg";
import iconCoin from "../../src/res/images/icon_old_coin.png";
import iconCoupon from "../../src/res/images/ic_coupon.png";
import iconPass from "../../src/res/images/ic_pass.png";
import iconDiamond from "../../src/res/images/ic_diamond.png";
import iconNFT from "../../src/res/images/ic_currency_nft.png";
import iconSnacks from "../../src/res/images/ic_snack.png";
import iconTickets from "../../src/res/images/ic_tickets.png";

export enum CurrencyType {
  DOLLAR = 1,
  // BTC = 58,
  USDT = 7,
  USDC = 8,
  BLR = 10,
  MXN = 11,
  SOL = 60,
  COIN = 100,
  DIAMOND = 101,
  REWARDED_COIN = 103,
  GEMS = 104,
  NFT = 105,
  CIRCLE_POINT = 106,
  COUPON = 110,
  SOL_TOKENS = 150,
  TICKETS = 200,
  SNACKS = 201,
  PASSES = 202,
}

export enum CurrencyId {
  SOP = 1,
  GEMS = 2,
  SOL = 5,
}

export const Currency = z.object({
  currencyId: zBigInt.default(0),
  name: z.string().optional(),
  symbol: z.string().optional(),
  icon: Media.optional(),
  decimals: z.number().default(18),
  currencyType: zEnum(CurrencyType),
  amount: z.string().default("0"),
  usdPrice: z.number().optional(),
  currencyCode: z.string().optional(),
  supportSwap: z.boolean().optional(),
  countryCode: z.string().optional(),
  chainType: zEnum(ChainType).optional(),
  supply: z.number().optional(),
  address: z.string().optional(),
  dexScreenerUrl: z.string().optional(),
  marketId: z.string().optional(),
  isCash: z.boolean().optional(),
  unitUSDPrice: zBigNumber.optional(),
  poolId: z.string().optional(),
});

export type Currency = zStatic<typeof Currency>;

export enum OrderType {
  // UserTransfer = 1,
  // AirdropBase = 2,
  // AirdropBonus = 3,
  AirdropAdvanced = 4,
  AirdropAdvancedDays = 5,
  // AirdropCheckInRewards = 6,
  // SendGiftBox = 7,
  // ClaimedGiftBox = 8,
  // NewUserTask = 9,
  // TransferTypeKOLAirdropBase = 10,
  // TransferTypeKOLAirdropBonus = 11,
  // TransferTypeRecallCompensation = 12,
  // TransferTypeRecallCustomize = 13,
  // TransferTypeSystemWidgetReward = 14,
  // AdsRewardTask = 15,
  // UserTaskRewardV2 = 17,
  // DatingMatchBonus = 18,
  // ProfileCompleted = 19,
}

export function getCurrencyName(
  currency?: Currency,
  i18n?: I18n,
): string | undefined {
  if (currency?.isCash) {
    return i18n?.cash_rewards_cash();
  }
  const currencyType = currency?.currencyType;
  if (currencyType === CurrencyType.SOL_TOKENS) {
    return currency?.symbol;
  } else {
    return (
      getCurrencyNameByCurrencyType(currencyType, i18n) ??
      currency?.currencyCode
    );
  }
}

export function getCurrencyNameByCurrencyType(
  currencyType?: CurrencyType,
  i18n?: I18n,
): string | undefined {
  switch (currencyType) {
    case CurrencyType.COIN:
      return i18n?.clover_coins();
    case CurrencyType.DIAMOND:
      return i18n?.clover_diamonds();
    case CurrencyType.COUPON:
      return i18n?.web3_mint_coupon_coupon();
    case CurrencyType.GEMS:
      return "Gems";
    case CurrencyType.SOL:
      return "SOL";
    case CurrencyType.USDT:
      return "USDT";
    case CurrencyType.USDC:
      return "USDC";
  }
}

export function getCurrencyIcon(
  currency?: Currency,
  size?: Size,
): string | undefined {
  const currencyType = currency?.currencyType;
  return (
    getCurrencyIconByCurrencyType(currencyType) ??
    (currency?.icon?.resourceList &&
      (size
        ? getFittestRes(currency.icon, size).url
        : getSmallestRes(currency.icon).url))
  );
}

export function getCurrencyIconByCurrencyType(
  currencyType?: CurrencyType,
): string | undefined {
  switch (currencyType) {
    case CurrencyType.REWARDED_COIN:
    case CurrencyType.COIN:
      return iconCoin;
    case CurrencyType.GEMS:
      return iconGEMS;
    case CurrencyType.SOL:
      return iconSOL;
    case CurrencyType.USDT:
      return iconUSDT;
    case CurrencyType.USDC:
      return iconUSDC;
    case CurrencyType.DIAMOND:
      return iconDiamond;
    case CurrencyType.NFT:
      return iconNFT;
    case CurrencyType.COUPON:
      return iconCoupon;
    case CurrencyType.SNACKS:
      return iconSnacks;
    case CurrencyType.TICKETS:
      return iconTickets;
    case CurrencyType.PASSES:
      return iconPass;
    default:
      return undefined;
  }
}

export function isCurrencyToken(currencyType?: number): boolean {
  return (
    currencyType === CurrencyType.COIN ||
    currencyType === CurrencyType.DIAMOND ||
    currencyType === CurrencyType.REWARDED_COIN ||
    currencyType === CurrencyType.GEMS ||
    currencyType === CurrencyType.USDC ||
    currencyType === CurrencyType.USDT ||
    currencyType === CurrencyType.SOL
  );
}

export function isCurrencyCash(currencyType?: CurrencyType): boolean {
  return currencyType === CurrencyType.BLR || currencyType === CurrencyType.MXN;
}

export function isCurrencyProps(currencyType?: CurrencyType): boolean {
  return (
    currencyType === CurrencyType.TICKETS ||
    currencyType === CurrencyType.PASSES ||
    currencyType === CurrencyType.SNACKS
  );
}

export type MonetaryFormat = "short" | "medium" | "long";

function formatToConfig(
  format: MonetaryFormat,
): Parameters<typeof formatBigNumberImpl>[1] {
  switch (format) {
    case "long":
      return {
        compressLeadingZeros: true,
      };
    case "medium":
      return {
        decimalPlaces: 3,
        decimalPlacesWhenLessThanZero: 5,
        compressLeadingZeros: true,
      };
    case "short":
      return {
        kmb: true,
        decimalPlaces: 2,
        decimalPlacesWhenLessThanZero: 5,
        compressLeadingZeros: true,
      };
  }
}

export function formatMoney<T extends Currency | BigNumber | undefined>(
  format: MonetaryFormat,
  value: T,
): AlignUndefined<string, T> {
  if (value === undefined) return undefined as any;

  const config = formatToConfig(format);

  if (BigNumber.isBigNumber(value)) {
    return formatBigNumberImpl(value, config);
  } else {
    return formatBigNumberImpl(getCurrencyAmount(value), config);
  }
}

export function plus<T extends Currency | undefined>(first: T, second: T) {
  if (first === undefined && second === undefined) return undefined;
  if (!first) {
    first = {
      ...second,
      amount: "0",
    };
  }
  if (!second) {
    second = {
      ...first,
      amount: "0",
    };
  }
  if (first.currencyType !== second.currencyType) return undefined;
  const total = BigNumber(first.amount).plus(BigNumber(second.amount));
  return {
    ...first,
    amount: total.toFixed(),
  };
}

export function multiply(
  currency: Currency | undefined,
  value: number | undefined,
) {
  if (currency === undefined || value === undefined) return undefined;
  const total = BigNumber(currency.amount).times(value);
  return {
    ...currency,
    amount: total.toFixed(),
  };
}

export function getCurrencyAmount<C extends Currency | undefined>(
  currency: C,
): AlignUndefined<BigNumber, Currency> {
  if (currency === undefined) return undefined as any;

  const amount = new BigNumber(currency.amount);
  const decimal = new BigNumber(`1${"0".repeat(currency.decimals)}`);

  return amount.div(decimal);
}

export function getCurrencyAmountFromString(amount: string, decimals?: number) {
  const amountInBigNumber = new BigNumber(amount);
  const decimal = new BigNumber(`1${"0".repeat(decimals || 18)}`);

  return amountInBigNumber.div(decimal);
}

export function getChainIcon(chainType: ChainType) {
  if (chainType === ChainType.Solana || chainType === ChainType.SolanaTestnet) {
    return sol;
  }
}

export const ChainInfo = z.object({
  chainType: z.number(),
  supply: z.number(),
});

export type ChainInfo = zStatic<typeof ChainInfo>;
