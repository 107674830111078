import { z } from "zod";
import { zBigInt, zStatic } from "../utils/zodUtils";
import { Media } from "./Media";

export const NFTUser = z.object({
  name: z.string().optional(),
  uid: zBigInt.optional(),
  icon: Media.optional(),
});

export type NFTUser = zStatic<typeof NFTUser>;
