import { RegularButton } from "../../../components/Buttons";
import React, { PropsWithChildren } from "react";
import { useI18n } from "../../../hooks/useI18n";
import styled from "styled-components";
import { useLoadState } from "../../../hooks/LoadState";
import { Spin } from "../../../components/Spin";
import { andLog, useErrorHandler } from "../../../components/handleError";
import { useNativePage } from "../../../hooks/useBridge";
import { Backend } from "../../../service/Backend";
import iconClose from "../../../res/images/ic_close.svg";
import { Image } from "../../../components/Image";
import { BottomSheet, ModalController } from "../../../components/Modal";
import { usePageSpec } from "../../common/usePageSpec";
import { useObscuredZoneForKey } from "../../../hooks/useObscuredZones";
import { useSWR } from "../../../hooks/swr/useSWR";
import { delay } from "../../../utils/ensureDur";
import { useBackend } from "../../../service/APIService";

export function TxSheet(
  props: PropsWithChildren<{
    modal: ModalController;
    performTx: ReturnType<typeof usePerformTx>;
  }>,
) {
  const i18n = useI18n();
  const pageSpec = usePageSpec();
  const [obscuredZone] = useObscuredZoneForKey("Browser", "root");

  return (
    <BottomSheet modal={props.modal}>
      <Container
        style={{
          paddingBottom: pageSpec !== "wide" ? obscuredZone.bottom + 18 : 0,
        }}
      >
        <Title>{i18n.newbie_process_confirm_your_transaction()}</Title>
        <Image
          onClick={() => {
            props.modal.close();
          }}
          style={{ position: "absolute", top: 0, right: 12 }}
          src={iconClose}
          width={36}
          height={36}
        />
        {props.children}
        <RegularButton
          onClick={() => props.performTx[1]().catch(andLog)}
          style={{
            marginInlineStart: 20,
            marginInlineEnd: 20,
            marginTop: 20,
            fontWeight: 500,
            fontSize: 16,
            marginBottom: 30,
          }}
        >
          <Spin state={props.performTx[0]}>
            {i18n.newbie_process_confirm_and_pay()}
          </Spin>
        </RegularButton>
      </Container>
    </BottomSheet>
  );
}

export function usePerformTx(
  pay: () => Promise<bigint | undefined>,
  onSuccess: (orderId: bigint | undefined) => Promise<void>,
) {
  const backend = useBackend();
  const walletSWR = useSWR(backend.getWallet());
  const paymentTask = useLoadState();
  const handleError = useErrorHandler();

  async function perform() {
    const r = await paymentTask.run(async () => {
      const resp = await pay();
      await delay(200);
      await walletSWR.load();

      return resp;
    });

    if (r.success) {
      onSuccess(r.result).catch(andLog);
    } else {
      handleError(r.error);
    }
  }

  return [paymentTask.state, perform] as const;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 18px;
  margin-top: 16px;
  text-align: center;
  margin-bottom: 30px;
`;
