//Powered by GPT-4o
import React from "react";

export interface SwitchProps {
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  disabled?: boolean;
}

export const Switch: React.FC<SwitchProps> = ({
  checked = false,
  onChange,
  disabled = false,
}) => {
  const handleClick = () => {
    if (disabled) return;
    onChange?.(!checked);
  };

  return (
    <button
      onClick={handleClick}
      style={{
        display: "inline-block",
        width: "50px",
        height: "25px",
        backgroundColor: checked ? "#4caf50" : "#ccc",
        borderRadius: "25px",
        position: "relative",
        cursor: disabled ? "not-allowed" : "pointer",
        border: "none",
        outline: "none",
        transition: "background-color 0.2s ease",
        flexShrink: 0,
        WebkitTapHighlightColor: "transparent", // 移除移动设备点击时的高亮效果
      }}
    >
      <span
        style={{
          position: "absolute",
          top: "2px",
          left: checked ? "26px" : "2px",
          width: "20px",
          height: "20px",
          backgroundColor: "#fff",
          borderRadius: "50%",
          transition: "left 0.2s ease",
        }}
      />
    </button>
  );
};

export default Switch;
