import { Page } from "../../../components/Page";
import { useBackend } from "../../../service/APIService";
import { useSWR } from "../../../hooks/swr/useSWR";
import { VStack } from "../../../components/VStack";
import EnergyPropsCell from "./EnergyPropsCell";
import { UserPropsType } from "../../../proto/Wallet";
import { useHopper } from "../../../hooks/useHopper";

function AccountPropsPage() {
  const backend = useBackend();
  const hopper = useHopper();
  const myPropsSWR = useSWR(backend.getUserProps());
  function onClickItem(userPropsType: UserPropsType) {
    switch (userPropsType) {
      case UserPropsType.matchPass:
        hopper.push("priority-match-passes");
        break;
    }
  }
  return (
    <Page alwaysShowsContent pageData={myPropsSWR}>
      <VStack
        style={{
          marginTop: 20,
          display: "grid",
          gridGap: 9,
        }}
      >
        <EnergyPropsCell
          key={UserPropsType.matchPass}
          units={myPropsSWR.content?.priorityMatchPassesAvailableUnits ?? 0}
          onClick={onClickItem}
        ></EnergyPropsCell>
      </VStack>
    </Page>
  );
}

export default AccountPropsPage;
