import React from "react";
import { DemoCell } from "./DemoCell";
import { useHopper } from "../../hooks/useHopper";
import { HStack, Spring } from "../../components/VStack";
import chevron from "../../res/images/chevron.svg";
import { Page } from "../../components/Page";
import { Image } from "../../components/Image";

import { useBackend } from "../../service/APIService";
import { NavMiddle } from "../../components/NavBar";

export function DebugHomePage() {
  const hopper = useHopper();
  const backend = useBackend();

  return (
    <Page pageData={undefined}>
      <NavMiddle>Demo Home</NavMiddle>
      <DemoCell onClick={() => hopper.push(`components-demo`)}>
        <HStack>
          <span style={{ color: "aqua", fontSize: 20 }}>Component Demos</span>
          <Spring />
          <Image src={chevron} alt={"reveal"} />
        </HStack>
      </DemoCell>

      <DemoCell onClick={() => hopper.push(`hooks-demo`)}>
        <HStack>
          <span style={{ color: "aqua", fontSize: 20 }}>Hook Demos</span>
          <Spring />
          <Image src={chevron} alt={"reveal"} />
        </HStack>
      </DemoCell>

      <DemoCell onClick={() => hopper.push(`home`)}>
        <HStack>
          <span style={{ color: "aqua", fontSize: 20 }}>New App</span>
          <Spring />
          <Image src={chevron} alt={"reveal"} />
        </HStack>
      </DemoCell>

      <DemoCell onClick={() => hopper.push(`home`)}>
        <HStack>
          <span style={{ color: "aqua", fontSize: 20 }}>Home tab entrance</span>
          <Spring />
          <Image src={chevron} alt={"reveal"} />
        </HStack>
      </DemoCell>

      <DemoCell onClick={() => hopper.push(`page-arch-demo`)}>
        <HStack>
          <span style={{ color: "aqua", fontSize: 20 }}>Page Demos</span>
          <Spring />
          <Image src={chevron} alt={"reveal"} />
        </HStack>
      </DemoCell>

      <DemoCell onClick={() => hopper.push(`circle-trade/1778370586329088000`)}>
        Trade Page
      </DemoCell>

      <DemoCell
        onClick={() => hopper.push("campaign-buttons/1791834330026733568")}
      >
        Edit Buttons
      </DemoCell>
      <DemoCell onClick={() => hopper.push("user-upgrade-success/2")}>
        user-upgrade-success
      </DemoCell>

      <DemoCell onClick={() => hopper.push("monetary-value-demo")}>
        Monetary Value
      </DemoCell>

      <DemoCell
        onClick={() => hopper.push("circle/1851173206740570112/card-picker")}
      >
        Circle Membership Card Picker
      </DemoCell>

      <DemoCell onClick={() => hopper.layer("create-circle-basic")}>
        Create Circle
      </DemoCell>

      <DemoCell
        onClick={() => {
          hopper.push("nft-circle-card/1855163758036094976");
        }}
      >
        NFT Detail
      </DemoCell>

      <DemoCell onClick={() => hopper.push("")}>About</DemoCell>
    </Page>
  );
}
