import { StateId } from "./StateId";
import { useHopState } from "./useHopState";
import { Dispatch, SetStateAction, useMemo } from "react";
import BigNumber from "bignumber.js";
import { AlignUndefined } from "../utils/Nullable";
import {
  formatBigNumberForEdit,
  formatNumberForEdit,
  getCurrentDecimalSeparator,
  getCurrentGroupSeparator,
} from "../utils/NumberI18n";

function normalizeNumberText(text: string) {
  const groupSep = getCurrentGroupSeparator();
  const decSep = getCurrentDecimalSeparator();
  return text.split(groupSep).join("").replace(decSep, ".");
}

function parseBigNumber(text: string) {
  const bn = new BigNumber(normalizeNumberText(text));
  if (bn.isNaN()) return new BigNumber(0);
  else return bn;
}

function parseNumber(text: string) {
  const n = parseFloat(normalizeNumberText(text));
  if (isNaN(n)) return 0;
  else return n;
}

export function useEditingBigNumber<B extends BigNumber | undefined>(
  id: StateId,
  initialValue?: B,
): readonly [
  AlignUndefined<string, B>,
  Dispatch<SetStateAction<AlignUndefined<string, B>>>,
  B,
  (b: B) => void,
] {
  const [text, setText] = useHopState<AlignUndefined<string, B>>(id, () =>
    initialValue instanceof BigNumber
      ? formatBigNumberForEdit(initialValue)
      : (undefined as any),
  );

  const bigNumber = useMemo<B>(() => {
    return text !== undefined ? parseBigNumber(text) : (undefined as any);
  }, [text]);

  return [
    text,
    setText,
    bigNumber,
    (b) => {
      if (b instanceof BigNumber) setText(formatBigNumberForEdit(b));
      else setText(undefined as any);
    },
  ] as const;
}

export function useEditingNumber<N extends number | undefined>(
  id: StateId,
  initialValue?: N,
): readonly [
  AlignUndefined<string, N>,
  Dispatch<SetStateAction<AlignUndefined<string, N>>>,
  N,
  (n: N) => void,
] {
  const [text, setText] = useHopState<AlignUndefined<string, N>>(id, () =>
    typeof initialValue === "number"
      ? formatNumberForEdit(initialValue)
      : (undefined as any),
  );

  const n = useMemo<N>(() => {
    return text !== undefined ? parseNumber(text) : (undefined as any);
  }, [text]);

  return [
    text,
    setText,
    n,
    (n) => {
      if (typeof n === "number") setText(formatNumberForEdit(n));
      else setText(undefined as any);
    },
  ] as const;
}
