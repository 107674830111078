import { CircleWidgetsCellProps } from "./CircleWidgetCell";
import ViewPager, { PagedItem } from "../../components/ViewPager";
import MediumWidget from "../../components/MediumWidget";
import { Spacing } from "../../components/Spacing";
import SectionHeader, {
  SectionHeaderEndIcon,
} from "../../components/SectionHeader";
import React from "react";
import { Spin } from "../../components/Spin";
import { useLoadState } from "../../hooks/LoadState";
import { useBackend } from "../../service/APIService";
import { useHopper } from "../../hooks/useHopper";
import { resolvePath } from "../../components/CircleWidgetBanner";
import { useErrorHandler } from "../../components/handleError";

function CircleWidgetMediumCell({ widget, i18n }: CircleWidgetsCellProps) {
  const linkTarget = useLoadState();
  const handleError = useErrorHandler();
  const backend = useBackend();
  const hopper = useHopper();

  async function openInternalLink(link: string) {
    const r = await linkTarget.run(async () => {
      const linkModel = await backend.linkPathFrom(link).run();
      linkModel.path && resolvePath(linkModel.path, hopper);
    });

    if (!r.success) {
      handleError(r.error);
    } else {
      // hopper.back();
    }
  }

  const items =
    widget.widgetItemList?.map((widgetChildItem, index) => {
      const pagedItem: PagedItem = {
        index: index,
        title: widgetChildItem.title,
        page: <MediumWidget widgetItem={widgetChildItem} i18n={i18n} />,
      };
      return pagedItem;
    }) ?? [];

  return (
    <>
      <Spacing height={24} />
      {widget.extensions?.hideTitle !== true && (
        <div
          onClick={() => {
            const currentTargetLink = widget.targetLink;
            currentTargetLink && openInternalLink(currentTargetLink);
          }}
        >
          <SectionHeader
            title={widget.title ?? ""}
            endIcon={
              widget.targetLink != undefined
                ? SectionHeaderEndIcon.Chevron
                : SectionHeaderEndIcon.Edit
            }
          />
          <Spacing height={6} />
          <div
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              zIndex: -10,
              left: 0,
              top: 0,
            }}
          >
            <Spin state={linkTarget.state}>{}</Spin>
          </div>
        </div>
      )}
      <div style={{ marginInlineEnd: 0 }}>
        <ViewPager
          adapter={{ pagedItems: items }}
          showShoulder={false}
          slidesPerView={1.3} // 每次显示 1.2 个 slide
          centeredSlides={false} // 居中当前 slide
          spaceBetween={10} // slide 之间的间距
          showTabsLayout={false}
          preventInteractionOnTransition={true}
          nested={true}
        />
      </div>
    </>
  );
}

export default CircleWidgetMediumCell;
