export type UrlQuery =
  | { [key: string]: string | boolean | number | bigint | undefined | null }
  | undefined;

function makeQuery(obj: UrlQuery): string {
  if (!obj) return "";

  const str = Object.keys(obj)
    .map((key) => {
      const value = obj[key];
      if (value === undefined || value === null) {
        return null;
      } else {
        const compat = typeof value === "bigint" ? value.toString() : value;
        return `${key}=${encodeURIComponent(compat)}`;
      }
    })
    .filter((x) => x)
    .join("&");
  if (str.length > 0) {
    return "?" + str;
  } else {
    return "";
  }
}

export function urlAppendQuery(url: string, obj: UrlQuery): string {
  return `${url}${makeQuery(obj)}`;
}

export function urlParseQuery(urlStr: string): [string, UrlQuery | undefined] {
  const splitted = urlStr.split("?");
  if (splitted.length > 1) {
    const search = new URLSearchParams(splitted[1]);
    const query: UrlQuery = {};
    search.forEach((value, name) => {
      query[name] = value;
    });

    return [splitted[0], query];
  } else {
    return [urlStr, undefined];
  }
}
