import { DependencyList, useEffect, useState } from "react";
import { useChange } from "./ExtHooks";

export function useCountDown<T extends number | undefined>(
  calcRemainingSec: () => T,
  deps: DependencyList,
) {
  const [remainingSec, setRemainingSec] = useState(calcRemainingSec());
  useEffect(() => {
    let timer = setInterval(() => {
      const res = calcRemainingSec();
      setRemainingSec(res);

      if (res === undefined || res < 0) {
        clearInterval(timer);
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [...deps]);

  return remainingSec;
}

export function useTimeout(
  remainingSec: number | undefined,
  onTimeout: () => void,
) {
  return useChange(remainingSec, (prev, current) => {
    if (
      prev !== undefined &&
      prev > 0 &&
      (current === undefined || current <= 0)
    ) {
      onTimeout();
    }
  });
}
