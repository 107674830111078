export const BASE_URL: string | undefined = "https://api.spongekit.com";
export const WS_URL: string | undefined = "wss://api.spongekit.com"
export const SHUMEI_PROXY = "shumei.spongekit.com"
export const AppleId = "com.spongekit.token-web";
export const GoogleId =
  "732651974899-6d98r8lu1fftussfvj7vcuism8dd8hv4.apps.googleusercontent.com";

export const ASSOCIATED_DOMAIN_NAKED = "spongekit.com"

export const cora = {
  didV: "04",
  did: "1fc3a7ea0bf56f311289d7ee8f724c16a01d",
  bodyV: "04",
  body: "3d07976dee3186476bca9a7a281fe8ea1e9cef34",

};