import { useBackend } from "../../../service/APIService";
import { Page } from "../../../components/Page";
import { useSWR } from "../../../hooks/swr/useSWR";
import { useBridgeResultProvider } from "../../../hooks/useBridgeResult";
import { HSpace, HStack } from "../../../components/VStack";
import { cell_on_pressed } from "../../../components/CommonStyles";
import { GroupCellDivider } from "../../../components/ContentGroup";
import { useHopState } from "../../../hooks/useHopState";
import { Image } from "../../../components/Image";
import arrowDown from "../../../res/images/icon_arrow_down.svg";

export function TopUpThirdPartyMethodPickerPage() {
  const backend = useBackend();
  // usePageTitle((i18n) => i18n.waffo_select_payment_method());
  const countryCode = navigator.language.split("-")[1].toUpperCase();
  const methods = useSWR(backend.getSupportedThirdPartyPaymentMethods());

  const [expand, setExpand] = useHopState(
    "expand-all-third-party-methods",
    false,
  );

  const didPick = useBridgeResultProvider<number>();

  return (
    <Page pageData={methods}>
      {methods.content?.list &&
        methods.content.list
          .filter((method) => expand || method.isPopular)
          .map((method) => {
            return (
              <>
                <HStack
                  key={method.type}
                  style={{ height: 76, flexShrink: 0 }}
                  onClick={() => didPick(method.type)}
                  mixin={[cell_on_pressed]}
                >
                  <Image
                    src={[method.icon, 36]}
                    style={{ width: 36, height: 36, borderRadius: 18 }}
                  />
                  <HSpace width={12} />
                  <div
                    style={{ fontWeight: 700, fontSize: 16, color: "white" }}
                  >
                    {method.name}
                  </div>
                </HStack>
                <GroupCellDivider />
              </>
            );
          })}
      {!expand &&
        methods.content?.list &&
        methods.content.list.filter((method) => !method.isPopular).length >
          0 && (
          <HStack style={{ marginTop: 10 }} onClick={() => setExpand(true)}>
            <div
              style={{
                fontWeight: 300,
                fontSize: 12,
                color: "rgba(255, 255, 255, 0.8)",
              }}
            >
              Tap to view more payment methods
            </div>
            <Image src={arrowDown} style={{ paddingLeft: 4, paddingTop: 4 }} />
          </HStack>
        )}
    </Page>
  );
}
