import { LoadState } from "../hooks/LoadState";
import { PropsWithChildren } from "react";
import { ChasingCircle } from "./vscroll/ChasingCircle";

export function Spin(
  props: PropsWithChildren<{
    state: LoadState | undefined;
  }>,
) {
  return (
    <>
      {props.state?.kind === "loading" ? (
        <ChasingCircle
          radius={8}
          strokeWidth={2}
          color={"var(--color-text00)"}
        />
      ) : (
        props.children
      )}
    </>
  );
}
