import React from "react";
import { useHopper } from "../../hooks/useHopper";
import { useBackend } from "../../service/APIService";
import { Page } from "../../components/Page";
import { useSWRList } from "../../hooks/swr/useSWRList";
import GridLayout from "../../components/GridLayout";
import { User } from "../../proto/User";
import { VStack } from "../../components/VStack";
import { alpha_on_pressed } from "../../components/CommonStyles";
import { UserIconView } from "../../components/views/UserAvatarView";
import { UserNameView } from "../../components/views/UserNameView";
import { css } from "styled-components";
import { tagPageBackground } from "./TagsPage";
import { ObjectType } from "../../proto/ObjectSpec";

export interface TagObjectProps {
  tagId: string;
  tagName?: string;
  manualPageIndex?: (index: number) => void;
}

export function TagUserPage({ tagId, tagName }: TagObjectProps) {
  const backend = useBackend();
  const usersSWR = useSWRList(
    backend.tagObjects(ObjectType.USER, User, tagId, tagName),
  );

  return (
    <Page
      underlay={tagPageBackground}
      pageData={usersSWR}
      gridSpan={3}
      gridGap={10}
    >
      {usersSWR.content?.list &&
        usersSWR.content.list.map((user, index) => {
          return <TagResultUser user={user} key={user.uid} />;
        })}
    </Page>
  );
}

export function TagResultUser(props: { user: User }) {
  const hopper = useHopper();
  return (
    <VStack
      style={{ alignItems: "center", gap: "8px" }}
      mixin={alpha_on_pressed}
      onClick={() => {
        hopper.push(`user/${props.user.uid}`);
      }}
    >
      <UserIconView user={props.user} iconSize={46} />
      <UserNameView
        user={props.user}
        style={css`
          width: 100%;
        `}
      />
    </VStack>
  );
}
