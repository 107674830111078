import styled from "styled-components";
import {
  CenterChild,
  HStack,
  VSpace,
  VStack,
} from "../../../components/VStack";
import { Image } from "../../../components/Image";
import { useI18n } from "../../../hooks/useI18n";
import { Circle } from "../../../proto/Circle";
import React, { useMemo } from "react";
import { CircleMembershipLevelIcon } from "../membership/MembershipLevelPicker";
import {
  CurrencyType,
  formatMoney,
  getCurrencyIcon,
  getCurrencyName,
} from "../../../proto/Currency";
import { useResizeObserver } from "../../../hooks/useResizeObserver";
import { SingleLineUnspecifiedWidth } from "../../../components/CommonViews";
import { CircleMembershipCard } from "../../../proto/CircleMembershipCard";
import { NFT } from "../../../proto/NFT";
import Cover from "../../../res/images/cover_circle_card.png";
import Border from "../../../res/images/border_circle_card.svg";
import IcEdit from "../../../res/images/ic_edit.svg";

export enum CardStyle {
  verySmall,
  small,
  medium,
  large,
}

export interface CircleCardsPreviewCellProps {
  card?: CircleMembershipCard;
  nft?: NFT;
  cardStyle?: CardStyle;
  width?: number | string;
  circle?: Circle;
  includesPrice?: boolean;
  displayCurrencyType?: CurrencyType;
  borderRadius?: number;
  showEditIcon?: boolean;
  onClick?: () => void;
  rootStyle?: React.CSSProperties;
}

function CircleCardsPreviewCell(props: CircleCardsPreviewCellProps) {
  const i18n = useI18n();

  const [ref, size] = useResizeObserver();

  const scale = useMemo(() => {
    if (!size) {
      return 0;
    }
    const rect = size.target.getBoundingClientRect();
    if (typeof props.width === "number") {
      return props.width / 335;
    }

    return rect.width / 335;
  }, [size]);

  const targetImageSize = useMemo(() => {
    if (props.width && typeof props.width === "number") {
      return props.width;
    }
    if (!size) {
      return 60;
    }
    return size.target.getBoundingClientRect().width;
  }, [size, props.width]);

  const paymentCurrency = useMemo(() => {
    const currencyType = props.displayCurrencyType ?? CurrencyType.SOL;
    return (
      props.card?.priceList?.find(
        (currency) => currency.currencyType === currencyType,
      ) ?? props.card?.currency
    );
  }, [props.card, props.displayCurrencyType]);

  const mainMedia = useMemo(() => {
    if (props.card) {
      return props.card.background;
    }
    if (props.nft?.circleMembershipCard) {
      return props.nft.circleMembershipCard.background;
    }
    return props.nft?.entity?.preview;
  }, [props.card, props.nft]);
  const level = useMemo(() => {
    if (props.card) {
      return props.card.membershipLevel;
    }
    if (props.nft?.circleMembershipCard) {
      return props.nft.circleMembershipCard.membershipLevel;
    }
    return props.nft?.membershipLevel ?? 0;
  }, [props.card, props.nft]);
  const name = useMemo(() => {
    if (props.card) {
      return props.card.name;
    }
    if (props.nft?.circleMembershipCard) {
      return props.nft.circleMembershipCard.name;
    }
    return props.nft?.name;
  }, [props.card, props.nft]);

  return (
    <Root
      ref={ref}
      style={{
        width: !!props.width ? props.width : undefined,
        flexGrow: !!props.width ? undefined : 10,
        ...props.rootStyle,
      }}
    >
      <Container
        onClick={(e) => {
          if (props.onClick) {
            e.stopPropagation();
            props.onClick();
          }
        }}
        style={{
          borderRadius: props.borderRadius ?? Math.max(2, 12 * scale),
        }}
      >
        <Image
          src={[mainMedia, targetImageSize]}
          width={"100%"}
          height={"100%"}
        />

        <div style={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }}></div>
        <Image src={Cover} style={{ objectFit: "fill" }} />
        <Image src={Border} style={{ objectFit: "fill" }} />
        {props.circle && props.cardStyle !== CardStyle.verySmall && (
          <AbsContainer>
            <HStack
              style={{
                position: "absolute",
                justifyContent: "space-between",
                maxWidth: "50%",
                gap: `${8 * scale}px`,
                top: `${20 * scale}px`,
                right: `${20 * scale}px`,
              }}
            >
              <Image
                src={[props.circle?.circleIcon, "smallest"]}
                alt={"circleIcon"}
                style={{
                  borderRadius: "16%",
                  border: `1px solid ${props.circle?.themeColor}`,
                }}
                width={`${32 * scale}px`}
                height={`${32 * scale}px`}
              />
              {props.cardStyle === CardStyle.large && (
                <VStack
                  style={{
                    flex: 1,
                    alignItems: "start",
                    marginInlineEnd: `${4 * scale}px`,
                    gap: `${2 * scale}px`,
                  }}
                >
                  <MiniCircleName style={{ fontSize: `${14 * scale}px` }}>
                    {props.circle.name}
                  </MiniCircleName>
                  <MiniCircleId style={{ fontSize: `${10 * scale}px` }}>
                    {`c/${props.circle?.socialId}`}
                  </MiniCircleId>
                </VStack>
              )}
            </HStack>
          </AbsContainer>
        )}

        <AbsContainer>
          <VStack
            style={{
              left: `${12 * scale}px`,
              position: "absolute",
              alignItems: "start",
              bottom: `${12 * scale}px`,
            }}
          >
            {props.cardStyle !== CardStyle.verySmall && (
              <CircleName style={{ fontSize: `${Math.max(8, 24 * scale)}px` }}>
                {name}
              </CircleName>
            )}

            <CircleMembershipLevelIcon
              level={level}
              width={`${Math.max(12, 42 * scale)}px`}
              adminSize={`${42 * scale * 2.5}px`}
            />
          </VStack>
        </AbsContainer>
      </Container>

      {props.includesPrice && paymentCurrency && (
        <>
          <VSpace height={12} />
          <HStack style={{ gap: 4 }}>
            <Image
              width={20}
              height={20}
              src={getCurrencyIcon(paymentCurrency)}
            ></Image>
            <PriceValue>{formatMoney("medium", paymentCurrency)}</PriceValue>
            <CurrencyName>{getCurrencyName(paymentCurrency)}</CurrencyName>
          </HStack>
          <VSpace height={6} />
          <PriceLabel>{i18n.clover_membership_card_price()}</PriceLabel>
        </>
      )}

      {props.showEditIcon && (
        <CenterChild
          style={{
            position: "absolute",
            bottom: 0,
            objectFit: "contain",
            borderTopLeftRadius: 8,
            borderBottomRightRadius: 12,
            padding: 6,
            right: 0,
            backgroundColor: "rgba(17,17,17,0.8)",
          }}
        >
          <HStack style={{ gap: 4 }}>
            <Image src={IcEdit} width={12} height={12} />
            <EditLabel>{i18n.edit()}</EditLabel>
          </HStack>
        </CenterChild>
      )}
    </Root>
  );
}

const MiniCircleName = styled.div`
  color: var(--color-text00);
  ${SingleLineUnspecifiedWidth};
  font-weight: 500;
  font-size: 14px;
`;

const EditLabel = styled.div`
  color: var(--color-text00);
  ${SingleLineUnspecifiedWidth};
  font-weight: 300;
  font-size: 12px;
`;

const MiniCircleId = styled.div`
  color: var(--color-text10);
  ${SingleLineUnspecifiedWidth};
  font-weight: 400;
  font-size: 10px;
`;

const AbsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const BottomOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 1px;
  background: linear-gradient(
    to bottom,
    rgba(17, 17, 17, 0.8),
    rgba(17, 17, 17, 0.3),
    rgba(17, 17, 17, 0.8)
  ); /* 顶部黑色到底部透明的渐变 */
  pointer-events: none; /* 让渐变层不阻挡点击事件 */
`;

const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  border-radius: 12px;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  overflow: hidden;

  & > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const CircleName = styled.div`
  color: var(--color-text00);
  font-size: 18px;
  font-weight: 500;

  flex-shrink: 1;
  text-overflow: ellipsis;
  word-break: break-word;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
`;

const PriceLabel = styled.div`
  color: var(--color-text20);
  font-size: 12px;
  font-weight: 500;
`;

const PriceValue = styled.div`
  color: var(--color-text00);
  font-size: 18px;
  font-weight: 500;
`;

const CurrencyName = styled.div`
  color: var(--color-text20);
  font-size: 14px;
  font-weight: 500;
`;

export default CircleCardsPreviewCell;
