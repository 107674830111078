import { Page } from "../../../components/Page";
import { useBigIntParam } from "../../../hooks/useTypedParam";
import SettingCell from "../../../components/SettingCell";
import { useSWR } from "../../../hooks/swr/useSWR";
import { Spacing } from "../../../components/Spacing";
import { PlaceholderButton } from "../../../components/Buttons";
import { Image } from "../../../components/Image";
import ic_add from "../../../res/images/ic_add.svg";
import React from "react";
import { useI18n } from "../../../hooks/useI18n";
import CircleBackgroundFrame from "../CircleBackgroundFrame";
import ColoredLabel from "../../../components/ColoredLabel";
import { useBackend } from "../../../service/APIService";
import { NavMiddle } from "../../../components/NavBar";
import { useHopper } from "../../../hooks/useHopper";
import styled from "styled-components";
import icMore from "../../../res/images/ic_wallet_more.svg";

function CircleMemberTitlesPage() {
  const circleId = useBigIntParam("circleId");
  const backend = useBackend();
  const circleSWR = useSWR(backend.getCircle(circleId));
  const i18n = useI18n();
  const memberTitlesSWR = useSWR(backend.getCircleMemberTitles(circleId));
  const hopper = useHopper();
  return (
    <Page
      pageData={[circleSWR, memberTitlesSWR]}
      scrollPaddingDisabled={true}
      underlay={
        <CircleBackgroundFrame
          circleBackground={circleSWR.content?.circleBackground}
        />
      }
    >
      <NavMiddle>{i18n.member_title_circle_titles()}</NavMiddle>
      {memberTitlesSWR.content?.list.map((memberTitle, index) => {
        const isLastItem =
          index === (memberTitlesSWR.content?.list?.length ?? 0) - 1; // 判断是否是最后一个元素
        return (
          <SettingCell
            titleValue={
              <ColoredLabel
                backgroundColor={memberTitle.color}
                style={{
                  padding: "4px 8px",
                  fontSize: 13,
                  fontWeight: 500,
                }}
              >
                {memberTitle.title}
              </ColoredLabel>
            }
            showDivider={!isLastItem}
            showAccessoryImage={false}
            endValue={
              <MenuButton src={icMore} onClick={() => hopper.modal("nyi")} />
            }
          />
        );
      })}
      <Spacing height={30} />
      <PlaceholderButton
        style={{ marginInlineStart: 30, marginInlineEnd: 30 }}
        onClick={() => hopper.modal("nyi")}
      >
        <Image src={ic_add} alt={"reveal"} />
        {i18n.member_title_add_new_title()}
      </PlaceholderButton>
      <Spacing height={30} />
    </Page>
  );
}

const MenuButton = styled.img`
  width: 26px;
  height: 26px;
`;

export default CircleMemberTitlesPage;
