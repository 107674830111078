import { z } from "zod";
import { zBigInt, zEnum, zStatic } from "../utils/zodUtils";

export enum TitleType {
  admin = 1,
  coAdmin = 2,
  // curator = 3,
  // welcomeTeam = 4,
  customized = 100,
}

export const CircleMemberTitle = z.object({
  titleId: zBigInt,
  circleId: zBigInt,
  color: z.string(),
  title: z.string(),
  visible: z.boolean().optional(),
  myVisibility: z.boolean().optional(),
  type: zEnum(TitleType),
});
export type CircleMemberTitle = zStatic<typeof CircleMemberTitle>;
