import { z } from "zod";
import { zBigInt, zEnum, zStatic } from "../utils/zodUtils";
import { Currency } from "./Currency";
import { User } from "./User";
import { GiftBox } from "./GiftBox";
import { UserTaskType } from "./Order";
import { Coupon } from "./Coupon";
import { NFT } from "./NFT";

export const TransferOrderExtensions = z.object({
  giftBoxId: zBigInt.optional(),
  giftBoxServiceFee: z.string().optional(),
  userTaskType: zEnum(UserTaskType).default(UserTaskType.Unknown),
  transferFeeRate: z.number().optional(),
  sourceType: z.number().optional(),
});

export const TransferOrder = z.object({
  orderId: zBigInt.default(0),
  orderStatus: z.number().optional(),
  currencyType: z.number().optional(),
  orderType: z.number().optional(),
  nft: NFT.optional(),
  currency: Currency.optional(),
  claimedTime: z.number().optional(),
  fromUid: zBigInt.optional(),
  toUid: zBigInt.optional(),
  minClaimedTime: z.number().optional(),
  createdTime: z.number().default(0),
  fromUser: User.optional(),
  toUser: User.optional(),
  returnTime: z.number().optional(),
  realAmount: z.string().optional(),
  expiredTimeV2: z.number().optional(),
  coupon: Coupon.optional(),
  giftBox: GiftBox.optional(),
  extensions: TransferOrderExtensions.optional(),
});

export type TransferOrder = zStatic<typeof TransferOrder>;

export enum TransferOrderType {
  UserTransfer = 1,
  AirdropBase = 2,
  AirdropBonus = 3,
  AirdropAdvanced = 4,
  AirdropAdvancedDays = 5,
  AirdropCheckInRewards = 6,
  SendGiftBox = 7,
  ClaimedGiftBox = 8,
  NewUserTask = 9,
  TransferTypeKOLAirdropBase = 10,
  TransferTypeKOLAirdropBonus = 11,
  TransferTypeRecallCompensation = 12,
  TransferTypeRecallCustomize = 13,
  TransferTypeSystemWidgetReward = 14,
  AdsRewardTask = 15,
  UserTaskRewardV2 = 17,
  ThirdPartyPurchase = 18,
}

export enum TransferOrderStatus {
  PENDING = 1,
  RETURN = 3,
  DONE = 4,
  WAITING_TO_START = -1, // client only
}

export enum SourceType {
  MonthlyGift = 1,
}

export function isAirdrop(orderType?: TransferOrderType): Boolean {
  return (
    orderType === TransferOrderType.AirdropBase ||
    orderType === TransferOrderType.AirdropBonus ||
    orderType === TransferOrderType.AirdropAdvanced ||
    orderType === TransferOrderType.AirdropAdvancedDays ||
    orderType === TransferOrderType.AirdropCheckInRewards ||
    orderType === TransferOrderType.NewUserTask ||
    orderType === TransferOrderType.TransferTypeKOLAirdropBase ||
    orderType === TransferOrderType.TransferTypeKOLAirdropBonus ||
    orderType === TransferOrderType.TransferTypeRecallCompensation ||
    orderType === TransferOrderType.TransferTypeRecallCustomize ||
    orderType === TransferOrderType.AdsRewardTask
  );
}
