import { getBestRes, isImage, isVideo, Media } from "../../proto/Media";
import styled, { css, RuleSet } from "styled-components";
import { ModalContext } from "../../components/ModalContext";
import { removeOptional } from "../../utils/typeUtils";
import { HStack, HStackMixin } from "../../components/VStack";
import React, { useEffect, useRef, useState } from "react";
import {
  Animation,
  ModalContainer,
  ModalController,
  ModalDimmer,
  requireModalController,
  useFreezeNavBar,
  useModal,
} from "../../components/Modal";
import { AbsImage } from "../../components/AbsImage";
import close from "../../res/images/ic_modal_close.svg";
import { FadeIn, None, ShrinkFadeOut } from "../../components/Keyframes";
import { Swiper, SwiperSlide } from "swiper/react";
import { Image } from "../../components/Image";
import iconVideoPlay from "../../res/images/ic_video_play.svg";
import iconVideoPause from "../../res/images/ic_video_pause.svg";
import { andLog } from "../../components/handleError";
import { SwiperClass } from "swiper/swiper-react";
import { useBackend } from "../../service/APIService";
import { useSWR } from "../../hooks/swr/useSWR";
import { useMyUid } from "../../service/AuthSessionService";
import { useNSFW } from "../../hooks/useNSFW";
import { User } from "../../proto/User";
import iconMenu from "../../res/images/ic_abar_more.svg";
import { BSMenu, BSMenuItem } from "../../components/BSMenu";
import { useI18n } from "../../hooks/useI18n";
import { useHopper } from "../../hooks/useHopper";
import { MediaOwner } from "../../proto/MediaOwner";
import { JSONUtil } from "../../utils/JSONUtil";

export function MediaViewer(props: {
  mediaList?: Media[];
  index?: number;
  modal: ModalController;
  mediaOwnerMap?: Map<bigint, MediaOwner>;
  onBackPressed?: () => void;
}) {
  requireModalController(props.modal);
  useFreezeNavBar(props.modal, props.onBackPressed);
  const backend = useBackend();
  const myUid = useMyUid();
  const userSWR = useSWR(backend.getUser(myUid));
  const [toolBarShow, setToolBarShow] = useState(true);
  const nsfwContext = useNSFW();
  const i18n = useI18n();

  const mediaShowList = props.mediaList?.filter(
    (media) => isImage(media) || isVideo(media),
  );
  const [currentIndex, setCurrentIndex] = useState<number>(props.index || 0);

  const menu = useModal("media-viewer-menu");
  const hopper = useHopper();

  return props.modal.mounted ? (
    <ModalContainer id={props.modal.id} modal={props.modal}>
      <ModalContext.Provider value={{ modal: props.modal }}>
        <ModalDimmer
          onClick={() => props.modal.close ?? removeOptional(props.modal.close)}
          $animation={props.modal.animation}
        />

        <MediaViewBackground
          onClick={(e) => {
            e.stopPropagation();
          }}
          $animation={props.modal.animation}
          onAnimationEnd={props.modal.onAnimationEnd}
        >
          <Swiper
            initialSlide={props.index || 0}
            style={{ width: "100%", height: "100%" }}
            onSlideChange={(swiper: SwiperClass) => {
              setToolBarShow(true);
              setCurrentIndex(swiper.realIndex);
            }}
          >
            {mediaShowList?.map((media, index) => (
              <SwiperSlide key={`media-viewer-${index}`}>
                <MediaItem
                  media={media}
                  toolBarShow={toolBarShow}
                  setToolBarShow={setToolBarShow}
                  myUser={userSWR.content}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          {toolBarShow && (
            <AbsImage
              src={close}
              style={{ top: 12, left: 12, zIndex: 1 }}
              onClick={props.onBackPressed ?? removeOptional(props.modal.close)}
            />
          )}
          {toolBarShow && (
            <AbsImage
              src={iconMenu}
              style={{ top: 12, right: 12, zIndex: 1 }}
              onClick={() => menu.open()}
            />
          )}
        </MediaViewBackground>
        <BSMenu modal={menu}>
          <BSMenuItem
            title={i18n.flag()}
            onClick={() => {
              const currentOwner = props.mediaOwnerMap?.get(
                mediaShowList?.at(currentIndex)?.mediaId || BigInt(0),
              );
              const objectSpec = currentOwner?.ownerSpec;
              props.modal.close(() =>
                hopper.layer("flag", {
                  objectId: objectSpec?.objectId,
                  objectType: objectSpec?.objectType,
                  extensions: JSONUtil.stringify(currentOwner?.flagExt),
                }),
              );
            }}
          />
        </BSMenu>
      </ModalContext.Provider>
    </ModalContainer>
  ) : (
    <></>
  );
}

const MediaViewBackground = styled.div<{ $animation: Animation }>`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: black;

  animation: ${(p) =>
      p.$animation === Animation.None
        ? None
        : p.$animation === Animation.In
          ? FadeIn
          : ShrinkFadeOut}
    0.3s ease-out 1 forwards;

  animation-fill-mode: both;
`;

function MediaItem(props: {
  media: Media;
  setToolBarShow: (value: boolean) => void;
  toolBarShow: boolean;
  myUser?: User;
}) {
  return (
    <ItemContainer>
      {
        //   props.media.nsfw &&
        // !props.myUser?.extensions?.showNSFWContent &&
        // !props.nsfwContext?.get.has(props.media.mediaId) ? (
        //   <RectNSFW
        //     mediaId={props.media.mediaId}
        //     roundedCorner={false}
        //     nsfwContext={props.nsfwContext}
        //   />
        // ) :
        isImage(props.media) ? (
          <Image src={[props.media, "best"]} style={{ width: "100%" }} />
        ) : isVideo(props.media) ? (
          <VideoPlayer
            media={props.media}
            setToolBarShow={props.setToolBarShow}
            toolBarShow={props.toolBarShow}
          />
        ) : null
      }
    </ItemContainer>
  );
}

const ItemContainer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
`;

function VideoPlayer(props: {
  media: Media;
  setToolBarShow: (value: boolean) => void;
  toolBarShow: boolean;
}) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const rootRef = useRef<HTMLDivElement>(null);

  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [targetTime, setTargetTime] = useState(0);
  const [duration, setDuration] = useState(0);

  async function onPlayButtonClick() {
    try {
      if (videoRef.current) {
        if (isPlaying) {
          videoRef.current.pause();
        } else {
          await videoRef.current.play();
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.currentTime = targetTime;
    }
  }, [targetTime]);

  useEffect(() => {
    if (videoRef.current) {
      if (videoRef.current.getBoundingClientRect().right < 0) {
        videoRef.current.pause();
      }
    }
  }, [videoRef.current?.getBoundingClientRect().right]);

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.intersectionRatio === 0) {
        videoRef.current?.pause();
      }
    });
  });

  if (rootRef.current) {
    observer.observe(rootRef.current);
  }

  return (
    <VideoContainer ref={rootRef}>
      <video
        ref={videoRef}
        src={getBestRes(props.media).url}
        style={{
          height: window.innerHeight,
          width: window.innerWidth,
        }}
        onLoadedMetadata={() => setDuration(videoRef.current?.duration || 0)}
        onTimeUpdate={() => setCurrentTime(videoRef.current?.currentTime || 0)}
        onPlaying={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
        onEnded={() => setIsPlaying(false)}
      />
      <VideoControlPanel
        style={{ background: props.toolBarShow ? "#00000066" : "transparent" }}
        onClick={() => {
          props.setToolBarShow(!props.toolBarShow);
        }}
      >
        {props.toolBarShow && (
          <img
            src={isPlaying ? iconVideoPause : iconVideoPlay}
            style={{
              position: "absolute",
              width: 70,
              height: 70,
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            onClick={(event) => {
              event.stopPropagation();
              onPlayButtonClick().catch(andLog);
            }}
          />
        )}

        <ControlBar
          currentValue={currentTime}
          totalValue={duration}
          style={ControlBarStyle}
          setCurrentValue={setTargetTime}
          shouldShow={props.toolBarShow}
        />
      </VideoControlPanel>
    </VideoContainer>
  );
}

const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
`;

const VideoControlPanel = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const ControlBarStyle = css`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  padding: 0 10px;
`;

function ControlBar(props: {
  currentValue: number;
  totalValue: number;
  style?: RuleSet<Object>;
  shouldShow: boolean;
  setCurrentValue: (value: number) => void;
}) {
  const progressRef = useRef<HTMLDivElement>(null);
  const progressBackgroundRef = useRef<HTMLDivElement>(null);
  const controlButtonRef = useRef<HTMLDivElement>(null);
  const [initX, setInitX] = useState<number>();
  return (
    <HStack
      style={{
        width: "100%",
        gap: 9,
        opacity: props.shouldShow ? 1 : 0,
      }}
      mixin={props.style}
    >
      <TimeLabel>{formatSecondsToMMSS(props.currentValue)}</TimeLabel>
      <ProgressContainer>
        <ProgressBackground ref={progressBackgroundRef} />
        <Progress
          ref={progressRef}
          style={{
            width:
              ((progressBackgroundRef.current?.clientWidth || 0) *
                props.currentValue) /
              props.totalValue,
          }}
        />
        <ProgressControlButton
          ref={controlButtonRef}
          style={{
            left:
              ((progressBackgroundRef.current?.clientWidth || 0) *
                props.currentValue) /
              props.totalValue,
          }}
          onTouchStart={() => {
            setInitX(
              (controlButtonRef.current?.getBoundingClientRect().left || 0) +
                (controlButtonRef.current?.clientWidth || 0) / 2,
            );
          }}
          onTouchMove={(event) => {
            event.stopPropagation();

            const targetValue =
              ((event.touches[0].clientX -
                (progressBackgroundRef.current?.getBoundingClientRect().left ||
                  0)) *
                props.totalValue) /
              (progressBackgroundRef.current?.clientWidth || 1);
            const limitedTargetValue =
              targetValue < 0
                ? 0
                : targetValue > props.totalValue
                  ? props.totalValue
                  : targetValue;

            if (controlButtonRef.current && progressRef.current) {
              const targetLeft =
                event.touches[0].clientX -
                (progressBackgroundRef.current?.getBoundingClientRect().left ||
                  0);

              const limitedLeft =
                targetLeft < 0
                  ? 0
                  : targetLeft >
                      (progressBackgroundRef.current?.clientWidth || 0)
                    ? progressBackgroundRef.current?.clientWidth || 0
                    : targetLeft;

              controlButtonRef.current.style.left = `${limitedLeft}px`;
              progressRef.current.style.width = `${limitedLeft}px`;
            }

            props.setCurrentValue(limitedTargetValue);
          }}
          onMouseUp={() => {}}
        />
      </ProgressContainer>
      <TimeLabel>{formatSecondsToMMSS(props.totalValue)}</TimeLabel>
    </HStack>
  );
}

function formatSecondsToMMSS(seconds: number) {
  const minutes = (seconds / 60).toFixed(0);
  const secs = (seconds % 60).toFixed(0);
  return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
}

const TimeLabel = styled.div`
  width: 50px;
  font-size: 15px;
  font-weight: 400;
  color: white;
  text-align: center;
`;
const ProgressContainer = styled.div`
  flex: 1;
  position: relative;
  height: 20px;
  padding: 0 10px;
  ${HStackMixin};
`;

const ProgressBackground = styled.div`
  flex: 1;
  background-color: #ffffff99;
  border-radius: 7px;
  height: 4px;
`;

const Progress = styled.div`
  position: absolute;
  background-color: white;
  border-radius: 7px;
  height: 4px;
`;

const ProgressControlButton = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: white;
`;
