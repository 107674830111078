import { usePageSpec } from "../common/usePageSpec";
import { Image } from "../../components/Image";
import React from "react";
import { FrameContainer, Overlay } from "../circle/CircleBackgroundFrame";
import { Media } from "../../proto/Media";

export interface UserBackgroundProps {
  background?: Media;
  backgroundColor?: string;
}

function UserBackgroundFrame({
  background,
  backgroundColor,
}: UserBackgroundProps) {
  const pageSpec = usePageSpec();
  return (
    <FrameContainer
      style={{
        height: "100%",
      }}
    >
      {background ? (
        <Image
          src={[background, "best"]}
          style={{ width: "100%", height: "100%" }}
        />
      ) : (
        <div
          style={{
            background: `${backgroundColor}`,
            width: "100%",
            height: "100%",
          }}
        />
      )}

      <Overlay />
    </FrameContainer>
  );
}

export default UserBackgroundFrame;
