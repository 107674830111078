import { Page } from "../../components/Page";
import { NavEnd, NavItem, NavMiddle } from "../../components/NavBar";
import { useI18n } from "../../hooks/useI18n";
import { useSWR } from "../../hooks/swr/useSWR";
import { useBackend } from "../../service/APIService";
import React, { useEffect, useMemo } from "react";
import GridLayout from "../../components/GridLayout";
import iconChart from "../../res/images/ic_cart.png";
import iconGiftingHistory from "../../res/images/ic_gifthistory.png";
import icSendGift from "../../res/images/ic_send_gift.png";
import WalletHeaderOperation from "./WalletHeaderOperation";
import WalletAccountCell from "./WalletAccountCell";
import { useHopper } from "../../hooks/useHopper";
import { useModal } from "../../components/Modal";
import iconCollectibles from "../../res/images/icon_collectibles.png";
import { NavButtonType } from "../../bridge/NavButtonDesc";
import { BSMenu, BSMenuItem } from "../../components/BSMenu";
import WalletBannerCell, {
  WalletBannerCellProps,
  WalletBannerType,
} from "./WalletBannerCell";
import ShopAccountCell from "./ShopAccountCell";
import { useGlobalSpinner } from "../../utils/globalSpinner";
import { useRegularAccount } from "./useRegularAccount";
import { OrderType } from "./order/OrderDetailPage";
import iconGems from "../../res/images/icon_gems.svg";
import {
  CurrencyId,
  CurrencyType,
  getCurrencyNameByCurrencyType,
} from "../../proto/Currency";
import { andLog } from "../../components/handleError";
import { useWebHost } from "../../hooks/useBridge";
import { useHopState } from "../../hooks/useHopState";
import { BoolNumber } from "../../proto/Wallet";

function WalletHomePage() {
  const i18n = useI18n();
  const backend = useBackend();
  const walletSWR = useSWR(backend.getWallet(), {
    reloadPolicy: "alwaysRefetch",
  });

  const regularAccount = useRegularAccount();

  const rewardedInfoSWR = useSWR(backend.getRewardedInfo());
  const shopAccount = walletSWR.content?.storeAccount;
  const webHost = useWebHost();
  const hopper = useHopper();
  const moreMenu = useModal("wallet-home-menu");
  const giftTypeMenu = useModal("gift-type-menu");

  const walletAccountId = regularAccount?.walletAccountId;
  const assetAccountsSWR = useSWR(
    walletAccountId ? backend.getAssetAccounts(walletAccountId) : undefined,
  );
  const assetAccountId = useMemo(() => {
    const validAssetAccount = assetAccountsSWR?.content?.list?.find(
      (account) => account.address.length > 0,
    );
    return validAssetAccount?.accountId ?? BigInt(0);
  }, [assetAccountsSWR?.content]);
  const method = useSWR(backend.getSupportedThirdPartyPaymentMethods());

  const globalSpinner = useGlobalSpinner();
  const markAsRead = async (type: WalletBannerType) => {
    try {
      await globalSpinner(async () => {
        await backend
          .markOrderAsRead({
            redPointType: type,
          })
          .run();
        await walletSWR.load();
        webHost.broadcast("ON_GIFT_READ", {}).catch(andLog);
        if (type === WalletBannerType.Transfer) {
          hopper.push(`wallet/gifting-history`);
        } else {
          hopper.push(`wallet/other-orders`);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const bannerOrders = useMemo(() => {
    const ret: WalletBannerCellProps[] = [];
    const wallet = walletSWR.content;
    if (wallet) {
      if (wallet.latestTopUpOrder && wallet.unreadTopUpOrderCount > 0) {
        // if (wallet.latestTopUpOrder) {
        ret.push({
          order: wallet.latestTopUpOrder,
          count: wallet.unreadTopUpOrderCount,
          type: WalletBannerType.TopUp,
          onClickBanner: markAsRead,
        });
      }

      if (wallet.latestTransferOrder && wallet.unreadTransferOrderCount > 0) {
        // if (wallet.latestTransferOrder) {
        ret.push({
          order: wallet.latestTransferOrder,
          count: wallet.unreadTransferOrderCount,
          type: WalletBannerType.Transfer,
          onClickBanner: markAsRead,
        });
      }

      if (wallet.latestUserMintOrder && wallet.unreadUserMintOrderCount > 0) {
        // if (wallet.latestUserMintOrder) {
        ret.push({
          order: wallet.latestUserMintOrder,
          count: wallet.unreadUserMintOrderCount,
          type: WalletBannerType.UserMint,
          onClickBanner: markAsRead,
        });
      }
    }

    return ret;
  }, [walletSWR.content]);

  const sendGift = (currencyId: bigint, currencyType: CurrencyType) => {
    hopper.layer(
      `wallet/${walletAccountId}/assetAccount/${assetAccountId}/send-gift`,
      {
        currencyId: currencyId,
        currencyType: currencyType,
      },
    );
  };

  return (
    <Page pageData={[walletSWR, rewardedInfoSWR]}>
      <NavMiddle>{i18n.my_wallet()}</NavMiddle>
      <NavEnd>
        {NavItem.image(NavButtonType.More, () => {
          moreMenu.open();
        })}
      </NavEnd>
      {bannerOrders.map((props) => (
        <WalletBannerCell
          order={props.order}
          count={props.count}
          type={props.type}
          onClickBanner={props.onClickBanner}
        />
      ))}
      <GridLayout
        style={{ paddingTop: 0, marginBottom: 20 }}
        spanCount={3}
        items={[
          <WalletHeaderOperation
            icon={iconChart}
            title={i18n.web3_v0_purchase_orders()}
            onClick={() => hopper.push("wallet/marketplace-orders")}
          />,
          <WalletHeaderOperation
            icon={iconGiftingHistory}
            title={i18n.web3_v0_gifting_history()}
            onClick={() => hopper.push("wallet/gifting-history")}
          />,
          <WalletHeaderOperation
            icon={icSendGift}
            title={i18n.web3_hongbao_send_gift_without_the()}
            onClick={() => {
              giftTypeMenu.open();
            }}
          />,
        ]}
      />

      {regularAccount && (
        <WalletAccountCell
          key={regularAccount.walletAccountId}
          walletAccount={regularAccount}
          supportThirdParty={
            walletSWR.content?.thirdPartyIapStatus === BoolNumber.On &&
            (method.content?.list?.length ?? 0) > 0
          }
        />
      )}

      {/*{shopAccount && (*/}
      {/*  <ShopAccountCell*/}
      {/*    key={shopAccount.accountId}*/}
      {/*    walletAccount={shopAccount}*/}
      {/*  />*/}
      {/*)}*/}

      <BSMenu modal={moreMenu}>
        <BSMenuItem
          title={i18n.web3_v0_purchase_orders()}
          onClick={() => hopper.push("wallet/marketplace-orders")}
        />
        <BSMenuItem
          title={i18n.web3_v0_gifting_history()}
          onClick={() => hopper.push("wallet/gifting-history")}
        />
        <BSMenuItem
          title={i18n.web3_hongbao_send_gift_without_the()}
          onClick={() => {
            giftTypeMenu.open();
          }}
        />
        <BSMenuItem
          title={i18n.web3_v0_other_orders()}
          onClick={() => hopper.push("wallet/other-orders")}
        />
      </BSMenu>

      <BSMenu modal={giftTypeMenu}>
        <BSMenuItem
          icon={iconGems}
          iconStyle={{ width: 48, height: 48 }}
          title={getCurrencyNameByCurrencyType(CurrencyType.GEMS, i18n)}
          titleStyle={{ fontSize: 16, fontWeight: 700 }}
          onClick={() => {
            giftTypeMenu.close();
            sendGift(BigInt(CurrencyId.GEMS.toString()), CurrencyType.GEMS);
          }}
        />

        {/*<BSMenuItem*/}
        {/*  icon={iconCollectibles}*/}
        {/*  iconStyle={{ width: 48, height: 48 }}*/}
        {/*  title={i18n.web3_v0_collectibles()}*/}
        {/*  titleStyle={{ fontSize: 16, fontWeight: 700 }}*/}
        {/*  onClick={() => {*/}
        {/*    sendGift(BigInt(0), CurrencyType.NFT);*/}
        {/*  }}*/}
        {/*/>*/}
      </BSMenu>
    </Page>
  );
}

export default WalletHomePage;
