import { HStack, VSpace, VStack } from "../../../components/VStack";
import { Image } from "../../../components/Image";
import {
  TransactionAmountType,
  TransactionLog,
  TransactionReason,
} from "../../../proto/TransactionLog";
import styled from "styled-components";
import {
  getTransactionIcon,
  TransactionStatus,
  transactionTitle,
} from "../utils/Transaction";
import { I18n, useI18n } from "../../../hooks/useI18n";
import {
  CurrencyType,
  formatMoney,
  getCurrencyIcon,
  getCurrencyName,
} from "../../../proto/Currency";
import { CellDivider } from "../../../components/ContentGroup";
import { useHopper } from "../../../hooks/useHopper";
import { useLocalRepo } from "../../../hooks/swr/useLocalRepo";
import { TokenTransactionType } from "../../../proto/DexSwapInfo";
import { TxCategory } from "../../../proto/AssetOrder";
import { StatusBar } from "../order/GiftOrderListPage";
import icMembershipCard from "../../../res/images/ic_transaction_membership_card.png";
import { NFT } from "../../../proto/NFT";
import { getOrderFormattedDateTime } from "../order/CreateNewTokenDetail";
import CircleCardsPreviewCell, {
  CardStyle,
} from "../../circle/card/CircleCardsPreviewCell";
import iconProto from "../../../res/images/ic_transaction_proto_nft.png";

export interface TransactionProps {
  transaction: TransactionLog;
  accountId: bigint;
}

function TransactionCell(props: TransactionProps) {
  const i18n = useI18n();
  const hopper = useHopper();
  const transactionRepo = useLocalRepo("transactionDetail");

  const isNFT = props.transaction.currency?.currencyType === CurrencyType.NFT;
  const isCoupon =
    props.transaction.currency?.currencyType === CurrencyType.COUPON;
  const operation = isNFT
    ? "×"
    : props.transaction.txAmountType == TransactionAmountType.ADD
      ? "+"
      : "-";

  function getStatus() {
    switch (props.transaction.txStatus) {
      case TransactionStatus.DONE:
        return { text: i18n.web3_v0_completed(), color: "#00E25BFF" };
      case TransactionStatus.PENDING:
      case TransactionStatus.SUBMITTED:
        return { text: i18n.processing(), color: "#FFB332FF" };
      default:
        return { text: i18n.circle_anmt_failed(), color: "#EF3537FF" };
    }
  }

  const transactionContent = () => {
    switch (props.transaction.txReason) {
      case TransactionReason.TRADE_FROM:
      case TransactionReason.TRADE_TO: {
        return getTradeDescription(props.transaction, i18n);
      }
    }
  };

  const goToDetail = async () => {
    try {
      await transactionRepo.fill(props.transaction);
      hopper.push(
        `wallet/${props.accountId}/transaction-history/${props.transaction.txId}`,
      );
    } catch (e) {
      console.error(e);
    }
  };

  const isMembershipCard =
    isNFT &&
    (props.transaction.txReason === TransactionReason.GET_MEMBERSHIP_CARD ||
      props.transaction.txReason === TransactionReason.REDEEM_MEMBERSHIP_CARD);
  return (
    <VStack style={{ width: "100%" }} onClick={goToDetail}>
      <HStack style={{ width: "100%", paddingTop: 19, alignItems: "center" }}>
        <Image
          src={getTransactionIcon(props.transaction)}
          style={{ width: 36, height: 36 }}
        />
        <VStack style={{ margin: "0 12px 0 8px", flex: 1, gap: 6 }}>
          <TransactionType>
            {transactionTitle(props.transaction, i18n)}
          </TransactionType>
          {(props.transaction.txReason === TransactionReason.TRADE_FROM ||
            props.transaction.txReason === TransactionReason.TRADE_TO) && (
            <TransactionContent>{transactionContent()}</TransactionContent>
          )}
          <TransactionTime>
            {getOrderFormattedDateTime(props.transaction.createdTime || 0)}
          </TransactionTime>
        </VStack>
        <VStack style={{ gap: 8, alignItems: "end" }}>
          <HStack style={{ gap: isMembershipCard ? 8 : 4 }}>
            {isMembershipCard && <CurrencyImg src={icMembershipCard} />}
            {isNFT && !isMembershipCard && (
              <Image
                src={
                  props.transaction.nft?.entity?.preview === undefined
                    ? iconProto
                    : [props.transaction.nft?.entity?.preview, "smallest"]
                }
                style={{
                  width: 22,
                  height: 22,
                  borderRadius: 4,
                }}
              />
            )}
            {!isNFT && (
              <Image
                src={getCurrencyIcon(props.transaction.currency)}
                style={{
                  width: 22,
                  height: 22,
                  borderRadius: 2,
                }}
              />
            )}
            {!isMembershipCard && (
              <TransactionAmount>
                {`${operation}${isNFT || isCoupon ? props.transaction.amount : formatMoney("medium", props.transaction.currency)}`}
              </TransactionAmount>
            )}
            {isMembershipCard &&
              props.transaction.assetOrder?.nft &&
              props.transaction.amount && (
                <MembershipCard
                  nft={props.transaction.assetOrder?.nft}
                  amount={props.transaction.amount}
                />
              )}
          </HStack>
          {props.transaction.txStatus !== undefined &&
            props.transaction.txStatus !== TransactionStatus.DONE && (
              <StatusBar
                statusColor={getStatus().color}
                statusText={getStatus().text}
              />
            )}
        </VStack>
      </HStack>
      <VSpace height={14} />
      <CellDivider />
    </VStack>
  );
}

const TransactionTime = styled.div`
  font-weight: 300;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.4);
`;
const TransactionType = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
`;
const TransactionAmount = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
`;
const TransactionContent = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: #ffffffcc;
`;
export default TransactionCell;

const CurrencyImg = styled.img`
  width: 24px;
  height: 16.5px;
`;

function MembershipCard(props: { nft: NFT; amount: string }) {
  return (
    <MembershipCardContainer style={{ width: 42 }}>
      <CircleCardsPreviewCell nft={props.nft} cardStyle={CardStyle.small} />
      <MembershipCardCount>X{props.amount}</MembershipCardCount>
    </MembershipCardContainer>
  );
}

const MembershipCardContainer = styled.div`
  position: relative;
`;

const MembershipCardCount = styled.div`
  position: absolute;
  background-color: #00000099;
  border-bottom-right-radius: 2px;
  border-top-left-radius: 2px;
  color: white;
  font-size: 10px;
  font-weight: 700;
  right: 0;
  bottom: 0;
  width: 21px;
  height: 12px;
  text-align: center;
`;

export function getTradeDescription(
  transaction: TransactionLog,
  i18n?: I18n,
): string | undefined {
  const sentCurrency = transaction.assetOrder?.txList?.find(
    (tx) => tx.txCategory == TxCategory.SENT,
  )?.currency;
  const sentAmountText = `${formatMoney("medium", sentCurrency)} ${getCurrencyName(sentCurrency, i18n)}`;
  const gotCurrency = transaction.assetOrder?.txList?.find(
    (tx) => tx.txCategory == TxCategory.GOT,
  )?.currency;
  const gotAmountText = `${formatMoney("medium", gotCurrency)} ${getCurrencyName(gotCurrency, i18n)}`;
  if (
    transaction.assetOrder?.dexSwapInfo?.tokenTransactionType ==
    TokenTransactionType.BUY
  ) {
    return i18n?.clover_trade_buy(
      sentAmountText,
      getCurrencyName(gotCurrency, i18n) || "",
    );
  } else {
    return i18n?.clover_trade_sell(
      gotAmountText,
      getCurrencyName(sentCurrency, i18n) || "",
    );
  }
}
