import { Image } from "../Image";
import { Currency, getCurrencyIconByCurrencyType } from "../../proto/Currency";
import { CSSProperties } from "react";

export function CurrencyIcon(props: {
  currency?: Currency;
  size: number;
  style?: CSSProperties;
}) {
  const buildInIcon = getCurrencyIconByCurrencyType(
    props.currency?.currencyType,
  );
  return (
    <Image
      width={props.size}
      height={props.size}
      src={buildInIcon ?? [props.currency?.icon, props.size]}
      style={{
        borderRadius: buildInIcon === undefined ? "12%" : undefined,
        ...props.style,
      }}
    />
  );
}
