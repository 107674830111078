import { Backend } from "./Backend";
import { createContext, PropsWithChildren, useContext, useMemo } from "react";
import { useHttpClient } from "./HttpClientService";

interface Context {
  apiClient: Backend;
}

const Context = createContext<Context>({
  apiClient: {} as Backend,
});

export function APIService(props: PropsWithChildren<{}>) {
  const httpClient = useHttpClient();
  const apiClient = useMemo(() => {
    return new Backend(httpClient);
  }, [httpClient]);

  return (
    <Context.Provider value={{ apiClient }}>{props.children}</Context.Provider>
  );
}

export function useBackend() {
  return useContext(Context).apiClient;
}
