import { Page } from "../../components/Page";
import { useSWRArray } from "../../hooks/swr/useSWRArray";
import { PostCell } from "./PostCell";
import { useBackend } from "../../service/APIService";
import { HFilter } from "../../components/FilterTab";
import { useSWR } from "../../hooks/swr/useSWR";
import { useEffect, useState } from "react";
import { PageHeader } from "../../components/PageHeaderFooter";
import { useMemoryRepoMap } from "../../hooks/swr/useLocalRepo";
import { zBigInt } from "../../utils/zodUtils";
import { Reaction } from "../../proto/Reaction";
import { z } from "zod";
import { andLog } from "../../components/handleError";
import { useReactionRecord } from "../../hooks/useReactionRecord";

export interface PostListProps {
  type: PostListType;
}

export function PostListPage(props: PostListProps) {
  const backend = useBackend();
  const breadcrumbSWR = useSWR(
    props.type === PostListType.RECOMMEND
      ? backend.getBreadcrumbs()
      : undefined,
  );
  const reactionRepo = useReactionRecord();

  const [label, setLabel] = useState<string>();
  const postSWR = useSWRArray(
    backend.getPosts(props.type, label).intercept((it) => {
      it.list.forEach((post) => {
        reactionRepo
          .setValue(post.blogId, post.reactionCountList)
          .catch(andLog);
      });
    }),
  );

  const candidates = breadcrumbSWR?.content?.breadcrumbList
    ?.filter((breadcrumb) => breadcrumb.isShow === true)
    .map((bc) => {
      return { title: bc.title || "", value: bc.rcmdBlogLabel || "" };
    });

  useEffect(() => {
    if (breadcrumbSWR?.content && label === undefined) {
      setLabel(breadcrumbSWR?.content.breadcrumbList?.at(0)?.rcmdBlogLabel);
    }
  }, [breadcrumbSWR?.content]);

  return (
    <Page pageData={postSWR}>
      <PageHeader>
        {candidates && (
          <HFilter
            candidates={candidates}
            onSelect={(label) => setLabel(label)}
            selected={label}
            style={{ padding: 20 }}
          />
        )}
      </PageHeader>
      {postSWR.content.map((post) => (
        <PostCell key={post.blogId} post={post} />
      ))}
    </Page>
  );
}

export enum PostListType {
  FOLLOWING = "following",
  LATEST = "latest",
  POPULAR = "popular",
  HOT = "hot",
  CHAT = "chat",
  FROMM_MY_CIRCLE = "user-joined-circle",
  RECOMMEND = "recommend",
}
