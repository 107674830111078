import { BareCircle, Post } from "../../proto/Post";
import { UserAvatarView } from "../../components/views/UserAvatarView";
import { UserNameView } from "../../components/views/UserNameView";
import styled, { css } from "styled-components";
import { HStack, HStackMixin, Spring, VStack } from "../../components/VStack";
import { OverFlowTextStyle } from "../../components/Text";
import { PostMediaContainer } from "./PostMediaContainer";
import iconArrowRight from "../../res/images/ic_arrow_right.svg";
import { useNativePage, useWebHost } from "../../hooks/useBridge";
import { useHopper } from "../../hooks/useHopper";
import { Comment } from "../../proto/Comment";
import { useI18n } from "../../hooks/useI18n";
import { ReactionListView } from "../reaction/ReactionListView";
import { ObjectType } from "../../proto/ObjectSpec";
import { useModal } from "../../components/Modal";
import { MediaViewer } from "../media/MediaViewer";
import { CSSProperties, useMemo, useState } from "react";
import { PollCell } from "./PollCell";
import {
  getBestRes,
  getFittestRes,
  isAudio,
  isImage,
  isVideo,
} from "../../proto/Media";
import { Image } from "../../components/Image";
import {
  cell_on_pressed,
  FullPageWidthCell,
  FullPageWidthCellNoPadding,
} from "../../components/CommonStyles";
import { truncate } from "../../utils/TextUtils";
import { StringUtil } from "../../utils/StringUtil";
import { RegularSmallButton } from "../../components/Buttons";
import icThreeDot from "../../res/images/ic_three_dot.svg";
import { Chat } from "../../proto/Chat";
import { SharedThreadCard } from "./PostDetailPage";
import iconMore from "../../res/images/ic_wallet_more.svg";
import { BSMenu, BSMenuItem } from "../../components/BSMenu";
import { useMyUid } from "../../service/AuthSessionService";
import { copyTextToClipboard } from "../../utils/CopyText";
import { andLog, useErrorHandler } from "../../components/handleError";
import { useBackend } from "../../service/APIService";
import { useGlobalSpinner } from "../../utils/globalSpinner";
import { QuickComment } from "./QuickComment";
import { useMemoryRepo } from "../../hooks/swr/useLocalRepo";

export type PostCellProps = {
  post: Post;
  objectType?: ObjectType;
  hPadding?: CSSProperties;
  circleId?: bigint;
  showMembershipLevel?: boolean;
  hideCommentButton?: boolean;
};

export function PostCell(props: PostCellProps) {
  const hopper = useHopper();
  const i18n = useI18n();
  const menu = useModal("post-menu");
  const myUid = useMyUid();
  const nativePage = useNativePage();
  const backend = useBackend();
  const handError = useErrorHandler();
  const globalSpinner = useGlobalSpinner();
  const quickCommentModal = useModal("quick-comment");

  function getBlogContentPreview() {
    if (!props.post.content) {
      return "";
    }
    if (!props.post.richFormat) {
      return StringUtil.getOneLineCleanText(i18n, props.post.content, 1000);
    }

    let tempContent = truncate(i18n, props.post.content, 1000, 0, true);
    const reversedSpans = props.post.richFormat.attachmentSpans
      ?.slice()
      .reverse();

    reversedSpans?.forEach((span) => {
      let placeHolder;
      switch (span.data.type) {
        case "link":
          placeHolder =
            span.data.link?.customTitle ||
            span.data.link?.title ||
            span.data.link?.url;
          break;
        default:
          placeHolder = "";
      }
      const start = span.start || 0;
      if (
        tempContent.length > start &&
        tempContent.length > span.end &&
        start <= span.end
      ) {
        const frontPart = truncate(i18n, tempContent, start, 0, true);
        const backPart = truncate(
          i18n,
          tempContent,
          tempContent.length - span.end + 1,
          span.end,
          true,
        );
        tempContent = frontPart + placeHolder + backPart;
      }
    });

    return StringUtil.getOneLineCleanText(i18n, tempContent, 1000);
  }

  async function copyLink() {
    try {
      globalSpinner(async () => {
        const path = `blog/${props.post.blogId}`;
        const linkModel = await backend.getShareLink(path).run();
        if (linkModel.shareLink) {
          await copyTextToClipboard(linkModel.shareLink);
          await nativePage.successHud(i18n.copied());
        }
      }).catch(andLog);
    } catch (e) {
      handError(e);
    }
  }

  return (
    <PostContainer
      style={{
        ...props.hPadding,
      }}
      onClick={() =>
        hopper.push(`post/${props.post.blogId}`, {
          objectType: props.objectType,
        })
      }
    >
      <HStack style={{ width: "100%", alignItems: "start" }}>
        <UserInfoContainer
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            if (!!props.circleId) {
              hopper.modal(
                `circle/${props.circleId}/user-card/${props.post.author?.uid}`,
              );
            } else {
              hopper.push(`user/${props.post.author?.uid}`);
            }
          }}
        >
          <UserAvatarView
            disableClick
            user={props.post.author}
            iconSize={36}
            stopPropagation={true}
          />
          <VStack
            style={{
              marginInlineStart: 12,
              gap: 2,
              alignItems: "start",
              width: 240,
            }}
          >
            <UserNameView
              user={props.post.author}
              showMembershipLevel={props.showMembershipLevel}
            />
            {props.post.createdTime && (
              <TimeLabel>
                {Intl.DateTimeFormat(navigator.language, {
                  dateStyle: "medium",
                  timeStyle: "short",
                }).format(props.post.createdTime * 1000)}
              </TimeLabel>
            )}
          </VStack>
        </UserInfoContainer>
        <img
          src={iconMore}
          width={20}
          height={20}
          style={{ flexShrink: 0 }}
          onClick={(event) => {
            event.stopPropagation();
            menu.open();
          }}
        />
      </HStack>

      <VStack style={{ marginTop: 12, gap: 8, width: "100%" }}>
        {props.post.title && (
          <PostTitle style={{ width: "100%" }}>{props.post.title}</PostTitle>
        )}
        {props.post.content && (
          <PostContent style={{ width: "100%" }}>
            {getBlogContentPreview()}
          </PostContent>
        )}
        {(props.post.mediaList?.length || 0) > 0 && !props.post.pollList && (
          <PostMediaContainer
            mediaList={props.post.mediaList}
            uid={props.post.author?.uid}
            objectId={props.post.blogId}
          />
        )}

        {props.post.pollList &&
          props.post.pollList.map((poll, index) => (
            <PollCell poll={poll} key={`poll-cell-${poll.pollId}`} />
          ))}

        {props.post.circleList && (
          <HStack style={{ width: "100%", flexWrap: "wrap", gap: 8 }}>
            {props.post.circleList.map((circle) => (
              <RelatedCircleCard
                circle={circle}
                key={`related-circle-${circle.circleId}`}
              />
            ))}
          </HStack>
        )}

        {props.post.sharedThread && (
          <SharedThreadCard sharedThread={props.post.sharedThread} />
        )}
      </VStack>

      <ReactionListView
        reactions={props.post.reactionCountList}
        objectId={props.post.blogId || BigInt(0)}
        objectType={props.objectType || ObjectType.BLOG}
        showCommentButton={!props.hideCommentButton}
        enable={true}
        quickComment={() => {
          // quickCommentModal.open();
        }}
      />

      {props.post.appearedCommentList && (
        <CommentList commentList={props.post.appearedCommentList} />
      )}
      <Divider style={{ marginTop: 25 }} />
      <BSMenu modal={menu}>
        <BSMenuItem title={i18n.share_copy_link()} onClick={copyLink} />
        <BSMenuItem title={i18n.share()} />
        {myUid !== props.post.author?.uid && (
          <BSMenuItem
            title={i18n.flag()}
            onClick={() =>
              hopper.layer("flag", {
                objectId: props.post.blogId,
                objectType: ObjectType.BLOG,
              })
            }
          />
        )}
      </BSMenu>
      <QuickComment
        modal={quickCommentModal}
        post={props.post}
        parentType={props.objectType || ObjectType.BLOG}
      />
    </PostContainer>
  );
}

const PostContainer = styled.div`
  padding-top: 25px;
  ${cell_on_pressed};
`;

const PostTitle = styled.div`
  width: 100%;
  font-weight: 700;
  color: white;
  font-size: 16px;
  word-break: break-word;
`;

const PostContent = styled.div`
  width: 100%;
  font-weight: 400;
  color: white;
  font-size: 16px;
  ${OverFlowTextStyle};
  -webkit-line-clamp: 2;
`;

const TimeLabel = styled.div`
  font-weight: 300;
  font-size: 12px;
  color: "#FFFFFF99";
`;

export const Divider = styled.div`
  width: 100%;
  height: 0.5px;
  background-color: #ffffff33;
`;

export const UserInfoContainer = styled.div`
  flex: 1;
  ${HStackMixin};
  transition: opacity 0.2s ease;
`;

function CommentList(props: { commentList: Comment[] }) {
  const i18n = useI18n();

  const firstComment = props.commentList.at(0);
  const secondComment = props.commentList.at(1);
  return (
    <VStack
      style={{
        width: "100%",
        backgroundColor: "#FFFFFF14",
        borderRadius: 6,
        paddingInlineStart: 12,
        paddingInlineEnd: 12,
        marginTop: 12,
      }}
    >
      {firstComment && <CommentItem comment={firstComment} />}
      {secondComment && (
        <div
          style={{ width: "100%", backgroundColor: "#FFFFFF1E", height: 1 }}
        />
      )}
      {secondComment && <CommentItem comment={secondComment} />}
      {props.commentList?.length > 2 && (
        <div
          style={{
            width: "100%",
            fontWeight: 300,
            fontSize: 12,
            color: "#FFFFFF66",
            marginBottom: 12,
          }}
        >
          {i18n
            .plural(props.commentList?.length)
            .count_plus_comments(props.commentList?.length) + " >>"}
        </div>
      )}
    </VStack>
  );
}

function CommentItem(props: { comment: Comment }) {
  const i18n = useI18n();
  const content = useMemo(() => {
    if (
      (props.comment.content?.length || 0) === 0 &&
      (props.comment.mediaList?.length || 0) > 0
    ) {
      let str = "";
      props.comment.mediaList?.forEach((media) => {
        if (isImage(media)) {
          str += i18n.chat_list_image();
        } else if (isVideo(media)) {
          str += i18n.chat_list_video();
        }
      });
      return str;
    } else {
      return props.comment.content;
    }
  }, [props.comment]);

  return (
    <HStack
      style={{ width: "100%", gap: 4, paddingTop: 12, paddingBottom: 12 }}
    >
      <CommentAuthorName>{props.comment.author.nickname}</CommentAuthorName>
      <div style={{ color: "white", fontSize: 14, fontWeight: 700 }}>:</div>
      <CommentContent>{content}</CommentContent>
    </HStack>
  );
}

const CommentAuthorName = styled.div`
  color: white;
  font-size: 14px;
  font-weight: 700;
  ${OverFlowTextStyle};
  -webkit-line-clamp: 1;
  max-width: 20%;
`;

const CommentContent = styled.div`
  max-width: 80%;
  color: #ffffffcc;
  font-size: 14px;
  font-weight: 300;
  ${OverFlowTextStyle};
  -webkit-line-clamp: 1;
`;

function RelatedCircleCard(props: { circle: BareCircle }) {
  const hopper = useHopper();
  return (
    <RelatedCircleContainer
      style={{
        border: `0.5px solid ${props.circle.themeColor ?? "#FFFFFF"}`,
        backgroundColor: `${props.circle.themeColor ?? "#FFFFFF"}33`,
      }}
      onClick={(event) => {
        event.stopPropagation();
        hopper.push(`circle/${props.circle.circleId}`);
      }}
    >
      <Image
        src={[props.circle.circleIcon, { width: 20, height: 20 }]}
        style={{
          boxSizing: "border-box",
          borderRadius: 3,
          border: `1px solid ${props.circle.themeColor ?? "#FFFFFF"}`,
          width: 20,
          height: 20,
        }}
      />
      <CircleName style={{ color: props.circle.themeColor ?? "#FFFFFF" }}>
        {props.circle.name}
      </CircleName>
    </RelatedCircleContainer>
  );
}

const RelatedCircleContainer = styled.div`
  ${cell_on_pressed};
  ${HStackMixin};
  padding: 4px;
  gap: 6px;
  max-width: 100%;
  border-radius: 6px;
`;

const CircleName = styled.div`
  font-weight: 700;
  font-size: 12px;
  ${OverFlowTextStyle};
  line-clamp: 1;
  -webkit-line-clamp: 1;
  flex: 1;
`;
