import { useEffect, useState } from "react";
import { toCanvas, toDataURL } from "qrcode";

export function QRCode(props: { value: string; width: number }) {
  const [canvasElement, setCanvasElement] = useState<HTMLCanvasElement | null>(
    null,
  );

  useEffect(() => {
    if (canvasElement) {
      toCanvas(
        canvasElement,
        props.value,
        {
          width: props.width,
          errorCorrectionLevel: "M",
          color: {
            dark: "#FFF",
            light: "#0000",
          },
        },
        (e) => {},
      );
    }
  }, [canvasElement, props.value, props.width]);
  return <canvas ref={(e) => setCanvasElement(e)}></canvas>;
}

export function getQRCodeData(
  value: string,
  width: number,
  type: "image/jpeg" | "image/png" | "image/webp",
): Promise<string> {
  return toDataURL(value, {
    type: type,
    width: width,
    errorCorrectionLevel: "M",
    color: {
      dark: "#FFF",
      light: "#0000",
    },
  });
}
