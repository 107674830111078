import { I18n } from "../../../hooks/useI18n";
import { UserTaskType } from "../../../proto/Order";

export function getUserTaskNameByType(
  userTaskType: UserTaskType,
  containsRewardsString: boolean,
  i18n: I18n,
): string {
  switch (userTaskType) {
    case UserTaskType.UserTaskTypeActivateWallet:
      return i18n.newbie_task_activating_wallet();
    case UserTaskType.UserTaskTypePurchaseMerch:
      return i18n.newbie_task_purchase_merch_rewards();
    case UserTaskType.UserTaskTypePolishProfile:
      return i18n.newbie_task_polish_profile_rewards();
    case UserTaskType.UserTaskTypeInviteFriends:
    case UserTaskType.InviteFriendsV2:
      return containsRewardsString
        ? i18n.cash_rewards_invite_friends_rewards()
        : i18n.invite_friends();
    case UserTaskType.UserTaskTypeMatch:
      return i18n.newbie_task_using_match_rewards();
    case UserTaskType.UserTaskTypeWidget:
    case UserTaskType.EnableWidgetV2:
      return containsRewardsString
        ? i18n.widget_reward_enable_widget_rewards()
        : i18n.widget_reward_enable_the_z_widget();
    case UserTaskType.CreateProtoNFT:
      return containsRewardsString
        ? i18n.cash_rewards_create_merch_rewards()
        : i18n.tx_impr_create_merch();
    case UserTaskType.CheckIn:
      return containsRewardsString
        ? i18n.cash_rewards_check_in_rewards()
        : i18n.cash_rewards_check_in();
    case UserTaskType.EnableCompanion:
      return containsRewardsString
        ? i18n.comp_task_enable_companion_rewards()
        : i18n.comp_task_enable_my_companion();
    case UserTaskType.Match:
      return containsRewardsString
        ? i18n.fast_match_meet_new_friends_rewards()
        : i18n.fast_match_meet_new_friends();
    default:
      return "";
  }
}
