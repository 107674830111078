import { I18n } from "../hooks/useI18n";
import icMessage from "../res/images/ic_gift_box_message.png";
import icEmail from "../res/images/ic_gift_box_message.png";
import icContact from "../res/images/ic_gift_box_message.png";
import icWhatsApp from "../res/images/ic_gift_box_message.png";
import icTikTok from "../res/images/ic_gift_box_message.png";
import icMessenger from "../res/images/ic_gift_box_message.png";
import icSnapchat from "../res/images/ic_gift_box_message.png";

export enum GiftBoxLocation {
  message = 1,
  email = 2,
  contact = 3,
  whatsapp = 4,
  tiktok = 5,
  messenger = 6,
  snapchat = 7,
  external = 8,
  party = 9,
  group = 10,
}

export function getGiftBoxLocationSource(
  location?: number,
): string | undefined {
  switch (location) {
    case GiftBoxLocation.message:
      return icMessage;
    case GiftBoxLocation.email:
      return icEmail;
    case GiftBoxLocation.contact:
      return icContact;
    case GiftBoxLocation.whatsapp:
      return icWhatsApp;
    case GiftBoxLocation.tiktok:
      return icTikTok;
    case GiftBoxLocation.messenger:
      return icMessenger;
    case GiftBoxLocation.snapchat:
      return icSnapchat;
  }
}

export function getGiftBoxLocationText(
  location?: number,
  i18n?: I18n,
): string | undefined {
  switch (location) {
    case GiftBoxLocation.message:
      return i18n?.share_messages_alt();
    case GiftBoxLocation.email:
      return i18n?.email();
    case GiftBoxLocation.contact:
      return i18n?.gift_more_contacts();
    case GiftBoxLocation.whatsapp:
      return i18n?.whatsapp();
    case GiftBoxLocation.tiktok:
      return i18n?.gift_more_tiktok();
    case GiftBoxLocation.messenger:
      return i18n?.messenger();
    case GiftBoxLocation.snapchat:
      return i18n?.gift_more_snapchat();
    case GiftBoxLocation.external:
      return i18n?.gift_more_external_platform();
  }
}
