import { z } from "zod";
import { zBigInt, zEnum, zStatic } from "../utils/zodUtils";
import { ChatMessage, ChatMessageType } from "./ChatMessage";

export enum WSType {
  ChatMsg = 1,
  ServerAck = 2,
  ClientAck = 3,
  ChatSubscribe = 6,
  ChatUnsubscribe = 7,
  ReportEnter = 8,
  ReportLeave = 9,
  ChatPing = 10,
  ForceQuit = 11,
  StrikeMute = 12,
  InAppPush = 13,
  RefreshCash = 14,
  RefreshMintOrder = 15,
  ThirdPartyPurchase = 16,
  UserUpgradeSuccess = 17,
  UserBlocklistUpdated = 20,
  UserStatusUpdated = 22,
}

export const PushMessage = z.object({
  title: z.string().default(""),
  body: z.string().default(""),
  deepLink: z.string().optional(),
});

export type PushMessage = zStatic<typeof PushMessage>;

export const ServerAck = z.object({
  refId: zBigInt.optional(),
  seqId: zBigInt.optional(),
  messageId: zBigInt.optional(),
  createdTime: z.number(),
  apiCode: z.number().optional(),
  apiMsg: z.string().optional(),
  diceSideId: z.number().optional(),
  redirectUrl: z.string().optional(),
});

export type ServerAck = zStatic<typeof ServerAck>;

export const ClientAck = z.object({
  threadId: zBigInt.optional(),
  messageId: zBigInt.optional(),
  markAsRead: z.boolean().optional(),
});

export type ClientAck = zStatic<typeof ClientAck>;

export const WSMessage = z.object({
  t: zEnum(WSType),
  msg: ChatMessage.optional(),
  pushMsg: PushMessage.optional(),
  serverAck: ServerAck.optional(),
  clientAck: ClientAck.optional(),
  threadId: zBigInt.optional(),
  nftId: zBigInt.optional(),
  orderId: zBigInt.optional(),
  circleId: zBigInt.optional(),
  userEngaged: z.boolean().optional(),
});

export type WSMessage = zStatic<typeof WSMessage>;

export function isMsgNeedAck(msg: WSMessage) {
  if (!msg.msg) {
    return false;
  }
  return !(
    msg.msg.type === ChatMessageType.UserTyping ||
    msg.msg.type === ChatMessageType.UserTalking ||
    msg.msg.type === ChatMessageType.Qi ||
    msg.msg.type === ChatMessageType.UserSendingVoiceMessage
  );
}

export function buildNewWSChatMessageToSend(threadId: bigint, myUid: bigint) {
  const now = Date.now();
  return {
    t: WSType.ChatMsg,
    msg: {
      type: ChatMessageType.Text,
      threadId: threadId,
      uid: myUid,
      createdTime: now,
      seqId: BigInt(now),
      extensions: {},
    } as ChatMessage,
  } as WSMessage;
}

export function getWSMsgUniqueId(msg: WSMessage) {
  switch (msg.t) {
    case WSType.ChatMsg:
      if (msg.msg?.refId) {
        return msg.msg.refId + "";
      } else if (msg.msg?.seqId) {
        return msg.msg.seqId + "";
      }
      break;
    case WSType.ServerAck:
      if (msg.serverAck?.refId) {
        return msg.serverAck.refId + "";
      } else if (msg.serverAck?.seqId) {
        return msg.serverAck.seqId + "";
      }
      break;
    case WSType.ClientAck:
      if (msg.msg?.messageId) {
        return msg.msg.messageId + "";
      }
      break;
    case WSType.ChatSubscribe:
    case WSType.ChatUnsubscribe:
      if (msg.threadId) {
        return msg.threadId + "";
      }
      break;
  }
}
