import { useI18n } from "../../hooks/useI18n";
import { useHopper } from "../../hooks/useHopper";
import { HStack, Spring, VStack } from "../../components/VStack";
import { Page } from "../../components/Page";
import { Image } from "../../components/Image";
import {
  useEnumSearchParam,
  useStringSearchParam,
} from "../../hooks/useTypedParam";
import { SignUpOrLogIn } from "./AuthHomePage";
import icEmail from "../../res/images/ic_email.svg";
import icPhone from "../../res/images/ic_phone.svg";
import { AuthBody, AuthType, SecurityPurpose } from "../../proto/Auth";
import { NavEnd, NavItem } from "../../components/NavBar";
import { usePageSpec } from "../common/usePageSpec";
import logo from "../../res/images/logo_clover.svg";
import icTelegram from "../../res/images/ic_telegram.svg";
import icGoogle from "../../res/images/icon_auth_google.svg";
import icApple from "../../res/images/icon_auth_apple.svg";
import styled from "styled-components";
import authHomeBg from "../../res/images/auth_home_bg.png";
import { alpha_on_pressed } from "../../components/CommonStyles";
import { useAppleAuth } from "../../hooks/useAppleAuth";
import { useMemoryRepo } from "../../hooks/swr/useLocalRepo";
import { genUUID } from "../../utils/uuid";
import { andLog, useErrorHandler } from "../../components/handleError";
import { useBackend } from "../../service/APIService";
import { useGlobalSpinner } from "../../utils/globalSpinner";
import { useAuthSessionService } from "../../service/AuthSessionService";
import IcSpace from "../../res/images/clover_space.svg";
import { AppleId, GoogleId } from "../../config/config";
import { useGoogleAuth } from "../../hooks/useGoogleAuth";
import { useNativePage } from "../../hooks/useBridge";
import { APIErrorCode } from "../../proto/APIErrorRespBody";
import { useRef } from "react";

export function SignUpOrLogInSelectionPage() {
  const type = useEnumSearchParam<SignUpOrLogIn>("type");
  const flowId = useStringSearchParam("f") ?? genUUID();
  const authInfo = useMemoryRepo<AuthBody>(flowId ?? "", {});

  const i18n = useI18n();
  const hopper = useHopper();
  const backend = useBackend();
  const spinner = useGlobalSpinner();

  const [appleAuth, appleAuthState] = useAppleAuth(AppleId);
  const [googleAuth, googleAuthState] = useGoogleAuth(GoogleId);

  const handleError = useErrorHandler();
  const authSessionService = useAuthSessionService();
  const nativePage = useNativePage();

  const latestAuthInfo = useRef<AuthBody>({});

  async function doLogin(authInfo: AuthBody, restore: boolean = false) {
    const { error } = await spinner(async () => {
      latestAuthInfo.current = authInfo;
      const auth = await backend.logIn(authInfo).run();
      await authSessionService.add(
        {
          uid: auth.account.uid,
          sid: auth.sId,
          ptoken: auth.secret,
        },
        true,
      );
    }, true);
    if (error) throw error;
  }

  const emailClick = async () => {
    switch (type) {
      case SignUpOrLogIn.SignUp:
        await authInfo.fill((prev) => ({
          ...prev,
          authType: AuthType.Email,
          purpose: SecurityPurpose.Register,
        }));
        hopper.push("invitation-code", {
          f: flowId,
        });
        break;
      case SignUpOrLogIn.LogIn:
        hopper.push("email-log-in");
        break;
    }
  };

  const phoneClick = async () => {
    switch (type) {
      case SignUpOrLogIn.SignUp:
        await authInfo.fill((prev) => ({
          ...prev,
          authType: AuthType.Phone,
          purpose: SecurityPurpose.Register,
        }));
        hopper.push("invitation-code", {
          f: flowId,
        });
        break;
      case SignUpOrLogIn.LogIn:
        hopper.push("phone-number-log-in");
        break;
    }
  };

  const onClickTelegram = async () => {
    hopper.modal("nyi");
  };

  async function showReactive() {
    try {
      const shouldRestore = await nativePage.alertYesOrCancel(
        i18n.account_reactivate_message(),
        i18n.reactivate(),
        i18n.cancel(),
      );
      if (shouldRestore) {
        if (latestAuthInfo.current.authType === AuthType.Apple) {
          onClickApple().catch(andLog);
        } else if (latestAuthInfo.current.authType === AuthType.Google) {
          onClickGoogle(true).catch(andLog);
        }
      }
    } catch (error) {
      handleError(error);
    }
  }

  async function customHandleError(error: any) {
    if (error.apiCode === APIErrorCode.APICodeAccountDeletedCanBeReactivated) {
      showReactive().catch(andLog);
    } else if (error) {
      handleError(error);
    }
  }

  async function onClickGoogle(restore?: boolean) {
    if (!googleAuth) return;
    try {
      const code = await googleAuth.signIn();
      const newInfo = await authInfo.fill((prev) => ({
        ...prev,
        googleCode: code,
        googleRedirectUrl: window.location.origin,
        authType: AuthType.Google,
        requestToBeReactivated: restore,
      }));
      switch (type) {
        case SignUpOrLogIn.SignUp:
          await backend.registerCheck(newInfo).run();
          hopper.push("invitation-code", {
            f: flowId,
          });
          break;
        case SignUpOrLogIn.LogIn:
          await doLogin(newInfo);
      }
    } catch (e) {
      customHandleError(e).catch(andLog);
    }
  }

  async function onClickApple(restore?: boolean) {
    if (!appleAuth) return;
    try {
      const code = await appleAuth.signIn();
      const newInfo = await authInfo.fill((prev) => ({
        ...prev,
        appleAuthorizationCode: code,
        authType: AuthType.Apple,
        requestToBeReactivated: restore,
      }));
      switch (type) {
        case SignUpOrLogIn.SignUp:
          await backend.registerCheck(newInfo).run();
          hopper.push("invitation-code", {
            f: flowId,
          });
          break;
        case SignUpOrLogIn.LogIn:
          await doLogin(newInfo);
      }
    } catch (e) {
      customHandleError(e).catch(andLog);
    }
  }

  const pageSpec = usePageSpec();

  return (
    <Page
      pageData={undefined}
      background={
        pageSpec !== "wide"
          ? `url(${authHomeBg}) no-repeat center/cover`
          : undefined
      }
    >
      <NavEnd>
        {NavItem.text(i18n.help(), () => hopper.push("feedback"))}
      </NavEnd>
      <Spring />

      {pageSpec !== "wide" && (
        <>
          <Image
            src={logo}
            alt={"logo"}
            width={80}
            height={80}
            style={{ margin: "16px auto 0" }}
          ></Image>
          <Image src={IcSpace} style={{ width: 138, margin: "16px auto 0" }} />
          <Spring />
        </>
      )}
      <SelectionTitleLabel>
        {type === SignUpOrLogIn.SignUp
          ? i18n.auth_sign_up()
          : i18n.auth_log_in()}
      </SelectionTitleLabel>
      <PlatformHStack onClick={() => onClickGoogle().catch(andLog)}>
        <Image
          src={icGoogle}
          style={{
            width: "32px",
            height: "32px",
            marginLeft: "14px",
            marginRight: "16px",
          }}
        />
        <ThirdPartyPlatformLabel>
          {i18n.continue_with_google()}
        </ThirdPartyPlatformLabel>
      </PlatformHStack>
      <PlatformHStack onClick={() => onClickApple().catch(andLog)}>
        <Image
          src={icApple}
          style={{
            width: "32px",
            height: "32px",
            marginLeft: "14px",
            marginRight: "16px",
          }}
        />
        <ThirdPartyPlatformLabel>
          {i18n.continue_with_apple()}
        </ThirdPartyPlatformLabel>
      </PlatformHStack>
      <PlatformHStack onClick={onClickTelegram} style={{ opacity: 0.6 }}>
        <Image
          src={icTelegram}
          alt={"icTelegram"}
          style={{
            width: "32px",
            height: "32px",
            marginLeft: "14px",
            marginRight: "16px",
          }}
        />
        <ThirdPartyPlatformLabel>
          {i18n.continue_with_telegram()}
        </ThirdPartyPlatformLabel>
      </PlatformHStack>
      {type === SignUpOrLogIn.LogIn ? (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              margin: "37px 60px 25px",
            }}
          >
            <div
              style={{
                height: "1px",
                borderBottom: "1px dashed #888888",
                flexGrow: "0.4",
              }}
            ></div>
            <div
              style={{
                flexGrow: "0.2",
                textAlign: "center",
              }}
            >
              {i18n.mars_or()}
            </div>
            <div
              style={{
                height: "1px",
                borderBottom: "1px dashed #888888",
                flexGrow: "0.4",
              }}
            ></div>
          </div>
          <HStack style={{ margin: "0 auto", gap: "54px" }}>
            <VStack
              style={{ gap: "10px", alignItems: "center" }}
              onClick={emailClick}
            >
              <Image
                src={icEmail}
                alt={"icEmail"}
                style={{ width: "44px", height: "44px" }}
              ></Image>
              <PlatformLabel>{i18n.email()}</PlatformLabel>
            </VStack>
            <VStack
              style={{ gap: "10px", alignItems: "center" }}
              onClick={phoneClick}
            >
              <Image
                src={icPhone}
                alt={"icPhone"}
                style={{ width: "44px", height: "44px" }}
              ></Image>
              <PlatformLabel>{i18n.phone()}</PlatformLabel>
            </VStack>
          </HStack>
        </>
      ) : (
        <Spring />
      )}
      <Spring />
    </Page>
  );
}

export const SelectionTitleLabel = styled.div`
  color: var(--color-text00);
  font-weight: 400;
  font-size: 18px;
  text-align: center;
`;

const PlatformLabel = styled.div`
  color: var(--color-text00);
  text-align: center;
  font-weight: 500;
  font-size: 14px;
`;

const PlatformHStack = styled.div`
  display: flex;
  flex-direction: row;
  background: white;
  border-radius: 6px;
  margin: 20px 62px 0;
  height: 60px;
  align-items: center;
  position: relative;

  ${alpha_on_pressed};
`;

const ThirdPartyPlatformLabel = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: black;
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  width: calc(100% - 120px);
`;
