import { WalletAccount } from "../../proto/Wallet";
import bgRegularAccount from "../../res/images/bg_regular_wallet_account.png";
import accountBgV3 from "../../res/images/bg_regular_wallet_account_v3.png";
import bgShopAccount from "../../res/images/bg_shop_account.png";
import iconBuiltIn from "../../res/images/icon_built_in_wallet.svg";
import iconShopAccount from "../../res/images/ic_wallet_shop_account.svg";
import iconCopy from "../../res/images/ic_copy_address.svg";
import iconMore from "../../res/images/ic_wallet_more.svg";
import styled from "styled-components";
import { HStack, VStack } from "../../components/VStack";
import { Image } from "../../components/Image";
import { useI18n } from "../../hooks/useI18n";
import { useHopper } from "../../hooks/useHopper";
import WalletBalanceCell from "./WalletBalanceCell";
import React, { useMemo } from "react";
import { CurrencyType } from "../../proto/Currency";
import { BSMenu, BSMenuItem } from "../../components/BSMenu";
import { useModal } from "../../components/Modal";
import AddressPicker from "./account/AddressPicker";
import { AccountType } from "../../proto/AccountType";
import {
  isInExternalAppShell,
  isLiteApp,
} from "../../bridge/ExternalAppShellData";
import { detect } from "detect-browser";
import { useNativePage } from "../../hooks/useBridge";
import { dicClickTopUp } from "./dicClickTopUp";
import { TopUpMethodMenu } from "./topup/TopUpMethodMenu";
import { useBroadcast } from "../../hooks/useBroadcast";
import { useOnMorePaymentBroadcast } from "../../hooks/useOnMorePaymentBroadcast";

export interface WalletAccountCellProps {
  walletAccount: WalletAccount;
  supportThirdParty: boolean;
  shopAccount?: boolean;
}

function WalletAccountCell(props: WalletAccountCellProps) {
  const i18n = useI18n();
  const hopper = useHopper();
  const nativePage = useNativePage();
  const addressInfos = props.walletAccount.assetAccountList;
  const topupMenu = useModal("top-up-menu");
  useOnMorePaymentBroadcast(() => {
    topupMenu.open();
  }, [topupMenu]);

  const solCurrency = useMemo(() => {
    return props.walletAccount.currencyList?.find((currency) => {
      return currency.currencyType === CurrencyType.SOL;
    });
  }, [props.walletAccount]);

  const gemsCurrency = useMemo(() => {
    return props.walletAccount.currencyList?.find((currency) => {
      return currency.currencyType === CurrencyType.GEMS;
    });
  }, [props.walletAccount]);

  const otherTokenCurrency = useMemo(() => {
    const otherTokens = props.walletAccount.currencyList?.filter((currency) => {
      return (
        currency.currencyType !== CurrencyType.COIN &&
        currency.currencyType !== CurrencyType.REWARDED_COIN &&
        currency.currencyType !== CurrencyType.DIAMOND &&
        currency.currencyType !== CurrencyType.GEMS &&
        currency.currencyType !== CurrencyType.SOL
      );
    });

    if (otherTokens && otherTokens.length > 0) {
      return otherTokens.reduce((max, currency) => {
        return Number(currency.usdPrice) > Number(max.usdPrice)
          ? currency
          : max;
      });
    }
  }, [props.walletAccount]);

  const isBuiltInAccount =
    props.walletAccount.accountType === AccountType.BuiltIn;

  let accountTitle: string;
  let accountBg: string;
  let startIcon: string;

  if (isBuiltInAccount) {
    accountTitle = i18n.arthur_wallet_built_in_account();
    accountBg = bgRegularAccount;
    startIcon = iconBuiltIn;
  } else {
    accountTitle = i18n.web3_v0_store_account();
    accountBg = bgShopAccount;
    startIcon = iconShopAccount;
  }
  const moreMenu = useModal("wallet-account-menu");
  const addressPicker = useModal("address-picker-menu");

  const walletAccountId = props.walletAccount.walletAccountId;

  const sendToken = () => {
    hopper.layer(`wallet/${walletAccountId}/select-token`, {
      purpose: "SendToken",
    });
  };

  const swap = () => {
    hopper.layer(`wallet/${walletAccountId}/select-token`, {
      purpose: "SwapToken",
    });
  };

  return (
    <Container
      onClick={() => {
        if (props.shopAccount) {
          hopper.push(`shop-account`);
        } else {
          hopper.push(`wallet/${props.walletAccount.walletAccountId}`);
        }
      }}
    >
      <Image
        src={otherTokenCurrency ? accountBgV3 : accountBg}
        width={"100%"}
        height={"100%"}
      />
      <VStack
        style={{
          width: "100%",
          position: "absolute",
          gap: 20,
          padding: 16,
        }}
      >
        <HLine>
          <HStack style={{ gap: 8 }}>
            <Image src={startIcon} width={32} height={32} />
            <Title>{accountTitle}</Title>
          </HStack>

          <HStack style={{ gap: 12 }}>
            {isBuiltInAccount && (
              <Image
                src={iconCopy}
                stopPropagation
                onClick={() => {
                  addressPicker.open();
                }}
                width={20}
                height={20}
              />
            )}
            <Image
              stopPropagation={true}
              onClick={() => {
                moreMenu.open();
              }}
              src={iconMore}
              width={20}
              height={20}
            />
          </HStack>
        </HLine>

        {gemsCurrency && (
          <WalletBalanceCell currency={gemsCurrency} showUsdPrice={false} />
        )}

        {solCurrency && (
          <WalletBalanceCell currency={solCurrency} showUsdPrice={true} />
        )}

        {otherTokenCurrency && (
          <WalletBalanceCell
            currency={otherTokenCurrency}
            showUsdPrice={true}
          />
        )}

        <AddressPicker modal={addressPicker} addressInfos={addressInfos} />

        <BSMenu modal={moreMenu}>
          {isBuiltInAccount ? (
            <>
              <BSMenuItem
                title={i18n.web3_hongbao_top_up()}
                onClick={() => {
                  dicClickTopUp(
                    hopper,
                    props.supportThirdParty ? topupMenu : undefined,
                  );
                }}
              />
              <BSMenuItem
                title={i18n.clover_send_tokens()}
                onClick={sendToken}
              />
              <BSMenuItem
                title={i18n.clover_receive()}
                onClick={() => {
                  hopper.push(`wallet/receive`);
                }}
              />
              {!isLiteApp() && (
                <BSMenuItem title={i18n.clover_swap()} onClick={swap} />
              )}
              <BSMenuItem
                title={i18n.tx_impr_create_merch()}
                onClick={() => {
                  hopper.push(`nft/create`);
                }}
              />
              {/*<BSMenuItem title={i18n.web3_mint_coupon_mint_nft()} />*/}
              <BSMenuItem
                title={i18n.web3_v0_transaction_history()}
                onClick={() =>
                  hopper.push(`wallet/${walletAccountId}/transaction-history`, {
                    accountType: props.walletAccount.accountType,
                  })
                }
              />
              {isInExternalAppShell() && detect()?.os === "iOS" && (
                <BSMenuItem
                  title={i18n.march_impr_2023_restore_iap()}
                  onClick={() => {
                    nativePage.restorePurchase();
                  }}
                />
              )}
            </>
          ) : (
            <>
              <BSMenuItem title={i18n.transfer()} />
              <BSMenuItem
                title={i18n.web3_v0_transaction_history()}
                onClick={() =>
                  hopper.push(`wallet/${walletAccountId}/transaction-history`, {
                    accountType: props.walletAccount.accountType,
                  })
                }
              />
            </>
          )}
        </BSMenu>
        {props.supportThirdParty && <TopUpMethodMenu modal={topupMenu} />}
      </VStack>
    </Container>
  );
}

const HLine = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  color: var(--color-text00);
  font-weight: 500;
  font-size: 16px;
`;

const Container = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 20px;
  flex-direction: column;
  align-items: center;
`;

export default WalletAccountCell;
