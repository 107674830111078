import React, { useState } from "react";
import { Page } from "../../components/Page";
import { NavEnd, NavItem, NavMiddle } from "../../components/NavBar";
import { usePageSpec } from "../common/usePageSpec";
import { AuthTitleLabel } from "./InvitationCodePage";
import { useI18n } from "../../hooks/useI18n";
import { useHopper } from "../../hooks/useHopper";
import { InterestCell } from "../../components/InterestsFlowLayout";
import { useBackend } from "../../service/APIService";
import { useSWR } from "../../hooks/swr/useSWR";
import { Interest } from "../../proto/Interest";
import { FlexFlowStyles } from "../../components/TagsFlowLayout";
import styled from "styled-components";
import { Spin } from "../../components/Spin";
import { RegularLargeButton } from "../../components/Buttons";
import { useLoadState } from "../../hooks/LoadState";
import { useErrorHandler, andLog } from "../../components/handleError";
import { useNativePage } from "../../hooks/useBridge";
import { PageFooter } from "../../components/PageHeaderFooter";
import { RowCenterButton } from "../../components/CommonStyles";
import CreateAccountSuccessAnimation from "./CreateAccountSuccessAnimationView";

export function PickInterestsPage() {
  const i18n = useI18n();
  const hopper = useHopper();
  const pageSpec = usePageSpec();
  const backend = useBackend();
  const handleError = useErrorHandler();
  const nativePage = useNativePage();
  const interestSWR = useSWR(backend.getInterests());

  const [currentPickedInterests, setCurrentPickedInterests] = useState<
    Interest[]
  >([]);

  function onInterestClick(interest: Interest) {
    const included = currentPickedInterests.includes(interest);
    if (included) {
      setCurrentPickedInterests(
        currentPickedInterests.filter((i) => i !== interest),
      );
    } else {
      setCurrentPickedInterests([...currentPickedInterests, interest]);
    }
  }

  const loadTask = useLoadState();

  async function onNextClick() {
    if (currentPickedInterests.length < 4) {
      nativePage.infoHud(i18n.please_pick_4_or_more_interests()).catch(andLog);
      return;
    }
    const result = await loadTask.run(async () => {
      await backend
        .chooseInterests(
          currentPickedInterests.map((interest) => interest.insId),
        )
        .run();
    });
    if (!result.success) {
      handleError(result.error);
    } else {
      setShowAnimation(true);
    }
  }

  const [showAnimation, setShowAnimation] = useState(false);

  return (
    <Page pageData={undefined}>
      {showAnimation ? (
        <>
          <CreateAccountSuccessAnimation
            onComplete={() => {
              setShowAnimation(false);
              hopper.push("");
            }}
          />
          <PageFooter obscuredZoneKey={"InterestsBottom"}></PageFooter>
        </>
      ) : (
        <>
          {pageSpec === "wide" && <NavMiddle>{i18n.auth_sign_up()}</NavMiddle>}
          <NavEnd>
            {NavItem.text(i18n.skip(), () => {
              setShowAnimation(true);
            })}
          </NavEnd>
          <AuthTitleLabel>
            {i18n.please_pick_4_or_more_interests()}
          </AuthTitleLabel>
          {interestSWR.content?.list && (
            <InterestsFlowLayout>
              {interestSWR.content?.list.map((interest) => (
                <InterestCell
                  key={interest.insId}
                  interest={interest}
                  picked={currentPickedInterests.includes(interest)}
                  onPickedResult={() => {
                    console.log("pickedResult", interest);
                    onInterestClick(interest);
                  }}
                />
              ))}
            </InterestsFlowLayout>
          )}
          <PageFooter obscuredZoneKey={"InterestsBottom"}>
            <RegularLargeButton
              disabled={currentPickedInterests.length <= 0}
              style={{
                ...RowCenterButton,
                marginTop: 8,
              }}
              onClick={onNextClick}
            >
              <Spin state={loadTask.state}>{i18n.next()}</Spin>
            </RegularLargeButton>
          </PageFooter>
        </>
      )}
    </Page>
  );
}

const InterestsFlowLayout = styled.div`
  ${FlexFlowStyles.tagsContainer}
`;
