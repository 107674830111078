import { ChatMessage } from "../../../proto/ChatMessage";
import icNewGift from "../images/ic_new_gift.svg";
import icOpenedGift from "../images/ic_opened_gift.svg";
import icExpiredGift from "../images/ic_expired_gift.svg";
import { Image } from "../../../components/Image";
import { GiftBox, GiftBoxStatus } from "../../../proto/GiftBox";
import { HStack } from "../../../components/VStack";
import styled from "styled-components";
import { useI18n } from "../../../hooks/useI18n";

function isGiftBoxExpired(giftBox?: GiftBox) {
  if (giftBox === undefined) {
    return true;
  }
  if (giftBox?.giftBoxStatus === GiftBoxStatus.Expired) {
    return true;
  }
  if (
    giftBox?.giftBoxStatus === GiftBoxStatus.Pending &&
    giftBox.expiredTime &&
    giftBox.expiredTime < new Date().getTime() / 1000
  ) {
    return true;
  }
  return false;
}

const HighLightTitle = styled.div`
  color: #fff;
  text-align: center;
  text-shadow: 0 0 12px #ff3a99;
  -webkit-text-stroke-width: 0.7px;
  -webkit-text-stroke-color: #ff24a0;
  font-size: 13px;
  font-weight: 900;
  line-height: normal;
`;

const NormalTitle = styled.div`
  color: rgba(255, 255, 255, 0.4);
  font-size: 13px;
`;

const StateLabel = styled.div`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.4);
`;

export default function MsgContentGiftBox(props: { msg: ChatMessage }) {
  const giftBox = props.msg.giftBox;
  const i18n = useI18n();

  let backgroundImg;
  let status;

  if (giftBox?.lastReadTime === undefined) {
    backgroundImg = icNewGift;
  } else if (isGiftBoxExpired(giftBox)) {
    backgroundImg = icExpiredGift;
    status = i18n.web3_v0_expired();
  } else {
    backgroundImg = icOpenedGift;
    status = i18n.hongbao_impr_opened();
  }

  return (
    <div style={{ position: "relative" }}>
      <Image src={backgroundImg} />
      <HStack
        style={{
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
          paddingLeft: "16px",
        }}
      >
        {!status && <HighLightTitle>{giftBox?.title}</HighLightTitle>}
        {status && <NormalTitle>{giftBox?.title}</NormalTitle>}
        {status && <StateLabel>{status}</StateLabel>}
      </HStack>
    </div>
  );
}
