import { z } from "zod";
import { zStatic } from "../../../utils/zodUtils";
import { Currency } from "../../../proto/Currency";

export const CreateTokenPresets = z.object({
  purchaseRatio: z.string(),
  deployFeeSol: Currency,
  deployFeeGems: Currency,
  purchaseFeeGems: Currency,
  purchaseFeeSol: Currency,
  chainType: z.number(),
  supply: z.number(),
  isFree: z.boolean().default(false),
});

export type CreateTokenPresets = zStatic<typeof CreateTokenPresets>;
