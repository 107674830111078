import { createContext, useContext, useMemo } from "react";
import { UrlQuery } from "../utils/UrlUtil";
import { useStringSearchParam } from "./useTypedParam";
import { StateId } from "./StateId";
import { anyToBase64, base64ToUnknown } from "../utils/Blob";
import { ModalController } from "../components/Modal";
import { useLayerId } from "../appshell/LayerBoundary";
import { useHopId } from "./useHopState";
import { useLocation } from "react-router-dom";
import { getExternalAppShellData } from "../bridge/ExternalAppShellData";

export type HopperMode =
  | "push"
  | "present"
  | "modal"
  | "layer"
  | "replace"
  | "popOver"
  | "startSide"
  | "endSide";

export type HopperRefer = {
  layerId: string;
  hopId: string;
};

export abstract class AbstractHopper {
  abstract openBridge(
    path: string,
    param:
      | {
          mode?: HopperMode;
          resultId?: string;
          resultEnv?: any;
        }
      | undefined,
    query: UrlQuery | undefined,
    refer: HopperRefer | undefined,
  ): void;

  push(path: string, query?: UrlQuery) {
    this.openBridge(path, undefined, query, undefined);
  }

  abstract back(): void;

  abstract dismissLayer(): void;

  abstract dismissLayerAndPush(path: string, query?: UrlQuery): void;

  abstract dismissModal(): void;

  addModalController(controller: ModalController) {}

  removeModalController(controller: ModalController) {}
}

export class Hopper {
  constructor(
    private readonly wrapped: AbstractHopper,
    private readonly layerId: string,
    private readonly hopId: string,
  ) {}

  openBridge(
    path: string,
    param?: {
      mode?: HopperMode;
      resultId?: string;
      resultEnv?: any;
    },
    query?: UrlQuery,
  ) {
    this.wrapped.openBridge(path, param, query, {
      layerId: this.layerId,
      hopId: this.hopId,
    });
  }

  push(path: string, query?: UrlQuery) {
    this.openBridge(path, undefined, query);
  }

  replace(path: string, query?: UrlQuery) {
    this.openBridge(path, { mode: "replace" }, query);
  }

  present(path: string, query?: UrlQuery) {
    this.openBridge(path, { mode: "present" }, query);
  }

  modal(path: string, query?: UrlQuery) {
    this.openBridge(path, { mode: "modal" }, query);
  }

  layer(path: string, query?: UrlQuery) {
    this.openBridge(path, { mode: "layer" }, query);
  }

  popOver(path: string, query?: UrlQuery) {
    this.openBridge(path, { mode: "popOver" }, query);
  }

  startSide(path: string, query?: UrlQuery) {
    this.openBridge(path, { mode: "startSide" }, query);
  }

  endSide(path: string, query?: UrlQuery) {
    this.openBridge(path, { mode: "endSide" }, query);
  }

  requestPush(path: string, resultId: StateId, query?: UrlQuery) {
    this.openBridge(path, { resultId: anyToBase64(resultId) }, query);
  }

  requestPresent(path: string, resultId: StateId, query?: UrlQuery) {
    this.openBridge(
      path,
      { mode: "present", resultId: anyToBase64(resultId) },
      query,
    );
  }

  back() {
    this.wrapped.back();
  }

  dismissLayer() {
    this.wrapped.dismissLayer();
  }

  dismissLayerAndPush(path: string, query?: UrlQuery) {
    this.wrapped.dismissLayerAndPush(path, query);
  }

  dismissModal() {
    this.wrapped.dismissModal();
  }

  addModalController(controller: ModalController) {
    this.wrapped.addModalController(controller);
  }

  removeModalController(controller: ModalController) {
    this.wrapped.removeModalController(controller);
  }
}

export interface HopperContext {
  hopper: AbstractHopper;
}

export const HopperContext = createContext<HopperContext>({
  hopper: {} as AbstractHopper,
});

export function useHopper() {
  const context = useContext(HopperContext);
  const layerId = useLayerId();
  const hopId = useHopId();

  return useMemo(
    () => new Hopper(context.hopper, layerId, hopId),
    [context.hopper],
  );
}

function isHopperRefer(refer: any): refer is HopperRefer {
  if (typeof refer !== "object") {
    return false;
  }
  if (!("layerId" in refer)) {
    return false;
  }
  if (!("hopId" in refer)) {
    return false;
  }

  return typeof refer.layerId === "string" && typeof refer.hopId === "string";
}

export function useReferId(): string | undefined {
  const location = useLocation();
  return useMemo(() => {
    if (
      location.state &&
      typeof location.state === "object" &&
      "refer" in location.state &&
      isHopperRefer(location.state.refer)
    ) {
      return location.state.refer.hopId;
    } else {
      const extData = getExternalAppShellData();
      console.log("extData", extData);
      return extData?.refer?.hopId;
    }
  }, [location.state]);
}

export function useConsumerId() {
  const base64 = useStringSearchParam("resultId")!!;
  return base64ToUnknown(base64) as StateId;
}
