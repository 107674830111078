import React, {
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";

interface CodeInputProps {
  onlyNumbers?: boolean;
  onChange: (value: string) => void;
  returnOnEachChange?: boolean;
}

const CodeInput = forwardRef<
  {
    focusLastFilledInput: () => void;
  },
  CodeInputProps
>(({ onlyNumbers = false, onChange, returnOnEachChange = false }, ref) => {
  const [values, setValues] = useState<string[]>(Array(6).fill(""));
  const inputsRef = useRef<(HTMLInputElement | null)[]>([]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    let { value } = e.target;
    const validInput = onlyNumbers ? /^[0-9]$/ : /^[a-zA-Z0-9]$/;
    if (value.length <= 1 && validInput.test(value)) {
      value = value.toUpperCase();
      const newValues = [...values];
      newValues[index] = value;
      setValues(newValues);

      if (returnOnEachChange) {
        onChange(newValues.join(""));
      } else if (newValues.every((char) => char !== "")) {
        onChange(newValues.join(""));
      }

      if (value && index < 5) {
        const nextInput = inputsRef.current[index + 1];
        if (nextInput) {
          nextInput.focus();
        }
      }
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number,
  ) => {
    if (e.key === "Backspace") {
      const newValues = [...values];
      if (!values[index] && index > 0) {
        const prevInput = inputsRef.current[index - 1];
        if (prevInput) {
          prevInput.focus();
        }
      } else {
        newValues[index] = "";
        setValues(newValues);
        if (returnOnEachChange) {
          onChange(newValues.join(""));
        }
      }
    }
  };

  // 聚焦到最后一个非空输入框的下一个
  const focusLastFilledInput = () => {
    const lastFilledIndex = values.findIndex((val) => val === "");
    const focusIndex =
      lastFilledIndex === -1 ? values.length - 1 : lastFilledIndex;

    const targetInput = inputsRef.current[focusIndex];
    if (targetInput) {
      targetInput.focus();
    }
  };

  // 使用 useImperativeHandle 暴露方法给父组件
  useImperativeHandle(ref, () => ({
    focusLastFilledInput,
  }));

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        gap: "10px",
        alignItems: "center",
      }}
    >
      {values.map((value, index) => (
        <input
          key={index}
          id={`input-${index}`}
          ref={(el) => (inputsRef.current[index] = el)}
          type={onlyNumbers ? "number" : "text"}
          maxLength={1}
          value={value}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          style={{
            width: "46px",
            height: "46px",
            textAlign: "center",
            fontSize: "34px",
            borderRadius: "6px",
            backgroundColor: "#2d2d2d",
            color: "#fff",
            border: "1px solid #555",
            padding: "0",
          }}
        />
      ))}
    </div>
  );
});

export default CodeInput;
