import { SliderDemoPage } from "./SliderDemoPage";
import { ButtonDemoPage } from "./ButtonDemoPage";
import { HapticDemoPage } from "./HapticDemoPage";
import { HooksDemoPage } from "./HooksDemoPage";
import {
  DemoBridgeBottomSheetPage,
  DemoBridgeModalPage,
  ModalDemoPage,
} from "./ModalDemoPage";
import { ComponentsDemoPage } from "./ComponentsDemoPage";
import { InputDemoPage } from "./InputDemoPage";
import React from "react";
import { ContentGroupDemoPage } from "./ContentGroupDemoPage";
import { ImageViewDemoPage } from "./ImageViewDemoPage";
import { SpeedTitleDemoPage } from "./SpeedTitleDemoPage";
import { PressedEffectDemoPage } from "./PressedEffectDemoPage";
import { InstructionDemoPage } from "./InstructionDemoPage";
import { PowerMarkdownDemoPage } from "./PowerMarkdownDemoPage";
import { PageBgDemoPage } from "./PageBgDemoPage";
import { HopMapDemoPage, HopStateDemoPage } from "./HopStateDemoPage";
import { CountDownDemoPage } from "./CountDownDemoPage";
import { SimpleListDemoPage } from "./SimpleListDemoPage";
import { PageArchDemoPage } from "./PageArchDemoPage";
import { SimpleDataDemoPage } from "./SimpleDataDemoPage";
import { FilterTabDemoPage } from "./FilterTabDemoPage";
import { PageFooterDemoPage } from "./PageFooterDemoPage";
import { AutoFitTextPage } from "./AutoFitTextPage";
import { RouteObject } from "react-router-dom";
import { DebugHomePage } from "./DebugHomePage";
import { ViewPager2DemoPage } from "./ViewPager2DemoPage";
import { LocalRepoDemoEditPage, LocalRepoDemoPage } from "./LocalRepoDemoPage";
import { MediaPickerDemoPage } from "./MediaPickerDemoPage";
import { HopperLayerDemoPage } from "./HopperLayerDemoPage";
import { MonetaryValueDemoPage } from "./MonetaryValueDemoPage";
import { HelpMDDemo } from "./HelpMDDemo";
import {
  ResultHookDemoPage,
  ResultProducerDifferentLayerSinglePage,
  ResultProducerFinalPage,
  ResultProducerIntermediatePage,
  ResultProducerSameLayerPage,
} from "./ResultHookDemoPage";
import { LocalRepoMapDemoPage, MemoryRepoMapDemoPage } from "./RepoMapDemoPage";

export const debugPagesRoute: RouteObject[] = [
  {
    path: "/debug-home",
    element: <DebugHomePage />,
  },
  {
    path: "/slider-demo",
    element: <SliderDemoPage />,
  },

  {
    path: "/button-demo",
    element: <ButtonDemoPage />,
  },
  {
    path: "/imageview-demo",
    element: <ImageViewDemoPage />,
  },
  {
    path: "/haptic-demo",
    element: <HapticDemoPage />,
  },
  {
    path: "/modal-demo",
    element: <ModalDemoPage />,
  },
  {
    path: "/components-demo",
    element: <ComponentsDemoPage />,
  },
  // {
  //   path: "/chart-demo",
  //   element: <ChartDemoPage />,
  // },
  {
    path: "/input-demo",
    element: <InputDemoPage />,
  },
  {
    path: "/demo-bridge-modal",
    element: <DemoBridgeModalPage />,
  },
  {
    path: "/demo-bridge-bottom-sheet",
    element: <DemoBridgeBottomSheetPage />,
  },

  {
    path: "/content-group-demo",
    element: <ContentGroupDemoPage />,
  },

  {
    path: "/speed-title-demo",
    element: <SpeedTitleDemoPage />,
  },

  {
    path: "/pressed-effect-demo",
    element: <PressedEffectDemoPage />,
  },

  {
    path: "/instruction-demo",
    element: <InstructionDemoPage />,
  },

  {
    path: "/power-markdown-demo",
    element: <PowerMarkdownDemoPage />,
  },

  {
    path: "/page-bg-demo",
    element: <PageBgDemoPage />,
  },

  {
    path: "/hop-state-demo",
    element: <HopStateDemoPage />,
  },
  {
    path: "/hop-map-demo",
    element: <HopMapDemoPage />,
  },
  {
    path: "/hooks-demo",
    element: <HooksDemoPage />,
  },
  {
    path: "/count-down-demo",
    element: <CountDownDemoPage />,
  },
  {
    path: "/count-down-demo",
    element: <CountDownDemoPage />,
  },
  {
    path: "/page-arch-demo",
    element: <PageArchDemoPage />,
  },
  {
    path: "/simple-list-demo",
    element: <SimpleListDemoPage />,
  },
  {
    path: "/simple-data-demo",
    element: <SimpleDataDemoPage />,
  },
  {
    path: "/filter-tab-demo",
    element: <FilterTabDemoPage />,
  },
  {
    path: "/page-footer-demo",
    element: <PageFooterDemoPage />,
  },
  {
    path: "/auto-fit-text-demo",
    element: <AutoFitTextPage />,
  },
  {
    path: "/view-pager2-demo",
    element: <ViewPager2DemoPage />,
  },
  {
    path: "/local-repo-demo",
    element: <LocalRepoDemoPage />,
  },
  {
    path: "/local-repo-demo-edit",
    element: <LocalRepoDemoEditPage />,
  },

  {
    path: "/media-picker-demo",
    element: <MediaPickerDemoPage />,
  },
  {
    path: "/hopper-layer-demo",
    element: <HopperLayerDemoPage />,
  },
  {
    path: "/monetary-value-demo",
    element: <MonetaryValueDemoPage />,
  },
  {
    path: "/help-demo",
    element: <HelpMDDemo />,
  },

  {
    path: "/result-hook-demo",
    element: <ResultHookDemoPage />,
  },
  {
    path: "/result-producer-same-layer-demo",
    element: <ResultProducerSameLayerPage />,
  },
  {
    path: "/result-producer-different-layer-single-demo",
    element: <ResultProducerDifferentLayerSinglePage />,
  },
  {
    path: "/result-producer-intermediate-demo",
    element: <ResultProducerIntermediatePage />,
  },
  {
    path: "/result-producer-final-demo",
    element: <ResultProducerFinalPage />,
  },
  {
    path: "/memory-repo-map-demo",
    element: <MemoryRepoMapDemoPage />,
  },
  {
    path: "/local-repo-map-demo",
    element: <LocalRepoMapDemoPage />,
  },
];
