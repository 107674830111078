import { z } from "zod";
import { Circle } from "./Circle";
import { zEnum, zStatic } from "../utils/zodUtils";
import { Media } from "./Media";

export enum SocialPlatformType {
  YouTube = 1,
  TikTok = 2,
  Instagram = 3,
  Twitter = 4,
  Reddit = 5,
  Discord = 6,
  Twitch = 7,
  Weibo = 8,
}

export const ExtSocialLink = z.object({
  icon: Media.optional(),
  link: z.string().optional(),
  name: z.string().optional(),
  platformType: zEnum(SocialPlatformType).optional(),
  username: z.string().optional(),
});

export type ExtSocialLink = zStatic<typeof ExtSocialLink>;
export const SocialLinksResp = z.object({
  circleInfo: Circle.optional(),
  extSocialLinks: z.array(ExtSocialLink).optional(),
});

export type SocialLinksResp = zStatic<typeof SocialLinksResp>;
