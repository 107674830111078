import { Page } from "../../components/Page";
import { useSWRArray } from "../../hooks/swr/useSWRArray";
import { useBackend } from "../../service/APIService";
import { CommonUserCell } from "./CommonUserCell";
import { NavEnd, NavItem, NavMiddle, NavStart } from "../../components/NavBar";
import { useI18n } from "../../hooks/useI18n";
import { NavButtonType } from "../../bridge/NavButtonDesc";
import React, { useState } from "react";
import { useHopper } from "../../hooks/useHopper";
import { HStack } from "../../components/VStack";
import { Image } from "../../components/Image";
import deleteIcon from "../../res/images/delete_icon.svg";
import { andLog, useErrorHandler } from "../../components/handleError";
import { useNativePage } from "../../hooks/useBridge";
import { useGlobalSpinner } from "../../utils/globalSpinner";

function BlockedUsersPage() {
  const backend = useBackend();
  const i18n = useI18n();
  const hopper = useHopper();
  const handleError = useErrorHandler();
  const blockUserSWR = useSWRArray(backend.getBlockedUsers());
  const [manage, setManage] = useState<boolean>(false);
  const nativePage = useNativePage();
  const globalSpinner = useGlobalSpinner();

  const unBlock = async (uid: bigint) => {
    try {
      const re = await nativePage.alertAreYouSure(
        i18n.unblock_user_confirm(),
        i18n.action_unblock(),
        i18n.cancel(),
      );
      if (re) {
        globalSpinner(async () => {
          await backend.unblockUser(uid).run();
          await blockUserSWR.load();
        }).catch(andLog);
      }
    } catch (e) {
      handleError(e);
    }
  };
  return (
    <Page pageData={blockUserSWR}>
      {blockUserSWR.content.map((user) => (
        <HStack style={{ gap: 20 }}>
          {manage ? (
            <Image
              src={deleteIcon}
              width={20}
              height={20}
              onClick={() => {
                unBlock(user.uid).catch(andLog);
              }}
            />
          ) : null}

          <CommonUserCell user={user} />
        </HStack>
      ))}
      <NavMiddle>{i18n.blocked_list()}</NavMiddle>
      {!manage && (
        <NavEnd>
          {NavItem.image(NavButtonType.Manage, () => {
            setManage((p) => !p);
          })}
        </NavEnd>
      )}
      <NavStart>
        {NavItem.image(NavButtonType.Cross, () => {
          if (manage) {
            setManage((p) => !p);
          } else {
            hopper.back();
          }
        })}
      </NavStart>
    </Page>
  );
}

export default BlockedUsersPage;
