import { useI18n } from "../../../hooks/useI18n";
import { HStack } from "../../../components/VStack";
import { Image } from "../../../components/Image";
import styled from "styled-components";
import React, { useMemo } from "react";
import {
  Currency,
  formatMoney,
  getCurrencyIcon,
  getCurrencyName,
} from "../../../proto/Currency";
import { TransactionBody } from "./TransactionBody";
import BigNumber from "bignumber.js";
import { copyObject } from "../../../utils/copyObject";

export interface CurrencyTransactionProps {
  body: TransactionBody;
}

function CurrencyTransactionView(props: CurrencyTransactionProps) {
  const i18n = useI18n();
  const body = props.body;

  const paymentAmount = body.amount ?? "0";
  const paymentCurrency = useMemo(() => {
    if (body.isFree) {
      return copyObject(body.currency, {
        amount: "0",
      });
    } else {
      return BigNumber(paymentAmount).isGreaterThan(BigNumber("0"))
        ? copyObject(body.currency, {
            amount: paymentAmount,
          })
        : undefined;
    }
  }, [body]);
  const gasFeeCurrency = body.gasFeeCurrency;

  const shouldMergeCurrency =
    gasFeeCurrency &&
    gasFeeCurrency?.currencyType === paymentCurrency?.currencyType;

  const gasAmount = gasFeeCurrency?.amount ?? "0";
  const amount = BigNumber(paymentAmount).plus(BigNumber(gasAmount));
  const mergedCurrency =
    body.payWithGems ??
    copyObject(paymentCurrency, {
      amount: body.totalPrice ?? amount?.toFixed(0),
    });

  return (
    <Container>
      {shouldMergeCurrency && mergedCurrency ? (
        <HStack style={{ gap: 8, justifyContent: "center" }}>
          <Image
            src={getCurrencyIcon(mergedCurrency)}
            width={24}
            height={24}
            style={{ borderRadius: 4 }}
          />
          <BalanceValue>{formatMoney("medium", mergedCurrency)}</BalanceValue>
          <CurrencyName>{getCurrencyName(mergedCurrency, i18n)}</CurrencyName>
        </HStack>
      ) : (
        <>
          {paymentCurrency && (
            <HStack style={{ gap: 8, justifyContent: "center" }}>
              <Image
                src={getCurrencyIcon(paymentCurrency)}
                width={24}
                height={24}
                style={{ borderRadius: 4 }}
              />
              <BalanceValue>
                {formatMoney("medium", paymentCurrency)}
              </BalanceValue>
              <CurrencyName>
                {getCurrencyName(paymentCurrency, i18n)}
              </CurrencyName>
            </HStack>
          )}

          {gasFeeCurrency && (
            <>
              <HStack
                style={{ gap: 8, justifyContent: "center", marginTop: 12 }}
              >
                <Image
                  src={getCurrencyIcon(gasFeeCurrency)}
                  width={24}
                  height={24}
                  style={{ borderRadius: 4 }}
                />
                <BalanceValue>
                  {formatMoney("medium", gasFeeCurrency)}
                </BalanceValue>
                <CurrencyName>
                  {getCurrencyName(gasFeeCurrency, i18n)}
                </CurrencyName>
              </HStack>
            </>
          )}
        </>
      )}

      <TotalDueLabel>{i18n.web3_v0_total_due()}</TotalDueLabel>

      {shouldMergeCurrency && mergedCurrency ? (
        <PaymentBackground>
          <PayFromLabel>{i18n.web3_v0_pay_from_default_account()}</PayFromLabel>

          <HStack style={{ justifyContent: "space-between" }}>
            <CurrencyNameStrong>
              {getCurrencyName(mergedCurrency, i18n)}
            </CurrencyNameStrong>

            <HStack style={{ gap: 8, justifyContent: "center" }}>
              <Image
                src={getCurrencyIcon(mergedCurrency)}
                width={24}
                height={24}
                style={{ borderRadius: 4 }}
              />
              <BalanceValueMini>
                {formatMoney("medium", mergedCurrency)}
              </BalanceValueMini>
            </HStack>
          </HStack>
        </PaymentBackground>
      ) : (
        <PaymentBackground>
          <PayFromLabel>{i18n.web3_v0_pay_from_default_account()}</PayFromLabel>

          {paymentCurrency && (
            <HStack style={{ justifyContent: "space-between" }}>
              <CurrencyNameStrong>
                {getCurrencyName(paymentCurrency, i18n)}
              </CurrencyNameStrong>

              <HStack style={{ gap: 8, justifyContent: "center" }}>
                <Image
                  src={getCurrencyIcon(paymentCurrency)}
                  width={24}
                  height={24}
                  style={{ borderRadius: 4 }}
                />
                <BalanceValueMini>
                  {formatMoney("medium", paymentCurrency)}
                </BalanceValueMini>
              </HStack>
            </HStack>
          )}

          {gasFeeCurrency && (
            <>
              <HStack style={{ justifyContent: "space-between" }}>
                <CurrencyNameStrong>
                  {getCurrencyName(gasFeeCurrency, i18n)}
                </CurrencyNameStrong>

                <HStack style={{ gap: 8, justifyContent: "center" }}>
                  <Image
                    src={getCurrencyIcon(gasFeeCurrency)}
                    width={24}
                    height={24}
                    style={{ borderRadius: 4 }}
                  />
                  <BalanceValueMini>
                    {formatMoney("medium", gasFeeCurrency)}
                  </BalanceValueMini>
                </HStack>
              </HStack>
            </>
          )}
        </PaymentBackground>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  margin-inline-start: 20px;
  margin-inline-end: 20px;
  flex-direction: column;
`;

const BalanceValue = styled.div`
  color: var(--color-text00);
  font-weight: 700;
  font-size: 24px;
`;

const BalanceValueMini = styled.div`
  color: var(--color-text00);
  font-weight: 400;
  font-size: 14px;
`;

const CurrencyName = styled.div`
  color: rgba(255, 255, 255, 0.6);
  font-weight: 400;
  font-size: 14px;
`;

const TotalDueLabel = styled.div`
  color: rgba(255, 255, 255, 0.4);
  margin-top: 12px;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
`;

const PayFromLabel = styled.div`
  color: rgba(255, 255, 255, 0.4);
  font-weight: 400;
  font-size: 12px;
`;

const CurrencyNameStrong = styled.div`
  color: var(--color-text10);
  font-weight: 400;
  font-size: 14px;
`;

const PaymentBackground = styled.div`
  background-color: rgba(255, 255, 255, 0.06);
  margin-top: 30px;
  display: flex;
  gap: 6px;
  border-radius: 6px;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px 16px;
`;

export default CurrencyTransactionView;
