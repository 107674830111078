import {
  ClientAck,
  getWSMsgUniqueId,
  isMsgNeedAck,
  WSMessage,
  WSType,
} from "../proto/WSMessage";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useAuthSessionService, useMyUid } from "./AuthSessionService";
import { andLog } from "../components/handleError";
import { JSONUtil } from "../utils/JSONUtil";
import { ChatMessageType, needSendAckFor } from "../proto/ChatMessage";
import { useBackend } from "./APIService";
import { WS_URL } from "../config/config";

export interface WSListener {
  onMessage: (msg: WSMessage) => void;
  onAck: (msg: WSMessage) => void;
  onFailureSend: (msg: WSMessage) => void;
  uniqueKey: string;
}

export enum WebSocketStatus {
  CONNECTING = 0,
  OPEN = 1,
  CLOSING = 2,
  CLOSED = 3,
}

export interface WebSocketContext {
  wsStatus: number;
  addListener: (listener: WSListener) => void;
  removeListener: (listener: WSListener) => void;
  send: (msg: WSMessage) => void;
  enterChat: (threadId: bigint) => void;
  leaveChat: (threadId: bigint) => void;
}

const Context = createContext<WebSocketContext>({} as WebSocketContext);

export function WebSocketService(props: PropsWithChildren<{}>) {
  const sessionService = useAuthSessionService();
  const myUid = useMyUid();
  const backend = useBackend();

  const [ws, setWs] = useState<WebSocket | undefined>(undefined);
  const listeners = useRef<WSListener[]>([]);
  const [status, setStatus] = useState(WebSocketStatus.CONNECTING);
  const statusRef = useRef(WebSocketStatus.CONNECTING);

  const isConnecting = useRef(false);
  const pingIntervalRef = useRef<number | undefined>(undefined);
  const reconnectIntervalRef = useRef<number | undefined>(undefined);
  const pongTime = useRef<Date | undefined>(undefined);

  const messageQueue = useRef<WSMessage[]>([]);
  const sendingQueue = useRef<WSMessage[]>([]);

  const activeThreadId = useRef<bigint | undefined>(undefined);

  const getSession = useCallback(async () => {
    if (sessionService.myUid === undefined) {
      return undefined;
    }
    return await sessionService.get(sessionService.myUid);
  }, [sessionService.myUid, sessionService.get]);

  const trySend = (msg: WSMessage | string) => {
    let sendStr = typeof msg === "string" ? msg : JSONUtil.stringify(msg);
    if (ws && statusRef.current === WebSocketStatus.OPEN) {
      try {
        ws.send(sendStr);
        return true;
      } catch (e) {
        console.error("ws send with error", e);
        setStatus(WebSocketStatus.CLOSED);
        statusRef.current = WebSocketStatus.CLOSED;
        connect().catch(andLog);
        return false;
      }
    } else if (!ws || statusRef.current === WebSocketStatus.CLOSED) {
      console.log("ws connect before send", ws, status);
      connect().catch(andLog);
      return false;
    }
  };

  const connect = async () => {
    const sid = (await getSession())?.sid;
    if (!sid) {
      return;
    }

    if (isConnecting.current) {
      return;
    }
    isConnecting.current = true;

    if (ws) {
      ws.onclose = null;
      ws.onerror = null;
      ws.close();
    }
    console.log("ws connecting");
    setStatus(WebSocketStatus.CONNECTING);
    statusRef.current = WebSocketStatus.CONNECTING;

    const socket = new WebSocket(`${WS_URL}/v1/chat/web-ws?sId=${sid}`);
    setWs(socket);

    socket.onopen = () => {
      console.log("ws on open", status);
      setStatus(WebSocketStatus.OPEN);
      statusRef.current = WebSocketStatus.OPEN;
      console.log("ws on open 2", status);
      isConnecting.current = false;
      setTimeout(sendIfNeed, 2000);
    };

    socket.onclose = (e) => {
      console.log("ws on close", ws, e);
      if (e.code === 1006) {
        // session invalid, call any api to trigger re-auth
        backend.getUser(myUid).run().catch(andLog);
      }
      setStatus(WebSocketStatus.CLOSED);
      statusRef.current = WebSocketStatus.CLOSED;
    };

    socket.onerror = (error) => {
      console.log("ws on error", error);
      isConnecting.current = false;
    };

    socket.onmessage = (wsMsg) => {
      const msgData = wsMsg.data;
      if (msgData === "Pong") {
        // reset time
        console.log("ws: get pong");
        pongTime.current = new Date();
      } else {
        const wsMsg = JSONUtil.parse(msgData, WSMessage);

        if (wsMsg.t === WSType.ServerAck) {
          const inQueueMsg = sendingQueue.current.find(
            (msg) => getWSMsgUniqueId(msg) === getWSMsgUniqueId(wsMsg),
          );
          if (inQueueMsg && inQueueMsg.msg && wsMsg.serverAck) {
            sendingQueue.current = sendingQueue.current.filter(
              (msg) => getWSMsgUniqueId(msg) !== getWSMsgUniqueId(inQueueMsg),
            );
            if (
              wsMsg.serverAck.apiCode === undefined ||
              wsMsg.serverAck.apiCode === 0
            ) {
              inQueueMsg.msg.messageId = wsMsg.serverAck.messageId;
              inQueueMsg.msg.createdTime = wsMsg.serverAck.createdTime;
              if (inQueueMsg.msg.extensions) {
                inQueueMsg.msg.extensions.diceSideId =
                  wsMsg.serverAck.diceSideId;
              }
              listeners.current.forEach((listener) => {
                listener.onAck(inQueueMsg);
              });
            } else {
              inQueueMsg.msg.errorCode = wsMsg.serverAck.apiCode;
              inQueueMsg.msg.errorMsg = wsMsg.serverAck.apiMsg;
              listeners.current.forEach((listener) => {
                listener.onFailureSend(inQueueMsg);
              });
            }
          }
        } else if (wsMsg.t === WSType.ForceQuit) {
          sessionService.remove(myUid).catch(andLog);
        } else {
          listeners.current.forEach((listener) => {
            listener.onMessage(wsMsg);
          });
          if (wsMsg.msg && needSendAckFor(wsMsg.msg)) {
            const msg = wsMsg.msg;
            let shouldMarkAsRead = false;
            if (
              msg.type === ChatMessageType.SmallNote ||
              msg.type === ChatMessageType.OnlyText
            ) {
              shouldMarkAsRead = false;
            } else {
              shouldMarkAsRead =
                msg.threadId === activeThreadId.current || myUid === msg.uid;
            }
            const ack = {
              threadId: wsMsg.msg.threadId,
              messageId: wsMsg.msg.messageId,
              markAsRead: shouldMarkAsRead,
            } as ClientAck;
            const wsAck = {
              t: WSType.ClientAck,
              clientAck: ack,
            } as WSMessage;
            trySend(wsAck);
          }
        }
      }
    };
  };

  const sendIfNeed = () => {
    if (messageQueue.current.length === 0) {
      return;
    }
    if (ws && statusRef.current === WebSocketStatus.OPEN) {
      const msg = messageQueue.current.shift();
      if (msg) {
        if (trySend(msg)) {
          if (isMsgNeedAck(msg)) {
            sendingQueue.current.push(msg);
          }
        } else {
          messageQueue.current.unshift(msg);
        }
      }
    }
  };

  useEffect(() => {
    const handleVisibilityChangeOrFocus = () => {
      if (document.visibilityState === "visible" || document.hasFocus()) {
        if (ws && statusRef.current === WebSocketStatus.OPEN) {
        } else {
          console.log("ws visible reconnect");
          connect().catch(andLog);
        }
      }
    };

    document.addEventListener(
      "visibilitychange",
      handleVisibilityChangeOrFocus,
    );
    window.addEventListener("focus", handleVisibilityChangeOrFocus);

    return () => {
      document.removeEventListener(
        "visibilitychange",
        handleVisibilityChangeOrFocus,
      );
      window.removeEventListener("focus", handleVisibilityChangeOrFocus);
    };
  }, [status, connect, ws]);

  useEffect(() => {
    console.log("ws uid connect");
    connect().catch(andLog);
    return () => {
      if (pingIntervalRef.current !== undefined) {
        clearInterval(pingIntervalRef.current);
      }
      if (reconnectIntervalRef.current !== undefined) {
        clearInterval(reconnectIntervalRef.current);
      }
      ws?.close();
    };
  }, [myUid]);

  useEffect(() => {
    const pingWebSocket = () => {
      trySend("Ping");
    };

    pingIntervalRef.current = window.setInterval(() => {
      pingWebSocket();
    }, 10000);

    return () => {
      if (pingIntervalRef.current !== undefined) {
        clearInterval(pingIntervalRef.current);
        pingIntervalRef.current = undefined;
      }
    };
  }, [ws, status]);

  const value: WebSocketContext = {
    wsStatus: status,
    addListener: (listener) => {
      listeners.current.push(listener);
    },
    removeListener: (listener) => {
      listeners.current = listeners.current.filter(
        (l) => l.uniqueKey !== listener.uniqueKey,
      );
    },
    send: (msg) => {
      messageQueue.current.push(msg);
      sendIfNeed();
    },
    enterChat: (threadId) => {
      activeThreadId.current = threadId;
    },
    leaveChat: (threadId) => {
      activeThreadId.current = undefined;
    },
  };
  return <Context.Provider value={value}>{props.children}</Context.Provider>;
}

export function useWS() {
  return useContext(Context);
}
