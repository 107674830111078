import { Page } from "../../components/Page";
import { useBackend } from "../../service/APIService";
import { useSWRArray } from "../../hooks/swr/useSWRArray";
import { useMyUid } from "../../service/AuthSessionService";
import { CommonUserCell } from "./CommonUserCell";
import { NavEnd, NavItem, NavMiddle } from "../../components/NavBar";
import { NavButtonType } from "../../bridge/NavButtonDesc";
import React from "react";
import { useI18n } from "../../hooks/useI18n";
import styled from "styled-components";
import { useSWRList } from "../../hooks/swr/useSWRList";
import { VStack } from "../../components/VStack";
import { useBigIntParam } from "../../hooks/useTypedParam";

function RecentVisitorsPage() {
  const backend = useBackend();
  const uid = useBigIntParam("uid");
  const i18n = useI18n();
  const visitorSWR = useSWRList(backend.getSocialUsers(uid, "visitor"));

  return (
    <Page pageData={visitorSWR}>
      {visitorSWR.content?.list?.map((user) => (
        <CommonUserCell
          key={user.uid}
          user={user}
          onUpdate={() => visitorSWR.load()}
        >
          {user.userVisitLog?.visitTimeMs && (
            <RecentVisitor>
              {i18n.recent_visits_last_visit_time(
                Intl.DateTimeFormat(navigator.language, {
                  dateStyle: "medium",
                }).format(user.userVisitLog?.visitTimeMs),
              )}
            </RecentVisitor>
          )}
        </CommonUserCell>
      ))}
      <NavMiddle>
        <VStack style={{ alignItems: "center" }}>
          {i18n.recent_visitors()}
          <Subtitle>
            {i18n.recent_visits_total_count(visitorSWR.content?.total ?? 0)}
          </Subtitle>
        </VStack>
      </NavMiddle>

      {/*<NavEnd>{NavItem.image(NavButtonType.Manage, () => {})}</NavEnd>*/}
    </Page>
  );
}

const RecentVisitor = styled.div`
  color: rgba(255, 255, 255, 0.4);
  font-family: DIN, sans-serif;
  font-size: 10px;
  font-weight: 500;
`;

const Subtitle = styled.div`
  color: rgba(255, 255, 255, 0.5);
  font-size: 10px;
`;

export default RecentVisitorsPage;
