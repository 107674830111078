import { ModalController } from "../../../components/Modal";
import React from "react";
import { useI18n } from "../../../hooks/useI18n";
import { useNativePage } from "../../../hooks/useBridge";
import { AssetAccount } from "../../../proto/AssetAccount";
import { BSMenu, BSMenuItem } from "../../../components/BSMenu";
import { ChainType } from "../../../proto/ChainType";
import icCopy from "../../../res/images/ic_copy_address.svg";
import { useCopyText } from "../../../utils/CopyText";
import { andLog, useErrorHandler } from "../../../components/handleError";

export interface IAccountPickerProps {
  modal: ModalController;
  addressInfos?: AssetAccount[];
}

function AddressPicker(props: IAccountPickerProps) {
  const i18n = useI18n();
  const nativePage = useNativePage();
  const copyText = useCopyText();
  const handleError = useErrorHandler();

  return (
    <BSMenu modal={props.modal} title={i18n.clover_my_account_address()}>
      {props.addressInfos
        ?.filter((addressInfo) => !!addressInfo.address)
        .map((addressInfo) => (
          <BSMenuItem
            key={addressInfo.address}
            title={
              addressInfo.chainType === ChainType.Solana ||
              addressInfo.chainType === ChainType.SolanaTestnet
                ? i18n.clover_solana_chain()
                : undefined
            }
            subtitle={addressInfo.address}
            trailingItem={
              <img src={icCopy} alt={"copy address"} width={20} height={20} />
            }
            onClick={() => {
              if (addressInfo.address) {
                copyText(addressInfo.address).catch(andLog);
              }
            }}
          />
        ))}
    </BSMenu>
  );
}

export default AddressPicker;
