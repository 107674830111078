import { Page } from "../../../components/Page";
import { useI18n } from "../../../hooks/useI18n";
import TabCellSeparator from "../../../components/TabCellSeparator";
import SettingCell from "../../../components/SettingCell";
import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import iconGreenSelected from "../../../res/images/icon_green_selected.svg";
import { Image } from "../../../components/Image";
import Switch from "../../settings/components/Switch";
import { CreateCircleData } from "./ComposeCircleContext";
import { HStack } from "../../../components/VStack";
import { CenterModal, ModalTitle, useModal } from "../../../components/Modal";
import { andLog, useErrorHandler } from "../../../components/handleError";
import { useCreateCircleResult } from "./CreateCirclePage";
import icCreateBack from "../../../res/images/icon_create_circle_back.png";
import { PlaceholderButton, RegularButton } from "../../../components/Buttons";
import { PageFooter } from "../../../components/PageHeaderFooter";
import { useHopper } from "../../../hooks/useHopper";
import { NavEnd, NavItem, NavMiddle } from "../../../components/NavBar";
import { RegularInputGroup } from "../../../components/Input";
import { useNativePage } from "../../../hooks/useBridge";
import { StringValidator } from "../../../utils/StringValidator";
import { CircleJoinPermission, Question } from "../JoinCircleRequest";
import { RelativeContainer } from "../CircleHomePage";
import icLock from "../../../res/images/ic_permission_lock.svg";
import icAnswer from "../../../res/images/ic_permission_answer.svg";
import { useLoadState } from "../../../hooks/LoadState";
import { useBackend } from "../../../service/APIService";
import { CircleExtensions } from "../../../proto/Circle";
import { PowerMarkdown } from "../../../components/PowerMarkdown";
import { removeOptional } from "../../../utils/typeUtils";
import AdminAgreementDialog from "./AdminAgreementDialog";
import { Spin } from "../../../components/Spin";
import CircleBackgroundFrame from "../CircleBackgroundFrame";

function CreateCirclePermissionPage() {
  const i18n = useI18n();
  const hopper = useHopper();
  const nativePage = useNativePage();

  const editingCircle = useCreateCircleResult();
  const editingObject = editingCircle;

  const passcodeModal = useModal("passcode-modal");
  const questionModal = useModal("question-modal");

  const agreementModal = useModal("agreement-modal");

  async function onPreClick() {
    hopper.back();
  }

  async function onClickPermission(value: number) {
    if (value === 1 && editingObject.content.discoverabilityType === 2) {
      const chooseYes = await nativePage.alertYesOrCancel(
        i18n.circle_perm_change_permission_to_open(),
        i18n.yes(),
        i18n.cancel(),
      );
      if (chooseYes) {
        editingObject
          .fill((prevData: any) => ({
            ...prevData,
            privacy: value,
            discoverabilityType: 1,
          }))
          .catch(andLog);
      }
      return;
    }
    editingObject
      .fill((prevData: any) => ({
        ...prevData,
        privacy: value,
      }))
      .catch(andLog);
  }

  async function onClickDiscoverability(value: number) {
    if (value === 2 && editingObject.content.privacy === 1) {
      const chooseYes = await nativePage.alertYesOrCancel(
        i18n.circle_perm_change_discoverability_to_private(),
        i18n.yes(),
        i18n.cancel(),
      );
      if (chooseYes) {
        editingObject
          .fill((prevData: any) => ({
            ...prevData,
            privacy: 0,
            discoverabilityType: value,
          }))
          .catch(andLog);
      }
      return;
    }
    editingObject
      .fill((prevData: any) => ({
        ...prevData,
        discoverabilityType: value,
      }))
      .catch(andLog);
  }

  const [currQuestion, setCurrQuestion] = useState<string | undefined>(
    undefined,
  );
  const maxQuestionLength = 200;
  const onSubmitQuestionClick = () => {
    if (currQuestion && currQuestion?.length > maxQuestionLength) {
      nativePage
        .infoHud(i18n.text_limit_number_of_characters_not_follow())
        .catch(andLog);
      return;
    }

    editingCircle.fill((prevData: any) => ({
      ...prevData,
      question: currQuestion,
    }));
    questionModal.close();
  };

  function getRandomPasscode() {
    return `${Math.floor(Math.random() * 900_000) + 100_000}`;
  }

  const [newPasscode, setNewPasscode] = useState<string | undefined>();

  const createCircleTask = useLoadState();
  const backend = useBackend();
  const handleError = useErrorHandler();

  async function onCreateClick() {
    const r = await createCircleTask.run(async () => {
      const circleData = editingCircle.content;

      var resPermission = CircleJoinPermission.LOCKED;
      if (circleData.privacy === 1) {
        resPermission |= CircleJoinPermission.OPEN;
      }

      if (circleData.acceptJoinRequests) {
        resPermission |= CircleJoinPermission.ACCEPT_JOIN_REQUEST;
      }

      if (circleData.passcodeEnable) {
        resPermission |= CircleJoinPermission.USE_PASSCODE;
      }

      if (circleData.allowMembersInvite) {
        resPermission |= CircleJoinPermission.ALLOW_MEMBER_INVITE;
      }

      const createData = CreateCircleData.parse({
        name: circleData.name,
        tagline: circleData.tagline,
        circleIcon: circleData.circleIcon,
        cover: circleData.cover,
        circleBackground: circleData.circleBackground,
        categoryId: circleData.category?.categoryId,
        language: circleData.language,
        themeColor: circleData.themeColor,
        joinPermission: resPermission,
        joinPasscode: circleData.joinPasscode,
        tagStrList: circleData.tags,
        discoverability: circleData.discoverabilityType,
        extensions: CircleExtensions.parse({
          question: Question.parse({ content: circleData.question }),
        }),
      });
      const circle = await backend.createCircle(createData).run();
      hopper.dismissLayerAndPush(`circle/${circle.circleId}`);
    });

    if (!r.success) {
      handleError(r.error);
    }
  }

  return (
    <Page
      pageData={undefined}
      scrollPaddingDisabled={true}
      underlay={
        <CircleBackgroundFrame
          circleBackground={editingCircle.content.circleBackground}
        />
      }
    >
      <NavMiddle>{i18n.circle_create_title()}</NavMiddle>
      <NavEnd>
        {NavItem.text(i18n.help(), () => hopper.push("feedback"))}
      </NavEnd>
      <StrongTitle>{i18n.mar_impr_permission()}</StrongTitle>
      <TabCellSeparator title={i18n.circle_perm_join_permission()} />
      <SettingCell
        title={i18n.circle_perm_open()}
        secondaryValue={i18n.circle_perm_anyone_can_join()}
        showAccessoryImage={false}
        onClick={() => onClickPermission(1)}
        endValue={
          editingCircle.content.privacy === 1 ? (
            <Image src={iconGreenSelected} width={24} height={24} />
          ) : (
            <></>
          )
        }
      />

      <SettingCell
        style={{ paddingTop: 10, paddingBottom: 10 }}
        showAccessoryImage={false}
        showDivider={editingCircle.content.privacy === 2}
        title={i18n.circle_perm_locked()}
        onClick={() => onClickPermission(0)}
        endValue={
          (editingCircle.content.privacy & CircleJoinPermission.OPEN) !==
          CircleJoinPermission.OPEN ? (
            <Image src={iconGreenSelected} width={24} height={24} />
          ) : (
            <></>
          )
        }
        secondaryValue={i18n.circle_perm_only_selected_users_join()}
      />

      {editingCircle.content.privacy !== 1 && (
        <>
          <SettingCell
            title={i18n.circle_perm_accept_join_requests()}
            showDivider={!Boolean(editingObject.content.acceptJoinRequests)}
            showAccessoryImage={false}
            endValue={
              <Switch
                checked={editingObject.content?.acceptJoinRequests}
                onChange={(b) => {
                  if (b) {
                    editingObject.fill((prevData: any) => ({
                      ...prevData,
                      acceptJoinRequests: true,
                    }));
                  } else {
                    editingObject.fill((prevData: any) => ({
                      ...prevData,
                      acceptJoinRequests: false,
                      question: undefined,
                    }));
                  }
                }}
              />
            }
            primaryTitleMarginLeft={"32px"}
            secondaryValue={i18n.circle_perm_allow_non_members()}
            secondaryTitleMarginLeft={"32px"}
          ></SettingCell>
          {editingObject.content.acceptJoinRequests && (
            <RelativeContainer
              style={{
                paddingLeft: 50,
                paddingRight: 40,
              }}
            >
              <Image
                style={{ position: "absolute", top: 0, left: 50 }}
                src={icAnswer}
                alt={"icAnswer"}
                width={17}
                height={17}
              ></Image>
              <PlaceholderButton
                mixin={css`
                  font-size: 12px;
                  width: 100%;
                  min-height: 45px;
                  display: inline-block;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  justify-content: ${editingObject.content.question &&
                  editingObject.content.question.length > 0
                    ? "start"
                    : "center"};
                  color: ${editingObject.content.question &&
                  editingObject.content.question.length > 0
                    ? "var(--color-text00)"
                    : "var(--color-text10)"};
                `}
                onClick={() => {
                  questionModal.open();
                }}
              >
                {editingObject.content.question ?? i18n.add_a_question()}
              </PlaceholderButton>
              <div
                style={{
                  marginTop: 12,
                  height: 1,
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                }}
              ></div>
            </RelativeContainer>
          )}

          <SettingCell
            showAccessoryImage={false}
            primaryTitleMarginLeft={"32px"}
            showDivider={!Boolean(editingObject.content.joinPasscode)}
            endValue={
              <Switch
                checked={editingObject.content?.passcodeEnable}
                key={"enable-passcode"}
                onChange={(b) => {
                  if (b) {
                    console.log("asddasasffasdfas", b);
                    passcodeModal.open();
                    editingObject
                      .fill((prevData: any) => ({
                        ...prevData,
                        passcodeEnable: true,
                      }))
                      .catch(andLog);
                    setNewPasscode(getRandomPasscode);
                  } else {
                    editingObject
                      .fill((prevData: any) => ({
                        ...prevData,
                        passcodeEnable: false,
                        joinPasscode: undefined,
                      }))
                      .catch(andLog);
                  }
                }}
              />
            }
            title={i18n.circle_perm_security_passcode()}
            secondaryValue={i18n.circle_perm_users_enter_passcode_join()}
            secondaryTitleMarginLeft={"32px"}
          ></SettingCell>
          {Boolean(editingObject.content.joinPasscode) && (
            <RelativeContainer
              style={{
                paddingLeft: 50,
                paddingRight: 40,
              }}
            >
              <Image
                style={{ position: "absolute", top: 0, left: 50 }}
                src={icLock}
                alt={"icLock"}
                width={17}
                height={17}
              ></Image>
              <PlaceholderButton
                mixin={css`
                  font-size: 16px;
                  width: 100%;
                  min-height: 45px;
                  justify-content: start;
                  color: #ffffff;
                `}
                onClick={() => {
                  passcodeModal.open();
                }}
              >
                {editingObject.content.joinPasscode}
              </PlaceholderButton>
              <div
                style={{
                  marginTop: 12,
                  height: 1,
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                }}
              ></div>
            </RelativeContainer>
          )}
          <SettingCell
            primaryTitleMarginLeft={"32px"}
            showAccessoryImage={false}
            endValue={
              <Switch
                checked={editingObject.content?.allowMembersInvite}
                key={"allow-member-invite"}
                onChange={(b) => {
                  if (b) {
                    console.log("asddasasffasdfas", b);
                    editingObject
                      .fill((prevData: any) => ({
                        ...prevData,
                        allowMembersInvite: true,
                      }))
                      .catch(andLog);
                  } else {
                    editingObject
                      .fill((prevData: any) => ({
                        ...prevData,
                        allowMembersInvite: false,
                      }))
                      .catch(andLog);
                  }
                }}
              />
            }
            title={i18n.party_room_allow_invite()}
            secondaryValue={i18n.clover_users_can_join_via_invitations_from_existing()}
            secondaryTitleMarginLeft={"32px"}
          ></SettingCell>
        </>
      )}

      <TabCellSeparator title={i18n.circle_perm_discoverability()} />
      <SettingCell
        style={{ paddingTop: 10, paddingBottom: 10 }}
        showAccessoryImage={false}
        title={i18n.public_label()}
        onClick={() => onClickDiscoverability(1)}
        endValue={
          editingObject.content.discoverabilityType === 1 ? (
            <Image src={iconGreenSelected} width={24} height={24} />
          ) : (
            <></>
          )
        }
        secondaryValue={i18n.circle_perm_public_circle_listed_category()}
      />

      <SettingCell
        style={{ paddingTop: 10, paddingBottom: 10 }}
        showDivider={false}
        showAccessoryImage={false}
        onClick={() => onClickDiscoverability(2)}
        endValue={
          editingObject.content.discoverabilityType === 2 ? (
            <Image src={iconGreenSelected} width={24} height={24} />
          ) : (
            <></>
          )
        }
        title={i18n.circle_visibility_private()}
        secondaryValue={i18n.circle_perm_private_circle_doesnt_appear_category()}
      />

      <CenterModal
        modal={passcodeModal}
        onClickClose={() => {
          editingObject
            .fill((prevData) => ({
              ...prevData,
              passcodeEnable: false,
            }))
            .catch(andLog);
          passcodeModal.close();
        }}
      >
        <ModalTitle>{i18n.circle_perm_set_passcode()}</ModalTitle>
        <RegularInputGroup
          style={css`
            margin-left: -10px;
            margin-right: -10px;
          `}
          value={newPasscode}
          type={"number"}
          updateValue={setNewPasscode}
          maxLength={6}
        />
        <RegularButton
          style={{ marginLeft: "21px", marginRight: "21px" }}
          onClick={() => {
            if (newPasscode && !StringValidator.isAllDigits(newPasscode)) {
              nativePage
                .infoHud(i18n.circle_perm_enter_valid_passcode())
                .catch(andLog);
              return;
            }
            if (newPasscode?.length === 0) {
              nativePage
                .infoHud(i18n.circle_perm_enter_valid_passcode())
                .catch(andLog);
              return;
            }
            passcodeModal.close();

            editingObject
              .fill((prevData: any) => ({
                ...prevData,
                passcodeEnable: true,
                joinPasscode: newPasscode,
              }))
              .catch(andLog);
          }}
        >
          {i18n.save()}
        </RegularButton>
      </CenterModal>

      <CenterModal
        modal={questionModal}
        onClickClose={() => {
          setCurrQuestion(undefined);
          questionModal.close();
        }}
      >
        <ModalTitle>{i18n.question()}</ModalTitle>
        <RegularInputGroup
          style={css`
            margin-left: -10px;
            margin-right: -10px;
          `}
          value={currQuestion}
          updateValue={setCurrQuestion}
          placeholder={i18n.question_placeholder()}
          maxLength={maxQuestionLength}
          multilineRows={3}
        />
        <RegularButton
          style={{ marginLeft: "21px", marginRight: "21px" }}
          onClick={onSubmitQuestionClick}
        >
          {i18n.submit()}
        </RegularButton>
      </CenterModal>

      <AdminAgreementDialog
        onClickAgree={() => {
          onCreateClick().catch(andLog);
        }}
        modal={agreementModal}
      />

      <PageFooter obscuredZoneKey={"CreateCircleBottom"}>
        <HStack
          style={{
            gap: 10,
            display: "flex",
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 20,
          }}
        >
          <div onClick={onPreClick}>
            <Image src={icCreateBack} width={60} height={60} />
          </div>
          <RegularButton
            style={{ height: 60, flex: 1 }}
            onClick={() => {
              agreementModal.open();
            }}
          >
            <Spin state={createCircleTask.state}>{i18n.create()}</Spin>
          </RegularButton>
        </HStack>
      </PageFooter>
    </Page>
  );
}

const QuestionPreview = styled.div`
  font-weight: 600;
  font-size: 15px;
  margin-inline-start: 52px;
  margin-inline-end: 40px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.06);
  border: 1px dashed rgba(255, 255, 255, 0.7);
  padding: 14px;
  color: var(--color-text00);
`;

const PasscodePreview = styled.div`
  font-weight: 600;
  font-size: 15px;
  margin-inline-start: 52px;
  margin-inline-end: 40px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.06);
  border: 1px dashed rgba(255, 255, 255, 0.7);
  padding: 14px;
  color: var(--color-text00);
`;

const StrongTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  margin-inline-start: 20px;
  margin-bottom: 8px;
  color: var(--color-text00);
`;

export const service_privacy_markdown_style = css`
  color: var(--color-text00);
  font-weight: 500;
  font-size: 12px;

  a {
    color: var(--color-text00);
    text-decoration: underline;
  }
`;

export default CreateCirclePermissionPage;
