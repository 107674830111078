import { createContext, useContext } from "react";
import { RepoStore } from "../hooks/swr/RepoStore";

interface Context {
  createLocalRepoStore<T>(repoId: string): RepoStore<T>;

  createMemoryRepoStore<T>(contentId: string): RepoStore<T>;
}

export const RepoStoreBuilder = createContext<Context>({
  createLocalRepoStore<T>(repoId: string) {
    return {} as RepoStore<T>;
  },
  createMemoryRepoStore<T>(repoId: string) {
    return {} as RepoStore<T>;
  },
});

export function useRepoStoreBuilder() {
  return useContext(RepoStoreBuilder);
}
