import { useI18n } from "../hooks/useI18n";
import iconSearch from "../res/images/icon_search_layout_placeholder.svg";
import styled from "styled-components";
import React from "react";
import { useHopper } from "../hooks/useHopper";

export interface SearchLayoutProps {
  onClick?: () => void;
  placeholder?: string;
}

function SearchLayout({ onClick, placeholder }: SearchLayoutProps) {
  const i18n = useI18n();
  const hopper = useHopper();
  return (
    <SearchBackground onClick={onClick}>
      <img alt={"search-icon"} src={iconSearch} width={24} height={24} />
      <SearchPlaceholder>{placeholder ?? i18n.search()}</SearchPlaceholder>
    </SearchBackground>
  );
}

const SearchBackground = styled.div`
  background-color: rgba(255, 255, 255, 0.08);
  border-radius: 6px;
  height: 36px;
  gap: 6px;
  padding-inline-start: 6px;
  align-items: center;
  display: flex;
  flex-direction: row;
  transition: opacity 0.2s ease;
`;

export const SearchPlaceholder = styled.div`
  color: rgba(255, 255, 255, 0.4);
  font-weight: 400;
  font-size: 15px;
`;

export default SearchLayout;
