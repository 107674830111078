import { useSWRArray } from "../../hooks/swr/useSWRArray";
import { useBackend } from "../../service/APIService";
import { Page } from "../../components/Page";
import UserHScrollItem from "../../components/UserHScrollItem";
import React from "react";
import { useHopper } from "../../hooks/useHopper";
import { PageHeader } from "../../components/PageHeaderFooter";
import SearchLayout from "../../components/SearchLayout";
import { SearchSource } from "../search/SearchPage";
import HomeMatchLayout from "../match/HomeMatchLayout";
import { Spacing } from "../../components/Spacing";
import { VStack } from "../../components/VStack";
import { hPaddingWithPageInset } from "../../components/CommonStyles";
import { NavMiddle, NavTitle } from "../../components/NavBar";
import { useI18n } from "../../hooks/useI18n";

export function RecommendUsersPage() {
  const backend = useBackend();
  const hopper = useHopper();
  const i18n = useI18n();
  const nameCardsSWR = useSWRArray(backend.getUserNameCards());

  return (
    <Page pageData={nameCardsSWR} gridSpan={3} gridGap={10}>
      <PageHeader
        children={
          <VStack style={{ ...hPaddingWithPageInset }}>
            <div
              style={{
                marginBottom: 20,
              }}
            >
              <SearchLayout
                onClick={() => {
                  hopper.push("search", {
                    searchSource: SearchSource.All,
                  });
                }}
              />
            </div>
            <HomeMatchLayout />
            <Spacing height={20} />
          </VStack>
        }
      />
      <NavMiddle>{i18n.recommended()}</NavMiddle>
      {nameCardsSWR.content &&
        nameCardsSWR.content.map((user) => (
          <UserHScrollItem
            containerStyle={{
              width: "100%",
            }}
            key={user.uid}
            user={user}
            iconSize={60}
            onGoProfile={() => {
              hopper.push(`user/${user.uid}`);
            }}
          />
        ))}
    </Page>
  );
}
