import { useHopper } from "../../hooks/useHopper";
import { useBackend } from "../../service/APIService";
import { useSWRList } from "../../hooks/swr/useSWRList";
import { Page } from "../../components/Page";
import CircleSnippetCell from "../circle/CircleSnippetCell";
import { hPaddingWithPageInset } from "../../components/CommonStyles";
import { Divider } from "../post/PostCell";
import * as React from "react";
import { TagObjectProps } from "./TagUserPage";
import { tagPageBackground } from "./TagsPage";
import { ObjectType } from "../../proto/ObjectSpec";
import { Circle } from "../../proto/Circle";

export function TagCirclePage({ tagId, tagName }: TagObjectProps) {
  const hopper = useHopper();
  const backend = useBackend();
  const circlesSWR = useSWRList(
    backend.tagObjects(ObjectType.CIRCLE, Circle, tagId, tagName),
  );

  return (
    <Page underlay={tagPageBackground} pageData={circlesSWR}>
      {circlesSWR.content &&
        circlesSWR.content.list.map((circle, index) => (
          <div key={circle.circleId}>
            <CircleSnippetCell
              hPadding={hPaddingWithPageInset}
              circle={circle}
              onClick={() => hopper.push(`circle/${circle.circleId}`)}
            />
            {index < circlesSWR.content.list.length - 1 && <Divider />}
          </div>
        ))}
    </Page>
  );
}
