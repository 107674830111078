import { useSWRArray } from "../../hooks/swr/useSWRArray";
import { Page } from "../../components/Page";
import { AlertGroup } from "../../proto/Alert";
import { AlertDetailCell } from "./AlertDetailCell";
import { useBackend } from "../../service/APIService";
import { usePageParent } from "../../components/PageCompositionBoundary";
import { useEffect } from "react";
import { andLog } from "../../components/handleError";
import { AlertCounts } from "../../proto/AlertCounts";
import { StateId } from "../../hooks/StateId";

export interface AlertCenterListProps {
  type: AlertGroup;
  alertCounts?: AlertCounts;
  refreshAlertCounts: () => void;
}

export function AlertGroupListPage(props: AlertCenterListProps) {
  const backend = useBackend();
  const alertSWR = useSWRArray(backend.getAlerts(props.type));
  const parentPage = usePageParent();

  const markAsRead = async () => {
    try {
      await backend.markAlertAsRead(props.type).run();
      await backend.getAlertCounts().run();
      props.refreshAlertCounts();
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    const primarySubpageId = parentPage.primarySubpageId;
    if (Number(primarySubpageId ?? 0) === Number(props.type.toString())) {
      switch (props.type) {
        case AlertGroup.Like: {
          if ((props.alertCounts?.likesAlertCount || 0) > 0) {
            markAsRead().catch(andLog);
          }
          break;
        }
        case AlertGroup.Follow: {
          if ((props.alertCounts?.followersAlertCount || 0) > 0) {
            markAsRead().catch(andLog);
          }
          break;
        }
        case AlertGroup.Circle: {
          if (
            (props.alertCounts?.circleAlertCount || 0) > 0 ||
            props.alertCounts?.hasCircleReminders
          ) {
            markAsRead().catch(andLog);
          }
          break;
        }
        case AlertGroup.Activity: {
          if ((props.alertCounts?.activitiesAlertCount || 0) > 0) {
            markAsRead().catch(andLog);
          }
          break;
        }
        case AlertGroup.Post: {
          if ((props.alertCounts?.postCount || 0) > 0) {
            markAsRead().catch(andLog);
          }
          break;
        }
      }
    }
  }, [parentPage.primarySubpageId]);
  return (
    <Page pageData={alertSWR}>
      {alertSWR.content.map((alert) => (
        <AlertDetailCell key={alert.alertId} alert={alert} />
      ))}
    </Page>
  );
}
