import { Image } from "../../../components/Image";
import { HStack, VStack } from "../../../components/VStack";
import React from "react";
import { useI18n } from "../../../hooks/useI18n";
import styled from "styled-components";
import { TransactionBody } from "../payment/TransactionBody";

export interface NFTGiftBoxPaymentProps {
  body: TransactionBody;
}

function NFTGiftBoxPaymentView(props: NFTGiftBoxPaymentProps) {
  const nft = props.body.nft;
  const i18n = useI18n();
  return (
    <Container>
      <PayFromLabel>{i18n.web3_v0_pay_from_default_account()}</PayFromLabel>
      <HStack style={{ gap: 12 }}>
        <Image
          src={[nft?.entity?.preview, "smallest"]}
          width={70}
          style={{ borderRadius: 4 }}
          height={70}
        />
        <VStack style={{ gap: 10 }}>
          <NFTName>{nft?.name}</NFTName>
          <NFTAmount>{`×️ ${props.body.amount}`}</NFTAmount>
        </VStack>
      </HStack>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  margin-inline-start: 20px;
  padding: 12px;
  gap: 12px;
  background-color: rgba(255, 255, 255, 0.06);
  border-radius: 6px;
  margin-inline-end: 20px;
  flex-direction: column;
`;

const NFTName = styled.div`
  color: var(--color-text00);
  font-size: 15px;
  font-weight: 500;
`;

const NFTAmount = styled.div`
  color: var(--color-text00);
  font-size: 12px;
  font-weight: 500;
`;

const PayFromLabel = styled.div`
  color: rgba(255, 255, 255, 0.4);
  font-weight: 500;
  font-size: 12px;
`;

export default NFTGiftBoxPaymentView;
