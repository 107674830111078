import { Page } from "../../../components/Page";
import { NavMiddle } from "../../../components/NavBar";
import { useI18n } from "../../../hooks/useI18n";
import { useBackend } from "../../../service/APIService";
import { useBigIntParam } from "../../../hooks/useTypedParam";
import { useSWRArray } from "../../../hooks/swr/useSWRArray";
import { EarningHistoryList } from "../AdminEarningsPage";

export function CircleCardEarnHistoryPage() {
  const i18n = useI18n();
  const backend = useBackend();

  const cardId = useBigIntParam("cardId");

  const list = useSWRArray(backend.getNFTCardEarningHistory(cardId));

  return (
    <Page pageData={list}>
      <NavMiddle>{i18n.clover_earnings_history()}</NavMiddle>
      <EarningHistoryList history={list.content} />
    </Page>
  );
}
