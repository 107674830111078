import { Page } from "../../../components/Page";
import { NavMiddle } from "../../../components/NavBar";
import { useI18n } from "../../../hooks/useI18n";
import { useBackend } from "../../../service/APIService";
import { useSWR } from "../../../hooks/swr/useSWR";
import { useSWRArray } from "../../../hooks/swr/useSWRArray";
import { useHopper } from "../../../hooks/useHopper";
import { HStack } from "../../../components/VStack";
import TopUpCell from "../TopUpCell";
import styled from "styled-components";
import GridLayout from "../../../components/GridLayout";
import { CurrencyType, formatMoney } from "../../../proto/Currency";
import React, { useMemo } from "react";
import { useTransactionBody } from "../send/SendTokenPage";
import { useAssetAccount } from "../useAssetAccount";
import { BottomSheet, closeModal, useModal } from "../../../components/Modal";
import { TxSheet, usePerformTx } from "../payment/TransactionDialog";
import { andLog } from "../../../components/handleError";
import { UserPropsPackage } from "../../../proto/UserPropsPackage";
import iconGems from "../../../res/images/icon_gems.svg";
import { Image } from "../../../components/Image";
import { useRegularAccount } from "../useRegularAccount";
import { TopUpType } from "../TopUpType";
import { OrderType } from "../order/OrderDetailPage";
import BigNumber from "bignumber.js";
import { useNativePage, useWebHost } from "../../../hooks/useBridge";
import { copyObject } from "../../../utils/copyObject";
import CurrencyTransactionView from "../payment/CurrencyTransactionView";
import { TransactionBody } from "../payment/TransactionBody";
import { useBooleanSearchParam } from "../../../hooks/useTypedParam";
import { useGlobalSpinner } from "../../../utils/globalSpinner";

export function TopUpPage() {
  const i18n = useI18n();
  const backend = useBackend();
  const thirdParty = useBooleanSearchParam("thirdParty", false);
  const myPropsSWR = useSWR(backend.getUserProps());
  const packageSWR = useSWRArray(
    backend.getPurchaseProducts(
      CurrencyType.GEMS,
      0,
      thirdParty ? undefined : 8,
    ),
  );
  const solPriceSWR = useSWR(backend.getSOLUSDPrice());
  const solUSDPrice = solPriceSWR.content?.usdPrice;
  const webHost = useWebHost();
  const editingBody = useTransactionBody();
  const hopper = useHopper();
  const paymentModal = useModal("payment-modal");
  const assetAccount = useAssetAccount();
  const assetAccountId = assetAccount?.accountId ?? BigInt(0);
  const globalSpinner = useGlobalSpinner();
  const regularAccount = useRegularAccount();
  const gemsCurrency = useMemo(() => {
    return regularAccount?.currencyList?.find((currency) => {
      return currency.currencyType === CurrencyType.GEMS;
    });
  }, [regularAccount]);

  const solCurrency = useMemo(() => {
    return regularAccount?.currencyList?.find((currency) => {
      return currency.currencyType === CurrencyType.SOL;
    });
  }, [regularAccount]);

  const nativePage = useNativePage();

  const next = async (pack: UserPropsPackage) => {
    if (thirdParty && pack.productId) {
      await globalSpinner(async () => {
        const response = await backend
          .startThirdPartyPurchase(pack.productId || "")
          .run();
        const link = response.link;
        if (link) await webHost.openInWebBrowser(link);
      });
    } else {
      const amount = BigNumber(pack.price?.amount ?? 0)
        .div(BigNumber(solUSDPrice ?? 1))
        .toFixed(0);
      const usdPrice = copyObject(pack.price, { amount: amount });

      if (
        BigNumber(usdPrice?.amount ?? "0").isGreaterThan(
          BigNumber(solCurrency?.amount ?? "0"),
        )
      ) {
        nativePage
          .infoHud(i18n.clover_dont_have_enough_balance())
          .catch(andLog);
        return;
      }

      await editingBody.fill((prevData) => ({
        ...prevData,
        productId: pack.productGUID,
        currency: usdPrice,
        amount: usdPrice?.amount ?? "0",
        currencyType: pack.price?.currencyType ?? CurrencyType.SOL,
        currencyId: pack.price?.currencyId,
      }));
      paymentModal.open();
    }
  };

  const performTx = usePerformTx(
    async () => {
      const updatedBody = copyObject<TransactionBody>(editingBody.content, {
        payAssetAccountId: assetAccountId,
      });
      const resp = await backend.performTopUp(updatedBody).run();
      return resp.orderId;
    },
    async (orderId: bigint | undefined) => {
      await closeModal(paymentModal);
      await editingBody.clear();
      if (orderId) {
        hopper.dismissLayerAndPush(`wallet/order/${orderId}`, {
          orderType: OrderType.OtherOrder,
          showDialog: true,
          title: i18n.web3_hongbao_top_up_succeeded(),
        });
      }
    },
  );

  return (
    <Page alwaysShowsContent pageData={[myPropsSWR, packageSWR, solPriceSWR]}>
      <NavMiddle>{i18n.web3_hongbao_top_up()}</NavMiddle>
      <HStack
        style={{
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Title>{i18n.web3_v0_your_balance()}</Title>
        <Amount>
          <Image width={24} height={24} src={iconGems} />
          {formatMoney("medium", gemsCurrency)}
        </Amount>
      </HStack>

      <GridLayout
        spanCount={3}
        items={packageSWR.content.map((pack) => (
          <TopUpCell
            key={pack.packageId}
            package={pack}
            onClick={next}
            solUSDPrice={solUSDPrice}
            topUpType={
              thirdParty ? TopUpType.THIRD_PARTY : TopUpType.SOL_BUY_GEMS
            }
          />
        ))}
      />
      <TxSheet modal={paymentModal} performTx={performTx}>
        <CurrencyTransactionView body={editingBody.content} />
      </TxSheet>
    </Page>
  );
}

const Amount = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  font-weight: 700;
  font-size: 18px;
  color: #ffffff;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
`;
