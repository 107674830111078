import { Page } from "../../components/Page";
import { HStack } from "../../components/VStack";
import { useState } from "react";
import { FilterTab } from "../../components/FilterTab";
import { range } from "../../utils/List";
import { NavMiddle } from "../../components/NavBar";

export function FilterTabDemoPage() {
  const [selected, setSelected] = useState(0);

  return (
    <Page pageData={undefined}>
      <NavMiddle>FilterTab</NavMiddle>
      <HStack style={{ gap: 10 }}>
        {range(0, 4).map((item, _) => (
          <FilterTab
            onClick={() => {
              setSelected(item);
            }}
            // style={getButtonStyle(selected)}
            selected={item === selected}
          >
            {`Tab #${item}`}
          </FilterTab>
        ))}
      </HStack>
    </Page>
  );
}
