import { useI18n } from "../../hooks/useI18n";
import { useHopper } from "../../hooks/useHopper";
import { useStringSearchParam } from "../../hooks/useTypedParam";
import React, { useState } from "react";
import { Page } from "../../components/Page";
import { NavEnd, NavItem, NavMiddle } from "../../components/NavBar";
import { AuthTitleLabel } from "./InvitationCodePage";
import { RegularLargeButton } from "../../components/Buttons";
import { RegularInputGroup } from "../../components/Input";
import { useLoadState } from "../../hooks/LoadState";
import { Spin } from "../../components/Spin";
import { useBackend } from "../../service/APIService";
import { usePageSpec } from "../common/usePageSpec";
import { useAuthSessionService } from "../../service/AuthSessionService";
import { andLog, useErrorHandler } from "../../components/handleError";
import { useNativePage } from "../../hooks/useBridge";
import { useMemoryRepo } from "../../hooks/swr/useLocalRepo";
import { AuthBody } from "../../proto/Auth";

export function EnterNicknamePage() {
  const i18n = useI18n();
  const backend = useBackend();
  const hopper = useHopper();
  const handleError = useErrorHandler();

  const flowId = useStringSearchParam("f");
  const authInfo = useMemoryRepo<AuthBody>(flowId ?? "", {});

  const [nickname, setNickname] = useState<string>();

  const loadTask = useLoadState();
  const authSessionService = useAuthSessionService();

  const nativePage = useNativePage();

  async function onNextClick() {
    if (!(nickname && nickname.length > 0 && nickname.length < 50)) {
      nativePage
        .alertNotice(
          i18n.text_limit_number_of_characters_not_follow(),
          i18n.ok(),
        )
        .catch(andLog);
      return;
    }
    const authBody = {
      ...authInfo.content,
      nickname: nickname,
    };
    const result = await loadTask.run(async () => {
      await backend.registerProfileCheck(authBody).run();
      const auth = await backend.register(authBody).run();
      await authSessionService.add(
        {
          uid: auth.account.uid,
          sid: auth.sId,
          ptoken: auth.secret,
        },
        true,
      );
    });
    if (!result.success) {
      handleError(result.error);
    } else {
      hopper.push("pick-interests");
    }
  }

  const pageSpec = usePageSpec();
  return (
    <Page pageData={undefined}>
      {pageSpec === "wide" && <NavMiddle>{i18n.auth_sign_up()}</NavMiddle>}
      <NavEnd>
        {NavItem.text(i18n.help(), () => hopper.push("feedback"))}
      </NavEnd>
      <AuthTitleLabel>{i18n.apple_signin_enter_your_nickname()}</AuthTitleLabel>
      <RegularInputGroup
        value={nickname}
        updateValue={(newValue) => {
          setNickname(newValue.trim());
        }}
        maxLength={50}
      />
      <RegularLargeButton
        disabled={!nickname}
        style={{ margin: "125px 62px 0" }}
        onClick={onNextClick}
      >
        <Spin state={loadTask.state}>{i18n.next()}</Spin>
      </RegularLargeButton>
    </Page>
  );
}
