import { z } from "zod";
import { zBigInt, zEnum, zStatic } from "../utils/zodUtils";
import { Media } from "./Media";
import { Background } from "./Background";
import { RichFormat } from "../components/zdoc/RichFormat";
import { ProfileFrame } from "./ProfileFrame";
import { Storyboard } from "./Storyboard";
import { Bubble } from "./Bubble";
import { CircleMembershipCard } from "./CircleMembershipCard";
import { Circle } from "./Circle";
import { NFTUser } from "./NFTUser";

export const Position = z.object({
  x: z.number().optional(),
  y: z.number().optional(),
  z: z.number().optional(),
});

export const Size = z.object({
  width: z.number().optional(),
  height: z.number().optional(),
});

export const LookAt = z.object({
  eye: Position.optional(),
  center: Position.optional(),
  up: Position.optional(),
});

export const Projection = z.object({
  focalLength: z.number().optional(),
  near: z.number().optional(),
  far: z.number().optional(),
});

export const Frame = z.object({
  center: Position.optional(),
  size: Size.optional(),
});

export type Frame = zStatic<typeof Frame>;

export const Model = z.object({
  lookAt: LookAt.optional(),
  projection: Projection.optional(),
  frame: Frame.optional(),
});

export const Animation = z.object({
  interactionName: z.string().optional(),
  animationIndex: z.number().optional(),
  start: z.number().optional(),
  end: z.number().optional(),
});

export const OrnamentParam = z.object({
  ornamentName: z.string().optional(),
  frame: Frame.optional(),
  zOrder: z.number().optional(),
});

export const RenderParams = z.object({
  model: Model.optional(),
  podium: Frame.optional(),
  animations: z.array(Animation).optional(),
  ornaments: z.array(OrnamentParam).optional(),
});

export const Ornament = z.object({
  name: z.string().optional(),
  media: Media.optional(),
});

export const NFTEntity = z.object({
  preview: Media.optional(),
  entityObject: z.string().optional(),
  renderParams: RenderParams.optional(),
  podium: Media.optional(),
  background: Background.optional(),
  ornaments: z.array(Ornament).optional(),
  description: z.string().optional(),
  richFormat: RichFormat.optional(),
});

export enum CapabilityType {
  ProfileFrame = 1,
  ChatBubble = 2,
  Storyboard = 3,
  ChatAI = 4,
  AiCharacter = 5,
}

export const ChatAi = z.object({
  id: zBigInt.optional(),
  name: z.string().optional(),
  preview: Media.optional(),
});

export const AICharacter = z.object({
  characterId: zBigInt.optional(),
  characterName: z.string().optional(),
  subtitle: z.string().optional(),
  avatar: Media.optional(),
});

export const CapabilityContent = z.object({
  profileFrame: ProfileFrame.optional(),
  storyboard: Storyboard.optional(),
  chatBubble: Bubble.optional(),
  chatAi: ChatAi.optional(),
  character: AICharacter.optional(),
});

export type CapabilityContent = zStatic<typeof CapabilityContent>;

export const Capability = z.object({
  type: zEnum(CapabilityType),
  contents: z.array(CapabilityContent).optional(),
});

export type Capability = zStatic<typeof Capability>;

export const NFTExtensions = z.object({
  nftMode: z.number().optional(),
});

export enum NFTStatus {
  Minted = 1,
  Pending = 2,
  Minting = 3,
  Draft = 4,
}

export const NFT = z.object({
  nftId: zBigInt,
  entity: NFTEntity.optional(),
  name: z.string().optional(),
  amount: z.number().default(9999),
  // grade: z.number(),
  chainName: z.string().optional(),
  chainType: z.number().optional(),
  extensions: NFTExtensions.optional(),
  nftStatus: zEnum(NFTStatus).optional(),
  balance: z.string().default("9999"),
  latestVersion: z.number().optional(),
  version: z.number().optional(),
  circleMembershipCard: CircleMembershipCard.optional(),
  availableBalance: z.string().default("0"),
  tokenAmount: z.string().default("0"),
  isOwn: z.boolean().optional(),
  capabilityContents: z.array(Capability).optional(),
  membershipLevel: z.number().optional(),
  container: Circle.optional(),
  author: NFTUser.optional(),
  createdTime: z.number().optional(),
});

export type NFT = zStatic<typeof NFT>;

export enum NFTMode {
  EasyEditor = 1,
  // AdvancedEditor = 0,
}

export function isProtoNFT(nft: NFT) {
  return (
    nft.nftStatus === NFTStatus.Pending ||
    nft.nftStatus === NFTStatus.Minting ||
    nft.nftStatus === NFTStatus.Draft
  );
}
