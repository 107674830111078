import { Page } from "../../../components/Page";
import { useBigIntParam } from "../../../hooks/useTypedParam";
import { useSWR } from "../../../hooks/swr/useSWR";
import { useI18n } from "../../../hooks/useI18n";
import styled from "styled-components";
import GridLayout from "../../../components/GridLayout";
import { HStack, VStack } from "../../../components/VStack";
import { Image } from "../../../components/Image";
import CircleBackgroundFrame from "../CircleBackgroundFrame";
import React from "react";
import { SingleLineUnspecifiedWidth } from "../../../components/CommonViews";
import { useHopper } from "../../../hooks/useHopper";
import { useBackend } from "../../../service/APIService";
import { NavMiddle } from "../../../components/NavBar";

function CircleDataCenterPage() {
  const circleId = useBigIntParam("circleId");
  const backend = useBackend();
  const circleSWR = useSWR(backend.getCircle(circleId));

  const dataSWR = useSWR(backend.getCircleStatsInfo(circleId));
  const i18n = useI18n();
  const hopper = useHopper();

  return (
    <Page
      pageData={[circleSWR, dataSWR]}
      underlay={
        <CircleBackgroundFrame
          circleBackground={circleSWR.content?.circleBackground}
        />
      }
    >
      <NavMiddle>{i18n.circle_dc_data_center()}</NavMiddle>
      {circleSWR.content && (
        <HStack style={{ gap: 12 }}>
          <VStack>
            <Image
              src={[circleSWR.content.cover, "best"]}
              width={50}
              height={50}
              style={{
                borderRadius: 6,
                border: `1px solid ${circleSWR.content.themeColor}`,
              }}
            />
          </VStack>
          <CircleHeaderName>{circleSWR.content.name}</CircleHeaderName>
        </HStack>
      )}
      <Title>{i18n.circle_dc_member_stats()}</Title>
      <GridLayout
        style={{ paddingTop: 0 }}
        spanCount={2}
        items={[
          <DataCenterItem
            number={lastOrUndefined(dataSWR.content?.dailyNewMembersCountList)}
            title={i18n.circle_dc_daily_new_members()}
          />,
          <DataCenterItem
            number={lastOrUndefined(
              dataSWR.content?.dailyActiveMembersCountList,
            )}
            title={i18n.circle_dc_daily_active_members()}
          />,
          <DataCenterItem
            number={lastOrUndefined(
              dataSWR.content?.dailyGuestVisitorsCountList,
            )}
            title={i18n.circle_dc_daily_guest_visitors()}
          />,
          <DataCenterItem
            number={lastOrUndefined(dataSWR.content?.totalMembersCountList)}
            title={i18n.circle_dc_total_members()}
          />,
        ]}
      />
      <Title>{i18n.circle_dc_content_stats()}</Title>
      <GridLayout
        style={{ paddingTop: 0 }}
        spanCount={2}
        items={[
          <DataCenterItem
            number={lastOrUndefined(dataSWR.content?.dailyNewBlogCountList)}
            title={i18n.circle_dc_daily_new_posts()}
          />,
          <DataCenterItem
            number={lastOrUndefined(dataSWR.content?.totalBlogCountList)}
            title={i18n.circle_dc_total_posts()}
          />,
          <DataCenterItem
            number={lastOrUndefined(dataSWR.content?.dailyNewChatsCountList)}
            title={i18n.circle_dc_daily_new_chats()}
          />,
          <DataCenterItem
            number={lastOrUndefined(dataSWR.content?.totalChatsCountList)}
            title={i18n.circle_dc_total_chats()}
          />,
        ]}
      />
    </Page>
  );
}

export function lastOrUndefined<T>(array: T[] | undefined): T | undefined {
  if (array) {
    return array.length > 0 ? array[array.length - 1] : undefined;
  }
  return undefined;
}

const CircleHeaderName = styled.div`
  font-size: 18px;
  ${SingleLineUnspecifiedWidth};
  flex: 1;
  font-weight: 600;
  color: var(--color-text00);
`;

export interface DataCenterItemProps {
  title: string;
  number?: bigint;
}

function DataCenterItem(props: DataCenterItemProps) {
  return (
    <DataCenterItemBg>
      <DataCenterNumber>{String(props.number ?? BigInt(0))}</DataCenterNumber>
      <DataCenterTitle>{props.title}</DataCenterTitle>
    </DataCenterItemBg>
  );
}

const DataCenterItemBg = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding-top: 16px;
  align-items: center;
  justify-content: center;
  padding-bottom: 16px;
  min-height: 80px;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const DataCenterNumber = styled.div`
  color: var(--color-text00);
  font-weight: 500;
  font-size: 24px;
`;

const DataCenterTitle = styled.div`
  color: var(--color-text10);
  font-weight: 400;
  font-size: 11px;
`;

const Title = styled.div`
  color: var(--color-text00);
  font-weight: 500;
  margin-top: 24px;
  margin-bottom: 8px;
  font-size: 16px;
`;

export default CircleDataCenterPage;
