import { z } from "zod";
import { zBigInt, zStatic } from "../utils/zodUtils";
import { CircleBackground } from "./CircleBackground";
import { User } from "./User";
import { merge } from "chart.js/helpers";
import { Media } from "./Media";
import { Reaction } from "./Reaction";

const CommentExtensions = z.object({
  replyToUid: zBigInt.optional(),
  replyToUser: User.optional(),
});

export const BareComment = z.object({
  commentId: zBigInt,
  content: z.string().optional(),
  author: User,
  createdTime: z.number(),
  mediaList: z.array(Media).optional(),
  extensions: CommentExtensions.optional(),
  reactionCountList: z.array(Reaction).optional(),
  replyId: zBigInt.optional(),
  subCommentsCount: z.number().default(0),
});
export type BareComment = zStatic<typeof BareComment>;

export const Comment = BareComment.merge(
  z.object({
    subComments: z.array(BareComment).default([]),
  }),
);

export type Comment = zStatic<typeof Comment>;

export const isComment = (value: BareComment): value is Comment => {
  return "subComments" in value;
};
