export enum FollowStatus {
  followed = 1,
  notFollowed = 2,
  favorites = 3,
}

export enum UserEnums {
  followed = 1,
  notFollowed = 2,
  favorites = 3,
}

export enum Gender {
  man = 1,
  woman = 2,
  // customize = 3,
  // notApplicable = 4,
  secret = 100,
}

export enum OnlineStatus {
  online = 1,
  offline = 2,
}
export enum UserMoodType {
  Unknown = 0,
  ONLINE = 1,
  OFFLINE = 2,
  CUSTOM = 3,
  DONOTDISTURB = 4,
}

export enum ZodiacType {
  Unknown = 0,
  Aries = 1,
  Taurus = 2,
  Gemini = 3,
  Cancer = 4,
  Leo = 5,
  Virgo = 6,
  Libra = 7,
  Scorpius = 8,
  Sagittarius = 9,
  Capricornus = 10,
  Aquarius = 11,
  Pisces = 12,
}

export enum FollowType {
  FOLLOWER = "follower",
  FOLLOWING = "following",
  FRIEND = "friend",
  FOLLOWING_FAVORITES = "followingFavorites",
  FOLLOWING_OTHER = "followingOther",
}
