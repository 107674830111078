import { Page } from "../../../components/Page";
import { NavMiddle } from "../../../components/NavBar";
import { useSWR } from "../../../hooks/swr/useSWR";
import { useBackend } from "../../../service/APIService";
import { TxSheet, usePerformTx } from "../../wallet/payment/TransactionDialog";
import { andLog } from "../../../components/handleError";
import { closeModal, useModal } from "../../../components/Modal";
import React, { useEffect, useMemo } from "react";
import { useHopper } from "../../../hooks/useHopper";
import { useTransactionBody } from "../../wallet/send/SendTokenPage";
import {
  CenterChild,
  HStack,
  VSpace,
  VStack,
} from "../../../components/VStack";
import { Currency, formatMoney } from "../../../proto/Currency";
import styled from "styled-components";
import { useI18n } from "../../../hooks/useI18n";
import PaymentAccount from "../../wallet/payment/PaymentAccount";
import PaymentFooter from "../../wallet/payment/PaymentFooter";
import PaymentServiceFee from "../../wallet/payment/PaymentServiceFee";
import { useAssetAccount } from "../../wallet/useAssetAccount";
import BigNumber from "bignumber.js";
import { useHopState } from "../../../hooks/useHopState";
import { GasFeeTxType } from "../../../proto/Wallet";
import { copyObject } from "../../../utils/copyObject";
import { useNativePage, useWebHost } from "../../../hooks/useBridge";
import { useRegularAccount } from "../../wallet/useRegularAccount";
import { OrderType } from "../../wallet/order/OrderDetailPage";
import { useCircleSWR } from "../useCircleSWR";
import CurrencyTransactionView from "../../wallet/payment/CurrencyTransactionView";
import { CircleCardStatus } from "../../../proto/CircleCardStatus";
import CircleCardsPreviewCell, {
  CardStyle,
} from "../card/CircleCardsPreviewCell";
import { ContentGroup } from "../../../components/ContentGroup";
import { AdminCard } from "../../wallet/swap/SwapPage";
import { Image } from "../../../components/Image";

function LaunchTokenSummaryPage() {
  const backend = useBackend();
  const walletSWR = useSWR(backend.getWallet());
  const assetAccount = useAssetAccount();

  const assetAccountId = assetAccount?.accountId ?? BigInt(0);

  const i18n = useI18n();
  const [circleId, circleSWR] = useCircleSWR();
  const presetSWR = useSWR(backend.createTokenPresets(circleId));

  const paymentModal = useModal("payment-modal");
  const hopper = useHopper();
  const editingBody = useTransactionBody();

  const adminCardSWR = useSWR(backend.getAdminMembershipCard(circleId));

  const regularAccount = useRegularAccount();
  const walletAccountId = regularAccount?.walletAccountId ?? BigInt(0);
  const currencyListSWR = useSWR(backend.getCurrencyList(walletAccountId));
  const nativePage = useNativePage();

  const paymentAvailableCurrency = useMemo(() => {
    return currencyListSWR.content?.list?.find(
      (item) => item.currency.currencyType === editingBody.content.currencyType,
    )?.currency;
  }, [currencyListSWR.content, editingBody.content]);

  const isFreeLaunch = useMemo(() => {
    return presetSWR.content?.isFree;
  }, [presetSWR.content]);

  const [initialFill, setInitialFill] = useHopState("initialFill", false);
  useEffect(() => {
    const preset = presetSWR.content;
    if (preset && !initialFill) {
      setInitialFill(true);
      const solAmount = BigNumber(preset.deployFeeSol.amount).plus(
        BigNumber(preset.purchaseFeeSol.amount),
      );
      const fullConsumedSolCurrency = copyObject<Currency>(
        preset.deployFeeSol,
        {
          amount: String(solAmount),
        },
      );
      editingBody
        .fill((prevData) => ({
          ...prevData,
          circleId: circleId,
          amount: fullConsumedSolCurrency.amount,
          decimals: fullConsumedSolCurrency.decimals,
          currencyId: fullConsumedSolCurrency.currencyId,
          currencyType: fullConsumedSolCurrency.currencyType,
          paymentCurrencyType: fullConsumedSolCurrency.currencyType,
          currency: fullConsumedSolCurrency,
          isFree: presetSWR.content?.isFree,
        }))
        .catch(andLog);
    }
  }, [presetSWR.content, initialFill]);

  const paymentCheck = async () => {
    if (isFreeLaunch) {
      return true;
    }

    if (
      BigNumber(editingBody.content.currency?.amount ?? "0").isGreaterThan(
        BigNumber(paymentAvailableCurrency?.amount ?? "0"),
      )
    ) {
      nativePage.infoHud(i18n.clover_dont_have_enough_balance()).catch(andLog);
      return false;
    }
    return true;
  };

  const performTx = usePerformTx(
    async () => {
      const resp = await backend.mintTokenProject(editingBody.content).run();
      return resp.assetOrder.orderId;
    },
    async (orderId: bigint | undefined) => {
      await closeModal(paymentModal);
      await editingBody.clear();
      if (orderId) {
        circleSWR.load().catch(andLog);
        hopper.dismissLayerAndPush(`wallet/order/${orderId}`, {
          orderType: OrderType.OtherOrder,
          showDialog: true,
        });
      }
    },
  );

  const webHost = useWebHost();

  return (
    <Page pageData={[walletSWR, presetSWR, currencyListSWR]}>
      <NavMiddle>{i18n.clover_create_a_new_token()}</NavMiddle>
      <ContentGroup style={{ marginBottom: 20 }}>
        <VStack style={{ alignItems: "center", gap: 8 }}>
          {adminCardSWR.content?.circleMembershipCard && (
            <CircleCardsPreviewCell
              width={180}
              card={adminCardSWR.content?.circleMembershipCard}
              cardStyle={CardStyle.large}
              circle={circleSWR.content}
            />
          )}

          <CardName>{i18n.clover_admin_membership_card()}</CardName>
          <MintDesc>{i18n.clover_deploy_will_own_one_percent()}</MintDesc>

          <HStack style={{ gap: 6 }}>
            <Image
              src={[circleSWR.content?.tokenProject?.image, "smallest"]}
              style={{ borderRadius: 2 }}
              width={16}
              height={16}
            />
            <TokenAmount>
              {`${formatMoney(
                "medium",
                BigNumber(
                  circleSWR.content?.tokenProject?.totalSupply ?? "0",
                ).div(BigNumber("100")),
              )} ${circleSWR.content?.tokenProject?.ticker}`}
            </TokenAmount>
          </HStack>
        </VStack>
      </ContentGroup>

      <PaymentServiceFee
        editingBody={editingBody}
        gasFeeTxType={GasFeeTxType.DeployCollectionNft}
        isFree={isFreeLaunch}
        presets={presetSWR.content}
      />
      <VSpace height={20} />
      <PaymentAccount />
      <PaymentFooter
        editingBody={editingBody}
        paymentModal={paymentModal}
        paymentCheck={paymentCheck}
        nextButtonText={i18n.create()}
      />
      <TxSheet modal={paymentModal} performTx={performTx}>
        <CurrencyTransactionView body={editingBody.content} />
      </TxSheet>
    </Page>
  );
}

const CardName = styled.div`
  color: var(--color-text00);
  font-weight: 500;
  font-size: 16px;
`;

const MintDesc = styled.div`
  color: var(--color-text00);
  font-weight: 400;
  text-align: center;
  font-size: 12px;
`;

const TokenAmount = styled.div`
  color: var(--color-text00);
  font-weight: 400;
  font-size: 14px;
`;

export default LaunchTokenSummaryPage;
