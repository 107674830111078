import { HStack, VStack } from "../../components/VStack";
import icPoll from "../../res/images/ic_poll.png";
import { Poll, PollItem } from "../../proto/Poll";
import styled, { keyframes } from "styled-components";
import { useI18n } from "../../hooks/useI18n";
import { Image } from "../../components/Image";
import iconCrown from "../../res/images/ic_poll_img_crown.png";
import iconDefault from "../../res/images/ic_poll_img_default.png";
import { getBestRes, isImage, isVideo } from "../../proto/Media";
import { useEffect, useMemo, useRef, useState } from "react";
import icCheck from "../../res/images/ic_abar_check.svg";
import { useBackend } from "../../service/APIService";
import { ObjectType } from "../../proto/ObjectSpec";
import { OverFlowTextStyle } from "../../components/Text";
import { andLog } from "../../components/handleError";
import icThreeDot from "../../res/images/ic_three_dot.svg";
import { useMyUid } from "../../service/AuthSessionService";
import { useSWR } from "../../hooks/swr/useSWR";
import { User } from "../../proto/User";
import { useHopper } from "../../hooks/useHopper";
import { VideoPlayCover } from "./PostMediaContainer";
import icVideoPlay from "../../res/images/ic_video_play.svg";
import { useNativePage } from "../../hooks/useBridge";
import icPlaceHolder from "../../res/images/ic_chat_list_avatar_placeholder.png";
import { ChatGroupIconContainer } from "../wallet/order/TransactionDetailPage";
import { FlagMediaLocationEnum } from "../../proto/Flag";
import { MediaOwner } from "../../proto/MediaOwner";

export function PollCell(props: { poll: Poll }) {
  const i18n = useI18n();
  const backend = useBackend();
  const myUid = useMyUid();
  const myUserSWR = useSWR<User>(backend.getUser(myUid));

  const [votedItem, setVotedItem] = useState<PollItem>();

  const pollItemList = props.poll.pollItemList;

  const voteSum = useMemo(() => {
    let count = 0;
    pollItemList?.forEach((item) => {
      count += item.votesCount;
    });
    return count;
  }, [votedItem]);

  const maxVotes = useMemo(() => {
    let max = 0;
    pollItemList?.forEach((item) => {
      max = item.votesCount > max ? item.votesCount : max;
    });
    return max;
  }, [votedItem]);

  useEffect(() => {
    setVotedItem(
      props.poll.pollItemList?.find((pollItem) => pollItem.votedValue === 1),
    );
  }, [props.poll.pollItemList]);

  function getLeftTime() {
    const now = Date.now() / 1000;
    const leftMinute = Math.ceil(((props.poll.expireTime || 0) - now) / 60);
    if (leftMinute <= 0) {
      return i18n.poll_end();
    } else if (leftMinute < 60) {
      return i18n.plural(leftMinute).poll_minites_left(leftMinute);
    } else if (leftMinute < 1440) {
      const leftHour = Math.ceil(leftMinute / 60);
      return i18n.plural(leftHour).poll_hours_left(leftHour);
    } else {
      const leftDay = Math.ceil(leftMinute / 1440);
      return i18n.plural(leftDay).poll_days_left(leftDay);
    }
  }

  async function vote(pollItem: PollItem) {
    try {
      const index = pollItemList?.indexOf(pollItem);
      if (index === undefined) return;
      if (myUserSWR.content === undefined) return;

      const newVotedUsers = pollItem.votedUsers || [];
      newVotedUsers.splice(0, 0, myUserSWR.content);

      const newPollItem = Object.assign({}, pollItem, {
        votesCount: pollItem.votesCount + 1,
        votedValue: 1,
        votedUsers: newVotedUsers,
      });
      pollItemList?.splice(index, 1, newPollItem);
      setVotedItem(newPollItem);
      await backend
        .pollVote({
          objectId: pollItem.pollItemId,
          objectType: ObjectType.POLL_ITEM,
        })
        .run();
    } catch (e) {
      console.error(e);
    }
  }

  async function deleteVote() {
    try {
      if (votedItem === undefined) return;
      const index = pollItemList?.indexOf(votedItem);
      if (index === undefined) return;

      const newVotedUsers = votedItem.votedUsers?.filter(
        (user) => String(user.uid) !== String(myUid),
      );
      const newPollItem = Object.assign({}, votedItem, {
        votesCount: votedItem.votesCount - 1,
        votedValue: 0,
        votedUsers: newVotedUsers,
      });

      pollItemList?.splice(index, 1, newPollItem);
      setVotedItem(undefined);

      await backend
        .deleteVote({
          objectId: newPollItem.pollItemId,
          objectType: ObjectType.POLL_ITEM,
        })
        .run();
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <VStack
      style={{
        width: "100%",
        border: "1px solid #9797974D",
        borderRadius: 14,
        padding: "8px 14px 18px",
        gap: 10,
        alignItems: "center",
      }}
    >
      <HStack style={{ width: "100%" }}>
        <img src={icPoll} width={50} height={19} />
      </HStack>
      <PollTitle>{props.poll.title}</PollTitle>
      <HStack style={{ width: "100%" }}>
        {voteSum > 0 && (
          <PollInfo>
            {i18n.plural(voteSum).poll_count(voteSum) + " | "}
          </PollInfo>
        )}
        {props.poll.expireTime && <PollInfo>{getLeftTime()}</PollInfo>}
      </HStack>
      <Divider />
      <VStack style={{ width: "100%", gap: 15 }}>
        {pollItemList?.map((pollItem, index) => (
          <PollOptionCell
            pollItem={pollItem}
            voteSum={voteSum}
            key={`poll-option-${pollItem.pollItemId}`}
            onVote={() => vote(pollItem)}
            showDetail={votedItem !== undefined}
            votedMost={maxVotes === pollItem.votesCount}
          />
        ))}
      </VStack>

      {votedItem && (
        <ChangeVoteButton
          onClick={(event) => {
            event.stopPropagation();
            deleteVote().catch(andLog);
          }}
        >
          {i18n.poll_change_vote()}
        </ChangeVoteButton>
      )}
    </VStack>
  );
}

const PollTitle = styled.div`
  color: white;
  font-weight: 700;
  font-size: 18px;
  width: 100%;
  word-wrap: break-word;
`;

const PollInfo = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #ffffff99;
`;

const ChangeVoteButton = styled.div`
  font-size: 13px;
  font-weight: 700;
  border: 0.5px solid #ffffff99;
  border-radius: 4px;
  padding: 5px 15px;
`;

const Divider = styled.div`
  background-color: #ffffff4d;
  width: 100%;
  height: 1px;
`;

function PollOptionCell(props: {
  pollItem: PollItem;
  voteSum: number;
  votedMost?: boolean;
  showDetail: boolean;
  onVote: () => void;
}) {
  const i18n = useI18n();
  const hopper = useHopper();
  const native = useNativePage();
  const defaultIcon =
    props.votedMost && props.showDetail ? iconCrown : iconDefault;

  const itemIcon = props.pollItem?.icon
    ? isImage(props.pollItem?.icon)
      ? getBestRes(props.pollItem.icon).url
      : isVideo(props.pollItem?.icon)
        ? getBestRes(props.pollItem.icon.cover!!).url
        : defaultIcon
    : defaultIcon;

  const percentValue = useMemo(() => {
    return props.pollItem.votesCount / props.voteSum;
  }, [props.pollItem.votesCount, props.voteSum]);

  const isMyVote = props.pollItem.votedValue === 1;

  const containerRef = useRef<HTMLDivElement>(null);

  const viewMedia = () => {
    if (props.pollItem.icon) {
      const mediaOwnerMap = new Map();
      const mediaList = [props.pollItem.icon];
      mediaList?.forEach((media) => {
        const ownerSpec = {
          objectId: props.pollItem.pollItemId,
          objectType: ObjectType.POLL_ITEM,
        };
        const flagExt = {
          flagMedia: media,
          flagMediaLocation: FlagMediaLocationEnum.CONTENT_IMAGE,
        };

        const mediaOwner: MediaOwner = {
          ownerSpec: ownerSpec,
          authorId: BigInt(0),
          flagExt: flagExt,
        };
        mediaOwnerMap.set(media.mediaId, mediaOwner);
      });
      native.viewMedia({
        mediaList: mediaList,
        position: 0,
        mediaOwnerMap: mediaOwnerMap,
      });
    }
  };

  return (
    <HStack style={{ gap: 8, width: "100%", alignItems: "start" }}>
      <OptionMediaContainer onClick={viewMedia}>
        <Image
          src={itemIcon}
          style={{ width: 48, height: 48, borderRadius: 6 }}
        />
        {isVideo(props.pollItem.icon) && (
          <VideoPlayCover style={{ borderRadius: 6 }}>
            <VideoPlayIcon src={icVideoPlay} />
          </VideoPlayCover>
        )}
      </OptionMediaContainer>

      <VStack style={{ flex: 1 }}>
        <PollContentContainer
          ref={containerRef}
          style={{
            background:
              props.votedMost && props.showDetail ? "#36013599" : "#19274899",
            border: `0.5px solid ${props.votedMost && props.showDetail ? "#EF45C7" : "#34A6FF"}`,
          }}
          onClick={(event) => {
            event.stopPropagation();
            if (!props.showDetail) {
              props.onVote();
            }
          }}
        >
          {!props.showDetail && (
            <OptionContent
              style={{
                display: "grid",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {props.pollItem.content}
            </OptionContent>
          )}

          {props.showDetail && (
            <VoteProgress
              style={{
                background: props.votedMost ? "#EF45C7" : "#34A6FF",
              }}
              $maxWidth={
                (containerRef.current?.clientWidth || 0) * percentValue
              }
            />
          )}

          {props.showDetail && (
            <HStack style={{ width: "100%", height: "100%" }}>
              <OptionContent
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  flex: 1,
                }}
              >
                {props.pollItem.content}
              </OptionContent>
              {isMyVote && (
                <img
                  src={icCheck}
                  width={16}
                  height={16}
                  style={{ marginRight: 4 }}
                />
              )}
              <PercentText>{(percentValue * 100).toFixed(0)}%</PercentText>
            </HStack>
          )}
        </PollContentContainer>
        {props.showDetail && props.pollItem.votesCount > 0 && (
          <HStack style={{ marginTop: 8, width: "100%" }}>
            <HStack style={{ flex: 1 }}>
              {props.pollItem.votedUsers?.slice(0, 4).map((user, index) => (
                <VoterContainer
                  key={`voter-${user.uid}`}
                  style={{ marginLeft: index === 0 ? 0 : -5 }}
                  onClick={(event) => {
                    event.stopPropagation();
                    hopper.push(`user/${user.uid}`);
                  }}
                >
                  <Image
                    src={[user.icon, "best"]}
                    style={{
                      width: 24,
                      height: 24,
                      borderRadius: "50%",
                      border: "1px solid white",
                      boxSizing: "border-box",
                    }}
                  />
                  {(props.pollItem.votedUsers?.length || 0) > 4 &&
                    index === 3 && (
                      <VoterDimmerContainer>
                        <VoterMoreLayer src={icThreeDot} />
                      </VoterDimmerContainer>
                    )}
                </VoterContainer>
              ))}
            </HStack>
            <VoteCountText>
              {i18n
                .plural(props.pollItem.votesCount)
                .poll_count(props.pollItem.votesCount)}
            </VoteCountText>
          </HStack>
        )}
      </VStack>
    </HStack>
  );
}

const OptionMediaContainer = styled.div`
  position: relative;
  display: grid;
`;

const VideoPlayIcon = styled.img`
  width: 25px;
  height: 25px;
`;

const PollContentContainer = styled.div`
  position: relative;
  height: 48px;
  border-radius: 8px;
  padding: 0 9px;
  flex: 1;
`;

const OptionContent = styled.div`
  font-weight: 700;
  font-size: 12px;
  height: 46px;
  color: white;
  ${OverFlowTextStyle};
`;

const PercentText = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: white;
`;

const VoteAnimation = (maxWidth: number) => keyframes`
    0% {
        width: 0
    }
    100% {
        width: ${maxWidth}px;
    }
`;

const VoteProgress = styled.div<{ $maxWidth: number }>`
  position: absolute;
  left: 0;
  height: 48px;
  border-radius: 8px;
  animation: ${(props) => VoteAnimation(props.$maxWidth)} 1s forwards;
`;

const VoterContainer = styled.div`
  position: relative;
`;

const VoterDimmerContainer = styled.div`
  position: absolute;
  box-sizing: border-box;
  display: grid;
  justify-content: center;
  background-color: #0000007e;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  border: 1px solid white;
  top: 0;
  left: 0;
`;

const VoterMoreLayer = styled.img`
  width: 20px;
  height: 20px;
`;

const VoteCountText = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #ffffffcc;
`;
