import { keyframes } from "styled-components";

export const Wiggle = keyframes`
  0%, 7% {
    transform: rotateZ(0);
  }
  15% {
    transform: rotateZ(-15deg);
  }
  20% {
    transform: rotateZ(10deg);
  }
  25% {
    transform: rotateZ(-10deg);
  }
  30% {
    transform: rotateZ(6deg);
  }
  35% {
    transform: rotateZ(-4deg);
  }
  40%, 100% {
    transform: rotateZ(0);
  }
`;

export const FadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;
export const FadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
export const SlideUp = keyframes`
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
`;

export const SlideDown = keyframes`
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(100%);
  }
`;

export const SlideUpFromEdge = keyframes`
  0% {
    transform: translateY(100svh);
  }
  100% {
    transform: translateY(0);
  }
`;

export const SlideDownToEdge = keyframes`
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(100svh);
  }
`;

export const SlideInFromStart = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
`;

export const SlideOutToStart = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
`;

export const SlideInFromEnd = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
`;

export const SlideOutToEnd = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
`;

export const None = keyframes``;

export const BounceIn = keyframes`
  0% {
    transform: scale(.3);
  }
  50% {
    transform: scale(1.05);
  }
  70% {
    transform: scale(.9);
  }
  100% {
    transform: scale(1);
  }
`;

export const ShrinkFadeOut = keyframes`
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.8);
  }
`;
