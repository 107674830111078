import { string, z } from "zod";
import { Background } from "./Background";
import { zBigInt, zStatic } from "../utils/zodUtils";

export const Interest = z.object({
  insId: zBigInt,
  insName: string().optional(),
  style: Background.optional(),
});
export type Interest = zStatic<typeof Interest>;
