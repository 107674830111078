import { Page } from "../../../components/Page";
import { useBigIntParam } from "../../../hooks/useTypedParam";
import { NavMiddle } from "../../../components/NavBar";
import { useI18n } from "../../../hooks/useI18n";
import {
  getTransactionActivity,
  getTransactionIcon,
  TransactionStatus,
  transactionTitle,
} from "../utils/Transaction";
import { Image } from "../../../components/Image";
import styled from "styled-components";
import {
  TransactionAmountType,
  TransactionLog,
  TransactionReason,
} from "../../../proto/TransactionLog";
import {
  CurrencyType,
  formatMoney,
  getCurrencyIcon,
  getCurrencyName,
} from "../../../proto/Currency";
import { HStack, HStackMixin, Spring } from "../../../components/VStack";
import { WeakButton } from "../../../components/Buttons";
import { CellDivider, ContentGroup } from "../../../components/ContentGroup";
import { OrderInfoContent, OrderInfoLabel } from "./GiftOrderDetail";
import { ObjectType } from "../../../proto/ObjectSpec";
import { ChatThreadType } from "../../../proto/ChatThreadType";
import { useMyUid } from "../../../service/AuthSessionService";
import { User } from "../../../proto/User";
import icPlaceHolder from "../../../res/images/ic_chat_list_avatar_placeholder.png";
import { formatDateWithOptions } from "../../../utils/DateTimeUtils";
import { useLocalRepo } from "../../../hooks/swr/useLocalRepo";
import { OrderType } from "./OrderDetailPage";
import { useHopper } from "../../../hooks/useHopper";
import { StatusBar } from "./GiftOrderListPage";
import {
  headSixTailFourEllipsis,
  isValidBase58,
} from "../../../utils/StringUtil";
import icMembershipCard from "../../../res/images/ic_transaction_membership_card.png";
import { getTradeDescription } from "../account/TransactionCell";
import icCopy from "../../../res/images/ic_copy.svg";
import icSolScan from "../../../res/images/ic_sol_scan.png";
import { copyTextToClipboard } from "../../../utils/CopyText";
import { andLog } from "../../../components/handleError";
import { useNativePage, useWebHost } from "../../../hooks/useBridge";
import { alpha_on_pressed } from "../../../components/CommonStyles";
import { isSpongeKit } from "../../../utils/isSpongeKit";
import CircleCardsPreviewCell from "../../circle/card/CircleCardsPreviewCell";
import iconProto from "../../../res/images/ic_transaction_proto_nft.png";

export function TransactionDetailPage() {
  const i18n = useI18n();
  const myUid = useMyUid();
  const transactionRepo = useLocalRepo<TransactionLog>("transactionDetail");
  const hopper = useHopper();
  const transactionLog = transactionRepo.content;
  const transactionId = useBigIntParam("transactionId");
  const nativePage = useNativePage();

  const noOrderDetailList = [
    TransactionReason.Redeem,
    TransactionReason.TxReasonStoreOrderServiceFee,
    TransactionReason.TxReasonWithdrawServiceFee,
    TransactionReason.UsePrimeMembershipCoupon,
    TransactionReason.InjectForCreatingNFT,
    TransactionReason.GasFee,
  ];

  const showViewDetail =
    !noOrderDetailList.find((reason) => transactionLog?.txReason === reason) &&
    !(
      transactionLog?.txReason === TransactionReason.TransferChainAsset &&
      transactionLog.txAmountType === TransactionAmountType.ADD
    );

  const operation =
    transactionLog?.txAmountType === TransactionAmountType.ADD ? "+" : "-";
  const isNFT = transactionLog?.currency?.currencyType === CurrencyType.NFT;
  const isCoupon =
    transactionLog?.currency?.currencyType === CurrencyType.COUPON;
  const amount =
    isNFT || isCoupon
      ? transactionLog?.amount
      : formatMoney("long", transactionLog?.currency);
  const isRedeem = transactionLog?.txReason === TransactionReason.Redeem;

  function getWhom() {
    if (
      transactionLog?.transferOrder?.giftBox?.toObjectType === ObjectType.CHAT
    ) {
      const chat = transactionLog?.transferOrder?.giftBox.chatThread;
      const chatName = chat?.title
        ? chat?.title
        : chat?.membersSummary?.map((user) => user.nickname).join(",");

      if (chat?.type === ChatThreadType.single) {
        return (
          <HStack style={{ gap: 4 }}>
            <Image
              src={[
                chat.membersSummary?.find((user) => user.uid !== myUid)?.icon,
                "best",
              ]}
              style={{ borderRadius: "50%", width: 26, height: 26 }}
            />
            <OrderInfoContent>{chatName}</OrderInfoContent>
          </HStack>
        );
      } else {
        if (chat?.icon) {
          return (
            <HStack style={{ gap: 4 }}>
              <Image
                src={[chat?.icon, { width: 40, height: 30 }]}
                style={{
                  width: 40,
                  height: 30,
                  borderRadius: 3,
                  border: "0.5px solid white",
                }}
              />
              <OrderInfoContent>{chatName}</OrderInfoContent>
            </HStack>
          );
        } else {
          if (chat?.membersSummary) {
            return (
              <HStack style={{ gap: 4 }}>
                <ChatIconGroup userList={chat?.membersSummary} />
                <OrderInfoContent>{chatName}</OrderInfoContent>
              </HStack>
            );
          }
        }
      }
    } else {
      const user =
        transactionLog?.txReason == TransactionReason.CreateTransferOrder
          ? transactionLog.transferOrder?.toUser
          : transactionLog?.txReason == TransactionReason.ReturnTransferOrder
            ? transactionLog?.transferOrder?.toUser
            : transactionLog?.transferOrder?.fromUser;
      return (
        <HStack
          style={{ gap: 4 }}
          onClick={() => {
            if ((user?.uid || 0) < 1000) {
              hopper.push(`user-official`);
            } else {
              hopper.push(`user/${user?.uid}`);
            }
          }}
        >
          <Image
            src={[user?.icon, { width: 26, height: 26 }]}
            style={{ width: 26, height: 26, borderRadius: "50%" }}
          />
          <OrderInfoContent>{user?.nickname}</OrderInfoContent>
        </HStack>
      );
    }
  }

  const storeOrderItem = transactionLog?.storeOrder?.orderItemList?.at(0);

  function getStatus() {
    switch (transactionLog?.txStatus) {
      case TransactionStatus.DONE:
        return { text: i18n.web3_v0_completed(), color: "#00E25BFF" };
      case TransactionStatus.PENDING:
      case TransactionStatus.SUBMITTED:
        return { text: i18n.processing(), color: "#FFB332FF" };
      default:
        return { text: i18n.circle_anmt_failed(), color: "#EF3537FF" };
    }
  }

  const isMembershipCard =
    isNFT &&
    (transactionLog.txReason === TransactionReason.GET_MEMBERSHIP_CARD ||
      transactionLog.txReason === TransactionReason.REDEEM_MEMBERSHIP_CARD);

  const webHost = useWebHost();

  async function goToSolScan(txHash: string) {
    try {
      if (isSpongeKit()) {
        await webHost.openInWebBrowser(
          `https://solscan.io/tx/${txHash}/?cluster=devnet`
        );
      } else {
        await webHost.openInWebBrowser(`https://solscan.io/tx/${txHash}`);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const hasStatusItem =
    !!transactionLog?.txReason &&
    [
      TransactionReason.TransferChainAsset,
      TransactionReason.TRADE_FROM,
      TransactionReason.TRADE_TO,
      TransactionReason.SwapFrom,
      TransactionReason.SwapTo,
      TransactionReason.CashSwapFrom,
    ].includes(transactionLog.txReason);

  if (transactionId === transactionLog?.txId) {
    return (
      <Page pageData={undefined}>
        {transactionLog && (
          <Image
            src={getTransactionIcon(transactionLog)}
            width={56}
            height={56}
            style={{ marginTop: 20, alignSelf: "center" }}
          />
        )}
        {transactionLog && (
          <TransactionTitle>
            {transactionTitle(transactionLog, i18n)}
          </TransactionTitle>
        )}
        {(transactionLog.txReason === TransactionReason.TRADE_FROM ||
          transactionLog.txReason === TransactionReason.TRADE_TO) && (
          <SubTitle>{getTradeDescription(transactionLog, i18n)}</SubTitle>
        )}
        <HStack style={{ gap: 4, marginTop: 6, alignSelf: "center" }}>
          {isMembershipCard ? (
            <Image
              src={icMembershipCard}
              style={{ width: 24, height: 16.5, borderRadius: 2 }}
            />
          ) : (
            <Image
              src={getCurrencyIcon(transactionLog?.currency)}
              style={{ width: 20, height: 20, borderRadius: 4 }}
            />
          )}

          <TransactionAmount>{operation + amount}</TransactionAmount>
        </HStack>

        <ContentGroup style={{ width: "100%", gap: 20, marginTop: 30 }}>
          {hasStatusItem && (
            <>
              <InfoItem>
                <OrderInfoLabel>{i18n.status()}</OrderInfoLabel>
                <Spring />
                <StatusBar
                  statusColor={getStatus().color}
                  statusText={getStatus().text}
                />
              </InfoItem>
              <CellDivider />
            </>
          )}

          <InfoItem>
            <OrderInfoLabel>{i18n.web3_v0_type()}</OrderInfoLabel>
            <Spring />
            <OrderInfoContent>
              {getTransactionActivity(transactionLog, i18n)}
            </OrderInfoContent>
          </InfoItem>

          {(transactionLog?.txReason === TransactionReason.ThirdPartyPurchase ||
            transactionLog?.txReason === TransactionReason.TopUp ||
            transactionLog?.txReason === TransactionReason.SwapFrom ||
            transactionLog?.txReason === TransactionReason.InAppPurchase ||
            transactionLog?.txReason === TransactionReason.WithDrawStore ||
            transactionLog?.txReason ===
              TransactionReason.TxReasonStoreOrderServiceFee ||
            transactionLog?.txReason ===
              TransactionReason.TxReasonWithdrawServiceFee ||
            transactionLog?.txReason ===
              TransactionReason.TransferOrderServiceFee ||
            transactionLog?.txReason ===
              TransactionReason.ReturnTransferOrderServiceFee ||
            transactionLog?.txReason === TransactionReason.GasFee ||
            transactionLog?.txReason ===
              TransactionReason.GiftOrderServiceFee ||
            transactionLog?.txReason === TransactionReason.WithDraw ||
            transactionLog?.txReason === TransactionReason.SwapTo ||
            isRedeem ||
            transactionLog?.txReason ===
              TransactionReason.CashTransferOutServiceFee ||
            transactionLog?.txReason === TransactionReason.TransferChainAsset ||
            transactionLog?.txReason === TransactionReason.TRADE_FROM ||
            transactionLog?.txReason === TransactionReason.TRADE_TO ||
            transactionLog?.txReason ===
              TransactionReason.GET_MEMBERSHIP_CARD ||
            transactionLog?.txReason ===
              TransactionReason.StakeMembershipCard ||
            transactionLog?.txReason ===
              TransactionReason.REDEEM_MEMBERSHIP_CARD ||
            transactionLog?.txReason === TransactionReason.TOKEN_CREATION_FEE ||
            transactionLog?.txReason ===
              TransactionReason.TransferAdminCommission) && (
            <>
              <CellDivider />
              <InfoItem>
                <OrderInfoLabel>{i18n.web3_v0_assets()}</OrderInfoLabel>
                <Spring />
                <HStack style={{ gap: 4 }}>
                  {isMembershipCard && (
                    <CircleCardsPreviewCell
                      nft={transactionLog?.nft}
                      width={32}
                    />
                  )}
                  {!isMembershipCard && (
                    <Image
                      src={getCurrencyIcon(transactionLog?.currency)}
                      style={{ width: 18, height: 18, borderRadius: 4 }}
                    />
                  )}
                  <OrderInfoContent>
                    {isMembershipCard
                      ? transactionLog?.nft?.name
                      : getCurrencyName(transactionLog?.currency, i18n)}
                  </OrderInfoContent>
                </HStack>
              </InfoItem>
            </>
          )}

          {transactionLog?.txReason ===
            TransactionReason.TransferChainAsset && (
            <>
              {transactionLog?.txAmountType === TransactionAmountType.MINUS && (
                <>
                  <CellDivider />
                  <InfoItem>
                    <OrderInfoLabel>
                      {i18n.web3_v0_service_fee()}
                    </OrderInfoLabel>
                    <Spring />
                    <OrderInfoContent>{`${formatMoney("long", transactionLog.gasFee)} ${getCurrencyName(transactionLog.gasFee, i18n)}`}</OrderInfoContent>
                  </InfoItem>
                </>
              )}
              <CellDivider />
              <InfoItem>
                <OrderInfoLabel>
                  {transactionLog?.txAmountType === TransactionAmountType.MINUS
                    ? i18n.web3_v0_to_whom()
                    : i18n.web3_v0_from_whom()}
                </OrderInfoLabel>
                <Spring />
                <OrderInfoContent>
                  {transactionLog?.txAmountType === TransactionAmountType.MINUS
                    ? transactionLog.toChainAddress
                    : transactionLog.fromChainAddress}
                </OrderInfoContent>
              </InfoItem>
            </>
          )}

          {(transactionLog?.txReason === TransactionReason.ClaimTransferOrder ||
            transactionLog?.txReason ===
              TransactionReason.CreateTransferOrder ||
            transactionLog?.txReason ===
              TransactionReason.ReturnTransferOrder) && (
            <>
              <CellDivider />
              <InfoItem>
                <OrderInfoLabel>
                  {transactionLog?.txReason ===
                  TransactionReason.CreateTransferOrder
                    ? i18n.web3_v0_to_whom()
                    : i18n.web3_v0_from_whom()}
                </OrderInfoLabel>
                <Spring />
                <OrderInfoContent>{getWhom()}</OrderInfoContent>
              </InfoItem>
              <CellDivider />
              <InfoItem>
                <OrderInfoLabel>{i18n.web3_v0_assets()}</OrderInfoLabel>
                <Spring />
                <HStack style={{ gap: 6 }}>
                  {isNFT && (
                    <Image
                      src={[
                        transactionLog.nft?.entity?.preview,
                        { width: 22, height: 22 },
                      ]}
                      width={22}
                      height={22}
                    />
                  )}

                  <OrderInfoContent>
                    {isNFT
                      ? transactionLog.nft?.name
                      : getCurrencyName(transactionLog.currency, i18n)}
                  </OrderInfoContent>
                </HStack>
              </InfoItem>
            </>
          )}

          {transactionLog?.txReason == TransactionReason.NftBought && (
            <>
              <CellDivider />
              <InfoItem>
                <OrderInfoLabel>{i18n.web3_v0_assets()}</OrderInfoLabel>
                <Spring />
                <HStack style={{ gap: 4 }}>
                  <Image
                    src={[storeOrderItem?.cover, { width: 22, height: 22 }]}
                    width={22}
                    height={22}
                  />
                  <OrderInfoContent>{storeOrderItem?.title}</OrderInfoContent>
                </HStack>
              </InfoItem>
            </>
          )}

          {(transactionLog?.txReason === TransactionReason.PayForOrder ||
            transactionLog?.txReason ===
              TransactionReason.UseCouponPayForOrder) && (
            <>
              <CellDivider />
              <InfoItem>
                <OrderInfoLabel>{i18n.web3_v0_assets()}</OrderInfoLabel>
                <Spring />
                <OrderInfoContent>
                  {getCurrencyName(transactionLog.currency, i18n)}
                </OrderInfoContent>
              </InfoItem>

              <CellDivider />
              <InfoItem>
                <OrderInfoLabel>
                  {transactionLog?.txAmountType === TransactionAmountType.ADD
                    ? i18n.web3_v0_sale()
                    : i18n.web3_v0_buy()}
                </OrderInfoLabel>
                <Spring />
                <HStack style={{ gap: 4 }}>
                  <Image
                    src={[storeOrderItem?.cover, { width: 22, height: 22 }]}
                    width={22}
                    height={22}
                  />
                  <OrderInfoContent>{storeOrderItem?.title}</OrderInfoContent>
                </HStack>
              </InfoItem>
            </>
          )}

          {(transactionLog?.txReason === TransactionReason.MintNFT ||
            transactionLog?.txReason ===
              TransactionReason.UserMintNFTServiceFee ||
            transactionLog?.txReason === TransactionReason.MintSpeedUpFee ||
            transactionLog?.txReason === TransactionReason.UseMintCoupon ||
            transactionLog?.txReason ===
              TransactionReason.UserBuyAIImageQuotaPackage ||
            transactionLog?.txReason ===
              TransactionReason.TxReasonUserBuyAIChatQuotaPackage ||
            transactionLog?.txReason ===
              TransactionReason.TxReasonUseCouponPayForAIImage ||
            transactionLog?.txReason ===
              TransactionReason.UsePrimeMembershipCoupon ||
            transactionLog?.txReason ===
              TransactionReason.TxReasonUseCouponPayForAIChat) && (
            <>
              {(transactionLog.assetOrder ||
                transactionLog.txReason ===
                  TransactionReason.UsePrimeMembershipCoupon) && (
                <>
                  <CellDivider />
                  <InfoItem>
                    <OrderInfoLabel>{i18n.web3_v0_assets()}</OrderInfoLabel>
                    <Spring />
                    <OrderInfoContent>
                      {getCurrencyName(transactionLog.currency, i18n)}
                    </OrderInfoContent>
                  </InfoItem>
                </>
              )}
              {(transactionLog.txReason ==
                TransactionReason.UserBuyAIImageQuotaPackage ||
                transactionLog.txReason ==
                  TransactionReason.TxReasonUserBuyAIChatQuotaPackage) && (
                <>
                  <CellDivider />
                  <InfoItem>
                    <OrderInfoLabel>{i18n.web3_v0_buy()}</OrderInfoLabel>
                    <Spring />
                    <OrderInfoContent>
                      {
                        transactionLog.assetOrder?.purchaseQuotaPackageInfo
                          ?.packageInfo?.title
                      }
                    </OrderInfoContent>
                  </InfoItem>
                </>
              )}

              {transactionLog.txReason ==
                TransactionReason.UsePrimeMembershipCoupon && (
                <>
                  <CellDivider />
                  <InfoItem>
                    <OrderInfoLabel>{i18n.web3_v0_buy()}</OrderInfoLabel>
                    <Spring />
                    <OrderInfoContent>
                      {transactionLog.coupon?.title}
                    </OrderInfoContent>
                  </InfoItem>
                </>
              )}
            </>
          )}

          {(transactionLog?.txReason ===
            TransactionReason.InjectForCreatingNFT ||
            transactionLog?.txReason === TransactionReason.ZECMintNFT ||
            transactionLog?.txReason ===
              TransactionReason.InjectForUserMintNFT) && (
            <>
              <CellDivider />
              <InfoItem>
                <OrderInfoLabel>{i18n.web3_v0_assets()}</OrderInfoLabel>
                <Spring />
                <HStack style={{ gap: 4 }}>
                  <Image
                    src={
                      transactionLog.nft?.entity?.preview
                        ? [transactionLog.nft?.entity?.preview, "smallest"]
                        : iconProto
                    }
                    style={{ width: 22, height: 22, borderRadius: 4 }}
                  />
                  <OrderInfoContent>
                    {transactionLog.nft?.name}
                  </OrderInfoContent>
                </HStack>
              </InfoItem>
            </>
          )}

          {(transactionLog?.txReason ===
            TransactionReason.TxReasonNFTCreatorFee ||
            transactionLog?.txReason === TransactionReason.CashTransferOut ||
            transactionLog?.txReason === TransactionReason.CashSwapFrom ||
            transactionLog?.txReason === TransactionReason.TxReasonReturn) && (
            <>
              <CellDivider />
              <InfoItem>
                <OrderInfoLabel>{i18n.web3_v0_assets()}</OrderInfoLabel>
                <Spring />
                <OrderInfoContent>
                  {getCurrencyName(transactionLog?.currency, i18n)}
                </OrderInfoContent>
              </InfoItem>
            </>
          )}

          {transactionLog?.txAmountType === TransactionAmountType.MINUS &&
            transactionLog?.coupon?.template?.extensions
              ?.supportOptionService && (
              <>
                <CellDivider />
                <InfoItem>
                  <OrderInfoLabel>{i18n.zed_eco_chain_add_on()}</OrderInfoLabel>
                  <Spring />
                  <OrderInfoContent>
                    {i18n.zed_eco_chain_expedited_minting_speed()}
                  </OrderInfoContent>
                </InfoItem>
              </>
            )}

          {isRedeem && (
            <>
              <CellDivider />
              <InfoItem>
                <OrderInfoLabel>
                  {i18n.web3_redeem_payment_request_id()}
                  <Spring />
                  <OrderInfoContent>
                    {transactionLog?.externalRelatedId}
                  </OrderInfoContent>
                </OrderInfoLabel>
              </InfoItem>
            </>
          )}

          <CellDivider />
          <InfoItem>
            <OrderInfoLabel>
              {i18n.web3_v0_transaction_detail_time()}
            </OrderInfoLabel>
            <Spring />
            <OrderInfoContent>
              {formatDateWithOptions(transactionLog?.createdTime || 0, {
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })}
            </OrderInfoContent>
          </InfoItem>

          {transactionLog?.externalRelatedId &&
            isValidBase58(transactionLog?.externalRelatedId) && (
              <>
                <CellDivider />
                <InfoItem>
                  <OrderInfoLabel>{i18n.clover_tx_hash()}</OrderInfoLabel>
                  <Spring />
                  <HashContainer
                    style={{ gap: 4 }}
                    onClick={() => {
                      if (transactionLog?.externalRelatedId) {
                        copyTextToClipboard(transactionLog?.externalRelatedId)
                          .then(() => {
                            nativePage
                              .successHud(i18n.clover_copied_successfully())
                              .catch(andLog);
                          })
                          .catch(andLog);
                      }
                    }}
                  >
                    <CopyImg src={icCopy} />
                    <OrderInfoContent>
                      {headSixTailFourEllipsis(
                        transactionLog?.externalRelatedId
                      )}
                    </OrderInfoContent>
                  </HashContainer>
                  <ScanImg
                    src={icSolScan}
                    onClick={() => {
                      if (transactionLog?.externalRelatedId) {
                        goToSolScan(transactionLog?.externalRelatedId).catch(
                          andLog
                        );
                      }
                    }}
                  />
                </InfoItem>
              </>
            )}

          <CellDivider />
          <InfoItem>
            <OrderInfoLabel>{i18n.web3_v0_transaction_id()}</OrderInfoLabel>
            <Spring />
            <OrderInfoContent>
              {transactionLog?.txId?.toString()}
            </OrderInfoContent>
          </InfoItem>

          {showViewDetail && (
            <WeakButton
              style={{
                alignSelf: "center",
                minWidth: 251,
                marginTop: 20,
              }}
              onClick={() => {
                if (transactionLog?.transferOrder) {
                  hopper.push(
                    `wallet/order/${transactionLog?.transferOrder.orderId}`,
                    { orderType: OrderType.GiftOrder }
                  );
                } else if (transactionLog?.storeOrder) {
                  hopper.push(
                    `wallet/order/${transactionLog?.storeOrder.orderId}`,
                    { orderType: OrderType.StoreOrder }
                  );
                } else if (transactionLog?.assetOrder) {
                  hopper.push(
                    `wallet/order/${transactionLog?.assetOrder.orderId}`,
                    { orderType: OrderType.OtherOrder }
                  );
                }
              }}
            >
              {i18n.web3_v0_view_order_detail()}
            </WeakButton>
          )}
        </ContentGroup>

        <NavMiddle>{i18n.web3_v0_transaction_detail_title()}</NavMiddle>
      </Page>
    );
  } else {
    return <></>;
  }
}

const TransactionTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: white;
  align-self: center;
  text-align: center;
`;

const TransactionAmount = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: white;
`;

const InfoItem = styled.div`
  width: 100%;
  ${HStackMixin};
`;

export const ChatGroupIconContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  width: 36px;
`;

const SubTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: #ffffff66;
  align-self: center;
  margin-top: 8px;
`;

function ChatIconGroup(props: { userList: User[] }) {
  const users: Array<User | undefined> = [...props.userList];
  users.fill(undefined, props.userList.length, 4);
  return (
    <ChatGroupIconContainer>
      {users.slice(0, 4).map((user, index) => {
        let margin = undefined;

        let zIndex = 0;
        switch (index) {
          case 0: {
            zIndex = 5;
            break;
          }
          case 1: {
            zIndex = 4;
            margin = "0 0 0 -8px";
            break;
          }
          case 2: {
            zIndex = 2;
            margin = "-8px 0 0 0";
            break;
          }
          case 3: {
            zIndex = 3;
            margin = "-8px 0 0 -8px";
            break;
          }
        }
        return (
          <Image
            src={user ? [user.icon, "best"] : icPlaceHolder}
            style={{
              width: 22,
              height: 22,
              borderRadius: "50%",
              border: "1px solid white",
              boxSizing: "border-box",
              zIndex: zIndex,
              margin: margin,
            }}
          />
        );
      })}
    </ChatGroupIconContainer>
  );
}

const HashContainer = styled.div`
  ${alpha_on_pressed}
  ${HStackMixin};
`;

const CopyImg = styled.img`
  width: 20px;
  height: 20px;
`;
const ScanImg = styled.img`
  width: 16px;
  height: 16px;
  margin-left: 8px;
`;
