import { Page } from "../../components/Page";
import { useHopper } from "../../hooks/useHopper";
import { Divider } from "../post/PostCell";
import CircleSnippetCell from "../circle/CircleSnippetCell";
import { SearchProps } from "./SearchPage";
import { useBackend } from "../../service/APIService";
import * as React from "react";
import { useEffect } from "react";
import { andLog } from "../../components/handleError";
import TabCellSeparator from "../../components/TabCellSeparator";
import { useI18n } from "../../hooks/useI18n";
import { useSWRList } from "../../hooks/swr/useSWRList";
import { hPaddingWithPageInset } from "../../components/CommonStyles";

function SearchCirclePage({ word }: SearchProps) {
  const hopper = useHopper();
  const onClickCircle = (circleId: bigint) => {
    hopper.push(`circle/${circleId}`);
  };

  const backend = useBackend();
  const i18n = useI18n();
  const circlesSWR = useSWRList(["SearchCirclePage", word], () =>
    backend.searchCircles(word).run(),
  );

  useEffect(() => {
    circlesSWR.load().catch(andLog);
  }, [word]);

  return (
    <Page pageData={circlesSWR} scrollPaddingDisabled={true}>
      {circlesSWR.content && (
        <TabCellSeparator title={i18n.matched_by_keywords()}></TabCellSeparator>
      )}
      {circlesSWR.content &&
        circlesSWR.content.list.map((circle, index) => (
          <div key={circle.circleId}>
            <CircleSnippetCell
              hPadding={hPaddingWithPageInset}
              circle={circle}
              onClick={() => onClickCircle(circle.circleId)}
            />
            {index < circlesSWR.content.list.length - 1 && <Divider />}
          </div>
        ))}
    </Page>
  );
}

export default SearchCirclePage;
