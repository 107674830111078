import json_bigint from "json-bigint";
import { ZodType } from "zod/lib/types";
import { zStatic } from "./zodUtils";
import { pruneNulls } from "./pick";

const JSONbigNative = json_bigint({ useNativeBigInt: true });

export const JSONUtil = {
  parseUnknown(str: string): unknown {
    return JSONbigNative.parse(str);
  },

  parse<S extends ZodType<any, any, any>>(
    str: string,
    schema: S,
    keepsNull: boolean = false,
  ): zStatic<S> {
    const obj = JSONbigNative.parse(str);
    if (!keepsNull) {
      pruneNulls(obj);
    }
    return schema.parse(obj);
  },

  stringify(obj: any): string {
    return JSONbigNative.stringify(obj);
  },
};
