export interface ObjectSpec {
  objectId: bigint;
  objectType: number;
}

export enum ObjectType {
  NONE = 0,
  CHAT = 1,
  BLOG = 2,
  COMMENT = 3,
  USER = 4,
  CIRCLE = 5,
  INTEREST = 6,
  MESSAGE = 7,
  TAG = 8,
  CHAT_ROLE = 10,
  OPERATION_EVENT = 18,
  INVITATION_CODE = 22,
  CHAT_ANNOUNCEMENT = 26,
  POLL = 28,
  POLL_ITEM = 29,
  MEET_TEXT = 30,
  MEET_VOICE = 31,
  FOLDER = 32,
  LINK = 33,
  SECTION_HEADER = 34,
  BOTTLE = 36,
  CIRCLE_ANNOUNCEMENT = 37,
  PAGE_WIDGET = 40,
  CIRCLE_MEMBER_TITLE = 44,
  NFT = 53,
  MERCH = 55,
  STORE = 56,
  MY_TREASURES = 57,
  CONTRACT = 58,
  TRANSFER = 59,
  STORE_ORDER = 60,
  ASSET_ORDER = 61,
  MARKET_WIDGET_ITEM = 64,
  GIFT_BOX = 67,
  CHARACTER_CHAT = 68,
  COMPANION_SETTINGS = 69,
  CHARACTER = 72,
}
