import { andLog, useErrorHandler } from "../components/handleError";
import { useNativePage } from "../hooks/useBridge";

export function useGlobalSpinner() {
  const nativePage = useNativePage();
  const handleError = useErrorHandler();

  return async function <R>(task: () => Promise<R>, dontHandleError?: boolean) {
    let spinner;
    try {
      spinner = await nativePage.showSpinner();
      const r = await task();
      await nativePage.endSpinner(spinner);

      return {
        success: true as const,
        result: r,
      };
    } catch (error) {
      if (spinner) nativePage.endSpinner(spinner).catch(andLog);
      if (!dontHandleError) {
        handleError(error);
      }
      return {
        success: false as const,
        error: error,
      };
    }
  };
}
