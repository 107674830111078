import { number, z } from "zod";
import { Currency } from "./Currency";
import { zStatic } from "../utils/zodUtils";

export enum TokenTransactionType {
  BUY = 1,
  SELL = 2,
}

export const DexSwapInfo = z.object({
  priorityCurrency: Currency.optional(),
  tokenTransactionType: z.number().optional(),
  networkFee: Currency.optional(),
});

export type DexSwapInfo = zStatic<typeof DexSwapInfo>;
