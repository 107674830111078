import { z } from "zod";
import { zBigInt, zStatic } from "../utils/zodUtils";
import { User } from "./User";

const FanCircleInfo = z.object({
  circleId: zBigInt.optional(),
});

export const InviterInfo = z.object({
  inviter: User.optional(),
  fanCircleInfo: FanCircleInfo.optional(),
});

export type InviterInfo = zStatic<typeof InviterInfo>;
