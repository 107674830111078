import { PagedList, PageParam } from "../../proto/PagedList";
import { range } from "../../utils/List";
import { useSWRArray } from "../../hooks/swr/useSWRArray";
import { Page } from "../../components/Page";
import { DemoCell } from "./DemoCell";
import { useHopper } from "../../hooks/useHopper";

function getData(pageParam: PageParam) {
  return new Promise<PagedList<string>>((resolve, reject) => {
    setTimeout(() => {
      const section = pageParam.pageToken ? parseInt(pageParam.pageToken) : 0;
      const data = range(0, pageParam.size - 1).map((i) => `P${section}.${i}`);
      resolve({
        list: data,
        pagination: {
          nextPageToken: `${section + 1}`,
        },
      });
    }, 500);
  });
}

export function SimpleListDemoPage() {
  const dataSWR = useSWRArray(
    ["SimpleListDemoPage2"],
    (pageParam) => getData(pageParam),
    { maxSize: 30 },
  );

  const hopper = useHopper();

  return (
    <Page pageData={dataSWR}>
      {dataSWR.content.map((d) => (
        <DemoCell
          key={d}
          style={{ height: 50 }}
          onClick={() => hopper.push("notfound")}
        >
          {d}
        </DemoCell>
      ))}
    </Page>
  );
}
