import { useI18n, useIsRTL } from "../../../hooks/useI18n";
import { Page } from "../../../components/Page";
import { useHopper } from "../../../hooks/useHopper";
import React, { useMemo, useState } from "react";
import { useSWR } from "../../../hooks/swr/useSWR";
import { genderName, User } from "../../../proto/User";
import TabCellSeparator from "../../../components/TabCellSeparator";
import SettingCell, {
  SettingEndValueText,
} from "../../../components/SettingCell";
import { useMyUid } from "../../../service/AuthSessionService";
import { NavMiddle } from "../../../components/NavBar";
import { useBackend } from "../../../service/APIService";
import { useNativePage } from "../../../hooks/useBridge";
import { andLog, useErrorHandler } from "../../../components/handleError";
import { BottomSheet, ModalTitle, useModal } from "../../../components/Modal";
import { HStack, VStack } from "../../../components/VStack";
import { AllGender, GenderSelectableView } from "../../auth/SelectGenderPage";
import { useObscuredZoneForKey } from "../../../hooks/useObscuredZones";
import { ButtonColor, RegularSmallButton } from "../../../components/Buttons";
import close from "../../../res/images/ic_modal_close.svg";
import { removeOptional } from "../../../utils/typeUtils";
import { AbsImage } from "../../../components/AbsImage";
import { BSMenu, BSMenuItem } from "../../../components/BSMenu";
import { allRegions, Region } from "../../../proto/Region";
import { AuthType, NumericBoolean } from "../../../proto/Auth";
import { Gender } from "../../../proto/UserEnums";
import { useGlobalSpinner } from "../../../utils/globalSpinner";
import { AuxPage } from "../../../components/AuxPage";
import { useGoogleAuth } from "../../../hooks/useGoogleAuth";
import { useAppleAuth } from "../../../hooks/useAppleAuth";
import { AppleId, GoogleId } from "../../../config/config";
import { OAuthType } from "../../auth/ThirdPartAccountConfirmPasswordPage";
import { SetPasswordType } from "../../auth/SetPasswordPage";

enum NeedPasswordAction {
  AddPhoneNumber,
  UpdateEmail,
  ChangePassword,
  ConnectApple,
  ConnectGoogle,
  ConnectTelegram,
  DisconnectApple,
  DisconnectGoogle,
  DisconnectTelegram,
  DeleteAccount,
}

export function AccountSettingsPage() {
  const i18n = useI18n();
  const myUid = useMyUid();
  const backend = useBackend();
  const hopper = useHopper();
  const userSWR = useSWR([`user-${myUid}`], () => backend.getUser(myUid).run());
  const account = useSWR(backend.account());
  const globalSpinner = useGlobalSpinner();
  const user = userSWR.content;
  const nativePage = useNativePage();
  const handleError = useErrorHandler();

  const genderBottomSheet = useModal("genderBottomSheet");
  const [obscuredZone] = useObscuredZoneForKey("Browser", "root");
  const [currentGender, setCurrentGender] = useState<Gender | undefined>(
    user?.gender,
  );

  const [appleAuth, appleState] = useAppleAuth(AppleId);
  const [googleAuth, googleState] = useGoogleAuth(GoogleId);

  async function checkCanDoAction(action: NeedPasswordAction) {
    if (account.content?.hasPassword !== NumericBoolean.True) {
      let title: string | undefined = undefined;
      let desc: string;
      switch (action) {
        case NeedPasswordAction.AddPhoneNumber:
          title = i18n.add_phone_number();
          desc = i18n.auth_impr_add_phone_number_hint();
          break;
        case NeedPasswordAction.UpdateEmail:
          title = i18n.change_email();
          desc = i18n.auth_impr_change_email_hint();
          break;
        case NeedPasswordAction.ChangePassword:
          desc = i18n.auth_impr_create_password_hint();
          break;
        case NeedPasswordAction.ConnectApple:
          title = i18n.auth_impr_connect_to(i18n.apple());
          desc = i18n.auth_impr_connect_to_third_part_account_hint(
            i18n.apple(),
          );
          break;
        case NeedPasswordAction.ConnectGoogle:
          title = i18n.auth_impr_connect_to(i18n.google());
          desc = i18n.auth_impr_connect_to_third_part_account_hint(
            i18n.google(),
          );
          break;
        case NeedPasswordAction.ConnectTelegram:
          title = i18n.auth_impr_connect_to(i18n.clover_telegram());
          desc = i18n.auth_impr_connect_to_third_part_account_hint(
            i18n.clover_telegram(),
          );
          break;
        case NeedPasswordAction.DisconnectApple:
          desc = i18n.auth_impr_disconnect_to_third_part_account_hint(
            i18n.apple(),
          );
          break;
        case NeedPasswordAction.DisconnectGoogle:
          desc = i18n.auth_impr_disconnect_to_third_part_account_hint(
            i18n.google(),
          );
          break;
        case NeedPasswordAction.DisconnectTelegram:
          desc = i18n.auth_impr_disconnect_to_third_part_account_hint(
            i18n.clover_telegram(),
          );
          break;
        case NeedPasswordAction.DeleteAccount:
          desc = i18n.auth_impr_delete_account_hint();
          break;
      }
      const userConfirm = await nativePage.alertAreYouSure(
        desc,
        i18n.continue_str(),
        i18n.cancel(),
      );
      if (userConfirm) {
        if (account.content?.appleId && appleAuth) {
          const code = await appleAuth.signIn();
          const query = {
            appleAuthorizationCode: code,
            authType: AuthType.Apple,
          };
          hopper.layer("create-new-password", query);
        } else if (account.content?.googleId && googleAuth) {
          const code = await googleAuth.signIn();
          const query = {
            googleCode: code,
            authType: AuthType.Google,
          };
          hopper.layer("create-new-password", query);
        }
        return false;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  async function onclickPhoneNumber() {
    try {
      const can = await checkCanDoAction(NeedPasswordAction.AddPhoneNumber);
      if (!can) return;
      hopper.push("associate-phone", {
        authType: AuthType.Phone,
        phoneNumber: account.content?.phoneNumber,
      });
    } catch (error) {
      handleError(error);
    }
  }

  async function onclickEmail() {
    try {
      const can = await checkCanDoAction(NeedPasswordAction.UpdateEmail);
      if (!can) return;
      hopper.push("associate-email", {
        authType: AuthType.Email,
        email: account.content?.email,
      });
    } catch (error) {
      handleError(error);
    }
  }

  async function onClickPassword() {
    try {
      const can = await checkCanDoAction(NeedPasswordAction.UpdateEmail);
      if (!can) return;
      hopper.push("change-password", {
        setPasswordType: SetPasswordType.Change,
      });
    } catch (error) {
      handleError(error);
    }
  }

  async function onClickConnectGoogle() {
    try {
      const can = await checkCanDoAction(
        account.content?.googleId === undefined
          ? NeedPasswordAction.ConnectGoogle
          : NeedPasswordAction.DisconnectGoogle,
      );
      if (!can) return;
      if (!googleAuth) return;
      const code = await googleAuth.signIn();
      hopper.layer("oauth-confirm-password", {
        type:
          account.content?.googleId === undefined
            ? OAuthType.Bind
            : OAuthType.Unbind,
        authType: AuthType.Google,
        googleCode: code,
      });
    } catch (error) {
      handleError(error);
    }
  }

  async function onClickConnectApple() {
    try {
      const can = await checkCanDoAction(
        account.content?.appleId === undefined
          ? NeedPasswordAction.ConnectApple
          : NeedPasswordAction.DisconnectApple,
      );
      if (!can) return;
      if (!appleAuth) return;
      const code = await appleAuth?.signIn();
      hopper.layer("oauth-confirm-password", {
        type:
          account.content?.appleId === undefined
            ? OAuthType.Bind
            : OAuthType.Unbind,
        authType: AuthType.Apple,
        appleAuthorizationCode: code,
      });
    } catch (e) {
      handleError(e);
    }
  }

  async function onClickDeleteAccount() {
    try {
      const can = await checkCanDoAction(NeedPasswordAction.DeleteAccount);
      if (!can) return;
      hopper.push("delete-account-declaration", {
        nickname: user?.nickname,
      });
    } catch (error) {
      handleError(error);
    }
  }

  const [currentGenderSelected, setCurrentGenderSelected] = useState<
    Gender | undefined
  >(currentGender);

  const genderClick = async () => {
    if (user?.extensions && user?.extensions.genderModified === true) {
      nativePage
        .alertNotice(
          i18n.gender_age_prompt_gender_change_error_tip(),
          i18n.ok(),
        )
        .catch(andLog);
      return;
    }
    genderBottomSheet.open();
  };

  const submitGender = () => {
    globalSpinner(async () => {
      const user: User = {
        uid: myUid,
        gender: currentGenderSelected,
      };
      await backend.updateProfile(user).run();
      genderBottomSheet.close();
      setCurrentGender(currentGenderSelected);
    }).catch(andLog);
  };

  const isRTL = useIsRTL();
  const regionMenu = useModal("regionMenu");
  const [currentRegionName, setCurrentRegionName] = React.useState<
    string | undefined
  >();

  function onRegionSelected(region: Region) {
    regionMenu.close();
    globalSpinner(async () => {
      const user: User = {
        uid: myUid,
        countryCode: region.code,
      };
      await backend.updateProfile(user).run();
      setCurrentRegionName(region.name);
    }).catch(andLog);
  }

  useMemo(() => {
    setCurrentGender(user?.gender);
    setCurrentRegionName(Region.from(user?.countryCode)?.name);
  }, [user]);

  return (
    <Page pageData={[userSWR, account]} scrollPaddingDisabled={true}>
      <NavMiddle>{i18n.account()}</NavMiddle>
      <TabCellSeparator title={i18n.basic_info()}></TabCellSeparator>
      <SettingCell
        title={i18n.user_social_id()}
        endValue={<SettingEndValueText>{user?.socialId}</SettingEndValueText>}
        onClick={() => hopper.modal("nyi")}
      ></SettingCell>
      <SettingCell
        title={i18n.phone()}
        endValue={account.content?.phoneNumber}
        onClick={onclickPhoneNumber}
      ></SettingCell>
      <SettingCell
        title={i18n.email()}
        endValue={account.content?.email}
        onClick={onclickEmail}
      ></SettingCell>
      <SettingCell
        title={i18n.password()}
        onClick={onClickPassword}
      ></SettingCell>
      <SettingCell
        title={i18n.gender_constel_date_of_birth()}
        endValue={<SettingEndValueText>{user?.birthday}</SettingEndValueText>}
        onClick={() => {
          nativePage
            .alertNotice(
              i18n.gender_constel_cant_change_birth_hint(),
              i18n.ok(),
            )
            .catch(andLog);
        }}
      ></SettingCell>
      <SettingCell
        title={i18n.user_gender()}
        endValue={
          <SettingEndValueText>
            {currentGender && genderName(i18n, currentGender)}
          </SettingEndValueText>
        }
        onClick={genderClick}
      ></SettingCell>
      <SettingCell
        title={i18n.region_info_impr_country_or_region()}
        endValue={
          <SettingEndValueText>
            {currentRegionName || i18n.select()}
          </SettingEndValueText>
        }
        onClick={() => {
          regionMenu.open();
        }}
      ></SettingCell>
      <SettingCell
        title={i18n.web3_ugc_identity_certificates()}
        showDivider={false}
        onClick={() => {}}
      ></SettingCell>
      <TabCellSeparator title={i18n.connected_account()}></TabCellSeparator>
      <SettingCell
        title={i18n.clover_connect_with_telegram()}
        showAccessoryImage={false}
        endValue={
          <RegularSmallButton $baseColor={ButtonColor.greenish}>
            {i18n.connect()}
          </RegularSmallButton>
        }
        onClick={() => {}}
      ></SettingCell>
      <SettingCell
        title={i18n.may23_impr_connect_with_google()}
        showAccessoryImage={false}
        endValue={
          <RegularSmallButton
            $baseColor={
              account.content?.googleId === undefined
                ? ButtonColor.greenish
                : ButtonColor.destructive
            }
          >
            {account.content?.googleId === undefined
              ? i18n.connect()
              : i18n.disconnect()}
          </RegularSmallButton>
        }
        onClick={onClickConnectGoogle}
      ></SettingCell>
      <SettingCell
        title={i18n.may23_impr_connect_with_apple()}
        showAccessoryImage={false}
        endValue={
          <RegularSmallButton
            $baseColor={
              account.content?.appleId === undefined
                ? ButtonColor.greenish
                : ButtonColor.destructive
            }
          >
            {account.content?.appleId === undefined
              ? i18n.connect()
              : i18n.disconnect()}
          </RegularSmallButton>
        }
        onClick={onClickConnectApple}
      ></SettingCell>
      <TabCellSeparator title={i18n.more()}></TabCellSeparator>
      <SettingCell
        showDivider={false}
        title={i18n.auth_delete_account()}
        onClick={onClickDeleteAccount}
      ></SettingCell>
      <BottomSheet modal={genderBottomSheet} hideCloseButton={true}>
        <VStack
          style={{
            padding: `20px 20px ${40 + obscuredZone.bottom}px`,
            gap: 18,
          }}
        >
          <AbsImage
            src={close}
            style={{ top: 12, left: 12 }}
            onClick={removeOptional(genderBottomSheet.close)}
          />
          <ModalTitle>{i18n.select_your_gender()}</ModalTitle>
          <RegularSmallButton
            disabled={
              !(
                currentGenderSelected && currentGenderSelected !== currentGender
              )
            }
            $baseColor={ButtonColor.greenish}
            style={{
              position: "absolute",
              top: 12,
              right: 12,
            }}
            onClick={submitGender}
          >
            {i18n.submit()}
          </RegularSmallButton>
          <HStack
            style={{
              margin: "33px auto 66px",
              justifyContent: "center",
              alignItems: "center",
              gap: "15px",
            }}
          >
            {AllGender.map((eleGender) => (
              <GenderSelectableView
                i18n={i18n}
                gender={eleGender}
                selected={
                  (currentGenderSelected || currentGender) === eleGender
                }
                onClick={() => setCurrentGenderSelected(eleGender)}
              ></GenderSelectableView>
            ))}
          </HStack>
        </VStack>
      </BottomSheet>
      <BSMenu modal={regionMenu}>
        {allRegions.map((region) => (
          <BSMenuItem
            title={isRTL ? region.rtlDes : region.des}
            onClick={() => {
              onRegionSelected(region);
            }}
          ></BSMenuItem>
        ))}
      </BSMenu>

      <AuxPage url={"aux-settings"} position={"start"} />
    </Page>
  );
}
