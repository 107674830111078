import { HStack, Spring, VStack } from "../../../components/VStack";
import { HSpacing, Spacing } from "../../../components/Spacing";
import { Divider } from "../../post/PostCell";
import React from "react";
import styled, { css } from "styled-components";
import { NFT } from "../../../proto/NFT";
import { Label, OneLineLabel } from "./Label";
import { SpeedTitle } from "../../../components/SpeedTitle";
import { useI18n } from "../../../hooks/useI18n";
import { formatMoney, plus } from "../../../proto/Currency";
import { PowerMarkdown } from "../../../components/PowerMarkdown";
import { formatDateWithOptions } from "../../../utils/DateTimeUtils";
import { CurrencyIcon } from "../../../components/views/CurrencyIcon";
import IcArrow from "../../chat/images/ic_arrow.svg";
import { Image } from "../../../components/Image";
import { alpha_on_pressed } from "../../../components/CommonStyles";
import { useHopper } from "../../../hooks/useHopper";

const RedeemInfo = styled.div`
  margin-top: 12px;
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.06);
  border-radius: 6px;
`;

const RewardTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  opacity: 0.6;

  flex-shrink: 3;
`;

const RewardCase = css`
  font-size: 12px;
  font-weight: 500;
  opacity: 0.6;
`;

export function NFTCardRewardInfoView(props: { nft: NFT }) {
  const i18n = useI18n();
  const hopper = useHopper();

  const card = props.nft.circleMembershipCard;

  const maturityPeriodDay =
    (props.nft.circleMembershipCard?.maturityPeriod ?? 0) / 86400;

  const isTemplate = props.nft.circleMembershipCard?.isTemplate ?? false;

  return (
    <>
      <SpeedTitle style={{ marginTop: 15 }}>
        {i18n.clover_reward_info()}
      </SpeedTitle>
      <RedeemInfo>
        <VStack style={{ padding: "0 16px" }}>
          {!isTemplate && (
            <>
              <div style={{ margin: "20px 0" }}>
                <HStack style={{ gap: 4 }}>
                  <RewardTitle>{i18n.clover_total_reward_amount()}</RewardTitle>
                  <Spring />
                  <CurrencyIcon size={20} currency={card?.currency} />
                  <OneLineLabel $fontSize={14}>
                    {formatMoney(
                      "medium",
                      plus(card?.currency, card?.earningCurrency),
                    )}{" "}
                    {card?.currency?.symbol}
                  </OneLineLabel>
                </HStack>
                <Spacing height={12} />
                <HStack style={{ minHeight: 24, gap: 4 }}>
                  <PowerMarkdown textStyle={RewardCase}>
                    {i18n.clover_reward_base()}
                  </PowerMarkdown>
                  <Spring />
                  <CurrencyIcon size={12} currency={card?.currency} />
                  <OneLineLabel $fontSize={12}>
                    {formatMoney("medium", card?.currency) ?? 0}{" "}
                    {card?.currency?.symbol}
                  </OneLineLabel>
                </HStack>
                <Spacing height={4} />
                <HStack style={{ minHeight: 24 }}>
                  <PowerMarkdown textStyle={RewardCase}>
                    {i18n.clover_reward_earnings()}
                  </PowerMarkdown>
                  <Spring />
                  <HStack
                    mixin={[alpha_on_pressed]}
                    onClick={() =>
                      hopper.push(
                        `nft-circle-card/${props.nft.nftId}/earnings-history`,
                      )
                    }
                  >
                    <CurrencyIcon size={12} currency={card?.currency} />
                    <HSpacing width={4} />
                    <Label $fontSize={12}>
                      {formatMoney("medium", card?.earningCurrency) ?? "0"}{" "}
                      {card?.currency?.symbol}
                    </Label>
                    <Image src={IcArrow} />
                  </HStack>
                </HStack>
              </div>
              <Divider />
            </>
          )}

          {isTemplate && (
            <>
              <HStack style={{ minHeight: 64 }}>
                <RewardTitle>{i18n.clover_free_token_rewards()}</RewardTitle>
                <Spring />
                <CurrencyIcon size={20} currency={card?.currency} />
                <HSpacing width={4} />
                <Label $fontSize={14}>
                  {formatMoney("medium", card?.currency)}{" "}
                  {card?.currency?.symbol}
                </Label>
              </HStack>
              <Divider />
            </>
          )}

          <HStack style={{ minHeight: 64 }}>
            <RewardTitle>{i18n.clover_mature_period()}</RewardTitle>
            <Spring />
            <>{i18n.plural(maturityPeriodDay).poll_days(maturityPeriodDay)}</>
          </HStack>
          <Divider />
          {!isTemplate && (
            <HStack style={{ minHeight: 64 }}>
              <RewardTitle>{i18n.clover_starting_time()}</RewardTitle>
              <Spring />
              <>
                {formatDateWithOptions(card?.createdTime ?? 0, {
                  month: "short",
                  year: "numeric",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: false,
                })}
              </>
            </HStack>
          )}
        </VStack>
      </RedeemInfo>
    </>
  );
}
