import { BareComment, Comment, isComment } from "../../proto/Comment";
import { useI18n } from "../../hooks/useI18n";
import {
  HStack,
  HStackMixin,
  VStack,
  VStackMixin,
} from "../../components/VStack";
import { UserAvatarView } from "../../components/views/UserAvatarView";
import { UserNameView } from "../../components/views/UserNameView";
import { PostMediaCell } from "../post/PostMediaContainer";
import { ReactionListView } from "../reaction/ReactionListView";
import { ObjectType } from "../../proto/ObjectSpec";
import styled, { css } from "styled-components";
import React, {
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import icAddReaction from "../../res/images/icon_add_reaction.svg";
import icComment from "../../res/images/ic_comment.svg";
import icImage from "../../res/images/ic_image.svg";
import icSendEnable from "../../res/images/ic_comment_send_enable.png";
import icSendDisable from "../../res/images/ic_comment_send_disable.png";
import icCancel from "../../res/images/ic_abar_cross.svg";
import { useBackend } from "../../service/APIService";
import { andLog, Spinner, useErrorHandler } from "../../components/handleError";
import { useNativePage, useWebHost } from "../../hooks/useBridge";
import { useGlobalSpinner } from "../../utils/globalSpinner";
import { PostType } from "../../proto/Post";
import { alpha_on_pressed, BlurBg } from "../../components/CommonStyles";
import { useObscuredZoneForKey } from "../../hooks/useObscuredZones";
import { OverFlowTextStyle } from "../../components/Text";
import { useHopper } from "../../hooks/useHopper";
import { useSWRList } from "../../hooks/swr/useSWRList";
import { LoadStateKind } from "../../hooks/LoadState";
import twitter from "twitter-text";
import { useOpenLink } from "../../hooks/useOpenLink";
import { ZDoc } from "../../components/zdoc/ZDoc";
import { ModalController, useModal } from "../../components/Modal";
import { StickerPicker } from "../reaction/StickerPicker";
import { isImage, isVideo, Media } from "../../proto/Media";
import { AspectRatioImage, Image } from "../../components/Image";
import icDelete from "../../res/images/ic_close_dark.svg";
import icVideo from "../../res/images/ic_video_play.svg";
import { genUUID } from "../../utils/uuid";
import { useMemoryRepoMap } from "../../hooks/swr/useLocalRepo";
import { zBigInt } from "../../utils/zodUtils";
import { z } from "zod";
import { Reaction } from "../../proto/Reaction";
import { useReactionRecord } from "../../hooks/useReactionRecord";
import { StringUtil } from "../../utils/StringUtil";

export function CommentCell(props: {
  parentId?: bigint;
  parentType?: number;
  comment: BareComment;
  onClickReply: (comment: BareComment) => void;
  onClick: (comment: BareComment) => void;
}) {
  const i18n = useI18n();
  const hopper = useHopper();

  return (
    <HStack
      style={{ alignItems: "start", gap: 12, width: "100%", marginBottom: 18 }}
      onClick={() => props.onClick(props.comment)}
    >
      <UserAvatarView user={props.comment.author} iconSize={36} />
      <VStack style={{ flex: 1 }}>
        <UserNameView
          user={props.comment.author}
          nameStyle={NameStyle}
          clickToProfile={true}
        />
        {props.comment.extensions?.replyToUser?.nickname && (
          <ReplyContainer>
            <ReplyToText>
              {i18n.meet_now_reply_to_your_match(
                <ReplyToUserName
                  onClick={() =>
                    hopper.push(
                      `user/${props.comment.extensions?.replyToUser?.uid}`,
                    )
                  }
                >
                  {props.comment.extensions?.replyToUser?.nickname}
                </ReplyToUserName>,
              )}
            </ReplyToText>
          </ReplyContainer>
        )}
        {props.comment.content && (
          <CommentContentFlow>
            <ZDoc
              content={props.comment.content}
              mediaList={undefined}
              pollList={undefined}
              onViewMedia={() => {}}
              richFormat={undefined}
            />
          </CommentContentFlow>
        )}

        {(props.comment.mediaList?.length || 0) > 0 && (
          <CommentMediaContainer>
            {props.comment.mediaList?.map((media, index) => (
              <PostMediaCell
                media={media}
                mediaList={props.comment.mediaList!!}
                index={index}
                smallSize={true}
                uid={props.comment.author.uid}
                objectId={props.comment.commentId}
                objectType={ObjectType.COMMENT}
              />
            ))}
          </CommentMediaContainer>
        )}

        <ReactionListView
          objectId={props.comment.commentId}
          objectType={ObjectType.COMMENT}
          reactions={props.comment.reactionCountList}
          enable={true}
        />
        <HStack style={{ gap: 12 }}>
          <CommentTimeLabel>
            {Intl.DateTimeFormat(navigator.language, {
              dateStyle: "medium",
              timeStyle: "short",
            }).format(props.comment.createdTime * 1000)}
          </CommentTimeLabel>
          <ReplyButton
            onClick={(event) => {
              event.stopPropagation();
              props.onClickReply(props.comment);
            }}
          >
            {i18n.reply()}
          </ReplyButton>
        </HStack>
        <div
          style={{
            width: "100%",
            height: 0.5,
            backgroundColor: "#FFFFFF33",
            marginTop: 12,
          }}
        />
        {isComment(props.comment) && (
          <SubCommentList
            parentId={props.parentId}
            parentType={props.parentType}
            comment={props.comment}
            onClickReply={props.onClickReply}
            onClick={props.onClick}
          />
        )}
      </VStack>
    </HStack>
  );
}

const NameStyle = css`
  font-weight: 600;
`;

const ReplyContainer = styled.div`
  margin-top: 12px;
`;

const ReplyToText = styled.div`
  font-weight: 300;
  font-size: 14px;
  color: white;
  word-break: break-all;
`;
const ReplyToUserName = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: #34a6ff;
  display: inline;
  ${alpha_on_pressed};
`;

const CommentContentFlow = styled.div`
  margin-top: 12px;
  width: 100%;
  word-break: break-all;
`;

const CommentContent = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: white;
  width: 100%;
  word-break: break-all;
`;

const UrlText = styled.span`
  text-decoration: underline;
  font-size: 14px;
  font-weight: 400;
  color: #34a6ff;
  word-break: break-all;
`;

const CommentTimeLabel = styled.div`
  font-weight: 400;
  font-size: 11px;
  color: #ffffff99;
`;

const ReplyButton = styled.div`
  font-weight: 400;
  font-size: 12px;
`;

const CommentMediaContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  column-gap: 6px;
  box-sizing: border-box;
  margin-top: 12px;
`;

function SubCommentList(props: {
  parentId?: bigint;
  parentType?: number;
  comment: Comment;
  onClickReply: (comment: BareComment) => void;
  onClick: (comment: BareComment) => void;
}) {
  const backend = useBackend();
  const i18n = useI18n();
  const [showMoreClickCount, setShowMoreClickCount] = useState(0);
  const [oldMoreCommentCount, setOldMoreCommentCount] = useState(0);
  const [oldList, setOldList] = useState<BareComment[] | undefined>();
  const reactionRepo = useReactionRecord();
  const subCommentsSWR = useSWRList(
    showMoreClickCount > 0 && props.parentId && props.parentType
      ? backend
          .getComments(
            props.parentId,
            props.parentType,
            props.comment.commentId,
          )
          .intercept((it) => {
            it.list.forEach((comment) => {
              reactionRepo
                .setValue(comment.commentId, comment.reactionCountList)
                .catch(andLog);
            });
          })
      : undefined,
    { pageSize: 5 },
  );
  const moreCommentCount = useMemo(() => {
    if (showMoreClickCount === 0) {
      const count =
        props.comment.subCommentsCount - props.comment.subComments.length;
      setOldMoreCommentCount(count);
      return count;
    } else if (subCommentsSWR?.loadState?.kind === LoadStateKind.loaded) {
      const count =
        props.comment.subCommentsCount -
        (subCommentsSWR?.content?.list.length || 0);
      setOldMoreCommentCount(count);
      return count;
    } else {
      return oldMoreCommentCount;
    }
  }, [showMoreClickCount, subCommentsSWR?.loadState]);

  const showList = useMemo(() => {
    if (showMoreClickCount === 0) {
      const list = props.comment.subComments;
      setOldList(list);
      return list;
    } else if (subCommentsSWR?.loadState?.kind === LoadStateKind.loaded) {
      const list = subCommentsSWR?.content.list;
      setOldList(list);
      return list;
    } else {
      return oldList;
    }
  }, [props.comment, subCommentsSWR?.loadState]);

  return (
    <VStack style={{ flex: 1, marginTop: 12 }}>
      {moreCommentCount > 0 && (
        <HStack style={{ marginBottom: 12 }}>
          <ReadMoreButton
            onClick={() => {
              if (showMoreClickCount + 1 >= 2) {
                subCommentsSWR?.loadMore();
              }
              setShowMoreClickCount(showMoreClickCount + 1);
            }}
          >
            {i18n.read_more_count(moreCommentCount)}
          </ReadMoreButton>
          {subCommentsSWR?.loadState?.kind === LoadStateKind.loading && (
            <Spinner
              style={{
                width: 10,
                height: 10,
                margin: 0,
                borderTopWidth: 2,
                borderWidth: 2,
              }}
            />
          )}
        </HStack>
      )}
      {showList
        ?.slice()
        .reverse()
        .map((comment) => (
          <CommentCell
            key={`${comment.commentId}`}
            comment={comment}
            onClickReply={props.onClickReply}
            parentId={props.parentId}
            parentType={props.parentType}
            onClick={props.onClick}
          />
        ))}
    </VStack>
  );
}

const ReadMoreButton = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: #34a6ff;
  margin-right: 4px;
  ${alpha_on_pressed};
`;

export function CommentInputBar(props: {
  parentId: bigint;
  parentType: number;
  replyToComment?: BareComment;
  onSendComment: () => void;
  stickerModal?: ModalController;
  commentCount?: number;
  hideCount?: boolean;
}) {
  const backend = useBackend();
  const nativePage = useNativePage();
  const i18n = useI18n();
  const globalSpinner = useGlobalSpinner();
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const reactionRecord = useReactionRecord();

  const [isInputting, setIsInputting] = useState<boolean>();

  const [enableReply, setEnableReply] = useState(false);

  const mediaPickerRef = useRef<HTMLInputElement>(null);

  const [obscuringZone] = useObscuredZoneForKey("Browser");

  const [selectedFileWithIdList, setFileWithIdList] =
    useState<{ file: File; fileId: string }[]>();

  const handleError = useErrorHandler();
  const [hasContent, setHasContent] = useState<boolean>(false);

  const isSubmittable = useMemo(() => {
    return hasContent || (selectedFileWithIdList?.length || 0) > 0;
  }, [hasContent, selectedFileWithIdList]);

  useEffect(() => {
    const inputHandler = (e: Event) => {
      if (inputRef.current) {
        inputRef.current.style.height = "18px";
        inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
      }
    };

    window.addEventListener("input", inputHandler);
    return () => {
      window.removeEventListener("input", inputHandler);
    };
  }, [inputRef]);

  useEffect(() => {
    if (props.replyToComment) {
      setEnableReply(true);
      inputRef.current?.focus();
    }
  }, [props.replyToComment]);

  async function sendComment() {
    try {
      const replyId = enableReply
        ? props.replyToComment?.replyId
          ? props.replyToComment?.replyId
          : props.replyToComment?.commentId
        : undefined;

      const replyToUid = enableReply
        ? props.replyToComment
          ? props.replyToComment.author.uid
          : undefined
        : undefined;

      try {
        await globalSpinner(async () => {
          const mediaList: Media[] = [];
          if (selectedFileWithIdList) {
            for (let i = 0; i < selectedFileWithIdList.length; i++) {
              const file = selectedFileWithIdList[i].file;
              const media = await backend.sendLocalMedia(
                file,
                getFileType(file.type) === "video"
                  ? "content_video"
                  : "content_image",
                (a, b) => {},
              );
              mediaList.push(media);
            }
          }
          const newComment = {
            parentId: props.parentId,
            parentType: props.parentType,
            content: inputRef.current?.value,
            contentType: PostType.TEXT,
            replyId: replyId,
            extensions: {
              replyToUid: replyToUid,
            },
            mediaList: mediaList,
          };
          await backend.createComment(newComment).run();
          clearAndBlur();
          props.onSendComment();
          await nativePage.successHud(i18n.done());
        });
      } catch (e) {
        handleError(e);
      }
    } catch (e) {
      handleError(e);
    }
  }

  function clearAndBlur() {
    if (inputRef.current) {
      inputRef.current.value = "";
      inputRef.current.style.height = "18px";
      inputRef.current.blur();
      setEnableReply(false);
      setIsInputting(false);
      setFileWithIdList([]);
    }
    if (mediaPickerRef.current) {
      mediaPickerRef.current.value = "";
    }
  }

  function getFileType(type: string) {
    if (type.startsWith("image")) {
      return "image";
    } else if (type.startsWith("video")) {
      return "video";
    } else {
      return undefined;
    }
  }

  function unmetConditionNotice(event: React.ChangeEvent<HTMLInputElement>) {
    event.target.value = "";
    nativePage
      .alertNotice(
        i18n.comment_impr_select_max_count_media_alert(5, 1),
        i18n.ok(),
      )
      .catch(andLog);
  }

  async function onMediaPickedChange(
    event: React.ChangeEvent<HTMLInputElement>,
  ) {
    if (event.target.files && event.target.files.length > 0) {
      if (
        event.target.files.length + (selectedFileWithIdList?.length || 0) >
        5
      ) {
        unmetConditionNotice(event);
        return;
      }

      let imageFlag = false;
      let videoFlag = false;
      const selectedType = getFileType(
        selectedFileWithIdList?.at(0)?.file.type || "",
      );
      if (selectedType === "image") {
        imageFlag = true;
      } else if (selectedType === "video") {
        videoFlag = true;
      }

      const newList: { file: File; fileId: string }[] = [
        ...(selectedFileWithIdList || []),
      ];
      for (let i = 0; i < event.target.files.length; i++) {
        const currentType = getFileType(event.target.files[i].type);
        if (currentType === "image") imageFlag = true;
        if (currentType === "video") videoFlag = true;

        // not allowed mixed type
        if (imageFlag && videoFlag) {
          unmetConditionNotice(event);
          return;
        }

        // one video allowed
        if (currentType === "video" && newList.length > 0) {
          unmetConditionNotice(event);
          return;
        }

        newList.push({ file: event.target.files[i], fileId: genUUID() });
      }

      setFileWithIdList(newList);
      event.target.value = "";
    }
  }

  const firstMedia = props.replyToComment?.mediaList?.at(0);

  const replyToContent = StringUtil.getOneLineCleanText(
    i18n,
    props.replyToComment?.content ||
      (firstMedia
        ? isImage(firstMedia)
          ? i18n.chat_list_image()
          : i18n.chat_list_video()
        : ""),
    200,
  );
  return (
    <InputBarBackground style={{ paddingBottom: obscuringZone.bottom + 18 }}>
      {enableReply && (
        <VStack>
          <HStack style={{ flex: 1, padding: "4px 0" }}>
            <ReplyTo>
              {i18n.post_reply_to(
                props.replyToComment?.author?.nickname || "",
                replyToContent,
              )}
            </ReplyTo>
            <CancelButton
              src={icCancel}
              onClick={() => setEnableReply(false)}
            />
          </HStack>
          <Divider />
        </VStack>
      )}
      <HStack
        style={{
          width: "100%",
          paddingTop: enableReply ? 8 : 12,
          gap: 4,
          alignItems: "end",
        }}
      >
        <InputContainer>
          <MsgInput
            ref={inputRef}
            placeholder={i18n.say_something()}
            onFocus={() => setIsInputting(true)}
            onInput={() => {
              if ((inputRef.current?.value?.length || 0) > 0) {
                setHasContent(true);
              } else {
                setHasContent(false);
              }
            }}
          />
          {isInputting && (
            <OperationIconContainer>
              <OperationIcon
                width={24}
                height={24}
                src={icImage}
                onClick={() => mediaPickerRef.current?.click()}
              />
            </OperationIconContainer>
          )}
        </InputContainer>

        {!isInputting && !props.hideCount && (
          <HStack style={{ gap: 8 }}>
            <VStack onClick={() => props.stickerModal?.open()}>
              <OperationIcon width={24} height={24} src={icAddReaction} />
              <CountText>
                {Intl.NumberFormat().format(
                  reactionRecord.map.get(props.parentId)?.length || 0,
                )}
              </CountText>
            </VStack>
            <VStack onClick={() => inputRef.current?.focus()}>
              <OperationIcon width={24} height={24} src={icComment} />
              <CountText>
                {Intl.NumberFormat().format(props.commentCount || 0)}
              </CountText>
            </VStack>
          </HStack>
        )}

        {isInputting && (
          <SendButton
            src={isSubmittable ? icSendEnable : icSendDisable}
            onClick={() => {
              if (isSubmittable) {
                sendComment().catch(andLog);
              }
            }}
          />
        )}
      </HStack>
      {(selectedFileWithIdList?.length || 0) > 0 && (
        <SelectedMediaContainer>
          {selectedFileWithIdList?.map((fileWithId) => (
            <EditingMediaCell
              file={fileWithId.file}
              fileId={fileWithId.fileId}
              onCancel={(fileId: string) => {
                setFileWithIdList(
                  selectedFileWithIdList.filter(
                    (selected) => selected.fileId !== fileId,
                  ),
                );
              }}
            />
          ))}
        </SelectedMediaContainer>
      )}

      <MediaPicker
        ref={mediaPickerRef}
        type={"file"}
        accept={"image/*"}
        onChange={onMediaPickedChange}
        multiple={true}
      />
    </InputBarBackground>
  );
}

const InputBarBackground = styled.div`
  width: 100%;
  background-color: #ffffff1a;
  align-items: end;
  padding-left: 18px;
  padding-right: 12px;
  ${VStackMixin};


  /* 背景颜色和透明度，增加黑色蒙层效果 */
  background-color: rgba(0, 0, 0, 0.45); /* 加入带透明度的黑色背景 */

  ${BlurBg};
}
`;

const InputContainer = styled.div`
  background-color: #ffffff1a;
  border-radius: 8px;
  ${HStackMixin};
  flex: 1;
  align-items: end;
`;

const OperationIconContainer = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
  margin-bottom: 4px;
  width: 30px;
  height: 30px;
`;

const OperationIcon = styled.img`
  ${alpha_on_pressed};
`;

const SendButton = styled.img`
  width: 30px;
  height: 30px;
  margin-bottom: 4px;
  ${alpha_on_pressed};
`;

const CountText = styled.div`
  font-weight: 400;
  font-size: 10px;
  color: #ffffff99;
  text-align: center;
  margin-top: 2px;
`;

const ReplyTo = styled.div`
  font-weight: 400;
  font-size: 10px;
  color: #ffffff33;
  flex: 1;
  ${OverFlowTextStyle};
  line-clamp: 1;
  -webkit-line-clamp: 1;
`;

const Divider = styled.div`
  height: 0.5px;
  background-color: #ffffff1a;
  width: 100%;
`;

const CancelButton = styled.img`
  width: 24px;
  height: 24px;
  ${alpha_on_pressed};
`;

const MsgInput = styled.textarea`
  background-color: transparent;
  height: 18px;
  flex-grow: 1;
  color: white;
  font-size: 14px;
  font-weight: 400;
  border: none;
  overflow: auto;
  margin: 8px 12px 12px;
  padding: 0;
  max-height: 80px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  resize: none;
`;

const MediaPicker = styled.input`
  display: none;
`;

const SelectedMediaContainer = styled.div`
  margin-top: 18px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  column-gap: 10px;
`;

function EditingMediaCell(props: {
  file: File;
  fileId: string;
  onCancel: (fileId: string) => void;
}) {
  const [src, setSrc] = useState<string>();
  const reader = new FileReader();
  reader.onload = (e) => {
    if (typeof e.target?.result === "string") {
      setSrc(e.target?.result);
    }
  };
  reader.readAsDataURL(props.file);
  return (
    <EditingMediaContainer>
      <AspectRatioImage
        aspectRatio={1}
        src={src}
        imageStyle={{ borderRadius: 6 }}
        style={{ width: "100%" }}
      />
      <CancelImage
        src={icDelete}
        onClick={() => props.onCancel(props.fileId)}
      />
      {props.file.type.startsWith("video") && <VideoImage src={icVideo} />}
    </EditingMediaContainer>
  );
}

const EditingMediaContainer = styled.div`
  position: relative;
  display: flex;
`;

const CancelImage = styled.img`
  position: absolute;
  top: -5px;
  right: -5px;
  width: 20px;
  height: 20px;
`;

const VideoImage = styled.img`
  position: absolute;
  width: 30px;
  height: 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
