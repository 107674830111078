import {
  getBestRes,
  getFittestRes,
  getSmallestRes,
  Media,
  Size,
} from "../proto/Media";
import icFailure from "../res/images/ic_broken_img.svg";
import empty_src from "../res/images/empty_src.svg";
import React, { CSSProperties, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useChange } from "../hooks/ExtHooks";
import { isBackground, isLocalMedia, LocalMedia } from "../bridge/LocalMedia";
import { Background } from "../proto/Background";

export type MediaSizeMode = Size | "best" | "smallest" | number;

const DefaultImg = styled.img<{ $background: string }>`
  background: ${(p) => p.$background};
  object-fit: cover;
`;

function urlFromSrc(
  src:
    | readonly [Media | LocalMedia | Background | undefined, MediaSizeMode]
    | string
    | undefined,
): string {
  if (src === undefined) {
    return empty_src;
  } else if (typeof src === "string") {
    if (src.length === 0) {
      return empty_src;
    } else {
      return src;
    }
  } else {
    const [media, fitMode] = src;
    if (media) {
      if (isLocalMedia(media)) {
        if (media instanceof Blob) {
          return URL.createObjectURL(media);
        } else {
          return media.url;
        }
      } else if (isBackground(media)) {
        if (media.backgroundImage) {
          return urlFromSrc([media.backgroundImage, fitMode]);
        } else {
          return media.backgroundColor ?? empty_src;
        }
      } else {
        if (fitMode === "best") {
          return getBestRes(media).url;
        } else if (fitMode === "smallest") {
          return getSmallestRes(media).url;
        } else if (typeof fitMode === "number") {
          return getFittestRes(media, {
            width: fitMode,
            height: fitMode,
          }).url;
        } else {
          return getFittestRes(media, fitMode).url;
        }
      }
    } else {
      return empty_src;
    }
  }
}

function useSrcUrl(
  src:
    | readonly [Media | LocalMedia | Background | undefined, MediaSizeMode]
    | string
    | undefined,
) {
  const [srcUrl, setSrcUrl] = useState<string>();

  useEffect(() => {
    const url = urlFromSrc(src);
    setSrcUrl(url);
    return () => {
      if (url?.startsWith("blob:")) {
        URL.revokeObjectURL(url);
      }
    };
  }, [src]);

  return srcUrl ?? empty_src;
}

export function Image(props: {
  width?: string | number | undefined;
  height?: string | number | undefined;
  src:
    | readonly [Media | LocalMedia | Background | undefined, MediaSizeMode]
    | string
    | undefined;
  style?: CSSProperties;
  alt?: string;
  styledImg?: typeof DefaultImg;
  needBg?: boolean;
  onClick?: () => void;
  stopPropagation?: boolean;
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const url = useSrcUrl(props.src);

  useChange(url, (prev, current) => {
    if (prev !== current) {
      setIsError(false);
      setIsLoading(true);
    }
  });

  const StyledImg = props.styledImg ?? DefaultImg;

  return (
    <StyledImg
      width={props.width}
      height={props.height}
      $background={
        (!url.startsWith("data") &&
          !url.startsWith("/static") &&
          (isLoading || isError)) ||
        props.needBg
          ? "rgba(255, 255, 255, 0.06)"
          : "transparent"
      }
      src={isError ? icFailure : url}
      style={props.style}
      onError={(e) => {
        if (e.currentTarget.src === icFailure) {
          return;
        }
        setIsLoading(false);
        setIsError(true);
      }}
      onLoad={(e) => {
        if (e.currentTarget.src === icFailure) {
          return;
        }

        setIsLoading(false);
        setIsError(false);
      }}
      alt={props.alt ?? "icon"}
      onClick={(event) => {
        if (props.stopPropagation) {
          event.stopPropagation();
        }
        if (props.onClick) {
          props.onClick();
        }
      }}
    />
  );
}

const AspectRatioContainer = styled.div<{
  $aspectRatio: number;
}>`
  position: relative;
  padding-bottom: ${(p) => (1 / p.$aspectRatio) * 100}%;
`;

const ImageContent = styled.img<{ $background: string }>`
  position: absolute;
  background: ${(p) => p.$background};
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export function AspectRatioImage(props: {
  width?: number | undefined;
  aspectRatio: number;
  src: readonly [Media | undefined, MediaSizeMode] | string | undefined;
  imageStyle?: CSSProperties;
  style?: CSSProperties;
  needBg?: boolean;
  onClick?: () => void;
  stopPropagation?: boolean;
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const url = useMemo(() => {
    if (typeof props.src === "string") {
      if (props.src.length === 0) {
        return empty_src;
      } else {
        return props.src;
      }
    } else if (props.src) {
      const [media, fitMode] = props.src;
      if (media) {
        if (fitMode === "best") {
          return getBestRes(media).url;
        } else if (fitMode === "smallest") {
          return getSmallestRes(media).url;
        } else if (typeof fitMode === "number") {
          return getFittestRes(media, {
            width: fitMode,
            height: fitMode,
          }).url;
        } else {
          return getFittestRes(media, fitMode).url;
        }
      } else {
        return empty_src;
      }
    } else {
      return empty_src;
    }
  }, [props.src]);

  useChange(url, (prev, current) => {
    if (prev !== current) {
      setIsError(false);
      setIsLoading(true);
    }
  });

  return (
    <AspectRatioContainer $aspectRatio={props.aspectRatio} style={props.style}>
      <ImageContent
        $background={
          (!url.startsWith("data") &&
            !url.startsWith("/static") &&
            (isLoading || isError)) ||
          props.needBg
            ? "rgba(255, 255, 255, 0.06)"
            : "transparent"
        }
        src={isError ? icFailure : url}
        style={props.imageStyle}
        onError={(e) => {
          if (e.currentTarget.src === icFailure) {
            return;
          }
          setIsLoading(false);
          setIsError(true);
        }}
        onLoad={(e) => {
          if (e.currentTarget.src === icFailure) {
            return;
          }
          setIsLoading(false);
          setIsError(false);
        }}
        alt={"aspect-ratio-image"}
        onClick={(event) => {
          if (props.stopPropagation) {
            event.stopPropagation();
          }
          if (props.onClick) {
            props.onClick();
          }
        }}
      />
    </AspectRatioContainer>
  );
}
