import { Page } from "../../../components/Page";
import { NavMiddle } from "../../../components/NavBar";
import { useI18n } from "../../../hooks/useI18n";
import { useBackend } from "../../../service/APIService";
import {
  useBigIntParam,
  useStringSearchParam,
} from "../../../hooks/useTypedParam";
import { useSWR } from "../../../hooks/swr/useSWR";
import CurrencyCell from "./CurrencyCell";
import styled from "styled-components";
import { Image } from "../../../components/Image";
import iconSOL from "../../../res/images/icon_sol.svg";
import { HStack, VStack } from "../../../components/VStack";
import arrowDown from "../../../res/images/icon_arrow_down.svg";
import { useHopper } from "../../../hooks/useHopper";
import { useMemo } from "react";
import BigNumber from "bignumber.js";
import { CurrencyType } from "../../../proto/Currency";
import { DefaultEmptyView } from "../../../components/LoadStateView";
import { getRandomId } from "../../../utils/randomId";

function SelectTokenPage() {
  const i18n = useI18n();
  const backend = useBackend();
  const walletAccountId = useBigIntParam("walletAccountId");
  const currencyListSWR = useSWR(backend.getCurrencyList(walletAccountId));
  const hopper = useHopper();

  const purpose = useStringSearchParam("purpose");
  const assetAccountsSWR = useSWR(backend.getAssetAccounts(walletAccountId));
  const assetAccountId = useMemo(() => {
    const validAssetAccount = assetAccountsSWR.content?.list?.find(
      (account) => account.address.length > 0,
    );
    return validAssetAccount?.accountId ?? BigInt(0);
  }, [assetAccountsSWR.content]);

  const displayCurrencyList = useMemo(() => {
    return currencyListSWR.content?.list?.filter((currencyHolder) => {
      if (
        purpose === "SwapToken" &&
        (!currencyHolder.currency.supportSwap ||
          currencyHolder.currency.currencyType === CurrencyType.SOL)
      ) {
        return false;
      }

      if([CurrencyType.COIN, CurrencyType.DIAMOND].includes(currencyHolder.currency.currencyType)) {
        return false;
      }

      return (
        currencyHolder.accountCurrencyList?.length > 0 &&
        BigNumber(
          currencyHolder.accountCurrencyList?.at(0)?.balance?.amount ?? "0",
        ) > BigNumber("0") &&
        currencyHolder.currency.currencyType !== CurrencyType.GEMS
      );
    });
  }, [currencyListSWR.content]);

  const isNotEmpty = displayCurrencyList && displayCurrencyList.length > 0;

  return (
    <Page pageData={[currencyListSWR, assetAccountsSWR]}>
      <NavMiddle>
        {purpose === "SendToken"
          ? i18n.clover_select_token_to_send()
          : i18n.clover_select_token_to_swap()}
      </NavMiddle>
      <HStack style={{ justifyContent: "center" }}>
        <ChainPickerBg>
          <Image src={iconSOL} width={16} height={16} />
          <ChainName>{i18n.clover_solana_chain()}</ChainName>
        </ChainPickerBg>
      </HStack>
      {isNotEmpty &&
        displayCurrencyList?.map((currencyHolder) => {
          return (
            <CurrencyCell
              key={currencyHolder.currency.currencyId}
              currency={
                currencyHolder.accountCurrencyList?.at(0)?.balance ??
                currencyHolder.currency
              }
              onClick={() => {
                hopper.push(
                  `wallet/${walletAccountId}/assetAccount/${assetAccountId}/${purpose === "SendToken" ? "send-token" : "swap"}`,
                  {
                    currencyId: currencyHolder.currency.currencyId,
                  },
                );
              }}
            />
          );
        })}
      {!isNotEmpty && (
        <DefaultEmptyView
          topSpace={98}
          title={
            purpose === "SwapToken"
              ? i18n.clover_no_available_tokens_to_swap()
              : i18n.clover_no_available_tokens_to_send()
          }
        />
      )}
    </Page>
  );
}

const ChainName = styled.div`
  color: var(--color-text00);
  font-size: 12px;
  font-weight: 400;
`;

const ChainPickerBg = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  padding: 8px;
  gap: 4px;
  margin-bottom: 10px;
  justify-content: center;
  border-radius: 8px;
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export default SelectTokenPage;
