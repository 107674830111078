import { dbDelete, dbGet, dbPut, openDB } from "./dbPromise";
import { JSONUtil } from "./JSONUtil";
import { z, ZodError } from "zod";
import { zStatic } from "./zodUtils";
import { RepoStore } from "../hooks/swr/RepoStore";
import { coerceBigInt } from "./pick";

export enum Store {
  SWR = "swr",
  Session = "session",
}

let dbPromise: Promise<IDBDatabase> | null = null;

export const DB = {
  get(): Promise<IDBDatabase> {
    if (dbPromise) {
      return dbPromise;
    }

    const promise = openDB("TheDB", 1, (db, oldVersion, newVersion) => {
      console.log("upgrade", { o: oldVersion, n: newVersion });
      if (!db.objectStoreNames.contains(Store.SWR)) {
        db.createObjectStore(Store.SWR, { keyPath: ["contentId", "userId"] });
        db.createObjectStore(Store.Session, { keyPath: ["uidStr"] });
      }
    });

    dbPromise = promise;
    return promise;
  },
};

const SWREntry = z.object({
  content: z.string(),
  contentId: z.string(),
  userId: z.string(),
  updatedAt: z.number(),
});

export type SWRGetResult<T> = {
  content: T;
  updatedAt: number;
};

export class IDBStore<T> implements RepoStore<T> {
  constructor(
    readonly storeName: string,
    readonly contentId: string,
    readonly userId: bigint,
  ) {}

  async put(content: T) {
    const entry: zStatic<typeof SWREntry> = {
      content: JSONUtil.stringify(content),
      contentId: this.contentId,
      userId: this.userId.toString(),
      updatedAt: new Date().getTime(),
    };
    const db = await DB.get();
    await dbPut(db, Store.SWR, entry);
  }

  async delete() {
    const db = await DB.get();
    await dbDelete(db, this.storeName, [
      this.contentId,
      this.userId.toString(),
    ]);
  }

  async get(): Promise<SWRGetResult<T> | undefined> {
    const db = await DB.get();
    try {
      const entry = SWREntry.optional().parse(
        await dbGet(db, this.storeName, [
          this.contentId,
          this.userId.toString(),
        ]),
      );
      if (entry) {
        const content = JSONUtil.parseUnknown(entry.content);
        coerceBigInt(content);
        return {
          content: content as T,
          updatedAt: entry.updatedAt,
        };
      } else {
        return undefined;
      }
    } catch (e) {
      if (e instanceof ZodError) {
        await this.delete();
      }
      console.error("Failed to read", e);
      return undefined;
    }
  }
}
