import React from "react";
import CircleGridPage, { CircleGridType } from "./CircleGridPage";
import { useI18n } from "../../hooks/useI18n";
import {
  ViewPagerPage,
  ViewPagerTabLayout,
} from "../../components/ViewPagerPage";
import { subpages } from "../../components/Subpage";
import { NavMiddle } from "../../components/NavBar";

function HomeMyCirclesPage() {
  const i18n = useI18n();

  const entries: [CircleGridType, string][] = [
    [CircleGridType.All, i18n.all()],
    [CircleGridType.Managed, i18n.managed()],
  ];

  return (
    <ViewPagerPage
      subpages={subpages(entries, (e) => (
        <CircleGridPage circleGridType={e} />
      ))}
    >
      <NavMiddle>{i18n.my_circles()}</NavMiddle>
      <ViewPagerTabLayout />
    </ViewPagerPage>
  );
}

export default HomeMyCirclesPage;
