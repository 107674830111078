import styled from "styled-components";
import { ChasingCircle } from "./vscroll/ChasingCircle";
import { SingleLineUnspecifiedWidth } from "./CommonViews";
import {
  ModalContainer,
  ModalController,
  ModalDimmer,
  requireModalController,
} from "./Modal";
import React, { PropsWithChildren } from "react";
import { ModalContext } from "./ModalContext";
import { VHudBg } from "./ModalHud";

const Title = styled.div`
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: var(--color-text00);
  ${SingleLineUnspecifiedWidth}
`;

export function ModalSpinner(
  props: PropsWithChildren<{
    title?: string;
    modal: ModalController;
  }>,
) {
  requireModalController(props.modal);

  return props.modal.mounted ? (
    <ModalContainer id={props.modal.id} modal={props.modal}>
      <ModalContext.Provider value={{ modal: props.modal }}>
        <ModalDimmer
          $animation={props.modal.animation}
          $backgroundColor={"rgba(0, 0, 0, 0.4)"}
        />
        <VHudBg
          onClick={(e) => {
            e.stopPropagation();
          }}
          $animation={props.modal.animation}
          onAnimationEnd={props.modal.onAnimationEnd}
        >
          <ChasingCircle
            radius={12}
            strokeWidth={2}
            color={"var(--color-text00)"}
          />
          {props.title ? <Title>{props.title}</Title> : undefined}
        </VHudBg>
      </ModalContext.Provider>
    </ModalContainer>
  ) : (
    <></>
  );
}
