import { Page } from "../../components/Page";
import { useMyUid } from "../../service/AuthSessionService";
import { useSWR } from "../../hooks/swr/useSWR";
import { useBackend } from "../../service/APIService";
import { NavEnd, NavItem, NavMiddle, NavStart } from "../../components/NavBar";
import { NavButtonType } from "../../bridge/NavButtonDesc";
import { RegularInputGroup } from "../../components/Input";
import React, { useState } from "react";
import { useI18n } from "../../hooks/useI18n";
import { useConsumerId, useHopper } from "../../hooks/useHopper";
import { andLog, useErrorHandler } from "../../components/handleError";
import { User } from "../../proto/User";
import { useNativePage } from "../../hooks/useBridge";
import { useStringSearchParam } from "../../hooks/useTypedParam";
import { useUserEditResult } from "./EditMyPublicProfilePage";
import { useGlobalSpinner } from "../../utils/globalSpinner";

function EditNicknamePage() {
  const uid = useMyUid();
  const backend = useBackend();
  const i18n = useI18n();
  const hopper = useHopper();
  const nativePage = useNativePage();
  const name = useStringSearchParam("name");
  const [nickname, setNickname] = useState(name);
  const requestId = useConsumerId();
  const userEdited = useUserEditResult(requestId, uid);
  const handleError = useErrorHandler();
  const globalSpinner = useGlobalSpinner();

  async function onCheckClick() {
    globalSpinner(async () => {
      const user: User = {
        uid,
        nickname,
      };
      await backend.updateProfile(user).run();
      await userEdited.fill((prev) => {
        return {
          uid,
          nickname,
        };
      });
      hopper.back();
    }).catch(andLog);
  }

  const backWithCheck = async () => {
    if (name === nickname) {
      hopper.back();
      return;
    }
    try {
      const quit = await nativePage.alertAreYouSure(
        i18n.compose_confirmation(),
        i18n.quit(),
        i18n.oct12_text_continue_editing(),
      );
      if (quit) {
        hopper.back();
      }
    } catch (e) {
      handleError(e);
    }
  };
  const userSWR = useSWR(backend.getUser(uid));
  return (
    <Page pageData={userSWR}>
      <NavStart>{NavItem.image(NavButtonType.Cross, backWithCheck)}</NavStart>
      <NavMiddle>{i18n.user_edit_social_id()}</NavMiddle>
      <NavEnd>{NavItem.image(NavButtonType.Check, onCheckClick)}</NavEnd>
      <RegularInputGroup
        value={nickname}
        updateValue={setNickname}
        maxLength={25}
      />
    </Page>
  );
}

export default EditNicknamePage;
