import { HStack, Spring, VStack } from "../../../components/VStack";
import { Image } from "../../../components/Image";

import styled from "styled-components";
import { AssetOrder, AssetOrderStatus } from "../../../proto/AssetOrder";
import { CellDivider, ContentGroup } from "../../../components/ContentGroup";
import { OrderInfoContent, OrderInfoLabel } from "./GiftOrderDetail";
import { useI18n } from "../../../hooks/useI18n";
import { ImageContainer, StatusBar } from "./GiftOrderListPage";
import { formatMoney, getCurrencyIcon } from "../../../proto/Currency";
import CircleCardsPreviewCell, {
  CardStyle,
} from "../../circle/card/CircleCardsPreviewCell";
import React from "react";

export function StakeTokenDetail(props: { assetOrder: AssetOrder }) {
  const i18n = useI18n();

  function getStatus() {
    switch (props.assetOrder.orderStatus) {
      case AssetOrderStatus.Done:
        return { text: i18n.web3_v0_completed(), color: "#00FF94" };
      case AssetOrderStatus.Pending:
      case AssetOrderStatus.Submitted: {
        return { text: i18n.web3_v0_pending(), color: "#34A6FFFF" };
      }
      case AssetOrderStatus.Failed: {
        return { text: i18n.circle_anmt_failed(), color: "#EF3537FF" };
      }
      case AssetOrderStatus.Rejected: {
        return { text: i18n.circle_anmt_rejected(), color: "#EF3537FF" };
      }
      default:
        return { text: "", color: "#FFFFFF66" };
    }
  }

  const sentCurrency = props.assetOrder.totalDeductionList?.at(0);

  const serviceFee = props.assetOrder.gasFee;
  const nft = props.assetOrder?.nft;
  return (
    <VStack style={{ width: "100%", gap: 20 }}>
      <HStack
        style={{
          width: "100%",
          padding: 8,
          backgroundColor: "#FFFFFF0F",
          borderRadius: 6,
          gap: 12,
        }}
      >
        <ImageContainer>
          <CircleCardsPreviewCell
            nft={nft}
            cardStyle={CardStyle.small}
            width={64}
          />
        </ImageContainer>
        <VStack style={{ width: "100%", gap: 8 }}>
          <CardName>{nft?.name}</CardName>
          <CountText>
            X {props.assetOrder.purchaseMembershipCardInfo?.count}
          </CountText>
          <ActionName>
            {i18n.clover_stake_tokens_to_get_membership_card()}
          </ActionName>
        </VStack>
      </HStack>
      <ContentGroup style={{ width: "100%", gap: 20 }}>
        <HStack>
          <OrderInfoLabel>{i18n.status()}</OrderInfoLabel>
          <Spring />
          <StatusBar
            statusColor={getStatus().color}
            statusText={getStatus().text}
          />
        </HStack>
        <CellDivider />
        <HStack>
          <OrderInfoLabel>{i18n.clover_token_staked()}</OrderInfoLabel>
          <Spring />
          <HStack style={{ gap: 4 }}>
            <Image
              src={[sentCurrency?.icon, { width: 18, height: 18 }]}
              style={{ width: 18, height: 18, borderRadius: 2 }}
            />
            <OrderInfoContent>
              {formatMoney("long", sentCurrency)}
            </OrderInfoContent>
          </HStack>
        </HStack>
        <CellDivider />
        <HStack>
          <OrderInfoLabel>{i18n.web3_v0_service_fee()}</OrderInfoLabel>
          <Spring />
          <HStack style={{ gap: 4 }}>
            <Image
              src={getCurrencyIcon(serviceFee)}
              style={{ width: 18, height: 18 }}
            />
            <OrderInfoContent>
              {formatMoney("long", serviceFee)}
            </OrderInfoContent>
          </HStack>
        </HStack>
        <CellDivider />
        <HStack>
          <OrderInfoLabel>{i18n.web3_v0_transaction_order_id()}</OrderInfoLabel>
          <Spring />
          <OrderInfoContent>
            {props.assetOrder.orderId.toString()}
          </OrderInfoContent>
        </HStack>
      </ContentGroup>
    </VStack>
  );
}

const CardName = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: white;
`;

const CountText = styled.div`
  font-weight: 400;
  font-size: 11px;
  color: white;
`;

const ActionName = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #ffffff66;
`;
