import { Page } from "../../../components/Page";
import { useSWR } from "../../../hooks/swr/useSWR";
import { useBackend } from "../../../service/APIService";
import {
  useBigIntParam,
  useEnumSearchParam,
  useStringSearchParam,
} from "../../../hooks/useTypedParam";
import Switch from "../../settings/components/Switch";
import styled, { css } from "styled-components";
import { Divider } from "../../post/PostCell";
import { NavMiddle } from "../../../components/NavBar";
import { HStack, Spring, VStack } from "../../../components/VStack";
import { useI18n } from "../../../hooks/useI18n";
import { useModal } from "../../../components/Modal";
import { alpha_on_pressed } from "../../../components/CommonStyles";
import { Image } from "../../../components/Image";
import { useHopper } from "../../../hooks/useHopper";
import { CSSProperties, useEffect, useMemo, useRef } from "react";
import {
  CircleFolder,
  ContentPermission,
  FolderPermission,
  FolderType,
  PermissionType,
} from "../../../proto/CircleFolder";
import {
  CircleMembershipLevelIcon,
  CircleMembershipLevelPickerType,
} from "../membership/MembershipLevelPicker";
import IcEdit from "../../../res/images/ic_edit.svg";
import { User } from "../../../proto/User";
import { CircleMemberTitle, TitleType } from "../../../proto/CircleMemberTitle";
import IcAdmin from "./imgs/ic_admin_user.svg";
import { UserIconView } from "../../../components/views/UserAvatarView";
import IcAdd from "../../../res/images/ic_token_add.svg";
import IcAdd2 from "../../../res/images/ic_add.svg";
import { BSMenu, BSMenuItem } from "../../../components/BSMenu";
import { DropdownButton } from "../../../components/DropdownButton";
import { useConsumeResult } from "../../../hooks/useResult";
import { useHopId, useHopState } from "../../../hooks/useHopState";
import { useMyUid } from "../../../service/AuthSessionService";
import { useMemoryRepo } from "../../../hooks/swr/useLocalRepo";
import { andLog, useErrorHandler } from "../../../components/handleError";
import { useGlobalSpinner } from "../../../utils/globalSpinner";
import { UserName } from "../../../components/views/UserNameView";
import { useNativePage } from "../../../hooks/useBridge";
import { produce } from "immer";

export const ContentTitle = styled.div`
  font-size: 18px;
  font-weight: 400;
`;

export const ContentSubTitle = styled.div`
  font-size: 12px;
  font-weight: 400;
  opacity: 0.4;
`;

const FontSize12Label = styled.div`
  font-size: 12px;
`;

const LevelCell = css`
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  height: 48px;
  padding: 0 12px;
  background-color: rgba(255, 255, 255, 0.05);
`;

const LevelAddCell = css`
  border-radius: 4px;
  border: 1px dashed rgba(255, 255, 255, 0.25);
  height: 48px;
  padding: 0 12px;
  background-color: rgba(255, 255, 255, 0.05);
`;

const UsersView = styled.div`
  display: flex;
  flex-wrap: wrap;

  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  padding: 4px;

  align-items: center;
  background-color: rgba(255, 255, 255, 0.05);
`;

const AdminTitle = css`
  font-size: 10px;
  font-weight: 500;
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 4px;
  padding: 5px;
  gap: 2px;
  display: flex;
`;

const NormalTitle = styled.div`
  padding: 3px 5px;
  color: rgb(17, 17, 17);
  font-size: 10px;
  font-weight: 500;
  border-radius: 4px;
  min-height: 18px;
  display: flex;
  align-items: center;
  ${alpha_on_pressed};
`;

const AddStyle = css`
  align-items: center;
  color: rgba(255, 255, 255, 0.4);
  font-size: 10px;
  gap: 4px;
  margin: 8px;
`;

function EditCircleMembershipLevelCell(props: {
  level?: number;
  onClickEdit: () => void;
}) {
  const i18n = useI18n();
  return (
    <HStack
      mixin={[
        props.level !== undefined ? LevelCell : LevelAddCell,
        alpha_on_pressed,
      ]}
      style={{
        gap: 8,
        fontSize: 12,
        justifyContent: props.level !== undefined ? "start" : "center",
        zIndex: 10,
      }}
      onClick={(e) => {
        e.stopPropagation();
        props.onClickEdit();
      }}
    >
      {props.level !== undefined ? (
        <>
          <CircleMembershipLevelIcon
            level={props.level}
            width={32}
          ></CircleMembershipLevelIcon>
          <FontSize12Label>
            {i18n.clover_membership_level_value(`${props.level}`)}
          </FontSize12Label>
          <Spring />
          <Image src={IcEdit} style={{ width: 18, height: 18 }} />
        </>
      ) : (
        <>
          <Image src={IcAdd2} />
          <div style={{ opacity: 0.4 }}>
            {i18n.clover_select_min_membership_level()}
          </div>
        </>
      )}
    </HStack>
  );
}

function MemberTitleCell(props: {
  title: CircleMemberTitle;
  onClick?: () => void;
  style?: CSSProperties;
}) {
  return (
    <NormalTitle
      style={{ margin: 8, backgroundColor: props.title.color, ...props.style }}
      onClick={props.onClick}
    >
      {props.title.title}
    </NormalTitle>
  );
}

// overflow: hidden;
// text-overflow: ellipsis;
//
// word-wrap: break-word;
// display: -webkit-box;
// line-clamp: 2;
// -webkit-line-clamp: 2;
// -webkit-box-orient: vertical;

function UserStackCell(props: {
  user: User;
  onClick: () => void;
  style?: CSSProperties;
}) {
  return (
    <VStack
      style={{
        margin: 8,
        overflow: "hidden",
        alignItems: "center",
        maxWidth: 66,
        gap: 4,
        ...props.style,
      }}
      mixin={alpha_on_pressed}
      onClick={props.onClick}
    >
      <UserIconView user={props.user} iconSize={40} />
      <UserName style={{ fontSize: 10, fontWeight: 400 }}>
        {props.user.nickname}
      </UserName>
    </VStack>
  );
}

function EditCircleUsersCell(props: {
  titles: CircleMemberTitle[] | undefined;
  users: User[] | undefined;
  onClickAdd: () => void;
  onClickTitle: (title: CircleMemberTitle) => void;
  onClickUser: (user: User) => void;
}) {
  const i18n = useI18n();

  return (
    <UsersView>
      <HStack
        mixin={[AdminTitle, alpha_on_pressed]}
        style={{ flexShrink: 0, zIndex: 10, margin: 8 }}
        onClick={() =>
          props.onClickTitle({
            type: TitleType.admin,
          } as CircleMemberTitle)
        }
      >
        <Image src={IcAdmin} width={12} />
        <div>{i18n.admin()}</div>
      </HStack>
      <MemberTitleCell
        title={
          { title: i18n.co_admin(), color: "#00CFA5" } as CircleMemberTitle
        }
        style={{ zIndex: 10 }}
        onClick={() =>
          props.onClickTitle({
            type: TitleType.coAdmin,
          } as CircleMemberTitle)
        }
      />
      {props.titles &&
        props.titles.map((title) => (
          <MemberTitleCell
            title={title}
            onClick={() => props.onClickTitle(title)}
            style={{ zIndex: 10 }}
          />
        ))}
      {props.users &&
        props.users.map((user) => (
          <UserStackCell
            user={user}
            key={user.uid}
            onClick={() => props.onClickUser(user)}
            style={{ zIndex: 10 }}
          />
        ))}
      <VStack
        mixin={[AddStyle, alpha_on_pressed]}
        style={{ zIndex: 10 }}
        onClick={(e) => {
          e.stopPropagation();
          props.onClickAdd();
        }}
      >
        <Image src={IcAdd} style={{ width: 40, height: 40 }} />
        <div>Add</div>
      </VStack>
    </UsersView>
  );
}

export default function CircleFolderPermissionPage() {
  const circleId = useBigIntParam("circleId");
  const folderId = useBigIntParam("folderId");
  const draftKey = useStringSearchParam("key");
  const type = useEnumSearchParam("type");
  const backend = useBackend();
  const nativePage = useNativePage();
  const globalSpinner = useGlobalSpinner();
  const i18n = useI18n();
  const hopper = useHopper();
  const handleError = useErrorHandler();
  const folderInfoSWR = useSWR(
    folderId
      ? backend.getCircleFolder(circleId, folderId, "folder-manage")
      : undefined,
  );
  const circleInfo = useSWR(backend.getCircle(circleId));

  const draftPermission = useMemoryRepo<CircleFolder>(
    draftKey ?? "folder-permission",
    {},
  );

  const shouldShowSubmitPermission = useMemo(() => {
    if (type !== undefined) {
      return type === FolderType.Blog;
    }
    if (folderInfoSWR?.content) {
      return folderInfoSWR.content.folderType === FolderType.Blog;
    }
  }, [folderId, folderInfoSWR, type]);

  const permissionHidden = useMemo(() => {
    if (folderId > 0 && folderInfoSWR?.content) {
      return folderInfoSWR.content.permission === FolderPermission.Hidden;
    }
    return draftPermission.content.permission === FolderPermission.Hidden;
  }, [folderId, folderInfoSWR, draftPermission.content]);

  const viewPermission = useMemo(() => {
    if (folderId > 0 && folderInfoSWR?.content) {
      return folderInfoSWR.content.contentViewPermission;
    }
    return draftPermission.content.contentViewPermission;
  }, [folderId, folderInfoSWR, draftPermission.content]);

  const viewPermissionTitles = useMemo(() => {
    if (folderId > 0 && folderInfoSWR?.content) {
      return folderInfoSWR.content.allowedViewMemberTitleList;
    }
    return draftPermission.content.allowedViewMemberTitleList;
  }, [folderId, folderInfoSWR, draftPermission.content]);

  const viewPermissionUsers = useMemo(() => {
    if (folderId > 0 && folderInfoSWR?.content) {
      return folderInfoSWR.content.allowedViewUserList;
    }
    return draftPermission.content.allowedViewUserList;
  }, [folderId, folderInfoSWR, draftPermission.content]);

  const submitPermission = useMemo(() => {
    if (folderId > 0 && folderInfoSWR?.content) {
      return folderInfoSWR.content.contentSubmitPermission;
    }
    return draftPermission.content.contentSubmitPermission;
  }, [folderId, folderInfoSWR, draftPermission.content]);

  const submitPermissionTitles = useMemo(() => {
    if (folderId > 0 && folderInfoSWR?.content) {
      return folderInfoSWR.content.allowedSubmitMemberTitleList;
    }
    return draftPermission.content.allowedSubmitMemberTitleList;
  }, [folderId, folderInfoSWR, draftPermission.content]);

  const submitPermissionUsers = useMemo(() => {
    if (folderId > 0 && folderInfoSWR?.content) {
      return folderInfoSWR.content.allowedSubmitUserList;
    }
    return draftPermission.content.allowedSubmitUserList;
  }, [folderId, folderInfoSWR, draftPermission.content]);

  const permissionModal = useModal("folder-permission-view");
  const addUserTypeModal = useModal("folder-add-user-type-view");
  const removeUserModal = useModal("folder-remove-user-view");
  const editSubmitLevelModal = useModal("folder-edit-submit-level");

  const [editingType, setEditingType] =
    useHopState<CircleMembershipLevelPickerType>(
      CircleMembershipLevelPickerType.ViewLimit,
    );

  const removingUserOrTitleRef = useRef<bigint | undefined>();

  const hopId = useHopId();
  const myUid = useMyUid();

  const levelPicker = useConsumeResult<number>("CircleMembershipLevelPicker", [
    "folder-permission",
    "membership-level",
    hopId,
    myUid,
  ]);

  const editingFolder = useMemo(() => {
    if (folderId > 0) {
      return folderInfoSWR?.content;
    } else {
      return draftPermission.content;
    }
  }, [folderId, folderInfoSWR, draftPermission]);

  const editingPermission = useMemo(() => {
    if (editingType === CircleMembershipLevelPickerType.ViewLimit) {
      return editingFolder?.contentViewPermission;
    } else {
      return editingFolder?.contentSubmitPermission;
    }
  }, [editingFolder, editingType]);

  async function updateFolderPermission(folder: CircleFolder) {
    try {
      if (folderId > 0) {
        await globalSpinner(async () => {
          const serverSideFolder = await backend
            .updateCircleFolder(circleId, folderId, folder)
            .run();
          await folderInfoSWR?.fill(serverSideFolder);
        });
      } else {
        draftPermission.fill(folder).catch(andLog);
      }
    } catch (e) {
      handleError(e);
    }
  }

  function updateFolderPermissionLevel(level: number | undefined) {
    if (!editingFolder) return;
    const updatedPermission: ContentPermission = {
      permissionType: PermissionType.Restricted,
      allowedMembershipLevels: level ? [level] : [],
      allowedUids: editingPermission?.allowedUids ?? [],
      allowedMemberTitleIds: editingPermission?.allowedMemberTitleIds ?? [],
    };
    let updatedFolder: CircleFolder;
    if (editingType === CircleMembershipLevelPickerType.ViewLimit) {
      updatedFolder = produce(editingFolder, (draft) => {
        draft.contentViewPermission = updatedPermission;
      });
    } else {
      updatedFolder = {
        ...editingFolder,
        contentSubmitPermission: updatedPermission,
      };
    }
    updateFolderPermission(updatedFolder).catch(andLog);
    levelPicker.clear().catch(andLog);
  }

  useEffect(() => {
    if (levelPicker.result !== undefined) {
      updateFolderPermissionLevel(levelPicker.result);
    }
  }, [editingFolder, levelPicker.result]);

  const userPicker = useConsumeResult<User>("CircleMemberPicker", [
    "folder-permission",
    "user",
    hopId,
    myUid,
  ]);

  function updateFolderPermissionUser(user: User) {
    if (!editingFolder) return;
    if (
      editingPermission?.allowedUids.find((uid) => uid === user.uid) !==
      undefined
    ) {
      return;
    }

    const editingUsers =
      (editingType === CircleMembershipLevelPickerType.ViewLimit
        ? editingFolder.allowedViewUserList
        : editingFolder.allowedSubmitUserList) ?? [];
    const updatedPermission: ContentPermission = {
      permissionType: PermissionType.Restricted,
      allowedMembershipLevels: editingPermission?.allowedMembershipLevels ?? [],
      allowedUids: (editingPermission?.allowedUids ?? []).concat([user.uid]),
      allowedMemberTitleIds: editingPermission?.allowedMemberTitleIds ?? [],
    };
    const updatedAllowedUsers = editingUsers.concat([user]);
    let updatedFolder: CircleFolder;
    if (editingType === CircleMembershipLevelPickerType.ViewLimit) {
      updatedFolder = produce(editingFolder, (draft) => {
        draft.contentViewPermission = updatedPermission;
        draft.allowedViewUserList = updatedAllowedUsers;
      });
    } else {
      updatedFolder = produce(editingFolder, (draft) => {
        draft.contentSubmitPermission = updatedPermission;
        draft.allowedSubmitUserList = updatedAllowedUsers;
      });
    }
    updateFolderPermission(updatedFolder).catch(andLog);
    userPicker.clear().catch(andLog);
  }

  useEffect(() => {
    if (userPicker.result === undefined) return;
    const pickedUser = userPicker.result as User;
    updateFolderPermissionUser(pickedUser);
  }, [editingFolder, userPicker.result]);

  const titlePicker = useConsumeResult<CircleMemberTitle>("CircleTitlePicker", [
    "folder-permission",
    "title",
    hopId,
    myUid,
  ]);

  function updateFolderPermissionTitle(title: CircleMemberTitle) {
    if (!editingFolder) return;

    if (
      editingPermission?.allowedMemberTitleIds.find(
        (tid) => tid === title.titleId,
      ) !== undefined
    ) {
      return;
    }

    const editingTitles =
      (editingType === CircleMembershipLevelPickerType.ViewLimit
        ? editingFolder.allowedViewMemberTitleList
        : editingFolder.allowedSubmitMemberTitleList) ?? [];
    const updatedPermission: ContentPermission = {
      permissionType: PermissionType.Restricted,
      allowedMembershipLevels: editingPermission?.allowedMembershipLevels ?? [],
      allowedUids: editingPermission?.allowedUids ?? [],
      allowedMemberTitleIds: (
        editingPermission?.allowedMemberTitleIds ?? []
      ).concat([title.titleId]),
    };
    const updatedAllowedTitles = editingTitles.concat([title]);
    let updatedFolder: CircleFolder;
    if (editingType === CircleMembershipLevelPickerType.ViewLimit) {
      updatedFolder = produce(editingFolder, (draft) => {
        draft.contentViewPermission = updatedPermission;
        draft.allowedViewMemberTitleList = updatedAllowedTitles;
      });
    } else {
      updatedFolder = produce(editingFolder, (draft) => {
        draft.contentSubmitPermission = updatedPermission;
        draft.allowedSubmitMemberTitleList = updatedAllowedTitles;
      });
    }
    updateFolderPermission(updatedFolder).catch(andLog);
    titlePicker.clear().catch(andLog);
  }

  useEffect(() => {
    if (titlePicker.result === undefined) {
      return;
    }
    const pickedTitle = titlePicker.result;
    updateFolderPermissionTitle(pickedTitle);
  }, [editingFolder, titlePicker]);

  const onClickDefaultPermission = () => {
    if (!editingFolder) return;
    if (editingPermission?.permissionType === PermissionType.Open) {
      return;
    }
    const updatedPermission: ContentPermission = {
      permissionType: PermissionType.Open,
      allowedMembershipLevels: [],
      allowedUids: [],
      allowedMemberTitleIds: [],
    };
    let updatedFolder: CircleFolder;
    if (editingType === CircleMembershipLevelPickerType.ViewLimit) {
      updatedFolder = produce(editingFolder, (draft) => {
        draft.contentViewPermission = updatedPermission;
        draft.allowedViewUserList = [];
        draft.allowedViewMemberTitleList = [];
      });
    } else {
      updatedFolder = produce(editingFolder, (draft) => {
        draft.contentSubmitPermission = updatedPermission;
        draft.allowedSubmitUserList = [];
        draft.allowedSubmitMemberTitleList = [];
      });
    }
    updateFolderPermission(updatedFolder).catch(andLog);
  };

  const onClickLimitPermission = async () => {
    if (!editingFolder) return;
    if (editingPermission?.permissionType === PermissionType.Restricted) {
      return;
    }
    if (editingType === CircleMembershipLevelPickerType.ViewLimit) {
      if (circleInfo.content?.tokenProjectId === undefined) {
        const result = await nativePage.alertYesOrCancel(
          i18n.clover_folder_permission_need_token_tip(),
          i18n.clover_go_to_admin_portal(),
          i18n.cancel(),
        );
        if (result) {
          hopper.push(`circle/${circleId}/admin-portal`);
        }
        return;
      }

      if (folderId > 0) {
        const result = await nativePage.alertAreYouSure(
          i18n.clover_folder_update_permission_tip(),
          i18n.yes(),
          i18n.cancel(),
        );
        if (!result) {
          return;
        }
      }

      hopper.requestPresent(
        `circle/${circleId}/membership-level-picker`,
        levelPicker.consumerId,
        { type: editingType },
      );
    } else if (editingType === CircleMembershipLevelPickerType.SubmitLimit) {
      let updatedFolder: CircleFolder = {
        ...editingFolder,
        contentSubmitPermission: {
          permissionType: PermissionType.Restricted,
          allowedMembershipLevels: [],
          allowedUids: [],
          allowedMemberTitleIds: [],
        },
      };
      updateFolderPermission(updatedFolder).catch(andLog);
    }
  };

  const onClickSelectTitle = () => {
    hopper.requestPresent(
      `circle/${circleId}/title-picker`,
      titlePicker.consumerId,
    );
  };

  const onClickSelectUser = () => {
    hopper.requestPresent(
      `circle/${circleId}/member-picker`,
      userPicker.consumerId,
      {
        showAdmin: false,
        type: editingType,
      },
    );
  };

  const onSwitchHidden = (checked: boolean) => {
    if (!editingFolder) return;
    let updatedFolder: CircleFolder;
    updatedFolder = {
      ...editingFolder,
      permission: checked ? FolderPermission.Hidden : FolderPermission.Open,
    };
    updateFolderPermission(updatedFolder).catch(andLog);
  };

  function onRemoveSelectedUserOrTitle() {
    if (!removingUserOrTitleRef.current || !editingFolder) {
      return;
    }
    const updatedPermission: ContentPermission = {
      permissionType: PermissionType.Restricted,
      allowedMembershipLevels: editingPermission?.allowedMembershipLevels ?? [],
      allowedUids: (editingPermission?.allowedUids ?? []).filter(
        (uid) => uid !== removingUserOrTitleRef.current,
      ),
      allowedMemberTitleIds: (
        editingPermission?.allowedMemberTitleIds ?? []
      ).filter((tid) => tid !== removingUserOrTitleRef.current),
    };
    const updatedUsers = (
      (editingType === CircleMembershipLevelPickerType.ViewLimit
        ? editingFolder.allowedViewUserList
        : editingFolder.allowedSubmitUserList) ?? []
    ).filter((user) => user.uid !== removingUserOrTitleRef.current);
    const updatedTitles = (
      (editingType === CircleMembershipLevelPickerType.ViewLimit
        ? editingFolder.allowedViewMemberTitleList
        : editingFolder.allowedSubmitMemberTitleList) ?? []
    ).filter((title) => title.titleId !== removingUserOrTitleRef.current);

    let updatedFolder: CircleFolder;
    if (editingType === CircleMembershipLevelPickerType.ViewLimit) {
      updatedFolder = produce(editingFolder, (draft) => {
        draft.contentViewPermission = updatedPermission;
        draft.allowedViewUserList = updatedUsers;
        draft.allowedViewMemberTitleList = updatedTitles;
      });
    } else {
      updatedFolder = produce(editingFolder, (draft) => {
        draft.contentSubmitPermission = updatedPermission;
        draft.allowedSubmitUserList = updatedUsers;
        draft.allowedSubmitMemberTitleList = updatedTitles;
      });
    }
    updateFolderPermission(updatedFolder).catch(andLog);
  }

  const onClickEditSubmitLevel = () => {
    hopper.requestPresent(
      `circle/${circleId}/membership-level-picker`,
      levelPicker.consumerId,
      {
        type: editingType,
        currentLevel: submitPermission?.allowedMembershipLevels?.at(0),
      },
    );
  };

  async function onClickRemoveSubmitLevel() {
    const result = await nativePage.alertAreYouSure(
      i18n.clover_remove_requirement_msg(),
      i18n.yes(),
      i18n.cancel(),
    );
    if (result) {
      if (!editingFolder) return;

      const updatedPermission: ContentPermission = {
        permissionType: PermissionType.Restricted,
        allowedMembershipLevels: [],
        allowedUids: editingFolder.contentSubmitPermission?.allowedUids ?? [],
        allowedMemberTitleIds:
          editingFolder.contentSubmitPermission?.allowedMemberTitleIds ?? [],
      };

      const updatedFolder = produce(editingFolder, (draft) => {
        draft.contentSubmitPermission = updatedPermission;
      });
      updateFolderPermission(updatedFolder).catch(andLog);
    }
  }

  return (
    <Page pageData={[folderInfoSWR, circleInfo]}>
      <NavMiddle>{i18n.circle2_1_permission()}</NavMiddle>
      <HStack
        style={{
          padding: "16px 0",
          alignItems: "start",
          gap: "8px",
        }}
      >
        <VStack style={{ flexShrink: 1, gap: "8px" }}>
          <ContentTitle>{i18n.circle2_1_hidden()}</ContentTitle>
          <ContentSubTitle>{i18n.clover_folder_hidden_desc()}</ContentSubTitle>
        </VStack>
        <Switch checked={permissionHidden} onChange={onSwitchHidden}></Switch>
      </HStack>
      <Divider />
      <VStack style={{ padding: "16px 0", gap: "8px" }}>
        <ContentTitle>{i18n.clover_view_content_permission()}</ContentTitle>
        <ContentSubTitle>
          {i18n.clover_who_can_view_folder_content()}
        </ContentSubTitle>
        {viewPermission?.permissionType !== PermissionType.Restricted && (
          <DropdownButton
            title={i18n.default_string()}
            subtitle={i18n.clover_all_can_view_folder_content()}
            onClick={() => {
              setEditingType(CircleMembershipLevelPickerType.ViewLimit);
              permissionModal.open();
            }}
          />
        )}
        {viewPermission?.permissionType === PermissionType.Restricted && (
          <DropdownButton
            title={i18n.clover_limited_to_selected_users()}
            subtitle={i18n.clover_selected_can_view_folder_content()}
            onClick={() => {
              setEditingType(CircleMembershipLevelPickerType.ViewLimit);
              permissionModal.open();
            }}
          >
            <ContentSubTitle style={{ fontWeight: 500 }}>
              {i18n.clover_minimum_membership_level()}
            </ContentSubTitle>
            <EditCircleMembershipLevelCell
              level={viewPermission.allowedMembershipLevels?.at(0) ?? 0}
              onClickEdit={() => {
                setEditingType(CircleMembershipLevelPickerType.ViewLimit);
                hopper.requestPresent(
                  `circle/${circleId}/membership-level-picker`,
                  levelPicker.consumerId,
                  {
                    type: editingType,
                    currentLevel: viewPermission.allowedMembershipLevels?.at(0),
                  },
                );
              }}
            ></EditCircleMembershipLevelCell>
            <ContentSubTitle style={{ fontWeight: 500 }}>
              {i18n.clover_specific_users()}
            </ContentSubTitle>
            <EditCircleUsersCell
              titles={viewPermissionTitles}
              users={viewPermissionUsers}
              onClickAdd={() => {
                setEditingType(CircleMembershipLevelPickerType.ViewLimit);
                addUserTypeModal.open();
              }}
              onClickTitle={(title) => {
                if (
                  title.type === TitleType.admin ||
                  title.type === TitleType.coAdmin
                ) {
                  nativePage
                    .alertNotice(
                      i18n.clover_folder_access_view_admin_tip(),
                      i18n.got(),
                    )
                    .catch(andLog);
                  return;
                }
                removingUserOrTitleRef.current = title.titleId;
                setEditingType(CircleMembershipLevelPickerType.ViewLimit);
                removeUserModal.open();
              }}
              onClickUser={(user) => {
                removingUserOrTitleRef.current = user.uid;
                setEditingType(CircleMembershipLevelPickerType.ViewLimit);
                removeUserModal.open();
              }}
            ></EditCircleUsersCell>
          </DropdownButton>
        )}
      </VStack>
      {shouldShowSubmitPermission && <Divider />}
      {shouldShowSubmitPermission && (
        <VStack style={{ padding: "16px 0", gap: "8px" }}>
          <ContentTitle>{i18n.clover_submit_posts_permission()}</ContentTitle>
          <ContentSubTitle>
            {i18n.clover_who_can_submit_to_folder()}
          </ContentSubTitle>
          {submitPermission?.permissionType !== PermissionType.Restricted && (
            <DropdownButton
              title={i18n.default_string()}
              subtitle={i18n.clover_all_can_submit_to_folder()}
              onClick={() => {
                setEditingType(CircleMembershipLevelPickerType.SubmitLimit);
                permissionModal.open();
              }}
            />
          )}
          {submitPermission?.permissionType === PermissionType.Restricted && (
            <DropdownButton
              title={i18n.clover_limited_to_selected_users()}
              subtitle={i18n.clover_selected_can_submit_to_folder()}
              onClick={() => {
                setEditingType(CircleMembershipLevelPickerType.SubmitLimit);
                permissionModal.open();
              }}
            >
              <ContentSubTitle style={{ fontWeight: 500 }}>
                {i18n.clover_minimum_membership_level()}
              </ContentSubTitle>
              <EditCircleMembershipLevelCell
                level={submitPermission.allowedMembershipLevels?.at(0)}
                onClickEdit={async () => {
                  if (circleInfo.content?.tokenProjectId === undefined) {
                    const result = await nativePage.alertYesOrCancel(
                      i18n.clover_folder_minimum_level_tip(),
                      i18n.clover_go_to_admin_portal(),
                      i18n.cancel(),
                    );
                    if (result) {
                      hopper.push(`circle/${circleId}/admin-portal`);
                    }
                    return;
                  }

                  setEditingType(CircleMembershipLevelPickerType.SubmitLimit);
                  if (
                    submitPermission?.allowedMembershipLevels?.at(0) ===
                    undefined
                  ) {
                    onClickEditSubmitLevel();
                  } else {
                    editSubmitLevelModal.open();
                  }
                }}
              ></EditCircleMembershipLevelCell>
              <ContentSubTitle style={{ fontWeight: 500 }}>
                {i18n.clover_specific_users()}
              </ContentSubTitle>
              <EditCircleUsersCell
                titles={submitPermissionTitles}
                users={submitPermissionUsers}
                onClickAdd={() => {
                  setEditingType(CircleMembershipLevelPickerType.SubmitLimit);
                  addUserTypeModal.open();
                }}
                onClickTitle={(title) => {
                  if (
                    title.type === TitleType.admin ||
                    title.type === TitleType.coAdmin
                  ) {
                    nativePage
                      .alertNotice(
                        i18n.clover_folder_access_admin_tip(),
                        i18n.got(),
                      )
                      .catch(andLog);
                    return;
                  }
                  removingUserOrTitleRef.current = title.titleId;
                  setEditingType(CircleMembershipLevelPickerType.SubmitLimit);
                  removeUserModal.open();
                }}
                onClickUser={(user) => {
                  removingUserOrTitleRef.current = user.uid;
                  setEditingType(CircleMembershipLevelPickerType.SubmitLimit);
                  removeUserModal.open();
                }}
              ></EditCircleUsersCell>
            </DropdownButton>
          )}
        </VStack>
      )}

      <BSMenu
        modal={permissionModal}
        title={
          editingType === CircleMembershipLevelPickerType.ViewLimit
            ? i18n.clover_view_content_permission()
            : i18n.clover_submit_posts_permission()
        }
      >
        <BSMenuItem
          title={i18n.default_string()}
          subtitle={
            editingType === CircleMembershipLevelPickerType.ViewLimit
              ? i18n.clover_all_can_view_folder_content()
              : i18n.clover_all_can_submit_to_folder()
          }
          onClick={() => {
            permissionModal.close();
            onClickDefaultPermission();
          }}
          selected={
            editingType === CircleMembershipLevelPickerType.ViewLimit
              ? viewPermission?.permissionType !== PermissionType.Restricted
              : submitPermission?.permissionType !== PermissionType.Restricted
          }
        />

        <BSMenuItem
          title={i18n.clover_limited_to_selected_users()}
          subtitle={
            editingType === CircleMembershipLevelPickerType.ViewLimit
              ? i18n.clover_selected_can_view_folder_content()
              : i18n.clover_selected_can_submit_to_folder()
          }
          onClick={() => {
            permissionModal.close();
            onClickLimitPermission().catch(andLog);
          }}
          selected={
            editingType === CircleMembershipLevelPickerType.ViewLimit
              ? viewPermission?.permissionType === PermissionType.Restricted
              : submitPermission?.permissionType === PermissionType.Restricted
          }
        />
      </BSMenu>

      <BSMenu modal={addUserTypeModal} title={i18n.clover_select_users()}>
        <BSMenuItem
          title={i18n.clover_select_specific_titles()}
          onClick={onClickSelectTitle}
        />
        <BSMenuItem
          title={i18n.clover_select_specific_users()}
          onClick={onClickSelectUser}
        />
      </BSMenu>

      <BSMenu modal={removeUserModal}>
        <BSMenuItem
          title={i18n.delete()}
          onClick={onRemoveSelectedUserOrTitle}
        />
        <BSMenuItem title={i18n.cancel()} />
      </BSMenu>

      <BSMenu modal={editSubmitLevelModal}>
        <BSMenuItem title={i18n.edit()} onClick={onClickEditSubmitLevel} />
        <BSMenuItem
          title={i18n.clover_remove_this_requirement()}
          onClick={onClickRemoveSubmitLevel}
        />
        <BSMenuItem title={i18n.cancel()} />
      </BSMenu>
    </Page>
  );
}
