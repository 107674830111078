import styled from "styled-components";
import ic_edit from "../../../res/images/ic_edit.svg";
import React from "react";
import { Size } from "../../../utils/Geo";
import { Currency } from "../../../proto/Currency";
import { alpha_on_pressed } from "../../../components/CommonStyles";
import { Image, MediaSizeMode } from "../../../components/Image";
import { useI18n } from "../../../hooks/useI18n";
import { Media } from "../../../proto/Media";
import { LocalMedia } from "../../../bridge/LocalMedia";

const TokenContainer = styled.div<{ $size: Size; $radius: number }>`
  width: ${(p) => p.$size.width}px;
  height: ${(p) => p.$size.height}px;
  flex-shrink: 0;
  flex-grow: 0;
  position: relative;
  border-radius: ${(p) => p.$radius}px;
  background-color: #ffffff0f;
  ${alpha_on_pressed}
`;

const EditBadge = styled.div<{ $radius: number }>`
  position: absolute;
  height: 24px;
  border-radius: 12px 0 ${(p) => p.$radius}px 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  padding: 2px 11px 2px 5px;
  gap: 2px;
`;

const EditText = styled.div`
  font-weight: 300;
  font-size: 12px;
  color: var(--color-text00);
`;
export function TokenIcon(props: {
  onClick?: () => void;
  size: Size;
  containerStyle?: React.CSSProperties;
  currency?: Currency;
  src:
    | readonly [Media | LocalMedia | undefined, MediaSizeMode]
    | string
    | undefined;
  showEditBadge?: boolean;
  radius: number;
}) {
  const i18n = useI18n();
  const onCellClick = () => {
    if (props.onClick) props.onClick();
  };
  return (
    <TokenContainer
      onClick={() => onCellClick()}
      $size={props.size}
      style={props.containerStyle}
      $radius={props.radius}
    >
      <Image
        width={props.size.width}
        height={props.size.height}
        src={props.src ? props.src : [props.currency?.icon, props.size]}
        style={{
          position: "absolute",
          borderRadius: `${props.radius}px`,
        }}
      />
      {props.showEditBadge && (
        <EditBadge $radius={props.radius}>
          <Image src={ic_edit} style={{ width: 16, height: 16 }} />
          <EditText>{i18n.edit()}</EditText>
        </EditBadge>
      )}
    </TokenContainer>
  );
}
