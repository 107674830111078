import { z } from "zod";
import { zBigInt, zEnum, zStatic } from "../utils/zodUtils";
import { User } from "./User";
import { Circle } from "./Circle";
import { CircleFolder } from "./CircleFolder";
import { ChainType } from "./ChainType";

export enum AlertGroup {
  Activity = 1,
  Like = 2,
  Follow = 3,
  GlobalAnnouncement = 4,
  Post = 5,
  Circle = 7,
}

export enum AlertType {
  Like = 1,
  Comment = 2,
  Followed = 3,
  Pinned = 4,
  OtherPostPinned = 5,
  SelfPostPinned = 6,
  NewPost = 7,
  PollVoted = 8,
  OtherChatPinned = 9,
  SelfChatPinned = 10,
  CommentPinned = 11,
  PollFinished = 12,
  CircleTitle = 13,
}

const AlertExtensions = z.object({
  stickerName: z.string().optional(),
  replyToUid: zBigInt.optional(),
  chainType: zEnum(ChainType).optional(),
});

export const Alert = z.object({
  alertId: zBigInt,
  circleId: zBigInt.optional(),
  circleInfo: Circle.optional(),
  circleFolder: CircleFolder.optional(),
  contextText: z.string().optional(),
  createdTime: z.number(),
  groupId: zEnum(AlertGroup).optional(),
  objectId: zBigInt.optional(),
  objectText: z.string().optional(),
  objectType: z.number().optional(),
  operator: User.optional(),
  operatorUid: zBigInt.optional(),
  parentId: zBigInt.optional(),
  parentText: z.string().optional(),
  parentType: z.number().optional(),
  targetUid: zBigInt.optional(),
  type: zEnum(AlertType).optional(),
  extensions: AlertExtensions.optional(),
});

export type Alert = zStatic<typeof Alert>;
