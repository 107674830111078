import { I18n, useI18n } from "../../hooks/useI18n";
import SearchCirclePage from "./SearchCirclePage";
import React, { useEffect } from "react";
import iconSearch from "../../res/images/icon_search_layout_placeholder.svg";
import styled, { RuleSet } from "styled-components";
import { Image } from "../../components/Image";
import { Input } from "../../components/Input";
import {
  ViewPagerPage,
  ViewPagerTabLayout,
} from "../../components/ViewPagerPage";
import { subpages } from "../../components/Subpage";
import { SearchUserPage } from "./SearchUserPage";
import { useDebounceHopState } from "../../hooks/ExtHooks";
import close from "../../../src/res/images/ic_close.svg";
import { HStack, VSpace } from "../../components/VStack";
import { useHopper } from "../../hooks/useHopper";
import { SearchPartyPage } from "./SearchPartyPage";
import { SearchPostPage } from "./SearchPostPage";
import {
  useBigIntSearchParam,
  useEnumSearchParam,
} from "../../hooks/useTypedParam";
import { SearchAllPage } from "./SearchAllPage";
import { useBackend } from "../../service/APIService";
import { OverFlowTextStyle } from "../../components/Text";
import { CircleIconView } from "../alertCenter/AlertDetailCell";
import { andLog, useErrorHandler } from "../../components/handleError";
import { Circle } from "../../proto/Circle";
import { useNativePage } from "../../hooks/useBridge";
import { useHopState } from "../../hooks/useHopState";
import { hPaddingWithPageInset } from "../../components/CommonStyles";
import {
  NavItem,
  NavMiddle,
  NavStart,
  useHasNavBar,
} from "../../components/NavBar";
import { NavButtonType } from "../../bridge/NavButtonDesc";

export interface SearchProps {
  word: string;
  circleId?: bigint;
}

function SearchPage() {
  const i18n = useI18n();
  const hopper = useHopper();
  const backend = useBackend();
  const nativePage = useNativePage();
  const [searchWord, setSearchWord] = useDebounceHopState<string>(
    "searchWord",
    300,
  );
  const handleError = useErrorHandler();
  const [inputWord, setInputWord] = useHopState<string>("inputWord");

  const searchSource = useEnumSearchParam<SearchSource>(
    "searchSource",
    SearchSource.All,
  );
  const [currentSource, setCurrentSource] =
    React.useState<SearchSource>(searchSource);

  function getDisplaySourceList(
    searchSource: SearchSource,
  ): [SearchSource, string][] {
    switch (searchSource) {
      case SearchSource.InCircleAll:
        return [
          [
            SearchSource.InCircleAll,
            SearchSource.title(i18n, SearchSource.InCircleAll),
          ],
          [SearchSource.Users, SearchSource.title(i18n, SearchSource.Users)],
          [SearchSource.Chats, SearchSource.title(i18n, SearchSource.Chats)],
          [
            SearchSource.Moments,
            SearchSource.title(i18n, SearchSource.Moments),
          ],
        ];
      default:
        return [
          [SearchSource.All, SearchSource.title(i18n, SearchSource.All)],
          [SearchSource.Users, SearchSource.title(i18n, SearchSource.Users)],
          [SearchSource.Circle, SearchSource.title(i18n, SearchSource.Circle)],
          [SearchSource.Chats, SearchSource.title(i18n, SearchSource.Chats)],
          [
            SearchSource.Moments,
            SearchSource.title(i18n, SearchSource.Moments),
          ],
        ];
    }
  }

  const [circleId, setCircleId] = useHopState<bigint | undefined>(
    "circleId",
    useBigIntSearchParam("circleId"),
  );

  const [circle, setCircle] = useHopState<Circle>("circle");
  useEffect(() => {
    const fetchCircle = async () => {
      if (circleId) {
        try {
          const result = await backend.getCircle(circleId).run();
          setCircle(result);
        } catch (error) {
          handleError(error);
        }
      }
    };
    fetchCircle().catch(andLog);
  }, [circleId]);

  const hasNavBar = useHasNavBar();

  return (
    <ViewPagerPage
      collapsingHeaderDisabled={true}
      initialPageId={searchSource}
      subpages={
        searchWord && searchWord.length > 0 && inputWord && inputWord.length > 0
          ? subpages(getDisplaySourceList(currentSource), (e) => {
              switch (e) {
                case SearchSource.All:
                  return (
                    <SearchAllPage
                      word={searchWord}
                      searchSource={currentSource}
                    />
                  );
                case SearchSource.InCircleAll:
                  return (
                    <SearchAllPage
                      word={searchWord}
                      circleId={circleId}
                      searchSource={currentSource}
                    />
                  );
                case SearchSource.Users:
                  return (
                    <SearchUserPage word={searchWord} circleId={circleId} />
                  );
                case SearchSource.Circle:
                  return <SearchCirclePage word={searchWord} />;
                case SearchSource.Chats:
                  return (
                    <SearchPartyPage word={searchWord} circleId={circleId} />
                  );
                case SearchSource.Moments:
                  return (
                    <SearchPostPage word={searchWord} circleId={circleId} />
                  );
              }
            })
          : []
      }
    >
      <NavMiddle>{i18n.search()}</NavMiddle>
      <NavStart>
        {NavItem.image(NavButtonType.Cross, () => hopper.back())}
      </NavStart>

      {!hasNavBar && <VSpace height={16} />}
      <HStack style={{ ...hPaddingWithPageInset }}>
        {!hasNavBar && (
          <Image
            src={close}
            width={36}
            height={36}
            onClick={() => {
              hopper.back();
            }}
          />
        )}
        <SearchBackground>
          <Image alt={"search-icon"} src={iconSearch} width={24} height={24} />
          <Input
            value={inputWord}
            style={{
              height: 36,
              margin: "0 6px 0 0",
              padding: "0",
              flexGrow: 1,
            }}
            ref={(e) => {
              if (e) e.focus();
            }}
            onChange={(e) => {
              if (e.target.value) {
                setSearchWord(e.target.value);
                setInputWord(e.target.value);
              } else {
                setInputWord("");
              }
            }}
          />
        </SearchBackground>
      </HStack>
      {currentSource === SearchSource.InCircleAll && circle && (
        <>
          <CircleInfoBackground>
            <CircleIconView circle={circle} iconSize={20}></CircleIconView>
            <CircleName>{circle.name}</CircleName>
            <Image
              src={close}
              width={24}
              height={24}
              onClick={() => {
                setCurrentSource(SearchSource.All);
                setCircleId(undefined);
              }}
            ></Image>
          </CircleInfoBackground>
        </>
      )}
      <ViewPagerTabLayout />
    </ViewPagerPage>
  );
}

export default SearchPage;

export const SearchBackground = styled.div`
  background-color: rgba(255, 255, 255, 0.08);
  border-radius: 6px;
  height: 36px;
  padding-inline-start: 6px;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  gap: 4px;
`;

const CircleInfoBackground = styled.div`
  background-color: rgba(255, 255, 255, 0.08);
  margin-left: var(--page-h-inset);
  border-radius: 6px;
  height: 32px;
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  border: 1px solid rgba(255, 255, 255, 0.1);
  gap: 4px;
  padding-inline-start: 6px;
  padding-inline-end: 6px;
  margin-top: 8px;
  width: fit-content;
  max-width: calc(100% - var(--page-h-inset) * 2);
`;
const CircleName = styled.div<{ mixin?: RuleSet<Object> }>`
  font-weight: 300;
  font-size: 12px;
  flex-shrink: 1;
  ${OverFlowTextStyle};
  -webkit-line-clamp: 1;
  ${(p) => p.mixin}
`;

export enum SearchSource {
  All = 0,
  Users = 1,
  // Character = 2,
  // Nfts = 3,
  // Shops = 4,
  Circle = 5,
  Chats = 6,
  Moments = 7,
  // Folders = 8,
  InCircleAll = 9,
}

export namespace SearchSource {
  export function title(i18n: I18n, source: SearchSource): string {
    switch (source) {
      case SearchSource.All:
        return i18n.all();
      case SearchSource.Circle:
        return i18n.circles();
      case SearchSource.Users:
        return i18n.users();
      // case SearchSource.Character:
      //   return i18n.web3_ugc_characters();
      // case SearchSource.Nfts:
      //   return i18n.web3_search_merch();
      // case SearchSource.Shops:
      //   return i18n.web3_search_shops();
      case SearchSource.Chats:
        return i18n.parties();
      case SearchSource.Moments:
        return i18n.posts();
      // case SearchSource.Folders:
      //   return i18n.incircle_search_folders();
      case SearchSource.InCircleAll:
        return i18n.all();
      default:
        return "";
    }
  }
}
