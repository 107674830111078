import {
  ViewPagerPage,
  ViewPagerTabLayout,
} from "../../components/ViewPagerPage";
import React from "react";
import { ObjectType } from "../../proto/ObjectSpec";
import { useI18n } from "../../hooks/useI18n";
import styled from "styled-components";
import { Image } from "../../components/Image";
import { subpages } from "../../components/Subpage";
import {
  useStringParam,
  useStringSearchParam,
} from "../../hooks/useTypedParam";
import { TagUserPage } from "./TagUserPage";
import { TagCirclePage } from "./TagCirclePage";
import { TagPostPage } from "./TagPostPage";
import { TagPartyPage } from "./TagPartyPage";
import { TagDiscoverPage } from "./TagDiscoverPage";
import { NavEnd, NavItem } from "../../components/NavBar";
import { NavButtonType } from "../../bridge/NavButtonDesc";
import { BSMenu, BSMenuItem } from "../../components/BSMenu";
import { useModal } from "../../components/Modal";
import { copyTextToClipboard } from "../../utils/CopyText";
import { andLog, useErrorHandler } from "../../components/handleError";
import { useBackend } from "../../service/APIService";
import { useNativePage } from "../../hooks/useBridge";
import { useGlobalSpinner } from "../../utils/globalSpinner";
import { useSWR } from "../../hooks/swr/useSWR";
import { useHopper } from "../../hooks/useHopper";

export const tagPageBackground = (
  <div style={{ width: "100%", height: "100%", background: "black" }}></div>
);

export function TagsPage() {
  const tagId = useStringParam("tagId");
  const tagName = useStringSearchParam("tagName");
  const bgColor = useStringSearchParam("bgColor");
  const i18n = useI18n();
  const globalSpinner = useGlobalSpinner();
  const handleError = useErrorHandler();
  const backend = useBackend();
  const nativePage = useNativePage();

  const tagSWR = useSWR(backend.getTag(tagId, tagName));
  const displayTagName = tagName || tagSWR.content?.tagName;
  const hopper = useHopper();

  const entries: [ObjectType, string][] = [
    [ObjectType.NONE, i18n.discover()],
    [ObjectType.USER, i18n.users()],
    [ObjectType.CIRCLE, i18n.circles()],
    [ObjectType.CHAT, i18n.parties()],
    [ObjectType.BLOG, i18n.posts()],
  ];

  const background = (
    <Image
      src={undefined}
      style={{ width: "100%", height: "100%", background: bgColor || "pink" }}
    />
  );
  const moreMenu = useModal("tags-page-more-menu");

  async function shareLink(tagId: string) {
    try {
      await globalSpinner(async () => {
        const path = `tag/${tagId}`;
        const linkModel = await backend.getShareLink(path).run();
        if (linkModel.shareLink) {
          await copyTextToClipboard(linkModel.shareLink);
          await nativePage.successHud(i18n.copied());
        }
      });
    } catch (e) {
      handleError(e);
    }
  }

  return (
    <ViewPagerPage
      subpages={subpages(entries, (e) => {
        switch (e) {
          case ObjectType.USER:
            return (
              <TagUserPage tagId={tagId} tagName={displayTagName}></TagUserPage>
            );
          case ObjectType.CIRCLE:
            return (
              <TagCirclePage
                tagId={tagId}
                tagName={displayTagName}
              ></TagCirclePage>
            );
          case ObjectType.BLOG:
            return (
              <TagPostPage tagId={tagId} tagName={displayTagName}></TagPostPage>
            );
          case ObjectType.CHAT:
            return (
              <TagPartyPage
                tagId={tagId}
                tagName={displayTagName}
              ></TagPartyPage>
            );
          default:
            return <TagDiscoverPage tagId={tagId} tagName={displayTagName} />;
        }
      })}
      contentBackground={background}
    >
      <NavEnd>
        {NavItem.image(NavButtonType.More, () => {
          moreMenu.open();
        })}
      </NavEnd>
      <TagName>{`#${displayTagName}`}</TagName>
      <ViewPagerTabLayout />

      <BSMenu modal={moreMenu}>
        <BSMenuItem
          title={i18n.share_copy_link()}
          onClick={() => {
            shareLink(tagId).catch(andLog);
          }}
        />
        {/*TODO:StevenQ*/}
        <BSMenuItem title={i18n.share()} onClick={() => {}} />
        <BSMenuItem
          title={i18n.flag()}
          onClick={() => {
            hopper.layer("flag", {
              objectId: tagId,
              objectType: ObjectType.TAG,
            });
          }}
        />
      </BSMenu>
    </ViewPagerPage>
  );
}

const TagName = styled.div`
  font-weight: 800;
  color: var(--color-text00);
  font-size: 20px;
  width: 100%;
  padding: 32px 0;
  text-align: center;
`;
