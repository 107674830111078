import {
  Currency,
  formatMoney,
  getCurrencyName,
} from "../../../proto/Currency";
import { ContentGroup } from "../../../components/ContentGroup";
import { HStack, VSpace, VStack } from "../../../components/VStack";
import { Image } from "../../../components/Image";
import styled from "styled-components";
import { useI18n } from "../../../hooks/useI18n";
import GridLayout from "../../../components/GridLayout";
import { useMemo } from "react";
import { NFT } from "../../../proto/NFT";
import BigNumber from "bignumber.js";
import icIncubation from "../../../res/images/ic_incubation.svg";
import icLaunch from "../../../res/images/ic_stage_launched.svg";
import CircleCardsPreviewCell, {
  CardStyle,
} from "../../circle/card/CircleCardsPreviewCell";
import { useHopper } from "../../../hooks/useHopper";
import icSendEnable from "../../chat/images/ic_send_enable.svg";
import icSendDisable from "../../chat/images/ic_send_disable.svg";

export interface TokenPreviewCellProps {
  currency: Currency;
  membershipCardList?: NFT[];
  onClickToken: () => void;
}

function TokenPreviewCell(props: TokenPreviewCellProps) {
  const i18n = useI18n();
  const hopper = useHopper();
  const smallCards = useMemo(() => {
    return props.membershipCardList?.slice(0, 4)?.map((nft) => {
      return (
        <CircleCardsPreviewCell
          nft={nft}
          cardStyle={CardStyle.small}
          onClick={() => {
            hopper.push(`nft-circle-card/${nft.nftId}`);
          }}
        />
      );
    });
  }, [props.membershipCardList]);
  return (
    <ContentGroup style={{ marginTop: 12 }} onClick={props.onClickToken}>
      <HStack style={{ justifyContent: "space-between", alignItems: "start" }}>
        <HStack style={{ gap: 8, alignItems: "start" }}>
          <Image
            style={{ borderRadius: 5 }}
            src={[props.currency.icon, "smallest"]}
            width={28}
            height={28}
          />
          <VStack>
            <HStack style={{ gap: 2 }}>
              <TokenName>{getCurrencyName(props.currency, i18n)}</TokenName>
              {/*<Image src={getCircleTokenStage(p)} />*/}
              <object
                data={!!props.currency.poolId ? icLaunch : icIncubation}
                style={{
                  width: 18,
                  height: 18,
                }}
              />
            </HStack>

            {!!props.currency.unitUSDPrice && (
              <MCContainer>
                MC
                <MCValue>
                  {`$ ${formatMoney("medium", props.currency.unitUSDPrice ?? BigNumber(0))}`}
                </MCValue>
              </MCContainer>
            )}
          </VStack>
        </HStack>

        <VStack style={{ alignItems: "end" }}>
          <TotalValueLabel>{i18n.web3_v0_total()}</TotalValueLabel>
          <VSpace height={8} />
          <HStack style={{ gap: 4 }}>
            <TokenAmount>{formatMoney("short", props.currency)}</TokenAmount>
            {/*<Image src={chevron} width={24} height={24} />*/}
            <TickerLabel>{props.currency.symbol}</TickerLabel>
          </HStack>
          <VSpace height={2} />
          <TotalUSDValue>{`${formatMoney("medium", BigNumber(props.currency.usdPrice ?? 0))} USD`}</TotalUSDValue>
        </VStack>
      </HStack>

      {smallCards && (
        <GridLayout spanCount={4} items={smallCards} style={{ gap: 6 }} />
      )}
    </ContentGroup>
  );
}

const TickerLabel = styled.div`
  padding: 2px;
  max-width: 40px;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 10px;
  color: #ffffff;
  background: rgba(255, 255, 255, 0.06);
  border-radius: 2px;
`;

const MCValue = styled.div`
  color: white;
  font-size: 12px;
  font-weight: 700;
`;

const MCContainer = styled.div`
  color: rgba(255, 255, 255, 0.6);
  font-size: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

const TokenName = styled.div`
  color: var(--color-text00);
  font-size: 18px;
  font-weight: 500;
  padding: 2px;
  background: rgba(255, 255, 255, 0.06);
  border-radius: 2px;
`;

const TokenAmount = styled.div`
  color: var(--color-text00);
  font-size: 18px;
  font-weight: 500;
`;

const TotalValueLabel = styled.div`
  color: var(--color-text20);
  font-size: 10px;
  font-weight: 400;
`;

const TotalUSDValue = styled.div`
  color: var(--color-text20);
  font-size: 10px;
  font-weight: 400;
`;

export default TokenPreviewCell;
