import { string, z } from "zod";
import { createResultToken } from "../../../hooks/useResult";

export const TagSchema = z.object({
  tags: z.array(z.string()),
});

export const EditTagsPickerResult = createResultToken(
  TagSchema,
  "EditTagsPickerResult",
);

export const hashCode = (str: string): number => {
  let h = 0;
  for (let i = 0; i < str.length; i++) {
    h = Math.imul(h, 31) + str.charCodeAt(i); // 等价于 h * 31 + char
    h |= 0; // 保持结果为 32 位整数
  }
  return h;
};

export const getColorByHash = (hash: number) => {
  const index = Math.abs(hash) % TopicColors.length;
  return TopicColors[index];
};

const TopicColors = [
  {
    backgroundColor: "#99391201",
    borderColor: "#ff7335",
    textColor: "#FF7335",
  },
  {
    backgroundColor: "#99190034",
    borderColor: "#A465FF",
    textColor: "#A465FF",
  },
  {
    backgroundColor: "#99002920",
    borderColor: "#00CFA5",
    textColor: "#00CFA5",
  },
  {
    backgroundColor: "#99112702",
    borderColor: "#6FC445",
    textColor: "#6FC445",
  },
  {
    backgroundColor: "#99001535",
    borderColor: "#0AB2F6",
    textColor: "#0AB2F6",
  },
  {
    backgroundColor: "#99360135",
    borderColor: "#FC38CF",
    textColor: "#FC38CF",
  },
  {
    backgroundColor: "#992C003A",
    borderColor: "#D33EFF",
    textColor: "#D33EFF",
  },
  {
    backgroundColor: "#99050337",
    borderColor: "#969BFF",
    textColor: "#969BFF",
  },
  {
    backgroundColor: "#9943001E",
    borderColor: "#FF2E7E",
    textColor: "#FF2E7E",
  },
  {
    backgroundColor: "#99382500",
    borderColor: "#ECAC16",
    textColor: "#ECAC16",
  },
];
