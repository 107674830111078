import { Page } from "../../../components/Page";
import { useI18n } from "../../../hooks/useI18n";
import { useBigIntParam } from "../../../hooks/useTypedParam";
import { useNativePage } from "../../../hooks/useBridge";
import { useSWR } from "../../../hooks/swr/useSWR";
import { Spacing } from "../../../components/Spacing";
import { Spring, VStack } from "../../../components/VStack";
import { ButtonColor, WeakButton } from "../../../components/Buttons";
import React from "react";
import { useLoadState } from "../../../hooks/LoadState";
import { andLog, useErrorHandler } from "../../../components/handleError";
import { Spin } from "../../../components/Spin";
import CircleBackgroundFrame from "../CircleBackgroundFrame";
import { useHopper } from "../../../hooks/useHopper";
import { useBackend } from "../../../service/APIService";
import { useCirclesSWR } from "../useCircleSWR";
import { CircleGridType } from "../CircleGridPage";
import { isCircleAdminOrCoAdmin } from "../../../proto/Circle";
import { useMyUid } from "../../../service/AuthSessionService";
import { NavMiddle } from "../../../components/NavBar";
import { CircleMemberStatus } from "../../../proto/CircleMemberStatus";

function CircleSettingsPage() {
  const circleId = useBigIntParam("circleId");
  const backend = useBackend();
  const circleSWR = useSWR(backend.getCircle(circleId));

  const i18n = useI18n();

  const leaveCircleTask = useLoadState();
  const nativePage = useNativePage();
  const handleError = useErrorHandler();
  const myUid = useMyUid();
  const allCirclesSWR = useCirclesSWR(CircleGridType.All);
  const managedCirclesSWR = useCirclesSWR(CircleGridType.Managed);

  async function leaveCircle() {
    const r = await leaveCircleTask.run(async () => {
      const circle = await backend.leaveCircle(circleId).run();
      allCirclesSWR.load().catch(andLog);
      if (
        circleSWR.content &&
        isCircleAdminOrCoAdmin(circleSWR.content, myUid)
      ) {
        managedCirclesSWR.load().catch(andLog);
      }
      await circleSWR.fill(circle);
      hopper.back();
    });

    if (!r.success) {
      handleError(r.error);
    }
  }

  async function leaveCircleAlert() {
    const yes = await nativePage.alertAreYouSure(
      i18n.circle_leave_confirmation(),
      i18n.action_leave(),
      i18n.cancel(),
    );
    if (yes) {
      leaveCircle().catch(andLog);
    }
  }

  const hopper = useHopper();

  return (
    <Page
      pageData={circleSWR}
      scrollPaddingDisabled={true}
      underlay={
        <CircleBackgroundFrame
          circleBackground={circleSWR.content?.circleBackground}
        />
      }
    >
      <NavMiddle>{i18n.circle_settings()}</NavMiddle>
      {/*<SettingCell*/}
      {/*  title={i18n.notification_settings()}*/}
      {/*  onClick={() => {*/}
      {/*    hopper.push(`circle/${circleId}/notification-settings`);*/}
      {/*  }}*/}
      {/*/>*/}

      {/*<SettingCell*/}
      {/*  title={i18n.member_title_manage_my_titles()}*/}
      {/*  showDivider={false}*/}
      {/*  onClick={() => {}}*/}
      {/*/>*/}
      <Spacing height={30} />
      {circleSWR.content?.joinedStatus === CircleMemberStatus.JOINED && (
        <VStack style={{ marginInlineStart: 50, marginInlineEnd: 50 }}>
          <WeakButton
            onClick={() => {
              leaveCircleAlert().catch(andLog);
            }}
            $baseColor={ButtonColor.destructive}
          >
            <Spin state={leaveCircleTask.state}>
              {i18n.leave_this_circle()}
            </Spin>
          </WeakButton>
          <Spring />
        </VStack>
      )}
      <Spacing height={30} />
    </Page>
  );
}

export default CircleSettingsPage;
