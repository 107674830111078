import { Page } from "../../../components/Page";
import { useBigIntParam } from "../../../hooks/useTypedParam";
import { useNativePage } from "../../../hooks/useBridge";
import { useSWR } from "../../../hooks/swr/useSWR";
import { useI18n } from "../../../hooks/useI18n";
import SettingCell, {
  SettingEndValueText,
} from "../../../components/SettingCell";
import React, { useEffect, useState } from "react";
import { Image } from "../../../components/Image";
import CircleBackgroundFrame from "../CircleBackgroundFrame";
import { useHopper } from "../../../hooks/useHopper";
import { useMyUid } from "../../../service/AuthSessionService";
import { useBackend } from "../../../service/APIService";
import { NavMiddle } from "../../../components/NavBar";

function CircleInfoSettingsPage() {
  const circleId = useBigIntParam("circleId");
  const backend = useBackend();
  const circleSWR = useSWR(backend.getCircle(circleId));
  const i18n = useI18n();
  const nativePage = useNativePage();

  const hopper = useHopper();
  const myUid = useMyUid();
  const [isAdmin, setAdmin] = useState<boolean>();
  useEffect(() => {
    const circle = circleSWR.content;
    if (circle) {
      const selfUid = String(myUid);
      setAdmin(circle.adminIdList.map((it) => String(it)).includes(selfUid));
    }
  }, [circleSWR.content]);

  const checkIsAdminAndThen = (block: () => void) => {
    if (isAdmin) {
      block();
    } else {
      nativePage
        .alertNotice(i18n.circle2_1_only_admin_can_manage_info(), i18n.ok())
        .then();
    }
  };

  return (
    <Page
      pageData={circleSWR}
      scrollPaddingDisabled={true}
      underlay={
        <CircleBackgroundFrame
          circleBackground={circleSWR.content?.circleBackground}
        />
      }
    >
      <NavMiddle>{i18n.circle2_1_circle_info()}</NavMiddle>
      <SettingCell
        title={i18n.circle2_1_circle_icon()}
        endValue={
          <Image
            src={[circleSWR.content?.cover, "best"]}
            width={36}
            style={{ borderRadius: 6, border: "1px solid white" }}
            height={36}
          />
        }
      />

      <SettingCell
        onClick={() => {
          checkIsAdminAndThen(() => {
            hopper.present(`circle/${circleId}/info-settings/edit-name`);
          });
        }}
        title={i18n.circle_name()}
        endValue={
          <SettingEndValueText>{circleSWR.content?.name}</SettingEndValueText>
        }
      />

      <SettingCell
        title={"SocialID"}
        onClick={() => {
          checkIsAdminAndThen(() => {
            if ((circleSWR.content?.socialIdModified ?? 0) >= 1) {
              nativePage.alertNotice("SocialId CAN NOT BE MODIFIED", i18n.ok());
            } else {
              hopper.present(`circle/${circleId}/info-settings/edit-social-id`);
            }
          });
        }}
        endValue={
          <SettingEndValueText>
            {circleSWR.content?.socialId}
          </SettingEndValueText>
        }
      />

      <SettingCell
        onClick={() => {
          checkIsAdminAndThen(() => {
            hopper.present(`circle/${circleId}/info-settings/edit-tagline`);
          });
        }}
        title={i18n.tagline()}
        endValue={
          <SettingEndValueText>
            {circleSWR.content?.tagline}
          </SettingEndValueText>
        }
      />

      <SettingCell
        title={i18n.description()}
        endValue={
          <SettingEndValueText>
            {circleSWR.content?.description}
          </SettingEndValueText>
        }
      />

      <SettingCell title={i18n.tags()} />

      <SettingCell
        onClick={() => {
          checkIsAdminAndThen(() => {
            hopper.present(
              `circle/${circleId}/info-settings/edit-welcome-message`,
            );
          });
        }}
        title={i18n.welcome_message()}
        endValue={
          <SettingEndValueText>
            {circleSWR.content?.welcomeMessage}
          </SettingEndValueText>
        }
      />

      <SettingCell
        title={i18n.language()}
        onClick={() => {
          nativePage
            .alertNotice(i18n.circle_cannot_modify_circle_language(), i18n.ok())
            .finally();
        }}
        endValue={
          <SettingEndValueText>
            {circleSWR.content?.language}
          </SettingEndValueText>
        }
      />

      <SettingCell
        title={i18n.category()}
        onClick={() => {
          nativePage
            .alertNotice(i18n.cannot_modify_category(), i18n.ok())
            .finally();
        }}
        endValue={
          <SettingEndValueText>
            {circleSWR.content?.category?.name}
          </SettingEndValueText>
        }
      />
    </Page>
  );
}

export default CircleInfoSettingsPage;
