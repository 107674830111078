import { splitZDoc, ZDocProps } from "./ZDocCommon";
import React, { ReactNode } from "react";
import { ZDocAtom } from "./ZDocAtom";
import { ParagraphStyle } from "./RichFormat";
import { flattenStateId } from "../../hooks/StateId";

export function ZDocText(
  props: ZDocProps & { paragraphStyle: ParagraphStyle | undefined },
) {
  const nodes: ReactNode[] = [];

  splitZDoc(
    props.richFormat,
    props.content,
    props.richFormat?.textSpans,
    (rf, content) => {
      nodes.push(
        <ZDocAtom
          key={flattenStateId([content, nodes.length])}
          content={content}
          textStyle={undefined}
          paragraphStyle={props.paragraphStyle}
          options={props.options}
        />,
      );
    },
    (textStyle, rf, content) => {
      nodes.push(
        <ZDocAtom
          key={flattenStateId([textStyle, content, nodes.length])}
          textStyle={textStyle}
          paragraphStyle={props.paragraphStyle}
          content={content}
          options={props.options}
        />,
      );
    },
  );
  return <>{nodes}</>;
}
