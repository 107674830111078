import { Page } from "../../../components/Page";
import { useBackend } from "../../../service/APIService";
import { useSWRArray } from "../../../hooks/swr/useSWRArray";
import { useBigIntParam } from "../../../hooks/useTypedParam";
import { PostCell } from "../../post/PostCell";
import { useI18n } from "../../../hooks/useI18n";
import { ObjectType } from "../../../proto/ObjectSpec";
import { NavMiddle } from "../../../components/NavBar";
import React from "react";
import { useMemoryRepoMap } from "../../../hooks/swr/useLocalRepo";
import { zBigInt } from "../../../utils/zodUtils";
import { z } from "zod";
import { Reaction } from "../../../proto/Reaction";
import { andLog } from "../../../components/handleError";
import { useReactionRecord } from "../../../hooks/useReactionRecord";

export function ChatroomAnnouncementsPage() {
  const threadId = useBigIntParam("threadId");
  const backend = useBackend();
  const i18n = useI18n();

  const reactionRepo = useReactionRecord();

  const announcementsSWR = useSWRArray(
    backend.getChatroomAnnouncements(threadId).intercept((it) => {
      it.list.forEach((post) => {
        reactionRepo
          .setValue(post.blogId, post.reactionCountList)
          .catch(andLog);
      });
    }),
  );

  return (
    <Page
      pageData={announcementsSWR}
      loadStateConfig={{ emptyText: i18n.no_announcement() }}
    >
      {announcementsSWR?.content.map((post) => (
        <PostCell
          key={`chatroom-${threadId}-announcement`}
          post={post}
          objectType={ObjectType.CHAT_ANNOUNCEMENT}
        />
      ))}
      <NavMiddle>{i18n.announcement()}</NavMiddle>
    </Page>
  );
}
