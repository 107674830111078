import { Page } from "../../components/Page";
import { NavMiddle } from "../../components/NavBar";
import { useI18n } from "../../hooks/useI18n";
import styled from "styled-components";
import { Image } from "../../components/Image";
import {
  ButtonColor,
  RegularButton,
  RegularLargeButton,
} from "../../components/Buttons";
import { useSWR } from "../../hooks/swr/useSWR";
import { useBackend } from "../../service/APIService";
import { useBigIntParam } from "../../hooks/useTypedParam";
import { formatMoney, getCurrencyIcon } from "../../proto/Currency";
import { useSWRArray } from "../../hooks/swr/useSWRArray";
import {
  formatDateWithOptions,
  isSameYearAndMonth,
} from "../../utils/DateTimeUtils";
import { useHopper } from "../../hooks/useHopper";
import { CircleCommissionLog, LogType } from "../../proto/CircleCommissionLog";
import { DefaultEmptyView } from "../../components/LoadStateView";
import { VStack } from "../../components/VStack";
import React, { useMemo } from "react";
import { UnderLineText } from "./card/MyCardPage";

function AdminEarningsPage() {
  const i18n = useI18n();
  const circleId = useBigIntParam("circleId");
  const hopper = useHopper();
  const backend = useBackend();

  const circleInfo = useSWR(backend.getCircle(circleId));

  const tokenCreated = useMemo(() => {
    return (
      circleInfo.content?.tokenProjectId &&
      circleInfo.content.tokenProjectId > BigInt(0)
    );
  }, [circleInfo]);

  const adminEarningSWR = useSWR(
    tokenCreated ? backend.getAdminEarningInfo(circleId) : undefined,
  );
  const earningSWR = useSWRArray(
    tokenCreated ? backend.getAdminEarningList(circleId) : undefined,
  );
  const onTransfer = () => {
    hopper.push(`circle/${circleId}/transfer-earning`);
  };

  return (
    <Page
      pageData={
        tokenCreated ? [circleInfo, adminEarningSWR, earningSWR] : [circleInfo]
      }
    >
      <NavMiddle>{i18n.clover_admin_earnings()}</NavMiddle>
      {tokenCreated ? (
        <>
          <Title>{i18n.clover_as_admin_receive_earnings()}</Title>
          <AmountFrame>
            <Image
              src={getCurrencyIcon(adminEarningSWR?.content?.currency)}
              style={{ width: 34, height: 34, borderRadius: 4 }}
            />
            {`${formatMoney("medium", adminEarningSWR?.content?.currency) || 0} ${adminEarningSWR?.content?.currency?.symbol ?? ""}`}
          </AmountFrame>
          <Total>{i18n.jul23_impr2_total()}</Total>
          <RegularButton
            style={{
              margin: "32px 13px 0",
            }}
            onClick={onTransfer}
            $baseColor={ButtonColor.greenish}
          >
            {i18n.transfer()}
          </RegularButton>
          <ListTitle>{i18n.clover_admin_earnings_history()}</ListTitle>
          {earningSWR && <EarningHistoryList history={earningSWR?.content} />}
        </>
      ) : (
        <VStack>
          <DefaultEmptyView
            topSpace={100}
            bottomSpace={24}
            title={i18n.clover_admin_earning_empty()}
          />
          <UnderLineText
            onClick={() => {
              hopper.push(`circle/${circleId}/admin-portal`);
            }}
          >
            {i18n.clover_go_to_admin_portal()}
          </UnderLineText>
        </VStack>
      )}
    </Page>
  );
}

export function EarningHistoryList(props: { history: CircleCommissionLog[] }) {
  return (
    <>
      {props.history.map((log, index, list) => (
        <>
          {index === 0 ||
          !isSameYearAndMonth(
            list?.[index - 1].createdTime * 1000,
            log.createdTime * 1000,
          ) ? (
            <TimeSectionTitle>
              {formatDateWithOptions(log.createdTime || 0, {
                year: "numeric",
                month: "short",
              })}
            </TimeSectionTitle>
          ) : null}

          <HistoryCell
            $showLine={
              !(
                index === 0 ||
                !isSameYearAndMonth(
                  list?.[index - 1].createdTime * 1000,
                  log.createdTime * 1000,
                )
              )
            }
          >
            <HistoryAmount>{`${log.logType === LogType.Commission ? "+" : "-"} ${formatMoney("medium", log.rewardCurrency)} ${log.rewardCurrency.symbol}`}</HistoryAmount>
            <HistoryDate>
              {formatDateWithOptions(log.createdTime || 0, {
                month: "short",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })}
            </HistoryDate>
          </HistoryCell>
        </>
      ))}
    </>
  );
}

const TimeSectionTitle = styled.div`
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  padding: 9.5px 20px;
  background: rgba(255, 255, 255, 0.08);
  margin: 0 var(--page-h-inset-neg);
`;

const HistoryCell = styled.div<{ $showLine: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 12px 0;
  border-top: ${(p) =>
    p.$showLine ? "1px solid rgba(255, 255, 255, 0.12)" : "none"};
`;

const HistoryAmount = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
`;

const HistoryDate = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.4);
`;

const ListTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  margin: 32px 0 13px 0;
  color: rgba(255, 255, 255, 0.8);
`;
const Total = styled.div`
  margin: 4px auto 0;
  font-size: 12px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
`;

const Title = styled.div`
  color: rgba(255, 255, 255, 0.4);
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  margin: 10px auto 0;
`;

const AmountFrame = styled.div`
  display: flex;
  flex-direction: row;
  margin: 18px auto 0;
  font-weight: 500;
  font-size: 28px;
  color: #ffffff;
  gap: 10px;
`;

export default AdminEarningsPage;
