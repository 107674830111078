import { Hopper } from "../../hooks/useHopper";
import { isLiteApp } from "../../bridge/ExternalAppShellData";
import { ModalController } from "../../components/Modal";

export function dicClickTopUp(hopper: Hopper, modal?: ModalController) {
  if (!!modal) {
    modal?.open();
    return;
  }
  if (isLiteApp()) {
    hopper.layer(`wallet/top-up`);
  } else {
    hopper.layer(`wallet/top-up-sol`);
  }
}
