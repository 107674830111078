import { I18n, useI18n } from "../../hooks/useI18n";
import React from "react";
import { AlertGroup } from "../../proto/Alert";
import { AlertGroupListPage } from "./AlertGroupPage";
import { GlobalAnnouncementsPage } from "../post/GlobalAnnouncementsPage";
import {
  ViewPagerPage,
  ViewPagerTabLayout,
} from "../../components/ViewPagerPage";
import { subpages } from "../../components/Subpage";
import { FilterTab } from "../../components/FilterTab";
import styled from "styled-components";
import { FlexFlowStyles } from "../../components/TagsFlowLayout";
import { hPaddingWithPageInset } from "../../components/CommonStyles";
import { NavMiddle } from "../../components/NavBar";
import { usePageParent } from "../../components/PageCompositionBoundary";
import { usePageSpec } from "../common/usePageSpec";
import { useSWR } from "../../hooks/swr/useSWR";
import { useBackend } from "../../service/APIService";

export function AlertCenterPage() {
  const i18n = useI18n();
  const backend = useBackend();
  const alertCounts = useSWR(backend.getAlertCounts());

  const entries: [AlertGroup, string][] = [
    [
      AlertGroup.GlobalAnnouncement,
      getTabTitle(i18n, AlertGroup.GlobalAnnouncement),
    ],
    [AlertGroup.Activity, getTabTitle(i18n, AlertGroup.Activity)],
    [AlertGroup.Follow, getTabTitle(i18n, AlertGroup.Follow)],
    [AlertGroup.Like, getTabTitle(i18n, AlertGroup.Like)],
    [AlertGroup.Post, getTabTitle(i18n, AlertGroup.Post)],
    [AlertGroup.Circle, getTabTitle(i18n, AlertGroup.Circle)],
  ];

  const [currentAlertGroup, setCurrentAlertGroup] = React.useState<AlertGroup>(
    AlertGroup.GlobalAnnouncement,
  );
  // const pageParent = usePageParent();
  const pageSpec = usePageSpec();
  return (
    <ViewPagerPage
      initialPageId={currentAlertGroup}
      subpages={subpages(entries, (e) => {
        switch (e) {
          case AlertGroup.GlobalAnnouncement:
            return (
              <GlobalAnnouncementsPage
                alertCounts={alertCounts.content}
                refreshAlertCounts={alertCounts.load}
              />
            );
          default:
            return (
              <AlertGroupListPage
                type={e}
                alertCounts={alertCounts.content}
                refreshAlertCounts={alertCounts.load}
              />
            );
        }
      })}
    >
      <NavMiddle>{i18n.alerts()}</NavMiddle>
      {/*<TitleFlowLayout style={{ ...hPaddingWithPageInset }}>*/}
      {/*  {[*/}
      {/*    AlertGroup.GlobalAnnouncement,*/}
      {/*    AlertGroup.Activity,*/}
      {/*    AlertGroup.Follow,*/}
      {/*    AlertGroup.Like,*/}
      {/*    AlertGroup.Post,*/}
      {/*    AlertGroup.Circle,*/}
      {/*  ].map((item, _) => (*/}
      {/*    <FilterTab*/}
      {/*      onClick={() => {*/}
      {/*        setCurrentAlertGroup(item);*/}
      {/*        // pageParent.setPrimarySubpageWithId(item, "smooth");*/}
      {/*      }}*/}
      {/*      selected={item === currentAlertGroup}*/}
      {/*    >*/}
      {/*      {getTabTitle(i18n, item)}*/}
      {/*    </FilterTab>*/}
      {/*  ))}*/}
      {/*</TitleFlowLayout>*/}
      <ViewPagerTabLayout></ViewPagerTabLayout>
    </ViewPagerPage>
  );
}

const getTabTitle = (i18n: I18n, page: AlertGroup): string => {
  switch (page) {
    case AlertGroup.Activity:
      return i18n.comments();
    case AlertGroup.Follow:
      return i18n.followers();
    case AlertGroup.Like:
      return i18n.reactions();
    case AlertGroup.Post:
      return i18n.posts();
    case AlertGroup.Circle:
      return i18n.circles();
    case AlertGroup.GlobalAnnouncement:
      return i18n.chatroom_info_title_announcements();
    default:
      return "-";
  }
};

const TitleFlowLayout = styled.div`
  ${FlexFlowStyles.tagsContainer}
`;
