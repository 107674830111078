import {
  TransactionAmountType,
  TransactionLog,
  TransactionReason,
} from "../../../proto/TransactionLog";
import { I18n } from "../../../hooks/useI18n";
import {
  CurrencyType,
  formatMoney,
  getCurrencyName,
  getCurrencyNameByCurrencyType,
} from "../../../proto/Currency";
import { displayTitle } from "../../../proto/Chat";
import { TransferOrderType } from "../../../proto/TransferOrder";
import { getUserTaskNameByType } from "./UserTask";
import iconTopUp from "../../../res/images/ic_top_up.png";
import iconCancel from "../../../res/images/ic_transaction_cancel.svg";
import iconServiceFee from "../../../res/images/ic_transaction_service_fee.png";
import iconSale from "../../../res/images/ic_transaction_sale.svg";
import iconSend from "../../../res/images/ic_send.png";
import iconSendGift from "../../../res/images/ic_transaction_send_gift.png";
import iconMenuGift from "../../../res/images/ic_transaction_receive_gift.png";
import iconSwap from "../../../res/images/ic_convert.png";
import iconNFT from "../../../res/images/ic_transaction_nft.png";
import iconProtoNFT from "../../../res/images/ic_proto_nft.png";
import iconCreatorFee from "../../../res/images/ic_transaction_creator_fee.svg";
import iconRedeem from "../../../res/images/ic_redeem.png";
import iconReceive from "../../../res/images/ic_receive.png";
import iconBuy from "../../../res/images/ic_transaction_buy.png";
import iconExpense from "../../../res/images/ic_transaction_expense.png";
import iconTransfer from "../../../res/images/ic_transaction_transfer.png";
import iconStake from "../../../res/images/ic_stake.png";
import { CouponType } from "../../../proto/Coupon";
import { TokenTransactionType } from "../../../proto/DexSwapInfo";
import { AssetOrderType, TxCategory } from "../../../proto/AssetOrder";
import icTrade from "../../../res/images/ic_trade.svg";

export function transactionTitle(transactionLog: TransactionLog, i18n: I18n) {
  let name: string | undefined;
  if (transactionLog.currency?.isCash === true) {
    name = i18n.cash_rewards_cash();
  } else {
    name = getCurrencyName(transactionLog?.currency);
  }

  switch (transactionLog.txReason) {
    case TransactionReason.UsePrimeMembershipCoupon:
      const title = transactionLog.coupon?.title || "";
      return i18n.web3_v0_transaction_type_buy(title);
    case TransactionReason.InAppPurchase:
    case TransactionReason.ThirdPartyPurchase:
      return i18n.web3_hongbao_transaction_type_top_up(
        getCurrencyNameByCurrencyType(transactionLog.currencyType) || "",
      );
    case TransactionReason.TxReasonWithdrawServiceFee:
    case TransactionReason.TxReasonStoreOrderServiceFee:
    case TransactionReason.TransferOrderServiceFee:
    case TransactionReason.GiftOrderServiceFee:
    case TransactionReason.CashTransferOutServiceFee:
    case TransactionReason.GasFee:
      return i18n.web3_v0_service_fee();
    case TransactionReason.ReturnTransferOrderServiceFee:
      return i18n.gift_tax_returned_service_fee();
    case TransactionReason.PayForOrder:
      const nftName =
        transactionLog.storeOrder?.orderItemList?.[0]?.title || "";
      switch (transactionLog.txAmountType) {
        case TransactionAmountType.ADD:
          return i18n.web3_v0_transaction_type_sale(nftName);
        case TransactionAmountType.MINUS:
          return i18n.web3_v0_transaction_type_buy(nftName);
        default:
          return "";
      }
    case TransactionReason.UseCouponPayForOrder:
      const nftName1 =
        transactionLog.storeOrder?.orderItemList?.[0]?.title || "";
      return i18n.web3_v0_transaction_type_buy(nftName1);
    case TransactionReason.CreateTransferOrder:
      let giftName;
      if (transactionLog.currency?.currencyType === CurrencyType.NFT) {
        giftName = transactionLog.transferOrder?.nft?.name || "";
      } else if (
        transactionLog.transferOrder?.fromUser?.uid &&
        transactionLog.transferOrder?.fromUser?.uid < 1000
      ) {
        giftName = "";
      } else {
        giftName = getCurrencyName(transactionLog.currency, i18n);
      }
      let toUserName;
      if (
        transactionLog.transferOrder?.giftBox?.toObjectId === BigInt(0) &&
        transactionLog.transferOrder?.giftBox?.toObjectType === 0
      ) {
        toUserName = i18n.gift_more_external_platform();
      } else {
        if (
          transactionLog.transferOrder?.giftBox?.toObjectType === 1 &&
          transactionLog.transferOrder.giftBox.chatThread
        ) {
          toUserName = displayTitle(
            transactionLog.transferOrder.giftBox.chatThread,
          );
        } else {
          toUserName = transactionLog.transferOrder?.toUser?.nickname;
        }
      }
      return i18n.web3_v0_transaction_type_gift_to(
        giftName || "",
        toUserName || "",
      );
    case TransactionReason.NftBought:
      const giftName1 =
        transactionLog.storeOrder?.orderItemList?.[0]?.title || "";
      switch (transactionLog.txAmountType) {
        case TransactionAmountType.ADD:
          return i18n.web3_v0_transaction_type_bought(giftName1);
        case TransactionAmountType.MINUS:
          return i18n.web3_v0_transaction_type_sale(giftName1);
        default:
          return "";
      }
    case TransactionReason.ClaimTransferOrder:
      const isFromOfficial =
        !!transactionLog.transferOrder?.fromUser?.uid &&
        transactionLog.transferOrder?.fromUser?.uid < 1000;
      let senderName;

      if (
        transactionLog.transferOrder?.giftBox?.toObjectType === 1 &&
        transactionLog.transferOrder.giftBox.chatThread
      ) {
        //chat
        senderName = displayTitle(
          transactionLog.transferOrder.giftBox.chatThread,
        );
      } else {
        if (isFromOfficial) {
          switch (transactionLog.transferOrder?.orderType) {
            case TransferOrderType.AirdropCheckInRewards:
              senderName = i18n.web3_checkin_check_in_rewards();
              break;
            case TransferOrderType.UserTaskRewardV2:
            case TransferOrderType.NewUserTask:
              const userTaskType =
                transactionLog.transferOrder.extensions?.userTaskType;
              if (userTaskType) {
                senderName = getUserTaskNameByType(userTaskType, true, i18n);
              } else {
                senderName = i18n.newbie_task_rewards();
              }
              break;
            default:
              senderName = i18n.team_z();
          }
        } else {
          senderName = transactionLog.transferOrder?.fromUser?.nickname || "";
        }
      }
      if (isFromOfficial) {
        return i18n.newbie_task_gift_from(senderName);
      } else {
        const giftName =
          transactionLog.currency?.currencyType === CurrencyType.NFT
            ? transactionLog.transferOrder?.nft?.name || ""
            : getCurrencyName(transactionLog.currency, i18n);
        return i18n.web3_v0_transaction_type_gift_from(
          giftName || "",
          senderName,
        );
      }
    case TransactionReason.ReturnTransferOrder:
      const senderName1 =
        transactionLog.transferOrder?.giftBox?.toObjectType === 1 &&
        transactionLog.transferOrder.giftBox.chatThread
          ? displayTitle(transactionLog.transferOrder.giftBox.chatThread)
          : transactionLog.transferOrder?.fromUser?.nickname || "";
      const giftName2 =
        transactionLog.currency?.currencyType === CurrencyType.NFT
          ? transactionLog.transferOrder?.nft?.name || ""
          : getCurrencyName(transactionLog.currency, i18n);
      return i18n.tx_impr_gift_return_from(giftName2 || "", senderName1);
    case TransactionReason.TopUp:
      return i18n.web3_v0_transaction_type_top_up(name || "");
    case TransactionReason.MintSpeedUpFee:
      return i18n.zed_eco_chain_expedited_time();
    case TransactionReason.WithDraw:
      return i18n.web3_v0_transaction_type_withdraw(name || "");
    case TransactionReason.SwapFrom:
      return i18n.web3_v0_transaction_type_swap_from(name || "");
    case TransactionReason.SwapTo:
      return i18n.web3_v0_transaction_type_swap_to(name || "");
    case TransactionReason.WithDrawStore:
      switch (transactionLog.txAmountType) {
        case TransactionAmountType.ADD:
          const dest = i18n.web3_v0_store_account();
          return i18n.web3_v0_transaction_type_transfer_from(dest);
        case TransactionAmountType.MINUS:
          const dest1 = i18n.web3_v0_default_account();
          return i18n.web3_v0_transaction_type_transfer_to(dest1);
        default:
          return "";
      }
    case TransactionReason.InjectForCreatingNFT:
      const nftName2 = transactionLog.nft?.name || "";
      return i18n.web3_ugc_proto_nft_created_with_name(nftName2);
    case TransactionReason.InjectForUserMintNFT:
    case TransactionReason.ZECMintNFT:
      const nftName3 = transactionLog.nft?.name || "";
      return i18n.web3_ugc_nft_minted_with_name(nftName3);
    case TransactionReason.TxReasonReturn:
      return i18n.web3_v0_transaction_type_transaction_cancel(name || "");
    case TransactionReason.TxReasonNFTCreatorFee:
      return `${i18n.web3_royalty_creator_fee()}: ${name || ""}`;
    case TransactionReason.Redeem:
      const shopAccount = i18n.web3_v0_store_account();
      return i18n.web3_redeem_redeem_from(shopAccount);
    case TransactionReason.CashTransferOut:
      const cash = i18n.cash_rewards_cash();
      return i18n.web3_v0_transaction_type_withdraw(cash);
    case TransactionReason.CashSwapFrom:
      const cash1 = i18n.cash_rewards_cash();
      return i18n.web3_v0_transaction_type_swap_from(cash1);
    case TransactionReason.CashTransferOutServiceFeeReturn:
      const serviceFee = i18n.web3_v0_service_fee();
      return i18n.web3_v0_transaction_type_transaction_cancel(serviceFee);
    case TransactionReason.TransferChainAsset: {
      if (transactionLog.txAmountType === TransactionAmountType.ADD) {
        return i18n.clover_receive_asset(name ?? "");
      } else {
        return i18n.clover_send_asset(name ?? "");
      }
    }
    case TransactionReason.TRADE_FROM:
    case TransactionReason.TRADE_TO: {
      return i18n.clover_trade();
    }
    case TransactionReason.SendAdminMembershipCard: {
      return i18n.clover_receive_membership_card(
        transactionLog.assetOrder?.nft?.name || "",
      );
    }

    case TransactionReason.GET_MEMBERSHIP_CARD: {
      if (
        transactionLog.assetOrder?.orderType ===
        AssetOrderType.GetMembershipCard
      ) {
        if (
          transactionLog.assetOrder?.currencyType === CurrencyType.SOL_TOKENS
        ) {
          // get membership card by staking
          return i18n?.clover_receive_membership_card(
            transactionLog.assetOrder.nft?.name || "",
          );
        } else {
          // get membership card by purchasing
          if (transactionLog.currencyType === CurrencyType.NFT) {
            // card item
            return i18n?.web3_v0_transaction_type_bought(
              transactionLog.assetOrder.nft?.name || "",
            );
          } else {
            // fee item
            return i18n?.web3_v0_transaction_type_buy(
              transactionLog.assetOrder.nft?.name || "",
            );
          }
        }
      } else {
        return i18n?.web3_v0_unknown_transcation();
      }
    }
    case TransactionReason.TOKEN_CREATION_FEE: {
      return i18n.clover_token_creation_fee();
    }
    case TransactionReason.UserBuyAIImageQuotaPackage: {
      return i18n?.web3_v0_transaction_type_buy(
        transactionLog.assetOrder?.purchaseQuotaPackageInfo?.packageInfo
          ?.title || "",
      );
    }

    case TransactionReason.REDEEM_MEMBERSHIP_CARD: {
      if (transactionLog.currencyType === CurrencyType.SOL_TOKENS) {
        return i18n?.clover_tokens_received_from_redemption();
      } else {
        return i18n?.clover_redeem(transactionLog?.assetOrder?.nft?.name || "");
      }
    }

    case TransactionReason.StakeMembershipCard: {
      return i18n?.clover_stake_token(transactionLog?.currency?.symbol || "");
    }
    case TransactionReason.TransferAdminCommission: {
      return i18n?.clover_transfer_admin_earnings();
    }
    default:
      return i18n.web3_v0_unknown_transcation();
  }
}

export function getTransactionIcon(transactionLog: TransactionLog) {
  const transactionReason = transactionLog.txReason;
  switch (transactionReason) {
    case TransactionReason.InAppPurchase:
    case TransactionReason.ThirdPartyPurchase:
    case TransactionReason.TopUp:
      return iconTopUp;
    case TransactionReason.TxReasonReturn:
    case TransactionReason.CashTransferOutServiceFeeReturn:
      return iconCancel;
    case TransactionReason.TxReasonWithdrawServiceFee:
    case TransactionReason.TxReasonStoreOrderServiceFee:
    case TransactionReason.ReturnTransferOrderServiceFee:
    case TransactionReason.TransferOrderServiceFee:
    case TransactionReason.GiftOrderServiceFee:
    case TransactionReason.CashTransferOutServiceFee:
    case TransactionReason.GasFee:
    case TransactionReason.TOKEN_CREATION_FEE:
      return iconServiceFee;
    case TransactionReason.PayForOrder:
    case TransactionReason.UseCouponPayForOrder: {
      if (transactionLog.txAmountType === TransactionAmountType.ADD) {
        return iconSale;
      } else {
        return iconSend;
      }
    }
    case TransactionReason.NftBought: {
      if (transactionLog.txAmountType == TransactionAmountType.ADD) {
        return iconSend;
      } else {
        return iconSale;
      }
    }
    case TransactionReason.CreateTransferOrder:
      return iconSendGift;
    case TransactionReason.ClaimTransferOrder:
    case TransactionReason.ReturnTransferOrder:
      return iconMenuGift;
    case TransactionReason.WithDraw:
    case TransactionReason.TxReasonUserBuyAIChatQuotaPackage:
    case TransactionReason.TxReasonUseCouponPayForAIImage:
    case TransactionReason.UsePrimeMembershipCoupon:
    case TransactionReason.TxReasonUseCouponPayForAIChat:
    case TransactionReason.CashTransferOut:
      return iconSend;
    case TransactionReason.SwapFrom:
    case TransactionReason.SwapTo:
    case TransactionReason.CashSwapFrom:
      return iconSwap;
    case TransactionReason.MintNFT:
    case TransactionReason.MintSpeedUpFee:
    case TransactionReason.UserMintNFTServiceFee:
    case TransactionReason.InjectForUserMintNFT:
    case TransactionReason.ZECMintNFT:
      return iconNFT;
    case TransactionReason.UseMintCoupon: {
      if (
        transactionLog.coupon?.couponType === CouponType.ForAIChatQuota ||
        transactionLog.coupon?.couponType === CouponType.ForAIGCQuota
      ) {
        return iconSend;
      } else {
        return iconNFT;
      }
    }
    case TransactionReason.InjectForCreatingNFT:
      return iconProtoNFT;
    case TransactionReason.TxReasonNFTCreatorFee:
      return iconCreatorFee;
    case TransactionReason.Redeem:
      return iconRedeem;
    case TransactionReason.TransferChainAsset: {
      if (transactionLog.txAmountType === TransactionAmountType.ADD) {
        return iconTopUp;
      } else {
        return iconSend;
      }
    }
    case TransactionReason.TRADE_FROM:
    case TransactionReason.TRADE_TO:
      return icTrade;
    case TransactionReason.REDEEM_MEMBERSHIP_CARD:
      return iconRedeem;
    case TransactionReason.TransferAdminCommission:
      return iconTransfer;
    case TransactionReason.UserBuyAIImageQuotaPackage:
      return iconExpense;
    case TransactionReason.StakeMembershipCard:
      return iconStake;
    case TransactionReason.SendAdminMembershipCard:
      return iconReceive;
    case TransactionReason.GET_MEMBERSHIP_CARD: {
      if (
        transactionLog.assetOrder?.orderType ===
        AssetOrderType.GetMembershipCard
      ) {
        if (
          transactionLog.assetOrder?.currencyType === CurrencyType.SOL_TOKENS
        ) {
          // get membership card by staking
          return iconReceive;
        } else {
          // get membership card by purchasing
          if (transactionLog.currencyType === CurrencyType.NFT) {
            // card item
            return iconBuy;
          } else {
            // fee item
            return iconExpense;
          }
        }
      }
    }
  }
}

export function getTransactionActivity(
  transactionLog?: TransactionLog,
  i18n?: I18n,
) {
  switch (transactionLog?.txReason) {
    case TransactionReason.UserBuyAIImageQuotaPackage:
    case TransactionReason.TxReasonUserBuyAIChatQuotaPackage:
    case TransactionReason.TxReasonUseCouponPayForAIImage:
    case TransactionReason.UsePrimeMembershipCoupon:
    case TransactionReason.TxReasonUseCouponPayForAIChat:
      return i18n?.tx_impr_expense();
    case TransactionReason.InAppPurchase:
    case TransactionReason.ThirdPartyPurchase:
      return i18n?.web3_hongbao_top_up();
    case TransactionReason.TxReasonWithdrawServiceFee:
    case TransactionReason.TxReasonStoreOrderServiceFee:
    case TransactionReason.TransferOrderServiceFee:
    case TransactionReason.ReturnTransferOrderServiceFee:
    case TransactionReason.GiftOrderServiceFee:
    case TransactionReason.CashTransferOutServiceFee:
    case TransactionReason.GasFee: {
      return i18n?.web3_v0_service_fee();
    }
    case TransactionReason.PayForOrder:
    case TransactionReason.UseCouponPayForOrder: {
      switch (transactionLog.txAmountType) {
        case TransactionAmountType.ADD: {
          return i18n?.web3_v0_sale();
        }
        default: {
          return i18n?.tx_impr_expense();
        }
      }
    }
    case TransactionReason.NftBought: {
      switch (transactionLog.txAmountType) {
        case TransactionAmountType.ADD: {
          return i18n?.web3_v0_buy();
        }
        default: {
          return i18n?.web3_v0_sale();
        }
      }
    }
    case TransactionReason.CreateTransferOrder: {
      return i18n?.web3_v0_gift();
    }
    case TransactionReason.ClaimTransferOrder: {
      return i18n?.web3_v0_gift_received();
    }
    case TransactionReason.ReturnTransferOrder: {
      return i18n?.web3_v0_gift_returned();
    }
    case TransactionReason.TopUp: {
      return i18n?.web3_v0_top_up();
    }
    case TransactionReason.WithDraw:
    case TransactionReason.CashTransferOut: {
      return i18n?.web3_v0_withdraw();
    }
    case TransactionReason.SwapFrom:
    case TransactionReason.SwapTo:
    case TransactionReason.CashSwapFrom: {
      return i18n?.web3_v0_swap();
    }
    case TransactionReason.WithDrawStore: {
      return i18n?.transfer();
    }
    case TransactionReason.MintNFT:
    case TransactionReason.UserMintNFTServiceFee:
    case TransactionReason.MintSpeedUpFee:
    case TransactionReason.InjectForUserMintNFT:
    case TransactionReason.ZECMintNFT: {
      return i18n?.web3_ugc_mint();
    }
    case TransactionReason.UseMintCoupon: {
      if (
        transactionLog.coupon?.couponType == CouponType.ForAIChatQuota ||
        transactionLog.coupon?.couponType == CouponType.ForAIGCQuota
      ) {
        return i18n?.tx_impr_expense();
      } else {
        return i18n?.web3_ugc_mint();
      }
    }
    case TransactionReason.InjectForCreatingNFT: {
      return i18n?.create();
    }
    case TransactionReason.TxReasonReturn: {
      return i18n?.cancel();
    }
    case TransactionReason.TxReasonNFTCreatorFee: {
      return i18n?.web3_royalty_creator_fee();
    }
    case TransactionReason.Redeem:
      return i18n?.web3_redeem_redeem();

    case TransactionReason.TransferChainAsset: {
      if (transactionLog.txAmountType === TransactionAmountType.ADD) {
        return i18n?.clover_receive();
      } else {
        return i18n?.send();
      }
    }

    case TransactionReason.TRADE_FROM:
    case TransactionReason.TRADE_TO:
      return i18n?.clover_trade();
    case TransactionReason.TOKEN_CREATION_FEE:
      return i18n?.web3_v0_service_fee();
    case TransactionReason.SendAdminMembershipCard:
      return i18n?.clover_receive();
    case TransactionReason.GET_MEMBERSHIP_CARD: {
      if (
        transactionLog.assetOrder?.orderType ===
        AssetOrderType.GetMembershipCard
      ) {
        if (
          transactionLog.assetOrder?.currencyType === CurrencyType.SOL_TOKENS
        ) {
          // get membership card by staking
          return i18n?.clover_receive();
        } else {
          // get membership card by purchasing
          if (transactionLog.currencyType === CurrencyType.SOL) {
            // fee item
            return i18n?.tx_impr_expense();
          } else {
            // card item
            return i18n?.web3_v0_buy();
          }
        }
      } else {
        return i18n?.web3_v0_unknown();
      }
    }
    case TransactionReason.REDEEM_MEMBERSHIP_CARD: {
      return i18n?.web3_redeem_redeem();
    }
    case TransactionReason.StakeMembershipCard: {
      return i18n?.clover_button_stake();
    }
    case TransactionReason.TransferAdminCommission: {
      return i18n?.transfer();
    }
    default: {
      return i18n?.web3_v0_unknown_transcation();
    }
  }
}

export enum TransactionStatus {
  PENDING = 1,
  SUBMITTED = 2,
  DONE = 3,
  FAILED = 4,
}

export enum TransactionQueryType {
  TxQueryTypeTopUp = 1,
  TxQueryTypeWithDraw = 2,
  TxQueryTypePurchase = 3,
  TxQueryTypeSwap = 4,
  TxQueryTypeGift = 5,
  TxQueryTypeBuy = 6,
  TxQueryTypeStoreTransfer = 7,
  TxQueryTypeCancel = 8,
  TxQueryTypeStoreAccountSale = 9,
  TxQueryTypeServiceFee = 10,
  TxQueryTypeDefaultAccountSale = 11,
  TxQueryTypeNFTMint = 12,
  TxQueryTypeNFTCreate = 13,
  TxQueryTypeNFTCreatorFee = 14,
  TxQueryTypeRedeem = 15,
  TxQueryTypeCashTransferOut = 16,
  TxQueryTypeSendTokens = 17,
  TxQueryTypeTransferNFTs = 18,
  TxQueryTypeReceive = 19,
  TxQueryTypeGasFee = 20,
  TxQueryTypeRewards = 21,
  TxQueryTypeConvert = 22,
  TxQueryTypeTrade = 23,
  TxQueryTypeStake = 24,
  TxQueryTypeNFTMembershipCard = 25,
}
