import { Page } from "../../components/Page";
import styled from "styled-components";
import { useI18n } from "../../hooks/useI18n";
import iconClover from "../../res/images/logo_clover.svg";

export function OfficialUserPage() {
  const i18n = useI18n();
  return (
    <Page pageData={undefined}>
      <UserImg src={iconClover} />
      <UserName>{i18n.team_z()}</UserName>
    </Page>
  );
}

const UserImg = styled.img`
  width: 100px;
  height: 100px;
  margin-top: 100px;
  align-self: center;
`;

const UserName = styled.div`
  font-weight: 600;
  font-size: 20px;
  color: white;
  margin-top: 20px;
  align-self: center;
`;
