import React from "react";
import { Page } from "../../../components/Page";
import {
  useEnumSearchParam,
  useStringSearchParam,
} from "../../../hooks/useTypedParam";
import styled from "styled-components";
import { AuthType, SecurityPurpose } from "../../../proto/Auth";
import { useI18n } from "../../../hooks/useI18n";
import { RegularButton } from "../../../components/Buttons";
import { useHopper } from "../../../hooks/useHopper";
import emailIcon from "../../../res/images/ic_line_email.svg";
import phoneIcon from "../../../res/images/ic_line_phone.svg";
import { Image } from "../../../components/Image";
import { NavMiddle } from "../../../components/NavBar";
import { usePageSpec } from "../../common/usePageSpec";

export enum AssociateType {
  Add = 1,
  Change = 2,
}

export function AssociateEmailOrPhonePage() {
  const email = useStringSearchParam("email");
  const phoneNumber = useStringSearchParam("phoneNumber");
  const authType = useEnumSearchParam<AuthType>("authType");
  const i18n = useI18n();
  const hopper = useHopper();
  const contentText =
    authType === AuthType.Email
      ? email || i18n.no_email()
      : phoneNumber || i18n.no_phone_number();
  const buttonText =
    authType === AuthType.Email
      ? email
        ? i18n.change_email()
        : i18n.add_email()
      : phoneNumber
        ? i18n.change_phone_number()
        : i18n.add_phone_number();

  const pushVerificationCodePage = () => {
    hopper.push("verification-code", {
      preEmail: email,
      prePhoneNumber: phoneNumber,
      purpose:
        authType === AuthType.Email
          ? SecurityPurpose.UpdateEmail
          : SecurityPurpose.UpdatePhoneNumber,
      authType: authType,
      associateType: AssociateType.Change,
    });
  };

  const onButtonClick = () => {
    switch (authType) {
      case AuthType.Email:
        if (email) {
          pushVerificationCodePage();
        } else {
          hopper.push("update-email-confirm", {
            authType: authType,
            purpose: SecurityPurpose.UpdateEmail,
            associateType: AssociateType.Add,
          });
        }
        break;
      case AuthType.Phone:
        if (phoneNumber) {
          pushVerificationCodePage();
        } else {
          hopper.push("update-phone-number-confirm", {
            authType: authType,
            purpose: SecurityPurpose.UpdatePhoneNumber,
            associateType: AssociateType.Add,
          });
        }
        break;
      default:
        break;
    }
  };

  const pageSpec = usePageSpec();
  return (
    <Page pageData={undefined}>
      <NavMiddle>
        {authType === AuthType.Email ? i18n.email() : i18n.phone()}
      </NavMiddle>
      <Image
        src={authType === AuthType.Email ? emailIcon : phoneIcon}
        alt={"icon"}
        style={{
          width: "84px",
          height: "84px",
          marginTop: pageSpec === "wide" ? "70px" : "95px",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "24px",
        }}
      ></Image>
      <ContentLabel>{contentText}</ContentLabel>
      <RegularButton
        style={{ marginRight: 50, marginLeft: 50, marginTop: 40 }}
        onClick={onButtonClick}
      >
        {buttonText}
      </RegularButton>
    </Page>
  );
}

const ContentLabel = styled.div`
  color: var(--color-text10);
  font-size: 14px;
  font-weight: 500;
  text-align: center;
`;
