import styled, { RuleSet } from "styled-components";
import { VStackMixin } from "./VStack";

export const ContentGroup = styled.div<{
  omitTopPadding?: boolean;
  omitBottomPadding?: boolean;
  mixin?: RuleSet<Object>;
}>`
  ${VStackMixin};
  align-items: stretch;
  border-radius: 12px;
  border: 0.5px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.06);
  padding-left: var(--group-inset);
  padding-right: var(--group-inset);
  padding-top: ${(p) =>
    p.omitTopPadding === true ? "0" : "var(--group-inset)"};
  padding-bottom: ${(p) =>
    p.omitBottomPadding === true ? "0" : "var(--group-inset)"};
  box-sizing: border-box;
  overflow: clip;
  flex-shrink: 0;
  ${(p) => p.mixin}
`;

export const GroupCellDivider = styled.div`
  height: 0.5px;
  background-color: var(--color-group-cell-divider);
  flex-shrink: 0;
`;

export const CellDivider = styled.div`
  flex-shrink: 0;
  height: 0.5px;
  background-color: var(--color-cell-divider);
`;

export const CellDividerWithMargin = styled.div`
  flex-shrink: 0;
  height: 0.5px;
  margin-inline-start: var(--page-h-inset);
  margin-inline-end: var(--page-h-inset);
  background-color: var(--color-cell-divider);
`;
