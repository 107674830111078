import styled from "styled-components";
import {
  getBestRes,
  isAudio,
  isImage,
  isVideo,
  Media,
} from "../../proto/Media";
import { AspectRatioImage, Image } from "../../components/Image";
import icAudio from "../../res/images/ic_audio.svg";
import { HStack, VStack } from "../../components/VStack";
import icVideoPlay from "../../res/images/ic_video_play.svg";
import React, { useMemo, useRef, useState } from "react";
import { andLog } from "../../components/handleError";
import staticWave from "../../res/images/audio_wave_static.png";
import animatedWave from "../../res/images/voice_wave_white.webp";
import { useNativePage } from "../../hooks/useBridge";
import { useNSFW } from "../../hooks/useNSFW";
import { useSWR } from "../../hooks/swr/useSWR";
import { useBackend } from "../../service/APIService";
import { useMyUid } from "../../service/AuthSessionService";
import { RichFormat } from "../../components/zdoc/RichFormat";
import { ObjectType } from "../../proto/ObjectSpec";
import { FlagMediaLocationEnum } from "../../proto/Flag";
import { MediaOwner } from "../../proto/MediaOwner";

export function PostMediaContainer(props: {
  mediaList?: Media[];
  richFormat?: RichFormat;
  uid?: bigint;
  objectId: bigint;
}) {
  const orderedMediaList = useMemo(() => {
    if (props.richFormat) {
      return props.richFormat.attachmentSpans
        ?.filter((span) => span.data.type === "media")
        .map((span) =>
          props.mediaList?.find(
            (media) => media.mediaId === span.data.media?.mediaRefId,
          ),
        )
        .filter((media): media is Media => media !== undefined);
    } else {
      return props.mediaList;
    }
  }, [props.mediaList]);

  const nativePage = useNativePage();

  const viewMedia = () => {
    if (orderedMediaList) {
      const mediaOwnerMap = new Map();
      orderedMediaList?.forEach((media) => {
        const ownerSpec = {
          objectId: props.objectId,
          objectType: ObjectType.BLOG,
        };
        const flagExt = {
          flagMedia: media,
          flagMediaLocation: FlagMediaLocationEnum.CONTENT_IMAGE,
        };

        const mediaOwner: MediaOwner = {
          ownerSpec: ownerSpec,
          authorId: props.uid,
          flagExt: flagExt,
        };
        mediaOwnerMap.set(media.mediaId, mediaOwner);
      });
      nativePage.viewMedia({
        mediaList: orderedMediaList,
        position: 0,
        mediaOwnerMap: mediaOwnerMap,
      });
    }
  };

  return (
    <Container>
      {orderedMediaList?.length == 1 &&
        orderedMediaList[0] &&
        (isImage(orderedMediaList[0]) ? (
          <SingleImage
            media={orderedMediaList[0]}
            showAsSquare={true}
            onViewMedia={viewMedia}
          />
        ) : isVideo(orderedMediaList[0]) ? (
          <SingleVideo media={orderedMediaList[0]} onViewMedia={viewMedia} />
        ) : isAudio(orderedMediaList[0]) ? (
          <AudioFlow media={orderedMediaList[0]} />
        ) : (
          <></>
        ))}
      {(orderedMediaList?.length || 0) > 1 &&
        (orderedMediaList?.length || 0) <= 4 && (
          <MediaGrid
            style={{
              gridTemplateColumns: "1fr 1fr",
            }}
          >
            {orderedMediaList?.map((media, index) => (
              <PostMediaCell
                media={media}
                key={media.mediaId}
                mediaList={orderedMediaList}
                index={index}
                objectId={props.objectId}
                objectType={ObjectType.BLOG}
              />
            ))}
          </MediaGrid>
        )}
      {(orderedMediaList?.length || 0) > 4 && (
        <MediaGrid
          style={{
            gridTemplateColumns: "1fr 1fr 1fr",
          }}
        >
          {orderedMediaList?.map((media, index) => (
            <PostMediaCell
              media={media}
              key={media.mediaId}
              mediaList={orderedMediaList}
              index={index}
              uid={props.uid}
              objectId={props.objectId}
              objectType={ObjectType.BLOG}
            />
          ))}
        </MediaGrid>
      )}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
`;

const SingleImageContainer = styled.div`
  display: flex;
  width: 100%;
`;

export function SingleImage(props: {
  media: Media;
  showAsSquare?: boolean;
  onViewMedia: () => void;
}) {
  const nsfwContext = useNSFW();
  const backend = useBackend();
  const myUid = useMyUid();
  const userSWR = useSWR(backend.getUser(myUid));

  return (
    <SingleImageContainer
      onClick={(event) => {
        event.stopPropagation();
        props.onViewMedia();
      }}
    >
      {/*{props.media.nsfw &&*/}
      {/*!userSWR.content?.extensions?.showNSFWContent &&*/}
      {/*!nsfwContext.get.has(props.media.mediaId) ? (*/}
      {/*  <RectNSFW*/}
      {/*    mediaId={props.media.mediaId}*/}
      {/*    roundedCorner={true}*/}
      {/*    nsfwContext={nsfwContext}*/}
      {/*  />*/}
      {/*) : (*/}
      {props.showAsSquare && (
        <AspectRatioImage
          src={[props.media, "best"]}
          aspectRatio={1}
          imageStyle={{ borderRadius: 12 }}
          style={{
            maxWidth: "100%",
            minWidth: props.showAsSquare ? "100%" : undefined,
          }}
        />
      )}
      {!props.showAsSquare && (
        <Image
          src={[props.media, "best"]}
          style={{
            maxWidth: "100%",
            borderRadius: 12,
          }}
        />
      )}

      {/*)}*/}
    </SingleImageContainer>
  );
}

const SingleVideoContainer = styled.div`
  display: flex;
  position: relative;
`;

export function SingleVideo(props: { media: Media; onViewMedia: () => void }) {
  const nsfwContext = useNSFW();
  const backend = useBackend();
  const myUid = useMyUid();
  const userSWR = useSWR(backend.getUser(myUid));
  const targetResource = getBestRes(props.media);

  // if (
  //   props.media.nsfw &&
  //   !userSWR.content?.extensions?.showNSFWContent &&
  //   !nsfwContext.get.has(props.media.mediaId)
  // ) {
  //   return (
  //     <SingleVideoContainer
  //       style={{
  //         maxWidth:
  //           targetResource.width >= targetResource.height ? "100%" : "60%",
  //       }}
  //     >
  //       <RectNSFW
  //         mediaId={props.media.mediaId}
  //         roundedCorner={true}
  //         nsfwContext={nsfwContext}
  //       />
  //     </SingleVideoContainer>
  //   );
  // } else {
  return (
    <SingleVideoContainer
      style={{
        maxWidth:
          targetResource.width >= targetResource.height ? "100%" : "60%",
      }}
      onClick={(event) => {
        event.stopPropagation();
        props.onViewMedia();
      }}
    >
      <Image
        src={[props.media.cover, "best"]}
        style={{ width: "100%", borderRadius: 12 }}
      />
      <VideoPlayCover style={{ borderRadius: 12 }}>
        <img alt={"video-cover"} src={icVideoPlay} width={54} height={54} />
      </VideoPlayCover>
    </SingleVideoContainer>
  );
  // }
}

export function AudioFlow(props: { media: Media }) {
  const audioRef = useRef<HTMLAudioElement>(null);
  const duration = (props.media.resourceList[0]?.duration || 0) / 1000;
  const [audioTime, setAudioTime] = useState(duration);

  const [wave, setWave] = useState<string>(staticWave);

  async function onAudioClick() {
    try {
      if (audioRef.current?.paused) {
        await audioRef.current.play();
        setWave(animatedWave);
      } else if (audioRef.current?.played) {
        audioRef.current.pause();
        setWave(staticWave);
        audioRef.current.currentTime = 0;
        setAudioTime(audioRef.current?.duration);
      }
    } catch (e) {
      console.error(e);
    }
  }

  const securityURL = props.media.baseUrl.replace("http://", "https://");

  return (
    <HStack
      style={{
        padding: "10px 15px",
        backgroundColor: "#012710D9",
        border: "0.5px solid #00E25B",
        borderRadius: 6,
        gap: 10,
        alignSelf: "flex-start",
      }}
      onClick={(event) => {
        event.stopPropagation();
        onAudioClick().catch(andLog);
      }}
    >
      <SingleAudioText>{audioTime?.toFixed(0)}"</SingleAudioText>
      <img src={wave} height={20} width={140} style={{ filter: "#00E25B" }} />
      <audio
        hidden={true}
        ref={audioRef}
        src={securityURL}
        onTimeUpdate={(event) => {
          const realDuration = event.currentTarget.duration;
          const remainingTime = realDuration - event.currentTarget.currentTime;
          if (remainingTime <= 0) {
            setWave(staticWave);
            setAudioTime(duration);
          } else {
            setAudioTime(remainingTime);
          }
        }}
      />
    </HStack>
  );
}

const SingleAudioText = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: #00e25b;
`;

const MediaGrid = styled.div`
  width: 100%;
  display: grid;
  row-gap: 5px;
  column-gap: 5px;
`;

export function PostMediaCell(props: {
  media: Media;
  mediaList: Media[];
  index: number;
  smallSize?: boolean;
  objectId: bigint;
  objectType: number;
  uid?: bigint;
}) {
  const nativePage = useNativePage();
  const nsfwContext = useNSFW();
  const backend = useBackend();
  const myUid = useMyUid();
  const userSWR = useSWR(backend.getUser(myUid));
  const orderedFilteredList = props.mediaList?.filter(
    (media) => isImage(media) || isVideo(media),
  );
  const onClickMedia = () => {
    const mediaOwnerMap = new Map();
    orderedFilteredList?.forEach((media) => {
      const ownerSpec = {
        objectId: props.objectId,
        objectType: props.objectType,
      };
      const flagExt = {
        flagMedia: media,
        flagMediaLocation: FlagMediaLocationEnum.CONTENT_IMAGE,
      };

      const mediaOwner: MediaOwner = {
        ownerSpec: ownerSpec,
        authorId: props.uid,
        flagExt: flagExt,
      };
      mediaOwnerMap.set(media.mediaId, mediaOwner);
    });
    nativePage.viewMedia({
      mediaList: orderedFilteredList,
      position: orderedFilteredList.findIndex(
        (media) => media.mediaId === props.media.mediaId,
      ),
      mediaOwnerMap: mediaOwnerMap,
    });
  };

  // if (
  //   props.media.nsfw &&
  //   !userSWR.content?.extensions?.showNSFWContent &&
  //   !nsfwContext.get.has(props.media.mediaId)
  // ) {
  //   return <SquareNSFW mediaId={props.media.mediaId} onClick={onClickMedia} />;
  // } else {
  if (isImage(props.media)) {
    return <ImageCell media={props.media} onClickMedia={onClickMedia} />;
  } else if (isVideo(props.media)) {
    return (
      <VideoCell
        media={props.media}
        onClickMedia={onClickMedia}
        smallSize={props.smallSize}
      />
    );
  } else if (isAudio(props.media)) {
    return <AudioCell media={props.media} />;
  } else return null;
}

export function ImageCell(props: { media: Media; onClickMedia?: () => void }) {
  return (
    <ImageContent>
      <AspectRatioImage
        src={getBestRes(props.media).url}
        aspectRatio={1}
        style={{ width: "100%" }}
        imageStyle={{ borderRadius: 2 }}
        onClick={props.onClickMedia}
        stopPropagation={true}
      />
    </ImageContent>
  );
}

const ImageContent = styled.div`
  width: 100%;
`;

export function VideoCell(props: {
  media: Media;
  onClickMedia: () => void;
  smallSize?: boolean;
}) {
  return (
    <VideoContent
      onClick={(event) => {
        event.stopPropagation();
        props.onClickMedia();
      }}
    >
      {props.media.cover && (
        <AspectRatioImage
          src={getBestRes(props.media.cover).url}
          aspectRatio={1}
          style={{ width: "100%" }}
          imageStyle={{ borderRadius: 2 }}
        />
      )}
      <VideoPlayCover style={{ borderRadius: 2 }}>
        <img
          alt={"video-cover"}
          src={icVideoPlay}
          width={props.smallSize ? 33 : 54}
          height={props.smallSize ? 33 : 54}
        />
      </VideoPlayCover>
    </VideoContent>
  );
}

export function AudioCell(props: { media: Media }) {
  const audioRef = useRef<HTMLAudioElement>(null);
  const duration = (props.media.resourceList[0]?.duration || 0) / 1000;

  const [audioTime, setAudioTime] = useState(duration);

  async function onAudioClick() {
    try {
      if (audioRef.current?.paused) {
        await audioRef.current.play();
      } else if (audioRef.current?.played) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        setAudioTime(duration);
      }
    } catch (e) {
      console.error(e);
    }
  }

  const securityURL = props.media.baseUrl.replace("http://", "https://");

  return (
    <SquareContainer>
      <AudioContent
        onClick={(event) => {
          event.stopPropagation();
          onAudioClick().catch(andLog);
        }}
      >
        <VStack>
          <img src={icAudio} width={48} height={48} />
          <AudioText>{audioTime?.toFixed(0)} "</AudioText>
        </VStack>
        <audio
          src={securityURL}
          hidden={true}
          ref={audioRef}
          onTimeUpdate={(event) => {
            const realDuration = event.currentTarget.duration;
            const remainingTime =
              realDuration - event.currentTarget.currentTime;
            if (remainingTime <= 0) {
              setAudioTime(duration);
            } else {
              setAudioTime(remainingTime);
            }
          }}
        />
      </AudioContent>
    </SquareContainer>
  );
}

const AudioContent = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
  background: #012710d9;
  border: 0.5px solid #00e25b;
  box-sizing: border-box;
  border-radius: 2px;
`;

const SquareContainer = styled.div`
  position: relative;
  padding-bottom: 100%;
`;

const AudioText = styled.div`
  font-weight: 700;
  font-size: 14px;
  color: #00e25b;
  text-align: center;
`;

export const VideoContent = styled.div`
  position: relative;
  width: 100%;
`;

export const VideoPlayCover = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: grid;
  align-items: center;
  justify-content: center;
  background-color: #00000099;
`;
