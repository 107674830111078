import { useBackend } from "../../service/APIService";
import { useI18n } from "../../hooks/useI18n";
import { useSWRList } from "../../hooks/swr/useSWRList";
import { Page } from "../../components/Page";
import TabCellSeparator from "../../components/TabCellSeparator";
import { Divider, PostCell } from "../post/PostCell";
import { hPaddingWithPageInset } from "../../components/CommonStyles";
import * as React from "react";
import { useEffect, useState } from "react";
import { SearchUserCell } from "./SearchUserPage";
import CircleSnippetCell from "../circle/CircleSnippetCell";
import { useHopper } from "../../hooks/useHopper";
import styled from "styled-components";
import { HStack } from "../../components/VStack";
import arrowBlue from "../../../src/res/images/icon_arrow_right_blue.svg";
import { Image } from "../../components/Image";
import { User } from "../../proto/User";
import { Circle } from "../../proto/Circle";
import { PostAndAnnouncement } from "../../proto/Post";
import { andLog, useErrorHandler } from "../../components/handleError";
import { SearchSource } from "./SearchPage";
import { useNativePage } from "../../hooks/useBridge";
import { usePageParent } from "../../components/PageCompositionBoundary";
import GridLayout from "../../components/GridLayout";
import { PartyGridCell } from "../tags/TagPartyPage";
import { useMemoryRepoMap } from "../../hooks/swr/useLocalRepo";
import { zBigInt } from "../../utils/zodUtils";
import { z } from "zod";
import { Reaction } from "../../proto/Reaction";
import { useReactionRecord } from "../../hooks/useReactionRecord";

export interface SearchAllProps {
  word: string;
  circleId?: bigint;
  searchSource: SearchSource;
}

export function SearchAllPage({
  word,
  circleId,
  searchSource,
}: SearchAllProps) {
  const backend = useBackend();
  const i18n = useI18n();
  const hopper = useHopper();
  const nativePage = useNativePage();
  const handleError = useErrorHandler();

  const displayItemNumber = 3;

  const pageParent = usePageParent();

  const usersSWR = useSWRList(["SearchAllPage", "searchUsers", word], () =>
    backend
      .searchUsers(
        word,
        searchSource === SearchSource.InCircleAll ? circleId : undefined,
      )
      .run(),
  );
  const chatsSWR = useSWRList(["SearchAllPage", "searchChats", word], () =>
    backend
      .searchChats(
        word,
        searchSource === SearchSource.InCircleAll ? circleId : undefined,
      )
      .run(),
  );

  const reactionRepo = useReactionRecord();

  const postsSWR = useSWRList(["SearchAllPage", "searchBlogs", word], () =>
    backend
      .searchBlogs(
        word,
        searchSource === SearchSource.InCircleAll ? circleId : undefined,
      )
      .intercept((it) => {
        it.list.forEach((item) => {
          if (item.blog) {
            reactionRepo
              .setValue(item.blog.blogId, item.blog.reactionCountList)
              .catch(andLog);
          }
        });
      })
      .run(),
  );

  const displayUsers = usersSWR.content?.list.slice(0, displayItemNumber) || [];

  const [displayCircles, setDisplayCircles] = useState<Circle[]>([]);
  const [fetchedCirclesCount, setFetchedCirclesCount] = useState(0);
  useEffect(() => {
    const searchCircles = async () => {
      if (searchSource !== SearchSource.InCircleAll) {
        //Not in circle search
        try {
          const { list } = await backend.searchCircles(word).run();
          setDisplayCircles(list.slice(0, displayItemNumber));
          setFetchedCirclesCount(list.length);
        } catch (error) {
          handleError(error);
        }
      }
    };
    searchCircles().catch(andLog);
  }, [searchSource, circleId, word]);

  const displayChats = chatsSWR.content?.list.slice(0, displayItemNumber) || [];
  const displayPosts = postsSWR.content?.list || [];

  // Reusable rendering logic
  const renderSection = (
    title: string,
    items: any[],
    renderItem: (item: any, index: number) => JSX.Element,
    showMoreCount: number,
    moreTitle: string,
    onMoreClick: () => void,
  ) => {
    if (items.length === 0) return null;
    return (
      <>
        <TabCellSeparator title={title} />
        {items.map(renderItem)}
        {showMoreCount > displayItemNumber && (
          <MoreView title={moreTitle} onClick={onMoreClick} />
        )}
      </>
    );
  };

  // Render functions for different sections
  const renderUser = (user: User) => (
    <SearchUserCell
      key={user.uid.toString()}
      user={user}
      onUpdate={() => usersSWR.load()}
    />
  );

  const renderCircle = (circle: Circle, index: number) => (
    <div key={circle.circleId}>
      <CircleSnippetCell
        hPadding={hPaddingWithPageInset}
        circle={circle}
        onClick={() => hopper.push(`circle/${circle.circleId}`)}
      />
      {index < displayCircles.length - 1 && <Divider />}
    </div>
  );

  const renderPost = (item: PostAndAnnouncement) =>
    item.blog && (
      <PostCell
        hPadding={hPaddingWithPageInset}
        post={item.blog}
        key={item.blog.blogId}
      />
    );

  return (
    <Page
      //TODO:pageData
      pageData={
        searchSource === SearchSource.InCircleAll
          ? [usersSWR, chatsSWR, postsSWR]
          : [usersSWR, chatsSWR, postsSWR]
      }
      scrollPaddingDisabled={true}
    >
      {renderSection(
        SearchSource.title(i18n, SearchSource.Users),
        displayUsers,
        renderUser,
        usersSWR.content?.list.length || 0,
        i18n.more_users(),
        () => pageParent.setPrimarySubpageWithId(SearchSource.Users, "smooth"),
      )}
      {renderSection(
        SearchSource.title(i18n, SearchSource.Circle),
        displayCircles,
        renderCircle,
        fetchedCirclesCount,
        i18n.more_circles(),
        () => pageParent.setPrimarySubpageWithId(SearchSource.Circle, "smooth"),
      )}
      {displayChats.length > 0 && (
        <>
          <TabCellSeparator
            title={SearchSource.title(i18n, SearchSource.Chats)}
          />
          <GridLayout
            style={{ ...hPaddingWithPageInset }}
            spanCount={2}
            items={displayChats.map((item, index) => {
              return <PartyGridCell chat={item} key={item.threadId} />;
            })}
          ></GridLayout>
          {(chatsSWR.content?.list.length || 0) > displayItemNumber && (
            <MoreView
              title={i18n.party_on_more_parties()}
              onClick={() =>
                pageParent.setPrimarySubpageWithId(SearchSource.Chats, "smooth")
              }
            />
          )}
        </>
      )}
      {displayPosts.length > 0 && (
        <>
          <TabCellSeparator
            title={SearchSource.title(i18n, SearchSource.Moments)}
          />
          {displayPosts.map(renderPost)}
        </>
      )}
    </Page>
  );
}

const MoreLabel = styled.div`
  color: var(--color-text-blue);
  font-size: 12px;
  font-weight: 500;
  text-align: center;
`;

type MoreLabelProps = {
  title: string;
  onClick: () => void;
};

export function MoreView({ title, onClick }: MoreLabelProps) {
  return (
    <HStack
      style={{
        margin: "20px auto 10px",
        justifyContent: "center",
      }}
      onClick={onClick}
    >
      <MoreLabel>{title}</MoreLabel>
      <Image src={arrowBlue} width={24} height={24} />
    </HStack>
  );
}
