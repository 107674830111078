import { resolvePath, WidgetItemProps } from "./CircleWidgetBanner";
import { Image } from "./Image";
import { GradientOverlay, OverlayText } from "./LargeWidget";
import React from "react";
import { SimpleCircle } from "../proto/WidgetItem";
import { HStack, VStack } from "./VStack";
import { getBestRes } from "../proto/Media";
import styled from "styled-components";
import { I18n } from "../hooks/useI18n";
import { useHopper } from "../hooks/useHopper";
import { useLoadState } from "../hooks/LoadState";
import { useBackend } from "../service/APIService";
import { Spin } from "./Spin";
import { useErrorHandler } from "./handleError";
import { string } from "zod";
import iconCircleUnreachable from "../res/images/icon_circle_unreachable.svg";

function MediumWidget(props: WidgetItemProps) {
  const linkTarget = useLoadState();
  const handleError = useErrorHandler();
  const backend = useBackend();
  const hopper = useHopper();

  async function openInternalLink(link: string) {
    const r = await linkTarget.run(async () => {
      const linkModel = await backend.linkPathFrom(link).run();
      linkModel.path && resolvePath(linkModel.path, hopper);
    });

    if (!r.success) {
      handleError(r.error);
    } else {
      // hopper.back();
    }
  }

  return (
    <div
      style={{
        height: 220,
        width: "100%",
        position: "relative", // 为了让渐变层定位
      }}
      onClick={() => {
        // hopper.
        let targetLink = props.widgetItem.targetLink;
        targetLink && openInternalLink(targetLink);
      }}
    >
      <Image
        key={props.widgetItem.widgetItemId}
        style={{
          borderRadius: 10,
          width: "100%",
          height: "100%",
          boxSizing: "border-box",
          border: "1px solid rgba(255, 255, 255, 0.2)",
        }}
        src={[props.widgetItem.background?.backgroundImage, "best"]}
        alt={`Medium-widget-${props.widgetItem.widgetItemId}`}
      />
      {/* 渐变层 */}
      <GradientOverlay />
      {/* 顶部文字 */}
      <OverlayText style={{ fontWeight: 500 }}>
        {props.widgetItem.title || "Empty Title"}
      </OverlayText>

      {
        <VStack
          style={{
            position: "absolute",
            left: 12,
            top: 60,
            gap: 7,
            width: "100%",
          }}
        >
          {props.widgetItem.circlePreviewList.map((item) => (
            <WidgetCirclePreviewCell
              circle={item}
              i18n={props.i18n}
            ></WidgetCirclePreviewCell>
          ))}
        </VStack>
      }

      <div
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          left: 0,
          top: 0,
        }}
      >
        <Spin state={linkTarget.state}>{}</Spin>
      </div>
    </div>
  );
}

const CircleTitleLabel = styled.div`
  color: var(--color-text00);
  font-weight: 400;
  font-size: 14px;
`;

const CircleMembersLabel = styled.div`
  color: rgba(255, 255, 255, 0.6);
  font-weight: 400;
  font-size: 10px;
`;

function WidgetCirclePreviewCell(props: { circle: SimpleCircle; i18n: I18n }) {
  const circleIcon = (): string => {
    if (props.circle.circleIcon !== undefined) {
      return getBestRes(props.circle.circleIcon)?.url;
    }
    return iconCircleUnreachable;
  };
  return (
    <div>
      <HStack>
        <Image
          src={circleIcon()}
          width={40}
          height={40}
          style={{
            borderRadius: 6,
            borderWidth: 1,
            borderColor: `${props.circle.circleIcon !== undefined ? props.circle.themeColor ?? "FFFFFF" : "FFFFFF"}`,
            borderStyle: "solid",
          }}
        />

        <VStack style={{ gap: 4, marginInlineStart: 6 }}>
          <CircleTitleLabel>{props.circle.name}</CircleTitleLabel>
          <CircleMembersLabel>
            {props.i18n.ext_social_members(
              (props.circle.membersCount ?? 0)?.toString(),
            )}
          </CircleMembersLabel>
        </VStack>
      </HStack>
      <div
        style={{
          marginInlineStart: 50,
          width: "calc(100% - 80px)",
          height: 0.5,
          marginTop: 4,
          backgroundColor: "rgba(255, 255, 255, 0.12)",
        }}
      ></div>
    </div>
  );
}

export default MediumWidget;
