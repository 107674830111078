import { useI18n, useIsRTL } from "../../hooks/useI18n";
import { Page } from "../../components/Page";
import React, { useMemo, useState } from "react";
import { PhoneNumberInput, RegularInputGroup } from "../../components/Input";
import { RegularLargeButton } from "../../components/Buttons";
import { useHopper } from "../../hooks/useHopper";
import { AuthBody, AuthType } from "../../proto/Auth";
import { useLoadState } from "../../hooks/LoadState";
import { Spin } from "../../components/Spin";
import { NavEnd, NavItem, NavMiddle } from "../../components/NavBar";
import { useBackend } from "../../service/APIService";
import { AuthTitleLabel } from "./InvitationCodePage";
import { BSMenu, BSMenuItem } from "../../components/BSMenu";
import { allRegions, Region } from "../../proto/Region";
import { useModal } from "../../components/Modal";
import { useErrorHandler } from "../../components/handleError";
import { useStringSearchParam } from "../../hooks/useTypedParam";
import { useMemoryRepo } from "../../hooks/swr/useLocalRepo";

export function VerifyEmailOrPhoneSignUpPage() {
  const i18n = useI18n();
  const backend = useBackend();
  const hopper = useHopper();
  const handleError = useErrorHandler();

  const [inputValue, setInputValue] = useState("");

  const loadTask = useLoadState();

  const flowId = useStringSearchParam("f");
  const authInfo = useMemoryRepo<AuthBody>(flowId ?? "", {});
  const authType = useMemo(() => {
    return authInfo.content.authType;
  }, [authInfo.content]);

  const isRTL = useIsRTL();
  const regionMenu = useModal("regionMenu");
  const locale = new Intl.Locale(navigator.language);
  const [region, setRegion] = React.useState<Region | null>(
    Region.from(locale.region || "US"),
  );

  async function verifyInputValue() {
    const result = await loadTask.run(async () => {
      const authBody = {
        email: authType === AuthType.Email ? inputValue : undefined,
        phoneNumber:
          authType === AuthType.Phone
            ? region?.phoneCodeWithMark + inputValue
            : undefined,
        ...authInfo,
      };
      await backend.registerCheck(authBody).run();
    });
    if (!result.success) {
      handleError(result.error);
    } else {
      await authInfo.fill((prev) => ({
        ...prev,
        email: authType === AuthType.Email ? inputValue : undefined,
        phoneNumber:
          authType === AuthType.Phone
            ? region?.phoneCodeWithMark + inputValue
            : undefined,
      }));
      hopper.push("verification-code", {
        f: flowId,
      });
    }
  }

  const title = (() => {
    switch (authType) {
      case AuthType.Email:
        return i18n.onboarding_input_email();
      case AuthType.Phone:
        return i18n.onboarding_input_phone_number();
      default:
        return "-";
    }
  })();

  const placeholder = (() => {
    switch (authType) {
      case AuthType.Email:
        return i18n.email();
      case AuthType.Phone:
        return i18n.auth_phone_number();
      default:
        return "-";
    }
  })();

  return (
    <Page pageData={undefined}>
      <NavMiddle>{i18n.auth_sign_up()}</NavMiddle>
      <NavEnd>
        {NavItem.text(i18n.help(), () => hopper.push("feedback"))}
      </NavEnd>
      <AuthTitleLabel>{title}</AuthTitleLabel>
      {authType === AuthType.Email && (
        <RegularInputGroup
          placeholder={placeholder}
          value={inputValue}
          updateValue={setInputValue}
        />
      )}
      {authType === AuthType.Phone && (
        <PhoneNumberInput
          placeholder={placeholder}
          value={inputValue}
          updateValue={setInputValue}
          regionCode={region?.phoneCodeWithMark}
          regionCodeOnClick={() => regionMenu.open()}
        />
      )}
      <RegularLargeButton
        style={{ margin: "40px 50px 0" }}
        onClick={verifyInputValue}
      >
        <Spin state={loadTask.state}>{i18n.next()}</Spin>
      </RegularLargeButton>
      <BSMenu modal={regionMenu}>
        {allRegions.map((region) => (
          <BSMenuItem
            title={isRTL ? region.rtlDes : region.des}
            trailingItem={region.phoneCodeWithMark}
            onClick={() => {
              regionMenu.close();
              setRegion(region);
            }}
          ></BSMenuItem>
        ))}
      </BSMenu>
    </Page>
  );
}
