import { useBackend } from "../../service/APIService";
import { useHopper } from "../../hooks/useHopper";
import { useDebounceHopState } from "../../hooks/ExtHooks";
import { useSWRList } from "../../hooks/swr/useSWRList";
import * as React from "react";
import { Page } from "../../components/Page";
import { HStack } from "../../components/VStack";
import { Image } from "../../components/Image";
import close from "../../res/images/ic_close.svg";
import { SearchBackground } from "./SearchPage";
import iconSearch from "../../res/images/icon_search_layout_placeholder.svg";
import { Input } from "../../components/Input";
import { useMyUid } from "../../service/AuthSessionService";
import { CommonUserCell } from "../user/CommonUserCell";
import { useHopState } from "../../hooks/useHopState";

export function SearchMyFriendPage() {
  const backend = useBackend();
  const hopper = useHopper();
  const myUid = useMyUid();
  const [word, setWord] = useDebounceHopState<string>(
    "SearchMyFriendWord",
    300,
  );

  const [inputWord, setInputWord] = useHopState<string>("inputWord");

  const usersSWR = useSWRList(
    word ? backend.getMembershipList(myUid, word) : undefined,
  );

  return (
    <Page pageData={undefined}>
      <HStack
        style={{
          width: "calc(100% + 36px)",
          marginLeft: `-18px`,
        }}
      >
        <Image
          src={close}
          width={36}
          height={36}
          onClick={() => {
            hopper.back();
          }}
        ></Image>
        <SearchBackground>
          <Image
            alt={"search-icon"}
            src={iconSearch}
            width={24}
            height={24}
          ></Image>
          <Input
            value={inputWord}
            style={{
              height: 36,
              margin: "0 6px 0 0",
              padding: "0",
              width: "calc(100% - 30px)",
            }}
            onChange={(e) => {
              if (e.target.value) {
                setWord(e.target.value);
                setInputWord(e.target.value);
              } else {
                setInputWord("");
              }
            }}
          ></Input>
        </SearchBackground>
      </HStack>

      {word &&
        word.length > 0 &&
        inputWord &&
        inputWord.length > 0 &&
        usersSWR &&
        usersSWR.content &&
        usersSWR.content.list.map((item) => (
          <CommonUserCell
            user={item}
            key={item.uid}
            onUpdate={() => usersSWR.load()}
          />
        ))}
    </Page>
  );
}
