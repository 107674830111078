import { z } from "zod";
import { zBigInt, zStatic } from "../utils/zodUtils";
import { base64ToUnknown } from "../utils/Blob";

export const ExternalAppShellData = z.object({
  isSpongeKit: z.boolean(),
  loggedInUserId: zBigInt,
  webHostCreatedTime: z.number(),
  webHostId: z.string(),
  refer: z
    .object({
      hopId: z.string(),
      layerId: z.string(),
    })
    .optional(),
  layerRefer: z
    .object({
      hopId: z.string(),
      layerId: z.string(),
    })
    .optional(),
  layerId: z.string().default("layer-id-missing"),
  savedStates: z.array(z.object({ key: z.string(), value: z.string() })),
  isLite: z.boolean().optional(),
});

let externalAppShellData:
  | zStatic<typeof ExternalAppShellData>
  | null
  | undefined = undefined;

function extractExternalAppShellData(): zStatic<
  typeof ExternalAppShellData
> | null {
  if ("webHostDataProvider" in window) {
    const provider = window["webHostDataProvider"];
    if (
      provider &&
      typeof provider === "object" &&
      "getWebHostDataBase64" in provider &&
      typeof provider["getWebHostDataBase64"] === "function"
    ) {
      const base64 = provider["getWebHostDataBase64"]();
      if (typeof base64 === "string") {
        const data = base64ToUnknown(base64);
        return ExternalAppShellData.parse(data);
      } else {
        return null;
      }
    } else {
      return null;
    }
  } else {
    return null;
  }
}

export function getExternalAppShellData(): zStatic<
  typeof ExternalAppShellData
> | null {
  if (externalAppShellData !== undefined) {
    return externalAppShellData;
  } else {
    const extracted = extractExternalAppShellData();
    externalAppShellData = extracted;
    return extracted;
  }
}

export function isInExternalAppShell() {
  return !!getExternalAppShellData();
}

export function isLiteApp() {
  const appShellData = getExternalAppShellData();
  return appShellData && appShellData.isLite;
}
