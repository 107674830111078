import { z } from "zod";
import { zStatic } from "../utils/zodUtils";
import { CircleMembershipCard } from "./CircleMembershipCard";
import { CircleMemberTitle } from "./CircleMemberTitle";

export const CurrentMemberInfo = z.object({
  circleJoinedTime: z.number().optional(),
  circleRole: z.number().optional(),
  joinedStatus: z.number().optional(),
  membershipLevel: z.number().optional(),
  circleMembershipCard: CircleMembershipCard.optional(),
  memberTitleList: z.array(CircleMemberTitle).optional(),
});

export type CurrentMemberInfo = zStatic<typeof CurrentMemberInfo>;
