import { Page } from "../../components/Page";
import { useSWR } from "../../hooks/swr/useSWR";
import { useBackend } from "../../service/APIService";
import { useBigIntParam } from "../../hooks/useTypedParam";
import { ZDoc } from "../../components/zdoc/ZDoc";
import React, { useMemo } from "react";
import UserBackgroundFrame from "./UserBackgroundFrame";
import { NavMiddle } from "../../components/NavBar";
import { useI18n } from "../../hooks/useI18n";
import {
  getOrderedFilteredMediaList,
  isImage,
  isVideo,
  Media,
} from "../../proto/Media";
import { useNativePage } from "../../hooks/useBridge";
import { ObjectType } from "../../proto/ObjectSpec";
import { FlagMediaLocationEnum } from "../../proto/Flag";
import { MediaOwner } from "../../proto/MediaOwner";

function UserBioPage() {
  const backend = useBackend();
  const uid = useBigIntParam("uid");
  const i18n = useI18n();
  const userSWR = useSWR(backend.getUser(uid));

  const nativePage = useNativePage();

  const orderedMediaList = getOrderedFilteredMediaList(
    userSWR.content?.mediaList,
    userSWR.content?.richFormat,
  );

  const viewMedia = (media: Media) => {
    const mediaOwnerMap = new Map();
    orderedMediaList?.forEach((media) => {
      const ownerSpec = { objectId: uid, objectType: ObjectType.USER };
      const flagExt = {
        flagMedia: media,
        flagMediaLocation: FlagMediaLocationEnum.BIO_IMAGE,
      };

      const mediaOwner: MediaOwner = {
        ownerSpec: ownerSpec,
        authorId: uid,
        flagExt: flagExt,
      };
      mediaOwnerMap.set(media.mediaId, mediaOwner);
    });
    if (orderedMediaList) {
      nativePage.viewMedia({
        mediaList: orderedMediaList,
        position: orderedMediaList.findIndex(
          (m) => m.mediaId === media.mediaId,
        ),
        mediaOwnerMap: mediaOwnerMap,
      });
    }
  };

  return (
    <Page
      pageData={userSWR}
      underlay={
        <UserBackgroundFrame
          background={userSWR.content?.background}
          backgroundColor={userSWR.content?.backgroundColor}
        />
      }
    >
      {userSWR.content && (
        <ZDoc
          content={userSWR.content.bio}
          richFormat={userSWR.content.richFormat}
          mediaList={userSWR.content.mediaList}
          pollList={[]}
          onViewMedia={viewMedia}
        />
      )}
      <NavMiddle>{i18n.user_bio()}</NavMiddle>
    </Page>
  );
}

export default UserBioPage;
