import { useI18n } from "../../hooks/useI18n";
import { Page } from "../../components/Page";
import React, { useState } from "react";
import { RegularInputGroup } from "../../components/Input";
import { HStack, VSpace } from "../../components/VStack";
import { useHopper } from "../../hooks/useHopper";
import { AuthType, SecurityPurpose } from "../../proto/Auth";
import {
  useEnumSearchParam,
  useStringSearchParam,
} from "../../hooks/useTypedParam";
import { RegularLargeButton } from "../../components/Buttons";
import { andLog, useErrorHandler } from "../../components/handleError";
import { Spin } from "../../components/Spin";
import { useLoadState } from "../../hooks/LoadState";
import styled from "styled-components";
import { StringValidator } from "../../utils/StringValidator";
import { NavEnd, NavItem } from "../../components/NavBar";
import { AuthTitleLabel } from "./InvitationCodePage";
import { useBackend } from "../../service/APIService";
import { useSWR } from "../../hooks/swr/useSWR";
import { ForgotPasswordLabel } from "./EmailLogInPage";
import { BSMenu, BSMenuItem } from "../../components/BSMenu";
import { useModal } from "../../components/Modal";
import { useAuthSessionService } from "../../service/AuthSessionService";
import { useNativePage } from "../../hooks/useBridge";
import { delay } from "../../utils/ensureDur";

export enum SetPasswordType {
  SignUp = 1,
  CreateANew = 2,
  Change = 3,
  SetThirdPassword = 4,
}

export function SetPasswordPage() {
  const i18n = useI18n();
  const backend = useBackend();
  const hopper = useHopper();
  const handleError = useErrorHandler();
  const authType = useEnumSearchParam<AuthType>("authType");
  const securityCode = useStringSearchParam("securityCode");
  const purpose = useEnumSearchParam<SecurityPurpose>("purpose");
  const setPasswordType = useEnumSearchParam<SetPasswordType>(
    "setPasswordType",
    SetPasswordType.SignUp,
  );
  const email = useStringSearchParam("email");
  const phoneNumber = useStringSearchParam("phoneNumber");
  const invitationCode = useStringSearchParam("invitationCode");
  const countryCode = useStringSearchParam("countryCode");
  const birthday = useStringSearchParam("birthday");

  const [oldPassword, setOldPassword] = useState<string | undefined>();
  const [newPassword, setNewPassword] = useState("");

  const loadTask = useLoadState();

  const account = useSWR(backend.account()).content;

  function getAuthTypeByNumber(value: number): AuthType | undefined {
    if (Object.values(AuthType).includes(value)) {
      return value as AuthType;
    }
    return undefined;
  }

  const buttonDisabled = (() => {
    const isPwdValid =
      StringValidator.containsNumbers(newPassword) &&
      StringValidator.containsLetters(newPassword) &&
      StringValidator.containsPunctuationMarks(newPassword) &&
      StringValidator.isPwdMinCharacters(newPassword);
    switch (setPasswordType) {
      case SetPasswordType.SignUp:
      case SetPasswordType.CreateANew:
        return !isPwdValid;
      case SetPasswordType.Change:
        return !isPwdValid && oldPassword === newPassword;
    }
  })();

  const authSessionService = useAuthSessionService();
  const nativePage = useNativePage();

  async function resetPassword() {
    const result = await loadTask.run(async () => {
      const tempEmail = email || account?.email;
      const tempPhoneNumber = phoneNumber || account?.phoneNumber;
      switch (setPasswordType) {
        case SetPasswordType.SignUp:
          {
            const authBody = {
              authType: authType,
              purpose: purpose,
              invitationCode: invitationCode,
              countryCode: countryCode,
              birthday: birthday,
              email: email,
              phoneNumber: phoneNumber,
              securityCode: securityCode,
              password: oldPassword,
            };
            await backend.registerCheck(authBody).run();
          }
          break;
        case SetPasswordType.CreateANew:
          {
            const authBody = {
              email: tempEmail,
              phoneNumber: tempPhoneNumber,
              purpose: SecurityPurpose.ChangePassword,
              authType: tempEmail ? AuthType.Email : AuthType.Phone,
              updatedPassword: newPassword,
              securityCode: securityCode,
            };
            await backend.resetPassword(authBody).run();
          }
          break;
        case SetPasswordType.Change:
          {
            const authBody = {
              oldPassword: oldPassword,
              newPassword: newPassword,
            };
            const auth = await backend.changePassword(authBody).run();
            await authSessionService.add(
              {
                uid: auth.account.uid,
                sid: auth.sId,
                ptoken: auth.secret,
              },
              true,
            );
            nativePage.successHud(i18n.done()).catch(andLog);
            await delay(1000);
            hopper.back();
          }
          break;
      }
    });
    if (!result.success) {
      handleError(result.error);
    } else {
      switch (setPasswordType) {
        case SetPasswordType.SignUp:
          hopper.push("select-gender", {
            authType: authType,
            purpose: purpose,
            invitationCode: invitationCode,
            countryCode: countryCode,
            birthday: birthday,
            email: email,
            phoneNumber: phoneNumber,
            securityCode: securityCode,
            password: newPassword,
          });
          break;
        case SetPasswordType.CreateANew:
          nativePage.successHud(i18n.done()).catch(andLog);
          await delay(1000);
          hopper.dismissLayer();
          break;
        case SetPasswordType.Change:
          break;
      }
    }
  }

  const titleText = (() => {
    switch (setPasswordType) {
      case SetPasswordType.SignUp:
        return i18n.auth_create_password();
      case SetPasswordType.CreateANew:
        return i18n.auth_create_new_password();
      case SetPasswordType.Change:
        return i18n.change_password();
    }
  })();

  const typeMenu = useModal("typeMenu");

  function onVerifyAccount(authType: AuthType) {
    switch (authType) {
      case AuthType.Email:
      case AuthType.Phone:
        {
          hopper.layer("verify-account", {
            authType: authType,
            setPasswordType: SetPasswordType.CreateANew,
          });
        }
        break;
      default:
        return;
    }
  }

  return (
    <Page pageData={undefined}>
      <NavEnd>
        {NavItem.text(i18n.help(), () => hopper.push("feedback"))}
      </NavEnd>
      <AuthTitleLabel>{titleText}</AuthTitleLabel>
      <VSpace height={20} />
      {setPasswordType === SetPasswordType.Change && (
        <RegularInputGroup
          type={"password"}
          value={oldPassword}
          updateValue={setOldPassword}
          placeholder={i18n.old_password()}
        />
      )}
      <VSpace height={20} />
      <RegularInputGroup
        type={"password"}
        value={newPassword}
        updateValue={setNewPassword}
        placeholder={
          setPasswordType === SetPasswordType.Change
            ? i18n.new_password()
            : i18n.password()
        }
      />
      <VSpace height={10}></VSpace>
      <CheckBoxWithLabel
        text={i18n.auth_impr_password_requirement_1()}
        isChecked={StringValidator.containsNumbers(newPassword)}
      ></CheckBoxWithLabel>
      <CheckBoxWithLabel
        text={i18n.auth_impr_password_requirement_2()}
        isChecked={StringValidator.containsLetters(newPassword)}
      ></CheckBoxWithLabel>
      <CheckBoxWithLabel
        text={i18n.auth_impr_password_requirement_3()}
        isChecked={StringValidator.containsPunctuationMarks(newPassword)}
      ></CheckBoxWithLabel>
      <CheckBoxWithLabel
        text={i18n.auth_impr_password_requirement_4()}
        isChecked={StringValidator.isPwdMinCharacters(newPassword)}
      ></CheckBoxWithLabel>
      <RegularLargeButton
        disabled={buttonDisabled}
        style={{ height: "54px", margin: "27px 50px 0" }}
        onClick={() => resetPassword()}
      >
        <Spin state={loadTask.state}>
          {setPasswordType === SetPasswordType.Change
            ? i18n.done()
            : i18n.next()}
        </Spin>
      </RegularLargeButton>
      {setPasswordType === SetPasswordType.Change && (
        <ForgotPasswordLabel
          onClick={() => {
            typeMenu.open();
          }}
        >
          {i18n.auth_forgot_password()}
        </ForgotPasswordLabel>
      )}
      <BSMenu modal={typeMenu}>
        <BSMenuItem
          title={i18n.email()}
          onClick={() => {
            onVerifyAccount(AuthType.Email);
          }}
        ></BSMenuItem>
        <BSMenuItem
          title={i18n.phone()}
          onClick={() => {
            onVerifyAccount(AuthType.Phone);
          }}
        ></BSMenuItem>
      </BSMenu>
    </Page>
  );
}

const DotCheckBox = styled.div<{
  isChecked: boolean;
}>`
  margin-top: 6px;
  width: 9px;
  height: 9px;
  border-radius: 20px;
  border: ${(p) => (p.isChecked ? undefined : "1px solid #a3abc1")};
  background: ${(p) => (p.isChecked ? "#1EA143" : undefined)};
`;

export interface CheckBoxWithLabelProps {
  text: string;
  isChecked: boolean;
}

export const CheckBoxWithLabel: React.FC<CheckBoxWithLabelProps> = ({
  text,
  isChecked,
}) => {
  return (
    <HStack
      style={{
        marginBottom: "6px",
        gap: "3px",
        alignItems: "start",
      }}
    >
      <DotCheckBox isChecked={isChecked}></DotCheckBox>
      <div
        style={{
          width: "100%",
          whiteSpace: "normal",
          wordWrap: "break-word",
          overflowWrap: "break-word",
        }}
      >
        {text}
      </div>
    </HStack>
  );
};
