import { useHopState } from "../../../hooks/useHopState";
import { HStack, VStack } from "../../../components/VStack";
import { CheckBox } from "../../user/UserProfilePage";
import React from "react";
import styled from "styled-components";
import { useI18n } from "../../../hooks/useI18n";
import { useBackend } from "../../../service/APIService";
import { useGlobalSpinner } from "../../../utils/globalSpinner";
import { ButtonColor, WeakButton } from "../../../components/Buttons";
import { Image } from "../../../components/Image";
import ic_add from "../../../res/images/ic_add.svg";
import { MonoLabel } from "../../debug/DemoCell";
import { Spin } from "../../../components/Spin";
import { useLoadState } from "../../../hooks/LoadState";
import { useErrorHandler } from "../../../components/handleError";
import { useWebHost } from "../../../hooks/useBridge";
import { WebHost } from "../../../bridge/WebHost";

export function CheckBoxLayout(props: {
  circleId: bigint;
  user: bigint;
  onDismiss: () => void;
  webHost: WebHost;
}) {
  const [block, setBlock] = useHopState<boolean>("block", false);
  const [removeContent, setRemoveContent] = useHopState<boolean>(
    "remove",
    false,
  );
  const task = useLoadState();
  const webHost = useWebHost();

  const backend = useBackend();
  const i18n = useI18n();
  const handleError = useErrorHandler();
  const onRemove = async () => {
    const r = await task.run(async () => {
      await backend
        .manageCircle(props.circleId, props.user, {
          type: block ? "block" : "remove",
          removeContent: removeContent,
        })
        .run();
      await webHost.broadcast("ON_REMOVE_FROM_CIRCLE", {});
    });
    if (!r.success) {
      const e = r.error;
      // handleError(e);
    }

    props.onDismiss();
  };
  return (
    <VStack style={{ gap: 10 }}>
      <HStack
        onClick={() => {
          setBlock((p) => !p);
        }}
        style={{ gap: 8, margin: "0 10px", width: "calc(100% - 20px)" }}
      >
        <CheckBox checked={block} />
        <CheckTitle>{i18n.chat_prevent_member_rejoining()}</CheckTitle>
      </HStack>
      <HStack
        onClick={() => {
          setRemoveContent((p) => !p);
        }}
        style={{ gap: 8, margin: "0 10px", width: "calc(100% - 20px)" }}
      >
        <CheckBox checked={removeContent} />
        <CheckTitle>
          {i18n.content_remove_delete_user_content_hint()}
        </CheckTitle>
      </HStack>
      <ActionContainer>
        <WeakButton style={{ flex: 1 }} onClick={props.onDismiss}>
          {i18n.cancel()}
        </WeakButton>
        <WeakButton
          style={{ flex: 1 }}
          $baseColor={ButtonColor.destructive}
          onClick={onRemove}
        >
          <Spin state={task.state}> {i18n.action_remove()}</Spin>
        </WeakButton>
        {/*<RemoveButton ></RemoveButton>*/}
      </ActionContainer>
    </VStack>
  );
}

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
const RemoveButton = styled.div`
  width: 100%;
  text-align: center;
  color: red;
`;

const CancelButton = styled.div`
  width: 100%;
  text-align: center;
  color: Blue;
`;

const CheckTitle = styled.div`
  flex: 1;
  color: white;
`;
