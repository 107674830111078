import { User } from "../../proto/User";
import styled from "styled-components";
import { Media } from "../../proto/Media";
import { Image, MediaSizeMode } from "../Image";
import moodBg from "../../res/images/icon_mood_bg.png";
import { OnlineStatus } from "../../proto/UserEnums";
import { useHopper } from "../../hooks/useHopper";
import { alpha_on_pressed } from "../CommonStyles";
import deletedIcon from "../../res/images/mask_src_circle.png";
import { ObjectStatus } from "../../proto/ObjectStatus";
import { CSSProperties } from "react";

const ContentView = styled.div`
  position: relative;
  width: 48px;
  height: 48px;
  ${alpha_on_pressed};
`;
export const OnLineStatusView = styled.div`
  position: absolute;
  left: 78%;
  border-radius: 50%;
  width: 20%;
  height: 20%;
`;

const MoodOnLineStatusView = styled.div`
  position: absolute;
  left: 95%;
  border-radius: 50%;
  width: 10%;
  height: 10%;
`;

enum UserAvatarStatus {
  all = 1,
  dot = 2,
}

type UserAvatarProps = {
  user: User | undefined;
  iconSize: number;
  avatarStatus?: UserAvatarStatus;
  onClick?: () => void;
  stopPropagation?: boolean;
  style?: CSSProperties;
  disableClick?: boolean;
};

export function UserMoodView(props: {
  moodIconStr: [Media | undefined, MediaSizeMode];
  status?: OnlineStatus;
}) {
  return (
    <div>
      <Image
        src={moodBg}
        style={{
          left: "65%",
          top: "0%",
          width: "40%",
          height: "38%",
          position: "absolute",
        }}
      ></Image>
      <Image
        src={props.moodIconStr}
        style={{
          width: "25%",
          height: "25%",
          position: "absolute",
          left: "72%",
          top: "8%",
        }}
      ></Image>
      <MoodOnLineStatusView
        style={{
          backgroundColor: getOnLineStatus(props.status),
        }}
      ></MoodOnLineStatusView>
    </div>
  );
}

// x: 0.15 * frame.width,
//   y: 0.15 * frame.height,
//   width: 0.7 * frame.width,
//   height: 0.7 * frame.height

export function getOnLineStatus(status?: OnlineStatus) {
  switch (status) {
    case OnlineStatus.online:
      return "#0DFFAF";
    case OnlineStatus.offline:
      return undefined;
  }
}

export function UserAvatarView(props: UserAvatarProps) {
  const user = props.user;
  const iconMedia: [Media | undefined, MediaSizeMode] | string | undefined =
    user?.status === ObjectStatus.DELETED
      ? deletedIcon
      : !!props.user?.icon
        ? [props.user?.icon, props.iconSize]
        : undefined;
  const hopper = useHopper();
  const moodMedia = props.user?.userMood?.sticker?.media;
  let contents;

  if (iconMedia) {
    contents = (
      <ContentView
        onClick={(event) => {
          if (!props.disableClick) {
            if (props.stopPropagation) {
              event.stopPropagation();
            }
            props.onClick
              ? props.onClick()
              : hopper.push(`user/${props.user?.uid}`);
          }
        }}
        style={{ width: props.iconSize, height: props.iconSize + 2 }}
      >
        <Image
          src={iconMedia}
          style={{
            width: props.iconSize,
            height: props.iconSize,
            borderRadius: props.iconSize / 2,
            position: "absolute",
            border: "1px inset rgba(255, 255, 255, 0.1)",
            left: 0,
          }}
        />
        {moodMedia ? (
          <UserMoodView
            moodIconStr={[moodMedia, 34]}
            status={props.user?.userMood?.onlineStatus}
          ></UserMoodView>
        ) : (
          <OnLineStatusView
            style={{
              backgroundColor: getOnLineStatus(
                props.user?.userMood?.onlineStatus,
              ),
            }}
          ></OnLineStatusView>
        )}
      </ContentView>
    );
  } else {
    contents = <ContentView></ContentView>;
  }

  return <div>{contents}</div>;
}

export function UserIconView(props: UserAvatarProps) {
  const iconMedia = props.user?.icon;
  return (
    <Image
      src={[iconMedia, props.iconSize]}
      style={{
        width: props.iconSize,
        height: props.iconSize,
        borderRadius: "50%",
        ...props.style,
      }}
      onClick={() => {
        if (!props.disableClick) {
          if (props.onClick) {
            props.onClick();
          }
        }
      }}
    />
  );
}
