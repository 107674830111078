import { Page } from "../../components/Page";
import React, { useEffect, useRef, useState } from "react";
import { PagedItem } from "../../components/ViewPager";
import ViewPagerChildPage from "./ViewPagerChildPage";
import { Swiper, SwiperClass, SwiperRef, SwiperSlide } from "swiper/react";
import { SimpleListDemoPage } from "../debug/SimpleListDemoPage";

function ViewPagerDemoPage() {
  const [chosenTabIndex, setChosenTabIndex] = React.useState(0);

  const swiperRef = useRef<SwiperRef | null>(null); // 用于存储 Swiper 实例
  // 当 chosenTabIndex 改变时，滑动到相应的页面
  useEffect(() => {
    swiperRef.current?.swiper?.slideTo(chosenTabIndex); // 调用 Swiper 的 slideTo 方法
  }, [chosenTabIndex]);

  const pagedItems: PagedItem[] = [
    {
      index: 0,
      page: <ViewPagerChildPage index={0} key={"sadasd-0"} />,
      title: "0",
    },
    {
      index: 1,
      page: <ViewPagerChildPage index={1} key={"sadasd-1"} />,
      title: "1",
    },
    {
      index: 2,
      page: <ViewPagerChildPage index={2} key={"sadasd-2"} />,
      title: "2",
    },
    {
      index: 3,
      page: <ViewPagerChildPage index={3} key={"sadasd-3"} />,
      title: "3",
    },
  ];

  return (
    <Page pageData={undefined} scrollPaddingDisabled={true}>
      {/* 将 header 和 Swiper 放在一个共同的滚动容器内 */}
      <div
        id="scrollContainer"
        style={{
          height: "100vh",
          overflowY: "auto", // 使整个页面可以滚动
        }}
      >
        <div
          id="header"
          style={{
            height: "180px",
            border: "1px solid blue",
          }}
        >
          Header Content
        </div>
        {/* Header 部分 */}
        <div
          id="tab-layout"
          style={{
            height: "40px",
            border: "1px solid green",
            position: "sticky",
            top: 0,
            zIndex: 10,
          }}
        >
          Tab Layout Content
        </div>

        {/* Swiper 部分 */}
        <Swiper
          ref={swiperRef}
          onSlideChange={(swiper: SwiperClass) => {
            setChosenTabIndex(swiper.activeIndex);
          }}
          initialSlide={chosenTabIndex}
        >
          {pagedItems.map((item) => (
            <SwiperSlide key={item.index}>{item.page}</SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Page>
  );
}

export default ViewPagerDemoPage;
