import React, { useMemo, useRef } from "react";
import { flattenStateId, StateId } from "./StateId";
import { useDeepChange } from "./ExtHooks";
import { useHopHost } from "./useHopState";
import { andLog } from "../components/handleError";
import {
  getScrollSize,
  ScrollSize,
  useScrollSizeObserver,
} from "./useScrollSizeObserver";

export function useSavedScroll(
  contentId: StateId | undefined,
  isHorizontal?: boolean,
  onScrollSizeChange?: (e: Element) => void,
) {
  const elementId = useMemo(
    () => (contentId ? flattenStateId(contentId) : undefined),
    [contentId],
  );
  const hasRestoredRef = useRef(new Map<string, boolean>());

  const hopHost = useHopHost();

  const timeOut = useRef<any>();

  const scrollerRef = useScrollSizeObserver((scroller) => {
    saveOrRestore(scroller);
    if (onScrollSizeChange) onScrollSizeChange(scroller);
  });

  useDeepChange([elementId, isHorizontal] as const, (prev, current) => {
    if (timeOut.current) {
      clearTimeout(timeOut.current);
    }

    if (prev[0]) hasRestoredRef.current.set(prev[0], false);
  });

  function saveScroll(target: Element) {
    if (!elementId) return;

    const newSavedScroll = getScrollSize(target);

    hopHost.setStateValue(newSavedScroll, elementId).catch(andLog);
  }

  function restoreScroll(target: Element) {
    if (!elementId) return;
    const savedScroll = hopHost.getState<ScrollSize>(elementId);
    if (savedScroll === undefined) {
      hasRestoredRef.current.set(elementId, true);
    } else {
      if (isHorizontal) {
        if (
          target.scrollWidth > 0 &&
          (savedScroll.scrollWidth - target.scrollWidth) / target.scrollWidth <
            0.01
        ) {
          target.scrollLeft = savedScroll.scrollLeft;
          hasRestoredRef.current.set(elementId, true);
        }
      } else {
        if (
          target.scrollHeight > 0 &&
          (savedScroll.scrollHeight - target.scrollHeight) /
            target.scrollHeight <
            0.01
        ) {
          target.scrollTop = savedScroll.scrollTop;
          hasRestoredRef.current.set(elementId, true);
        }
      }
    }
  }

  function saveOrRestore(scroller: Element | null) {
    if (!scroller || !elementId) return;
    if (hasRestoredRef.current.get(elementId)) {
      saveScroll(scroller);
    } else {
      restoreScroll(scroller);
    }
  }

  return {
    scrollRef: scrollerRef,
    onScroll: (event: React.UIEvent<HTMLElement>) => {
      if (timeOut.current) {
        clearTimeout(timeOut.current);
      }

      timeOut.current = setTimeout(() => {
        saveOrRestore(event.currentTarget);
      }, 300);
    },
  };
}
