import React from "react";
import { useWebHost } from "../../hooks/useBridge";
import { useHopper } from "../../hooks/useHopper";
import { DemoCell } from "./DemoCell";
import { andLog } from "../../components/handleError";
import { Page } from "../../components/Page";

export function HapticDemoPage() {
  const hopper = useHopper();
  const webHost = useWebHost();

  return (
    <Page pageData={undefined}>
      <DemoCell
        onClick={() => {
          webHost.haptic("light").catch(andLog);
        }}
      >
        Light
      </DemoCell>

      <DemoCell
        onClick={() => {
          webHost.haptic("medium").catch(andLog);
        }}
      >
        Medium
      </DemoCell>
      <DemoCell
        onClick={() => {
          webHost.haptic("heavy").catch(andLog);
        }}
      >
        Heavy
      </DemoCell>
      <DemoCell
        onClick={() => {
          webHost.haptic("soft").catch(andLog);
        }}
      >
        Soft
      </DemoCell>
      <DemoCell
        onClick={() => {
          webHost.haptic("rigid").catch(andLog);
        }}
      >
        Rigid
      </DemoCell>
      <DemoCell
        onClick={() => {
          webHost.vibrate().catch(andLog);
        }}
      >
        Vibrate
      </DemoCell>
    </Page>
  );
}
