import { z } from "zod";

export const APIErrorRespBody = z.object({
  apiCode: z.number(),
  apiMsg: z.string(),
  debugMsg: z.string().optional(),
  redirectUrl: z.string().optional(),
});

export enum APIErrorCode {
  APICodeOK = 0,
  APICodeNeedToUpdateApp = 4,

  //Request header error
  APICodeInvalidHeader = 1000,
  APICodeBadRequest = 1001,
  APICodeReadHeaderError = 1002,
  APICodeBasicInfoError = 1003,
  APICodeNotSupportedLanguage = 1004,
  APICodeRateLimit = 1005,
  APICodeRequestSignatureWrong = 1006,
  APICodeWSServiceStopped = 1007,
  APICodeCaptchaTriggered = 1008,
  APICodeCaptchaFailed = 1009,

  // claim sticker error
  APICodeClaimStickerOpenDaysNotEnough = 1010,
  APICodeClaimStickerAppNotRated = 1011,
  APICodeClaimStickerFansCountNotEnough = 1012,
  APICodeClaimStickerNotGetNewUserAchievement = 1013,
  APICodeClaimStickerInviteFriendsCountTooLittle = 1014,
  APICodeClaimStickerSocialInviteCountTooLittle = 1015,
  APICodeClaimStickerFollowingCountTooLittle = 1016,

  APICodePushAndWidgetNotEnabled = 1017,
  APICodePushNotEnabled = 1018,

  APICodeTooLongContentLength = 1101,
  APICodeTooLongContentLengthWithOutTips = 1102,

  // Blog
  APICodeReachMaxCirclePinnedBlogCount = 1201,
  APICodeBlogNotFound = 1202,
  APICodeBlogDisabled = 1203,
  APICodeBlogPopularListNotEnough = 1204,
  APICodeBlogLinkCircleWrong = 1205,
  APICodeReachMaxUserPinnedBlogCount = 1206,
  APICodeBlogOldEditorCanNotEdit = 1207,
  APICodeAddContentToDisabledBlog = 1208,
  APICodeBlogTitleInvalid = 1209,

  // post
  APICodePostNotFound = 1260,

  // for all object db insert
  APICodeAddDuplicateError = 1301,

  //for all object not available
  APICodeContentNotAvailable = 1401,

  //poll
  APICodePollCannotEdit = 1501,
  APICodePollHasVoted = 1502,
  APICodePollHasChangedVote = 1503,
  APICodePollNotExist = 1504,

  //Auth error
  APICodeUidAccountNotExisted = 2001,
  APICodeSessionExpired = 2002,
  APICodeInvalidSession = 2004,
  APICodeSecurityCodeError = 2005,
  APICodePasswordError = 2006,
  APICodeInvalidDeviceId = 2007,
  APICodeCreateSessionFailed = 2008,
  APICodeEmailAccountNotExisted = 2009,
  APICodePasswordWrong = 2010,
  APICodeAccountStatusAbnormal = 2011,
  APICodeNotSupportAuthType = 2012,
  APICodePhoneNumberError = 2013,
  APICodePhoneNumberExisted = 2014,
  APICodePhoneNumberNotSupportArea = 2015,
  APICodePhoneNumberAccountNotExisted = 2016,
  APICodeCannotGetGoogleAccount = 2017,
  APICodeGoogleAccountAuthFailed = 2018,
  APICodeGoogleAccountNotExisted = 2019,
  APICodeFetchApplePublicKeyFailed = 2020,
  APICodeAppleAuthorizeFailed = 2021,
  APICodeEmailFormatError = 2022,
  APICodePTokenError = 2023,
  APICodeSessionEmpty = 2024,
  APICodeUpdatePhoneNumberFailed = 2025,
  APICodeUpdateEmailFailed = 2026,
  APICodeDeleteAccountFailed = 2027,
  APICodeSocialIdCannotModify = 2028,
  APICodeSocialIdIllegal = 2029,
  APICodeModifySocialIdFailed = 2030,
  APICodeSocialIdAlreadyExisted = 2031,
  APICodeCreatePTokenFailed = 2032,
  APICodeLoginFailed = 2033,
  APICodeUserProfileNotExisted = 2034,
  APICodeSessionDeviceIdError = 2035,
  APICodeUpdateTokenFailed = 2036,
  APICodeSessionUserHasNoRight = 2037,
  APICodeEmailAccountExisted = 2038,
  APICodeSocialIdNotExisted = 2039,
  APICodeSocialInfoObjectIdNotExisted = 2040,
  APICodePasswordCannotContainEmailOrPhoneNumber = 2041,
  APICodePurposeError = 2042,
  APICodeSecurityCodeTooFrequent = 2043,
  APICodeSecurityCodeTimeOut = 2044,
  APICodeEmailInvalidDomain = 2045,
  APICodeGoogleAccountAlreadyAssociated = 2046,
  APICodeGoogleAccountNotAssociated = 2047,
  APICodeGoogleAccountMissingEmail = 2048,
  APICodeGoogleAccountUnverifiedEmail = 2049,
  APICodeGoogleAccountNotAssociatedButGmailAssociated = 2050,
  APICodeFacebookAccountAlreadyAssociated = 2051,
  APICodeFacebookAccountNotAssociated = 2052,
  APICodeFacebookAccountMissingEmail = 2053,
  APICodeFacebookAccountUnverifiedEmail = 2054,
  APICodeFacebookAccountNotAssociatedButEmailAssociated = 2055,
  APICodeAppleAccountAlreadyAssociated = 2056,
  APICodeAppleAccountNotAssociated = 2057,
  APICodeAppleAccountMissingEmail = 2058,
  APICodeAppleAccountNotAssociatedButEmailAssociated = 2059,
  APICodeFacebookAccountAuthFailed = 2060,
  APICodeThirdPartAccountEmailInvalid = 2061,
  APICodeAppleAccountAuthFailed = 2062,
  APICodeIPBanned = 2063,
  APICodeDeviceId2Banned = 2064,
  APICodeGoogleAccountAuthTokenUsedTooLate = 2065,
  APICodeLoginPasswordWrongTooManyTimes = 2066,
  APICodeRevokeAppleAccountFailed = 2067,
  APICodeSSDeviceNotFound = 2068,
  APICodeShadowAccountCannotAccessThisAPI = 2069,
  APICodeInvalidShadowAccountAuthToken = 2070,

  APICodeAccountDeletedCanBeReactivated = 2071,

  APICodePhoneNumNotMatch = 2072,
  APICodeEmailNotMatch = 2073,
  APICodeAccountHasBeenDeleted = 2074,
  APICodeHasCircleOrChatAdminRole = 2075,
  APICodeDisabledDueToTOSViolations = 2076,

  //huawei auth

  APICodeHuaweiAccountAuthFailed = 2080,
  APICodeHuaweiAccountMissingEmail = 2081,
  APICodeHuaweiAccountUnverifiedEmail = 2082,
  APICodeHuaweiAccountAlreadyAssociated = 2083,
  APICodeHuaweiAccountNotAssociated = 2084,
  APICodeHuaweiAccountNotAssociatedButEmailAssociated = 2085,

  //ShadowAccount Related

  APICodeShadowAccountTargetUserNotAuthorized = 2100,
  APICodeShadowAccountTargeUserStatusAbnormal = 2101,
  APICodeShadowAccountCurrentDeviceNotDebugMode = 2102,
  APICodeShadowAccountOpAccountTerminated = 2103,

  //Profile error

  APICodeDeviceAccountCountOutOfRange = 3001,
  APICodeCreateAccountError = 3003,
  APICodeUserProfileEmpty = 3004,
  APICodeNicknameIllegal = 3005,
  APICodeGenderIllegal = 3006,
  APICodeAgeIllegal = 3007,
  APICodeSchoolIllegal = 3008,
  APICodeIconIllegal = 3009,
  APICodeNewUserProfileFailed = 3010,
  APICodeCreateProfileWithoutAccount = 3011,
  APICodeCreateDuplicatedProfile = 3012,
  APICodeChatInvitationStatusIllegal = 3013,
  APICodeShowsJoinedCirclesIllegal = 3014,
  APICodeShowsLocationIllegal = 3015,
  APICodeUpdateProfileError = 3016,
  APICodeDeleteProfileError = 3017,
  APICodeContentRegionIllegal = 3018,
  APICodeShowsSchoolIllegal = 3019,
  APICodeBioIllegal = 3020,
  APICodeNameCardFlagIllegal = 3021,
  APICodeEnableNameCardTagEmpty = 3022,
  APICodeEnableNameCardBioEmpty = 3023,
  APICodeSpecialTitleIllegal = 3024,
  APICodeUserStatusIllegal = 3025,
  APICodeLocationIllegal = 3026,
  APICodeVoiceBioError = 3027,
  APICodeBackgroundError = 3028,
  APICodeNameCardBackgroundError = 3029,
  APICodeIconError = 3033,
  APICodeAchievementNotFound = 3035,
  APICodeAchievementAlreadyClaim = 3036,
  APICodeAchievementNotAvailable = 3037,
  APICodeAchievementLinkTaskDuplicate = 3038,
  APICodeAchievementClaimNoteQualified = 3039,
  APICodeAchievementAlreadyGrant = 3040,
  APICodeTaglineExceedMaxLengthLimitation = 3041,
  APICodeTaglineIllegal = 3042,
  APICodeTimeCanEditVisitModeNotYet = 3043,
  APICodeNotAllowModifyGender = 3044,
  APICodeNotAllowModifyBirthday = 3045,
  APICodeModifyGenderUpgradeAppPrompt = 3046,
  APICodeSystemAdminUidCantBeRequested = 3047,

  //User membership
  APICodeFollowFailedDueToBeBlocking = 3101,
  APICodeFollowFailedDueToBeBlocked = 3102,
  APICodeFollowSelf = 3103,
  APICodeUnFollowSelf = 3104,

  //User block
  APICodeUserBlockingSendMsgError = 3201,
  APICodeUserBeingBlockedSendMsgError = 3202,
  APICodeUserBlockingStartThreadError = 3203,
  APICodeUserBeingBlockedStartThreadError = 3204,
  APICodeUserBlockingCommentError = 3205,
  APICodeUserBlockedCommentError = 3206,
  APICodeUserBlockingVoteError = 3207,
  APICodeUserBlockedVoteError = 3208,
  APICodeBlockUserExceedMaxCount = 3209,
  APICodeUserBlockSelf = 3210,

  //Invitation
  APICodeInvitationCodeNotExisted = 3301,
  APICodeInvitationCodeAlreadyUsed = 3302,
  APICodeInvitationCodeGenFailed = 3303,
  APICodeInvitationCodeInvalidCode = 3304,
  APICodeInvitationCodeExceedMaxLimitation = 3305,
  APICodeInvitationCodeStatusError = 3306,
  APICodeInvitationCodeBadLabel = 3307,
  APICodeCooperationVideoPromoDateOverlapping = 3308,

  //es
  APICodeAddSearchDocumentError = 3401,
  APICodeUpdateSearchDocumentError = 3402,
  APICodeDeleteSearchDocumentError = 3403,
  APICodeIndexNameNotSupport = 3404,

  //flag
  APICodeNotSupportFlagObject = 3501,
  APICodeFlagUsersMustUploadAnImage = 3502,

  //match
  APICodeNoMatchTimes = 3601,
  APICodeNotSupportMatchUserStatus = 3602,
  APICodeBottleNotFound = 3603,
  APICodeHasBeenMatchStrike = 3604,
  APICodeCantUseMatchCard = 3605,

  //Circle error
  APICodeCircleCreateExceedMaxCount = 4001,
  APICodeCircleNameIllegal = 4002,
  APICodeCircleTaglineIllegal = 4003,
  APICodeCircleIconIllegal = 4004,
  APICodeCircleBackgroundIllegal = 4005,
  APICodeCircleCreateFailed = 4006,
  APICodeCircleLanguageIllegal = 4007,
  APICodeCircleNotExisted = 4008,
  APICodeUpdateCircleFailed = 4009,
  APICodeCircleStatusAbnormal = 4010,
  APICodeCircleManageError = 4011,
  APICodeCircleUserBeenBlocked = 4013,
  APICodeCircleCannotBeDeletedUserCountReachThreshold = 4014,
  APICodeCircleInfoNotExisted = 4015,
  APICodeJoinCircleExceedMaxLimitation = 4016,
  APICodeJoinCircleError = 4017,
  APICodeLeaveCircleError = 4018,
  APICodeCircleDescriptionIllegal = 4019,
  APICodeDuplicateCircleName = 4020,
  APICodeCirclePopularListNotEnough = 4021,
  APICodeCircleReachMaxAdminCount = 4022,
  APICodeCircleReachMaxCoAdminCount = 4023,
  APICodeAbnormalStateOnPromotion = 4024,
  APICodeCircleTakeTooManyCircleAdmin = 4025,
  APICodeCircleAlreadySendJoinRequest = 4026,
  APICodeCircleAlreadyJoined = 4027,
  APICodeCircleJoinApprovalRequired = 4028,
  APICodeUpdateCircleVisibilityFrequently = 4029,
  APICodePasswordFormatError = 4030,
  APICodeUserHasMuteStrike = 4031,
  APICodeThreadReachMaxCoHostCount = 4032,
  APICodeNoAccessReLinkBlogToCircle = 4033,
  APICodeNoAccessReLinkChatToCircle = 4034,
  APICodeCircleCoverIllegal = 4035,
  APICodeCircleThemeColorIllegal = 4036,
  APICodeCircleOnlyAdminCanManage = 4037,
  APICodeCircleReachMaxHomeTabCount = 4038,
  APICodeCircleHomeTabObjectDuplicate = 4039,
  APICodeCircleHomeTabNameIsInvalid = 4040,
  APICodeCircleHomeLayoutNotFound = 4041,
  APICodeCircleAnnouncementPushNotFound = 4042,
  APICodeCircleTooManyAnnouncementPush = 4043,
  APICodeCircleAnnouncementPushStatusChanged = 4044,
  APICodeCircleJoinRequestHasBeenHandled = 4045,
  APICodeCirclePleaseCheckBlockedMembersList = 4046,
  APICodeCircleNotAllowMembersToInvite = 4047,
  APICodeCircleInvalidJoinPasscode = 4048,
  APICodeCircleInvalidInvitationLink = 4049,
  APICodeCircleUnableToJoinCircle = 4050,
  APICodeCircleJoinPermissionIsFullLocked = 4051,
  APICodeCircleMemberTitleInvalid = 4052,
  APICodeAlreadyExistsCircleMemberTitle = 4053,
  APICodeCircleReachMaxTitleCountForAMember = 4054,
  APICodeCircleReachMinHomeTabCount = 4055,
  APICodeCircleMemberTitleBeDisabled = 4056,
  APICodeNotAddItemToDisabledFolder = 4057,
  APICodeNotAddContentToDisabledCircle = 4058,

  needPurchaseMembershipCard = 4066,

  //Feed Related error
  APICodeNotSupportFeedType = 4501,

  // link
  APICodeShareObjectNotSupport = 4601,
  APICodeGenerateShareLinkError = 4602,
  APICodeResolveLinkError = 4603,
  APICodeShareLinkError = 4604,

  //Search Related error
  APICodeNotSupportSearchObject = 4801,

  //Tag service
  APICodeTagIllegal = 5001,
  APICodeTagCountExceedLimitation = 5002,
  APICodeTagNotExisted = 5003,
  APICodeTagStatusAbornormal = 5004,
  APICodeCategoryNotFound = 5005,
  APICodeUpdateCategoryAgainError = 5006,

  //Persona service
  APICodeCreateTemplateFailed = 5101,
  APICodeAddInsFailed = 5102,
  APICodeChooseInsFailed = 5103,

  //QIVote
  APICodeVotingDateLessThanLastVotedDate = 5201,
  // Chat error
  APICodeThreadNotJoined = 6000,
  APICodeThreadNotFound = 6001,
  APICodeThreadMemberCanNotInvite = 6002,
  APICodeChatRoleNotFound = 6003,
  APICodeChatRoleShouldQuit = 6004,
  APICodeThreadMemberBeRemoved = 6005,
  APICodeChatRoleNameDuplicate = 6006,
  APICodeChatRoleTakenByOthers = 6007,
  APICodeThreadMemberWithoutInvitation = 6008,
  APICodeChatMessageNotExist = 6009,
  APICodeChatNameIsInvalid = 6010,
  APICodeThreadDisabled = 6011,
  APICodeReachMaxCirclePinnedChatCount = 6012,
  APICodeChatInvitationStatusLimited = 6013,
  APICodeChatSenderNotAllowed = 6014,
  APICodeChatMemberReachedMaximumLimit = 6015,
  APICodeChatMemberNotQualifiedToBeCoHost = 6016,
  APICodeThreadHaveAnotherActivity = 6017,
  APICodeChatMemberMutedByHost = 6018,
  APICodeContainerStatusIsAbandoned = 6020,
  APICodeCannotAcceptAdmin = 6021,
  APICodeInvitationNoValid = 6022,
  APICodeMustNotTakeManagementRole = 6023,
  APICodeChatManagerBeRemoved = 6024,
  APICodeCircleManagerBeRemoved = 6025,
  APICodeThreadDeleted = 6026,
  APICodeCircleDisabled = 6027,
  APICodeCircleDeleted = 6028,
  APICodeCanNotJoinChat = 6029,
  APICodeChatContainerStatusIsAbandoned = 6030,
  APICodeCircleContainerStatusIsAbandoned = 6031,
  APICodeApplicationSubmitted = 6032,
  APICodeCircleNotJoined = 6033,
  APICodeJoinedTooManyThreads = 6034,
  APICodeVideoUnavailable = 6035,
  APICodeHasUnJoinedMatchThread = 6036,
  APICodeUpdateChatLanguageCodeAgainError = 6037,
  APICodeChatCannotBeDeletedDue2TooManyUsers = 6038,
  APICodeChatRoleDisabled = 6039,

  APICodeChatOnlySendTextMassage = 6040,
  APICodePartyAdminCantLeaveParty = 6041,
  APICodeCantGetRandomParty = 6042,
  APICodePartyOffline = 6043,
  APICodePartyMemberReachedMaximumLimit = 6044,
  APICodePartyCannotBeDeletedDue2TooManyUsers = 6045,
  APICodePartyDisabled = 6046,
  APICodePartyMemberCanNotInvite = 6047,
  APICodePartyNameIsInvalid = 6048,
  APICodePartyDeleted = 6049,
  APICodeCanNotJoinParty = 6050,
  APICodeNotAddContentToDisabledParty = 6051,
  APICodePartyMemberBeBlocked = 6052,
  APICodeCharacterChatWaiting = 6053,
  APICodeRoleNameInvalid = 6054,
  APICodeCharacterHitBlackList = 6055,
  APICodeReachMaxChatCharacterCount = 6056,

  // Comment error
  APICodeCommentNotFound = 6100,
  APICodeCommentedIsNotAvailableForPost = 6101,
  APICodeCommentedIsNotAvailableForUser = 6102,
  APICodeCommentContentAndMediaListIsNil = 6104,
  APICodeCommentParentTypeIsInvalid = 6105,
  APICodeCommentReplyIdAndUidIsInvalid = 6106,

  APICodeTooManyRequest = 6107,
  // pinned too many comment
  APICodePinnedTooManyComment = 6108,
  APICodeContentSpam = 6109,

  // User error
  APICodeUserDisabled = 6200,

  // Character error
  APICodeCharacterNameInvalid = 6300,
  APICodeCharacterGenderInvalid = 6301,
  APICodeCharacterDescriptionInvalid = 6302,
  APICodeCharacterGreetingMsgInvalid = 6303,
  APICodeCharacterSubtitleInvalid = 6304,

  // mood
  APICodeCustomMoodDuplicate = 6500,

  // Media
  APICodeBadMediaFormat = 7000,
  APICodeBadMediaTarget = 7001,
  APICodeBadMediaType = 7002,
  APICodeVideoTooLarge = 7004,
  APICodeImageTooLarge = 7005,
  APICodeAudioTooLarge = 7006,
  APICodeBadMediaURL = 7007,
  APICodeBadMediaStatus = 7008,
  APICodeBadMediaCount = 7009,

  //Operation Event
  APICodeOperationEventNotFound = 7100,
  APICodeOperationEventStatusAbnormal = 7101,

  //Circle Folder
  APICodeCircleFolderNotExist = 7200,
  APICodeAddFolderItemTypeInvalid = 7201,
  APICodeFolderNameLengthInvalid = 7202,
  APICodeObjectAlreadyInFolder = 7203,
  APICodeFolderDescriptionLengthInvalid = 7204,
  APICodeReachMaxFolderPinnedItemCount = 7205,
  APICodeCircleFolderItemNotExist = 7206,
  APICodeFolderTypeInvalid = 7207,
  APICodeFolderPermissionTypeInvalid = 7208,
  APICodeFolderSectionHeaderInvalidLength = 7209,
  APICodeObjectLinkFolderReachMaximumNumberOfFolders = 7210,
  APICodeContentFolderBlocked = 7211,
  APICodeEditBuiltinFolder = 7212,
  APICodeReorderFolderItem = 7213,
  APICodeDeleteCircleFolderHasSubFolder = 7214,
  APICodeRemoveBuiltinFolderFromMenu = 7215,
  APICodeDeleteBuiltinCircleFolder = 7216,
  APICodeCircleFolderInvalidPinItem = 7217,
  APICodeAddFolderReachMaximumNumberOfFolders = 7218,
  APICodeFolderLinkItemInvalid = 7219,
  APICodeContentCircleBlocked = 7220,
  APICodeFolderPermissionDenied = 7221,
  APICodeFolderWidgetItemInvalid = 7222,
  APICodeObjectNameIsLocked = 7223,
  APICodeLinkNameInvalid = 7224,
  APICodeLinkInvalid = 7225,
  APICodeFolderWidgetTitleInvalid = 7226,
  APICodeInvalidSocialLink = 7227,
  APICodeImageNotReady = 7228,
  APICodeAIGenImageFailed = 7229,
  APICodeAIGenImageRecordCountLimit = 7230,
  APICodePromptInvalid = 7231,
  APICodeAIImageStyleUnsupported = 7232,
  APICodePromptImageBlock = 7233,
  APICodeAIGenImageRecordPendingCountLimit = 7234,
  APICodeChatRoleNoteInvalid = 7235,
  APICodeCommentedIsNotAvailableForNFT = 7236,
  APICodeServiceCurrentlyUnavailable = 7237,

  APICodePhoneNumberCountryNotSupported = 7249,

  APICodeCircleTokenCreateFailed = 7276,

  //remark
  APICodeInvalidRemark = 7300,

  APICodeCheckedIn = 7310,
  APICodeResendCheckInReward = 7311,
  APICodeCampaignConflicts = 7312,

  //zai
  APICodeAIServiceRateLimit = 8000,

  //Internal server error
  APICodeInternalServerError = 9000,
  APICodePermissionDenied = 9001,
}
