import { useMemo } from "react";

class Detector {
  private count = 0;
  private lastTimeMs = 0;
  private startTimeMs = 0;

  private reset(now: number) {
    this.startTimeMs = now;
    this.count = 0;
  }

  render() {
    const now = new Date().getTime();
    this.lastTimeMs = now;

    if (this.startTimeMs === 0) {
      this.reset(now);
    } else {
      if (now - this.lastTimeMs < 3000) {
        this.count += 1;
        if (this.count > 100) {
          const error = new Error(
            `A components is rendered more than 100 times within ${now - this.startTimeMs} milliseconds.`,
          );
          console.error(`Over Render Detected`, error);
          this.reset(now);
        }
      } else {
        this.reset(now);
      }
    }
  }
}

export function useRenderDetection() {
  const detector = useMemo(() => new Detector(), []);
  detector.render();
}
