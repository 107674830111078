import { Image } from "../../../components/Image";
import { Chat } from "../../../proto/Chat";
import styled from "styled-components";

const Card = styled.div`
  display: flex;
  width: 30px;
  height: 44px;
  position: relative;
  overflow: clip;
  align-content: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.2);
`;

const Color = styled.div<{ $fillColor?: string }>`
  width: 100%;
  height: 100%;
  background-color: ${(p) => p.$fillColor};
`;

export default function ChatBackgroundSmallCard(props: { chat: Chat }) {
  return (
    <Card>
      {props.chat.backgroundColor && (
        <Color $fillColor={props.chat.backgroundColor} />
      )}
      {props.chat.background && (
        <Image src={[props.chat.background, "smallest"]}></Image>
      )}
    </Card>
  );
}
