import { Image } from "../Image";
import React from "react";
import { User } from "../../proto/User";
import { HStack } from "../VStack";
import styled from "styled-components";

export function StackUserIconView(props: {
  users: User[];
  iconSize: number;
  offset: number;
}) {
  return (
    <HStack
      style={{
        height: props.iconSize,
      }}
    >
      {props.users.slice(0, 4).map((user, i) => (
        <div
          key={user.uid}
          style={{
            zIndex: i,
            position: "relative",
            marginInlineStart: i === 0 ? 0 : props.offset,
          }}
        >
          <Image
            src={[user.icon, "smallest"]}
            width={props.iconSize}
            height={props.iconSize}
            key={user.uid}
            style={{
              borderRadius: "50%",
              border: "1px solid #FFFFFF33",
              boxSizing: "border-box",
            }}
          />
          {i === 3 ? (
            <Mask
              style={{
                borderRadius: "50%",
                width: props.iconSize,
                height: props.iconSize,
                boxSizing: "border-box",

                border: "1px solid #FFFFFF33",
              }}
            >
              {props.users.length}
            </Mask>
          ) : undefined}
        </div>
      ))}
    </HStack>
  );
}

const Mask = styled.div`
  position: absolute;
  display: grid;
  align-items: center;
  justify-content: center;
  background-color: #000000cc;
  top: 0;
`;
