import React, { PropsWithChildren, useEffect } from "react";
import {
  Animation,
  ModalDimmer,
  ModalRelay,
  requireModalController,
  useModal,
} from "../components/Modal";
import { HopperMode, useHopper } from "../hooks/useHopper";
import { usePageSpec } from "../pages/common/usePageSpec";
import { NavBarBoundary } from "../components/NavBar";
import styled from "styled-components";
import {
  FadeIn,
  FadeOut,
  None,
  SlideDown,
  SlideDownToEdge,
  SlideInFromEnd,
  SlideInFromStart,
  SlideOutToEnd,
  SlideOutToStart,
  SlideUp,
  SlideUpFromEdge,
} from "../components/Keyframes";

const WideSlideFrame = styled.div<{ $animation: Animation }>`
  position: relative;
  width: 60%;
  max-width: 800px;
  height: 80%;

  border-radius: 18px;
  border: solid 1px rgba(128, 128, 128, 0.5);

  box-sizing: border-box;
  overflow: hidden;

  animation: ${(p) =>
      p.$animation === Animation.None
        ? None
        : p.$animation === Animation.In
          ? SlideUpFromEdge
          : SlideDownToEdge}
    0.3s ease-out 1 forwards;

  animation-fill-mode: both;
`;

const NarrowSlideFrame = styled.div<{ $animation: Animation }>`
  position: relative;
  width: 100%;
  height: 100%;

  box-sizing: border-box;
  overflow: hidden;

  animation: ${(p) =>
      p.$animation === Animation.None
        ? None
        : p.$animation === Animation.In
          ? SlideUp
          : SlideDown}
    0.3s ease-out 1 forwards;

  animation-fill-mode: both;
`;

const Container = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
`;

function useLayerModal(layerId: string, relay: ModalRelay) {
  const modal = useModal(layerId);

  relay.attach(modal);

  useEffect(() => {
    modal.open();
  }, [layerId]);

  requireModalController(modal);

  return modal;
}

function Default(
  props: PropsWithChildren<{
    layerId: string;
    relay: ModalRelay;
    mode: HopperMode;
  }>,
) {
  const modal = useLayerModal(props.layerId, props.relay);

  const hopper = useHopper();

  const pageSpec = usePageSpec();
  const inner = (
    <NavBarBoundary
      canDismiss={true}
      onBackward={(type) => {
        if (type === "dismiss") {
          modal.close(() => hopper.dismissLayer());
        } else {
          hopper.back();
        }
      }}
    >
      {props.children}
    </NavBarBoundary>
  );

  const Frame = pageSpec === "wide" ? WideSlideFrame : NarrowSlideFrame;

  return modal.mounted ? (
    <Container style={{ justifyContent: "center", alignItems: "center" }}>
      <ModalDimmer $animation={modal.animation}></ModalDimmer>

      <Frame $animation={modal.animation} onAnimationEnd={modal.onAnimationEnd}>
        {inner}
      </Frame>
    </Container>
  ) : null;
}

const PopOverFrame = styled.div<{ $animation: Animation }>`
  position: relative;
  width: 60%;
  max-width: 800px;
  height: 80%;

  border-radius: 18px;
  border: solid 1px rgba(128, 128, 128, 0.5);

  box-sizing: border-box;
  overflow: hidden;

  animation: ${(p) =>
      p.$animation === Animation.None
        ? None
        : p.$animation === Animation.In
          ? FadeIn
          : FadeOut}
    0.3s ease-out 1 forwards;

  animation-fill-mode: both;
`;

function PopOver(
  props: PropsWithChildren<{
    layerId: string;
    relay: ModalRelay;
    mode: HopperMode;
  }>,
) {
  const modal = useLayerModal(props.layerId, props.relay);

  const hopper = useHopper();

  return modal.mounted ? (
    <Container style={{ justifyContent: "center", alignItems: "center" }}>
      <ModalDimmer $animation={modal.animation} onClick={() => hopper.back()} />

      <PopOverFrame
        $animation={modal.animation}
        onAnimationEnd={modal.onAnimationEnd}
      >
        {props.children}
      </PopOverFrame>
    </Container>
  ) : null;
}

const StartSideFrame = styled.div<{ $animation: Animation }>`
  position: relative;
  width: 80%;
  max-width: 500px;
  height: 100%;

  box-sizing: border-box;
  overflow: hidden;

  animation: ${(p) =>
      p.$animation === Animation.None
        ? None
        : p.$animation === Animation.In
          ? SlideInFromStart
          : SlideOutToStart}
    0.3s ease-out 1 forwards;

  animation-fill-mode: both;
`;

function StartSide(
  props: PropsWithChildren<{
    layerId: string;
    relay: ModalRelay;
    mode: HopperMode;
  }>,
) {
  const modal = useLayerModal(props.layerId, props.relay);

  const hopper = useHopper();

  return modal.mounted ? (
    <Container
      style={{
        justifyContent: "start",
        alignItems: "stretch",
        flexDirection: "row",
      }}
    >
      <ModalDimmer $animation={modal.animation} onClick={() => hopper.back()} />

      <StartSideFrame
        $animation={modal.animation}
        onAnimationEnd={modal.onAnimationEnd}
      >
        {props.children}
      </StartSideFrame>
    </Container>
  ) : null;
}

const EndSideFrame = styled.div<{ $animation: Animation }>`
  position: relative;
  width: 80%;
  max-width: 500px;
  height: 100%;

  box-sizing: border-box;
  overflow: hidden;

  animation: ${(p) =>
      p.$animation === Animation.None
        ? None
        : p.$animation === Animation.In
          ? SlideInFromEnd
          : SlideOutToEnd}
    0.3s ease-out 1 forwards;

  animation-fill-mode: both;
`;

function EndSide(
  props: PropsWithChildren<{
    layerId: string;
    relay: ModalRelay;
    mode: HopperMode;
  }>,
) {
  const modal = useLayerModal(props.layerId, props.relay);

  const hopper = useHopper();

  return modal.mounted ? (
    <Container
      style={{
        justifyContent: "end",
        alignItems: "stretch",
        flexDirection: "row",
      }}
    >
      <ModalDimmer $animation={modal.animation} onClick={() => hopper.back()} />

      <EndSideFrame
        $animation={modal.animation}
        onAnimationEnd={modal.onAnimationEnd}
      >
        {props.children}
      </EndSideFrame>
    </Container>
  ) : null;
}

export function LayerModeSwitch(
  props: PropsWithChildren<{
    layerId: string;
    relay: ModalRelay;
    mode: HopperMode;
  }>,
) {
  switch (props.mode) {
    case "layer":
      return <Default {...props} />;
    case "popOver":
      return <PopOver {...props} />;
    case "startSide":
      return <StartSide {...props} />;
    case "endSide":
      return <EndSide {...props} />;
    case "modal":
      return <>{props.children}</>;
    default:
      return null;
  }
}
