import { z } from "zod";
import { zBigInt, zStatic } from "../utils/zodUtils";
import { Media } from "./Media";

export const Dice = z.object({
  diceId: zBigInt,
  name: z.string().default(""),
  icon: Media.optional(),
  animation: Media,
  sides: z.array(Media),
});

export type Dice = zStatic<typeof Dice>;
