import React from "react";
import { Instruction } from "../../components/Instruction";
import { DemoNote } from "./DemoCell";
import { reactPrintF } from "../../hooks/useI18n";
import { Image } from "../../components/Image";
import ic_energy from "../../res/images/ic_energy.webp";
import { Page } from "../../components/Page";

const instruction = `You can contribute to your community through:
- **Social Activities:** Engage with your circle daily by interacting with fellow members and inviting new members to join.
- **Content Contribution:** Create high-quality content that adds positive value to your circle. Note that inappropriate content and behavior will negatively impact your contribution.
- **Financial Contribution:** Support your circle by helping the token launch, holding and staking community tokens, or providing liquidity to the token DEX LP.`;

export function InstructionDemoPage() {
  return (
    <Page pageData={undefined}>
      <DemoNote>Simple</DemoNote>
      <Instruction>
        {
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc imperdiet, dolor non pulvinar fermentum, erat augue condimentum leo, non cursus velit nisl ac felis. Nullam quis vestibulum tellus, nec commodo orci. Proin odio ante, sodales quis pellentesque sit amet, aliquam sit amet ex. Sed a velit dignissim, aliquet nibh ut, rutrum mauris. Donec id augue et lectus tristique lacinia dignissim quis leo. Donec eget dignissim mi. Mauris pulvinar placerat dolor sit amet vulputate. Aliquam erat volutpat. Sed vel lacus congue, congue metus et, gravida erat."
        }
      </Instruction>

      <DemoNote>Markdown</DemoNote>
      <Instruction>{instruction}</Instruction>

      <DemoNote>React Markdown</DemoNote>
      <Instruction>
        {reactPrintF(
          "Lorem ipsum %s sit amet\n- Lorem ipsum dolor sit amet, %s adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.\n- Nunc %s imperdiet, \n\n\n\ndolor non pulvinar fermentum",
          <span style={{ color: "red" }}>dolor</span>,
          <span style={{ fontSize: 20, fontWeight: 600, color: "yellow" }}>
            consectetur
          </span>,

          <Image width={30} height={30} src={ic_energy} alt={"energy"} />,
        )}
      </Instruction>
    </Page>
  );
}
