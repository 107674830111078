import { Page } from "../../components/Page";
import { NavMiddle } from "../../components/NavBar";
import { useI18n } from "../../hooks/useI18n";
import React from "react";
import { useCircleSWR, useTokenSWR } from "./useCircleSWR";
import { useMyUid } from "../../service/AuthSessionService";
import { Image } from "../../components/Image";
import { useBackend } from "../../service/APIService";
import {
  CircleToken,
  isValidCircleToken,
  ProjectLaunchedPlatform,
  ProjectSource,
} from "../../proto/CircleFin";
import styled from "styled-components";
import { HStack, Spring } from "../../components/VStack";
import { Property } from "csstype";
import icClover from "../../res/images/ic_clover.png";
import { ContentGroup, GroupCellDivider } from "../../components/ContentGroup";
import { formatMoney, getChainIcon } from "../../proto/Currency";
import { GetChainName } from "./TokenDesignPage";
import icCopy from "../../res/images/ic_copy.svg";
import { dealStringByLimit } from "../../utils/StringUtil";
import icDoubleArrow from "../../res/images/ic_double_arrow.svg";
import icRaydium from "../../res/images/ic_raydium.svg";
import { useCopyText } from "../../utils/CopyText";
import { andLog } from "../../components/handleError";
import { useNativePage, useWebHost } from "../../hooks/useBridge";
import { CircleTokenDeployStatus } from "./CircleTokenCreateIntroducePage";
import { useHopState } from "../../hooks/useHopState";
import { useHopper } from "../../hooks/useHopper";
import { formatPercentage } from "../../utils/NumberI18n";
import pageBg from "../../res/images/token_deploy_status_bg.png";
import CircleTokenDeployStatusContent from "./CircleTokenDeployStatusContent";
import { Circle, CircleTokenLinkType, isCircleAdmin } from "../../proto/Circle";
import { OrderType } from "../wallet/order/OrderDetailPage";
import {
  alpha_on_pressed,
  cell_on_pressed,
  shrink_on_pressed,
} from "../../components/CommonStyles";
import { getCircleTokenStageImage } from "./CircleDetailInfoPage";
import BigNumber from "bignumber.js";
import GridLayout from "../../components/GridLayout";
import icSolScan from "../../res/images/ic_sol_scan.png";
import { isSpongeKit } from "../../utils/isSpongeKit";
import {
  circleTokenFluctuationImage,
  marketCap1HipBackgroundColor,
  marketCap1HipColor,
  marketCap1HipString,
} from "./CircleStatsPage";
import { CircleTokenStatus } from "../../proto/CircleTokenStatus";

export enum CircleTokenStage {
  Incubation = 1,
  Launched = 2,
}

export function toDeployStatus(
  circleToken: CircleToken | {} | undefined,
): CircleTokenDeployStatus {
  if (isValidCircleToken(circleToken)) {
    console.log("circleToken.status", circleToken.status);
    switch (circleToken.status) {
      case CircleTokenStatus.Pending:
        return CircleTokenDeployStatus.Deploying;
      case CircleTokenStatus.Running:
      case CircleTokenStatus.Finished:
      case CircleTokenStatus.DexPooled:
        return CircleTokenDeployStatus.Deployed;
      default:
        return CircleTokenDeployStatus.Unbound;
    }
  } else {
    return CircleTokenDeployStatus.Unbound;
  }
}

export function getCircleTokenStage(
  circleToken: CircleToken | {} | undefined,
): CircleTokenStage {
  if (isValidCircleToken(circleToken)) {
    switch (circleToken.status) {
      case CircleTokenStatus.Pending:
      case CircleTokenStatus.Running:
      case CircleTokenStatus.Finished:
        return CircleTokenStage.Incubation;
      case CircleTokenStatus.DexPooled:
        return CircleTokenStage.Launched;
      default:
        return CircleTokenStage.Incubation;
    }
  } else {
    return CircleTokenStage.Incubation;
  }
}

export function CircleTokenInfoPage() {
  const i18n = useI18n();
  const backend = useBackend();

  const [circleId, circleSWR] = useCircleSWR();
  const myUid = useMyUid();
  const tokenSWR = useTokenSWR(circleId);
  const copyText = useCopyText();

  const pairAddress =
    isValidCircleToken(tokenSWR.content) &&
    (tokenSWR.content.status === CircleTokenStatus.DexPooled
      ? tokenSWR.content.dexPoolAddress
      : tokenSWR.content.curveAddress);
  const sourceName = (() => {
    if (!isValidCircleToken(tokenSWR.content)) {
      return "-";
    }
    switch (tokenSWR.content.projectSource) {
      case ProjectSource.ToDaMoon:
        return "ToDaMoon";
      case ProjectSource.PumpFun:
        return "PumpFun";
      case ProjectSource.Stable:
        return "Stable";
      case ProjectSource.Clover:
      default:
        return "Clover.Space";
    }
  })();

  const launchedPlatformName = (() => {
    if (!isValidCircleToken(tokenSWR.content)) {
      return "-";
    }
    switch (tokenSWR.content.projectLaunchedPlatform) {
      case ProjectLaunchedPlatform.Raydium:
        return "Raydium";
      default:
        return "-";
    }
  })();

  const nativePage = useNativePage();
  const deployStatus = toDeployStatus(tokenSWR.content);
  const needShowDeployStatus = (() => {
    switch (deployStatus) {
      case CircleTokenDeployStatus.Unbound:
        return false;
      case CircleTokenDeployStatus.Deploying:
        return true;
      case CircleTokenDeployStatus.Deployed:
        return (
          (circleSWR.content && circleSWR.content?.coAdminIdList) ??
          []
        ).indexOf(myUid) !== -1
          ? false
          : circleSWR.content?.extensions?.showTokenCreatedAnim === true &&
              isCircleAdmin(circleSWR.content, myUid);
    }
  })();

  const [manualDismissDeployStatusPage, setManualDismissDeployStatusPage] =
    useHopState<boolean>("manualDismissDeployStatusPage", false);

  const hopper = useHopper();

  console.log("CircleTokenInfoPage deployStatus:", deployStatus);

  const webHost = useWebHost();

  const tokenAddressOnClick = () => {
    if (isValidCircleToken(tokenSWR.content) && tokenSWR.content.tokenAddress) {
      copyText(
        tokenSWR.content.tokenAddress,
        i18n.clover_copied_successfully(),
      ).catch(andLog);
    }
  };

  const pairAddressOnClick = () => {
    if (isValidCircleToken(tokenSWR.content) && pairAddress) {
      copyText(pairAddress, i18n.clover_copied_successfully()).catch(andLog);
    }
  };

  return (
    <Page
      pageData={[circleSWR, tokenSWR]}
      background={
        needShowDeployStatus && !manualDismissDeployStatusPage
          ? `url("${pageBg}") center top/cover no-repeat`
          : undefined
      }
    >
      {needShowDeployStatus && !manualDismissDeployStatusPage && (
        <CircleTokenDeployStatusContent
          circleId={circleId}
          deployStatus={deployStatus}
          viewDetailClick={() => {
            if (
              isValidCircleToken(tokenSWR.content) &&
              tokenSWR.content.extensions?.mintAssetOrderId
            ) {
              hopper.push(
                `wallet/order/${tokenSWR.content.extensions?.mintAssetOrderId}`,
                { orderType: OrderType.OtherOrder },
              );
            }
          }}
          doneClick={() => {
            setManualDismissDeployStatusPage(true);
            backend
              .markTokenCreatedAnimShown(circleId)
              .run()
              .then(() => {
                circleSWR.load().catch(andLog);
                tokenSWR.load().catch(andLog);
                webHost.broadcast("ON_CIRCLE_TOKEN_CREATED", {}).catch(andLog);
              })
              .catch(andLog);
          }}
        ></CircleTokenDeployStatusContent>
      )}
      {(!needShowDeployStatus || manualDismissDeployStatusPage) &&
        isValidCircleToken(tokenSWR.content) && (
          <>
            <NavMiddle>{i18n.clover_circle_token_info()}</NavMiddle>
            <Image
              src={[tokenSWR.content.image, 80]}
              alt={"tokenIcon"}
              width={80}
              height={80}
              style={{ borderRadius: 4, margin: "48px auto 0" }}
            ></Image>
            <TokenTickerContainer
              onClick={() => {
                if (isValidCircleToken(tokenSWR.content)) {
                  copyText(
                    tokenSWR.content.tokenAddress,
                    i18n.clover_ca_copied(),
                  ).catch(andLog);
                }
              }}
            >
              <TokenTickerLabel>{tokenSWR.content.ticker}</TokenTickerLabel>
              <Image
                src={icCopy}
                alt={"tokenIcon"}
                width={20}
                height={20}
              ></Image>
            </TokenTickerContainer>
            <TokenName>{tokenSWR.content.name}</TokenName>
            <StageView circleToken={tokenSWR.content}></StageView>
            <GridLayout
              spanCount={3}
              items={[
                <MarketCard>
                  <MarketDescLabel>
                    {i18n.moon_ads_market_cap()}
                  </MarketDescLabel>
                  <MarketValueLabel>
                    $
                    {isValidCircleToken(tokenSWR.content) &&
                      formatMoney(
                        "short",
                        BigNumber(
                          tokenSWR.content.tradingStatistics?.marketCap || "0",
                        ),
                      )}
                  </MarketValueLabel>
                  <Spring></Spring>
                  <TokenPercentageChangeLabel circleToken={tokenSWR.content} />
                </MarketCard>,
                <MarketCard>
                  <MarketDescLabel>Price USD</MarketDescLabel>
                  <MarketValueLabel>
                    {isValidCircleToken(tokenSWR.content) &&
                      `$${formatMoney("short", BigNumber(tokenSWR.content.tradingStatistics?.priceUsd || "0"))}`}
                  </MarketValueLabel>
                  <Spring></Spring>
                  <MarketDescLabel style={{ height: 20 }}>USD</MarketDescLabel>
                </MarketCard>,
                <MarketCard>
                  <MarketDescLabel>Price SOL</MarketDescLabel>
                  <MarketValueLabel>
                    {isValidCircleToken(tokenSWR.content) &&
                      `${formatMoney("short", BigNumber(tokenSWR.content.tradingStatistics?.priceQuote || "0"))}`}
                  </MarketValueLabel>
                  <Spring></Spring>
                  <MarketDescLabel style={{ height: 20 }}>SOL</MarketDescLabel>
                </MarketCard>,
              ]}
            ></GridLayout>
            {circleSWR.content?.extensions?.tokenLinkType ===
              CircleTokenLinkType.Create && (
              <OriginalCircleContainer>
                <HStack
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <OriginalNameLabel>
                    {i18n.clover_original_circle()}
                  </OriginalNameLabel>
                  <Spring></Spring>
                  <HStack
                    style={{
                      gap: 4,
                      maxWidth: "calc(27% + 32px)",
                    }}
                    mixin={[alpha_on_pressed]}
                    onClick={() => {
                      hopper.push(`circle/${circleId}`);
                    }}
                  >
                    <Image
                      src={[circleSWR.content?.circleIcon, 28]}
                      alt={"tokenIcon"}
                      width={28}
                      height={28}
                      style={{
                        borderRadius: 5,
                        border: "0.88px solid rgba(84, 236, 235, 1)",
                        marginInlineEnd: 4,
                      }}
                    ></Image>
                    <CircleNameLabel style={{ maxWidth: "calc(100% - 32px)" }}>
                      {circleSWR.content?.name}
                    </CircleNameLabel>
                  </HStack>
                </HStack>
              </OriginalCircleContainer>
            )}
            <SectionTitle>{i18n.web3_ugc_basic_info()}</SectionTitle>
            <ContentGroup style={{ paddingTop: 0, paddingBottom: 0 }}>
              <HStack style={{ padding: "20px 0" }}>
                <Label>{i18n.arthur_wallet_ticker()}</Label>
                <Spring />
                <Value>{tokenSWR.content.ticker}</Value>
              </HStack>
              <GroupCellDivider />
              <HStack style={{ padding: "20px 0" }}>
                <Label>{i18n.web3_v0_network()}</Label>
                <Spring />
                <Image
                  src={getChainIcon(tokenSWR.content.chainType)}
                  style={{ width: 18, height: 18, marginRight: 4 }}
                />
                <Value>{GetChainName(tokenSWR.content.chainType)}</Value>
              </HStack>
              <GroupCellDivider />
              <HStack style={{ padding: "20px 0" }}>
                <Label>{i18n.arthur_token_address()}</Label>
                <Spring />
                <Image
                  src={icCopy}
                  width={20}
                  height={20}
                  alt={"icCopy"}
                  style={{ marginInlineEnd: 4 }}
                  onClick={tokenAddressOnClick}
                ></Image>
                <Value onClick={tokenAddressOnClick}>
                  {dealStringByLimit(tokenSWR.content.tokenAddress, 10)}
                </Value>
                <Image
                  src={icSolScan}
                  width={16}
                  height={16}
                  alt={"icSolScan"}
                  style={{ marginInlineStart: 8 }}
                  onClick={() => {
                    if (
                      isValidCircleToken(tokenSWR.content) &&
                      tokenSWR.content.tokenAddress
                    ) {
                      webHost
                        .openInWebBrowser(
                          `https://solscan.io/account/${tokenSWR.content.tokenAddress}${isSpongeKit() ? "?cluster=devnet" : ""}`,
                        )
                        .catch(andLog);
                    }
                  }}
                ></Image>
              </HStack>
              <GroupCellDivider />
              <HStack style={{ padding: "20px 0" }}>
                <Label>{i18n.clover_pair_address()}</Label>
                <Spring />
                {isValidCircleToken(tokenSWR.content) && pairAddress && (
                  <Image
                    src={icCopy}
                    width={20}
                    height={20}
                    alt={"icCopy"}
                    style={{ marginInlineEnd: 4 }}
                    onClick={pairAddressOnClick}
                  ></Image>
                )}
                <Value>
                  {pairAddress && dealStringByLimit(pairAddress, 10)}
                </Value>
                <Image
                  src={icSolScan}
                  width={16}
                  height={16}
                  alt={"icSolScan"}
                  style={{ marginInlineStart: 8 }}
                  onClick={() => {
                    if (isValidCircleToken(tokenSWR.content) && pairAddress) {
                      webHost
                        .openInWebBrowser(
                          `https://solscan.io/account/${pairAddress}${isSpongeKit() ? "?cluster=devnet" : ""}`,
                        )
                        .catch(andLog);
                    }
                  }}
                ></Image>
              </HStack>
              <GroupCellDivider />
              <HStack style={{ padding: "20px 0" }}>
                <Label>{i18n.clover_current_supply()}</Label>
                <Spring />
                <Value>{tokenSWR.content.totalSupply}</Value>
              </HStack>
              <GroupCellDivider />

              {getCircleTokenStage(tokenSWR.content) ===
                CircleTokenStage.Launched && (
                <>
                  <HStack style={{ padding: "20px 0" }}>
                    <Label>{i18n.clover_lp_burned()}</Label>
                    <Spring />
                    <Value>
                      {formatPercentage(
                        tokenSWR.content?.dexPoolInfo?.lpBurnedRatio,
                      ) || ""}
                    </Value>
                  </HStack>
                  <GroupCellDivider />
                </>
              )}

              <HStack style={{ padding: "20px 0" }}>
                <Label>{i18n.web3_v0_source()}</Label>
                <Spring />
                <Image
                  src={icClover}
                  alt={"icClover"}
                  width={18}
                  height={18}
                  style={{ marginInlineEnd: 4 }}
                ></Image>
                <Value>{sourceName}</Value>
                {getCircleTokenStage(tokenSWR.content.status) ===
                  CircleTokenStage.Launched && (
                  <>
                    <Image
                      src={icDoubleArrow}
                      width={12}
                      height={12}
                      alt={"icDoubleArrow"}
                      style={{ margin: "0 4px" }}
                    ></Image>
                    <Image
                      src={icRaydium}
                      alt={"icClover"}
                      width={18}
                      height={18}
                      style={{ marginInlineEnd: 4 }}
                    ></Image>
                    <Value>{launchedPlatformName}</Value>
                  </>
                )}
              </HStack>
            </ContentGroup>
          </>
        )}
    </Page>
  );
}

const Label = styled.div`
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  line-height: 24px;
`;

const Value = styled.div`
  color: var(--color-text00);
  font-size: 14px;
  line-height: 24px;
`;

interface StageProps {
  // text: string;
  // color: Property.Color;
  // background: Property.Background;
  circleToken: CircleToken | {} | undefined;
  // onClick: () => void;
}

const StageView: React.FC<StageProps> = (props: StageProps) => {
  const i18n = useI18n();
  const nativePage = useNativePage();

  const color =
    getCircleTokenStage(props.circleToken) === CircleTokenStage.Incubation
      ? "#FFB332"
      : "#00FF94";

  const background =
    getCircleTokenStage(props.circleToken) === CircleTokenStage.Incubation
      ? "#FFEB321A"
      : "#00FF941A";

  const text =
    getCircleTokenStage(props.circleToken) === CircleTokenStage.Incubation
      ? i18n.clover_incubation()
      : i18n.clover_launched();

  return (
    <HStack
      style={{
        gap: "4px",
        margin: "0 auto",
        padding: "4px 8px",
        background: background,
        borderRadius: "4px",
      }}
      onClick={() => {
        nativePage
          .infoHud(
            getCircleTokenStage(props.circleToken) ===
              CircleTokenStage.Incubation
              ? i18n.clover_incubation_toast()
              : i18n.clover_launched_toast(),
          )
          .catch(andLog);
      }}
    >
      <Image
        src={getCircleTokenStageImage(props.circleToken)}
        width={12}
        height={12}
        alt={"stageIcon"}
      ></Image>
      <StageLabel
        style={{
          color: color,
        }}
      >
        {text}
      </StageLabel>
    </HStack>
  );
};

const DotBox = styled.div<{
  $color: Property.Color;
}>`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: ${({ $color }) => $color};
`;

const StageLabel = styled.div`
  font-size: 12px;
  font-weight: 400;
`;

const TokenName = styled.div`
  font-size: 22px;
  font-weight: 700;
  color: var(--color-text00);
  text-align: center;
  margin: 0 auto 8px;
  word-wrap: break-word;
  word-break: break-word;
  white-space: normal;
`;

const TokenTickerLabel = styled.div`
  font-weight: 700;
  font-size: 12px;
  color: var(--color-text00);
  text-align: center;
`;

const TokenTickerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 8px auto;
  padding: 2px 2px 2px 4px;
  gap: 4px;
  background: #ffffff0f;
  border-radius: 2px;
  ${cell_on_pressed}
`;

const MarketCard = styled.div<{ $isShrinkable?: boolean }>`
  padding: 7px 0;
  display: flex;
  flex-direction: column;
  background: #ffffff0d;
  border: 1px solid #ffffff0f;
  border-radius: 6px;
  height: 61px;
  ${(p) => p.$isShrinkable && shrink_on_pressed}
`;

const MarketDescLabel = styled.div`
  font-size: 10px;
  font-weight: 300;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  line-height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MarketValueLabel = styled.div`
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: var(--color-text00);
  line-height: 20px;
  margin-top: 8px;
`;

const SectionTitle = styled.div`
  color: rgba(255, 255, 255, 0.6);
  font-weight: 300;
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 12px;
`;

const PercentageChangeLabel = styled.div`
  font-weight: 500;
  font-size: 10px;
  border-radius: 6px;
  padding: 4px;
  display: inline-flex;
  justify-content: center;
  gap: 2px;
  margin-left: auto;
  margin-right: auto;
`;

type TokenPercentageChangeLabelProps = {
  circleToken: CircleToken | {} | undefined;
};

function TokenPercentageChangeLabel(props: TokenPercentageChangeLabelProps) {
  const circleToken = props.circleToken;
  return (
    <PercentageChangeLabel
      style={{
        color: marketCap1HipColor(circleToken),
        background: marketCap1HipBackgroundColor(circleToken),
      }}
    >
      {isValidCircleToken(circleToken) &&
        circleToken.tradingStatistics?.marketCap1Hip !== 0 && (
          <Image
            src={circleTokenFluctuationImage(circleToken)}
            alt={"PercentageChangeDirection"}
            width={12}
            height={12}
          ></Image>
        )}
      {marketCap1HipString(circleToken)}
    </PercentageChangeLabel>
  );
}

const OriginalNameLabel = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: var(--color-text00);
`;

const CircleNameLabel = styled.div`
  font-size: 10px;
  font-weight: 400;
  color: var(--color-text00);
  word-wrap: break-word;
  word-break: break-word;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const OriginalCircleContainer = styled.div`
  position: relative;
  margin-top: 20px;
  height: 60px;
  padding: 0 16px;
  gap: 4px;
  border-radius: 6px;
  background: linear-gradient(
    90deg,
    rgba(255, 234, 181, 0.2) 0%,
    rgba(242, 168, 93, 0.2) 26%,
    rgba(128, 82, 228, 0.2) 50%,
    rgba(46, 133, 234, 0.2) 74.5%,
    rgba(104, 239, 174, 0.2) 100%
  );
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 6px; /* 确保圆角和容器一致 */
    padding: 1px; /* 边框宽度 */
    background: linear-gradient(
      90deg,
      #ffeab5 0%,
      #f2a85d 26%,
      #8052e4 50%,
      #2e85ea 74.5%,
      #68efae 100%
    );
    mask:
      linear-gradient(
          90deg,
          #ffeab5 0%,
          #f2a85d 26%,
          #8052e4 50%,
          #2e85ea 74.5%,
          #68efae 100%
        )
        content-box,
      linear-gradient(
        90deg,
        #ffeab5 0%,
        #f2a85d 26%,
        #8052e4 50%,
        #2e85ea 74.5%,
        #68efae 100%
      );
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
`;

export default CircleTokenInfoPage;
