import React, { PropsWithChildren, ReactNode } from "react";
import { StateId } from "../hooks/StateId";
import { omit } from "lodash";

type Context = Readonly<{
  depth: number;
  primarySubpageId: StateId | undefined;
  setPrimarySubpageWithId: (pageId: StateId, behavior?: ScrollBehavior) => void;
  subpageItems: { id: StateId; title: ReactNode | undefined }[];

  subpagePropsOverrides?:
    | {
        fullPageStateDisabled?: boolean;
        scrollDisabled?: boolean;
        safeTopDisabled?: boolean;
      }
    | undefined;
  customComposition?: Object | undefined;
}>;

const defaultContext: Context = {
  depth: 0,
  primarySubpageId: undefined,
  setPrimarySubpageWithId: () => {},
  subpageItems: [],
  subpagePropsOverrides: undefined,
  customComposition: undefined,
};

const Context = React.createContext<Context>(defaultContext);

export function usePageParent() {
  return React.useContext(Context);
}

export type PageCompositingProps = Omit<Context, "depth">;
export const kDefaultPageCompositingProps = omit(defaultContext, ["depth"]);

export function PageCompositionBoundary(
  props: PropsWithChildren<PageCompositingProps>,
) {
  const parent = usePageParent();
  return (
    <Context.Provider value={{ ...props, depth: parent.depth + 1 }}>
      {props.children}
    </Context.Provider>
  );
}
