import React, {
  PropsWithChildren,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useI18n } from "../hooks/useI18n";
import { useModal } from "../components/Modal";
import { genUUID } from "../utils/uuid";
import hudWarn from "../res/images/ic_hud_warn.svg";
import hudSuc from "../res/images/ic_hud_success.svg";
import { DatePickerConfig, MediaMenuConfig } from "../bridge/NativePage";
import { EventName } from "../bridge/EventName";
import { AppShellContext } from "../hooks/useAppShell";
import { AlertButton, WhiteAlert } from "../components/WhiteAlert";
import { ModalSpinner } from "../components/ModalSpinner";
import { ModalHud } from "../components/ModalHud";
import { MediaViewer } from "../pages/media/MediaViewer";
import { useObscuredZones } from "../hooks/useObscuredZones";
import { useMyUid } from "./AuthSessionService";
import { MediaDisplayBody } from "../proto/MediaDisplayBody";

export function AppShellService(props: PropsWithChildren<{}>) {
  const i18n = useI18n();
  const spinnerIds = useRef<string[]>([]);
  const [hud, setHud] = useState<{ title: string; icon: string | undefined }>();
  const [alertNotice, setAlertNotice] = useState<{
    title: string;
    okButton: string;
  }>();

  const [alertAreYouSure, setAlertAreYouSure] = useState<{
    message: string;
    sureTitle: string;
    cancelTitle: string;
    destructive: boolean;
  }>();

  const areYouSureResolver =
    useRef<(value: boolean | PromiseLike<boolean>) => void>();

  const [mediaDisplay, setMediaDisplay] = useState<MediaDisplayBody>();

  const myUid = useMyUid();

  const spinner = useModal("spinner");
  const hudModal = useModal("hudModal");
  const alertNoticeModal = useModal("alertNoticeModal");
  const alertAreYouSureModal = useModal("alertAreYouSureModal");
  const mediaViewer = useModal("mediaViewer");

  function showNextSpinner() {
    const id = spinnerIds.current[0];
    if (id) {
      spinner.open();
    }
  }

  const nativePage = useMemo(() => {
    return {
      showSpinner: async () => {
        const newId = genUUID();
        spinnerIds.current = spinnerIds.current.concat([newId]);
        if (spinnerIds.current.length === 1) {
          showNextSpinner();
        }
        return newId;
      },
      endSpinner: async (id: string) => {
        if (id === spinnerIds.current[0]) {
          spinner.close();
        }
        spinnerIds.current = spinnerIds.current.filter((i) => i !== id);
        showNextSpinner();
      },

      infoHud: async (title: string) => {
        setHud({ title: title, icon: undefined });
        hudModal.open();
        setTimeout(() => hudModal.close(), 2000);
      },
      warnHud: async (title: string) => {
        setHud({ title: title, icon: hudWarn });
        hudModal.open();
        setTimeout(() => hudModal.close(), 2000);
      },
      successHud: async (title: string) => {
        setHud({ title: title, icon: hudSuc });
        hudModal.open();
        setTimeout(() => hudModal.close(), 2000);
      },

      alertNotice: async (message: string, okTitle: string) => {
        setAlertNotice({ title: message, okButton: okTitle });
        alertNoticeModal.open();
      },

      alertAreYouSure: async (
        message: string,
        sureTitle: string,
        cancelTitle: string,
      ) => {
        setAlertAreYouSure({
          message: message,
          sureTitle: sureTitle,
          cancelTitle: cancelTitle,
          destructive: true,
        });
        alertAreYouSureModal.open();
        return new Promise<boolean>((resolve, reject) => {
          areYouSureResolver.current = resolve;
        });
      },
      alertYesOrCancel: async (
        message: string,
        sureTitle: string,
        cancelTitle: string,
      ) => {
        setAlertAreYouSure({
          message: message,
          sureTitle: sureTitle,
          cancelTitle: cancelTitle,
          destructive: false,
        });
        alertAreYouSureModal.open();
        return new Promise<boolean>((resolve, reject) => {
          areYouSureResolver.current = resolve;
        });
      },

      freezeNavBar: async (id: string, back: () => void) => {},
      unfreezeNavBar: async (id: string) => {},

      listenToBroadcast: async (
        id: string,
        event: EventName,
        action: (param: object) => void,
      ) => {},
      unlistenToBroadcast: async (id: string, event: EventName) => {},

      showMediaMenu: async (resultId: string, config: MediaMenuConfig) => {},
      pickDate: async (resultId: string, config: DatePickerConfig) => {},

      pickColor: async (resultId: string, current: string, title: string) => {},

      pickSticker: async (resultId: string) => {},

      // back pressed callback is android only
      showFlagController: async (objectType: number, identifier: bigint) => {},

      viewMedia: (mediaDisplayBody: MediaDisplayBody) => {
        setMediaDisplay(mediaDisplayBody);
        mediaViewer.open();
      },

      restorePurchase: () => {},
    };
  }, [
    spinner,
    alertNotice,
    alertAreYouSure,
    alertNoticeModal,
    setHud,
    setAlertNotice,
    setAlertAreYouSure,
    i18n,
  ]);

  const webHost = useMemo(() => {
    return {
      onContentSizeChange: async (width: number, height: number) => {},

      broadcast: async (event: EventName, eventInfo: object) => {},
      haptic: async (
        style: "light" | "medium" | "heavy" | "soft" | "rigid",
      ) => {},
      vibrate: async () => {},
      openInWebBrowser: async (url: string) => {
        // https://www.jitbit.com/alexblog/256-targetblank---the-most-underestimated-vulnerability-ever/
        const newWindow = window.open(url, "_blank", "noopener,noreferrer");
        if (newWindow) newWindow.opener = null;
      },

      saveImage: async (
        base64Data: string,
        mimeType: string,
        isStatic: boolean,
        name: string,
        dataUrl: string,
      ) => {
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = `${name}.png`;
        link.click();
      },

      nativeLogger: async (log: string) => {},
    };
  }, []);

  const [_, setObscuredZone] = useObscuredZones();

  useLayoutEffect(() => {
    const rootStyle = getComputedStyle(document.documentElement);
    const top = parseInt(
      rootStyle.getPropertyValue("--sait").replace("px", ""),
    );
    const bottom = parseInt(
      rootStyle.getPropertyValue("--saib").replace("px", ""),
    );
    setObscuredZone("Browser", { top: top, bottom: bottom });
  }, [setObscuredZone]);

  return (
    <AppShellContext.Provider
      value={{ webHost: webHost, nativePage: nativePage }}
    >
      {props.children}

      <WhiteAlert modal={alertNoticeModal} title={alertNotice?.title}>
        <AlertButton onClick={() => alertNoticeModal.close()}>
          {alertNotice?.okButton}
        </AlertButton>
      </WhiteAlert>

      <WhiteAlert modal={alertAreYouSureModal} title={alertAreYouSure?.message}>
        <AlertButton
          style={alertAreYouSure?.destructive ? { color: "red" } : undefined}
          onClick={() =>
            alertAreYouSureModal.close(() => {
              if (areYouSureResolver.current) {
                areYouSureResolver.current(true);
              }
            })
          }
        >
          {alertAreYouSure?.sureTitle}
        </AlertButton>
        <AlertButton
          onClick={() =>
            alertAreYouSureModal.close(() => {
              if (areYouSureResolver.current) {
                areYouSureResolver.current(false);
              }
            })
          }
        >
          {alertAreYouSure?.cancelTitle}
        </AlertButton>
      </WhiteAlert>

      <ModalSpinner modal={spinner}></ModalSpinner>
      <ModalHud title={hud?.title} icon={hud?.icon} modal={hudModal}></ModalHud>

      {myUid > 0 && (
        <MediaViewer
          modal={mediaViewer}
          mediaList={mediaDisplay?.mediaList}
          index={mediaDisplay?.position}
          mediaOwnerMap={mediaDisplay?.mediaOwnerMap}
        />
      )}
    </AppShellContext.Provider>
  );
}
