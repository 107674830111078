import { Post } from "../../proto/Post";
import { FolderLayoutType } from "../../proto/CircleFolder";
import { PostCell } from "../post/PostCell";
import { Fragment } from "react";

export interface PostsFlowProps {
  circleId: bigint;
  posts?: Post[];
  folderLayoutType?: FolderLayoutType;
  showMembershipLevel?: boolean;
}

const styles = {
  separator: {
    margin: "0", // 可选：设置上下间距
    border: "none", // 不显示默认边框
    borderTop: "1px solid rgba(255, 255, 255, 0.1)", // 设置上边框样式
  },
};

function StyledPostsFlow(props: PostsFlowProps) {
  return (
    <>
      {props.posts?.map((post, index) => (
        <Fragment key={post.blogId}>
          <PostCell
            post={post}
            circleId={props.circleId}
            showMembershipLevel={props.showMembershipLevel}
          />
          <hr style={styles.separator} />
        </Fragment>
      ))}
    </>
  );
}

export default StyledPostsFlow;
