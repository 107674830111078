import { string, z, ZodString } from "zod";
import { zBigInt, zStatic } from "../utils/zodUtils";
import { Media } from "./Media";

export const Sticker = z.object({
  stickerId: zBigInt,
  name: z.string().optional(),
  media: Media.optional(),
});

export type Sticker = zStatic<typeof Sticker>;

export enum StickerType {
  Static = "static",
  Animation = "animation",
  Customized = "customized",
}

export const StickerZip = z.object({
  width: z.number().default(0),
  height: z.number().default(0),
  path: z.string().default(""),
  url: z.string().default(""),
});

export const StickerGroup = z.object({
  stickerList: z.array(Sticker).optional(),
  name: z.string().default(""),
});

export const StickerPackage = z.object({
  pkgList: z.array(StickerZip).optional(),
  groupList: z.array(StickerGroup).optional(),
  stickerList: z.array(Sticker).optional(),
});

export type StickerPackage = zStatic<typeof StickerPackage>;

export const Reaction = z.object({
  createdTime: z.number(),
  stickerId: zBigInt,
  sticker: Sticker.optional(),
  reacted: z.boolean().default(false),
  count: z.number().default(0),
});

export type Reaction = zStatic<typeof Reaction>;

export const StickerVersion = z.object({
  name: z.string().optional(),
  version: z.string().optional(),
});

export type StickerVersion = zStatic<typeof StickerVersion>;
