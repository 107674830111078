import React, { useEffect, useRef } from "react";
import { useI18n } from "../../hooks/useI18n";
import styled from "styled-components";
import animationPath from "./video/create_account_success_animation.mp4";

const CreateAccountSuccessAnimation: React.FC<{ onComplete: () => void }> = ({
  onComplete,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const i18n = useI18n();
  useEffect(() => {
    const container = containerRef.current;

    // Simulate text shadow animation
    const labels = Array.from(
      container?.querySelectorAll(".success-label") || [],
    );
    labels.forEach((label, index) => {
      label.animate(
        [
          { textShadow: "0px 0px 0px rgba(255, 255, 255, 0)", opacity: 1 },
          { textShadow: "0px 0px 9px rgba(255, 255, 255, 1)", opacity: 1 },
          { textShadow: "0px 0px 0px rgba(255, 255, 255, 0)", opacity: 0 },
        ],
        { duration: 1400, delay: 0, easing: "ease-in-out" },
      );
    });

    const timeout = setTimeout(() => {
      onComplete();
    }, 1400);

    return () => clearTimeout(timeout);
  }, [onComplete]);

  return (
    <div
      ref={containerRef}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "black",
        zIndex: 1000,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <video
        autoPlay
        muted
        loop
        playsInline
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
          zIndex: -1,
        }}
      >
        <source src={animationPath} type="video/mp4" />
      </video>

      <Label className="success-label">{i18n.meet_now_matched()}</Label>
      <Label className="success-label">
        {i18n.signup_impr_account_created()}
      </Label>
    </div>
  );
};

const Label = styled.div`
  color: white;
  font-size: 27px;
  text-align: center;
  z-index: 10;
  margin-top: 20px;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
`;

export default CreateAccountSuccessAnimation;
