import { useSWR } from "../../hooks/swr/useSWR";
import { useMemo } from "react";
import { AccountType } from "../../proto/AccountType";
import { useBackend } from "../../service/APIService";

export function useRegularAccount() {
  const backend = useBackend();
  const walletSWR = useSWR(backend.getWallet());
  return useMemo(() => {
    return walletSWR.content?.walletAccountList?.find(
      (account) => account.accountType === AccountType.BuiltIn,
    );
  }, [walletSWR.content]);
}
