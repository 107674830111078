import styled from "styled-components";
import React, {
  CSSProperties,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { HStackMixin, Spring, VSpace, VStackMixin } from "./VStack";
import svgToMiniDataURI from "mini-svg-data-uri";
import {
  DefaultEmptyView,
  LoadStateCell,
  LoadStateView,
} from "./LoadStateView";
import { VScroll, VScrollStyle } from "./vscroll/VScroll";
import { SWRSingle } from "../hooks/swr/useSWR";
import {
  full_page_width_cell_no_padding,
  hPaddingWithPageInset,
} from "./CommonStyles";
import {
  AllDoneWithErrorFirst,
  LoadState,
  LoadStateKind,
} from "../hooks/LoadState";
import { andLog } from "./handleError";
import { PageDecoration } from "./PageDecoration";
import { SWRAccum } from "../hooks/swr/SWRAccum";
import { useCurrentLanguage, useI18n } from "../hooks/useI18n";
import { useNativePage } from "../hooks/useBridge";
import { getErrorMsg } from "../bridge/Rejectable";
import { NativePage } from "../bridge/NativePage";
import { useObscuredZones } from "../hooks/useObscuredZones";
import { flattenStateId, StateId } from "../hooks/StateId";
import { PageDepthChecker, usePageId } from "./Subpage";
import {
  kDefaultPageCompositingProps,
  PageCompositingProps,
  PageCompositionBoundary,
  usePageParent,
} from "./PageCompositionBoundary";
import { useRenderDetection } from "../hooks/useRenderDetection";
import { usePullToRefresh } from "./PullToRefresh";
import { isReadOnlyArray } from "../utils/typeUtils";
import { usePageDefaultBgKilled } from "./PageDefaultBgKiller";
import { usePageSpec } from "../pages/common/usePageSpec";

const BgSVG = `
<svg width="374" height="812" viewBox="0 0 374 812" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2878_34011)">
<g filter="url(#filter0_f_2878_34011)">
<ellipse cx="-42.5" cy="559.5" rx="89.5" ry="109.5" fill="white" fill-opacity="0.3"/>
</g>
<g filter="url(#filter1_f_2878_34011)">
<ellipse cx="408.5" cy="207.5" rx="85.5" ry="115.5" fill="white" fill-opacity="0.3"/>
</g>
</g>
<defs>
<filter id="filter0_f_2878_34011" x="-325.8" y="256.2" width="566.6" height="606.6" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="96.9" result="effect1_foregroundBlur_2878_34011"/>
</filter>
<filter id="filter1_f_2878_34011" x="129.2" y="-101.8" width="558.6" height="618.6" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="96.9" result="effect1_foregroundBlur_2878_34011"/>
</filter>
<clipPath id="clip0_2878_34011">
<rect width="375" height="812" fill="white"/>
</clipPath>
</defs>
</svg>

`;

export const DefaultPagePg = `url("${svgToMiniDataURI(BgSVG)}") center/cover no-repeat var(--color-bg)`;

const PageFrame = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden; // don't allow document scrolling

  scroll-snap-align: center;
  ${VStackMixin};
  box-sizing: border-box;
`;

export type PageData = {
  contentId: StateId;
  loadState: LoadState | undefined;
  hasContents: boolean;
  refresh: (reason?: string) => Promise<void>;
};

export function staticPageData(contentId: StateId): PageData {
  return {
    contentId: contentId,
    loadState: undefined,
    hasContents: true,
    refresh: () => Promise.resolve(),
  };
}

export type LoadStateConfig = {
  emptyText?: string;
  errorImage?: string;
  reloadText?: string;
  emptyStateProvider?: () => ReactElement | undefined;
};

type SWRType =
  | Omit<SWRSingle<{}>, "fill"> // fill is contravariance
  | SWRAccum<{}[]> // useSWRArray
  | SWRAccum<{ list: {}[] }> // useSWRList
  | SWRAccum<unknown>;

function isSWRSingle(
  obj: undefined | PageData | SWRType,
): obj is Omit<SWRSingle<{}>, "fill"> {
  return (
    obj !== undefined && "load" in obj && "fill" in obj && !("loadMore" in obj)
  );
}

function isSWRArray(
  obj: undefined | PageData | SWRType,
): obj is SWRAccum<{}[]> {
  return (
    obj !== undefined &&
    "load" in obj &&
    "loadMore" in obj &&
    "content" in obj &&
    Array.isArray(obj.content)
  );
}

function isValidSWRList(
  obj: undefined | PageData | SWRType,
): obj is SWRAccum<{ list: {}[] }> {
  return (
    obj !== undefined &&
    "load" in obj &&
    "loadMore" in obj &&
    "content" in obj &&
    obj.content !== undefined &&
    obj.content !== null &&
    typeof obj.content === "object" &&
    "list" in obj.content &&
    Array.isArray(obj.content["list"])
  );
}

function isSWRAccumKnown(
  obj: undefined | PageData | SWRType,
): obj is SWRAccum<unknown> {
  return (
    obj !== undefined &&
    "load" in obj &&
    "loadMore" in obj &&
    !isSWRArrayOrList(obj)
  );
}

function isSWRArrayOrList(
  obj: undefined | PageData | SWRType,
): obj is SWRAccum<{ list: {}[] }> | SWRAccum<{}[]> {
  return isSWRArray(obj) || isValidSWRList(obj);
}

function isSWRType(obj: undefined | PageData | SWRType): obj is
  | Omit<SWRSingle<{}>, "fill">
  | SWRAccum<{
      list: {}[];
    }>
  | SWRAccum<{}[]> {
  return isSWRSingle(obj) || isSWRArray(obj) || isValidSWRList(obj);
}

function swrTypeHasContents(swr: SWRType) {
  if (isValidSWRList(swr)) {
    return swr.content.list.length > 0;
  } else if (isSWRArray(swr)) {
    return swr.content.length > 0;
  } else if (isSWRSingle(swr)) {
    return swr.content !== undefined && Object.keys(swr.content).length > 0;
  } else {
    return false;
  }
}

function swrListHasContents(swrs: readonly (SWRType | undefined)[]) {
  for (const swr of swrs) {
    if (swr) {
      if (swrTypeHasContents(swr)) return true;
    }
  }

  return false;
}

function getAllErrors(
  obj: PageData | SWRType | readonly (SWRType | undefined)[],
) {
  if (isReadOnlyArray(obj)) {
    return obj
      .map((o) => o?.loadState)
      .flatMap((s) => {
        if (s?.kind === "loadFailed") {
          return [s.error];
        } else {
          return [];
        }
      });
  } else if (obj) {
    if (obj.loadState?.kind === "loadFailed") {
      return [obj.loadState.error];
    } else {
      return [];
    }
  } else {
    return [];
  }
}

function combineToOne(
  obj: PageData | SWRType | readonly (SWRType | undefined)[],
): PageData & { allErrors: unknown[] } {
  function combineToPageData(
    obj: PageData | SWRType | readonly (SWRType | undefined)[],
  ): PageData {
    if (isReadOnlyArray(obj)) {
      return {
        contentId: obj.map((swr) => swr?.contentId),
        loadState: AllDoneWithErrorFirst(obj.map((s) => s?.loadState)),
        hasContents: swrListHasContents(obj),
        refresh: (reason?: string) =>
          Promise.all(obj.map((s) => s?.load(reason))).then(),
      };
    } else if (isSWRType(obj)) {
      return {
        contentId: obj.contentId,
        loadState: obj.loadState,
        hasContents: swrTypeHasContents(obj),
        refresh: (reason) => obj.load(reason),
      };
    } else if (isSWRAccumKnown(obj)) {
      return {
        contentId: obj.contentId,
        loadState: obj.loadState,
        hasContents: false,
        refresh: (reason) => obj.load(reason),
      };
    } else {
      return obj;
    }
  }

  return {
    ...combineToPageData(obj),
    allErrors: getAllErrors(obj),
  };
}

function getAllSWRArrayOrList(
  obj: undefined | PageData | SWRType | readonly (SWRType | undefined)[],
) {
  if (obj === undefined) {
    return [];
  } else if (isReadOnlyArray(obj)) {
    return obj.filter(isSWRArrayOrList);
  } else if (isSWRArrayOrList(obj)) {
    return [obj];
  } else if (isSWRSingle(obj)) {
    return [];
  } else {
    return [];
  }
}

const FullPageState = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;
  overflow: hidden;
`;

const ContentContainer = styled.div`
  ${VStackMixin};
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;

  box-sizing: border-box;
  overflow: hidden;
`;

async function displayErrors(
  nativePage: NativePage,
  errors: readonly unknown[],
) {
  for (const error of errors) {
    await nativePage.warnHud(getErrorMsg(error));
  }
}

function getFinalProp<T extends {}>(
  override: T | undefined,
  prop: T | undefined,
) {
  if (override !== undefined) return override;
  else return prop;
}

const MaxWidthContainer = styled.div`
  ${HStackMixin};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  overflow: hidden;
  justify-content: center;
  align-items: stretch;
`;

const MaxWidthElement = styled.div`
  ${VStackMixin};
  height: 100%;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  flex-shrink: 0;
  flex-grow: 1;
`;

export function Page(
  props: PropsWithChildren<{
    background?: CSSProperties["background"];
    scrollStyle?: Pick<
      VScrollStyle,
      "counterReset" | "userSelect" | "msUserSelect"
    >;
    pageData: undefined | PageData | SWRType | readonly (SWRType | undefined)[];
    pullToRefreshDisabled?: boolean;
    alwaysShowsContent?: boolean;
    loadStateConfig?: LoadStateConfig;
    emptyTopSpace?: number;
    safeTopDisabled?: boolean;
    safeBottomDisabled?: boolean;
    scrollDisabled?: boolean;
    scrollPaddingDisabled?: boolean;
    gridSpan?: number;
    gridGap?: number;
    extraSafeBottom?: number;
    fullPageStateDisabled?: boolean;
    underlay?: ReactElement;
    underlayPosition?: Pick<CSSProperties, "top" | "bottom" | "position">;
    onContentScroll?: (event: React.UIEvent<HTMLElement>) => void;
    composition?: PageCompositingProps;
    maxContentWidth?: number | string;
  }>,
) {
  const pageSpec = usePageSpec();
  const { pageId, absolutePageId } = usePageId();

  const [obscuredZone] = useObscuredZones();

  useRenderDetection();

  const pageParent = usePageParent();
  const defaultBgKilled = usePageDefaultBgKilled();

  const [header, setHeader] = useState<ReactNode>();
  const [footer, setFooter] = useState<ReactNode>();

  const nativePage = useNativePage();
  const canonicalPageData = useMemo(
    () => (props.pageData ? combineToOne(props.pageData) : undefined),
    [props.pageData],
  );

  const fullPageStateDisabled = useMemo(() => {
    return getFinalProp(
      pageParent.subpagePropsOverrides?.fullPageStateDisabled,
      props.fullPageStateDisabled,
    );
  }, [
    pageParent.subpagePropsOverrides?.fullPageStateDisabled,
    props.fullPageStateDisabled,
  ]);

  const safeTopDisabled = useMemo(() => {
    return getFinalProp(
      pageParent.subpagePropsOverrides?.safeTopDisabled,
      props.safeTopDisabled,
    );
  }, [
    pageParent.subpagePropsOverrides?.safeTopDisabled,
    props.safeTopDisabled,
  ]);

  const scrollDisabled = useMemo(() => {
    return getFinalProp(
      pageParent.subpagePropsOverrides?.scrollDisabled,
      props.scrollDisabled,
    );
  }, [pageParent.subpagePropsOverrides?.scrollDisabled, props.scrollDisabled]);

  const displayedErrorsRef = useRef<readonly unknown[]>([]);

  useEffect(() => {
    if (canonicalPageData) {
      const newErrors = canonicalPageData.allErrors.filter(
        (e) => !displayedErrorsRef.current.includes(e),
      );

      displayErrors(nativePage, canonicalPageData.hasContents ? newErrors : [])
        .then(() => {
          displayedErrorsRef.current =
            displayedErrorsRef.current.concat(newErrors);
        })
        .catch(andLog);
    }
  }, [canonicalPageData, nativePage]);

  const refreshTarget = useMemo(() => {
    if (canonicalPageData) {
      return {
        onRefresh: () => canonicalPageData.refresh("pullToRefresh"),
        isLoading:
          canonicalPageData.loadState?.kind === LoadStateKind.loading &&
          canonicalPageData.loadState.reason === "pullToRefresh",
      };
    } else {
      return undefined;
    }
  }, [canonicalPageData]);

  const refreshTargetId = canonicalPageData?.contentId
    ? flattenStateId([absolutePageId, canonicalPageData.contentId])
    : undefined;

  const pullToRefresh = usePullToRefresh();
  useEffect(() => {
    if (refreshTarget && refreshTargetId) {
      if (props.pullToRefreshDisabled) {
        pullToRefresh.removeRefreshTarget(refreshTargetId);
      } else {
        pullToRefresh.addRefreshTarget(refreshTarget, refreshTargetId);
      }
    }
    return () => {
      if (refreshTargetId) {
        pullToRefresh.removeRefreshTarget(refreshTargetId);
      }
    };
  }, [refreshTargetId, props.pullToRefreshDisabled]);

  const allSWRArrayOrLists = useMemo(
    () => getAllSWRArrayOrList(props.pageData),
    [props.pageData],
  );

  const i18n = useI18n();

  let pageStateView: ReactElement | undefined = undefined;
  if (canonicalPageData) {
    if (!canonicalPageData.hasContents) {
      if (canonicalPageData.loadState?.kind === LoadStateKind.loaded) {
        if (props.loadStateConfig?.emptyStateProvider) {
          pageStateView = props.loadStateConfig.emptyStateProvider();
        } else {
          pageStateView = (
            <DefaultEmptyView
              title={props.loadStateConfig?.emptyText ?? i18n.no_content_yet()}
              topSpace={props.emptyTopSpace}
            />
          );
        }
      } else {
        pageStateView = (
          <LoadStateView
            loadState={canonicalPageData.loadState}
            onClickRetry={() => canonicalPageData.refresh().catch(andLog)}
            {...props.loadStateConfig}
          />
        );
      }
    }
  }

  const uniqueSWRArrayOrList =
    allSWRArrayOrLists.length == 1 ? allSWRArrayOrLists[0] : undefined;

  let content = props.children;
  if (!scrollDisabled) {
    content = (
      <VScroll
        style={{
          paddingBottom: !props.safeBottomDisabled
            ? obscuredZone.bottom + (props.extraSafeBottom ?? 30)
            : 0,
          ...(props.scrollPaddingDisabled ? undefined : hPaddingWithPageInset),

          ...(props.gridSpan
            ? {
                display: "grid",
                gridTemplateColumns: `repeat(${props.gridSpan}, 1fr)`,
                gap: props.gridGap,
                alignContent: "start",
              }
            : undefined),
          ...props.scrollStyle,
        }}
        contentId={[absolutePageId, canonicalPageData?.contentId]}
        // refreshTarget={refreshTarget}
        loadMoreTarget={allSWRArrayOrLists}
        onScroll={props.onContentScroll}
      >
        {content}

        {!props.alwaysShowsContent &&
          uniqueSWRArrayOrList &&
          uniqueSWRArrayOrList.loadState?.kind === LoadStateKind.loaded &&
          !swrTypeHasContents(uniqueSWRArrayOrList) &&
          (props.loadStateConfig?.emptyStateProvider ? (
            props.loadStateConfig.emptyStateProvider()
          ) : (
            <DefaultEmptyView
              title={props.loadStateConfig?.emptyText ?? i18n.no_content_yet()}
              topSpace={props.emptyTopSpace}
            />
          ))}

        {uniqueSWRArrayOrList && (
          <LoadStateCell loadState={uniqueSWRArrayOrList.loadState} />
        )}
      </VScroll>
    );
  }

  // including
  //   Full page state
  //   safe top
  //   header
  //   content
  //   footer
  const foreground = (
    <>
      {!props.alwaysShowsContent && !fullPageStateDisabled && pageStateView && (
        <FullPageState id={"FullPageState"}>{pageStateView}</FullPageState>
      )}

      {!safeTopDisabled && <VSpace height={obscuredZone.top} />}
      {header}
      <PageDecoration.Provider
        value={{
          setHeader: setHeader,
          setFooter: setFooter,
        }}
      >
        {fullPageStateDisabled && pageStateView && (
          <ContentContainer>{pageStateView}</ContentContainer>
        )}
        {
          <>
            <ContentContainer
              style={
                props.alwaysShowsContent ||
                canonicalPageData?.hasContents !== false
                  ? undefined
                  : { display: "none" }
              }
            >
              {content}
            </ContentContainer>
            {/*when ContentContainer with 'display' being 'none', */}
            {/*it doesn't occupy the space, so we need a 'Spring' */}
            {/*to push the 'footers' to the bottom.*/}
            {canonicalPageData?.hasContents === false && <Spring />}
          </>
        }
        {footer}
      </PageDecoration.Provider>
    </>
  );

  const language = useCurrentLanguage();

  return (
    <PageCompositionBoundary
      {...(props.composition ?? kDefaultPageCompositingProps)}
    >
      <PageFrame
        id={flattenStateId(pageId)}
        lang={language}
        style={{
          background:
            props.background ??
            (pageParent.depth === 0 && !defaultBgKilled
              ? DefaultPagePg
              : undefined),
        }}
      >
        <div
          style={
            props.underlayPosition ?? {
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            }
          }
        >
          {props.underlay}
        </div>
        {props.maxContentWidth && pageSpec === "wide" ? (
          <MaxWidthContainer>
            <MaxWidthElement style={{ maxWidth: props.maxContentWidth }}>
              {foreground}
            </MaxWidthElement>
          </MaxWidthContainer>
        ) : (
          foreground
        )}
      </PageFrame>
      <PageDepthChecker />
    </PageCompositionBoundary>
  );
}

const ShoulderContainer = styled.div`
  z-index: -1;
  position: sticky;
  height: 0;
  top: 0;
  ${full_page_width_cell_no_padding};
  box-sizing: border-box;
`;

const ShoulderInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100lvh;
  border-radius: 18px 18px 0 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(50.28821563720703px);
`;

export function PageShoulder() {
  return (
    <ShoulderContainer>
      <ShoulderInner />
    </ShoulderContainer>
  );
}
