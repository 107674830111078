import { User } from "../../proto/User";
import styled from "styled-components";
import { UserAvatarView } from "../../components/views/UserAvatarView";
import { UserNameView } from "../../components/views/UserNameView";
import { ButtonColor, RegularSmallButton } from "../../components/Buttons";
import followedIcon from "../../res/images/ic_followed.svg";
import friendIcon from "../../res/images/ic_friend.svg";
import favIcon from "../../res/images/ic_favorites.svg";
import favFriendIcon from "../../res/images/ic_fav_friends.svg";
import { useI18n } from "../../hooks/useI18n";
import * as React from "react";
import MercuryMenu from "../../components/MercuryMenu";
import { useLoadState } from "../../hooks/LoadState";
import { Spin } from "../../components/Spin";
import { useBackend } from "../../service/APIService";
import { andLog, useErrorHandler } from "../../components/handleError";
import { useNativePage } from "../../hooks/useBridge";
import { useHopper } from "../../hooks/useHopper";
import iconPicked from "../../res/images/ic_choosed.svg";
import iconDisablePick from "../../res/images/ic_choose_disable.svg";
import iconUnPicked from "../../res/images/ic_unchoosed.svg";
import { OverFlowTextStyle } from "../../components/Text";
import { SingleLineUnspecifiedWidth } from "../../components/CommonViews";
import { MouseEventHandler } from "react";
import { useMyUid } from "../../service/AuthSessionService";

export interface OnPicked {
  picker: boolean;
  disable: boolean;
  onClick: () => void;
}

export interface UserCellProps {
  user: User;
  onUpdate?: () => Promise<void>;
  children?: React.ReactNode;
  onPicked?: OnPicked;
  showMembershipLevel?: boolean;
  onClick?: () => void;
}

export function CommonUserCell(props: UserCellProps) {
  const i18n = useI18n();
  const backend = useBackend();
  const nativePage = useNativePage();
  const hopper = useHopper();
  const handleError = useErrorHandler();
  const myUid = useMyUid();
  const userStatusIcon = (): string | undefined => {
    const user = props.user;
    if (user.followMeStatus === 2 && user.followedByMeStatusV2 === 1) {
      return followedIcon;
    } else if (user.followMeStatus === 1 && user.followedByMeStatusV2 === 1) {
      return friendIcon;
    } else if (user.followMeStatus === 2 && user.followedByMeStatusV2 === 3) {
      return favIcon;
    } else if (user.followMeStatus == 1 && user.followedByMeStatusV2 === 3) {
      return favFriendIcon;
    } else {
      return undefined;
    }
  };
  const loadTask = useLoadState();
  const unFollow = async () => {
    const r = await loadTask.run(async () => {
      await backend.unfollowUser(props.user.uid).run();
      if (props.onUpdate) await props.onUpdate();
    });

    if (!r.success) {
      handleError(r.error);
    }
  };

  const rmFavorites = async () => {
    const r = await loadTask.run(async () => {
      await backend.removeFavourites(props.user.uid).run();
      if (props.onUpdate) await props.onUpdate();
    });
    if (!r.success) {
      handleError(r.error);
    }
  };

  const addFavorites = async () => {
    const r = await loadTask.run(async () => {
      await backend.addToFavourites({ targetUids: [props.user.uid] }).run();
      if (props.onUpdate) await props.onUpdate();
    });
    if (!r.success) {
      handleError(r.error);
    }
  };

  const onFollow = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const r = await loadTask.run(async () => {
      await backend.followUser(props.user.uid).run();
      if (props.onUpdate) await props.onUpdate();
    });

    if (!r.success) {
      handleError(r.error);
    }
  };
  const getOptions = () => {
    const user = props.user;
    const unFollowOption = {
      title: i18n.unfollow(),
      type: "warning",
      onClick: () => {
        unFollow().catch(andLog);
      },
    };
    const rmFav = {
      title: i18n.fav_widget_remove_from_favorites(),
      type: "warning",
      onClick: () => {
        rmFavorites().catch(andLog);
      },
    };
    const addFav = {
      title: i18n.fav_widget_add_to_favorites(),
      type: "normal",
      onClick: () => {
        addFavorites().catch(andLog);
      },
    };
    if (user.followedByMeStatusV2 === 3) {
      return [unFollowOption, rmFav];
    } else {
      return [unFollowOption, addFav];
    }
  };
  const navigateToProfile = () => {
    console.log("@_@ onclick");
    if (props.onClick) {
      props.onClick();
    } else {
      if (props.onPicked) {
        props.onPicked.onClick();
      } else {
        hopper.push(`user/${props.user.uid}`);
      }
    }
  };
  return (
    <UserFrame onClick={navigateToProfile}>
      <UserAvatarView disableClick user={props.user} iconSize={40} />
      <UserInfoFrame>
        <UserNameView
          user={props.user}
          showMembershipLevel={props.showMembershipLevel}
        />
        {props.user.remark ? <Remark>{props.user.remark}</Remark> : null}

        {!!props.user.socialId ? (
          <SocialId>{`@${props.user.socialId}`}</SocialId>
        ) : null}

        {props.children}
      </UserInfoFrame>
      {props.onPicked ? (
        <PickerIcon
          // onClick={navigateToProfile}
          $state={props.onPicked?.disable ? 2 : props.onPicked?.picker ? 1 : 0}
        />
      ) : props.user.uid !== myUid ? (
        <>
          {props.user.followedByMeStatusV2 === 2 ? (
            <RegularSmallButton
              $baseColor={ButtonColor.bluish}
              style={{ height: 30, fontSize: 12, flexShrink: 0 }}
              onClick={onFollow}
            >
              <Spin state={loadTask.state}>{i18n.follow()}</Spin>
            </RegularSmallButton>
          ) : (
            <div onClick={(e) => e.stopPropagation()}>
              <MercuryMenu options={getOptions()}>
                <div
                  style={{
                    height: 34,
                    width: 34,
                    display: "flex",
                    flexShrink: 0,
                  }}
                >
                  <Spin state={loadTask.state}>
                    <div
                      style={{
                        width: 34,
                        height: 34,
                        backgroundImage: `url(${userStatusIcon()})`,
                      }}
                    />
                  </Spin>
                </div>
              </MercuryMenu>
            </div>
          )}
        </>
      ) : null}
    </UserFrame>
  );
}

const Remark = styled.div`
  font-size: 9px;
  width: fit-content;
  padding: 3px;
  border-radius: 4px;
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  background: rgba(255, 255, 255, 0.09);
  ${SingleLineUnspecifiedWidth}
`;

const PickerIcon = styled.div<{ $state: number }>`
  width: 30px;
  height: 30px;
  background-image: ${(p) =>
    `url(${p.$state === 0 ? iconUnPicked : p.$state === 1 ? iconPicked : iconDisablePick})`};
`;

interface UserFrameProps {
  padding?: string;
  borderLeftOffset?: string;
}

export const UserFrame = styled.div<UserFrameProps>`
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: calc(100%);
  padding: ${(props) => props.padding || "20px 0"};
  flex-shrink: 0;
  position: relative;

  &:not(:last-child) {
    border: none;

    &::after {
      content: "";
      position: absolute;
      left: ${(props) => props.borderLeftOffset || "0px"};
      bottom: 0;
      width: ${(props) =>
        props.borderLeftOffset
          ? `calc(100% - ${props.borderLeftOffset})`
          : "100%"};
      height: 1px;
      background-color: var(--color-text30);
    }
  }
`;

export const UserInfoFrame = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;
  flex-shrink: 10;
  margin: 0 10px;
`;

export const SocialId = styled.div`
  color: #fff;
  font-family: DIN, sans-serif;
  font-size: 12px;
  font-weight: 500;
  flex-shrink: 1;
  ${OverFlowTextStyle};
  -webkit-line-clamp: 1;
`;
