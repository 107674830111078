import { VStack } from "../../../components/VStack";
import CircleCardsPreviewCell, { CardStyle } from "./CircleCardsPreviewCell";
import React from "react";
import { NFT } from "../../../proto/NFT";
import { Circle } from "../../../proto/Circle";
import styled from "styled-components";

export interface CardPaymentPreviewProps {
  card?: NFT;
  circle?: Circle;
}

export function CardPaymentPreview(props: CardPaymentPreviewProps) {
  return (
    <VStack
      style={{
        marginInlineStart: 40,
        marginInlineEnd: 40,
        gap: 10,
      }}
    >
      {props.card && (
        <CircleCardsPreviewCell
          card={props.card.circleMembershipCard}
          circle={props?.circle}
          cardStyle={CardStyle.large}
          includesPrice={false}
        />
      )}
      <CardName>{props.card?.name ?? props?.circle?.name}</CardName>
    </VStack>
  );
}

const CardName = styled.div`
  color: var(--color-text00);
  text-align: center;
  font-weight: 700;
  font-size: 14px;
`;
