import { Page } from "../../../components/Page";
import { useBigIntParam } from "../../../hooks/useTypedParam";
import { useBackend } from "../../../service/APIService";
import { useI18n } from "../../../hooks/useI18n";
import { useHopper } from "../../../hooks/useHopper";
import { useSWR } from "../../../hooks/swr/useSWR";
import { ChatSettingsSectionTitle } from "../../common/ChatBubblePicker";
import SettingCell from "../../../components/SettingCell";
import { FullPageWidthCellNoPadding } from "../../../components/CommonStyles";
import { NavMiddle } from "../../../components/NavBar";
import styled from "styled-components";
import { getChatDisplayName } from "../../../proto/Chat";
import { useMyUid } from "../../../service/AuthSessionService";

const ValueTitle = styled.div`
  opacity: 0.6;
  font-size: 14px;
`;

export default function ChatSettingsInfoPage() {
  const threadId = useBigIntParam("threadId");
  const backend = useBackend();
  const i18n = useI18n();
  const myUid = useMyUid();
  const language = new Intl.DisplayNames(navigator.language, {
    type: "language",
  });

  const thread = useSWR(["thread-info", threadId], () =>
    backend.getThreadInfo(threadId, false).run(),
  );
  return (
    <Page pageData={thread} scrollPaddingDisabled={true}>
      <NavMiddle>{i18n.party_on_party_info()}</NavMiddle>
      <SettingCell
        title={i18n.party_on_party_title()}
        endValue={
          <ValueTitle>
            {thread.content ? getChatDisplayName(thread.content, myUid) : ""}
          </ValueTitle>
        }
        onClick={() => {}}
      />
      <SettingCell title={i18n.description()} onClick={() => {}} />
      <SettingCell title={i18n.tags()} onClick={() => {}} />
      <SettingCell title={i18n.welcome_message()} onClick={() => {}} />
      <SettingCell
        title={i18n.language()}
        endValue={
          <ValueTitle>
            {thread.content?.language
              ? language.of(thread.content.language)
              : ""}
          </ValueTitle>
        }
        onClick={() => {}}
      />
      <SettingCell
        title={i18n.category()}
        endValue={<ValueTitle>{thread.content?.category?.name}</ValueTitle>}
        onClick={() => {}}
      />
    </Page>
  );
}
