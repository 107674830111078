import _ from "lodash";
import { SetStateAction, useCallback, useState } from "react";
import { DispatchWithReturn, isSetActionFunction } from "./Initializer";

function deleteKey<K, V>(map: ReadonlyMap<K, V>, key: K) {
  if (map.has(key)) {
    const newMap = new Map(map);
    newMap.delete(key);
    return newMap;
  } else {
    return map;
  }
}

export type MapKey = string | number | bigint | symbol;

export function useSetValueForMap<K extends MapKey, T, R>(
  setMap: DispatchWithReturn<SetStateAction<ReadonlyMap<K, T>>, R>,
) {
  return useCallback(
    (key: K, action: SetStateAction<T | undefined>) => {
      return setMap((prev) => {
        if (action) {
          const prevValue = prev.get(key);
          const value = isSetActionFunction(action)
            ? action(prevValue)
            : action;
          if (prevValue && _.isEqual(prevValue, value)) {
            return prev;
          }
          if (value !== undefined) {
            const newMap = new Map(prev);
            newMap.set(key, value);
            return newMap;
          } else {
            return deleteKey(prev, key);
          }
        } else {
          return deleteKey(prev, key);
        }
      });
    },
    [setMap],
  );
}

export function useMap<K extends MapKey, T>(
  defaultValue?: (key: K) => T | undefined,
  defaultKeys?: () => ReadonlyArray<K>,
) {
  const [map, setMap] = useState<ReadonlyMap<K, T>>(new Map());

  const setValue = useSetValueForMap(setMap);
  const getValue = useCallback(
    (key: K) => {
      const value = map.get(key);
      if (value !== undefined) {
        return value;
      } else {
        const newValue = defaultValue?.(key);

        if (newValue !== undefined) {
          const newMap = new Map(map);
          newMap.set(key, newValue);
          setMap(newMap);
          return newValue;
        }
      }
    },
    [map, setMap, defaultValue],
  );

  const getAllKeys = useCallback(() => {
    return [...Array.from(map.keys()), ...(defaultKeys?.() ?? [])];
  }, [map, defaultKeys]);

  const getFullMap = useCallback(() => {
    const keys = getAllKeys();
    const fullMap = new Map<K, T>();
    for (const key of keys) {
      const value = getValue(key);
      if (value) {
        fullMap.set(key, value);
      }
    }
    return fullMap;
  }, [getValue, getAllKeys]);

  return [getValue, setValue, getAllKeys, getFullMap] as const;
}
