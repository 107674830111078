import { useI18n } from "../../../hooks/useI18n";
import {
  CenterModal,
  ModalController,
  ModalTitle,
} from "../../../components/Modal";
import { PowerMarkdown } from "../../../components/PowerMarkdown";
import { service_privacy_markdown_style } from "../../auth/AuthHomePage";
import { RegularButton } from "../../../components/Buttons";
import { removeOptional } from "../../../utils/typeUtils";
import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";

interface AdminAgreementProps {
  modal: ModalController;
  onClickAgree: () => void;
}

function AdminAgreementDialog(props: AdminAgreementProps) {
  const i18n = useI18n();

  const [timeLeft, setTimeLeft] = useState(5);

  useEffect(() => {
    if (timeLeft <= 0) return;

    const timerId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    // 清除定时器，防止内存泄漏
    return () => clearInterval(timerId);
  }, [timeLeft]);

  useEffect(() => {
    if (timeLeft <= 5) {
      setTimeLeft(5);
    }
  }, [props.modal.open]);

  return (
    <CenterModal modal={props.modal} mixin={ModalStyle} hideCloseBtn={true}>
      <ModalTitle style={{ marginTop: 32, fontSize: 18 }}>
        {i18n.auth_impr_pop_title()}
      </ModalTitle>
      <PowerMarkdown textStyle={service_privacy_markdown_style}>
        {i18n.feb_2023_impr_circle_admin_agreement_content_v2()}
      </PowerMarkdown>
      <PowerMarkdown textStyle={service_privacy_markdown_style}>
        {i18n.moon_landing_terms_base_md()}
      </PowerMarkdown>
      <RegularButton
        onClick={() => {
          props.onClickAgree();
          props.modal.close();
        }}
        disabled={timeLeft !== 0}
      >
        {timeLeft > 0
          ? i18n.agree_and_continue_with_second(timeLeft)
          : i18n.agree_and_continue()}
      </RegularButton>
      <Cancel onClick={removeOptional(props.modal.close)}>
        {i18n.cancel()}
      </Cancel>
    </CenterModal>
  );
}

const ModalStyle = css`
  padding: 1px 20px 20px;
`;

const Cancel = styled.button`
  border: none;
  background: none;
  color: var(--color-text00);
  font-size: 16px;
`;

export default AdminAgreementDialog;
