import { Page } from "../../components/Page";
import { useSWR } from "../../hooks/swr/useSWR";
import { useHopper } from "../../hooks/useHopper";
import styled from "styled-components";
import { AspectRatioImage, Image } from "../../components/Image";
import { HStack, HStackMixin, VSpace, VStack } from "../../components/VStack";
import { UserAvatarView } from "../../components/views/UserAvatarView";
import { useBigIntParam, useEnumSearchParam } from "../../hooks/useTypedParam";
import { UserNameView } from "../../components/views/UserNameView";
import { UserInfoContainer } from "./PostCell";
import {
  alpha_on_pressed,
  FullPageWidthCellNoPadding,
} from "../../components/CommonStyles";
import { usePageSpec } from "../common/usePageSpec";
import { BareComment } from "../../proto/Comment";
import { ReactionListView } from "../reaction/ReactionListView";
import { ObjectType } from "../../proto/ObjectSpec";
import { useI18n } from "../../hooks/useI18n";
import { useSWRArray } from "../../hooks/swr/useSWRArray";
import React, { useMemo, useRef, useState } from "react";
import { useBackend } from "../../service/APIService";
import { BareCircle } from "../../proto/Post";
import { OverFlowTextStyle } from "../../components/Text";
import { ZDoc } from "../../components/zdoc/ZDoc";
import { CommentCell, CommentInputBar } from "../comment/Comment";
import { PageFooter } from "../../components/PageHeaderFooter";
import {
  getBestRes,
  getFittestRes,
  getOrderedFilteredMediaList,
  isAudio,
  isImage,
  isVideo,
  Media,
} from "../../proto/Media";
import iconLink from "../../res/images/ic_link.svg";
import iconLinkInline from "../../res/images/ic_link_inline.svg";
import { useNativePage, useWebHost } from "../../hooks/useBridge";
import FollowButton from "../../components/FollowButton";
import { ObjectPool } from "../chat/utils/ObjectPool";
import { User } from "../../proto/User";
import { useMyUid } from "../../service/AuthSessionService";
import icEmptyState from "../../res/images/empty_state.svg";
import { AudioFlow, SingleImage, SingleVideo } from "./PostMediaContainer";
import { Chat } from "../../proto/Chat";
import icThreeDot from "../../res/images/ic_three_dot.svg";
import { RegularSmallButton } from "../../components/Buttons";
import { NavEnd, NavItem } from "../../components/NavBar";
import { NavButtonType } from "../../bridge/NavButtonDesc";
import { useModal } from "../../components/Modal";
import { BSMenu, BSMenuItem } from "../../components/BSMenu";
import { useGlobalSpinner } from "../../utils/globalSpinner";
import { andLog, useErrorHandler } from "../../components/handleError";
import { copyTextToClipboard } from "../../utils/CopyText";
import { useOpenLink } from "../../hooks/useOpenLink";
import { FlagMediaLocationEnum } from "../../proto/Flag";
import { MediaOwner } from "../../proto/MediaOwner";
import { Simulate } from "react-dom/test-utils";
import click = Simulate.click;
import { useMemoryRepoMap } from "../../hooks/swr/useLocalRepo";
import { zBigInt } from "../../utils/zodUtils";
import { z } from "zod";
import { Reaction } from "../../proto/Reaction";
import { useReactionRecord } from "../../hooks/useReactionRecord";

export function PostDetailPage() {
  const postId = useBigIntParam("postId");
  const backend = useBackend();
  const nativePage = useNativePage();
  const objectType = useEnumSearchParam("objectType");

  const reactionRepo = useReactionRecord();

  const postSWR = useSWR(
    (objectType === ObjectType.CIRCLE_ANNOUNCEMENT
      ? backend.getCircleAnnouncement(postId)
      : backend.getPost(postId, objectType || ObjectType.BLOG)
    ).intercept((post) => {
      reactionRepo.setValue(post.blogId, post.reactionCountList).catch(andLog);
    }),
  );

  const hopper = useHopper();
  const i18n = useI18n();
  const pageSpec = usePageSpec();
  const commentSWR = useSWRArray(
    backend
      .getComments(postId, objectType ? objectType : ObjectType.BLOG, BigInt(0))
      .intercept((it) => {
        it.list.forEach((comment) => {
          reactionRepo
            .setValue(comment.commentId, comment.reactionCountList)
            .catch(andLog);
        });
      }),
  );

  const userPool = useRef(
    new ObjectPool<User>((oId) => backend.getUser(oId).run()),
  );

  const userSWR = useSWR(
    postSWR.content?.author
      ? backend.getUser(postSWR.content?.author?.uid)
      : undefined,
  );

  if (userSWR?.content) {
    userPool.current.cacheObjectIfNotExist(
      userSWR.content.uid,
      userSWR.content,
    );
  }

  const [replyToComment, setReplyToComment] = useState<BareComment>();

  const myUid = useMyUid();

  const orderedMediaList = getOrderedFilteredMediaList(
    postSWR.content?.mediaList,
    postSWR.content?.richFormat,
  );

  const commentMenu = useModal("comment-menu");
  const [clickedComment, setClickedComment] = useState<BareComment>();

  const viewMedia = (media: Media) => {
    const mediaOwnerMap = new Map();
    orderedMediaList?.forEach((media) => {
      const ownerSpec = {
        objectId: postId,
        objectType: objectType || ObjectType.BLOG,
      };
      const flagExt = {
        flagMedia: media,
        flagMediaLocation: FlagMediaLocationEnum.CONTENT_IMAGE,
      };

      const mediaOwner: MediaOwner = {
        ownerSpec: ownerSpec,
        authorId: postSWR.content?.author?.uid,
        flagExt: flagExt,
      };
      mediaOwnerMap.set(media.mediaId, mediaOwner);
    });
    if (orderedMediaList) {
      nativePage.viewMedia({
        mediaList: orderedMediaList,
        position: orderedMediaList.findIndex(
          (m) => m.mediaId === media.mediaId,
        ),
      });
    }
  };
  const contentBackground = postSWR.content?.background ? (
    <BackgroundContainer>
      <BackgroundImage src={getBestRes(postSWR.content?.background).url} />
      <Dimmer />
    </BackgroundContainer>
  ) : undefined;

  const globalSpinner = useGlobalSpinner();

  function copyLink() {
    globalSpinner(async () => {
      const resp = await backend.getShareLink(`blog/${postId}`).run();
      if (resp.shareLink) {
        await copyTextToClipboard(resp.shareLink);
      }
      nativePage.successHud(i18n.copied()).catch(andLog);
    }).catch(andLog);
  }

  const moreMenu = useModal("moreMenu");
  const stickerModal = useModal("sticker-picker");
  const handleError = useErrorHandler();

  const onCopy = async () => {
    try {
      await globalSpinner(async () => {
        const content = clickedComment?.content;
        if (content) {
          await copyTextToClipboard(content);
        }
      });
      await nativePage.successHud(i18n.copied());
    } catch (e) {
      handleError(e);
    }
  };

  const deleteComment = (commentId: bigint) => {
    globalSpinner(async () => {
      await backend.deleteComment(commentId).run();
    }).then(() => commentSWR.load());
  };

  return (
    <Page
      pageData={postSWR}
      safeTopDisabled={
        (postSWR.content?.cover?.mediaId || 0) > 0 && pageSpec !== "wide"
      }
      underlay={contentBackground}
    >
      <NavEnd>
        {NavItem.image(NavButtonType.Ellipsis, () => moreMenu.open())}
      </NavEnd>
      {(postSWR.content?.cover?.mediaId || 0) > 0 && (
        <AspectRatioImage
          src={[postSWR.content?.cover, "best"]}
          style={
            pageSpec !== "wide" ? { ...FullPageWidthCellNoPadding } : undefined
          }
          aspectRatio={1.78}
        />
      )}
      <VStack style={{ marginTop: postSWR.content?.cover ? 15 : 0 }}>
        <Title>{postSWR.content?.title}</Title>
        <HStack
          style={{
            width: "100%",
            marginTop: postSWR.content?.title ? 30 : 0,
            gap: 10,
          }}
        >
          <UserInfoContainer>
            <UserAvatarView user={postSWR.content?.author} iconSize={32} />
            <VStack
              style={{
                marginInlineStart: 12,
                gap: 2,
                alignItems: "start",
                flex: 1,
              }}
            >
              {postSWR.content?.author && (
                <UserNameView
                  user={postSWR.content?.author}
                  clickToProfile={true}
                />
              )}
              <TimeLabel>
                {Intl.DateTimeFormat(navigator.language, {
                  dateStyle: "medium",
                  timeStyle: "short",
                }).format((postSWR.content?.createdTime ?? 0) * 1000)}
              </TimeLabel>
            </VStack>
          </UserInfoContainer>
          {userSWR?.content && userSWR?.content.uid !== myUid && (
            <FollowButton
              user={userSWR.content}
              backend={backend}
              userPool={userPool.current}
              height={25}
              padding={10}
              fontSize={11}
            />
          )}
        </HStack>

        {postSWR.content?.content && (
          <>
            <VSpace height={16} />
            <ZDoc
              content={postSWR.content.content}
              richFormat={postSWR.content.richFormat}
              mediaList={postSWR.content.mediaList}
              pollList={postSWR.content.pollList}
              onViewMedia={viewMedia}
            />
          </>
        )}

        {!postSWR.content?.richFormat &&
          (postSWR.content?.mediaList?.length || 0) > 0 && (
            <VStack style={{ gap: 12 }}>
              {postSWR.content?.mediaList?.map((media) => {
                if (isImage(media)) {
                  return (
                    <SingleImage
                      media={media}
                      onViewMedia={() => viewMedia(media)}
                    />
                  );
                } else if (isVideo(media)) {
                  return (
                    <SingleVideo
                      media={media}
                      onViewMedia={() => viewMedia(media)}
                    />
                  );
                } else if (isAudio(media)) {
                  return <AudioFlow media={media} />;
                } else {
                  return null;
                }
              })}
            </VStack>
          )}

        <VSpace height={10} />

        {postSWR.content?.sharedThread && (
          <SharedThreadCard sharedThread={postSWR.content?.sharedThread} />
        )}

        {postSWR.content?.circleList && (
          <VStack style={{ marginTop: 10, gap: 10 }}>
            <LinkedCircleTitle>{i18n.linked_circle()}</LinkedCircleTitle>
            <VStack style={{ gap: 10 }}>
              {postSWR.content.circleList.map((circle) => (
                <RelatedCircleItem
                  key={`related-circle-item-${circle.circleId}`}
                  circle={circle}
                />
              ))}
            </VStack>
          </VStack>
        )}

        <ReactionListView
          objectId={postSWR.content?.blogId ?? BigInt(0)}
          objectType={objectType || ObjectType.BLOG}
          reactions={postSWR.content?.reactionCountList}
          enable={true}
          modal={stickerModal}
        />
        <CommentsHeader style={{ ...FullPageWidthCellNoPadding }}>
          {i18n.comments_num(postSWR.content?.commentsCount ?? 0)}
        </CommentsHeader>

        {commentSWR.content?.map((comment) => (
          <CommentCell
            parentId={postId}
            parentType={objectType || ObjectType.BLOG}
            comment={comment}
            onClickReply={(comment) => {
              setReplyToComment(Object.assign({}, comment));
            }}
            onClick={(comment) => {
              setClickedComment(comment);
              commentMenu.open();
            }}
          />
        ))}
        {(commentSWR.content.length || 0) === 0 && (
          <VStack style={{ width: "100%", alignItems: "center" }}>
            <EmptyState src={icEmptyState} />
            <EmptyTitle>{i18n.nov_impr_no_comments_hint()}</EmptyTitle>
          </VStack>
        )}
      </VStack>
      <PageFooter obscuredZoneKey={"CommentInputBar"} safeBottomDisabled={true}>
        <CommentInputBar
          parentId={postId}
          parentType={objectType || ObjectType.BLOG}
          onSendComment={() => commentSWR.load()}
          commentCount={commentSWR.content.length}
          replyToComment={replyToComment}
          stickerModal={stickerModal}
        />
      </PageFooter>
      <BSMenu modal={moreMenu}>
        <BSMenuItem title={i18n.copy()} onClick={copyLink} />
        <BSMenuItem
          title={i18n.flag()}
          onClick={() =>
            hopper.layer("flag", {
              objectId: postId,
              objectType: objectType || ObjectType.BLOG,
            })
          }
        />
      </BSMenu>
      <BSMenu modal={commentMenu}>
        <BSMenuItem
          title={i18n.reply()}
          onClick={() => setReplyToComment(Object.assign({}, clickedComment))}
        />
        <BSMenuItem title={i18n.copy()} onClick={onCopy} />
        {clickedComment?.author.uid !== myUid && (
          <BSMenuItem
            title={i18n.flag()}
            onClick={() =>
              hopper.layer("flag", {
                objectId: clickedComment?.commentId,
                objectType: ObjectType.COMMENT,
              })
            }
          />
        )}
        {clickedComment?.author.uid === myUid && (
          <BSMenuItem
            title={i18n.delete()}
            titleStyle={{ color: "#FF3E65" }}
            onClick={() => {
              deleteComment(clickedComment?.commentId);
            }}
          />
        )}
      </BSMenu>
    </Page>
  );
}

const BackgroundContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Dimmer = styled.div`
  background-color: #00000066;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
`;

const Title = styled.div`
  width: 100%;
  font-weight: 700;
  font-size: 22px;
  word-break: break-word;
`;

const TimeLabel = styled.div`
  font-weight: 300;
  font-size: 12px;
  color: "#FFFFFF99";
`;

const CommentsHeader = styled.div`
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  color: #ffffffcc;
  background-color: #ffffff14;
  padding: 9px 20px;
  margin-bottom: 18px;
`;

export const EmptyState = styled.img`
  width: 120px;
  height: 120px;
`;

export const EmptyTitle = styled.div`
  font-size: 14px;
  text-align: center;
  margin-inline-start: 40px;
  margin-inline-end: 40px;
  font-weight: 400;
  color: #ffffff66;
`;

const LinkedCircleTitle = styled.div`
  font-weight: 400;
  font-size: 13px;
  ${OverFlowTextStyle};
  line-clamp: 1;
  -webkit-line-clamp: 1;
  color: #ffffffcc;
`;

function RelatedCircleItem(props: { circle: BareCircle }) {
  const hopper = useHopper();
  return (
    <RelatedCircleContainer
      onClick={() => hopper.push(`circle/${props.circle.circleId}`)}
    >
      <Image
        src={[props.circle.circleIcon, { width: 32, height: 32 }]}
        style={{
          width: 32,
          height: 32,
          borderRadius: 4,
          border: `1px solid ${props.circle.themeColor ?? "#FFFFFF"}`,
        }}
      />
      <VStack style={{ gap: 2, flex: 1 }}>
        <CircleName>{props.circle.name}</CircleName>
        <CircleTagline>{props.circle.tagline}</CircleTagline>
      </VStack>
    </RelatedCircleContainer>
  );
}

const RelatedCircleContainer = styled.div`
  ${HStackMixin};
  width: 100%;
  border-radius: 6px;
  padding: 8px;
  gap: 9px;
  background-color: #ffffff33;
`;

const CircleName = styled.div`
  font-weight: 700;
  font-size: 12px;
  ${OverFlowTextStyle};
  line-clamp: 1;
  -webkit-line-clamp: 1;
`;

const CircleTagline = styled.div`
  font-weight: 500;
  font-size: 10px;
  ${OverFlowTextStyle};
  line-clamp: 1;
  -webkit-line-clamp: 1;
`;

export function LinkCell(props: {
  title?: string;
  url?: string;
  media?: Media;
}) {
  const openLink = useOpenLink();

  return (
    <LinkContainer
      onClick={() => {
        if (props.url) openLink(props.url).catch(andLog);
      }}
    >
      {props.media && (
        <Image
          src={getFittestRes(props.media, { width: 50, height: 50 }).url}
        />
      )}

      {!props.media && (
        <IconContainer>
          <LinkIcon src={iconLink} />
        </IconContainer>
      )}

      <VStack style={{ flex: 1, gap: 4 }}>
        <LinkTitle>{props.title}</LinkTitle>
        <HStack style={{ flex: 1, gap: 4 }}>
          <LinkIconInline src={iconLinkInline} />
          <LinkURL>{props.url}</LinkURL>
        </HStack>
      </VStack>
    </LinkContainer>
  );
}

const LinkContainer = styled.div`
  border-radius: 10px;
  background-color: #ffffff14;
  width: 100%;
  padding: 8px;
  gap: 12px;
  ${alpha_on_pressed};
  ${HStackMixin};
`;

const IconContainer = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 5px;
  background-color: white;
  display: grid;
  justify-content: center;
  align-items: center;
`;

const LinkIcon = styled.img`
  width: 33px;
  height: 33px;
`;
const LinkIconInline = styled.img`
  width: 14px;
  height: 8px;
`;

const LinkTitle = styled.div`
  font-size: 15px;
  font-weight: 700;
  ${OverFlowTextStyle};
  line-clamp: 1;
  -webkit-line-clamp: 1;
`;

const LinkURL = styled.div`
  font-size: 11px;
  font-weight: 400;
  ${OverFlowTextStyle};
  line-clamp: 1;
  -webkit-line-clamp: 1;
  flex: 1;
`;

export function SharedThreadCard(props: { sharedThread: Chat }) {
  const hopper = useHopper();
  const i18n = useI18n();
  return (
    <SharedThreadContainer
      onClick={(event) => {
        event.stopPropagation();
        hopper.modal("nyi");
        // hopper.push(`chat/${props.sharedThread.threadId}`);
      }}
    >
      <Image
        width={100}
        height={75}
        src={[props.sharedThread.icon, "best"]}
        style={{ borderRadius: 6 }}
      />
      <HStack style={{ position: "absolute", bottom: 8, left: 14 }}>
        {props.sharedThread.membersSummary?.slice(0, 4).map((user, index) => (
          <MemberIconContainer
            key={`voter-${user.uid}`}
            style={{ marginLeft: index === 0 ? 0 : -8 }}
            onClick={(event) => {
              event.stopPropagation();
              hopper.push(`user/${user.uid}`);
            }}
          >
            <Image
              src={[user.icon, "best"]}
              style={{
                width: 24,
                height: 24,
                borderRadius: "50%",
                border: "1px solid white",
                boxSizing: "border-box",
              }}
            />
            {(props.sharedThread?.membersSummary?.length || 0) > 4 &&
              index === 3 && (
                <MemberDimmerContainer>
                  <MemberMoreLayer src={icThreeDot} />
                </MemberDimmerContainer>
              )}
          </MemberIconContainer>
        ))}
      </HStack>
      <VStack style={{ flex: 1, margin: 5, gap: 3, height: 75 }}>
        <ThreadTitle>{props.sharedThread.title}</ThreadTitle>
        <HStack style={{ flex: 1, alignItems: "end", gap: 5 }}>
          <ThreadContent>{props.sharedThread.content}</ThreadContent>
          <RegularSmallButton
            style={{ minHeight: 28, paddingLeft: 15, paddingRight: 15 }}
          >
            {i18n.join()}
          </RegularSmallButton>
        </HStack>
      </VStack>
    </SharedThreadContainer>
  );
}

const SharedThreadContainer = styled.div`
  position: relative;
  flex: 1;
  background-color: #ffffff14;
  border-radius: 6px;
  height: 75px;
  ${HStackMixin};
  ${alpha_on_pressed};
`;

const ThreadTitle = styled.div`
  flex: 1;
  ${OverFlowTextStyle};
  line-clamp: 2;
  -webkit-line-clamp: 2;
  font-size: 14px;
  font-weight: 600;
  color: white;
`;

const ThreadContent = styled.div`
  font-size: 11px;
  font-weight: 500;
  color: #ffffff99;
  flex: 1;
  ${OverFlowTextStyle};
  min-height: 25px;
  line-clamp: 2;
  -webkit-line-clamp: 2;
`;

const MemberIconContainer = styled.div`
  position: relative;
`;

const MemberDimmerContainer = styled.div`
  position: absolute;
  box-sizing: border-box;
  display: grid;
  align-items: center;
  justify-content: center;
  background-color: #0000007e;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  border: 1px solid white;
  top: 0;
  left: 0;
`;

const MemberMoreLayer = styled.img`
  width: 20px;
  height: 20px;
`;
