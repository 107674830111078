import { NFT } from "../../../proto/NFT";
import { Circle } from "../../../proto/Circle";
import CircleCardsPreviewCell, {
  CardStyle,
} from "../../circle/card/CircleCardsPreviewCell";
import { NFTMediaView } from "./NFTDisplayView";
import styled from "styled-components";
import { Image } from "../../../components/Image";

const Container = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

const CircleCard = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
`;

export function NFTCircleCardDisplayView(props: { nft: NFT; circle: Circle }) {
  const is3DModel = props.nft.entity?.entityObject?.endsWith("glb") === true;

  return (
    <Container>
      <Image
        src={[props.nft.entity?.background, "best"]}
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
        }}
      />
      {props.nft.entity?.podium && (
        <NFTMediaView
          media={[props.nft.entity?.podium, "best"]}
          frame={props.nft.entity.renderParams?.podium}
        />
      )}
      <CircleCard>
        <CircleCardsPreviewCell
          card={props.nft.circleMembershipCard}
          cardStyle={CardStyle.large}
          circle={props.circle}
        />
      </CircleCard>
    </Container>
  );
}
