import { z } from "zod";
import { User } from "./User";
import { zBigInt, zStatic } from "../utils/zodUtils";
import { Currency } from "./Currency";
import { Media } from "./Media";
import { Coupon } from "./Coupon";
import { DexSwapInfo } from "./DexSwapInfo";
import { Circle } from "./Circle";
import { TokenProject } from "./TokenProject";
import { NFT } from "./NFT";
import { PurchaseMembershipCardInfo } from "./PurchaseMembershipCardInfo";

export const TotalPay = z.object({
  currency: Currency.optional(),
});

export type TotalPay = zStatic<typeof TotalPay>;

export const OrderRelatedTx = z.object({
  txId: zBigInt.optional(),
  amount: z.string().optional(),
  nftId: zBigInt.optional(),
  txCategory: z.number().optional(),
  currency: Currency.optional(),
  coupon: Coupon.optional(),
});

export type OrderRelatedTx = zStatic<typeof OrderRelatedTx>;

export const AssetOrderExtensions = z.object({
  beforeExpiresTime: zBigInt.default(0),
  afterExpiresTime: zBigInt.default(0),
});

export type AssetOrderExtensions = zStatic<typeof AssetOrderExtensions>;

export const BlockChainNetWork = z.object({
  chainNetwork: z.number(),
  name: z.string().default(""),
  icon: Media.optional(),
});

export type BlockChainNetWork = zStatic<typeof BlockChainNetWork>;

export const SmartContractInfo = z.object({
  chainType: z.number(),
  contractAddress: z.string(),
  contractId: zBigInt,
  name: z.string(),
  etherscanLink: z.string(),
});

export type SmartContractInfo = zStatic<typeof SmartContractInfo>;

export const MintOptionService = z.object({
  price: Currency.optional(),
  speedUpWaitingDuration: z.number().default(0),
});

export type MintOptionService = zStatic<typeof MintOptionService>;

export const MintServiceTemplate = z.object({
  chainInfoList: z.array(BlockChainNetWork).optional(),
  chainTypeList: z.array(z.number()).optional(),
  contractInfoList: z.array(SmartContractInfo).optional(),
  contractTypeList: z.array(z.string()).optional(),
  mintServiceName: z.string().optional(),
  mintServiceType: z.number(),
  availableCouponCount: z.number().default(0),
  priceInfo: Currency.optional(),
  supplyCountCandidates: z.array(z.number()).optional(),
  creatorFeePercentCandidates: z.array(z.number()).optional(),
  templateId: z.number().optional(),
  waitingDuration: z.number().optional(),
  orgInfo: User.optional(),
  mintServiceDescription: z.string().optional(),
  creatorFeePercent: z.number().default(0),
  orderedCount: z.number().optional(),
  price: Currency.optional(), // 单价
  currency: Currency.optional(), //总价
  paymentList: z.array(Currency).optional(),
  nft: NFT.optional(),
  bestValue: z.boolean().default(false),
  optionService: MintOptionService.optional(),
  nftAmount: z.string().optional(),
  chainInfo: BlockChainNetWork.optional(),
  mintServiceMarkdownDesc: z.string().default(""),
  cancelDuration: z.number().default(0),
});

export type MintServiceTemplate = zStatic<typeof MintServiceTemplate>;

export const UserPropsPackage = z.object({
  packageId: zBigInt,
  quota: z.number().optional(),
  units: z.number().optional(),
  propsType: z.number(),
  price: Currency.optional(),
  originalPrice: Currency.optional(),
  discountRate: z.number().default(0),
  icon: Media.optional(),
  title: z.string().optional(),
});

export type UserPropsPackage = zStatic<typeof UserPropsPackage>;

export const PurchaseUserPropsPackageInfo = z.object({
  packageInfo: UserPropsPackage.optional(),
  count: z.number().default(0),
  totalQuota: z.number().optional(),
  totalPrice: Currency.optional(),
  totalPay: Currency.optional(),
});

export type PurchaseUserPropsPackageInfo = zStatic<
  typeof PurchaseUserPropsPackageInfo
>;

export const PurchaseProductExtensions = z.object({
  renewalInterval: z.number().default(0),
  membershipStatus: z.number().default(0),
  androidBasePlanId: z.string().default(""),
  androidOfferId: z.string().default(""),
  androidSubscription: z.string().default(""),
});

export type PurchaseProductExtensions = zStatic<
  typeof PurchaseProductExtensions
>;

export const PurchaseProduct = z.object({
  productGUID: zBigInt.default(0),
  productId: z.string().default(""),
  productType: z.number().optional(),
  title: z.string().default(""),
  icon: Media.optional(),
  appStoreType: z.number().optional(),
  currencyType: z.number().optional(),
  discountRate: z.number().optional(),
  amount: Currency.optional(),
  price: Currency.optional(),
  originPrice: Currency.optional(),
  extensions: PurchaseProductExtensions.optional(),
});

export type PurchaseProduct = zStatic<typeof PurchaseProduct>;

export const PurchaseInfo = z.object({
  productId: z.string().default(""),
  vendorType: z.number().default(0),
  product: PurchaseProduct.optional(),
});

export type PurchaseInfo = zStatic<typeof PurchaseInfo>;

export const AssetOrder = z.object({
  orderId: zBigInt,
  uid: zBigInt.optional(),
  user: User.optional(),
  orderType: z.number().optional(),
  orderStatus: z.number().optional(),
  createdTime: z.number().optional(),
  claimedTime: z.number().optional(),
  totalPay: TotalPay.optional(),
  toBeClaimedTime: z.number().optional(),
  processedTime: z.number().optional(),
  txList: z.array(OrderRelatedTx).optional(),
  adminUserInfo: User.optional(),
  extension: AssetOrderExtensions.optional(),
  mintServiceInfo: MintServiceTemplate.optional(),
  purchaseQuotaPackageInfo: PurchaseUserPropsPackageInfo.optional(),
  purchaseInfo: PurchaseInfo.optional(),
  gasFee: Currency.optional(),
  totalDeductionList: z.array(Currency).optional(),
  fromChainAddress: z.string().optional(),
  toChainAddress: z.string().optional(),
  currencyType: z.number().optional(),
  amount: z.string().optional(),
  dexSwapInfo: DexSwapInfo.optional(),
  circle: Circle.optional(),
  tokenProject: TokenProject.optional(),
  purchaseMembershipCardInfo: PurchaseMembershipCardInfo.optional(),
  nft: NFT.optional(),
  currency: Currency.optional(),
});

export type AssetOrder = zStatic<typeof AssetOrder>;

export enum AssetOrderType {
  InAppPurchase = 1,
  TopUp = 2,
  WithDraw = 3,
  NFT_GIFT = 4,
  Swap = 6,
  NFT_MINT = 7, // ????????
  TransferFromStore = 9,
  MINT = 14,
  QUOTA = 15,
  CashSwap = 16,
  TransferOut = 17,
  ThirdPartyPurchase = 18,
  SendToken = 20,
  Trade = 26,
  SOLToGems = 27,
  SolSwap = 28,
  GetMembershipCard = 29,
  RedeemMembershipCard = 30,
  TransferAdminCommission = 31,
  CreateSOLToken = 32,
  ConvertGems = 33,
}

export enum AssetOrderStatus {
  Pending = 1,
  Done = 2,
  waitingReview = 3,
  Rejected = 4,
  Canceled = 5,
  Failed = 6,
  ChainDone = 7, //用户无感知，当作pending
  ToBeClaimed = 8,
  Refunded = 9,
  ThirdPartyRefunded = 10,
  ToBeTriggered = 11,
  Submitted = 12,
}

export enum TxCategory {
  SENT = 1,
  GOT = 2,
  FEE = 3,
}
