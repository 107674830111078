import { I18n, useI18n } from "../../hooks/useI18n";
import { useHopper } from "../../hooks/useHopper";
import { Page } from "../../components/Page";
import { NavEnd, NavItem, NavMiddle } from "../../components/NavBar";
import React from "react";
import { AuthSubtitleLabel, AuthTitleLabel } from "./InvitationCodePage";
import { RegularLargeButton } from "../../components/Buttons";
import { useNativePage } from "../../hooks/useBridge";
import styled, { RuleSet } from "styled-components";
import { Image } from "../../components/Image";
import { HStack, VStackMixin } from "../../components/VStack";
import genderManHighlight from "../../res/images/icon_sign_gender_man_highlight.svg";
import genderMan from "../../res/images/icon_sign_gender_man.svg";
import genderWomanHighlight from "../../res/images/icon_sign_gender_woman_highlight.svg";
import genderWoman from "../../res/images/icon_sign_gender_woman.svg";
import genderCustomizeHighlight from "../../res/images/icon_sign_gender_secret_highlight.svg";
import genderCustomize from "../../res/images/icon_sign_gender_secret.svg";
import genderHighlightBg from "../../res/images/icon_sign_gender_highlight_bg.svg";
import { usePageSpec } from "../common/usePageSpec";
import { Gender } from "../../proto/UserEnums";
import { useStringSearchParam } from "../../hooks/useTypedParam";
import { useMemoryRepo } from "../../hooks/swr/useLocalRepo";
import { AuthBody } from "../../proto/Auth";
import { accent_font } from "../../components/CommonStyles";

export const AllGender = [Gender.man, Gender.woman, Gender.secret];

export function SelectGenderPage() {
  const i18n = useI18n();
  const hopper = useHopper();
  const nativePage = useNativePage();

  const flowId = useStringSearchParam("f");
  const authInfo = useMemoryRepo<AuthBody>(flowId ?? "", {});

  const [gender, setGender] = React.useState<Gender>();

  async function onNextClick() {
    const chooseYes = await nativePage.alertYesOrCancel(
      i18n.gender_age_prompt_gender_submit_tip(getSignGenderName(i18n, gender)),
      i18n.yes(),
      i18n.cancel(),
    );
    if (chooseYes) {
      await authInfo.fill((prev) => ({
        ...prev,
        gender: gender,
      }));
      hopper.push("upload-profile-image", {
        f: flowId,
      });
    }
  }
  const pageSpec = usePageSpec();

  return (
    <Page pageData={undefined}>
      <NavMiddle>{i18n.auth_sign_up()}</NavMiddle>
      <NavEnd>
        {NavItem.text(i18n.help(), () => hopper.push("feedback"))}
      </NavEnd>
      <TitleLabel
        style={{
          fontWeight: pageSpec === "wide" ? 400 : 500,
          fontSize: pageSpec === "wide" ? 28 : 20,
        }}
      >
        {i18n.select_your_gender()}
      </TitleLabel>
      <SubtitleLabel
        style={{
          marginTop: "12px",
          fontWeight: pageSpec === "wide" ? 400 : 500,
          fontSize: pageSpec === "wide" ? 20 : 13,
        }}
      >
        {i18n.gender_constel_cant_change_gender_hint()}
      </SubtitleLabel>
      <HStack
        style={{
          margin: "33px auto 66px",
          justifyContent: "center",
          alignItems: "center",
          gap: "15px",
        }}
      >
        {AllGender.map((eleGender) => (
          <GenderSelectableView
            i18n={i18n}
            gender={eleGender}
            selected={gender === eleGender}
            onClick={() => setGender(eleGender)}
          ></GenderSelectableView>
        ))}
      </HStack>
      <RegularLargeButton
        disabled={!gender}
        style={{ margin: "0 62px" }}
        onClick={onNextClick}
      >
        {i18n.next()}
      </RegularLargeButton>
    </Page>
  );
}

const TitleLabel = styled.div`
  color: var(--color-text00);
  text-align: center;
  margin-top: 24px;
`;

const SubtitleLabel = styled.div`
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  font-weight: 500;
  text-align: center;
`;

export function GenderSelectableView(props: {
  i18n: I18n;
  gender: Gender;
  selected?: boolean;
  style?: RuleSet<Object>;
  onClick?: () => void;
}) {
  const { i18n, gender, selected = false, style, onClick } = props;
  let genderIcon = getSignGenderIcon(gender, selected);
  let genderName = getSignGenderName(i18n, gender);
  const pageSpec = usePageSpec();

  const width = pageSpec === "wide" ? "140px" : "96px";
  const height = pageSpec === "wide" ? "160px" : "114px";
  return (
    <Container
      style={{ width: width, height: height, position: "relative" }}
      mixin={style}
      onClick={onClick}
    >
      {selected && (
        <Image
          src={genderHighlightBg}
          alt={"genderHighlightBg"}
          width={width}
          height={height}
          style={{
            position: "absolute",
            zIndex: -1,
          }}
        ></Image>
      )}
      <Image
        src={genderIcon}
        style={{
          width: pageSpec === "wide" ? 79 : 54,
          height: pageSpec === "wide" ? 79 : 54,
          marginTop: pageSpec === "wide" ? "21px" : "15px",
          marginInlineStart: "auto",
          marginInlineEnd: "auto",
        }}
      />
      <GenderNameLabel
        style={{
          color: selected ? "var(--color-text00)" : "var(--color-text20)",
          marginTop: pageSpec === "wide" ? "13px" : "9px",
        }}
      >
        {genderName}
      </GenderNameLabel>
    </Container>
  );
}

const GenderNameLabel = styled.div`
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  text-shadow: 0 #ffffff99;
`;

export function getSignGenderIcon(
  gender?: Gender,
  needHighlight: Boolean = false,
) {
  switch (gender) {
    case Gender.man:
      return needHighlight ? genderManHighlight : genderMan;
    case Gender.woman:
      return needHighlight ? genderWomanHighlight : genderWoman;
    case Gender.secret:
      return needHighlight ? genderCustomizeHighlight : genderCustomize;
    default:
      return undefined;
  }
}

export function getSignGenderName(i18n: I18n, gender?: Gender) {
  switch (gender) {
    case Gender.man:
      return i18n.male();
    case Gender.woman:
      return i18n.female();
    case Gender.secret:
    default:
      return i18n.non_binary();
  }
}

const Container = styled.div<{ mixin?: RuleSet<Object> }>`
  ${VStackMixin}
`;
