import React from "react";

interface GridLayoutProps {
  spanCount: number; // 每行的列数
  items: React.ReactNode[]; // 子项的数组，可以是任何 React 元素
  style?: React.CSSProperties; // 支持外界传入的样式
}

const GridLayout: React.FC<GridLayoutProps> = ({ items, spanCount, style }) => {
  return (
    <div
      style={{
        ...styles.container,
        ...style,
        gridTemplateColumns: `repeat(${spanCount}, 1fr)`,
      }}
    >
      {items.map((item, index) => (
        <div key={index} style={{ ...styles.item, direction: "ltr" }}>
          {item}
        </div>
      ))}
    </div>
  );
};

const styles = {
  container: {
    display: "grid",
    paddingTop: "20px", // 添加顶部间距
    gap: "10px", // 控制每个子项之间的间距
  },
  item: {
    textAlign: "center" as const, // 让文本居中
  },
};

export default GridLayout;
