import { z } from "zod";
import { zBigInt, zStatic } from "../utils/zodUtils";

export const AuthSession = z.object({
  uid: zBigInt,
  sid: z.string().min(1),
  ptoken: z.string().min(1),
});

export type AuthSession = zStatic<typeof AuthSession>;
