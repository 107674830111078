import React, { CSSProperties, useEffect, useRef } from "react";
import { Swiper, SwiperClass, SwiperRef, SwiperSlide } from "swiper/react";
import "swiper/css";
import { usePageSpec } from "../pages/common/usePageSpec";
import { ObscuredZoneBoundary } from "./ObscuredZoneBoundary";
import { Subpage } from "./Subpage";
import styled, { css } from "styled-components";
import { useHopId, useHopState } from "../hooks/useHopState";
import ViewPagerIndicator from "./ViewPagerIndicator";
import { CenterChild, VSpace } from "./VStack";

export interface PagedItem {
  index: number;
  page: React.ReactElement;
  title: React.ReactNode;
}

export interface ViewPagerAdapter {
  pagedItems: PagedItem[];
}

export interface ViewPagerProps {
  adapter: ViewPagerAdapter;
  themeColor?: string;
  showTabsLayout?: boolean;
  showShoulder?: boolean;
  allowTouchMove?: boolean;
  initialIndex?: number;
  currentIndex?: number;
  preventInteractionOnTransition?: boolean;
  nested?: boolean;
  centeredSlides?: boolean;
  slidesPerView?: number | "auto";
  spaceBetween?: number;
  customTab?: JSX.Element;
  tabLayoutAllowMultipleLines?: boolean;
  scaleSideItems?: boolean;
  onSlideChange?: (activeIndex: number) => void;
}

interface StyledSwiperProps {
  scaleSideItems?: boolean;
}

const StyledSwiper = styled(Swiper)<StyledSwiperProps>`
  .swiper-slide {
    transition: transform 0.3s ease;
  }

  .swiper-slide-active {
    transform: scale(1); /* 中间卡片正常显示 */
  }

  ${({ scaleSideItems }) =>
    scaleSideItems &&
    css`
      .swiper-slide-prev,
      .swiper-slide-next {
        transform: scale(0.85); /* 左右两侧缩小 */
      }
    `}
`;

function ViewPager({
  adapter,
  themeColor,
  showTabsLayout,
  initialIndex,
  currentIndex,
  showShoulder,
  allowTouchMove,
  preventInteractionOnTransition,
  nested,
  centeredSlides,
  spaceBetween,
  slidesPerView,
  tabLayoutAllowMultipleLines = false,
  scaleSideItems = false,
  onSlideChange,
}: ViewPagerProps) {
  const pageId = useHopId();
  const [chosenTabIndex, setChosenTabIndex] = useHopState(
    `view-pager-index-${pageId}`,
    initialIndex ?? 0,
  );
  const pagedItems = adapter.pagedItems;

  useEffect(() => {
    setChosenTabIndex(currentIndex ?? 0);
  }, [currentIndex]);

  const swiperRef = useRef<SwiperRef | null>(null); // 用于存储 Swiper 实例
  // 当 chosenTabIndex 改变时，滑动到相应的页面
  useEffect(() => {
    swiperRef.current?.swiper?.slideTo(chosenTabIndex); // 调用 Swiper 的 slideTo 方法
  }, [chosenTabIndex]);

  useEffect(() => {
    console.log(`viewpager themeColor ${themeColor}`);
  }, [themeColor]);

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <ObscuredZoneBoundary>
        <StyledSwiper
          ref={swiperRef}
          nested={nested}
          slidesPerView={slidesPerView}
          centeredSlides={centeredSlides}
          allowTouchMove={allowTouchMove}
          spaceBetween={spaceBetween}
          preventInteractionOnTransition={preventInteractionOnTransition}
          scaleSideItems={scaleSideItems}
          onSlideChange={(swiper: SwiperClass) => {
            setChosenTabIndex(swiper.activeIndex); // 更新 chosenTabIndex
            if (onSlideChange) {
              onSlideChange(swiper.activeIndex);
            }
          }}
          initialSlide={chosenTabIndex} // 同步 Swiper 的初始索引
        >
          {pagedItems.map((item) => (
            <SwiperSlide key={item.index}>
              <Subpage id={item.index}>{item.page}</Subpage>
            </SwiperSlide>
          ))}
        </StyledSwiper>
        <VSpace height={20} />
        <CenterChild>
          <ViewPagerIndicator
            currentIndex={chosenTabIndex}
            pageSize={pagedItems.length}
          />
        </CenterChild>
      </ObscuredZoneBoundary>
    </div>
  );
}

export default ViewPager;
