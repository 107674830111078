import { Page } from "../../components/Page";
import { useBackend } from "../../service/APIService";
import { useSWR } from "../../hooks/swr/useSWR";
import CircleSnippetCell from "./CircleSnippetCell";
import { useHopper } from "../../hooks/useHopper";

export function CircleCircleLinkPage(props: { circleId: bigint }) {
  const backend = useBackend();
  const hopper = useHopper();
  const circleSWR = useSWR(backend.getCircle(props.circleId));

  return (
    <Page pageData={circleSWR}>
      {circleSWR.content && (
        <CircleSnippetCell
          circle={circleSWR.content}
          onClick={() => {
            hopper.push(`circle/${props.circleId}`);
          }}
        />
      )}
    </Page>
  );
}
