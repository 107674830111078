import { Page } from "../../components/Page";
import { useSWR } from "../../hooks/swr/useSWR";
import { CircleChildPageProps } from "./CircleChildPageProps";
import { useSWRArray } from "../../hooks/swr/useSWRArray";
import {
  CircleFolder,
  FolderPermission,
  FolderType,
} from "../../proto/CircleFolder";
import ChatGridCell from "../../components/ChatGridCell";
import StyledPostsFlow, { PostsFlowProps } from "./StyledPostsFlow";
import GridLayout from "../../components/GridLayout";
import {
  useBigIntParam,
  useBigIntSearchParam,
} from "../../hooks/useTypedParam";
import React from "react";
import CircleWidgetCell from "./CircleWidgetCell";
import { useBackend } from "../../service/APIService";
import { useI18n } from "../../hooks/useI18n";
import { HStack, Spring, VSpace, VStack } from "../../components/VStack";
import styled from "styled-components";
import icLock from "../../res/images/ic_folder_content_lock.svg";
import { CircleMembershipLevelIcon } from "./membership/MembershipLevelPicker";
import { RegularButton } from "../../components/Buttons";
import { useHopper } from "../../hooks/useHopper";
import { NavMiddle } from "../../components/NavBar";
import { PostCell } from "../post/PostCell";
import CircleMenuIconCell, {
  CircleMenuIconView,
} from "../../proto/CircleMenuIconCell";
import { SingleLineUnspecifiedWidth } from "../../components/CommonViews";
import { Image } from "../../components/Image";
import icFolderHide from "../../res/images/ic_folder_hidden.svg";
import icFolderLink from "../../res/images/icon_default_folder_link.png";
import icLink from "../../res/images/ic_link.svg";
import { CircleFolderItem } from "../../proto/CircleFolderItem";
import { ObjectType } from "../../proto/ObjectSpec";
import { SpeedSubtitle } from "../../components/SpeedTitle";
import { resolvePath } from "../../components/CircleWidgetBanner";
import { useLoadState } from "../../hooks/LoadState";
import { andLog, useErrorHandler } from "../../components/handleError";
import { theme } from "antd";
import { useMemoryRepoMap } from "../../hooks/swr/useLocalRepo";
import { zBigInt } from "../../utils/zodUtils";
import { z } from "zod";
import { Reaction } from "../../proto/Reaction";
import { useReactionRecord } from "../../hooks/useReactionRecord";

const FolderTitleLabel = styled.div`
  color: #ffffff;
  font-size: 24px;
  font-weight: bold;
`;

const FolderDescriptionLabel = styled.div`
  color: rgba(255, 255, 255, 0.8);
  font-size: 13px;
`;

function CircleFolderPage(props: CircleChildPageProps) {
  const backend = useBackend();
  const circleId = useBigIntParam("circleId");
  const pFolderId = useBigIntSearchParam("folderId", BigInt(0));
  const folderId = props.folderId ?? pFolderId;
  const circleSWR = useSWR(backend.getCircle(circleId));

  const circleFolderSWR = useSWR(backend.getCircleFolder(circleId, folderId));

  const folderName = () => {
    const folderName = circleFolderSWR.content?.name;
    if (!props.folderId && folderName) {
      return folderName;
    }
    return "";
  };

  const reactionRepo = useReactionRecord();

  const folderItemsSWR = useSWRArray(
    backend.getCircleFolderItems(circleId, folderId).intercept((it) => {
      if (circleFolderSWR.content?.folderType === FolderType.BLOG) {
        it.list.forEach((item) => {
          if (item.blog) {
            reactionRepo
              .setValue(item.blog.blogId, item.blog.reactionCountList)
              .catch(andLog);
          }
        });
      }
    }),
  );
  const i18n = useI18n();

  return (
    <Page
      pageData={[circleSWR, folderItemsSWR, circleFolderSWR]}
      alwaysShowsContent={!circleFolderSWR.content?.isViewable}
    >
      <NavMiddle>{folderName()}</NavMiddle>
      {!circleFolderSWR.content?.isViewable &&
      circleFolderSWR.content?.requiredMembershipLevel !== undefined ? (
        <>
          <VSpace height={88} />
          <ContentLimitedContainer
            circleId={circleId}
            level={circleFolderSWR.content?.requiredMembershipLevel}
          />
        </>
      ) : (
        <>
          <FolderTitleLabel>{circleFolderSWR.content?.name}</FolderTitleLabel>
          <FolderDescriptionLabel>
            {circleFolderSWR.content?.description}
          </FolderDescriptionLabel>
          {circleFolderSWR.content?.folderWidgets?.map((widget) => (
            <CircleWidgetCell
              circleId={circleId}
              folderId={folderId}
              widget={widget}
              i18n={i18n}
            />
          ))}
          {folderItemsSWR.content.length > 0 &&
            circleFolderSWR.content?.folderType === FolderType.BLOG && (
              <StyledPostsFlow
                circleId={circleId}
                showMembershipLevel={true}
                posts={folderItemsSWR.content.map((item) => item.blog!)}
                folderLayoutType={circleFolderSWR.content.folderLayout}
              />
            )}

          {folderItemsSWR.content.length > 0 &&
            circleFolderSWR.content?.folderType === FolderType.CHAT && (
              <GridLayout
                spanCount={2}
                items={folderItemsSWR.content.map((folderItem) => {
                  return (
                    <ChatGridCell
                      key={folderItem.chat?.threadId}
                      chat={folderItem?.chat}
                    />
                  );
                })}
              />
            )}

          {folderItemsSWR.content.length > 0 &&
            circleFolderSWR.content?.folderType === FolderType.GENERIC && (
              <GridLayout
                spanCount={1}
                items={folderItemsSWR.content.map((folderItem) => {
                  if (folderItem.objectType === ObjectType.FOLDER) {
                    return (
                      <FolderInFolder
                        folderItem={folderItem}
                        themeColor={circleSWR.content?.themeColor}
                      />
                    );
                  } else if (folderItem.objectType === ObjectType.LINK) {
                    return (
                      <CircleFolderLinkCell
                        folderItem={folderItem}
                        themeColor={circleSWR.content?.themeColor}
                      />
                    );
                  } else if (
                    folderItem.objectType === ObjectType.SECTION_HEADER
                  ) {
                    return (
                      <CircleSectionHeader title={folderItem.objectName} />
                    );
                  } else {
                    return <></>;
                  }
                })}
              />
            )}
        </>
      )}
    </Page>
  );
}

export default CircleFolderPage;

export function CircleSectionHeader(props: { title: string | undefined }) {
  return (
    <div style={{ maxWidth: "100%" }}>
      <SpeedSubtitle
        style={{
          flexShrink: 1,
          maxHeight: "100%",
          color: "white",
          overflow: "hidden",
          wordBreak: "break-all",
          maxLines: 1,
          lineClamp: 1,
          WebkitLineClamp: 1,
          display: "-webkit-box",
          textOverflow: "ellipsis",
          WebkitBoxOrient: "vertical",
        }}
      >
        {props.title}
      </SpeedSubtitle>
    </div>
  );
}

const Title = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: var(--color-text00);
  ${SingleLineUnspecifiedWidth}; // 直接应用 CSS 模块
`;

const TaglineTitle = styled.div`
  font-size: 11px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6); // 直接应用 CSS 模块
  ${SingleLineUnspecifiedWidth}; // 直接应用 CSS 模块
`;

function FolderInFolder(props: {
  folderItem: CircleFolderItem;
  themeColor?: string;
}) {
  const folderPrivacyIcon = () => {
    switch (props.folderItem.folder?.permission) {
      case FolderPermission.ReadOnly:
        return icLock;
      case FolderPermission.Hidden:
        return icFolderHide;
    }
    return undefined;
  };

  const hopper = useHopper();

  return (
    <div
      style={{
        backgroundColor: "rgba(255, 255, 255, 0.15)",
        borderRadius: 8,
        maxWidth: "100%",
      }}
      onClick={() => {
        const circleId = props.folderItem.circleId;
        const folderId = props.folderItem.folder?.folderId;
        hopper.push(`circle/${circleId}/folder`, { folderId: folderId });
      }}
    >
      <HStack
        style={{
          marginInlineStart: 12,
          marginInlineEnd: 12,
          paddingTop: 20,
          paddingBottom: 20,
          width: "100%",
          gap: 8,
          alignItems: "flex-start",
        }}
      >
        <CircleMenuIconView
          themeColor={props.themeColor}
          icon={props.folderItem.folder?.icon}
          title={props.folderItem.folder?.name}
        />
        <VStack
          style={{
            alignItems: "flex-start",
            height: 40,
            justifyContent: "center",
            maxWidth: "60%",
          }}
        >
          <Title>{props.folderItem.folder?.name}</Title>
          {props.folderItem.folder?.description &&
            props.folderItem.folder?.description.length > 0 && (
              <TaglineTitle>
                {props.folderItem.folder?.description}
              </TaglineTitle>
            )}
        </VStack>
        <Spring />
        <VStack
          style={{
            alignItems: "flex-start",
            height: 40,
            justifyContent: "center",
            paddingInlineEnd: 20,
          }}
        >
          <HStack>
            <Image src={folderPrivacyIcon()} width={14} height={14} />
            {props.folderItem.folder?.isViewable == false && (
              <Image src={icLock} width={14} height={14} />
            )}
            {props.folderItem.folder?.requiredMembershipLevel !== undefined &&
              props.folderItem.folder?.requiredMembershipLevel > 0 && (
                <CircleMembershipLevelIcon
                  level={props.folderItem.folder?.requiredMembershipLevel}
                  width={30}
                />
              )}
          </HStack>
        </VStack>
      </HStack>
    </div>
  );
}

function ContentLimitedContainer(props: { level: number; circleId: bigint }) {
  const i18n = useI18n();
  const hopper = useHopper();
  return (
    <VStack style={{ width: "100%", alignItems: "center" }}>
      <HStack>
        <LockImg src={icLock} />
        <CircleMembershipLevelIcon level={props.level} width={56} />
      </HStack>
      <FolderLimitationText>
        {i18n.clover_folder_content_limit_tip(props.level)}
      </FolderLimitationText>
      <RegularButton
        style={{ minWidth: 213, fontWeight: 500, marginTop: 7 }}
        onClick={() => hopper.push(`circle/${props.circleId}/card-picker`)}
      >
        {i18n.clover_upgrade_my_membership()}
      </RegularButton>
    </VStack>
  );
}

const LockImg = styled.img`
  width: 24px;
  height: 24px;
`;

const FolderLimitationText = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #ffffff66;
  word-break: break-word;
  margin: 16px 10px 0;
  text-align: center;
`;

function CircleFolderLinkCell(props: {
  folderItem: CircleFolderItem;
  themeColor?: string;
}) {
  const linkTarget = useLoadState();
  const handleError = useErrorHandler();
  const backend = useBackend();
  const hopper = useHopper();

  async function openInternalLink(link: string) {
    const r = await linkTarget.run(async () => {
      const linkModel = await backend.linkPathFrom(link).run();
      linkModel.path && resolvePath(linkModel.path, hopper);
    });

    if (!r.success) {
      handleError(r.error);
    } else {
      // hopper.back();
    }
  }

  return (
    <div
      style={{
        backgroundColor: "rgba(255, 255, 255, 0.15)",
        borderRadius: 8,
        maxWidth: "100%",
      }}
      onClick={() => {
        const link = props.folderItem.link;
        link && openInternalLink(link);
      }}
    >
      <HStack
        style={{
          marginInlineStart: 12,
          marginInlineEnd: 12,
          paddingTop: 20,
          paddingBottom: 20,
          width: "100%",
          gap: 8,
          alignItems: "flex-start",
        }}
      >
        <Image
          src={icFolderLink}
          width={36}
          height={36}
          style={{
            borderRadius: 8,
            borderWidth: 1,
            borderColor: props.themeColor,
            borderStyle: "solid",
          }}
        />
        <VStack
          style={{
            alignItems: "flex-start",
            height: 40,
            justifyContent: "center",
            maxWidth: "calc(100% - 45px)",
          }}
        >
          <Title>{props.folderItem.objectName}</Title>
          {props.folderItem.link && props.folderItem.link.length > 0 && (
            <TaglineTitle>{props.folderItem?.link}</TaglineTitle>
          )}
        </VStack>
        <Spring />
      </HStack>
    </div>
  );
}
