import { TagObjectProps } from "./TagUserPage";
import { useHopper } from "../../hooks/useHopper";
import { useBackend } from "../../service/APIService";
import { useI18n } from "../../hooks/useI18n";
import { useSWRList } from "../../hooks/swr/useSWRList";
import { Page } from "../../components/Page";
import { tagPageBackground } from "./TagsPage";
import * as React from "react";
import { ObjectType } from "../../proto/ObjectSpec";
import { Chat } from "../../proto/Chat";
import GridLayout from "../../components/GridLayout";
import {
  ChatGridCellProps,
  ChatGridContainer,
  ChatGridTitle,
} from "../../components/ChatGridCell";
import { Image } from "../../components/Image";
import { VStack } from "../../components/VStack";

export function TagPartyPage({ tagId, tagName }: TagObjectProps) {
  const hopper = useHopper();
  const backend = useBackend();
  const i18n = useI18n();
  const chatsSWR = useSWRList(
    backend.tagObjects(ObjectType.CHAT, Chat, tagId, tagName),
  );

  return (
    <Page underlay={tagPageBackground} pageData={chatsSWR}>
      {chatsSWR.content?.list && (
        <GridLayout
          spanCount={2}
          items={chatsSWR.content.list.map((item, index) => {
            return <PartyGridCell chat={item} key={item.threadId} />;
          })}
        ></GridLayout>
      )}
    </Page>
  );
}

export const PartyGridCell: React.FC<ChatGridCellProps> = ({ chat }) => {
  const hopper = useHopper();
  return (
    <VStack style={{ alignItems: "start" }}>
      <ChatGridContainer
        key={chat?.threadId}
        onClick={() => {
          hopper.modal("nyi");
          // hopper.push(`chat/${chat?.threadId}`);
        }}
      >
        <Image
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
          src={[chat?.icon, "best"]}
        />
      </ChatGridContainer>
      <ChatGridTitle style={{ marginTop: 10 }}>{chat?.title}</ChatGridTitle>
    </VStack>
  );
};
