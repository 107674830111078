import React, { DependencyList, TouchEventHandler, useMemo } from "react";
import { assert } from "../utils/asserts";

export type DragLifecycle = {
  start: TouchEventHandler<HTMLDivElement>;
  move: TouchEventHandler<HTMLDivElement>;
  end: TouchEventHandler<HTMLDivElement>;
  cancel: TouchEventHandler<HTMLDivElement>;
};

type DragSession = {
  readonly initClientY: number;
  readonly initCenter: number;
  readonly draggedHeight: number;
  readonly allElements: HTMLElement[];
  readonly allCenters: number[];
  readonly draggedIndex: number;

  insertIndex: number;
};

function findInsertIndex(session: DragSession, dy: number) {
  const initCenter = session.initCenter;
  const centerY = initCenter + dy;
  let insertIndex = session.draggedIndex;
  let minDistance = -1;
  session.allCenters.forEach((center, index) => {
    const distance = Math.abs(centerY - center);
    if (minDistance < 0 || distance < minDistance) {
      minDistance = distance;
      insertIndex = index;
    }
  });

  return insertIndex;
}

export function moveElement<T>(array: T[], from: number, to: number) {
  const copy = [...array];
  const fromElement = copy.splice(from, 1)[0];
  copy.splice(to, 0, fromElement);
  return copy;
}

export function useReorder(
  onReorder: (fromIndex: number, toIndex: number) => void,
  deps: DependencyList,
): DragLifecycle;

export function useReorder(
  startIndex: number,
  onReorder: (fromIndex: number, toIndex: number) => void,
  deps: DependencyList,
): DragLifecycle;

export function useReorder(
  arg0: number | ((fromIndex: number, toIndex: number) => void),
  arg1: any,
  arg2?: any,
): DragLifecycle {
  let startIndex: number;
  let onReorder: (fromIndex: number, toIndex: number) => void;
  let deps: DependencyList;

  if (typeof arg0 === "number") {
    startIndex = arg0;
    onReorder = arg1;
    deps = arg2;
  } else {
    startIndex = 0;
    onReorder = arg0;
    deps = arg1;
  }

  return useReorderImpl(startIndex, onReorder, deps);
}

export const kRecorderCellId = "reorder-cell";

function ensureDraggedCell(event: React.TouchEvent<HTMLElement>) {
  const draggedCell = event.currentTarget.closest(`#${kRecorderCellId}`);
  assert(
    !!draggedCell && draggedCell instanceof HTMLElement,
    "dragged cell not found",
  );
  const position = getComputedStyle(draggedCell).position;
  assert(
    position === "relative",
    `top relies on position being relative. it is ${position} instead`,
  );
  return draggedCell;
}

function useReorderImpl(
  startIndex: number,
  onReorder: (fromIndex: number, toIndex: number) => void,
  deps: DependencyList,
): DragLifecycle {
  return useMemo<DragLifecycle>(() => {
    let session: DragSession | null = null;
    return {
      start: (event) => {
        const draggedCell = ensureDraggedCell(event);

        const parent = draggedCell.parentElement;

        if (parent) {
          const allElements: HTMLElement[] = [];
          const allCenters: number[] = [];
          let draggedIndex = 0;
          parent.childNodes.forEach((n) => {
            if (n instanceof HTMLElement) {
              if (n === draggedCell) {
                draggedIndex = allElements.length;
                n.style.transition = "";
              } else {
                n.style.transition = "top 0.2s ease-in-out";
                n.style.top = "0px";
              }
              allElements.push(n);
              const rect = n.getBoundingClientRect();
              allCenters.push(rect.top + (rect.bottom - rect.top) / 2);
            }
          });

          if (draggedIndex < startIndex) {
            return;
          }

          const computedStyle = getComputedStyle(draggedCell);
          const marginTop = parseInt(computedStyle.marginTop);
          const marginBottom = parseInt(computedStyle.marginBottom);
          const draggedRect = draggedCell.getBoundingClientRect();
          session = {
            initClientY: event.touches[0].clientY,
            initCenter:
              draggedRect.top + (draggedRect.bottom - draggedRect.top) / 2,
            draggedHeight:
              draggedCell.getBoundingClientRect().height +
              marginTop +
              marginBottom,
            allElements: allElements,
            allCenters: allCenters,
            draggedIndex: draggedIndex,
            insertIndex: draggedIndex,
          };
        }
      },
      move: (event) => {
        const draggedCell = ensureDraggedCell(event);

        if (session) {
          const dy = event.touches[0].clientY - session.initClientY;
          draggedCell.style.top = `${dy}px`;

          const insertIndex = findInsertIndex(session, dy);
          if (insertIndex < startIndex) {
            return;
          }

          const draggedIndex = session.draggedIndex;
          const draggedHeight = session.draggedHeight;
          session.allElements.forEach((e, index) => {
            if (index < draggedIndex && index >= insertIndex) {
              e.style.top = `${draggedHeight}px`;
            } else if (index > draggedIndex && index <= insertIndex) {
              e.style.top = `-${draggedHeight}px`;
            } else if (index !== draggedIndex) {
              e.style.top = "0px";
            }
          });
          session.insertIndex = insertIndex;
        }
      },
      end: (event) => {
        const draggedCell = ensureDraggedCell(event);

        if (session) {
          const insertCenter = session.allCenters[session.insertIndex];

          draggedCell.style.top = `${insertCenter - session.initCenter}px`;
          draggedCell.style.transition = "top 0.2s ease-in-out";

          const allElements = session.allElements;
          const fromIndex = session.draggedIndex;
          const toIndex = session.insertIndex;

          draggedCell.addEventListener(
            "transitionend",
            (event) => {
              // allElements.forEach((e, index) => {
              //   e.style.top = "0px";
              //   e.style.transition = "";
              // });
              onReorder(fromIndex, toIndex);
            },
            { once: true },
          );
          session = null;
        }
      },
      cancel: (event) => {
        if (session) {
          session.allElements.forEach((e, index) => {
            e.style.top = "0px";
            e.style.transition = "";
          });

          session = null;
        }
      },
    };
  }, deps);
}
