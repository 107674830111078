import { Page } from "../../../components/Page";
import { ModalController } from "../../../components/Modal";
import { Category } from "../../../proto/Category";
import { useSWR } from "../../../hooks/swr/useSWR";
import { ObjectType } from "../../../proto/ObjectSpec";
import { useBackend } from "../../../service/APIService";
import styled from "styled-components";
import { FlexFlowStyles } from "../../../components/TagsFlowLayout";
import { Image } from "../../../components/Image";
import { useContext } from "react";
import { ModalContext } from "../../../components/ModalContext";
import { Backend } from "../../../service/Backend";

interface CategoryPickerProps {
  modal: ModalController;
  backend: Backend;
  onChoose: (category: Category) => void;
  lastChosenCategory?: Category;
}

function CategoryPicker(props: CategoryPickerProps) {
  const categoriesSWR = useSWR(props.backend.getCategories(ObjectType.CIRCLE));
  const { modal } = useContext(ModalContext);

  return (
    <Container>
      <div style={FlexFlowStyles.tagsContainer}>
        {categoriesSWR.content?.list?.map((category) => {
          return (
            <MiniCategoryCell
              key={category.categoryId}
              category={category}
              lastChosenCategory={props.lastChosenCategory}
              onClick={() => {
                props.onChoose(category);
                modal.close();
              }}
            />
          );
        })}
      </div>
    </Container>
  );
}

interface MiniCategoryProps {
  category: Category;
  lastChosenCategory?: Category;
  onClick: () => void;
}

function MiniCategoryCell(props: MiniCategoryProps) {
  return (
    <MiniCategoryBg onClick={props.onClick}>
      <Image
        src={[props.category.sticker?.media, "smallest"]}
        width={24}
        height={24}
      />
      <CategoryName>{props.category?.name}</CategoryName>
    </MiniCategoryBg>
  );
}

const CategoryName = styled.div`
  color: var(--color-text00);
  font-size: 13px;
  font-weight: 500;
`;

const MiniCategoryBg = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  padding: 6px 12px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.06);
  flex-direction: row;
`;

const Container = styled.div`
  height: 400px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

export default CategoryPicker;
