import { z } from "zod";
import { zBigInt, zEnum, zStatic } from "../utils/zodUtils";
import { User } from "./User";
import { Gender } from "./UserEnums";
import { Media } from "./Media";

export const SupportedSMS = z.object({
  phoneCode: z.number(),
  countryCode: z.string(),
});

export enum AuthType {
  Email = 1,
  Phone = 2,
  Google = 3,
  Apple = 4,
  PToken = 5,
  Facebook = 6,
  Huawei = 7,
  ShadowAccount = 8,
}

export enum SecurityPurpose {
  Register = 1,
  ChangePassword = 2,
  UpdateEmail = 3,
  UpdatePhoneNumber = 4,
  AddPaymentPassword = 5,
  UpdatePaymentPassword = 6,
  ResetPaymentPassword = 7,
  Withdraw = 8,
}

export enum NumericBoolean {
  True = 1,
  False = 2,
}

export const AuthBody = z.object({
  authType: zEnum(AuthType).optional(),
  email: z.string().optional(),
  password: z.string().optional(),
  secret: z.string().optional(),
  rawDeviceIdThree: z.string().optional(),
  phoneNumber: z.string().optional(),
  purpose: zEnum(SecurityPurpose).optional(),
  securityCode: z.string().optional(),
  updatedPassword: z.string().optional(),
  invitationCode: z.string().optional(),
  joinFanCircle: z.boolean().optional(),
  countryCode: z.string().optional(),
  birthday: z.string().optional(),
  gender: zEnum(Gender).optional(),
  oldPassword: z.string().optional(),
  newPassword: z.string().optional(),
  preEmail: z.string().optional(),
  prePhoneNumber: z.string().optional(),
  preSecurityCode: z.string().optional(),
  nickname: z.string().optional(),
  icon: Media.optional(),
  newEmail: z.string().optional(),
  newPhoneNumber: z.string().optional(),
  newSecurityCode: z.string().optional(),
  requestToBeReactivated: z.boolean().optional(),
  ignoresDisabled: z.boolean().optional(),
  googleToken: z.string().optional(),
  googleCode: z.string().optional(),
  googleRedirectUrl: z.string().optional(),
  appleToken: z.string().optional(),
  appleAuthorizationCode: z.string().optional(),
});

export type AuthBody = zStatic<typeof AuthBody>;

export const Account = z.object({
  uid: zBigInt,
  email: z.string().optional(),
  phoneNumber: z.string().optional(),
  createdTime: z.number(),
  googleId: z.string().optional(),
  appleId: z.string().optional(),
  hasPassword: zEnum(NumericBoolean).optional(),
});

export type Account = zStatic<typeof Account>;

export const Auth = z.object({
  sId: z.string(),
  secret: z.string(),
  account: Account,
  userProfile: User.optional(),
});
