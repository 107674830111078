import styled from "styled-components";

export interface TabCellSeparatorProps {
  title: string;
}

function TabCellSeparator(props: TabCellSeparatorProps) {
  return (
    <div
      style={{
        height: 34,
        display: "flex",
        paddingInlineStart: 20,
        paddingInlineEnd: 20,
        alignItems: "center",
        backgroundColor: "rgba(255,255,255,0.05)",
      }}
    >
      <TabTitle>{props.title}</TabTitle>
    </div>
  );
}

const TabTitle = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: var(--color-text10);
`;

export default TabCellSeparator;
