import { SearchProps } from "./SearchPage";
import { useBackend } from "../../service/APIService";
import { useI18n } from "../../hooks/useI18n";
import * as React from "react";
import { useEffect } from "react";
import { andLog } from "../../components/handleError";
import { Page } from "../../components/Page";
import TabCellSeparator from "../../components/TabCellSeparator";
import { useSWRList } from "../../hooks/swr/useSWRList";
import GridLayout from "../../components/GridLayout";
import { PartyGridCell } from "../tags/TagPartyPage";
import { hPaddingWithPageInset } from "../../components/CommonStyles";

export function SearchPartyPage({ word, circleId }: SearchProps) {
  const backend = useBackend();
  const i18n = useI18n();
  const chatsSWR = useSWRList(["SearchPartyPage", word], () =>
    backend.searchChats(word, circleId).run(),
  );

  useEffect(() => {
    chatsSWR.load().catch(andLog);
  }, [word]);

  return (
    <Page pageData={chatsSWR} scrollPaddingDisabled={true}>
      {chatsSWR.content && (
        <TabCellSeparator title={i18n.matched_by_keywords()}></TabCellSeparator>
      )}
      {chatsSWR.content && (
        <GridLayout
          style={{ ...hPaddingWithPageInset }}
          spanCount={2}
          items={chatsSWR.content.list.map((item, index) => {
            return <PartyGridCell chat={item} key={item.threadId} />;
          })}
        ></GridLayout>
      )}
    </Page>
  );
}
