import { number, z } from "zod";
import { User } from "./User";
import { zBigInt, zEnum, zStatic } from "../utils/zodUtils";
import { isValidMedia, Media } from "./Media";
import { ChatThreadType } from "./ChatThreadType";
import { Dice } from "./Dice";
import { Sticker } from "./Reaction";
import { colord } from "colord";
import { BareGiftBox } from "./BareGiftBox";

export enum MatchType {
  TextMatch = 1,
  VoiceMatch = 2,
  BottleMatch = 3,
}

export enum ChatMessageType {
  Text = 1,
  Image = 2,
  Video = 4,
  Deleted = 5,
  Audio = 6,
  Sticker = 7,
  MemberNotAcceptChatRequest = 8,
  MemberHasLeft = 9,
  MemberJoined = 10,
  MemberQuit = 11,
  InviteMembers = 12,
  KickOutMember = 13,
  RemoveMember = 14,
  RemoveCoHosts = 15,
  AddCoHosts = 16,
  RemoveByHost = 17,
  RemoveByCohost = 18,
  DeclineInvitation = 19,
  InvitationToRolePlay = 20,
  StartFreeTalk = 21,
  EndFreeTalk = 22,
  StartRolePlay = 23,
  EndRolePlay = 24,
  StartVoiceCall = 25,
  EndVoiceCall = 26,
  RejectVoiceCall = 27,
  CancelVoiceCall = 28,
  AcceptVoiceCall = 29,
  AddFreeTalkMember = 30,
  DeleteFreeTalkMember = 31,
  InviteToFreeTalk = 32,
  FreeTalkApply = 33,
  AcceptFreeTalkApply = 34,
  TalkingMembersUpdate = 35,
  FreeTalkApplyCount = 36,
  Roles = 37,
  RoleUpdate = 38,
  RoleplayApplyCount = 39,
  ConversationLevel = 40,
  AcceptRolePlayApply = 41,
  RemovePlayerFromRole = 42,
  AddThreadOnlineMember = 43,
  Dice = 44,
  Poll = 45,
  StartScreenRoom = 46,
  EndScreenRoom = 47,
  MatchAgree = 48,
  MatchBecameFriends = 49,
  ThreadDeleted = 50,
  ThreadHostChanged = 51,
  ThreadDisabled = 53,
  UserTyping = 60,
  UserTalking = 61,
  UserPlaying = 62,
  StartRolePlayV2 = 70,
  UpdateRolePlayPermissionMode = 80,
  UpdateFreeTalkPermissionMode = 81,
  UpdateScreenRoomPermissionMode = 82,
  ActivityTypeUpdate = 90,
  Share = 95,
  AddChatAnnouncement = 100,
  VoiceCallNotAnswered = 120,
  MatchRate = 121,
  NameCard = 122,
  MatchQuestion = 123,
  MatchV2Rate = 124,
  MatchContinueTip = 125,
  MatchLeave = 126,
  Bottle = 127,
  GiftBox = 128,
  GiftBoxSomeoneClaim = 129,
  FriendWasBanned = 130,
  GiftBoxAllClaimed = 131,
  ChatAiInfo = 132,
  CharacterLackOfEnergy = 133,
  CharacterWithoutOwnership = 134,
  StartCharacterChat = 135,

  Qi = 200,
  AddReaction = 201,
  DeleteReaction = 202,
  TakeRole = 203,
  SmallNote = 204,
  ContainerStatus = 205,
  RemoveAllMessagesOfMember = 206,
  MatchSuccess = 207,
  MatchJoined = 208,
  PlayItemSkipCheck = 209,

  ThreadEnableMic = 210,
  ThreadDisableMic = 211,
  ThreadMemberMute = 212,
  ThreadMemberUnMute = 213,
  MessagePollVote = 214,
  MessagePollUnVote = 215,

  MessagePollUpdate = 216,
  StartPlayScreenRoomItem = 217,
  EndPlayScreenRoomItem = 218,
  PlayNextScreenRoomItem = 219,
  PlayPrevScreenRoomItem = 220,
  CurrentPlayScreenRoomItem = 221,
  PlayItemNowPlayingInfo = 222,
  MatchBothJoinedTime = 223,
  PlayItemSkipTimeLeft = 224,

  MatchTimeout = 226,
  OnlyText = 227,
  DeleteReactionByAdmin = 229,
  PartyOnlineStatusChanged = 230,
  UserSendingVoiceMessage = 231,
  RoleTakeAndUntake = 232,

  ImageOrVideo = 255,

  MessageTime = 9999,
  Guideline = 10000,
  Welcome = 10001,
  MessageSentReward = 10002,
}

export const ChatMessageExtension = z.object({
  pollId: zBigInt.optional(),
  roleId: zBigInt.optional(),
  invitedUids: z.array(zBigInt).optional(),
  giftBoxId: zBigInt.optional(),
  roleContentStatus: z.number().optional(),
  activityAutomaticEnded: z.boolean().optional(),
  diceId: zBigInt.optional(),
  diceSideId: z.number().optional(),
  matchType: zEnum(MatchType).optional(),
  threadType: zEnum(ChatThreadType).optional(),
  stickerId: zBigInt.optional(),
});

export const ChatMessageRef = z.object({
  messageId: zBigInt.optional(),
  pollId: zBigInt.optional(),
});

export const ChatMessage = z.object({
  messageId: zBigInt.optional(),
  seqId: zBigInt.optional(),
  threadId: zBigInt.optional(),
  author: User.optional(),
  content: z.string().optional(),
  type: zEnum(ChatMessageType),
  asSummary: z.boolean().optional(),
  userList: z.array(User).optional(),
  bubbleColor: z.string().optional(),
  bubbleMedia: Media.optional(),
  bubbleTextColor: z.string().optional(),
  bubbleNFTId: zBigInt.optional(),
  extensions: ChatMessageExtension.optional(),
  dice: Dice.optional(),
  createdTime: z.number(),
  media: Media.optional(),
  uid: zBigInt,
  giftBox: BareGiftBox.optional(),
  refId: zBigInt.optional(),
  ref: ChatMessageRef.optional(),
  sticker: Sticker.optional(),

  errorCode: z.number().optional(),
  errorMsg: z.string().optional(),
});

export type ChatMessageExtension = zStatic<typeof ChatMessageExtension>;
export type ChatMessage = zStatic<typeof ChatMessage>;

export function isControlMessage(msg: ChatMessage): boolean {
  return [ChatMessageType.Deleted].includes(msg.type);
}

export function getChatMessageContentColor(msg: ChatMessage): string {
  if (msg.bubbleTextColor) {
    return msg.bubbleTextColor;
  }
  if (msg.bubbleColor) {
    const r = parseInt(msg.bubbleColor.substring(1, 3), 16);
    const g = parseInt(msg.bubbleColor.substring(3, 5), 16);
    const b = parseInt(msg.bubbleColor.substring(5, 7), 16);
    const luminance =
      (r * 0.2126) / 255 + (g * 0.7152) / 255 + (b * 0.0722) / 255;
    if (luminance < 0.7) {
      return "#FFFFFF";
    } else {
      return "#000000";
    }
  }
  return "#FFFFFF";
}

export function needSendAckFor(msg: ChatMessage): boolean {
  switch (msg.type) {
    case ChatMessageType.UserTyping:
    case ChatMessageType.UserSendingVoiceMessage:
    case ChatMessageType.MemberNotAcceptChatRequest:
      return false;
    default:
      return true;
  }
}

export function getChatMsgTextColor(msg: ChatMessage) {
  if (isValidMedia(msg.bubbleMedia)) {
    return msg.bubbleTextColor ?? "#ffffff";
  } else {
    return colord(msg.bubbleColor ?? "rgba(30, 158, 151)").isDark()
      ? "white"
      : "black";
  }
}

const NoBubbleMsgType = [
  ChatMessageType.Image,
  ChatMessageType.Video,
  ChatMessageType.Sticker,
  ChatMessageType.Dice,
  ChatMessageType.Share,
  ChatMessageType.GiftBox,
  ChatMessageType.AddChatAnnouncement,
];

export function isChatMessageNeedBubble(msg: ChatMessage) {
  return !NoBubbleMsgType.includes(msg.type);
}
