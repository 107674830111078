import styled from "styled-components";
import { ChatActivityType } from "../../../proto/Chat";
import { Image } from "../../../components/Image";
import IcRolePlay from "../images/ic_roleplay.svg";
import IcScreenRoom from "../images/ic_screenroom.svg";
import IcFreeTalk from "../images/ic_freetalk.svg";
import IcSettings from "../images/ic_stage_settings.svg";
import IcClose from "../images/ic_stage_close.svg";
import Arrow from "../images/ic_arrow.svg";
import { I18n, useI18n } from "../../../hooks/useI18n";
import { Spring } from "../../../components/VStack";
import { Spacing } from "../../../components/Spacing";
import { button_on_pressed } from "../../../components/CommonStyles";

const StageBackground = styled.div`
  position: relative;
  height: 200px;
  background-color: rgba(255, 255, 255, 0.1);
  margin: 0 var(--page-h-inset);
  border-radius: 8px;
  display: flex;
  align-items: start;
  gap: 10px;
`;

const Flag = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;

  // align left and center
  justify-content: start;
  align-items: center;
  box-sizing: border-box;

  background-color: rgba(0, 0, 0, 0.4);
  padding-left: 10px;
  border-bottom-right-radius: 10px;
  height: 30px;
`;

const ControlButton = styled.button`
  border: none;
  padding: 0;

  background-color: rgba(255, 255, 255, 0.15);
  width: 30px;
  height: 30px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;

  ${button_on_pressed}
`;

export function getActivityName(activity: ChatActivityType, i18n: I18n) {
  switch (activity) {
    case ChatActivityType.RolePlay:
    case ChatActivityType.RolePlayV2:
      return i18n.roleplay();
    case ChatActivityType.ScreenRoom:
      return i18n.screening_room_name();
    default:
      return i18n.party_free_talk();
  }
}

export function getActivityIcon(activity: ChatActivityType) {
  console.log(activity);
  switch (activity) {
    case ChatActivityType.RolePlay:
    case ChatActivityType.RolePlayV2:
      return IcRolePlay;
    case ChatActivityType.ScreenRoom:
      return IcScreenRoom;
    default:
      return IcFreeTalk;
  }
}

export default function ThreadStage(props: {
  activityType: ChatActivityType;
  onSettingClicked: () => void;
  onCloseClicked: () => void;
}) {
  const i18n = useI18n();
  return (
    <StageBackground>
      <Flag style={{}}>
        <Image src={getActivityIcon(props.activityType)} />
        <div style={{ fontSize: 14 }}>
          {getActivityName(props.activityType, i18n)}
        </div>
        <Image src={Arrow} />
      </Flag>
      <Spring />
      <ControlButton onClick={props.onSettingClicked}>
        <Image src={IcSettings} />
      </ControlButton>
      <ControlButton
        style={{ borderBottomRightRadius: 0 }}
        onClick={props.onCloseClicked}
      >
        <Image src={IcClose} />
      </ControlButton>
    </StageBackground>
  );
}
