import { User } from "../../../proto/User";
import styled from "styled-components";
import { UserIconView } from "../../../components/views/UserAvatarView";
import { Image } from "../../../components/Image";
import IcMore from "../../../res/images/icon_ra_more.svg";

const StackHolder = styled.div`
  position: relative;
  display: flex;
`;

export default function MemberStack(props: { users?: User[]; total?: number }) {
  const membersCount = props.users?.length ?? props.total ?? 0;
  return (
    <StackHolder>
      {membersCount > 3 && (
        <UserIconView user={props.users?.[3]} iconSize={30} />
      )}
      {membersCount > 2 && (
        <UserIconView
          user={props.users?.[2]}
          iconSize={30}
          style={{ marginLeft: -7 }}
        />
      )}
      {membersCount > 1 && (
        <UserIconView
          user={props.users?.[1]}
          iconSize={30}
          style={{ marginLeft: -7 }}
        />
      )}
      {membersCount > 0 && (
        <UserIconView
          user={props.users?.[0]}
          iconSize={30}
          style={{ marginLeft: membersCount > 1 ? -7 : 0 }}
        />
      )}
      {membersCount > 4 && (
        <Image
          src={IcMore}
          style={{
            width: 30,
            height: 30,
            borderRadius: 15,
            position: "absolute",
            right: 0,
            top: 0,
            background: "rgba(0,0,0,0.8)",
          }}
        ></Image>
      )}
    </StackHolder>
  );
}
