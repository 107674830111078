import { useBigIntParam } from "../../../hooks/useTypedParam";
import { useNativePage } from "../../../hooks/useBridge";
import { useSWR } from "../../../hooks/swr/useSWR";
import { useI18n } from "../../../hooks/useI18n";
import { useLoadState } from "../../../hooks/LoadState";
import React, { useEffect, useState } from "react";
import { andLog, useErrorHandler } from "../../../components/handleError";
import { RegularInputGroup } from "../../../components/Input";
import { Page } from "../../../components/Page";
import { Spacing } from "../../../components/Spacing";
import { RegularButton } from "../../../components/Buttons";
import { Spin } from "../../../components/Spin";
import { Circle } from "../../../proto/Circle";
import { useHopper } from "../../../hooks/useHopper";
import { useBackend } from "../../../service/APIService";
import {
  NavEnd,
  NavItem,
  NavMiddle,
  NavStart,
} from "../../../components/NavBar";
import { useHopState } from "../../../hooks/useHopState";
import { CircleInfoMaxLength } from "../compose/EditCirclePage";
import { delay } from "../../../utils/ensureDur";
import { CircleCardStatus } from "../../../proto/CircleCardStatus";
import { NavButtonType } from "../../../bridge/NavButtonDesc";
import { useGlobalSpinner } from "../../../utils/globalSpinner";
import { copyTextToClipboard } from "../../../utils/CopyText";

export function EditCircleCardPage() {
  const cardId = useBigIntParam("cardId");
  const circleId = useBigIntParam("circleId");
  const backend = useBackend();
  const cardSWR = useSWR(backend.getMembershipCard(circleId, cardId));
  const cardsSWR = useSWR(backend.getCircleCardsList(circleId));
  const i18n = useI18n();
  const handleError = useErrorHandler();
  const nativePage = useNativePage();

  const hopper = useHopper();

  const [initialFill, setInitialFill] = useHopState(
    `${cardId}-initialFill`,
    false,
  );
  const [input, setInput] = useHopState<string>(`${cardId}-card-name`);
  useEffect(() => {
    const card = cardSWR.content;
    if (card && !initialFill) {
      setInitialFill(true);
      setInput(card.name);
    }
  }, [initialFill, cardSWR.content]);

  const globalSpinner = useGlobalSpinner();

  const onClickUpdateCard = async () => {
    try {
      const length = (input ?? "").length;
      if (length > CircleInfoMaxLength.CardName) {
        nativePage
          .infoHud(i18n.signup_impr_nickname_length_invalid_toast())
          .catch(andLog);
        return;
      }

      await globalSpinner(async () => {
        const card = await backend
          .updateMembershipCard(circleId, cardId, { name: input })
          .run();
        await delay(200);
        await cardSWR.load();
        await cardsSWR.load();
        hopper.back();
      });
    } catch (e) {
      handleError(e);
    }
  };

  const backWithCheck = async () => {
    if (input === cardSWR.content?.name) {
      hopper.back();
      return;
    }
    try {
      const quit = await nativePage.alertAreYouSure(
        i18n.compose_confirmation(),
        i18n.quit(),
        i18n.oct12_text_continue_editing(),
      );
      if (quit) {
        hopper.back();
      }
    } catch (e) {
      handleError(e);
    }
  };

  return (
    <Page pageData={cardSWR}>
      <NavStart>{NavItem.image(NavButtonType.Cross, backWithCheck)}</NavStart>
      <NavMiddle>{i18n.clover_edit_card_name()}</NavMiddle>
      <NavEnd>{NavItem.image(NavButtonType.Check, onClickUpdateCard)}</NavEnd>
      <RegularInputGroup
        value={input}
        updateValue={setInput}
        maxLength={CircleInfoMaxLength.CardName}
      />
      <Spacing height={100} />
    </Page>
  );
}
