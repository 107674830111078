import {
  ObscuredZone,
  ObscuredZoneContext,
  ObscuredZoneKey,
} from "../hooks/useObscuredZones";
import { PropsWithChildren, useContext } from "react";
import { useMap } from "../hooks/useMap";

export function ObscuredZoneBoundary(
  props: PropsWithChildren<{ id?: string }>,
) {
  const [getZone, setZone, getAllKeys, getFullMap] = useMap<
    ObscuredZoneKey,
    ObscuredZone
  >();

  const parent = useContext(ObscuredZoneContext);
  return (
    <ObscuredZoneContext.Provider
      value={{
        id: props.id,
        zones: getFullMap(),
        setZone: setZone,
        parent: parent.id === "default" ? undefined : parent,
      }}
    >
      {props.children}
    </ObscuredZoneContext.Provider>
  );
}
