import { LoadState, LoadStateKind } from "../hooks/LoadState";
import React from "react";
import styled from "styled-components";
import emptyState from "../res/images/empty_state.svg";
import { getErrorMsg } from "../bridge/Rejectable";
import { andLog, Spinner } from "./handleError";
import { VSpace, VStack } from "./VStack";
import { RegularButton } from "./Buttons";
import { useI18n } from "../hooks/useI18n";
import {
  cell_on_pressed,
  full_page_width_cell,
  h_padding_with_page_inset,
} from "./CommonStyles";
import { Image } from "./Image";

const InfoText = styled.div`
  text-align: center;
  padding: 10px 0;
  flex-shrink: 0;
  ${h_padding_with_page_inset};
  color: var(--color-text20);
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  width: 100%;

  box-sizing: border-box;
  word-wrap: break-word;
`;
export const LoadMoreState = styled.div`
  text-align: center;
  padding: 10px;
  color: var(--color-text10);
  font-size: 12px;
  font-weight: 400;
`;

interface Loadable {
  readonly load: (reason?: string) => Promise<void>;
}

export function isLoadable(
  refresh: Loadable | (() => void),
): refresh is Loadable {
  return (refresh as any).load;
}

type LoadStateProps = {
  loadState: LoadState | undefined;
  onClickRetry?: Loadable | (() => void);
  doneText?: string;
  errorImage?: string;
  reloadText?: string;
  notStartedText?: string;
};

export function DefaultEmptyView(props: {
  title: string;
  heightSpace?: number;
  topSpace?: number;
  bottomSpace?: number;
}) {
  return (
    <VStack>
      <VSpace height={props.topSpace ?? 0} />
      <Image
        src={emptyState}
        style={{
          width: 120,
          height: 120,
          objectFit: "contain",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      />
      <VSpace height={props.heightSpace ?? 30} />
      <InfoText style={{ paddingTop: 0 }}>{props.title}</InfoText>
      <VSpace height={props.bottomSpace ?? 0} />
    </VStack>
  );
}

export function LoadStateView(props: LoadStateProps) {
  const i18n = useI18n();
  if (props.loadState) {
    switch (props.loadState.kind) {
      case LoadStateKind.loadFailed:
        return (
          <VStack>
            {props.errorImage && (
              <Image
                src={props.errorImage}
                style={{
                  width: 120,
                  height: 120,
                  objectFit: "contain",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
            )}
            <InfoText>{getErrorMsg(props.loadState.error)}</InfoText>
            <VSpace height={26} />
            <RegularButton
              style={{ fontSize: 15, marginLeft: "auto", marginRight: "auto" }}
              onClick={() => {
                if (props.onClickRetry) {
                  if (isLoadable(props.onClickRetry)) {
                    props.onClickRetry.load().catch(andLog);
                  } else {
                    props.onClickRetry();
                  }
                }
              }}
            >
              {props.reloadText ?? i18n.meet_now_try_again()}
            </RegularButton>
          </VStack>
        );
      case LoadStateKind.loading:
        return (
          <VStack>
            <VSpace height={40} />
            <Spinner />
            <VSpace height={20} />
            <InfoText>{i18n.loading()}</InfoText>
          </VStack>
        );
      case LoadStateKind.loaded:
        if (props.doneText) {
          return <InfoText>{props.doneText}</InfoText>;
        } else {
          return <></>;
        }
    }
  } else if (props.notStartedText) {
    return <InfoText>{props.notStartedText}</InfoText>;
  } else {
    return <></>;
  }
}

export function LoadStateCell(props: LoadStateProps) {
  const i18n = useI18n();
  if (props.loadState) {
    switch (props.loadState.kind) {
      case LoadStateKind.loadFailed:
        return (
          <VStack
            mixin={[cell_on_pressed, full_page_width_cell]}
            onClick={() => {
              if (props.onClickRetry) {
                if (isLoadable(props.onClickRetry)) {
                  props.onClickRetry.load().catch(andLog);
                } else {
                  props.onClickRetry();
                }
              }
            }}
          >
            <InfoText>{getErrorMsg(props.loadState.error)}</InfoText>
            <InfoText>{props.reloadText ?? i18n.meet_now_try_again()}</InfoText>
          </VStack>
        );
      case LoadStateKind.loading:
        return (
          <InfoText style={{ paddingTop: 20, paddingBottom: 20 }}>
            {i18n.loading()}
          </InfoText>
        );
      case LoadStateKind.loaded:
        if (props.doneText) {
          return (
            <InfoText style={{ paddingTop: 20, paddingBottom: 20 }}>
              {props.notStartedText}
            </InfoText>
          );
        } else {
          return <></>;
        }
    }
  } else if (props.notStartedText) {
    return (
      <InfoText style={{ paddingTop: 20, paddingBottom: 20 }}>
        {props.notStartedText}
      </InfoText>
    );
  } else {
    return <></>;
  }
}
