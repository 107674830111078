import { z } from "zod";
import { zBigInt, zEnum, zStatic } from "../utils/zodUtils";
import { AuthType } from "./Auth";
import { Media } from "./Media";
import { CircleBackground } from "./CircleBackground";
import { CircleWidget } from "./CircleWidget";

export const OperationPage = z.object({
  pageId: zBigInt,
  pageType: z.number().optional().default(0),
  status: z.number(),
  title: z.string().optional().default(""),
  widgetList: CircleWidget.array().optional(),
  titleImage: Media.optional(),
  background: CircleBackground.optional(),
});

export type OperationPage = zStatic<typeof OperationPage>;
