import styled from "styled-components";
import { Image, MediaSizeMode } from "../../../components/Image";
import { Media } from "../../../proto/Media";
import { Frame, NFT } from "../../../proto/NFT";

const Container = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

const CircleCard = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
`;

export function NFTDisplayView(props: { nft: NFT }) {
  const is3DModel = props.nft.entity?.entityObject?.endsWith("glb") === true;

  return (
    <Container>
      <Image
        src={[props.nft.entity?.background, "best"]}
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
        }}
      />
      {props.nft.entity?.podium && (
        <NFTMediaView
          key={"nft_podium"}
          media={[props.nft.entity?.podium, "best"]}
          frame={props.nft.entity.renderParams?.podium}
        />
      )}
      <NFTMediaView
        key={"nft_object"}
        media={props.nft.entity?.entityObject}
        frame={props.nft.entity?.renderParams?.model?.frame}
      />
      {props.nft.entity?.ornaments?.map((ornament) => {
        const renderParam = props.nft.entity?.renderParams?.ornaments?.find(
          (param) => param.ornamentName === ornament.name,
        );
        return (
          <NFTMediaView
            key={ornament.name}
            media={[ornament.media, "best"]}
            frame={renderParam?.frame}
          />
        );
      })}
      {is3DModel ? undefined : undefined}
    </Container>
  );
}

export function NFTMediaView(props: {
  media: string | [Media | undefined, MediaSizeMode] | undefined;
  frame?: Frame;
}) {
  const offsetX = props.frame?.center?.x ?? 0;
  const offsetY = props.frame?.center?.y ?? 0;
  const multiW = props.frame?.size?.width ?? 0.37;
  const multiH = props.frame?.size?.height ?? 0.37;
  return (
    <Image
      src={props.media}
      style={{
        position: "absolute",
        width: `${100 * multiW}vh`,
        height: `${100 * multiH}vh`,
        left: "50%",
        top: "50%",
        transform: `translateX(${100 * (-0.5 - 3 * offsetX)}%) translateY(${100 * (-0.5 + 3 * offsetY)}%)`,
      }}
    />
  );
}
