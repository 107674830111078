import styled from "styled-components";
import { Image } from "../../components/Image";
import { cell_on_pressed } from "../../components/CommonStyles";
import { ReactNode } from "react";
import { SingleLineUnspecifiedWidth } from "../../components/CommonViews";
import { HStack, VStack } from "../../components/VStack";
import { CircleMembershipLevelIcon } from "./membership/MembershipLevelPicker";
import icLock from "../../res/images/ic_folder_content_lock.svg";
import icFolderHidden from "../../res/images/ic_folder_hidden.svg";
import { CircleFolder, FolderPermission } from "../../proto/CircleFolder";

export interface CircleMenuFolderProps {
  icon?: ReactNode;
  title?: string;
  description?: string;
  showDivider?: boolean; // 可选属性
  onClick?: () => void;
  folder?: CircleFolder;
}

function CircleMenuFolderCell(props: CircleMenuFolderProps) {
  const { showDivider = true } = props; // 默认值为 true
  const isHidden = props.folder?.permission === FolderPermission.Hidden;
  const readable = props.folder?.isViewable;
  return (
    <MenuFolderBackground
      showDivider={showDivider}
      onClick={props.onClick}
      style={{ opacity: isHidden ? 0.4 : 1 }}
    >
      {props.icon}
      <div
        style={{ gap: 4, flex: 1, display: "flex", flexDirection: "column" }}
      >
        <Title>{props.title}</Title>
        {props.description && (
          <SecondaryTitle>{props.description}</SecondaryTitle>
        )}
      </div>
      <HStack>
        {!isHidden && !readable && <LockIcon src={icLock} />}
        {isHidden && (
          <HiddenIcon
            src={icFolderHidden}
            style={{ width: readable ? 24 : 16, height: readable ? 24 : 16 }}
          />
        )}
        {props.folder?.requiredMembershipLevel !== undefined &&
          props.folder?.requiredMembershipLevel > 0 && (
            <CircleMembershipLevelIcon
              level={props.folder?.requiredMembershipLevel}
              width={25}
            />
          )}
      </HStack>
    </MenuFolderBackground>
  );
}

const MenuFolderBackground = styled.div<{ showDivider?: boolean }>`
  display: flex;
  align-items: center; // 垂直居中对齐
  color: var(--color-text00);
  font-size: 16px;
  gap: 12px;
  flex-direction: row;
  // 根据 showDivider 的值设置底部边框
  border-bottom: ${({ showDivider }) =>
    showDivider ? "1px solid rgba(255, 255, 255, 0.1)" : "none"};
  min-height: 70px;
  padding-inline-start: 20px;
  padding-inline-end: 20px;
  flex-shrink: 0;
  ${cell_on_pressed};
`;

const Title = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: var(--color-text00);
  ${SingleLineUnspecifiedWidth}; // 直接应用 CSS 模块
`;

const SecondaryTitle = styled.div`
  font-size: 11px;
  font-weight: 400;
  color: var(--color-text10);
  ${SingleLineUnspecifiedWidth}; // 直接应用 CSS 模块
`;

const LockIcon = styled.img`
  width: 16px;
  height: 16px;
`;

const HiddenIcon = styled.img`
  width: 24px;
  height: 24px;
`;

export default CircleMenuFolderCell;
