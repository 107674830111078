import { Page } from "../../../components/Page";
import { BottomSheet, ModalController } from "../../../components/Modal";
import styled from "styled-components";
import { NavMiddle } from "../../../components/NavBar";
import { useI18n } from "../../../hooks/useI18n";
import { useContext, useEffect } from "react";
import { ModalContext } from "../../../components/ModalContext";
import { JSONUtil } from "../../../utils/JSONUtil";
import { cell_on_pressed } from "../../../components/CommonStyles";
import * as fs from "node:fs";
import { andLog } from "../../../components/handleError";
import { Region } from "../../../proto/Region";
import theMap from "./sheet.json";
import { VStack } from "../../../components/VStack";
import IcChecked from "../../chat/settings/images/ic_checked.svg";
import { Image } from "../../../components/Image";

const typedMap: LanguageMap = theMap;

export interface LanguagePickerProps {
  modal: ModalController;
  onChoose: (code: string) => void;
  lastChosenLanguage?: string;
}

function LanguagePicker(props: LanguagePickerProps) {
  const i18n = useI18n();
  const { modal } = useContext(ModalContext);

  const onChoose = (code: string) => {
    props.onChoose(code);
    modal.close();
  };

  return (
    <ListContainer>
      {CANONICAL_CODES.map((code) => (
        <LanguageCell
          key={code}
          lastChosenLanguage={props.lastChosenLanguage}
          spec={getLanguageCountryName(code)}
          onClick={() => {
            onChoose(code);
          }}
        />
      ))}
    </ListContainer>
  );
}

interface LanguageCellProps {
  spec: LanguageNameSpec;
  lastChosenLanguage?: string;
  onClick: () => void;
}

function LanguageCell(props: LanguageCellProps) {
  return (
    <LanguageBg onClick={props.onClick}>
      <VStack>
        <LanguageName>{props.spec.nameByItself}</LanguageName>
        <OriginName>{props.spec.nameByUi}</OriginName>
      </VStack>
      {props.lastChosenLanguage === props.spec.code && (
        <Image src={IcChecked} width={28} height={28} />
      )}
    </LanguageBg>
  );
}

const ListContainer = styled.div`
  height: 400px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const LanguageBg = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 20px;
  justify-content: space-between;
  padding-inline-start: 20px;
  ${cell_on_pressed};
  padding-inline-end: 20px;
`;

const LanguageName = styled.div`
  color: var(--color-text00);
  font-size: 16px;
  font-weight: 600;
`;

const OriginName = styled.div`
  color: var(--color-text10);
  font-size: 13px;
  font-weight: 400;
`;

type LanguageMap = { [key: string]: { [key: string]: string } };

export function getLanguageCountryName(code: string): LanguageNameSpec {
  const nameByItself = typedMap[code]?.[code];
  const uiCode = getCanonicalLanguageCode() || "en";
  const nameByUi = typedMap[code]?.[uiCode];

  return {
    code: code,
    nameByUi: nameByUi || code,
    nameByItself: nameByItself || code,
  };
}

function getCanonicalLanguageCode(): string {
  return "en";
}

interface LanguageNameSpec {
  code: string;
  nameByUi: string;
  nameByItself: string;
}

const CANONICAL_CODES: string[] = [
  "en",
  "fr",
  "es",
  "pt",
  "it",
  "de",
  "zh-Hans",
  "zh-Hant",
  "nl",
  "ja",
  "ko",
  "vi",
  "ru",
  "sv",
  "da",
  "fi",
  "nb",
  "tr",
  "el",
  "id",
  "ms",
  "th",
  "hi",
  "hu",
  "pl",
  "cs",
  "sk",
  "uk",
  "hr",
  "ca",
  "ro",
  "he",
  "ar",
  "my",
];

export default LanguagePicker;
