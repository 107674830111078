import { Page } from "../../components/Page";
import { useI18n } from "../../hooks/useI18n";
import { useBigIntParam } from "../../hooks/useTypedParam";
import React from "react";
import { useBackend } from "../../service/APIService";
import { PostCell } from "../post/PostCell";
import { useSWRArray } from "../../hooks/swr/useSWRArray";
import { ObjectType } from "../../proto/ObjectSpec";
import { NavMiddle } from "../../components/NavBar";
import { andLog } from "../../components/handleError";
import { useMemoryRepoMap } from "../../hooks/swr/useLocalRepo";
import { zBigInt } from "../../utils/zodUtils";
import { z } from "zod";
import { Reaction } from "../../proto/Reaction";
import { useReactionRecord } from "../../hooks/useReactionRecord";

function CircleAnnouncementsPage() {
  const circleId = useBigIntParam("circleId");
  const i18n = useI18n();
  const backend = useBackend();
  const reactionRepo = useReactionRecord();

  const circleAnnouncementsSWR = useSWRArray(
    backend.getCircleAnnouncements(circleId).intercept((it) => {
      it.list.forEach((post) => {
        reactionRepo
          .setValue(post.blogId, post.reactionCountList)
          .catch(andLog);
      });
    }),
  );

  return (
    <Page
      pageData={circleAnnouncementsSWR}
      loadStateConfig={{ emptyText: i18n.no_announcement() }}
    >
      {circleAnnouncementsSWR.content?.map((post) => (
        <PostCell post={post} objectType={ObjectType.CIRCLE_ANNOUNCEMENT} />
      ))}
      <NavMiddle>{i18n.announcement()}</NavMiddle>
    </Page>
  );
}

export default CircleAnnouncementsPage;
