import { useLoadScript } from "./useLoadScript";
import { useCallback } from "react";
import { useLoadTask } from "./LoadState";

interface GoogleWindow extends Window {
  google?: any;
}

export function useGoogleAuth(appId: string) {
  const scriptLoaded = useLoadScript({
    src: "https://accounts.google.com/gsi/client",
  });

  const task = useCallback(async () => {
    const currentWindow: GoogleWindow = window;

    const promiseExecutor = {
      resolve: (value: string) => {},
      reject: (error: unknown) => {},
    };
    const client = currentWindow.google?.accounts?.oauth2.initCodeClient({
      client_id: appId,
      redirect_uri: window.location.origin,
      scope: "openid profile email",
      callback: (response: { code: string; error: any }) => {
        if (response.error) {
          promiseExecutor.reject(response.error);
        } else {
          promiseExecutor.resolve(response.code);
        }
      },
    });

    if (!client) throw Error("Failed to init google client");

    return {
      signIn: () => {
        const ret = new Promise<string>((resolve, reject) => {
          promiseExecutor.resolve = resolve;
          promiseExecutor.reject = reject;
        });
        client.requestCode();
        return ret;
      },
    };
  }, [appId]);

  return useLoadTask(scriptLoaded ? task : undefined);
}
