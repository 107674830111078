import { InternalAxiosRequestConfig } from "axios";
import jsSHA from "jssha";
import {
  arrayBufferToBase64,
  concatArrayBuffers,
  hexToArrayBuffer,
  utf8ToArrayBuffer,
} from "../utils/Blob";
import { z } from "zod";
import { assert } from "../utils/asserts";
import { cora } from "../config/config";

export enum HttpHeaderKey {
  DEVICE_ID = "rawDeviceId",
  DEVICE_ID2 = "rawDeviceIdTwo",
  DEVICE_ID3 = "rawDeviceIdThree",
  APP_TYPE = "appType",
  APP_VERSION = "appVersion",
  OS_TYPE = "osType",
  DEVICE_TYPE = "deviceType",
  SID = "sId",
  ACCEPT_LANGUAGE = "Accept-Language",
  COUNTRY_CODE = "countryCode",
  REQ_TIME = "reqTime",
  USER_AGENT = "User-Agent",
  CONTENT_REGION = "contentRegion",
  NONCE = "nonce",
  CARRIER_COUNTRY_CODES = "carrierCountryCodes",
  TIME_ZONE = "timeZone",
  TIME_ZONE_ID = "timeZoneId",
  FLAVOR = "flavor",
  PAGE_SPEC = "pageSpec",
  EXP_ID = "expId",
}

const HttpHeaderKeySignables = [
  HttpHeaderKey.DEVICE_ID,
  HttpHeaderKey.DEVICE_ID2,
  HttpHeaderKey.DEVICE_ID3,
  HttpHeaderKey.APP_TYPE,
  HttpHeaderKey.APP_VERSION,
  HttpHeaderKey.OS_TYPE,
  HttpHeaderKey.DEVICE_TYPE,
  HttpHeaderKey.SID,
  HttpHeaderKey.COUNTRY_CODE,
  HttpHeaderKey.REQ_TIME,
  HttpHeaderKey.USER_AGENT,
  HttpHeaderKey.CONTENT_REGION,
  HttpHeaderKey.NONCE,
  HttpHeaderKey.CARRIER_COUNTRY_CODES,
];

class HMAC {
  private readonly hasher: jsSHA;
  private readonly blockSize = 64;
  private readonly iKey: Uint8Array;
  private readonly oKey: Uint8Array;

  private clampKey(key: ArrayBuffer): Uint8Array {
    if (key.byteLength > this.blockSize) {
      const keyHasher = new jsSHA("SHA-256", "UINT8ARRAY");
      keyHasher.update(new Uint8Array(key));
      return keyHasher.getHash("UINT8ARRAY");
    } else {
      const clone = new Uint8Array(this.blockSize);

      clone.set(new Uint8Array(key), 0);
      return clone;
    }
  }

  constructor(key: ArrayBuffer, variant: boolean) {
    this.hasher = new jsSHA("SHA-256", "UINT8ARRAY");

    this.iKey = new Uint8Array(this.blockSize);
    this.oKey = new Uint8Array(this.blockSize);

    const clamped = this.clampKey(key);

    clamped.forEach((v, i) => {
      this.iKey[i] = v ^ (variant ? 0x5b : 0x5c);
      this.oKey[i] = v ^ 0x36;
    });

    this.hasher.update(this.iKey);
  }

  add(chunk: ArrayBuffer) {
    this.hasher.update(new Uint8Array(chunk));
  }

  getHash(): ArrayBuffer {
    const innerHash = this.hasher.getHash("UINT8ARRAY");

    const oHasher = new jsSHA("SHA-256", "UINT8ARRAY");
    oHasher.update(this.oKey);
    oHasher.update(innerHash);
    return oHasher.getHash("UINT8ARRAY");
  }
}

function customEncodeURL(url: string): string {
  return url.replace(/'/g, "%27");
}

export async function signRequest(request: InternalAxiosRequestConfig) {
  const keyHash = new jsSHA("SHA-256", "UINT8ARRAY");
  keyHash.update(new Uint8Array(hexToArrayBuffer(cora.body)));
  const hmac = new HMAC(
    keyHash.getHash("ARRAYBUFFER"),
    parseInt(cora.bodyV) === 8,
  );

  const url = customEncodeURL(request.url ?? "");
  if (url) {
    hmac.add(utf8ToArrayBuffer(url));
  }

  // Work around "Refused to set unsafe header"
  const headers = { ...request.headers };
  headers[HttpHeaderKey.USER_AGENT] = navigator.userAgent;

  HttpHeaderKeySignables.forEach((key, i) => {
    const valueOpt = headers[key];
    if (valueOpt !== undefined && valueOpt !== null) {
      const value = z.string().parse(valueOpt);

      hmac.add(utf8ToArrayBuffer(value));
    }
  });

  if (request.data) {
    assert(
      request.data instanceof ArrayBuffer || request.data instanceof FormData,
    );
    if (request.data instanceof ArrayBuffer) {
      hmac.add(request.data);
    } else {
      const file = request.data.get("media");
      if (file instanceof File) {
        const arrayBuffer = await file.arrayBuffer();
        hmac.add(arrayBuffer);
      }
    }
  }

  const hash = hmac.getHash();

  request.headers["hjtrfs"] = arrayBufferToBase64(
    concatArrayBuffers(hexToArrayBuffer(cora.bodyV), hash),
  );
  return request;
}
