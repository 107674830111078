import {
  CenterModal,
  ModalController,
  ModalTitle,
} from "../../../components/Modal";
import styled, { css } from "styled-components";
import React, { useEffect, useMemo, useState } from "react";
import { useI18n } from "../../../hooks/useI18n";
import { RegularButton } from "../../../components/Buttons";
import { Input, RegularInputGroup } from "../../../components/Input";
import { TokenProject } from "../../../proto/TokenProject";
import { useLoadState } from "../../../hooks/LoadState";
import { Backend } from "../../../service/Backend";
import {
  Currency,
  formatMoney,
  getCurrencyName,
} from "../../../proto/Currency";
import { useHopState } from "../../../hooks/useHopState";
import { andLog } from "../../../components/handleError";
import { useBigIntParam } from "../../../hooks/useTypedParam";
import { useSWR } from "../../../hooks/swr/useSWR";
import { useBackend } from "../../../service/APIService";
import { useEditingBigNumber } from "../../../hooks/useEditingBigNumber";
import { jsonifyBigNumber } from "../../../utils/CurrencyAmount";
import BigNumber from "bignumber.js";
import { Image } from "../../../components/Image";
import iconSearch from "../../../res/images/icon_search_layout_placeholder.svg";
import { SearchBackground } from "../../search/SearchPage";
import { HStack, VSpace } from "../../../components/VStack";
import { useNativePage } from "../../../hooks/useBridge";

export interface TokenRequirementProps {
  modal: ModalController;
  backend: Backend;
  performEdit: (tokenAmount: string) => void;
  currency?: Currency;
}

export function TokenRequirementModal(props: TokenRequirementProps) {
  const i18n = useI18n();
  const cardId = useBigIntParam("cardId");
  const circleId = useBigIntParam("circleId");
  const cardSWR = useSWR(props.backend.getMembershipCard(circleId, cardId));
  const [tokenAmount, setTokenAmount] = useEditingBigNumber(
    `editing-tokenAmount-${cardId}`,
  );

  const [initialFill, setInitialFill] = useHopState("initialFill", false);
  useEffect(() => {
    const currency = cardSWR.content?.currency;
    if (currency && !initialFill) {
      setInitialFill(true);
      setTokenAmount(
        BigNumber(currency.amount ?? "0")
          .div(BigNumber("1000000"))
          .toFixed(0),
      );
    }
  }, [cardSWR.content, initialFill]);
  const cardsSWR = useSWR(props.backend.getCircleCardsList(circleId));

  const prevCard = useMemo(() => {
    const thisCard = cardSWR.content;
    const cards = cardsSWR.content?.list;
    if (thisCard && cards) {
      const currentIndex = cards.findIndex(
        (card) => card.circleMembershipCard?.cardId === thisCard.cardId,
      );
      if (currentIndex > 0) {
        return cards[currentIndex - 1];
      }
    }
  }, [cardSWR.content, cardSWR.content]);

  const nextCard = useMemo(() => {
    const thisCard = cardSWR.content;
    const cards = cardsSWR.content?.list;
    if (thisCard && cards) {
      const currentIndex = cards.findIndex(
        (card) => card.circleMembershipCard?.cardId === thisCard.cardId,
      );
      if (currentIndex >= 0) {
        return cards[currentIndex + 1];
      }
    }
  }, [cardSWR.content, cardSWR.content]);

  const nativePage = useNativePage();
  const checkAndSet = async () => {
    if (Number(tokenAmount ?? "0") <= Number(0)) {
      nativePage
        .infoHud(i18n.clover_please_enter_valid_quantity())
        .catch(andLog);
      return;
    }

    const bigAmount = BigNumber(
      jsonifyBigNumber(BigNumber(tokenAmount ?? "0"), 6),
    );

    if (bigAmount.isGreaterThan(BigNumber("8000000000000000"))) {
      nativePage
        .infoHud(i18n.clover_cant_set_over_supply_amount_alert())
        .catch(andLog);
      return;
    }

    if (
      prevCard &&
      BigNumber(
        prevCard?.circleMembershipCard?.currency?.amount ?? "0",
      ).isGreaterThan(bigAmount)
    ) {
      nativePage
        .infoHud(i18n.clover_cant_set_smaller_amount_alert())
        .catch(andLog);
      return;
    }

    console.log(`sdadsw2 ${nextCard?.circleMembershipCard?.currency?.amount}`);

    if (
      nextCard &&
      bigAmount.isGreaterThan(
        BigNumber(nextCard?.circleMembershipCard?.currency?.amount ?? "0"),
      )
    ) {
      nativePage
        .infoHud(i18n.clover_cant_set_larger_amount_alert())
        .catch(andLog);
      return;
    }

    if (tokenAmount) {
      props.modal.close(() => {
        props.performEdit(tokenAmount);
      });
    }
  };
  return (
    <CenterModal modal={props.modal} mixin={ModalStyle}>
      <ModalTitle
        mixin={css`
          font-weight: 700;
        `}
      >
        {i18n.clover_clean_token_requirement()}
      </ModalTitle>
      <SearchBackground
        style={{
          height: 50,
          paddingInlineStart: 8,
          paddingInlineEnd: 8,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <HStack style={{ alignItems: "center" }}>
          <Image
            src={[cardSWR?.content?.currency?.icon, "smallest"]}
            style={{ borderRadius: 2 }}
            width={24}
            height={24}
          />
          <Input
            placeholder={"0"}
            type="number"
            value={tokenAmount}
            style={{
              height: 36,
              marginInlineStart: 6,
              padding: "0",
              width: 110,
            }}
            ref={(e) => {
              if (e) e.focus();
            }}
            onChange={(e) => {
              setTokenAmount(e.target.value);
            }}
          />
        </HStack>
        <TokenAmount>{getCurrencyName(cardSWR.content?.currency)}</TokenAmount>
      </SearchBackground>
      <VSpace height={1} />
      <RegularButton
        style={{ marginLeft: 25, marginRight: 25 }}
        onClick={checkAndSet}
      >
        {i18n.save()}
      </RegularButton>
    </CenterModal>
  );
}

const TokenAmount = styled.div`
  color: var(--color-text00);
  font-weight: 400;
  font-size: 14px;
`;

const ModalStyle = css`
  padding: 32px 25px;
`;
