export class JobCancelled extends Error {
  constructor() {
    super("SWR Job cancelled"); // call the parent constructor
    this.name = "JobCancelled"; // set the name property
  }
}

export class SWRJob {
  cancelled = false;

  cancel() {
    this.cancelled = true;
  }

  async run<R>(action: () => Promise<R>) {
    const r = await action();
    if (this.cancelled) {
      throw new JobCancelled();
    }
    return r;
  }

  async runIf<R>(condition: boolean, action: () => Promise<R>) {
    if (!condition) {
      return null;
    }
    const r = await action();
    if (this.cancelled) {
      throw new JobCancelled();
    }
    return r;
  }
}
