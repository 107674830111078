import { isLocalMedia, LocalMedia, MediaScene } from "../bridge/LocalMedia";
import { Media } from "../proto/Media";
import { MediaMenuConfig, MediaMenuResult } from "../bridge/NativePage";
import { isRejectable } from "../bridge/Rejectable";
import { mediaMenuProvider, useBridgeResultConsumer } from "./useBridgeResult";
import { useHopState } from "./useHopState";

import { StateId } from "./StateId";
import { useBackend } from "../service/APIService";

export function useSingleMediaEdit(
  pendingId: StateId,
  remoteMedia: Media | undefined,
  scene: MediaScene,
  mediaMenuConfig?: MediaMenuConfig,
) {
  const backend = useBackend();
  const [pending, setPending] = useHopState<Media | LocalMedia | undefined>(
    pendingId,
    undefined,
  );

  const showMenu = useBridgeResultConsumer<MediaMenuResult>(
    mediaMenuProvider(
      mediaMenuConfig || {
        mimeTypes: RegularImage,
        maxCount: 1,
        enableCapture: false,
      },
    ),
    (r) => {
      if (r && !isRejectable(r)) {
        switch (r.type) {
          case "picked":
            const media = r.mediaList[0]!!;
            setPending(media);
            break;
          case "deleted":
            setPending(undefined);
            break;
        }
      }
      return true;
    },
    [setPending],
  );

  const ensureUploaded = async (): Promise<Media | undefined> => {
    if (pending === undefined) {
      return remoteMedia;
    } else if (isLocalMedia(pending)) {
      const localMedia = pending as LocalMedia;
      const remote = await backend.sendLocalMedia(localMedia, scene, (u, t) => {
        console.log(`image picker upload: ${u}/${t}`);
      });
      setPending(remote);
      return remote;
    } else {
      return pending as Media;
    }
  };

  return {
    display: pending || remoteMedia, //用于ui 显示，如果本地没有图片，会显示远程的图片
    hasChanged: !!pending,
    ensureUploaded,
    showMenu,
  };
}

export const RegularImage = ["image/jpeg", "image/png", "image/webp"];
