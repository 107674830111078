import { useI18n } from "../../../hooks/useI18n";
import { useBackend } from "../../../service/APIService";
import { useNativePage } from "../../../hooks/useBridge";
import { useHopper } from "../../../hooks/useHopper";
import { AuthType } from "../../../proto/Auth";
import { useLoadState } from "../../../hooks/LoadState";
import { useState } from "react";
import { Page } from "../../../components/Page";
import { NavEnd, NavItem, NavMiddle } from "../../../components/NavBar";
import { AuthTitleLabel } from "../../auth/InvitationCodePage";
import { VSpace } from "../../../components/VStack";
import { RegularInputGroup } from "../../../components/Input";
import { ButtonColor, RegularButton } from "../../../components/Buttons";
import { Spin } from "../../../components/Spin";
import styled from "styled-components";
import {
  useAuthSessionService,
  useMyUid,
} from "../../../service/AuthSessionService";
import { useErrorHandler } from "../../../components/handleError";

export function DeleteAccountConfirmPage() {
  const i18n = useI18n();
  const backend = useBackend();
  const authSessionService = useAuthSessionService();
  const nativePage = useNativePage();
  const hopper = useHopper();
  const myUid = useMyUid();
  const handleError = useErrorHandler();

  const loadTask = useLoadState();

  function getAuthTypeByNumber(value: number): AuthType | undefined {
    if (Object.values(AuthType).includes(value)) {
      return value as AuthType;
    }
    return undefined;
  }

  const [inputValue, setInputValue] = useState("");

  async function deleteAccount() {
    const result = await loadTask.run(async () => {
      const authBody = {
        password: inputValue,
      };
      await backend.markDeleteAccount(authBody).run();
    });
    if (!result.success) {
      handleError(result.error);
    } else {
      await backend.logOut().run();
      await authSessionService.remove(myUid);
    }
  }

  return (
    <Page pageData={undefined}>
      <NavMiddle>{i18n.auth_delete_account()}</NavMiddle>
      <NavEnd>
        {NavItem.text(i18n.help(), () => hopper.push("feedback"))}
      </NavEnd>
      <AuthTitleLabel>{i18n.confirm_your_password()}</AuthTitleLabel>
      <VSpace height={20} />
      <RegularInputGroup
        value={inputValue}
        updateValue={setInputValue}
        placeholder={i18n.password()}
        type="password"
      />
      <TipsLabel>
        {i18n.delete_cleanup_confirm_password_for_security()}
      </TipsLabel>
      <RegularButton
        disabled={!inputValue}
        style={{ height: "54px", margin: "40px 50px 0" }}
        onClick={() => deleteAccount()}
        $baseColor={ButtonColor.destructive}
      >
        <Spin state={loadTask.state}>{i18n.delete()}</Spin>
      </RegularButton>
    </Page>
  );
}

const TipsLabel = styled.div`
  color: var(--color-text20);
  font-size: 12px;
  font-weight: 300;
  margin-top: 6px;
`;
