import { Page } from "../../../components/Page";
import { useBackend } from "../../../service/APIService";
import { useSWRArray } from "../../../hooks/swr/useSWRArray";
import { useBigIntSearchParam } from "../../../hooks/useTypedParam";
import { StoreOrderType } from "../../../proto/Order";
import { MarketPlaceOrderCell } from "./MarketplaceOrderListPage";
import { NavMiddle } from "../../../components/NavBar";
import { useI18n } from "../../../hooks/useI18n";

export function SalesHistoryPage() {
  const backend = useBackend();
  const i18n = useI18n();
  const storeId = useBigIntSearchParam("storeId");
  const merchId = useBigIntSearchParam("merchId");
  const salesHistorySWR = useSWRArray(
    backend.getMarketOrderList(StoreOrderType.SALES_HISTORY, storeId, merchId),
  );
  return (
    <Page pageData={salesHistorySWR}>
      {salesHistorySWR.content.map((order) => (
        <MarketPlaceOrderCell
          storeOrder={order}
          type={StoreOrderType.SALES_HISTORY}
        />
      ))}
    </Page>
  );
}
