import { Page } from "../../../components/Page";
import styled from "styled-components";
import { GemsIcon } from "./ShopTransferPage";
import { useI18n } from "../../../hooks/useI18n";
import { PageFooter } from "../../../components/PageHeaderFooter";
import { RegularButton } from "../../../components/Buttons";
import React from "react";
import { useHopper } from "../../../hooks/useHopper";
import { NavMiddle } from "../../../components/NavBar";
import { formatMoney } from "../../../proto/Currency";
import { BottomSheet, closeModal, useModal } from "../../../components/Modal";
import { TxSheet, usePerformTx } from "../payment/TransactionDialog";
import { andLog } from "../../../components/handleError";
import { useBackend } from "../../../service/APIService";
import { useWalletSWR } from "../useWallet";
import { useTransactionBody } from "./SendTokenPage";
import { OrderType } from "../order/OrderDetailPage";
import { copyObject } from "../../../utils/copyObject";
import PaymentServiceFee from "../payment/PaymentServiceFee";
import { GasFeeTxType } from "../../../proto/Wallet";
import { RowCenterButton } from "../../../components/CommonStyles";
import PaymentAccount from "../payment/PaymentAccount";
import CurrencyTransactionView from "../payment/CurrencyTransactionView";

function ShopTransferSummaryPage() {
  const i18n = useI18n();
  const hopper = useHopper();
  const backend = useBackend();
  const [wallet, walletSWR] = useWalletSWR();
  const paymentModal = useModal("payment-modal");
  const editingBody = useTransactionBody();
  const currency = copyObject(editingBody.content.currency, {
    amount: editingBody.content.amount,
  });

  const performTx = usePerformTx(
    async () => {
      const resp = await backend
        .transferStoreCurrency(editingBody.content)
        .run();
      return resp.orderId;
    },
    async (orderId: bigint | undefined) => {
      await closeModal(paymentModal);
      await editingBody.clear();
      if (orderId) {
        hopper.dismissLayerAndPush(`wallet/order/${orderId}`, {
          orderType: OrderType.OtherOrder,
          title: i18n.clover_transfer_successful(),
          showDialog: true,
        });
      }
    },
  );

  return (
    <Page pageData={undefined}>
      <NavMiddle>{i18n.web3_v0_summary()}</NavMiddle>
      <SummaryTitle>
        <GemsIcon style={{ width: 36, height: 36 }} />
        {formatMoney("medium", currency)}
      </SummaryTitle>
      <Total>{i18n.jul23_impr2_total()}</Total>
      <TableFrame>
        <TableChild>
          <ChildTitle>{i18n.web3_v0_source()}</ChildTitle>
          <ChildContent>{i18n.web3_v0_store_account()}</ChildContent>
        </TableChild>
        <TableChild>
          <ChildTitle>{i18n.march_impr_2023_destination()}</ChildTitle>
          <ChildContent>{i18n.arthur_wallet_built_in_account()}</ChildContent>
        </TableChild>
        <TableChild>
          <ChildTitle>{i18n.march_impr_2023_transfer_amount()}</ChildTitle>
          <ChildContent>
            <GemsIcon style={{ width: 18, height: 18, margin: "auto 4px" }} />
            {formatMoney("medium", currency)}
          </ChildContent>
        </TableChild>
      </TableFrame>

      <PageFooter obscuredZoneKey={"SendNext"}>
        <RegularButton
          $baseBgColor={"var(--color-bg)"}
          onClick={() => {
            paymentModal.open();
          }}
          style={{
            ...RowCenterButton,
            marginTop: 8,
          }}
        >
          {i18n.transfer()}
        </RegularButton>
      </PageFooter>
      <TxSheet modal={paymentModal} performTx={performTx}>
        <CurrencyTransactionView body={editingBody.content} />
      </TxSheet>
    </Page>
  );
}
const ChildTitle = styled.div`
  font-weight: 300;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
`;

const ChildContent = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  display: flex;
  flex-direction: row;
`;

const TableChild = styled.div`
  display: flex;
  flex-direction: row;
  height: 64px;
  margin: 0 16px;
  align-items: center;
  justify-content: space-between;
  &:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.06);
  }
`;

const TableFrame = styled.div`
  margin-top: 32px;
  width: 100%;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.06);
  background: rgba(255, 255, 255, 0.05);
  display: flex;
  flex-direction: column;
`;

const SummaryTitle = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: row;
  margin: 20px auto 8px;
  color: #ffffff;
  font-size: 28px;
  font-weight: 700;
`;

const Total = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
  margin: 0 auto;
`;

export default ShopTransferSummaryPage;
