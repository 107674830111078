import { StateId } from "../StateId";
import { omit } from "../../utils/pick";
import { SWRAccum, useSWRAccum } from "./SWRAccum";
import { EndPointLike, isEndPointLike } from "../../service/EndPoint";
import { OffsetPagedList, PagedList, Pagination } from "../../proto/PagedList";
import { assert } from "../../utils/asserts";

import { SWRConfig } from "./useSWRRepo";

export interface CursorAndSize {
  pageToken: string | null;
  size: number;
}

type SWRListContent<P extends PagedList<any>> = Partial<
  Omit<P, "pagination" | "list">
> &
  Pick<P, "list"> &
  Pick<Pagination, "total">;

export type SWRListConfig = SWRConfig & {
  maxSize?: number;
  pageSize?: number;
};

export function getListActualArgs(
  arg0: StateId | EndPointLike<PagedList<any>> | undefined,
  arg1?:
    | ((pageParam: CursorAndSize) => Promise<PagedList<any>>)
    | SWRListConfig,
  arg2?: SWRListConfig,
): [
  StateId | undefined,
  ((pageParam: CursorAndSize) => Promise<PagedList<any>>) | undefined,
  SWRListConfig | undefined,
] {
  if (arg0 === undefined) {
    return [undefined, undefined, undefined];
  }

  if (isEndPointLike(arg0)) {
    const contentId = arg0.getId();
    const fetcher = (pageParam: CursorAndSize) => arg0.run(pageParam);
    return [contentId, fetcher, arg1 as any];
  } else {
    assert(arg1 !== undefined, "no fetcher defined");
    return [arg0, arg1 as () => Promise<any>, arg2];
  }
}

export function useSWRList<P extends PagedList<any>>(
  contentId: StateId,
  fetcher: (pageParam: CursorAndSize) => Promise<P>,
  config?: SWRListConfig,
): SWRAccum<SWRListContent<P>>;

export function useSWRList<P extends PagedList<any>>(
  contentId: StateId | undefined,
  fetcher: ((pageParam: CursorAndSize) => Promise<P>) | undefined,
  config?: SWRListConfig,
): SWRAccum<SWRListContent<P>> | undefined;

export function useSWRList<P extends PagedList<any>>(
  endPoint: EndPointLike<P>,
  config?: SWRListConfig,
): SWRAccum<SWRListContent<P>>;

export function useSWRList<P extends PagedList<any>>(
  endPoint: EndPointLike<P> | undefined,
  config?: SWRListConfig,
): SWRAccum<SWRListContent<P>> | undefined;

export function useSWRList(
  arg0: StateId | EndPointLike<PagedList<any>> | undefined,
  arg1?:
    | ((pageParam: CursorAndSize) => Promise<PagedList<any>>)
    | SWRListConfig,
  arg2?: SWRListConfig,
): any {
  const [contentId, fetcher, config] = getListActualArgs(arg0, arg1, arg2);
  const theFetcher = fetcher
    ? async (
        prev: SWRListContent<PagedList<any>> | undefined,
        cursor: string | null,
      ) => {
        const page = await fetcher({
          pageToken: cursor,
          size:
            config?.pageSize !== undefined
              ? config.pageSize
              : process.env.NODE_ENV === "development"
                ? 10
                : 30,
        });

        const accumList = prev ? prev.list.concat(page.list) : page.list;
        let newList: any[];
        let hasMore: boolean;

        if (
          config?.maxSize !== undefined &&
          config.maxSize <= accumList.length
        ) {
          newList = accumList.slice(0, config.maxSize);
          hasMore = false;
        } else {
          newList = accumList;
          hasMore = !!page.pagination.nextPageToken;
        }

        return {
          content: {
            ...omit(page, ["pagination", "list"]),
            list: newList,
            total: page.pagination.total,
          },
          cursor: page.pagination.nextPageToken ?? null,
          hasMore: hasMore,
        };
      }
    : undefined;

  return useSWRAccum(contentId, undefined, null, theFetcher, config);
}

type SWROffsetListContent<P extends OffsetPagedList<any>> = Partial<
  Omit<P, "isEnd" | "list">
> &
  Pick<P, "list">;

export interface OffsetAndSize {
  start: number;
  size: number;
}

export function getOffsetListActualArgs(
  arg0: StateId | EndPointLike<OffsetPagedList<any>> | undefined,
  arg1?:
    | ((pageParam: OffsetAndSize) => Promise<OffsetPagedList<any>>)
    | SWRListConfig,
  arg2?: SWRListConfig,
): [
  StateId | undefined,
  ((pageParam: OffsetAndSize) => Promise<OffsetPagedList<any>>) | undefined,
  SWRListConfig | undefined,
] {
  if (arg0 === undefined) {
    return [undefined, undefined, undefined];
  }

  if (isEndPointLike(arg0)) {
    const contentId = arg0.getId();
    const fetcher = (pageParam: OffsetAndSize) => arg0.run(pageParam);
    return [contentId, fetcher, arg1 as any];
  } else {
    assert(arg1 !== undefined, "no fetcher defined");
    return [arg0, arg1 as () => Promise<any>, arg2];
  }
}

export function useSWROffsetList<P extends OffsetPagedList<any>>(
  contentId: StateId,
  fetcher: (pageParam: OffsetAndSize) => Promise<P>,
  config?: SWRListConfig,
): SWRAccum<SWROffsetListContent<P>>;

export function useSWROffsetList<P extends OffsetPagedList<any>>(
  contentId: StateId | undefined,
  fetcher: ((pageParam: OffsetAndSize) => Promise<P>) | undefined,
  config?: SWRListConfig,
): SWRAccum<SWROffsetListContent<P>> | undefined;

export function useSWROffsetList<P extends OffsetPagedList<any>>(
  endPoint: EndPointLike<P>,
  config?: SWRListConfig,
): SWRAccum<SWROffsetListContent<P>>;

export function useSWROffsetList<P extends OffsetPagedList<any>>(
  endPoint: EndPointLike<P> | undefined,
  config?: SWRListConfig,
): SWRAccum<SWROffsetListContent<P>> | undefined;

export function useSWROffsetList(
  arg0: StateId | EndPointLike<OffsetPagedList<any>> | undefined,
  arg1?:
    | ((pageParam: OffsetAndSize) => Promise<OffsetPagedList<any>>)
    | SWRListConfig,
  arg2?: SWRListConfig,
): any {
  const [contentId, fetcher, config] = getOffsetListActualArgs(
    arg0,
    arg1,
    arg2,
  );
  const theFetcher = fetcher
    ? async (
        prev: SWROffsetListContent<OffsetPagedList<any>> | undefined,
        cursor: number,
      ) => {
        const page = await fetcher({
          start: cursor,
          size:
            config?.pageSize !== undefined
              ? config.pageSize
              : process.env.NODE_ENV === "development"
                ? 10
                : 30,
        });

        const accumList = prev ? prev.list.concat(page.list) : page.list;
        let newList: any[];
        let hasMore: boolean;

        if (
          config?.maxSize !== undefined &&
          config.maxSize <= accumList.length
        ) {
          newList = accumList.slice(0, config.maxSize);
          hasMore = false;
        } else {
          newList = accumList;
          hasMore = !page.isEnd;
        }

        return {
          content: {
            ...omit(page, ["isEnd", "list"]),
            list: newList,
          },
          cursor: cursor + page.list.length,
          hasMore: hasMore,
        };
      }
    : undefined;

  return useSWRAccum(contentId, undefined, 0, theFetcher, config);
}
