import {
  CenterModal,
  ModalController,
  ModalTitle,
} from "../../components/Modal";
import { useI18n } from "../../hooks/useI18n";
import { Image } from "../../components/Image";
import bindCircleTokenImage from "../../res/images/token_create_introduce.png";
import { RegularButton } from "../../components/Buttons";
import styled, { css } from "styled-components";
import React from "react";

export function BindCircleTokenIntroduceModal(props: {
  modal: ModalController;
  bind: () => void;
}) {
  const i18n = useI18n();
  return (
    <CenterModal modal={props.modal} mixin={ModalStyle}>
      <ModalTitle
        mixin={css`
          font-weight: 700;
        `}
      >
        {i18n.clover_bind_circle_token()}
      </ModalTitle>
      <Image
        style={{ margin: "30px auto 20px" }}
        src={bindCircleTokenImage}
        alt={"bindCircleTokenImage"}
        width={173}
        height={131}
      ></Image>
      <DescLabel>{i18n.clover_bind_circle_token_desc()}</DescLabel>
      <RegularButton
        style={{ marginLeft: 25, marginRight: 25 }}
        onClick={() => {
          props.modal.close(() => props.bind());
        }}
      >
        {i18n.clover_bind()}
      </RegularButton>
    </CenterModal>
  );
}

const ModalStyle = css`
  padding: 32px 25px;
`;

const DescLabel = styled.div`
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: var(--color-text00);
`;
