import { Page } from "../../../components/Page";
import { NavEnd, NavItem, NavMiddle } from "../../../components/NavBar";
import { useI18n } from "../../../hooks/useI18n";
import { NavButtonType } from "../../../bridge/NavButtonDesc";
import { FullPageWidthCell } from "../../../components/CommonStyles";
import { HStack, Spring, VStack } from "../../../components/VStack";
import { useHopper } from "../../../hooks/useHopper";
import { useCircleSWR } from "../useCircleSWR";
import { RegularSmallButton } from "../../../components/Buttons";
import { CircleIconView } from "../../alertCenter/AlertDetailCell";
import styled, { css } from "styled-components";
import { SpeedSubtitle } from "../../../components/SpeedTitle";
import { Spacing } from "../../../components/Spacing";
import { AdminTitleCell, UserMemberTitleCell } from "./CircleTeamPage";
import { useSWRArray } from "../../../hooks/swr/useSWRArray";
import { CircleMemberTitle, TitleType } from "../../../proto/CircleMemberTitle";
import { useBackend } from "../../../service/APIService";
import React, { Fragment, PropsWithChildren, useMemo } from "react";
import UserHScrollItem from "../../../components/UserHScrollItem";
import MemberTitle from "../../../components/MemberTitle";
import { useModal } from "../../../components/Modal";
import { BSMenu, BSMenuItem } from "../../../components/BSMenu";
import { LoadStateCell } from "../../../components/LoadStateView";
import { useSWR } from "../../../hooks/swr/useSWR";
import { CommonUserCell } from "../../user/CommonUserCell";
import { useBroadcast } from "../../../hooks/useBroadcast";
import { andLog } from "../../../components/handleError";
import { StackUserIconView } from "../../../components/views/StackUserIconView";
import { SingleLineUnspecifiedWidth } from "../../../components/CommonViews";
import { Circle } from "../../../proto/Circle";
import { useMyUid } from "../../../service/AuthSessionService";
import icArrow from "../../../res/images/ic_arrow_white60.svg";

export function CircleMembersPage() {
  const [circleId, circleSWR] = useCircleSWR();
  const circle = circleSWR.content;
  const hopper = useHopper();
  const myUid = useMyUid();

  const i18n = useI18n();
  const moreMenu = useModal("moreMenu");

  const isAdmin = useMemo(() => {
    return (
      circleSWR.content?.adminIdList.includes(myUid) ||
      circleSWR.content?.coAdminIdList.includes(myUid)
    );
  }, [circleSWR.content]);
  useBroadcast(
    "ON_REMOVE_FROM_CIRCLE",
    () => {
      circleSWR.load().catch(andLog);
    },
    [circleSWR],
  );
  return (
    <Page pageData={circleSWR}>
      <NavMiddle>{i18n.circle_all_members()}</NavMiddle>
      {isAdmin && (
        <NavEnd>
          {NavItem.image(NavButtonType.More, () => {
            moreMenu.open();
          })}
        </NavEnd>
      )}
      <CircleHeader circleId={circleId} circle={circle} />
      <Spacing height={20} />
      <CircleTeamStructure circleId={circleId} />
      <Spacing height={32} />
      <CircleActiveMember circleId={circleId} />
      <Spacing height={32} />
      <CircleLevelGroup circleId={circleId} />
      <Spacing height={16} />
      <OtherMembers circleId={circleId} circle={circle} />
      <BSMenu modal={moreMenu}>
        <BSMenuItem
          title={i18n.member_title_manage_circle_members()}
          onClick={() => hopper.push(`circle/${circleId}/manage-members`)}
        />
        <BSMenuItem
          title={i18n.member_title_manage_circle_titles()}
          onClick={() => hopper.push(`circle/${circleId}/member-titles`)}
        />
      </BSMenu>
    </Page>
  );
}

function CircleHeader(props: { circleId: bigint; circle?: Circle }) {
  const i18n = useI18n();
  const hopper = useHopper();
  const circleId = props.circleId;
  return (
    <HStack
      style={{
        borderRadius: "4px",
        gap: "4px",
        backgroundColor: "#00000066",
        padding: 4,
      }}
    >
      <CircleIconView circle={props.circle} iconSize={24} />
      <CircleName>{props.circle?.name}</CircleName>
      <RegularSmallButton
        style={{
          fontWeight: 500,
          flexShrink: 0,
        }}
        onClick={() => {
          hopper.push(`circle/${circleId}/invite`);
        }}
      >
        {i18n.invite()}
      </RegularSmallButton>
    </HStack>
  );
}

function OtherMembers(props: { circleId: bigint; circle?: Circle }) {
  const backend = useBackend();
  const i18n = useI18n();
  const hopper = useHopper();
  const circleMembers = useSWRArray(
    backend.getCircleMembers(props.circleId, 0),
  );

  const levelGroups = useSWR(
    backend.getMemberGroupPreview(props.circleId, "membershipLevel"),
  );

  const content = useMemo(() => {
    if (props.circle && props.circle.tokenProject) {
      return levelGroups.content?.list
        ?.filter((e) => e.membershipLevel === 0)
        ?.flatMap((item) => item.memberList);
    } else {
      return circleMembers.content;
    }
  }, [levelGroups.content, props.circle, circleMembers.content]);
  return (
    <>
      {content !== undefined && content.length > 0 ? (
        <>
          <SpeedTitleWithArrow
            onClick={() =>
              hopper.push(`circle/${props.circleId}/level-members`, {
                level: 0,
              })
            }
          >
            {i18n.plural(content.length).clover_other_members(content.length)}
          </SpeedTitleWithArrow>
          {content.map((e) => {
            return (
              <CommonUserCell
                user={e}
                showMembershipLevel={true}
                key={e.uid}
                onUpdate={levelGroups.load}
                onClick={() => {
                  hopper.modal(`circle/${props.circleId}/user-card/${e.uid}`);
                }}
              />
            );
          })}
        </>
      ) : undefined}
      {content === undefined && (
        <LoadStateCell
          key={`load-other-members-${props.circleId}`}
          loadState={levelGroups.loadState}
          onClickRetry={levelGroups}
        />
      )}
    </>
  );
}

function CircleLevelGroup(props: { circleId: bigint }) {
  const backend = useBackend();
  const circleId = props.circleId;
  const i18n = useI18n();
  const hopper = useHopper();
  const levelGroups = useSWR(
    backend.getMemberGroupPreview(circleId, "membershipLevel"),
  );
  const content = levelGroups.content;
  return (
    <>
      {content &&
        content.list
          .filter((e) => e.membershipLevel > 0 && e.membershipLevel < 999)
          .reverse()
          .map((e, i) => (
            <Fragment key={`fragment-${e.membershipLevel}`}>
              {i === 0 ? undefined : (
                <Spacing height={16} key={`spacing-${e.membershipLevel}`} />
              )}
              <SpeedTitleWithArrow
                key={`title-${e.membershipLevel}`}
                onClick={() =>
                  hopper.push(`circle/${circleId}/level-members`, {
                    level: e.membershipLevel,
                  })
                }
              >
                {i18n
                  .plural(e.memberCount)
                  .clover_level_members(e.membershipLevel, e.memberCount)}
              </SpeedTitleWithArrow>
              {e.memberList.map((member) => (
                <CommonUserCell
                  key={`${e.membershipLevel}-${member.uid}`}
                  user={member}
                  showMembershipLevel={true}
                  onUpdate={levelGroups.load}
                  onClick={() => {
                    hopper.modal(
                      `circle/${props.circleId}/user-card/${member.uid}`,
                    );
                  }}
                />
              ))}
            </Fragment>
          ))}
      {content === undefined && (
        <LoadStateCell
          key={`load-level-group-${props.circleId}`}
          loadState={levelGroups.loadState}
          onClickRetry={levelGroups}
        />
      )}
    </>
  );
}

function CircleTeamStructure(props: { circleId: bigint }) {
  const i18n = useI18n();
  const circleId = props.circleId;
  const hopper = useHopper();
  const backend = useBackend();
  const titles = useSWRArray(backend.getVisibleTitles(circleId));
  useBroadcast(
    "ON_CIRCLE_TITLE_MEMBER_UPDATE",
    () => {
      titles.load().catch(andLog);
    },
    [titles],
  );
  let content = titles.content;
  const groupedTitles = useMemo(() => {
    if (content === undefined) return undefined;
    else {
      let map = new Map<TitleType, CircleMemberTitle>();
      for (let i = 0; i < content.length; i++) {
        if (content[i].type == TitleType.admin) {
          map.set(TitleType.admin, content[i]);
          if (map.get(TitleType.coAdmin)) break;
        } else if (content[i].type == TitleType.coAdmin) {
          map.set(TitleType.coAdmin, content[i]);
          if (map.get(TitleType.admin)) break;
        }
      }
      return map;
    }
  }, [content]);
  const noTeamTitles = useMemo(() => {
    if (content === undefined) return undefined;
    else {
      let result = [];
      for (let i = 0; i < content.length; i++) {
        if (
          content[i].type != TitleType.admin &&
          content[i].type != TitleType.coAdmin &&
          content[i].visible
        ) {
          result.push(content[i]);
          if (result.length >= 2) break;
        }
      }
      return result;
    }
  }, [content]);
  const adminTitle = groupedTitles?.get(TitleType.admin);
  const coAdminTitle = groupedTitles?.get(TitleType.coAdmin);
  const gotoTeamPage = () => {
    hopper.push(`circle/${circleId}/team`);
  };
  return (
    <VStack onClick={() => gotoTeamPage()}>
      <SpeedTitleWithArrow>{i18n.clover_team_structure()}</SpeedTitleWithArrow>
      <Spacing height={12} />
      <TeamStructurePanel>
        {adminTitle === undefined ? undefined : (
          <AdminTitleCell title={adminTitle} treeMode={false} />
        )}
        {coAdminTitle === undefined ? undefined : (
          <UserMemberTitleCell title={coAdminTitle} treeMode={false} />
        )}
        {noTeamTitles && noTeamTitles[0] && (
          <OtherMemberTitleCell
            leftTitle={noTeamTitles[0]}
            rightTitle={noTeamTitles[1]}
          />
        )}
      </TeamStructurePanel>
    </VStack>
  );
}

function OtherMemberTitleCell(props: {
  leftTitle: CircleMemberTitle;
  rightTitle?: CircleMemberTitle;
}) {
  return (
    <div
      style={{
        display: "grid",
        gap: "48px",
        gridTemplateColumns: `repeat(2, minmax(0, 1fr))`,
      }}
    >
      <OtherMemberTitleItem
        key={"left"}
        title={props.leftTitle}
        showDivider={false}
      />
      {props.rightTitle === undefined ? (
        <div
          style={{
            flexGrow: 1,
            flexShrink: 1,
          }}
        ></div>
      ) : (
        <OtherMemberTitleItem
          key={"right"}
          title={props.rightTitle}
          showDivider={true}
        />
      )}
    </div>
  );
}

function OtherMemberTitleItem(props: {
  title: CircleMemberTitle;
  showDivider: boolean;
}) {
  const backend = useBackend();
  const circleId = props.title.circleId;
  const titleId = props.title.titleId;
  const users = useSWRArray(backend.getCircleTitleUsers(circleId, titleId));
  const content = users.content;
  return (
    <VStack
      mixin={
        props.showDivider
          ? css`
              gap: 9px;

              &::before {
                content: " ";
                width: 1px;
                height: 39px;
                background-color: #4c4c54;
                position: absolute;
                left: -24px;
                top: 9px;
              }
            `
          : css`
              gap: 9px;
            `
      }
    >
      <MemberTitle
        style={{
          alignSelf: "start",
        }}
        memberTitle={props.title}
      />

      {content && (
        <StackUserIconView users={content} iconSize={30} offset={-7} />
      )}
      {content === undefined && (
        <LoadStateCell
          loadState={users.loadState}
          onClickRetry={users}
          key={`other-member-title-${props.title.titleId}`}
        />
      )}
    </VStack>
  );
}

function CircleActiveMember(props: { circleId: bigint }) {
  const backend = useBackend();
  const i18n = useI18n();
  const activeMembersSWR = useSWRArray(
    backend.getCircleActiveMembers(props.circleId),
  );
  const hopper = useHopper();
  const content = activeMembersSWR.content;
  return (
    <>
      <SpeedTitleWithArrow
        onClick={() => {
          hopper.push(`circle/${props.circleId}/active-members`);
        }}
      >
        {i18n.active_members()}
      </SpeedTitleWithArrow>
      <HStack
        style={{
          marginTop: "12px",
          overflowX: "scroll",
          scrollbarWidth: "none" as "none",
          msOverflowStyle: "none" as "none",
          paddingInlineStart: 12,
          gap: 10,
          ...FullPageWidthCell,
        }}
      >
        {content &&
          activeMembersSWR.content.map((e) => {
            return (
              <UserHScrollItem
                onGoProfile={() => {
                  hopper.modal(`circle/${props.circleId}/user-card/${e.uid}`);
                }}
                user={e}
                showMembershipLevel={true}
                key={e.uid}
              />
            );
          })}
      </HStack>
    </>
  );
}

const CircleName = styled.div`
  ${SingleLineUnspecifiedWidth};
  font-weight: 500;
  flex-grow: 1;
  flex-shrink: 1;
`;

const TeamStructurePanel = styled.div`
  background: rgba(255, 255, 255, 0.05);
  border-color: rgba(255, 255, 255, 0.06);
  border-radius: 6px;
  border-width: 1px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 32px;
`;

const ArrowImage = styled.img`
  width: 20px;
  height: 20px;
`;

export function SpeedTitleWithArrow(
  props: { onClick?: () => void } & PropsWithChildren,
) {
  return (
    <HStack style={{ width: "100%" }} onClick={props.onClick}>
      <SpeedSubtitle>{props.children}</SpeedSubtitle>
      <Spring />
      <ArrowImage src={icArrow} />
    </HStack>
  );
}
