import { Page } from "../../../components/Page";
import { useBigIntParam } from "../../../hooks/useTypedParam";
import { useBackend } from "../../../service/APIService";
import { useI18n } from "../../../hooks/useI18n";
import { useHopper } from "../../../hooks/useHopper";
import {
  NavEnd,
  NavItem,
  NavMiddle,
  NavStart,
} from "../../../components/NavBar";
import { Spacing } from "../../../components/Spacing";
import { PlaceholderButton } from "../../../components/Buttons";
import { Image } from "../../../components/Image";
import ic_add from "../../../res/images/ic_add.svg";
import iconSettings from "../../../res/images/ic_abar_manage.svg";
import styled, { css } from "styled-components";
import { useGlobalSpinner } from "../../../utils/globalSpinner";
import {
  DragLifecycle,
  kRecorderCellId,
  moveElement,
  useReorder,
} from "../../../hooks/useReorder";
import { Chat } from "../../../proto/Chat";
import colorSetAlpha from "color-alpha";
import { useSWRList } from "../../../hooks/swr/useSWRList";
import delete_icon from "../../../res/images/delete_icon.svg";
import reorder_handle from "../../../res/images/reorder_handle.svg";
import {
  cell_on_pressed,
  full_page_width_cell,
} from "../../../components/CommonStyles";
import { urlAppendQuery } from "../../../utils/UrlUtil";
import { detect } from "detect-browser";
import { OverFlowTextStyle } from "../../../components/Text";
import { useBroadcast } from "../../../hooks/useBroadcast";
import { andLog } from "../../../components/handleError";
import { useMemo, useState } from "react";
import { useHopState } from "../../../hooks/useHopState";
import { NavButtonType } from "../../../bridge/NavButtonDesc";
import { useNativePage } from "../../../hooks/useBridge";
import { useMyUid } from "../../../service/AuthSessionService";
import { useCircleSWR } from "../useCircleSWR";
import { isCircleAdminOrCoAdmin } from "../../../proto/Circle";
import { CellDivider } from "../../../components/ContentGroup";

function CircleRoomSettingsPage() {
  const circleId = useBigIntParam("circleId");
  const backend = useBackend();
  const i18n = useI18n();
  const [_, circle] = useCircleSWR();
  const hopper = useHopper();
  const myUid = useMyUid();

  const chatList = useSWRList(backend.getCircleRooms(circleId), {
    reloadPolicy: "alwaysRefetch",
  });
  const isAdminOrCoAdmin = useMemo(
    () =>
      circle.content ? isCircleAdminOrCoAdmin(circle.content, myUid) : false,
    [circle.content, myUid],
  );

  const [orderVersion, setOrderVersion] = useState(0);
  const nativePage = useNativePage();

  const [pendingLocalIds, setPendingLocalIds] =
    useHopState<bigint[]>("pendingLocalIds");
  const [isEditing, setIsEditing] = useHopState<boolean>("isEditing", false);
  const list = chatList.content?.list;

  const chatMap = useMemo(() => {
    let map = new Map<bigint, Chat>();
    if (list !== undefined) {
      list.forEach((e) => {
        map.set(e.threadId, e);
      });
    }
    return map;
  }, [list]);
  const remoteThreadIds = useMemo(
    () => list?.map((e) => e.threadId) || [],
    [list],
  );
  const displayThreadIds = useMemo(() => {
    if (pendingLocalIds === undefined) {
      return remoteThreadIds;
    } else {
      return pendingLocalIds;
    }
  }, [pendingLocalIds, remoteThreadIds]);
  const dragEvents = useReorder(
    (fromIndex, toIndex) => {
      setOrderVersion((prev) => prev + 1);
      setPendingLocalIds(moveElement(displayThreadIds, fromIndex, toIndex));
    },
    [displayThreadIds],
  );

  let globalSpinner = useGlobalSpinner();

  async function save() {
    if (pendingLocalIds !== undefined) {
      await globalSpinner(async () => {
        await backend.reorderCircleRooms(circleId, pendingLocalIds).run();
      });
      setIsEditing(false);
    } else {
      setIsEditing(false);
    }
  }

  useBroadcast(
    "ON_CHAT_CREATED",
    () => {
      chatList.load("ON_CHAT_CREATED").catch(andLog);
    },
    [chatList],
  );

  function gotoChatCompose() {
    hopper.layer(`chat-compose/${circleId}`);
  }

  async function deleteChat(threadId: bigint) {
    if (
      await nativePage.alertYesOrCancel(
        i18n.party_on_are_you_sure_you_want_to_delete_this_party(),
        i18n.yes(),
        i18n.cancel(),
      )
    ) {
      await globalSpinner(async () => {
        await backend.deleteCircleRoom(threadId).run();
        await chatList.load();
        if (pendingLocalIds !== undefined) {
          let i = pendingLocalIds.indexOf(threadId);
          pendingLocalIds.splice(i, 1);
        }
      });
    }
  }

  function cancelEditing() {
    setIsEditing(false);
    setPendingLocalIds(undefined);
  }

  function startEditing() {
    setIsEditing(true);
  }

  return (
    <Page
      pageData={isAdminOrCoAdmin ? chatList : undefined}
      pullToRefreshDisabled={isEditing}
      alwaysShowsContent={isAdminOrCoAdmin}
    >
      <NavStart>
        {isEditing
          ? NavItem.image(NavButtonType.Cross, () => cancelEditing())
          : undefined}
      </NavStart>
      <NavMiddle>{i18n.clover_circle_rooms()}</NavMiddle>
      <NavEnd>
        {isEditing
          ? NavItem.image(NavButtonType.Check, () => save())
          : NavItem.text(i18n.edit(), () => startEditing())}
      </NavEnd>

      {displayThreadIds.map((item) => {
        let chat = chatMap.get(item);
        if (chat != null)
          return (
            <>
              <CircleRoomPreviewCell
                isEditing={isEditing}
                chat={chat}
                dragEvents={dragEvents}
                key={`${orderVersion}.${item}`}
                onDelete={deleteChat}
              />
              <CellDivider />
            </>
          );
        else return undefined;
      })}

      <Spacing height={30} />
      {!isEditing && (
        <PlaceholderButton
          mixin={css`
            background-color: ${() => colorSetAlpha("white", 0.1)};
          `}
          onClick={() => gotoChatCompose()}
        >
          <Image src={ic_add} alt={"reveal"} />
          {i18n.clover_add_new_room()}
        </PlaceholderButton>
      )}
      <Spacing height={30} />
    </Page>
  );
}

interface CircleRoomProps {
  chat: Chat;
  dragEvents: DragLifecycle;
  isEditing: boolean;
  onDelete: (threadId: bigint) => Promise<void>;
}

function CircleRoomPreviewCell(props: CircleRoomProps) {
  const hopper = useHopper();

  function gotoChatSettings() {
    if (props.isEditing) return;
    hopper.layer(`chat/${props.chat.threadId}/settings`);
  }

  return (
    <CircleRoomLayout
      id={props.isEditing ? kRecorderCellId : undefined}
      onClick={() => {
        gotoChatSettings();
      }}
    >
      {props.isEditing && (
        <Image
          src={delete_icon}
          alt="delete"
          width={30}
          height={30}
          onClick={() => props.onDelete(props.chat.threadId)}
        />
      )}

      <Image
        style={{
          borderRadius: 4,
          border: "0.5px solid rgba(255, 255, 255, 0.4)",
          flexShrink: 0,
        }}
        src={[props.chat.icon, "smallest"]}
        width={44}
        height={33}
      />
      <CircleRoomName>{props.chat.title}</CircleRoomName>

      <div
        style={{ touchAction: "none" }}
        onTouchStartCapture={
          props.isEditing ? props.dragEvents.start : undefined
        }
        onTouchMoveCapture={props.isEditing ? props.dragEvents.move : undefined}
        onTouchEndCapture={props.isEditing ? props.dragEvents.end : undefined}
        onTouchCancelCapture={
          props.isEditing ? props.dragEvents.cancel : undefined
        }
      >
        <Image
          width={36}
          height={36}
          src={props.isEditing ? reorder_handle : iconSettings}
        ></Image>
      </div>
    </CircleRoomLayout>
  );
}

const CircleRoomLayout = styled.div`
  min-height: 80px;
  display: flex;
  position: relative;
  align-items: center;
  ${full_page_width_cell};
  ${cell_on_pressed};
  flex-direction: row;
  justify-content: space-between;
  gap: 12px;
`;

const CircleRoomName = styled.div`
  font-size: 15px;
  font-weight: 400;
  color: var(--color-text00);
  flex-grow: 1;
  flex-shrink: 1;
  ${OverFlowTextStyle};
  -webkit-line-clamp: 1;
`;

export default CircleRoomSettingsPage;
