import { Page } from "../../components/Page";
import { DemoCell } from "./DemoCell";
import { useLocalRepo } from "../../hooks/swr/useLocalRepo";
import { andLog } from "../../components/handleError";
import { useHopper } from "../../hooks/useHopper";
import { formatDate } from "../../utils/DateTimeUtils";
import { NavMiddle } from "../../components/NavBar";
import { VSpace } from "../../components/VStack";
import {
  useBigIntSearchParam,
  useEnumSearchParam,
} from "../../hooks/useTypedParam";

type ColorPickerResult = {
  changeCount: number;
  color: string;
};

enum ColorPickerPurpose {
  Chat = 1,
  Circle = 2,
}

function useColorPickerResult(objectId: bigint, purpose: ColorPickerPurpose) {
  return useLocalRepo<ColorPickerResult>(["ColorPicker", objectId, purpose], {
    changeCount: 0,
    color: "red",
  });
}

function getRandomHexColor(): string {
  const randomInt = Math.floor(Math.random() * 16777215); // Max value for 24-bit color
  return `#${randomInt.toString(16).padStart(6, "0")}`; // Ensure it's always 6 digits
}

export function LocalRepoDemoEditPage() {
  const objectId = useBigIntSearchParam("objectId", BigInt(0));
  const purpose = useEnumSearchParam<ColorPickerPurpose>(
    "purpose",
    ColorPickerPurpose.Chat,
  );

  const colorPicked = useColorPickerResult(objectId, purpose);

  return (
    <Page pageData={undefined}>
      <DemoCell>{`id: ${colorPicked.contentId}`}</DemoCell>
      <VSpace
        height={100}
        style={{ backgroundColor: colorPicked.content.color }}
      ></VSpace>
      <DemoCell>{`color: ${colorPicked.content.color}  (${colorPicked.content.changeCount})`}</DemoCell>
      <DemoCell>{`loadState: ${colorPicked.loadState?.kind}`}</DemoCell>
      <DemoCell>{`updatedAt: ${formatDate(colorPicked.meta.updatedAt)}`}</DemoCell>
      <DemoCell
        onClick={() => {
          colorPicked
            .fill((prev) => {
              return {
                ...prev,
                changeCount: prev.changeCount + 1,
                color: getRandomHexColor(),
              };
            })
            .catch(andLog);
        }}
      >
        Change Color
      </DemoCell>

      <NavMiddle>Edit Color</NavMiddle>
    </Page>
  );
}

export function LocalRepoDemoPage() {
  const circleId = BigInt(1234);
  const colorPicked = useColorPickerResult(circleId, ColorPickerPurpose.Circle);
  const hopper = useHopper();
  return (
    <Page pageData={undefined}>
      <DemoCell>{`id: ${colorPicked.contentId}`}</DemoCell>
      <VSpace
        height={100}
        style={{ backgroundColor: colorPicked.content.color }}
      ></VSpace>
      <DemoCell>{`color: ${colorPicked.content.color}  (${colorPicked.content.changeCount})`}</DemoCell>
      <DemoCell>{`loadState: ${colorPicked.loadState?.kind}`}</DemoCell>
      <DemoCell>{`updatedAt: ${formatDate(colorPicked.meta.updatedAt)}`}</DemoCell>
      <DemoCell
        onClick={() =>
          hopper.push("local-repo-demo-edit", {
            objectId: circleId,
            purpose: ColorPickerPurpose.Circle,
          })
        }
      >
        Edit
      </DemoCell>

      <NavMiddle>Preview Color</NavMiddle>
    </Page>
  );
}
