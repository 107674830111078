import { string, z, ZodBigInt } from "zod";
import { zBigInt, zEnum, zStatic } from "../utils/zodUtils";

export const CircleAttendData = z.object({
  statDateUnixList: zBigInt.array().optional(),
  dailyNewMembersCountList: zBigInt.array().optional(),
  dailyActiveMembersCountList: zBigInt.array().optional(),
  dailyGuestVisitorsCountList: zBigInt.array().optional(),
  totalMembersCountList: zBigInt.array().optional(),
  dailyNewBlogCountList: zBigInt.array().optional(),
  totalBlogCountList: zBigInt.array().optional(),
  dailyNewChatsCountList: zBigInt.array().optional(),
  totalChatsCountList: zBigInt.array().optional(),
  totalPaidMemberCountList: zBigInt.array().optional(),
});
export type CircleAttendData = zStatic<typeof CircleAttendData>;
