import { SearchProps } from "./SearchPage";
import { useBackend } from "../../service/APIService";
import { useI18n } from "../../hooks/useI18n";
import { useSWRList } from "../../hooks/swr/useSWRList";
import * as React from "react";
import { useEffect } from "react";
import { andLog } from "../../components/handleError";
import { Page } from "../../components/Page";
import TabCellSeparator from "../../components/TabCellSeparator";
import { PostCell } from "../post/PostCell";
import {
  FullPageWidthCellNoPadding,
  hPaddingWithPageInset,
} from "../../components/CommonStyles";
import styled from "styled-components";
import { useMemoryRepoMap } from "../../hooks/swr/useLocalRepo";
import { zBigInt } from "../../utils/zodUtils";
import { z } from "zod";
import { Reaction } from "../../proto/Reaction";
import { useReactionRecord } from "../../hooks/useReactionRecord";

export function SearchPostPage({ word, circleId }: SearchProps) {
  const backend = useBackend();
  const i18n = useI18n();
  const reactionRepo = useReactionRecord();

  const postsSWR = useSWRList(["SearchPostPage", word], () =>
    backend
      .searchBlogs(word, circleId)
      .intercept((it) => {
        it.list.forEach((item) => {
          if (item.blog) {
            reactionRepo
              .setValue(item.blog.blogId, item.blog.reactionCountList)
              .catch(andLog);
          }
        });
      })
      .run(),
  );

  useEffect(() => {
    postsSWR.load().catch(andLog);
  }, [word]);

  return (
    <Page pageData={postsSWR} scrollPaddingDisabled={true}>
      {postsSWR.content && (
        <TabCellSeparator title={i18n.matched_by_keywords()}></TabCellSeparator>
      )}
      {postsSWR.content &&
        postsSWR.content.list.map((item) =>
          item.blog ? (
            <PostCell
              hPadding={hPaddingWithPageInset}
              post={item.blog}
              key={item.blog?.blogId}
            />
          ) : null,
        )}
    </Page>
  );
}

const TabCellContainer = styled.div`
  ${FullPageWidthCellNoPadding}
`;
