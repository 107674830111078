import { Page } from "../../../components/Page";
import {
  useBigIntParam,
  useStringSearchParam,
} from "../../../hooks/useTypedParam";
import { useTransactionBody } from "../../wallet/send/SendTokenPage";
import { useI18n } from "../../../hooks/useI18n";
import { useHopper } from "../../../hooks/useHopper";
import { useBackend } from "../../../service/APIService";
import { NavMiddle } from "../../../components/NavBar";
import React from "react";
import styled from "styled-components";
import icSend from "../../../res/images/ic_transfer_info.svg";
import iconSOL from "../../../res/images/icon_sol.svg";
import { Image } from "../../../components/Image";
import { HStack, VSpace, VStack } from "../../../components/VStack";
import PaymentServiceFee from "../../wallet/payment/PaymentServiceFee";
import { GasFeeTxType } from "../../../proto/Wallet";

import PaymentFooter from "../../wallet/payment/PaymentFooter";
import { BottomSheet, closeModal, useModal } from "../../../components/Modal";
import { TxSheet, usePerformTx } from "../../wallet/payment/TransactionDialog";
import { andLog } from "../../../components/handleError";
import { OrderType } from "../../wallet/order/OrderDetailPage";
import { formatMoney, getCurrencyIcon } from "../../../proto/Currency";
import { copyObject } from "../../../utils/copyObject";
import PaymentAccount from "../../wallet/payment/PaymentAccount";
import CurrencyTransactionView from "../../wallet/payment/CurrencyTransactionView";

function AdminTransferSummaryPage() {
  const i18n = useI18n();
  const circleId = useBigIntParam("circleId");
  const hopper = useHopper();
  const paymentModal = useModal("transfer-modal");
  const backend = useBackend();
  const editingBody = useTransactionBody({}, "admin-earning-transfer");
  const curCurrency = copyObject(editingBody.content.currency, {
    amount: editingBody.content.amount,
  });

  const performTx = usePerformTx(
    async () => {
      const resp = await backend
        .transferAdminCommission(circleId, editingBody.content)
        .run();
      return resp.orderId;
    },
    async (orderId: bigint | undefined) => {
      await closeModal(paymentModal);
      await editingBody.clear();
      if (orderId) {
        hopper.dismissLayerAndPush(`wallet/order/${orderId}`, {
          orderType: OrderType.OtherOrder,
          showDialog: true,
          title: i18n.clover_transfer_successful(),
        });
      }
    },
  );

  return (
    <Page pageData={undefined}>
      <NavMiddle>{i18n.web3_v0_summary()}</NavMiddle>
      <TransferInfoFrame>
        <TransferTitle>
          <Image src={icSend} width={20} height={20} />
          {i18n.clover_transfer_info()}
        </TransferTitle>
        <HStack style={{ margin: 12, gap: 12 }}>
          <Image
            src={getCurrencyIcon(editingBody.content.currency, {
              width: 74,
              height: 74,
            })}
            style={{ width: 74, height: 74, borderRadius: 4 }}
          />
          <VStack style={{ gap: 8 }}>
            <NFTName>{editingBody.content.currency?.symbol}</NFTName>
            <SolanaChain>
              <Image src={iconSOL} width={16} height={16} />
              {"Solana"}
            </SolanaChain>
          </VStack>
        </HStack>
        <HStack
          style={{ justifyContent: "space-between", margin: "0 12px 12px" }}
        >
          <Quantity>{i18n.web3_v0_quantity()}</Quantity>
          <QuantityAmount>{formatMoney("medium", curCurrency)}</QuantityAmount>
        </HStack>
      </TransferInfoFrame>
      <VSpace height={20} />
      <PaymentServiceFee
        editingBody={editingBody}
        gasFeeTxType={GasFeeTxType.Transfer}
      />
      <VSpace height={20} />
      <PaymentAccount />
      <PaymentFooter editingBody={editingBody} paymentModal={paymentModal} />
      <TxSheet modal={paymentModal} performTx={performTx}>
        <CurrencyTransactionView body={editingBody.content} />
      </TxSheet>
    </Page>
  );
}

const QuantityAmount = styled.div`
  color: white;
  font-weight: 500;
  font-size: 18px;
`;

const Quantity = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
`;

const SolanaChain = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 500;
  font-size: 11px;
`;

const NFTName = styled.div`
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
`;

const TransferTitle = styled.div`
  display: flex;
  flex-direction: row;
  padding: 12px 0;
  margin: 0 12px;
  gap: 6px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.06);
`;

const TransferInfoFrame = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.06);
  margin-top: 20px;
  color: rgba(255, 255, 255, 0.6);
  font-weight: 500;
  font-size: 12px;
`;

export default AdminTransferSummaryPage;
