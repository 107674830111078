import { StateId } from "../StateId";
import { SWRConfig, useSWRRepo } from "./useSWRRepo";
import { andLog } from "../../components/handleError";
import { LoadState } from "../LoadState";
import { omit } from "../../utils/pick";
import { SWRFetchResult, SWRRepoData } from "./SWRRepo";

export type SWRAccumMeta<T, C> = Omit<SWRRepoData<T, C>, "content">;

export type SWRAccum<T, C = string | null> = {
  readonly contentId: StateId;
  readonly content: T;
  readonly meta: SWRAccumMeta<T, C>;
  readonly loadState: LoadState | undefined;
  readonly load: (reason?: string) => Promise<void>;
  readonly loadMore: () => void;
};

export function useSWRAccum<T, C = string | null>(
  contentId: StateId,
  initialAccum: T,
  initialCursor: C,
  fetcher: (prev: T, cursor: C) => Promise<SWRFetchResult<T, C>>,
  config?: SWRConfig,
): SWRAccum<T, C>;

export function useSWRAccum<T, C = string | null>(
  contentId: StateId | undefined,
  initialAccum: T,
  initialCursor: C,
  fetcher: ((prev: T, cursor: C) => Promise<SWRFetchResult<T, C>>) | undefined,
  config?: SWRConfig,
): SWRAccum<T, C> | undefined;

export function useSWRAccum(
  contentId: StateId | undefined,
  initialAccum: any,
  initialCursor: any,
  fetcher:
    | ((prev: any, cursor: any) => Promise<SWRFetchResult<any, any>>)
    | undefined,
  config?: SWRConfig,
): any {
  const repo = useSWRRepo(
    contentId,
    initialAccum,
    initialCursor,
    fetcher,
    config,
  );

  if (repo === undefined) return undefined;

  return {
    contentId: contentId,
    content: repo.data.content,
    meta: omit(repo.data, ["content"]),
    loadState: repo.loadState,
    load: async (reason?: string) => await repo.repo.reload(reason),
    loadMore: () => {
      repo.repo.loadMore().catch(andLog);
    },
  };
}
