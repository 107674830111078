import {
  CenterModal,
  ModalController,
  useModalWithItsOwnPage,
} from "../../../components/Modal";
import styled, { keyframes } from "styled-components";
import { Image } from "../../../components/Image";
import icRing from "../../../res/images/ic_gift_box_received_ring.png";
import icGemsGift from "../../../res/images/ic_gift_gems.png";
import icEmptyGift from "../../../res/images/icon_gift_box_received_empty.png";
import {
  ButtonColor,
  RegularButton,
  RegularLargeButton,
} from "../../../components/Buttons";
import React, { useRef, useState } from "react";
import { useI18n } from "../../../hooks/useI18n";
import { GiftBox, GiftBoxStatus } from "../../../proto/GiftBox";
import { useCountDown } from "../../../hooks/useCountDown";
import {
  TransferOrder,
  TransferOrderStatus,
} from "../../../proto/TransferOrder";
import { timeIntervalString, toHHMMSS } from "../../../utils/DateTimeUtils";
import { VStack } from "../../../components/VStack";
import openAnimation from "../../../res/images/open_gift_box.webp";
import { useHopper } from "../../../hooks/useHopper";
import { OrderType } from "./OrderDetailPage";
import { useBackend } from "../../../service/APIService";
import {
  andLog,
  Spinner,
  useErrorHandler,
} from "../../../components/handleError";
import { useMyUid } from "../../../service/AuthSessionService";
import { useBigIntParam } from "../../../hooks/useTypedParam";
import { useSWR } from "../../../hooks/swr/useSWR";
import {
  Curtain,
  ExpiringTime,
  GiftIcon,
  GiftIconContainer,
  GiftRing,
  GiftTitle,
  OpenGiftBoxImg,
  ViewGiftButton,
} from "./GiftOrderClaimDialog";

export function GiftOrderClaimModalPage() {
  const i18n = useI18n();
  const hopper = useHopper();
  const backend = useBackend();
  const myUid = useMyUid();
  const boxId = useBigIntParam("boxId");
  const boxSWR = useSWR(backend.getGiftBox(boxId));
  const giftBox = boxSWR.content;
  const handleError = useErrorHandler();
  const remainingTimeToExpire = useCountDown(() => {
    if (
      giftBox?.expiredTime !== undefined &&
      giftBox?.giftBoxStatus === GiftBoxStatus.Pending
    ) {
      return (giftBox?.expiredTime * 1000 - new Date().getTime()) / 1000;
    } else {
      return 0;
    }
  }, [giftBox]);

  async function onAcceptClick() {
    if (giftBox?.boxId) {
      setShowSpinner(true);
      try {
        const order = await backend.claimGiftBox(giftBox?.boxId).run();
        playAnimation(order.orderId);
      } catch (error) {
        handleError(error);
      } finally {
        setShowSpinner(false);
      }
    }
  }

  const [showSpinner, setShowSpinner] = useState(false);

  const giftIcon = () => {
    switch (giftBox?.giftBoxStatus) {
      case GiftBoxStatus.Done:
      case GiftBoxStatus.Expired:
      case GiftBoxStatus.Withdrawn:
        return icEmptyGift;
      case GiftBoxStatus.Pending:
        return icGemsGift;
    }
  };

  const giftTitle = () => {
    switch (giftBox?.giftBoxStatus) {
      case GiftBoxStatus.Done:
        return i18n.web3_hongbao_no_gifts_left_in_the_box();
      case GiftBoxStatus.Expired:
        return i18n.web3_hongbao_the_gift_has_expired();
      case GiftBoxStatus.Withdrawn:
        return i18n.hongbao_impr_the_gift_withdrawn();
      case GiftBoxStatus.Pending:
        return giftBox?.title || i18n.web3_hongbao_best_wishes();
    }
  };
  const showCountDown = giftBox?.giftBoxStatus === GiftBoxStatus.Pending;
  const giftIconRef = useRef<HTMLImageElement>(null);
  const lightRef = useRef<HTMLImageElement>(null);
  const ringRef = useRef<HTMLImageElement>(null);
  const curtainRef = useRef<HTMLImageElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const shakingKeyFrames = [
    { transform: "translateX(-50%) rotate(0) ", offset: 0 },
    { transform: "translateX(-50%) rotate(-10deg) ", offset: 0.125 },
    { transform: "translateX(-50%) rotate(10deg) ", offset: 0.25 },
    { transform: "translateX(-50%) rotate(-10deg) ", offset: 0.375 },
    { transform: "translateX(-50%) rotate(10deg) ", offset: 0.5 },
    { transform: "translateX(-50%) rotate(-10deg) ", offset: 0.625 },
    { transform: "translateX(-50%) rotate(10deg) ", offset: 0.75 },
    { transform: "translateX(-50%) rotate(-10deg) ", offset: 0.875 },
    { transform: "translateX(-50%) rotate(0) " },
  ];

  const movingUpKeyFrames = [
    { transform: "translate(-50%, -400px)", opacity: 0, offset: 1 },
  ];

  const darkKeyFrames = [
    { backgroundColor: "transparent", offset: 0 },
    { backgroundColor: "black", offset: 1 },
  ];

  const fadeOutKeyFrames = [{ opacity: "0", offset: 1 }];
  const modal = useModalWithItsOwnPage();

  function playAnimation(orderId: bigint) {
    const shakingAnimation = giftIconRef.current?.animate(shakingKeyFrames, {
      duration: 500,
      fill: "none",
    });
    if (shakingAnimation) {
      shakingAnimation.onfinish = (ev) => {
        if (lightRef.current) {
          lightRef.current.style.opacity = "1";
        }

        lightRef.current?.animate(fadeOutKeyFrames, {
          duration: 1000,
          fill: "forwards",
        });

        buttonRef.current?.animate(fadeOutKeyFrames, {
          duration: 1000,
          fill: "forwards",
        });

        const darkAnimation = curtainRef.current?.animate(darkKeyFrames, {
          duration: 1000,
          fill: "forwards",
        });

        if (darkAnimation) {
          darkAnimation.onfinish = () => {
            if (ringRef.current) {
              ringRef.current.style.opacity = "0";
            }
            const movingAnimation = giftIconRef.current?.animate(
              movingUpKeyFrames,
              {
                duration: 600,
                fill: "forwards",
              },
            );
            if (movingAnimation) {
              movingAnimation.onfinish = () => {
                hopper.dismissModal();
                hopper.push(`wallet/order/${orderId}`, {
                  orderType: OrderType.GiftOrder,
                  showGiftClaimedDialog: true,
                });
              };
            }
          };
        }
      };
    }
  }

  return (
    <CenterModal modal={modal} disableOverFlow={true}>
      <VStack style={{ width: "100%", alignItems: "center" }}>
        <Curtain ref={curtainRef} />
        <GiftIconContainer>
          <GiftRing ref={ringRef} src={icRing} />
          <OpenGiftBoxImg ref={lightRef} src={openAnimation} />
          <GiftIcon
            ref={giftIconRef}
            src={giftIcon()}
            style={{
              marginLeft:
                giftBox?.giftBoxStatus !== GiftBoxStatus.Pending
                  ? 12
                  : undefined,
            }}
          />
        </GiftIconContainer>
        <GiftTitle>{giftTitle()}</GiftTitle>
        <RegularButton
          ref={buttonRef}
          style={{
            backgroundColor: "#002B30B2",
            marginTop: 32,
            zIndex: 3,
            width: "100%",
          }}
          $baseColor={ButtonColor.greenish}
          onClick={() => onAcceptClick()}
        >
          {showSpinner && <Spinner style={{ width: 20, height: 20 }} />}
          {!showSpinner && i18n.web3_hongbao_open_the_gift()}
        </RegularButton>
        {(giftBox?.giftBoxStatus !== GiftBoxStatus.Pending ||
          giftBox.uid === myUid) && (
          <ViewGiftButton
            style={{
              marginTop:
                giftBox?.giftBoxStatus !== GiftBoxStatus.Pending ? 80 : 16,
            }}
          >
            {i18n.web3_hongbao_view_the_gift()}
          </ViewGiftButton>
        )}
        <ExpiringTime
          style={{
            fontWeight: showCountDown ? 700 : 400,
            color: showCountDown ? "#ffaf02" : "#FFFFFF66",
            marginTop: 16,
          }}
        >
          {showCountDown
            ? i18n.web3_v0_expiring_in(toHHMMSS(remainingTimeToExpire))
            : timeIntervalString(
                new Date((giftBox?.allClaimedTime || 0) * 1000),
                i18n,
              )}
        </ExpiringTime>
      </VStack>
    </CenterModal>
  );
}
