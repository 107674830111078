import { Page } from "../../../components/Page";
import React, { useEffect, useState } from "react";
import { useI18n } from "../../../hooks/useI18n";
import { BottomSheet, useModal } from "../../../components/Modal";
import { StateId } from "../../../hooks/StateId";
import { useLocalRepo } from "../../../hooks/swr/useLocalRepo";
import { Media } from "../../../proto/Media";
import { Category } from "../../../proto/Category";
import { Spacing } from "../../../components/Spacing";
import { CenterChild } from "../../../components/VStack";
import { Image } from "../../../components/Image";
import { RegularInputGroup } from "../../../components/Input";
import { CircleInfoMaxLength } from "../../circle/compose/EditCirclePage";
import LanguagePicker, {
  getLanguageCountryName,
} from "../../circle/compose/LanguagePicker";
import {
  EditingInfoBackground,
  EditingInfoLabel,
  EditingInfoValue,
} from "../../circle/compose/CreateCircleBasicInfoPage";
import TagsFlowLayout from "../../../components/TagsFlowLayout";
import CategoryPicker from "../../circle/compose/CategoryPicker";
import { useBackend } from "../../../service/APIService";
import { useSWR } from "../../../hooks/swr/useSWR";
import { NavEnd, NavItem, NavMiddle } from "../../../components/NavBar";
import { useHopper } from "../../../hooks/useHopper";
import CircleBackgroundFrame from "../../circle/CircleBackgroundFrame";
import SettingCell from "../../../components/SettingCell";
import AdminAgreementDialog from "../../circle/compose/AdminAgreementDialog";
import { andLog, useErrorHandler } from "../../../components/handleError";
import { useLoadState } from "../../../hooks/LoadState";
import { useNativePage } from "../../../hooks/useBridge";
import { ObjectPermission } from "../../circle/compose/ComposeCircleContext";

export interface ChatData extends ObjectPermission {
  title?: string;
  icon?: Media;
  background?: Media;
  category?: Category;
  categoryId?: bigint;
  language?: string;
}

export function useCreateChatResult(sceneId: StateId) {
  return useLocalRepo<ChatData>(["CreateChat", sceneId], {
    title: undefined,
    icon: undefined,
    background: undefined,
    category: undefined,
    categoryId: undefined,
    language: undefined,
    privacy: 1,
    discoverabilityType: 1,
  });
}

function CreateChatPage() {
  const [titleInput, setTitleInput] = useState<string>();
  const i18n = useI18n();

  const backend = useBackend();
  const handleError = useErrorHandler();

  const bgSWR = useSWR(backend.getDefaultBackgroundList());
  const [bgMedia, setBgMedia] = useState<Media>();
  const [iconMedia, setIconMedia] = useState<Media>();

  const sceneId = ["CreateChat", 0]; // you are editing a chatroom with threadId = 12345
  const editingChat = useCreateChatResult(sceneId);

  const languageModal = useModal("language-picker");
  const categoryModal = useModal("category-picker");
  const hopper = useHopper();

  useEffect(() => {
    const array = bgSWR.content;
    if (array) {
      if (bgMedia === undefined) {
        const randomIndex = Math.floor(Math.random() * (array.length - 1));
        const media = array[randomIndex].media;
        setBgMedia(media);
        editingChat.fill((prevData) => ({
          ...prevData,
          background: media,
        }));
      }

      if (iconMedia === undefined) {
        const randomIndex = Math.floor(Math.random() * (array.length - 1));
        const media = array[randomIndex].media;
        setIconMedia(media);
        editingChat.fill((prevData) => ({
          ...prevData,
          icon: media,
        }));
      }
    }
  }, [bgSWR.content]);
  const agreementModal = useModal("agreement-modal");

  useEffect(() => {
    editingChat.fill((prevData) => ({
      ...prevData,
      title: titleInput,
    }));
  }, [titleInput]);
  const nativePage = useNativePage();

  const createChatTask = useLoadState();

  async function createChat() {
    const r = await createChatTask.run(async () => {
      const chatData = editingChat.content;
      const chat = await backend.createParty(chatData).run();
      hopper.push(`thread/${chat.threadId}`);
    });

    if (!r.success) {
      handleError(r.error);
    }
  }

  return (
    <Page
      pageData={undefined}
      underlay={
        <CircleBackgroundFrame
          circleBackground={{
            displayMode: 0,
            backgroundImage: bgMedia,
          }}
        />
      }
    >
      <NavMiddle>{i18n.party_on_compose_new_party()}</NavMiddle>
      <NavEnd>
        {NavItem.text(i18n.help(), () => hopper.push("feedback"))}
      </NavEnd>
      <Spacing height={20} />
      <CenterChild>
        <Image
          src={[editingChat.content.icon, "best"]}
          width={110}
          height={110}
          style={{ borderRadius: 10 }}
        />
      </CenterChild>
      <Spacing height={32} />
      <RegularInputGroup
        placeholder={i18n.party_on_party_title()}
        value={titleInput}
        updateValue={setTitleInput}
        maxLength={CircleInfoMaxLength.Name}
      />
      <Spacing height={12} />
      <EditingInfoBackground>
        <EditingInfoLabel>
          {i18n.party_on_party_language_prefix()}
        </EditingInfoLabel>
        <EditingInfoValue
          onClick={() => {
            languageModal.open();
          }}
        >
          {editingChat.content?.language
            ? getLanguageCountryName(editingChat.content.language)?.nameByUi
            : i18n.select()}
        </EditingInfoValue>
      </EditingInfoBackground>
      <Spacing height={12} />
      <EditingInfoBackground>
        <EditingInfoLabel>{i18n.category_prefix()}</EditingInfoLabel>
        <EditingInfoValue
          onClick={() => {
            categoryModal.open();
          }}
        >
          {editingChat.content?.category
            ? editingChat.content?.category?.name
            : i18n.select()}
        </EditingInfoValue>
      </EditingInfoBackground>
      <Spacing height={12} />
      <TagsFlowLayout tags={[]} showAdd={true} />
      <SettingCell
        title={i18n.background()}
        endValue={
          <Image
            style={{
              borderRadius: 4,
              border: "1px solid rgba(255, 255, 255, 0.4)",
            }}
            src={[editingChat.content.background, "best"]}
            width={30}
            height={44}
          />
        }
      />
      <SettingCell
        title={i18n.mar_impr_permission()}
        onClick={() => {
          hopper.push("");
        }}
      ></SettingCell>

      <BottomSheet modal={languageModal}>
        <LanguagePicker
          modal={languageModal}
          lastChosenLanguage={editingChat.content?.language}
          onChoose={(code: string) => {
            editingChat.fill((prevData) => ({
              ...prevData,
              language: code,
            }));
          }}
        />
      </BottomSheet>

      <BottomSheet modal={categoryModal}>
        <CategoryPicker
          backend={backend}
          modal={categoryModal}
          lastChosenCategory={editingChat.content?.category}
          onChoose={(category: Category) => {
            editingChat.fill((prevData) => ({
              ...prevData,
              category: category,
            }));
          }}
        />
      </BottomSheet>

      <AdminAgreementDialog
        onClickAgree={() => {
          createChat().catch(andLog);
        }}
        modal={agreementModal}
      />
    </Page>
  );
}

export default CreateChatPage;
