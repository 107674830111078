import { DemoCell, DemoNote } from "./DemoCell";
import React from "react";
import { NumberInput } from "../../components/NumberInput";
import { useEditingBigNumber } from "../../hooks/useEditingBigNumber";
import { formatMoney } from "../../proto/Currency";
import BigNumber from "bignumber.js";
import { Page } from "../../components/Page";

export function MonetaryValueDemoPage() {
  const [pendingLocalCountText, setPendingLocalCountText, pendingLocalCount] =
    useEditingBigNumber("localAssetAmount", new BigNumber(0.00009));
  return (
    <Page pageData={undefined}>
      <NumberInput
        value={pendingLocalCountText}
        decimal={true}
        onTextChange={setPendingLocalCountText}
      />
      <DemoNote>Short</DemoNote>
      <DemoCell>{formatMoney("short", pendingLocalCount)}</DemoCell>
      <DemoNote>Medium</DemoNote>
      <DemoCell>{formatMoney("medium", pendingLocalCount)}</DemoCell>
      <DemoNote>Long</DemoNote>
      <DemoCell>{formatMoney("long", pendingLocalCount)}</DemoCell>
    </Page>
  );
}
