import { Page } from "../../components/Page";
import { CircleGridType } from "./CircleGridPage";
import { I18n, useI18n } from "../../hooks/useI18n";
import { useHopper } from "../../hooks/useHopper";
import { ObjectType } from "../../proto/ObjectSpec";
import GridLayout from "../../components/GridLayout";
import CategoryCell from "../../components/CategoryCell";
import { useSWR } from "../../hooks/swr/useSWR";
import { Category } from "../../proto/Category";
import {
  useBigIntSearchParam,
  useStringSearchParam,
} from "../../hooks/useTypedParam";
import React, { useEffect, useRef, useState } from "react";
import { Spacing } from "../../components/Spacing";
import SearchLayout from "../../components/SearchLayout";
import { usePageSpec } from "../common/usePageSpec";
import CircleWidgetCell from "./CircleWidgetCell";
import { useBackend } from "../../service/APIService";
import { subpages } from "../../components/Subpage";
import { SearchSource } from "../search/SearchPage";
import {
  ViewPagerPage,
  ViewPagerTabLayout,
} from "../../components/ViewPagerPage";
import CircleSnippetListPage from "./CircleSnippetListPage";
import { AuxPage } from "../../components/AuxPage";
import { NavEnd, NavItem, NavMiddle } from "../../components/NavBar";
import { NavButtonType } from "../../bridge/NavButtonDesc";
import iconMoreCategory from "../../res/images/icon_circle_more_category.svg";
import iconLessCategory from "../../res/images/icon_circle_less_category.svg";
import { HStack, Spring } from "../../components/VStack";
import { Image } from "../../components/Image";

function useExploreCirclesHeadData() {
  const backend = useBackend();
  const categoryId = useBigIntSearchParam("categoryId", BigInt(0));

  const categoriesSWR = useSWR(backend.getCategories(ObjectType.CIRCLE));
  const operationSWR = useSWR(
    backend.getOperationPage(
      1,
      categoryId,
      ObjectType.CIRCLE,
      categoryId ? 2 : 1,
    ),
  );

  return [categoriesSWR, operationSWR] as const;
}

function ExploreCirclesHead(props: {
  swr: ReturnType<typeof useExploreCirclesHeadData>;
  i18n: I18n;
}) {
  const [categoriesSWR, operationSWR] = props.swr;

  const hopper = useHopper();

  const onClickCategory = (category: Category) => {
    hopper.push(`explore-circles`, {
      categoryId: category.categoryId,
      categoryName: category.name,
    });
  };
  const categoryId = useBigIntSearchParam("categoryId");

  const pageSpec = usePageSpec();

  const [expandCategory, setExpandCategory] = useState(false);

  return (
    <div
      style={{
        marginInlineStart: "var(--page-h-inset)",
        marginInlineEnd: "var(--page-h-inset)",
      }}
    >
      <SearchLayout
        onClick={() => {
          hopper.push("search", {
            searchSource: SearchSource.Circle,
          });
        }}
      />
      <Spacing height={24} />
      {!categoryId && categoriesSWR.content?.list && !expandCategory && (
        <GridLayout
          spanCount={4}
          style={{
            paddingTop: 0,
            paddingBottom: 0,
          }}
          items={categoriesSWR.content?.list
            ?.slice(0, pageSpec === "wide" ? 30 : 8)
            .map((category) => (
              <CategoryCell
                onClick={() => {
                  onClickCategory(category);
                }}
                category={category}
              />
            ))}
        />
      )}
      {!categoryId && categoriesSWR.content?.list && expandCategory && (
        <GridLayout
          spanCount={4}
          style={{
            paddingTop: 0,
            paddingBottom: 0,
          }}
          items={categoriesSWR.content?.list.map((category) => (
            <CategoryCell
              onClick={() => {
                onClickCategory(category);
              }}
              category={category}
            />
          ))}
        />
      )}
      {!categoryId && !expandCategory && (
        <HStack
          style={{ height: 12 }}
          onClick={() => {
            setExpandCategory(true);
          }}
        >
          <Spring />
          <Image src={iconMoreCategory} width={55} height={12} />
          <Spring />
        </HStack>
      )}
      {!categoryId && expandCategory && (
        <HStack
          style={{ height: 14 }}
          onClick={() => {
            setExpandCategory(false);
          }}
        >
          <Spring />
          <Image src={iconLessCategory} width={56} height={14} />
          <Spring />
        </HStack>
      )}

      {operationSWR.content?.widgetList && (
        <>
          {operationSWR.content.widgetList.map((widget) => (
            <CircleWidgetCell
              circleId={BigInt(0)}
              folderId={BigInt(0)}
              widget={widget}
              i18n={props.i18n}
            />
          ))}
          <Spacing height={24} />
        </>
      )}
    </div>
  );
}

export function ExploreCirclesHeadPage() {
  const headData = useExploreCirclesHeadData();
  const i18n = useI18n();
  return (
    <Page pageData={[...headData]} extraSafeBottom={0}>
      <NavMiddle>{i18n.explore_circles()}</NavMiddle>
      <ExploreCirclesHead swr={headData} i18n={i18n} />
    </Page>
  );
}

export function ExploreCirclesPage() {
  const i18n = useI18n();

  const pageSpec = usePageSpec();
  const swr = useExploreCirclesHeadData();

  const entries: [CircleGridType, string][] = [
    [CircleGridType.RECOMMEND, i18n.recommended()],
    [CircleGridType.POPULAR, i18n.popular()],
    [CircleGridType.LATEST, i18n.latest()],
  ];

  const categoryName = useStringSearchParam("categoryName");
  const categoryId = useBigIntSearchParam("categoryId");
  const hopper = useHopper();
  return (
    <ViewPagerPage
      key={categoryId}
      pageData={[...swr]}
      subpages={subpages(entries, (e) => (
        <CircleSnippetListPage circleGridType={e} />
      ))}
    >
      <NavEnd>
        {NavItem.image(NavButtonType.Search, () => {
          hopper.push("search", {
            searchSource: SearchSource.Circle,
          });
        })}
      </NavEnd>
      {pageSpec !== "wide" && <ExploreCirclesHead swr={swr} i18n={i18n} />}
      <ViewPagerTabLayout />

      {categoryName && <NavMiddle>{categoryName}</NavMiddle>}

      <AuxPage url={"explore-circles-head"} />
    </ViewPagerPage>
  );
}
