import { useI18n } from "../../hooks/useI18n";
import { useHopper } from "../../hooks/useHopper";
import { Page } from "../../components/Page";
import { NavEnd, NavItem, NavMiddle } from "../../components/NavBar";
import { AuthSubtitleLabel, AuthTitleLabel } from "./InvitationCodePage";
import { AuthBody, AuthType } from "../../proto/Auth";
import React, { useMemo } from "react";
import { Image } from "../../components/Image";
import arrowDown from "../../res/images/icon_arrow_down.svg";
import { RegularLargeButton } from "../../components/Buttons";
import { DatePicker } from "antd";
import styled from "styled-components";
import { useNativePage } from "../../hooks/useBridge";
import { andLog } from "../../components/handleError";
import { useStringSearchParam } from "../../hooks/useTypedParam";
import { useMemoryRepo } from "../../hooks/swr/useLocalRepo";
import dayjs from "dayjs";

const CustomStyledDatePicker = styled(DatePicker)`
  margin-top: 66px;
  align-items: stretch;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.06);
  padding: var(--group-inset);
  box-sizing: border-box;
  overflow: clip;
  font-weight: 500;
  font-size: 20px;
  text-align: center;

  && input {
    font-family: DIN, sans-serif;
    font-weight: 500;
    font-size: 20px !important;
    text-align: center;
    color: var(--color-text00);
  }

  && input::placeholder {
    font-family: DIN, sans-serif;
    font-weight: 500;
    font-size: 20px !important;
    text-align: center;
    color: var(--color-text00);
  }

  &:hover,
  &:focus {
    background-color: transparent;
  }

  /* 当日期选择器获得焦点时，防止输入框高亮 */

  &:focus-within {
    background-color: transparent;
  }
`;

export function SelectBirthdayPage() {
  const i18n = useI18n();
  const hopper = useHopper();
  const nativePage = useNativePage();
  const [birthday, setBirthday] = React.useState<string | undefined>();

  const flowId = useStringSearchParam("f");
  const authInfo = useMemoryRepo<AuthBody>(flowId ?? "", {});

  const authType = useMemo(() => {
    return authInfo.content.authType;
  }, [authInfo.content]);
  const countryCode = useMemo(() => {
    return authInfo.content.countryCode;
  }, [authInfo.content]);

  // 计算今天减去18年的日期
  const defaultBirthday = dayjs().subtract(18, "years");

  function isOlderThan(birthday: string, validAge: number = 18): boolean {
    const birthDate = new Date(birthday); // 将生日字符串解析为 Date 对象
    const today = new Date();

    // 计算当前年份减去出生年份
    let age = today.getFullYear() - birthDate.getFullYear();

    // 如果还没到生日，就减去一年
    const birthMonth = birthDate.getMonth();
    const birthDay = birthDate.getDate();
    const currentMonth = today.getMonth();
    const currentDay = today.getDate();

    if (
      currentMonth < birthMonth ||
      (currentMonth === birthMonth && currentDay < birthDay)
    ) {
      age--;
    }

    return age >= validAge;
  }

  async function onNextClick() {
    if (!birthday) {
      return;
    } else if (!isOlderThan(birthday, 18)) {
      nativePage
        .infoHud(i18n.age_not_meet_requirement_reminder())
        .catch(andLog);
      return;
    }
    const chooseYes = await nativePage.alertYesOrCancel(
      i18n.gender_age_prompt_birth_next_tip(birthday),
      i18n.yes(),
      i18n.cancel(),
    );
    if (chooseYes) {
      let path = "";
      switch (authType) {
        case AuthType.Email:
          path = "email-sign-up";
          break;
        case AuthType.Phone:
          path = "phone-sign-up";
          break;
        case AuthType.Google:
        case AuthType.Apple:
          path = "select-gender";
          break;
        default:
          // TODO
          return;
      }
      await authInfo.fill((prev) => ({
        ...prev,
        birthday: birthday,
      }));
      hopper.push(path, {
        f: flowId,
      });
    }
  }

  return (
    <Page pageData={undefined}>
      <NavMiddle>{i18n.auth_sign_up()}</NavMiddle>
      <NavEnd>
        {NavItem.text(i18n.help(), () => hopper.push("feedback"))}
      </NavEnd>
      <AuthTitleLabel>
        {i18n.linear_signup_select_your_date_of_birth()}
      </AuthTitleLabel>
      <CustomStyledDatePicker
        inputReadOnly={true}
        suffixIcon={
          <Image
            src={arrowDown}
            alt={"arrowDown"}
            width={20}
            height={20}
          ></Image>
        }
        onChange={(date, dateString) => {
          //TODO:check dateString format satisfies server 'en' requirements
          if (typeof dateString === "string") {
            setBirthday(dateString);
          } else if (Array.isArray(dateString)) {
            setBirthday(dateString[0] || undefined);
          }
        }}
        placeholder={birthday ? birthday : i18n.select()}
        defaultPickerValue={defaultBirthday}
      />

      <AuthSubtitleLabel style={{ marginTop: "32px" }}>
        {i18n.sign_up_birthday_request_description()}
      </AuthSubtitleLabel>
      <RegularLargeButton
        disabled={!birthday}
        style={{ margin: "80px 62px 0" }}
        onClick={onNextClick}
      >
        {i18n.next()}
      </RegularLargeButton>
    </Page>
  );
}
