import { Page } from "../../components/Page";
import CircleGridCell, {
  CircleGridWithTokenCell,
  CirclePendingCell,
} from "./CircleGridCell";
import { useHopper } from "../../hooks/useHopper";
import { useCirclesSWR } from "./useCircleSWR";
import styled from "styled-components";
import GridLayout from "../../components/GridLayout";
import { SpeedTitle } from "../../components/SpeedTitle";
import { useI18n } from "../../hooks/useI18n";
import { BSMenu, BSMenuItem } from "../../components/BSMenu";
import { useModal } from "../../components/Modal";
import { useRef } from "react";
import { useLoadState } from "../../hooks/LoadState";
import { useBackend } from "../../service/APIService";
import { andLog, useErrorHandler } from "../../components/handleError";
import { useNativePage } from "../../hooks/useBridge";

export enum CircleGridType {
  All = "joined",
  Managed = "manage",
  RECOMMEND = "recommend",
  POPULAR = "popular",
  LATEST = "latest",
  PENDING = "pending",
}

export interface CircleGridProps {
  circleGridType: CircleGridType;
}

const HorizontalContainer = styled.div`
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  gap: 10px;
  width: 100%;
`;

function CircleGridPage(props: CircleGridProps) {
  const hopper = useHopper();
  const onClickCircle = (circleId: bigint) => {
    hopper.push(`circle/${circleId}`);
  };

  const circlesSWR = useCirclesSWR(props.circleGridType);
  const circlesPendingSWR = useCirclesSWR(CircleGridType.PENDING);
  const i18n = useI18n();
  const bsMenu = useModal("bsMenu");
  const clickCircleId = useRef<bigint | undefined>();
  const circleRequestId = useRef<bigint | undefined>();

  const backend = useBackend();
  const removeTarget = useLoadState();
  const handleError = useErrorHandler();
  const nativePage = useNativePage();

  async function removeCard(requestId: bigint) {
    const r = await removeTarget.run(async () => {
      const res = await backend.removeCircleCard(requestId).run();
    });

    if (!r.success) {
      handleError(r.error);
    } else {
      nativePage.infoHud(i18n.clover_remove_successfully()).catch(andLog);
      circlesPendingSWR.load().catch(andLog);
      circlesSWR.load().catch(andLog);
    }
  }

  return (
    <Page pageData={[circlesSWR, circlesPendingSWR]}>
      {props.circleGridType === CircleGridType.All &&
        circlesPendingSWR.content.length > 0 && (
          <SpeedTitle style={{ marginBottom: 12, marginTop: 12 }}>
            {i18n.clover_capital_pending()}
          </SpeedTitle>
        )}
      {props.circleGridType === CircleGridType.All &&
        circlesPendingSWR.content.length > 0 && (
          <HorizontalContainer>
            {circlesPendingSWR.content.map((circle) => (
              <CirclePendingCell
                circle={circle}
                key={circle.circleId}
                onClick={() => {
                  clickCircleId.current = circle.circleId;
                  circleRequestId.current = circle.joinRequest?.requestId;
                  bsMenu.open();
                }}
              />
            ))}
          </HorizontalContainer>
        )}
      {props.circleGridType === CircleGridType.All &&
        circlesPendingSWR.content.length > 0 && (
          <SpeedTitle style={{ marginTop: 20 }}>
            {i18n.clover_capital_joined()}
          </SpeedTitle>
        )}
      <GridLayout
        style={{ paddingTop: 12 }}
        spanCount={3}
        items={circlesSWR?.content.map((circle) => (
          <CircleGridWithTokenCell
            circleId={circle.circleId}
            circle={circle}
            key={circle.circleId}
            onClick={() => {
              onClickCircle(circle.circleId);
            }}
          />
        ))}
      />
      <BSMenu modal={bsMenu}>
        <BSMenuItem
          title={i18n.circle_room_view_this_circle()}
          onClick={() => {
            if (clickCircleId.current) {
              onClickCircle(clickCircleId.current);
            }
          }}
        />
        <BSMenuItem
          title={i18n.clover_remove_this_card()}
          onClick={() => {
            if (circleRequestId.current) {
              removeCard(circleRequestId.current).catch(andLog);
            }
          }}
        />
      </BSMenu>
    </Page>
  );
}

export default CircleGridPage;
