import { useMemo } from "react";
import { z } from "zod";
import styled, { css } from "styled-components";

import { Media } from "../../proto/Media";
import type { Background } from "../../proto/BackgroundMedia";
import { useI18n } from "../../hooks/useI18n";
import { useHopper } from "../../hooks/useHopper";
import { useBackend } from "../../service/APIService";
import { useSWR } from "../../hooks/swr/useSWR";
import {
  createResultToken,
  useLayerProduceResult,
  useV2ConsumeResult,
  useV2ProduceResult,
} from "../../hooks/useResult";
import { Page } from "../../components/Page";
import { NavMiddle } from "../../components/NavBar";
import { Image } from "../../components/Image";
import GridLayout from "../../components/GridLayout";
import { SingleLineUnspecifiedWidth } from "../../components/CommonViews";
import { FilePicker } from "../../components/FilePicker";
import { RegularButton } from "../../components/Buttons";
import { PageFooter } from "../../components/PageHeaderFooter";

import empty from "../../res/images/ic_picture.png";

enum PICKER_TYPE_ENUM {
  LIBRARY = 1,
  COLOR = 2,
  SYSTEM = 3,
}

const RootContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px; // 这个值可以根据需要调整
  justify-content: center;
`;

const RatioImageContainer = styled.div<{ $selected?: boolean }>`
  width: 100%;
  padding-bottom: ${({ $selected }) =>
    $selected ? "146%" : "148.65%"}; // 计算出的新值
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-sizing: border-box;
  ${({ $selected }) =>
    $selected &&
    css`
      border: 1px solid #00ff94;
    `}
`;

const Caption = styled.div`
  ${SingleLineUnspecifiedWidth};
  font-weight: 400;
  font-size: 11px;
  color: var(--color-text00);
`;

const OtherPicker = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.08);
`;

export interface BgMediaProps {
  backgroundMedia: Background;
  chosenMediaId?: bigint;
  onClick: (media: Background) => void;
}

function BgMediaPreview({
  backgroundMedia,
  chosenMediaId,
  onClick,
}: BgMediaProps) {
  return (
    <RootContainer
      onClick={() => {
        onClick(backgroundMedia);
      }}
    >
      <RatioImageContainer
        $selected={chosenMediaId === backgroundMedia.media?.mediaId}
      >
        <Image
          src={[backgroundMedia.media, "best"]}
          style={{
            borderRadius: 6,
            position: "absolute",
            left: 0,
            top: 0,
            width:
              chosenMediaId === backgroundMedia.media?.mediaId
                ? "calc(100% - 10px)"
                : "100%",
            height:
              chosenMediaId === backgroundMedia.media?.mediaId
                ? "calc(100% - 10px)"
                : "100%",
            margin:
              chosenMediaId === backgroundMedia.media?.mediaId ? "5px" : 0, // 负边距抵消边框
            transition: "all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1)", // 使用自定义插值器
          }}
        />
      </RatioImageContainer>
      <Caption>{backgroundMedia.title}</Caption>
    </RootContainer>
  );
}

const BridgeLocalMedia = z.object({
  url: z.string(),
  id: z.string(),
});

const backgroundSchema = z.object({
  media: Media.optional(),
  local: BridgeLocalMedia.optional(),
  blob: z.instanceof(Blob).optional(),
});

type BackgroundSchema = z.infer<typeof backgroundSchema>;

export const BackgroundPickerResult = createResultToken(
  backgroundSchema,
  "BackgroundPickerPageResult"
);

const BackrougndPickerInnerResult = createResultToken(
  backgroundSchema,
  "BackrougndPickerInnerResult"
);

export const getBackgroundValue = (data?: BackgroundSchema) => {
  return data?.media || data?.blob || data?.local;
};

function BackgroundPickerPage() {
  const i18n = useI18n();
  const hopper = useHopper();
  const backend = useBackend();
  const bgSWR = useSWR(backend.getDefaultBackgroundList());

  const list: ({ type: number } & Partial<Background>)[] = useMemo(() => {
    return [
      { type: PICKER_TYPE_ENUM.LIBRARY },
      ...(bgSWR.content || []).map((item) => ({
        type: PICKER_TYPE_ENUM.SYSTEM,
        ...item,
      })),
    ];
  }, [bgSWR.content]);

  const backgroudProducer = useLayerProduceResult(BackgroundPickerResult);

  const innerComsumer = useV2ConsumeResult(BackrougndPickerInnerResult);

  return (
    <Page pageData={bgSWR}>
      <NavMiddle>{i18n.background()}</NavMiddle>
      <GridLayout
        spanCount={4}
        style={{ paddingTop: 0 }}
        items={list.map((item) => {
          if (item.type === PICKER_TYPE_ENUM.LIBRARY) {
            return (
              <FilePicker
                id="backgroundPicker"
                key={item.type}
                accept={"image/*"}
                onPick={(localMedia) => {
                  if (localMedia instanceof Blob) {
                    innerComsumer
                      .fill({ blob: localMedia })
                      .then(() => hopper.push("background-apply"));
                  } else {
                    innerComsumer
                      .fill({ local: localMedia })
                      .then(() => hopper.push("background-apply"));
                  }
                }}
              >
                <RootContainer>
                  <RatioImageContainer>
                    <OtherPicker>
                      <img src={empty} width={36} height={36} />
                    </OtherPicker>
                  </RatioImageContainer>
                  <Caption>{i18n.library()}</Caption>
                </RootContainer>
              </FilePicker>
            );
          }
          if (item.type === PICKER_TYPE_ENUM.SYSTEM) {
            return (
              <BgMediaPreview
                key={item?.media?.mediaId}
                backgroundMedia={item}
                chosenMediaId={backgroudProducer.result?.media?.mediaId}
                onClick={(background) => {
                  if (background.media) {
                    innerComsumer
                      .fill({ media: background.media })
                      .then(() => hopper.push("background-apply"));
                  }
                }}
              />
            );
          }
        })}
      />
    </Page>
  );
}

const ImageContainer = styled.div`
  width: 100%;
  aspect-ratio: 9/16;
  border-radius: 12px;
  overflow: hidden;
`;

export function BackgroudApplyPage() {
  const i18n = useI18n();
  const hopper = useHopper();

  const backgroudProducer = useLayerProduceResult(BackgroundPickerResult);
  const { result } = useV2ProduceResult(BackrougndPickerInnerResult);

  return (
    <Page pageData={undefined}>
      <NavMiddle>{i18n.background()}</NavMiddle>
      <ImageContainer>
        <Image
          width="100%"
          height="100%"
          src={[getBackgroundValue(result), "best"]}
        />
      </ImageContainer>
      <PageFooter obscuredZoneKey="NFTBottom">
        <RegularButton
          style={{ margin: "0 auto", width: 275 }}
          onClick={() => {
            if (result) {
              backgroudProducer.fill(result).then(() => hopper.dismissLayer());
            }
          }}
        >
          {i18n.bg_impr_apply()}
        </RegularButton>
      </PageFooter>
    </Page>
  );
}

export default BackgroundPickerPage;
