import { z } from "zod";
import { zBigInt, zStatic } from "../utils/zodUtils";
import { Media } from "./Media";

export const Storyboard = z.object({
  storyboardId: zBigInt.optional(),
  name: z.string().optional(),
  preview: Media.optional(),
});

export type Storyboard = zStatic<typeof Storyboard>;
