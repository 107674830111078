import { Circle } from "../../proto/Circle";
import { Image } from "../../components/Image";
import styled from "styled-components";
import { HStack, Spring, VStack } from "../../components/VStack";
import { SingleLineUnspecifiedWidth } from "../../components/CommonViews";
import React, { CSSProperties } from "react";
import lockIcon from "../../res/images/ic_nft_lock.svg";
import { CircleJoinPermission } from "./JoinCircleRequest";
import iconQRCode from "../../res/images/qr_code.svg";
import { useHopper } from "../../hooks/useHopper";
import { CircleBottomTokenView } from "./CircleGridCell";
import { isValidCircleToken } from "../../proto/CircleFin";

export interface CircleSnippetProps {
  circle: Circle;
  onClick: () => void;
  hPadding?: CSSProperties;
}

function CircleSnippetCell(props: CircleSnippetProps) {
  const hopper = useHopper();
  return (
    <div
      onClick={props.onClick}
      style={{
        display: "flex",
        flexDirection: "row",
        gap: 12,
        paddingTop: 12,
        paddingBottom: 12,
        ...props.hPadding,
      }}
    >
      <div style={{ position: "relative" }}>
        <Image
          src={[props.circle.cover, "best"]}
          width={100}
          height={150}
          style={{
            borderRadius: 10,
            borderColor: "rgba(255, 255, 255, 0.5)",
            borderStyle: "solid",
            borderWidth: 0.5,
          }}
        />
        {props.circle.joinPermission !== CircleJoinPermission.OPEN && (
          <div
            style={{
              width: 20,
              height: 20,
              borderStartStartRadius: 10,
              borderStartEndRadius: 0,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 4,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              position: "absolute",
              left: 0,
              top: 0,
            }}
          >
            <Image
              src={lockIcon}
              width={14}
              height={14}
              style={{ marginInlineStart: 2, marginTop: 4 }}
            />
          </div>
        )}
        <Image
          src={[props.circle.circleIcon, "best"]}
          width={30}
          height={30}
          style={{
            borderRadius: 4,
            position: "absolute",
            right: 4,
            top: 4,
            border: `0.5px solid ${props.circle.themeColor}`,
          }}
        />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          paddingTop: 10,
          maxWidth: "calc(100% - 206px)",
          gap: 8,
        }}
      >
        <CircleName>{props.circle.name}</CircleName>
        <HStack style={{ gap: 4, maxHeight: "100%", display: "flex" }}>
          <Image
            src={iconQRCode}
            alt={"iconQRCode"}
            width={20}
            height={20}
            onClick={() => {
              hopper.modal(`circle/${props.circle.circleId}/qr-code-share`);
            }}
          />
          <div
            style={{
              fontWeight: 400,
              fontSize: 12,
              maxWidth: "calc(100% - 50px)",
              overflow: "hidden", // 隐藏超出的内容
              textOverflow: "ellipsis", // 添加省略号
              whiteSpace: "nowrap", // 禁止换行
            }}
          >
            {`c/${props.circle.socialId}`}
          </div>

          <div style={{ fontWeight: 400, fontSize: 12 }}>|</div>

          <div style={{ fontWeight: 400, fontSize: 12 }}>
            {props.circle.language}
          </div>
        </HStack>
        <CircleTagline>{props.circle.tagline}</CircleTagline>
      </div>

      <VStack style={{ width: 80 }}>
        <CircleBottomTokenView
          circle={props.circle}
          style={{
            borderStyle: "solid",
            borderWidth: 0.35,
            borderColor: isValidCircleToken(props.circle.tokenProject)
              ? "rgba(141,177,242,0.69)"
              : "rgba(255, 255, 255, 0.1)",
            borderStartStartRadius: 8,
            borderStartEndRadius: 8,
          }}
        ></CircleBottomTokenView>
        <Spring />
      </VStack>
    </div>
  );
}

const CircleName = styled.div`
  ${SingleLineUnspecifiedWidth}; // 直接应用 CSS 模块
  font-weight: 700;
  font-size: 20px;
`;

const CircleTagline = styled.div`
  ${SingleLineUnspecifiedWidth}; // 直接应用 CSS 模块
  font-weight: 400;
  font-size: 12px;
`;

export default CircleSnippetCell;
