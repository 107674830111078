import { useHopper } from "../../hooks/useHopper";
import { Page } from "../../components/Page";
import { DemoCell } from "./DemoCell";
import React from "react";

export function PageArchDemoPage() {
  const hopper = useHopper();

  return (
    <Page pageData={undefined}>
      <DemoCell onClick={() => hopper.push(`simple-data-demo`)}>
        Simple Data
      </DemoCell>
      <DemoCell onClick={() => hopper.push(`simple-list-demo`)}>
        Simple List
      </DemoCell>
      <DemoCell onClick={() => hopper.push(`page-footer-demo`)}>
        Page Footer
      </DemoCell>

      <DemoCell onClick={() => hopper.push("page-bg-demo")}>
        Page Background
      </DemoCell>

      <DemoCell onClick={() => hopper.push("view-pager2-demo")}>
        View Pager2
      </DemoCell>

      <DemoCell onClick={() => hopper.layer("hopper-layer-demo")}>
        Layer
      </DemoCell>
    </Page>
  );
}
