import React from "react";

interface FlexGridProps {
  items: string[];
}

// FlexGrid 组件
const FlexGrid: React.FC<FlexGridProps> = ({ items }) => {
  return (
    <div style={styles.gridContainer}>
      {items.map((item, index) => (
        <div key={index} style={styles.cellContainer}>
          <div style={styles.cellContent}>{item}</div>
          {index !== items.length - 1 && (
            <div style={styles.verticalDivider}></div>
          )}
        </div>
      ))}
    </div>
  );
};

// 样式对象
const styles = {
  gridContainer: {
    display: "flex",
    flexWrap: "wrap" as "wrap", // 支持换行
    alignItems: "center", // 垂直方向对齐
  },
  cellContainer: {
    display: "flex",
    alignItems: "center",
    position: "relative" as "relative",
  },
  cellContent: {
    fontSize: 12,
    color: "rgba(255,255,255,0.6)", // 文本颜色
    fontWeight: 400,
  },
  verticalDivider: {
    width: "1px", // 分割线的宽度
    height: "10px", // 你可以根据需求调整分割线的高度
    backgroundColor: "rgba(255,255,255,0.4)", // 分割线的颜色
    marginLeft: "14px", // 左边距
    marginRight: "14px", // 右边距
  },
};

export default FlexGrid;
