import React, { createContext, useContext, useState, ReactNode } from "react";
import { Media } from "../../../proto/Media";
import { Category } from "../../../proto/Category";
import { CircleBackground } from "../../../proto/CircleBackground";
import { CircleExtensions, Discoverability } from "../../../proto/Circle";
import { string, z } from "zod";
import { zEnum, zStatic } from "../../../utils/zodUtils";
import { CircleJoinPermission } from "../JoinCircleRequest";

export interface CircleData extends ObjectPermission {
  name?: string;
  tagline?: string;
  circleIcon?: Media;
  cover?: Media;
  circleBackground?: CircleBackground;
  category?: Category;
  language?: string;
  themeColor?: string;
  viewPagerIndex: number;
  tags?: string[];
}

export const CreateCircleData = z.object({
  name: z.string().optional(),
  tagline: z.string().optional(),
  circleIcon: Media.optional(),
  cover: Media.optional(),
  circleBackground: CircleBackground.optional(),
  categoryId: z.bigint().optional(),
  language: z.string().optional(),
  themeColor: z.string().optional(),
  joinPermission: zEnum(CircleJoinPermission),
  joinPasscode: z.string().optional(),
  tagStrList: z.array(z.string()).optional(),
  discoverability: zEnum(Discoverability).optional(),
  extensions: CircleExtensions.optional(),
});

export type CreateCircleData = zStatic<typeof CreateCircleData>;

export interface ObjectPermission {
  privacy: CircleJoinPermission;
  discoverabilityType: number;
  acceptJoinRequests?: boolean;
  question?: string;
  joinPasscode?: string;
  passcodeEnable?: boolean;
  allowMembersInvite?: boolean;
}

interface CircleContextProps {
  circleData: CircleData;
  setCircleData: React.Dispatch<React.SetStateAction<CircleData>>;
}

const ComposeCircleContext = createContext<CircleContextProps | undefined>(
  undefined,
);

export const ComposeCircleProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [circleData, setCircleData] = useState<CircleData>({
    name: undefined,
    tagline: undefined,
    circleIcon: undefined,
    cover: undefined,
    circleBackground: undefined,
    themeColor: "#6068FF",
    privacy: 1,
    discoverabilityType: 1,
    acceptJoinRequests: true,
    question: undefined,
    joinPasscode: undefined,
    passcodeEnable: false,
    allowMembersInvite: undefined,
    viewPagerIndex: 0,
  });

  return (
    <ComposeCircleContext.Provider value={{ circleData, setCircleData }}>
      {children}
    </ComposeCircleContext.Provider>
  );
};

export const useCircleComposeData = () => {
  const context = useContext(ComposeCircleContext);
  if (!context) {
    throw new Error(
      "useCircleData must be used within a ComposeCircleProvider",
    );
  }
  return context;
};
