import { Page } from "../../components/Page";
import { NavMiddle } from "../../components/NavBar";
import { useI18n } from "../../hooks/useI18n";
import { Image } from "../../components/Image";
import icTokenIntroduce from "../../res/images/token_create_introduce.png";
import { useBigIntParam } from "../../hooks/useTypedParam";
import styled from "styled-components";
import { PageFooter } from "../../components/PageHeaderFooter";
import { RegularButton } from "../../components/Buttons";
import {
  alpha_on_pressed,
  RowCenterButton,
} from "../../components/CommonStyles";
import React from "react";
import { useHopper } from "../../hooks/useHopper";
import { BSMenu } from "../../components/BSMenu";
import { useModal } from "../../components/Modal";
import SettingCell from "../../components/SettingCell";
import icInfoYellow from "../../res/images/ic_info_yellow.svg";
import { HStack, Spring, VStack } from "../../components/VStack";
import { SingleLineUnspecifiedWidth } from "../../components/CommonViews";
import { useBackend } from "../../service/APIService";
import { useSWR } from "../../hooks/swr/useSWR";
import { useNativePage } from "../../hooks/useBridge";
import { andLog } from "../../components/handleError";

export enum CircleTokenDeployStatus {
  Unbound = 0,
  Deploying = 1,
  Deployed = 2,
}

export function CircleTokenCreateIntroducePage() {
  const i18n = useI18n();
  const circleId = useBigIntParam("circleId");
  const hopper = useHopper();

  const circleTokenCreationMenu = useModal("circleTokenCreationMenu");
  const backend = useBackend();

  const presetSWR = useSWR(backend.createTokenPresets(circleId));

  const nativePage = useNativePage();
  return (
    <Page pageData={undefined}>
      <NavMiddle>{i18n.clover_circle_token()}</NavMiddle>
      <Spring />
      <Image
        style={{ margin: "60px auto 48px" }}
        src={icTokenIntroduce}
        alt={"StatusImage"}
        width={300}
        height={225}
      ></Image>
      <DescLabel>{i18n.clover_circle_token_deploy_unbound_tips()}</DescLabel>
      <Spring style={{ flexGrow: 3 }} />
      <PageFooter obscuredZoneKey={"TokenDeployBottom"}>
        <RegularButton
          style={{
            ...RowCenterButton,
            marginTop: 8,
          }}
          onClick={() => {
            circleTokenCreationMenu.open();
          }}
        >
          {i18n.create()}
        </RegularButton>
      </PageFooter>
      <BSMenu
        title={i18n.clover_select_circle_token_creation_method()}
        modal={circleTokenCreationMenu}
      >
        <SettingCell
          style={{ paddingLeft: 0, paddingRight: 0, height: 80 }}
          title={i18n.clover_create_a_new_token()}
          showAccessoryImage={false}
          secondaryValue={
            presetSWR.content?.isFree ? (
              <HStack>
                <Image
                  src={icInfoYellow}
                  alt={"icInfoYellow"}
                  width={14}
                ></Image>
                <FreeLabel>{i18n.clover_free_for_a_limited_time()}</FreeLabel>
              </HStack>
            ) : undefined
          }
          onClick={() => {
            circleTokenCreationMenu.close();
            hopper.push(`circle/${circleId}/token-design`);
          }}
        ></SettingCell>
        <VStack
          style={{ gap: 4, height: 80, padding: "20px 0" }}
          onClick={() => {
            nativePage.infoHud(i18n.web3_v0_coming_soon()).catch(andLog);
          }}
        >
          <ChooseExistingTokenTitleLabel>
            {i18n.clover_choose_an_existing_token()}
          </ChooseExistingTokenTitleLabel>
          <ChooseExistingTokenSecondaryLabel>
            {i18n.web3_v0_coming_soon()}
          </ChooseExistingTokenSecondaryLabel>
        </VStack>
      </BSMenu>
    </Page>
  );
}

export const ChooseExistingTokenTitleLabel = styled.div`
  font-size: 16px;
  font-weight: 400;
  ${SingleLineUnspecifiedWidth};
  color: var(--color-text30);
`;

export const ChooseExistingTokenSecondaryLabel = styled.div`
  font-size: 12px;
  font-weight: 300;
  ${SingleLineUnspecifiedWidth};
  color: var(--color-text30);
`;

export const FreeLabel = styled.div`
  color: #ffaf02;
  font-size: 12px;
  font-weight: 300;
`;

const DescLabel = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: var(--color-text00);
  text-align: center;
`;

export default CircleTokenCreateIntroducePage;
