import { matchPath, Navigate, Params, RouteObject } from "react-router-dom";
import { AuthHomePage } from "./pages/auth/AuthHomePage";
import { EmailLogInPage } from "./pages/auth/EmailLogInPage";
import React, { PropsWithChildren, ReactElement, useMemo } from "react";
import { MoonCardWidget } from "./pages/ads/MoonCardWidget";
import { DebugHomePage } from "./pages/debug/DebugHomePage";
import HomeTabPage from "./pages/home/HomeTabPage";
import HomeMyCirclesPage from "./pages/circle/HomeMyCirclesPage";
import CircleHomePage from "./pages/circle/CircleHomePage";
import CircleSettingsPage from "./pages/circle/settings/CircleSettingsPage";
import CircleAnnouncementsPage from "./pages/circle/CircleAnnouncementsPage";
import CircleAnnouncementEditPage from "./pages/circle/compose/CircleAnnouncementEditPage";
import HomeMyChatsPage from "./pages/home/HomeMyChatsPage";
import SearchPage from "./pages/search/SearchPage";
import {
  HomeDiscoverHeadPage,
  HomeDiscoverPage,
} from "./pages/home/HomeDiscoverPage";
import { PhoneNumberLogInPage } from "./pages/auth/PhoneNumberLogInPage";
import { SignUpOrLogInSelectionPage } from "./pages/auth/SignUpOrLogInSelectionPage";
import { FeedBackPage } from "./pages/auth/FeedBackPage";
import { VerifyYourAccountPage } from "./pages/auth/VerifyYourAccountPage";
import CircleAdminPortalPage from "./pages/circle/settings/CircleAdminPortalPage";
import {
  ExploreCirclesHeadPage,
  ExploreCirclesPage,
} from "./pages/circle/ExploreCirclesPage";
import { VerificationCodePage } from "./pages/auth/VerificationCodePage";
import CircleSideMenuPage from "./pages/circle/CircleSideMenuPage";
import ViewPagerDemoPage from "./pages/circle/ViewPagerDemoPage";
import CircleAppearancePage from "./pages/circle/settings/CircleAppearancePage";
import CircleBlockedContentsPage from "./pages/circle/settings/CircleBlockedContentsPage";
import CircleBlockedObjectsPage from "./pages/circle/settings/CircleBlockedObjectsPage";
import CircleDataCenterPage from "./pages/circle/settings/CircleDataCenterPage";
import CircleFolderSettingsPage from "./pages/circle/settings/CircleFolderSettingsPage";
import CircleHomeLayoutPage from "./pages/circle/settings/CircleHomeLayoutPage";
import CircleInfoSettingsPage from "./pages/circle/settings/CircleInfoSettingsPage";
import CircleJoinRequestsPage from "./pages/circle/settings/CircleJoinRequestsPage";
import CircleManageCoAdminsPage from "./pages/circle/settings/CircleManageCoAdminsPage";
import CircleOperationRecordsPage from "./pages/circle/settings/CircleOperationRecordsPage";
import { CirclePermissionAndPrivacyPage } from "./pages/circle/settings/CirclePermissionAndPrivacyPage";
import { SetPasswordPage } from "./pages/auth/SetPasswordPage";
import { SettingsPage } from "./pages/settings/SettingsPage";
import { AccountSettingsPage } from "./pages/settings/accountSettings/AccountSettingsPage";
import { SecurityCenterPage } from "./pages/settings/securityCenter/SecurityCenterPage";
import { SafetyAndPrivacyPage } from "./pages/settings/safetyAndPrivacy/SafetyAndPrivacyPage";
import CirclePostsPage from "./pages/circle/CirclePostsPage";
import CircleChatsPage from "./pages/circle/CircleChatsPage";
import CircleExplorePage from "./pages/circle/CircleExplorePage";
import CircleFolderPage from "./pages/circle/CircleFolderPage";
import CircleMemberTitlesPage from "./pages/circle/settings/CircleMemberTitlesPage";
import {
  EditCircleNamePage,
  EditCircleSocialIdPage,
  EditCircleTaglinePage,
  EditCircleWelcomeMessagePage,
} from "./pages/circle/compose/EditCirclePage";
import CircleNotificationSettingsPage from "./pages/circle/settings/CircleNotificationSettingsPage";
import BackgroundPickerPage, {
  BackgroudApplyPage,
} from "./pages/common/BackgroundPickerPage";
import CircleVisibleTitlesPage from "./pages/circle/settings/CircleVisibleTitlesPage";
import { VerifyBeforeContinuePage } from "./pages/settings/securityCenter/VerifyBeforeContinuePage";
import { AlertCenterPage } from "./pages/alertCenter/AlertCenterPage";
import UserProfilePage from "./pages/user/UserProfilePage";
import { PostDetailPage } from "./pages/post/PostDetailPage";
import SocialViewPagerPage from "./pages/user/SocialViewPagerPage";
import { UserCenterPage } from "./pages/user/UserCenterPage";
import { InvitationCodePage } from "./pages/auth/InvitationCodePage";
import { SelectRegionPage } from "./pages/auth/SelectRegionPage";
import { SelectGenderPage } from "./pages/auth/SelectGenderPage";
import { SelectBirthdayPage } from "./pages/auth/SelectBirthdayPage";
import EditRemarkPage from "./pages/user/EditRemarkPage";
import { UploadProfileImagePage } from "./pages/auth/UploadProfileImagePage";
import ChatInfoPage from "./pages/chat/info/ChatInfoPage";
import { VerifyEmailOrPhoneSignUpPage } from "./pages/auth/VerifyEmailOrPhoneSignUpPage";
import { EditSocialIdPage } from "./pages/settings/accountSettings/EditSocialIdPage";
import { ChatroomAnnouncementsPage } from "./pages/chat/list/ChatroomAnnouncementsPage";
import { DeleteAccountDeclarationPage } from "./pages/settings/accountSettings/DeleteAccountDeclarationPage";
import { DeleteAccountConfirmPage } from "./pages/settings/accountSettings/DeleteAccountConfirmPage";
import { AssociateEmailOrPhonePage } from "./pages/settings/accountSettings/AssociateEmailOrPhonePage";
import { EnterEmailOrPhoneNumberConfirmPage } from "./pages/settings/accountSettings/EnterEmailOrPhoneNumberConfirmPage";
import RecentVisitorsPage from "./pages/user/RecentVisitorsPage";
import ChatBubblePicker from "./pages/common/ChatBubblePicker";
import CircleRoomSettingsPage from "./pages/circle/settings/CircleRoomSettingsPage";
import ChatSettingsPage from "./pages/chat/settings/ChatSettingsPage";
import { GlobalAnnouncementsPage } from "./pages/post/GlobalAnnouncementsPage";
import CircleMembershipsPage from "./pages/user/CircleMembershipsPage";
import BlockedUsersPage from "./pages/user/BlockedUsersPage";
import ChatSettingsInfoPage from "./pages/chat/settings/ChatSettingsInfoPage";
import ChatSettingsAppearancePage from "./pages/chat/settings/ChatSettingsAppearancePage";
import ChatSettingsPermissionsPage, {
  ChatWhoCanTalkPage,
} from "./pages/chat/settings/ChatSettingsPermissionsPage";
import ChatSettingsPrivacyPage from "./pages/chat/settings/ChatSettingsPrivacyPage";
import UserPickerPage from "./pages/user/UserPickerPage";
import EditMyPublicProfilePage from "./pages/user/EditMyPublicProfilePage";
import EditNicknamePage from "./pages/user/EditNicknamePage";
import { SearchJoinedChatsPage } from "./pages/search/SearchJoinedChatsPage";
import EditStatusPage from "./pages/user/EditStatusPage";
import { TagsPage } from "./pages/tags/TagsPage";
import { CircleJoinQuestionPage } from "./pages/circle/CircleJoinQuestionPage";
import CreateChatPage from "./pages/chat/compose/CreateChatPage";
import { CircleJoinManageRequestPage } from "./pages/circle/settings/CircleJoinManageRequestPage";
import { EnterNicknamePage } from "./pages/auth/EnterNicknamePage";
import { PickInterestsPage } from "./pages/auth/PickInterestsPage";
import WallListPage from "./pages/user/WallListPage";
import WalletHomePage from "./pages/wallet/WalletHomePage";
import SendTokenPage from "./pages/wallet/send/SendTokenPage";
import WalletAccountPage from "./pages/wallet/WalletAccountPage";
import UserBioPage from "./pages/user/UserBioPage";
import SendTokenSummaryPage from "./pages/wallet/send/SendTokenSummaryPage";
import OtherSocialListPage from "./pages/user/OtherSocialListPage";
import RecaptchaPage from "./pages/recaptcha/RecaptchaPage";
import OrderDetailPage, {
  OrderType,
} from "./pages/wallet/order/OrderDetailPage";
import { NotFoundPage } from "./pages/error/NotFoundPage";
import { debugPagesRoute } from "./pages/debug/debugPagesRoute";
import { useMyUid } from "./service/AuthSessionService";
import { SwapPage } from "./pages/wallet/swap/SwapPage";
import { SearchMyFriendPage } from "./pages/search/SearchMyFriendPage";
import { usePageSpec } from "./pages/common/usePageSpec";
import MigratePage from "./pages/migrate/MigratePage";
import { GiftOrderListPage } from "./pages/wallet/order/GiftOrderListPage";
import SelectTokenPage from "./pages/wallet/send/SelectTokenPage";
import { GoToAdminPortalModalPage } from "./pages/circle/GoToAdminPortalModalPage";
import ShopAccountPage from "./pages/wallet/ShopAccountPage";
import SendGiftPage from "./pages/wallet/giftbox/SendGiftPage";
import NFTPickerPage from "./pages/wallet/NFTPickerPage";
import CircleDetailInfoPage from "./pages/circle/CircleDetailInfoPage";
import TokenDetailPage from "./pages/wallet/account/TokenDetailPage";
import { MarketplaceOrderListPage } from "./pages/wallet/order/MarketplaceOrderListPage";
import { OtherOrderListPage } from "./pages/wallet/order/OtherOrderListPage";
import CircleStatsPage from "./pages/circle/CircleStatsPage";
import ShopTransferPage from "./pages/wallet/send/ShopTransferPage";
import ShopTransferSummaryPage from "./pages/wallet/send/ShopTransferSummaryPage";
import ReceiveAssetsPage from "./pages/wallet/ReceiveAssetsPage";
import { CircleTokenHoldersPage } from "./pages/circle/CircleTokenHoldersPage";
import { SalesHistoryPage } from "./pages/wallet/order/SalesHistoryPage";
import { CreatorFeeHistoryPage } from "./pages/wallet/order/CreatorFeeHistoryPage";
import MatchPassesPage from "./pages/wallet/MatchPassesPage";
import LaunchTokenSummaryPage from "./pages/circle/compose/LaunchTokenSummaryPage";
import TokenDesignPage from "./pages/circle/TokenDesignPage";
import CircleTokenCreateIntroducePage from "./pages/circle/CircleTokenCreateIntroducePage";
import CircleTokenInfoPage from "./pages/circle/CircleTokenInfoPage";
import { TransactionHistoryPage } from "./pages/wallet/order/TransactionHistoryPage";
import CircleCardPickerPage from "./pages/circle/card/CircleCardPickerPage";
import CircleCardPurchasePage from "./pages/circle/card/CircleCardPurchasePage";
import CircleCardRedeemPage from "./pages/circle/card/CircleCardRedeemPage";
import { TransactionDetailPage } from "./pages/wallet/order/TransactionDetailPage";
import AssetDetailPage from "./pages/wallet/account/AssetDetailPage";
import AdminEarningTransferPage from "./pages/circle/transactions/AdminEarningTransferPage";
import CircleFolderPermissionPage from "./pages/circle/folder/CircleFolderPermissionPage";
import { CircleMembershipLevelPicker } from "./pages/circle/membership/MembershipLevelPicker";
import AdminTransferSummaryPage from "./pages/circle/transactions/AdminTransferSummaryPage";
import { CircleMemberPicker } from "./pages/circle/pickers/CircleMemberPicker";
import { CircleMemberTitlePicker } from "./pages/circle/pickers/CircleMemberTitlePicker";
import { RouterLocation } from "./appshell/RouterLocation";
import AdminEarningsPage from "./pages/circle/AdminEarningsPage";
import { RecommendUsersPage } from "./pages/home/RecommendUsersPage";
import { CircleCardStakePage } from "./pages/circle/card/CircleCardStakePage";
import { StakeInfoModal } from "./pages/circle/card/StakeInfoModal";
import { NFTCircleCardDisplayPage } from "./pages/nft/pages/NFTCircleCardDisplayPage";
import { NFTCircleCardInfoPage } from "./pages/nft/pages/NFTCircleCardInfoPage";
import { CircleCardEarnHistoryPage } from "./pages/circle/card/CircleCardEarnHistoryPage";
import { TopUpPage } from "./pages/wallet/topup/TopUpPage";
import { MyCardPage } from "./pages/circle/card/MyCardPage";
import { CircleMembersPage } from "./pages/circle/members/CircleMembersPage";
import { CircleTeamPage } from "./pages/circle/members/CircleTeamPage";
import { CircleActiveMembersPage } from "./pages/circle/members/CircleActiveMembersPage";
import { CircleLevelMembersPage } from "./pages/circle/members/CircleLevelMembersPage";
import { CircleCardBottomSheetPage } from "./pages/circle/card/CircleCardBottomSheetPage";
import { ThirdPartAccountConfirmPasswordPage } from "./pages/auth/ThirdPartAccountConfirmPasswordPage";
import { GiftOrderClaimModalPage } from "./pages/wallet/order/GiftOrderClaimModalPage";
import { urlAppendQuery, urlParseQuery, UrlQuery } from "./utils/UrlUtil";
import {
  NotYetImplModalPage,
  NotYetImplPage,
} from "./pages/common/NotYetImplPage";
import { VisitLink } from "./pages/recaptcha/VisitLink";
import { NFTDisplayPage } from "./pages/nft/pages/NFTDisplayPage";
import { useWideLayout, WideLayout } from "./components/WideAppShellLayout";
import { SharePage } from "./pages/share/SharePage";
import { MembershipLevelsPage } from "./pages/circle/membership/MembershipLevelsPage";
import { AuxPage } from "./components/AuxPage";
import { ComingSoonPage } from "./pages/common/ComingSoonPage";
import MarkdownHelpPage from "./pages/help/MarkdownHelpPage";
import { OfficialUserPage } from "./pages/user/OfficialUserPage";
import { NFTInfoPage } from "./pages/nft/pages/NFTInfoPage";
import { useOptionalCircleSWR } from "./pages/circle/useCircleSWR";
import { CircleJoinPermission } from "./pages/circle/JoinCircleRequest";
import { CircleMemberStatus } from "./proto/CircleMemberStatus";
import { assert } from "./utils/asserts";
import FlagPage from "./pages/common/FlagPage";
import CreateCircleBasicInfoPage from "./pages/circle/compose/CreateCircleBasicInfoPage";
import CreateCircleAppearancePage from "./pages/circle/compose/CreateCircleAppearancePage";
import CreateCirclePermissionPage from "./pages/circle/compose/CreateCirclePermissionPage";
import LegacyTokenPage from "./pages/wallet/account/LegacyTokenPage";
import { TopUpThirdPartyMethodPickerPage } from "./pages/wallet/topup/TopUpThirdPartyMethodPickerPage";
import { SolServicesPage } from "./pages/wallet/topup/SolServicesPage";
import { MorePaymentModal } from "./pages/wallet/topup/MorePaymentModal";
import { CreateNewPasswordPage } from "./pages/settings/accountSettings/CreateNewPasswordPage";
import { ContentEditorPage } from "./pages/post/ContentEditorPage";
import { CircleTokenOverviewPage } from "./pages/circle/token/CircleTokenOverviewPage";
import { CircleCardOverviewPage } from "./pages/circle/token/CircleCardOverviewPage";
import { CircleTokenPickerPage } from "./pages/circle/token/CircleTokenPickerPage";
import { EditCircleCardPage } from "./pages/circle/token/EditCircleCardPage";
import { EditTagsPage } from "./pages/circle/compose/EditTagsPage";
import { PurchaseCardModal } from "./pages/circle/card/PurchaseCardModal";
import { AdminCardStakePage } from "./pages/circle/card/AdminCardStakePage";
import ChatroomPage from "./pages/chat/detail/ChatroomPage";

function RedirectOnWide(props: PropsWithChildren<{ redirectUrl: string }>) {
  const pageSpec = usePageSpec();
  return pageSpec === "wide" ? (
    <Navigate to={props.redirectUrl} replace={true} />
  ) : (
    <>{props.children}</>
  );
}

function WideLayoutConfigNYI() {
  const myUid = useMyUid();
  useWideLayout(myUid ? "threeColumn" : "fullWidth");
  return <></>;
}

type DeepLinkDesc = {
  fromWebLink:
    | ((params: Params, query: UrlQuery) => string | undefined)
    | undefined;
  pattern: string;
  toWebLink: (params: Params, query: UrlQuery) => string;
};

function isDeepLinkDesc(
  deepLink: DeepLinkDesc | string | true,
): deepLink is DeepLinkDesc {
  return typeof deepLink === "object" && "pattern" in deepLink;
}

type Config = {
  authRequirement?: "loggedIn" | "notLoggedIn" | "none"; // default to "loggedIn"
  wideLayout?: WideLayout | ReactElement; // undefined as WideLayoutConfigDefault
  deepLink?: DeepLinkDesc | string | true;
};

const configs: (RouteObject & Config)[] = [
  {
    path: "/s/:shareType/:shareId",
    element: <SharePage />,
    authRequirement: "none",
    wideLayout: <WideLayoutConfigNYI />,
  },
  { path: "/feedback", element: <FeedBackPage />, authRequirement: "none" },
  {
    path: "/change-password",
    element: <SetPasswordPage />,
    authRequirement: "none",
  },
  {
    path: "/verify-account",
    element: <VerifyYourAccountPage />,
    authRequirement: "none",
  },
  {
    path: "/verification-code",
    element: <VerificationCodePage />,
    authRequirement: "none",
  },
  {
    path: "/create-password",
    element: <SetPasswordPage />,
    authRequirement: "none",
  },
  {
    path: "/create-new-password",
    element: <CreateNewPasswordPage />, // only use while hasPassword !== NumericBoolean.True
  },
  {
    path: "/migrate",
    element: <MigratePage />,
    authRequirement: "none",
    wideLayout: "fullWidth",
  },

  { path: "/*", element: <NotFoundPage />, authRequirement: "none" },

  {
    path: "/demo-nyi",
    element: <NotYetImplPage />,
    authRequirement: "none",
    wideLayout: <WideLayoutConfigNYI />,
  },
  {
    path: "/downloads",
    element: <NotYetImplPage />,
    authRequirement: "none",
    wideLayout: <WideLayoutConfigNYI />,
  },
  {
    path: "/nyi",
    element: <NotYetImplModalPage />,
    authRequirement: "none",
  },
  ...debugPagesRoute.map((r) => {
    return {
      ...r,
      authRequirement: "none" as const,
    };
  }),
  {
    path: "/os-native-result-hook-demo",
    element: <NotYetImplPage />,
    deepLink: true,
  },

  {
    path: "/debug-home",
    element: <DebugHomePage />,
    authRequirement: "none",
  },

  // from z-web
  ...[
    "terms-of-service",
    "privacy-policy",
    "help-center",
    "copyright-policy",
    "user-guidelines",
    "suicide-prevention-resources",
  ].map((d) => {
    return {
      path: "/" + d,
      element: <MarkdownHelpPage name={d} />,
      wideLayout: "fullWidth" as const,
      authRequirement: "none" as const,
    };
  }),

  {
    path: "/identify",
    element: <RecaptchaPage />,
    wideLayout: "fullWidth",
    authRequirement: "none",
  },
  {
    path: "/verify-link",
    element: <VisitLink />,
    wideLayout: "fullWidth",
    authRequirement: "none",
  },
  // auth
  {
    path: "/auth-home",
    element: <AuthHomePage />,
    authRequirement: "notLoggedIn",
  },
  {
    path: "/email-log-in",
    element: <EmailLogInPage />,
    authRequirement: "notLoggedIn",
  },
  {
    path: "/phone-number-log-in",
    element: <PhoneNumberLogInPage />,
    authRequirement: "notLoggedIn",
  },
  {
    path: "/sign-log-selection",
    element: <SignUpOrLogInSelectionPage />,
    authRequirement: "notLoggedIn",
  },
  {
    path: "/invitation-code",
    element: <InvitationCodePage />,
    authRequirement: "notLoggedIn",
  },
  {
    path: "/select-region",
    element: <SelectRegionPage />,
    authRequirement: "notLoggedIn",
  },
  {
    path: "/select-gender",
    element: <SelectGenderPage />,
    authRequirement: "notLoggedIn",
  },
  {
    path: "/select-birthday",
    element: <SelectBirthdayPage />,
    authRequirement: "notLoggedIn",
  },
  {
    path: "/email-sign-up",
    element: <VerifyEmailOrPhoneSignUpPage />,
    authRequirement: "notLoggedIn",
  },
  {
    path: "/phone-sign-up",
    element: <VerifyEmailOrPhoneSignUpPage />,
    authRequirement: "notLoggedIn",
  },
  {
    path: "/upload-profile-image",
    element: <UploadProfileImagePage />,
    authRequirement: "notLoggedIn",
  },
  {
    path: "/enter-nickname",
    element: <EnterNicknamePage />,
    authRequirement: "notLoggedIn",
  },

  // logged in
  { path: "/", element: <HomeTabPage /> },
  { path: "/home", element: <HomeTabPage /> },
  { path: "/home-ads", element: <MoonCardWidget /> },

  { path: "/user-center", element: <UserCenterPage /> },
  { path: "/home-discover", element: <HomeDiscoverPage /> },
  { path: "/my-chats", element: <HomeMyChatsPage /> },

  { path: "/home-discover-head", element: <HomeDiscoverHeadPage /> },

  { path: "/explore-circles", element: <ExploreCirclesPage /> },
  { path: "/explore-circles-head", element: <ExploreCirclesHeadPage /> },

  { path: "/search", element: <SearchPage /> },
  { path: "/view-pager-demo", element: <ViewPagerDemoPage /> },

  { path: "/chat/:threadId", element: <ChatroomPage />, deepLink: true },
  { path: "/chat-info/:threadId", element: <ChatInfoPage /> },
  {
    path: "/chat/:threadId/announcement",
    element: <ChatroomAnnouncementsPage />,
  },
  {
    path: "/chat-compose/:parentCircleId",
    element: <CreateChatPage />,
    deepLink: true,
  },
  {
    path: "/nft/create",
    element: <NotYetImplPage />,
    deepLink: true,
  },
  {
    path: "/chat/:threadId/settings",
    element: <ChatSettingsPage />,
    deepLink: {
      fromWebLink: (params, query) => `chat-settings/${params["threadId"]}`,
      pattern: "/chat-settings/:threadId",
      toWebLink: (params, query) => `chat/${params["threadId"]}`,
    },
  },

  {
    path: "/chat/:threadId/settings/info",
    element: <ChatSettingsInfoPage />,
  },
  {
    path: "/chat/:threadId/settings/appearance",
    element: <ChatSettingsAppearancePage />,
  },
  {
    path: "/chat/:threadId/settings/permissions",
    element: <ChatSettingsPermissionsPage />,
  },
  {
    path: "/chat/:threadId/settings/permissions/who-can-talk",
    element: <ChatWhoCanTalkPage />,
  },
  {
    path: "/chat/:threadId/settings/privacy",
    element: <ChatSettingsPrivacyPage />,
  },
  { path: "/my-circles", element: <HomeMyCirclesPage /> },
  { path: "/background-picker", element: <BackgroundPickerPage /> },
  { path: "/background-apply", element: <BackgroudApplyPage /> },
  {
    path: "/create-circle-basic",
    element: <CreateCircleBasicInfoPage />,
  },
  {
    path: "/create-circle-appearance",
    element: <CreateCircleAppearancePage />,
  },
  {
    path: "/create-circle-permission",
    element: <CreateCirclePermissionPage />,
  },
  {
    path: "/circle/:circleId",
    element: <CircleHomePage />,
    deepLink: true,
  },

  { path: "/edit-tags/:tagMaxCount", element: <EditTagsPage /> },

  { path: "/circle/:circleId/question", element: <CircleJoinQuestionPage /> },
  {
    path: "/circle/:circleId/folder",
    element: <CircleFolderPage />,
    deepLink: {
      fromWebLink: (params, query) => {
        if (query) {
          return `circle/${params["circleId"]}/folder/${query["folderId"]}`;
        } else {
          return undefined;
        }
      },
      pattern: "circle/:circleId/folder/:folderId",
      toWebLink: (params, query) =>
        urlAppendQuery(`circle/${params["circleId"]}/folder`, {
          folderId: params["folderId"],
        }),
    },
  },
  {
    path: "/circle/:circleId/explore",
    element: <CircleExplorePage />,
  },
  { path: "/circle/:circleId/posts", element: <CirclePostsPage /> },
  { path: "/circle/:circleId/chats", element: <CircleChatsPage /> },

  { path: "/circle/:circleId/menu", element: <CircleSideMenuPage /> },
  {
    path: "/circle/:circleId/data-center",
    element: <CircleDataCenterPage />,
  },
  {
    path: "/circle/:circleId/home-layout",
    element: <CircleHomeLayoutPage />,
  },
  { path: "/circle/:circleId/appearance", element: <CircleAppearancePage /> },
  {
    path: "/circle/:circleId/permission-and-privacy",
    element: <CirclePermissionAndPrivacyPage />,
  },
  {
    path: "/circle/:circleId/operation-records",
    element: <CircleOperationRecordsPage />,
  },
  {
    path: "/circle/:circleId/co-admins",
    element: <CircleManageCoAdminsPage />,
  },
  {
    path: "/circle/:circleId/join-requests",
    element: <CircleJoinRequestsPage />,
  },
  {
    path: "/circle/:circleId/join-request/:requestId",
    element: <CircleJoinManageRequestPage />,
  },
  {
    path: "/circle/:circleId/info-settings",
    element: <CircleInfoSettingsPage />,
  },
  {
    path: "/circle/:circleId/info-settings/edit-name",
    element: <EditCircleNamePage />,
  },
  {
    path: "/circle/:circleId/info-settings/edit-tagline",
    element: <EditCircleTaglinePage />,
  },
  {
    path: "/circle/:circleId/info-settings/edit-welcome-message",
    element: <EditCircleWelcomeMessagePage />,
  },
  {
    path: "/circle/:circleId/info-settings/edit-social-id",
    element: <EditCircleSocialIdPage />,
  },
  {
    path: "/circle/:circleId/notification-settings",
    element: <CircleNotificationSettingsPage />,
  },
  {
    path: "/circle/:circleId/folder-settings",
    element: <CircleFolderSettingsPage />,
  },
  {
    path: "/circle/:circleId/folder/:folderId/settings/permission",
    element: <CircleFolderPermissionPage />,
  },
  {
    path: "/circle/:circleId/room-settings",
    element: <CircleRoomSettingsPage />,
  },
  {
    path: "/circle/:circleId/blocked-contents",
    element: <CircleBlockedContentsPage />,
  },
  {
    path: "/circle/:circleId/member-titles",
    element: <CircleMemberTitlesPage />,
    deepLink: true,
  },
  {
    path: "/circle/:circleId/card-picker",
    element: <CircleCardPickerPage />,
  },
  {
    path: "/circle/:circleId/card/:nftId/purchase",
    element: <CircleCardPurchasePage />,
  },
  {
    path: "/circle/:circleId/card/:nftId/stake",
    element: <CircleCardStakePage />,
  },
  {
    path: "/circle/:circleId/card/:nftId/admin-card-stake",
    element: <AdminCardStakePage />,
  },
  {
    path: "/circle/:circleId/stake-info",
    element: <StakeInfoModal />,
  },
  {
    path: "/circle/:circleId/purchase-info",
    element: <PurchaseCardModal />,
  },
  {
    path: "/circle/:circleId/visible-titles",
    element: <CircleVisibleTitlesPage />,
  },
  {
    path: "/circle/:circleId/blocked-objects",
    element: <CircleBlockedObjectsPage />,
  },
  {
    path: "/circle/:circleId/setting",
    element: <CircleSettingsPage />,
  },
  {
    path: "/circle/:circleId/admin-portal",
    element: <CircleAdminPortalPage />,
    deepLink: true,
  },
  {
    path: "/circle/:circleId/announcement",
    element: <CircleAnnouncementsPage />,
  },
  {
    path: "/circle/:circleId/announcement/:announcementId",
    element: <CircleAnnouncementEditPage />,
  },

  {
    path: "/wallet",
    element: <WalletHomePage />,
  },
  {
    path: "/wallet/:walletAccountId",
    element: <WalletAccountPage />,
  },
  {
    path: "/wallet/:walletAccountId/select-token",
    element: <SelectTokenPage />,
  },
  {
    path: "/wallet/:walletAccountId/assetAccount/:assetAccountId/send-gift",
    element: <SendGiftPage />,
  },
  {
    path: "/wallet/:walletAccountId/assetAccount/:assetAccountId/send-token",
    element: <SendTokenPage />,
  },
  {
    path: "/wallet/:walletAccountId/nft-picker",
    element: <NFTPickerPage />,
  },
  {
    path: "/wallet/:walletAccountId/assetAccount/:assetAccountId/send-token/summary",
    element: <SendTokenSummaryPage />,
  },
  {
    path: "/wallet/:walletAccountId/assetAccount/:assetAccountId/swap",
    element: <SwapPage />,
  },
  {
    path: "/wallet/:walletAccountId/legacy-tokens",
    element: <LegacyTokenPage />,
  },
  {
    path: `/wallet/order/:orderId`,
    element: <OrderDetailPage />,
    deepLink: {
      fromWebLink: undefined,
      pattern: "asset-order/:orderId",
      toWebLink: (params, query) =>
        urlAppendQuery(`wallet/order/${params["orderId"]}`, {
          orderType: OrderType.OtherOrder,
        }),
    },
  },
  {
    path: "/settings",
    element: (
      <RedirectOnWide redirectUrl={"/account-settings"}>
        <SettingsPage />
      </RedirectOnWide>
    ),
  },
  {
    path: "/aux-settings",
    element: <SettingsPage />,
  },
  {
    path: "/account-settings",
    element: <AccountSettingsPage />,
  },
  {
    path: "/security-center",
    element: <SecurityCenterPage />,
  },
  {
    path: "/safety-privacy",
    element: <SafetyAndPrivacyPage />,
  },
  {
    path: "/verify-before-continue",
    element: <VerifyBeforeContinuePage />,
  },
  {
    path: "/alert-center",
    element: <AlertCenterPage />,
  },
  {
    path: "/user/:uid",
    element: <UserProfilePage />,
    deepLink: true,
  },
  {
    path: "/user-official",
    element: <OfficialUserPage />,
  },
  {
    path: "/post/:postId",
    element: <PostDetailPage />,
    deepLink: {
      fromWebLink: (params, query) =>
        urlAppendQuery(`blog/${params["postId"]}`, query),
      pattern: "blog/:blogId",
      toWebLink: (params, query) =>
        urlAppendQuery(`post/${params["blogId"]}`, query),
    },
  },
  {
    path: "/social",
    element: <SocialViewPagerPage />,
  },
  {
    path: "/remark",
    element: <EditRemarkPage />,
  },
  {
    path: "/edit-social-id",
    element: <EditSocialIdPage />,
  },
  {
    path: "/edit-username",
    element: <EditNicknamePage />,
  },
  {
    path: "/delete-account-declaration",
    element: <DeleteAccountDeclarationPage />,
  },
  {
    path: "/delete-account-confirm",
    element: <DeleteAccountConfirmPage />,
  },
  {
    path: "/associate-email",
    element: <AssociateEmailOrPhonePage />,
  },
  {
    path: "/associate-phone",
    element: <AssociateEmailOrPhonePage />,
  },
  {
    path: "/update-email-confirm",
    element: <EnterEmailOrPhoneNumberConfirmPage />,
  },
  {
    path: "/update-phone-number-confirm",
    element: <EnterEmailOrPhoneNumberConfirmPage />,
  },
  {
    path: "/recent-visitor/:uid",
    element: <RecentVisitorsPage />,
  },
  {
    path: "/chat-bubble-picker",
    element: <ChatBubblePicker />,
  },
  {
    path: "/circle-memberships/:uid",
    element: <CircleMembershipsPage />,
  },
  {
    path: "/global-announcements",
    element: <GlobalAnnouncementsPage />,
  },
  {
    path: "/blocked-users",
    element: <BlockedUsersPage />,
  },
  {
    path: "/users-picker",
    element: <UserPickerPage />,
  },
  {
    path: "/edit-public-profile",
    element: <EditMyPublicProfilePage />,
  },
  {
    path: "/search-joined-chats",
    element: <SearchJoinedChatsPage />,
  },
  {
    path: "/edit-status",
    element: <EditStatusPage />,
  },
  {
    path: "/tags/:tagId",
    element: <TagsPage />,
    deepLink: {
      toWebLink: (params, query) => `tags/${params["tagId"]}`,
      pattern: "/tag/:tagId",
      fromWebLink: (params) => `tag/${params["tagId"]}`,
    },
  },
  {
    path: "/wall/:uid",
    element: <WallListPage />,
  },
  { path: "/pick-interests", element: <PickInterestsPage /> },
  {
    path: "/user-bio/:uid",
    element: <UserBioPage />,
  },
  {
    path: "/users/:uid",
    element: <OtherSocialListPage />,
  },
  {
    path: "/search-my-friends",
    element: <SearchMyFriendPage />,
  },
  {
    path: "/wallet/gifting-history",
    element: <GiftOrderListPage />,
  },
  {
    path: "/circle/:circleId/go-admin-portal",
    element: <GoToAdminPortalModalPage />,
  },
  {
    path: "/shop-account",
    element: <ShopAccountPage />,
  },
  {
    path: "/circle/:circleId/detail-info",
    element: <CircleDetailInfoPage />,
  },
  {
    path: "/wallet/marketplace-orders",
    element: <MarketplaceOrderListPage />,
  },
  {
    path: "/wallet/other-orders",
    element: <OtherOrderListPage />,
  },
  {
    path: "/circle/:circleId/circle-stats",
    element: <CircleStatsPage />,
  },
  {
    path: "/circle/:circleId/launch-token-summary",
    element: <LaunchTokenSummaryPage />,
  },
  {
    path: "/wallet/:walletAccountId/token/:currencyType",
    element: <TokenDetailPage />,
  },
  {
    path: "/circle/:circleId/token-holders",
    element: <CircleTokenHoldersPage />,
  },
  {
    path: "/shop-transfer",
    element: <ShopTransferPage />,
  },
  {
    path: "/shop-transfer-summary",
    element: <ShopTransferSummaryPage />,
  },
  {
    path: "/wallet/receive",
    element: <ReceiveAssetsPage />,
  },
  {
    path: "/wallet/sales-history",
    element: <SalesHistoryPage />,
  },
  {
    path: "/wallet/creator-fee-history",
    element: <CreatorFeeHistoryPage />,
  },
  {
    path: "/priority-match-passes",
    element: <MatchPassesPage />,
  },
  {
    path: "/wallet/top-up",
    element: <TopUpPage />,
    deepLink: true,
  },
  {
    path: "/wallet/top-up-sol",
    element: <TopUpPage />,
  },
  {
    path: "/circle/:circleId/token-design",
    element: <TokenDesignPage />,
  },
  {
    path: "/circle/:circleId/token-create-introduce",
    element: <CircleTokenCreateIntroducePage />,
  },
  {
    path: "/circle/:circleId/token-info",
    element: <CircleTokenInfoPage />,
  },
  {
    path: "/nft-circle-card/:cardId/redeem",
    element: <CircleCardRedeemPage />,
  },
  {
    path: "/wallet/:accountId/transaction-history",
    element: <TransactionHistoryPage />,
  },
  {
    path: "/wallet/:accountId/transaction-history/:transactionId",
    element: <TransactionDetailPage />,
  },
  {
    path: "/asset-detail",
    element: <AssetDetailPage />,
  },
  {
    path: "/circle/:circleId/transfer-earning",
    element: <AdminEarningTransferPage />,
  },
  {
    path: "/circle/:circleId/transfer-earning-summary",
    element: <AdminTransferSummaryPage />,
  },
  {
    path: "/circle/:circleId/membership-level-picker",
    element: <CircleMembershipLevelPicker />,
  },
  {
    path: "/circle/:circleId/member-picker",
    element: <CircleMemberPicker />,
  },
  {
    path: "/circle/:circleId/title-picker",
    element: <CircleMemberTitlePicker />,
  },
  {
    path: "/recommend-users",
    element: <RecommendUsersPage />,
  },
  {
    path: "/circle/:circleId/membership-levels-page",
    element: <MembershipLevelsPage />,
  },
  {
    path: "/circle/:circleId/token-overview",
    element: <CircleTokenOverviewPage />,
  },
  {
    path: "/circle/:circleId/card-overview/:cardId",
    element: <CircleCardOverviewPage />,
  },
  {
    path: "/circle/:circleId/card-overview/:cardId/edit-name",
    element: <EditCircleCardPage />,
  },
  {
    path: "/circle/:circleId/token-picker",
    element: <CircleTokenPickerPage />,
  },
  {
    path: "/circle/:circleId/admin-earnings",
    element: <AdminEarningsPage />,
  },
  {
    path: "/nft-circle-card/:cardId",
    element: <NFTCircleCardDisplayPage />,
    deepLink: true,
  },
  {
    path: "/nft-circle-card/:cardId/info",
    element: <NFTCircleCardInfoPage />,
  },
  {
    path: "/nft-circle-card/:cardId/earnings-history",
    element: <CircleCardEarnHistoryPage />,
  },
  {
    path: "/circle/:circleId/my-circle-membership",
    element: <MyCardPage />,
  },
  {
    path: "/circle/:circleId/members",
    element: <CircleMembersPage />,
  },
  {
    path: "/circle/:circleId/team",
    element: <CircleTeamPage />,
  },
  {
    path: "/circle/:circleId/active-members",
    element: <CircleActiveMembersPage />,
  },
  {
    path: "/circle/:circleId/level-members",
    element: <CircleLevelMembersPage />,
  },
  {
    path: "/circle/:circleId/user-card/:uid",
    element: <CircleCardBottomSheetPage />,
  },
  {
    path: "/oauth-confirm-password",
    element: <ThirdPartAccountConfirmPasswordPage />,
  },
  {
    path: "/wallet/:boxId/claim",
    element: <GiftOrderClaimModalPage />,
  },
  {
    path: "/voice-match",
    element: <NotYetImplPage />,
    deepLink: true,
  },
  {
    path: "/text-match",
    element: <NotYetImplPage />,
    deepLink: true,
  },
  {
    path: "/circle/:circleId/manage-my-titles",
    element: <NotYetImplPage />,
    deepLink: true,
  },
  {
    path: "/treasure/:uid",
    element: <NotYetImplPage />,
    deepLink: true,
  },
  {
    path: "/nft/:nftId",
    element: <NFTDisplayPage />,
    deepLink: true,
  },
  {
    path: "/nft/:nftId/info",
    element: <NFTInfoPage />,
    deepLink: true,
  },
  {
    path: "/circle/:circleId/manage-members",
    element: <NotYetImplPage />,
    deepLink: true,
  },
  {
    path: "/circle/:circleId/manage-team",
    element: <NotYetImplPage />,
    deepLink: true,
  },
  {
    path: "/circle/:circleId/qr-code-share",
    element: <NotYetImplModalPage />,
    deepLink: true,
  },
  {
    path: "/circle/:circleId/invite",
    element: <NotYetImplPage />,
    deepLink: true,
  },
  {
    path: "/coming-soon",
    element: <ComingSoonPage />,
    authRequirement: "none",
  },
  {
    path: "/flag/:objectId/:objectType",
    element: <NotYetImplPage />,
    deepLink: true,
  },
  {
    path: "/manage-visible-circle",
    element: <NotYetImplPage />,
    deepLink: true,
  },
  {
    path: "/flag",
    element: <FlagPage />,
  },
  {
    path: "/content-editor",
    element: <ContentEditorPage />,
  },
  {
    path: "/third-party-payment-method-picker",
    element: <TopUpThirdPartyMethodPickerPage />,
  },
  {
    path: "/get-sol",
    element: <SolServicesPage />,
  },
  {
    path: "/more-payment-modal",
    element: <MorePaymentModal />,
  },
];

function WideLayoutConfig(props: { layout: WideLayout }) {
  useWideLayout(props.layout);
  return <></>;
}

function WideLayoutConfigDefault() {
  const myUid = useMyUid();
  useWideLayout(myUid ? "threeColumn" : "twin");
  return <></>;
}

function canAccessCircle(
  circleSWR: ReturnType<typeof useOptionalCircleSWR>[1],
) {
  if (!circleSWR) return false;
  if (!circleSWR.content) return false;

  return (
    circleSWR.content.joinedStatus === CircleMemberStatus.JOINED ||
    circleSWR.content.joinPermission === CircleJoinPermission.OPEN
  );
}

function AddAuxMenuToCircle(
  props: PropsWithChildren<{ config: RouteObject & Config }>,
) {
  const [circleId, circleSWR] = useOptionalCircleSWR();
  const isCirclePage = props.config.path?.startsWith("/circle/:circleId");
  return (
    <>
      {isCirclePage && canAccessCircle(circleSWR) && (
        <AuxPage url={`circle/${circleId}/menu`} position={"start"} />
      )}
      {props.children}
    </>
  );
}

function ConfigRoute(
  props: PropsWithChildren<{ config: RouteObject & Config }>,
) {
  const myUid = useMyUid();

  const wideLayoutElement = useMemo(() => {
    if (props.config.wideLayout === undefined) {
      return <WideLayoutConfigDefault />;
    } else if (typeof props.config.wideLayout === "string") {
      return <WideLayoutConfig layout={props.config.wideLayout} />;
    } else {
      return props.config.wideLayout;
    }
  }, [props.config.wideLayout]);

  const configedChild = (
    <AddAuxMenuToCircle config={props.config}>
      {wideLayoutElement}
      {props.children}
    </AddAuxMenuToCircle>
  );

  if (props.config.authRequirement === "none") {
    return <>{configedChild}</>;
  } else if (
    props.config.authRequirement === "loggedIn" ||
    props.config.authRequirement === undefined
  ) {
    return (
      <>
        {myUid ? configedChild : <Navigate to={"/auth-home"} replace={true} />}
      </>
    );
  } else {
    return <>{!myUid ? configedChild : <Navigate to={"/"} replace={true} />}</>;
  }
}

export const allRoutes = configs.map((c) => {
  assert(
    c.path === undefined || c.path?.startsWith("/"),
    `router path should starts with '/': ${c.path}`,
  );
  return {
    ...c,
    element: (
      <RouterLocation>
        <ConfigRoute config={c}>{c.element}</ConfigRoute>
      </RouterLocation>
    ),
  };
});

const deepLinkConfigs = configs.flatMap((r) => {
  if (r.deepLink && r.path) {
    return [
      {
        path: r.path,
        deepLink: r.deepLink,
      },
    ];
  } else {
    return [];
  }
});

export function webLinkToDeepLink(webLink: string, urlQuery: UrlQuery) {
  for (const entry of deepLinkConfigs) {
    const matched = matchPath(
      {
        path: entry.path,
        caseSensitive: false,
        end: true,
      },
      webLink,
    );

    if (matched) {
      if (entry.deepLink === true) {
        return webLink.slice(1);
      } else if (typeof entry.deepLink === "string") {
        return entry.deepLink;
      } else if (entry.deepLink.fromWebLink) {
        return entry.deepLink.fromWebLink(matched.params, urlQuery);
      }
    }
  }

  return undefined;
}

export function deepLinkToWebLink(deepLinkFull: string) {
  const [deepLink, query] = urlParseQuery(deepLinkFull);
  for (const entry of deepLinkConfigs) {
    if (entry.deepLink === true) {
      const matched = matchPath(
        {
          path: entry.path,
          caseSensitive: false,
          end: true,
        },
        "/" + deepLink,
      );
      if (matched) {
        return deepLink;
      }
    } else if (typeof entry.deepLink === "string") {
      if (entry.deepLink === deepLink) {
        return entry.path;
      }
    } else {
      const matched = matchPath(
        {
          path: entry.deepLink.pattern,
          caseSensitive: false,
          end: true,
        },
        "/" + deepLink,
      );
      if (matched) {
        return entry.deepLink.toWebLink(matched.params, query);
      }
    }
  }

  return undefined;
}
