import { CenterModal, useModalWithItsOwnPage } from "../../components/Modal";
import { useI18n } from "../../hooks/useI18n";
import { PowerMarkdown } from "../../components/PowerMarkdown";
import { RegularButton, StrongButton } from "../../components/Buttons";
import { css } from "styled-components";
import React from "react";
import { useHopper } from "../../hooks/useHopper";
import { useBigIntParam } from "../../hooks/useTypedParam";
import { Image } from "../../components/Image";
import goAdminPortalImage from "../../res/images/go_admin_portal.png";

export function GoToAdminPortalModalPage() {
  const modal = useModalWithItsOwnPage();
  const i18n = useI18n();
  const hopper = useHopper();
  const circleId = useBigIntParam("circleId");
  return (
    <CenterModal modal={modal} mixin={ModalStyle}>
      <Image
        style={{ margin: "30px auto 20px" }}
        src={goAdminPortalImage}
        alt={"goAdminPortalImage"}
        width={246}
        height={123}
      ></Image>
      <PowerMarkdown textStyle={markdown_style}>
        {i18n.clover_go_to_admin_portal_tips_md()}
      </PowerMarkdown>
      <RegularButton
        style={{ marginLeft: 25, marginRight: 25 }}
        onClick={() => {
          modal.close(() => hopper.push(`circle/${circleId}/admin-portal`));
        }}
      >
        {i18n.clover_go_to_admin_portal()}
      </RegularButton>
    </CenterModal>
  );
}

const markdown_style = css`
  color: var(--color-text00);
  font-weight: 500;
  font-size: 14px;
`;

const ModalStyle = css`
  padding: 30px 22px 25px;
`;
