import { useBridgeResultProvider } from "../../hooks/useBridgeResult";
import { Bubble } from "../../proto/Bubble";
import { useBigIntSearchParam } from "../../hooks/useTypedParam";
import { Page } from "../../components/Page";
import { useI18n } from "../../hooks/useI18n";
import styled from "styled-components";
import {
  full_page_width_cell,
  shrink_on_pressed,
  two_line_text,
} from "../../components/CommonStyles";
import GridLayout from "../../components/GridLayout";
import { VStack } from "../../components/VStack";
import { useSWRList } from "../../hooks/swr/useSWRList";
import { useBackend } from "../../service/APIService";
import { getBestRes } from "../../proto/Media";
import { NavMiddle } from "../../components/NavBar";

export const ChatSettingsSectionTitle = styled.div`
  background-color: rgba(255, 255, 255, 0.08);
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  ${full_page_width_cell};
  padding: 3px var(--page-h-inset);
`;

const BubbleContainer = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 100%;
  position: relative;
  border-radius: 6px;
  display: flex;
`;

const BubbleText = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 18px 21px;
  font-size: 23px;

  background-color: blue;
`;

const BubbleName = styled.div`
  ${two_line_text};
  font-size: 12px;
`;

function ColorBubble(props: { isSelected: boolean }) {
  return (
    <VStack style={{ gap: "8px" }} mixin={[shrink_on_pressed]}>
      <BubbleContainer
        style={{
          borderWidth: props.isSelected ? "2px" : "1px",
          borderColor: props.isSelected
            ? "#00d08b"
            : "rgba(255, 255, 255, 0.2)",
        }}
      >
        <BubbleText
          style={{
            backgroundColor: "blue",
            borderRadius: "11px",
          }}
        >
          Hi
        </BubbleText>
      </BubbleContainer>
      <BubbleName>Default</BubbleName>
    </VStack>
  );
}

function NFTBubble(props: {
  bubble: Bubble;
  isSelected: boolean;
  onSelect: () => void;
}) {
  return (
    <VStack
      style={{ gap: "8px" }}
      mixin={[shrink_on_pressed]}
      onClick={props.onSelect}
    >
      <BubbleContainer
        style={{
          borderWidth: props.isSelected ? "2px" : "1px",
          borderColor: props.isSelected
            ? "#00d08b"
            : "rgba(255, 255, 255, 0.2)",
        }}
      >
        <BubbleText
          style={{
            background: `url(${getBestRes(props.bubble.preview!).url})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            padding: "26px 30px",
            color: "transparent",
          }}
        >
          Hi
        </BubbleText>
      </BubbleContainer>
      <BubbleName>{props.bubble.name}</BubbleName>
    </VStack>
  );
}

export default function ChatBubblePicker() {
  const selectedBubbleId = useBigIntSearchParam("bubbleId");

  const i18n = useI18n();
  const backend = useBackend();

  const provider = useBridgeResultProvider<Bubble | string>();

  const bubbleSWR = useSWRList(backend.getNFTBubbles());

  // TODO: color picker
  return (
    <Page pageData={bubbleSWR}>
      <NavMiddle>{i18n.pretty_bubble_chat_bubble_style()}</NavMiddle>
      <ChatSettingsSectionTitle>
        {i18n.easy_ugc_chat_bubbles_from_my_wallet()}
      </ChatSettingsSectionTitle>
      {bubbleSWR.content && bubbleSWR.content.list && (
        <GridLayout
          spanCount={3}
          items={[<ColorBubble isSelected={false} />].concat(
            bubbleSWR.content.list.map((bubble) => (
              <NFTBubble
                bubble={bubble}
                isSelected={false}
                key={bubble.bubbleId}
                onSelect={() => provider(bubble)}
              />
            )),
          )}
        ></GridLayout>
      )}
    </Page>
  );
}
