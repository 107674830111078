import { z } from "zod";
import { Media } from "./Media";
import { zBigInt, zEnum, zStatic } from "../utils/zodUtils";
import { Chat } from "./Chat";
import { Post } from "./Post";
import { CircleWidget } from "./CircleWidget";
import { CircleMemberTitle } from "./CircleMemberTitle";
import { User } from "./User";

export enum FolderPermission {
  Open = 1,
  ReadOnly = 2,
  Hidden = 3,
}

export enum PermissionType {
  Open = 1,
  Restricted = 2,
}

export enum FolderType {
  Menu = 1,
  Generic = 2,
  Explore = 3,
  LatestChat = 4,
  LatestBlog = 5,
  Blog = 6,
  AdvanceBlog = 7,
  Chat = 8,
}

export const ContentPermission = z.object({
  permissionType: zEnum(PermissionType).default(PermissionType.Open),
  allowedMembershipLevels: z.array(z.number()).default([]),
  allowedUids: z.array(zBigInt).default([]),
  allowedMemberTitleIds: z.array(zBigInt).default([]),
});

export type ContentPermission = zStatic<typeof ContentPermission>;

export const CircleFolder = z.object({
  folderId: zBigInt.optional(),
  circleId: zBigInt.optional(),
  objectType: z.number().optional(),
  contentType: z.number().optional(),
  folderType: zEnum(FolderType).optional(),
  folderLayout: z.number().optional(),
  cover: Media.optional(),
  icon: Media.optional(),
  name: z.string().optional(),
  description: z.string().optional(),
  folderWidgets: CircleWidget.array().optional(),
  chat: Chat.optional(),
  blog: Post.optional(),
  permission: zEnum(FolderPermission).optional(),
  contentViewPermission: ContentPermission.optional(),
  contentSubmitPermission: ContentPermission.optional(),
  allowedViewMemberTitleList: z.array(CircleMemberTitle).optional(),
  allowedViewUserList: z.array(User).optional(),
  allowedSubmitMemberTitleList: z.array(CircleMemberTitle).optional(),
  allowedSubmitUserList: z.array(User).optional(),
  requiredMembershipLevel: z.number().optional(),
  isViewable: z.boolean().optional(),
});
export type CircleFolder = zStatic<typeof CircleFolder>;

export enum FolderType {
  MENU = 1,
  GENERIC = 2,
  EXPLORE = 3,
  LATEST_CHAT = 4,
  LATEST_BLOG = 5,
  BLOG = 6,
  ADVANCED_BLOG = 7,
  CHAT = 8,
}

export enum FolderLayoutType {
  DEFAULT = 1,
  GALLERY = 2,
  COMPACT = 3,
  MASONRY = 4,
}
