import { z } from "zod";
import { zStatic } from "../../../utils/zodUtils";
import { TokenProject } from "../../../proto/TokenProject";
import { MiniAssetOrder } from "./MiniAssetOrder";

export const LaunchTokenResult = z.object({
  tokenProject: TokenProject,
});

export type LaunchTokenResult = zStatic<typeof LaunchTokenResult>;
