import { Page } from "../../components/Page";
import { PostListPage, PostListType } from "../post/PostListPage";
import { useI18n } from "../../hooks/useI18n";
import { useHopper } from "../../hooks/useHopper";
import { useSWRArray } from "../../hooks/swr/useSWRArray";
import { HStack, VSpace, VStack } from "../../components/VStack";
import UserHScrollItem from "../../components/UserHScrollItem";
import SectionHeader, {
  SectionHeaderEndIcon,
} from "../../components/SectionHeader";
import { Spacing } from "../../components/Spacing";
import GridLayout from "../../components/GridLayout";
import ChatGridCell from "../../components/ChatGridCell";
import FlexGrid from "../../components/FlexGrid";
import { CircleGridType } from "../circle/CircleGridPage";
import CircleGridCell, {
  CircleGridWithTokenCell,
} from "../circle/CircleGridCell";
import React from "react";
import {
  FullPageWidthCell,
  hPaddingWithPageInset,
} from "../../components/CommonStyles";
import { usePageSpec } from "../common/usePageSpec";
import { useBackend } from "../../service/APIService";
import { subpages } from "../../components/Subpage";
import {
  ViewPagerPage,
  ViewPagerTabLayout,
} from "../../components/ViewPagerPage";
import { AuxPage } from "../../components/AuxPage";
import { NavMiddle } from "../../components/NavBar";
import SearchLayout from "../../components/SearchLayout";
import { SearchSource } from "../search/SearchPage";

function useDiscoverHeadData() {
  const backend = useBackend();

  const nameCardsSWR = useSWRArray(backend.getUserNameCards());

  const hotThreadsSWR = useSWRArray(backend.getHotThreads());

  const circlesSWR = useSWRArray(backend.getCircles(CircleGridType.RECOMMEND));

  return [nameCardsSWR, hotThreadsSWR, circlesSWR] as const;
}

function HomeDiscoverHead(props: {
  swr: ReturnType<typeof useDiscoverHeadData>;
}) {
  const i18n = useI18n();

  const hopper = useHopper();

  const [nameCardsSWR, hotThreadsSWR, circlesSWR] = props.swr;

  const onClickCircle = (circleId: bigint) => {
    hopper.push(`circle/${circleId}`);
  };
  return (
    <>
      <SearchLayout
        onClick={() => {
          hopper.push("search", {
            searchSource: SearchSource.All,
          });
        }}
      />
      <VSpace height={20} />
      <SectionHeader
        title={i18n.recommended()}
        onClickMore={() => hopper.push("recommend-users")}
        endIcon={SectionHeaderEndIcon.Chevron}
      />
      <Spacing height={12} />
      <HStack
        style={{
          overflowX: "scroll",
          scrollbarWidth: "none" as "none",
          msOverflowStyle: "none" as "none",
          paddingInlineStart: 12,
          gap: 10,
          ...FullPageWidthCell,
        }}
      >
        {nameCardsSWR.content &&
          nameCardsSWR.content.map((user) => (
            <UserHScrollItem
              key={user.uid}
              user={user}
              onGoProfile={() => {
                hopper.push(`user/${user.uid}`);
              }}
            />
          ))}
      </HStack>

      <Spacing height={32} />
      <SectionHeader
        title={i18n.live_parties()}
        endIcon={SectionHeaderEndIcon.Chevron}
        onClickMore={() => hopper.modal("nyi")}
      />
      <Spacing height={8} />
      <FlexGrid items={["Free Talk", "Alastor", "Roleplay", "Relationship"]} />
      {hotThreadsSWR.content && (
        <GridLayout
          spanCount={3}
          style={{ paddingTop: 10 }}
          items={hotThreadsSWR.content.map((chat) => (
            <ChatGridCell chat={chat} />
          ))}
        ></GridLayout>
      )}

      <Spacing height={28} />
      <SectionHeader
        title={i18n.explore_circles()}
        endIcon={SectionHeaderEndIcon.Chevron}
        onClickMore={() => {
          hopper.push("explore-circles");
        }}
      />
      {circlesSWR.content && (
        <GridLayout
          style={{ paddingTop: 8 }}
          spanCount={4}
          items={circlesSWR.content.slice(0, 4).map((circle) => (
            <CircleGridWithTokenCell
              circleId={circle.circleId}
              circle={circle}
              key={circle.circleId}
              onClick={() => {
                onClickCircle(circle.circleId);
              }}
            />
          ))}
        />
      )}
    </>
  );
}

export function HomeDiscoverHeadPage() {
  const i18n = useI18n();
  const headData = useDiscoverHeadData();
  return (
    <Page pageData={[...headData]}>
      <NavMiddle>{i18n.the_app_name()}</NavMiddle>
      <HomeDiscoverHead swr={headData} />
    </Page>
  );
}

export function HomeDiscoverPage() {
  const i18n = useI18n();

  const pageSpec = usePageSpec();
  const entries: [PostListType, string][] = [
    [PostListType.RECOMMEND, i18n.for_you()],
    [PostListType.LATEST, i18n.latest()],
    [PostListType.FROMM_MY_CIRCLE, i18n.home_impr_from_my_circles()],
    [PostListType.FOLLOWING, i18n.jun1_text_from_following()],
  ];

  const headData = useDiscoverHeadData();
  return (
    <ViewPagerPage
      collapsingHeaderDisabled={false}
      pageData={[...headData]}
      subpages={subpages(entries, (e) => (
        <PostListPage type={e} />
      ))}
    >
      <NavMiddle>{i18n.discover()}</NavMiddle>
      {pageSpec !== "wide" && (
        <>
          <VStack style={{ ...hPaddingWithPageInset }}>
            <HomeDiscoverHead swr={headData} />
          </VStack>
          <Spacing height={24} />
        </>
      )}
      <ViewPagerTabLayout />

      <AuxPage url={"home-discover-head"} />
    </ViewPagerPage>
  );
}
