import { Page } from "../../../components/Page";
import iconNft from "../../../res/images/ic_nft.png";
import { HStack } from "../../../components/VStack";
import styled from "styled-components";
import goForwardIcon from "../../../res/images/go_forward.svg";
import { Image } from "../../../components/Image";
import { useWalletSWR } from "../useWallet";
import NFTCell from "./NFTCell";
import { DefaultEmptyView } from "../../../components/LoadStateView";
import { useI18n } from "../../../hooks/useI18n";
import { AccountType } from "../../../proto/AccountType";
import { useHopper } from "../../../hooks/useHopper";
import { useMyUid } from "../../../service/AuthSessionService";
import { useSWRArray } from "../../../hooks/swr/useSWRArray";
import { useBackend } from "../../../service/APIService";
import { useRegularAccount } from "../useRegularAccount";

function AccountCollectiblesPage() {
  const i18n = useI18n();
  const hopper = useHopper();
  const backend = useBackend();
  const [wallet, walletSWR] = useWalletSWR();
  const regularAccount = useRegularAccount();
  const walletAccountId = regularAccount?.walletAccountId;
  const nftListSWR = useSWRArray(
    walletAccountId
      ? backend.getAccountNFTList(regularAccount?.walletAccountId)
      : undefined,
  );
  const uid = useMyUid();
  return (
    <Page alwaysShowsContent pageData={[walletSWR, nftListSWR]}>
      <HStack style={{ margin: "32px auto 4px" }}>
        <NFTIcon />
        <NFTAmount>{nftListSWR?.total ?? 0}</NFTAmount>
      </HStack>
      <Total>{i18n.jul23_impr2_total()}</Total>
      {/*<RegularButton*/}
      {/*  $baseColor={ButtonColor.greenish}*/}
      {/*  style={{ margin: "32px 30px 30px" }}*/}
      {/*>*/}
      {/*  {i18n.aigc_quota_get_more()}*/}
      {/*</RegularButton>*/}

      {(nftListSWR?.content?.length ?? 0) > 0 ? (
        <>
          <SectionHead>{i18n.web3_v0_nfts_title()}</SectionHead>
          {/*<ShowcaseFrame*/}
          {/*  onClick={() => {*/}
          {/*    hopper.push(`treasure/${uid}`);*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <ShowcaseTitle>*/}
          {/*    {i18n.nft_rebrand_see_my_treasures()}*/}
          {/*    <Image src={goForwardIcon} width={24} height={24} />*/}
          {/*  </ShowcaseTitle>*/}
          {/*  <ShowcaseText>*/}
          {/*    {i18n.nft_rebrand_select_merch_and_display()}*/}
          {/*  </ShowcaseText>*/}
          {/*</ShowcaseFrame>*/}
          <NFTContainer>
            {nftListSWR?.content.map((nft) => (
              <NFTCell key={nft.nftId} nft={nft} />
            ))}
          </NFTContainer>
        </>
      ) : (
        <DefaultEmptyView topSpace={98} title={i18n.web3_v0_no_nfts_yet()} />
      )}
    </Page>
  );
}

const Total = styled.div`
  color: #ffffff;
  font-size: 12px;
  margin: 0 auto 32px;
`;

const NFTContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;

  > * {
    flex-basis: calc(33.33% - 10px); // 每行3个，减去10px是为了考虑间距
    margin: 5px; // 可选，增加元素间的间距
  }
`;

const ShowcaseText = styled.div`
  font-weight: 300;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
`;

const ShowcaseTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  align-items: center;
`;

const ShowcaseFrame = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px 16px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.06);
  margin: 18px 2px 20px;
`;

const NFTIcon = styled.div`
  width: 38px;
  height: 38px;
  background-image: url("${iconNft}");
  background-size: 100%;
  background-repeat: no-repeat;
`;

const NFTAmount = styled.div`
  font-size: 28px;
  color: white;
  font-weight: 700;
`;

const SectionHead = styled.div`
  width: 100%;
  height: 33px;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.15);
  margin: 0 var(--page-h-inset-neg) 20px;
  padding: 0 var(--page-h-inset);
`;

export default AccountCollectiblesPage;
