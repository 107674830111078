import React, { ReactNode } from "react";
import { clampZDoc, ZDocProps } from "./ZDocCommon";
import { ZDocParagraph } from "./ZDocParagraph";
import { flattenStateId } from "../../hooks/StateId";

function splitLines(s: string, keepEnds: boolean): string[] {
  let lines = s.split("\n"); // Split by '\n'

  if (keepEnds) {
    // Add '\n' back to all lines except the last one
    for (let i = 0; i < lines.length - 1; i++) {
      lines[i] += "\n";
    }
  }

  // Handle possible '\r\n' or '\r' endings (Windows/Mac)
  lines = lines.map((line) => {
    if (line.endsWith("\r\n")) {
      return keepEnds ? line : line.slice(0, -2); // Remove '\r\n' if not keeping
    } else if (line.endsWith("\r")) {
      return keepEnds ? line : line.slice(0, -1); // Remove '\r' if not keeping
    }
    return line; // No change needed
  });

  return lines;
}

export function ZDocChunk(props: ZDocProps) {
  const paragraphs = splitLines(props.content, true);

  const nodes: ReactNode[] = [];

  let paragraphStart = 0;
  for (let i = 0; i < paragraphs.length; i++) {
    const paragraph = paragraphs[i];
    const paragraphEnd = paragraphStart + paragraph.length;

    const [rf, c] = clampZDoc(
      paragraphStart,
      paragraphEnd,
      props.richFormat,
      props.content,
    );
    nodes.push(
      <ZDocParagraph
        key={flattenStateId([rf, c, nodes.length])}
        richFormat={rf}
        content={c}
        options={props.options}
      />,
    );

    paragraphStart = paragraphEnd;
  }
  return <>{nodes}</>;
}
