import { z } from "zod";
import { zBigInt, zStatic } from "../utils/zodUtils";
import { Currency } from "./Currency";
import { CouponTemplate } from "./CouponTemplate";
import { CouponExtensions } from "./CouponExtensions";

export const Coupon = z.object({
  accountId: zBigInt.optional(),
  activeSeconds: z.number().optional(),
  coin: z.number().optional(),
  coinCurrency: Currency.optional(),
  couponId: zBigInt.optional(),
  couponType: z.number().optional(),
  createdTime: z.number().optional(),
  description: z.string().optional(),
  diamond: z.number().optional(),
  diamondCurrency: Currency.optional(),
  expiredAt: z.number().optional(),
  expiredCount: z.number().optional(),
  sentCount: z.number().optional(),
  status: z.number().optional(),
  supply: z.number().optional(),
  membershipInterval: z.number().optional(),
  typeName: z.string().optional(),
  title: z.string().optional(),
  usedAt: z.number().optional(),
  usedCount: z.number().optional(),
  extensions: CouponExtensions.optional(),
  applicable: z.boolean().optional(),
  currency: Currency.optional(),
  template: CouponTemplate.optional(),
});

export enum CouponType {
  Unknown = 0,
  ForStandardMinting = 5,
  ForMerch = 2,
  ForPremiumMinting = 3,
  ForLiteMinting = 4,
  ForAIGCQuota = 6,
  ForAIChatQuota = 7,
  ForPrimeMembership = 8,
}
