import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import { useEffect, useRef, useState } from "react";
import { BottomSheet, ModalController } from "../../components/Modal";
import { SwiperClass } from "swiper/swiper-react";
import { ensureHttps, StickerPanel } from "./StickerPanel";
import { Sticker, StickerType } from "../../proto/Reaction";
import styled from "styled-components";
import { getBestRes } from "../../proto/Media";
import { HStackMixin } from "../../components/VStack";
import icStaticEmoji from "../../res/images/ic_static_sticker.png";
import icAnimatedEmoji from "../../res/images/ic_animated_sticker.webp";
import icCustomizedEmoji from "../../res/images/ic_customized_sticker.png";
import { useSWRArray } from "../../hooks/swr/useSWRArray";
import { useSWR } from "../../hooks/swr/useSWR";
import { useBackend } from "../../service/APIService";
import { Backend } from "../../service/Backend";
import { usePageSpec } from "../common/usePageSpec";

export function StickerPicker(props: {
  modal: ModalController;
  onChoose: (sticker: Sticker) => void;
  backend: Backend;
}) {
  const swiperRef = useRef<SwiperRef>(null);
  const [index, setIndex] = useState(0);

  const onChooseSticker = (sticker: Sticker) => {
    props.modal.close();
    props.onChoose(sticker);
  };

  const pageSpec = usePageSpec();

  return (
    <BottomSheet modal={props.modal}>
      <Swiper
        loop={false}
        ref={swiperRef}
        initialSlide={index}
        onSlideChange={(swiper: SwiperClass) => {
          setIndex(swiper.activeIndex);
        }}
      >
        <SwiperSlide>
          <StickerPanel
            key={`sticker-panel-${StickerType.Customized}`}
            type={StickerType.Customized}
            onChoose={onChooseSticker}
            backend={props.backend}
          />
        </SwiperSlide>
        <SwiperSlide>
          <StickerPanel
            key={`sticker-panel-${StickerType.Static}`}
            type={StickerType.Static}
            onChoose={onChooseSticker}
            backend={props.backend}
          />
        </SwiperSlide>
        <SwiperSlide>
          <StickerPanel
            key={`sticker-panel-${StickerType.Animation}`}
            type={StickerType.Animation}
            onChoose={onChooseSticker}
            backend={props.backend}
          />
        </SwiperSlide>
      </Swiper>
      <StickerNav
        style={{
          borderBottomLeftRadius: pageSpec === "wide" ? 18 : "unset",
          borderBottomRightRadius: pageSpec === "wide" ? 18 : "unset",
        }}
      >
        <SelectedReactionType
          onClick={() => {
            if (index !== 0) {
              setIndex(0);
              swiperRef.current?.swiper.slideTo(0);
            }
          }}
          hasBeenSelected={index === 0}
        >
          <img width={24} height={24} src={icCustomizedEmoji} />
        </SelectedReactionType>

        <SelectedReactionType
          onClick={() => {
            if (index !== 1) {
              setIndex(1);
              swiperRef.current?.swiper.slideTo(1);
            }
          }}
          hasBeenSelected={index === 1}
        >
          <img width={24} height={24} src={icStaticEmoji} />
        </SelectedReactionType>

        <SelectedReactionType
          onClick={() => {
            if (index !== 2) {
              setIndex(2);
              swiperRef.current?.swiper.slideTo(2);
            }
          }}
          hasBeenSelected={index === 2}
        >
          <img width={30} height={30} src={icAnimatedEmoji} />
        </SelectedReactionType>
      </StickerNav>
    </BottomSheet>
  );
}

export const StickerNav = styled.div`
  width: 100%;
  height: 76px;
  background-color: black;
  padding: 0 20px;
  gap: 8px;
  ${HStackMixin};
  position: absolute;
  bottom: 0;
  z-index: 10;
`;

export const SelectedReactionType = styled.div<{ hasBeenSelected: boolean }>`
  width: 50px;
  height: 38px;
  display: flex;
  margin-top: 8px;
  margin-bottom: 30px;
  justify-content: center;
  align-items: center;
  background: ${(prop) =>
    prop.hasBeenSelected ? "rgba(255, 255, 255, 0.12)" : "none"};
  border-left: 8px;
  border-radius: 8px;
`;
