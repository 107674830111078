import { Media } from "./Media";
import { z } from "zod";
import { zBigInt, zEnum, zStatic } from "../utils/zodUtils";
import { Currency } from "./Currency";
import { TransferOrder } from "./TransferOrder";
import { ObjectStatus } from "./ObjectStatus";
import { AccountType } from "./AccountType";
import { AssetAccount } from "./AssetAccount";
import { AccountStatus } from "./AccountStatus";
import { AccountNFT } from "./AccountNFT";
import { NFT } from "./NFT";
import { AccountCurrency } from "./AccountCurrency";

export const TypedAddressInfo = z.object({
  accountName: z.string().optional(),
  walletAccountId: zBigInt.optional(),
});

export enum GasFeeTxType {
  Transfer = 1,
  Swap = 2,
  PrintNFT = 3,
  DeployNft = 4,
  DeployCollectionNft = 5,
  TransferNft = 6,
}

export type TypedAddressInfo = zStatic<typeof TypedAddressInfo>;

export const CurrencyHolder = z.object({
  accountCurrencyList: z.array(AccountCurrency).default([]),
  currency: Currency,
  membershipCardList: z.array(NFT).optional(),
});

export type CurrencyHolder = zStatic<typeof CurrencyHolder>;

export const WalletAccount = z.object({
  accountId: zBigInt.default(0),
  walletAccountId: zBigInt.default(0),
  icon: Media.optional(),
  accountType: zEnum(AccountType),
  name: z.string().optional(),
  assetAccountList: z.array(AssetAccount).default([]),
  accountStatus: zEnum(AccountStatus).optional(),
  status: zEnum(ObjectStatus),
  currencyList: z.array(Currency).default([]),
  nftList: z.array(AccountNFT).default([]),
  nftCount: z.number().default(0),
});

export type WalletAccount = zStatic<typeof WalletAccount>;

export enum BoolNumber {
  On = 1,
  Off = 2,
}

export const Wallet = z.object({
  walletAccountList: z.array(WalletAccount).default([]),
  status: zEnum(ObjectStatus).optional(),
  storeAccount: AssetAccount.optional(),
  thirdPartyIapStatus: zEnum(BoolNumber).default(2),
  unreadUserMintOrderCount: z.number().default(0),
  unreadTopUpOrderCount: z.number().default(0),
  unreadTransferOrderCount: z.number().default(0),
  latestTopUpOrder: TransferOrder.optional(),
  latestTransferOrder: TransferOrder.optional(),
  latestUserMintOrder: TransferOrder.optional(),
  // regularAccount: AssetAccount.optional(),
});

export type Wallet = zStatic<typeof Wallet>;

export const AddressInfo = z.object({
  chainType: z.number().optional(),
  addressType: z.number().optional(),
  address: z.string().optional(),
});

export type AddressInfo = zStatic<typeof AddressInfo>;

export const UserPropsAvailableUnitsResp = z.object({
  energyAvailableUnits: z.number().optional(),
});

export type UserPropsAvailableUnitsResp = zStatic<
  typeof UserPropsAvailableUnitsResp
>;

export enum UserPropsType {
  image = 1,
  character = 2,
  matchPass = 3,
}

export const UserProps = z.object({
  characterSnacksAvailableUnits: z.number().optional(),
  creationTicketsAvailableUnits: z.number().optional(),
  priorityMatchPassesAvailableUnits: z.number().optional(),
});
export type UserProps = zStatic<typeof UserProps>;
