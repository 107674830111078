import { useSWRArray } from "../../hooks/swr/useSWRArray";
import { useI18n } from "../../hooks/useI18n";
import { Page } from "../../components/Page";
import { CommonUserCell } from "./CommonUserCell";
import { useMyUid } from "../../service/AuthSessionService";
import { useBackend } from "../../service/APIService";
import { HStack, VStack } from "../../components/VStack";
import styled from "styled-components";
import FriendUserCell from "./FriendUserCell";
import { useSWR } from "../../hooks/swr/useSWR";
import { Image } from "../../components/Image";
import addIcon from "../../res/images/ic_add.svg";
import React, { useEffect } from "react";
import { useHopper } from "../../hooks/useHopper";
import { UserPickerPurpose, UserPickerResult } from "./UserPickerPage";
import { FollowType } from "../../proto/UserEnums";
import { andLog } from "../../components/handleError";
import { useConsumeResult } from "../../hooks/useResult";

export interface UserListProps {
  type: FollowType;
}

function UserListPage(props: UserListProps) {
  const backend = useBackend();
  const i18n = useI18n();
  const myUid = useMyUid();
  const usersSWR = useSWRArray(backend.getSocialUsers(myUid, props.type));
  const userPicked = useConsumeResult<UserPickerResult>("UserPicker", [
    "User",
    myUid,
  ]);
  const hooper = useHopper();
  const activeFriendSWR = useSWR(
    backend.getSocialUsers(
      myUid,
      props.type,
      undefined,
      undefined,
      undefined,
      props.type === "friend" ? 1 : undefined,
    ),
  );

  const favSWR = useSWRArray(
    backend.getSocialUsers(myUid, FollowType.FOLLOWING_FAVORITES),
  );
  const pageDate =
    props.type === "friend"
      ? [activeFriendSWR]
      : props.type === "following"
        ? [favSWR]
        : undefined;
  useEffect(() => {
    if (userPicked.result?.uid) {
      favSWR.load().catch(andLog);
      usersSWR.load().catch(andLog);
    }
  }, [userPicked.result?.uid]);
  return (
    <Page pageData={pageDate ? [usersSWR, ...pageDate] : usersSWR}>
      {props.type === "friend" &&
      (activeFriendSWR.content?.list?.length || 0) > 0 ? (
        <VStack style={{ marginTop: 20 }}>
          <SectionTitle>{i18n.home_impr_recent_active()}</SectionTitle>
          <ActiveFrame>
            {activeFriendSWR.content?.list.map((user) => (
              <FriendUserCell user={user} />
            ))}
          </ActiveFrame>
        </VStack>
      ) : props.type === "following" ? (
        <VStack style={{ marginTop: 20 }}>
          <SectionTitle>{i18n.fav_widget_favorites()}</SectionTitle>
          {favSWR.content?.map((user) => (
            <FavFrame
              style={{
                position: "relative",
              }}
            >
              <div
                style={{
                  background: "rgba(255, 92, 0, 0.1)",
                  position: "absolute",
                  left: -18,
                  right: -18,
                  top: 0,
                  bottom: 0,
                }}
              />
              <CommonUserCell user={user} onUpdate={() => favSWR.load()} />
            </FavFrame>
          ))}
          <HStack
            style={{ height: 66, gap: 18 }}
            onClick={() =>
              hooper.requestPresent(`users-picker`, userPicked.consumerId, {
                purpose: UserPickerPurpose.AddFavourites,
              })
            }
          >
            <Image width={40} height={40} src={addIcon} />
            <div>{i18n.fav_widget_add_favorites()}</div>
          </HStack>
          <SectionTitle>{i18n.others()}</SectionTitle>
        </VStack>
      ) : null}
      {props.type === "friend" ? (
        <SectionTitle>{i18n.home_impr_all_friends()}</SectionTitle>
      ) : null}

      {usersSWR.content.map((user) => (
        <CommonUserCell
          key={user.uid.toString()}
          user={user}
          onUpdate={() => usersSWR.load()}
        />
      ))}
    </Page>
  );
}

const SectionTitle = styled.div`
  margin: 10px 0 5px;
`;
const FavFrame = styled.div`
  border-bottom: 1px solid var(--color-text30);
`;

const ActiveFrame = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;

  > * {
    flex-basis: calc(33.33% - 10px); // 每行3个，减去10px是为了考虑间距
    margin: 5px; // 可选，增加元素间的间距
  }
`;

export default UserListPage;
