import { useI18n } from "../../hooks/useI18n";
import { Page } from "../../components/Page";
import { StrongButton } from "../../components/Buttons";
import { useHopper } from "../../hooks/useHopper";
import { HStack, Spring, VSpace } from "../../components/VStack";
import { Image } from "../../components/Image";
import { useState } from "react";
import boxUncheck from "../../res/images/box_uncheck.svg";
import boxChecked from "../../res/images/box_checked.svg";
import { PowerMarkdown } from "../../components/PowerMarkdown";
import { css } from "styled-components";
import { ServiceAndPrivacyModal } from "./ServiceAndPrivacyModal";
import { useModal } from "../../components/Modal";
import authHomeBg from "../../res/images/auth_home_bg.png";
import { usePageSpec } from "../common/usePageSpec";
import logo from "../../res/images/logo_clover.svg";
import { AuthTitleLabel } from "./InvitationCodePage";
import { NavStart } from "../../components/NavBar";
import { useObscuredZoneForKey } from "../../hooks/useObscuredZones";
import { useHopState } from "../../hooks/useHopState";
import { genUUID } from "../../utils/uuid";
import { ensureInternalLink } from "../common/InternalLink";

export enum SignUpOrLogIn {
  SignUp = 0,
  LogIn = 1,
}

export function AuthHomePage() {
  const i18n = useI18n();
  const hopper = useHopper();
  const serviceAndPrivacyModal = useModal("serviceAndPrivacyModal");

  const [obscuringZone] = useObscuredZoneForKey("Browser");

  const [agree, setAgree] = useHopState<boolean>("hasAgreed", false);
  const checkBox = () => {
    setAgree(!agree);
  };

  const [nextActionAfterAgree, setNextActionAfterAgree] = useState<
    SignUpOrLogIn | undefined
  >();

  const signUpClick = () => {
    if (agree) {
      const flowId = genUUID();
      hopper.push("sign-log-selection", {
        type: SignUpOrLogIn.SignUp,
        f: flowId,
      });
    } else {
      setNextActionAfterAgree(SignUpOrLogIn.SignUp);
      serviceAndPrivacyModal.open();
    }
  };

  const logInClick = () => {
    if (agree) {
      const flowId = genUUID();
      hopper.push("sign-log-selection", {
        type: SignUpOrLogIn.LogIn,
        f: flowId,
      });
    } else {
      setNextActionAfterAgree(SignUpOrLogIn.LogIn);
      serviceAndPrivacyModal.open();
    }
  };
  const pageSpec = usePageSpec();
  return (
    <Page
      pageData={undefined}
      scrollDisabled={true}
      background={
        pageSpec !== "wide"
          ? `url(${authHomeBg}) no-repeat center/cover`
          : undefined
      }
    >
      <NavStart>{<></>}</NavStart>
      <Spring />
      {pageSpec !== "wide" && (
        <Image
          src={logo}
          alt={"logo"}
          width={153}
          height={153}
          style={{ margin: "21px auto" }}
        ></Image>
      )}
      <AuthTitleLabel>{i18n.clover_meet_metaverse_friends()}</AuthTitleLabel>
      <Spring />
      <HStack style={{ margin: "0 62px", alignItems: "start" }}>
        <Image
          style={{ width: 14, height: 14, marginRight: 7, marginTop: 2 }}
          src={agree ? boxChecked : boxUncheck}
          alt={"check_box"}
          onClick={checkBox}
        ></Image>
        <PowerMarkdown textStyle={service_privacy_markdown_style}>
          {ensureInternalLink(i18n.moon_landing_terms_base_md())}
        </PowerMarkdown>
      </HStack>
      <StrongButton style={{ margin: "26px 62px" }} onClick={signUpClick}>
        {i18n.auth_sign_up()}
      </StrongButton>
      <StrongButton style={{ margin: "0 62px" }} onClick={logInClick}>
        {i18n.auth_log_in()}
      </StrongButton>
      <VSpace height={obscuringZone.bottom + 30} />
      <ServiceAndPrivacyModal
        modal={serviceAndPrivacyModal}
        agree={() => {
          setAgree(true);
          const flowId = genUUID();
          switch (nextActionAfterAgree) {
            case SignUpOrLogIn.SignUp:
              hopper.push("sign-log-selection", {
                type: SignUpOrLogIn.SignUp,
                f: flowId,
              });
              break;
            case SignUpOrLogIn.LogIn:
              hopper.push("sign-log-selection", {
                type: SignUpOrLogIn.LogIn,
                f: flowId,
              });
              break;
            default:
              break;
          }
        }}
      />
    </Page>
  );
}

export const service_privacy_markdown_style = css`
  color: var(--color-text00);
  font-weight: 500;
  font-size: 12px;

  a {
    color: var(--color-text00);
    text-decoration: underline;
  }
`;
