import { WidgetItem } from "../proto/WidgetItem";
import { Image } from "./Image";
import { I18n } from "../hooks/useI18n";
import { useLoadState } from "../hooks/LoadState";
import { useBackend } from "../service/APIService";
import { Hopper, useHopper } from "../hooks/useHopper";
import { Spin } from "./Spin";
import React from "react";
import { useErrorHandler } from "./handleError";

export interface WidgetItemProps {
  widgetItem: WidgetItem;
  i18n: I18n;
}

export function resolvePath(path: string, hopper: Hopper) {
  try {
    // 将传入的路径转为小写，并尝试创建 URL 对象

    console.log("path🧧", path);
    // 获取 URL 的路径组件
    const pathComponents = path.toLowerCase().split("/").filter(Boolean);

    // 如果路径组件为空，返回 'unrecognized'
    if (pathComponents.length === 0) {
      return "unrecognized";
    }

    if (pathComponents.length > 2) {
      hopper.modal("nyi");
    } else if (pathComponents.length > 1) {
      const objectId = BigInt(pathComponents[1]);
      const firstStr = pathComponents[0];
      if (firstStr === "blog") {
        hopper.push(`post/${objectId}`);
      } else if (firstStr === "user") {
        hopper.push(`user/${objectId}`);
      } else if (firstStr === "chat") {
        hopper.modal("nyi");
        // hopper.push(`chat/${objectId}`);
      } else if (firstStr === "circle") {
        hopper.push(`circle/${objectId}`);
      } else {
        console.log("fix Router👺:", firstStr);
        hopper.modal("nyi");
      }
    }
    // 你可以在这里添加更多逻辑来处理路径
  } catch (error) {
    // 如果路径无法转换为有效的 URL 对象，返回 'unrecognized'
    console.log("🧧error:", error);
  }
}

function CircleWidgetBanner(props: WidgetItemProps) {
  const linkTarget = useLoadState();
  const handleError = useErrorHandler();
  const backend = useBackend();
  const hopper = useHopper();

  async function openInternalLink(link: string) {
    const r = await linkTarget.run(async () => {
      const linkModel = await backend.linkPathFrom(link).run();
      linkModel.path && resolvePath(linkModel.path, hopper);
    });

    if (!r.success) {
      handleError(r.error);
    } else {
      // hopper.back();
    }
  }

  return (
    <div
      style={{
        display: "flex",
        position: "relative",
      }}
      onClick={() => {
        // hopper.
        let targetLink = props.widgetItem.targetLink;
        targetLink && openInternalLink(targetLink);
      }}
    >
      <Image
        style={{
          borderRadius: 10,
          width: "100%",
          boxSizing: "border-box",
          border: "1px solid rgba(255, 255, 255, 0.2)",
        }}
        height={80}
        src={[props.widgetItem.background?.backgroundImage, "best"]}
      />
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          left: 0,
          top: 0,
        }}
      >
        <Spin state={linkTarget.state}>{}</Spin>
      </div>
    </div>
  );
}

export default CircleWidgetBanner;
