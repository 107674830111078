import { z } from "zod";
import { zBigInt, zStatic } from "../utils/zodUtils";
import { Media } from "./Media";

export const LivePricingData = z.object({
  gemsAmount: z.string().default("255"),
  gemsPerUsd: z.string().default("255"),
  usdPrice: z.string().default("240"),
});

export type LivePricingData = zStatic<typeof LivePricingData>;
