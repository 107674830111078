import React, { forwardRef, useMemo } from "react";
import { HStack, VStack } from "../../../components/VStack";
import styled, { css, RuleSet } from "styled-components";
import { UserAvatarView } from "../../../components/views/UserAvatarView";
import { UserNameView } from "../../../components/views/UserNameView";
import { User } from "../../../proto/User";
import active_flame_1 from "../../../res/images/active_flame_1.svg";
import active_flame_2 from "../../../res/images/active_flame_2.svg";
import active_flame_3 from "../../../res/images/active_flame_3.svg";
import active_flame_4 from "../../../res/images/active_flame_4.svg";
import {
  alpha_on_pressed,
  FullPageWidthCell,
} from "../../../components/CommonStyles";
import { Image } from "../../../components/Image";
import { useI18n } from "../../../hooks/useI18n";
import { SingleLineUnspecifiedWidth } from "../../../components/CommonViews";
import { useSWRArray } from "../../../hooks/swr/useSWRArray";
import { LoadStateKind } from "../../../hooks/LoadState";
import { useBackend } from "../../../service/APIService";
import { useCircleSWR } from "../useCircleSWR";
import { useMeasure } from "react-use";
import { Page } from "../../../components/Page";
import { CircleMemberTitle, TitleType } from "../../../proto/CircleMemberTitle";
import MemberTitle from "../../../components/MemberTitle";
import { useHopper } from "../../../hooks/useHopper";
import { activeTime, timePair } from "../../user/UserProfilePage";
import { LoadStateCell } from "../../../components/LoadStateView";
import { NavEnd, NavItem, NavMiddle } from "../../../components/NavBar";
import { NavButtonType } from "../../../bridge/NavButtonDesc";
import { BSMenu, BSMenuItem } from "../../../components/BSMenu";
import { useModal } from "../../../components/Modal";
import { useBroadcast } from "../../../hooks/useBroadcast";
import { andLog } from "../../../components/handleError";
import { useMyUid } from "../../../service/AuthSessionService";

const MainBodyCss = css`
  margin-left: -1px;
  flex-grow: 1;
  flex-shrink: 1;
`;

const CoAdminUsersContainer = styled.div`
  width: 100%;
  display: grid;
  margin-top: 12px;
  grid-template-columns: repeat(3, 1fr); /* 创建3列，每列宽度相等 */
  gap: 10px; /* 子项之间的间距 */
`;

const MemberUsersContainer = styled.div`
  width: 100%;
  display: grid;
  margin-top: 12px;
  grid-template-columns: repeat(4, 1fr); /* 创建3列，每列宽度相等 */
  gap: 10px; /* 子项之间的间距 */
`;

const OrganizationCardStyle = css`
  background-size: 2px;
  padding-left: 25px;
  padding-bottom: 18px;
  padding-right: 20px;
  align-items: start;
  margin-top: 30px;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: -8px;
    width: 20px; /* 调整这个值来改变边框的宽度 */
    height: 20px; /* 调整这个值来改变边框的高度 */
    border-left: 1px solid #4c4c54; /* 左边框的样式 */
    border-bottom: 1px solid #4c4c54; /* 下边框的样式 */
    border-bottom-left-radius: 10px; /* 左下角的圆角半径 */
  }
`;

const OrganizationNoTreeCardStyle = css`
  background-size: 2px;
  align-items: start;
  gap: 10px;
`;

const ActiveIcon = styled.img``;

const CoAdminActiveLabel = styled.div<{ mixin?: RuleSet<Object> }>`
  opacity: 0.4;
  color: #fff;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.167px;
  ${(p) => p.mixin}
`;

const TreeStroke = styled.div<{ calculated_height: number }>`
  background-color: #4c4c54;
  width: 1px;
  height: ${(p) => p.calculated_height}px;
  flex-shrink: 0;

  &::before {
    content: " ";
    width: 6px;
    height: 6px;
    background-color: #4c4c54;
    position: absolute;
    border-radius: 3px;
    left: -3px;
  }
`;

const AdminTitleStyle = css`
  color: white;
  word-wrap: break-word;
  line-clamp: 1;
  font-size: 18px;
  font-weight: 700;
`;

const AdminTagLine = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: #fff;
  text-overflow: ellipsis;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  word-break: break-word;
  opacity: 0.8;
`;

const NoMember = styled.div`
  color: #fff;
  text-align: justify;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;

  opacity: 0.4;
  margin-top: 12px;
`;

export function CircleTeamPage() {
  const backend = useBackend();
  const i18n = useI18n();
  const [circleId, circleValue] = useCircleSWR();
  const titles = useSWRArray(backend.getVisibleTitles(circleId));
  useBroadcast(
    "ON_CIRCLE_TITLE_MEMBER_UPDATE",
    () => {
      titles.load().catch(andLog);
    },
    [titles],
  );
  const myUid = useMyUid();
  const isAdmin = useMemo(() => {
    return (
      circleValue?.content?.adminIdList.includes(myUid) ||
      circleValue?.content?.coAdminIdList.includes(myUid)
    );
  }, [circleValue]);
  const [elementRef, elementRect] = useMeasure<HTMLDivElement>();
  const [lastChildRef, lastChildRect] = useMeasure<HTMLDivElement>();
  const calculatedHeight = elementRect.height - lastChildRect.height - 14;
  const titleCount = titles.content.length;
  const moreMenu = useModal("moreMenu");
  const hopper = useHopper();
  useBroadcast(
    "ON_REMOVE_FROM_CIRCLE",
    () => {
      circleValue.load().catch(andLog);
    },
    [circleValue],
  );
  return (
    <Page pageData={circleValue}>
      <NavMiddle>{i18n.clover_team_structure()}</NavMiddle>
      {isAdmin && (
        <NavEnd>
          {NavItem.image(NavButtonType.More, () => {
            moreMenu.open();
          })}
        </NavEnd>
      )}
      <HStack
        mixin={css`
          align-items: start;
        `}
      >
        <TreeStroke calculated_height={calculatedHeight} />

        <VStack mixin={MainBodyCss} ref={elementRef}>
          {titles.content.map((e, i) => {
            if (e.type === TitleType.admin) {
              return (
                <AdminTitleCell title={e} key={e.titleId} treeMode={true} />
              );
            } else {
              return (
                <UserMemberTitleCell
                  title={e}
                  treeMode={true}
                  ref={i === titleCount - 1 ? lastChildRef : undefined}
                  key={e.titleId}
                />
              );
            }
          })}
        </VStack>
      </HStack>
      <BSMenu modal={moreMenu}>
        <BSMenuItem
          title={i18n.member_title_manage_circle_titles()}
          onClick={() => hopper.push(`circle/${circleId}/member-titles`)}
        />
        <BSMenuItem
          title={i18n.clover_manage_team_structure_page_layout()}
          onClick={() => hopper.push(`circle/${circleId}/manage-team`)}
        />
      </BSMenu>
    </Page>
  );
}

export function AdminTitleCell(props: MemberTitleProps) {
  const circleId = props.title.circleId;
  const titleId = props.title.titleId;
  const hopper = useHopper();

  const backend = useBackend();
  const users = useSWRArray(backend.getCircleTitleUsers(circleId, titleId));

  const admin: User | undefined =
    users.content.length > 0 ? users.content[0] : undefined;

  return (
    <VStack
      mixin={
        props.treeMode ? OrganizationCardStyle : OrganizationNoTreeCardStyle
      }
      style={{ overflow: "hidden" }}
    >
      <MemberTitle memberTitle={props.title} />

      {admin && (
        <HStack
          mixin={[
            alpha_on_pressed,
            css`
              margin-top: 12px;
              overflow: hidden;
              width: 100%;
            `,
          ]}
          onClick={(e) => {
            if (props.treeMode) {
              e.stopPropagation();
              hopper.modal(`circle/${circleId}/user-card/${admin.uid}`);
              // hopper.push(`user/${admin.uid}`);
            }
          }}
        >
          <AdminCellInternal admin={admin} />
        </HStack>
      )}
      {admin === null && (
        <LoadStateCell loadState={users.loadState} onClickRetry={users} />
      )}
    </VStack>
  );
}

function AdminCellInternal(props: { admin: User }) {
  const admin = props.admin;
  const activeIcon = getUserActiveIcon(admin);
  const i18n = useI18n();

  const formatedActiveTime = useMemo(() => {
    if (admin !== undefined && admin.lastActiveTime !== undefined) {
      const { minute, deltaTime } = timePair(admin.lastActiveTime);
      return activeTime(minute, deltaTime, i18n);
    } else {
      return undefined;
    }
  }, [admin]);
  return (
    <>
      <UserAvatarView disableClick user={admin} iconSize={75} />
      <VStack
        mixin={css`
          margin-left: 10px;
          flex-shrink: 1;
          overflow: hidden;
        `}
      >
        <UserNameView
          user={admin}
          nameStyle={AdminTitleStyle}
          style={css`
            overflow: hidden;
            flex-shrink: 1;
          `}
        />
        <AdminTagLine>{admin.tagline}</AdminTagLine>
        <HStack
          mixin={css`
            margin-top: 4px;
            justify-content: start;
          `}
        >
          <Image src={activeIcon} width={16} height={16} />
          <CoAdminActiveLabel
            mixin={css`
              margin-left: 4px;
            `}
          >
            {formatedActiveTime === undefined
              ? ""
              : i18n.aug23_impr_last_active(formatedActiveTime)}
          </CoAdminActiveLabel>
        </HStack>
      </VStack>
    </>
  );
}

type MemberTitleProps = {
  title: CircleMemberTitle;
  treeMode: boolean;
};

export const UserMemberTitleCell = forwardRef<HTMLDivElement, MemberTitleProps>(
  (props, ref) => {
    const i18n = useI18n();
    const backend = useBackend();
    const circleId = props.title.circleId;
    const titleId = props.title.titleId;
    const users = useSWRArray(backend.getCircleTitleUsers(circleId, titleId));
    const children = users.content.map((user) => {
      if (props.title.type === TitleType.coAdmin) {
        return (
          <CoAdminUserCell
            user={user}
            circleId={circleId}
            key={user.uid}
            enableClick={props.treeMode}
            containerStyle={
              props.treeMode
                ? undefined
                : {
                    width: "75px",
                  }
            }
          />
        );
      } else {
        return (
          <UserCell
            circleId={circleId}
            user={user}
            key={user.uid}
            enableClick={props.treeMode}
          />
        );
      }
    });
    return (
      <VStack
        mixin={
          props.treeMode ? OrganizationCardStyle : OrganizationNoTreeCardStyle
        }
        style={
          props.title.type === TitleType.coAdmin
            ? undefined
            : { paddingRight: 0 }
        }
        ref={ref}
      >
        <MemberTitle memberTitle={props.title} />

        {users.content.length === 0 &&
          users.loadState?.kind !== LoadStateKind.loaded && (
            <LoadStateCell loadState={users.loadState} onClickRetry={users} />
          )}

        {users.content.length === 0 &&
          users.loadState?.kind === LoadStateKind.loaded && (
            <NoMember>{i18n.member_title_no_members()}</NoMember>
          )}

        {props.title.type === TitleType.coAdmin ? (
          props.treeMode ? (
            <CoAdminUsersContainer>{children}</CoAdminUsersContainer>
          ) : (
            <HStack
              style={{
                width: "100%",
                overflowX: "auto",
                scrollbarWidth: "none" as "none",
                msOverflowStyle: "none" as "none",
                gap: 48,
                alignItems: "start",
              }}
            >
              {children}
            </HStack>
          )
        ) : (
          <MemberUsersContainer>{children}</MemberUsersContainer>
        )}
      </VStack>
    );
  },
);

function getUserActiveIcon(user?: User) {
  const managerAbsencesDays = user?.managerAbsencesDays ?? Number.MAX_VALUE;
  let activeIcon;
  if (managerAbsencesDays <= 0) {
    activeIcon = active_flame_1;
  } else if (managerAbsencesDays <= 2) {
    activeIcon = active_flame_2;
  } else if (managerAbsencesDays <= 4) {
    activeIcon = active_flame_3;
  } else {
    activeIcon = active_flame_4;
  }
  return activeIcon;
}

function CoAdminUserCell(props: {
  user: User;
  enableClick: boolean;
  circleId: bigint;
  containerStyle?: React.CSSProperties;
}) {
  const i18n = useI18n();
  const user = props.user;
  const activeIcon = getUserActiveIcon(user);
  const hopper = useHopper();
  const admin = user;
  const formatedActiveTime = useMemo(() => {
    if (admin !== undefined && admin.lastActiveTime !== undefined) {
      const { minute, deltaTime } = timePair(admin.lastActiveTime);
      return activeTime(minute, deltaTime, i18n);
    } else {
      return undefined;
    }
  }, [admin]);
  return (
    <VStack
      mixin={[
        alpha_on_pressed,
        css`
          align-items: center;
          gap: 4px;
        `,
      ]}
      onClick={(e) => {
        if (props.enableClick) {
          e.stopPropagation();
          hopper.modal(`circle/${props.circleId}/user-card/${user.uid}`);
          // hopper.push(`user/${user.uid}`);
        }
      }}
      style={props.containerStyle}
    >
      <UserAvatarView disableClick user={user} iconSize={44} />
      <UserNameView
        user={user}
        style={css`
          gap: 0 !important;
          max-width: 44px;
          margin-top: 4px;
        `}
        showMembershipLevel={true}
        nameStyle={[
          SingleLineUnspecifiedWidth,
          css`
            font-size: 10px;
            font-weight: 700;
          `,
        ]}
      />
      <ActiveIcon src={activeIcon} alt="active" />
      <CoAdminActiveLabel>
        {formatedActiveTime === undefined
          ? ""
          : i18n.aug23_impr_last_active(formatedActiveTime)}
      </CoAdminActiveLabel>
    </VStack>
  );
}

function UserCell(props: {
  user: User;
  enableClick: boolean;
  circleId: bigint;
}) {
  const hopper = useHopper();
  return (
    <VStack
      mixin={alpha_on_pressed}
      onClick={(e) => {
        if (props.enableClick) {
          e.stopPropagation();
          hopper.modal(`circle/${props.circleId}/user-card/${props.user.uid}`);
          // hopper.push(`user/${props.user.uid}`);
        }
      }}
      style={{ alignItems: "center", gap: 4 }}
    >
      <UserAvatarView disableClick user={props.user} iconSize={44} />
      <UserNameView
        user={props.user}
        style={css`
          gap: 0 !important;
          max-width: 44px;
          margin-top: 8px;
        `}
        showMembershipLevel={true}
        nameStyle={[
          SingleLineUnspecifiedWidth,
          css`
            font-size: 10px;
            font-weight: 300;
          `,
        ]}
      />
    </VStack>
  );
}
