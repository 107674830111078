import React, { createContext, PropsWithChildren, useContext } from "react";
import type { Router as RemixRouter } from "@remix-run/router/dist/router";
import { createBrowserRouter, createMemoryRouter } from "react-router-dom";
import { NotFoundPage } from "../pages/error/NotFoundPage";
import { UrlQuery } from "../utils/UrlUtil";

interface Context {
  baseRouter: RemixRouter;
  createMemoryRouter: (startUrl: string) => RemixRouter;
  webLinkToDeepLink: (
    webLink: string,
    urlQuery: UrlQuery,
  ) => string | undefined;
  deepLinkToWebLink: (deepLinkFull: string) => string | undefined;
}

const Context = createContext<Context>({
  baseRouter: createBrowserRouter([
    {
      path: "/*",
      element: <NotFoundPage />,
    },
  ]),
  createMemoryRouter: (startUrl: string) => createMemoryRouter([]),
  webLinkToDeepLink: (webLink: string, urlQuery: UrlQuery) => {
    return undefined;
  },
  deepLinkToWebLink: (deepLinkFull: string) => {
    return undefined;
  },
});

export function ReactRoutesService(props: PropsWithChildren<Context>) {
  return (
    <Context.Provider
      value={{
        ...props,
      }}
    >
      {props.children}
    </Context.Provider>
  );
}

export function useReactRoutes() {
  return useContext(Context);
}
