import { z } from "zod";
import { zBigInt, zEnum, zStatic } from "../utils/zodUtils";
import { CircleFolderItem } from "./CircleFolderItem";

export const CircleMenu = z.object({
  folderId: zBigInt,
  circleId: zBigInt,
  uid: zBigInt,
  folderItemIdList: zBigInt.array(),
  folderItems: CircleFolderItem.array().optional(),
  name: z.string(),
  description: z.string(),
});
export type CircleMenu = zStatic<typeof CircleMenu>;
