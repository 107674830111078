import { CircleToken } from "../../../proto/CircleFin";
import styled from "styled-components";
import { CenterChild, HStack, VStack } from "../../../components/VStack";
import { Image } from "../../../components/Image";
import { NFT } from "../../../proto/NFT";
import icCopy from "../../../res/images/ic_copy.svg";
import icSolScan from "../../../res/images/ic_sol_scan.png";
import { useI18n } from "../../../hooks/useI18n";
import { formatMoney } from "../../../proto/Currency";
import BigNumber from "bignumber.js";
import IcEdit from "../../../res/images/ic_edit.svg";
import { TokenProject } from "../../../proto/TokenProject";

export interface TokenSnippetCellProps {
  tokenProject: TokenProject;
  showEditIcon?: boolean;
  onPick?: () => void;
}

export function TokenSnippetCell(props: TokenSnippetCellProps) {
  const i18n = useI18n();
  const isDraftToken =
    String(props.tokenProject.tokenAddress ?? "").length === 0;
  return (
    <Container
      onClick={props.onPick}
      style={{
        position: "relative",
        borderRadius: 8,
        boxSizing: "content-box",
      }}
    >
      <Image
        src={[props.tokenProject.image, "best"]}
        style={{ borderRadius: 4 }}
        width={60}
        height={60}
      />
      <VStack style={{ gap: 4, flex: 1 }}>
        <TokenName>{props.tokenProject.name}</TokenName>
        <HStack>
          <Ticker>{props.tokenProject.ticker}</Ticker>
        </HStack>
        {isDraftToken && (
          <DraftTokenLabel>{i18n.clover_new_token()}</DraftTokenLabel>
        )}
        {!isDraftToken && (
          <HStack style={{ gap: 4 }}>
            <Image src={icCopy} width={20} height={20} />
            <CAValue>{shortenAddress(props.tokenProject.tokenAddress)}</CAValue>
            <Image src={icSolScan} width={14} height={14} />
          </HStack>
        )}
      </VStack>
      {!isDraftToken && (
        <VStack style={{ gap: 2, alignItems: "end" }}>
          <PriceUSD>{i18n.clover_price_usd()}</PriceUSD>
          <PriceValue>{`$${formatMoney(
            "short",
            BigNumber(props.tokenProject.currency?.usdPrice ?? 0),
          )}`}</PriceValue>
          <HStack style={{ gap: 4 }}>
            <MC>MC</MC>
            <MCValue>
              {formatMoney(
                "short",
                BigNumber(props.tokenProject.tradingStatistics?.marketCap ?? 0),
              )}
            </MCValue>
          </HStack>
        </VStack>
      )}

      {props.showEditIcon && (
        <CenterChild
          style={{
            position: "absolute",
            bottom: 0,
            objectFit: "contain",
            width: 24,
            borderTopLeftRadius: 4,
            borderBottomRightRadius: 4,
            height: 24,
            right: 0,
            backgroundColor: "rgba(17,17,17,0.8)",
          }}
        >
          <Image src={IcEdit} width={12} height={12} />
        </CenterChild>
      )}
    </Container>
  );
}

export function shortenAddress(input?: string): string {
  if (!input) {
    return "";
  }

  const length = input.length;

  if (length <= 8) {
    // If the string is 16 characters or less, return it as it is
    return input;
  }

  const start = input.slice(0, 4);
  const end = input.slice(-4);

  return `${start}...${end}`;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-top: 12px;
  padding: 8px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
`;

const Label = styled.div`
  color: var(--color-text10);
  font-weight: 300;
  font-size: 10px;
`;

const TokenName = styled.div`
  color: var(--color-text00);
  font-weight: 700;
  font-size: 16px;
`;

const Ticker = styled.div`
  color: var(--color-text00);
  font-weight: 700;
  background-color: rgba(255, 255, 255, 0.06);
  border-radius: 2px;
  padding: 2px 4px;
  font-size: 12px;
`;

const Value = styled.div`
  color: var(--color-text00);
  font-weight: 500;
  font-size: 16px;
`;

const PriceUSD = styled.div`
  color: #b6b6b6;
  font-weight: 400;
  font-size: 12px;
`;

const PriceValue = styled.div`
  color: var(--color-text00);
  font-weight: 500;
  font-size: 16px;
`;

const MC = styled.div`
  color: rgba(255, 255, 255, 0.4);
  font-weight: 400;
  font-size: 14px;
`;

const MCValue = styled.div`
  color: var(--color-text00);
  font-weight: 400;
  font-size: 14px;
`;

const CAValue = styled.div`
  color: var(--color-text00);
  font-weight: 400;
  font-size: 12px;
`;

const DraftTokenLabel = styled.div`
  color: #0dffcc;
  font-weight: 400;
  font-size: 10px;
`;
