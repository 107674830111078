import React, { PropsWithChildren, useContext } from "react";
import { HopperRefer } from "../hooks/useHopper";

interface Context {
  layerId: string;
  refer: HopperRefer | undefined;
}

const Context = React.createContext<Context>({
  layerId: "default-layer",
  refer: undefined,
});

export function useLayerId() {
  return useContext(Context).layerId;
}

export function useLayerRefer() {
  return useContext(Context).refer;
}

export function LayerBoundary(
  props: PropsWithChildren<{ id: string; refer: HopperRefer | undefined }>,
) {
  return (
    <Context.Provider value={{ layerId: props.id, refer: props.refer }}>
      {props.children}
    </Context.Provider>
  );
}
