import { Page } from "../../components/Page";
import { Spring } from "../../components/VStack";
import { useCirclesSWR, useCircleSWR } from "./useCircleSWR";
import styled from "styled-components";
import { useI18n } from "../../hooks/useI18n";
import { ButtonColor, WeakButton } from "../../components/Buttons";
import { CircleMemberStatus } from "../../proto/CircleMemberStatus";
import { useLoadState } from "../../hooks/LoadState";
import { andLog, useErrorHandler } from "../../components/handleError";
import { useBackend } from "../../service/APIService";
import { Spin } from "../../components/Spin";
import React from "react";
import { useHopper } from "../../hooks/useHopper";
import { CircleGridType } from "./CircleGridPage";
import { isCircleAdminOrCoAdmin } from "../../proto/Circle";
import { useMyUid } from "../../service/AuthSessionService";

export function CircleHasBeenDeletedCircle() {
  const [circleId, circleSWR] = useCircleSWR();
  const i18n = useI18n();

  const backend = useBackend();
  const leaveCircleTask = useLoadState();
  const handleError = useErrorHandler();
  const hopper = useHopper();
  const myUid = useMyUid();
  const allCirclesSWR = useCirclesSWR(CircleGridType.All);
  const managedCirclesSWR = useCirclesSWR(CircleGridType.Managed);

  async function leaveCircle() {
    const r = await leaveCircleTask.run(async () => {
      const circle = await backend.leaveCircle(circleId).run();
      allCirclesSWR.load().catch(andLog);
      if (
        circleSWR.content &&
        isCircleAdminOrCoAdmin(circleSWR.content, myUid)
      ) {
        managedCirclesSWR.load().catch(andLog);
      }
      await circleSWR.fill(circle);
      hopper.back();
    });

    if (!r.success) {
      handleError(r.error);
    }
  }

  return (
    <Page pageData={undefined}>
      <Spring />
      <DescLabel>{i18n.circle_has_been_deleted()}</DescLabel>
      {circleSWR.content?.joinedStatus &&
        isNotLeaved(
          circleSWR.content?.joinedStatus || CircleMemberStatus.UNKNOWN,
        ) && (
          <WeakButton
            onClick={() => {
              leaveCircle().catch(andLog);
            }}
            style={{ margin: "12px auto 0" }}
            $baseColor={ButtonColor.destructive}
          >
            <Spin state={leaveCircleTask.state}>
              {i18n.leave_this_circle()}
            </Spin>
          </WeakButton>
        )}
      <Spring />
    </Page>
  );
}

const DescLabel = styled.div`
  font-size: 14px;
  color: var(--color-text20);
  text-align: center;
`;

export function isNotLeaved(status: CircleMemberStatus): boolean {
  switch (status) {
    case CircleMemberStatus.JOINED:
    case CircleMemberStatus.REMOVED:
    case CircleMemberStatus.REMOVEDBLOCKED:
    case CircleMemberStatus.INVITED:
      return true;
    case CircleMemberStatus.UNKNOWN:
    case CircleMemberStatus.LEFT:
    case CircleMemberStatus.BLOCKED:
    case CircleMemberStatus.NOTJOINED:
      return false;
  }
}
