import { Page } from "../../components/Page";
import { NavMiddle } from "../../components/NavBar";
import { useI18n } from "../../hooks/useI18n";
import { SpeedTitle } from "../../components/SpeedTitle";
import { CellDivider } from "../../components/ContentGroup";
import styled from "styled-components";
import GridLayout from "../../components/GridLayout";
import * as React from "react";
import { HStack, Spring, VStack } from "../../components/VStack";
import { Image } from "../../components/Image";
import {
  alpha_on_pressed,
  shrink_on_pressed,
} from "../../components/CommonStyles";
import iconCopy from "../../res/images/ic_copy.svg";
import { ButtonColor, RegularSmallButton } from "../../components/Buttons";
import {
  CurrencyType,
  formatMoney,
  getCurrencyNameByCurrencyType,
} from "../../proto/Currency";
import BigNumber from "bignumber.js";
import icIncrease from "../../res/images/ic_increase.svg";
import icDecrease from "../../res/images/ic_decrease.svg";
import { PercentageChangeLabel, TokenPriceLabel } from "./CircleDetailInfoPage";
import iconSol from "../../res/images/icon_sol.svg";
import { useHopper } from "../../hooks/useHopper";
import { CircleToken, isValidCircleToken } from "../../proto/CircleFin";
import { useCircleSWR } from "./useCircleSWR";
import { useSWR } from "../../hooks/swr/useSWR";
import { useBackend } from "../../service/APIService";
import { copyTextToClipboard } from "../../utils/CopyText";
import { andLog } from "../../components/handleError";
import { useNativePage } from "../../hooks/useBridge";
import { useRegularAccount } from "../wallet/useRegularAccount";
import { useAssetAccount } from "../wallet/useAssetAccount";
import { EmptyState, EmptyTitle } from "../post/PostDetailPage";
import icEmptyState from "../../res/images/empty_state.svg";
import { Circle } from "../../proto/Circle";
import { formatPercentage } from "../../utils/NumberI18n";
import { isLiteApp } from "../../bridge/ExternalAppShellData";
import { CircleTokenStatus } from "../../proto/CircleTokenStatus";

function CircleStatsPage() {
  const i18n = useI18n();
  const hopper = useHopper();
  const [circleId, circleSWR] = useCircleSWR();
  const backend = useBackend();
  const tokenSWR = useSWR(backend.getCircleToken(circleId));
  const circleStatsSWR = useSWR(backend.getCircleStatsInfo(circleId));
  const nativePage = useNativePage();
  const regularAccount = useRegularAccount();
  const walletAccountId = regularAccount?.walletAccountId;
  const assetAccount = useAssetAccount();
  const assetAccountId = assetAccount?.accountId;

  return (
    <Page pageData={undefined}>
      <NavMiddle>{i18n.clover_circle_stats()}</NavMiddle>
      <SpeedTitle>{i18n.clover_token_stats()}</SpeedTitle>
      {isValidCircleToken(tokenSWR.content) &&
        tokenSWR.content.status !== CircleTokenStatus.Pending && (
          <>
            <HStack
              style={{
                marginTop: 12,
                marginBottom: 16,
                padding: 12,
                background: "rgba(255, 255, 255, 0.06)",
                borderRadius: 6,
              }}
            >
              <Image
                style={{ borderRadius: 4, marginRight: 8 }}
                src={[tokenSWR.content.image, "best"]}
                alt={"tokenImage"}
                width={48}
                height={48}
                onClick={() => {
                  hopper.push(`circle/${circleId}/token-info`);
                }}
              ></Image>
              <TokenTicker
                onClick={() => {
                  hopper.push(`circle/${circleId}/token-info`);
                }}
              >
                {tokenSWR.content.ticker}
              </TokenTicker>
              <Image
                style={{ marginLeft: 4 }}
                src={iconCopy}
                alt={"iconCopy"}
                width={20}
                height={20}
                onClick={() => {
                  if (
                    isValidCircleToken(tokenSWR.content) &&
                    tokenSWR.content.tokenAddress
                  ) {
                    copyTextToClipboard(tokenSWR.content.tokenAddress)
                      .then(() => {
                        nativePage
                          .successHud(i18n.clover_ca_copied())
                          .catch(andLog);
                      })
                      .catch(andLog);
                  }
                }}
              ></Image>
              <Spring />
              {!isLiteApp() && (
                <RegularSmallButton
                  $baseColor={ButtonColor.greenish}
                  onClick={() => {
                    const token = tokenSWR.content;
                    if (token && isValidCircleToken(token)) {
                      hopper.layer(
                        `wallet/${walletAccountId}/assetAccount/${assetAccountId}/swap`,
                        {
                          currencyId: token.projectId,
                        },
                      );
                    }
                  }}
                >
                  {i18n.clover_trade()}
                </RegularSmallButton>
              )}
            </HStack>
            <HStack style={{ gap: 8 }}>
              <TokenPriceLabel style={{ fontSize: 22 }}>
                {isValidCircleToken(tokenSWR.content) &&
                  `$${formatMoney("medium", BigNumber(tokenSWR.content.tradingStatistics?.priceUsd || "0"))}`}
              </TokenPriceLabel>
              <PercentageChangeLabel
                style={{
                  fontSize: 12,
                  color: marketCap1HipColor(tokenSWR.content),
                  background: marketCap1HipBackgroundColor(tokenSWR.content),
                }}
              >
                <Image
                  src={circleTokenFluctuationImage(tokenSWR.content)}
                  alt={"PercentageChangeDirection"}
                  width={16}
                  height={16}
                ></Image>
                {marketCap1HipString(tokenSWR.content)}
              </PercentageChangeLabel>
            </HStack>
            <HStack style={{ marginTop: 4, gap: 4, marginBottom: 20 }}>
              <Image
                src={iconSol}
                alt={"iconSol"}
                width={16}
                height={16}
              ></Image>
              <TokenPrice>
                {isValidCircleToken(tokenSWR.content) &&
                  `${formatMoney("medium", BigNumber(tokenSWR.content.tradingStatistics?.priceQuote || "0"))} ` +
                    (getCurrencyNameByCurrencyType(CurrencyType.SOL, i18n) ||
                      "")}
              </TokenPrice>
            </HStack>
            <GridLayout
              spanCount={2}
              items={[
                <StatsCard style={{ height: 64 }}>
                  <StatsCardTitle>{i18n.moon_ads_market_cap()}</StatsCardTitle>
                  <StatsCardNumber>
                    $
                    {isValidCircleToken(tokenSWR.content) &&
                      formatMoney(
                        "short",
                        BigNumber(
                          tokenSWR.content.tradingStatistics?.marketCap || "0",
                        ),
                      )}
                  </StatsCardNumber>
                </StatsCard>,
                <StatsCard style={{ height: 64 }}>
                  <StatsCardTitle>
                    {i18n.clover_total_value_locked()}
                  </StatsCardTitle>
                  <StatsCardNumber>
                    $
                    {isValidCircleToken(tokenSWR.content) &&
                      formatMoney(
                        "short",
                        BigNumber(tokenSWR.content.totalValueLocked),
                      )}
                  </StatsCardNumber>
                </StatsCard>,
                // <StatsCard
                //   style={{ height: 64 }}
                //   $isShrinkable={true}
                //   onClick={() =>
                //     hopper.push(`circle/${circleId}/token-holders`, {
                //       isCirclePoint: !isValidCircleToken(tokenSWR.content),
                //     })
                //   }
                // >
                //   <HStack>
                //     <StatsCardTitle>
                //       {i18n.clover_total_holders()}
                //     </StatsCardTitle>
                //     <Spring></Spring>
                //     <Image
                //       src={icShowMore}
                //       alt={"icShowMore"}
                //       width={20}
                //       height={20}
                //     ></Image>
                //   </HStack>
                //   <StatsCardNumber>
                //     {isValidCircleToken(tokenSWR.content) &&
                //       tokenSWR.content.holdersCount}
                //   </StatsCardNumber>
                // </StatsCard>,
              ]}
            ></GridLayout>
          </>
        )}
      {(!isValidCircleToken(tokenSWR.content) ||
        (isValidCircleToken(tokenSWR.content) &&
          tokenSWR.content.status === CircleTokenStatus.Pending)) && (
        <VStack style={{ width: "100%", alignItems: "center" }}>
          <EmptyState src={icEmptyState} />
          <EmptyTitle>{i18n.clover_no_circle_token_created_yet()}</EmptyTitle>
        </VStack>
      )}
      <CellDivider style={{ marginTop: 32, marginBottom: 32 }}></CellDivider>
      <SpeedTitle>{i18n.clover_membership_stats()}</SpeedTitle>
      <GridLayout
        spanCount={2}
        items={[
          <StatsCard>
            <StatsCardTitle>
              {i18n.clover_total_circle_members()}
            </StatsCardTitle>
            <StatsCardNumber>
              {`${formatMoney(
                "long",
                BigNumber(
                  (
                    circleStatsSWR.content?.totalMembersCountList?.at(-1) ??
                    BigInt(0)
                  ).toString(10),
                ),
              )}`}
            </StatsCardNumber>
            <StatsCardUpdateLabel>
              {i18n.clover_updated_daily()}
            </StatsCardUpdateLabel>
          </StatsCard>,
          <StatsCard>
            <StatsCardTitle>{i18n.clover_total_paid_members()}</StatsCardTitle>
            <StatsCardNumber>{`${formatMoney(
              "long",
              BigNumber(
                (
                  circleStatsSWR.content?.totalPaidMemberCountList?.at(-1) ??
                  BigInt(0)
                ).toString(10),
              ),
            )}`}</StatsCardNumber>
            <StatsCardUpdateLabel>
              {i18n.clover_updated_daily()}
            </StatsCardUpdateLabel>
          </StatsCard>,
          <StatsCard>
            <StatsCardTitle>
              {i18n.clover_30_day_average_turnover_rate()}
            </StatsCardTitle>

            <StatsCardNumber>
              {circleBounceRateDisplayString(circleSWR.content)}
            </StatsCardNumber>
            <StatsCardUpdateLabel>
              {i18n.clover_updated_daily()}
            </StatsCardUpdateLabel>
          </StatsCard>,
          <StatsCard>
            <StatsCardTitle>
              {i18n.circle_dc_daily_new_members()}
            </StatsCardTitle>
            <StatsCardNumber>{`${formatMoney(
              "medium",
              BigNumber(
                (
                  circleStatsSWR.content?.dailyNewMembersCountList?.at(-1) ??
                  BigInt(0)
                ).toString(10),
              ),
            )}`}</StatsCardNumber>
            <StatsCardUpdateLabel>
              {i18n.clover_updated_daily()}
            </StatsCardUpdateLabel>
          </StatsCard>,
          <StatsCard>
            <StatsCardTitle>
              {i18n.circle_dc_daily_active_members()}
            </StatsCardTitle>
            <StatsCardNumber>{`${formatMoney(
              "medium",
              BigNumber(
                (
                  circleStatsSWR.content?.dailyActiveMembersCountList?.at(-1) ??
                  BigInt(0)
                ).toString(10),
              ),
            )}`}</StatsCardNumber>
            <StatsCardUpdateLabel>
              {i18n.clover_updated_daily()}
            </StatsCardUpdateLabel>
          </StatsCard>,
        ]}
      ></GridLayout>
      <CellDivider style={{ marginTop: 32, marginBottom: 32 }}></CellDivider>
      <SpeedTitle>{i18n.clover_circle_activity_stats()}</SpeedTitle>
      <GridLayout
        spanCount={2}
        items={[
          <StatsCard>
            <StatsCardTitle>{i18n.circle_dc_daily_new_posts()}</StatsCardTitle>
            <StatsCardNumber>{`${formatMoney(
              "medium",
              BigNumber(
                (
                  circleStatsSWR.content?.dailyNewBlogCountList?.at(-1) ??
                  BigInt(0)
                ).toString(10),
              ),
            )}`}</StatsCardNumber>
            <StatsCardUpdateLabel>
              {i18n.clover_updated_daily()}
            </StatsCardUpdateLabel>
          </StatsCard>,
          <StatsCard>
            <StatsCardTitle>{i18n.clover_daily_active_rooms()}</StatsCardTitle>
            <StatsCardNumber>{`${formatMoney(
              "medium",
              BigNumber(
                (
                  circleStatsSWR.content?.dailyNewChatsCountList?.at(-1) ??
                  BigInt(0)
                ).toString(10),
              ),
            )}`}</StatsCardNumber>
            <StatsCardUpdateLabel>
              {i18n.clover_updated_daily()}
            </StatsCardUpdateLabel>
          </StatsCard>,
        ]}
      ></GridLayout>
    </Page>
  );
}

const TokenTicker = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: var(--color-text00);
  ${alpha_on_pressed}
`;

const TokenPrice = styled.div`
  font-weight: 300;
  font-size: 14px;
  color: #0dffcc;
`;

const StatsCard = styled.div<{ $isShrinkable?: boolean }>`
  padding: 12px;
  display: flex;
  flex-direction: column;
  background: #ffffff0d;
  border: 1px solid #ffffff0f;
  border-radius: 6px;
  height: 84px;
  justify-content: space-between;

  ${(p) => p.$isShrinkable && shrink_on_pressed}
`;

const StatsCardTitle = styled.div`
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  color: var(--color-text00);
  height: 32px;
  padding-bottom: 4px;
  line-height: 14px;
`;

const StatsCardNumber = styled.div`
  font-size: 28px;
  font-weight: 700;
  text-align: left;
  color: var(--color-text00);
  line-height: 34px;
`;

const StatsCardUpdateLabel = styled.div`
  font-size: 10px;
  font-weight: 400;
  text-align: left;
  color: rgba(255, 255, 255, 0.6);
  line-height: 12px;
`;

export function marketCap1HipString(
  circleToken: CircleToken | {} | undefined,
): string {
  if (
    isValidCircleToken(circleToken) &&
    circleToken.tradingStatistics &&
    circleToken.tradingStatistics.marketCap1Hip
  ) {
    return `${formatPercentage(Number(circleToken.tradingStatistics.marketCap1Hip) / 100) || ""} (1h)`;
  }
  return "0% (1h)";
}

export function marketCap1HipColor(
  circleToken: CircleToken | {} | undefined,
): string {
  if (
    isValidCircleToken(circleToken) &&
    circleToken.tradingStatistics &&
    circleToken.tradingStatistics.marketCap1Hip
  ) {
    return circleToken.tradingStatistics.marketCap1Hip < 0
      ? "#EF3537"
      : circleToken.tradingStatistics.marketCap1Hip === 0
        ? "#B6B6B6"
        : "#00ff94";
  }
  return "#B6B6B6";
}

export function marketCap1HipBackgroundColor(
  circleToken: CircleToken | {} | undefined,
): string {
  if (
    isValidCircleToken(circleToken) &&
    circleToken.tradingStatistics &&
    circleToken.tradingStatistics.marketCap1Hip
  ) {
    return circleToken.tradingStatistics.marketCap1Hip < 0
      ? "#EF35371A"
      : circleToken.tradingStatistics.marketCap1Hip === 0
        ? "#B6B6B61A"
        : "#00ff941a";
  }
  return "#B6B6B61A";
}

export function circleBounceRateDisplayString(
  circle: Circle | undefined,
): string {
  return formatPercentage(circle?.bounceRate) || "";
}

export function circleTokenFluctuationImage(
  circleToken: CircleToken | {} | undefined,
): string | undefined {
  if (
    isValidCircleToken(circleToken) &&
    circleToken.tradingStatistics &&
    circleToken.tradingStatistics.marketCap1Hip
  ) {
    return circleToken.tradingStatistics.marketCap1Hip < 0
      ? icDecrease
      : circleToken.tradingStatistics.marketCap1Hip === 0
        ? undefined
        : icIncrease;
  }
  return undefined;
}

export default CircleStatsPage;
