import { StateId } from "../StateId";
import {
  CursorAndSize,
  getListActualArgs,
  SWRListConfig,
  useSWRList,
} from "./useSWRList";
import { omit } from "../../utils/pick";
import { SWRAccum } from "./SWRAccum";
import { EndPointLike } from "../../service/EndPoint";
import { PagedElement, PagedList } from "../../proto/PagedList";
import { AssetOrder } from "../../proto/AssetOrder";

export type SWRArrayResult<E> = SWRAccum<E[]> & { total: number | undefined };

export function useSWRArray<E extends {}>(
  contentId: StateId,
  fetcher: (pageParam: CursorAndSize) => Promise<PagedList<E>>,
  config?: SWRListConfig,
): SWRArrayResult<E>;

export function useSWRArray<E extends {}>(
  contentId: StateId | undefined,
  fetcher: ((pageParam: CursorAndSize) => Promise<PagedList<E>>) | undefined,
  config?: SWRListConfig,
): SWRArrayResult<E> | undefined;

export function useSWRArray<P extends PagedList<any>>(
  endPoint: EndPointLike<P>,
  config?: SWRListConfig,
): SWRArrayResult<PagedElement<P>>;

export function useSWRArray<P extends PagedList<any>>(
  endPoint: EndPointLike<P> | undefined,
  config?: SWRListConfig,
): SWRArrayResult<PagedElement<P>> | undefined;

export function useSWRArray(
  arg0: StateId | EndPointLike<PagedList<any>> | undefined,
  arg1?:
    | ((pageParam: CursorAndSize) => Promise<PagedList<any>>)
    | SWRListConfig,
  arg2?: SWRListConfig,
): SWRArrayResult<any> | undefined {
  const [contentId, fetcher, config] = getListActualArgs(arg0, arg1, arg2);
  const res = useSWRList(contentId, fetcher, config);
  if (res === undefined) {
    return undefined;
  } else {
    return {
      content: res.content?.list ?? [],
      ...omit(res, ["content"]),
      total: res.content?.total,
    };
  }
}
