import { LoadState, LoadStateKind } from "../LoadState";
import { JobCancelled, SWRJob } from "./SWRJob";
import { RepoRemoteReadStore, RepoStore } from "./RepoStore";
import { andLog } from "../../components/handleError";

export type RepoDataSource = "init" | "fetch" | "fill";

export type SWRRepoData<T, C> = {
  readonly content: T;
  readonly source: RepoDataSource;
  readonly updatedAt: number;
  readonly updatedMoreAt?: number;
  readonly cursor: C;
  readonly hasMore: boolean;
};

export type SWRFetchResult<T, C> = {
  readonly content: T;
  readonly cursor: C;
  readonly hasMore: boolean;
};

export interface SWRRepoSubscriber<T, C> {
  onDataDelete(): void;

  onDataChange(data: SWRRepoData<T, C>, fromCache: boolean): void;

  onLoadStateChange(loadState: LoadState | undefined): void;
}

export type SWRCachePolicy =
  | "neverRefetch"
  | "refetchOnCacheMiss"
  | "alwaysRefetch";

export class SWRFillCancelled extends Error {
  constructor() {
    super("SWR fill cancelled"); // call the parent constructor
    this.name = "SWRFillCancelled"; // set the name property
  }
}

export class SWRRepo<T, C> {
  private subscribers: Map<string, SWRRepoSubscriber<T, C>>;
  private loadState: LoadState | undefined;
  private dataInfo: { data: SWRRepoData<T, C>; fromCache: boolean } | undefined;

  constructor(
    readonly repoId: string,
    private readonly localStore: RepoStore<SWRRepoData<T, C>>,
    private readonly remoteStore: RepoRemoteReadStore<T, C> | undefined,
  ) {
    this.subscribers = new Map();
  }

  private loadingJob: SWRJob | undefined;

  private startJob() {
    const job = new SWRJob();
    this.loadingJob?.cancel();
    this.loadingJob = job;

    return job;
  }

  private endJob() {
    this.loadingJob = undefined;
  }

  private deleteDataAndNotify() {
    this.dataInfo = undefined;
    this.subscribers.forEach((r) => r.onDataDelete());
  }

  private setDataAndNotify(data: SWRRepoData<T, C>, fromCache: boolean) {
    this.dataInfo = { data, fromCache };
    this.subscribers.forEach((r) => r.onDataChange(data, fromCache));
  }

  private setLoadStateAndNotify(loadState: LoadState | undefined) {
    this.loadState = loadState;
    this.subscribers.forEach((r) => r.onLoadStateChange(loadState));
  }

  subscribe(
    id: string,
    subscriber: SWRRepoSubscriber<T, C>,
    reloadPolicy: SWRCachePolicy,
  ) {
    if (this.subscribers.get(id) === subscriber) return;

    this.subscribers.set(id, subscriber);
    if (this.dataInfo) {
      const { data, fromCache } = this.dataInfo;
      this.subscribers.forEach((r) => r.onDataChange(data, fromCache));
    }

    if (this.loadState) {
      this.subscribers.forEach((r) => r.onLoadStateChange(this.loadState));
    }

    this.doReload(reloadPolicy).catch(andLog);
  }

  unsubscribe(id: string) {
    this.subscribers.delete(id);
  }

  hasSubscribed(id: string) {
    return this.subscribers.has(id);
  }

  private async refetch(job: SWRJob) {
    const remoteStore = this.remoteStore;
    if (!remoteStore) return;

    const fetchResult = await job.run(() => remoteStore.get());
    const data: SWRRepoData<T, C> = {
      content: fetchResult.content,
      source: "fetch",
      updatedAt: new Date().getTime(),
      cursor: fetchResult.cursor,
      hasMore: fetchResult.hasMore,
    };
    this.setDataAndNotify(data, false);
    await job.run(() => this.localStore.put(data));
  }

  private async doReload(cachePolicy: SWRCachePolicy, reason?: string) {
    const job = this.startJob();

    this.setLoadStateAndNotify({ kind: LoadStateKind.loading, reason: reason });
    try {
      const storeResult = await job.run(() => this.localStore.get());
      if (storeResult) {
        this.setDataAndNotify(storeResult.content, true);
      }

      let shouldRefetch = false;
      switch (cachePolicy) {
        case "neverRefetch":
          shouldRefetch = false;
          break;
        case "alwaysRefetch":
          shouldRefetch = true;
          break;
        case "refetchOnCacheMiss":
          shouldRefetch = storeResult === undefined;
          break;
      }

      if (shouldRefetch) {
        await this.refetch(job);
      }

      this.endJob();
      this.setLoadStateAndNotify({ kind: LoadStateKind.loaded });
    } catch (e) {
      if (!(e instanceof JobCancelled)) {
        console.error(e);
        this.endJob();
        this.setLoadStateAndNotify({
          kind: LoadStateKind.loadFailed,
          error: e,
        });
      }
    }
  }

  async reload(reason?: string) {
    return await this.doReload("alwaysRefetch", reason);
  }

  async loadMore() {
    if (this.loadingJob) {
      return;
    }

    if (!this.dataInfo) return;

    const remoteStore = this.remoteStore;
    if (!remoteStore) return;

    const prevData = this.dataInfo.data;
    if (!prevData.hasMore) return;

    const job = this.startJob();

    try {
      this.setLoadStateAndNotify({ kind: LoadStateKind.loading });
      const fetchResult = await job.run(() =>
        remoteStore.getMore(prevData.content, prevData.cursor),
      );

      const data: SWRRepoData<T, C> = {
        content: fetchResult.content,
        source: "fetch",
        updatedAt: this.dataInfo.data.updatedAt,
        cursor: fetchResult.cursor,
        hasMore: fetchResult.hasMore,
        updatedMoreAt: new Date().getTime(),
      };
      this.setDataAndNotify(data, false);
      await job.run(() => this.localStore.put(data));

      this.endJob();
      this.setLoadStateAndNotify({ kind: LoadStateKind.loaded });
    } catch (e) {
      if (!(e instanceof JobCancelled)) {
        console.error(e);
        this.endJob();
        this.setLoadStateAndNotify({
          kind: LoadStateKind.loadFailed,
          error: e,
        });
      }
    }
  }

  private async doFill(result: SWRFetchResult<T, C>) {
    const job = this.startJob();
    this.setLoadStateAndNotify({ kind: LoadStateKind.loading });

    try {
      const data: SWRRepoData<T, C> = {
        content: result.content,
        source: "fill",
        updatedAt: new Date().getTime(),
        cursor: result.cursor,
        hasMore: result.hasMore,
      };

      this.setDataAndNotify(data, false);
      await job.run(() => this.localStore.put(data));

      this.endJob();
      this.setLoadStateAndNotify({ kind: LoadStateKind.loaded });
    } catch (e) {
      if (!(e instanceof JobCancelled)) {
        this.endJob();
        this.setLoadStateAndNotify({
          kind: LoadStateKind.loadFailed,
          error: e,
        });
      }
    }
  }

  async fill(
    resultOrSetResult:
      | SWRFetchResult<T, C>
      | ((prev: SWRRepoData<T, C> | undefined) => SWRFetchResult<T, C>),
  ) {
    if (typeof resultOrSetResult === "function") {
      const data = this.dataInfo?.data;
      try {
        const newResult = resultOrSetResult(data ?? undefined);
        await this.doFill(newResult);
        return newResult;
      } catch (e) {
        if (e instanceof SWRFillCancelled) {
          return data;
        } else {
          throw e;
        }
      }
    } else {
      await this.doFill(resultOrSetResult);
      return resultOrSetResult;
    }
  }

  async clear() {
    const job = this.startJob();
    this.setLoadStateAndNotify({ kind: LoadStateKind.loading });

    try {
      this.deleteDataAndNotify();
      await job.run(() => this.localStore.delete());

      this.endJob();
      this.setLoadStateAndNotify({ kind: LoadStateKind.loaded });
    } catch (e) {
      if (!(e instanceof JobCancelled)) {
        this.endJob();
        this.setLoadStateAndNotify({
          kind: LoadStateKind.loadFailed,
          error: e,
        });
      }
    }
  }
}

const repoMap = new Map<string, SWRRepo<any, any>>();

export function ensureRepo<T, C>(
  repoId: string,
  localStore: RepoStore<SWRRepoData<T, C>>,
  remoteStore: RepoRemoteReadStore<T, C> | undefined,
) {
  const repo = repoMap.get(repoId);

  if (repo) return repo as SWRRepo<T, C>;

  const newRepo = new SWRRepo<T, C>(repoId, localStore, remoteStore);
  repoMap.set(repoId, newRepo);
  return newRepo;
}

export function ensureSubscription<T, C>(
  subscriberId: string,
  repo: SWRRepo<T, C>,
  reloadPolicy: SWRCachePolicy,
  subscriber: SWRRepoSubscriber<T, C>,
) {
  if (!repo.hasSubscribed(subscriberId)) {
    repo.subscribe(subscriberId, subscriber, reloadPolicy);
  }

  repoMap.forEach((r, id) => {
    if (repo.repoId !== id) {
      if (r.hasSubscribed(subscriberId)) {
        r.unsubscribe(subscriberId);
      }
    }
  });
}
