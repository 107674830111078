import styled, { css } from "styled-components";
import BigNumber from "bignumber.js";
import {
  Currency,
  formatMoney,
  getCurrencyAmount,
} from "../../../proto/Currency";
import { CenterModal, ModalController } from "../../../components/Modal";
import { WalletAccount } from "../../../proto/Wallet";
import { useEditingBigNumber } from "../../../hooks/useEditingBigNumber";
import React, { useState } from "react";
import { useNativePage } from "../../../hooks/useBridge";
import { andLog } from "../../../components/handleError";
import { jsonifyBigNumber } from "../../../utils/CurrencyAmount";
import { useI18n } from "../../../hooks/useI18n";
import { NumberInput } from "../../../components/NumberInput";
import { HSpace, HStack } from "../../../components/VStack";
import { CurrencyIcon } from "../../../components/views/CurrencyIcon";
import { ButtonColor, RegularButton } from "../../../components/Buttons";

const SectionTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: white;
  text-align: start;
  width: 100%;
`;

const SectionDescription = styled.div`
  font-weight: 300;
  font-size: 12px;
  color: white;
  width: 100%;
  text-align: start;
`;

const CurrencyCode = styled.div`
  color: white;
  font-weight: 500;
  font-size: 16px;
`;

export type DexTradeAdvanceSetting = {
  slippage: BigNumber;
  priorityFee: Currency;
};

const kMinPriorityFee = BigNumber(0.003);

export function CircleTokenTradeAdvanceModal(props: {
  modal: ModalController;
  wallet: WalletAccount;
  priorityFee: Currency;
  onSave: (settings: DexTradeAdvanceSetting) => void;
  slippage: BigNumber;
}) {
  const DefaultSlippage = BigNumber(5);

  const [slippageText, setSlippageText, slippage, setSlippage] =
    useEditingBigNumber<BigNumber | undefined>(
      "customSlippage",
      props.slippage,
    );

  const [localPriorityFee, setLocalPriorityFee] = useState<Currency>(
    props.priorityFee,
  );

  const [localFeeText, setLocalFeeText, feeCount, setFeeCount] =
    useEditingBigNumber(
      "customLocalPriorityFee",
      getCurrencyAmount(props.priorityFee),
    );

  const nativePage = useNativePage();

  async function saveSettings() {
    if (slippage !== undefined && slippage.comparedTo(BigNumber(0)) === 0) {
      nativePage
        .alertNotice(i18n.circle_fin_invalid_slippage(), i18n.ok())
        .catch(andLog);
      return;
    }
    if (
      feeCount.isLessThan(kMinPriorityFee) &&
      !(await nativePage.alertAreYouSure(
        i18n.circle_fin_priority_fee_warning(
          formatMoney("long", kMinPriorityFee),
        ),
        i18n.circle_fin_save_anyway(),
        i18n.edit(),
      ))
    ) {
      return;
    }

    props.modal.close(() => {
      props.onSave({
        slippage: slippage ?? DefaultSlippage,
        priorityFee: {
          ...localPriorityFee,
          amount: jsonifyBigNumber(feeCount, props.priorityFee.decimals),
        },
      });
      if (slippage === undefined) {
        setSlippage(DefaultSlippage);
      }
    });
  }

  const i18n = useI18n();
  return (
    <>
      <CenterModal
        modal={props.modal}
        mixin={css`
          padding: 40px 20px 32px;
          gap: 8px;
        `}
      >
        <SectionTitle>{i18n.circle_fin_set_max_slippage()}</SectionTitle>
        <div style={{ width: "100%" }}>
          <NumberInput
            value={slippageText}
            type={"number"}
            decimal={true}
            placeholder={"5"}
            onTextChange={(value) => {
              if (value.length > 0) {
                const floatValue = BigNumber(value.slice(0, 8));
                console.log(floatValue.toString());
                if (floatValue.comparedTo(BigNumber(25)) > 0) {
                  const v = BigNumber(25);
                  setSlippageText(v.toString());
                  setSlippage(v);
                } else {
                  let v = BigNumber(value);
                  const rightValue = v.decimalPlaces(7, BigNumber.ROUND_DOWN);
                  if (v.comparedTo(rightValue) === 0) {
                    setSlippageText(value);
                    setSlippage(v);
                  } else {
                    setSlippageText(rightValue.toString());
                    setSlippage(rightValue);
                  }
                }
              } else {
                setSlippageText("0");
              }
            }}
            textAlign={"start"}
            cssProperties={{
              height: 40,
            }}
          />
        </div>
        <SectionDescription>
          {i18n.circle_fin_set_max_slippage_desc()}
        </SectionDescription>
        <SectionTitle>{i18n.circle_fin_priority_fee()}</SectionTitle>
        <div style={{ width: "100%" }}>
          <HStack
            style={{
              marginTop: 8,
              backgroundColor: "rgba(255, 255, 255, 0.06)",
              borderRadius: 6,
            }}
          >
            <HSpace width={14} />
            <NumberInput
              decimal={true}
              type={"number"}
              placeholder={"0.00"}
              value={localFeeText}
              textAlign={"start"}
              cssProperties={{
                padding: 0,
                background: "none",
                flexGrow: 10,
              }}
              onTextChange={(value) => {
                if (value.length > 0) {
                  const floatValue = BigNumber(value.slice(0, 8));
                  console.log(floatValue.toString());
                  if (floatValue.comparedTo(BigNumber("0.05")) > 0) {
                    setLocalFeeText("0.05");
                  } else {
                    setLocalFeeText(value.slice(0, 8));
                  }
                } else {
                  setLocalFeeText("0");
                }
              }}
            />
            <CurrencyIcon currency={localPriorityFee} size={24} />
            <HSpace width={5} />
            <CurrencyCode>{localPriorityFee.currencyCode}</CurrencyCode>
            <HSpace width={14} style={{ height: 4 }} />
          </HStack>
        </div>
        <SectionDescription>
          {i18n.circle_fin_priority_fee_desc()}
        </SectionDescription>

        <RegularButton
          color={ButtonColor.greenish}
          style={{ width: "100%", marginTop: 32 }}
          onClick={() => saveSettings()}
        >
          {i18n.circle_fin_save_changes()}
        </RegularButton>
      </CenterModal>
    </>
  );
}
