import { Page } from "../../../components/Page";
import { useSWRArray } from "../../../hooks/swr/useSWRArray";
import { useBackend } from "../../../service/APIService";
import { useWalletSWR } from "../useWallet";
import {
  useBigIntParam,
  useNumberSearchParam,
} from "../../../hooks/useTypedParam";
import TransactionCell from "../account/TransactionCell";
import { NavMiddle } from "../../../components/NavBar";
import { useI18n } from "../../../hooks/useI18n";
import { AccountType } from "../../../proto/AccountType";
import { HStack, HStackMixin } from "../../../components/VStack";
import styled from "styled-components";
import icChevronDown from "../../../res/images/chevorn_down.svg";
import { BSMenu, BSMenuItem } from "../../../components/BSMenu";
import { CurrencyId, CurrencyType } from "../../../proto/Currency";
import { useMemo, useState } from "react";
import { useModal } from "../../../components/Modal";
import { FullPageWidthCell } from "../../../components/CommonStyles";
import { TransactionQueryType } from "../utils/Transaction";
import { useHopState } from "../../../hooks/useHopState";
import { DefaultEmptyView } from "../../../components/LoadStateView";
import { LoadStateKind } from "../../../hooks/LoadState";
import { PageHeader } from "../../../components/PageHeaderFooter";

export function TransactionHistoryPage() {
  const backend = useBackend();
  const accountId = useBigIntParam("accountId");
  const accountType = useNumberSearchParam("accountType");
  const i18n = useI18n();
  const transactionActivityMenu = useModal("transaction-activity-menu");
  const transactionAssetMenu = useModal("transaction-asset-menu");

  const [typeIndex, setTypeIndex] = useHopState<number>(
    "transaction-history-type",
    0,
  );
  const [assetIndex, setAssetIndex] = useHopState<number>(
    "transaction-history-asset",
    0,
  );

  const typeList = [
    { text: i18n.web3_v0_all_activities(), type: 0 },
    { text: i18n.web3_v0_buy(), type: TransactionQueryType.TxQueryTypeBuy },
    { text: i18n.clover_trade(), type: TransactionQueryType.TxQueryTypeTrade },
    { text: i18n.send(), type: TransactionQueryType.TxQueryTypeSendTokens },
    {
      text: i18n.web3_v0_gift(),
      type: TransactionQueryType.TxQueryTypeGift,
    },
    {
      text: i18n.transfer(),
      type: TransactionQueryType.TxQueryTypeStoreTransfer,
    },
    {
      text: i18n.clover_receive(),
      type: TransactionQueryType.TxQueryTypeReceive,
    },
    {
      text: i18n.web3_redeem_redeem(),
      type: TransactionQueryType.TxQueryTypeRedeem,
    },
    {
      text: i18n.clover_button_stake(),
      type: TransactionQueryType.TxQueryTypeStake,
    },
    {
      text: i18n.create(),
      type: TransactionQueryType.TxQueryTypeNFTCreate,
    },
    {
      text: i18n.web3_hongbao_top_up(),
      type: TransactionQueryType.TxQueryTypeTopUp,
    },
    {
      text: i18n.web3_v0_service_fee(),
      type: TransactionQueryType.TxQueryTypeGasFee,
    },{
      text: i18n.web3_v0_swap(),
      type: TransactionQueryType.TxQueryTypeSwap,
    },
  ];

  const [wallet] = useWalletSWR();

  const tokenList = wallet?.walletAccountList
    .find((account) => account.accountType === AccountType.BuiltIn)
    ?.assetAccountList.find(
      (account) =>
        (account.address?.length || 0) > 0 &&
        (account.currencyList.length || 0) > 0,
    )
    ?.currencyList.filter(
      (currency) => currency.currencyType === CurrencyType.SOL_TOKENS,
    );

  const assetList = useMemo(() => {
    const list: {
      text: string | undefined;
      currencyId: string | undefined;
      currencyType: string | undefined;
      queryMembershipCard: boolean | undefined;
    }[] = [
      {
        text: i18n.web3_v0_all_assets(),
        currencyId: undefined,
        currencyType: undefined,
        queryMembershipCard: undefined,
      },
      {
        text: i18n.web3_v0_diamonds(),
        currencyId: CurrencyId.GEMS.toString(),
        currencyType: undefined,
        queryMembershipCard: undefined,
      },
      {
        text: "SOL",
        currencyId: undefined,
        currencyType: CurrencyType.SOL.toString(),
        queryMembershipCard: undefined,
      },
      {
        text: i18n.web3_v0_collectibles(),
        currencyId: undefined,
        currencyType: CurrencyType.NFT.toString(),
        queryMembershipCard: undefined,
      },
      {
        text: i18n.clover_coins(),
        currencyId: undefined,
        currencyType: CurrencyType.COIN.toString(),
        queryMembershipCard: undefined,
      },
      {
        text: i18n.clover_diamonds(),
        currencyId: undefined,
        currencyType: CurrencyType.DIAMOND.toString(),
        queryMembershipCard: undefined,
      },
    ];

    if (tokenList) {
      const newList = tokenList.map((token) => {
        return {
          text: token.balance?.symbol,
          currencyId: token.currencyId.toString(),
          currencyType: undefined,
          queryMembershipCard: undefined,
        };
      });
      list.push(...newList);
    }

    list.push({
      text: i18n.member_title_membership_card(),
      currencyType: undefined,
      currencyId: undefined,
      queryMembershipCard: true,
    });
    return list;
  }, [tokenList]);

  const historySWR = useSWRArray(
    accountId
      ? backend.getWalletTransactionLogs(
          accountId,
          typeList?.at(typeIndex || 0)?.type,
          assetList?.at(assetIndex || 0)?.currencyType,
          assetList?.at(assetIndex || 0)?.currencyId,
          assetList?.at(assetIndex || 0)?.queryMembershipCard,
        )
      : undefined,
  );

  return (
    <Page pageData={historySWR} alwaysShowsContent={true}>
      <PageHeader>
        <HStack style={{ gap: 12, marginLeft: 20, marginBottom: 20 }}>
          <FilterContainer onClick={() => transactionActivityMenu.open()}>
            <FilterText>{typeList.at(typeIndex || 0)?.text}</FilterText>
            <FilterImg src={icChevronDown} />
          </FilterContainer>
          <FilterContainer onClick={() => transactionAssetMenu.open()}>
            <FilterText>{assetList.at(assetIndex || 0)?.text}</FilterText>
            <FilterImg src={icChevronDown} />
          </FilterContainer>
        </HStack>
      </PageHeader>

      <TransactionTitle style={{ ...FullPageWidthCell }}>
        {i18n.web3_v0_transaction_history()}
      </TransactionTitle>

      {historySWR?.content.map((log) => (
        <TransactionCell transaction={log} accountId={accountId} />
      ))}

      {historySWR?.content.length === 0 &&
        historySWR.loadState?.kind === LoadStateKind.loaded && (
          <DefaultEmptyView title={i18n.no_content_yet()} topSpace={100} />
        )}

      <BSMenu modal={transactionActivityMenu}>
        {typeList.map((item, index) => (
          <BSMenuItem
            title={item.text}
            onClick={() => {
              setTypeIndex(index);
            }}
            selected={typeIndex === index}
          />
        ))}
      </BSMenu>
      <BSMenu modal={transactionAssetMenu}>
        {assetList.map((item, index) => (
          <BSMenuItem
            title={item.text}
            onClick={() => {
              setAssetIndex(index);
            }}
            selected={assetIndex === index}
          />
        ))}
      </BSMenu>
      <NavMiddle>{i18n.web3_v0_transaction_history()}</NavMiddle>
    </Page>
  );
}

const FilterContainer = styled.div`
  gap: 4px;
  padding: 4px 4px 4px 12px;
  border-radius: 8px;
  background-color: #ffffff0a;
  ${HStackMixin};
`;

const FilterText = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: white;
`;
const FilterImg = styled.img`
  width: 20px;
  height: 20px;
`;

const TransactionTitle = styled.div`
  background-color: #ffffff26;
  font-size: 12px;
  font-weight: 300;
  color: #ffffffcc;
  padding-left: 14px;
  padding-top: 10px;
  padding-bottom: 9px;
`;
