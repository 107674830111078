import { Page } from "../../../components/Page";
import { useBackend } from "../../../service/APIService";
import { useSWRArray } from "../../../hooks/swr/useSWRArray";
import styled, { css, RuleSet } from "styled-components";
import {
  HStack,
  Spring,
  VStack,
  VStackMixin,
} from "../../../components/VStack";
import { Image } from "../../../components/Image";
import {
  isAirdrop,
  SourceType,
  TransferOrder,
  TransferOrderStatus,
  TransferOrderType,
} from "../../../proto/TransferOrder";
import { OverFlowTextStyle } from "../../../components/Text";
import { useCountDown } from "../../../hooks/useCountDown";
import { useI18n } from "../../../hooks/useI18n";
import { GiftBoxStatus } from "../../../proto/GiftBox";
import { NavMiddle } from "../../../components/NavBar";
import use from "@starrah/scope-extensions-js";
import { useEffect, useMemo, useState } from "react";
import { RegularSmallButton } from "../../../components/Buttons";
import { useMyUid } from "../../../service/AuthSessionService";
import {
  formatDateWithOptions,
  shortFromDate,
  timeIntervalString,
  toHHMMSS,
} from "../../../utils/DateTimeUtils";
import {
  Currency,
  CurrencyType,
  formatMoney,
  getCurrencyIconByCurrencyType,
} from "../../../proto/Currency";
import iconGems from "../../../res/images/icon_gems.svg";
import { useHopper } from "../../../hooks/useHopper";
import { useNativePage, useWebHost } from "../../../hooks/useBridge";
import { andLog } from "../../../components/handleError";
import { OrderType } from "./OrderDetailPage";
import { GiftOrderClaimDialog } from "./GiftOrderClaimDialog";
import { useModal } from "../../../components/Modal";
import icGiftNotOpen from "../../../res/images/ic_gift_box_not_opened.png";
import icGiftOpen from "../../../res/images/icon_gift_box_received_empty.png";
import icCheckIn from "../../../res/images/ic_gift_check_in.png";
import { getUserTaskNameByType } from "../utils/UserTask";
import { useEnumSearchParam } from "../../../hooks/useTypedParam";
import { WalletBannerType } from "../WalletBannerCell";
import { useHopState } from "../../../hooks/useHopState";
import { useSWR } from "../../../hooks/swr/useSWR";
import { useMarkOrderAsRead } from "./useMarkOrderAsRead";

export function GiftOrderListPage() {
  const backend = useBackend();
  const i18n = useI18n();
  const giftListSWR = useSWRArray(backend.getTransferOrderList());
  useMarkOrderAsRead();

  return (
    <Page pageData={giftListSWR}>
      {giftListSWR.content.map((order) => (
        <GiftOrderCell
          key={`transfer-order-${order.orderId}`}
          transferOrder={order}
          refresh={giftListSWR.load}
        />
      ))}
      <NavMiddle>{i18n.web3_v0_gifting_history()}</NavMiddle>
    </Page>
  );
}

function GiftOrderCell(props: {
  transferOrder?: TransferOrder;
  refresh: () => void;
}) {
  const i18n = useI18n();
  const myUid = useMyUid();
  const hopper = useHopper();
  const backend = useBackend();
  const nativePage = useNativePage();
  const giftClaimingModal = useModal("giftClaimingModal");

  const orderStatus = useMemo(() => {
    if (props.transferOrder?.orderStatus == TransferOrderStatus.PENDING) {
      const startTime = (props.transferOrder.minClaimedTime || 0) * 1000;
      const now = new Date().getTime();
      if (now > startTime) {
        const expiredTime = (props.transferOrder.expiredTimeV2 || 0) * 1000;
        if (now < expiredTime) {
          return TransferOrderStatus.PENDING;
        } else {
          return TransferOrderStatus.RETURN;
        }
      } else {
        return TransferOrderStatus.WAITING_TO_START;
      }
    } else {
      return props.transferOrder?.orderStatus;
    }
  }, [props.transferOrder]);

  const remainingTimeToExpire = useCountDown(() => {
    if (
      props.transferOrder?.expiredTimeV2 !== undefined &&
      orderStatus === TransferOrderStatus.PENDING
    ) {
      return (
        (props.transferOrder.expiredTimeV2 * 1000 - new Date().getTime()) / 1000
      );
    } else {
      return 0;
    }
  }, [props.transferOrder]);

  const remainingTimeToStart = useCountDown(() => {
    if (
      props.transferOrder?.minClaimedTime !== undefined &&
      orderStatus === TransferOrderStatus.WAITING_TO_START
    ) {
      return (
        (props.transferOrder?.minClaimedTime * 1000 - new Date().getTime()) /
        1000
      );
    } else {
      return 0;
    }
  }, [props.transferOrder]);

  function getStatus() {
    if (orderStatus === TransferOrderStatus.DONE) {
      return { text: i18n.web3_v0_completed(), color: "#00FF94" };
    } else if (orderStatus === TransferOrderStatus.PENDING) {
      return { text: i18n.web3_v0_pending(), color: "#00FF94" };
    } else if (
      (!props.transferOrder?.giftBox &&
        orderStatus === TransferOrderStatus.RETURN) ||
      (props.transferOrder?.giftBox &&
        props.transferOrder?.giftBox.giftBoxStatus !==
          GiftBoxStatus.Withdrawn &&
        orderStatus === TransferOrderStatus.RETURN)
    ) {
      return { text: i18n.web3_v0_expired(), color: "#FFFFFF66" };
    } else if (
      orderStatus === TransferOrderStatus.RETURN &&
      props.transferOrder?.giftBox?.giftBoxStatus === GiftBoxStatus.Withdrawn
    ) {
      return { text: i18n.web3_hongbao_status_withdrawn(), color: "#FFFFFF66" };
    } else {
      return { text: "", color: "#FFFFFF66" };
    }
  }

  const isOrderPendingOrNotStarted =
    orderStatus === TransferOrderStatus.PENDING ||
    orderStatus === TransferOrderStatus.WAITING_TO_START;

  const isSentToMe =
    props.transferOrder?.toUid === myUid ||
    props.transferOrder?.orderType === TransferOrderType.ClaimedGiftBox ||
    isAirdrop(props.transferOrder?.orderType);

  const isSentByMe = props.transferOrder?.fromUid == myUid;

  const isAcceptButtonEnable = orderStatus == TransferOrderStatus.PENDING;

  const isCheckInGift =
    props.transferOrder?.orderType === TransferOrderType.AirdropCheckInRewards;

  const isNewUserTaskGift =
    props.transferOrder?.orderType === TransferOrderType.NewUserTask ||
    props.transferOrder?.orderType === TransferOrderType.AdsRewardTask ||
    props.transferOrder?.orderType === TransferOrderType.UserTaskRewardV2;

  const giftName = () => {
    if (isSentByMe && !isSentToMe) {
      return i18n.web3_hongbao_you_have_send_a_gift();
    }

    if (isSentToMe) {
      if (isCheckInGift) {
        return i18n.web3_checkin_check_in_rewards();
      }
      if (isNewUserTaskGift && props.transferOrder?.extensions?.userTaskType) {
        return getUserTaskNameByType(
          props.transferOrder?.extensions?.userTaskType,
          true,
          i18n,
        );
      }
      switch (props.transferOrder?.orderType) {
        case TransferOrderType.AirdropBase:
        case TransferOrderType.AirdropBonus: {
          if (
            props.transferOrder.extensions?.sourceType ===
            SourceType.MonthlyGift
          ) {
            return i18n.membership_monthly_gifts();
          } else {
            return i18n.newbie_task_rewards();
          }
        }
        case TransferOrderType.AirdropAdvanced:
        case TransferOrderType.AirdropAdvancedDays: {
          if (
            props.transferOrder.extensions?.sourceType ===
            SourceType.MonthlyGift
          ) {
            return i18n.membership_monthly_gifts();
          } else {
            return i18n.newbie_task_airdrop_rewards();
          }
        }
        case TransferOrderType.TransferTypeKOLAirdropBase:
        case TransferOrderType.TransferTypeKOLAirdropBonus:
          return i18n.newbie_task_rewards_from_kol();
        case TransferOrderType.TransferTypeRecallCompensation:
          return i18n.newbie_task_recall_compensation();
        case TransferOrderType.TransferTypeRecallCustomize:
          return (
            props.transferOrder.giftBox?.title || i18n.web3_hongbao_gift_box()
          );
        case TransferOrderType.AdsRewardTask:
          return i18n.rewarded_video_ad_rewards();
        default:
          return i18n.web3_hongbao_gift_box();
      }
    }
  };

  function getAmountText() {
    if (
      props.transferOrder?.currencyType === CurrencyType.COUPON ||
      props.transferOrder?.currencyType === CurrencyType.NFT ||
      props.transferOrder?.currencyType === CurrencyType.PASSES
    ) {
      return `× ${props.transferOrder.currency?.amount}`;
    } else if (props.transferOrder?.currency) {
      return formatMoney("medium", props.transferOrder.currency);
    }
  }

  function getCurrencyIcon() {
    if (props.transferOrder?.currencyType === CurrencyType.NFT) {
      return undefined;
    } else {
      return getCurrencyIconByCurrencyType(props.transferOrder?.currencyType);
    }
  }

  const shouldFormatMoney = !(
    props.transferOrder?.currencyType === CurrencyType.NFT ||
    props.transferOrder?.currencyType === CurrencyType.COUPON ||
    props.transferOrder?.currencyType === CurrencyType.PASSES
  );

  const getGiftIcon = () => {
    if (orderStatus === TransferOrderStatus.DONE && !isSentByMe) {
      return icGiftOpen;
    } else {
      return icGiftNotOpen;
    }
  };

  return (
    <OrderCellContainer
      onClick={() => {
        if (isSentToMe && isOrderPendingOrNotStarted && isAcceptButtonEnable) {
          giftClaimingModal.open();
        } else {
          hopper.push(`wallet/order/${props.transferOrder?.orderId}`, {
            orderType: OrderType.GiftOrder,
          });
        }
      }}
    >
      <HStack style={{ gap: 6 }}>
        <Image
          src={[props.transferOrder?.fromUser?.icon, { width: 20, height: 20 }]}
          style={{ borderRadius: "50%" }}
          width={20}
          height={20}
        />
        <OrderSenderName>
          {props.transferOrder?.fromUser?.nickname}
        </OrderSenderName>
        <OrderCreatedTime>
          {formatDateWithOptions(props.transferOrder?.createdTime || 0, {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          })}
        </OrderCreatedTime>
      </HStack>
      <OrderDivider />
      <HStack style={{ gap: 12, alignItems: "start", width: "100%" }}>
        <ImageContainer>
          <Image width={44} height={44} src={getGiftIcon()} />
        </ImageContainer>

        <VStack style={{ flex: 1 }}>
          <OrderNameText>{giftName()}</OrderNameText>
          <HStack style={{ flex: 1, marginTop: 8, gap: 4 }}>
            {props.transferOrder?.currencyType === CurrencyType.NFT && (
              <Image
                src={[
                  props.transferOrder.nft?.entity?.preview,
                  { width: 16, height: 16 },
                ]}
                width={26}
                height={26}
                style={{ borderRadius: 4 }}
              />
            )}
            {props.transferOrder?.currencyType !== CurrencyType.NFT &&
              getCurrencyIcon() && (
                <Image src={getCurrencyIcon()} width={16} height={16} />
              )}
            <AmountText
              style={{
                fontWeight: shouldFormatMoney ? 700 : 500,
                fontSize: shouldFormatMoney ? 16 : 11,
              }}
            >
              {getAmountText()}
            </AmountText>
          </HStack>
        </VStack>
      </HStack>
      <HStack style={{ flex: 1 }}>
        {isOrderPendingOrNotStarted && (
          <RemainingTime>
            {remainingTimeToExpire > 0 &&
              i18n.web3_v0_expiring_in(toHHMMSS(remainingTimeToExpire))}
            {remainingTimeToStart > 0 &&
              i18n.web3_v0_starting_in(toHHMMSS(remainingTimeToStart))}
          </RemainingTime>
        )}

        <Spring />
        {getStatus().text.length > 0 &&
          (isSentByMe ||
            (!isSentByMe && orderStatus !== TransferOrderStatus.PENDING)) && (
            <StatusBar
              statusText={getStatus().text}
              statusColor={getStatus().color}
            />
          )}
        {isSentToMe && isOrderPendingOrNotStarted && (
          <RegularSmallButton
            $baseColor={isAcceptButtonEnable ? "#0DFFCC" : "#FFFFFF"}
            style={{
              color: isAcceptButtonEnable ? "#94FFDE" : "#FFFFFF",
              background: isAcceptButtonEnable ? "#002B30B2" : undefined,
              fontSize: 14,
              minWidth: 91,
            }}
            disabled={!isAcceptButtonEnable}
            onClick={(event) => {
              event.stopPropagation();
              giftClaimingModal.open();
            }}
          >
            {i18n.accept()}
          </RegularSmallButton>
        )}
      </HStack>
      {props.transferOrder?.giftBox && (
        <GiftOrderClaimDialog
          modal={giftClaimingModal}
          giftBox={props.transferOrder?.giftBox}
          transferOrderId={props.transferOrder.orderId}
          updateStatus={() => {
            props.refresh();
          }}
        />
      )}
    </OrderCellContainer>
  );
}

export const OrderCellContainer = styled.div`
  border-radius: 12px;
  ${VStackMixin};
  gap: 12px;
  background-color: #ffffff0f;
  padding: 12px;
  margin-bottom: 20px;
`;

export const OrderSenderName = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: #ffffff99;
  flex: 1;
  ${OverFlowTextStyle};
  line-clamp: 1;
  -webkit-line-clamp: 1;
`;

export const OrderCreatedTime = styled.div`
  font-weight: 300;
  font-size: 12px;
  color: #ffffff66;
`;

export const OrderDivider = styled.div`
  width: 100%;
  background-color: #ffffff0f;
  height: 1px;
`;

export const ImageContainer = styled.div`
  width: 74px;
  height: 74px;
  background-color: #00000033;
  border-radius: 6px;
  display: grid;
  align-items: center;
  justify-content: center;
`;

export const OrderNameText = styled.div`
  font-weight: 800;
  font-size: 14px;
  flex: 1;
  color: white;
  ${OverFlowTextStyle};
  line-clamp: 2;
  -webkit-line-clamp: 2;
`;

const AmountText = styled.div`
  color: #ffffffcc;
`;

export function StatusBar(props: {
  statusColor: string;
  statusText: string;
  statusBarStyle?: RuleSet<object>;
}) {
  return (
    <HStack
      mixin={props.statusBarStyle}
      style={{
        borderRadius: 4,
        padding: "2px 8px",
        backgroundColor: "rgba(0, 0, 0, 0.3)",
        gap: 4,
      }}
    >
      <StatusDot style={{ background: props.statusColor }} />
      <StatusText style={{ color: props.statusColor }}>
        {props.statusText}
      </StatusText>
    </HStack>
  );
}

const StatusDot = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
`;

const StatusText = styled.div`
  font-size: 12px;
  font-weight: 400;
`;

const RemainingTime = styled.div`
  font-size: 11px;
  font-weight: 400;
  color: #ff793e;
`;
