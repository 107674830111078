import { z } from "zod";
import { zBigInt, zStatic } from "../utils/zodUtils";
import { Reaction } from "./Reaction";
import { User } from "./User";
import { GiftBoxExtensions } from "./GiftBoxExtensions";
import { GiftBoxServiceFee } from "./GiftBoxServiceFee";
import { Coupon } from "./Coupon";

export const BareGiftBox = z.object({
  boxId: zBigInt,
  uid: zBigInt.optional(),
  toObjectId: zBigInt.default(0),
  assetId: zBigInt.optional(),
  createdTime: z.number().optional(),
  expiredTime: z.number().optional(),
  lastReadTime: z.number().optional(),
  allClaimedTime: z.number().optional(),
  amount: z.string().optional(),
  claimedAmount: z.string().optional(),
  paymentPassword: z.string().optional(),
  toObjectType: z.number().default(0),
  currencyType: z.number().optional(),
  lightCurrencyType: z.number().optional(),
  claimedCount: z.number().optional(),
  maxClaimedCount: z.number().optional(),
  distributeMode: z.number().optional(),
  giftBoxStatus: z.number().optional(),
  coupon: Coupon.optional(),
  title: z.string().optional(),
  claimCode: z.string().optional(),
  extensions: GiftBoxExtensions.optional(),
  currentTransferOrderId: zBigInt.optional(),
  reactionCountList: z.array(Reaction).optional(),
  user: User.optional(),
  returnAmount: z.string().optional(),
  currentUserClaimedAmount: z.string().optional(),
  expiredTimeV2: zBigInt.optional(),
  serviceFee: GiftBoxServiceFee.optional(),
});

export type BareGiftBox = zStatic<typeof BareGiftBox>;
