import React, { PropsWithChildren, useMemo } from "react";
import { usePageSpec } from "../pages/common/usePageSpec";
import { WideAppShell } from "./WideAppShell";
import { NarrowAppShell } from "./NarrowAppShell";
import { AuthSessionService, useMyUid } from "../service/AuthSessionService";
import { HttpClientService } from "../service/HttpClientService";
import { APIService } from "../service/APIService";
import { WebSocketService } from "../service/WebSocketService";
import { HopHost, HopHostContext } from "../hooks/useHopState";
import { LayerManager } from "./LayerManager";
import { RepoStoreBuilder } from "../service/RepoStoreService";
import { RepoStore } from "../hooks/swr/RepoStore";
import { IDBStore, Store } from "../utils/DB";
import { flattenStateId } from "../hooks/StateId";

const webMemoryMap = new Map<
  string,
  {
    content: unknown;
    updatedAt: number;
  }
>();

export class WebMemoryRepoStore<T> implements RepoStore<T> {
  id: string;

  constructor(
    readonly myUid: bigint,
    readonly contentId: string,
  ) {
    this.id = flattenStateId([this.myUid, this.contentId]);
  }

  async delete(): Promise<void> {
    webMemoryMap.delete(this.id);
  }

  async get(): Promise<{ content: T; updatedAt: number } | undefined> {
    const r = webMemoryMap.get(this.id);
    if (!r) return undefined;
    return {
      content: r.content as T,
      updatedAt: r.updatedAt,
    };
  }

  async put(content: T): Promise<void> {
    webMemoryMap.set(this.id, {
      content: content,
      updatedAt: new Date().getTime(),
    });
  }
}

function RepoStoreService(props: PropsWithChildren<{}>) {
  const myUid = useMyUid();

  return (
    <RepoStoreBuilder.Provider
      value={{
        createLocalRepoStore<T>(contentId: string): RepoStore<T> {
          return new IDBStore<T>(Store.SWR, contentId, myUid);
        },
        createMemoryRepoStore<T>(contentId: string): RepoStore<T> {
          return new WebMemoryRepoStore<T>(myUid, contentId);
        },
      }}
    >
      {props.children}
    </RepoStoreBuilder.Provider>
  );
}

function HopHostService(props: PropsWithChildren<{}>) {
  const hopHosts = useMemo(() => new Map<string, HopHost>(), []);

  return (
    <HopHostContext.Provider
      value={{
        hopHostForId: (pageId: string) => {
          const hopHost = hopHosts.get(pageId);
          if (hopHost) return hopHost;
          const newHopHost = new HopHost(pageId);
          hopHosts.set(pageId, newHopHost);
          return newHopHost;
        },
      }}
    >
      {props.children}
    </HopHostContext.Provider>
  );
}

export function AppShell() {
  const pageSpec = usePageSpec();

  return (
    <AuthSessionService>
      <RepoStoreService>
        <HttpClientService>
          <APIService>
            <WebSocketService>
              <HopHostService>
                <LayerManager>
                  {pageSpec === "wide" ? <WideAppShell /> : <NarrowAppShell />}
                </LayerManager>
              </HopHostService>
            </WebSocketService>
          </APIService>
        </HttpClientService>
      </RepoStoreService>
    </AuthSessionService>
  );
}
