import {
  CenterModal,
  ModalTitle,
  useModalWithItsOwnPage,
} from "../../components/Modal";
import { Image } from "../../components/Image";
import { useI18n } from "../../hooks/useI18n";
import React from "react";
import { Page } from "../../components/Page";
import { Spring, VSpace } from "../../components/VStack";
import logo from "../../res/images/logo_clover.svg";
import { DownloadApps } from "../../components/DownloadApps";
import { useBooleanSearchParam } from "../../hooks/useTypedParam";

export function NotYetImplModalPage() {
  const modal = useModalWithItsOwnPage();
  const getApp = useBooleanSearchParam("getApp");
  const i18n = useI18n();
  return (
    <CenterModal modal={modal}>
      <ModalTitle>
        {getApp ? i18n.clover_get_app_title() : i18n.clover_nyi_title()}
      </ModalTitle>
      <DownloadApps />
    </CenterModal>
  );
}

export function NotYetImplPage() {
  const i18n = useI18n();
  return (
    <Page pageData={undefined} maxContentWidth={600}>
      <Spring />
      <ModalTitle>{i18n.clover_nyi_title()}</ModalTitle>

      <VSpace height={64} />
      <Image
        src={logo}
        alt={"logo"}
        width={80}
        height={80}
        style={{ margin: "16px auto 0" }}
      ></Image>

      <VSpace height={64} />
      <DownloadApps />
      <Spring />
    </Page>
  );
}
