import { Page } from "../../../components/Page";
import { Image, MediaSizeMode } from "../../../components/Image";
import { RegularInputGroup } from "../../../components/Input";
import React, { useEffect, useState } from "react";
import { CircleInfoMaxLength } from "./EditCirclePage";
import { useI18n } from "../../../hooks/useI18n";
import { CenterChild, HStack, VStack } from "../../../components/VStack";
import { Spacing } from "../../../components/Spacing";
import { CircleData, useCircleComposeData } from "./ComposeCircleContext";
import { JSONUtil } from "../../../utils/JSONUtil";
import styled from "styled-components";
import TagsFlowLayout from "../../../components/TagsFlowLayout";
import LanguagePicker, { getLanguageCountryName } from "./LanguagePicker";
import { BottomSheet, useModal } from "../../../components/Modal";
import CategoryPicker from "./CategoryPicker";
import { Category } from "../../../proto/Category";
import { useBackend } from "../../../service/APIService";
import { useCreateCircleResult } from "./CreateCirclePage";
import { FilePicker } from "../../../components/FilePicker";
import { useHopState } from "../../../hooks/useHopState";
import { getBestRes, Media } from "../../../proto/Media";
import { isLocalMedia, LocalMedia } from "../../../bridge/LocalMedia";
import { NavEnd, NavItem, NavMiddle } from "../../../components/NavBar";
import { useHopper } from "../../../hooks/useHopper";
import { RegularButton } from "../../../components/Buttons";
import { useLoadState } from "../../../hooks/LoadState";
import { andLog, useErrorHandler } from "../../../components/handleError";
import { PageFooter } from "../../../components/PageHeaderFooter";
import { useSWR } from "../../../hooks/swr/useSWR";
import iconPlaceholder from "../../../res/images/img_create_circle_icon_placeholder.png";
import { useNativePage } from "../../../hooks/useBridge";
import { Spin } from "../../../components/Spin";
import icDown from "../../../res/images/icon_down_arrow.png";
import { TokenIcon } from "./TokenIcon";
import { Tag, TagCollection } from "./EditTagsPage";
import { useV2ConsumeResult } from "../../../hooks/useResult";
import { tag } from "type-fest/source/opaque";
import CircleBackgroundFrame from "../CircleBackgroundFrame";
import {
  EditTagsPickerResult,
  getColorByHash,
  hashCode,
} from "./TagsPickerResult";

function CreateCircleBasicInfoPage() {
  const i18n = useI18n();
  const hopper = useHopper();
  const handleError = useErrorHandler();

  const [pendingMedia, setPendingMedia] = useHopState<
    Media | LocalMedia | undefined
  >("pendingMedia");

  const languageModal = useModal("language-picker");
  const categoryModal = useModal("category-picker");

  const backend = useBackend();
  const bgSWR = useSWR(backend.getDefaultBackgroundList());
  const [bgMedia, setBgMedia] = useState<Media>();

  const editingCircle = useCreateCircleResult();

  const loadTask = useLoadState();
  const nativePage = useNativePage();

  useEffect(() => {
    const array = bgSWR.content;
    if (array) {
      if (editingCircle.content.circleBackground === undefined) {
        const randomIndex = Math.floor(Math.random() * (array.length - 1));
        const media = array[randomIndex].media;
        setBgMedia(media);
        editingCircle
          .fill((prevData) => ({
            ...prevData,
            circleBackground: {
              displayMode: 0,
              backgroundImage: media,
            },
          }))
          .catch(andLog);
      }
    }
  }, [bgSWR.content]);

  const tagsConsumer = useV2ConsumeResult(EditTagsPickerResult);

  async function onNextClick() {
    if ((editingCircle.content.name?.length ?? 0) < 1) {
      nativePage
        .infoHud(i18n.circle2_1_create_check_name_toast())
        .catch(andLog);
      return;
    }

    if ((editingCircle.content.name?.length ?? 0) > 50) {
      await nativePage.alertNotice(
        i18n.text_limit_number_of_characters_not_follow(),
        i18n.ok(),
      );
      return;
    }

    if ((editingCircle.content.tagline?.length ?? 0) < 1) {
      nativePage
        .infoHud(i18n.circle2_1_create_check_desc_toast())
        .catch(andLog);
      return;
    }

    if ((editingCircle.content.tagline?.length ?? 0) > 200) {
      await nativePage.alertNotice(
        i18n.text_limit_number_of_characters_not_follow(),
        i18n.ok(),
      );
      return;
    }

    if (
      (editingCircle.content.category?.categoryId.toString().length ?? 0) < 1
    ) {
      nativePage
        .infoHud(i18n.circle2_1_create_check_category_toast())
        .catch(andLog);
      return;
    }

    {
      tagsConsumer.result?.tags &&
        tagsConsumer.result?.tags.length > 0 &&
        editingCircle
          .fill((prevData) => ({
            ...prevData,
            tags: tagsConsumer.result?.tags ?? [],
          }))
          .catch(andLog);
      tagsConsumer.clear().catch(andLog);
    }

    if (editingCircle.content.circleIcon !== undefined || pendingMedia) {
      if (pendingMedia) {
        let serverMedia: Media;
        if (isLocalMedia(pendingMedia)) {
          const r = await loadTask.run(async () => {
            serverMedia = await backend.sendLocalMedia(
              pendingMedia,
              "nft_thumbnail",
              (a, b) => {},
            );
            setPendingMedia(serverMedia);
          });
          if (!r.success) {
            handleError(r.error);
            return;
          }
        } else {
          serverMedia = pendingMedia;
        }

        await editingCircle.fill((prev) => ({
          ...prev,
          circleIcon: serverMedia,
        }));
      }

      const languageCountry = getLanguageCountryName(
        editingCircle.content.language ?? "en",
      ).nameByUi;
      const chooseEdit = await nativePage.alertYesOrCancel(
        i18n.create_circle_confirm(languageCountry),
        i18n.edit(),
        i18n.continue_str(),
      );
      if (chooseEdit) {
        return;
      } else {
        hopper.push("create-circle-appearance");
      }
    } else {
      nativePage
        .infoHud(i18n.circle2_1_create_check_icon_toast())
        .catch(andLog);
    }
  }

  function taglineEdit(value: string) {
    editingCircle
      .fill((prevData) => ({
        ...prevData,
        tagline: value,
      }))
      .catch(andLog);
  }

  function onCircleNameEdit(value: string) {
    editingCircle
      .fill((prevData) => ({
        ...prevData,
        name: value,
      }))
      .catch(andLog);
  }

  function pendingCircleIcon(value: Media | undefined) {
    editingCircle
      .fill((prevData) => ({
        ...prevData,
        cover: value,
      }))
      .catch(andLog);
  }

  return (
    <Page
      pageData={undefined}
      underlay={
        <CircleBackgroundFrame
          circleBackground={editingCircle.content.circleBackground}
        />
      }
    >
      <NavMiddle>{i18n.circle_create_title()}</NavMiddle>
      <NavEnd>
        {NavItem.text(i18n.help(), () => hopper.push("feedback"))}
      </NavEnd>
      <Spacing height={10} />
      <CenterChild>
        <FilePicker accept={"image/*"} onPick={setPendingMedia}>
          <TokenIcon
            size={{ width: 140, height: 140 }}
            src={
              pendingMedia || editingCircle.content.circleIcon
                ? [pendingMedia ?? editingCircle.content.circleIcon, 140]
                : iconPlaceholder
            }
            showEditBadge={!!(pendingMedia || editingCircle.content.circleIcon)}
            containerStyle={{
              margin: "12px auto",
            }}
            radius={17.5}
          />
        </FilePicker>
      </CenterChild>

      <RegularInputGroup
        placeholder={i18n.circle_name()}
        value={editingCircle.content.name}
        updateValue={(value) => onCircleNameEdit(value)}
        maxLength={CircleInfoMaxLength.Name}
      />
      <Spacing height={4} />
      <RegularInputGroup
        placeholder={i18n.circle_create_description_placeholder()}
        value={editingCircle.content.tagline}
        updateValue={(value) => taglineEdit(value)}
        maxLength={CircleInfoMaxLength.Tagline}
      />
      <Spacing height={16} />
      <EditingInfoBackground
        onClick={() => {
          languageModal.open();
        }}
      >
        <EditingInfoLabel>
          {i18n.circle_create_language_prefix()}
        </EditingInfoLabel>
        <EditingInfoValue>
          {editingCircle.content?.language
            ? getLanguageCountryName(editingCircle.content.language)?.nameByUi
            : i18n.select()}
          <Image src={icDown} width={26} height={26} />
        </EditingInfoValue>
      </EditingInfoBackground>
      <Spacing height={16} />
      <EditingInfoBackground
        onClick={() => {
          categoryModal.open();
        }}
      >
        <EditingInfoLabel>{i18n.category_prefix()}</EditingInfoLabel>
        <EditingInfoValue>
          {editingCircle.content?.category
            ? editingCircle.content?.category?.name
            : i18n.select()}
          <Image src={icDown} width={26} height={26} />
        </EditingInfoValue>
      </EditingInfoBackground>
      <Spacing height={16} />
      <TagCollection
        onClick={() => {
          if ((tagsConsumer.result?.tags.length ?? 0) < 1) {
            tagsConsumer
              .fill({ tags: editingCircle.content?.tags ?? [] })
              .catch(andLog);
          }
          hopper.push(`edit-tags/${5}`);
        }}
      >
        {tagsConsumer.result?.tags &&
          tagsConsumer.result?.tags.length > 0 &&
          tagsConsumer.result?.tags.map((tag) => {
            const color = getColorByHash(hashCode(tag));
            return (
              <Tag
                key={tag}
                style={{
                  backgroundColor: color.backgroundColor,
                  borderColor: color.textColor,
                  color: color.textColor,
                }}
              >
                #{tag}
              </Tag>
            );
          })}
        {editingCircle.content?.tags &&
          (tagsConsumer.result?.tags.length ?? 0) < 1 &&
          editingCircle.content?.tags.map((tag) => {
            const color = getColorByHash(hashCode(tag));
            return (
              <Tag
                key={tag}
                style={{
                  backgroundColor: color.backgroundColor,
                  borderColor: color.textColor,
                  color: color.textColor,
                }}
              >
                #{tag}
              </Tag>
            );
          })}
        {editingCircle.content?.tags?.length === 0 && (
          <AddATag>{i18n.add_a_tag()}</AddATag>
        )}
      </TagCollection>
      <PageFooter obscuredZoneKey={"CreateCircleBottom"}>
        <HStack
          style={{
            display: "flex",
            paddingLeft: 30,
            paddingRight: 30,
            paddingBottom: 20,
          }}
        >
          <RegularButton onClick={onNextClick} style={{ height: 60, flex: 1 }}>
            <Spin state={loadTask.state}>
              {i18n.meet_now_next_button(1, 3)}
            </Spin>
          </RegularButton>
        </HStack>
      </PageFooter>

      <BottomSheet modal={languageModal}>
        <LanguagePicker
          modal={languageModal}
          lastChosenLanguage={editingCircle.content?.language}
          onChoose={(code: string) => {
            editingCircle.fill((prevData) => ({
              ...prevData,
              language: code,
            }));
          }}
        />
      </BottomSheet>

      <BottomSheet modal={categoryModal}>
        <CategoryPicker
          backend={backend}
          modal={categoryModal}
          lastChosenCategory={editingCircle.content?.category}
          onChoose={(category: Category) => {
            editingCircle.fill((prevData) => ({
              ...prevData,
              category: category,
            }));
          }}
        />
      </BottomSheet>
    </Page>
  );
}

export const EditingInfoBackground = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: row;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
`;

export const EditingInfoLabel = styled.div`
  font-weight: 400;
  font-size: 13px;
  color: var(--color-text00);
`;

export const EditingInfoValue = styled.div`
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: var(--color-text00);
`;

const AddATag = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
  padding: 5px 10px;
  border-style: dashed;
  border-width: 0.5px;
  border-radius: 20px;
`;

export default CreateCircleBasicInfoPage;
