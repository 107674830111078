import React, { CSSProperties } from "react";
import { ZDocProps } from "./ZDocCommon";
import { Attachment } from "./Attachment";
import styled from "styled-components";
import iconLink from "../../res/images/ic_link_inline.svg";
import { useWebHost } from "../../hooks/useBridge";
import { getDefaultText } from "./ZDocAtom";
import { useHopper } from "../../hooks/useHopper";
import { useOpenLink } from "../../hooks/useOpenLink";
import { TextStyle } from "./RichFormat";
import { andLog } from "../handleError";

export function ZDocInlineAttachment(
  props: ZDocProps & { attachment: Attachment },
) {
  const hopper = useHopper();
  const openLink = useOpenLink();
  const textStyle = props.richFormat?.textSpans?.at(0)?.data;
  switch (props.attachment.type) {
    case "link": {
      const getText = props.options?.overrides?.text || getDefaultLink;
      const text = getText(textStyle, undefined);
      const linkContent =
        props.attachment.link?.customTitle ||
        props.attachment.link?.title ||
        props.attachment.link?.url;

      return (
        <LinkContainer
          onClick={() => {
            if (props.attachment.link?.url) {
              openLink(props.attachment.link?.url).catch(andLog);
            }
          }}
        >
          <LinkIcon src={iconLink} />
          {text === undefined ? (
            <LinkContent>{linkContent}</LinkContent>
          ) : (
            <text.elementType {...text.props}>{linkContent}</text.elementType>
          )}
        </LinkContainer>
      );
    }

    case "mention": {
      const getText = props.options?.overrides?.text || getDefaultAtMention;
      const text = getText(textStyle, undefined);

      if (text === undefined) {
        return (
          <AtMentionContainer
            onClick={() => {
              hopper.push(`user/${props.attachment.mention?.uid}`);
            }}
          >
            <AtMentionContent>{props.content}</AtMentionContent>
          </AtMentionContainer>
        );
      } else {
        return (
          <text.elementType
            {...text.props}
            onClick={() => {
              hopper.push(`user/${props.attachment.mention?.uid}`);
            }}
          >
            {props.content}
          </text.elementType>
        );
      }
    }
    case "hashTag": {
      const getText = props.options?.overrides?.text || getDefaultHashTag;
      const text = getText(textStyle, undefined);
      const goToTagsPage = () =>
        hopper.push("tags/0", { tagName: props.content.replace("#", "") });
      if (text === undefined) {
        return (
          <HashTagContent onClick={goToTagsPage}>
            {props.content}
          </HashTagContent>
        );
      } else {
        return (
          <text.elementType {...text.props} onClick={goToTagsPage}>
            {props.content}
          </text.elementType>
        );
      }
    }
  }
  return <></>;
}

const LinkContainer = styled.span``;

const LinkIcon = styled.img`
  width: 14px;
  height: 8px;
  display: inline;
  margin-right: 4px;
`;

const LinkContent = styled.span`
  word-break: break-all;
  text-decoration: underline;
  color: white;
`;

const AtMentionContainer = styled.span`
  background: #ffffff33;
  border-radius: 4px;
  padding: 0 7px;
`;

const AtMentionContent = styled.span`
  word-break: break-all;
`;

const HashTagContent = styled.span`
  word-break: break-all;
  text-decoration: underline;
  color: "#34A6FF";
`;

export function getDefaultLink(textStyle: TextStyle | undefined) {
  const style: CSSProperties = {
    textDecoration: "underline",
    fontWeight: textStyle?.bold ? "bold" : 400,
    fontStyle: textStyle?.italic ? "oblique" : undefined,
    wordBreak: "break-all",
  };

  return {
    elementType: "span",
    props: { style: style },
  };
}

export function getDefaultAtMention(textStyle: TextStyle | undefined) {
  const style: CSSProperties = {
    backgroundColor: "underline",
    fontWeight: textStyle?.bold ? "bold" : 400,
    fontStyle: textStyle?.italic ? "oblique" : undefined,
    wordBreak: "break-all",
  };

  return {
    elementType: "span",
    props: { style: style },
  };
}

export function getDefaultHashTag(textStyle: TextStyle | undefined) {
  const style: CSSProperties = {
    textDecoration: "underline",
    fontWeight: textStyle?.bold ? "bold" : 400,
    fontStyle: textStyle?.italic ? "oblique" : undefined,
    color: "#34A6FF",
    wordBreak: "break-all",
  };

  return {
    elementType: "span",
    props: { style: style },
  };
}
