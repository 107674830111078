import { Navigate, useLocation } from "react-router-dom";
import { useBackend } from "../../service/APIService";
import { useSWR } from "../../hooks/swr/useSWR";
import { Page } from "../../components/Page";
import React, { useEffect, useMemo } from "react";
import { useMyUid } from "../../service/AuthSessionService";
import { subpage } from "../../components/Subpage";
import { NotYetImplPage } from "../common/NotYetImplPage";
import { useReactRoutes } from "../../service/ReactRoutesService";

export function SharePage() {
  const location = useLocation();
  const backend = useBackend();
  const routes = useReactRoutes();
  const shareLinkSWR = useSWR(backend.parseShareLink(location.pathname));
  const webLink = useMemo(() => {
    if (shareLinkSWR.content?.path) {
      return routes.deepLinkToWebLink(shareLinkSWR.content.path);
    } else {
      return undefined;
    }
  }, [shareLinkSWR.content]);

  const myUid = useMyUid();

  useEffect(() => {
    console.log("myuid", myUid);
    console.log("webLink", webLink);
  }, [myUid, webLink]);

  return (
    <Page pageData={shareLinkSWR} maxContentWidth={600}>
      {myUid > 0 && webLink ? (
        <Navigate to={"/" + webLink} replace={true} />
      ) : (
        subpage("fallback", <NotYetImplPage />)
      )}
    </Page>
  );
}
