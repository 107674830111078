import { Page } from "../../components/Page";
import { useSWRArray } from "../../hooks/swr/useSWRArray";
import { useBackend } from "../../service/APIService";
import { useMyUid } from "../../service/AuthSessionService";
import {
  useBigIntSearchParam,
  useEnumSearchParam,
} from "../../hooks/useTypedParam";
import React, { useEffect, useState } from "react";
import { HStack, VStack } from "../../components/VStack";
import { CommonUserCell } from "./CommonUserCell";
import { NavEnd, NavItem, NavMiddle } from "../../components/NavBar";
import { I18n, useI18n } from "../../hooks/useI18n";
import { NavButtonType } from "../../bridge/NavButtonDesc";
import { useNativePage } from "../../hooks/useBridge";
import { useHopper } from "../../hooks/useHopper";
import {
  ChatInvitationStatusType,
  isFriendWith,
  isMyFollower,
  User,
} from "../../proto/User";
import { andLog } from "../../components/handleError";
import styled from "styled-components";
import {
  FullPageWidthCell,
  hPaddingWithPageInset,
} from "../../components/CommonStyles";
import { Media } from "../../proto/Media";
import { Image } from "../../components/Image";
import iconSearch from "../../res/images/icon_search_layout_placeholder.svg";
import { Input } from "../../components/Input";
import { SearchBackground } from "../search/SearchPage";
import { ChatMemberStatus } from "../../proto/ChatMember";
import { FollowStatus } from "../../proto/UserEnums";
import { useGlobalSpinner } from "../../utils/globalSpinner";
import { useProduceResult } from "../../hooks/useResult";
import { useDebounceHopState } from "../../hooks/ExtHooks";
import { PageHeader } from "../../components/PageHeaderFooter";

export enum UserPickerPurpose {
  AddFavourites = 1,
  CreateChat = 2,
  InviteToChat = 3,
  InviteToCircle = 4,
  SendGift = 5,
}

function userQueryType(purpose: UserPickerPurpose) {
  switch (purpose) {
    case UserPickerPurpose.AddFavourites:
      return "following";
    default:
      return "follower";
  }
}

function pageTitleFor(purpose: UserPickerPurpose, i18n: I18n) {
  switch (purpose) {
    case UserPickerPurpose.AddFavourites:
      return i18n.following();
    default:
      return i18n.my_follower_list_title();
  }
}

export type UserPickerResult = {
  uid: bigint[];
};

function UserPickerPage() {
  const backend = useBackend();
  const myUid = useMyUid();
  const i18n = useI18n();
  const globalSpinner = useGlobalSpinner();
  const purpose =
    useEnumSearchParam<UserPickerPurpose>("purpose") ??
    UserPickerPurpose.AddFavourites;
  const objectId = useBigIntSearchParam("oId");
  const queryUserType = userQueryType(purpose);

  const { fill } = useProduceResult<UserPickerResult>("UserPicker");
  const [searchWord, setSearchWord] = useDebounceHopState<string>(
    `search-user`,
    400,
  );
  const [selectedIds, setSelectedIds] = useState<
    { uid: bigint; icon?: Media }[]
  >([]);
  const nativePage = useNativePage();
  const userSWR = useSWRArray(
    backend.getSocialUsers(
      myUid,
      queryUserType,
      purpose === UserPickerPurpose.InviteToChat ? objectId : undefined,
      purpose === UserPickerPurpose.InviteToCircle ? objectId : undefined,
      searchWord,
    ),
  );
  const title = pageTitleFor(purpose, i18n);

  const hopper = useHopper();
  const isSingleSelection = purpose === UserPickerPurpose.SendGift;

  const updateBigIntList = (user: User) => {
    if (!isUserSelectable(user, true)) {
      return;
    }

    if (isSingleSelection) {
      if (selectedIds && selectedIds?.at(0)?.uid === user.uid) {
        setSelectedIds([]);
      } else {
        setSelectedIds([{ uid: user.uid, icon: user.icon }]);
      }
    } else {
      // 检查是否已经存在于列表中
      const exists = selectedIds.map((item) => item.uid).includes(user.uid);

      if (exists) {
        // 如果存在，返回移除该值的新列表
        setSelectedIds((list) => list.filter((item) => item.uid !== user.uid));
      } else {
        // 如果不存在，返回添加该值的新列表
        setSelectedIds((list) => [...list, { uid: user.uid, icon: user.icon }]);
      }
    }
  };

  const onSave = () => {
    switch (purpose) {
      case UserPickerPurpose.AddFavourites:
        saveFavorites().catch(andLog);
        return;
      case UserPickerPurpose.InviteToChat:
        inviteToChat().catch(andLog);
        return;
      case UserPickerPurpose.SendGift:
        fill({
          uid: selectedIds.map((user) => user.uid),
        })
          .then(() => hopper.back())
          .catch(andLog);
        return;
      default:
        console.log("save picked uses");
    }
  };

  const saveFavorites = async () => {
    try {
      await globalSpinner(async () => {
        await backend
          .addToFollowingFavourites({
            targetUids: selectedIds.map((item) => item.uid),
          })
          .run();
      });
      fill({
        uid: selectedIds.map((user) => user.uid),
      })
        .then(() => hopper.back())
        .catch(andLog);
    } catch (error) {
      console.log(error);
    }
  };

  const inviteToChat = async () => {
    try {
      await globalSpinner(async () => {
        await backend
          .inviteUsersToChat(
            objectId!,
            selectedIds.map((item) => item.uid),
          )
          .run();
      });
      hopper.back();
    } catch (error) {
      console.log(error);
    }
  };

  const isUserSelectable = (
    user: User,
    showReasonAlert: boolean = false,
  ): boolean => {
    switch (purpose) {
      case UserPickerPurpose.AddFavourites:
        if (user.followedByMeStatusV2 === FollowStatus.favorites) {
          if (showReasonAlert) {
            nativePage
              .alertNotice(i18n.fav_widget_favorite_dup_tip(), i18n.got())
              .catch(andLog);
          }
          return false;
        }
        return true;
      case UserPickerPurpose.InviteToChat:
        if (user.chatMemberStatus === ChatMemberStatus.Active) {
          if (showReasonAlert) {
            nativePage
              .alertNotice(i18n.party_on_user_has_joined(), i18n.got())
              .catch(andLog);
          }
          return false;
        } else if (user.chatMemberStatus === ChatMemberStatus.InviteSend) {
          if (showReasonAlert) {
            nativePage
              .alertNotice(i18n.circle_perm_has_invited_chat(), i18n.got())
              .catch(andLog);
          }
          return false;
        } else if (
          user.privateChatInvitationStatus === ChatInvitationStatusType.NoOne ||
          (user.privateChatInvitationStatus ===
            ChatInvitationStatusType.Friend &&
            !isFriendWith(user)) ||
          (user.privateChatInvitationStatus ===
            ChatInvitationStatusType.Following &&
            !isMyFollower(user))
        ) {
          if (showReasonAlert) {
            nativePage
              .alertNotice(i18n.jun1_text_not_allow_chat_invite(), i18n.got())
              .catch(andLog);
          }
          return false;
        }
        return true;
      default:
        return true;
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      userSWR.load().catch(andLog);
    }, 400); // 400ms 防抖
    return () => {
      clearTimeout(handler);
    };
  }, [searchWord]);

  return (
    <Page pageData={userSWR}>
      <PageHeader>
        <VStack style={{ ...hPaddingWithPageInset }}>
          <SearchBackground style={{ width: "100%" }}>
            <Image
              alt={"search-icon"}
              src={iconSearch}
              width={24}
              height={24}
            ></Image>
            <Input
              style={{
                height: 36,
                margin: "0 6px 0 0",
                padding: "0",
                width: "calc(100% - 30px)",
              }}
              onChange={(e) => {
                setSearchWord(e.target.value);
              }}
            ></Input>
          </SearchBackground>
          {!isSingleSelection && selectedIds.length > 0 ? (
            <SelectedFrame>
              <SelectedTitle>
                {i18n.member_title_selected_number(selectedIds.length)}
              </SelectedTitle>
              <HStack
                style={{
                  overflowX: "scroll",
                  scrollbarWidth: "none" as "none",
                  msOverflowStyle: "none" as "none",
                  gap: 10,
                  ...FullPageWidthCell,
                }}
              >
                {selectedIds.map((item) => (
                  <Image
                    style={{
                      width: 46,
                      height: 46,
                      borderRadius: "50%",
                      border: "1.15px solid #FFF",
                    }}
                    src={[item.icon, { width: 46, height: 46 }]}
                  />
                ))}
              </HStack>
            </SelectedFrame>
          ) : null}
        </VStack>
      </PageHeader>

      {userSWR.content.map((user) => (
        <CommonUserCell
          user={user}
          onPicked={{
            picker: selectedIds.map((item) => item.uid).includes(user.uid),
            disable: !isUserSelectable(user),
            onClick: () => {
              updateBigIntList(user);
            },
          }}
        />
      ))}
      <NavMiddle>{title}</NavMiddle>
      <NavEnd>{NavItem.image(NavButtonType.Check, onSave)}</NavEnd>
    </Page>
  );
}

const SelectedFrame = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 var(--page-h-inset) 14px;
  margin: 24px var(--page-h-inset-neg);
  border-bottom: 5px solid rgba(255, 255, 255, 0.08);
`;

const SelectedTitle = styled.div`
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  font-weight: 400;
`;

export default UserPickerPage;
