import { string, z } from "zod";
import { Media } from "./Media";
import { zBigInt, zStatic } from "../utils/zodUtils";
import { Sticker } from "./Reaction";

export const Category = z.object({
  categoryId: zBigInt,
  subCategoryId: zBigInt.optional(),
  contentRegion: zBigInt.optional(),
  stickerId: zBigInt.optional(),
  createdTime: z.number(),
  score: z.number(),
  name: z.string().optional(),
  title: z.string().optional(),
  conceptName: z.string().optional(),
  icon: Media.optional(),
  sticker: Sticker.optional(),
  lowerCaseName: z.string().optional(),
});
export type Category = zStatic<typeof Category>;

export const CategoryList = z.object({
  list: Category.array().optional(),
});
export type CategoryList = zStatic<typeof CategoryList>;
