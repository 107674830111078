import React from "react";
import { Page } from "../../components/Page";
import { range } from "../../utils/List";
import { DemoCell } from "./DemoCell";
import { PageFooter } from "../../components/PageHeaderFooter";
import { RegularButton } from "../../components/Buttons";
import { RowCenterButton } from "../../components/CommonStyles";

export function PageFooterDemoPage() {
  return (
    <Page pageData={undefined}>
      {range(0, 100)
        .map(
          (i) =>
            `data.${i} long text long text long text long text long text long text long text`,
        )
        .map((d) => (
          <DemoCell key={d}>{d}</DemoCell>
        ))}
      <PageFooter obscuredZoneKey={"DemoPageFoot"}>
        <RegularButton
          $baseBgColor={"var(--color-bg)"}
          style={{
            ...RowCenterButton,
            marginTop: 8,
          }}
        >
          Bottom Button
        </RegularButton>
      </PageFooter>
    </Page>
  );
}
