import { Page } from "../../components/Page";
import ReCAPTCHA from "react-google-recaptcha";
import { useStringSearchParam } from "../../hooks/useTypedParam";
import { useBackend } from "../../service/APIService";
import { useHopper } from "../../hooks/useHopper";
import { andLog } from "../../components/handleError";
import { useGlobalSpinner } from "../../utils/globalSpinner";

export default function RecaptchaPage() {
  const s = useStringSearchParam("s");
  const c = useStringSearchParam("c");
  const backend = useBackend();
  const hopper = useHopper();

  const globalSpinner = useGlobalSpinner();

  const verifyCaptcha = async (token: string | null) => {
    if (!token) return;
    try {
      await globalSpinner(async () => {
        await backend.verifyCaptcha(token, s).run();
      });
      if (c === "w") {
        hopper.back();
      } else {
        window.location.href = "z://identify-finished";
      }
    } catch (error) {
      console.error(error);
      window.location.reload();
    }
  };

  return (
    <Page pageData={undefined}>
      <ReCAPTCHA
        sitekey={"6Lf_TS8eAAAAAI_HlQtAhFD0YO7yyKhryVPH8eYq"}
        size={"normal"}
        onChange={(token) => {
          verifyCaptcha(token).catch(andLog);
        }}
      />
    </Page>
  );
}
