import { Page } from "../../../components/Page";
import { useBackend } from "../../../service/APIService";
import { useBigIntParam } from "../../../hooks/useTypedParam";
import { useSWR } from "../../../hooks/swr/useSWR";
import { NavMiddle } from "../../../components/NavBar";
import styled from "styled-components";
import { HStack, Spring, VStack } from "../../../components/VStack";
import { Image } from "../../../components/Image";
import IcAdd from "./imgs/ic_add_round.svg";
import { useI18n } from "../../../hooks/useI18n";
import {
  alpha_on_pressed,
  cell_bottom_full_divider,
} from "../../../components/CommonStyles";
import { useProduceResult } from "../../../hooks/useResult";
import { useHopper } from "../../../hooks/useHopper";
import { andLog } from "../../../components/handleError";
import { CircleMemberGroupPreview } from "../../../proto/CircleMember";
import { Label } from "../../nft/components/Label";
import MemberStack from "../../chat/components/MemberStack";
import { CircleMemberTitle, TitleType } from "../../../proto/CircleMemberTitle";

const NormalTitle = styled.div`
  padding: 2px 5px;
  color: rgb(17, 17, 17);
  font-size: 10px;
  font-weight: 500;
  border-radius: 4px;
  min-height: 18px;
  display: flex;
  align-items: center;
`;

function TitleCell(props: {
  info: CircleMemberGroupPreview;
  onPick: () => void;
}) {
  const i18n = useI18n();
  return (
    <HStack
      style={{ minHeight: 80 }}
      mixin={[cell_bottom_full_divider, alpha_on_pressed]}
      onClick={props.onPick}
    >
      <HStack style={{ gap: 8, alignItems: "start" }}>
        <NormalTitle style={{ backgroundColor: props.info.memberTitle?.color }}>
          {props.info.memberTitle?.title}
        </NormalTitle>
        <VStack style={{ alignItems: "start" }}>
          <Label $fontSize={16}>
            (
            {i18n
              .plural(props.info.memberCount)
              .number_of_members(props.info.memberCount)}
            )
          </Label>
          <MemberStack users={props.info.memberList} />
        </VStack>
      </HStack>
      <Spring />
      <Image src={IcAdd} style={{ width: 20, height: 20 }} />
    </HStack>
  );
}

export function CircleMemberTitlePicker() {
  const circleId = useBigIntParam("circleId");
  const backend = useBackend();
  const i18n = useI18n();
  const titleSWR = useSWR(
    circleId
      ? backend.getMemberGroupPreview(circleId, "memberTitle")
      : undefined,
  );
  const hopper = useHopper();

  const produce = useProduceResult<CircleMemberTitle>("CircleTitlePicker");

  return (
    <Page pageData={titleSWR}>
      <NavMiddle>{i18n.clover_select_titles()}</NavMiddle>
      {titleSWR?.content?.list
        .filter(
          (title) =>
            title.memberTitle?.type !== TitleType.admin &&
            title.memberTitle?.type !== TitleType.coAdmin,
        )
        .map((title) => (
          <TitleCell
            key={title.memberTitle?.titleId}
            info={title}
            onPick={() => {
              if (title.memberTitle?.titleId) {
                produce
                  .fill(title.memberTitle)
                  .then(() => hopper.back())
                  .catch(andLog);
              }
            }}
          />
        ))}
    </Page>
  );
}
