import React from "react";
import {
  clampRichFormat,
  ParagraphStyle,
  RichFormat,
  RichSpan,
  TextStyle,
} from "./RichFormat";

export type ZDocOptions = {
  overrides?: {
    paragraph?: (
      style: ParagraphStyle | undefined,
      isBlank: boolean,
    ) => {
      elementType: React.ElementType;
      props: Object | undefined;
    };
    text?: (
      style: TextStyle | undefined,
      paragraphStyle: ParagraphStyle | undefined,
    ) =>
      | {
          elementType: React.ElementType;
          props: Object | undefined;
        }
      | undefined;
  };
};

export type ZDocProps = {
  richFormat: RichFormat | undefined;
  content: string;
  options?: ZDocOptions;
};

export function clampZDoc(
  start: number,
  end: number,
  richFormat: RichFormat | undefined,
  content: string,
) {
  const contentPiece = content?.substring(start, end);
  const formatPiece =
    richFormat && contentPiece
      ? clampRichFormat(richFormat, start, end, true)
      : undefined;

  return [formatPiece, contentPiece] as const;
}

export function splitZDoc<T extends {}>(
  richFormat: RichFormat | undefined,
  content: string,
  splitterSpans: RichSpan<T>[] | undefined,
  beforeSplitter: (richFormat: RichFormat | undefined, content: string) => void,
  onSplitter: (
    splitter: T,
    richFormat: RichFormat | undefined,
    content: string,
  ) => void,
  getNextStart?: (splitterSpan: RichSpan<T>) => number,
) {
  if (!content) return;
  const contentLength = content.length;

  let pieceStart = 0;
  for (let i = 0; i <= (splitterSpans?.length ?? 0); ++i) {
    const splitterSpan = splitterSpans?.at(i);
    let pieceEnd;
    let nextPieceStart;

    if (splitterSpan) {
      pieceEnd = splitterSpan.start ?? 0;
      nextPieceStart = getNextStart
        ? getNextStart(splitterSpan)
        : splitterSpan.end;
    } else {
      pieceEnd = contentLength;
      nextPieceStart = contentLength;
    }

    if (
      pieceStart < pieceEnd &&
      pieceStart < contentLength &&
      pieceEnd <= contentLength
    ) {
      beforeSplitter(...clampZDoc(pieceStart, pieceEnd, richFormat, content));
    }

    if (splitterSpan) {
      onSplitter(
        splitterSpan.data,
        ...clampZDoc(
          splitterSpan.start ?? 0,
          splitterSpan.end,
          richFormat,
          content,
        ),
      );
    }

    pieceStart = nextPieceStart;
  }
}
