import React, { PropsWithChildren, ReactNode, useState } from "react";
import { CenterChild } from "../components/VStack";
import { UserCenterPage } from "../pages/user/UserCenterPage";
import { Image } from "../components/Image";
import { Animation, CenterModalBg } from "../components/Modal";
import { ObscuredZoneBoundary } from "../components/ObscuredZoneBoundary";
import { WideTopBar } from "../components/WideTopBar";
import type { Router as RemixRouter } from "@remix-run/router/dist/router";
import { andLog } from "../components/handleError";
import styled from "styled-components";
import { NavBarBoundary } from "../components/NavBar";
import { RouterProvider } from "react-router-dom";
import { AuxPageBoundary } from "../components/AuxPage";
import { useObscuredZones } from "../hooks/useObscuredZones";
import Logo from "../res/images/logo_clover.svg";
import LogoText from "../res/images/clover_space.svg";
import { PageDefaultBgKiller } from "../components/PageDefaultBgKiller";
import {
  WideLayout,
  WideLayoutContext,
} from "../components/WideAppShellLayout";
import { useMyUid } from "../service/AuthSessionService";
import { useReactRoutes } from "../service/ReactRoutesService";

const ThreeColFrame = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 300px 1000px;
  justify-content: center;

  background-color: var(--color-bg);
`;

function ThreeColLayout(props: { router: RemixRouter }) {
  const [obscuredZone] = useObscuredZones();
  const myUid = useMyUid();
  return (
    <ThreeColFrame
      id={"ThreeColLayout"}
      style={{ paddingTop: obscuredZone.top }}
    >
      <PageDefaultBgKiller>
        <ObscuredZoneBoundary>
          {myUid > 0 && <UserCenterPage />}
        </ObscuredZoneBoundary>
        <AuxPageBoundary>
          {<RouterProvider router={props.router} />}
        </AuxPageBoundary>
      </PageDefaultBgKiller>
    </ThreeColFrame>
  );
}

const TwinFrame = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 500px 500px;
  justify-content: center;
  gap: 30px;

  background-color: var(--color-bg);
`;

function TwinLayout(props: { router: RemixRouter }) {
  const [obscuredZone] = useObscuredZones();
  return (
    <TwinFrame id={"TwinLayout"} style={{ paddingTop: obscuredZone.top }}>
      <CenterChild
        style={{
          width: "100%",
          height: "100%",
          flexDirection: "column",
          gap: 30,
        }}
      >
        <Image
          src={Logo}
          style={{
            width: 256,
            height: 256,
            minWidth: 128,
            minHeight: 128,
          }}
        />
        <Image
          src={LogoText}
          style={{
            width: 256,
            minWidth: 128,
          }}
        />
      </CenterChild>
      <CenterChild style={{ width: "100%", height: "100%" }}>
        <CenterModalBg
          $animation={Animation.None}
          style={{ width: 674, height: 726 }}
        >
          <ObscuredZoneBoundary>
            <NavBarBoundary
              canDismiss={false}
              onBackward={() => props.router.navigate(-1).catch(andLog)}
            >
              <RouterProvider router={props.router} />
            </NavBarBoundary>
          </ObscuredZoneBoundary>
        </CenterModalBg>
      </CenterChild>
    </TwinFrame>
  );
}

export function WideAppShell(props: PropsWithChildren<{}>) {
  const [layout, setLayout] = useState<WideLayout>("threeColumn");
  const myUid = useMyUid();

  const routes = useReactRoutes();

  let element: ReactNode | undefined;
  switch (layout) {
    case "threeColumn":
      element = <ThreeColLayout router={routes.baseRouter} />;
      break;
    case "twin":
      element = <TwinLayout router={routes.baseRouter} />;
      break;
    case "fullWidth":
      element = <RouterProvider router={routes.baseRouter} />;
      break;
  }
  return (
    <WideLayoutContext.Provider
      value={{
        setWideLayout: setLayout,
      }}
    >
      {element}
      {myUid > 0 && <WideTopBar />}
    </WideLayoutContext.Provider>
  );
}
