import { DependencyList, useContext, useEffect, useId } from "react";
import { andLog } from "../components/handleError";
import { EventName } from "../bridge/EventName";
import { useNativePage } from "./useBridge";

export function useBroadcast<T extends object = {}>(
  eventName: EventName,
  listener: (param: T) => void,
  deps: DependencyList,
) {
  const nativePage = useNativePage();

  const id = useId();

  useEffect(() => {
    nativePage
      .listenToBroadcast(id, eventName, (param) => {
        console.log("Broadcast Received", {
          eventName: eventName,
          param: param,
        });
        listener(param as T);
      })
      .catch(andLog);

    return () => {
      nativePage.unlistenToBroadcast(id, eventName).catch(andLog);
    };
  }, deps);
}
