import { Page } from "../../../components/Page";
import { useSWR } from "../../../hooks/swr/useSWR";
import { useBackend } from "../../../service/APIService";
import {
  useBigIntParam,
  useBigIntSearchParam,
  useNumberParam,
  useStringSearchParam,
} from "../../../hooks/useTypedParam";
import { NavEnd, NavItem, NavMiddle } from "../../../components/NavBar";
import {
  CurrencyType,
  formatMoney,
  getCurrencyIcon,
  getCurrencyName,
} from "../../../proto/Currency";
import { HStack, VSpace, VStack } from "../../../components/VStack";
import styled from "styled-components";
import { Image } from "../../../components/Image";
import { useI18n } from "../../../hooks/useI18n";
import { ButtonColor, RegularButton } from "../../../components/Buttons";
import { useSWRArray } from "../../../hooks/swr/useSWRArray";
import TransactionCell from "./TransactionCell";
import { useWalletSWR } from "../useWallet";
import React, { useMemo } from "react";
import iconActionSend from "../../../res/images/ic_action_send.svg";
import iconActionReceive from "../../../res/images/ic_action_receive.svg";
import iconActionTopup from "../../../res/images/ic_action_topup.svg";
import { useHopper } from "../../../hooks/useHopper";
import { NavButtonType } from "../../../bridge/NavButtonDesc";
import { useModal } from "../../../components/Modal";
import { PageHeader } from "../../../components/PageHeaderFooter";
import { BSMenu, BSMenuItem } from "../../../components/BSMenu";
import { useAssetAccount } from "../useAssetAccount";
import { DefaultEmptyView } from "../../../components/LoadStateView";
import solIcon from "../../../res/images/ic_sol.svg";
import { dicClickTopUp } from "../dicClickTopUp";
import questionMark from "../../../res/images/ic_sol_question_mark.svg";
import chevron from "../../../res/images/icon_section_header_show_more.svg";
import { BoolNumber } from "../../../proto/Wallet";
import { TopUpMethodMenu } from "../topup/TopUpMethodMenu";
import { useBroadcast } from "../../../hooks/useBroadcast";
import { useOnMorePaymentBroadcast } from "../../../hooks/useOnMorePaymentBroadcast";
import { isLiteApp } from "../../../bridge/ExternalAppShellData";

function TokenDetailPage() {
  const backend = useBackend();
  const hopper = useHopper();
  const accountId = useBigIntParam("walletAccountId");
  const currencyType = useNumberParam("currencyType");
  const currencyId = useBigIntSearchParam("currencyId", BigInt(0));
  const from = useStringSearchParam("from", "regular");
  const currencyListSWR = useSWR(backend.getCurrencyList(accountId));
  const currencySWR = useSWR(
    backend.getWalletCurrencyInfo(accountId, currencyId),
  );
  const [wallet, walletSWR] = useWalletSWR();
  const methods = useSWR(backend.getSupportedThirdPartyPaymentMethods());

  const supportThirdParty = useMemo(() => {
    return (
      (!isLiteApp() || wallet?.thirdPartyIapStatus === BoolNumber.On) &&
      (methods.content?.list?.length ?? 0) > 0
    );
  }, [wallet, methods.content]);
  const topupMenu = useModal("top-up-menu");
  const currency =
    from === "store"
      ? wallet?.storeAccount?.currencyList?.find(
          (cur) => cur.currencyType === currencyType,
        )?.availableCurrency || {
          currencyId: BigInt(0),
          currencyType: CurrencyType.GEMS,
          decimals: 18,
          amount: "0",
          symbol: "Gems",
        }
      : currencySWR.content?.currency;
  const transactionSWR = useSWRArray(
    backend.getWalletTransactionLogs(
      accountId,
      0,
      currencyType.toString(),
      currency?.currencyId.toString(),
    ),
  );
  const moreMenu = useModal("token-detail-menu");
  const i18n = useI18n();
  const transfer = () => {
    hopper.layer(`shop-transfer`);
  };
  const assetAccount = useAssetAccount();
  const assetAccountId = assetAccount?.accountId ?? BigInt(0);
  const send = () => {
    if (currency) {
      hopper.layer(
        `wallet/${accountId}/assetAccount/${assetAccountId}/send-token`,
        {
          currencyId: currency.currencyId,
        },
      );
    }
  };
  useOnMorePaymentBroadcast(() => {
    topupMenu.open();
  }, [topupMenu]);
  return (
    <Page
      alwaysShowsContent
      pageData={
        from === "store"
          ? [transactionSWR, walletSWR, currencyListSWR, methods]
          : [transactionSWR, currencySWR, walletSWR, currencyListSWR, methods]
      }
    >
      <NavEnd>
        {NavItem.image(NavButtonType.More, () => {
          moreMenu.open();
        })}
      </NavEnd>
      <PageHeader>
        <>
          <HStack style={{ gap: 8, margin: "0 auto" }}>
            <Image
              src={getCurrencyIcon(currency)}
              style={{ width: 38, height: 38, borderRadius: 12 }}
            />
            <TokenAmount>{formatMoney("medium", currency) || 0}</TokenAmount>
          </HStack>
          <TotalText>{i18n.web3_v0_total()}</TotalText>
          {/*<HStack style={{ margin: "8px auto", alignItems: "center" }}>*/}
          {/*  <AvailableText>{i18n.web3_v0_available()}</AvailableText>*/}
          {/*  <Image*/}
          {/*    src={getCurrencyIcon(currency)}*/}
          {/*    style={{ width: 16, height: 16, margin: "0 5px" }}*/}
          {/*  />*/}
          {/*  <AvailableAmount>{`${formatMoney("medium", currency) || 0} ${currency?.symbol}`}</AvailableAmount>*/}
          {/*</HStack>*/}
          {from === "store" ? (
            <RegularButton
              onClick={transfer}
              style={{ margin: "32px 20px" }}
              $baseColor={ButtonColor.greenish}
            >
              {i18n.transfer()}
            </RegularButton>
          ) : currency?.currencyType === CurrencyType.GEMS ? (
            <ActionContainer>
              <VStack
                style={{ gap: 4, alignItems: "center" }}
                onClick={() => {
                  dicClickTopUp(
                    hopper,
                    supportThirdParty ? topupMenu : undefined,
                  );
                }}
              >
                <Image
                  src={iconActionTopup}
                  style={{ width: 37, height: 37 }}
                />
                <ActionName>{i18n.web3_hongbao_top_up()}</ActionName>
              </VStack>
            </ActionContainer>
          ) : (
            <ActionContainer>
              <VStack style={{ gap: 4, alignItems: "center" }} onClick={send}>
                <Image src={iconActionSend} style={{ width: 37, height: 37 }} />
                <ActionName>{i18n.send()}</ActionName>
              </VStack>
              <VStack
                style={{ gap: 4, alignItems: "center" }}
                onClick={() => {
                  hopper.push(`wallet/receive`);
                }}
              >
                <Image
                  src={iconActionReceive}
                  style={{ width: 37, height: 37 }}
                />
                <ActionName>{i18n.clover_receive()}</ActionName>
              </VStack>
            </ActionContainer>
          )}
          {currency?.currencyType === CurrencyType.SOL &&
            (!isLiteApp() || wallet?.thirdPartyIapStatus === BoolNumber.On) && (
              <HStack
                onClick={() => {
                  hopper.push("get-sol");
                }}
                style={{ margin: "20px auto 0", gap: 6 }}
              >
                <Image src={questionMark} width={16} height={16} />
                <GetSol>{i18n.clover_how_do_i_get_sol()}</GetSol>
                <Image src={chevron} width={12} height={12} />
              </HStack>
            )}
          <VSpace height={32} />
          <SectionTitle style={{ margin: "0" }}>
            {i18n.web3_v0_transaction_history()}
          </SectionTitle>
        </>
      </PageHeader>
      {(transactionSWR.content?.length ?? 0) > 0 ? (
        <>
          {transactionSWR.content.map((transactionLog) => (
            <TransactionCell
              transaction={transactionLog}
              accountId={accountId}
            />
          ))}
        </>
      ) : (
        <EmptyFlame>
          <DefaultEmptyView title={i18n.no_content_yet()} />
        </EmptyFlame>
      )}

      <BSMenu modal={moreMenu}>
        {from === "store" ? (
          <BSMenuItem title={i18n.transfer()} onClick={transfer} />
        ) : currencyType === CurrencyType.GEMS ? (
          <BSMenuItem
            title={i18n.web3_hongbao_top_up()}
            onClick={() => {
              dicClickTopUp(hopper, supportThirdParty ? topupMenu : undefined);
            }}
          />
        ) : (
          <>
            <BSMenuItem title={i18n.send()} onClick={send} />
            <BSMenuItem
              title={i18n.clover_receive()}
              onClick={() => {
                hopper.push(`wallet/receive`);
              }}
            />
          </>
        )}
      </BSMenu>
      {supportThirdParty && <TopUpMethodMenu modal={topupMenu} />}
      <NavMiddle>
        {/*{getCurrencyName(currency, i18n)}*/}
        <VStack style={{ alignItems: "center" }}>
          {getCurrencyName(currency, i18n)}
          <HStack style={{ gap: 4 }}>
            <Image src={solIcon} style={{ width: 10, height: 10, margin: 0 }} />
            <ChainName>{i18n.clover_solana_chain()}</ChainName>
          </HStack>
        </VStack>
      </NavMiddle>
    </Page>
  );
}

const GetSol = styled.div`
  color: white;
  font-size: 12px;
  font-weight: 400;
`;

const ChainName = styled.div`
  color: #ffffff;
  font-size: 11px;
  font-weight: 300;
`;
const EmptyFlame = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const ActionName = styled.div`
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 35px;
  margin: 24px auto 0;
`;

const SectionTitle = styled.div`
  background: rgba(255, 255, 255, 0.15);
  padding: 5px 14px;
  font-size: 12px;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.8);
  margin: 0 var(--page-h-inset-neg);
`;
const AvailableAmount = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
`;

const AvailableText = styled.div`
  font-size: 12px;
  font-weight: 500;
  margin: 0 5px;
  color: rgba(255, 255, 255, 0.4);
`;
const TotalText = styled.div`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 400;
  margin: 3px auto 0;
`;

const TokenAmount = styled.div`
  color: #ffffff;
  font-weight: 700;
  font-size: 28px;
`;

export default TokenDetailPage;
