import { Page } from "../../../components/Page";
import { useBigIntParam } from "../../../hooks/useTypedParam";
import { useBackend } from "../../../service/APIService";
import { useI18n } from "../../../hooks/useI18n";
import { useHopper } from "../../../hooks/useHopper";
import { useSWR } from "../../../hooks/swr/useSWR";
import { useNativePage } from "../../../hooks/useBridge";
import { useProduceResult, useV2ProduceResult } from "../../../hooks/useResult";
import React, { useEffect, useState } from "react";
import { andLog } from "../../../components/handleError";
import { useHopState } from "../../../hooks/useHopState";
import { useRegularAccount } from "../../wallet/useRegularAccount";
import { NavEnd, NavItem, NavMiddle } from "../../../components/NavBar";
import { NavButtonType } from "../../../bridge/NavButtonDesc";
import { TokenSnippetCell } from "./TokenSnippetCell";
import SearchLayout from "../../../components/SearchLayout";
import { useBridgeResultProvider } from "../../../hooks/useBridgeResult";
import { TokenPickedResult } from "./CircleTokenOverviewPage";
import { useGlobalSpinner } from "../../../utils/globalSpinner";
import { delay } from "../../../utils/ensureDur";
import { CircleCardStatus } from "../../../proto/CircleCardStatus";
import { Image } from "../../../components/Image";
import iconSearch from "../../../res/images/icon_search_layout_placeholder.svg";
import { Input } from "../../../components/Input";
import { SearchBackground } from "../../search/SearchPage";
import { useDebounceHopState } from "../../../hooks/ExtHooks";
import { HStack, VSpace, VStack } from "../../../components/VStack";
import { debounce } from "lodash";
import { PageFooter, PageHeader } from "../../../components/PageHeaderFooter";
import { hPaddingWithPageInset } from "../../../components/CommonStyles";
import { Spacing } from "../../../components/Spacing";
import styled, { css } from "styled-components";
import { EmptyState, EmptyTitle } from "../../post/PostDetailPage";
import icEmptyState from "../../../res/images/empty_state.svg";
import { WeakButton } from "../../../components/Buttons";
import { useSWRList } from "../../../hooks/swr/useSWRList";
import { PowerMarkdown } from "../../../components/PowerMarkdown";
import { service_privacy_markdown_style } from "../../auth/AuthHomePage";
import { LoadStateKind } from "../../../hooks/LoadState";

export type TokenPickerResult = {
  tokenProjectIds: bigint[];
};

export function CircleTokenPickerPage() {
  const backend = useBackend();
  const i18n = useI18n();
  const hopper = useHopper();
  const circleId = useBigIntParam("circleId");
  const [searchWord, setSearchWord] = useHopState<string>("searchWord");
  const hasValidInput = (searchWord ?? "").trim().length > 0;
  const [inputWord, setInputWord] = useHopState<string>("inputWord");
  const tokenListSWR = useSWRList(
    hasValidInput ? backend.searchTokenProjects(searchWord) : undefined,
  );
  const result = useV2ProduceResult(TokenPickedResult);

  const debouncedSearch = debounce((value?: string) => {
    setSearchWord(value);
  }, 400);

  useEffect(() => {
    debouncedSearch(inputWord);
    return () => {
      debouncedSearch.cancel();
    };
  }, [inputWord, debouncedSearch]);

  const cardsSWR = useSWR(backend.getCircleCardsList(circleId));
  const circleSWR = useSWR(backend.getCircle(circleId));

  const globalSpinner = useGlobalSpinner();
  const linkToken = (projectId: bigint) => {
    globalSpinner(async () => {
      await backend
        .linkTokenWithCircle(circleId, { tokenProjectId: projectId })
        .run();
      await delay(200);
      await cardsSWR.load();
      await circleSWR.load();
      await result.fill(projectId);
      hopper.back();
    }).catch(andLog);
  };

  return (
    <Page pageData={tokenListSWR} alwaysShowsContent={true}>
      <NavMiddle>{i18n.clover_search_token()}</NavMiddle>
      <PageHeader>
        <HStack style={{ ...hPaddingWithPageInset }}>
          <SearchBackground>
            <Image
              alt={"search-icon"}
              src={iconSearch}
              width={24}
              height={24}
            />
            <Input
              placeholder={i18n.clover_search_key_of_token()}
              value={inputWord}
              style={{
                height: 36,
                margin: "0 6px 0 0",
                padding: "0",
                flexGrow: 1,
              }}
              ref={(e) => {
                if (e) e.focus();
              }}
              onChange={(e) => {
                setInputWord(e.target.value);
              }}
            />
          </SearchBackground>
        </HStack>
      </PageHeader>
      {hasValidInput &&
        tokenListSWR?.loadState?.kind !== LoadStateKind.loading &&
        (tokenListSWR?.content?.list?.length ?? 0) === 0 && (
          <>
            <VStack style={{ width: "100%", alignItems: "center" }}>
              <VSpace height={160} />
              <EmptyState src={icEmptyState} />
              <EmptyTitle>{i18n.clover_no_available_token()}</EmptyTitle>
              <VSpace height={20} />
              <WeakButton
                onClick={() => {
                  tokenListSWR?.load().catch(andLog);
                }}
              >
                {i18n.refresh()}
              </WeakButton>
            </VStack>
          </>
        )}
      {tokenListSWR?.content?.list.map((token) => (
        <TokenSnippetCell
          key={token.projectId}
          tokenProject={token}
          onPick={() => {
            linkToken(token.projectId);
          }}
        />
      ))}
      <PageFooter obscuredZoneKey={"TokenDeployBottom"}>
        <PowerMarkdown textStyle={SupportStyle}>
          {i18n.clover_token_picker_desc()}
        </PowerMarkdown>
      </PageFooter>
    </Page>
  );
}

const SupportStyle = css`
  color: var(--color-text00);
  font-weight: 400;
  text-align: center;
  margin-inline-start: 30px;
  margin-inline-end: 30px;
  font-size: 12px;

  a {
    color: #34a6ff;
    text-decoration: none;
  }
`;

const EmptyContent = styled.div`
  font-weight: 500;
  background-color: #111111;
  text-align: center;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-inline-start: 30px;
  padding-inline-end: 30px;
  color: var(--color-text00);
  font-size: 12px;
`;
