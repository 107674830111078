import { Page } from "../../components/Page";
import { CircleChildPageProps } from "./CircleChildPageProps";
import { useSWR } from "../../hooks/swr/useSWR";
import SectionHeader from "../../components/SectionHeader";
import { useI18n } from "../../hooks/useI18n";
import TagsFlowLayout from "../../components/TagsFlowLayout";
import { Spacing } from "../../components/Spacing";
import styled from "styled-components";
import { HStack } from "../../components/VStack";
import { useBigIntParam } from "../../hooks/useTypedParam";
import { CellDivider } from "../../components/ContentGroup";
import { shortFromDate } from "../../utils/DateTimeUtils";
import { useBackend } from "../../service/APIService";
import { ZDoc } from "../../components/zdoc/ZDoc";
import { getOrderedFilteredMediaList, Media } from "../../proto/Media";
import { useNativePage } from "../../hooks/useBridge";
import { ObjectType } from "../../proto/ObjectSpec";
import { FlagMediaLocationEnum } from "../../proto/Flag";
import { MediaOwner } from "../../proto/MediaOwner";

function CircleInfoPage(props: CircleChildPageProps) {
  const backend = useBackend();
  const circleId = useBigIntParam("circleId");
  const i18n = useI18n();

  const circleSWR = useSWR(backend.getCircle(circleId));
  const nativePage = useNativePage();

  const orderedMediaList = getOrderedFilteredMediaList(
    circleSWR.content?.mediaList,
    circleSWR.content?.richFormat,
  );

  const viewMedia = (media: Media) => {
    if (orderedMediaList) {
      const mediaOwnerMap = new Map();
      orderedMediaList?.forEach((media) => {
        const ownerSpec = { objectId: circleId, objectType: ObjectType.CIRCLE };
        const flagExt = {
          flagMedia: media,
          flagMediaLocation: FlagMediaLocationEnum.DESCRIPTION_IMAGE,
        };

        const mediaOwner: MediaOwner = {
          ownerSpec: ownerSpec,
          authorId: circleSWR.content?.author?.uid,
          flagExt: flagExt,
        };
        mediaOwnerMap.set(media.mediaId, mediaOwner);
      });
      nativePage.viewMedia({
        mediaList: orderedMediaList,
        position: orderedMediaList.findIndex(
          (m) => m.mediaId === media.mediaId,
        ),
        mediaOwnerMap: mediaOwnerMap,
      });
    }
  };

  return (
    <Page pageData={circleSWR}>
      <Spacing height={24} />
      <TagLineBg>
        <HStack style={{ gap: 16 }}>
          <div
            style={{
              height: 1,
              width: 60,
              backgroundColor: "rgba(255, 255, 255, 0.3)",
            }}
          />
          <TagLineLabel>{i18n.tagline()}</TagLineLabel>
          <div
            style={{
              height: 1,
              width: 60,
              backgroundColor: "rgba(255, 255, 255, 0.3)",
            }}
          />
        </HStack>
        <TagLineContent>{circleSWR.content?.tagline}</TagLineContent>
      </TagLineBg>
      <Spacing height={24} />
      <SectionHeader title={i18n.description()} />
      <Spacing height={12} />
      {circleSWR.content &&
        (circleSWR.content?.description.length ?? 0) > 0 && (
          <ZDoc
            content={circleSWR.content?.description}
            richFormat={circleSWR.content?.richFormat}
            mediaList={circleSWR.content?.mediaList}
            pollList={[]}
            onViewMedia={viewMedia}
          />
        )}
      {circleSWR.content &&
        (circleSWR.content?.description?.length ?? 0) === 0 && (
          <CircleDesc>{i18n.no_content_yet()}</CircleDesc>
        )}
      <Spacing height={36} />
      <SectionHeader title={i18n.tags()} />
      <Spacing height={6} />
      {circleSWR.content && (circleSWR.content?.tagList?.length ?? 0) > 0 && (
        <TagsFlowLayout tags={circleSWR.content?.tagList ?? []} />
      )}

      {circleSWR.content && (circleSWR.content?.tagList?.length ?? 0) === 0 && (
        <CircleDesc>{i18n.no_tags_yet()}</CircleDesc>
      )}

      <Spacing height={36} />
      {/*<CellDivider />*/}
      {/*<Spacing height={12} />*/}
      {/*{circleSWR.content?.createdTime && (*/}
      {/*  <CircleDesc>*/}
      {/*    {i18n.created_on(shortFromDate(circleSWR.content?.createdTime))}*/}
      {/*  </CircleDesc>*/}
      {/*)}*/}

      <Spacing height={100} />
    </Page>
  );
}

const TagLineLabel = styled.div`
  color: var(--color-text20);
  font-weight: 500;
  font-size: 12px;
`;

const TagLineContent = styled.div`
  color: var(--color-text00);
  font-weight: 400;
  word-break: break-word;
  font-size: 15px;
`;

export const TagLineBg = styled.div`
  padding: 8px 30px 20px;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 4px;
  display: flex;
  gap: 14px;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.08);
  align-items: center;

  /* 左上角和右下角切掉一个三角形 */
  clip-path: polygon(
    20px 0,
    /* 左上角切掉的三角形 */ 100% 0,
    100% calc(100% - 20px),
    /* 右下角切掉的三角形 */ calc(100% - 20px) 100%,
    0 100%,
    0 20px
  );
`;

const CircleDesc = styled.div`
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
`;

export default CircleInfoPage;
