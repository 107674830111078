import { JSONUtil } from "./JSONUtil";
import { ZodType } from "zod/lib/types";
import { zStatic } from "./zodUtils";

export function arrayBufferToBase64(arrayBuffer: ArrayBuffer) {
  const uint8Array = new Uint8Array(arrayBuffer);
  const binaryString = Array.from(uint8Array)
    .map((byte) => String.fromCharCode(byte))
    .join("");
  return btoa(binaryString);
}

export function base64ToArrayBuffer(base64String: string) {
  const binaryString = atob(base64String);
  const length = binaryString.length;
  const uint8Array = new Uint8Array(length);

  for (let i = 0; i < length; i++) {
    uint8Array[i] = binaryString.charCodeAt(i);
  }

  return uint8Array.buffer;
}

export function utf8ToBase64(str: string) {
  return arrayBufferToBase64(new TextEncoder().encode(str).buffer);
}

export function base64ToUtf8(base64: string) {
  return new TextDecoder().decode(base64ToArrayBuffer(base64));
}

export function anyToBase64(value: any) {
  return utf8ToBase64(JSONUtil.stringify(value));
}

export function base64ToUnknown(base64: string) {
  return JSONUtil.parseUnknown(base64ToUtf8(base64));
}

export function base64ToTyped<S extends ZodType<any, any, any>>(
  base64: string,
  schema: S,
  keepsNull: boolean = false,
): zStatic<S> {
  return JSONUtil.parse(base64ToUtf8(base64), schema, keepsNull);
}

export function hexToArrayBuffer(hex: string) {
  // Remove any optional "0x" or spaces from the hex string
  hex = hex.replace(/^0x/, "").replace(/\s+/g, "");

  // Create an ArrayBuffer with the same byte length as the hex string
  const buffer = new ArrayBuffer(hex.length / 2);
  const view = new Uint8Array(buffer);

  // Loop through the hex string, converting each pair of hex digits into a byte
  for (let i = 0; i < hex.length; i += 2) {
    view[i / 2] = parseInt(hex.substring(i, i + 2), 16);
  }

  return buffer;
}

export function utf8ToArrayBuffer(str: string) {
  // Create a TextEncoder instance
  const encoder = new TextEncoder();

  // Encode the string as a Uint8Array (UTF-8 encoded bytes)
  const uint8Array = encoder.encode(str);

  // Return the buffer underlying the Uint8Array
  return uint8Array.buffer;
}

export function concatArrayBuffers(buffer1: ArrayBuffer, buffer2: ArrayBuffer) {
  // Create a new ArrayBuffer that can hold both buffer1 and buffer2
  const combinedBuffer = new ArrayBuffer(
    buffer1.byteLength + buffer2.byteLength,
  );

  // Create a Uint8Array view for the new buffer
  const combinedView = new Uint8Array(combinedBuffer);

  // Copy the first buffer into the new buffer
  combinedView.set(new Uint8Array(buffer1), 0);

  // Copy the second buffer into the new buffer, starting at the end of the first buffer
  combinedView.set(new Uint8Array(buffer2), buffer1.byteLength);

  return combinedBuffer;
}

export function arrayBufferToHex(buffer: ArrayBuffer) {
  // Create a Uint8Array view of the buffer
  const view = new Uint8Array(buffer);

  // Convert each byte to a 2-digit hexadecimal string and join them together
  const hexArray = Array.from(view).map((byte) =>
    byte.toString(16).padStart(2, "0"),
  );

  // Join the array of hex strings into a single string
  return hexArray.join("");
}

export function arrayBufferToObj<S extends ZodType<any, any, any>>(
  schema: S,
  buffer: unknown,
): zStatic<S> {
  if (!buffer) throw new Error("nullish buffer");

  if (!(buffer instanceof ArrayBuffer)) {
    throw new Error(`not ArrayBuffer ${buffer}`);
  }
  if (buffer.byteLength === 0) throw new Error("empty ArrayBuffer");

  const str = new TextDecoder("utf-8").decode(buffer);
  return JSONUtil.parse(str, schema);
}
