import { User } from "./User";
import { z } from "zod";
import { zBigInt, zStatic } from "../utils/zodUtils";
import { Currency } from "./Currency";
import { CircleLevelInfo } from "./Circle";
import { NFT } from "./NFT";

export const CircleUpgradeRecord = z.object({
  recordId: zBigInt.default(BigInt(0)),
  targetContribution: z.number().optional(),
  currentContribution: z.number().optional(),
  contributorCount: z.number().optional(),
  createdTime: z.number().optional(),
  upgradeTime: z.number().optional(),
  circleToken: Currency.optional(),
  circleNFT: NFT.optional(),
  circleMemberAllowed: z.number(),
  circleMemberReachedTime: z.number().optional(),
  energyCollectedStatus: z.number(),
  currentCircleMemberCount: z.number(),
  nextLevel: z.number(),
  nftAmount: z.number(),
  hasRewardedContribution: z.number(),
  newCircleLevelInfo: CircleLevelInfo.optional(),
  collectionReturnTime: z.number().optional(),
  currentUserContribution: z.number(),
});

export type CircleUpgradeRecord = zStatic<typeof CircleUpgradeRecord>;

export const CircleUpgradeContributionRecord = z.object({
  user: User,
  contribution: z.number(),
});

export type CircleUpgradeContributionRecord = zStatic<
  typeof CircleUpgradeContributionRecord
>;

export enum RecordStatus {
  NOT_START = 0,
  START = 1,
  PAUSE = 2,
  DONE = 3,
  RETURN = 4,
}

export const CircleUpgradeHistory = z.object({
  list: z.array(CircleUpgradeRecord).optional(),
});

export type CircleUpgradeHistory = zStatic<typeof CircleUpgradeHistory>;
