import { string, z } from "zod";
import { zBigInt, zStatic } from "../utils/zodUtils";
import { Media } from "./Media";
import { Background } from "./Background";
import { User } from "./User";

export const Tag = z.object({
  tagName: string().optional(),
  themeColor: string().optional(),
  style: Background.optional(),
  tagId: zBigInt,
});
export type Tag = zStatic<typeof Tag>;

// export const TagObject = z.object({
//   user: User.optional(),
// });
// export type TagObject = zStatic<typeof TagObject>;
