import { useBackend } from "../../service/APIService";
import { useBigIntParam } from "../../hooks/useTypedParam";
import { useI18n } from "../../hooks/useI18n";
import { useSWR } from "../../hooks/swr/useSWR";
import { useNativePage } from "../../hooks/useBridge";
import { getOrderedFilteredMediaList, Media } from "../../proto/Media";
import { Page } from "../../components/Page";
import { HSpacing, Spacing } from "../../components/Spacing";
import { HStack, Spring, VSpace, VStack } from "../../components/VStack";
import { ZDoc } from "../../components/zdoc/ZDoc";
import TagsFlowLayout from "../../components/TagsFlowLayout";
import { ContentGroup, GroupCellDivider } from "../../components/ContentGroup";
import { Image } from "../../components/Image";
import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { SingleLineUnspecifiedWidth } from "../../components/CommonViews";
import iconQRCode from "../../res/images/qr_code.svg";
import CircleBackgroundFrame from "./CircleBackgroundFrame";
import { useHopper } from "../../hooks/useHopper";
import { User } from "../../proto/User";
import icIndicator from "../../res/images/ic_indicator_24.svg";
import { TagLineBg } from "./CircleInfoPage";
import { NavEnd, NavItem, NavStart } from "../../components/NavBar";
import { NavButtonType } from "../../bridge/NavButtonDesc";
import {
  accent_font,
  cell_on_pressed,
  RowCenterButton,
} from "../../components/CommonStyles";
import { formatMoney } from "../../proto/Currency";
import BigNumber from "bignumber.js";
import { PageFooter } from "../../components/PageHeaderFooter";
import {
  ButtonColor,
  RegularButton,
  StrongButton,
  WeakButton,
} from "../../components/Buttons";
import { andLog, useErrorHandler } from "../../components/handleError";
import {
  CircleJoinPermission,
  JoinCircleBody,
  JoinCircleBodyQuestion,
  JoinCircleMethod,
  JoinRequestStatus,
} from "./JoinCircleRequest";
import iconLock from "../../res/images/img_circle_join_lock.png";
import { Spin } from "../../components/Spin";
import { useLoadState } from "../../hooks/LoadState";
import { CenterModal, ModalTitle, useModal } from "../../components/Modal";
import { RegularInputGroup } from "../../components/Input";
import { CircleMemberStatus } from "../../proto/CircleMemberStatus";
import { useMyUid } from "../../service/AuthSessionService";
import { BSMenu, BSMenuItem } from "../../components/BSMenu";
import { copyTextToClipboard } from "../../utils/CopyText";
import { useGlobalSpinner } from "../../utils/globalSpinner";
import { isInExternalAppShell } from "../../bridge/ExternalAppShellData";
import lockIcon from "../../res/images/ic_nft_lock.svg";
import { CircleToken, isValidCircleToken } from "../../proto/CircleFin";
import {
  circleBounceRateDisplayString,
  circleTokenFluctuationImage,
  marketCap1HipColor,
  marketCap1HipString,
} from "./CircleStatsPage";
import { ObjectType } from "../../proto/ObjectSpec";
import CircleCardsPreviewCell, {
  CardStyle,
} from "./card/CircleCardsPreviewCell";
import GridLayout from "../../components/GridLayout";
import { RelativeContainer } from "./CircleHomePage";
import icArrow from "../../pages/chat/images/ic_arrow.svg";
import {
  Circle,
  CircleJoinConditionType,
  CircleJoinStep,
  isCircleAdmin,
  isRequireCardToJoin,
} from "../../proto/Circle";
import iconPending from "../../res/images/icon_pending_time.png";
import iconChecked from "../../res/images/ic_choosed.svg";
import iconWait from "../../res/images/icon_ra_more.svg";
import iconRejected from "../../res/images/img_request_reject.png";
import iconNeedCard from "../../res/images/img_circle_need_card.png";
import { APIErrorCode } from "../../proto/APIErrorRespBody";
import { isRejectable } from "../../bridge/Rejectable";
import iconIncubation from "../../res/images/ic_incubation.svg";
import iconLaunched from "../../res/images/ic_stage_launched.svg";
import iconHourglass from "../../res/images/ic_hourglass.png";
import { CircleCardStatus } from "../../proto/CircleCardStatus";
import { FlagMediaLocationEnum } from "../../proto/Flag";
import { MediaOwner } from "../../proto/MediaOwner";
import { CircleTokenStatus } from "../../proto/CircleTokenStatus";

function CircleDetailInfoPage() {
  const backend = useBackend();
  const circleId = useBigIntParam("circleId");
  const i18n = useI18n();
  const circleSWR = useSWR(backend.getCircle(circleId));
  const nativePage = useNativePage();
  const hopper = useHopper();

  const joinCircleRes = useRef<Circle | undefined>(undefined);

  const orderedMediaList = getOrderedFilteredMediaList(
    circleSWR.content?.mediaList,
    circleSWR.content?.richFormat,
  );

  const viewMedia = (media: Media) => {
    if (orderedMediaList) {
      const mediaOwnerMap = new Map();
      orderedMediaList?.forEach((media) => {
        const ownerSpec = { objectId: circleId, objectType: ObjectType.CIRCLE };
        const flagExt = {
          flagMedia: media,
          flagMediaLocation: FlagMediaLocationEnum.DESCRIPTION_IMAGE,
        };

        const mediaOwner: MediaOwner = {
          ownerSpec: ownerSpec,
          authorId: circleSWR.content?.author?.uid,
          flagExt: flagExt,
        };
        mediaOwnerMap.set(media.mediaId, mediaOwner);
      });
      nativePage.viewMedia({
        mediaList: orderedMediaList,
        position: orderedMediaList.findIndex(
          (m) => m.mediaId === media.mediaId,
        ),
        mediaOwnerMap: mediaOwnerMap,
      });
    }
  };

  const [isJoined, setJoined] = useState<boolean>();
  const [isAdmin, setAdmin] = useState<boolean>();
  const myUid = useMyUid();
  useEffect(() => {
    const circle = circleSWR.content;
    if (circle) {
      const joined = circle.joinedStatus === CircleMemberStatus.JOINED;
      if (joined) {
        const selfUid = String(myUid);
        setAdmin(
          circle.adminIdList.map((it) => String(it)).includes(selfUid) ||
            circle.coAdminIdList.map((it) => String(it)).includes(selfUid),
        );
      } else {
      }
      setJoined(joined);
    } else {
      setJoined(undefined);
    }
  }, [circleSWR.content]);

  const joinCircleTask = useLoadState();
  const checkOwnCardTask = useLoadState();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const handleError = useErrorHandler();

  const lockModal = useModal("lockModal");
  const [questionInput, setQuestionInput] = useState("");
  const questionModal = useModal("questionModal");

  const [passcodeInput, setPasscodeInput] = useState("");
  const passcodeModal = useModal("passcodeModal");

  async function refreshCircleInfo() {
    const res = await joinCircleTask.run(async () => {
      return await backend.getCircle(circleId).run();
    });
    if (res.success) {
      return res.result;
    }
  }

  async function joinCircle(body: JoinCircleBody) {
    const r = await joinCircleTask.run(async () => {
      const circle = await backend.joinCircle(circleId, body).run();
      await circleSWR.fill(circle);
    });

    if (!r.success) {
      const e = r.error;
      if (
        isRejectable(e) &&
        e.apiCode === APIErrorCode.APICodeCircleUserBeenBlocked
      ) {
        nativePage.alertNotice(e.message, i18n.ok()).catch(andLog);
      } else if (
        isRejectable(e) &&
        e.apiCode === APIErrorCode.needPurchaseMembershipCard
      ) {
        questionModal.close();
        passcodeModal.close();
        onlyNeedCardModal.open();
      } else {
        handleError(r.error);
      }
    }
  }

  const pendingApproveModal = useModal("pendingApproveModal");

  const approvedAndWaitMembershipModal = useModal(
    "approvedAndWaitMembershipModal",
  );

  const rejectedApproveModal = useModal("rejectedApproveModal");

  const onlyNeedCardModal = useModal("onlyNeedCardModal");

  function checkPermissionAndShow(
    currentCircle: Circle,
    permission: CircleJoinPermission,
  ) {
    const joinStep = currentCircle.joinRequest?.extensions?.nextStep;
    const currRequireCardToJoin =
      currentCircle.joinConditions?.some(
        (condition) =>
          condition.conditionType === CircleJoinConditionType.MembershipCard,
      ) ?? false;

    console.log("currRequireCardToJoin🧧", currRequireCardToJoin);
    console.log("permission🧧", permission);
    if (currRequireCardToJoin && permission & CircleJoinPermission.OPEN) {
      checkHasOwnerCard(true).catch();
    } else if (permission === CircleJoinPermission.OPEN) {
      joinCircle({ joinMethod: JoinCircleMethod.OPEN }).catch(andLog);
    } else if (permission === CircleJoinPermission.LOCKED) {
      lockModal.open();
    } else if (
      currentCircle.joinRequest?.status === JoinRequestStatus.unprocess
    ) {
      pendingApproveModal.open();
    } else if (
      currentCircle.joinRequest?.status === JoinRequestStatus.rejected
    ) {
      rejectedApproveModal.open();
    } else if (joinStep === CircleJoinStep.purchaseMembershipCard) {
      checkHasOwnerCard(false).catch();
    } else if (permission & CircleJoinPermission.ACCEPT_JOIN_REQUEST) {
      if ((currentCircle.extensions?.question?.content?.length ?? 0) > 0) {
        hopper.push(`circle/${circleId}/question`);
      } else {
        questionModal.open();
      }
    } else if (permission & CircleJoinPermission.USE_PASSCODE) {
      passcodeModal.open();
    } else {
      lockModal.open();
    }
  }

  async function checkHasOwnerCard(onlyCard: boolean) {
    const r = await checkOwnCardTask.run(async () => {
      const nftList = await backend.getNFTList(108, circleId).run();
      if (nftList.list.length > 0) {
        requestToJoin().catch();
      } else {
        if (onlyCard) {
          onlyNeedCardModal.open();
        } else {
          approvedAndWaitMembershipModal.open();
        }
      }
    });
  }

  async function requestToJoin() {
    const questionId = circleSWR.content?.extensions?.questionId ?? BigInt(0);
    const body: JoinCircleBodyQuestion = {
      answer: questionInput,
      questionId: questionId,
      receiveCircleUpdate: true,
    };

    const r = await joinCircleTask.run(async () => {
      await backend.joinCircleInQuestion(circleId, body).run();
    });

    if (r.success) {
      questionModal.close();
    } else {
      const e = r.error;
      if (
        isRejectable(e) &&
        e.apiCode === APIErrorCode.APICodeCircleUserBeenBlocked
      ) {
        nativePage.alertNotice(e.message, i18n.ok()).catch(andLog);
      } else if (
        isRejectable(e) &&
        e.apiCode === APIErrorCode.needPurchaseMembershipCard
      ) {
        questionModal.close();
        passcodeModal.close();
        onlyNeedCardModal.open();
      } else {
        handleError(r.error);
      }

      questionModal.close();
    }
  }

  async function requestPasscodeToJoin() {
    const body: JoinCircleBody = {
      joinMethod: JoinCircleMethod.USEPASSCODE,
      joinToken: passcodeInput,
    };
    const r = await joinCircleTask.run(async () => {
      const circle = await backend.joinCircle(circleId, body).run();
      await circleSWR.fill(circle);
      await circleSWR.load();
    });

    if (!r.success) {
      const e = r.error;
      if (
        isRejectable(e) &&
        e.apiCode === APIErrorCode.needPurchaseMembershipCard
      ) {
        questionModal.close();
        passcodeModal.close();
        onlyNeedCardModal.open();
      } else if (
        isRejectable(e) &&
        e.apiCode === APIErrorCode.APICodeCircleUserBeenBlocked
      ) {
        nativePage.alertNotice(e.message, i18n.ok()).catch(andLog);
      } else {
        handleError(r.error);
      }
    } else {
      questionModal.close();
      passcodeModal.close();
    }
  }

  const navMenu = useModal("navMenu");
  const globalSpinner = useGlobalSpinner();

  const tokenSWR = useSWR(backend.getCircleToken(circleId));

  async function shareLink() {
    try {
      globalSpinner(async () => {
        const path = `circle/${circleId}`;
        const linkModel = await backend.getShareLink(path).run();
        if (linkModel.shareLink) {
          await copyTextToClipboard(linkModel.shareLink);
          await nativePage.successHud(i18n.copied());
        }
      }).catch(andLog);
    } catch (e) {
      handleError(e);
    }
  }

  const cardsSWR = useSWR(
    circleSWR.content &&
      circleSWR.content?.tokenProjectId &&
      isValidCircleToken(tokenSWR.content) &&
      tokenSWR.content.status !== CircleTokenStatus.Pending
      ? backend.getCircleCardsList(circleId, CircleCardStatus.Enable)
      : undefined,
  );
  const showLock =
    circleSWR.content &&
    (circleSWR.content.joinPermission !== CircleJoinPermission.OPEN ||
      circleSWR.content.joinConditions?.some(
        (condition) =>
          condition.conditionType === CircleJoinConditionType.MembershipCard,
      ));

  const noCircleTokenYetClick = () => {
    if (circleSWR.content && isCircleAdmin(circleSWR.content, myUid)) {
      hopper.push(`circle/${circleId}/admin-portal`);
    } else {
      nativePage
        .alertNotice(i18n.clover_circle_no_token_yet(), i18n.ok())
        .catch(andLog);
    }
  };

  return (
    <Page
      pageData={[circleSWR, tokenSWR, cardsSWR]}
      underlay={
        <CircleBackgroundFrame
          circleBackground={circleSWR.content?.circleBackground}
        />
      }
    >
      {isJoined && (
        <NavStart>
          {NavItem.image(NavButtonType.Back, () => {
            hopper.back();
          })}
        </NavStart>
      )}

      {!isJoined && (
        <>
          <NavStart>{<></>}</NavStart>
          <NavEnd>
            {NavItem.image(NavButtonType.Ellipsis, () => {
              navMenu.open();
            })}
            {NavItem.image(NavButtonType.Cross, () => {
              hopper.back();
            })}
          </NavEnd>
        </>
      )}

      <HStack>
        <Spring />
        <div style={{ position: "relative", alignItems: "center" }}>
          <Image
            src={[circleSWR.content?.circleIcon, 93]}
            alt={"circleIcon"}
            style={{
              margin: "20px auto",
              borderRadius: 12,
              border: `1px solid ${circleSWR.content?.themeColor}`,
            }}
            width={93}
            height={93}
            onClick={() => {
              circleSWR.content?.circleIcon &&
                nativePage.viewMedia({
                  mediaList: [circleSWR.content?.circleIcon],
                  position: 0,
                });
            }}
          ></Image>
          {circleSWR.content?.joinPermission !== CircleJoinPermission.OPEN && (
            <div
              style={{
                width: 20,
                height: 20,
                borderStartStartRadius: 10,
                borderStartEndRadius: 0,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 4,
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                position: "absolute",
                left: 0,
                top: 21,
              }}
            >
              <Image
                src={lockIcon}
                width={14}
                height={14}
                style={{ marginInlineStart: 4, marginTop: 4 }}
              />
            </div>
          )}
        </div>
        <Spring />
      </HStack>

      <CircleName>{circleSWR.content?.name}</CircleName>
      <HStack style={{ margin: "0 auto 20px" }}>
        <Image
          src={iconQRCode}
          alt={"iconQRCode"}
          width={24}
          height={24}
          onClick={() => {
            hopper.modal(`circle/${circleId}/qr-code-share`);
          }}
        />
        <CircleID>{`c/${circleSWR.content?.socialId || "-"}`}</CircleID>
      </HStack>
      {isValidCircleToken(tokenSWR.content) &&
        tokenSWR.content.status !== CircleTokenStatus.Pending && (
          <>
            <TokenValRectangleBackground
              onClick={() => {
                if (
                  !isJoined &&
                  circleSWR.content?.joinPermission !==
                    CircleJoinPermission.OPEN
                ) {
                  nativePage
                    .infoHud(
                      i18n.clover_you_cannot_view_more_info_because_this_circle_is_private_now(),
                    )
                    .catch(andLog);
                  return;
                }
                hopper.push(`circle/${circleId}/token-info`);
              }}
            >
              <HStack>
                <Image
                  alt={"tokenInfoIcon"}
                  src={[tokenSWR.content.image, "best"]}
                  width={24}
                  height={24}
                  style={{ borderRadius: "2px", marginInlineEnd: 4 }}
                ></Image>
                <TokenTickerLabel>
                  {isValidCircleToken(tokenSWR.content) &&
                    tokenSWR.content.ticker}
                </TokenTickerLabel>
                <Image
                  style={{ marginInlineStart: 4 }}
                  src={getCircleTokenStageImage(tokenSWR.content)}
                  width={12}
                  height={12}
                ></Image>
                <Spring />
                <VStack style={{ alignItems: "end" }}>
                  <HStack style={{ gap: 4 }}>
                    <InfoTitleLabel>
                      {i18n.moon_ads_market_cap()}
                    </InfoTitleLabel>
                    <MarketCapLabel>
                      $
                      {isValidCircleToken(tokenSWR.content) &&
                        formatMoney(
                          "short",
                          BigNumber(
                            tokenSWR.content.tradingStatistics?.marketCap ||
                              "0",
                          ),
                        )}
                    </MarketCapLabel>
                  </HStack>
                  <TokenValPercentageChangeLabel
                    style={{
                      color: marketCap1HipColor(tokenSWR.content),
                    }}
                  >
                    <Image
                      src={circleTokenFluctuationImage(tokenSWR.content)}
                      alt={"PercentageChangeDirection"}
                      width={12}
                      height={12}
                    ></Image>
                    {marketCap1HipString(tokenSWR.content)}
                  </TokenValPercentageChangeLabel>
                </VStack>
              </HStack>
            </TokenValRectangleBackground>
          </>
        )}
      {(!isValidCircleToken(tokenSWR.content) ||
        (isValidCircleToken(tokenSWR.content) &&
          tokenSWR.content.status === CircleTokenStatus.Pending)) && (
        <TokenValNoCircleTokenYetBackground onClick={noCircleTokenYetClick}>
          <Image
            src={iconHourglass}
            width={12}
            height={12}
            alt={"iconHourglass"}
          ></Image>
          <NoCircleTokenYetLabel>
            {i18n.clover_no_circle_token_yet()}
          </NoCircleTokenYetLabel>
        </TokenValNoCircleTokenYetBackground>
      )}

      {circleSWR.content?.joinConditions?.some(
        (condition) =>
          condition.conditionType === CircleJoinConditionType.MembershipCard,
      ) && (
        <RequireCardToJoinLabel>
          {i18n.clover_require_membership_card_to_join()}
        </RequireCardToJoinLabel>
      )}
      {circleSWR.content && circleSWR.content.memberPreview && (
        <HStack style={{ margin: "24px auto 20px" }}>
          <HStack>
            <HSpacing width={6} />
            {circleSWR.content.memberPreview.slice(0, 4).map((user, index) => (
              <CircleMember
                user={user}
                showIndicator={
                  index === 3 && (circleSWR.content?.membersCount || 0) > 4
                }
              ></CircleMember>
            ))}
          </HStack>
          <MembersCountText>
            {i18n
              .plural(circleSWR.content?.membersCount ?? 0)
              .number_of_members(circleSWR.content?.membersCount ?? 0)}
          </MembersCountText>
        </HStack>
      )}

      <TurnoverRate>
        <span className="text">
          {i18n.clover_30_day_average_turnover_rate()}
        </span>
        <span className="rate">
          {circleBounceRateDisplayString(circleSWR.content)}
        </span>
      </TurnoverRate>
      <TagLineBg style={{ padding: "7px 18px" }}>
        <HStack style={{ gap: 25, width: "100%" }}>
          <TaglineQuotationMarkContainer>
            <TaglineQuotationMark style={{ top: 0, left: 0 }}>
              “
            </TaglineQuotationMark>
          </TaglineQuotationMarkContainer>
          <TagLineContent>{circleSWR.content?.tagline}</TagLineContent>
          <TaglineQuotationMarkContainer>
            <TaglineQuotationMark style={{ bottom: 0, right: 0 }}>
              ”
            </TaglineQuotationMark>
          </TaglineQuotationMarkContainer>
        </HStack>
      </TagLineBg>
      <VSpace height={20} />
      {cardsSWR &&
        cardsSWR.content?.list &&
        cardsSWR.content?.list.length > 0 && (
          <>
            <DescriptionLabel>{i18n.clover_membership_info()}</DescriptionLabel>
            <ContentGroup
              onClick={() => {
                hopper.push(`circle/${circleId}/card-picker`);
              }}
              mixin={[cell_on_pressed]}
            >
              <MembershipLevelLabel>
                {i18n
                  .plural(cardsSWR.content?.list.length)
                  .clover_membership_level_some(cardsSWR.content?.list.length)}
              </MembershipLevelLabel>
              <GridLayout
                style={{ paddingTop: 0 }}
                spanCount={3}
                items={cardsSWR.content?.list.slice(0, 6).map((item, index) => (
                  <RelativeContainer>
                    <CircleCardsPreviewCell
                      cardStyle={CardStyle.medium}
                      circle={circleSWR.content}
                      card={item.circleMembershipCard}
                    />
                    {cardsSWR.content?.list &&
                      cardsSWR.content?.list.length > 6 &&
                      index === 5 && (
                        <ViewMoreContainer>
                          <ViewMoreLabel>
                            {i18n.clover_view_more()}
                          </ViewMoreLabel>
                          <Image
                            src={icArrow}
                            alt={"icArrow"}
                            width={16}
                            height={16}
                          ></Image>
                        </ViewMoreContainer>
                      )}
                  </RelativeContainer>
                ))}
              ></GridLayout>
            </ContentGroup>
          </>
        )}
      {circleSWR.content &&
        (circleSWR.content?.description.length ?? 0) > 0 && (
          <>
            <DescriptionLabel>{i18n.description()}</DescriptionLabel>

            <ZDoc
              content={circleSWR.content?.description}
              richFormat={circleSWR.content?.richFormat}
              mediaList={circleSWR.content?.mediaList}
              pollList={[]}
              onViewMedia={viewMedia}
            />
            <Spacing height={20} />
          </>
        )}
      {circleSWR.content && (circleSWR.content?.tagList?.length ?? 0) > 0 && (
        <TagsFlowLayout tags={circleSWR.content?.tagList ?? []} />
      )}
      <Spacing height={20} />
      {/*<CellDivider />*/}
      {/*{circleSWR.content?.createdTime && (*/}
      {/*  <CircleCreateDate>*/}
      {/*    {i18n.created_on(shortFromDate(circleSWR.content?.createdTime))}*/}
      {/*  </CircleCreateDate>*/}
      {/*)}*/}
      <Spacing height={100} />
      <PageFooter obscuredZoneKey={"CircleBottom"} safeBottomDisabled={true}>
        {!isInExternalAppShell() &&
          isJoined !== undefined &&
          circleSWR.content &&
          !isJoined && (
            <VStack
              style={{
                paddingBottom: 20,
                background:
                  "linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.6),  rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.2))",
              }}
            >
              <RegularButton
                style={{
                  ...RowCenterButton,
                  marginTop: 8,
                }}
                $baseBgColor={"var(--color-bg)"}
                $baseColor={ButtonColor.greenish}
                onClick={() => {
                  console.log("CircleDetailInfoPage🧧");
                  refreshCircleInfo().then((circle) => {
                    if (!circle) return;
                    joinCircleRes.current = circle;
                    checkPermissionAndShow(
                      circle,
                      circle.joinPermission ?? CircleJoinPermission.OPEN,
                    );
                  });
                }}
              >
                <Spin state={joinCircleTask.state}>
                  {showLock && <Image src={iconLock} alt={"reveal"} />}
                  {i18n.clover_join_the_circle()}
                </Spin>
              </RegularButton>
              {circleSWR.content.joinPermission === CircleJoinPermission.OPEN &&
                !isRequireCardToJoin(circleSWR.content) && (
                  <WeakButton
                    style={{
                      ...RowCenterButton,
                      marginTop: 8,
                    }}
                    $baseBgColor={"var(--color-bg)"}
                    onClick={() => {
                      hopper.push(`circle/${circleId}`, {
                        isBrowse: true,
                      });
                    }}
                  >
                    {i18n.arthur_browse_this_circle()}
                  </WeakButton>
                )}
            </VStack>
          )}
      </PageFooter>

      <CenterModal modal={pendingApproveModal} hideCloseBtn={true}>
        <HStack>
          <Spring />
          <Image src={iconPending} width={84} height={84} />
          <Spring />
        </HStack>
        <PendingApprovalTitle>
          {i18n.clover_pending_approval()}
        </PendingApprovalTitle>
        <PendingApprovalSubtitle>
          {i18n.clover_submitted_join_request()}
        </PendingApprovalSubtitle>
        <RegularButton
          onClick={() => {
            pendingApproveModal.close();
          }}
        >
          {i18n.ok()}
        </RegularButton>

        <HStack
          onClick={() => {
            if (
              joinCircleRes.current?.joinPermission &&
              joinCircleRes.current?.joinPermission &
                CircleJoinPermission.ACCEPT_JOIN_REQUEST
            ) {
              if (
                (joinCircleRes.current?.extensions?.question?.content?.length ??
                  0) > 0
              ) {
                hopper.push(`circle/${circleId}/question`);
              } else {
                questionModal.open();
              }
            } else if (
              joinCircleRes.current?.joinPermission &&
              joinCircleRes.current?.joinPermission &
                CircleJoinPermission.USE_PASSCODE
            ) {
              passcodeModal.open();
            } else {
              lockModal.open();
            }
            pendingApproveModal.close();
          }}
        >
          <Spring />
          <div
            style={{
              fontSize: 14,
              fontWeight: 500,
              color: "rgba(52, 166, 255, 1)",
              textDecoration: "underline",
            }}
          >
            {i18n.clover_request_again()}
          </div>
          <Spring />
        </HStack>
      </CenterModal>

      <CenterModal modal={approvedAndWaitMembershipModal}>
        <HStack
          style={{
            display: "flex",
            alignItems: "center",
            marginInlineStart: 20,
            marginInlineEnd: 20,
          }}
        >
          <Image src={iconChecked} width={36} height={36} />
          <div
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.4)",
              height: 1,
              flexGrow: 1,
            }}
          ></div>
          <Image src={iconWait} width={36} height={36} />
        </HStack>
        <HStack>
          <div
            style={{
              fontSize: 16,
              color: "rgba(0, 255, 148, 1)",
              fontWeight: 400,
            }}
          >
            {i18n.clover_approved()}
          </div>
          <Spring />
          <div
            style={{
              fontSize: 16,
              color: "rgba(255, 255, 255, 1)",
              fontWeight: 400,
            }}
          >
            {i18n.clover_get_membership()}
          </div>
        </HStack>
        <div
          style={{
            fontSize: 14,
            fontWeight: 500,
            color: "rgba(255, 255, 255, 1)",
          }}
        >
          {i18n.clover_only_need_card()}
        </div>
        <RegularButton
          onClick={() => {
            approvedAndWaitMembershipModal.close();
            hopper.push(`circle/${circleId}/card-picker`);
          }}
        >
          {i18n.web3_v0_purchase()}
        </RegularButton>
      </CenterModal>

      <CenterModal modal={lockModal} hideCloseBtn={true}>
        <HStack>
          <Spring />
          <Image src={iconLock} width={84} height={84} alt={"iconLock"} />
          <Spring />
        </HStack>

        <ModalTitle style={{ fontSize: 16, fontWeight: 500 }}>
          {i18n.circle_perm_reject_join_request()}
        </ModalTitle>

        <RegularButton
          onClick={() => {
            lockModal.close();
          }}
        >
          {i18n.ok()}
        </RegularButton>
      </CenterModal>

      <CenterModal modal={rejectedApproveModal}>
        <HStack>
          <Spring />
          <Image src={iconRejected} width={160} height={80} />
          <Spring />
        </HStack>
        <div
          style={{
            fontSize: 18,
            fontWeight: 500,
            color: "rgba(255, 255, 255, 1)",
            textAlign: "center",
          }}
        >
          {i18n.clover_join_request_rejected()}
        </div>
        <RegularButton
          onClick={() => {
            rejectedApproveModal.close();
          }}
        >
          {i18n.ok()}
        </RegularButton>

        <HStack
          onClick={() => {
            console.log(
              "ACCEPT_JOIN_REQUEST🧧",
              joinCircleRes.current?.joinPermission,
            );
            if (
              joinCircleRes.current?.joinPermission &&
              joinCircleRes.current?.joinPermission &
                CircleJoinPermission.ACCEPT_JOIN_REQUEST
            ) {
              if (
                (joinCircleRes.current.extensions?.question?.content?.length ??
                  0) > 0
              ) {
                hopper.push(`circle/${circleId}/question`);
              } else {
                questionModal.open();
              }
            } else {
              passcodeModal.open();
            }
            rejectedApproveModal.close();
          }}
        >
          <Spring />
          <div
            style={{
              fontSize: 14,
              fontWeight: 500,
              color: "rgba(52, 166, 255, 1)",
              textDecoration: "underline",
            }}
          >
            {i18n.clover_request_again()}
          </div>
          <Spring />
        </HStack>
      </CenterModal>

      <CenterModal modal={onlyNeedCardModal}>
        <HStack>
          <Spring />
          <Image src={iconNeedCard} width={160} height={80} />
          <Spring />
        </HStack>
        <div
          style={{
            fontSize: 18,
            fontWeight: 500,
            color: "rgba(255, 255, 255, 1)",
            textAlign: "center",
          }}
        >
          {i18n.clover_only_need_card()}
        </div>
        <RegularButton
          onClick={() => {
            onlyNeedCardModal.close();
            hopper.push(`circle/${circleId}/card-picker`);
          }}
        >
          {i18n.web3_v0_purchase()}
        </RegularButton>
      </CenterModal>

      <CenterModal modal={questionModal}>
        <HStack>
          <Spring />
          <Image src={iconLock} width={84} height={84} alt={"iconLock"} />
          <Spring />
        </HStack>

        <ModalTitle style={{ fontSize: 16, fontWeight: 500 }}>
          {i18n.circle_perm_input_answer_alert()}
        </ModalTitle>

        <RegularInputGroup
          updateValue={setQuestionInput}
          placeholder={i18n.circle_perm_add_a_note()}
        ></RegularInputGroup>

        <StrongButton
          onClick={() => {
            requestToJoin().catch(andLog);
          }}
        >
          <Spin state={joinCircleTask.state}>{i18n.request_to_join()}</Spin>
        </StrongButton>

        {joinCircleRes.current?.joinPermission &&
          (joinCircleRes.current?.joinPermission &
            CircleJoinPermission.USE_PASSCODE) !==
            0 && (
            <HStack
              onClick={() => {
                passcodeModal.open();
              }}
            >
              <Spring />
              <EnterPasswordInsteadLabel>
                {i18n.circle_perm_enter_passcode_instead()}
              </EnterPasswordInsteadLabel>
              <Spring />
            </HStack>
          )}
      </CenterModal>

      <CenterModal modal={passcodeModal}>
        <HStack>
          <Spring />
          <Image src={iconLock} width={84} height={84} alt={"iconLock"} />
          <Spring />
        </HStack>

        <ModalTitle style={{ fontSize: 16, fontWeight: 500 }}>
          {i18n.circle_perm_enter_the_passcode()}
        </ModalTitle>

        <RegularInputGroup updateValue={setPasscodeInput}></RegularInputGroup>

        <RegularButton
          onClick={() => {
            requestPasscodeToJoin().catch();
          }}
        >
          {i18n.ok()}
        </RegularButton>
      </CenterModal>
      <BSMenu modal={navMenu}>
        <HStack onClick={() => navMenu.close()}>
          <Image
            src={[circleSWR.content?.circleIcon, 30]}
            alt={"circleIcon"}
            style={{
              borderRadius: 4,
              marginRight: 6,
              border: `1px solid ${circleSWR.content?.themeColor}`,
            }}
            width={30}
            height={30}
          ></Image>
          <CircleBsMenuName>{circleSWR.content?.name}</CircleBsMenuName>
        </HStack>
        <BSMenuItem
          title={i18n.may22_impr_copy_link_to_this_circle()}
          onClick={() => {
            shareLink().catch(andLog);
          }}
        ></BSMenuItem>
        <BSMenuItem
          title={i18n.circle_perm_invite_and_share()}
          onClick={() => {
            hopper.modal(`circle/${circleId}/qr-code-portal`);
          }}
        ></BSMenuItem>
        <BSMenuItem
          title={i18n.may22_impr_flag_this_circle()}
          onClick={() => {
            hopper.layer("flag", {
              objectId: circleId,
              objectType: ObjectType.CIRCLE,
            });
          }}
        ></BSMenuItem>
        {isJoined === true && (
          <BSMenuItem
            title={i18n.circle_settings()}
            onClick={() => {
              hopper.push(`circle/${circleId}/setting`);
            }}
          ></BSMenuItem>
        )}
      </BSMenu>
    </Page>
  );
}

const CircleName = styled.div`
  ${accent_font(20)};
  color: var(--color-text00);
  word-break: break-word;
  ${SingleLineUnspecifiedWidth};
  margin: 0 auto;
`;

const CircleBsMenuName = styled.div`
  font-size: 16px;
  font-weight: 500;
  text-align: start;
  color: var(--color-text00);
  width: calc(100% - 36px);
  ${SingleLineUnspecifiedWidth}
`;

const CircleID = styled.div`
  font-weight: 500;
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  margin-right: 4px;
`;

const MembersCountText = styled.div`
  color: var(--color-text00);
  font-weight: 500;
  font-size: 16px;
  margin-inline-start: 8px;
`;

const TurnoverRate = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 14px;
  color: white;
  margin: 0 auto 36px;
  border-radius: 13px;
  padding: 4px 12px;
  background: #ffffff0f;

  word-wrap: break-word;
  word-break: break-word;

  .text {
    font-weight: 500;
    margin-right: 4px;
    white-space: normal;
    max-width: 100%;
  }

  .rate {
    font-weight: 700;
    color: #0dffcc;
    white-space: normal;
    max-width: 100%;
  }
`;

export const TokenValRectangleBackground = styled.div`
  padding: 4px 8px;
  align-items: center;
  justify-content: center;
  border: 0.35px solid #8db1f2cc;
  border-radius: 6px;
  background: #8db1f233;
  ${cell_on_pressed};
`;

export const TokenValNoCircleTokenYetBackground = styled.div`
  padding: 14px 8px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  border: 0.35px solid #ffffff66;
  border-radius: 6px;
  background: #51515166;
  ${cell_on_pressed};
`;

export const NoCircleTokenYetLabel = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.4);
`;

const TokenTickerLabel = styled.div`
  font-weight: 700;
  font-size: 10px;
  color: var(--color-text00);
  background: #ffffff0f;
  padding: 2px;
  border-radius: 2px;
`;

export const TokenPriceLabel = styled.div`
  font-weight: 700;
  font-size: 17px;
  color: #0dffcc;
`;

export const TokenValPercentageChangeLabel = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #00ff94;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const PercentageChangeLabel = styled.div`
  font-weight: 500;
  font-size: 10px;
  color: #00ff94;
  border-radius: 6px;
  padding: 4px;
  background: #00ff941a;
  display: flex;
  align-items: center;
  gap: 2px;
`;

export const InfoTitleLabel = styled.div`
  color: var(--color-text10);
  font-weight: 400;
  font-size: 10px;
`;

export const MarketCapLabel = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: var(--color-text00);
`;

const TaglineQuotationMarkContainer = styled.div`
  position: relative;
  height: 100%;
`;

const TaglineQuotationMark = styled.div`
  position: absolute;
  color: rgba(255, 255, 255, 058);
  font-weight: 700;
  font-size: 50px;
  height: 30px;
  width: 24px;
`;

const TagLineContent = styled.div`
  color: var(--color-text00);
  font-weight: 700;
  word-break: break-word;
  font-size: 15px;
  width: calc(100% - 48px);
  margin: 24px auto;
  line-height: 18px;
  text-align: center;
`;

const DescriptionLabel = styled.div`
  color: #0dffcc;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  margin: 32px auto 12px;
`;

const CircleDesc = styled.div`
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  text-align: center;
  margin: 0 auto;
`;

const CircleCreateDate = styled.div`
  margin-top: 20px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
`;

const EnterPasswordInsteadLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: white;
  text-decoration: underline;
`;

function CircleMember(props: { user: User; showIndicator: boolean }) {
  return (
    <VStack style={{ alignItems: "center" }}>
      <Image
        src={[props.user.icon, "smallest"]}
        width={30}
        height={30}
        style={{
          borderRadius: "50%",
          marginInlineStart: -6,
          border: "0.5px solid white",
        }}
      />
      {props.showIndicator && <Indicator></Indicator>}
    </VStack>
  );
}

export function getCircleTokenStageImage(
  circleToken: CircleToken | {} | undefined,
): string {
  if (isValidCircleToken(circleToken)) {
    switch (circleToken.status) {
      case CircleTokenStatus.Pending:
      case CircleTokenStatus.Running:
      case CircleTokenStatus.Finished:
        return iconIncubation;
      case CircleTokenStatus.DexPooled:
        return iconLaunched;
      default:
        return iconIncubation;
    }
  } else {
    return iconIncubation;
  }
}

const Indicator = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-inline-start: -6px;

  background-image: url(${icIndicator});
  background-repeat: no-repeat;
  background-position: center;
  background-color: rgba(0, 0, 0, 0.2);
`;

const MembershipLevelLabel = styled.div`
  font-weight: 500;
  color: var(--color-text00);
  font-size: 12px;
  margin-bottom: 8px;
`;

const ViewMoreContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 7.5px;
`;

const ViewMoreLabel = styled.div`
  font-weight: 500;
  color: var(--color-text00);
  font-size: 10px;
  text-align: center;
`;

const RequireCardToJoinLabel = styled.div`
  font-weight: 500;
  color: #ffa800;
  background: rgba(255, 255, 255, 0.08);
  font-size: 15px;
  text-align: center;
  padding: 12px 20px;
  margin: 24px auto 0;
  border-radius: 6px;
`;

const PendingApprovalTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  color: rgba(255, 175, 2, 1);
`;

const PendingApprovalSubtitle = styled.div`
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  color: var(--color-text00);
`;

export default CircleDetailInfoPage;
