import { resolvePath, WidgetItemProps } from "./CircleWidgetBanner";
import React from "react";
import styled from "styled-components";
import { Image } from "./Image";
import { SingleLineUnspecifiedWidth } from "./CommonViews";
import { useLoadState } from "../hooks/LoadState";
import { useBackend } from "../service/APIService";
import { useHopper } from "../hooks/useHopper";
import { Spin } from "./Spin";
import { useErrorHandler } from "./handleError";

const Container = styled.div`
  position: relative;
  width: 100%; /* 每个 item 的宽度是 80%，左右留白 10% */
  padding-bottom: 100%; /* 确保宽高比为1:1 */
  margin: 0 auto; /* 居中 */
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const GradientOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px; /* 与 Image 保持一致 */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0)
  ); /* 顶部黑色到底部透明的渐变 */
  pointer-events: none; /* 让渐变层不阻挡点击事件 */
`;

export const OverlayText = styled.div`
  position: absolute;
  top: 10px; /* 距离顶部的间距 */
  left: 10px; /* 距离左边的间距 */
  color: white;
  font-size: 18px;
  ${SingleLineUnspecifiedWidth};
  font-weight: 400;
  z-index: 1; /* 确保文字在最上层 */
  pointer-events: none; /* 让文字也不阻挡点击 */
`;

function LargeWidget(props: WidgetItemProps) {
  const linkTarget = useLoadState();
  const handleError = useErrorHandler();
  const backend = useBackend();
  const hopper = useHopper();

  async function openInternalLink(link: string) {
    const r = await linkTarget.run(async () => {
      const linkModel = await backend.linkPathFrom(link).run();
      linkModel.path && resolvePath(linkModel.path, hopper);
    });

    if (!r.success) {
      handleError(r.error);
    } else {
      // hopper.back();
    }
  }

  return (
    <Container
      onClick={() => {
        // hopper.
        let targetLink = props.widgetItem.targetLink;
        targetLink && openInternalLink(targetLink);
      }}
    >
      <Content>
        <Image
          key={props.widgetItem.widgetItemId}
          style={{
            borderRadius: 10,
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
            border: "1px solid rgba(255, 255, 255, 0.2)",
          }}
          src={[props.widgetItem.background?.backgroundImage, "best"]}
          alt={`Large-widget-${props.widgetItem.widgetItemId}`}
        />
        {/* 渐变层 */}
        <GradientOverlay />
      </Content>
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          left: 0,
          top: 0,
        }}
      >
        <Spin state={linkTarget.state}>{}</Spin>
      </div>
    </Container>
  );
}

export default LargeWidget;
