import React from "react";
import styled from "styled-components";
import iconDiscover0 from "../../src/res/images/icon_home_discover_0.svg";
import iconDiscover1 from "../../src/res/images/icon_home_discover_1.png";
import iconCircle0 from "../../src/res/images/icon_home_circle_0.svg";
import iconCircle1 from "../../src/res/images/icon_home_circle_1.png";
import iconChat0 from "../../src/res/images/icon_home_chat_0.svg";
import iconChat1 from "../../src/res/images/icon_home_chat_1.png";
import iconMarket0 from "../../src/res/images/icon_home_market_0.svg";
import iconMarket1 from "../../src/res/images/icon_home_market_1.png";
import iconCompose0 from "../../src/res/images/icon_home_compose_0.svg";
import { Image } from "./Image";
import { useHopState } from "../hooks/useHopState";
import { useObscuredZoneForKey } from "../hooks/useObscuredZones";
import { BlurBg } from "./CommonStyles";

interface BottomNavigationProps {
  onTabChange: (tabIndex: number) => void;
  initialTab?: number;
}

// Tab 配置包含每个 Tab 的名称和选中/未选中的图标
const TabData = [
  { name: "Discover", activeIcon: iconDiscover1, inactiveIcon: iconDiscover0 },
  { name: "Circles", activeIcon: iconCircle1, inactiveIcon: iconCircle0 },
  { name: "Compose", activeIcon: iconCompose0, inactiveIcon: iconCompose0 },
  { name: "Chats", activeIcon: iconChat1, inactiveIcon: iconChat0 },
  { name: "Market", activeIcon: iconMarket1, inactiveIcon: iconMarket0 },
];

const BottomNavigation: React.FC<BottomNavigationProps> = ({
  onTabChange,
  initialTab = 0,
}) => {
  const [obscuringZone] = useObscuredZoneForKey("Browser");

  const [activeTab, setActiveTab] = useHopState<number>(
    "activeTab",
    initialTab,
  );

  const handleTabClick = (index: number) => {
    setActiveTab(index);
    onTabChange(index);
  };

  return (
    <HomeTabBarContainer style={{ paddingBottom: obscuringZone.bottom }}>
      {TabData.map((tab, index) => (
        <Tab
          key={index}
          $isActive={index === activeTab}
          onClick={() => handleTabClick(index)}
        >
          <Image
            width={44}
            height={44}
            src={index === activeTab ? tab.activeIcon : tab.inactiveIcon}
          />
        </Tab>
      ))}
    </HomeTabBarContainer>
  );
};

const HomeTabBarContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 60px;
  width: 100%;

  /* 背景颜色和透明度，增加黑色蒙层效果 */
  background-color: rgba(0, 0, 0, 0.45); /* 加入带透明度的黑色背景 */
  border-top: 1px solid rgba(255, 255, 255, 0.2); /* 为了提升视觉效果的边框 */

  ${BlurBg};
`;

const Tab = styled.div<{ $isActive: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  transition:
    transform 0.2s ease,
    color 0.2s ease,
    opacity 0.2s ease; /* 为透明度和缩放添加平滑过渡 */

  &:active {
    opacity: 0.6; /* 按压时降低透明度 */
    transform: scale(0.95); /* 按压时缩小到95% */
  }
`;

export default BottomNavigation;
