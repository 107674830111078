import { z } from "zod";
import { zStatic } from "../../../utils/zodUtils";
import { TokenProject } from "../../../proto/TokenProject";
import { MiniAssetOrder } from "./MiniAssetOrder";

export const MintTokenResult = z.object({
  assetOrder: MiniAssetOrder,
});

export type MintTokenResult = zStatic<typeof MintTokenResult>;
