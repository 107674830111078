import { flattenStateId, StateId } from "../hooks/StateId";
import { createContext, ReactElement, ReactNode, useContext } from "react";
import { usePageParent } from "./PageCompositionBoundary";
import { assert } from "../utils/asserts";

interface Context {
  pageId: StateId;
  precedingPageIds: StateId[];
}

const Context = createContext<Context>({
  pageId: "subpage-root",
  precedingPageIds: [],
});

export type SubpageProps = {
  id: StateId;
  title?: ReactNode;
  children: ReactElement;
};

export function Subpage(props: SubpageProps) {
  const context = useContext(Context);
  return (
    <Context.Provider
      value={{
        pageId: props.id,
        precedingPageIds: [...context.precedingPageIds, context.pageId],
      }}
    >
      {props.children}
    </Context.Provider>
  );
}

export function usePageId() {
  const subpageContext = useContext(Context);
  return {
    pageId: subpageContext.pageId,
    absolutePageId: [...subpageContext.precedingPageIds, subpageContext.pageId],
  };
}

export function PageDepthChecker() {
  const pageParent = usePageParent();
  const subpageContext = useContext(Context);
  assert(
    subpageContext.precedingPageIds.length + 1 === pageParent.depth,
    `Page inside a Page should be enclosed by a <Subpage> tag. current=<${flattenStateId(subpageContext.pageId)}>, preceding=[${subpageContext.precedingPageIds}], depth=${pageParent.depth}`,
  );
  return <></>;
}

export function subpage(id: StateId, element: ReactElement, title?: ReactNode) {
  return (
    <Subpage id={id} key={flattenStateId(id)} title={title}>
      {element}
    </Subpage>
  );
}

export function subpages<K extends StateId>(
  entries: [K, ReactNode][],
  element: (k: K) => ReactElement,
) {
  return entries.map((e) => subpage(e[0], element(e[0]), e[1]));
}
