import { ButtonColor, genBg, genBorder } from "../../components/Buttons";
import React from "react";
import { useI18n } from "../../hooks/useI18n";
import styled, { RuleSet } from "styled-components";
import iconTextMatch from "../../res/images/icon_text_match_hd.webp";
import iconVoiceMatch from "../../res/images/icon_voice_match_hd.webp";
import { button_on_pressed } from "../../components/CommonStyles";
import { Image } from "../../components/Image";
import { useHopper } from "../../hooks/useHopper";

function HomeMatchLayout() {
  const i18n = useI18n();
  const hopper = useHopper();
  function onClickTextMatch() {
    hopper.layer("text-match");
  }
  function onClickVoiceMatch() {
    hopper.layer("voice-match");
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: 12,
      }}
    >
      <MatchCell
        $baseColor={ButtonColor.bluish}
        onClick={() => onClickTextMatch()}
      >
        <MatchTypeText>{i18n.meet_fix_option_text_match()}</MatchTypeText>
        <Image
          style={{ position: "absolute", right: 6 }}
          src={iconTextMatch}
          width={54}
          height={54}
          alt={"text-match"}
        />
      </MatchCell>

      <MatchCell
        $baseColor={ButtonColor.greenish}
        onClick={() => onClickVoiceMatch()}
      >
        <MatchTypeText>{i18n.meet_fix_option_video_match()}</MatchTypeText>
        <Image
          style={{ position: "absolute", right: 6 }}
          src={iconVoiceMatch}
          width={54}
          height={54}
          alt={"voice-match"}
        />
      </MatchCell>
    </div>
  );
}

const MatchTypeText = styled.div`
  font-weight: 700;
  font-size: 13px;
  color: var(--color-text00);
  text-shadow: 0 0 6px rgba(255, 255, 255, 0.66); /* 白色阴影，66%透明度，12px模糊 */
`;

const MatchCell = styled.div<{
  $baseColor?: string;
  mixin?: RuleSet<Object>;
}>`
  color: ${(p) => p.$baseColor ?? "white"};
  display: flex;
  flex-grow: 1;
  align-items: center;
  height: 80px;
  position: relative;
  padding-inline-start: 6px;
  padding-inline-end: 6px;

  box-sizing: border-box;

  border-radius: 6px;

  background: ${(p) => genBg(p.$baseColor)};

  border-image: ${(p) => genBorder(p.$baseColor)};

  &:disabled {
    opacity: 0.4;
  }

  ${button_on_pressed}
  ${(p) => p.mixin}
`;

export default HomeMatchLayout;
