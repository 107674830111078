import styled from "styled-components";
import {
  Animation,
  ModalContainer,
  ModalController,
  ModalDimmer,
  requireModalController,
} from "./Modal";
import { VStackMixin } from "./VStack";
import { FadeIn, FadeOut, None } from "./Keyframes";
import React, { PropsWithChildren } from "react";
import { ModalContext } from "./ModalContext";
import { Image } from "./Image";

export const VHudBg = styled.div<{ $animation: Animation }>`
  ${VStackMixin};
  background: rgba(0, 0, 0, 0.85);

  border-radius: 12px;
  align-items: center;
  justify-content: center;

  animation: ${(p) =>
      p.$animation === Animation.None
        ? None
        : p.$animation === Animation.In
          ? FadeIn
          : FadeOut}
    ${(p) => (p.$animation === Animation.In ? "0.3" : "0.15")}s linear 1
    forwards;
  animation-fill-mode: both;
  box-sizing: border-box;

  border: solid 1px rgba(255, 255, 255, 0.2);

  padding: 20px;
  min-width: 160px;
  max-width: 50%;
  gap: 12px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: var(--color-text00);
`;

export function ModalHud(
  props: PropsWithChildren<{
    icon?: string;
    title?: string;
    modal: ModalController;
  }>,
) {
  requireModalController(props.modal);

  return props.modal.mounted ? (
    <ModalContainer id={props.modal.id} modal={props.modal}>
      <ModalContext.Provider value={{ modal: props.modal }}>
        <ModalDimmer
          $animation={props.modal.animation}
          $backgroundColor={"rgba(0, 0, 0, 0.4)"}
        />
        <VHudBg
          onClick={(e) => {
            e.stopPropagation();
          }}
          $animation={props.modal.animation}
          onAnimationEnd={props.modal.onAnimationEnd}
        >
          {props.icon && <Image src={props.icon} width={32} height={32} />}
          {props.title ? <Title>{props.title}</Title> : undefined}
        </VHudBg>
      </ModalContext.Provider>
    </ModalContainer>
  ) : (
    <></>
  );
}
