import { useI18n } from "../../hooks/useI18n";
import { useNativePage } from "../../hooks/useBridge";
import { useHopper } from "../../hooks/useHopper";
import { useStringSearchParam } from "../../hooks/useTypedParam";
import { AuthBody } from "../../proto/Auth";
import { Page } from "../../components/Page";
import { NavEnd, NavItem } from "../../components/NavBar";
import { HStack, VSpace } from "../../components/VStack";
import CodeInput from "../../components/CodeInput";
import { RegularLargeButton } from "../../components/Buttons";
import styled from "styled-components";
import invitationFromBg from "../../res/images/invitation_from_bg.svg";
import { Image } from "../../components/Image";
import React, { useEffect, useRef, useState } from "react";
import boxUncheck from "../../res/images/box_uncheck.svg";
import boxChecked from "../../res/images/box_checked.svg";
import { InviterInfo } from "../../proto/InviterInfo";
import { UserAvatarView } from "../../components/views/UserAvatarView";
import { UserNameView } from "../../components/views/UserNameView";
import { StringValidator } from "../../utils/StringValidator";
import { isRejectable } from "../../bridge/Rejectable";
import { APIErrorCode } from "../../proto/APIErrorRespBody";
import { useBackend } from "../../service/APIService";
import { andLog, useErrorHandler } from "../../components/handleError";
import { useLoadState } from "../../hooks/LoadState";
import { Spin } from "../../components/Spin";
import { useMemoryRepo } from "../../hooks/swr/useLocalRepo";
import { accent_font } from "../../components/CommonStyles";

export function InvitationCodePage() {
  const i18n = useI18n();
  const backend = useBackend();
  const hopper = useHopper();
  const nativePage = useNativePage();
  const handleError = useErrorHandler();
  const loadTask = useLoadState();

  const flowId = useStringSearchParam("f");
  const authInfo = useMemoryRepo<AuthBody>(flowId ?? "", {});
  useEffect(() => {
    if (flowId === undefined) {
      window.location.href = "/auth-home";
    }
  }, [flowId]);

  const [inviterInfo, setInviterInfo] = useState<InviterInfo | undefined>();

  const invitationCode = useRef<string>("");

  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);

  async function handleInvitationCodeChange(value: string) {
    if (!StringValidator.isValidInvitationCode(value)) {
      clearInviterInfo();
      return;
    }
    const result = await loadTask.run(async () => {
      const auth = {
        ...authInfo.content,
        invitationCode: value,
        joinFanCircle: joinFanCircle,
      };
      const info = await backend.checkInvitationCode(auth).run();
      if (info.inviter) {
        invitationCode.current = value;
        setInviterInfo(info);
        setNextButtonDisabled(false);
      } else {
        clearInviterInfo();
      }
    });

    if (!result.success) {
      const e = result.error;
      clearInviterInfo();
      if (
        isRejectable(e) &&
        e.apiCode === APIErrorCode.APICodeInvitationCodeNotExisted
      ) {
        const chooseEdit = await nativePage.alertYesOrCancel(
          e.debugMessage || "",
          i18n.edit(),
          i18n.skip(),
        );
        if (chooseEdit) {
          if (codeInputRef.current) {
            codeInputRef.current.focusLastFilledInput();
          }
        } else {
          onSkip();
        }
      } else {
        handleError(e);
      }
    } else {
      onNextButtonClick().catch(andLog);
    }
  }

  const onSkip = () => {
    hopper.push("select-region", { f: flowId });
  };

  function clearInviterInfo() {
    invitationCode.current = "";
    setInviterInfo(undefined);
    setNextButtonDisabled(true);
  }

  const onNextButtonClick = async () => {
    await authInfo.fill((prev) => ({
      ...prev,
      invitationCode: invitationCode.current,
      joinFanCircle: joinFanCircle,
    }));
    hopper.push("select-region", {
      f: flowId,
    });
  };

  const [joinFanCircle, setJoinFanCircle] = useState<boolean>(false);
  const checkBox = () => {
    setJoinFanCircle(!joinFanCircle);
  };

  const codeInputRef = useRef<{ focusLastFilledInput: () => void }>(null);

  return (
    <Page pageData={undefined}>
      <NavEnd>
        {NavItem.text(i18n.help(), () => hopper.push("feedback"))}
      </NavEnd>
      <AuthTitleLabel>{i18n.onboarding_input_invitation_code()}</AuthTitleLabel>
      <AuthSubtitleLabel>
        {i18n.onboarding_input_invitation_description()}
      </AuthSubtitleLabel>
      <VSpace height={46} />
      <CodeInput
        ref={codeInputRef}
        returnOnEachChange={true}
        onChange={(value) => handleInvitationCodeChange(value)}
      />
      <InvitationHintLabel>{i18n.invitation_hint()}</InvitationHintLabel>
      <VSpace height={61} />
      {inviterInfo?.inviter && (
        <InviterInfoView>
          <>
            <InviteFromView>
              <Image
                style={{ position: "absolute" }}
                src={invitationFromBg}
                alt={"invitationFromBg"}
              ></Image>
              <InviteFromText>{i18n.fav_widget_invite_from()}</InviteFromText>
            </InviteFromView>
            <VSpace height={30} />
            <UserAvatarView
              onClick={() => {}}
              user={inviterInfo?.inviter}
              iconSize={100}
            ></UserAvatarView>
            <VSpace height={8} />
            <UserNameView user={inviterInfo?.inviter}></UserNameView>
            {inviterInfo?.fanCircleInfo?.circleId && (
              <HStack style={{ margin: "56px 50px 0", alignItems: "start" }}>
                <Image
                  style={{ width: 14, height: 14, marginRight: 10 }}
                  src={joinFanCircle ? boxChecked : boxUncheck}
                  alt={"check_box"}
                  onClick={checkBox}
                ></Image>
                <JoinSomeBodyCircle>
                  {i18n.ext_social_join_fan_circle("")}
                </JoinSomeBodyCircle>
              </HStack>
            )}
          </>
        </InviterInfoView>
      )}
      <RegularLargeButton
        disabled={nextButtonDisabled}
        style={{ height: "54px", margin: "15px 50px 0" }}
        onClick={onNextButtonClick}
      >
        <Spin state={loadTask.state}>{i18n.next()}</Spin>
      </RegularLargeButton>
      <VSpace height={16} />
      <SkipButton onClick={onSkip}>{i18n.skip()}</SkipButton>
    </Page>
  );
}

export const AuthTitleLabel = styled.div`
  color: var(--color-text00);
  text-align: center;
  margin-top: 24px;
  ${accent_font(20)};
`;

export const AuthSubtitleLabel = styled.div`
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  font-weight: 500;
  text-align: center;
`;

const InviterInfoView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const InviteFromView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-inline-start: auto;
  margin-inline-end: auto;
`;

const InviteFromText = styled.div`
  color: white;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
`;

const JoinSomeBodyCircle = styled.div`
  color: rgba(255, 255, 255, 0.8);
  font-size: 11px;
  font-weight: 500;
  text-align: center;
`;

const SkipButton = styled.div`
  color: var(--color-text00);
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  text-decoration: underline;
  margin-top: 9px;
`;

export const InvitationHintLabel = styled.div`
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  margin-top: 20px;
`;
