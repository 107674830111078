import {
  ChatMessage,
  ChatMessageType,
  MatchType,
} from "../../../proto/ChatMessage";
import { I18n, useI18n } from "../../../hooks/useI18n";
import { Hopper, useHopper } from "../../../hooks/useHopper";
import { isSpongeKit } from "../../../utils/isSpongeKit";
import { User } from "../../../proto/User";
import styled from "styled-components";
import { ChatThreadType } from "../../../proto/ChatThreadType";
import { useMyUid } from "../../../service/AuthSessionService";

export function isSystemMessage(msg: ChatMessage): boolean {
  return [
    ChatMessageType.Deleted,
    ChatMessageType.RemoveByHost,
    ChatMessageType.RemoveByCohost,
    ChatMessageType.MemberJoined,
    ChatMessageType.MemberQuit,
    ChatMessageType.StartFreeTalk,
    ChatMessageType.EndFreeTalk,
    ChatMessageType.StartVoiceCall,
    ChatMessageType.StartRolePlay,
    ChatMessageType.StartRolePlayV2,
    ChatMessageType.EndRolePlay,
    ChatMessageType.EndVoiceCall,
    ChatMessageType.AcceptVoiceCall,
    ChatMessageType.CancelVoiceCall,
    ChatMessageType.RejectVoiceCall,
    ChatMessageType.ThreadHostChanged,
    ChatMessageType.VoiceCallNotAnswered,
    ChatMessageType.InviteMembers,
    ChatMessageType.AddCoHosts,
    ChatMessageType.RemoveCoHosts,
    ChatMessageType.RemoveMember,
    ChatMessageType.KickOutMember,
    ChatMessageType.MessageTime,
    ChatMessageType.Guideline,
    ChatMessageType.Welcome,
    ChatMessageType.StartScreenRoom,
    ChatMessageType.EndScreenRoom,
    ChatMessageType.MatchAgree,
    ChatMessageType.MatchLeave,
    ChatMessageType.OnlyText,
    ChatMessageType.GiftBoxSomeoneClaim,
    ChatMessageType.StartCharacterChat,
    ChatMessageType.MessageSentReward,
    ChatMessageType.RoleTakeAndUntake,
  ].includes(msg.type);
}

const ClickableSpan = styled.span`
  color: #34a6ff;
`;

function ClickableUserName(props: { user?: User }) {
  const hopper = useHopper();
  return (
    <ClickableSpan onClick={() => hopper.push(`user/${props.user?.uid}`)}>
      {props.user?.nickname}
    </ClickableSpan>
  );
}

function getSystemMsgContent(
  msg: ChatMessage,
  i18n: I18n,
  hopper: Hopper,
  myUid: bigint,
) {
  const clickableAuthor = <ClickableUserName user={msg.author} />;
  let otherUsers = (
    <>
      {msg.userList?.map((user) => (
        <ClickableUserName user={user} key={user.uid} />
      ))}
    </>
  );

  switch (msg.type) {
    case ChatMessageType.Deleted:
      switch (msg.extensions?.matchType) {
        case MatchType.TextMatch:
        case MatchType.VoiceMatch:
          if (msg.author?.uid === myUid) {
            return i18n.meet_now_delete_message_by_myself();
          } else {
            return i18n.meet_now_the_user_delete_message();
          }
        default:
          return i18n.chat_delete_message(clickableAuthor);
      }
    case ChatMessageType.RemoveByHost:
      return i18n.chat_remove_message_by_host(clickableAuthor);
    case ChatMessageType.RemoveByCohost:
      return i18n.chat_remove_message_by_host(clickableAuthor);
    case ChatMessageType.MemberNotAcceptChatRequest:
      return i18n.one_to_one_has_not_accept_chat();
    case ChatMessageType.MemberHasLeft:
      const invite = (
        <ClickableSpan>{i18n.user_has_left_invite()}</ClickableSpan>
      );
      return i18n.user_has_left(clickableAuthor, invite);
    case ChatMessageType.MemberJoined:
      if (msg.extensions?.threadType === ChatThreadType.public) {
        return i18n.party_on_party_join_room(clickableAuthor);
      } else {
        return i18n.chat_join_room(clickableAuthor);
      }
    case ChatMessageType.MemberQuit:
      if (msg.extensions?.threadType === ChatThreadType.public) {
        return i18n.party_on_party_leave_room(clickableAuthor);
      } else {
        return i18n.chat_leave_room(clickableAuthor);
      }
    case ChatMessageType.StartFreeTalk:
      return i18n.chat_start_voice_chat(clickableAuthor);
    case ChatMessageType.EndFreeTalk:
      if (msg.extensions?.activityAutomaticEnded === true) {
        return i18n.silent_activity_voice_chat_end_automatically();
      } else {
        return i18n.chat_end_voice_call(clickableAuthor);
      }
    case ChatMessageType.StartScreenRoom:
      return i18n.screening_room_start_screening_room(clickableAuthor);
    case ChatMessageType.EndScreenRoom:
      if (msg.extensions?.activityAutomaticEnded === true) {
        return i18n.silent_activity_screening_room_end_automatically();
      } else {
        return i18n.screening_room_end_screening_room(clickableAuthor);
      }
    case ChatMessageType.EndRolePlay:
      if (msg.extensions?.activityAutomaticEnded === true) {
        return i18n.silent_activity_screening_room_end_automatically();
      } else {
        return i18n.chat_end_role_play(clickableAuthor);
      }
    case ChatMessageType.StartRolePlay:
    case ChatMessageType.StartRolePlayV2:
      return i18n.chat_start_role_play(clickableAuthor);
    case ChatMessageType.RoleTakeAndUntake:
      // TODO: fill logic
      return;
    case ChatMessageType.StartVoiceCall:
      return i18n.chat_start_voice_chat(clickableAuthor);
    case ChatMessageType.EndVoiceCall:
      return i18n.chat_end_voice_call(clickableAuthor);
    case ChatMessageType.CancelVoiceCall:
      return i18n.chat_cancel_voice_call(clickableAuthor);
    case ChatMessageType.AcceptVoiceCall:
      return i18n.chat_accept_voice_call(clickableAuthor);
    case ChatMessageType.RejectVoiceCall:
      return i18n.chat_reject_voice_call(clickableAuthor);
    case ChatMessageType.ThreadHostChanged:
      return i18n.chat_host_changed(clickableAuthor);
    case ChatMessageType.VoiceCallNotAnswered:
      return i18n.chat_voice_call_not_answer();
    case ChatMessageType.InviteMembers:
      let members = (
        <span>
          {msg.userList?.map((user) => (
            <ClickableUserName user={user} key={user.uid} />
          ))}
        </span>
      );
      if (msg.extensions?.threadType === ChatThreadType.public) {
        return i18n.party_on_invite_members_list(clickableAuthor, members);
      } else {
        return i18n.chat_invite_members_list(clickableAuthor, members);
      }
    case ChatMessageType.AddCoHosts:
      return i18n.chat_added_cohost(clickableAuthor);
    case ChatMessageType.RemoveCoHosts:
      return i18n.chat_remove_cohost_alert(otherUsers);
    case ChatMessageType.RemoveMember:
    case ChatMessageType.KickOutMember:
      return i18n.chat_remove_member(clickableAuthor);
    case ChatMessageType.DeclineInvitation:
      return i18n.decline_invite(clickableAuthor);
    case ChatMessageType.FriendWasBanned:
      return i18n.the_user_has_been_muted();
    case ChatMessageType.MatchAgree:
      if (msg.author?.uid === myUid) {
        return i18n.meet_now_accepted_by_myself();
      } else {
        return i18n.meet_now_the_other_user_accepted();
      }
    case ChatMessageType.MatchLeave:
      return i18n.meet_now_user_left();
    case ChatMessageType.MatchContinueTip:
      return i18n.meet_now_only_45s_left();
    case ChatMessageType.OnlyText:
      return i18n.only_text_before_accepts();
    case ChatMessageType.GiftBoxSomeoneClaim:
      return i18n.web3_hongbao_someone_accepted_your_gift(clickableAuthor);
    case ChatMessageType.StartCharacterChat:
      return i18n.ai_voice_all_messages_generated_by_ai_hint();
    default:
      if (isSpongeKit()) {
        return `${i18n.unknown_message_type()} - ${msg.type}`;
      } else {
        return i18n.unknown_message_type();
      }
  }
}

export default function SystemMsgCell(props: { msg: ChatMessage }) {
  const i18n = useI18n();
  const hopper = useHopper();
  const myUid = useMyUid();
  return (
    <div
      key={props.msg.messageId}
      style={{ textAlign: "center", paddingTop: "16px" }}
    >
      {getSystemMsgContent(props.msg, i18n, hopper, myUid)}
    </div>
  );
}
