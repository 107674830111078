import React, {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";
import type { Router as RemixRouter } from "@remix-run/router";
import {
  closeAllModals,
  ModalController,
  ModalRelay,
} from "../components/Modal";
import { ObscuredZoneBoundary } from "../components/ObscuredZoneBoundary";
import { ModalBoundary } from "../components/ModalContext";
import {
  AbstractHopper,
  HopperContext,
  HopperMode,
  HopperRefer,
} from "../hooks/useHopper";
import { AppShellService } from "../service/AppShellService";
import { urlAppendQuery, UrlQuery } from "../utils/UrlUtil";
import { andLog } from "../components/handleError";
import { RouterProvider } from "react-router-dom";
import { PullToRefresh } from "../components/PullToRefresh";
import { getRandomId } from "../utils/randomId";
import { LayerBoundary } from "./LayerBoundary";
import { assert } from "../utils/asserts";
import { LayerModeSwitch } from "./LayerModeSwitch";
import { useReactRoutes } from "../service/ReactRoutesService";

function Layer(
  props: PropsWithChildren<{
    index: number;
    hopper: LayerHopper;
  }>,
) {
  return (
    <ObscuredZoneBoundary id={`layer-${props.index}`}>
      <HopperContext.Provider
        value={{
          hopper: props.hopper,
        }}
      >
        <ModalBoundary>
          <AppShellService>
            <PullToRefresh>{props.children}</PullToRefresh>
          </AppShellService>
        </ModalBoundary>
      </HopperContext.Provider>
    </ObscuredZoneBoundary>
  );
}

type LayerInfo = {
  id: string;
  modal: ModalRelay;
  router: RemixRouter;
  element: ReactNode | undefined;
  canNavigate: boolean;
  refer: HopperRefer | undefined;
};

class LayerHopper extends AbstractHopper {
  private modalControllers: ReadonlyArray<ModalController> = [];

  constructor(
    private readonly prevHopper: LayerHopper | undefined,
    private readonly layerRouter: RemixRouter,
    private readonly canNavigate: boolean,
    private readonly onNewLayer: (
      url: string,
      mode: HopperMode,
      refer: HopperRefer | undefined,
    ) => void,
    private readonly onDismiss: (completion: (() => void) | undefined) => void,
  ) {
    super();
  }

  openBridge(
    path: string,
    param:
      | {
          mode?: HopperMode;
          resultId?: string;
          resultEnv?: any;
        }
      | undefined,
    query: UrlQuery | undefined,
    refer: HopperRefer | undefined,
  ) {
    const url = urlAppendQuery(`/${path}`, { ...query, ...param });
    switch (param?.mode) {
      case "modal":
      case "layer":
      case "popOver":
      case "startSide":
      case "endSide":
        this.onNewLayer(url, param.mode, refer);
        break;

      default:
        (async () => {
          if (this.canNavigate) {
            await this.layerRouter.navigate(url, {
              replace: param?.mode === "replace",
              state: refer ? { refer: refer } : undefined,
            });
          } else {
            await closeAllModals(this.modalControllers);
            this.onDismiss(() =>
              this.prevHopper?.openBridge(path, param, query, refer),
            );
          }
        })().catch(andLog);
    }
  }

  back() {
    (async () => {
      await closeAllModals(this.modalControllers);
      if (this.canNavigate) {
        await this.layerRouter.navigate(-1);
      } else {
        this.dismissLayer();
      }
    })().catch(andLog);
  }

  dismissLayer() {
    (async () => {
      await closeAllModals(this.modalControllers);
      this.onDismiss(undefined);
    })().catch(andLog);
  }

  dismissLayerAndPush(path: string, query?: UrlQuery) {
    (async () => {
      await closeAllModals(this.modalControllers);
      this.onDismiss(() => this.prevHopper?.push(path, query));
    })().catch(andLog);
  }

  dismissModal() {
    (async () => {
      await closeAllModals(this.modalControllers);
      this.onDismiss(undefined);
    })().catch(andLog);
  }

  addModalController(controller: ModalController) {
    this.modalControllers = [...this.modalControllers, controller];
  }

  removeModalController(controller: ModalController) {
    this.modalControllers = this.modalControllers.filter(
      (c) => c !== controller,
    );
  }
}

export function LayerManager(props: PropsWithChildren<{}>) {
  const [extraLayers, setExtraLayers] = useState<ReadonlyArray<LayerInfo>>([]);

  const routes = useReactRoutes();

  const onNewLayer = useCallback(
    (url: string, mode: HopperMode, refer: HopperRefer | undefined) => {
      setExtraLayers((prev) => {
        const newRouter = routes.createMemoryRouter(url);
        const routerProvider = <RouterProvider router={newRouter} />;
        const layerId = `layer-${getRandomId()}`;
        const modal = new ModalRelay();
        return [
          ...prev,
          {
            id: layerId,
            modal: modal,
            router: newRouter,
            canNavigate: mode === "layer",
            element: (
              <LayerModeSwitch layerId={layerId} mode={mode} relay={modal}>
                {routerProvider}
              </LayerModeSwitch>
            ),
            refer: refer,
          },
        ];
      });
    },
    [setExtraLayers],
  );

  const layerProps = useMemo(() => {
    const layerInfos: LayerInfo[] = [
      {
        id: `layer-base`,
        modal: new ModalRelay(),
        router: routes.baseRouter,
        canNavigate: true,
        element: props.children,
        refer: undefined,
      },
      ...extraLayers,
    ];

    const ret: {
      id: string;
      hopper: LayerHopper;
      element: ReactNode | undefined;
      refer: HopperRefer | undefined;
    }[] = [];
    for (let i = 0; i < layerInfos.length; i++) {
      const layer = layerInfos[i];
      const hopper = new LayerHopper(
        ret[i - 1]?.hopper,
        layer.router,
        layer.canNavigate,
        onNewLayer,
        (completion: (() => void) | undefined) => {
          assert(i > 0);
          layer.modal.close(() => {
            setExtraLayers((prev) => {
              return prev.filter((m) => m.id !== layer.id);
            });
            completion?.();
          });
        },
      );
      ret.push({
        hopper,
        element: layer.element,
        id: layer.id,
        refer: layer.refer,
      });
    }

    return ret;
  }, [
    extraLayers,
    routes.baseRouter,
    props.children,
    onNewLayer,
    setExtraLayers,
  ]);

  return (
    <>
      {layerProps.map((l, i) => (
        <Layer index={i} key={`layer-${i}`} hopper={l.hopper}>
          <LayerBoundary id={l.id} refer={l.refer}>
            {l.element}
          </LayerBoundary>
        </Layer>
      ))}
    </>
  );
}
