import { HSpace, HStack } from "../../../components/VStack";
import React, { CSSProperties, useEffect, useMemo, useState } from "react";
import { NFT } from "../../../proto/NFT";
import styled from "styled-components";
import ImgComet from "../imgs/img_progress_comet.svg";
import { Image } from "../../../components/Image";
import { formatPercentage } from "../../../utils/NumberI18n";
import { formatMoney, multiply, plus } from "../../../proto/Currency";
import { CurrencyIcon } from "../../../components/views/CurrencyIcon";
import { useI18n } from "../../../hooks/useI18n";

export function MinMax(min: number, value: number, max: number) {
  return Math.min(Math.max(min, value), max);
}

const Maturity = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 14px 12px;
  align-items: center;
  margin-top: 13px;
  border: 1px solid #3ec5ff50;
  border-radius: 6px;
  background: linear-gradient(to bottom, #3ec5ff16 0%, #3ec5ff02 100%);
`;

export function NFTCardMaturityView(props: { nft: NFT; updateTime: number }) {
  const [progress, setProgress] = useState<number>(
    props.nft.circleMembershipCard?.maturityProgress ?? 0,
  );

  const i18n = useI18n();

  const canRedeemCurrency = useMemo(() => {
    const total = plus(
      props.nft.circleMembershipCard?.earningCurrency,
      props.nft.circleMembershipCard?.currency,
    );
    return multiply(total, progress / 100);
  }, [props.nft.circleMembershipCard, progress]);

  useEffect(() => {
    const calculateProgress = () => {
      const now = Date.now();
      const totalTime =
        props.nft.circleMembershipCard?.maturityPeriod ?? 604800;
      const offsetProgress = (now - props.updateTime) / (10 * totalTime);
      const newProgress =
        (props.nft.circleMembershipCard?.maturityProgress ?? 0) +
        offsetProgress;
      setProgress(MinMax(0, newProgress, 100));
    };

    const timer = setInterval(calculateProgress, 1000);
    return () => clearInterval(timer);
  }, [props.nft, setProgress, props.updateTime]);

  return (
    <>
      <Maturity>
        <MaturityProgress progress={progress} style={{ width: "80%" }} />
        <div style={{ fontSize: 14, fontWeight: 500, marginTop: 12 }}>
          {i18n.clover_current_maturity_progress()}
        </div>
        <HStack>
          <div style={{ fontSize: 12, opacity: 0.6 }}>
            {i18n.clover_redeemable_amount_with_colon()}
          </div>
          <HSpace width={10} />
          <CurrencyIcon currency={canRedeemCurrency} size={16} />
          <HSpace width={5} />
          <div style={{ fontSize: 12 }}>
            {formatMoney("medium", canRedeemCurrency) ?? "0"}{" "}
            {canRedeemCurrency?.symbol}
          </div>
        </HStack>
      </Maturity>
    </>
  );
}

const GradientBg = styled.div`
  width: 100%;
  height: 20px;
  border-radius: 10px;
  background: linear-gradient(
    to left,
    rgba(26, 112, 131, 1),
    rgba(62, 197, 255, 0)
  );

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const GradientContent = styled.div`
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  border-radius: 8px;
  background: linear-gradient(
    to right,
    rgba(26, 44, 71, 1),
    rgba(21, 23, 32, 1)
  );
`;

const Progress = styled.div`
  position: relative;
  background: linear-gradient(
    150deg,
    #ffffff 26.64%,
    #3ec5ff 51.99%,
    #cdff3e 68.25%
  );
  box-shadow: 0 0 3px 0 rgba(255, 255, 255, 1) inset;
`;

const Comet = styled.img`
  position: absolute;
  right: -2px;
  top: 50%;
  transform: translateY(-50%);
  height: 33px;
`;

const ProgressText = styled.div`
  color: white;
  font-size: 12px;
  position: absolute;
  right: 0;
  top: calc(100% + 4px);
`;

export function MaturityProgress(props: {
  progress: number;
  style?: CSSProperties;
}) {
  const displayProgress = Math.max(0, Math.min(props.progress, 100));
  return (
    <GradientBg>
      <GradientContent>
        <Progress
          style={{
            height: 16,
            width: `${Math.max(6, displayProgress)}%`,
            borderRadius: 8,
          }}
        >
          <Image src={ImgComet} styledImg={Comet} />
          <ProgressText
            style={{ transform: `translateX(${100 - displayProgress}%)` }}
          >
            {formatPercentage(displayProgress / 100, 3)}
          </ProgressText>
        </Progress>
      </GradientContent>
    </GradientBg>
  );
}
