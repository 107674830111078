import { z } from "zod";
import { zBigInt, zStatic } from "../utils/zodUtils";
import { Media } from "./Media";
import { Chat } from "./Chat";
import { Post } from "./Post";
import { WidgetItem } from "./WidgetItem";

export const WidgetExtensions = z.object({
  hideTitle: z.boolean().default(false),
});
export type WidgetExtensions = zStatic<typeof WidgetExtensions>;

export const CircleWidget = z.object({
  widgetId: zBigInt,
  pageId: zBigInt,
  creatorUid: zBigInt.optional(),
  sortNumber: z.number(),
  createdTime: z.number().optional(),
  lastModifiedTime: z.number().optional(),
  widgetType: z.number(),
  status: z.number(),
  title: z.string().optional(),
  contentType: z.number().optional(),
  folderType: z.number().optional(),
  folderLayout: z.number().optional(),
  cover: Media.optional(),
  icon: Media.optional(),
  name: z.string().optional(),
  chat: Chat.optional(),
  extensions: WidgetExtensions.optional(),
  blog: Post.optional(),
  targetLink: z.string().optional(),
  widgetItemList: WidgetItem.array().optional(),
});
export type CircleWidget = zStatic<typeof CircleWidget>;

export enum WidgetType {
  LARGE = 1,
  MEDIUM = 2,
  BANNER = 3,
  ACTIVE_MEMBER = 4,
  LIVE_PARTIES = 5,
}
