import { truncate } from "./TextUtils";
import { I18n } from "../hooks/useI18n";

export const StringUtil = {
  alertTrimming(str: string | undefined): string {
    return str?.split(/\r?\n/).join(" ") || "";
  },

  getOneLineCleanText(i18n: I18n, str: string, limit: number): string {
    const replacedString = str.replace("⊠", "").replace(RegExp("\\s+"), "");
    return truncate(i18n, replacedString, limit).trim();
  },
};
/**
 * 根据长度从中间缩略字符串
 * @param val 字符
 * @param limit 位数，默认10
 * @returns string
 */
export const dealStringByLimit = (val?: string, limit = 10) => {
  if (!val?.length) return "";
  if (val.length <= limit) return val;
  const left = Math.ceil(limit / 2);
  const right = limit - left;
  return `${val.substring(0, left)}...${val.slice(0 - right)}`;
};

export function isValidBase58(str: string) {
  const charSet = new Set(
    "123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz",
  );
  for (let c of str) {
    if (!charSet.has(c)) {
      return false;
    }
  }
  return true;
}

export function headSixTailFourEllipsis(str: string) {
  return str.slice(0, 6) + "..." + str.slice(-4);
}
