import { useSWR } from "../../hooks/swr/useSWR";
import { useMemo } from "react";
import { useRegularAccount } from "./useRegularAccount";
import { useBackend } from "../../service/APIService";

export function useAssetAccount(withinAddress: boolean = true) {
  const backend = useBackend();
  const regularAccount = useRegularAccount();
  const walletAccountId = regularAccount?.walletAccountId;
  const assetAccountsSWR = useSWR(
    walletAccountId ? backend.getAssetAccounts(walletAccountId) : undefined,
  );
  return useMemo(() => {
    return assetAccountsSWR?.content?.list?.find((account) =>
      withinAddress
        ? account.address.length > 0
        : account.address === undefined ||
          account.address === null ||
          account.address.length === 0,
    );
  }, [assetAccountsSWR?.content]);
}
