import { Popover } from "antd";
import { useEffect, useRef, useState } from "react";
import * as React from "react";
import type { RenderFunction } from "antd/es/_util/getRenderPropValue";
import { VStack } from "./VStack";
import styled from "styled-components";
import { alpha_on_pressed } from "./CommonStyles";

export interface MenuProps {
  children: React.ReactNode;
  options: {
    title: string;
    type: string;
    onClick: () => void;
  }[];
}

function MercuryMenu(props: MenuProps) {
  const [menu, setMenu] = useState<boolean>(false);
  const hide = () => {
    setMenu(false);
  };
  const popoverRef = useRef<HTMLDivElement>(null);

  const handleOpenChange = (newOpen: boolean) => {
    setMenu(newOpen);
  };
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // 如果点击的区域不在 Popover 或其触发器内
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target as Node)
      ) {
        hide();
      }
    };

    document.addEventListener("click", handleClickOutside);

    // 清理事件监听器
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <div ref={popoverRef}>
      <Popover
        open={menu}
        trigger="click"
        onOpenChange={handleOpenChange}
        placement="bottomLeft"
        content={() => (
          <VStack style={{ display: "flex", flexDirection: "column", gap: 10 }}>
            {props.options.map((option) => (
              <Option
                onClick={(e) => {
                  option.onClick();
                  hide();
                }}
                key={option.title}
                $type={option.type}
              >
                {option.title}
              </Option>
            ))}
          </VStack>
        )}
        arrow={false}
      >
        {props.children}
      </Popover>
    </div>
  );
}

const Option = styled.div<{ $type: string }>`
  font-size: 12px;
  color: ${(p) => (p.$type === "normal" ? `#000` : `#EF3537`)};
  ${alpha_on_pressed}
`;

export default MercuryMenu;
