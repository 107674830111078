import { Chat } from "../proto/Chat";
import React from "react";
import styled from "styled-components";
import { Image } from "./Image";
import { useHopper } from "../hooks/useHopper";

export interface ChatGridCellProps {
  chat?: Chat;
}

export const ChatGridContainer = styled.div`
  position: relative;
  width: 100%; // 宽度占满父容器
  padding-bottom: 75%; // 高宽比 160:120
  border-radius: 12px; // 可选：设置圆角
  overflow: hidden; // 隐藏超出部分
  transition: transform 0.2s ease; // 平滑过渡效果

  &:active {
    transform: scale(0.95); // 按下时缩小到 95%
  }
`;

const ChatGridCell: React.FC<ChatGridCellProps> = ({ chat }) => {
  const hopper = useHopper();
  return (
    <ChatGridContainer
      key={chat?.threadId}
      onClick={() => {
        hopper.modal("nyi");
        // hopper.push(`chat/${chat?.threadId}`);
      }}
    >
      <Image
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
        src={[chat?.icon, "best"]}
      />
      <div style={{ position: "absolute", bottom: 0, margin: 6 }}>
        <ChatGridTitle>{chat?.title}</ChatGridTitle>
      </div>
    </ChatGridContainer>
  );
};

export const ChatGridTitle = styled.div`
  font-weight: 800;
  color: var(--color-text00);
  font-size: 12px;
`;

export default ChatGridCell;
