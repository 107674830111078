import svgToMiniDataURI from "mini-svg-data-uri";

const cos = Math.cos;
const sin = Math.sin;
const π = Math.PI;

const f_matrix_times = (
  [[a, b], [c, d]]: [[number, number], [number, number]],
  [x, y]: [number, number],
): [number, number] => [a * x + b * y, c * x + d * y];

const f_rotate_matrix = (x: number): [[number, number], [number, number]] => {
  const cosx = cos(x);
  const sinx = sin(x);
  return [
    [cosx, -sinx],
    [sinx, cosx],
  ];
};

const f_vec_add = (
  [a1, a2]: [number, number],
  [b1, b2]: [number, number],
): [number, number] => [a1 + b1, a2 + b2];

export const f_svg_ellipse_arc = (
  [cx, cy]: [number, number],
  [rx, ry]: [number, number],
  [t1, Δ]: [number, number],
  φ: number,
): (string | number)[] => {
  /* [
  returns a a array that represent a ellipse for SVG path element d attribute.
  cx,cy → center of ellipse.
  rx,ry → major minor radius.
  t1 → start angle, in radian.
  Δ → angle to sweep, in radian. positive.
  φ → rotation on the whole, in radian.
  url: SVG Circle Arc http://xahlee.info/js/svg_circle_arc.html
  Version 2019-06-19
  ] */
  Δ = Δ % (2 * π);
  const rotMatrix = f_rotate_matrix(φ);
  const [sX, sY] = f_vec_add(
    f_matrix_times(rotMatrix, [rx * cos(t1), ry * sin(t1)]),
    [cx, cy],
  );
  const [eX, eY] = f_vec_add(
    f_matrix_times(rotMatrix, [rx * cos(t1 + Δ), ry * sin(t1 + Δ)]),
    [cx, cy],
  );
  const fA = Δ > π ? 1 : 0;
  const fS = Δ > 0 ? 1 : 0;
  return [" M ", sX, " ", sY, " A ", rx, ry, (φ / π) * 180, fA, fS, eX, eY];
};

export function svgDrawRoundedRect(
  width: number,
  height: number,
  outerCornerRadius: number,
  lineWidth: number,
): string {
  const adjustedWidth = width - 2 * outerCornerRadius;
  const adjustedHeight = height - 2 * outerCornerRadius;
  const halfLineWidth = lineWidth / 2;
  const centerCornerRadius = outerCornerRadius - halfLineWidth;

  return `
        M${outerCornerRadius} ${halfLineWidth}
        h${adjustedWidth}
        a${centerCornerRadius} ${centerCornerRadius} 0 0 1 ${centerCornerRadius} ${centerCornerRadius}
        v${adjustedHeight}
        a${centerCornerRadius} ${centerCornerRadius} 0 0 1 -${centerCornerRadius} ${centerCornerRadius}
        H${outerCornerRadius}
        a${centerCornerRadius} ${centerCornerRadius} 0 0 1 -${centerCornerRadius} -${centerCornerRadius}
        V${outerCornerRadius}
        a${centerCornerRadius} ${centerCornerRadius} 0 0 1 ${centerCornerRadius} -${centerCornerRadius}
        Z
    `.trim();
}

type Point = {
  x: number;
  y: number;
};

export function generateLinearGradient(
  id: string,
  gradientUnits: "userSpaceOnUse" | "objectBoundingBox",
  start: Point,
  end: Point,
  stops: { offset: number; color: string }[],
) {
  const stopsString = stops
    .map((stop) => `<stop offset='${stop.offset}' stop-color='${stop.color}'/>`)
    .join("\n");

  return `
<linearGradient id='${id}' x1='${start.x}' x2='${end.x}' y1='${start.y}' y2='${end.y}' gradientUnits='${gradientUnits}'>
    ${stopsString}
</linearGradient>`.trim();
}

export function generateButtonBorder(
  width: number,
  height: number,
  outerCornerRadius: number,
  lineWidth: number,
  gradient: {
    units: "userSpaceOnUse" | "objectBoundingBox";
    start: Point;
    end: Point;
    stops: { offset: number; color: string }[];
  },
) {
  const svg = `
  <svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='none'>
    <path stroke='url(#a)' stroke-width='${lineWidth}'
          d='${svgDrawRoundedRect(width, height, outerCornerRadius, lineWidth)}'/>
    <defs>
      ${generateLinearGradient("a", gradient.units, gradient.start, gradient.end, gradient.stops)}
    </defs>
  </svg>
  `;
  return svgToMiniDataURI(svg);
}

function calculateSVGGradientPoints(angle: number): {
  start: Point;
  end: Point;
} {
  // Normalize the angle between 0 and 360 degrees
  angle = angle % 360;
  if (angle < 0) angle += 360;

  // Convert the angle to radians
  const rad = (Math.PI / 180) * angle;

  // Calculate the direction vector
  const directionX = Math.cos(rad);
  const directionY = Math.sin(rad);

  let startPoint: Point;
  let endPoint: Point;

  // Determine start and end points based on angle
  if (angle <= 45 || angle > 315) {
    // Right side
    startPoint = { x: 1, y: 0.5 - directionY / (2 * Math.abs(directionX)) };
    endPoint = { x: 0, y: 0.5 + directionY / (2 * Math.abs(directionX)) };
  } else if (angle <= 135) {
    // Bottom side
    startPoint = { x: 0.5 + directionX / (2 * Math.abs(directionY)), y: 1 };
    endPoint = { x: 0.5 - directionX / (2 * Math.abs(directionY)), y: 0 };
  } else if (angle <= 225) {
    // Left side
    startPoint = { x: 0, y: 0.5 + directionY / (2 * Math.abs(directionX)) };
    endPoint = { x: 1, y: 0.5 - directionY / (2 * Math.abs(directionX)) };
  } else {
    // Top side
    startPoint = { x: 0.5 - directionX / (2 * Math.abs(directionY)), y: 0 };
    endPoint = { x: 0.5 + directionX / (2 * Math.abs(directionY)), y: 1 };
  }

  return { start: startPoint, end: endPoint };
}

export function linear_gradient_border(
  outerCornerRadius: number,
  lineWidth: number,
  deg: number,
  ...stops: [string, number][]
) {
  const gradient = {
    units: "objectBoundingBox" as const,
    ...calculateSVGGradientPoints(deg),
    stops: stops.map((s) => {
      return {
        color: s[0],
        offset: s[1],
      };
    }),
  };

  return `
  url("${generateButtonBorder(100, 100, outerCornerRadius, lineWidth, gradient)}") ${outerCornerRadius} / ${outerCornerRadius}px stretch
  `;
}

export function genDashedBorderSvgUrl(
  radius: number,
  color: string,
  width: number,
  dashArray: string,
  dashOffset: number,
  linecap: string,
) {
  const svg =
    `<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'>` +
    `<rect width='100%' height='100%' fill='none' ` +
    (radius > 0 ? `rx='${radius}' ry='${radius}' ` : "") +
    `stroke='${color}' ` +
    `stroke-width='${width}' ` +
    `stroke-dasharray='${dashArray}' ` +
    `stroke-dashoffset='${dashOffset}' ` +
    `stroke-linecap='${linecap}'/></svg>`;
  return `
  url("${svgToMiniDataURI(svg)}")
  `;
}
