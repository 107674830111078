import styled from "styled-components";
import React, { useEffect, useMemo, useState } from "react";
import { f_svg_ellipse_arc } from "./SVGUtils";
import { ChasingCircle } from "./ChasingCircle";
import { useChange } from "../../hooks/ExtHooks";

const METRIC = {
  circleDiameter: 64,
  progress: {
    radius: 14,
    strokeWidth: 4,
  },
  circleTarget: 64,
};

function calcRefreshControlLayout(metric: typeof METRIC, pullDistance: number) {
  const overscrollTop = Math.max(0, pullDistance);
  const M = metric.circleTarget * 2;
  const k = 0.8;

  const targetY = M * (1 - Math.exp((-k * overscrollTop) / M));
  return {
    targetY: targetY,
    progress: Math.max(0, Math.min(1, targetY / metric.circleTarget)),
  };
}

const Container = styled.div<{ height: number }>`
  width: 100%;
  height: ${(p) => p.height}px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;

export function CircleRefreshControl(props: {
  readonly pullDistance: number | null;
  readonly isLoading: boolean;
  readonly onThresholdPassed: (passed: boolean) => void;
  readonly onStartRefresh: () => void;
}) {
  const metric = useMemo(() => METRIC, []);
  const [isDocking, setIsDocking] = useState<boolean>(false);

  const refreshControlLayout = useMemo(
    () =>
      props.pullDistance !== null
        ? calcRefreshControlLayout(metric, props.pullDistance)
        : null,
    [props.pullDistance, props.isLoading, metric],
  );

  useEffect(() => {
    if (props.isLoading || props.pullDistance !== null) {
      setIsDocking(false);
    }
  }, [props.pullDistance, props.isLoading]);

  const height = useMemo(() => {
    if (props.isLoading || isDocking) {
      return metric.circleTarget;
    } else if (refreshControlLayout) {
      return refreshControlLayout.targetY;
    } else {
      return 0;
    }
  }, [refreshControlLayout, metric, props]);

  useChange(props.pullDistance, (prev, current) => {
    if (prev !== null && current === null) {
      setIsDocking(true);
    }
  });

  useEffect(() => {
    props.onThresholdPassed(
      refreshControlLayout ? refreshControlLayout.progress === 1 : false,
    );
  }, [refreshControlLayout, props, metric]);

  return (
    <Container
      height={height}
      style={{
        transition:
          props.pullDistance && props.pullDistance > 0
            ? "none"
            : "height 0.2s ease-in",
      }}
      onTransitionEnd={() => {
        if (isDocking) {
          setIsDocking(false);
          props.onStartRefresh();
        }
      }}
    >
      {refreshControlLayout && (
        <svg
          width={metric.circleDiameter}
          height={metric.circleDiameter}
          viewBox={`0 0 ${metric.circleDiameter} ${metric.circleDiameter}`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d={f_svg_ellipse_arc(
              [metric.circleDiameter / 2, metric.circleDiameter / 2],
              [
                metric.progress.radius + metric.progress.strokeWidth / 2,
                metric.progress.radius + metric.progress.strokeWidth / 2,
              ],
              [0, (359 / 360) * Math.PI * 2 * refreshControlLayout.progress],
              -Math.PI / 2,
            ).join(" ")}
            stroke="var(--color-text00)"
            strokeWidth={`${metric.progress.strokeWidth}`}
          />
        </svg>
      )}
      {!refreshControlLayout && props.isLoading && (
        <ChasingCircle
          radius={metric.progress.radius}
          strokeWidth={metric.progress.strokeWidth}
          color={"var(--color-text00)"}
        />
      )}
    </Container>
  );
}
