import { Page } from "../../../components/Page";
import { useBigIntParam } from "../../../hooks/useTypedParam";
import { useSWR } from "../../../hooks/swr/useSWR";
import { HStack, VStack } from "../../../components/VStack";
import styled, { css } from "styled-components";
import { Image } from "../../../components/Image";
import IcAnn from "../images/ic_announcement.svg";
import MemberStack from "../components/MemberStack";
import { useSWRList } from "../../../hooks/swr/useSWRList";
import { ChatMessage, ChatMessageType } from "../../../proto/ChatMessage";
import ChatInputBar from "../components/ChatInputBar";
import { PageFooter, PageHeader } from "../../../components/PageHeaderFooter";
import SystemMsgCell from "../components/SystemMsgCell";
import GeneralMsgCell from "../components/GeneralMsgCell";
import { useEffect, useMemo, useRef, useState } from "react";
import NameCardCell from "../components/NameCardCell";
import { useMyUid } from "../../../service/AuthSessionService";
import { useBackend } from "../../../service/APIService";
import { ChatThreadType } from "../../../proto/ChatThreadType";
import ThreadStage from "../components/ThreadStage";
import ChatNavBar from "../components/ChatNavBar";
import { NavEnd, NavItem, NavStart } from "../../../components/NavBar";
import { useHopper } from "../../../hooks/useHopper";
import { useWS, WSListener } from "../../../service/WebSocketService";
import {
  buildNewWSChatMessageToSend,
  WSMessage,
  WSType,
} from "../../../proto/WSMessage";
import { NavButtonType } from "../../../bridge/NavButtonDesc";
import { BottomSheet, useModal } from "../../../components/Modal";
import { User } from "../../../proto/User";
import { Spacing } from "../../../components/Spacing";
import { UserIconView } from "../../../components/views/UserAvatarView";
import { UserNameView } from "../../../components/views/UserNameView";
import { ButtonColor, RegularButton } from "../../../components/Buttons";
import { ObjectPool } from "../utils/ObjectPool";
import { useGlobalSpinner } from "../../../utils/globalSpinner";
import { useI18n } from "../../../hooks/useI18n";
import FollowButton from "../../../components/FollowButton";
import { Sticker } from "../../../proto/Reaction";
import { Dice } from "../../../proto/Dice";
import { SmallNoteView } from "../components/SmallNoteView";

const AnnouncementView = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 1;
  gap: 10px;

  padding: 0 15px 0 10px;
  height: 30px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.12);

  font-size: 12px;
  color: white;
`;

function isContentMessage(msg: ChatMessage) {
  return [
    ChatMessageType.Text,
    ChatMessageType.Image,
    ChatMessageType.Video,
    ChatMessageType.Audio,
    ChatMessageType.Sticker,
    ChatMessageType.Dice,
    ChatMessageType.AddChatAnnouncement,
    ChatMessageType.Poll,
    ChatMessageType.Share,
    ChatMessageType.GiftBox,
    ChatMessageType.CharacterLackOfEnergy,
    ChatMessageType.CharacterWithoutOwnership,
  ].includes(msg.type);
}

function BuildMsgCell(props: {
  msg: ChatMessage;
  onAuthorClicked: () => void;
}) {
  // 先处理特殊类型，比如level up，match rate等
  // 再把非内容信息处理成系统消息，最后统一用General处理
  const isNameCard = props.msg.type === ChatMessageType.NameCard;
  const isSome = false;
  const isSpecial = isNameCard || isSome;
  return (
    <div style={{ transform: "scaleY(-1)" }}>
      {isNameCard && <NameCardCell msg={props.msg} />}
      {!isSpecial && !isContentMessage(props.msg) && (
        <SystemMsgCell msg={props.msg} />
      )}
      {!isSpecial && isContentMessage(props.msg) && (
        <GeneralMsgCell
          key={props.msg.messageId}
          msg={props.msg}
          onAuthorClicked={props.onAuthorClicked}
        />
      )}
    </div>
  );
}

function ChatroomPage() {
  const threadId = useBigIntParam("threadId");
  const backend = useBackend();
  const myUid = useMyUid();
  const hopper = useHopper();
  const i18n = useI18n();
  const globalSpinner = useGlobalSpinner();
  const { send, enterChat, leaveChat, addListener, removeListener } = useWS();
  const userPool = useRef(
    new ObjectPool<User>((oId) => backend.getUser(oId).run()),
  );

  const [waitingMergeMsgs, setWaitingMergeMsgs] = useState<ChatMessage[]>([]);
  const safeBuffer = useRef<ChatMessage[]>([]);
  const unFilledBufferMsgs = useRef<ChatMessage[]>([]);
  const pendingAckMsgs = useRef<ChatMessage[]>([]);
  const failureMsgs = useRef<ChatMessage[]>([]);
  const msgUpdated = useRef(false);

  const shouldShowLatestMsg = useRef(true);
  const bottomRef = useRef<HTMLDivElement>(null);
  const userInfoRef = useRef<User | undefined>(undefined);
  const userInfoModal = useModal("chat-user-card");

  const thread = useSWR(backend.getThreadInfo(threadId, true));

  const onlineMembers = useSWR(backend.getThreadOnlineMembers(threadId, 10));

  const msgs = useSWRList(backend.getThreadMessages(threadId, false));

  const myProfile = useSWR(backend.getUser(myUid));

  const activityType = useMemo(() => {
    return thread.content?.extensions?.activityType;
  }, [thread.content]);

  const displayMsgs = useMemo(() => {
    const buff = waitingMergeMsgs;
    if (!msgs.content) {
      return buff;
    }

    const allMessages = msgs.content.list.concat(buff);

    const sorted = allMessages.sort((msgA, msgB) => {
      if (msgA.messageId && msgB.messageId) {
        return msgA.messageId < msgB.messageId ? 1 : -1;
      } else {
        return msgA.createdTime < msgB.createdTime ? 1 : -1;
      }
    });
    // TODO: filter GiftBoxSomeoneClaim type not related with me
    // TODO: filter GiftBoxAllClaimed type
    const filteredMsgs = sorted.filter((msg) => {
      return (
        (msg.type !== ChatMessageType.MemberNotAcceptChatRequest &&
          msg.type !== ChatMessageType.OnlyText &&
          msg.type !== ChatMessageType.DeclineInvitation) ||
        msg.uid !== myUid
      );
    });
    let uniqueMsgs: ChatMessage[] = [];
    let msgIdMap = new Map();
    for (let i = 0; i < filteredMsgs.length; i++) {
      if (!msgIdMap.has(filteredMsgs[i].messageId)) {
        msgIdMap.set(filteredMsgs[i].messageId, true);
        uniqueMsgs.push(filteredMsgs[i]);
      }
    }
    return uniqueMsgs;
  }, [waitingMergeMsgs.length, msgs]);

  const isSingle = thread.content?.type === ChatThreadType.single;

  const fillMsgInfoAndCacheInQueue = (wsMsg: WSMessage) => {
    wsMsg.msg!.author = myProfile.content;
    wsMsg.msg!.bubbleMedia = thread.content?.currentMemberInfo?.chatBubble?.res;
    wsMsg.msg!.bubbleColor =
      thread.content?.currentMemberInfo?.extensions?.bubbleColor;
    wsMsg.msg!.bubbleTextColor =
      thread.content?.currentMemberInfo?.chatBubble?.textColor;

    pendingAckMsgs.current.push(wsMsg.msg!);
  };

  const sendTextMsg = (msg: string) => {
    let wsMsg = buildNewWSChatMessageToSend(threadId, myUid);
    wsMsg.msg!.type = ChatMessageType.Text;
    wsMsg.msg!.content = msg;

    send(wsMsg);
    fillMsgInfoAndCacheInQueue(wsMsg);
  };

  const sendStickerMsg = (sticker: Sticker) => {
    let wsMsg = buildNewWSChatMessageToSend(threadId, myUid);
    wsMsg.msg!.type = ChatMessageType.Sticker;
    wsMsg.msg!.sticker = sticker;
    wsMsg.msg!.extensions!.stickerId = sticker.stickerId;
    send(wsMsg);
    fillMsgInfoAndCacheInQueue(wsMsg);
  };

  const sendDiceMsg = (dice: Dice) => {
    let wsMsg = buildNewWSChatMessageToSend(threadId, myUid);
    wsMsg.msg!.type = ChatMessageType.Dice;
    wsMsg.msg!.dice = dice;
    wsMsg.msg!.extensions!.diceId = dice.diceId;
    send(wsMsg);
    fillMsgInfoAndCacheInQueue(wsMsg);
  };

  const background = (
    <div style={{ width: "100%", height: "100%" }}>
      <Image
        src={[thread.content?.background, "best"]}
        style={{ objectFit: "cover", width: "100%" }}
      />
      <div
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0,0,0, 0.7)",
        }}
      ></div>
    </div>
  );

  const header = (
    <HStack
      style={{
        width: "100%",
        gap: "20px",
        padding: "0 var(--page-h-inset) 10px",
      }}
    >
      <AnnouncementView
        onClick={(event) => {
          event.stopPropagation();
          hopper.push(`chat/${threadId}/announcement`);
        }}
      >
        <Image src={IcAnn}></Image>
        <>Announcement</>
      </AnnouncementView>
      <MemberStack users={onlineMembers.content?.list}></MemberStack>
    </HStack>
  );

  const footer = (
    <ChatInputBar
      onSend={sendTextMsg}
      onSticker={sendStickerMsg}
      onDice={sendDiceMsg}
    ></ChatInputBar>
  );

  useEffect(() => {
    const listener: WSListener = {
      onMessage(msg: WSMessage): void {
        if (msg && msg.msg) {
          if (isContentMessage(msg.msg)) {
            unFilledBufferMsgs.current.push(msg.msg);
            msgUpdated.current = true;
          } else if (msg.msg.type === ChatMessageType.AddThreadOnlineMember) {
            // add
          }
        }
      },
      onAck(msg: WSMessage): void {
        if (!msg.msg || msg.msg.threadId !== threadId) {
          return;
        }
        pendingAckMsgs.current = pendingAckMsgs.current.filter(
          (qMsg) => msg.msg?.seqId !== qMsg.seqId,
        );
        unFilledBufferMsgs.current.push(msg.msg);
        msgUpdated.current = true;
      },
      onFailureSend(msg: WSMessage): void {
        if (!msg.msg) {
          return;
        }
        pendingAckMsgs.current = pendingAckMsgs.current.filter(
          (qMsg) => msg.msg?.seqId !== qMsg.seqId,
        );
        failureMsgs.current.push(msg.msg);
        msgUpdated.current = true;
      },
      uniqueKey: `thread-${threadId}`,
    };
    addListener(listener);
    return () => {
      removeListener(listener);
    };
  }, [threadId, addListener]);

  useEffect(() => {
    const chatSubscribe = window.setInterval(() => {
      const wsMsg = {
        t: WSType.ChatSubscribe,
        threadId: threadId,
      } as WSMessage;
      send(wsMsg);
    }, 60000);
    const chatPing = window.setInterval(() => {
      const wsMsg = {
        t: WSType.ChatPing,
        threadId: threadId,
      } as WSMessage;
      send(wsMsg);
    }, 10000);
    return () => {
      clearInterval(chatSubscribe);
      clearInterval(chatPing);
    };
  }, [thread, send]);

  useEffect(() => {
    enterChat(threadId);
    return () => {
      leaveChat(threadId);
    };
  }, [threadId, enterChat, leaveChat]);

  useEffect(() => {
    const mergeBufferMsgs = () => {
      if (
        unFilledBufferMsgs.current.length > 0 &&
        thread.content &&
        myProfile.content
      ) {
        for (const msg of unFilledBufferMsgs.current) {
          if (msg.uid === myUid) {
            (msg as any).author = myProfile.content;
            (msg as any).bubbleMedia =
              thread.content?.currentMemberInfo?.chatBubble?.res;
            (msg as any).bubbleTextColor =
              thread.content?.currentMemberInfo?.chatBubble?.textColor;
            (msg as any).bubbleColor =
              thread.content?.currentMemberInfo?.extensions?.bubbleColor;
          }
        }
        safeBuffer.current = safeBuffer.current.concat(
          unFilledBufferMsgs.current,
        );
        unFilledBufferMsgs.current = [];
      }
      if (
        !msgUpdated.current &&
        safeBuffer.current.length === waitingMergeMsgs.length
      ) {
        return;
      }
      msgUpdated.current = false;
      const merged = [...safeBuffer.current, ...pendingAckMsgs.current];
      setWaitingMergeMsgs(merged);
    };
    const cleanBuffer = window.setInterval(mergeBufferMsgs, 1000);
    return () => {
      clearInterval(cleanBuffer);
    };
  }, [thread, myProfile]);

  useEffect(() => {
    if (shouldShowLatestMsg.current) {
      bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [bottomRef, shouldShowLatestMsg, displayMsgs]);

  const onStageSettingClicked = () => {
    console.log("stage setting clicked");
  };

  const onStageCloseClicked = () => {
    console.log("stage close clicked");
  };

  const onChatInfoClicked = () => {
    hopper.push(`chat-info/${threadId}`);
  };

  const onMsgAuthorClicked = async (msg: ChatMessage) => {
    try {
      await globalSpinner(async () => {
        userInfoRef.current = await userPool.current.getObject(msg.uid);
        userInfoModal.open();
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Page
      pageData={[thread, myProfile]}
      underlay={background}
      onContentScroll={(e) => {
        shouldShowLatestMsg.current =
          (e.target as HTMLDivElement).scrollTop +
            (e.target as HTMLDivElement).clientHeight ===
          (e.target as HTMLDivElement).scrollHeight;
      }}
    >
      {!isSingle && <PageHeader children={header} />}
      {activityType !== undefined && (
        <PageHeader
          children={
            <ThreadStage
              activityType={activityType}
              onSettingClicked={onStageSettingClicked}
              onCloseClicked={onStageCloseClicked}
            />
          }
        />
      )}
      {thread.content !== undefined && (
        <NavStart showsBackEvenStartPresents={true}>
          <ChatNavBar chat={thread.content} />
        </NavStart>
      )}
      <NavEnd>{NavItem.image(NavButtonType.Info, onChatInfoClicked)}</NavEnd>
      <div style={{ transform: "scaleY(-1)", flexGrow: 1, paddingTop: "10px" }}>
        <div ref={bottomRef}></div>
        {displayMsgs.map((msg) => (
          <BuildMsgCell
            msg={msg}
            key={msg.messageId}
            onAuthorClicked={() => onMsgAuthorClicked(msg)}
          />
        ))}
      </div>
      <PageFooter safeBottomDisabled={true} obscuredZoneKey={"ChatInputBar"}>
        {thread.content?.smallNoteList &&
        thread.content.smallNoteList.length > 0 ? (
          <SmallNoteView
            smallNote={thread.content.smallNoteList[0]}
            threadInfo={thread.content}
          />
        ) : (
          footer
        )}
      </PageFooter>
      <BottomSheet modal={userInfoModal} hideCloseButton={false}>
        <VStack style={{ alignItems: "center" }}>
          <div style={{ fontSize: "18px" }}>{i18n.arthur_user_info()}</div>
          <Spacing height={20} />
          <UserIconView user={userInfoRef.current} iconSize={100} />
          <UserNameView
            user={userInfoRef.current}
            nameStyle={css`
              font-size: 22px;
            `}
          />
          <Spacing height={13} />
          <div>@ {userInfoRef.current?.socialId}</div>
          {myUid !== userInfoRef.current?.uid && (
            <>
              <Spacing height={30} />
              <HStack style={{ gap: "8px" }}>
                {userInfoRef.current && (
                  <FollowButton
                    user={userInfoRef.current}
                    backend={backend}
                    userPool={userPool.current}
                  />
                )}
                <RegularButton
                  $baseColor={ButtonColor.greenish}
                  style={{ minWidth: "120px", minHeight: "42px" }}
                >
                  {i18n.chat()}
                </RegularButton>
              </HStack>
            </>
          )}
          <Spacing height={40} />
        </VStack>
      </BottomSheet>
    </Page>
  );
}

export default ChatroomPage;
