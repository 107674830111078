import { Page } from "../../../components/Page";
import { NavMiddle } from "../../../components/NavBar";
import { useI18n } from "../../../hooks/useI18n";
import {
  useBigIntParam,
  useBooleanSearchParam,
  useNumberSearchParam,
  useStringSearchParam,
} from "../../../hooks/useTypedParam";
import { useSWR } from "../../../hooks/swr/useSWR";
import { useBackend } from "../../../service/APIService";
import { GiftOrderDetail } from "./GiftOrderDetail";
import { PurchaseOrderDetail } from "./PurchaseOrderDetail";
import { AssetOrderType, TxCategory } from "../../../proto/AssetOrder";
import { PropsPurchaseOrderDetail } from "./PropsPurchaseOrderDetail";
import { TopUpOrderDetail } from "./TopUpOrderDetail";
import { SendTokenOrderDetail } from "./SendTokenOrderDetail";
import { MintOrderDetail } from "./MintOrderDetail";
import { StoreOrderType } from "../../../proto/Order";
import { SalesOrderDetail } from "./SalesOrderDetail";
import {
  TransactionResult,
  TransactionResultModal,
} from "./TransactionResultModal";
import { useModal } from "../../../components/Modal";
import { TransferFromShopDetail } from "./TransferFromShopDetail";
import { TradeOrderDetail } from "./TradeOrderDetail";
import { CreateNewTokenDetail } from "./CreateNewTokenDetail";
import { GiftContentDialog } from "./GiftContentDialog";
import { useHopState } from "../../../hooks/useHopState";
import { CurrencyType } from "../../../proto/Currency";
import { StakeTokenDetail } from "./StakeTokenDetail";
import { MembershipCardRedemptionDetail } from "./MembershipCardRedemptionDetail";
import { PurchaseMembershipCardDetail } from "./PurchaseMembershipCardDetail";
import { TransferAdminEarningsOrderDetail } from "./TransferAdminEarningsOrderDetail";
import ConvertToGemsDetail from "./ConvertToGemsDetail";

export enum OrderType {
  GiftOrder = 1,
  StoreOrder = 2,
  OtherOrder = 3,
}

export function OrderDetailPage() {
  const i18n = useI18n();
  const backend = useBackend();
  const orderId = useBigIntParam("orderId");
  const orderType = useNumberSearchParam("orderType");

  const title = useStringSearchParam("title");
  const showDialog = useBooleanSearchParam("showDialog");
  const showGiftClaimedDialog = useBooleanSearchParam("showGiftClaimedDialog");
  const showDescription = useBooleanSearchParam("showDescription");
  const showAddTreasureCheckBox = useBooleanSearchParam(
    "showAddTreasureCheckBox"
  );

  const giftOrderSWR = useSWR(
    orderType === OrderType.GiftOrder
      ? backend.getTransferOrder(orderId)
      : undefined
  );
  const storeOrderSWR = useSWR(
    orderType === OrderType.StoreOrder
      ? backend.getStoreOrder(orderId)
      : undefined
  );
  const otherOrderSWR = useSWR(
    orderType === OrderType.OtherOrder
      ? backend.getOtherOrder(orderId)
      : undefined
  );

  const targetSWR =
    orderType === OrderType.GiftOrder
      ? giftOrderSWR
      : orderType === OrderType.StoreOrder
        ? storeOrderSWR
        : orderType === OrderType.OtherOrder
          ? otherOrderSWR
          : undefined;

  const modal = useModal("transaction-result-dialog");
  const [hasTransactionModalOpened, setHasTransactionModalOpened] = useHopState(
    "hasTransactionModalOpened",
    false
  );
  const giftClaimedModal = useModal("gift-claimed-Dialog");
  const [hasGiftClaimedModalOpened, setHasOpenedGiftClaimedModal] = useHopState(
    "hasGiftClaimedModalOpened",
    false
  );

  if (showDialog && !hasTransactionModalOpened) {
    modal.open();
    setHasTransactionModalOpened(true);
  }

  if (showGiftClaimedDialog && !hasGiftClaimedModalOpened) {
    giftClaimedModal.open();
    setHasOpenedGiftClaimedModal(true);
  }

  const isByStaking =
    otherOrderSWR?.content?.currencyType === CurrencyType.SOL_TOKENS;

  return (
    <Page pageData={targetSWR}>
      <NavMiddle>{i18n.web3_v0_order_detail()}</NavMiddle>
      {giftOrderSWR?.content && <GiftOrderDetail orderSWR={giftOrderSWR} />}

      {storeOrderSWR?.content &&
        storeOrderSWR?.content.orderType ===
          StoreOrderType.PURCHASE_ORDER_HISTORY && (
          <PurchaseOrderDetail storeOrder={storeOrderSWR?.content} />
        )}

      {storeOrderSWR?.content &&
        storeOrderSWR?.content.orderType !==
          StoreOrderType.PURCHASE_ORDER_HISTORY && (
          <SalesOrderDetail storeOrder={storeOrderSWR?.content} />
        )}

      {otherOrderSWR?.content &&
        otherOrderSWR?.content.orderType === AssetOrderType.QUOTA && (
          <PropsPurchaseOrderDetail assetOrder={otherOrderSWR.content} />
        )}

      {otherOrderSWR?.content &&
        (otherOrderSWR?.content.orderType === AssetOrderType.SOLToGems ||
          otherOrderSWR?.content.orderType === AssetOrderType.InAppPurchase ||
          otherOrderSWR?.content.orderType === AssetOrderType.TopUp ||
          otherOrderSWR?.content.orderType ===
            AssetOrderType.ThirdPartyPurchase) && (
          <TopUpOrderDetail assetOrder={otherOrderSWR.content} />
        )}

      {otherOrderSWR?.content &&
        otherOrderSWR?.content.orderType === AssetOrderType.SendToken && (
          <SendTokenOrderDetail assetOrder={otherOrderSWR.content} />
        )}

      {otherOrderSWR?.content &&
        otherOrderSWR?.content.orderType ===
          AssetOrderType.TransferFromStore && (
          <TransferFromShopDetail assetOrder={otherOrderSWR.content} />
        )}

      {otherOrderSWR?.content &&
        otherOrderSWR?.content.orderType === AssetOrderType.MINT && (
          <MintOrderDetail assetOrder={otherOrderSWR.content} />
        )}

      {otherOrderSWR?.content &&
        otherOrderSWR?.content.orderType === AssetOrderType.Trade && (
          <TradeOrderDetail assetOrder={otherOrderSWR.content} />
        )}

      {otherOrderSWR?.content &&
        otherOrderSWR?.content.orderType === AssetOrderType.CreateSOLToken && (
          <CreateNewTokenDetail assetOrder={otherOrderSWR.content} />
        )}

      {otherOrderSWR?.content &&
        otherOrderSWR?.content.orderType === AssetOrderType.GetMembershipCard &&
        (isByStaking ? (
          <StakeTokenDetail assetOrder={otherOrderSWR.content} />
        ) : (
          <PurchaseMembershipCardDetail assetOrder={otherOrderSWR.content} />
        ))}

      {otherOrderSWR?.content &&
        otherOrderSWR?.content.orderType ===
          AssetOrderType.RedeemMembershipCard && (
          <MembershipCardRedemptionDetail assetOrder={otherOrderSWR.content} />
        )}

      {otherOrderSWR?.content &&
        otherOrderSWR?.content.orderType ===
          AssetOrderType.TransferAdminCommission && (
          <TransferAdminEarningsOrderDetail
            assetOrder={otherOrderSWR.content}
          />
        )}

      {otherOrderSWR?.content &&
        otherOrderSWR.content.orderType === AssetOrderType.ConvertGems && (
          <ConvertToGemsDetail assetOrder={otherOrderSWR.content} />
        )}

      <TransactionResultModal
        modal={modal}
        title={title || i18n.web3_insuf_purchase_completed()}
        state={TransactionResult.SUCCESS}
        showDescription={showDescription}
        showAddTreasureCheckBox={showAddTreasureCheckBox}
        orderItemList={storeOrderSWR?.content?.orderItemList}
      />

      {giftOrderSWR?.content && (
        <GiftContentDialog
          modal={giftClaimedModal}
          currency={giftOrderSWR?.content.currency}
          nft={giftOrderSWR?.content.nft}
        />
      )}
    </Page>
  );
}

export default OrderDetailPage;
