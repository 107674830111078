import { z } from "zod";
import { zStatic } from "../utils/zodUtils";

export const AlertCounts = z.object({
  activitiesAlertCount: z.number().default(0),
  followersAlertCount: z.number().default(0),
  likesAlertCount: z.number().default(0),
  globalAnnouncementCount: z.number().default(0),
  postCount: z.number().default(0),
  hasCircleReminders: z.boolean().default(false),
  circleAlertCount: z.number().default(0),
});

export type AlertCounts = zStatic<typeof AlertCounts>;
