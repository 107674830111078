import {
  Chat,
  ChatListCategory,
  getChatDisplayName,
} from "../../../proto/Chat";
import { Page } from "../../../components/Page";
import { useSWROffsetList } from "../../../hooks/swr/useSWRList";
import { HStack, Spring, VSpace, VStack } from "../../../components/VStack";
import ChatThreadIcon from "../components/ChatThreadIcon";
import { cell_on_pressed } from "../../../components/CommonStyles";
import styled, { css } from "styled-components";
import { Image } from "../../../components/Image";
import DisableNoticeIcon from "./../images/ic_chat_notice_disable.svg";
import { useHopper } from "../../../hooks/useHopper";
import { useBackend } from "../../../service/APIService";
import { useMyUid } from "../../../service/AuthSessionService";
import { ObjectPool, ObjectPoolSubscriber } from "../utils/ObjectPool";
import { useEffect, useMemo, useState } from "react";
import { timeIntervalString } from "../../../utils/DateTimeUtils";
import { useI18n } from "../../../hooks/useI18n";
import { genUUID } from "../../../utils/uuid";
import { SpecialTitle } from "../../../proto/User";
import { ChatThreadType } from "../../../proto/ChatThreadType";
import IcAI from "../images/icon_ai.png";
import IcTeamClover from "../images/icon_team_clover.png";
import { ChatAlert } from "../../../proto/ChatMember";
import { ObjectStatus } from "../../../proto/ObjectStatus";
import { SmallNoteType } from "../../../proto/SmallNote";
import { OneLineLabel } from "../../nft/components/Label";

export enum ChatListType {
  private = 1,
  party = 2,
  invite = 3,
  atMention = 4,
  managed = 5,
}

interface JoinedChatsResp {
  list: Chat[];
  cursor: number | undefined;
}

function useJoinedChatListSWR(type: ChatListCategory) {
  const backend = useBackend();
  return useSWROffsetList(backend.getJoinedThreads(type));
}

function chatCategoryFor(type: ChatListType) {
  switch (type) {
    case ChatListType.private:
      return ChatListCategory.private;
    case ChatListType.invite:
      return ChatListCategory.invite;
    case ChatListType.atMention:
      return ChatListCategory.atMention;
    case ChatListType.managed:
      return ChatListCategory.atMention;
    default:
      return ChatListCategory.all;
  }
}

const ThreadContentStack = css`
  flex-grow: 1;
  margin-right: var(--page-h-inset);
  align-items: start;
  overflow: hidden;
  width: calc(100% - 2 * var(--page-h-inset) - 50px);
`;

const PublicFlag = styled.div`
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  font-size: 10px;
  padding: 2px 3px;
  border: 1px solid rgba(255, 255, 255, 0.4);
`;

export const ChatTitle = styled.div`
  font-size: 16px;
  color: white;

  flex-shrink: 1;
  flex-basis: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ChatLastMsgTime = styled.div`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.4);
`;

const LastMsg = styled.span`
  font-size: 14px;
  color: white;

  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
  overflow: hidden;
`;

export const Sep = styled.div`
  margin-left: calc(var(--page-h-inset) + 54px);
  margin-right: 0;
  height: 1px;
  background-color: var(--color-cell-divider);
`;

const TeamCloverBg = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #0bab6420 0%, #0499f220 100%);
`;

const Remark = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  padding: 4px 7px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.8);
  border-radius: 3px;
`;

const MentionedBy = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 14px;
  border: 1px dotted red;
  padding: 2px;
`;

export function ChatThreadCell(props: {
  threadId: bigint;
  initialValue: Chat;
  pool?: ObjectPool<Chat>;
}) {
  const hopper = useHopper();
  const myUid = useMyUid();
  const i18n = useI18n();

  const [chat, setChat] = useState(props.initialValue);

  const latestMsgTime = useMemo(() => {
    if (chat.latestMessage) {
      let time = chat.latestMessage.createdTime;
      let date = new Date(time * 1000);
      return timeIntervalString(date, i18n);
    }
  }, [chat, i18n]);

  const firstOther = useMemo(() => {
    return chat.membersSummary?.find((user) => user.uid !== myUid);
  }, [chat]);

  const showTeamClover = useMemo(() => {
    return (
      chat.type === ChatThreadType.single &&
      firstOther?.specialTitle === SpecialTitle.Official
    );
  }, [chat, firstOther]);

  const [smallNote, isRead] = useMemo(() => {
    const note = chat.smallNoteList?.at(0);
    if (note) {
      const isRead = note.readTime === undefined || note.readTime === 0;
      switch (note.type) {
        case SmallNoteType.ContainerInvitedMember:
          return [
            chat.type === ChatThreadType.public
              ? i18n.party_on_has_been_invited_to_join_party()
              : i18n.party_on_has_been_invited_to_join_chat(),
            isRead,
          ];
        case SmallNoteType.ContainerInvitedTobeCoAdmin:
          return [i18n.smallnote_unread_invited_become_coadmin(), isRead];
        case SmallNoteType.ContainerMemberRemovedByManager:
          return [i18n.chat_read_only_you_are_removed_from_this_chat(), isRead];
        case SmallNoteType.ContainerInvitedTobeAdmin:
          return [i18n.smallnote_unread_invited_become_admin(), isRead];
        case SmallNoteType.ContainerManagerBeRemoved:
          return [i18n.smallnote_unread_removed_coadmin(), isRead];
      }
    }
    return [undefined, true];
  }, [chat]);

  useEffect(() => {
    if (!props.pool) {
      return;
    }
    const sub = {
      onObjectUpdated: (chat) => {
        console.log("chat update: ", chat);
        setChat({ ...chat });
      },
      uniqueId: genUUID(),
    } as ObjectPoolSubscriber<Chat>;
    props.pool.addSubscribe(sub, props.threadId);
    return () => {
      props.pool?.removeSubscribe(sub, props.threadId);
    };
  }, [props.threadId, props.pool, setChat]);

  return (
    <>
      <HStack
        style={{
          width: "100%",
          padding: "16px 0",
          gap: "10px",
          overflow: "hidden",
        }}
        key={props.threadId}
        mixin={[cell_on_pressed]}
        onClick={() => hopper.push(`chat/${props.threadId}`)}
      >
        {showTeamClover && <TeamCloverBg />}
        <ThreadIconContainer>
          <ChatThreadIcon chat={chat} />
        </ThreadIconContainer>

        <VStack mixin={ThreadContentStack}>
          <HStack style={{ width: "100%", gap: "6px" }}>
            {chat.type === ChatThreadType.public && (
              <PublicFlag>{i18n.party_on_party_compose_title()}</PublicFlag>
            )}
            {(chat.type === ChatThreadType.chatAi ||
              chat.type === ChatThreadType.character) && (
              <Image src={IcAI} width={17}></Image>
            )}
            <ChatTitle>{getChatDisplayName(chat, myUid)}</ChatTitle>
            {showTeamClover && <Image src={IcTeamClover} width={50} />}
            <Spring />
            <ChatLastMsgTime>{latestMsgTime}</ChatLastMsgTime>
            {chat.currentMemberInfo?.alertOption === ChatAlert.Off ? (
              <Image src={DisableNoticeIcon} />
            ) : undefined}
          </HStack>
          {!!chat.remark && (
            <>
              <VSpace height={6} />
              <Remark>{i18n.add_remark_display(chat.remark)}</Remark>
            </>
          )}
          {chat.status === ObjectStatus.DISABLED ? (
            <>
              <VSpace height={6} />
              <LastMsg
                style={{
                  backgroundColor: "red",
                  fontSize: 12,
                  padding: "1px 5px",
                  borderRadius: "2px",
                }}
              >
                {i18n.disabled()}
              </LastMsg>
            </>
          ) : (
            <>
              {!!chat.latestMessage && (
                <>
                  <VSpace height={chat.mentionedBy ? 1 : 6} />
                  <HStack style={{ width: "100%", overflow: "hidden" }}>
                    <LastMsg style={{ flexShrink: 1 }}>
                      {chat.latestMessage?.author?.nickname}
                    </LastMsg>
                    <div>:</div>
                    <LastMsg style={{ flexShrink: 100 }}>
                      {chat.latestMessage?.content}
                    </LastMsg>
                    <Spring />
                    {chat.mentionedBy && (
                      <Image
                        src={[chat.mentionedBy.icon, 24]}
                        styledImg={MentionedBy}
                      ></Image>
                    )}
                  </HStack>
                </>
              )}
              {!!smallNote && (
                <>
                  <VSpace height={6} />
                  <HStack
                    style={{
                      color: isRead ? "rgba(255, 255, 255, 0.6)" : "#ff9222",
                    }}
                  >
                    <>[</>
                    <OneLineLabel>{smallNote}</OneLineLabel>
                    <>]</>
                  </HStack>
                </>
              )}
            </>
          )}
        </VStack>
      </HStack>
      <Sep />
    </>
  );
}

export const ThreadIconContainer = styled.div`
  width: 44px;
  height: 44px;
  margin-left: var(--page-h-inset);
`;

export function GeneralChatListPage(props: {
  type: ChatListType;
  threadPool: ObjectPool<Chat>;
  extraThreads: Chat[];
}) {
  const category = chatCategoryFor(props.type);
  const contentSWR = useJoinedChatListSWR(category);

  const displayThreads = useMemo(() => {
    if (!contentSWR.content) return props.extraThreads;
    return contentSWR.content.list
      .concat(props.extraThreads)
      .filter(
        (thread, index, self) =>
          index === self.findIndex((t) => t.threadId === thread.threadId),
      )
      .sort((t1, t2) =>
        (t1.latestMessageId ?? 0) < (t2.latestMessageId ?? 0) ? 1 : -1,
      );
  }, [contentSWR, props.extraThreads]);

  useMemo(() => {
    if (!contentSWR.content) return;
    contentSWR.content.list.forEach((chat: Chat) => {
      if (chat.threadId) {
        props.threadPool.cacheObjectIfNotExist(chat.threadId, chat);
      }
    });
  }, [contentSWR, props.threadPool]);

  return (
    <Page pageData={contentSWR} scrollPaddingDisabled={true}>
      {displayThreads.map((item) => (
        <ChatThreadCell
          threadId={item.threadId}
          initialValue={item}
          pool={props.threadPool}
          key={item.threadId}
        />
      ))}
    </Page>
  );
}
