import { isSpongeKit } from "../utils/isSpongeKit";

export enum ChainType {
  // ETHMainnet = 1,
  Solana = 2,
  Merlin = 4200,
  // BTC = 10000,
  // ETHRopsten = 900001,
  // Rinkeby = 900002,
  // Goerli = 900003,
  // BTCTestnet = 900004,
  // BTCSignet = 900005,
  // MerlinTestnet = 900006,
  SolanaTestnet = 900007,
}

export function getRuntimeChainType() {
  if (isSpongeKit()) {
    return ChainType.SolanaTestnet;
  }
  return ChainType.Solana;
}
