import {
  ChatMessage,
  ChatMessageType,
  getChatMessageContentColor,
  getChatMsgTextColor,
  isChatMessageNeedBubble,
} from "../../../proto/ChatMessage";
import { HStack, VStack } from "../../../components/VStack";
import { UserIconView } from "../../../components/views/UserAvatarView";
import { Image } from "../../../components/Image";
import { UserNameView } from "../../../components/views/UserNameView";
import { PropsWithChildren, useEffect, useMemo, useRef, useState } from "react";
import { getBestRes, getMediaDuration, isVideo } from "../../../proto/Media";
import icPlay from "../images/icon_play.svg";
import icWave from "../images/ic_wave.svg";
import styled from "styled-components";
import CssFilterConverter from "css-filter-converter";
import MsgContentGiftBox from "./MsgContentGiftBox";
import { useI18n } from "../../../hooks/useI18n";
import { andLog } from "../../../components/handleError";
import AniWaveWhite from "../images/wave_white.webp";
import AniWateBlack from "../images/wave_black.webp";
import { colord } from "colord";

function TextContent(props: { msg: ChatMessage }) {
  const i18n = useI18n();
  let content;
  if (props.msg.type === ChatMessageType.CharacterLackOfEnergy) {
    content = i18n.ai_char_lack_of_energy_hint(
      <span style={{ color: "#2FADFE", backgroundColor: "white" }}>
        {i18n.ai_char_feed_now()}
      </span>,
    );
  } else {
    content = props.msg.content;
  }
  return <div style={{ wordWrap: "break-word" }}>{content}</div>;
}

const MediaContainer = styled.div`
  border-radius: 8px;
  position: relative;
  overflow: hidden;
`;

const Media = styled.img`
  max-width: 60vw;
  max-height: 60vw;
`;

const MediaDarkCover = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
`;

const IconPlay = styled.img`
  position: absolute;
  width: 50px;
  height: 50px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

function MediaContent(props: { msg: ChatMessage }) {
  const isV = isVideo(props.msg.media);
  return (
    <MediaContainer>
      <Image
        src={[isV ? props.msg.media?.cover : props.msg.media, "best"]}
        styledImg={Media}
      />
      {isV && <MediaDarkCover />}
      {isV && <Image src={icPlay} styledImg={IconPlay} />}
    </MediaContainer>
  );
}

function StickerContent(props: { msg: ChatMessage }) {
  return (
    <Image
      src={[props.msg.sticker?.media, "best"]}
      style={{ width: "120px", height: "120px" }}
    />
  );
}

function DiceContent(props: { msg: ChatMessage }) {
  const [shouldShowDiceResult, setShow] = useState(
    props.msg.extensions?.diceSideId &&
      Date.now() - props.msg.createdTime * 1000 > 3500,
  );
  useEffect(() => {
    const checkDiceResult = () => {
      if (
        props.msg.extensions?.diceSideId &&
        Date.now() - props.msg.createdTime * 1000 > 3500
      ) {
        setShow(true);
      }
    };
    if (!shouldShowDiceResult) {
      window.setTimeout(
        checkDiceResult,
        Math.max(props.msg.createdTime * 1000 + 3500 - Date.now(), 1000),
      );
    }
  }, [shouldShowDiceResult]);

  if (shouldShowDiceResult) {
    return (
      <Image
        src={[
          props.msg.dice?.sides?.[(props.msg.extensions?.diceSideId ?? 1) - 1],
          "best",
        ]}
        style={{ width: "120px", height: "120px" }}
      />
    );
  } else {
    return (
      <Image
        src={[props.msg.dice?.animation, "best"]}
        style={{ width: "120px", height: "120px" }}
      />
    );
  }
}

function AudioContent(props: { msg: ChatMessage }) {
  const duration = getMediaDuration(props.msg.media);
  const wi = parseInt(duration.toFixed(0));
  const contentColor = getChatMessageContentColor(props.msg);
  let filter = CssFilterConverter.hexToFilter(contentColor);
  const ref = useRef<HTMLAudioElement | null>(null);
  const [playing, setPlaying] = useState(false);
  const [showingTime, setShowingTime] = useState(duration.toFixed(0));

  const onTimeUpdate = () => {
    if (!ref.current) {
      return;
    }
    if (playing) {
      const cu = ref.current.currentTime;
      setShowingTime((duration - cu).toFixed(0));
    } else {
      setShowingTime(duration.toFixed(0));
    }
  };

  const showDarkContent = colord(getChatMsgTextColor(props.msg)).isDark();

  return (
    <div
      style={{
        width: `${wi}vw`,
        minWidth: "15vw",
        maxWidth: "60vw",
        alignItems: "center",
        justifyContent: "start",
        display: "flex",
        gap: "3px",
      }}
      onClick={() => {
        if (!ref.current) {
          return;
        }
        if (ref.current.paused) {
          ref.current.play().catch(andLog);
          setPlaying(true);
        } else {
          ref.current.pause();
          ref.current.currentTime = 0;
          setPlaying(false);
        }
      }}
    >
      <audio
        src={props.msg.media?.resourceList[0].url}
        ref={ref}
        onTimeUpdate={onTimeUpdate}
        onEnded={() => setPlaying(false)}
      ></audio>
      {showingTime}"
      {!playing && <Image src={icWave} style={{ filter: filter.color + "" }} />}
      {playing && (
        <Image
          src={showDarkContent ? AniWateBlack : AniWaveWhite}
          style={{
            filter: filter.color + "",
            width: "16px",
            height: "16px",
          }}
        />
      )}
    </div>
  );
}

function MsgContent(props: { msg: ChatMessage }) {
  switch (props.msg.type) {
    case ChatMessageType.Text:
    case ChatMessageType.CharacterLackOfEnergy:
      return <TextContent msg={props.msg} />;
    case ChatMessageType.Sticker:
      return <StickerContent msg={props.msg} />;
    case ChatMessageType.Dice:
      return <DiceContent msg={props.msg} />;
    case ChatMessageType.Image:
    case ChatMessageType.Video:
      return <MediaContent msg={props.msg} />;
    case ChatMessageType.Audio:
      return <AudioContent msg={props.msg} />;
    case ChatMessageType.GiftBox:
      return <MsgContentGiftBox msg={props.msg} />;
    default:
      return <></>;
  }
}

function MsgBubble(props: PropsWithChildren<{ msg: ChatMessage }>) {
  return (
    <>
      {isChatMessageNeedBubble(props.msg) && (
        <div
          style={{
            position: "relative",
            background: props.msg.bubbleMedia ? "none" : props.msg.bubbleColor,
            maxWidth: "65%",
            padding: props.msg.bubbleMedia ? "0px" : "8px 14px",
            borderRadius: props.msg.bubbleMedia ? "0" : "8px",
            borderWidth: props.msg.bubbleMedia ? "20px" : "0",
            borderStyle: "solid",
            borderImageSource: props.msg.bubbleMedia
              ? `url(${getBestRes(props.msg.bubbleMedia).url})`
              : "none",
            borderImageRepeat: "round",
            borderImageSlice: "80 89 80 89 fill",
            color: getChatMsgTextColor(props.msg),
          }}
        >
          {props.children}
        </div>
      )}
      {!isChatMessageNeedBubble(props.msg) && <>{props.children}</>}
    </>
  );
}

export default function GeneralMsgCell(props: {
  msg: ChatMessage;
  onAuthorClicked: () => void;
}) {
  return (
    <div style={{ paddingTop: "16px" }} key={props.msg.messageId}>
      <HStack style={{ alignItems: "start", gap: "8px" }}>
        <UserIconView
          user={props.msg.author}
          iconSize={36}
          onClick={props.onAuthorClicked}
        />
        <VStack style={{ width: "100%", alignItems: "start", gap: "2px" }}>
          {props.msg.author && <UserNameView user={props.msg.author} />}
          <MsgBubble msg={props.msg}>
            <MsgContent msg={props.msg} />
          </MsgBubble>
        </VStack>
      </HStack>
    </div>
  );
}
