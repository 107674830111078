import styled, { css, RuleSet } from "styled-components";
import { useCircleSWR } from "./useCircleSWR";
import { useBackend } from "../../service/APIService";
import { SWRSingle, useSWR } from "../../hooks/swr/useSWR";
import React, { useMemo } from "react";
import {
  ChainInfo,
  Currency,
  CurrencyType,
  getChainIcon,
} from "../../proto/Currency";
import { useI18n } from "../../hooks/useI18n";
import { useNativePage } from "../../hooks/useBridge";
import { useHopState } from "../../hooks/useHopState";
import Grapheme from "grapheme-splitter";
import { NavButtonType } from "../../bridge/NavButtonDesc";
import { andLog, useErrorHandler } from "../../components/handleError";
import { formatNumber } from "../../utils/NumberI18n";
import {
  BridgeLocalMedia,
  isLocalMedia,
  LocalMedia,
} from "../../bridge/LocalMedia";
import { TokenIcon } from "./compose/TokenIcon";
import { HStack, Spring, VStack } from "../../components/VStack";
import { RegularInputGroup } from "../../components/Input";
import { Image } from "../../components/Image";
import { ChainType } from "../../proto/ChainType";
import { Page } from "../../components/Page";
import { useGlobalSpinner } from "../../utils/globalSpinner";
import { NavItem, NavMiddle, NavStart } from "../../components/NavBar";
import { isValidMedia, Media } from "../../proto/Media";
import { FilePicker } from "../../components/FilePicker";
import { useLoadState } from "../../hooks/LoadState";
import { PageFooter } from "../../components/PageHeaderFooter";
import { RowCenterButton } from "../../components/CommonStyles";
import { Spin } from "../../components/Spin";
import { RegularButton } from "../../components/Buttons";
import { useHopper } from "../../hooks/useHopper";
import { delay } from "../../utils/ensureDur";
import { TokenProject } from "../../proto/TokenProject";
import { CreateTokenReq } from "../../proto/CreateTokenReq";
import { CircleCardStatus } from "../../proto/CircleCardStatus";
import {
  useEnumSearchParam,
  useOptionalBigIntParam,
} from "../../hooks/useTypedParam";
import { UpdateTokenProjectRequest } from "../../proto/UpdateTokenProjectRequest";
import { isValidCircleToken } from "../../proto/CircleFin";
import { useTransactionBody } from "../wallet/send/SendTokenPage";
import { CircleTokenStatus } from "../../proto/CircleTokenStatus";

export enum TokenDesignType {
  Create = 1,
  Edit = 2,
}

export function TokenDesignPage() {
  const [circleId, circleSWR] = useCircleSWR();
  const backend = useBackend();
  const tokenDesignType = useEnumSearchParam<TokenDesignType>(
    "tokenDesignType",
    TokenDesignType.Create,
  );
  const tokenProjectId = useOptionalBigIntParam("tokenProjectId");
  const circleTokenSWR = useSWR(
    tokenProjectId ? backend.getTokenProject(tokenProjectId) : undefined,
  );

  const presetSWR = useSWR(backend.createTokenPresets(circleId));

  const chainInfoSWR = useSWR(["chain-info", circleId], () =>
    backend.getTokenChainInfo(circleId).run(),
  );

  const circle = circleSWR.content;
  const initTokenTuple = useMemo<
    [UpdateTokenProjectRequest, boolean] | undefined
  >(() => {
    if (
      circleTokenSWR?.content &&
      isValidCircleToken(circleTokenSWR.content.tokenProject)
    ) {
      return [
        {
          name: circleTokenSWR.content.tokenProject.name,
          ticker: circleTokenSWR.content.tokenProject.ticker,
          image: circleTokenSWR.content.tokenProject.image,
        },
        true,
      ];
    } else if (circle) {
      return [
        {
          name: circle.name.slice(0, 50),
          image: circle.circleIcon,
        },
        true,
      ];
    }
  }, [circle, circleTokenSWR?.content]);

  const i18n = useI18n();

  return (
    <Page pageData={undefined}>
      <NavMiddle>{i18n.clover_create_a_new_token()}</NavMiddle>
      {/*{initTokenTuple && chainInfoSWR.content && (*/}
      <MainContent
        circleId={circleId}
        tokenTuple={
          initTokenTuple || [
            {
              name: circle?.name.slice(0, 50),
              image: circle?.circleIcon,
            },
            true,
          ]
        }
        chainInfo={
          chainInfoSWR.content || {
            chainType: ChainType.Solana,
            supply: presetSWR.content?.supply || 0,
          }
        }
        tokenDesignType={tokenDesignType}
        tokenProjectId={tokenProjectId}
      />
    </Page>
  );
}

function MainContent(props: {
  circleId: bigint;
  tokenTuple: [UpdateTokenProjectRequest, boolean];
  chainInfo: ChainInfo;
  tokenDesignType: TokenDesignType;
  tokenProjectId: bigint | undefined;
}) {
  const circleId = props.circleId;
  const [token, isFromCircle] = props.tokenTuple;
  const i18n = useI18n();
  const nativePage = useNativePage();
  const backend = useBackend();
  const globalSpinner = useGlobalSpinner();

  const [tokenName, setTokenName] = useHopState(
    ["token-design", "token-name", circleId, props.tokenProjectId],
    token.name,
  );
  const [ticker, setTicker] = useHopState(
    ["token-design", "token-ticker", circleId, props.tokenProjectId],
    token.ticker,
  );
  const tickerMaxLength = 6;
  const nameMaxLength = 50;

  const handleError = useErrorHandler();
  const uploadTask = useLoadState();
  const cardsSWR = useSWR(backend.getCircleCardsList(circleId));
  const circleSWR = useSWR(backend.getCircle(circleId));

  const circleTokenSWR = useSWR(
    props.tokenProjectId
      ? backend.getTokenProject(props.tokenProjectId)
      : undefined,
  );

  async function upload() {
    if (pendingMedia) {
      if (isLocalMedia(pendingMedia)) {
        const r = await uploadTask.run(async () => {
          const media = await backend.sendLocalMedia(
            pendingMedia,
            "nft_thumbnail",
            (a, b) => {},
          );
          setPendingMedia(media);
          return media;
        });
        if (!r.success) {
          handleError(r.error);
          return undefined;
        } else {
          return r.result;
        }
      } else {
        return pendingMedia;
      }
    }
  }

  const onNextClick = () => {
    const nameTrimmed = tokenName?.trim() || "";
    const tickerTrimmed = ticker?.trim() || "";
    if (nameTrimmed.length === 0) {
      nativePage
        .infoHud(i18n.circle_level_have_fill_token_name())
        .catch(andLog);
    } else if (tickerTrimmed.length === 0) {
      nativePage.infoHud(i18n.circle_level_have_fill_ticker()).catch(andLog);
    } else if (!RegExp(`[a-zA-Z0-9]{1,}`).test(tickerTrimmed)) {
      nativePage
        .alertNotice(
          i18n.circle_level_symbol_cannot_contain_special_char(),
          i18n.ok(),
        )
        .catch(andLog);
    } else if (
      nameMaxLength < TextLengthHelper.splitGraphemes(nameTrimmed).length ||
      tickerMaxLength < TextLengthHelper.splitGraphemes(tickerTrimmed).length
    ) {
      nativePage
        .alertNotice(
          i18n.text_limit_number_of_characters_not_follow(),
          i18n.ok(),
        )
        .catch(andLog);
    } else {
      globalSpinner(async () => {
        const uploadedMedia = await upload();
        if (uploadedMedia === undefined) {
          nativePage
            .alertNotice("uploadedMedia undefined", i18n.ok())
            .catch(andLog);
          return;
        }
        switch (props.tokenDesignType) {
          case TokenDesignType.Create:
            {
              const tokenProject: TokenProject = {
                projectId: BigInt(0),
                ticker: tickerTrimmed,
                chainType: 2,
                name: nameTrimmed,
                image: uploadedMedia,
                status: CircleTokenStatus.Pending,
                enableShareToEarn: false,
                availableShareToEarnReward: "0",
                topTenUserEarnRewards: [],
                totalSupply: "0",
              };
              const body: CreateTokenReq = {
                circleId: circleId,
                tokenProject: tokenProject,
              };

              const resp = await backend.performCreateTokenProject(body).run();
              const tokenProjectId = resp.tokenProject.projectId;
              await delay(200);
              await backend
                .linkTokenWithCircle(circleId, {
                  tokenProjectId: tokenProjectId,
                })
                .run();
            }
            break;
          case TokenDesignType.Edit:
            {
              if (props.tokenProjectId === undefined) {
                return;
              }
              const body: UpdateTokenProjectRequest = {
                name: nameTrimmed,
                ticker: tickerTrimmed,
                image: uploadedMedia,
              };
              await backend
                .updateTokenProject(props.tokenProjectId, body)
                .run();
              await circleTokenSWR?.load();
            }
            break;
        }
        await cardsSWR.load();
        await circleSWR.load();
        hopper.back();
      }).catch(andLog);
    }
  };

  const hopper = useHopper();
  const onBack = async () => {
    if (
      mediaHasChanged ||
      token.name !== tokenName ||
      token.ticker !== ticker
    ) {
      const quit = await nativePage.alertAreYouSure(
        i18n.bottle_editor_quit_edit_prompt_content(),
        i18n.confirm(),
        i18n.cancel(),
      );
      if (quit) {
        hopper.back();
      }
    } else {
      hopper.back();
    }
  };

  const TextLengthHelper = new Grapheme();

  const supply = useMemo(() => {
    return formatNumber(props.chainInfo.supply);
  }, [props.chainInfo]);

  const [pendingMedia, setPendingMedia] = useHopState<
    Media | LocalMedia | undefined
  >("pendingMedia", token.image);

  const mediaHasChanged = (() => {
    if (pendingMedia === undefined) {
      return true;
    }
    if (isLocalMedia(pendingMedia)) {
      return (
        (pendingMedia as BridgeLocalMedia).id !== String(token.image?.mediaId)
      );
    }
    if (isValidMedia(pendingMedia)) {
      return pendingMedia.mediaId !== token.image?.mediaId;
    }
  })();
  return (
    <>
      <NavStart>{NavItem.image(NavButtonType.Back, onBack)}</NavStart>
      <DesignYourTokenTitle>
        {i18n.clover_design_your_token()}
      </DesignYourTokenTitle>
      <FilePicker accept={"image/*"} onPick={setPendingMedia}>
        <TokenIcon
          size={{ width: 224, height: 224 }}
          src={[pendingMedia, 224]}
          showEditBadge={true}
          containerStyle={{
            margin: "12px auto",
          }}
          radius={20}
        />
      </FilePicker>

      <Label
        mixin={css`
          margin-top: 12px;
          text-align: center;
        `}
      >
        {i18n.circle_level_token_image()}
      </Label>

      <VStack
        mixin={css`
          align-self: stretch;
          margin-top: 48px;
          gap: 32px;
        `}
      >
        <RegularInputGroup
          label={i18n.circle_level_token_name()}
          style={css`
            align-self: stretch;
          `}
          maxLength={nameMaxLength}
          value={tokenName || ""}
          updateValue={(e) => setTokenName(e)}
        />
        <RegularInputGroup
          label={i18n.arthur_wallet_ticker()}
          style={css`
            align-self: stretch;
          `}
          maxLength={tickerMaxLength}
          value={ticker || ""}
          updateValue={(e) => setTicker(e)}
        />
        <TickerDescLabel>
          {i18n.clover_short_name_for_you_token_desc()}
        </TickerDescLabel>
        <HStack
          mixin={css`
            align-self: stretch;
          `}
        >
          <Label>{i18n.web3_v0_network()}</Label>
          <Spring />
          <Image
            src={getChainIcon(props.chainInfo.chainType)}
            style={{ width: 18, height: 18, marginRight: 4 }}
          />
          <TableValue>{GetChainName(props.chainInfo.chainType)}</TableValue>
        </HStack>
        <HStack
          mixin={css`
            align-self: stretch;
          `}
        >
          <Label>{i18n.circle_level_total_supply()}</Label>
          <Spring />
          <TableValue>{supply}</TableValue>
        </HStack>
      </VStack>
      <PageFooter obscuredZoneKey={"TokenDesignBottom"}>
        <RegularButton
          style={{
            ...RowCenterButton,
            marginTop: 8,
          }}
          onClick={onNextClick}
        >
          <Spin state={uploadTask.state}>{i18n.next()}</Spin>
        </RegularButton>
      </PageFooter>
    </>
  );
}

export function GetChainName(chainType?: ChainType): string {
  let chainName = "";
  if (chainType === ChainType.Solana || chainType === ChainType.SolanaTestnet) {
    chainName = "Solana";
  }
  return chainName;
}

const DesignYourTokenTitle = styled.div`
  color: var(--color-text00);
  font-size: 14px;
  font-weight: 400;
`;

const TickerDescLabel = styled.div`
  color: rgba(255, 255, 255, 0.8);
  font-size: 10px;
  font-weight: 300;
  margin-top: -20px;
`;

const Label = styled.div<{ mixin?: RuleSet<Object> }>`
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;

  opacity: 0.8;
  ${(p) => p.mixin}
`;

export const TableValue = styled.div`
  color: #fff;
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export default TokenDesignPage;
