import { useI18n } from "../../../hooks/useI18n";
import React from "react";
import { Page } from "../../../components/Page";
import SettingCell from "../../../components/SettingCell";
import Switch from "../components/Switch";
import { NavMiddle } from "../../../components/NavBar";
import { useHopper } from "../../../hooks/useHopper";

export function SafetyAndPrivacyPage() {
  const i18n = useI18n();
  const hopper = useHopper();

  return (
    <Page pageData={undefined} scrollPaddingDisabled={true}>
      <NavMiddle>{i18n.may23_impr_safety_and_privacy()}</NavMiddle>
      <SettingCell
        title={i18n.circle_perm_accept_private_chat_invite_request()}
        onClick={() => {}}
      ></SettingCell>
      <SettingCell
        title={i18n.party_on_accept_parties()}
        onClick={() => {}}
      ></SettingCell>
      <SettingCell
        title={i18n.circle_perm_accept_circle_invite_request()}
        onClick={() => {}}
      ></SettingCell>
      <SettingCell
        title={i18n.visitor_impr_hide_recent_visitors()}
        secondaryValue={i18n.visitor_impr_hide_recent_visitors_detail()}
        style={{ paddingTop: 10, paddingBottom: 14 }}
        showAccessoryImage={false}
        onClick={() => {}}
        endValue={<Switch checked={true} />}
      ></SettingCell>
      <SettingCell
        title={i18n.visitor_impr_public_profile_privacy()}
        onClick={() => {}}
      ></SettingCell>
      <SettingCell
        title={i18n.blocked_list()}
        showDivider={false}
        onClick={() => {
          hopper.push(`blocked-users`);
        }}
      ></SettingCell>
    </Page>
  );
}
