import { useBigIntParam } from "../../../hooks/useTypedParam";
import { useSWR } from "../../../hooks/swr/useSWR";
import { useBackend } from "../../../service/APIService";
import { Page } from "../../../components/Page";
import {
  Chat,
  getChatDisplayName,
  isChatThreadParty,
  isChatThreadSingle,
  isUserHostOrCoHostIn,
} from "../../../proto/Chat";
import React, { useMemo, useState } from "react";
import { Image } from "../../../components/Image";
import ChatBackgroundSmallCard from "../components/ChatBackgroundSmallCard";
import { HStack, Spring, VStack } from "../../../components/VStack";
import IcArrow from "../images/ic_cell_arrow.svg";
import { CellDivider } from "../../../components/ContentGroup";
import { useI18n } from "../../../hooks/useI18n";
import { RegularButton } from "../../../components/Buttons";
import { useMyUid } from "../../../service/AuthSessionService";
import { ChatAlert, ChatMemberStatus } from "../../../proto/ChatMember";
import Switch from "../../settings/components/Switch";
import { useNativePage } from "../../../hooks/useBridge";
import styled, { css } from "styled-components";
import { Overlay } from "../../circle/CircleBackgroundFrame";
import { UserIconView } from "../../../components/views/UserAvatarView";
import { UserNameView } from "../../../components/views/UserNameView";
import { useSWRList } from "../../../hooks/swr/useSWRList";
import { getPostDisplayCover, Post } from "../../../proto/Post";
import { Spacing } from "../../../components/Spacing";
import GridLayout from "../../../components/GridLayout";
import { User } from "../../../proto/User";
import IcAdd from "../images/ic_add.svg";
import { useHopper } from "../../../hooks/useHopper";
import {
  alpha_on_pressed,
  cell_on_pressed,
  FullPageWidthCell,
} from "../../../components/CommonStyles";
import IcIndicator from "../images/ic_indicator.svg";
import {
  pushProvider,
  useBridgeResultConsumer,
} from "../../../hooks/useBridgeResult";
import { Media } from "../../../proto/Media";
import { Bubble } from "../../../proto/Bubble";
import { NavEnd, NavItem, NavMiddle } from "../../../components/NavBar";
import { NavButtonType } from "../../../bridge/NavButtonDesc";
import { useModal } from "../../../components/Modal";
import { BSMenu, BSMenuItem } from "../../../components/BSMenu";
import { UserPickerPurpose } from "../../user/UserPickerPage";
import { useGlobalSpinner } from "../../../utils/globalSpinner";

const CreatedTime = styled.div`
  font-size: 12px;
  opacity: 0.6;
  margin-top: 5px;
`;

const TopCover = styled.div`
  position: absolute;

  min-height: 230px;
  width: 100%;
  left: 0;
  top: 0;

  z-index: -2;
`;

const BottomBackground = styled.div`
  position: absolute;

  top: 270px;
  width: 100%;
  height: 100%;
  left: 0;

  z-index: -1;

  background-color: #0a001a;

  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`;

const PartyName = styled.div`
  margin-top: 200px;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: bold;
  color: white;
`;

const AdminTag = styled.div`
  border: 1px solid #0dcfa5;
  padding: 2px;
  border-radius: 2px;

  font-size: 10px;
  font-weight: bold;
  color: #0dcfa5;
`;

const ChatBaseInfo = styled.div`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
  padding: 3px 0;
`;

const AnnouncementCard = styled.div`
  height: 153px;
  border: 1px solid #ffffff26;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-content: start;
  position: relative;
`;

const FillParentImage = styled.img`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

const AnnouncementAuthorName = css`
  font-size: 14px;
`;

const AnnouncementGradient = styled.span`
  background: linear-gradient(180deg, #0a001a00, #0a001aff);
  position: absolute;
  left: 0;
  right: 0;
  top: 70px;
  bottom: 0;
`;

const AnnouncementTitle = styled.div`
  font-size: 17px;
  font-weight: bold;
`;

const AddUser = styled.div`
  border: 1px dashed white;
  width: 46px;
  height: 46px;
  border-radius: 50%;

  opacity: 0.4;
`;

const AddUserIcon = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Indicator = styled.div`
  position: absolute;
  width: 46px;
  height: 46px;

  background-image: url(${IcIndicator});
  background-repeat: no-repeat;
  background-position: center;
  background-color: rgba(0, 0, 0, 0.6);
`;

function SectionTitle(props: { title: string }) {
  return (
    <HStack style={{ margin: "20px 0 10px" }}>
      <div style={{ fontSize: 16 }}>{props.title}</div>
      <Spring />
      <Image src={IcArrow} />
    </HStack>
  );
}

function ChatSettingAnnouncement(props: { announcement?: Post }) {
  const i18n = useI18n();
  if (!props.announcement) {
    return (
      <div style={{ fontSize: 15, color: "rgba(255,255,255,0.4)" }}>
        {i18n.no_announcement_yet()}
      </div>
    );
  }

  const cover = getPostDisplayCover(props.announcement);

  return (
    <AnnouncementCard
      style={{ backgroundColor: cover ? "none" : "#6648d7", padding: "12px" }}
    >
      {cover && <Image src={[cover, "best"]} styledImg={FillParentImage} />}
      {cover && <Overlay />}
      {cover && <AnnouncementGradient />}
      <Spring />
      <HStack style={{ gap: 4 }}>
        <UserIconView user={props.announcement?.author} iconSize={26} />
        <UserNameView
          user={props.announcement?.author}
          nameStyle={AnnouncementAuthorName}
        />
        <AdminTag>{i18n.admin()}</AdminTag>
      </HStack>
      <Spacing height={12} />
      <AnnouncementTitle>{props.announcement.title}</AnnouncementTitle>
    </AnnouncementCard>
  );
}

function ChatSettingUser(props: { user: User; showIndicator: boolean }) {
  const hopper = useHopper();
  return (
    <VStack
      style={{ alignItems: "center", gap: "8px" }}
      mixin={alpha_on_pressed}
      onClick={() => {
        hopper.push(`user/${props.user.uid}`);
      }}
    >
      <UserIconView user={props.user} iconSize={46} />
      <UserNameView
        user={props.user}
        style={css`
          width: 100%;
        `}
      />
      {props.showIndicator && <Indicator></Indicator>}
    </VStack>
  );
}

export function ChatSettingAdd(props: { onClicked: () => void }) {
  return (
    <VStack style={{ alignItems: "center" }}>
      <AddUser onClick={props.onClicked}>
        <Image src={IcAdd} styledImg={AddUserIcon} />
      </AddUser>
    </VStack>
  );
}

function SingleChatInfo(props: {
  chat: Chat;
  displayAlert: boolean;
  switchNotification: (value: boolean) => void;
  displayPin: boolean;
  switchPin: (value: boolean) => void;
  showLeave: boolean;
  createdTime: string;
}) {
  const i18n = useI18n();

  const backgroundPicker = useBridgeResultConsumer<Media | string>(
    pushProvider("background-picker"),
    (background) => {
      console.log("select background", background);
      // TODO: update chat background
      return true;
    },
    [],
  );

  const bubblePicker = useBridgeResultConsumer<Bubble | string>(
    pushProvider("chat-bubble-picker"),
    (bubble) => {
      console.log("select bubble", bubble);
      // TODO: update chat bubble
      return true;
    },
    [],
  );

  return (
    <>
      <HStack
        style={{ gap: 10, height: 70 }}
        mixin={[cell_on_pressed, FullPageWidthCell]}
        onClick={() => backgroundPicker()}
      >
        <>{i18n.change_background()}</>
        <Spring />
        <ChatBackgroundSmallCard chat={props.chat} />
        <Image src={IcArrow}></Image>
      </HStack>
      <CellDivider />

      <HStack
        style={{ gap: 10, height: 70 }}
        mixin={[cell_on_pressed, FullPageWidthCell]}
        onClick={() => bubblePicker()}
      >
        <>{i18n.pretty_bubble_chat_bubble_style()}</>
        <Spring />
        <Image src={IcArrow}></Image>
      </HStack>
      <CellDivider />
      <HStack style={{ gap: 10, height: 70 }}>
        <>{i18n.notifications()}</>
        <Spring />
        <Switch
          checked={props.displayAlert}
          onChange={(e) => props.switchNotification(e)}
        ></Switch>
      </HStack>
      <CellDivider />
      <HStack style={{ gap: 10, height: 70 }}>
        <>{i18n.may23_impr_pin_to_top()}</>
        <Spring />
        <Switch
          checked={props.displayPin}
          onChange={(e) => props.switchPin(e)}
        ></Switch>
      </HStack>
      <CellDivider />
      <CreatedTime>{i18n.created_on(props.createdTime)}</CreatedTime>
      <Spring />

      <RegularButton
        $baseColor={props.showLeave ? "red" : "green"}
        style={{ marginTop: "80px", fontSize: "16px" }}
      >
        {props.showLeave ? i18n.leave_chatroom() : i18n.join_chatroom()}
      </RegularButton>
    </>
  );
}

function GroupOrPublicChatInfo(props: {
  chat: Chat;
  displayAlert: boolean;
  switchNotification: (value: boolean) => void;
  displayPin: boolean;
  switchPin: (value: boolean) => void;
  showLeave: boolean;
  createdTime: string;
}) {
  const threadId = props.chat.threadId;
  const myUid = useMyUid();
  const i18n = useI18n();
  const backend = useBackend();
  const hopper = useHopper();
  const language = new Intl.DisplayNames(navigator.language, {
    type: "language",
  });

  const announcements = useSWRList(
    ["announcement", threadId],
    () => backend.getChatAnnouncements(threadId).run(),
    { pageSize: 1 },
  );

  const managersSWR = useSWR(backend.getChatManageTeam(threadId, false, false));
  const membersSWR = useSWRList(
    [threadId, "members", "setting"],
    () => backend.getChatMembers(threadId).run(),
    { pageSize: 12 },
  );

  const bubblePicker = useBridgeResultConsumer<Bubble | string>(
    pushProvider("chat-bubble-picker"),
    (bubble) => {
      console.log("select bubble", bubble);
      // TODO: update chat bubble
      return true;
    },
    [],
  );

  const inviteUser = () => {
    hopper.present("users-picker", {
      oId: threadId,
      purpose: UserPickerPurpose.InviteToChat,
    });
  };

  return (
    <>
      <TopCover>
        <Image src={[props.chat.icon, "best"]} style={{ width: "100%" }} />
        <Overlay />
      </TopCover>
      <BottomBackground></BottomBackground>

      <PartyName>{getChatDisplayName(props.chat, myUid)}</PartyName>
      <HStack style={{ gap: "10px" }}>
        <UserIconView user={props.chat.host} iconSize={30} />
        <UserNameView user={props.chat.host} />
        <AdminTag>{i18n.admin()}</AdminTag>
      </HStack>
      <SectionTitle title={i18n.description()} />
      {props.chat.category?.name && isChatThreadParty(props.chat) && (
        <ChatBaseInfo>
          {i18n.category_prefix_with_name(props.chat.category.name)}
        </ChatBaseInfo>
      )}
      {props.chat.language && isChatThreadParty(props.chat) && (
        <ChatBaseInfo>
          {i18n.region_info_impr_language_prefix_with_name(
            language.of(props.chat.language) ?? "",
          )}
        </ChatBaseInfo>
      )}
      {props.chat.content && <>{props.chat.content}</>}
      <SectionTitle title={i18n.chatroom_info_title_announcements()} />
      <ChatSettingAnnouncement
        announcement={announcements.content?.list?.[0]}
      />
      <SectionTitle
        title={i18n.chat_read_only_chat_management_team_detail_number(
          1 + (props.chat.coHostUids?.length ?? 0),
        )}
      />
      {managersSWR.content?.list && (
        <GridLayout
          spanCount={5}
          items={managersSWR.content.list.slice(0, 9).map((user, index) => {
            return (
              <ChatSettingUser
                user={user}
                key={user.uid}
                showIndicator={
                  index === 9 && (managersSWR.content?.list.length ?? 0) > 10
                }
              />
            );
          })}
        ></GridLayout>
      )}
      <SectionTitle
        title={i18n.party_room_members(props.chat.allMembersCount ?? 0)}
      />
      {membersSWR.content?.list && (
        <GridLayout
          spanCount={5}
          items={[<ChatSettingAdd onClicked={inviteUser} />].concat(
            membersSWR.content.list.slice(0, 9).map((user, index) => {
              return (
                <ChatSettingUser
                  user={user}
                  showIndicator={
                    index === 8 && membersSWR.content.list.length > 9
                  }
                  key={user.uid}
                />
              );
            }),
          )}
        />
      )}

      <HStack
        style={{ gap: 10, height: 70 }}
        mixin={[cell_on_pressed, FullPageWidthCell]}
        onClick={() => bubblePicker()}
      >
        <>{i18n.pretty_bubble_chat_bubble_style()}</>
        <Spring />
        <Image src={IcArrow}></Image>
      </HStack>
      <CellDivider />
      <HStack style={{ gap: 10, height: 70 }}>
        <>{i18n.notifications()}</>
        <Spring />
        <Switch
          checked={props.displayAlert}
          onChange={(e) => props.switchNotification(e)}
        ></Switch>
      </HStack>
      <CellDivider />
      <HStack style={{ gap: 10, height: 70 }}>
        <>{i18n.may23_impr_pin_to_top()}</>
        <Spring />
        <Switch
          checked={props.displayPin}
          onChange={(e) => props.switchPin(e)}
        ></Switch>
      </HStack>
      <CellDivider />
      <CreatedTime>{i18n.created_on(props.createdTime)}</CreatedTime>
      <Spring />

      <RegularButton
        $baseColor={props.showLeave ? "red" : "green"}
        style={{ marginTop: "80px", fontSize: "16px" }}
      >
        {props.showLeave
          ? i18n.party_on_leave_this_party()
          : i18n.party_on_join_this_party()}
      </RegularButton>
    </>
  );
}

export default function ChatInfoPage() {
  const threadId = useBigIntParam("threadId");
  const backend = useBackend();
  const nativePage = useNativePage();
  const i18n = useI18n();
  const myUid = useMyUid();
  const hopper = useHopper();
  const globalSpinner = useGlobalSpinner();
  const thread = useSWR(backend.getThreadInfo(threadId, true));

  const isSingle = useMemo(() => {
    return thread.content && isChatThreadSingle(thread.content);
  }, [thread]);

  const createdTime = useMemo(() => {
    if (thread.content?.createdTime) {
      const date = new Date(thread.content.createdTime * 1000);
      const formatter = new Intl.DateTimeFormat(navigator.language, {
        dateStyle: "medium",
      });
      return formatter.format(date);
    }
    return "";
  }, [thread]);

  const showLeave = useMemo(() => {
    if (thread.content?.currentMemberInfo?.chatMemberStatusV2) {
      const s = thread.content?.currentMemberInfo?.chatMemberStatusV2;
      return (
        s === ChatMemberStatus.Active ||
        s === ChatMemberStatus.RemovedByManager ||
        s === ChatMemberStatus.BlockedByHost
      );
    }
    return false;
  }, [thread]);

  const [updatedAlert, setUpdatedAlert] = useState<ChatAlert | undefined>(
    undefined,
  );
  const displayAlert = useMemo(() => {
    if (updatedAlert) {
      return updatedAlert === ChatAlert.On;
    }
    if (thread.content) {
      return thread.content.currentMemberInfo?.alertOption === ChatAlert.On;
    }
    return true;
  }, [thread, updatedAlert]);
  const [updatedPin, setUpdatedPin] = useState<boolean | undefined>(undefined);
  const displayPin = useMemo(() => {
    if (updatedPin) {
      return updatedPin;
    }
    if (thread.content) {
      return thread.content.currentMemberInfo?.isPinned === true;
    }
    return false;
  }, [thread, updatedPin]);

  const switchNotification = async (value: boolean) => {
    try {
      await globalSpinner(async () => {
        const newValue = value ? ChatAlert.On : ChatAlert.Off;
        await backend.updateChatNotification(newValue, threadId).run();
        setUpdatedAlert(newValue);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const switchPin = async (value: boolean) => {
    try {
      await globalSpinner(async () => {
        await backend.updateChatPin(value, threadId).run();
        setUpdatedPin(value);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const moreMenu = useModal("chat-info-more-menu");

  return (
    <Page pageData={thread} safeTopDisabled={!isSingle}>
      {isSingle && <NavMiddle>{i18n.settings()}</NavMiddle>}
      {!isSingle && (
        <NavEnd>
          {NavItem.image(NavButtonType.More, () => moreMenu.open())}
        </NavEnd>
      )}
      {isSingle && thread.content && (
        <SingleChatInfo
          chat={thread.content}
          displayAlert={displayAlert}
          switchNotification={switchNotification}
          displayPin={displayPin}
          switchPin={switchPin}
          showLeave={showLeave}
          createdTime={createdTime}
        />
      )}
      {!isSingle && thread.content && (
        <GroupOrPublicChatInfo
          chat={thread.content}
          displayAlert={displayAlert}
          switchNotification={switchNotification}
          displayPin={displayPin}
          switchPin={switchPin}
          showLeave={showLeave}
          createdTime={createdTime}
        />
      )}
      <BSMenu modal={moreMenu}>
        <BSMenuItem title={i18n.web3_v0_edit_remark()} onClick={() => {}} />
        {thread.content && isChatThreadParty(thread.content) && (
          <BSMenuItem title={i18n.share()} onClick={() => {}} />
        )}
        {thread.content && isUserHostOrCoHostIn(thread.content, myUid) && (
          <BSMenuItem
            title={i18n.jun1_text_admin_portal()}
            onClick={() => hopper.push(`chat/${threadId}/settings`)}
          />
        )}
      </BSMenu>
    </Page>
  );
}
