import { z } from "zod";
import { zStatic } from "../utils/zodUtils";

export const Breadcrumb = z.object({
  title: z.string().optional(),
  rcmdBlogLabel: z.string().optional(),
  isShow: z.boolean().optional(),
});

export type Breadcrumb = zStatic<typeof Breadcrumb>;

export const BreadcrumbBody = z.object({
  breadcrumbList: z.array(Breadcrumb).optional(),
});

export type BreadcrumbBody = zStatic<typeof BreadcrumbBody>;
