import { Page } from "../../../components/Page";
import { useBackend } from "../../../service/APIService";
import {
  AssetOrder,
  AssetOrderStatus,
  AssetOrderType,
  TxCategory,
} from "../../../proto/AssetOrder";
import {
  ImageContainer,
  OrderCellContainer,
  OrderCreatedTime,
  OrderDivider,
  OrderNameText,
  OrderSenderName,
  StatusBar,
} from "./GiftOrderListPage";
import { HStack, Spring, VStack } from "../../../components/VStack";
import { Image } from "../../../components/Image";
import { useI18n } from "../../../hooks/useI18n";
import { useSWRArray } from "../../../hooks/swr/useSWRArray";
import icSendToken from "../../../res/images/ic_send.png";
import icTopUp from "../../../res/images/ic_top_up.png";
import {
  CurrencyType,
  formatMoney,
  getCurrencyIcon,
  getCurrencyIconByCurrencyType,
  getCurrencyName,
} from "../../../proto/Currency";
import styled from "styled-components";
import { NavMiddle } from "../../../components/NavBar";
import React, { useEffect, useMemo, useState } from "react";
import { getFittestRes } from "../../../proto/Media";
import {
  CountText,
  TotalCurrency,
  TotalLabel,
} from "./MarketplaceOrderListPage";
import { OverFlowTextStyle } from "../../../components/Text";
import { formatDateWithOptions } from "../../../utils/DateTimeUtils";
import { RegularSmallButton } from "../../../components/Buttons";
import { useHopper } from "../../../hooks/useHopper";
import iconSwap from "../../../res/images/ic_convert.png";
import { useNativePage, useWebHost } from "../../../hooks/useBridge";
import { andLog } from "../../../components/handleError";
import { OrderType } from "./OrderDetailPage";
import icMint from "../../../res/images/ic_transaction_nft.png";
import icTransfer from "../../../res/images/ic_transaction_transfer.png";
import icTrade from "../../../res/images/ic_trade.svg";
import { TokenTransactionType } from "../../../proto/DexSwapInfo";
import { getOrderFormattedDateTime } from "./CreateNewTokenDetail";
import CircleCardsPreviewCell, {
  CardStyle,
} from "../../circle/card/CircleCardsPreviewCell";
import { useMarkOrderAsRead } from "./useMarkOrderAsRead";

export function OtherOrderListPage() {
  const backend = useBackend();
  const i18n = useI18n();
  const otherOrdersSWR = useSWRArray(backend.getOtherOrderList());

  useMarkOrderAsRead();

  return (
    <Page pageData={otherOrdersSWR}>
      {otherOrdersSWR.content?.map((order) => {
        switch (order.orderType) {
          case AssetOrderType.MINT:
            return (
              <MintOrderCell
                key={`other-order-${order.orderId}`}
                assetOrder={order}
              />
            );
          case AssetOrderType.Trade:
            return (
              <TradeOrderCell
                key={`other-order-${order.orderId}`}
                assetOrder={order}
              />
            );
          case AssetOrderType.GetMembershipCard:
            return (
              <GetMembershipCardCell
                key={`other-order-${order.orderId}`}
                assetOrder={order}
              />
            );
          default:
            return (
              <AssetOrderCell
                key={`other-order-${order.orderId}`}
                assetOrder={order}
              />
            );
        }
      })}
      <NavMiddle>{i18n.web3_v0_order_history()}</NavMiddle>
    </Page>
  );
}

function AssetOrderCell(props: { assetOrder: AssetOrder }) {
  const i18n = useI18n();
  const hopper = useHopper();

  function getStatus() {
    switch (props.assetOrder.orderStatus) {
      case AssetOrderStatus.Done:
        return { text: i18n.web3_v0_completed(), color: "#00FF94" };
      case AssetOrderStatus.Pending:
      case AssetOrderStatus.Submitted: {
        return { text: i18n.web3_v0_pending(), color: "#34A6FFFF" };
      }
      case AssetOrderStatus.Failed: {
        return { text: i18n.circle_anmt_failed(), color: "#EF3537FF" };
      }
      case AssetOrderStatus.Rejected: {
        return { text: i18n.circle_anmt_rejected(), color: "#EF3537FF" };
      }
      default:
        return { text: "", color: "#FFFFFF66" };
    }
  }

  function getOrderImage() {
    switch (props.assetOrder.orderType) {
      case AssetOrderType.SOLToGems:
      case AssetOrderType.InAppPurchase:
      case (AssetOrderType.TopUp, AssetOrderType.ThirdPartyPurchase):
        return icTopUp;
      case AssetOrderType.SendToken:
        return icSendToken;
      case AssetOrderType.QUOTA: {
        if (props.assetOrder.purchaseQuotaPackageInfo?.packageInfo?.icon) {
          return getFittestRes(
            props.assetOrder.purchaseQuotaPackageInfo?.packageInfo?.icon,
            { width: 74, height: 74 }
          )?.url;
        } else {
          return "";
        }
      }
      case AssetOrderType.Swap:
      case AssetOrderType.SolSwap:
      case AssetOrderType.ConvertGems:
        return iconSwap;
      case AssetOrderType.TransferFromStore:
        return icTransfer;
      case AssetOrderType.CreateSOLToken: {
        if (props.assetOrder.tokenProject?.image) {
          return getFittestRes(props.assetOrder.tokenProject?.image, {
            width: 74,
            height: 74,
          })?.url;
        } else {
          return "";
        }
      }
      case AssetOrderType.TransferAdminCommission:
        return icTransfer;
    }
  }

  const gotCurrency =
    props.assetOrder.txList?.find((tx) => tx.txCategory === TxCategory.GOT)
      ?.currency || props.assetOrder.currency;

  const sentCurrency =
    props.assetOrder.totalDeductionList?.at(0) ||
    props.assetOrder.txList?.find((tx) => tx.txCategory === TxCategory.SENT)
      ?.currency;

  function getOrderName() {
    const currencyName = getCurrencyName(gotCurrency, i18n) || "";

    switch (props.assetOrder.orderType) {
      case AssetOrderType.SOLToGems:
      case AssetOrderType.InAppPurchase:
      case (AssetOrderType.TopUp, AssetOrderType.ThirdPartyPurchase):
        return i18n.web3_hongbao_top_up_asset(currencyName);
      case AssetOrderType.SendToken:
        return i18n.clover_send_tokens();
      case AssetOrderType.QUOTA:
        return props.assetOrder.purchaseQuotaPackageInfo?.packageInfo?.title;
      case AssetOrderType.Swap:
      case AssetOrderType.SolSwap:
        return i18n.web3_v0_swap_asset_to_asset(
          getCurrencyName(sentCurrency, i18n) || "",
          getCurrencyName(gotCurrency, i18n) || ""
        );
      case AssetOrderType.TransferFromStore:
        return i18n.web3_v0_transfer_asset_to_default_account();
      case AssetOrderType.CreateSOLToken:
        return i18n.clover_token_creation();
      case AssetOrderType.RedeemMembershipCard:
        return i18n.clover_membership_card_redemption();
      case AssetOrderType.TransferAdminCommission:
        return i18n.clover_transfer_admin_earnings();
    }
  }

  function getDisplayingCurrency() {
    switch (props.assetOrder.orderType) {
      case AssetOrderType.SOLToGems:
      case AssetOrderType.InAppPurchase:
      case (AssetOrderType.TopUp, AssetOrderType.ThirdPartyPurchase):
        return gotCurrency;
      case AssetOrderType.QUOTA:
        return props.assetOrder.purchaseQuotaPackageInfo?.packageInfo?.price;
      case AssetOrderType.Swap:
      case AssetOrderType.SolSwap:
        return gotCurrency;
      case AssetOrderType.TransferFromStore:
        return gotCurrency;
      case AssetOrderType.TransferAdminCommission:
        return sentCurrency;
      case AssetOrderType.SendToken:
        return sentCurrency;
    }
  }

  function getItemCount() {
    switch (props.assetOrder.orderType) {
      case AssetOrderType.QUOTA:
        return props.assetOrder.purchaseQuotaPackageInfo?.count;
    }
  }

  function getTotalCurrency() {
    switch (props.assetOrder.orderType) {
      case AssetOrderType.QUOTA:
        return props.assetOrder.purchaseQuotaPackageInfo?.totalPay;
    }
  }

  const useNameFirstStyle = props.assetOrder.orderType === AssetOrderType.QUOTA;

  const useBigImage =
    props.assetOrder.orderType === AssetOrderType.QUOTA ||
    props.assetOrder.orderType === AssetOrderType.CreateSOLToken;

  const relatedToCircle =
    props.assetOrder.orderType === AssetOrderType.RedeemMembershipCard ||
    props.assetOrder.orderType === AssetOrderType.CreateSOLToken;

  const needUserInfo =
    !!props.assetOrder.orderType &&
    [AssetOrderType.CreateSOLToken, AssetOrderType.ConvertGems].includes(
      props.assetOrder.orderType
    );

  return (
    <OrderCellContainer
      onClick={() =>
        hopper.push(`wallet/order/${props.assetOrder.orderId}`, {
          orderType: OrderType.OtherOrder,
        })
      }
    >
      <HStack style={{ gap: 6 }}>
        <Image
          src={[
            props.assetOrder.orderType === AssetOrderType.RedeemMembershipCard
              ? props.assetOrder?.nft?.container?.icon
              : needUserInfo
                ? props.assetOrder?.user?.icon
                : props.assetOrder?.adminUserInfo?.icon,
            { width: 20, height: 20 },
          ]}
          style={{
            borderRadius:
              props.assetOrder.orderType === AssetOrderType.RedeemMembershipCard
                ? 4
                : "50%",
          }}
          width={20}
          height={20}
        />
        <OrderSenderName>
          {props.assetOrder.orderType === AssetOrderType.RedeemMembershipCard
            ? props.assetOrder?.nft?.container?.name
            : needUserInfo
              ? props.assetOrder?.user?.nickname
              : props.assetOrder?.adminUserInfo?.nickname}
        </OrderSenderName>
        {useNameFirstStyle && getStatus().text.length > 0 && (
          <StatusBar
            statusColor={getStatus().color}
            statusText={getStatus().text}
          />
        )}
        {!useNameFirstStyle && (
          <OrderCreatedTime>
            {formatDateWithOptions(props.assetOrder?.createdTime || 0, {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })}
          </OrderCreatedTime>
        )}
      </HStack>
      <OrderDivider />
      <HStack style={{ gap: 12, alignItems: "start", width: "100%" }}>
        <ImageContainer>
          {props.assetOrder.orderType ===
          AssetOrderType.RedeemMembershipCard ? (
            <CircleCardsPreviewCell
              nft={props.assetOrder?.nft}
              width={64}
              cardStyle={CardStyle.small}
            />
          ) : (
            <Image
              src={getOrderImage()}
              style={{
                borderRadius:
                  props.assetOrder.orderType ===
                  AssetOrderType.RedeemMembershipCard
                    ? 2
                    : 6,
              }}
              width={
                props.assetOrder.orderType ===
                AssetOrderType.RedeemMembershipCard
                  ? 56
                  : useBigImage
                    ? 74
                    : 44
              }
              height={
                props.assetOrder.orderType ===
                AssetOrderType.RedeemMembershipCard
                  ? 31
                  : useBigImage
                    ? 74
                    : 44
              }
            />
          )}
        </ImageContainer>

        <VStack style={{ flex: 1 }}>
          {getDisplayingCurrency() && !useNameFirstStyle && (
            <HStack style={{ flex: 1, gap: 4, marginBottom: 6 }}>
              <Image
                src={getCurrencyIcon(getDisplayingCurrency())}
                width={16}
                height={16}
                style={{
                  borderRadius:
                    getDisplayingCurrency()?.currencyType ===
                    CurrencyType.SOL_TOKENS
                      ? 2
                      : "unset",
                }}
              />
              <HStack style={{ alignItems: "baseline", gap: 4 }}>
                <AmountText>
                  {formatMoney("medium", getDisplayingCurrency())}
                </AmountText>
                <AmountText>
                  {getCurrencyName(getDisplayingCurrency(), i18n)}
                </AmountText>
              </HStack>
            </HStack>
          )}

          {props.assetOrder.orderType === AssetOrderType.CreateSOLToken && (
            <TokenName>{props.assetOrder.tokenProject?.ticker}</TokenName>
          )}

          {props.assetOrder.orderType ===
            AssetOrderType.RedeemMembershipCard && (
            <TokenName>{props.assetOrder?.nft?.name}</TokenName>
          )}

          {props.assetOrder.orderType === AssetOrderType.ConvertGems && (
            <TokenName>{`${i18n.web3_v0_swap()} ${formatMoney("medium", gotCurrency)} Gems`}</TokenName>
          )}

          <OrderName
            style={{
              fontSize: useNameFirstStyle ? 14 : 11,
              fontWeight: useNameFirstStyle ? 700 : 500,
              color: useNameFirstStyle ? "white" : "#FFFFFFCC",
            }}
          >
            {getOrderName()}
          </OrderName>
          {getDisplayingCurrency() && useNameFirstStyle && (
            <HStack style={{ flex: 1, gap: 4, marginTop: 6 }}>
              <Image
                src={getCurrencyIconByCurrencyType(
                  getDisplayingCurrency()?.currencyType
                )}
                width={16}
                height={16}
              />
              <HStack style={{ alignItems: "baseline", gap: 4 }}>
                <AmountText>
                  {formatMoney("medium", getDisplayingCurrency())}
                </AmountText>
                <CountText>×{getItemCount()}</CountText>
              </HStack>
            </HStack>
          )}
        </VStack>
      </HStack>
      <HStack style={{ width: "100%" }}>
        {useNameFirstStyle && (
          <TotalLabel>{i18n.web3_mint_coupon_total_paid()}</TotalLabel>
        )}
        <Spring />
        {!useNameFirstStyle && getStatus().text.length > 0 && (
          <StatusBar
            statusColor={getStatus().color}
            statusText={getStatus().text}
          />
        )}
        {useNameFirstStyle && (
          <HStack style={{ gap: 4 }}>
            <Image
              src={getCurrencyIconByCurrencyType(
                getTotalCurrency()?.currencyType
              )}
              width={20}
              height={20}
            />
            <TotalCurrency>
              {formatMoney("medium", getTotalCurrency())}
            </TotalCurrency>
          </HStack>
        )}
      </HStack>
    </OrderCellContainer>
  );
}

const OrderName = styled.div`
  ${OverFlowTextStyle};
  flex: 1;
  line-clamp: 2;
  -webkit-line-clamp: 2;
`;

const AmountText = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: white;
`;

const TokenName = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  ${OverFlowTextStyle};
  line-clamp: 2;
  -webkit-line-clamp: 2;
  margin-bottom: 8px;
`;

function MintOrderCell(props: { assetOrder: AssetOrder }) {
  const i18n = useI18n();
  const hopper = useHopper();
  const backend = useBackend();
  const nativePage = useNativePage();

  const [status, setStatus] = useState(props.assetOrder.orderStatus);

  function getStatus() {
    switch (status) {
      case AssetOrderStatus.Done:
        return { text: i18n.web3_v0_completed(), color: "#00FF94" };
      case AssetOrderStatus.Pending:
      case AssetOrderStatus.ChainDone:
        return { text: i18n.web3_v0_pending(), color: "#FF7335" };
      case AssetOrderStatus.ToBeClaimed:
        return { text: i18n.web3_ugc_ready_to_claim(), color: "#FF7335" };
      case AssetOrderStatus.Failed:
        return { text: i18n.circle_anmt_failed(), color: "#EF3537FF" };
      case AssetOrderStatus.Rejected:
        return { text: i18n.circle_anmt_rejected(), color: "#EF3537FF" };
      case AssetOrderStatus.Canceled:
        return { text: i18n.circle_anmt_cancelled(), color: "#EF3537FF" };
      default:
        return { text: "", color: "FFFFFF66" };
    }
  }

  async function onClaimClick() {
    try {
      await backend.claimMintOrder(props.assetOrder.orderId).run();
      await nativePage.successHud(i18n.done());
      setStatus(AssetOrderStatus.Done);
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <OrderCellContainer
      onClick={() =>
        hopper.push(`wallet/order/${props.assetOrder.orderId}`, {
          orderType: OrderType.OtherOrder,
        })
      }
    >
      <HStack style={{ gap: 6 }}>
        <Image
          src={[
            props.assetOrder?.adminUserInfo?.icon,
            { width: 20, height: 20 },
          ]}
          style={{ borderRadius: "50%" }}
          width={20}
          height={20}
        />
        <OrderSenderName>
          {props.assetOrder?.adminUserInfo?.nickname}
        </OrderSenderName>
        {getStatus().text.length > 0 && (
          <StatusBar
            statusColor={getStatus().color}
            statusText={getStatus().text}
          />
        )}
      </HStack>
      <OrderDivider />
      <HStack style={{ gap: 12, alignItems: "start", width: "100%" }}>
        <ImageContainer>
          <Image src={icMint} width={44} height={44} />
        </ImageContainer>
        <VStack style={{ flex: 1 }}>
          <OrderNameText>
            {props.assetOrder.mintServiceInfo?.mintServiceName}
          </OrderNameText>
          <HStack style={{ flex: 1, marginTop: 8, gap: 4 }}>
            <Image
              src={[
                props.assetOrder.mintServiceInfo?.nft?.entity?.preview,
                { width: 20, height: 20 },
              ]}
              style={{ borderRadius: 2 }}
              width={20}
              height={20}
            />
            <CountText>
              ×{props.assetOrder.mintServiceInfo?.nftAmount}
            </CountText>
          </HStack>
        </VStack>
      </HStack>
      {status === AssetOrderStatus.ToBeClaimed && (
        <HStack style={{ width: "100%" }}>
          <ClaimHint>{i18n.web3_ugc_nft_minted_claim_prompt()}</ClaimHint>
          <RegularSmallButton
            $baseColor={"#0DFFCC"}
            style={{
              color: "#94FFDE",
              background: "#002B30B2",
              fontSize: 14,
              minWidth: 91,
            }}
            onClick={(event) => {
              event.stopPropagation();
              onClaimClick().catch(andLog);
            }}
          >
            {i18n.claim()}
          </RegularSmallButton>
        </HStack>
      )}
    </OrderCellContainer>
  );
}

const ClaimHint = styled.div`
  font-size: 11px;
  font-weight: 500;
  color: #ffffff99;
  flex: 1;
  word-break: break-all;
`;

function TradeOrderCell(props: { assetOrder: AssetOrder }) {
  const hopper = useHopper();
  const i18n = useI18n();
  const isBuying =
    props.assetOrder.dexSwapInfo?.tokenTransactionType ===
    TokenTransactionType.BUY;

  const gotCurrency = props.assetOrder.txList?.find(
    (tx) => tx.txCategory === TxCategory.GOT
  )?.currency;

  const sentCurrency = props.assetOrder.txList?.find(
    (tx) => tx.txCategory === TxCategory.SENT
  )?.currency;

  const orderName = useMemo(() => {
    if (isBuying) {
      return i18n.clover_trade_buy(
        `${formatMoney("medium", sentCurrency)} ${getCurrencyName(sentCurrency, i18n)}`,
        getCurrencyName(gotCurrency, i18n) || ""
      );
    } else {
      return i18n.clover_trade_sell(
        `${formatMoney("medium", gotCurrency)} ${getCurrencyName(gotCurrency)}`,
        getCurrencyName(sentCurrency, i18n) || ""
      );
    }
  }, []);

  function getStatus() {
    switch (props.assetOrder.orderStatus) {
      case AssetOrderStatus.Done:
        return { text: i18n.web3_v0_completed(), color: "#00FF94" };
      case AssetOrderStatus.Pending:
      case AssetOrderStatus.Submitted: {
        return { text: i18n.web3_v0_pending(), color: "#34A6FFFF" };
      }
      case AssetOrderStatus.Failed: {
        return { text: i18n.circle_anmt_failed(), color: "#EF3537FF" };
      }
      case AssetOrderStatus.Rejected: {
        return { text: i18n.circle_anmt_rejected(), color: "#EF3537FF" };
      }
      default:
        return { text: "", color: "#FFFFFF66" };
    }
  }

  return (
    <OrderCellContainer
      onClick={() =>
        hopper.push(`wallet/order/${props.assetOrder.orderId}`, {
          orderType: OrderType.OtherOrder,
        })
      }
    >
      <HStack style={{ gap: 6 }}>
        <HStack
          style={{ flex: 1, gap: 6 }}
          onClick={() => {
            if ((props.assetOrder?.user?.uid || 0) < 1000) {
              hopper.push(`user-official`);
            } else {
              hopper.push(`user/${props.assetOrder?.user?.uid}`);
            }
          }}
        >
          <Image
            src={[props.assetOrder?.user?.icon, { width: 20, height: 20 }]}
            style={{ borderRadius: "50%" }}
            width={20}
            height={20}
          />
          <OrderSenderName>{props.assetOrder?.user?.nickname}</OrderSenderName>
        </HStack>
        <OrderCreatedTime>
          {getOrderFormattedDateTime(props.assetOrder?.createdTime)}
        </OrderCreatedTime>
      </HStack>
      <OrderDivider />
      <HStack style={{ width: "100%", gap: 12, alignItems: "start" }}>
        <ImageContainer>
          <Image src={icTrade} style={{ width: 44, height: 44 }} />
        </ImageContainer>
        <VStack style={{ flex: 1 }}>
          <OrderName style={{ fontWeight: 700, fontSize: 16 }}>
            {orderName}
          </OrderName>
        </VStack>
      </HStack>
      <HStack style={{ width: "100%" }}>
        <Spring />
        <StatusBar
          statusColor={getStatus().color}
          statusText={getStatus().text}
        />
      </HStack>
    </OrderCellContainer>
  );
}

function GetMembershipCardCell(props: { assetOrder: AssetOrder }) {
  const hopper = useHopper();
  const i18n = useI18n();
  const isByStaking = props.assetOrder.currencyType === CurrencyType.SOL_TOKENS;

  const sentCurrency = props.assetOrder.txList?.find(
    (tx) => tx.txCategory === TxCategory.SENT
  )?.currency;

  const orderName = isByStaking
    ? i18n.clover_stake_to_get(
        sentCurrency?.symbol || "",
        props.assetOrder?.nft?.name || ""
      )
    : i18n.clover_circle_membership_card(props.assetOrder?.nft?.name || "");

  function getStatus() {
    switch (props.assetOrder.orderStatus) {
      case AssetOrderStatus.Done:
        return { text: i18n.web3_v0_completed(), color: "#00FF94" };
      case AssetOrderStatus.Pending:
      case AssetOrderStatus.Submitted: {
        return { text: i18n.web3_v0_pending(), color: "#34A6FFFF" };
      }
      case AssetOrderStatus.Failed: {
        return { text: i18n.circle_anmt_failed(), color: "#EF3537FF" };
      }
      case AssetOrderStatus.Rejected: {
        return { text: i18n.circle_anmt_rejected(), color: "#EF3537FF" };
      }
      default:
        return { text: "", color: "#FFFFFF66" };
    }
  }

  const totalPayCurrency = props.assetOrder.txList?.find(
    (tx) => tx.txCategory === TxCategory.SENT
  )?.currency;

  return (
    <OrderCellContainer
      onClick={() =>
        hopper.push(`wallet/order/${props.assetOrder.orderId}`, {
          orderType: OrderType.OtherOrder,
        })
      }
    >
      <HStack style={{ gap: 6 }}>
        <Image
          src={[
            props.assetOrder?.nft?.container?.icon,
            { width: 20, height: 20 },
          ]}
          style={{
            borderRadius: 4,
          }}
          width={20}
          height={20}
        />
        <OrderSenderName>
          {props.assetOrder?.nft?.container?.name}
        </OrderSenderName>

        {isByStaking ? (
          <OrderCreatedTime>
            {getOrderFormattedDateTime(props.assetOrder?.createdTime)}
          </OrderCreatedTime>
        ) : (
          <StatusBar
            statusColor={getStatus().color}
            statusText={getStatus().text}
          />
        )}
      </HStack>
      <OrderDivider />
      <HStack style={{ width: "100%", gap: 12, alignItems: "start" }}>
        <ImageContainer>
          <CircleCardsPreviewCell
            nft={props.assetOrder?.nft}
            cardStyle={CardStyle.small}
            width={64}
          />
        </ImageContainer>
        <VStack style={{ gap: 8, flex: 1 }}>
          <OrderName style={{ fontWeight: 700, fontSize: 16 }}>
            {orderName}
          </OrderName>
          {!isByStaking && (
            <HStack style={{ gap: 4 }}>
              <CurrencyImg
                src={getCurrencyIconByCurrencyType(
                  props.assetOrder.currencyType
                )}
              />
              <AmountText>
                {formatMoney(
                  "medium",
                  Object.assign({}, totalPayCurrency, {
                    amount: (
                      BigInt(totalPayCurrency?.amount || "0") /
                      BigInt(
                        props.assetOrder.purchaseMembershipCardInfo?.count || 1
                      )
                    ).toString(),
                  })
                )}
              </AmountText>
              <CountText>
                × {props.assetOrder.purchaseMembershipCardInfo?.count}
              </CountText>
            </HStack>
          )}
        </VStack>
      </HStack>
      <HStack style={{ width: "100%" }}>
        {!isByStaking && <TotalLabel>{i18n.web3_v0_total_amount()}</TotalLabel>}
        <Spring />
        {isByStaking ? (
          <StatusBar
            statusColor={getStatus().color}
            statusText={getStatus().text}
          />
        ) : (
          <HStack style={{ gap: 4 }}>
            <BigCurrencyImg src={getCurrencyIcon(totalPayCurrency)} />
            <AmountText>{formatMoney("medium", totalPayCurrency)}</AmountText>
          </HStack>
        )}
      </HStack>
    </OrderCellContainer>
  );
}

const CurrencyImg = styled.img`
  width: 16px;
  height: 16px;
`;

const BigCurrencyImg = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 2px;
`;
