import { CircleData } from "./ComposeCircleContext";
import { StateId } from "../../../hooks/StateId";
import { useLocalRepo } from "../../../hooks/swr/useLocalRepo";
import { useLayerId } from "../../../appshell/LayerBoundary";

export function useCreateCircleResult() {
  const layerId = useLayerId();
  return useLocalRepo<CircleData>(["CreateCircleResult", layerId], {
    name: undefined,
    tagline: undefined,
    circleIcon: undefined,
    cover: undefined,
    circleBackground: undefined,
    themeColor: "#6068FF",
    privacy: 1,
    discoverabilityType: 1,
    acceptJoinRequests: true,
    question: undefined,
    joinPasscode: undefined,
    passcodeEnable: false,
    allowMembersInvite: undefined,
    viewPagerIndex: 0,
    language: "en",
    tags: [],
  });
}

export function useCircleHasAdminReminder(sceneId: StateId) {
  return useLocalRepo<boolean>(["CircleHasAdminReminder", sceneId], false);
}
