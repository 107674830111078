import { z } from "zod";
import { zBigInt, zStatic } from "../utils/zodUtils";
import { Currency } from "./Currency";

export const AccountCurrency = z.object({
  accountId: zBigInt.default(0),
  currencyType: z.number(),
  currencyId: zBigInt.default(0),
  availableCurrency: Currency.optional(),
  balance: Currency.optional(),
});

export type AccountCurrency = zStatic<typeof AccountCurrency>;
