import { useSWR } from "../../hooks/swr/useSWR";
import { useBroadcast } from "../../hooks/useBroadcast";
import { andLog } from "../../components/handleError";
import { useBackend } from "../../service/APIService";

export function useWalletSWR() {
  const backend = useBackend();
  const walletSWR = useSWR(backend.getWallet());

  useBroadcast(
    "ON_NEED_REFRESH_WALLET",
    () => {
      walletSWR.load().catch(andLog);
    },
    [walletSWR],
  );

  return [walletSWR.content, walletSWR] as const;
}
