import React, { useMemo } from "react";
import { Page } from "../../../components/Page";
import { NavMiddle } from "../../../components/NavBar";
import { useI18n } from "../../../hooks/useI18n";
import styled, { css } from "styled-components";
import { HStack, VSpace } from "../../../components/VStack";
import { RegularInputGroup } from "../../../components/Input";
import { CircleInfoMaxLength } from "../../circle/compose/EditCirclePage";
import { PageFooter } from "../../../components/PageHeaderFooter";
import { RowCenterButton } from "../../../components/CommonStyles";
import { RegularButton } from "../../../components/Buttons";
import {
  CurrencyType,
  formatMoney,
  getCurrencyAmount,
  getCurrencyName,
} from "../../../proto/Currency";
import { useBackend } from "../../../service/APIService";
import { useSWR } from "../../../hooks/swr/useSWR";
import { ChainType, getRuntimeChainType } from "../../../proto/ChainType";
import {
  useBigIntParam,
  useBigIntSearchParam,
} from "../../../hooks/useTypedParam";
import { TransactionBody } from "../payment/TransactionBody";
import { useHopper } from "../../../hooks/useHopper";
import { useEditingBigNumber } from "../../../hooks/useEditingBigNumber";
import { jsonifyBigNumber } from "../../../utils/CurrencyAmount";
import BigNumber from "bignumber.js";
import { Image } from "../../../components/Image";
import { useNativePage } from "../../../hooks/useBridge";
import { andLog } from "../../../components/handleError";
import { useMemoryRepo } from "../../../hooks/swr/useLocalRepo";
import { useLayerId } from "../../../appshell/LayerBoundary";
import { ZeroSOL } from "../swap/SwapPage";

function SendTokenPage() {
  const i18n = useI18n();

  const walletAccountId = useBigIntParam("walletAccountId");
  const assetAccountId = useBigIntParam("assetAccountId");

  const backend = useBackend();
  const walletSWR = useSWR(backend.getWallet());
  const layerId = useLayerId();
  const editingBody = useTransactionBody();

  const targetAssetAccount = useMemo(() => {
    return walletSWR.content?.walletAccountList
      ?.find(
        (walletAccount) => walletAccount.walletAccountId === walletAccountId,
      )
      ?.assetAccountList?.find(
        (assetAccount) => assetAccount.accountId === assetAccountId,
      );
  }, [walletSWR.content]);

  const hopper = useHopper();
  const [amountInput, setAmountInput] = useEditingBigNumber(`send-token`);

  const currencyId = useBigIntSearchParam("currencyId");

  const toChainAddress = useMemo(() => {
    return editingBody.content?.toChainAddress;
  }, [editingBody.content]);

  const findMaxAmount = () => {
    if (sendTargetCurrency) {
      setAmountInput(String(getCurrencyAmount(sendTargetCurrency)));
    }
  };

  const currencyListSWR = useSWR(backend.getCurrencyList(walletAccountId));
  const sendTargetCurrency = useMemo(() => {
    return currencyListSWR.content?.list?.find(
      (item) => String(item.currency.currencyId) === String(currencyId),
    )?.currency;
  }, [currencyListSWR.content, currencyId]);

  const hasAddress = (toChainAddress ?? "").length > 0;
  const hasAmount = (amountInput ?? "").length > 0;

  const nativePage = useNativePage();
  const next = async () => {
    if (!hasAddress) {
      nativePage.infoHud(i18n.arthur_enter_valid_address()).catch(andLog);
      return;
    }

    if (!isValidSolanaAddress(toChainAddress ?? "")) {
      nativePage.infoHud(i18n.arthur_enter_valid_address()).catch(andLog);
      return;
    }

    if (toChainAddress === targetAssetAccount?.address) {
      nativePage.infoHud(i18n.clover_cant_send_to_yourself()).catch(andLog);
      return;
    }

    if (!hasAmount) {
      nativePage.infoHud(i18n.mars_enter_valid_amound()).catch(andLog);
      return;
    }

    if (!sendTargetCurrency) {
      return;
    }

    const serverAmount = new BigNumber(
      jsonifyBigNumber(
        BigNumber(amountInput ?? "0"),
        sendTargetCurrency?.decimals ?? 9,
      ),
    );
    const balance = new BigNumber(sendTargetCurrency.amount);
    if (serverAmount.isGreaterThan(balance)) {
      nativePage.infoHud(i18n.clover_dont_have_enough_balance()).catch(andLog);
      return;
    }

    await editingBody
      .fill((prevData) => ({
        ...prevData,
        toChainAddress: toChainAddress,
        amount: jsonifyBigNumber(
          BigNumber(amountInput ?? "0"),
          sendTargetCurrency?.decimals ?? 9,
        ),
        currencyId: sendTargetCurrency.currencyId,
        currencyType: sendTargetCurrency.currencyType,
        currency: sendTargetCurrency,
        decimals: sendTargetCurrency.decimals ?? 9,
        gasFeeCurrency: undefined,
        gasFeeCurrencyType: undefined,
        gasFeeAmount: undefined,
        gasFeeCurrencyId: undefined,
      }))
      .catch(andLog);
    hopper.push(
      `wallet/${walletAccountId}/assetAccount/${assetAccountId}/send-token/summary`,
    );
  };

  return (
    <Page pageData={currencyListSWR}>
      <NavMiddle>{i18n.send()}</NavMiddle>
      <SectionTitle>{i18n.send_to_no_colon()}</SectionTitle>
      <RegularInputGroup
        placeholder={i18n.web3_v0_address()}
        autoComplete={"off"}
        value={toChainAddress}
        updateValue={(newValue) => {
          editingBody
            .fill((prevData) => ({
              ...prevData,
              toChainAddress: newValue,
            }))
            .catch(andLog);
        }}
        maxLength={CircleInfoMaxLength.Name}
      />
      <VSpace height={32} />
      <SectionTitle>{i18n.web3_v0_amount()}</SectionTitle>
      <FrameLayout>
        <RegularInputGroup
          placeholder={i18n.web3_v0_amount()}
          autoComplete={"off"}
          type={"number"}
          value={amountInput}
          style={css`
            width: 100%;
          `}
          updateValue={setAmountInput}
        />

        <HStack
          style={{
            marginTop: 12,
            justifyContent: "center",
            right: 12,
            position: "absolute",
          }}
        >
          <TickerName>{getCurrencyName(sendTargetCurrency, i18n)}</TickerName>
          <VerticalDivider />
          <MaxButton onClick={findMaxAmount}>{i18n.web3_v0_max()}</MaxButton>
        </HStack>
      </FrameLayout>

      {sendTargetCurrency && (
        <HStack style={{ marginTop: 10, gap: 5 }}>
          <AvailableLabel>{i18n.web3_v0_available()}</AvailableLabel>
          <Image
            src={[sendTargetCurrency.icon, "smallest"]}
            width={16}
            height={16}
          />
          <AvailableCurrency>
            {formatMoney("medium", sendTargetCurrency)}
          </AvailableCurrency>
          <AvailableCurrency>
            {getCurrencyName(sendTargetCurrency, i18n)}
          </AvailableCurrency>
        </HStack>
      )}

      <PageFooter obscuredZoneKey={"SendNext"}>
        <RegularButton
          $baseBgColor={"var(--color-bg)"}
          disabled={!Boolean(sendTargetCurrency)}
          onClick={next}
          style={{
            ...RowCenterButton,
            marginTop: 8,
          }}
        >
          {i18n.next()}
        </RegularButton>
      </PageFooter>
    </Page>
  );
}

const FrameLayout = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const VerticalDivider = styled.div`
  height: 12px;
  margin-inline-start: 8px;
  margin-inline-end: 8px;
  width: 1px;
  vertical-align: 2px;
  background-color: rgba(255, 255, 255, 0.2);
`;

const MaxButton = styled.div`
  color: #00c4ff;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`;

const TickerName = styled.div`
  color: var(--color-text10);
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
`;

export function isValidSolanaAddress(address: string): boolean {
  if (address.length < 32 || address.length > 44) {
    return false;
  }

  const base58Chars =
    "123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz";

  for (const char of address) {
    if (!base58Chars.includes(char)) {
      return false;
    }
  }

  return true;
}

const AvailableCurrency = styled.div`
  color: var(--color-text00);
  font-size: 12px;
  font-weight: 500;
`;

const AvailableLabel = styled.div`
  color: var(--color-text20);
  font-size: 12px;
  margin-inline-end: 5px;
  font-weight: 400;
`;

export function useTransactionBody(
  partialData: Partial<TransactionBody> = {},
  id?: string,
) {
  const layerId = useLayerId();
  const dataId = id || layerId;

  const defaultValues: TransactionBody = {
    currencyType: CurrencyType.SOL,
    chainType: getRuntimeChainType(),
    currency: ZeroSOL,
    currencyId: BigInt(5),
    toChainAddress: "",
    amount: "0",
    decimals: 9,
  };

  const mergedData = { ...defaultValues, ...partialData };

  return useMemoryRepo<TransactionBody>(
    ["TransactionBody", dataId],
    mergedData,
  );
}

const SectionTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: var(--color-text10);
`;

export default SendTokenPage;
