import { CircleFolderItem } from "./CircleFolderItem";
import styled from "styled-components";
import { SingleLineUnspecifiedWidth } from "../components/CommonViews";
import { Image } from "../components/Image";
import { Media } from "./Media";

export interface CircleMenuIconCellProps {
  themeColor?: string;
  folderItem: CircleFolderItem;
}

function CircleMenuIconCell(props: CircleMenuIconCellProps) {
  const firstCharacter = props.folderItem.objectName?.charAt(0) ?? ""; // 获取 name 的第一个字符
  const isIconExist = Boolean(props.folderItem?.folder?.icon);
  return (
    <IconContainer
      themeColor={props.themeColor}
      showBefore={!isIconExist}
      showAfter={!isIconExist}
    >
      {isIconExist ? (
        <Image
          src={[props.folderItem?.folder?.icon, "smallest"]}
          style={{ width: "100%", height: "100%", borderRadius: 6 }}
        />
      ) : (
        <NameCharacter themeColor={props.themeColor}>
          {firstCharacter}
        </NameCharacter>
      )}
    </IconContainer>
  );
}

export function CircleMenuIconView(props: {
  themeColor?: string;
  icon?: Media;
  title?: string;
}) {
  const firstCharacter = props.title?.charAt(0) ?? ""; // 获取 name 的第一个字符
  const isIconExist = Boolean(props.icon);
  return (
    <IconContainer
      themeColor={props.themeColor}
      showBefore={!isIconExist}
      showAfter={!isIconExist}
    >
      {isIconExist ? (
        <Image
          src={[props.icon, "smallest"]}
          style={{ width: "100%", height: "100%", borderRadius: 6 }}
        />
      ) : (
        <NameCharacter themeColor={props.themeColor}>
          {firstCharacter}
        </NameCharacter>
      )}
    </IconContainer>
  );
}

const IconContainer = styled.div<{
  themeColor?: string;
  showBefore?: boolean;
  showAfter?: boolean;
}>`
  width: 38px;
  height: 38px;
  border-radius: 6px;
  box-sizing: border-box;
  border: 1px solid ${({ themeColor }) => themeColor || "white"};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative; // 使伪元素相对定位

  // 添加背景色层
  &::before {
    content: ${({ showBefore }) => (showBefore ? '""' : "none")};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${({ themeColor }) =>
      themeColor || "white"}; // 底层背景色
    border-radius: 6px; // 确保边角圆润
    z-index: 1; // 低于 NameCharacter
  }

  // 添加黑色覆盖层
  &::after {
    content: ${({ showAfter }) => (showAfter ? '""' : "none")};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 6px; // 确保边角圆润
    z-index: 2; // 低于 NameCharacter
  }
`;

// 辅助函数：将 HEX 颜色转换为 RGB
const hexToRgb = (hex: string): string => {
  const bigint = parseInt(hex.replace(/^#/, ""), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return `${r}, ${g}, ${b}`;
};

const NameCharacter = styled.div<{ themeColor?: string }>`
  color: ${({ themeColor }) => themeColor || "white"}; // 默认颜色
  text-shadow: ${({ themeColor }) =>
    `0 0 10px ${themeColor ? `rgba(${hexToRgb(themeColor)}, 0.6)` : "rgba(255, 255, 255, 0.6)"}`}; // 默认颜色
  z-index: 5;
  font-weight: 500;
  font-size: 18px;
`;

export default CircleMenuIconCell;
