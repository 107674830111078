// Function to copy text to clipboard

import { useGlobalSpinner } from "./globalSpinner";
import { useNativePage } from "../hooks/useBridge";
import { useI18n } from "../hooks/useI18n";
import { useCopyToClipboard } from "react-use";

export function useCopyText() {
  const globalSpinner = useGlobalSpinner();
  const nativePage = useNativePage();
  const i18n = useI18n();
  const [_, doCopy] = useCopyToClipboard();

  return async function (text: string, successTip: string = i18n.copied()) {
    await globalSpinner(async () => {
      doCopy(text);
      await nativePage.successHud(successTip);
    });
  };
}

export async function copyTextToClipboard(text: string) {
  if (navigator.clipboard) {
    await navigator.clipboard.writeText(text);
  } else {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    const successful = document.execCommand("copy");
    const msg = successful ? "successful" : "unsuccessful";
    document.body.removeChild(textArea);
  }
}
