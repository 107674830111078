import { range } from "../../utils/List";
import { useSWR } from "../../hooks/swr/useSWR";
import { Page } from "../../components/Page";
import { DemoCell } from "./DemoCell";
import { useWebHost } from "../../hooks/useBridge";
import { useHopper } from "../../hooks/useHopper";

function getData() {
  return new Promise<string[]>((resolve, reject) => {
    setTimeout(() => {
      const data = range(0, 100).map((i) => `data.${i}`);
      resolve(data);
    }, 500);
  });
}

export function SimpleDataDemoPage() {
  const dataSWR = useSWR(["SimpleDataDemoPage"], getData);
  const hopper = useHopper();
  return (
    <Page pageData={dataSWR}>
      {dataSWR.content?.map((d) => (
        <DemoCell
          key={d}
          onClick={() => {
            hopper.push("notfound");
          }}
        >
          {d}
        </DemoCell>
      ))}
    </Page>
  );
}
