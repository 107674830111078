import { Page } from "../../../components/Page";
import { NavMiddle } from "../../../components/NavBar";
import { useI18n } from "../../../hooks/useI18n";
import { HStack, VStack } from "../../../components/VStack";
import styled from "styled-components";
import { CircleMembershipLevelIcon } from "../membership/MembershipLevelPicker";
import React, { useMemo } from "react";
import { CircleMembershipCard } from "./CircleMembershipCard";
import { RegularButton } from "../../../components/Buttons";
import iconMore from "../../../res/images/icon_more.svg";
import GridLayout from "../../../components/GridLayout";
import { useCircleSWR } from "../useCircleSWR";
import { useMyUid } from "../../../service/AuthSessionService";
import { useSWR } from "../../../hooks/swr/useSWR";
import { useBackend } from "../../../service/APIService";
import { useSWRArray } from "../../../hooks/swr/useSWRArray";
import { useHopper } from "../../../hooks/useHopper";
import MercuryMenu from "../../../components/MercuryMenu";
import { DefaultEmptyView } from "../../../components/LoadStateView";
import CircleCardsPreviewCell, { CardStyle } from "./CircleCardsPreviewCell";

export function MyCardPage() {
  // const circleId = useBigIntParam("circleId");
  const [circleId, circleSWR] = useCircleSWR();
  const myId = useMyUid();
  const i18n = useI18n();
  const backend = useBackend();
  const userSWR = useSWR(backend.getUser(myId));
  const nftListSWR = useSWRArray(backend.getNFTList(108, circleId));
  const hopper = useHopper();
  const isAdmin = useMemo(() => {
    return circleSWR.content?.adminIdList.includes(myId);
  }, [circleSWR, myId]);

  const hasToken = useMemo(() => {
    return (
      !!circleSWR.content?.tokenProjectId &&
      circleSWR.content.tokenProjectId > BigInt(0)
    );
  }, [circleSWR]);
  const goCardShop = () => {
    const circle = circleSWR.content;
    if (circle?.tokenProjectId && circle.tokenProjectId > BigInt(0)) {
      hopper.push(`circle/${circleId}/card-picker`);
    }
  };
  const getOptions = () => {
    const downGrade = {
      title: i18n.clover_downgrade(),
      type: "normal",
      onClick: () => {
        hopper.push(`circle/${circleId}/card-picker`);
      },
    };
    const cancel = {
      title: i18n.cancel(),
      type: "normal",
      onClick: () => {},
    };
    return [downGrade, cancel];
  };
  return (
    <Page alwaysShowsContent pageData={[circleSWR, userSWR, nftListSWR]}>
      <HStack style={{ justifyContent: "space-between" }}>
        <LevelTitle>{i18n.clover_current_membership_level()}</LevelTitle>
        {!(!hasToken && isAdmin) && (
          <CircleMembershipLevelIcon
            level={
              hasToken && isAdmin
                ? 999
                : circleSWR.content?.currentMemberInfo?.membershipLevel ?? 0
            }
            width={44}
          />
        )}
      </HStack>
      <CircleMembershipCard
        circle={circleSWR.content}
        user={userSWR.content}
        titles={circleSWR.content?.currentMemberInfo?.memberTitleList}
        level={circleSWR.content?.currentMemberInfo?.membershipLevel}
        background={
          circleSWR.content?.currentMemberInfo?.circleMembershipCard?.background
        }
      />
      {isAdmin || !hasToken ? null : (
        <ActionContainer>
          <RegularButton style={{ height: 48, flex: 1 }} onClick={goCardShop}>
            {i18n.nov_impr_button_upgrade()}
          </RegularButton>
          <MercuryMenu options={getOptions()}>
            <MoreIcon />
          </MercuryMenu>
        </ActionContainer>
      )}

      <SectionTitle>{i18n.clover_all_owned_membership_cards()}</SectionTitle>
      {nftListSWR.content.length > 0 ? (
        <GridLayout
          spanCount={3}
          items={nftListSWR.content.map((nft) => (
            <CardCellFrame>
              <CircleCardsPreviewCell
                card={nft.circleMembershipCard}
                circle={circleSWR.content}
                cardStyle={CardStyle.medium}
                width={`calc(100% - 6px)`}
                onClick={() => {
                  hopper.push(`nft-circle-card/${nft.nftId}`);
                }}
              />
            </CardCellFrame>
          ))}
        />
      ) : (
        <VStack>
          <DefaultEmptyView
            title={
              hasToken
                ? i18n.clover_no_membership_card_yet()
                : i18n.clover_cards_purchasable_hint()
            }
          />
          {isAdmin && !hasToken ? (
            <UnderLineText
              onClick={() => {
                hopper.push(`circle/${circleId}/admin-portal`);
              }}
            >
              {i18n.clover_go_to_admin_portal()}
            </UnderLineText>
          ) : null}
        </VStack>
      )}

      {hasToken ? (
        <RegularButton
          style={{ margin: "29px 44px 0" }}
          onClick={() => {
            hopper.push(`circle/${circleId}/card-picker`);
          }}
        >
          {i18n.aigc_quota_get_more()}
        </RegularButton>
      ) : null}

      <NavMiddle>{i18n.circle_fin_membership()}</NavMiddle>
    </Page>
  );
}

export const UnderLineText = styled.div`
  color: #34a6ff;
  font-size: 14px;
  text-decoration: underline;
  text-align: center;
`;

const CardCellFrame = styled.div`
  aspect-ratio: 1;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding-left: 6px;
  padding-right: 6px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const SectionTitle = styled.div`
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  padding: 9.5px 20px;
  background: rgba(255, 255, 255, 0.08);
  margin: 0 var(--page-h-inset-neg);
`;

const MoreIcon = styled.div`
  width: 48px;
  height: 48px;
  background-image: url("${iconMore}");
  background-size: 100%;
`;

const ActionContainer = styled.div`
  display: flex;
  margin: 0 15px 33px;
  width: calc(100% - 30px);
  gap: 12px;
`;

const LevelTitle = styled.div`
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 500;
`;
