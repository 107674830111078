import {
  BottomSheet,
  CenterModal,
  ModalTitle,
  useModal,
  useModalWithItsOwnPage,
} from "../../../components/Modal";
import { useHopper } from "../../../hooks/useHopper";
import { useObscuredZoneForKey } from "../../../hooks/useObscuredZones";
import {
  useBigIntParam,
  useBooleanSearchParam,
} from "../../../hooks/useTypedParam";
import { CircleMembershipCard } from "./CircleMembershipCard";
import { useBackend } from "../../../service/APIService";
import { useSWR } from "../../../hooks/swr/useSWR";
import { HStack, VStack } from "../../../components/VStack";
import { NavMiddle } from "../../../components/NavBar";
import { useI18n } from "../../../hooks/useI18n";
import React, { useMemo, useRef } from "react";
import menu from "../../../res/images/ic_card_more.svg";
import { removeOptional } from "../../../utils/typeUtils";
import { AbsImage } from "../../../components/AbsImage";
import MercuryMenu from "../../../components/MercuryMenu";
import { andLog } from "../../../components/handleError";
import { useMyUid } from "../../../service/AuthSessionService";
import styled from "styled-components";
import { CheckBox } from "../../user/UserProfilePage";
import { useHopState } from "../../../hooks/useHopState";
import { CheckBoxLayout } from "./CheckBoxLayout";
import { useGlobalSpinner } from "../../../utils/globalSpinner";
import { ObjectType } from "../../../proto/ObjectSpec";
import { useWebHost } from "../../../hooks/useBridge";

export function CircleCardBottomSheetPage() {
  const bottomSheet = useModalWithItsOwnPage();
  const hopper = useHopper();
  const webHost = useWebHost();
  const fromProfile = useBooleanSearchParam("fromProfile", false);
  const [obscuredZone] = useObscuredZoneForKey("Browser", "root");
  const circleId = useBigIntParam("circleId");
  const uid = useBigIntParam("uid");
  const i18n = useI18n();
  const backend = useBackend();
  const circleSWR = useSWR(backend.getCircle(circleId));
  const userSWR = useSWR(backend.getUser(uid));
  const myId = useMyUid();
  const isSelf = myId === uid;
  const modal = useModal("modal");
  const isAdmin = useMemo(() => {
    return circleSWR.content?.adminIdList?.includes(myId);
  }, [circleSWR.content, uid]);
  const globalSpinner = useGlobalSpinner();
  const circleMemberCardSWR = useSWR(
    backend.getCircleMemberCard(circleId, uid),
  );

  const getOptions = () => {
    const flagOp = {
      title: i18n.flag(),
      type: "normal",
      onClick: () => {
        hopper.push(`flag/${uid}/${ObjectType.USER}`);
      },
    };
    const rmOp = {
      title: i18n.action_remove_from_circle(),
      type: "normal",
      onClick: () => {
        modal.open();
      },
    };
    const manageOp = {
      title: i18n.member_title_manage_my_titles(),
      type: "normal",
      onClick: () => {
        hopper.push(`circle/${circleId}/manage-my-titles`);
      },
    };
    if (isSelf) {
      return [manageOp];
    } else {
      if (isAdmin) {
        return [rmOp, flagOp];
      } else {
        return [flagOp];
      }
    }
  };
  const onDismiss = () => {
    modal.close();
  };
  return (
    <>
      <BottomSheet modal={bottomSheet}>
        <ModalTitle>{i18n.member_title_circle_membership()}</ModalTitle>
        <VStack style={{ padding: `20px 20px ${40 + obscuredZone.bottom}px` }}>
          <CircleMembershipCard
            hopper={hopper}
            circle={circleSWR.content}
            user={userSWR.content}
            level={
              circleMemberCardSWR.content?.circleMembershipCard?.membershipLevel
            }
            titles={circleMemberCardSWR.content?.memberTitleList}
            background={
              circleMemberCardSWR.content?.circleMembershipCard?.background
            }
          />
        </VStack>
        {!fromProfile && (
          <MercuryMenu options={getOptions()}>
            <AbsImage src={menu} style={{ top: 20, right: 20 }} />
          </MercuryMenu>
        )}
      </BottomSheet>

      <CenterModal modal={modal}>
        <MyTitle>{i18n.block_from_circle_confirm()}</MyTitle>
        <CheckBoxLayout
          circleId={circleId}
          user={uid}
          onDismiss={onDismiss}
          webHost={webHost}
        />
      </CenterModal>
    </>
  );
}

const CheckTitle = styled.div`
  flex: 1;
  color: white;
`;

const MyTitle = styled.div`
  color: #ffffff;
  font-size: 14px;
  text-align: center;
`;
