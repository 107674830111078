import { Page } from "../../../components/Page";
import { useI18n } from "../../../hooks/useI18n";
import { useBackend } from "../../../service/APIService";
import {
  useBigIntParam,
  useEnumSearchParam,
} from "../../../hooks/useTypedParam";
import { copyObject } from "../../../utils/copyObject";
import { Currency, CurrencyType, formatMoney } from "../../../proto/Currency";
import { useTransactionBody } from "../../wallet/send/SendTokenPage";
import { useSWR } from "../../../hooks/swr/useSWR";
import { useNativePage } from "../../../hooks/useBridge";
import { useHopper } from "../../../hooks/useHopper";
import { closeModal, useModal } from "../../../components/Modal";
import { useRegularAccount } from "../../wallet/useRegularAccount";
import { useAssetAccount } from "../../wallet/useAssetAccount";
import { NavMiddle } from "../../../components/NavBar";
import { ContentGroup } from "../../../components/ContentGroup";
import { HStack, VSpace, VStack } from "../../../components/VStack";
import { Image } from "../../../components/Image";
import PaymentServiceFee, {
  DEFAULT_PRIORITY_FEE,
  DEFAULT_SLIPPAGE,
} from "../../wallet/payment/PaymentServiceFee";
import { GasFeeTxType } from "../../../proto/Wallet";
import PaymentAccount from "../../wallet/payment/PaymentAccount";
import PaymentFooter from "../../wallet/payment/PaymentFooter";
import { TxSheet, usePerformTx } from "../../wallet/payment/TransactionDialog";
import { andLog } from "../../../components/handleError";
import React, { useEffect, useMemo } from "react";
import styled, { css } from "styled-components";
import { PowerMarkdown } from "../../../components/PowerMarkdown";
import { SpeedTitle } from "../../../components/SpeedTitle";
import { isValidCircleToken } from "../../../proto/CircleFin";
import { useHopState } from "../../../hooks/useHopState";
import CircleCardsPreviewCell, { CardStyle } from "./CircleCardsPreviewCell";
import { OrderType } from "../../wallet/order/OrderDetailPage";
import { ZeroSOL } from "../../wallet/swap/SwapPage";
import BigNumber from "bignumber.js";
import StakeNoticeDialog from "./StakeNoticeDialog";
import CurrencyTransactionView from "../../wallet/payment/CurrencyTransactionView";
import { TransactionBody } from "../../wallet/payment/TransactionBody";
import { buyTokenAAAA } from "../../wallet/swap/TradeGroup";
import { useMergedCurrency } from "../../wallet/swap/useMergedCurrency";

export function AdminCardStakePage() {
  const i18n = useI18n();
  const backend = useBackend();
  const circleId = useBigIntParam("circleId");
  const tokenSWR = useSWR(backend.getCircleToken(circleId));
  const regularAccount = useRegularAccount();
  const walletAccountId = regularAccount?.walletAccountId;
  const assetAccount = useAssetAccount();
  const currencyListSWR = useSWR(
    walletAccountId ? backend.getCurrencyList(walletAccountId) : undefined,
  );
  const currencyType = useEnumSearchParam("currencyType");
  const adminCardSWR = useSWR(backend.getAdminMembershipCard(circleId));
  const unitPriceCurrency = useMemo(() => {
    return adminCardSWR?.content?.circleMembershipCard?.currency;
  }, [adminCardSWR?.content]);

  const [isUnitPriceFilled, setUnitPriceFilled] = useHopState(
    "unitPriceFilled",
    false,
  );
  const agreementModal = useModal("agreement-modal");

  const editingBody = useTransactionBody();

  useEffect(() => {
    if (unitPriceCurrency && !isUnitPriceFilled && editingBody.content) {
      setUnitPriceFilled(true);
      editingBody
        .fill((prevData) => ({
          ...prevData,
          cardCount: 1,
          currency: unitPriceCurrency,
          decimals: unitPriceCurrency.decimals,
          CFM135B: true,
          slippage: DEFAULT_SLIPPAGE,
          priorityFee: DEFAULT_PRIORITY_FEE,
          currencyId: unitPriceCurrency.currencyId,
          currencyType:
            unitPriceCurrency.currencyType ?? CurrencyType.CIRCLE_POINT,
          amount: unitPriceCurrency.amount,
        }))
        .catch(andLog);
    }
  }, [isUnitPriceFilled, editingBody.content, unitPriceCurrency]);
  const circleSWR = useSWR(backend.getCircle(circleId));
  const nativePage = useNativePage();
  const hopper = useHopper();
  const paymentModal = useModal("payment-modal");

  const assetAccountId = assetAccount?.accountId ?? BigInt(0);

  const paymentTargetCurrency = useMemo(() => {
    const token = tokenSWR.content;
    if (isValidCircleToken(token)) {
      return currencyListSWR?.content?.list
        ?.find((item) => item.currency.currencyId === token.projectId)
        ?.accountCurrencyList?.at(0)?.balance;
    }
  }, [currencyListSWR?.content, tokenSWR.content]);

  const tokenProjectId = circleSWR.content?.tokenProjectId;

  const tokenPoolSWR = useSWR(
    tokenProjectId ? backend.getTokenPoolNoRun(tokenProjectId) : undefined,
  );

  const payWithSol = useMemo(() => {
    const pool = tokenPoolSWR?.content;
    if (pool && unitPriceCurrency) {
      const amount = BigNumber(unitPriceCurrency.amount ?? "0");
      const solAmount = buyTokenAAAA(pool, amount.toFixed(0));
      return copyObject<Currency>(ZeroSOL, {
        amount: solAmount.toFixed(0),
      });
    }
  }, [tokenPoolSWR?.content, unitPriceCurrency]);

  const paymentCheck = async () => {
    // if (
    //   BigNumber(editingBody.content.amount ?? "0").isGreaterThan(
    //     BigNumber(paymentTargetCurrency?.amount ?? "0"),
    //   )
    // ) {
    //   nativePage.infoHud(i18n.clover_dont_have_enough_balance()).catch(andLog);
    //   return false;
    // }
    //
    // const token = tokenSWR.content;
    // if (isValidCircleToken(token)) {
    //   await editingBody.fill((prevData) => ({
    //     ...prevData,
    //     currencyId: token.projectId,
    //     currencyType: CurrencyType.SOL_TOKENS,
    //   }));
    // }

    agreementModal.open();
    return false;
  };
  const gemsAssetAccount = useAssetAccount(false);
  const gemsAssetAccountId = gemsAssetAccount?.accountId ?? BigInt(0);

  const payAccountId =
    editingBody.content.payWithGems ||
    editingBody.content.currency?.currencyType === CurrencyType.GEMS
      ? gemsAssetAccountId
      : assetAccountId;

  const performTx = usePerformTx(
    async () => {
      const body = editingBody.content;
      const paymentCurrency = body.payWithGems ?? body.currency;
      const gasFeeCurrency = body.payWithGems
        ? body.gemsGasFeeCurrency
        : body.gasFeeCurrency;
      const updatedBody = copyObject<TransactionBody>(body, {
        cardId: adminCardSWR.content?.circleMembershipCard?.cardId,
        payAssetAccountId: payAccountId,
        fromAccountId: payAccountId,
        fromAssetAccountId: payAccountId,
        gasFeeAmount: gasFeeCurrency?.amount,
        gasFeeCurrencyId: gasFeeCurrency?.currencyId,
        gasFeeCurrencyType: gasFeeCurrency?.currencyType,
        currency: undefined,
        gemsGasFeeCurrency: undefined,
        payWithGems: undefined,
        payWithSOL: undefined,
        gasFeeCurrency: undefined,
      });
      const resp = await backend.performPurchaseCircleCard(updatedBody).run();
      return resp.orderId;
    },
    async (orderId: bigint | undefined) => {
      await closeModal(paymentModal);
      await editingBody.clear();
      if (orderId) {
        hopper.dismissLayerAndPush(`wallet/order/${orderId}`, {
          orderType: OrderType.OtherOrder,
          showDialog: true,
          title: i18n.clover_stake_successful(),
        });
      }
    },
  );

  return (
    <Page pageData={[circleSWR, adminCardSWR, currencyListSWR, tokenPoolSWR]}>
      <NavMiddle>{i18n.clover_staking_order()}</NavMiddle>
      <ContentGroup>
        <VStack style={{ alignItems: "center", gap: 8 }}>
          {adminCardSWR.content?.circleMembershipCard && (
            <CircleCardsPreviewCell
              width={180}
              card={adminCardSWR.content?.circleMembershipCard}
              cardStyle={CardStyle.large}
              circle={circleSWR.content}
            />
          )}

          <CardName>{i18n.clover_admin_membership_card()}</CardName>

          <HStack style={{ gap: 6 }}>
            <TokenAmount>{`${i18n.clover_stake_amount()}: `}</TokenAmount>
            <Image
              src={[circleSWR.content?.tokenProject?.image, "smallest"]}
              style={{ borderRadius: 2 }}
              width={16}
              height={16}
            />
            <TokenAmount>
              {`${formatMoney(
                "medium",
                BigNumber(
                  circleSWR.content?.tokenProject?.totalSupply ?? "0",
                ).div(BigNumber("100")),
              )} ${circleSWR.content?.tokenProject?.ticker}`}
            </TokenAmount>
          </HStack>
        </VStack>
      </ContentGroup>

      <VSpace height={20} />
      <PaymentServiceFee
        editingBody={editingBody}
        gasFeeTxType={GasFeeTxType.Swap}
        unitPriceCurrency={unitPriceCurrency}
        payWithSol={payWithSol}
        maxCount={100}
        totalPriceLabel={i18n.clover_total_stake_amount()}
        payWithLabel={i18n.clover_pay_service_fee_with()}
      />
      <VSpace height={20} />
      {isValidCircleToken(tokenSWR.content) && (
        <PaymentAccount
          withSolBalance={true}
          highPriorityCurrencyId={tokenSWR.content?.projectId}
          fallbackCurrency={copyObject<Currency>(
            tokenSWR.content.currency ?? ZeroSOL,
            { amount: "0" },
          )}
        />
      )}

      <PaymentFooter
        editingBody={editingBody}
        nextButtonText={i18n.clover_place_order()}
        paymentModal={paymentModal}
        paymentCheck={paymentCheck}
      />

      <TxSheet modal={paymentModal} performTx={performTx}>
        <CurrencyTransactionView body={editingBody.content} />
      </TxSheet>

      <StakeNoticeDialog
        onClickAgree={() => {
          paymentModal.open();
        }}
        modal={agreementModal}
      />
    </Page>
  );
}

const DescStyle = css`
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.2px;

  a {
    color: #34a6ff;
    text-decoration: underline;
  }
`;

const MintDesc = styled.div`
  color: var(--color-text00);
  font-weight: 400;
  text-align: center;
  font-size: 12px;
`;

const TokenAmount = styled.div`
  color: var(--color-text00);
  font-weight: 400;
  font-size: 14px;
`;

const CardName = styled.div`
  color: var(--color-text00);
  text-align: center;
  font-weight: 700;
  font-size: 14px;
`;

const RequirementTitle = styled.div`
  color: var(--color-text00);
  font-weight: 400;
  font-size: 15px;
`;
const markdown_style = css`
  color: var(--color-text00);
  font-weight: 300;
  font-size: 14px;
  margin: 0 6px;
`;
const RequirementSecondaryTitle = styled.div`
  color: var(--color-text00);
  font-weight: 300;
  font-size: 14px;
`;

const RequirementValue = styled.div`
  color: var(--color-text00);
  font-weight: 400;
  font-size: 14px;
`;
