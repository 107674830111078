import { useCallback, useEffect, useId, useMemo, useState } from "react";
import { LoadState } from "../LoadState";

import { flattenStateId, StateId } from "../StateId";
import { IDBStore, Store } from "../../utils/DB";
import { AlignUndefined } from "../../utils/Nullable";
import {
  ensureRepo,
  ensureSubscription,
  SWRCachePolicy,
  SWRFetchResult,
  SWRRepo,
  SWRRepoData,
} from "./SWRRepo";
import { useMyUid } from "../../service/AuthSessionService";
import { useHopHost } from "../useHopState";
import { RepoRemoteReadStore } from "./RepoStore";
import { useMap } from "../useMap";
import { usePageId } from "../../components/Subpage";
import { andLog } from "../../components/handleError";

type SWRRepoResult<T, C, ID extends StateId | undefined> = AlignUndefined<
  {
    readonly data: SWRRepoData<T, C>;
    readonly loadState: LoadState | undefined;
    readonly repo: SWRRepo<T, C>;
  },
  ID
>;

export type SWRReloadPolicy = SWRCachePolicy | "refetchOnNewSubscriber";

export type SWRConfig = {
  expirationMs?: number;
  ignoreCache?: boolean;
  reloadPolicy?: SWRReloadPolicy;
};

function shouldAcceptData<T, C>(
  config: SWRConfig | undefined,
  data: SWRRepoData<T, C>,
  fromCache: boolean,
) {
  if (!config) return true;

  if (config.ignoreCache && fromCache) return false;

  if (
    config.expirationMs &&
    new Date().getTime() - data.updatedAt > config.expirationMs
  )
    return false;

  return true;
}

class FetcherStore<T, C> implements RepoRemoteReadStore<T, C> {
  constructor(
    private readonly initialContent: T,
    private readonly initialCursor: C,
    private readonly fetcher: (
      prev: T,
      cursor: C,
    ) => Promise<SWRFetchResult<T, C>>,
  ) {}

  get = () => this.fetcher(this.initialContent, this.initialCursor);
  getMore = (prev: T, cursor: C) => this.fetcher(prev, cursor);
}

export function useSWRRepo<T, C, ID extends StateId | undefined = StateId>(
  contentId: ID,
  initialContent: T,
  initialCursor: C,
  fetcher: AlignUndefined<
    (prev: T, cursor: C) => Promise<SWRFetchResult<T, C>>,
    ID
  >,
  config?: SWRConfig,
): SWRRepoResult<T, C, ID> {
  const myUid = useMyUid();

  const repoId = contentId
    ? "SWRRepoData." + flattenStateId([contentId, myUid])
    : undefined;

  const { absolutePageId } = usePageId();
  const subscriberId = useId();

  const hopHost = useHopHost();

  const subscription = repoId
    ? flattenStateId(["fetchSuccessTime", absolutePageId, repoId])
    : undefined;
  const fetchSuccessTime = subscription
    ? hopHost.getState<number>(subscription)
    : undefined;

  const repo = repoId
    ? ensureRepo<T, C>(
        repoId,
        new IDBStore<SWRRepoData<T, C>>(Store.SWR, repoId, myUid),
        new FetcherStore(initialContent, initialCursor, fetcher!!),
      )
    : undefined;

  const getInitData = useCallback(() => {
    return {
      content: initialContent,
      source: "init" as const,
      updatedAt: 0,
      cursor: initialCursor,
      hasMore: false,
    };
  }, [initialContent, initialCursor]);

  const [getData, setData] = useMap<string, SWRRepoData<T, C>>((key) =>
    getInitData(),
  );

  const [loadState, setLoadState] = useState<LoadState>();

  useEffect(() => {
    if (repo) {
      const reloadPolicy = config?.reloadPolicy ?? "refetchOnNewSubscriber";

      let cachePolicy: SWRCachePolicy;
      switch (reloadPolicy) {
        case "refetchOnNewSubscriber":
          if (fetchSuccessTime !== undefined) {
            cachePolicy = "refetchOnCacheMiss";
          } else {
            cachePolicy = "alwaysRefetch";
          }
          break;
        default:
          cachePolicy = reloadPolicy;
      }

      ensureSubscription(subscriberId, repo, cachePolicy, {
        onDataDelete() {
          setData(repo.repoId, getInitData());
        },
        onDataChange(data: SWRRepoData<T, C>, fromCache: boolean) {
          if (subscription && !fromCache && data.source === "fetch") {
            const now = new Date().getTime();
            hopHost.setStateValue(now, subscription).catch(andLog);
          }
          if (shouldAcceptData(config, data, fromCache)) {
            setData(repo.repoId, data);
          }
        },
        onLoadStateChange(loadState: LoadState | undefined) {
          setLoadState(loadState);
        },
      });
    }
    return () => {
      repo?.unsubscribe(subscriberId);
    };
  }, [
    repo,
    subscriberId,
    setData,
    setLoadState,
    getInitData,
    config?.reloadPolicy,
  ]);

  const data = useMemo(
    () => (repoId !== undefined ? getData(repoId) : undefined),
    [repoId, getData],
  );

  if (data !== undefined && repo !== undefined) {
    return { data, loadState, repo };
  } else {
    return undefined as SWRRepoResult<T, C, ID>;
  }
}
