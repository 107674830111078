import { usePageParent } from "./PageCompositionBoundary";
import { assert } from "../utils/asserts";
import { omit } from "lodash";

export type ViewPagerComposition = {
  setHeaderStickyHeight: (h: number) => void;
};

function isViewPagerComposition(
  composition: Object | undefined,
): composition is ViewPagerComposition {
  return !!composition && "setHeaderStickyHeight" in composition;
}

export function useParentViewPager() {
  const parent = usePageParent();
  assert(isViewPagerComposition(parent.customComposition));
  return {
    ...omit(parent, ["customComposition"]),
    ...parent.customComposition,
  };
}
