// https://github.com/microsoft/TypeScript/issues/37663
import { SetStateAction } from "react";

export type Initializer<S> = S | (() => S);

export type SetStateActionOptionalPrev<S extends {}> =
  | S
  | ((prevState: S | undefined) => S);

export function isInitialFunction<S>(
  valueOrFunc: Initializer<S>,
): valueOrFunc is () => S {
  return typeof valueOrFunc === "function";
}

export function isSetActionFunction<S>(
  valueOrFunc: SetStateAction<S>,
): valueOrFunc is (prevState: S) => S {
  return typeof valueOrFunc === "function";
}

export function evalInitializer<T>(
  initialContent?: Initializer<T | undefined>,
) {
  if (isInitialFunction(initialContent)) {
    return initialContent();
  } else {
    return initialContent;
  }
}

export type DispatchWithReturn<A, T> = (value: A) => T;
