import { z } from "zod";
import { Currency } from "./Currency";
import { zBigInt, zStatic } from "../utils/zodUtils";

export const CheckInInfo = z.object({
  checkedIn: z.boolean().optional(),
  needDays: z.number().optional(),
  currency: Currency.optional(),
  checkInDateUnix: z.number().optional(),
  orderId: zBigInt.optional(),
  nextMultipleStr: z.string().optional(),
  checkInDate: z.number(),
});

export type CheckInInfo = zStatic<typeof CheckInInfo>;
