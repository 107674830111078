import {
  useBigIntParam,
  useEnumSearchParam,
} from "../../../hooks/useTypedParam";
import { useBackend } from "../../../service/APIService";
import { useI18n } from "../../../hooks/useI18n";
import { useSWRArray } from "../../../hooks/swr/useSWRArray";
import { Page } from "../../../components/Page";
import { NavMiddle, NavTitle } from "../../../components/NavBar";
import { CommonUserCell } from "../../user/CommonUserCell";
import { useHopper } from "../../../hooks/useHopper";
import { User } from "../../../proto/User";
import { useBroadcast } from "../../../hooks/useBroadcast";
import { andLog } from "../../../components/handleError";

export function CircleLevelMembersPage() {
  const circleId = useBigIntParam("circleId");
  const level = useEnumSearchParam("level");
  console.log(level);
  const backend = useBackend();
  const i18n = useI18n();
  const activeMembersSWR = useSWRArray(
    backend.getCircleMembers(circleId, level || 0),
  );
  const content = activeMembersSWR.content;
  const hopper = useHopper();
  const showMemberCard = (e: User) => {
    hopper.modal(`circle/${circleId}/user-card/${e.uid}`);
  };
  useBroadcast(
    "ON_REMOVE_FROM_CIRCLE",
    () => {
      activeMembersSWR.load().catch(andLog);
    },
    [activeMembersSWR],
  );

  return (
    <Page pageData={activeMembersSWR}>
      <NavMiddle>
        {level === 0 || level === undefined
          ? i18n.clover_other_member_page_title()
          : i18n.clover_level_member_page_title(level)}
      </NavMiddle>
      {content &&
        content.map((e) => {
          return (
            <CommonUserCell
              user={e}
              onUpdate={activeMembersSWR.load}
              showMembershipLevel={true}
              onClick={() => {
                showMemberCard(e);
              }}
            />
          );
        })}
    </Page>
  );
}
