import { Page } from "../../components/Page";
import { CircleChildPageProps } from "./CircleChildPageProps";
import { useSWR } from "../../hooks/swr/useSWR";
import ChatGridCell from "../../components/ChatGridCell";
import { useSWRArray } from "../../hooks/swr/useSWRArray";
import GridLayout from "../../components/GridLayout";
import {
  useBigIntParam,
  useBigIntSearchParam,
} from "../../hooks/useTypedParam";
import { useBackend } from "../../service/APIService";
import styled from "styled-components";
import { NavMiddle } from "../../components/NavBar";
import React from "react";

const FolderTitleLabel = styled.div`
  color: #ffffff;
  font-size: 24px;
  font-weight: bold;
`;

const FolderDescriptionLabel = styled.div`
  color: rgba(255, 255, 255, 0.8);
  font-size: 13px;
`;

function CircleChatsPage(props: CircleChildPageProps) {
  const backend = useBackend();
  const circleId = useBigIntParam("circleId");
  const pFolderId = useBigIntSearchParam("folderId", BigInt(0));
  const folderId = props.folderId ?? pFolderId;

  const circleSWR = useSWR(backend.getCircle(circleId));

  const chatsSWR = useSWRArray(
    backend.getCircleFolderItems(circleId, folderId),
  );
  const circleFolderSWR = useSWR(backend.getCircleFolder(circleId, folderId));

  const folderName = () => {
    const folderName = circleFolderSWR.content?.name;
    if (!props.folderId && folderName) {
      return folderName;
    }
    return "";
  };

  return (
    <Page pageData={[circleSWR, chatsSWR]}>
      <NavMiddle>{folderName()}</NavMiddle>
      {(props.showTitle ?? true) && (
        <FolderTitleLabel>{circleFolderSWR.content?.name}</FolderTitleLabel>
      )}
      {(props.showTitle ?? true) && (
        <FolderDescriptionLabel>
          {circleFolderSWR.content?.description}
        </FolderDescriptionLabel>
      )}
      {chatsSWR.content && (
        <GridLayout
          spanCount={2}
          items={chatsSWR.content.map((folderItem) => {
            return (
              <ChatGridCell
                key={folderItem.chat?.threadId}
                chat={folderItem?.chat}
              />
            );
          })}
        />
      )}
    </Page>
  );
}

export default CircleChatsPage;
