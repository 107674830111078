import { Page } from "../../components/Page";
import { useSWRArray } from "../../hooks/swr/useSWRArray";
import { useBackend } from "../../service/APIService";
import { useMyUid } from "../../service/AuthSessionService";
import MemberCard from "../circle/compose/MemberCard";
import { useSWR } from "../../hooks/swr/useSWR";
import { useBigIntParam } from "../../hooks/useTypedParam";
import { VStack } from "../../components/VStack";
import { NavEnd, NavItem, NavMiddle } from "../../components/NavBar";
import { useI18n } from "../../hooks/useI18n";
import { NavButtonType } from "../../bridge/NavButtonDesc";
import React from "react";
import { CircleMembershipCard } from "../circle/card/CircleMembershipCard";
import { useHopper } from "../../hooks/useHopper";

function CircleMembershipsPage() {
  const backend = useBackend();
  const uid = useBigIntParam("uid");
  const circlesSWR = useSWRArray(
    backend.getVisibleCircles(uid, { showInUserProfile: true }),
  );
  const userSWR = useSWR(backend.getUser(uid));
  const i18n = useI18n();
  const hopper = useHopper();
  const myId = useMyUid();
  return (
    <Page pageData={[circlesSWR, userSWR]}>
      {myId === uid && (
        <NavEnd>
          {NavItem.image(NavButtonType.Manage, () => {
            // moreMenu.open();
            hopper.push(`manage-visible-circle`);
          })}
        </NavEnd>
      )}

      <VStack style={{ gap: 34 }}>
        {circlesSWR.content.map((circle) => (
          <CircleMembershipCard
            key={circle.circleInfo.circleId}
            user={userSWR.content}
            circle={circle.circleInfo}
            titles={circle.memberTitleList}
            hopper={hopper}
            level={circle.circleMembershipCard?.membershipLevel ?? 0}
            background={circle.circleMembershipCard?.background}
          />
        ))}
      </VStack>
      <NavMiddle>{i18n.member_title_circle_memberships()}</NavMiddle>
    </Page>
  );
}

export default CircleMembershipsPage;
