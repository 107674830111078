import { useLoadScript } from "./useLoadScript";
import { useLoadTask } from "./LoadState";
import { useCallback } from "react";

interface AppleWindow extends Window {
  AppleID?: any;
}

interface AppleSignIn {
  authorization: {
    code: string;
    id_token: string;
    state: string;
  };
  user?: {
    name: { firstName: string; lastName: string };
    email: string;
  };
}

export function useAppleAuth(appId: string) {
  const scriptLoaded = useLoadScript({
    src: "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js",
  });

  const task = useCallback(async () => {
    const currentWindow: AppleWindow = window;
    currentWindow.AppleID.auth.init({
      clientId: appId,
      scope: "email name",
      redirectURI: window.location.origin,
      usePopup: true,
    });

    return {
      signIn: async () => {
        const currentWindow: AppleWindow = window;
        const data: AppleSignIn = await currentWindow.AppleID.auth.signIn();
        if (data.authorization?.code) {
          return data.authorization.code;
        } else {
          throw new Error("Apple login failed.");
        }
      },
    };
  }, [appId]);

  return useLoadTask(scriptLoaded ? task : undefined);
}
