import { Page } from "../../../components/Page";
import { useBigIntParam } from "../../../hooks/useTypedParam";
import { useBackend } from "../../../service/APIService";
import { useI18n } from "../../../hooks/useI18n";
import { useSWR } from "../../../hooks/swr/useSWR";
import SettingCell from "../../../components/SettingCell";
import Switch from "../../settings/components/Switch";
import { NavMiddle } from "../../../components/NavBar";
import React, { useState } from "react";
import { useGlobalSpinner } from "../../../utils/globalSpinner";
import { useNativePage } from "../../../hooks/useBridge";
import { andLog } from "../../../components/handleError";
import { Image } from "../../../components/Image";
import IcChecked from "./images/ic_checked.svg";
import { useHopper } from "../../../hooks/useHopper";
import GridLayout from "../../../components/GridLayout";
import { useSWRList } from "../../../hooks/swr/useSWRList";
import { UserIconView } from "../../../components/views/UserAvatarView";
import { ChatSettingAdd } from "../info/ChatInfoPage";
import { VStack } from "../../../components/VStack";
import IcAdminUser from "./images/ic_admin_user.svg";
import { alpha_on_pressed } from "../../../components/CommonStyles";
import { Divider } from "antd";

export function ChatWhoCanTalkPage() {
  const threadId = useBigIntParam("threadId");
  const i18n = useI18n();
  const nativePage = useNativePage();
  const backend = useBackend();
  const hopper = useHopper();
  const globalSpinner = useGlobalSpinner();
  const [isEveryone, setIsEveryone] = useState(false);

  const setAllowAll = (value: boolean) => {
    globalSpinner(async () => {
      await backend.toggleChatSenderAllowList(threadId, false).run();
      setIsEveryone(value);
    }).catch(andLog);
  };

  const senderAllowListSWR = useSWRList(["allow-list", threadId], () =>
    backend.getChatSenderAllowList(threadId).run(),
  );

  const admin = (
    <VStack
      style={{ alignItems: "center", gap: "8px", fontSize: "11px" }}
      mixin={alpha_on_pressed}
      onClick={() =>
        nativePage.alertNotice(i18n.chat_permission_hosts_alert(), i18n.got())
      }
    >
      <Image
        src={IcAdminUser}
        style={{
          backgroundColor: "#00D08B",
          width: "48px",
          height: "48px",
          borderRadius: "50%",
        }}
      />
      <>Admin</>
    </VStack>
  );

  const addAllowedUser = () => {
    hopper.present(`users-picker`);
  };

  return (
    <Page
      pageData={isEveryone ? undefined : senderAllowListSWR}
      scrollPaddingDisabled={true}
      alwaysShowsContent={true}
    >
      <NavMiddle>{i18n.party_on_who_can_talk_in_this_party()}</NavMiddle>
      <SettingCell
        title={i18n.everyone()}
        endValue={isEveryone ? <Image src={IcChecked} /> : <></>}
        showAccessoryImage={false}
        onClick={() => setAllowAll(true)}
      />
      <SettingCell
        title={i18n.party_room_allow_invite()}
        endValue={isEveryone ? <></> : <Image src={IcChecked} />}
        showAccessoryImage={false}
        showDivider={isEveryone}
        onClick={() => setAllowAll(false)}
      />
      {!isEveryone && senderAllowListSWR.content?.list && (
        <GridLayout
          spanCount={4}
          items={[<ChatSettingAdd onClicked={addAllowedUser} />, admin].concat(
            senderAllowListSWR.content.list.map((user) => (
              <UserIconView user={user} iconSize={40} />
            )),
          )}
          style={{
            borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
            paddingBottom: "20px",
          }}
        ></GridLayout>
      )}
      <Divider />
    </Page>
  );
}

export default function ChatSettingsPermissionsPage() {
  const threadId = useBigIntParam("threadId");
  const backend = useBackend();
  const i18n = useI18n();
  const hopper = useHopper();

  const thread = useSWR(["thread-info", threadId], () =>
    backend.getThreadInfo(threadId, false).run(),
  );
  return (
    <Page pageData={thread} scrollPaddingDisabled={true}>
      <NavMiddle>{i18n.circle2_1_permission()}</NavMiddle>
      <SettingCell
        title={i18n.party_on_who_can_talk_in_this_party()}
        onClick={() =>
          hopper.push(`chat/${threadId}/settings/permissions/who-can-talk`)
        }
      />
      <SettingCell
        title={i18n.party_room_allow_invite()}
        endValue={<Switch />}
        showAccessoryImage={false}
      />
    </Page>
  );
}
