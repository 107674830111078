import { Dispatch, useEffect, useRef, useState } from "react";
import _ from "lodash";
import { StateId } from "./StateId";
import { useHopState } from "./useHopState";

function useDebouncing<T>(
  delay: number,
  setValue: Dispatch<React.SetStateAction<T>>,
) {
  const valueRef = useRef<T | null>(null);
  const timeOut = useRef<any>(null);

  const debouncedSet = (v: T) => {
    if (timeOut.current) {
      clearTimeout(timeOut.current);
    }

    timeOut.current = setTimeout(() => {
      if (valueRef.current !== v) {
        setValue(v);
        valueRef.current = v;
      }
    }, delay);
  };
  const immediateSet = (v: T) => {
    if (timeOut.current) {
      clearTimeout(timeOut.current);
    }
    timeOut.current = null;
    setValue(v);
  };

  return [debouncedSet, immediateSet] as const;
}

export function useDebounceState<T>(
  delay: number,
  initialValue?: T,
): [T | undefined, (v: T) => void, (v: T) => void] {
  const [value, setValue] = useState(initialValue);
  const [debouncedSet, immediateSet] = useDebouncing(delay, setValue);

  return [value, debouncedSet, immediateSet];
}

export function useDebounceHopState<T>(
  id: StateId,
  delay: number,
  initialValue?: T,
): [T | undefined, (v: T) => void, (v: T) => void] {
  const [value, setValue] = useHopState(id, initialValue);
  const [debouncedSet, immediateSet] = useDebouncing(delay, setValue);

  return [value, debouncedSet, immediateSet];
}

export function useChange<T>(
  value: T,
  onChange: (prev: T, current: T) => void,
  onInit?: (value: T) => void,
) {
  const prev = useRef<{ value: T }>();
  useEffect(() => {
    const record = prev.current;
    if (record !== undefined) {
      const prevValue = record.value;
      if (prevValue !== value) {
        prev.current = { value: value };
        onChange(prevValue, value);
      }
    } else {
      prev.current = { value: value };
      if (onInit) {
        onInit(value);
      }
    }
  }, [value]);
}

export function useDeepChange<T>(
  value: T,
  onChange: (prev: T, current: T) => void,
  onInit?: (value: T) => void,
) {
  const prev = useRef<{ value: T }>();
  useEffect(() => {
    const record = prev.current;
    if (record !== undefined) {
      const prevValue = record.value;
      if (!_.isEqual(prevValue, value)) {
        prev.current = { value: value };
        onChange(prevValue, value);
      }
    } else {
      prev.current = { value: value };
      if (onInit) {
        onInit(value);
      }
    }
  }, [value]);
}
