import { Page } from "../../components/Page";
import styled, { css, RuleSet } from "styled-components";
import { NavEnd, NavItem, NavMiddle, NavStart } from "../../components/NavBar";
import { useI18n } from "../../hooks/useI18n";
import React, { ChangeEvent, CSSProperties, useRef, useState } from "react";
import { HStack, HStackMixin, VStack } from "../../components/VStack";
import { useBackend } from "../../service/APIService";
import { useErrorHandler } from "../../components/handleError";
import { useHopper } from "../../hooks/useHopper";
import { Image } from "../../components/Image";
import { useNativePage } from "../../hooks/useBridge";
import { useGlobalSpinner } from "../../utils/globalSpinner";
import { Media } from "../../proto/Media";
import { PageFooter } from "../../components/PageHeaderFooter";
import { genUUID } from "../../utils/uuid";
import { RichFormat } from "../../components/zdoc/RichFormat";
import { Post } from "../../proto/Post";
import icImageDisabled from "../../res/images/ic_image_disabled.svg";
import icImage from "../../res/images/ic_image.svg";
import icEdit from "../../res/images/ic_edit.svg";
import icAdd from "../../res/images/ic_add.svg";

export function ContentEditorPage() {
  const i18n = useI18n();
  const backend = useBackend();
  const hopper = useHopper();
  const nativePage = useNativePage();
  const editorRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLTextAreaElement>(null);
  const handleError = useErrorHandler();
  const [hasContent, setHasContent] = useState<boolean>(false);
  const [showPlaceHolder, setPlaceHolder] = useState<boolean>(!hasContent);
  const globalSpinner = useGlobalSpinner();
  const mediaInputRef = useRef<HTMLInputElement>(null);

  const [title, setTitle] = useState<string>();
  const [titleCoverInputOpen, setTitleCoverInputOpen] = useState<boolean>();
  const coverRef = useRef<HTMLInputElement>(null);
  const [cover, setCover] = useState<File>();
  const [coverToDisplay, setCoverToDisplay] = useState<string>();
  const [selectedMediaFileList, setSelectedMediaFileList] = useState<
    { id: string; file: File }[]
  >([]);
  const [isFocusedOnEditor, setIsFocusedOnEditor] = useState<boolean>(false);

  const observer = new MutationObserver((mutationsList) => {
    let existingFileList = selectedMediaFileList;
    mutationsList.forEach((mutation) => {
      mutation.removedNodes.forEach((node) => {
        if (node instanceof HTMLImageElement) {
          existingFileList = existingFileList.filter(
            (fileWithId) => fileWithId.id !== node.id,
          );
        }
      });
    });
    setSelectedMediaFileList(existingFileList);
  });

  if (editorRef.current) {
    observer.observe(editorRef.current, { childList: true });
  }

  async function submit() {
    if ((titleRef.current?.value.length || 0) === 0) {
      await nativePage.infoHud(i18n.winter_2022_impr_please_fill_the_title());
      return;
    }

    if ((titleRef.current?.value.length || 0) > 200) {
      await nativePage.alertNotice(
        i18n.text_limit_number_of_characters_not_follow(),
        i18n.ok(),
      );
      return;
    }
    try {
      await globalSpinner(async () => {
        const coverMedia = cover
          ? await backend.sendLocalMedia(cover, "content_image", () => {})
          : undefined;
        let newPost: Partial<Post> = {
          title: titleRef.current?.value,
          cover: coverMedia,
          content: editorRef.current?.textContent || "",
        };
        newPost = await exportRichFormatAndMediaList(newPost);
        const post = await backend.createPost(newPost).run();
        hopper.dismissLayerAndPush(`post/${post.blogId}`);
      });
    } catch (e) {
      handleError(e);
    }
  }

  async function exportRichFormatAndMediaList(newPost: Partial<Post>) {
    const nodes = editorRef.current?.children;
    const content = editorRef.current?.textContent || "";
    let start = 0;
    const attachmentSpans = [];
    const mediaList = [];
    if (nodes) {
      for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i];
        if (node instanceof HTMLElement) {
          const spanStart = content.indexOf(`⊠`, start);
          start += 1;
          if (node.id.startsWith("media")) {
            const file = selectedMediaFileList.find(
              (it) => it.id === node.id,
            )?.file;
            if (file) {
              const media = await backend.sendLocalMedia(
                file,
                "content_image",
                () => {},
              );
              mediaList.push(media);
              attachmentSpans.push({
                data: {
                  type: "media",
                  media: { mediaRefId: media.mediaId },
                },
                start: spanStart,
                end: spanStart + 1,
              });
            }
          }
        }
      }
    }
    return Object.assign(newPost, {
      richFormat: {
        version: 4,
        attachmentSpans: attachmentSpans,
      },
      mediaList: mediaList,
    });
  }

  function logSelection() {
    const selection = window.getSelection();
    const range = selection?.getRangeAt(0);
    console.log(range?.startOffset);
    console.log(range?.endOffset);
  }

  function createMediaElement(src: string, id: string) {
    const img = document.createElement("img");
    img.textContent =
      editorRef.current?.textContent?.length !== 0 ? "\n⊠" : "⊠";
    img.src = src;
    img.style.maxWidth = "100%";
    img.style.borderRadius = "10px";
    img.id = id;
    return img;
  }

  function insertElementAtCursor(element: HTMLElement) {
    const selection = window.getSelection();
    const range = selection?.getRangeAt(0);
    range?.deleteContents();
    range?.insertNode(element);
    range?.setStartAfter(element);
    editorRef.current?.focus();
  }

  function onMediaChange(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.files) {
      const newFiles = [...selectedMediaFileList];
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];
        const id = "media/" + genUUID();
        newFiles.push({ file: file, id: id });
        const reader = new FileReader();
        reader.onload = (event) => {
          if (typeof event.target?.result === "string") {
            const img = createMediaElement(event.target.result, id);
            insertElementAtCursor(img);
          }
        };
        reader.readAsDataURL(file);
      }
      setSelectedMediaFileList(newFiles);
    }
    event.target.value = "";
  }

  function onCoverChange(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.files) {
      setCover(event.target.files[0]);
      const reader = new FileReader();
      reader.onloadend = (e) => {
        if (typeof e.target?.result === "string") {
          setCoverToDisplay(e.target.result);
        }
      };
      reader.readAsDataURL(event.target.files[0]);
    }
    event.target.value = "";
  }

  function onBlur() {
    setIsFocusedOnEditor(false);
    if (!hasContent) {
      setPlaceHolder(true);
    }
  }

  function onFocus() {
    setIsFocusedOnEditor(true);
    clearPlaceHolder();
  }

  function clearPlaceHolder() {
    setPlaceHolder(false);
  }

  return (
    <Page pageData={undefined}>
      <NavMiddle>{i18n.new_post()}</NavMiddle>
      <NavEnd>{NavItem.done(i18n.submit(), submit)}</NavEnd>
      {!titleCoverInputOpen && (
        <AddTitleAndCover onClick={() => setTitleCoverInputOpen(true)}>
          <AddButton src={icAdd} />
          {i18n.clover_add_title_or_cover_image()}
        </AddTitleAndCover>
      )}

      {titleCoverInputOpen && (
        <VStack>
          <CoverContainer
            onClick={() => {
              coverRef.current?.click();
            }}
          >
            {!coverToDisplay && (
              <VStack
                style={{
                  position: "absolute",
                  alignItems: "center",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <ImageIcon src={icImage} />
                <AddCoverTip>{i18n.add_a_cover_image()}</AddCoverTip>
              </VStack>
            )}
            {coverToDisplay && (
              <Image
                src={coverToDisplay}
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  borderRadius: 10,
                }}
              />
            )}
            {coverToDisplay && (
              <EditContainer>
                <EditIcon src={icEdit} />
                <EditText>{i18n.edit()}</EditText>
              </EditContainer>
            )}
          </CoverContainer>
          <TitleContainer>
            <TitleInputBar
              ref={titleRef}
              placeholder={i18n.title()}
              wrap={"off"}
              onChange={(e) => setTitle(e.target.value)}
            />
            <TextLimiter
              currentLength={title?.length || 0}
              maxLength={200}
              style={{ position: "absolute", right: 0, bottom: 14 }}
            />
          </TitleContainer>
        </VStack>
      )}
      <Editor
        ref={editorRef}
        suppressContentEditableWarning={true}
        contentEditable={true}
        onFocus={onFocus}
        onBlur={onBlur}
      >
        {/*{showPlaceHolder && (*/}
        {/*  <PlaceHolder>{i18n.compose_impr_post_placeholder()}</PlaceHolder>*/}
        {/*)}*/}
      </Editor>
      <PageFooter obscuredZoneKey={"EditorToolBar"} safeBottomDisabled={true}>
        <ToolBar>
          <ToolButton
            src={isFocusedOnEditor ? icImage : icImageDisabled}
            onClick={() => {
              if (isFocusedOnEditor) {
                mediaInputRef.current?.click();
              }
            }}
            style={{ fill: isFocusedOnEditor ? "white" : "blue" }}
          />
        </ToolBar>
      </PageFooter>
      <CoverInput
        ref={coverRef}
        type={"file"}
        accept={"image/*"}
        onChange={onCoverChange}
        multiple={false}
      />
      <MediaInput
        ref={mediaInputRef}
        type={"file"}
        accept={"image/*"}
        onChange={onMediaChange}
        multiple={true}
      />
    </Page>
  );
}

const Editor = styled.span`
  outline: none;
  margin-top: 12px;
  word-break: break-all;
`;

const PlaceHolder = styled.p`
  font-weight: 500;
  font-size: 15px;
  color: #ffffff33;
`;

const AddTitleAndCover = styled.div`
  ${HStackMixin};
  gap: 4px;
  border-radius: 12px;
  background-color: #ffffff0c;
  padding: 2px 8px;
  font-size: 10px;
  font-weight: 500;
  color: #ffffff99;
  align-self: center;
`;

const AddButton = styled.img`
  width: 18px;
  height: 18px;
`;

const PlainText = styled.span`
  font-size: 15px;
  font-weight: 500;
  color: white;
`;

const CoverContainer = styled.div`
  position: relative;
  display: grid;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  width: 100%;
  background-color: #ffffff4c;
  padding-bottom: calc(178 / 335 * 100%);
`;

const AddCoverTip = styled.span`
  font-size: 12px;
  font-weight: 400;
`;

const ImageIcon = styled.img`
  width: 36px;
  height: 36px;
`;

const TitleInputBar = styled.textarea`
  flex: 1;
  font-weight: 700;
  font-size: 18px;
  background-color: transparent;
  outline: none;
  color: white;
  margin-top: 20px;
  height: 34px;
  border: none;
  resize: none;
  scrollbar-width: none;
  -ms-overflow-style: none;
`;

const TitleContainer = styled.div`
  width: 100%;
  ${HStackMixin};
  padding-right: 50px;
  border-bottom: 1px solid #ffffff7f;
`;

function TextLimiter(props: {
  currentLength: number;
  maxLength: number;
  style?: CSSProperties;
}) {
  return (
    <TextCountContainer style={props.style}>
      {props.currentLength > 0 && (
        <LimitText
          style={{
            color: props.currentLength > props.maxLength ? "#EF3537" : "white",
          }}
        >
          {props.currentLength}
        </LimitText>
      )}
      {props.currentLength > 0 && <LimitText>/</LimitText>}

      <LimitText>{props.maxLength}</LimitText>
    </TextCountContainer>
  );
}

const TextCountContainer = styled.span``;

const LimitText = styled.span`
  color: white;
  font-size: 12px;
  font-weight: 300;
`;

const CoverInput = styled.input`
  display: none;
`;

const EditContainer = styled.div`
  position: absolute;
  background: #00000099;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  right: 0;
  bottom: 0;
  padding: 4px 11px;
  gap: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const EditText = styled.span`
  font-weight: 300;
  font-size: 12px;
  color: #ffffffcc;
`;

const EditIcon = styled.img`
  width: 13px;
  height: 13px;
`;

const MediaInput = styled.input`
  display: none;
`;

const ToolButton = styled.img`
  width: 26px;
  height: 26px;
`;

const ToolBar = styled.div`
  ${HStackMixin};
  gap: 20px;
  background: black;
  height: 63px;
  padding-left: 17px;
`;

const MediaContainer = styled.div``;
