import { Page } from "../../../components/Page";
import { useBackend } from "../../../service/APIService";
import {
  useBigIntParam,
  useEnumSearchParam,
} from "../../../hooks/useTypedParam";
import { useSWR } from "../../../hooks/swr/useSWR";
import { HStack, Spring } from "../../../components/VStack";
import { CSSProperties, useMemo, useState } from "react";
import { NFTDisplayInfoView } from "../components/NFTDisplayInfoView";
import { PageFooter } from "../../../components/PageHeaderFooter";
import { useI18n } from "../../../hooks/useI18n";
import { isProtoNFT } from "../../../proto/NFT";
import { useHopper } from "../../../hooks/useHopper";
import { NFTCapabilityModal } from "./NFTCapabilityModal";
import { useModal } from "../../../components/Modal";
import { LinkButton, RegularLargeButton } from "../../../components/Buttons";
import IcStore from "../imgs/ic_store.svg";
import { Image } from "../../../components/Image";
import ArrowDown from "../../../res/images/chevorn_down.svg";
import { Label } from "../components/Label";
import { alpha_on_pressed } from "../../../components/CommonStyles";
import styled from "styled-components";
import BgHolder from "../imgs/bg_nft_holder.svg";
import { useResizeObserver } from "../../../hooks/useResizeObserver";
import { Circle, isValidCircle } from "../../../proto/Circle";
import { CircleIconView } from "../../alertCenter/AlertDetailCell";
import { Currency, CurrencyType, formatMoney } from "../../../proto/Currency";
import { BSMenu, BSMenuItem } from "../../../components/BSMenu";
import { NFTCircleCardDisplayView } from "../components/NFTCircleCardDisplayView";
import { isLiteApp } from "../../../bridge/ExternalAppShellData";
import { OverFlowTextStyle } from "../../../components/Text";
import { NavEnd, NavItem } from "../../../components/NavBar";
import { NavButtonType } from "../../../bridge/NavButtonDesc";
import { useGlobalSpinner } from "../../../utils/globalSpinner";
import { useErrorHandler } from "../../../components/handleError";
import { useNativePage } from "../../../hooks/useBridge";
import { useTransactionBody } from "../../wallet/send/SendTokenPage";
import { NFTUser } from "../../../proto/NFTUser";
import { copyTextToClipboard } from "../../../utils/CopyText";

export function NFTCircleCardDisplayPage() {
  const nftId = useBigIntParam("cardId");
  const preferCurrencyType = useEnumSearchParam<CurrencyType>("currencyType");
  const backend = useBackend();
  const i18n = useI18n();
  const hopper = useHopper();
  const globalSpinner = useGlobalSpinner();
  const handleError = useErrorHandler();
  const nativePage = useNativePage();

  // const nftInfo = useSWR(backend.getNFTCircleMembershipCard(nftId));
  const nftInfo = useSWR(backend.getMembershipCardNFT(nftId));
  const circleInfo = useSWR(
    nftInfo.content?.circleMembershipCard?.circleId
      ? backend.getCircle(nftInfo.content.circleMembershipCard.circleId)
      : undefined,
  );
  const circleId = useMemo(() => {
    return nftInfo.content?.circleMembershipCard?.circleId;
  }, [nftInfo.content]);

  const [selectedPrice, setSelectedPrice] = useState<Currency | undefined>(
    undefined,
  );

  const displayPaymentCurrencies = useMemo(() => {
    const allPrices = nftInfo.content?.circleMembershipCard?.priceList;
    return isLiteApp()
      ? allPrices?.filter((item) => item.currencyType === CurrencyType.GEMS)
      : allPrices;
  }, [nftInfo?.content]);

  const displayPrice = useMemo(() => {
    if (selectedPrice) return selectedPrice;
    if (displayPaymentCurrencies && displayPaymentCurrencies.length > 0) {
      if (
        preferCurrencyType &&
        displayPaymentCurrencies.find(
          (c) => c.currencyType === preferCurrencyType,
        )
      ) {
        return displayPaymentCurrencies.find(
          (c) => c.currencyType === preferCurrencyType,
        );
      }
      return displayPaymentCurrencies[0];
    }
  }, [nftInfo.content, selectedPrice]);

  const capabilityModal = useModal("nft-capability-modal");
  const priceModal = useModal("nft-price-currency-type-modal");
  const moreModal = useModal("nft-more-modal");

  const onClickEdit = () => {
    hopper.modal("nyi");
  };
  const onClickOpen = () => {
    capabilityModal.open();
  };

  const onClickStore = () => {
    if (circleId) {
      hopper.push(`circle/${circleId}/card-picker`);
    }
  };

  const editingBody = useTransactionBody();

  const onClickBuy = async () => {
    if (circleId) {
      await editingBody.clear();
      hopper.layer(`circle/${circleId}/card/${nftId}/purchase`, {
        currencyType: displayPrice?.currencyType,
      });
    }
  };

  const onClickStake = () => {
    if (circleId) {
      hopper.push(`circle/${circleId}/card/${nftId}/stake`);
    }
  };

  const onClickCopyLink = async () => {
    try {
      await globalSpinner(async () => {
        const link = await backend
          .getShareLink(`nft-circle-card/${nftId}`)
          .run();
        if (link.shareLink) {
          await copyTextToClipboard(link.shareLink);
        }
      });
      await nativePage.successHud(i18n.copied());
    } catch (e) {
      handleError(e);
    }
  };

  const isOwn = useMemo(() => {
    return nftInfo.content?.isOwn ?? false;
  }, [nftInfo]);

  const canBuy = useMemo(() => {
    return nftInfo.content?.circleMembershipCard?.isTemplate ?? false;
  }, [nftInfo]);

  const isProto = useMemo(() => {
    return nftInfo.content && isProtoNFT(nftInfo.content);
  }, [nftInfo]);

  return (
    <Page
      pageData={[nftInfo, circleInfo]}
      scrollPaddingDisabled={true}
      safeTopDisabled={true}
      safeBottomDisabled={true}
    >
      <NavEnd>
        {NavItem.image(NavButtonType.More, () => moreModal.open())}
      </NavEnd>
      {nftInfo.content && circleInfo?.content && (
        <NFTCircleCardDisplayView
          nft={nftInfo.content}
          circle={circleInfo.content}
        />
      )}
      {nftInfo.content && circleInfo?.content && (
        <NFTDisplayInfoView nft={nftInfo.content} circle={circleInfo.content} />
      )}

      <PageFooter obscuredZoneKey={"NFTBottom"}>
        {/*{isOwn ? (*/}
        {/*  <HStack style={{ padding: "0 44px", gap: "10px" }}>*/}
        {/*    {isProto && (*/}
        {/*      <VStack*/}
        {/*        style={{ width: 44 }}*/}
        {/*        mixin={[alpha_on_pressed]}*/}
        {/*        onClick={onClickEdit}*/}
        {/*      >*/}
        {/*        <div>✏️</div>*/}
        {/*        <div style={{ fontSize: 11 }}>Edit</div>*/}
        {/*      </VStack>*/}
        {/*    )}*/}
        {/*    <Spring />*/}
        {/*    <RegularButton onClick={onClickOpen}>*/}
        {/*      {i18n.bottle_open_bottle()}*/}
        {/*    </RegularButton>*/}
        {/*  </HStack>*/}
        {/*) : (*/}
        {/*  <HStack style={{ padding: "0 44px", gap: "10px" }}>*/}
        {/*    {isProto && (*/}
        {/*      <VStack style={{ width: 44, alignItems: "center" }}>*/}
        {/*        <div>👁️</div>*/}
        {/*        <div>Preview</div>*/}
        {/*      </VStack>*/}
        {/*    )}*/}
        {/*    <Spring />*/}
        {/*    {!isProto && <RegularButton>{i18n.preview()}</RegularButton>}*/}
        {/*  </HStack>*/}
        {/*)}*/}
        {canBuy && (
          <div>
            <HStack style={{ padding: "16px var(--page-h-inset)", gap: 4 }}>
              <Spring />
              {displayPrice && (
                <>
                  <Label $fontSize={12} style={{ opacity: 0.4 }}>
                    {i18n.jun23_impr_price()}
                  </Label>
                  <Image src={[displayPrice.icon, 24]} style={{ width: 24 }} />
                  <Label $fontSize={18}>
                    {formatMoney("medium", displayPrice)}
                  </Label>
                  {(displayPaymentCurrencies?.length ?? 0) > 1 && (
                    <HStack
                      style={{
                        backgroundColor: "rgba(255, 255, 255, 0.1)",
                        borderRadius: 4,
                        gap: 2,
                        padding: "3px 6px",
                      }}
                      mixin={alpha_on_pressed}
                      onClick={() => priceModal.open()}
                    >
                      <Image
                        src={[displayPrice.icon, 24]}
                        style={{ width: 16 }}
                      />
                      <Label $fontSize={12}>{displayPrice.name}</Label>
                      <Image src={ArrowDown} />
                    </HStack>
                  )}
                </>
              )}
            </HStack>
            <HStack style={{ padding: "0 var(--page-h-inset)", gap: 8 }}>
              <RegularLargeButton
                onClick={onClickStore}
                style={{ width: 60, padding: 0 }}
              >
                <Image src={IcStore} style={{ width: 36 }} />
              </RegularLargeButton>
              <RegularLargeButton
                style={{
                  flexGrow: 1,
                }}
                onClick={onClickBuy}
              >
                {i18n.web3_v0_buy()}
              </RegularLargeButton>
            </HStack>
            {!isLiteApp() && (
              <LinkButton
                style={{ padding: "16px 16px 0", textDecoration: "underline" }}
                onClick={onClickStake}
              >
                {i18n.clover_get_membership_card_by_staking()}
              </LinkButton>
            )}
          </div>
        )}
      </PageFooter>
      {nftInfo.content && (
        <NFTCapabilityModal modal={capabilityModal} nft={nftInfo.content} />
      )}

      <BSMenu modal={priceModal} title={i18n.web3_v0_select_inapp_currency()}>
        {(displayPaymentCurrencies ?? []).map((price) => (
          <BSMenuItem
            title={price.name}
            icon={[price.icon, 36]}
            onClick={() => setSelectedPrice(price)}
          />
        ))}
      </BSMenu>

      <BSMenu modal={moreModal}>
        <BSMenuItem
          title={i18n.info()}
          onClick={() => hopper.push(`nft-circle-card/${nftId}/info`)}
        />
        <BSMenuItem title={i18n.share_copy_link()} onClick={onClickCopyLink} />
      </BSMenu>
    </Page>
  );
}

const HolderBorder = styled.div`
  position: absolute;

  width: 40px;

  border: 14px solid transparent;
  border-right: 200px solid transparent;
  border-image-source: url("${BgHolder}");
  border-image-repeat: stretch;
  border-image-slice: 14 200 14 20 fill;
`;

const HolderName = styled.div`
  font-weight: 400;
  font-size: 12px;
  flex-shrink: 1;
  ${OverFlowTextStyle};
  -webkit-line-clamp: 1;
  ${OverFlowTextStyle};
`;

const HolderType = styled.div`
  padding: 0 15px;
  font-size: 12px;
  font-weight: 400;
  text-shadow: 0 0 6px rgba(255, 255, 255, 0.8);
`;

export function NFTHolderView(props: {
  style?: CSSProperties;
  holder: NFTUser | Circle | undefined;
}) {
  const [ref, size] = useResizeObserver();
  const titleSize = useMemo(() => {
    if (!size) return 0;
    const rect = size.target.getBoundingClientRect();
    return Math.ceil(rect.width - 46);
  }, [size]);
  const i18n = useI18n();
  const hopper = useHopper();

  let title;
  let info;

  if (isValidCircle(props.holder)) {
    title = i18n.circle();
    info = (
      <HStack style={{ gap: 4, overflow: "hidden", flexShrink: 1 }}>
        <CircleIconView circle={props.holder} iconSize={16} borderRadius={2} />
        <HolderName>{props.holder.name}</HolderName>
      </HStack>
    );
  } else if (props.holder !== undefined) {
    title = i18n.comment_impr_author();
    info = (
      <HStack style={{ gap: 4, overflow: "hidden", flexShrink: 1 }}>
        <Image
          src={[props.holder.icon, 16]}
          style={{
            borderRadius: "50%",
            overflow: "hidden",
            width: 16,
            height: 16,
          }}
        />
        <HolderName>{props.holder.name}</HolderName>
      </HStack>
    );
  }

  function onClickHolder() {
    if (isValidCircle(props.holder)) {
      hopper.push(`circle/${props.holder.circleId}`);
    } else if (props.holder !== undefined) {
      hopper.push(`user/${props.holder.uid}`);
    }
  }

  return (
    <div style={{ ...props.style }}>
      <HStack
        style={{ position: "relative", gap: 7 }}
        mixin={[alpha_on_pressed]}
        onClick={onClickHolder}
      >
        <HolderType ref={ref}>{title}</HolderType>
        {info}
        <HolderBorder style={{ width: titleSize, height: 0 }}></HolderBorder>
      </HStack>
    </div>
  );
}

export function randomColor() {
  return (
    "#" + (0x1000000 + Math.random() * 0xffffff).toString(16).substring(0, 6)
  );
}
