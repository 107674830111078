import { CircleTabItem } from "../../proto/Circle";
import { Page } from "../../components/Page";
import { Image } from "../../components/Image";
import { getBestRes } from "../../proto/Media";
import { HStack, VStack } from "../../components/VStack";
import styled from "styled-components";
import { resolvePath } from "../../components/CircleWidgetBanner";
import { useHopper } from "../../hooks/useHopper";

const LinkTitleLabel = styled.div`
  font-size: 16px;
`;

const LinkSubtitleLabel = styled.div`
  font-size: 11px;
  color: rgba(255, 255, 255, 0.6);
`;

export function CircleOtherLinkPage(props: {
  tab: CircleTabItem;
  path: string;
}) {
  const hopper = useHopper();

  return (
    <Page pageData={undefined}>
      <div
        style={{
          backgroundColor: "rgba(255, 255, 255, 0.15)",
          borderRadius: 6,
          height: 80,
          marginTop: 20,
        }}
        onClick={() => {
          resolvePath(props.path, hopper);
        }}
      >
        <HStack style={{ marginTop: 20, gap: 12 }}>
          <div style={{ marginInlineStart: 12 }}>
            {props.tab.linkIcon && (
              <Image
                src={getBestRes(props.tab.linkIcon).url}
                width={40}
                height={40}
                style={{ borderRadius: 6 }}
              />
            )}
          </div>
          <VStack style={{ gap: 4 }}>
            <LinkTitleLabel>{props.tab.name}</LinkTitleLabel>
            <LinkSubtitleLabel>{props.tab.link}</LinkSubtitleLabel>
          </VStack>
        </HStack>
      </div>
    </Page>
  );
}
