export function isOnHomeScreen() {
  // Check if the app is running in standalone mode (PWA) on Android and modern browsers
  const isStandaloneAndroid = window.matchMedia(
    "(display-mode: standalone)",
  ).matches;

  // Check if the app is running in standalone mode on iOS Safari
  const isStandaloneIOS = (window.navigator as any).standalone;

  return isStandaloneAndroid || isStandaloneIOS;
}
