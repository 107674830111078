import React, { useMemo } from "react";
import styled from "styled-components";

import { BoolNumber, CurrencyHolder } from "../../../proto/Wallet";
import { isSpongeKit } from "../../../utils/isSpongeKit";
import { useBigIntParam } from "../../../hooks/useTypedParam";
import { useI18n } from "../../../hooks/useI18n";
import { useSWR } from "../../../hooks/swr/useSWR";
import { useBackend } from "../../../service/APIService";
import { useHopper } from "../../../hooks/useHopper";
import { useLoadState } from "../../../hooks/LoadState";
import { useErrorHandler } from "../../../components/handleError";
import { Page } from "../../../components/Page";
import { HStack } from "../../../components/VStack";
import { Image } from "../../../components/Image";
import GridLayout from "../../../components/GridLayout";
import { RegularButton } from "../../../components/Buttons";
import { Spin } from "../../../components/Spin";
import GridTokenCell from "../GridTokenCell";
import TokenPreviewCell from "./TokenPreviewCell";
import { CellDivider } from "../../../components/ContentGroup";
import { CurrencyType } from "../../../proto/Currency";

import chevron from "../../../res/images/icon_section_header_show_more.svg";
import { shrink_on_pressed } from "../../../components/CommonStyles";
import questionMark from "../../../res/images/ic_sol_question_mark.svg";
import { isLiteApp } from "../../../bridge/ExternalAppShellData";

const LegacyToken = styled(HStack)`
  height: 38px;
  margin-top: 12px;
  padding-left: 15px;
  padding-right: 12px;
  justify-content: space-between;
  border: 1px solid rgba(255, 255, 255, 0.35);
  border-radius: 8px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1)
    ),
    #141414;

  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.4);
  ${shrink_on_pressed};
`;

function AccountTokensPage() {
  const i18n = useI18n();
  const backend = useBackend();
  const walletAccountId = useBigIntParam("walletAccountId");
  const currencyListSWR = useSWR(backend.getCurrencyList(walletAccountId), {
    reloadPolicy: "alwaysRefetch",
  });
  const hopper = useHopper();

  const walletSWR = useSWR(backend.getWallet());
  const returnTask = useLoadState();
  const handleError = useErrorHandler();

  const [legacyTokensCurrencies, gridCardCurrencies, largeCardCurrencies] =
    useMemo(() => {
      const legacyTokens: CurrencyHolder[] = [];
      const gridCard: CurrencyHolder[] = [];
      const largeCard: CurrencyHolder[] = [];
      currencyListSWR.content?.list.forEach((item) => {
        if (
          [CurrencyType.COIN, CurrencyType.DIAMOND].includes(
            item.currency.currencyType,
          )
        ) {
          if (item.currency.amount !== "0") {
            legacyTokens.push(item);
          }
        } else if (
          [
            CurrencyType.GEMS,
            CurrencyType.SOL,
            CurrencyType.USDT,
            CurrencyType.USDC,
          ].includes(item.currency.currencyType)
        ) {
          gridCard.push(item);
        } else {
          largeCard.push(item);
        }
      });
      return [legacyTokens, gridCard, largeCard];
    }, [currencyListSWR.content]);

  const returnGems = async () => {
    const r = await returnTask.run(async () => {
      await backend.returnGems(walletAccountId).run();
      currencyListSWR.load();
    });
    if (!r.success) {
      handleError(r.error);
    }
  };

  return (
    <Page pageData={[currencyListSWR, walletSWR]}>
      {legacyTokensCurrencies.length > 0 && (
        <LegacyToken
          onClick={() =>
            hopper.layer(`wallet/${walletAccountId}/legacy-tokens`)
          }
        >
          <span>{i18n.clover_legacy_tokens()}</span>
          <Image src={chevron} width={20} height={20} />
        </LegacyToken>
      )}
      {/** 用于return test */}
      {isSpongeKit() && legacyTokensCurrencies.length === 0 && (
        <RegularButton
          style={{
            height: 38,
            minHeight: 38,
            marginTop: 12,
          }}
          onClick={returnGems}
        >
          <Spin state={returnTask.state}>Return Coins and Diamond</Spin>
        </RegularButton>
      )}

      {gridCardCurrencies && (
        <GridLayout
          spanCount={2}
          items={gridCardCurrencies.map((currencyHolder) => (
            <GridTokenCell
              currency={currencyHolder.currency}
              onClickToken={() => {
                hopper.push(
                  `wallet/${walletAccountId}/token/${currencyHolder.currency.currencyType}`,
                  {
                    currencyId: currencyHolder.currency.currencyId,
                  },
                );
              }}
            />
          ))}
        />
      )}
      {!isLiteApp() ||
      walletSWR.content?.thirdPartyIapStatus === BoolNumber.On ? (
        <HStack
          onClick={() => {
            hopper.push("get-sol");
          }}
          style={{ margin: "20px auto 0", gap: 6 }}
        >
          <Image src={questionMark} width={16} height={16} />
          <GetSol>{i18n.clover_how_do_i_get_sol()}</GetSol>
          <Image src={chevron} width={12} height={12} />
        </HStack>
      ) : null}

      <CellDivider style={{ marginTop: 20, marginBottom: 8 }} />
      {largeCardCurrencies?.map((currencyHolder) => (
        <TokenPreviewCell
          key={currencyHolder.currency.currencyId}
          currency={currencyHolder.currency}
          membershipCardList={currencyHolder.membershipCardList}
          onClickToken={() => {
            hopper.push(
              `asset-detail`,
              {
                walletAccountId,
                currencyId: currencyHolder.currency.currencyId,
              },
              // `wallet/${walletAccountId}/token/${currencyHolder.currency.currencyType}`,
            );
          }}
        />
      ))}
    </Page>
  );
}

export default AccountTokensPage;

const GetSol = styled.div`
  color: white;
  font-size: 12px;
  font-weight: 400;
`;
