import { Bridge, getBridgeHook } from "../bridge/Bridge";
import { Backend } from "../service/Backend";
import { BackendClient } from "../service/EndPoint";
import { useContext } from "react";
import { AppShellContext } from "./useAppShell";

export function useWebHost() {
  return useContext(AppShellContext).webHost;
}

export function useNativePage() {
  return useContext(AppShellContext).nativePage;
}

export function useNativePageOrNull() {
  return useContext(AppShellContext).nativePage;
}

let toDaMoon: Backend;

export function useToDaMoon() {
  if (!toDaMoon) {
    toDaMoon = new Backend(
      getBridgeHook<BackendClient>(Bridge.getInstance(), "todamoon"),
    );
  }

  return toDaMoon;
}
