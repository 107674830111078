import { z } from "zod";
import { zBigInt, zEnum, zStatic } from "../utils/zodUtils";
import { ObjectType } from "./ObjectSpec";
import { User } from "./User";

export enum SmallNoteType {
  Normal = 0,
  Abandoning = 1,
  Abandoned = 2,
  ContainerManageRemovedByAbandoned = 3,
  ContainerInvitedTobeAdmin = 4,
  ContainerInvitedTobeCoAdmin = 5,
  ContainerManagerBeRemoved = 6,
  ContainerInvitedMember = 7,
  ContainerMemberRemovedByManager = 8,
  InviteUserToCircle = 9,
}

export const SmallNote = z.object({
  noteId: zBigInt,
  targetUid: zBigInt.optional(),
  objectId: zBigInt,
  objectType: zEnum(ObjectType),
  type: zEnum(SmallNoteType),
  inviterUid: zBigInt.optional(),
  inviter: User.optional(),
  createdTime: z.number().optional(),
  readTime: z.number().optional(),
});

export type SmallNote = zStatic<typeof SmallNote>;
