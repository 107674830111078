export const delay = (delayMs: number) => {
  return new Promise((resolve) => setTimeout(resolve, delayMs));
};

export const delayForever = async (): Promise<never> => {
  while (true) {
    await delay(24 * 3600 * 1000);
  }
};

export async function ensureDur<T>(
  dur: number,
  action: () => Promise<T>,
): Promise<T> {
  const startTs = new Date();
  const res = await action();
  const endTs = new Date();
  const actionTime = endTs.getTime() - startTs.getTime();
  if (actionTime < dur) {
    await delay(dur - actionTime);
  }

  return res;
}
