import { z } from "zod";
import { Chat } from "./Chat";
import { zBigInt, zStatic } from "../utils/zodUtils";
import { Currency } from "./Currency";
import { Reaction } from "./Reaction";
import { User } from "./User";
import { BareGiftBox } from "./BareGiftBox";

export enum GiftBoxDistributeType {
  Evenly = 1,
  Random = 2,
  NFT = 3,
}

export enum GiftBoxStatus {
  Pending = 1,
  Withdrawn = 2,
  Expired = 3,
  Done = 4,
}

export const GiftBox = BareGiftBox.merge(
  z.object({
    chatThread: Chat.optional(),
  }),
);

export type GiftBox = zStatic<typeof GiftBox>;
