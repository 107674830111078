import { z } from "zod";
import { Currency } from "./Currency";
import { AccountCurrency } from "./AccountCurrency";
import { zStatic } from "../utils/zodUtils";
import { NFT } from "./NFT";
import { Circle } from "./Circle";

export const CurrencyDetail = z.object({
  currency: Currency.optional(),
  accountCurrencyList: z.array(AccountCurrency).optional(),
  membershipCardList: z.array(NFT).optional(),
  circleList: z.array(Circle).optional(),
  membershipCardCurrency: Currency.optional(),
});

export type CurrencyDetail = zStatic<typeof CurrencyDetail>;
