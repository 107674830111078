import { Circle } from "../../../proto/Circle";
import styled from "styled-components";
import { Image } from "../../../components/Image";
import { useI18n } from "../../../hooks/useI18n";
import { RegularSmallButton } from "../../../components/Buttons";
import { useHopper } from "../../../hooks/useHopper";

function FanCircleCard(props: { circle: Circle }) {
  const i18n = useI18n();
  const hopper = useHopper();
  return (
    <CardFrame onClick={() => hopper.push(`circle/${props.circle.circleId}`)}>
      <Image
        src={[props.circle.circleIcon, { width: 65, height: 65 }]}
        style={{
          width: 65,
          height: 65,
          borderRadius: 6,
          border: `1px solid ${props.circle.themeColor}`,
        }}
      />
      <InfoFrame>
        <CircleName>{props.circle.name}</CircleName>
        <CircleMemberCount>
          {i18n
            .plural(props.circle.membersCount ?? 0)
            .number_of_members(props.circle.membersCount ?? 0)}
        </CircleMemberCount>
      </InfoFrame>
      <ActionFrame>
        <RegularSmallButton style={{ height: 20 }}>
          {i18n.join()}
        </RegularSmallButton>
      </ActionFrame>
    </CardFrame>
  );
}

const CardFrame = styled.div`
  width: calc(100% - 40px);
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.2);
`;

const InfoFrame = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ActionFrame = styled.div`
  height: 100%;
  display: flex;
  justify-content: end;
  flex-direction: column;
`;

const CircleName = styled.div`
  font-size: 14px;
`;

const CircleMemberCount = styled.div`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
`;
export default FanCircleCard;
