import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import styled from "styled-components";
import { NavBarBoundary } from "./NavBar";
import { ObscuredZoneBoundary } from "./ObscuredZoneBoundary";
import { useHopper } from "../hooks/useHopper";
import { RouterProvider } from "react-router-dom";
import { useReactRoutes } from "../service/ReactRoutesService";
import { WideLayoutContext } from "./WideAppShellLayout";

type AuxPos = "start" | "end";

interface Context {
  setAuxPage: (url: string, position: AuxPos) => void;
  removeAuxPage: () => void;
}

const Context = createContext<Context>({
  setAuxPage: (url: string, position: AuxPos) => {},
  removeAuxPage: () => {},
});

const PageFrame = styled.div`
  position: relative;
  overflow: hidden; // don't allow document scrolling

  display: grid;
  justify-content: stretch;
`;

const Pane = styled.div`
  display: flex;
  position: relative;
  overflow: hidden; // don't allow document scrolling

  justify-content: stretch;
  align-items: stretch;
`;

function getColSpec(auxPosition: AuxPos | undefined) {
  switch (auxPosition) {
    case "end":
      return "1fr 400px";
    case "start":
      return "400px 1fr";
    default:
      return "1fr";
  }
}

export function AuxPageBoundary(props: PropsWithChildren<{}>) {
  const [auxPage, setAuxPage] = useState<{
    url: string;
    position: AuxPos;
  }>({
    url: "home-discover-head",
    position: "end",
  });

  const hopper = useHopper();
  const routes = useReactRoutes();

  const auxPane = auxPage ? (
    <Pane id={"aux-pane"}>
      <ObscuredZoneBoundary>
        <NavBarBoundary canDismiss={false} onBackward={() => {}}>
          <WideLayoutContext.Provider
            value={{
              setWideLayout: (l) => {},
            }}
          >
            <RouterProvider
              key={auxPage.url}
              router={routes.createMemoryRouter("/" + auxPage.url)}
            />
          </WideLayoutContext.Provider>
        </NavBarBoundary>
      </ObscuredZoneBoundary>
    </Pane>
  ) : undefined;

  return (
    <PageFrame style={{ gridTemplateColumns: getColSpec(auxPage?.position) }}>
      <Context.Provider
        value={{
          setAuxPage: (url: string, pos: AuxPos) => {
            setAuxPage({ url: url, position: pos });
          },
          removeAuxPage: () => {
            setAuxPage({
              url: "home-discover-head",
              position: "end",
            });
          },
        }}
      >
        {auxPage && auxPage.position === "start" && auxPane}

        <Pane id={"main-pane"}>
          <ObscuredZoneBoundary>
            <NavBarBoundary canDismiss={false} onBackward={() => hopper.back()}>
              {props.children}
            </NavBarBoundary>
          </ObscuredZoneBoundary>
        </Pane>

        {auxPage && auxPage.position === "end" && auxPane}
      </Context.Provider>
    </PageFrame>
  );
}

export function AuxPage(props: { url: string; position?: AuxPos }) {
  const context = useContext(Context);
  useEffect(() => {
    context.setAuxPage(props.url, props.position ?? "end");
    return () => {
      context.removeAuxPage();
    };
  }, []);
  return <></>;
}
