import { ParagraphStyle, TextStyle } from "./RichFormat";
import { ZDocOptions } from "./ZDocCommon";
import { CSSProperties } from "react";

export function getDefaultText(
  textStyle: TextStyle | undefined,
  paragraphStyle: ParagraphStyle | undefined,
) {
  if (textStyle === undefined) {
    return undefined;
  }

  const textDecor: string[] = [];
  if (textStyle.underline) {
    textDecor.push("underline");
  }
  if (textStyle.strikethrough) {
    textDecor.push("line-through");
  }

  const style: CSSProperties = {
    backgroundColor: textStyle.backgroundColor,
    color: textStyle.foregroundColor,
    textDecoration: textDecor ? textDecor.join(" ") : undefined,
    fontWeight: textStyle.bold ? "bold" : undefined,
    fontStyle: textStyle.italic ? "oblique" : undefined,
    wordBreak: "break-all",
  };

  return {
    elementType: "span",
    props: { style: style },
  };
}

export function ZDocAtom(props: {
  textStyle: TextStyle | undefined;
  paragraphStyle: ParagraphStyle | undefined;
  content: string;
  options?: ZDocOptions;
}) {
  const getText = props.options?.overrides?.text ?? getDefaultText;
  const text = getText(props.textStyle, props.paragraphStyle);
  if (text === undefined) {
    return <>{props.content}</>;
  } else {
    return <text.elementType {...text.props}>{props.content}</text.elementType>;
  }
}
