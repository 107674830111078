import React, {
  isValidElement,
  PropsWithChildren,
  useLayoutEffect,
  useMemo,
} from "react";
import { ExternalAppShellData } from "../bridge/ExternalAppShellData";
import { Bridge, decodeTPS, encodeTPS, getBridgeHook } from "../bridge/Bridge";
import { HopHost, HopHostContext, useHopHost } from "../hooks/useHopState";
import { ObscuredZoneBoundary } from "../components/ObscuredZoneBoundary";
import { RouterProvider } from "react-router-dom";
import { zStatic } from "../utils/zodUtils";
import { andLog } from "../components/handleError";
import {
  AbstractHopper,
  HopperContext,
  HopperMode,
  HopperRefer,
} from "../hooks/useHopper";
import { WebHost } from "../bridge/WebHost";
import { AppShellContext } from "../hooks/useAppShell";
import { useI18n } from "../hooks/useI18n";
import { ExternalAuthSessionService } from "../service/AuthSessionService";
import { ExternalHttpClientService } from "../service/HttpClientService";
import { APIService } from "../service/APIService";
import { urlAppendQuery, UrlQuery } from "../utils/UrlUtil";
import { ModalBoundary } from "../components/ModalContext";
import { RepoStoreBuilder } from "../service/RepoStoreService";
import { RepoStore } from "../hooks/swr/RepoStore";
import { NavBarContext, NavItem } from "../components/NavBar";
import { useExternalSafeAreaInsets } from "../hooks/useSafeAreaInsets";
import { useObscuredZones } from "../hooks/useObscuredZones";
import { PullToRefresh } from "../components/PullToRefresh";
import { LayerBoundary } from "./LayerBoundary";
import { NavButtonDesc } from "../bridge/NavButtonDesc";
import { NativePage } from "../bridge/NativePage";
import { useReactRoutes } from "../service/ReactRoutesService";

export type ExternalAppShellStateStore = {
  setStateValue: (value: string, key: string) => Promise<void>;
  removeStateValue: (key: string) => Promise<void>;
};

function HopHostService(
  props: PropsWithChildren<{ data: zStatic<typeof ExternalAppShellData> }>,
) {
  const externalHopHost = useMemo(() => {
    const stateStore = getBridgeHook<ExternalAppShellStateStore>(
      Bridge.getInstance(),
      "webHost",
    );
    return new HopHost(
      props.data.webHostId,
      props.data.webHostCreatedTime,
      props.data.savedStates,
      (value, key) => stateStore.setStateValue(value, key),
      (key) => stateStore.removeStateValue(key),
    );
  }, [props.data]);

  return (
    <HopHostContext.Provider
      value={{
        hopHostForId: (pageId) => externalHopHost,
      }}
    >
      {props.children}
    </HopHostContext.Provider>
  );
}

function HopperService(props: PropsWithChildren<{}>) {
  const hopHost = useHopHost();
  const routes = useReactRoutes();

  function deepLinkFromBridge(
    path: string,
    param:
      | {
          mode?: HopperMode;
          resultId?: string;
          resultEnv?: any;
        }
      | undefined,
    query: UrlQuery | undefined,
    refer: HopperRefer | undefined,
  ) {
    const totalQuery = {
      mode: param?.mode ?? "push",
      resultId: param?.resultId,
      resultEnv: param?.resultEnv ? encodeTPS(param?.resultEnv) : undefined,
      ...query,
    };

    return (
      routes.webLinkToDeepLink(`/${path}`, totalQuery) ??
      urlAppendQuery(`web-bridge/${path}`, totalQuery)
    );
  }

  return (
    <HopperContext.Provider
      value={{
        hopper: new (class extends AbstractHopper {
          webHost = getBridgeHook<{
            openLink: (path: string) => Promise<void>;
            dismissLayer: (push: string) => Promise<void>;
            dismissModal: () => Promise<void>;
          }>(Bridge.getInstance(), "webHost");

          nativePage = getBridgeHook<{ navigateBack: () => Promise<void> }>(
            Bridge.getInstance(),
            "nativePage",
          );

          openBridge(
            path: string,
            param:
              | {
                  mode?: HopperMode;
                  resultId?: string;
                  resultEnv?: any;
                }
              | undefined,
            query: UrlQuery | undefined,
            refer: HopperRefer | undefined,
          ): void {
            const deepLink = deepLinkFromBridge(path, param, query, refer);

            (async () => {
              await hopHost.allSetStateTasksSettled();
              await this.webHost.openLink(deepLink);
            })().catch(andLog);
          }

          back() {
            (async () => {
              await hopHost.allSetStateTasksSettled();
              await this.nativePage.navigateBack();
            })().catch(andLog);
          }

          dismissLayer() {
            (async () => {
              await this.webHost.dismissLayer("");
            })().catch(andLog);
          }

          dismissLayerAndPush(path: string, query?: UrlQuery) {
            (async () => {
              const deepLink = deepLinkFromBridge(
                path,
                { mode: "push" },
                query,
                undefined,
              );
              await this.webHost.dismissLayer(deepLink);
            })().catch(andLog);
          }

          dismissModal() {
            (async () => {
              await this.webHost.dismissModal();
            })().catch(andLog);
          }
        })(),
      }}
    >
      {props.children}
    </HopperContext.Provider>
  );
}

function AppShellService(props: PropsWithChildren<{}>) {
  const i18n = useI18n();

  const nativePage = useMemo(() => {
    return getBridgeHook<NativePage>(Bridge.getInstance(), "nativePage");
  }, []);

  const webHost = useMemo(() => {
    return getBridgeHook<WebHost>(Bridge.getInstance(), "webHost");
  }, []);

  return (
    <AppShellContext.Provider
      value={{
        webHost: webHost,
        nativePage: nativePage,
      }}
    >
      {props.children}
    </AppShellContext.Provider>
  );
}

interface Hook {
  localRepoStorePut(
    memoryOnly: boolean,
    contentId: string,
    content: string,
  ): Promise<void>;

  localRepoStoreDelete(memoryOnly: boolean, contentId: string): Promise<void>;

  localRepoStoreGet(
    memoryOnly: boolean,
    contentId: string,
  ): Promise<{ content: string; updatedAt: number }>;
}

export class ExternalRepoStore<T> implements RepoStore<T> {
  constructor(
    private readonly hook: Hook,
    readonly contentId: string,
    private readonly memoryOnly: boolean,
  ) {}

  delete(): Promise<void> {
    return this.hook.localRepoStoreDelete(this.memoryOnly, this.contentId);
  }

  async get(): Promise<{ content: T; updatedAt: number } | undefined> {
    const r = await this.hook.localRepoStoreGet(
      this.memoryOnly,
      this.contentId,
    );
    return r.content
      ? {
          content: decodeTPS(r.content),
          updatedAt: r.updatedAt,
        }
      : undefined;
  }

  put(content: T): Promise<void> {
    return this.hook.localRepoStorePut(
      this.memoryOnly,
      this.contentId,
      encodeTPS(content),
    );
  }
}

function ExternalRepoStoreService(props: PropsWithChildren<{}>) {
  const hook = useMemo(() => {
    return getBridgeHook<Hook>(Bridge.getInstance(), "webHost");
  }, []);

  return (
    <RepoStoreBuilder.Provider
      value={{
        createLocalRepoStore<T>(contentId: string): RepoStore<T> {
          return new ExternalRepoStore(hook, contentId, false);
        },
        createMemoryRepoStore<T>(contentId: string): RepoStore<T> {
          return new ExternalRepoStore(hook, contentId, true);
        },
      }}
    >
      {props.children}
    </RepoStoreBuilder.Provider>
  );
}

interface ExternalNavBar {
  setNavBarEndButton: (
    button: NavButtonDesc,
    action: () => void,
  ) => Promise<void>;
  setNavBarEndButton2: (
    button: NavButtonDesc,
    action: () => void,
    button2: NavButtonDesc,
    action2: () => void,
  ) => Promise<void>;

  removeAllNavBarEndButtons: () => Promise<void>;
  setNavBarStartButton: (
    button: NavButtonDesc,
    action: () => void,
  ) => Promise<void>;
  resetNavBarStartButtonsToBack: () => Promise<void>;

  setPageTitle: (title: string) => Promise<void>;
}

function ExternalNavBarBoundary(props: PropsWithChildren<{}>) {
  const safeInsets = useExternalSafeAreaInsets();
  const [_, setObscuredZone] = useObscuredZones();

  useLayoutEffect(() => {
    setObscuredZone("Browser", {
      top: safeInsets.top,
      bottom: safeInsets.bottom,
    });
  }, [setObscuredZone, safeInsets]);

  const externalNavBar = useMemo(() => {
    return getBridgeHook<ExternalNavBar>(Bridge.getInstance(), "nativePage");
  }, []);

  return (
    <NavBarContext.Provider
      value={{
        hasNavBar: true,
        setCanGoBack: () => {},
        setEndItems: (items: NavItem[]) => {
          const descs = items.flatMap((i) => (isValidElement(i) ? [] : [i]));
          if (descs.length === 1) {
            externalNavBar
              .setNavBarEndButton(descs[0].button, descs[0].action)
              .catch(andLog);
          } else if (descs.length > 1) {
            externalNavBar
              .setNavBarEndButton2(
                descs[0].button,
                descs[0].action,
                descs[1].button,
                descs[1].action,
              )
              .catch(andLog);
          } else {
            externalNavBar.removeAllNavBarEndButtons().catch(andLog);
          }
        },
        setStartItems: (items, shows) => {
          const descs = items.flatMap((i) => (isValidElement(i) ? [] : [i]));
          if (descs.length > 0) {
            externalNavBar
              .setNavBarStartButton(descs[0].button, descs[0].action)
              .catch(andLog);
          } else {
            externalNavBar.resetNavBarStartButtonsToBack().catch(andLog);
          }
        },
        setMiddleItem: (item) => {
          if (!isValidElement(item) && item) {
            externalNavBar.setPageTitle(item).catch(andLog);
          } else {
            externalNavBar.setPageTitle("").catch(andLog);
          }
        },
      }}
    >
      {props.children}
    </NavBarContext.Provider>
  );
}

export function ExternalAppShell(props: {
  data: zStatic<typeof ExternalAppShellData>;
}) {
  const routes = useReactRoutes();

  return (
    <ExternalRepoStoreService>
      <ExternalAuthSessionService
        myUid={props.data.loggedInUserId ?? undefined}
      >
        <ExternalHttpClientService>
          <APIService>
            <ObscuredZoneBoundary id={"root"}>
              <AppShellService>
                <HopHostService data={props.data}>
                  <HopperService>
                    <LayerBoundary
                      id={props.data.layerId}
                      refer={props.data.layerRefer}
                    >
                      <ModalBoundary>
                        <ExternalNavBarBoundary>
                          <PullToRefresh>
                            <RouterProvider router={routes.baseRouter} />
                          </PullToRefresh>
                        </ExternalNavBarBoundary>
                      </ModalBoundary>
                    </LayerBoundary>
                  </HopperService>
                </HopHostService>
              </AppShellService>
            </ObscuredZoneBoundary>
          </APIService>
        </ExternalHttpClientService>
      </ExternalAuthSessionService>
    </ExternalRepoStoreService>
  );
}
