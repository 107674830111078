import { useI18n } from "../../hooks/useI18n";
import { NavMiddle } from "../../components/NavBar";
import { Image } from "../../components/Image";
import { RegularButton } from "../../components/Buttons";
import {
  alpha_on_pressed,
  RowCenterButton,
} from "../../components/CommonStyles";
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { CircleTokenDeployStatus } from "./CircleTokenCreateIntroducePage";
import tokenDeploy from "../../res/images/token_deploy.png";
import tokenDeploying from "../../res/images/token_deploying.webp";
import tokenDeployed from "../../res/images/token_deployed.webp";
import { delay } from "../../utils/ensureDur";
import { useSWR } from "../../hooks/swr/useSWR";
import { useBackend } from "../../service/APIService";
import { andLog } from "../../components/handleError";
import { toDeployStatus } from "./CircleTokenInfoPage";
import { useNativePage } from "../../hooks/useBridge";
import { isRejectable } from "../../bridge/Rejectable";
import { APIErrorCode } from "../../proto/APIErrorRespBody";
import { useHopper } from "../../hooks/useHopper";

export function CircleTokenDeployStatusContent(props: {
  circleId: bigint;
  deployStatus: CircleTokenDeployStatus;
  viewDetailClick: () => void;
  doneClick: () => void;
}) {
  const i18n = useI18n();
  const deployStatus = props.deployStatus;
  const [currDeployStatus, setCurrDeployStatus] =
    useState<CircleTokenDeployStatus>(deployStatus);
  const [deployingImageOpacity, setDeployingImageOpacity] = useState(1);
  const [deployedImageOpacity, setDeployedImageOpacity] = useState(0);

  useEffect(() => {
    setCurrDeployStatus(deployStatus);
    if (deployStatus === CircleTokenDeployStatus.Deployed) {
      setDeployedImageOpacity(1);
      setDeployingImageOpacity(0);
    } else {
      setDeployedImageOpacity(0);
      setDeployingImageOpacity(1);
    }
  }, [deployStatus]);

  const descText = () => {
    switch (deployStatus) {
      case CircleTokenDeployStatus.Unbound:
        return "";
      case CircleTokenDeployStatus.Deploying:
        return i18n.clover_circle_token_deploying_tips();
      case CircleTokenDeployStatus.Deployed:
        return i18n.clover_circle_token_deployed_tips();
    }
  };

  const backend = useBackend();
  const tokenSWR = useSWR(
    props.deployStatus === CircleTokenDeployStatus.Deploying
      ? backend.getCircleToken(props.circleId)
      : undefined,
  );

  const [shouldFetch, setShouldFetch] = useState(
    props.deployStatus === CircleTokenDeployStatus.Deploying,
  );

  const hopper = useHopper();
  const nativePage = useNativePage();

  useEffect(() => {
    if (!shouldFetch) return;
    // 每 10 秒触发一次请求
    const intervalId = setInterval(() => {
      tokenSWR?.load().catch(async (e) => {
        if (
          isRejectable(e) &&
          e.apiCode === APIErrorCode.APICodeCircleTokenCreateFailed
        ) {
          //TODO:StevenQ alertYesOrCancel make cancel nil
          const ok = await nativePage.alertYesOrCancel(
            i18n.clover_circle_token_create_failed_tips(),
            i18n.ok(),
            "",
          );
          if (ok) {
            hopper.back();
          }
        }
      });
    }, 10000);
    return () => clearInterval(intervalId);
  }, [shouldFetch, tokenSWR?.content]);

  useEffect(() => {
    if (
      toDeployStatus(tokenSWR?.content) === CircleTokenDeployStatus.Deployed
    ) {
      animateDeployingToDeployed().catch(andLog);
      setShouldFetch(false);
    }
  }, [tokenSWR?.content]);

  async function animateDeployingToDeployed() {
    setDeployingImageOpacity(0);
    await delay(500);
    setCurrDeployStatus(CircleTokenDeployStatus.Deployed);
    setDeployedImageOpacity(1);
  }

  console.log(
    "CircleTokenDeployStatusContent",
    "deployStatus:",
    deployStatus,
    "currDeployStatus:",
    currDeployStatus,
  );

  return (
    <>
      <NavMiddle>{i18n.clover_circle_token()}</NavMiddle>
      <AnimationContent>
        <Image
          style={{ margin: 0, position: "absolute" }}
          src={tokenDeploy}
          alt={"tokenDeploy"}
          width={300}
          height={300}
        ></Image>
        {currDeployStatus === CircleTokenDeployStatus.Deploying && (
          <Image
            style={{
              margin: 0,
              position: "absolute",
              transition: "opacity 0.5s ease-out",
              opacity: deployingImageOpacity,
            }}
            src={tokenDeploying}
            alt={"tokenDeploying"}
            width={300}
            height={300}
          ></Image>
        )}
        {currDeployStatus === CircleTokenDeployStatus.Deployed && (
          <Image
            style={{
              margin: 0,
              position: "absolute",
              transition: "opacity 0.5s ease-in",
              opacity: deployedImageOpacity,
            }}
            src={tokenDeployed}
            alt={"tokenDeployed"}
            width={300}
            height={300}
          ></Image>
        )}
      </AnimationContent>
      <DescLabel>{descText()}</DescLabel>
      {currDeployStatus === CircleTokenDeployStatus.Deploying && (
        <ViewDetailsLabel onClick={props.viewDetailClick}>
          {i18n.folder_style_view_details()}
        </ViewDetailsLabel>
      )}
      {currDeployStatus === CircleTokenDeployStatus.Deployed && (
        <RegularButton
          style={{
            ...RowCenterButton,
            marginTop: 24,
          }}
          onClick={props.doneClick}
        >
          {i18n.done()}
        </RegularButton>
      )}
    </>
  );
}

const DescLabel = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: var(--color-text00);
  text-align: center;
`;

const ViewDetailsLabel = styled.div`
  color: #00c4ff;
  font-weight: 400;
  font-size: 14px;
  margin: 24px auto 0;
  ${alpha_on_pressed}
`;

const AnimationContent = styled.div`
  width: 300px;
  height: 300px;
  margin: 60px auto 48px;
`;

export default CircleTokenDeployStatusContent;
