export enum CircleMemberStatus {
  UNKNOWN = 0,
  JOINED = 1,
  LEFT = 2,
  BLOCKED = 3,
  NOTJOINED = 4,
  REMOVED = 5,
  REMOVEDBLOCKED = 6,
  INVITED = 7,
}
