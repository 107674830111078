import {
  BottomSheet,
  ModalController,
  ModalTitle,
} from "../../../components/Modal";
import React from "react";
import { useI18n } from "../../../hooks/useI18n";
import { HStack, VSpace, VStack } from "../../../components/VStack";
import icGooglePay from "../../../res/images/ic_google_pay.svg";
import icApplePay from "../../../res/images/ic_apple_pay.svg";
import icSolPay from "../../../res/images/ic_sol_pay.svg";
import icMorePay from "../../../res/images/ic_more_payment.svg";
import { Image } from "../../../components/Image";
import styled from "styled-components";
import { useHopper } from "../../../hooks/useHopper";
import { useSWR } from "../../../hooks/swr/useSWR";
import { useBackend } from "../../../service/APIService";
import { Media } from "../../../proto/Media";
import {
  isInExternalAppShell,
  isLiteApp,
} from "../../../bridge/ExternalAppShellData";
import { getOS } from "../../../utils/deviceModel";
import * as os from "node:os";
import { detect } from "detect-browser";

export function TopUpMethodMenu(props: { modal: ModalController }) {
  const i18n = useI18n();
  const hopper = useHopper();
  const backend = useBackend();
  const method = useSWR(backend.getSupportedThirdPartyPaymentMethods());

  return (
    <BottomSheet modal={props.modal}>
      <ModalTitle>{i18n.clover_select_your_payment_method()}</ModalTitle>

      <VStack>
        {!isLiteApp() ? (
          <HStack
            onClick={() => {
              props.modal.close();
              hopper.layer(`wallet/top-up-sol`);
            }}
            style={{
              gap: 16,
              height: 88,
              margin: "0 20px",
              borderBottom: "1px solid rgba(255, 255, 255, 0.16)",
            }}
          >
            <Image src={icSolPay} width={48} height={48} />
            <PayName>{i18n.clover_pay_with_sol()}</PayName>
          </HStack>
        ) : null}
        {isInExternalAppShell() &&
        (detect()?.os === "iOS" || detect()?.os === "Android OS") ? (
          <HStack
            onClick={() => {
              props.modal.close();
              hopper.layer(`wallet/top-up`);
            }}
            style={{
              gap: 16,
              height: 88,
              margin: "0 20px",
              borderBottom: "1px solid rgba(255, 255, 255, 0.16)",
            }}
          >
            <Image
              src={detect()?.os === "Android OS" ? icGooglePay : icApplePay}
              width={48}
              height={48}
            />
            <PayName>
              {detect()?.os === "Android OS"
                ? i18n.thrparty_pay_google()
                : i18n.thrparty_pay_apple_pay()}
            </PayName>
          </HStack>
        ) : null}
        {(method.content?.list?.length ?? 0) > 0 && (
          <HStack
            onClick={() => {
              props.modal.close();
              hopper.layer(`wallet/top-up-sol`, { thirdParty: true });
              // hopper.modal(`more-payment-modal`, { failed: true });
            }}
            style={{
              gap: 16,
              height: 88,
              margin: "0 20px",
            }}
          >
            <Image src={icMorePay} width={48} height={48} />
            <PayName>{i18n.clover_more_payment_options()}</PayName>
            <HStack style={{ gap: 4 }}>
              {method.content?.list
                ?.slice(0, 3)
                .map((method) => (
                  <Image src={[method.icon, 16]} width={16} height={16} />
                ))}
            </HStack>
          </HStack>
        )}

        <VSpace height={33} />
      </VStack>
    </BottomSheet>
  );
}

const PayName = styled.div`
  color: #ffffff;
  font-weight: 700;
  font-size: 16px;
`;
