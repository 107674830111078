import { Page } from "../../../components/Page";
import { useBigIntParam } from "../../../hooks/useTypedParam";
import { useSWR } from "../../../hooks/swr/useSWR";
import { useI18n } from "../../../hooks/useI18n";
import CircleBackgroundFrame from "../CircleBackgroundFrame";
import React, { useEffect, useState } from "react";
import SettingCell from "../../../components/SettingCell";
import { Spacing } from "../../../components/Spacing";
import { PlaceholderButton } from "../../../components/Buttons";
import { Image } from "../../../components/Image";
import ic_add from "../../../res/images/ic_add.svg";
import { CircleMemberTitle } from "../../../proto/CircleMemberTitle";
import TabCellSeparator from "../../../components/TabCellSeparator";
import ColoredLabel from "../../../components/ColoredLabel";
import { useHopper } from "../../../hooks/useHopper";
import { useBackend } from "../../../service/APIService";
import { NavMiddle } from "../../../components/NavBar";

function CircleVisibleTitlesPage() {
  const circleId = useBigIntParam("circleId");
  const backend = useBackend();
  const circleSWR = useSWR(backend.getCircle(circleId));
  const i18n = useI18n();
  const hopper = useHopper();
  const memberTitlesSWR = useSWR(backend.getCircleMemberTitles(circleId));

  const [visibleItems, setVisibleItems] = useState<CircleMemberTitle[]>();
  const [invisibleItems, setInvisibleItems] = useState<CircleMemberTitle[]>();

  useEffect(() => {
    if (memberTitlesSWR.content) {
      setVisibleItems(memberTitlesSWR.content.list.filter((it) => it.visible));
      setInvisibleItems(
        memberTitlesSWR.content.list.filter((it) => !it.visible),
      );
    }
  }, [memberTitlesSWR.content]);

  return (
    <Page
      pageData={[circleSWR, memberTitlesSWR]}
      scrollPaddingDisabled={true}
      underlay={
        <CircleBackgroundFrame
          circleBackground={circleSWR.content?.circleBackground}
        />
      }
    >
      <NavMiddle>{i18n.member_title_page_layout()}</NavMiddle>
      {visibleItems && (
        <>
          <TabCellSeparator
            title={i18n
              .plural(visibleItems.length)
              .member_title_visible_titles_count(visibleItems.length)}
          />
          {visibleItems.map((memberTitle, index) => {
            return (
              <SettingCell
                titleValue={
                  <ColoredLabel
                    backgroundColor={memberTitle.color}
                    style={{
                      padding: "4px 8px",
                      fontSize: 13,
                      fontWeight: 500,
                    }}
                  >
                    {memberTitle.title}
                  </ColoredLabel>
                }
                showAccessoryImage={false}
              />
            );
          })}
        </>
      )}

      {invisibleItems && (
        <>
          <TabCellSeparator
            title={i18n
              .plural(invisibleItems.length)
              .member_title_invisible_titles_count(invisibleItems.length)}
          />
          {invisibleItems.map((memberTitle, index) => {
            return (
              <SettingCell
                titleValue={
                  <ColoredLabel
                    backgroundColor={memberTitle.color}
                    style={{
                      padding: "4px 8px",
                      fontSize: 13,
                      fontWeight: 500,
                    }}
                  >
                    {memberTitle.title}
                  </ColoredLabel>
                }
                showAccessoryImage={false}
              />
            );
          })}
        </>
      )}

      <Spacing height={30} />
      <PlaceholderButton style={{ marginInlineStart: 30, marginInlineEnd: 30 }}>
        <Image src={ic_add} alt={"reveal"} />
        {i18n.edit()}
      </PlaceholderButton>
      <Spacing height={30} />
    </Page>
  );
}

export default CircleVisibleTitlesPage;
