import { Page } from "../../components/Page";
import { useI18n } from "../../hooks/useI18n";
import styled, { css } from "styled-components";
import { Image } from "../../components/Image";
import IcMigrate from "./images/ic_migrate.png";
import { RegularButton } from "../../components/Buttons";
import { Spacing } from "../../components/Spacing";
import { PowerMarkdown } from "../../components/PowerMarkdown";
import {
  accent_font,
  alpha_on_pressed,
  RowCenterButton,
} from "../../components/CommonStyles";
import { CenterModal, ModalController, useModal } from "../../components/Modal";
import { useBackend } from "../../service/APIService";
import { andLog } from "../../components/handleError";
import { useWebHost } from "../../hooks/useBridge";
import { useGlobalSpinner } from "../../utils/globalSpinner";
import IcSuccess from "./../../res/images/ic_hud_success.svg";
import {
  useAuthSessionService,
  useMyUid,
} from "../../service/AuthSessionService";
import { useHopper } from "../../hooks/useHopper";
import { useSWR } from "../../hooks/swr/useSWR";
import { Spring } from "../../components/VStack";
import { SWRFillCancelled } from "../../hooks/swr/SWRRepo";

const Title = styled.div`
  ${accent_font(16)};
  color: #fff;
  text-align: center;
`;

const Info = css`
  font-size: 14px;
  font-weight: normal;
  color: white;
  opacity: 0.4;
`;

const LogOut = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: red;
  padding: 10px 10px;

  text-align: center;
  ${alpha_on_pressed};
`;

function MigratedModal(props: { modal: ModalController; onClose: () => void }) {
  const i18n = useI18n();

  return (
    <CenterModal
      modal={props.modal}
      mixin={css`
        align-items: center;
      `}
      hideCloseBtn={true}
    >
      <Image src={IcSuccess} style={{ width: 84, height: 84 }} />
      <div>{i18n.clover_migrate_account_migrated()}</div>
      <RegularButton
        onClick={() => props.modal.close(() => props.onClose())}
        style={{ width: "70%" }}
      >
        {i18n.ok()}
      </RegularButton>
    </CenterModal>
  );
}

export default function MigratePage() {
  const i18n = useI18n();
  const backend = useBackend();

  const myUid = useMyUid();
  const myUserSWR = useSWR(backend.getUser(myUid), {
    reloadPolicy: "neverRefetch",
  });

  const hopper = useHopper();
  const webHost = useWebHost();
  const migratedModal = useModal("account-migrated");
  const spinner = useGlobalSpinner();

  const authSessionService = useAuthSessionService();

  const onMigrate = async () => {
    await spinner(async () => {
      await backend.migrate2Clover().run();
      migratedModal.open();
    });
  };

  const onLogOut = async () => {
    await spinner(async () => {
      await backend.logOut().run();
      await authSessionService.remove(myUid);
    });
  };

  async function onClose() {
    await myUserSWR.fill((prev) => {
      if (prev?.content === undefined) throw new SWRFillCancelled();
      return {
        ...prev.content,
        extensions: {
          ...prev.content.extensions,
          isCloverUser: true,
        },
      };
    }); // web
    await myUserSWR.load(); // web
    await webHost.broadcast("ON_USER_MIGRATED", {}); // iOS
    hopper.dismissModal(); // android
  }

  return (
    <Page pageData={undefined} maxContentWidth={600}>
      <Spring />

      <Image src={IcMigrate} style={{ width: 200, margin: "0 auto" }} />
      <Spacing height={10}></Spacing>
      <Title>{i18n.clover_migrate_account_migration()}</Title>
      <Spacing height={20}></Spacing>
      <PowerMarkdown textStyle={Info}>
        {i18n.clover_migrate_account_migration_info()}
      </PowerMarkdown>
      <Spacing height={24}></Spacing>
      <RegularButton
        style={{ ...RowCenterButton }}
        onClick={() => onMigrate().catch(andLog)}
      >
        {i18n.clover_migrate_migrate()}
      </RegularButton>
      <LogOut
        style={{ ...RowCenterButton }}
        onClick={() => onLogOut().catch(andLog)}
      >
        {i18n.auth_log_out()}
      </LogOut>

      <Spring />
      <Spring />
      <MigratedModal
        modal={migratedModal}
        onClose={() => onClose().catch(andLog)}
      />
    </Page>
  );
}
