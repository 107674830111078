import styled, { css, RuleSet } from "styled-components";
import { linear_gradient_border } from "./vscroll/SVGUtils";
import {
  accent_font,
  alpha_on_pressed,
  button_on_pressed,
} from "./CommonStyles";
import colorSetAlpha from "color-alpha";
import strong_button_border from "../res/images/strong_button_border.png";
import strong_large_button_border from "../res/images/strong_large_button_border.png";

function genBg(baseColor: string | undefined) {
  return `
  linear-gradient(
    -30deg,
    ${colorSetAlpha(baseColor ?? "white", 0.1)} 0%,
    ${colorSetAlpha(baseColor ?? "white", 0.03)} 55%,
    ${colorSetAlpha(baseColor ?? "white", 0.1)} 100%
  )
  `;
}

function genBorder(baseColor: string | undefined) {
  return linear_gradient_border(
    6,
    1,
    -30,
    [colorSetAlpha(baseColor ?? "white", 0.5), 0],
    [colorSetAlpha(baseColor ?? "white", 0.1), 0.4],
    [colorSetAlpha(baseColor ?? "white", 0.1), 0.6],
    [colorSetAlpha(baseColor ?? "white", 0.5), 1],
  );
}

export { genBg, genBorder };

export const ButtonColor = {
  default: "white",
  greenish: "#0DFFCC",
  bluish: "#00E6FF",
  destructive: "#EF3537",
};

const CenterContent = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  text-align: center;

  & > img {
    width: 28px;
    height: 28px;
  }
`;

const DefaultButtonHeight = css`
  min-height: 48px;
  padding: 0 20px;
`;

export const StrongButton = styled.button`
  ${CenterContent};
  ${DefaultButtonHeight};
  font-size: 16px;
  color: var(--color-text00);
  font-style: normal;
  font-weight: 400;
  position: relative;
  box-sizing: border-box;

  border-radius: 6px;
  background: transparent;

  border-image: url(${strong_button_border}) 36 198 fill/ 12px 66px / 6px 6px
    7px 6px stretch;

  &:disabled {
    opacity: 0.4;
  }

  ${button_on_pressed}
`;

export const StrongLargeButton = styled.button`
  ${CenterContent};
  min-height: 60px;
  color: var(--color-text00);
  ${accent_font(20)};
  font-style: normal;
  position: relative;
  box-sizing: border-box;

  border-radius: 6px;
  background: transparent;

  border-image: url(${strong_large_button_border}) 36 198 fill/ 12px 66px / 6px
    6px 7px 6px stretch;

  &:disabled {
    opacity: 0.4;
  }

  ${button_on_pressed}
`;

export const RegularButton = styled.button<{
  $baseBgColor?: string;
  $baseColor?: string;
}>`
  ${CenterContent};
  ${DefaultButtonHeight};
  font-size: 16px;
  color: ${(p) => p.$baseColor ?? "white"};
  font-style: normal;
  font-weight: 400;
  position: relative;

  box-sizing: border-box;

  border-radius: 6px;

  background: ${(p) => `${p.$baseBgColor ?? "transparent"} ${genBg(p.$baseColor)};`}

  border-image: ${(p) => genBorder(p.$baseColor)};

  &:disabled {
    opacity: 0.4;
  }

  ${button_on_pressed}
`;

export const RegularSmallButton = styled.button<{
  $baseBgColor?: string;
  $baseColor?: string;
}>`
  ${CenterContent};
  min-height: 32px;
  padding: 0 10px;
  font-size: 12px;
  color: ${(p) => p.$baseColor ?? "white"};
  font-style: normal;
  font-weight: 400;
  position: relative;

  box-sizing: border-box;

  border-radius: 6px;

  background: ${(p) => `${p.$baseBgColor ?? "transparent"} ${genBg(p.$baseColor)};`}

  border-image: ${(p) => genBorder(p.$baseColor)};

  &:disabled {
    opacity: 0.4;
  }

  ${button_on_pressed}
`;

export const RegularLargeButton = styled.button<{
  $baseBgColor?: string;
  $baseColor?: string;
}>`
  ${CenterContent};
  min-height: 60px;
  padding: 0 20px;
  color: ${(p) => p.$baseColor ?? "white"};
  ${accent_font(20)};
  text-transform: uppercase;
  font-style: normal;
  font-weight: 700;
  position: relative;
  box-sizing: border-box;

  border-radius: 6px;

  background: ${(p) => `${p.$baseBgColor ?? "transparent"} ${genBg(p.$baseColor)};`}

  border-image: ${(p) => genBorder(p.$baseColor)};

  &:disabled {
    opacity: 0.4;
  }

  ${button_on_pressed}
`;

export const WeakButton = styled.button<{
  $baseBgColor?: string;
  $baseColor?: string;
}>`
  ${CenterContent};
  ${DefaultButtonHeight};
  font-size: 16px;
  color: ${(p) => p.$baseColor ?? "white"};
  font-style: normal;
  font-weight: 400;
  position: relative;

  box-sizing: border-box;
  border-radius: 6px;

  border-width: 1px;
  border-style: solid;
  border-color: ${(p) => colorSetAlpha(p.$baseColor ?? "white", 0.5)};

  background: transparent;

  &:disabled {
    opacity: 0.4;
  }

  ${button_on_pressed}
`;

export const PlaceholderButton = styled.button<{ mixin?: RuleSet<Object> }>`
  ${CenterContent};
  min-height: 56px;
  padding: 0 20px;
  font-size: 16px;
  color: ${(p) => colorSetAlpha("white", 0.7)};
  font-style: normal;
  font-weight: 400;
  border-width: 1px;
  position: relative;
  box-sizing: border-box;

  border-color: rgba(255, 255, 255, 0.2);
  border-radius: 6px;
  border-style: dashed;
  background: rgba(255, 255, 255, 0.08);

  &:disabled {
    opacity: 0.4;
  }

  ${button_on_pressed}
  ${(p) => p.mixin}
`;

export const LinkButton = styled.div<{ $underline?: boolean }>`
  font-size: 14px;
  text-align: center;
  color: rgba(40, 197, 243, 1);
  text-decoration: ${(p) => (p.$underline === false ? "none" : "$underline")};
  box-sizing: border-box;
  ${alpha_on_pressed}
`;
