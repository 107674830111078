import { NavMiddle } from "../../../components/NavBar";
import { Page } from "../../../components/Page";
import { useBackend } from "../../../service/APIService";
import { useSWR } from "../../../hooks/swr/useSWR";
import { useBigIntParam } from "../../../hooks/useTypedParam";
import React from "react";
import { NFT } from "../../../proto/NFT";
import CircleCardsPreviewCell, {
  CardStyle,
} from "../card/CircleCardsPreviewCell";
import {
  formatMoney,
  getCurrencyIcon,
  getCurrencyName,
} from "../../../proto/Currency";
import { I18n, useI18n } from "../../../hooks/useI18n";
import { HStack, Spring, VStack } from "../../../components/VStack";
import { Circle } from "../../../proto/Circle";
import { CircleMembershipLevelIcon } from "./MembershipLevelPicker";
import { Image } from "../../../components/Image";
import iconEdit from "../../../res/images/ic_edit.svg";
import { AlertButton, WhiteAlert } from "../../../components/WhiteAlert";
import { useModal } from "../../../components/Modal";
import { Spacing } from "../../../components/Spacing";
import emptyIcon from "../../../res/images/empty_icon.png";
import { useHopper } from "../../../hooks/useHopper";
import { CircleCardStatus } from "../../../proto/CircleCardStatus";

function CircleLevelsItemCell(props: {
  nft: NFT;
  circle: Circle;
  i18n: I18n;
  onClick?: () => void;
}) {
  const card = props.nft.circleMembershipCard;
  return (
    <>
      <HStack
        style={{
          marginInlineStart: 16,
          marginInlineEnd: 16,
          gap: 12,
          height: 45,
          marginTop: 8,
          marginBottom: 8,
          display: "flex",
        }}
        onClick={props.onClick}
      >
        <div
          style={{
            fontSize: 10,
            fontWeight: 400,
            color: "rgba(255, 255, 255, 0.3)",
          }}
        >
          {`LV.${card?.membershipLevel ?? 0}`}
        </div>
        <div style={{ width: 33, height: 18 }}>
          <CircleCardsPreviewCell
            card={props.nft.circleMembershipCard}
            circle={props.circle}
            cardStyle={CardStyle.verySmall}
            includesPrice={false}
            borderRadius={2}
          />
        </div>

        <VStack style={{ gap: 8, flexGrow: 1 }}>
          <HStack style={{ gap: 4 }}>
            <div style={{ fontSize: 14, fontWeight: 500 }}>
              {props.nft.name ?? props.circle.name}
            </div>
            <CircleMembershipLevelIcon
              level={card?.membershipLevel ?? 1}
              width={30}
              adminSize={30}
            />
            <Spring />
            <Image src={iconEdit} width={12} height={12} />
          </HStack>

          <HStack style={{ gap: 4 }}>
            <div style={{ fontSize: 10, fontWeight: 300, color: "white" }}>
              {props.i18n.clover_token_requirement()}
            </div>
            <Image
              width={16}
              height={16}
              style={{ borderRadius: "2px" }}
              src={getCurrencyIcon(card?.currency)}
            />
            <div style={{ fontSize: 14, fontWeight: 400 }}>
              {formatMoney("medium", card?.currency)}
            </div>
            <div style={{ fontSize: 14, fontWeight: 400 }}>
              {getCurrencyName(card?.currency, props.i18n)}
            </div>
            <Spring />
          </HStack>
        </VStack>
      </HStack>
      {card?.membershipLevel && card.membershipLevel !== 10 && (
        <div
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.1)",
            height: 1,
            marginInlineStart: 16,
            marginInlineEnd: 16,
          }}
        ></div>
      )}
    </>
  );
}

export function MembershipLevelsPage() {
  const circleId = useBigIntParam("circleId");
  const i18n = useI18n();
  const backend = useBackend();
  const circleSWR = useSWR(backend.getCircle(circleId));
  const cardsSWR = useSWR(
    backend.getCircleCardsList(circleId, CircleCardStatus.Enable),
  );

  const comingSoonModal = useModal("comingSoonModal");

  const hopper = useHopper();

  return (
    <Page pageData={cardsSWR}>
      <NavMiddle>{i18n.clover_manage_membership_levels()}</NavMiddle>
      <VStack
        style={{
          backgroundColor: "rgba(255, 255, 255, 0.05)",
          borderRadius: 6,
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: "rgba(255, 255, 255, 0.06)",
        }}
      >
        {circleSWR.content &&
          cardsSWR.content?.list
            ?.filter(
              (nft: NFT) =>
                Number(nft.circleMembershipCard?.membershipLevel ?? "0") > 0,
            )
            ?.map((card, index) => {
              return (
                circleSWR.content && (
                  <CircleLevelsItemCell
                    nft={card}
                    circle={circleSWR.content}
                    i18n={i18n}
                    onClick={() => {
                      comingSoonModal.open();
                    }}
                  />
                )
              );
            })}
      </VStack>

      {cardsSWR.content?.list.length === 0 && (
        <VStack
          style={{
            alignItems: "center",
            marginInlineStart: 40,
            marginInlineEnd: 40,
          }}
        >
          <Spacing height={100} />
          <Image src={emptyIcon} width={120} height={120} />
          <div
            style={{
              fontSize: 14,
              fontWeight: 400,
              color: "rgba(255, 255, 255, 0.4)",
              textAlign: "center",
            }}
          >
            {i18n.clover_will_appear_has_token()}
          </div>
          <div
            style={{
              marginTop: 24,
              fontSize: 14,
              fontWeight: 400,
              color: "rgba(52, 166, 255, 1)",
              textDecoration: "underline",
            }}
            onClick={() => {
              hopper.push(`circle/${circleId}/admin-portal`);
            }}
          >
            {i18n.clover_go_to_admin_portal()}
          </div>
          <Spacing height={30} />
        </VStack>
      )}

      <WhiteAlert modal={comingSoonModal} title={i18n.clover_coming_soon()}>
        <AlertButton
          onClick={() => {
            comingSoonModal.close();
          }}
        >
          {i18n.ok()}
        </AlertButton>
      </WhiteAlert>
    </Page>
  );
}
