import BigNumber from "bignumber.js";
import styled from "styled-components";

import { useI18n } from "../../hooks/useI18n";
import {
  Currency,
  CurrencyType,
  formatMoney,
  getCurrencyIcon,
  getCurrencyName,
  MonetaryFormat,
} from "../../proto/Currency";
import { HStack } from "../../components/VStack";
import { Image } from "../../components/Image";

import chevron from "../../../src/res/images/icon_section_header_show_more.svg";

interface GridTokenCellProps {
  currency: Currency;
  format?: MonetaryFormat;
  onClickToken?: () => void;
}

function GridTokenCell(props: GridTokenCellProps) {
  const i18n = useI18n();
  return (
    <GridTokenBg onClick={props.onClickToken}>
      <HStack style={{ justifyContent: "space-between" }}>
        <HStack style={{ gap: 4 }}>
          <Image src={getCurrencyIcon(props.currency)} width={20} height={20} />
          <BalanceName>{getCurrencyName(props.currency, i18n)}</BalanceName>
        </HStack>

        {/*<Image src={chevron} width={20} height={20} />*/}
      </HStack>

      <BalanceValue>
        {formatMoney(props.format || "short", props.currency)}
      </BalanceValue>
      <BalanceUSDValue
        style={{
          opacity: [
            CurrencyType.COIN,
            CurrencyType.DIAMOND,
            CurrencyType.GEMS,
          ].includes(props.currency.currencyType)
            ? 0
            : 1,
        }}
      >{`$${formatMoney("medium", BigNumber(props.currency.usdPrice ?? "0"))} USD`}</BalanceUSDValue>
    </GridTokenBg>
  );
}

const GridTokenBg = styled.div`
  padding: 12px;
  flex-direction: column;
  border-radius: 6px;
  display: flex;
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.06);
`;

const BalanceName = styled.div`
  color: var(--color-text00);
  font-weight: 400;
  font-size: 12px;
`;

const BalanceValue = styled.div`
  color: var(--color-text00);
  font-weight: 500;
  margin-top: 12px;
  text-align: start;
  font-size: 20px;
`;

const BalanceUSDValue = styled.div`
  color: var(--color-text20);
  font-weight: 400;
  margin-top: 8px;
  text-align: start;
  font-size: 10px;
`;

export default GridTokenCell;
