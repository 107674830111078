import { CenterModal, ModalTitle, useModal } from "../../components/Modal";
import { Page } from "../../components/Page";
import { HStack, Spring } from "../../components/VStack";
import iconLock from "../../res/images/img_circle_join_lock.png";
import React, { useRef, useState } from "react";
import { RuleSet, styled } from "styled-components";
import { RegularInputGroup } from "../../components/Input";
import { RegularButton } from "../../components/Buttons";
import { useI18n } from "../../hooks/useI18n";
import { useSWR } from "../../hooks/swr/useSWR";
import { useBigIntParam } from "../../hooks/useTypedParam";
import { useBackend } from "../../service/APIService";
import {
  CircleJoinPermission,
  JoinCircleBody,
  JoinCircleBodyQuestion,
  JoinCircleMethod,
} from "./JoinCircleRequest";
import { useLoadState } from "../../hooks/LoadState";
import { andLog, useErrorHandler } from "../../components/handleError";
import { useHopper } from "../../hooks/useHopper";
import { useNativePage } from "../../hooks/useBridge";

const QuestionTitle = styled.div<{ mixin?: RuleSet<Object> }>`
  font-size: 18px;
  font-weight: 500;
  color: var(--color-text00);
  text-align: center;
  word-break: break-word;
  ${(p) => p.mixin};
`;

const TextArea = styled.textarea`
  height: 100%;
  background-color: rgba(255, 255, 255, 0.06);
  border-color: rgba(255, 255, 255, 0.06);
  border-radius: 12px;
  font-size: 16px;
  font-weight: 500;
  padding: 20px;
  margin-inline-start: 15px;
  margin-inline-end: 15px;
  color: #ffffff;
  -webkit-tap-highlight-color: transparent;

  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  resize: none;
`;

export function CircleJoinQuestionPage() {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const circleId = useBigIntParam("circleId");
  const backend = useBackend();
  const circleSWR = useSWR(backend.getCircle(circleId));
  const [canSend, setCanSend] = useState(false);
  const i18n = useI18n();
  const handleError = useErrorHandler();

  const nativePage = useNativePage();
  const hopper = useHopper();

  const joinCircleTask = useLoadState();

  async function requestToJoin() {
    const questionId = circleSWR.content?.extensions?.questionId ?? BigInt(0);
    const body: JoinCircleBodyQuestion = {
      answer: inputRef.current?.value ?? "",
      questionId: questionId,
      receiveCircleUpdate: true,
    };

    const r = await joinCircleTask.run(async () => {
      await backend.joinCircleInQuestion(circleId, body).run();
    });

    if (r.success) {
      hopper.back();
      // questionModal.close();
    } else {
      hopper.back();
      // questionModal.close();
    }
  }

  const [passcodeInput, setPasscodeInput] = useState("");
  const passcodeModal = useModal("passcodeModal");

  async function requestPasscodeToJoin() {
    const body: JoinCircleBody = {
      joinMethod: JoinCircleMethod.USEPASSCODE,
      joinToken: passcodeInput,
    };
    const r = await joinCircleTask.run(async () => {
      const circle = await backend.joinCircle(circleId, body).run();
      await circleSWR.fill(circle);
    });

    if (!r.success) {
      handleError(r.error);
    } else {
      passcodeModal.close();
      hopper.back();
    }
  }

  function canUsePasscode() {
    const permission =
      circleSWR.content?.joinPermission ?? CircleJoinPermission.OPEN;
    if (permission & CircleJoinPermission.USE_PASSCODE) {
      return true;
    }
    return false;
  }

  return (
    <Page pageData={undefined}>
      <HStack>
        <Spring />
        <img src={iconLock} width={84} height={84} />
        <Spring />
      </HStack>
      <QuestionTitle style={{ marginTop: 20, marginBottom: 32 }}>
        {circleSWR.content?.extensions?.question?.content}
      </QuestionTitle>

      <TextArea
        ref={inputRef}
        placeholder={i18n.answer_placeholder()}
        style={{ height: 150 }}
        onInput={(e) => {
          setCanSend((inputRef.current?.textLength || 0) > 0);
        }}
      ></TextArea>

      <HStack>
        <Spring />
        <RegularButton
          disabled={!canSend}
          style={{ width: 210, marginTop: 32 }}
          onClick={() => {
            requestToJoin().catch(andLog);
          }}
        >
          {i18n.circle_perm_submit_request()}
        </RegularButton>
        <Spring />
      </HStack>

      {canUsePasscode() && (
        <HStack
          style={{ marginTop: 20 }}
          onClick={() => {
            passcodeModal.open();
          }}
        >
          <Spring />
          <div
            style={{
              fontSize: 14,
              fontWeight: 500,
              color: "#00FF94",
              textDecoration: "underline",
            }}
          >
            {i18n.circle_perm_enter_passcode_instead()}
          </div>
          <Spring />
        </HStack>
      )}

      <CenterModal modal={passcodeModal}>
        <HStack>
          <Spring />
          <img src={iconLock} width={84} height={84} />
          <Spring />
        </HStack>

        <ModalTitle style={{ fontSize: 16, fontWeight: 500 }}>
          {i18n.circle_perm_enter_the_passcode()}
        </ModalTitle>

        <RegularInputGroup updateValue={setPasscodeInput}></RegularInputGroup>

        <RegularButton
          onClick={() => {
            requestPasscodeToJoin().catch();
          }}
        >
          {i18n.ok()}
        </RegularButton>
      </CenterModal>
    </Page>
  );
}
