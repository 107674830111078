import { CenterModal, useModalWithItsOwnPage } from "../../../components/Modal";
import { useI18n } from "../../../hooks/useI18n";
import { useHopper } from "../../../hooks/useHopper";
import {
  useBigIntParam,
  useBigIntSearchParam,
} from "../../../hooks/useTypedParam";
import { PowerMarkdown } from "../../../components/PowerMarkdown";
import React, { useMemo } from "react";
import { RegularButton } from "../../../components/Buttons";
import styled, { css } from "styled-components";
import { CenterChild, HStack, VSpace } from "../../../components/VStack";
import { useSWR } from "../../../hooks/swr/useSWR";
import { useBackend } from "../../../service/APIService";
import CircleCardsPreviewCell, { CardStyle } from "./CircleCardsPreviewCell";
import { AdminCard } from "../../wallet/swap/SwapPage";

export function PurchaseCardModal() {
  const modal = useModalWithItsOwnPage();
  const i18n = useI18n();
  const hopper = useHopper();
  const circleId = useBigIntParam("circleId");
  const backend = useBackend();
  const adminCardSWR = useSWR(backend.getAdminMembershipCard(circleId));
  const circleSWR = useSWR(backend.getCircle(circleId));
  return (
    <CenterModal modal={modal} mixin={ModalStyle}>
      <CenterChild>
        <Title>{i18n.clover_purchase_membership_card()}</Title>
      </CenterChild>
      <VSpace height={2} />
      {adminCardSWR.content?.circleMembershipCard && (
        <CircleCard>
          <CircleCardsPreviewCell
            card={adminCardSWR.content?.circleMembershipCard}
            circle={circleSWR.content}
            cardStyle={CardStyle.large}
            width={180}
          />
        </CircleCard>
      )}
      <CenterChild style={{ flexDirection: "column" }}>
        <CardName>{i18n.clover_admin_membership_card()}</CardName>
      </CenterChild>

      <PowerMarkdown textStyle={markdown_style}>
        {i18n.clover_purchase_admin_card_instruction()}
      </PowerMarkdown>
      <VSpace height={10} />
      <RegularButton
        style={{ marginLeft: 25, marginRight: 25 }}
        onClick={() => {
          modal.close(() => {
            hopper.layer(`circle/${circleId}/launch-token-summary`);
          });
        }}
      >
        {i18n.web3_v0_purchase()}
      </RegularButton>
    </CenterModal>
  );
}

const CircleCard = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

const CardName = styled.div`
  color: var(--color-text00);
  font-weight: 400;
  font-size: 11;
`;

const Title = styled.div`
  color: var(--color-text00);
  font-weight: 600;
  font-size: 18;
`;

const StakeQuantityLabel = styled.div`
  color: var(--color-text00);
  font-weight: 500;
  font-size: 14;
`;

const StakeQuantityValue = styled.div`
  color: var(--color-text00);
  font-weight: 600;
  font-size: 14;
`;

const markdown_style = css`
  color: rgba(255, 255, 255, 0.6);
  font-weight: 400;
  font-size: 12px;
  margin-top: 16px;
`;

const ModalStyle = css`
  padding: 30px 22px 25px;
  gap: 10px;
`;
