export function isSpongeKit() {
  const hostname = window.location.hostname;
  return (
    hostname.includes("spongekit.com") ||
    hostname.startsWith("192.168.") ||
    hostname.startsWith("172.16.") ||
    hostname.startsWith("127.0.0.") ||
    hostname.startsWith("10.") ||
    hostname === "localhost"
  );
}
