import { Page } from "../../components/Page";
import { NavMiddle } from "../../components/NavBar";
import { useI18n } from "../../hooks/useI18n";
import styled from "styled-components";
import { HStack, VStack } from "../../components/VStack";
import cardIcon from "../../res/images/ic_match_card.svg";
import { ButtonColor, RegularButton } from "../../components/Buttons";
import TopUpCell from "./TopUpCell";
import { useSWR } from "../../hooks/swr/useSWR";
import { UserPropsType } from "../../proto/Wallet";
import { useBackend } from "../../service/APIService";
import { useSWRArray } from "../../hooks/swr/useSWRArray";
import { useHopper } from "../../hooks/useHopper";
import { TxSheet, usePerformTx } from "./payment/TransactionDialog";
import { closeModal, useModal } from "../../components/Modal";
import React from "react";
import { useTransactionBody } from "./send/SendTokenPage";
import { CurrencyType } from "../../proto/Currency";
import { UserPropsPackage } from "../../proto/UserPropsPackage";
import { PaymentItem } from "./payment/PaymentItem";
import { TopUpType } from "./TopUpType";
import { OrderType } from "./order/OrderDetailPage";
import CurrencyTransactionView from "./payment/CurrencyTransactionView";

function MatchPassesPage() {
  const i18n = useI18n();
  const backend = useBackend();
  const myPropsSWR = useSWR(backend.getUserProps());
  const packageSWR = useSWRArray(
    backend.getUserPropsPackages(UserPropsType.matchPass),
  );
  const hopper = useHopper();
  const onTextMatch = () => {
    hopper.push(`text-match`);
  };
  const paymentModal = useModal("payment-modal");
  const editingBody = useTransactionBody();
  const onVoiceMatch = () => {
    hopper.push(`voice-match`);
  };

  const performTx = usePerformTx(
    async () => {
      const resp = await backend
        .purchaseUserProps(
          editingBody.content.packageId ?? BigInt(0),
          editingBody.content,
        )
        .run();

      return resp.orderId;
    },
    async (orderId: bigint | undefined) => {
      await closeModal(paymentModal);
      await editingBody.clear();
      await myPropsSWR.load();
      if (orderId) {
        hopper.push(`wallet/order/${orderId}`, {
          orderType: OrderType.OtherOrder,
          showDialog: true,
          title: i18n.web3_insuf_purchase_completed(),
        });
      }
    },
  );

  const onTopUp = async (pack: UserPropsPackage) => {
    const currencyList: PaymentItem[] = [
      {
        currencyType: pack.price?.currencyType || CurrencyType.GEMS,
        amount: pack.price?.amount || "0",
      },
    ];
    await editingBody.fill((prevData) => ({
      ...prevData,
      packageId: pack.packageId,
      count: 1,
      paymentItemList: currencyList,
      currency: pack.price,
      amount: pack.price?.amount ?? "0",
      currencyType: pack.price?.currencyType ?? CurrencyType.GEMS,
      currencyId: pack.price?.currencyId,
    }));
    paymentModal.open();
  };

  return (
    <Page alwaysShowsContent pageData={[myPropsSWR, packageSWR]}>
      <HStack
        style={{
          width: "100%",
          justifyContent: "space-between",
          paddingBottom: 20,
          borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
        }}
      >
        <Title>{i18n.web3_v0_available()}</Title>
        <Amount>
          <CardIcon />
          {i18n
            .plural(myPropsSWR.content?.priorityMatchPassesAvailableUnits ?? 0)
            .aigc_quota_units(
              myPropsSWR.content?.priorityMatchPassesAvailableUnits ?? 0,
            )}
        </Amount>
      </HStack>

      <VStack
        style={{
          marginTop: 20,
          paddingBottom: 20,
          borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
        }}
      >
        <Title>{i18n.cash_rewards_instructions()}</Title>
        <Instructions>
          {i18n.lite_asset_priority_match_passes_description()}
        </Instructions>
        <HStack style={{ marginTop: 20, width: "100%", gap: 11 }}>
          <RegularButton
            $baseColor={ButtonColor.bluish}
            style={{ flex: 1 }}
            onClick={onTextMatch}
          >
            {i18n.meet_now_option_text_match()}
          </RegularButton>
          <RegularButton
            $baseColor={ButtonColor.greenish}
            style={{ flex: 1 }}
            onClick={onVoiceMatch}
          >
            {i18n.meet_now_option_video_match()}
          </RegularButton>
        </HStack>
      </VStack>

      <Title style={{ marginTop: 20 }}>{i18n.web3_hongbao_top_up()}</Title>
      <TopUpContainer>
        {packageSWR.content.map((pack) => (
          <TopUpCell
            onClick={onTopUp}
            key={pack.packageId}
            package={pack}
            topUpType={TopUpType.MATCH_CARD}
          />
        ))}
      </TopUpContainer>

      <TxSheet modal={paymentModal} performTx={performTx}>
        <CurrencyTransactionView body={editingBody.content} />
      </TxSheet>

      <NavMiddle>{i18n.lite_asset_priority_match_passes()}</NavMiddle>
    </Page>
  );
}

const TopUpContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;

  > * {
    flex-basis: calc(33.33% - 10px); // 每行3个，减去10px是为了考虑间距
    margin: 5px; // 可选，增加元素间的间距
  }
`;

const Instructions = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.4);
  margin-top: 12px;
`;

const CardIcon = styled.div`
  width: 24px;
  height: 24px;
  background-image: url("${cardIcon}");
`;

const Amount = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  font-weight: 700;
  font-size: 18px;
  color: #ffffff;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
`;

export default MatchPassesPage;
