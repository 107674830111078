import { LoadState } from "../LoadState";
import { StateId } from "../StateId";
import { SWRConfig, useSWRRepo } from "./useSWRRepo";
import { omit, pick } from "../../utils/pick";
import { RepoDataSource, SWRRepoData } from "./SWRRepo";
import { EndPointLike, isEndPointLike } from "../../service/EndPoint";
import { assert } from "../../utils/asserts";

export type SWRSingleMeta<T> = Omit<
  SWRRepoData<T, undefined>,
  "content" | "updatedMoreAt" | "hasMore" | "cursor"
>;

export type SWRSingle<T extends {}> = {
  readonly contentId: StateId;
  readonly content: T | undefined;
  readonly meta: SWRSingleMeta<T>;
  readonly loadState: LoadState | undefined;
  readonly load: (reason?: string) => Promise<void>;
  readonly fill: (
    content:
      | T
      | ((
          prev:
            | {
                content: T;
                updatedAt: number;
                source: RepoDataSource;
              }
            | undefined,
        ) => T),
  ) => Promise<T>;
};

function getActualArgs<T extends {}>(
  arg0: StateId | EndPointLike<T> | undefined,
  arg1?: (() => Promise<T>) | SWRConfig,
  arg2?: SWRConfig,
): [
  StateId | undefined,
  (() => Promise<T>) | undefined,
  SWRConfig | undefined,
] {
  if (arg0 === undefined) {
    return [undefined, undefined, undefined];
  }
  if (isEndPointLike(arg0)) {
    const contentId = arg0.getId();
    const fetcher = () => arg0.run();
    return [contentId, fetcher, arg1 as SWRConfig | undefined];
  } else {
    assert(arg1 !== undefined, "no fetcher defined");
    return [arg0, arg1 as () => Promise<T>, arg2];
  }
}

export function useSWR<T extends {}>(
  contentId: StateId,
  fetcher: () => Promise<T>,
  config?: SWRConfig,
): SWRSingle<T>;

export function useSWR<T extends {}>(
  contentId: StateId | undefined,
  fetcher: (() => Promise<T>) | undefined,
  config?: SWRConfig,
): SWRSingle<T> | undefined;

export function useSWR<T extends {}>(
  endPoint: EndPointLike<T>,
  config?: SWRConfig,
): SWRSingle<T>;

export function useSWR<T extends {}>(
  endPoint: EndPointLike<T> | undefined,
  config?: SWRConfig,
): SWRSingle<T> | undefined;

export function useSWR<T extends {}>(
  arg0: StateId | EndPointLike<T> | undefined,
  arg1?: (() => Promise<T>) | SWRConfig,
  arg2?: SWRConfig,
): any {
  const [contentId, fetcher, config] = getActualArgs(arg0, arg1, arg2);

  const repo = useSWRRepo(
    contentId,
    undefined,
    undefined,
    fetcher
      ? () =>
          fetcher().then((r) => {
            return { content: r, cursor: undefined, hasMore: false };
          })
      : undefined,
    config,
  );

  if (repo === undefined) {
    return undefined;
  }

  return {
    contentId: contentId,
    content: repo.data.content,
    meta: omit(repo.data, ["content", "updatedMoreAt", "hasMore", "cursor"]),
    loadState: repo.loadState,
    load: async (reason?: string) => await repo.repo.reload(reason),
    fill: async (
      contentOrSetContent: unknown | ((prev: T | undefined) => T),
    ) => {
      if (typeof contentOrSetContent === "function") {
        const result = await repo.repo.fill((prev) => {
          return {
            content: contentOrSetContent(
              prev ? pick(prev, ["content", "updatedAt", "source"]) : undefined,
            ),
            cursor: undefined,
            hasMore: false,
          };
        });
        return result?.content;
      } else {
        const result = await repo.repo.fill({
          content: contentOrSetContent as T,
          cursor: undefined,
          hasMore: false,
        });
        return result?.content;
      }
    },
  };
}
