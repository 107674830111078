import { ContentGroup } from "../../../components/ContentGroup";
import { useI18n } from "../../../hooks/useI18n";
import styled from "styled-components";
import iconBuiltIn from "../../../res/images/icon_built_in_wallet.svg";
import { HStack, VSpace, VStack } from "../../../components/VStack";
import { Image } from "../../../components/Image";
import React, { useMemo } from "react";
import {
  Currency,
  CurrencyType,
  formatMoney,
  getCurrencyIcon,
  getCurrencyName,
} from "../../../proto/Currency";
import { ZeroGems, ZeroSOL } from "../swap/SwapPage";
import { useRegularAccount } from "../useRegularAccount";
import { useNativePage } from "../../../hooks/useBridge";
import { useSWR } from "../../../hooks/swr/useSWR";
import { useBackend } from "../../../service/APIService";
import iconSOL from "../../../res/images/icon_sol.svg";
import iconGEMS from "../../../res/images/icon_gems.svg";

export interface PaymentAccountProps {
  withSolBalance?: boolean;
  highPriorityCurrencyId?: bigint;
  fallbackCurrency?: Currency;
}

function PaymentAccount(props: PaymentAccountProps) {
  const i18n = useI18n();
  const regularAccount = useRegularAccount();
  const nativePage = useNativePage();
  const backend = useBackend();

  const walletAccountId = regularAccount?.walletAccountId;

  const currencyListSWR = useSWR(
    walletAccountId ? backend.getCurrencyList(walletAccountId) : undefined,
  );
  const solCurrency = useMemo(() => {
    const currencyList = currencyListSWR?.content?.list;
    if (currencyList) {
      return (
        currencyList.find(
          (item) => item.currency.currencyType === CurrencyType.SOL,
        )?.currency ?? ZeroSOL
      );
    }
  }, [currencyListSWR?.content]);

  const highPriorityCurrency = useMemo(() => {
    const currencyList = currencyListSWR?.content?.list;
    if (currencyList) {
      return (
        currencyList
          ?.find(
            (item) => item.currency.currencyId === props.highPriorityCurrencyId,
          )
          ?.accountCurrencyList?.at(0)?.balance ?? props.fallbackCurrency
      );
    }
  }, [currencyListSWR?.content]);

  const gemsCurrency = useMemo(() => {
    const currencyList = currencyListSWR?.content?.list;
    if (currencyList) {
      return (
        currencyList.find(
          (item) => item.currency.currencyType === CurrencyType.GEMS,
        )?.currency ?? ZeroGems
      );
    }
  }, [currencyListSWR?.content]);

  return (
    <ContentGroup>
      <Label>{i18n.web3_mint_coupon_payment_method()}</Label>
      <HStack style={{ gap: 8, marginTop: 12 }}>
        <Image
          src={iconBuiltIn}
          width={32}
          height={32}
          style={{ borderRadius: 4 }}
        />
        <Value>{i18n.arthur_wallet_built_in_account()}</Value>
      </HStack>

      <VSpace height={8} />
      <HStack style={{ alignItems: "start", gap: 10 }}>
        <Available>{i18n.web3_v0_available()}</Available>
        <VStack style={{ gap: 6 }}>
          {highPriorityCurrency && (
            <HStack style={{ gap: 4 }}>
              <Image
                src={getCurrencyIcon(highPriorityCurrency)}
                width={20}
                height={20}
                style={{ borderRadius: 4 }}
              />
              <AvailableValue>
                {formatMoney("medium", highPriorityCurrency)}
              </AvailableValue>
              <AvailableValue>
                {getCurrencyName(highPriorityCurrency, i18n)}
              </AvailableValue>
            </HStack>
          )}

          {(!highPriorityCurrency || props.withSolBalance) && solCurrency && (
            <HStack style={{ gap: 4 }}>
              <Image
                src={iconSOL}
                width={20}
                height={20}
                style={{ borderRadius: 4 }}
              />
              <AvailableValue>
                {formatMoney("medium", solCurrency)}
              </AvailableValue>
              <AvailableValue>
                {getCurrencyName(solCurrency, i18n)}
              </AvailableValue>
            </HStack>
          )}

          {/*{!highPriorityCurrency && gemsCurrency && (*/}
          {/*  <HStack style={{ gap: 4 }}>*/}
          {/*    <Image*/}
          {/*      src={iconGEMS}*/}
          {/*      width={20}*/}
          {/*      height={20}*/}
          {/*      style={{ borderRadius: 4 }}*/}
          {/*    />*/}
          {/*    <AvailableValue>*/}
          {/*      {formatMoney("medium", gemsCurrency)}*/}
          {/*    </AvailableValue>*/}
          {/*    <AvailableValue>*/}
          {/*      {getCurrencyName(gemsCurrency, i18n)}*/}
          {/*    </AvailableValue>*/}
          {/*  </HStack>*/}
          {/*)}*/}
        </VStack>
      </HStack>
    </ContentGroup>
  );
}

const Available = styled.div`
  color: rgba(255, 255, 255, 0.4);
  font-weight: 300;
  font-size: 12px;
`;

const AvailableValue = styled.div`
  color: var(--color-text00);
  font-weight: 400;
  font-size: 12px;
`;

const Label = styled.div`
  color: var(--color-text00);
  font-weight: 500;
  font-size: 14px;
`;

const Value = styled.div`
  color: var(--color-text00);
  font-weight: 500;
  font-size: 14px;
`;

export default PaymentAccount;
