import { CircleTabItem } from "../../proto/Circle";
import { useI18n } from "../../hooks/useI18n";
import { useSWR } from "../../hooks/swr/useSWR";
import { useBigIntParam } from "../../hooks/useTypedParam";
import { useBackend } from "../../service/APIService";
import { ReactElement } from "react";
import { PostDetailPage } from "../post/PostDetailPage";
import { CircleOtherLinkPage } from "./CircleOtherLinkPage";
import { CircleChatLinkPage } from "./CircleChatLinkPage";
import { CircleCircleLinkPage } from "./CircleCircleLinkPage";
import { InjectParams } from "../../hooks/InjectParams";

export interface CircleLinkPageProps {
  circleId: bigint;
  folderId: bigint;
  tab: CircleTabItem;
}

function CircleLinkPage(props: CircleLinkPageProps) {
  const backend = useBackend();
  const i18n = useI18n();
  const circleId = useBigIntParam("circleId");

  const linkSWR = useSWR(backend.linkPathFrom(props.tab.link ?? ""));

  const getPage = (): ReactElement => {
    const linkComponents =
      linkSWR.content?.path?.toLowerCase().split("/").filter(Boolean) ?? [];
    let page: ReactElement;

    if (linkComponents.length > 1) {
      const objectId = BigInt(linkComponents[1]);
      const firstStr = linkComponents[0];

      if (firstStr === "blog") {
        page = (
          <InjectParams params={{ postId: linkComponents[1] }}>
            <PostDetailPage />
          </InjectParams>
        );
      } else if (firstStr === "chat") {
        page = <CircleChatLinkPage threadId={objectId} />;
      } else if (firstStr === "circle") {
        page = <CircleCircleLinkPage circleId={objectId} />;
      } else {
        page = (
          <CircleOtherLinkPage
            tab={props.tab}
            path={linkSWR.content?.path ?? ""}
          />
        );
      }
    } else {
      page = <></>;
    }
    return page;
  };

  return getPage();
}

export default CircleLinkPage;
