import {
  CenterModal,
  ModalController,
  useModalWithItsOwnPage,
} from "../../../components/Modal";
import { VStack } from "../../../components/VStack";
import icPaymentCancel from "../../../res/images/ic_payment_cancel.svg";
import icPaymentFailed from "../../../res/images/ic_payment_failed.svg";
import { Image } from "../../../components/Image";
import { useBooleanSearchParam } from "../../../hooks/useTypedParam";
import styled from "styled-components";
import { useI18n } from "../../../hooks/useI18n";
import { RegularButton } from "../../../components/Buttons";
import { useWebHost } from "../../../hooks/useBridge";
import { andLog } from "../../../components/handleError";

export function MorePaymentModal() {
  const modal = useModalWithItsOwnPage();
  const failed = useBooleanSearchParam("failed", false);
  const i18n = useI18n();
  const webHost = useWebHost();
  const onMorePayment = () => {
    webHost.broadcast("ON_MORE_PAYMENT", {}).catch(andLog);
    modal.close();
  };

  return (
    <CenterModal hideCloseBtn modal={modal}>
      <VStack style={{ width: "100%", alignItems: "center" }}>
        <Image
          src={failed ? icPaymentFailed : icPaymentCancel}
          width={84}
          height={84}
        />
        <ModalString>
          {failed ? i18n.clover_pay_failed() : i18n.clover_pay_cancel()}
        </ModalString>
        <RegularButton style={{ marginTop: 32 }} onClick={onMorePayment}>
          {i18n.clover_more_payment_options()}
        </RegularButton>
        <Close
          onClick={() => {
            modal.close();
          }}
        >
          {i18n.close()}
        </Close>
      </VStack>
    </CenterModal>
  );
}

const ModalString = styled.div`
  color: white;
  font-size: 18px;
  font-weight: 500;
  margin-top: 24px;
  text-align: center;
`;

const Close = styled.div`
  color: #ffffff;
  font-weight: 500;
  font-size: 15px;
  margin-top: 20px;
`;
