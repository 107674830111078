import { z } from "zod";
import { zBigInt, zStatic } from "../utils/zodUtils";
import { User } from "./User";
import { Media } from "./Media";

export const PollItem = z.object({
  pollId: zBigInt,
  pollItemId: zBigInt,
  createdTime: z.number().default(0),
  votedUsers: z.array(User).optional(),
  icon: Media.optional(),
  content: z.string().default(""),
  votedValue: z.number().optional(),
  votesCount: z.number().default(0),
});

export type PollItem = zStatic<typeof PollItem>;

export const Poll = z.object({
  pollId: zBigInt,
  uid: zBigInt,
  parentId: zBigInt,
  parentType: z.number(),
  duration: z.number().default(0),
  createdTime: z.number().default(0),
  expireTime: z.number().optional(),
  title: z.string().default(""),
  pollItemList: z.array(PollItem).optional(),
});

export type Poll = zStatic<typeof Poll>;
