import { Page } from "../../../components/Page";
import { useBigIntParam } from "../../../hooks/useTypedParam";
import { useSWR } from "../../../hooks/swr/useSWR";
import { useI18n } from "../../../hooks/useI18n";
import { ObjectType } from "../../../proto/ObjectSpec";
import CircleMenuDividerCell from "../CircleMenuDividerCell";
import { CircleLegacyFolderName } from "../../../proto/CircleLegacyFolderName";
import iconExplore from "../../../res/images/icon_circle_folder_explore.png";
import iconPosts from "../../../res/images/icon_circle_folder_posts.png";
import iconChats from "../../../res/images/icon_circle_folder_chats.png";
import CircleMenuFolderCell from "../CircleMenuFolderCell";
import { Image } from "../../../components/Image";
import CircleMenuIconCell from "../../../proto/CircleMenuIconCell";
import { CircleFolderItem } from "../../../proto/CircleFolderItem";
import { PlaceholderButton } from "../../../components/Buttons";
import ic_add from "../../../res/images/ic_add.svg";
import React from "react";
import { Spacing } from "../../../components/Spacing";
import CircleBackgroundFrame from "../CircleBackgroundFrame";
import { Hopper, useHopper } from "../../../hooks/useHopper";
import { useBackend } from "../../../service/APIService";
import { resolvePath } from "../../../components/CircleWidgetBanner";
import { useLoadState } from "../../../hooks/LoadState";
import { useErrorHandler } from "../../../components/handleError";
import { NavMiddle } from "../../../components/NavBar";

function CircleFolderSettingsPage() {
  const circleId = useBigIntParam("circleId");
  const backend = useBackend();
  const circleSWR = useSWR(backend.getCircle(circleId));
  const i18n = useI18n();
  const hopper = useHopper();
  const linkTarget = useLoadState();
  const handleError = useErrorHandler();

  async function openInternalLink(link: string) {
    const r = await linkTarget.run(async () => {
      const linkModel = await backend.linkPathFrom(link).run();
      linkModel.path && resolvePath(linkModel.path, hopper);
    });

    if (!r.success) {
      handleError(r.error);
    } else {
      // hopper.back();
    }
  }

  return (
    <Page
      pageData={circleSWR}
      scrollPaddingDisabled={true}
      underlay={
        <CircleBackgroundFrame
          circleBackground={circleSWR.content?.circleBackground}
        />
      }
    >
      <NavMiddle>{i18n.circle2_1_circle_folders()}</NavMiddle>
      {circleSWR.content?.menu?.folderItems?.map((folderItem, index) => {
        if (folderItem.objectType === ObjectType.SECTION_HEADER) {
          return <CircleMenuDividerCell title={folderItem.objectName ?? ""} />;
        } else {
          let iconResource = "";
          if (folderItem.objectName === CircleLegacyFolderName.Explore) {
            iconResource = iconExplore;
          } else if (folderItem.objectName === CircleLegacyFolderName.Posts) {
            iconResource = iconPosts;
          } else if (folderItem.objectName === CircleLegacyFolderName.Chats) {
            iconResource = iconChats;
          }
          return (
            <CircleMenuFolderCell
              icon={
                iconResource.length > 0 ? (
                  <Image src={iconResource} width={38} height={38} />
                ) : (
                  <CircleMenuIconCell
                    themeColor={circleSWR.content?.themeColor}
                    folderItem={folderItem}
                  />
                )
              }
              showDivider={
                index !==
                (circleSWR.content?.menu?.folderItems?.length ?? 0) - 1
              }
              title={folderItem.folder?.name}
              onClick={() => {
                onClickCircleFolder(hopper, folderItem);
              }}
            />
          );
        }
      })}
      <Spacing height={30} />
      <PlaceholderButton style={{ marginInlineStart: 30, marginInlineEnd: 30 }}>
        <Image src={ic_add} alt={"reveal"} />
        {i18n.circle2_1_add_new_folder()}
      </PlaceholderButton>
      <Spacing height={30} />
    </Page>
  );
}

export const onClickCircleFolder = (
  hopper: Hopper,
  folderItem: CircleFolderItem,
) => {
  if (folderItem.objectName === CircleLegacyFolderName.Explore) {
    hopper.push(`circle/${folderItem.circleId}/explore`, {
      folderId: folderItem.objectId,
    });
  } else if (folderItem.objectName === CircleLegacyFolderName.Posts) {
    hopper.push(`circle/${folderItem.circleId}/posts`, {
      folderId: folderItem.objectId,
    });
  } else if (folderItem.objectName === CircleLegacyFolderName.Chats) {
    hopper.push(`circle/${folderItem.circleId}/chats`, {
      folderId: folderItem.objectId,
    });
  } else if (folderItem.objectType === ObjectType.LINK) {
  } else {
    hopper.push(`circle/${folderItem.circleId}/folder`, {
      folderId: folderItem.objectId,
    });
  }
};

export default CircleFolderSettingsPage;
