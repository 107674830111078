import { z } from "zod";
import { zBigInt, zStatic } from "../utils/zodUtils";
import { CircleBackground } from "./CircleBackground";
import { Media } from "./Media";

export const SimpleCircle = z.object({
  circleId: zBigInt,
  circleIcon: Media.optional(),
  name: z.string().optional().default(""),
  themeColor: z.string().optional(),
  membersCount: z.number().optional(),
});

export type SimpleCircle = zStatic<typeof SimpleCircle>;

export const WidgetItem = z.object({
  widgetItemId: zBigInt,
  widgetId: zBigInt,
  title: z.string().optional(),
  targetLink: z.string().optional(),
  widgetItemType: z.number(),
  creatorUid: zBigInt.optional(),
  createdTime: z.number().optional(),
  background: CircleBackground.optional(),
  lastModifiedTime: z.number().optional(),
  circlePreviewList: z.array(SimpleCircle).optional().default([]),
});
export type WidgetItem = zStatic<typeof WidgetItem>;
