import { Page } from "../../../components/Page";
import { useBigIntParam } from "../../../hooks/useTypedParam";
import { useSWR } from "../../../hooks/swr/useSWR";
import { useI18n } from "../../../hooks/useI18n";
import { useHopper } from "../../../hooks/useHopper";
import { useBackend } from "../../../service/APIService";

function CircleManageCoAdminsPage() {
  const circleId = useBigIntParam("circleId");
  const backend = useBackend();
  const circleSWR = useSWR(backend.getCircle(circleId));
  const i18n = useI18n();
  const hopper = useHopper();
  return <Page pageData={circleSWR}></Page>;
}

export default CircleManageCoAdminsPage;
