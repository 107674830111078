import { I18n, useI18n } from "../../hooks/useI18n";
import { useNativePage } from "../../hooks/useBridge";
import React, { useEffect, useRef, useState } from "react";
import { Page, PageShoulder } from "../../components/Page";
import styled, { css } from "styled-components";
import { useBigIntParam } from "../../hooks/useTypedParam";
import { useSWR } from "../../hooks/swr/useSWR";
import { UserAvatarView } from "../../components/views/UserAvatarView";
import { UserNameView } from "../../components/views/UserNameView";
import { shortFromDate } from "../../utils/DateTimeUtils";
import bgFan from "../../res/images/bg_fan_count.png";
import editIcon from "../../res/images/edit_profile.svg";
import notification from "../../res/images/notification_icon.svg";
import notificationYellow from "../../res/images/notification_yellow_icon.svg";
import TagsFlowLayout from "../../components/TagsFlowLayout";
import messageIcon from "../../res/images/leave_message_icon.svg";
import { useSWRArray } from "../../hooks/swr/useSWRArray";
import {
  BottomSheet,
  CenterModal,
  ModalTitle,
  useModal,
} from "../../components/Modal";
import { RegularInputGroup } from "../../components/Input";
import { RegularButton, WeakButton } from "../../components/Buttons";
import { Chat } from "../../proto/Chat";
import { useMyUid } from "../../service/AuthSessionService";
import { andLog, useErrorHandler } from "../../components/handleError";
import { PostCell } from "../post/PostCell";
import { Spin } from "../../components/Spin";
import { useLoadState } from "../../hooks/LoadState";
import UserBackgroundFrame from "./UserBackgroundFrame";
import {
  AudioCell,
  AudioFlow,
  ImageCell,
  VideoCell,
} from "../post/PostMediaContainer";
import MiniCircleMemberCard from "../circle/MiniCircleMemberCard";
import { FullPageWidthCell } from "../../components/CommonStyles";
import { HStack, VSpace, VStack } from "../../components/VStack";
import { ChatThreadType } from "../../proto/ChatThreadType";
import { NavEnd, NavItem } from "../../components/NavBar";
import { NavButtonType } from "../../bridge/NavButtonDesc";
import { BSMenu, BSMenuItem } from "../../components/BSMenu";
import { useBackend } from "../../service/APIService";
import { useObscuredZoneForKey } from "../../hooks/useObscuredZones";
import { Image } from "../../components/Image";
import hintIcon from "../../res/images/ic_hint.svg";
import unCheckedIcon from "../../res/images/ic_unchecked.svg";
import checkedIcon from "../../res/images/ic_checked.svg";
import { subscribeOrNot, User } from "../../proto/User";
import { useHopper } from "../../hooks/useHopper";
import emptyIcon from "../../res/images/empty_icon.png";
import ExtSocialCell from "./ExtSocialCell";
import ListScroller from "../common/ListScroller";
import wallBg from "../../res/images/wall_bg.svg";
import MercuryMenu from "../../components/MercuryMenu";
import followedIcon from "../../res/images/ic_followed.svg";
import friendIcon from "../../res/images/ic_friend.svg";
import favIcon from "../../res/images/ic_favorites.svg";
import favFriendIcon from "../../res/images/ic_fav_friends.svg";
import { ZDoc } from "../../components/zdoc/ZDoc";
import { useHopState } from "../../hooks/useHopState";
import { useGlobalSpinner } from "../../utils/globalSpinner";
import {
  getOrderedFilteredMediaList,
  isAudio,
  isImage,
  isVideo,
  Media,
} from "../../proto/Media";
import FanCircleCard from "../circle/compose/FanCircleCard";
import { Attachment } from "../../components/zdoc/Attachment";
import { FollowType } from "../../proto/UserEnums";
import { ObjectType } from "../../proto/ObjectSpec";
import { FlagMediaLocationEnum } from "../../proto/Flag";
import { MediaOwner } from "../../proto/MediaOwner";
import { useMemoryRepoMap } from "../../hooks/swr/useLocalRepo";
import { zBigInt } from "../../utils/zodUtils";
import { z } from "zod";
import { Reaction } from "../../proto/Reaction";
import { useReactionRecord } from "../../hooks/useReactionRecord";

export function takeAttachment(attachment: Attachment): any | null {
  switch (attachment.type) {
    case "media":
      return attachment.media;

    default:
      return null;
  }
}

export function timePair(time: number): { minute: number; deltaTime: number } {
  const currentTimeMillis = Date.now();
  const minute = Math.floor((currentTimeMillis - time * 1000) / 1000 / 60);
  const deltaTime = Math.floor(minute / 60);
  return { minute, deltaTime };
}

export function activeTime(
  minute: number,
  deltaTime: number,
  i18n: I18n,
): string {
  if (minute >= 0 && minute <= 15) {
    return i18n.user_active_now(); // Replace with actual localized string
  } else if (minute >= 16 && minute <= 60) {
    return i18n.user_active_minutes_ago(15); // Replace with actual localized string
  } else if (minute >= 61 && minute < 60 * 24) {
    return i18n.plural(deltaTime).user_active_hour(deltaTime); // Adjust pluralization logic
  } else {
    const days = Math.floor(deltaTime / 24);
    return i18n.plural(days).user_active_day(days); // Adjust pluralization logic
  }
}

export function timeSpan(
  minute: number,
  deltaTime: number,
  i18n: I18n,
): string {
  if (minute >= 0 && minute <= 15) {
    return i18n.date_minute_plurals_zero(); // Replace with actual localized string
  } else if (minute >= 16 && minute <= 60) {
    return i18n.plural(15).recent_visits_date_minute(15); // Replace with actual localized string
  } else if (minute >= 61 && minute < 60 * 24) {
    return i18n.plural(deltaTime).date_hour(deltaTime); // Adjust pluralization logic
  } else {
    const days = Math.floor(deltaTime / 24);
    return i18n.plural(days).date_day(days); // Adjust pluralization logic
  }
}

export function visitorActiveTime(user: User, i18n: I18n) {
  const lastActiveTime = user.lastActiveTime;
  if (!lastActiveTime) return undefined;
  const { minute, deltaTime } = timePair(lastActiveTime);
  if (deltaTime <= 3 * 24) {
    return timeSpan(minute, deltaTime, i18n);
  } else {
    return undefined;
  }
}

export function userActiveTime(user: User, i18n: I18n) {
  const lastActiveTime = user.lastActiveTime;
  if (!lastActiveTime) return undefined;
  const { minute, deltaTime } = timePair(lastActiveTime);
  if (deltaTime <= 3 * 24) {
    return activeTime(minute, deltaTime, i18n);
  } else {
    return undefined;
  }
}

function UserProfilePage() {
  const i18n = useI18n();
  const hopper = useHopper();
  const globalSpinner = useGlobalSpinner();
  const [obscuredZone] = useObscuredZoneForKey("Browser", "root");
  const [firstCheck, setFirstCheck] = useState<boolean>(false);
  const [secondCheck, setSecondCheck] = useState<boolean>(false);
  const [thirdCheck, setThirdCheck] = useState<boolean>(false);
  const [forthCheck, setForthCheck] = useState<boolean>(false);

  const backend = useBackend();
  const uid = useBigIntParam("uid");
  const myUid = useMyUid();
  const nativePage = useNativePage();
  const handleError = useErrorHandler();
  const isIn24Hours = (timestamp: number): boolean => {
    const oneDayInMillis = 24 * 60 * 60 * 1000;
    return Date.now() - timestamp * 1000 < oneDayInMillis;
  };
  const loadTask = useLoadState();
  const reactionRepo = useReactionRecord();
  const userSWR = useSWR(backend.getUser(uid));
  const commentSWRS = useSWRArray(backend.getComments(uid, 4, BigInt(0)));
  const blogsSWRS = useSWRArray(
    backend.getUserBlogs(uid).intercept((it) => {
      it.list.forEach((post) => {
        reactionRepo
          .setValue(post.blogId, post.reactionCountList)
          .catch(andLog);
      });
    }),
  );
  const circlesSWR = useSWR(
    backend.getVisibleCircles(uid, {
      invisible: false,
      pageToken: null,
      size: 21,
      showInUserProfile: true,
    }),
  );
  const blockSWR = useSWR(backend.getBlocking());
  const socialLinksSWR = useSWR(backend.getSocialLinks(uid));

  useEffect(() => {
    if (userSWR.content) {
      setUpUserSubscription(userSWR.content);
    }
  }, [userSWR.content]);

  const setUpUserSubscription = (user: User) => {
    setFirstCheck(
      (user.notification?.receiveWallComment ?? false) ||
        (user.notification?.receiveWallOnlyMyComment ?? false),
    );
    setSecondCheck(user.notification?.receiveWallOnlyMyComment ?? false);
    if (uid === myUid) {
      setThirdCheck(user.notification?.receiveNewFollower ?? false);
      setForthCheck(!(user.notification?.notReceiveNewVisitor ?? false));
    } else {
      setThirdCheck(user.notification?.receiveUserUpdate ?? false);
    }
  };

  async function onFollow() {
    const r = await loadTask.run(async () => {
      await backend.followUser(uid).run();
      await userSWR.load();
    });
    if (!r.success) {
      handleError(r.error);
    }
  }

  async function onUnFollow() {
    const r = await loadTask.run(async () => {
      await backend.unfollowUser(uid).run();
      await userSWR.load();
    });
    if (!r.success) {
      handleError(r.error);
    }
  }

  const bottomSheet = useModal("bottomSheet");
  const onNotificationClick = () => {
    bottomSheet.open();
  };

  const [input, setInput] = useState("");

  const modal = useModal("chat", false);
  const listRef = useRef<HTMLDivElement | null>(null); // 指定类型为 HTMLDivElement
  const scrollSpeed = 50; // 滚动速度，单位为毫秒

  function onChat() {
    hopper.modal("nyi");
    // try {
    //   const chat = await backend.getDirectChat(uid).run();
    // } catch (e: any) {
    //   if (isRejectable(e) && e.apiCode === APIErrorCode.APICodeThreadNotFound) {
    //     // console.log("chat not fund");
    //     // modal.open();
    //     hopper.modal("nyi");
    //   }
    // }
  }

  async function onCreateChat() {
    try {
      const bgList = await backend.getDefaultBackgroundList().run();
      const background = bgList[0].media;
      const chatBody: Chat = {
        background,
        type: ChatThreadType.single,
        threadId: BigInt(0),
        invitedUids: [uid],
        inviteMessageContent: input,
        matchRate: 0,
      };
      const newChat = await backend.createChat(chatBody).run();
      console.log("Chat id", newChat.threadId);
    } catch (e) {}
  }

  const powerMenu = useModal("powerMenu");
  useEffect(() => {
    const interval = setInterval(() => {
      // Check if listRef.current exists and has a firstChild
      if (listRef.current && listRef.current.firstChild) {
        const firstChild = listRef.current.firstChild as HTMLElement;
        const childHeight = firstChild.offsetHeight;

        // Scroll the list
        listRef.current.style.transform = `translateY(-${childHeight}px)`;

        // Move the first child to the end of the list
        setTimeout(() => {
          if (listRef.current) {
            listRef.current.appendChild(firstChild);
            listRef.current.style.transition = "none";
            listRef.current.style.transform = "translateY(0)";
            requestAnimationFrame(() => {
              requestAnimationFrame(() => {
                if (listRef.current) {
                  listRef.current.style.transition = "transform 0.5s ease";
                }
              });
            });
          }
        }, 1000); // Transition duration of 0.5s
      }
    }, scrollSpeed); // Scroll every 'scrollSpeed' milliseconds

    return () => clearInterval(interval); // Clear the interval on component unmount
  }, [commentSWRS]);

  const onBlockUser = async () => {
    try {
      const re = await nativePage.alertAreYouSure(
        i18n.block_user_confirm(),
        i18n.action_block(),
        i18n.cancel(),
      );
      if (re) {
        globalSpinner(async () => {
          await backend.blockUser(uid).run();
          await blockSWR.load();
        }).catch(andLog);
      }
    } catch (e) {
      handleError(e);
    }
  };

  const onUnblockUser = async () => {
    try {
      const re = await nativePage.alertAreYouSure(
        i18n.unblock_user_confirm(),
        i18n.action_unblock(),
        i18n.cancel(),
      );
      if (re) {
        globalSpinner(async () => {
          await backend.unblockUser(uid).run();
          await blockSWR.load();
        }).catch(andLog);
      }
    } catch (e) {
      handleError(e);
    }
  };
  const userStatusIcon = (): string | undefined => {
    const user = userSWR.content;
    if (user?.followMeStatus === 2 && user?.followedByMeStatusV2 === 1) {
      return followedIcon;
    } else if (user?.followMeStatus === 1 && user?.followedByMeStatusV2 === 1) {
      return friendIcon;
    } else if (user?.followMeStatus === 2 && user?.followedByMeStatusV2 === 3) {
      return favIcon;
    } else if (user?.followMeStatus == 1 && user?.followedByMeStatusV2 === 3) {
      return favFriendIcon;
    } else {
      return undefined;
    }
  };

  const unFollow = async () => {
    const r = await loadTask.run(async () => {
      await backend.unfollowUser(uid).run();
      await userSWR.load();
    });

    if (!r.success) {
      handleError(r.error);
    }
  };

  const rmFavorites = async () => {
    const r = await loadTask.run(async () => {
      await backend.removeFavourites(uid).run();
      await userSWR.load();
    });
    if (!r.success) {
      handleError(r.error);
    }
  };

  const addFavorites = async () => {
    const r = await loadTask.run(async () => {
      await backend.addToFavourites({ targetUids: [uid] }).run();
      await userSWR.load();
    });
    if (!r.success) {
      handleError(r.error);
    }
  };

  const getOptions = () => {
    const user = userSWR.content;
    const unFollowOption = {
      title: i18n.unfollow(),
      type: "warning",
      onClick: () => {
        unFollow().catch(andLog);
      },
    };
    const rmFav = {
      title: i18n.fav_widget_remove_from_favorites(),
      type: "warning",
      onClick: () => {
        rmFavorites().catch(andLog);
      },
    };
    const addFav = {
      title: i18n.fav_widget_add_to_favorites(),
      type: "normal",
      onClick: () => {
        addFavorites().catch(andLog);
      },
    };
    if (user?.followedByMeStatusV2 === 3) {
      return [unFollowOption, rmFav];
    } else {
      return [unFollowOption, addFav];
    }
  };
  const onSaveNotificationSetting = async () => {
    const isSelf = uid === myUid;

    try {
      if (isSelf) {
        const body = {
          objectId: uid,
          objectType: 4,
          extensions: {
            receiveNewFollower: thirdCheck,
            notReceiveNewVisitor: !forthCheck,
            receiveWallComment: firstCheck,
            receiveWallOnlyMyComment: secondCheck,
          },
        };
        await backend.subscribeObject(body).run();
      } else {
        const body = {
          objectId: uid,
          objectType: 4,
          extensions: {
            receiveUserUpdate: thirdCheck,
            receiveWallComment: firstCheck,
            receiveWallOnlyMyComment: secondCheck,
          },
        };
        await backend.subscribeObject(body).run();
      }
      await userSWR.load();
    } catch (e) {
      handleError(e);
    }
  };

  const contentRef = useRef<HTMLDivElement | null>(null); // 指定 ref 的类型

  const [isTruncated, setIsTruncated] = useHopState<boolean>(
    `bio_seeall_${uid}`,
    false,
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      const element = contentRef.current;
      if (element) {
        setIsTruncated(element.scrollHeight > element.clientHeight);
      }
    }, 100); // 延迟100ms，确保 ZDoc 完全加载

    return () => clearTimeout(timer);
  }, []); // 仅在组件挂载时执行

  const orderedMediaList = getOrderedFilteredMediaList(
    userSWR.content?.mediaList,
    userSWR.content?.richFormat,
  );

  const viewMedia = (media: Media) => {
    if (orderedMediaList) {
      nativePage.viewMedia({
        mediaList: orderedMediaList,
        position: orderedMediaList.findIndex(
          (m) => m.mediaId === media.mediaId,
        ),
      });
    }
  };

  const bioMedias = (user: User): Media[] | undefined => {
    const mediaList = user.mediaList;
    const attachmentSpans = user.richFormat?.attachmentSpans;
    const ids: bigint[] | undefined = attachmentSpans
      ?.filter((span) => !!takeAttachment(span.data)?.mediaRefId)
      .map((span) => takeAttachment(span.data).mediaRefId)
      .slice(0, 4);
    return mediaList?.filter((media) => ids?.includes(media.mediaId));
  };

  return (
    <Page
      // safeBottomDisabled
      // loadStateConfig={{}}
      pageData={[
        userSWR,
        commentSWRS,
        blogsSWRS,
        circlesSWR,
        blockSWR,
        socialLinksSWR,
      ]}
      underlay={
        <UserBackgroundFrame
          background={userSWR.content?.background}
          backgroundColor={userSWR.content?.backgroundColor}
        />
      }
    >
      <IconFrame>
        <UserAvatarView
          user={userSWR.content}
          iconSize={100}
          onClick={() => {
            hopper.modal("nyi");
          }}
        />
      </IconFrame>
      <VStack style={{ alignItems: "center" }}>
        <UserNameView user={userSWR.content} />
        {userSWR.content?.remark ? (
          <Remark>{`${i18n.remark()}: ${userSWR.content.remark}`}</Remark>
        ) : null}
        <SocialId>{`@${userSWR.content?.socialId}`}</SocialId>
        {userSWR.content?.createdTime ? (
          <SocialId>{shortFromDate(userSWR.content.createdTime)}</SocialId>
        ) : null}
        <ActiveTime>
          {userSWR.content ? userActiveTime(userSWR.content, i18n) : "-"}
        </ActiveTime>
      </VStack>
      <FansFrame>
        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            width: 120,
          }}
          onClick={() => {
            if (uid === myUid) {
              hopper.push(`social`, { type: FollowType.FOLLOWER });
            } else {
              hopper.push(`users/${uid}`, { type: "follower" });
            }
          }}
        >
          <div>{userSWR.content?.fansCount ?? 0}</div>
          <div>{i18n.followers()}</div>
        </div>
        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            width: 120,
          }}
          onClick={() => {
            if (uid === myUid) {
              hopper.push(`social`, { type: FollowType.FOLLOWING });
            } else {
              hopper.push(`users/${uid}`, { type: "following" });
            }
          }}
        >
          <div>{userSWR.content?.followingCount ?? 0}</div>
          <div>{i18n.following()}</div>
        </div>
        {userSWR.content?.extensions?.showRecentVisitor ? (
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              width: 120,
            }}
            onClick={() => {
              hopper.push(`recent-visitor/${uid}`);
            }}
          >
            <div>{userSWR.content?.userVisitorInfo?.totalViewCount ?? 0}</div>
            <div>{i18n.visitor_impr_visits()}</div>
          </div>
        ) : null}
      </FansFrame>
      <ActionContainer>
        {myUid === uid ? (
          <div style={{ position: "relative" }}>
            <EditButton onClick={() => hopper.modal("nyi")}>
              <img src={editIcon} />
              {i18n.edit()}
            </EditButton>
            {/*<RedPoints />*/}
          </div>
        ) : null}
        {userSWR.content?.followedByMeStatusV2 !== 2 && myUid !== uid ? (
          <MercuryMenu options={getOptions()}>
            <div style={{ height: 46, width: 43, display: "flex" }}>
              <Spin state={loadTask.state}>
                <div
                  style={{
                    width: 43,
                    height: 46,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "100% 100%",
                    backgroundImage: `url(${userStatusIcon()})`,
                  }}
                />
              </Spin>
            </div>
          </MercuryMenu>
        ) : null}
        {userSWR.content?.followedByMeStatusV2 === 2 && myUid !== uid ? (
          <ActionButton
            onClick={() => {
              onFollow().catch(andLog);
            }}
            style={{
              borderColor: "#00E6FF",
              background: "rgba(0, 58, 107, 0.75)",
              color: "#00E6FF",
              textShadow: " 0px 1px 8px #0088A8",
              justifyContent: "center",
            }}
          >
            <Spin state={loadTask.state}>{i18n.follow()}</Spin>
          </ActionButton>
        ) : (
          <img
            src={
              userSWR.content && subscribeOrNot(userSWR.content)
                ? notificationYellow
                : notification
            }
            onClick={() => hopper.modal("nyi")}
          />
        )}

        {myUid !== uid ? (
          <ActionButton style={{ justifyContent: "center" }} onClick={onChat}>
            {i18n.chat()}
          </ActionButton>
        ) : null}
      </ActionContainer>

      {(blockSWR.content?.blockedByMeList?.find((id) => uid === id) ?? -1) >
      -1 ? (
        <BlockUserFrame>
          <Image src={emptyIcon} width={120} height={120} />
          <BlockTitle>{i18n.user_you_blocked_user()}</BlockTitle>
          <BlockTitle style={{ opacity: 0.4, marginBottom: 32 }}>
            Interaction between you and this user is prohibited
          </BlockTitle>
          <RegularButton onClick={onUnblockUser}>Unblock</RegularButton>
        </BlockUserFrame>
      ) : (
        <>
          <VSpace height={20} />
          <PageShoulder />
          <AboutTitle style={{ marginBottom: 10 }}>{i18n.about()}</AboutTitle>

          {!!userSWR.content?.tagline ? (
            <TagLine>{userSWR.content.tagline}</TagLine>
          ) : null}
          {!!socialLinksSWR.content?.circleInfo ? (
            <FanCircleCard circle={socialLinksSWR.content.circleInfo} />
          ) : null}
          {socialLinksSWR.content?.extSocialLinks?.filter(
            (item) => !!item.link,
          ) ? (
            <HStack style={{ gap: 10, marginTop: 10 }}>
              {socialLinksSWR.content?.extSocialLinks
                ?.filter((item) => !!item.link)
                .map((socialLink) => <ExtSocialCell extSocial={socialLink} />)}
            </HStack>
          ) : null}
          <AboutTitle style={{ fontSize: 16, fontWeight: 600 }}>
            {i18n.user_bio()}
          </AboutTitle>
          {userSWR.content?.bio ? (
            <VStack onClick={() => hopper.push(`user-bio/${uid}`)}>
              {userSWR.content?.voiceBio ? (
                <>
                  <VSpace height={6} />
                  <AudioFlow media={userSWR.content.voiceBio} />
                </>
              ) : null}
              <div
                ref={contentRef}
                style={{
                  display: "-webkit-box",
                  WebkitLineClamp: 5, // Limits the number of lines to 5
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <VSpace height={10} />
                <ZDoc
                  content={userSWR.content?.bio}
                  richFormat={userSWR.content?.richFormat}
                  mediaList={[]}
                  pollList={[]}
                  onViewMedia={viewMedia}
                />
              </div>
              {isTruncated ? <SeeALL>{i18n.see_all()}</SeeALL> : null}
              <BioMediaContainer>
                {bioMedias(userSWR.content)
                  ?.slice(0, 4)
                  ?.map((media) => (
                    <MediaGridCell>
                      {isImage(media) && <ImageCell media={media} />}
                      {isVideo(media) && (
                        <VideoCell
                          media={media}
                          onClickMedia={() => {}}
                          smallSize
                        />
                      )}
                      {isAudio(media) && <AudioCell media={media} />}
                    </MediaGridCell>
                  ))}
              </BioMediaContainer>
            </VStack>
          ) : (
            <NoBio>{i18n.no_bio_yet()}</NoBio>
          )}
          <TagsFlowLayout tags={userSWR.content?.tagList ?? []} />

          {userSWR.content?.school ? (
            <>
              <AboutTitle style={{ fontSize: 16, fontWeight: 600 }}>
                {i18n.school()}
              </AboutTitle>
              <AboutContent>{userSWR.content.school}</AboutContent>
            </>
          ) : null}
          <VStack></VStack>

          <TitleContainer>
            <AboutTitle style={{ fontSize: 16, fontWeight: 600 }}>
              {i18n.member_title_circle_memberships()}
            </AboutTitle>
            {(circlesSWR.content?.list?.length ?? 0) > 0 ? (
              <SeeAll
                onClick={() => {
                  hopper.push(`circle-memberships/${uid}`);
                }}
              >
                {i18n.see_all()}
              </SeeAll>
            ) : null}
          </TitleContainer>

          {(circlesSWR.content?.list?.length ?? 0) > 0 ? (
            <HStack
              style={{
                overflowX: "scroll",
                scrollbarWidth: "none" as "none",
                msOverflowStyle: "none" as "none",
                gap: 10,
                marginTop: 10,
                paddingBottom: 20,
                borderBottom: "1px solid var(--color-text30)",
                ...FullPageWidthCell,
              }}
            >
              {circlesSWR.content?.list.map((card) => (
                <MiniCircleMemberCard card={card} />
              ))}
            </HStack>
          ) : (
            <EmptyCircleCard>
              {i18n.profile_setting2_no_circle_membership()}
            </EmptyCircleCard>
          )}

          <AboutTitle>
            {commentSWRS.content.length > 0
              ? i18n.wall_count(commentSWRS.content.length)
              : i18n.wall()}
          </AboutTitle>

          <WallFrame
            onClick={() => {
              hopper.push(`wall/${uid}`);
            }}
          >
            <ListScroller
              items={commentSWRS.content.slice(0, 15)}
              i18n={i18n}
            />

            <LeaveMessage>
              <img src={messageIcon} />
              {i18n.leave_a_message()}
            </LeaveMessage>
          </WallFrame>
          {blogsSWRS.content.length > 0 ? (
            <>
              <AboutTitle>
                {i18n.mar_impr_moments_with_count(
                  userSWR.content?.blogsCount ?? 0,
                )}
              </AboutTitle>
            </>
          ) : (
            <>
              <AboutTitle>{i18n.moments()}</AboutTitle>
              <NoPost>{i18n.no_posts_yet()}</NoPost>
            </>
          )}
        </>
      )}
      {blogsSWRS.content.map((post) => (
        <PostCell key={post.blogId} post={post} />
      ))}
      <CenterModal modal={modal}>
        <ModalTitle>Chat Request</ModalTitle>
        <RegularInputGroup
          value={input}
          updateValue={setInput}
          style={css`
            ${inputStyle}
          `}
        />
        <RegularButton onClick={onCreateChat}>Send Request </RegularButton>
      </CenterModal>
      <NavEnd>
        {NavItem.image(NavButtonType.Manage, () => powerMenu.open())}
      </NavEnd>
      <BSMenu modal={powerMenu}>
        {uid !== myUid && (
          <BSMenuItem
            title={i18n.web3_v0_edit_remark()}
            onClick={() => {
              // hopper.push(`remark`, { oid: uid, otype: 4 });
              hopper.modal("nyi");
            }}
          />
        )}

        {/*<BSMenuItem title={i18n.share_copy_link()} onClick={() => {}} />*/}
        {/*<BSMenuItem title={i18n.share()} onClick={() => {}} />*/}
        {uid !== myUid && (
          <BSMenuItem
            title={i18n.flag()}
            onClick={() => {
              hopper.layer("flag", {
                objectId: uid,
                objectType: ObjectType.USER,
              });
            }}
          />
        )}
        {uid !== myUid && (
          <>
            {(blockSWR.content?.blockedByMeList?.find((id) => uid === id) ??
              -1) > -1 ? (
              <BSMenuItem
                title={i18n.may23_impr_remove_from_my_blocked_list()}
                onClick={onUnblockUser}
              />
            ) : (
              <BSMenuItem
                title={i18n.may23_impr_add_to_my_blocked_list()}
                onClick={onBlockUser}
              />
            )}
          </>
        )}
        {uid === myUid && (
          <BSMenuItem
            title={i18n.better_profile_edit_public_profile()}
            onClick={() => hopper.modal("nyi")}
          />
        )}
      </BSMenu>
      <BottomSheet modal={bottomSheet}>
        <VStack
          style={{
            padding: `20px 20px ${40 + obscuredZone.bottom}px`,
            gap: 18,
          }}
        >
          {userSWR.content ? (
            <TitleUserFrame>
              <UserAvatarView user={userSWR.content} iconSize={28} />
              <UserNameView user={userSWR.content} />
            </TitleUserFrame>
          ) : null}
          <HStack style={{ justifyContent: "space-between" }}>
            <div>{i18n.notification_settings()}</div>
          </HStack>
          <HStack
            style={{ gap: 8 }}
            onClick={() => {
              setFirstCheck((p) => !p);
            }}
          >
            <CheckBox checked={firstCheck} />
            <CheckTitle>{i18n.receive_notifications_of_user_wall()}</CheckTitle>
            <Image src={hintIcon} />
          </HStack>
          <HStack
            style={{
              gap: 8,
              marginLeft: 29,
              borderTop: "1px solid var(--color-text30",
              paddingTop: 15,
            }}
            onClick={() => {
              setSecondCheck((p) => {
                if (!p) {
                  setFirstCheck(true);
                }
                return !p;
              });
            }}
          >
            <CheckBox checked={secondCheck} />
            <CheckTitle style={{ opacity: 0.4, fontSize: 14 }}>
              {i18n.my_comment_related_only()}
            </CheckTitle>
          </HStack>
          <HStack
            style={{ gap: 8 }}
            onClick={() => {
              setThirdCheck((p) => !p);
            }}
          >
            <CheckBox checked={thirdCheck} />
            <CheckTitle>
              {uid === myUid
                ? i18n.receive_notifications_of_user_new_followers()
                : i18n.receive_notifications_of_user()}
            </CheckTitle>
          </HStack>
          {uid === myUid ? (
            <HStack
              style={{ gap: 8 }}
              onClick={() => {
                setForthCheck((p) => !p);
              }}
            >
              <CheckBox checked={forthCheck} />
              <CheckTitle>
                {i18n.recent_visits_permission_for_push()}
              </CheckTitle>
            </HStack>
          ) : null}

          <HStack style={{ gap: 14 }}>
            <WeakButton style={{ flex: 1 }}>{i18n.cancel()}</WeakButton>
            <RegularButton
              style={{ flex: 1 }}
              onClick={onSaveNotificationSetting}
            >
              {i18n.ok()}
            </RegularButton>
          </HStack>
        </VStack>
      </BottomSheet>
    </Page>
  );
}

const TagLine = styled.div`
  font-size: 15px;
  color: #ffffff;
  font-weight: 500;
  margin: 5px 0;
`;

const MediaGridCell = styled.div`
  flex: 1;
  max-width: 82px;
  margin: 10px 0;
`;

const BioMediaContainer = styled.div`
  width: 100%;
  gap: 8px;
  display: flex;
  flex-direction: row;
`;

const EmptyCircleCard = styled.div`
  width: 100%;
  height: 50px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
  padding-top: 10px;
  align-items: center;
  text-align: center;
`;

const NoBio = styled.div`
  font-size: 13px;
  color: rgba(255, 255, 255, 0.5);
`;

const NoPost = styled.div`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
`;

const SeeALL = styled.div`
  font-size: 15px;
  color: #34a6ff;
  font-weight: 500;
  margin-top: 8px;
`;

const BlockTitle = styled.div`
  color: #fff;
  text-align: center;
  font-family: DIN, sans-serif;
  font-size: 14px;
  font-weight: 500;
`;

const BlockUserFrame = styled.div`
  width: 100%;
  margin: 20px -18px -30px;
  padding: 0 20px;
  align-items: center;
  flex: 1;
  border-radius: 18px 18px 0 0;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(50.28821563720703px);
  display: flex;
  padding-top: 40px;
  flex-direction: column;
`;

export const CheckBox = styled.div<{ checked: boolean }>`
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  background-image: ${(p) =>
    p.checked ? `url(${checkedIcon})` : `url(${unCheckedIcon})`};
`;

const CheckTitle = styled.div`
  flex: 1;
  color: #fff;
  font-family: DIN, sans-serif;
  font-size: 16px;
  font-weight: 500;
`;

const TitleUserFrame = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 20px 0;
  border-bottom: 1px solid var(--color-text30);
`;

const SeeAll = styled.div`
  margin-top: 20px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

const CircleFrame = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
  overflow-x: auto; /* 允许横向滚动 */
  flex-wrap: nowrap; /* 不换行，保持水平排列 */
  white-space: nowrap; /* 防止子元素换行 */
  margin-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--color-text30);
`;

export const inputStyle = css`
  width: 100%;
  height: 50px;
`;

const MessageFrame = styled.div`
  border-radius: 0px 100px 100px 0px;
  background: rgba(255, 255, 255, 0.08);
  overflow: hidden;
  line-clamp: 1;
  text-overflow: ellipsis;
  word-break: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-left: -14px;
  height: 30px;
  align-items: center;
  align-content: center;
  padding: 0 26px 0 17px;
  max-width: calc(100% - 88px);
  white-space: nowrap; /* 防止文本换行 */
`;

const WallList = styled.div`
  display: flex;
  flex: 1;
  flex-shrink: 2;
  overflow: hidden;
  flex-direction: column;
  gap: 14px;
  transition: transform 1s ease; /* 平滑滚动效果 */
`;
const LeaveMessage = styled.div`
  width: calc(100% - 48px);
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.4);
  font-size: 14px;
  font-weight: 400;
  flex-direction: row;
  height: 47px;
  margin: 0 12px;
  border-top: 0.5px solid rgba(255, 255, 255);
`;
const WallFrame = styled.div`
  width: 100%;
  height: 219px;
  display: flex;
  flex-direction: column;
  margin: 13px 0;
  overflow: hidden; /* 隐藏超出的内容 */
  position: relative;
  padding-top: 10px;
  background-size: 100% auto; // 横向拉伸，纵向保持比例
  background-repeat: no-repeat;
  background-image: url(${wallBg});
`;
const AboutContent = styled.div`
  color: #fff;
  font-size: 15px;
  font-weight: 500;
`;

const AboutTitle = styled.div`
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  margin: 20px 0 0;
`;

const EditButton = styled.div`
  border-radius: 8px;
  border: 0.5px solid rgba(255, 255, 255, 0.4);
  background: rgba(255, 255, 255, 0.12);
  min-width: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2px;
  align-items: center;
  padding: 0 5px;
  height: 42px;
`;

const RedPoints = styled.div`
  width: 10px;
  height: 10px;
  background: #ef1e57;
  border-radius: 50%;
  position: absolute;
  top: -4px;
  right: -4px;
`;

const ActionButton = styled.div`
  border-radius: 8px;
  align-items: center;
  text-align: center;
  display: flex;
  border: 0.5px solid #0dffcc;
  padding: 12px 10px;
  min-width: 100px;
  background: rgba(0, 58, 61, 0.75);
  color: #94ffde;
  text-shadow: 0px 1px 12px #00a88c;
  font-size: 16px;
  font-weight: 700;
`;

const ActionContainer = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: row;
  margin: 29px auto 0;
`;

const IconFrame = styled.div`
  position: relative;
  align-items: center;
  justify-content: center;
  margin: 10px auto;
`;

const Remark = styled.div`
  max-width: 150px;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const SocialId = styled.div`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.4);
`;

const ActiveTime = styled.div`
  color: #0dffaf;
  font-size: 14px;
  font-weight: 500;
`;

const FansFrame = styled.div`
  display: flex;
  margin-top: 21px;
  margin-left: auto;
  margin-right: auto;
  height: 67px;
  justify-content: space-between;
  flex-direction: row;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url(${bgFan});
`;

export default UserProfilePage;
