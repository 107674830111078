import React from "react";
import { useSWR } from "../../hooks/swr/useSWR";
import { useCountDown, useTimeout } from "../../hooks/useCountDown";
import { MonoLabel } from "./DemoCell";
import { toHHMMSS } from "../../utils/DateTimeUtils";
import { andLog } from "../../components/handleError";
import { Page } from "../../components/Page";

export function CountDownDemoPage() {
  const payloadSWR = useSWR("CountDownDemoPage", () => {
    return Promise.resolve({ expTimeSec: new Date().getTime() / 1000 + 20 });
  });

  const remainingSecs = useCountDown(() => {
    if (payloadSWR.content !== undefined) {
      return (
        (payloadSWR.content.expTimeSec * 1000 - new Date().getTime()) / 1000
      );
    } else {
      return undefined;
    }
  }, [payloadSWR.content]);

  useTimeout(remainingSecs, () => {
    payloadSWR.load().catch(andLog);
  });

  return (
    <Page pageData={undefined}>
      <MonoLabel>
        {remainingSecs !== undefined ? toHHMMSS(remainingSecs) : "?"}
      </MonoLabel>
    </Page>
  );
}
