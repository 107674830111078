import { amIFollowing, User } from "../proto/User";
import { RegularButton } from "./Buttons";
import { useI18n } from "../hooks/useI18n";
import styled from "styled-components";
import followedIcon from "../res/images/ic_followed.svg";
import friendIcon from "../res/images/ic_friend.svg";
import favIcon from "../res/images/ic_favorites.svg";
import favFriendIcon from "../res/images/ic_fav_friends.svg";
import { button_on_pressed } from "./CommonStyles";
import { Spin } from "./Spin";
import MercuryMenu from "./MercuryMenu";
import { andLog, useErrorHandler } from "./handleError";
import { useLoadState } from "../hooks/LoadState";
import { Backend } from "../service/Backend";
import {
  ObjectPool,
  ObjectPoolSubscriber,
} from "../pages/chat/utils/ObjectPool";
import { FollowStatus } from "../proto/UserEnums";
import { useEffect, useMemo, useState } from "react";
import { genUUID } from "../utils/uuid";

const FollowedButton = styled.button`
  width: 42px;
  height: 42px;
  border: none;
  background-color: transparent;
  ${button_on_pressed};
`;

export default function FollowButton(props: {
  user: User;
  backend: Backend;
  height?: number;
  padding?: number;
  fontSize?: number;
  hiddenIfFollowed?: boolean;
  onUpdate?: () => Promise<void>;
  userPool?: ObjectPool<User>;
}) {
  const i18n = useI18n();
  const minSize = props.height ?? 42;
  const handleError = useErrorHandler();

  const [user, setUser] = useState<User>(props.user);

  const userStatusIcon = useMemo(() => {
    if (user.followMeStatus === 2 && user.followedByMeStatusV2 === 1) {
      return followedIcon;
    } else if (user.followMeStatus === 1 && user.followedByMeStatusV2 === 1) {
      return friendIcon;
    } else if (user.followMeStatus === 2 && user.followedByMeStatusV2 === 3) {
      return favIcon;
    } else if (user.followMeStatus == 1 && user.followedByMeStatusV2 === 3) {
      return favFriendIcon;
    } else {
      return undefined;
    }
  }, [user]);

  const noticeUpdate = async (status: FollowStatus) => {
    if (props.onUpdate) await props.onUpdate();
    if (props.userPool) {
      let user = props.userPool.getCachedObject(props.user.uid);
      if (user) {
        (user as any).followedByMeStatusV2 = status;
        props.userPool.cacheObject(props.user.uid, user);
      }
    }
  };

  const loadTask = useLoadState();
  const unFollow = async () => {
    const r = await loadTask.run(async () => {
      await props.backend.unfollowUser(user.uid).run();
      await noticeUpdate(FollowStatus.notFollowed);
    });

    if (!r.success) {
      handleError(r.error);
    }
  };

  const rmFavorites = async () => {
    const r = await loadTask.run(async () => {
      await props.backend.removeFavourites(user.uid).run();
      await noticeUpdate(FollowStatus.followed);
    });
    if (!r.success) {
      handleError(r.error);
    }
  };

  const addFavorites = async () => {
    const r = await loadTask.run(async () => {
      await props.backend.addToFavourites({ targetUids: [user.uid] }).run();
      await noticeUpdate(FollowStatus.favorites);
    });
    if (!r.success) {
      handleError(r.error);
    }
  };

  const onFollow = async () => {
    const r = await loadTask.run(async () => {
      await props.backend.followUser(user.uid).run();
      await noticeUpdate(FollowStatus.followed);
    });

    if (!r.success) {
      handleError(r.error);
    }
  };

  const unFollowOption = {
    title: i18n.unfollow(),
    type: "warning",
    onClick: () => {
      unFollow().catch(andLog);
    },
  };
  const rmFav = {
    title: i18n.fav_widget_remove_from_favorites(),
    type: "warning",
    onClick: () => {
      rmFavorites().catch(andLog);
    },
  };
  const addFav = {
    title: i18n.fav_widget_add_to_favorites(),
    type: "normal",
    onClick: () => {
      addFavorites().catch(andLog);
    },
  };

  const menuOptions = useMemo(() => {
    if (user.followedByMeStatusV2 === 3) {
      return [unFollowOption, rmFav];
    } else {
      return [unFollowOption, addFav];
    }
  }, [user]);

  useEffect(() => {
    const sub = {
      onObjectUpdated: (user) => {
        setUser(user);
      },
      uniqueId: genUUID(),
    } as ObjectPoolSubscriber<User>;
    props.userPool?.addSubscribe(sub, user.uid);

    return () => {
      props.userPool?.removeSubscribe(sub, user.uid);
    };
  }, [props.userPool, user]);

  return (
    <>
      {!amIFollowing(user) && (
        <RegularButton
          style={{
            minHeight: minSize,
            fontSize: props.fontSize,
            padding: `0 ${props.padding ?? 20}px`,
          }}
          onClick={() => onFollow()}
        >
          <Spin state={loadTask.state}>{i18n.follow()}</Spin>
        </RegularButton>
      )}
      {props.hiddenIfFollowed !== true && amIFollowing(user) && (
        <MercuryMenu options={menuOptions}>
          <div style={{ height: minSize, width: minSize, display: "flex" }}>
            <Spin state={loadTask.state}>
              <div
                style={{
                  width: minSize,
                  height: minSize,
                  background: `url(${userStatusIcon}) no-repeat center center / cover`,
                }}
              />
            </Spin>
          </div>
        </MercuryMenu>
      )}
    </>
  );
}
