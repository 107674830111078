import styled from "styled-components";
import { linear_gradient_border } from "../../../components/vscroll/SVGUtils";
import colorSetAlpha from "color-alpha";
import { HStack, VSpace, VStack } from "../../../components/VStack";
import { CircleMembershipLevelIcon } from "../membership/MembershipLevelPicker";
import React from "react";
import { Image, MediaSizeMode } from "../../../components/Image";
import verifiedIcon from "../../../res/images/icon_circle_verified.svg";
import { SingleLineUnspecifiedWidth } from "../../../components/CommonViews";
import { Circle, VerifiedStatus } from "../../../proto/Circle";
import { User } from "../../../proto/User";
import { UserIconView } from "../../../components/views/UserAvatarView";
import { CircleIconView } from "../../alertCenter/AlertDetailCell";
import { useI18n } from "../../../hooks/useI18n";
import iconAdmin from "../../../res/images/ic_admin_title.svg";
import bgAdmin from "../../../res/images//bg_admin_card.png";
import bgNoCard from "../../../res/images/bg_no_card.png";
import { Media } from "../../../proto/Media";
import { LocalMedia } from "../../../bridge/LocalMedia";
import { Hopper } from "../../../hooks/useHopper";
import { CircleMemberTitle } from "../../../proto/CircleMemberTitle";
import MemberTitle from "../../../components/MemberTitle";
export interface CircleCardProps {
  hopper?: Hopper;
  circle?: Circle;
  user?: User;
  level?: number;
  titles?: CircleMemberTitle[];
  background?: Media;
}

export function CircleMembershipCard(props: CircleCardProps) {
  const i18n = useI18n();
  const isAdmin = props.user?.uid
    ? props.circle?.adminIdList?.includes(props.user?.uid) ?? false
    : false;
  const isCoAdmin = props.user?.uid
    ? props.circle?.coAdminIdList?.includes(props.user.uid) ?? false
    : false;

  const hasToken =
    !!props.circle?.tokenProjectId && props.circle.tokenProjectId > BigInt(0);

  const bg = props.circle?.currentMemberInfo?.circleMembershipCard?.background;
  const bgSrc:
    | readonly [Media | LocalMedia | undefined, MediaSizeMode]
    | string
    | undefined =
    isAdmin && hasToken
      ? bgAdmin
      : (props.level ?? 0) === 0 || !hasToken
        ? bgNoCard
        : [props.background, { width: 335, height: 189 }];

  const goUserProfile = () => {
    if (props.hopper) {
      props.hopper.push(`user/${props.user?.uid}`);
    }
  };
  const goCircle = () => {
    if (props.hopper) {
      props.hopper.push(`circle/${props.circle?.circleId}`);
    }
  };
  return (
    <CardFrame>
      <Image
        src={bgSrc}
        style={{
          width: "calc(100% - 2px)",
          height: "calc(100% - 2px)",
          borderRadius: 12,
          zIndex: -1,
          position: "absolute",
          left: "1px",
          right: "1px",
          top: "1px",
          bottom: "1px",
        }}
      />
      <UserInfoFrame onClick={goUserProfile}>
        <UserIconView user={props.user} iconSize={72} />
        <HStack style={{ gap: 4, alignItems: "center" }}>
          <UserName>{props.user?.nickname}</UserName>
          {isAdmin ? null : (
            <CircleMembershipLevelIcon
              level={hasToken ? props.level || 0 : 0}
              width={33}
              style={{ display: "flex", margin: "auto 0" }}
            />
          )}
        </HStack>
        {(props.titles ?? []).length > 0 ? (
          <FlexContainer>
            {(props.titles ?? []).map((memberTitle) => (
              <MemberTitle memberTitle={memberTitle} />
            ))}
          </FlexContainer>
        ) : (
          <VSpace height={18} />
        )}
      </UserInfoFrame>
      <CircleInfoFrame onClick={goCircle}>
        <CircleIconView circle={props.circle} iconSize={32} borderRadius={4} />
        <VStack>
          <HStack style={{ gap: 4 }}>
            <CircleName>{props.circle?.name}</CircleName>
            {props.circle?.verifiedStatus === VerifiedStatus.VERIFIED ? (
              <CircleVerifiedIcon />
            ) : null}
          </HStack>
          <CircleId>{`c/${props.circle?.socialId ?? ""}`}</CircleId>
        </VStack>
      </CircleInfoFrame>
    </CardFrame>
  );
}
export const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px; /* Optional: to add space between items */
  max-height: 25px;
  overflow: hidden;
`;

const CoAdminFrame = styled.div`
  font-size: 10px;
  font-weight: 500;
  color: #111111;
  padding: 5px 3px;
  width: fit-content;
  border-radius: 4px;
  background: #00cfa5;
  line-height: 12px;
`;

const AdminFrame = styled.div`
  display: flex;
  flex-direction: row;
  width: fit-content;
  padding: 3px 4px;
  gap: 1px;
  line-height: 12px;
  color: #ffffff;
  font-weight: 500;
  font-size: 10px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.2);
`;

const CircleId = styled.div`
  font-size: 10px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
`;

const CircleVerifiedIcon = styled.div`
  width: 16px;
  height: 16px;
  background-image: url("${verifiedIcon}");
  background-size: 100%;
  background-repeat: no-repeat;
`;

const CircleName = styled.div`
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  max-width: 95px;
  ${SingleLineUnspecifiedWidth}
`;

const CircleInfoFrame = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  position: absolute;
  top: 20px;
  right: 19px;
`;

const UserName = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: white;
  max-width: 200px;
  ${SingleLineUnspecifiedWidth}
`;

const UserInfoFrame = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: absolute;
  left: 24px;
  bottom: 15px;
  right: 24px;
`;

const CardFrame = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  width: 100%;
  aspect-ratio: 1.78;
  border-radius: 12px;
  position: relative;
  border-image: ${linear_gradient_border(
    12,
    1,
    40,
    [colorSetAlpha("white", 0.5), 0],
    [colorSetAlpha("white", 0.1), 0.4],
    [colorSetAlpha("white", 0.1), 0.6],
    [colorSetAlpha("white", 0.5), 1],
  )};
`;
