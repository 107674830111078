import { useBackend } from "../../service/APIService";
import { useI18n } from "../../hooks/useI18n";
import { useSWRArray } from "../../hooks/swr/useSWRArray";
import React, { useEffect } from "react";
import { PostCell } from "./PostCell";
import { Page } from "../../components/Page";
import { andLog } from "../../components/handleError";
import { usePageParent } from "../../components/PageCompositionBoundary";
import { AlertGroup } from "../../proto/Alert";
import { AlertCounts } from "../../proto/AlertCounts";
import { StateId } from "../../hooks/StateId";
import { useMemoryRepoMap } from "../../hooks/swr/useLocalRepo";
import { zBigInt } from "../../utils/zodUtils";
import { z } from "zod";
import { Reaction } from "../../proto/Reaction";
import { useReactionRecord } from "../../hooks/useReactionRecord";

export function GlobalAnnouncementsPage(props: {
  alertCounts?: AlertCounts;
  refreshAlertCounts?: () => void;
}) {
  const backend = useBackend();
  const i18n = useI18n();

  const reactionRepo = useReactionRecord();

  const announcementsSWR = useSWRArray(
    backend.getGlobalAnnouncements().intercept((it) => {
      it.list.forEach((post) => {
        reactionRepo
          .setValue(post.blogId, post.reactionCountList)
          .catch(andLog);
      });
    }),
  );

  const markAsRead = async () => {
    try {
      await backend.markAlertAsRead(AlertGroup.GlobalAnnouncement).run();
      await backend.getAlertCounts().run();
      if (props.refreshAlertCounts) {
        props.refreshAlertCounts();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const parentPage = usePageParent();

  useEffect(() => {
    const primarySubpageId = parentPage.primarySubpageId;
    if (
      Number(primarySubpageId ?? 0) === Number(AlertGroup.GlobalAnnouncement)
    ) {
      if ((props.alertCounts?.globalAnnouncementCount || 0) > 0) {
        markAsRead().catch(andLog);
      }
    }
  }, [parentPage.primarySubpageId]);

  return (
    <Page
      pageData={announcementsSWR}
      loadStateConfig={{ emptyText: i18n.no_announcement() }}
    >
      {announcementsSWR?.content.map((post) => (
        <PostCell key={post.blogId} post={post} />
      ))}
    </Page>
  );
}
