import React from "react";
import pageBg from "../../res/images/user_upgrade_success_bg.svg";
import { Page } from "../../components/Page";

export function PageBgDemoPage() {
  return (
    <Page
      pageData={undefined}
      background={`url("${pageBg}") center top/contain no-repeat var(--color-bg)`}
    ></Page>
  );
}
