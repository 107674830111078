import { JSONUtil } from "../utils/JSONUtil";

export type StateIdPrimitive = number | string | bigint | boolean;
export type StateIdArray = readonly (StateId | undefined)[];
export type StateIdObject = { [x: string]: StateId };

export type StateId = StateIdPrimitive | StateIdArray | StateIdObject;

export function flattenStateId(id: StateId): string {
  if (Array.isArray(id)) {
    return id
      .flatMap((it) => (it !== undefined ? [flattenStateId(it)] : []))
      .join("-");
  } else if (typeof id === "object") {
    return JSONUtil.stringify(id);
  } else if (typeof id === "string") {
    return id;
  } else {
    return `${id}`;
  }
}
