export enum NavButtonType {
  More = 1,
  Cross = 2,
  Check = 3,
  Info = 4,
  Manage = 5,
  Plus,
  Power = 7,
  Search = 8,
  Ellipsis = 9,
  Back = 10,
  Share = 11,
  Text = 100,

  DoneTitle = 200,
}

export type NavButtonDesc = {
  type: NavButtonType;
  title?: string;
};
