import { Animation, BottomSheetBg } from "../../../components/Modal";
import { SmallNote, SmallNoteType } from "../../../proto/SmallNote";
import { useI18n } from "../../../hooks/useI18n";
import styled from "styled-components";
import { Chat } from "../../../proto/Chat";
import {
  ButtonColor,
  LinkButton,
  RegularButton,
} from "../../../components/Buttons";
import { useBackend } from "../../../service/APIService";
import { useObscuredZoneForKey } from "../../../hooks/useObscuredZones";
import { HStack, VStack } from "../../../components/VStack";
import { timeIntervalString } from "../../../utils/DateTimeUtils";
import { andLog, useErrorHandler } from "../../../components/handleError";
import { useEffect } from "react";
import { ChatThreadType } from "../../../proto/ChatThreadType";
import { UserIconView } from "../../../components/views/UserAvatarView";

const InlineBoldText = styled.span`
  font-weight: bold;
`;

const NoteSlipInfo = styled.span`
  text-align: center;
  font-size: 14px;
`;

const TimeLabel = styled.div`
  font-size: 12px;
  opacity: 0.5;
`;

function InviteToChatNote(props: {
  smallNote: SmallNote;
  isParty: boolean;
  onHandled: () => {};
}) {
  const i18n = useI18n();
  const backend = useBackend();
  const handleError = useErrorHandler();
  const createdTime = props.smallNote.createdTime ?? Date.now() / 1000;

  async function onClickDecline() {
    try {
      await backend.leaveChatThread(props.smallNote.objectId).run();
      props.onHandled();
    } catch (e) {
      handleError(e);
    }
  }

  async function onClickAccept() {
    try {
      await backend.acceptChatInvitation(props.smallNote.objectId).run();
      props.onHandled();
    } catch (e) {
      handleError(e);
    }
  }

  return (
    <>
      <HStack style={{ gap: 10 }}>
        <UserIconView user={props.smallNote.inviter} iconSize={44} />
        <VStack>
          <div>
            {props.isParty
              ? i18n.someone_invited_you_to_this_party(
                  <InlineBoldText>
                    {props.smallNote.inviter?.nickname}
                  </InlineBoldText>,
                )
              : i18n.someone_invited_you_to_this_chat(
                  <InlineBoldText>
                    {props.smallNote.inviter?.nickname}
                  </InlineBoldText>,
                )}
          </div>
          <TimeLabel>
            {timeIntervalString(new Date(createdTime * 1000), i18n)}
          </TimeLabel>
        </VStack>
      </HStack>
      <HStack style={{ gap: 10 }}>
        <RegularButton
          $baseColor={ButtonColor.destructive}
          style={{ flexGrow: 1 }}
          onClick={onClickDecline}
        >
          {i18n.decline()}
        </RegularButton>
        <RegularButton
          $baseColor={ButtonColor.greenish}
          style={{ flexGrow: 1 }}
          onClick={onClickAccept}
        >
          {i18n.accept()}
        </RegularButton>
      </HStack>
    </>
  );
}

function InviteToBeAdminNote(props: {
  smallNote: SmallNote;
  isParty: boolean;
  onHandled: () => void;
}) {
  const i18n = useI18n();
  const backend = useBackend();
  const handleError = useErrorHandler();
  const createdTime = props.smallNote.createdTime ?? Date.now() / 1000;

  async function onClickReject() {
    try {
      await backend.rejectToBeAdmin(props.smallNote.objectId).run();
      props.onHandled();
    } catch (error) {
      handleError(error);
    }
  }

  async function onClickAccept() {
    try {
      await backend.acceptToBeAdmin(props.smallNote.objectId).run();
    } catch (error) {
      handleError(error);
    }
  }

  return (
    <>
      <div>
        <UserIconView
          user={props.smallNote.inviter}
          iconSize={15}
          style={{ transform: "translateY(4px)", margin: 2 }}
        />
        <NoteSlipInfo style={{ lineHeight: 2 }}>
          {props.isParty
            ? i18n.party_on_invite_to_be_host_hint(
                <InlineBoldText>
                  {props.smallNote.inviter?.nickname ?? ""}
                </InlineBoldText>,
              )
            : i18n.chat_read_only_chat_invite_to_be_host_hint(
                <InlineBoldText>
                  {props.smallNote.inviter?.nickname ?? ""}
                </InlineBoldText>,
              )}
        </NoteSlipInfo>
      </div>
      <NoteSlipInfo style={{ textAlign: "justify" }}>
        {props.isParty
          ? i18n.party_on_invite_to_be_host_content()
          : i18n.chat_read_only_chat_invite_to_be_host_content()}
      </NoteSlipInfo>
      <TimeLabel>
        {timeIntervalString(new Date(createdTime * 1000), i18n)}
      </TimeLabel>
      <HStack style={{ gap: 10 }}>
        <RegularButton
          $baseColor={ButtonColor.destructive}
          onClick={onClickReject}
          style={{ flexGrow: 1 }}
        >
          {i18n.reject()}
        </RegularButton>
        <RegularButton
          $baseColor={ButtonColor.greenish}
          onClick={onClickAccept}
          style={{ flexGrow: 1 }}
        >
          {i18n.accept()}
        </RegularButton>
      </HStack>
    </>
  );
}

function InviteToBeCoAdminNote(props: {
  smallNote: SmallNote;
  isParty: boolean;
  onHandled: () => void;
}) {
  const i18n = useI18n();
  const backend = useBackend();
  const handleError = useErrorHandler();
  const createdTime = props.smallNote.createdTime ?? Date.now() / 1000;

  async function onClickReject() {
    try {
      await backend.rejectToBeCoAdmin(props.smallNote.objectId).run();
      props.onHandled();
    } catch (error) {
      handleError(error);
    }
  }

  async function onClickAccept() {
    try {
      await backend.acceptToBeCoAdmin(props.smallNote.objectId).run();
    } catch (error) {
      handleError(error);
    }
  }

  return (
    <>
      <div>
        <UserIconView
          user={props.smallNote.inviter}
          iconSize={15}
          style={{ transform: "translateY(4px)", margin: 2 }}
        />
        <NoteSlipInfo style={{ lineHeight: 2 }}>
          {props.isParty
            ? i18n.party_on_invite_to_be_cohost_hint(
                <InlineBoldText>
                  {props.smallNote.inviter?.nickname ?? ""}
                </InlineBoldText>,
              )
            : i18n.chat_read_only_chat_invite_to_be_cohost_hint(
                <InlineBoldText>
                  {props.smallNote.inviter?.nickname ?? ""}
                </InlineBoldText>,
              )}
        </NoteSlipInfo>
      </div>
      <NoteSlipInfo style={{ textAlign: "justify" }}>
        {props.isParty
          ? i18n.party_on_invite_to_be_cohost_content()
          : i18n.chat_read_only_chat_invite_to_be_cohost_content()}
      </NoteSlipInfo>
      <TimeLabel>
        {timeIntervalString(new Date(createdTime * 1000), i18n)}
      </TimeLabel>
      <HStack style={{ gap: 10 }}>
        <RegularButton
          $baseColor={ButtonColor.destructive}
          onClick={onClickReject}
          style={{ flexGrow: 1 }}
        >
          {i18n.reject()}
        </RegularButton>
        <RegularButton
          $baseColor={ButtonColor.greenish}
          onClick={onClickAccept}
          style={{ flexGrow: 1 }}
        >
          {i18n.accept()}
        </RegularButton>
      </HStack>
    </>
  );
}

function ManagerBeRemovedNote(props: {
  smallNote: SmallNote;
  onHandled: () => void;
}) {
  const i18n = useI18n();
  const createdTime = props.smallNote.createdTime ?? Date.now() / 1000;
  return (
    <>
      <NoteSlipInfo>
        {i18n.chat_read_only_you_have_been_removed_as_the_coadmin()}
      </NoteSlipInfo>
      <TimeLabel>
        {timeIntervalString(new Date(createdTime * 1000), i18n)}
      </TimeLabel>
      <RegularButton onClick={props.onHandled}>{i18n.ok()}</RegularButton>
    </>
  );
}

function AbandoningNote(props: {
  smallNote: SmallNote;
  onHandled: () => void;
}) {
  const i18n = useI18n();
  const createdTime = props.smallNote.createdTime ?? Date.now() / 1000;
  return (
    <>
      <NoteSlipInfo>{i18n.chat_read_only_chat_abandoning_hint()}</NoteSlipInfo>
      <TimeLabel>
        {timeIntervalString(new Date(createdTime * 1000), i18n)}
      </TimeLabel>
      <RegularButton onClick={props.onHandled}>{i18n.ok()}</RegularButton>
    </>
  );
}

function AbandonedNote(props: { smallNote: SmallNote; onHandled: () => void }) {
  const i18n = useI18n();
  const backend = useBackend();
  const handleError = useErrorHandler();

  async function onClickTakeAdmin() {
    try {
      await backend.takeOverAdmin(props.smallNote.objectId).run();
      props.onHandled();
    } catch (error) {
      handleError(error);
    }
  }

  return (
    <>
      <NoteSlipInfo>
        {i18n.chat_read_only_chat_take_over_manager_hint(
          <LinkButton
            style={{ display: "inline-block" }}
            onClick={onClickTakeAdmin}
          >
            {i18n.chat_read_only_here()}
          </LinkButton>,
        )}
      </NoteSlipInfo>
    </>
  );
}

export function SmallNoteView(props: {
  smallNote: SmallNote;
  threadInfo: Chat | undefined;
}) {
  const i18n = useI18n();
  const backend = useBackend();
  const handleError = useErrorHandler();

  const [obscuringZone] = useObscuredZoneForKey("Browser");

  const createdTime = props.smallNote.createdTime ?? Date.now() / 1000;
  const isParty = props.threadInfo?.type === ChatThreadType.public;

  async function markSmallNoteAsRead() {
    try {
      await backend.markSmallNoteAsRead(props.smallNote.noteId).run();
      // todo: update thread small note
    } catch (error) {
      handleError(error);
    }
  }

  async function deleteSmallNote() {
    try {
      await backend.deleteSmallNote(props.smallNote.noteId).run();
      // todo: jerry - update thread in pool
    } catch (error) {
      handleError(error);
    }
  }

  useEffect(() => {
    if (props.smallNote.readTime ?? 0 === 0) {
      markSmallNoteAsRead().catch(andLog);
    }
  }, []);

  return (
    <BottomSheetBg $animation={Animation.None}>
      <VStack
        style={{
          gap: 16,
          margin: `10px var(--page-h-inset) ${20 + obscuringZone.bottom}px var(--page-h-inset)`,
        }}
      >
        {props.smallNote.type === SmallNoteType.ContainerInvitedMember && (
          <InviteToChatNote
            smallNote={props.smallNote}
            isParty={isParty}
            onHandled={deleteSmallNote}
          />
        )}
        {props.smallNote.type === SmallNoteType.ContainerManagerBeRemoved && (
          <ManagerBeRemovedNote
            smallNote={props.smallNote}
            onHandled={deleteSmallNote}
          />
        )}
        {props.smallNote.type === SmallNoteType.ContainerInvitedTobeCoAdmin && (
          <InviteToBeCoAdminNote
            smallNote={props.smallNote}
            isParty={isParty}
            onHandled={deleteSmallNote}
          />
        )}
        {props.smallNote.type === SmallNoteType.ContainerInvitedTobeAdmin && (
          <InviteToBeAdminNote
            smallNote={props.smallNote}
            isParty={isParty}
            onHandled={deleteSmallNote}
          />
        )}
        {props.smallNote.type === SmallNoteType.Abandoning && (
          <AbandoningNote
            smallNote={props.smallNote}
            onHandled={deleteSmallNote}
          />
        )}
        {props.smallNote.type === SmallNoteType.Abandoned && (
          <AbandonedNote
            smallNote={props.smallNote}
            onHandled={deleteSmallNote}
          />
        )}
      </VStack>
    </BottomSheetBg>
  );
}
