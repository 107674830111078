import { HStack, Spring, VStack } from "./VStack";
import { CSSProperties, PropsWithChildren } from "react";
import { alpha_on_pressed } from "./CommonStyles";
import { Label } from "../pages/nft/components/Label";
import styled from "styled-components";
import { Image } from "./Image";
import IcDropdown from "../res/images/ic_drop_down.svg";

const ContentHolder = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.06);
  border-radius: 6px;
  z-index: 1;

  ${alpha_on_pressed};
`;

export function DropdownButton(
  props: PropsWithChildren<{
    title: string;
    subtitle: string;
    onClick?: () => void;
    style?: CSSProperties;
  }>,
) {
  return (
    <div style={{ position: "relative", padding: 14 }}>
      <ContentHolder onClick={props.onClick} />
      <VStack style={{ gap: 4 }}>
        <HStack>
          <Label $fontSize={14}>{props.title}</Label>
          <Spring />
          <Image src={IcDropdown} />
        </HStack>
        <Label $fontSize={12} style={{ opacity: 0.4 }}>
          {props.subtitle}
        </Label>
        {props.children}
      </VStack>
    </div>
  );
}
