import { Page } from "../../components/Page";
import { NavEnd, NavItem, NavMiddle } from "../../components/NavBar";
import { useHopper } from "../../hooks/useHopper";
import { useI18n, useIsRTL } from "../../hooks/useI18n";
import { AuthSubtitleLabel, AuthTitleLabel } from "./InvitationCodePage";
import { ContentGroup } from "../../components/ContentGroup";
import { HStack } from "../../components/VStack";
import React from "react";
import styled from "styled-components";
import { Image } from "../../components/Image";
import arrowDown from "../../res/images/icon_arrow_down.svg";
import { RegularLargeButton } from "../../components/Buttons";
import { BSMenu, BSMenuItem } from "../../components/BSMenu";
import { useModal } from "../../components/Modal";
import { allRegions, Region } from "../../proto/Region";
import { useStringSearchParam } from "../../hooks/useTypedParam";
import { useMemoryRepo } from "../../hooks/swr/useLocalRepo";
import { AuthBody } from "../../proto/Auth";

export function SelectRegionPage() {
  const i18n = useI18n();
  const isRTL = useIsRTL();
  const hopper = useHopper();
  const regionMenu = useModal("regionMenu");
  const [region, setRegion] = React.useState<Region>();

  const flowId = useStringSearchParam("f");
  const authInfo = useMemoryRepo<AuthBody>(flowId ?? "", {});

  const onNextClick = async () => {
    if (!region) {
      return;
    }

    await authInfo.fill((prev) => ({
      ...prev,
      countryCode: region.code,
    }));
    hopper.push("select-birthday", {
      f: flowId,
    });
  };
  return (
    <Page pageData={undefined}>
      <NavMiddle>{i18n.auth_sign_up()}</NavMiddle>
      <NavEnd>
        {NavItem.text(i18n.help(), () => hopper.push("feedback"))}
      </NavEnd>
      <AuthTitleLabel>{i18n.sign_up_country_region_request()}</AuthTitleLabel>
      <ContentGroup
        style={{ marginTop: "66px" }}
        onClick={() => regionMenu.open()}
      >
        <HStack>
          <RegionLabel>{region ? region.name : i18n.select()}</RegionLabel>
          <Image
            src={arrowDown}
            alt={"arrowDown"}
            width={20}
            height={20}
          ></Image>
        </HStack>
      </ContentGroup>
      <AuthSubtitleLabel style={{ marginTop: "32px" }}>
        {i18n.sign_up_birthday_request_description()}
      </AuthSubtitleLabel>
      <RegularLargeButton
        disabled={!region}
        style={{ margin: "80px 62px 0" }}
        onClick={onNextClick}
      >
        {i18n.next()}
      </RegularLargeButton>
      <BSMenu modal={regionMenu}>
        {allRegions.map((region) => (
          <BSMenuItem
            title={isRTL ? region.rtlDes : region.des}
            trailingItem={"+" + region.phoneCode}
            onClick={() => {
              regionMenu.close();
              setRegion(region);
            }}
          ></BSMenuItem>
        ))}
      </BSMenu>
    </Page>
  );
}

export const RegionLabel = styled.div`
  flex-grow: 1;
  color: var(--color-text00);
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  font-family: DIN, sans-serif;
`;
