import { HStack, VStack } from "./VStack";
import { Spacing } from "./Spacing";
import { UserAvatarView } from "./views/UserAvatarView";
import { User } from "../proto/User";
import { UserNameView } from "./views/UserNameView";
import styled, { css } from "styled-components";
import { Image } from "./Image";
import genderWoman from "../res/images/icon_gender_woman.svg";
import { OverFlowTextStyle } from "./Text";
import { useWebHost } from "../hooks/useBridge";

export interface UserHScrollItemProps {
  user: User;
  iconSize?: number;
  containerStyle?: object;
  onGoProfile?: () => void;
  showMembershipLevel?: boolean;
}

const HScrollItemContainer = styled.div`
  flex-shrink: 0;
  width: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: opacity 0.2s ease; // 平滑的透明度过渡

  &:active {
    opacity: 0.4; // 按下时透明度变为 0.4
  }
`;

function UserHScrollItem(props: UserHScrollItemProps) {
  return (
    <HScrollItemContainer
      onClick={props.onGoProfile}
      style={props.containerStyle}
    >
      <UserAvatarView
        disableClick={!!props.onGoProfile}
        user={props.user}
        iconSize={props.iconSize || 48}
        stopPropagation={true}
      />
      <Spacing height={10} />
      <UserNameView
        showMembershipLevel={props.showMembershipLevel}
        user={props.user}
        nameStyle={css`
          max-width: 60px;
        `}
      />
    </HScrollItemContainer>
  );
}

export default UserHScrollItem;
