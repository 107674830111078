import { z } from "zod";
import { zBigInt, zEnum, zStatic } from "../utils/zodUtils";
import { ObjectType } from "./ObjectSpec";
import { Media } from "./Media";

export const Bubble = z.object({
  bubbleId: zBigInt.optional(),
  colorCode: z.string().optional(),
  textColor: z.string().optional(),
  uid: zBigInt.optional(),
  createdTime: z.number().optional(),
  customizedPreview: z.boolean().optional(),
  name: z.string().optional(),
  parentId: zBigInt.optional(),
  parentType: zEnum(ObjectType).optional(),
  preview: Media.optional(),
  res: Media.optional(),
  updatedTime: z.string().optional(),
});

export type Bubble = zStatic<typeof Bubble>;
