import { Sticker, StickerType } from "../../proto/Reaction";
import { useSWR } from "../../hooks/swr/useSWR";
import { CSSProperties, useEffect, useState } from "react";
import icLoadingEmoji from "../../res/images/ic_loading_emoji.svg";
import styled from "styled-components";
import { getBestRes } from "../../proto/Media";
import { Backend } from "../../service/Backend";

export type StickerContainerProps = {
  type: StickerType;
  onChoose: (sticker: Sticker) => void;
  backend: Backend;
  style?: CSSProperties;
};

export function StickerPanel(props: StickerContainerProps) {
  const stickerSWR = useSWR(
    props.type === StickerType.Customized
      ? props.backend.getStickers(StickerType.Customized)
      : props.type === StickerType.Static
        ? props.backend.getStickers(StickerType.Static)
        : props.backend.getStickers(StickerType.Animation),
  );

  return (
    <StickerList style={props.style}>
      {stickerSWR.content?.groupList
        ?.flatMap((group) => group.stickerList || [])
        ?.map((sticker) => {
          return (
            <EmojiContainer
              key={sticker.stickerId}
              onClick={() => props.onChoose(sticker)}
            >
              {sticker.media && (
                <Emoji src={getBestRes(sticker.media)?.url} type={props.type} />
              )}
            </EmojiContainer>
          );
        })}
    </StickerList>
  );
}

export function ensureHttps(url?: string): string {
  if ((url?.length ?? 0) === 0) {
    return "";
  }

  if (url?.startsWith("http://")) {
    return url.replace("http://", "https://");
  }
  return url ?? "";
}

function Emoji(props: { src?: string; type: StickerType }) {
  const [imageSrc, setImageSrc] = useState<string>();
  const [emojiSize, setEmojiSize] = useState<number>(30);

  useEffect(() => {
    setImageSrc(icLoadingEmoji);
    const img = new Image();
    if (props.src) {
      img.src = props.src;
      img.onload = () => {
        setImageSrc(props.src);
        setEmojiSize(
          props.type === StickerType.Customized
            ? 32
            : props.type === StickerType.Animation
              ? 40
              : 30,
        );
      };
    }
    img.onerror = () => {
      setImageSrc(icLoadingEmoji);
      setEmojiSize(30);
    };
  }, [props.src]);

  return <EmojiItem src={imageSrc} width={emojiSize} height={emojiSize} />;
}

const StickerList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 7px;
  width: 100%;
  padding: 0 16px 76px 16px;
  box-sizing: border-box;
  overflow-y: auto;
  height: 400px;
`;

const EmojiContainer = styled.div`
  width: 50px;
  height: 50px;
  display: grid;
  align-items: center;
  justify-content: center;
`;

const EmojiItem = styled.img``;
