import {
  alpha_on_pressed,
  shrink_on_pressed,
} from "../../components/CommonStyles";
import React from "react";
import styled from "styled-components";
import { DemoCell, DemoNote } from "./DemoCell";
import { ContentGroup } from "../../components/ContentGroup";
import { HStack, VSpace } from "../../components/VStack";
import { Image } from "../../components/Image";
import ic_energy from "../../res/images/ic_energy.webp";
import { Page } from "../../components/Page";

const ButtonLike = styled.div`
  padding: 20px;
  color: var(--color-text00);
  font-size: 16px;
  text-align: center;
`;

export function PressedEffectDemoPage() {
  return (
    <Page pageData={undefined}>
      <DemoNote>cell_on_pressed</DemoNote>
      <DemoCell>Used for row style cell</DemoCell>

      <DemoNote>shrink_on_pressed</DemoNote>

      <ContentGroup mixin={shrink_on_pressed}>
        Used for card style cell
        <VSpace height={100} />
      </ContentGroup>

      <DemoNote>alpha_on_pressed</DemoNote>
      <HStack mixin={alpha_on_pressed}>
        <Image width={30} height={30} src={ic_energy} alt={"energy"} />
        <ButtonLike>Used for anything else</ButtonLike>
      </HStack>
    </Page>
  );
}
