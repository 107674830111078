import { HStack, Spring, VStack } from "../../../components/VStack";
import { AssetOrder, AssetOrderStatus } from "../../../proto/AssetOrder";
import { Image } from "../../../components/Image";
import styled from "styled-components";
import { OverFlowTextStyle } from "../../../components/Text";
import { CellDivider, ContentGroup } from "../../../components/ContentGroup";
import { OrderInfoContent, OrderInfoLabel } from "./GiftOrderDetail";
import { I18n, useI18n } from "../../../hooks/useI18n";
import { StatusBar } from "./GiftOrderListPage";
import { getStatus } from "antd/es/form/util";
import {
  formatDateWithOptions,
  timeIntervalString,
} from "../../../utils/DateTimeUtils";
import {
  formatMoney,
  getCurrencyIcon,
  getCurrencyName,
} from "../../../proto/Currency";

export function CreateNewTokenDetail(props: { assetOrder: AssetOrder }) {
  const i18n = useI18n();

  function getStatus() {
    switch (props.assetOrder.orderStatus) {
      case AssetOrderStatus.Done:
        return { text: i18n.web3_v0_completed(), color: "#00FF94" };
      case AssetOrderStatus.Pending:
      case AssetOrderStatus.Submitted: {
        return { text: i18n.web3_v0_pending(), color: "#34A6FFFF" };
      }
      case AssetOrderStatus.Failed: {
        return { text: i18n.circle_anmt_failed(), color: "#EF3537FF" };
      }
      case AssetOrderStatus.Rejected: {
        return { text: i18n.circle_anmt_rejected(), color: "#EF3537FF" };
      }
      default:
        return { text: "", color: "#FFFFFF66" };
    }
  }

  return (
    <VStack style={{ width: "100%", gap: 20 }}>
      <HStack
        style={{
          width: "100%",
          padding: 8,
          backgroundColor: "#FFFFFF0F",
          borderRadius: 6,
          gap: 12,
        }}
      >
        <Image
          src={[
            props.assetOrder.tokenProject?.image,
            { width: 74, height: 74 },
          ]}
          style={{ width: 74, height: 74, borderRadius: 6 }}
        />
        <VStack style={{ flex: 1, gap: 8 }}>
          <TokenName>{props.assetOrder.tokenProject?.ticker}</TokenName>
          <TokenCreation>{i18n.clover_token_creation()}</TokenCreation>
        </VStack>
      </HStack>

      <ContentGroup style={{ width: "100%", gap: 20 }}>
        <HStack>
          <OrderInfoLabel>{i18n.status()}</OrderInfoLabel>
          <Spring />
          <StatusBar
            statusColor={getStatus().color}
            statusText={getStatus().text}
          />
        </HStack>
        <CellDivider />
        <HStack>
          <OrderInfoLabel>{i18n.clover_token_creation_fee()}</OrderInfoLabel>
          <Spring />
          <HStack style={{ gap: 4 }}>
            <Image
              src={getCurrencyIcon(props.assetOrder?.totalDeductionList?.at(0))}
              style={{ width: 18, height: 18 }}
            />
            <TokenAmount>
              {`${formatMoney("long", props.assetOrder?.totalDeductionList?.at(0))} ${getCurrencyName(props.assetOrder?.totalDeductionList?.at(0), i18n)}`}
            </TokenAmount>
          </HStack>
        </HStack>
        <CellDivider />
        <HStack>
          <OrderInfoLabel>{i18n.circle_level_total_supply()}</OrderInfoLabel>
          <Spring />
          <OrderInfoContent>
            {props.assetOrder.tokenProject?.totalSupply}
          </OrderInfoContent>
        </HStack>
        <CellDivider />
        <HStack style={{ width: "100%" }}>
          <OrderInfoLabel>{i18n.clover_related_circle()}</OrderInfoLabel>
          <Spring />
          <HStack style={{ gap: 4 }}>
            <Image
              src={[props.assetOrder.circle?.icon, { width: 18, height: 18 }]}
              style={{ width: 18, height: 18, borderRadius: 4 }}
            />
            <SingleOrderInfoContent>
              {props.assetOrder.circle?.name}
            </SingleOrderInfoContent>
          </HStack>
        </HStack>
        <CellDivider />
        <HStack>
          <OrderInfoLabel>{i18n.web3_v0_created_time()}</OrderInfoLabel>
          <Spring />
          <OrderInfoContent>
            {getOrderFormattedDateTime(props.assetOrder.createdTime)}
          </OrderInfoContent>
        </HStack>
        <CellDivider />
        <HStack>
          <OrderInfoLabel>{i18n.web3_v0_transaction_order_id()}</OrderInfoLabel>
          <Spring />
          <OrderInfoContent>
            {props.assetOrder.orderId.toString()}
          </OrderInfoContent>
        </HStack>
      </ContentGroup>
    </VStack>
  );
}

const TokenName = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: white;
  ${OverFlowTextStyle};
  line-clamp: 1;
  -webkit-line-clamp: 1;
`;

const TokenCreation = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #ffffff66;
`;

const TokenAmount = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: white;
`;

export const SingleOrderInfoContent = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: white;
  ${OverFlowTextStyle};
  line-clamp: 1;
  -webkit-line-clamp: 1;
  max-width: 150px;
`;

export function getOrderFormattedDateTime(seconds?: number): string {
  return formatDateWithOptions(seconds || 0, {
    dateStyle: "medium",
    timeStyle: "short",
    hour12: false,
  });
}
