import { HStack, HStackMixin, Spring } from "./VStack";
import { Image } from "./Image";
import SearchLayout from "./SearchLayout";
import React from "react";
import styled from "styled-components";
import logo from "../res/images/logo_clover_bar.svg";
import icBellUnread from "../res/images/icon_bell_unread.svg";
import icBell from "../res/images/ic_bell.svg";
import icSOL from "../res/images/icon_sol.svg";
import icGem from "../res/images/icon_gems.svg";
import { useI18n } from "../hooks/useI18n";
import { useHopper } from "../hooks/useHopper";
import { useObscuringBar } from "../hooks/useObscuringBar";
import { useWalletSWR } from "../pages/wallet/useWallet";
import { CurrencyType, formatMoney } from "../proto/Currency";
import { useSWR } from "../hooks/swr/useSWR";
import { useBackend } from "../service/APIService";
import icGetApp from "../res/images/ic_mobile.svg";

const Bg = styled.div`
  ${HStackMixin};
  height: 96px;
  width: 100%;
  top: 0;
  position: fixed;
  box-sizing: border-box;
  justify-content: space-around;
  align-items: center;

  padding: 8px 20px;

  /* 背景颜色和透明度，增加黑色蒙层效果 */
  background-color: rgba(0, 0, 0, 0.45); /* 加入带透明度的黑色背景 */
  backdrop-filter: blur(10px); /* 毛玻璃效果 */

  /* 为不支持 backdrop-filter 的浏览器提供兼容性支持 */
  -webkit-backdrop-filter: blur(10px); /* Safari 兼容 */

  /* 备用方案，若浏览器不支持 backdrop-filter，提供带黑色透明的背景颜色 */
  @supports not (
    (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))
  ) {
    background-color: rgba(0, 0, 0, 0.5); /* 提供带黑色透明的背景颜色 */
  }

  border-bottom: 1px solid rgba(255, 255, 255, 0.2); /* 为了提升视觉效果的边框 */
`;

export const WideTopBar = function () {
  const i18n = useI18n();
  const hopper = useHopper();
  const [ref] = useObscuringBar("WideTopBar", "top");
  const [wallet, walletSWR] = useWalletSWR();
  const backend = useBackend();
  const alertCounts = useSWR(backend.getAlertCounts());
  const hasAlerts =
    (alertCounts.content?.activitiesAlertCount || 0) +
      (alertCounts.content?.followersAlertCount || 0) +
      (alertCounts.content?.likesAlertCount || 0) +
      (alertCounts.content?.postCount || 0) +
      (alertCounts.content?.circleAlertCount || 0) >
    0;

  return (
    <Bg ref={ref}>
      <HStack
        style={{
          height: "100%",
          padding: "8px 20px",
          flexGrow: 1,
          justifyContent: "center",
          gap: 12,
          alignItems: "center",
        }}
      >
        <Image src={logo} height={48} onClick={() => hopper.push("")} />
        <Spring />
        <div style={{ width: "600px" }}>
          <SearchLayout onClick={() => hopper.popOver("search")} />
        </div>
        <Spring />
        <Image
          src={hasAlerts ? icBellUnread : icBell}
          width={36}
          height={36}
          alt={"icon-dropdown"}
          onClick={() => hopper.push("alert-center")}
        />
        <WalletPreviewBg onClick={() => hopper.push(`wallet`)}>
          <HStack style={{ gap: 6 }}>
            <Image src={icGem} width={16} height={16} alt={"icon-sop"} />
            <WalletBalance>
              {formatMoney(
                "short",
                wallet?.walletAccountList?.[0]?.currencyList?.find(
                  (cur) => cur.currencyType === CurrencyType.GEMS,
                ),
              ) || 0}
            </WalletBalance>
          </HStack>
          <div
            style={{
              height: 16,
              width: 1,
              backgroundColor: "rgba(255, 255, 255, 0.1)",
            }}
          ></div>
          <HStack style={{ gap: 6 }}>
            <Image src={icSOL} width={16} height={16} alt={"icon-sol"} />
            <WalletBalance>
              {formatMoney(
                "short",
                wallet?.walletAccountList?.[0]?.currencyList?.find(
                  (cur) => cur.currencyType === CurrencyType.SOL,
                ),
              ) || 0}
            </WalletBalance>
          </HStack>
        </WalletPreviewBg>
        <GetAppBg onClick={() => hopper.modal(`nyi`, { getApp: true })}>
          <GetAppImg src={icGetApp} />
          <WalletBalance>{i18n.clover_get_app()}</WalletBalance>
        </GetAppBg>
      </HStack>
    </Bg>
  );
};

const BrandTitle = styled.div`
  font-weight: 900;
  font-size: 24px;
  color: var(--color-text00);
`;

const LanguagePickerBg = styled.div`
  display: flex;
  flex-direction: row;
  margin-inline-start: 4px;
  align-items: center;
  gap: 8px;
  padding: 12px;
  border-radius: 6px;
  background-color: rgba(45, 45, 45, 0.7);
`;

const LanguageTitle = styled.div`
  font-weight: 500;
  font-size: 15px;
  color: var(--color-text00);
`;

const WalletPreviewBg = styled.div`
  display: flex;
  flex-direction: row;
  margin-inline-start: 4px;
  align-items: center;
  gap: 12px;
  padding: 12px;
  border-radius: 6px;
  background-color: rgba(45, 45, 45, 0.7);
`;

const WalletBalance = styled.div`
  font-weight: 500;
  font-size: 15px;
  color: var(--color-text00);
`;

const GetAppBg = styled.div`
  background: #2d2d2db2;
  ${HStackMixin};
  gap: 12px;
  padding: 8px 12px;
  border-radius: 6px;
`;

const GetAppImg = styled.img`
  width: 18px;
  height: 26px;
`;
