import { CenterModal, ModalController } from "../../../components/Modal";
import styled from "styled-components";
import { useI18n } from "../../../hooks/useI18n";
import { HStack, VStack } from "../../../components/VStack";
import {
  Currency,
  formatMoney,
  getCurrencyIcon,
} from "../../../proto/Currency";
import { RegularButton } from "../../../components/Buttons";
import { Image } from "../../../components/Image";
import { useContext } from "react";
import { ModalContext } from "../../../components/ModalContext";
import { NFT } from "../../../proto/NFT";

export function GiftContentDialog(props: {
  modal: ModalController;
  currency?: Currency;
  nft?: NFT;
}) {
  const i18n = useI18n();

  return (
    <CenterModal modal={props.modal} hideCloseBtn={true}>
      <VStack style={{ width: "100%", alignItems: "center" }}>
        <Title>{i18n.web3_v0_you_have_claimed_the_gift()}</Title>
        {props.currency && (
          <HStack style={{ marginTop: 35 }}>
            <CurrencyIcon src={getCurrencyIcon(props.currency)} />
            <CurrencyAmount>
              {formatMoney("long", props.currency)}
            </CurrencyAmount>
          </HStack>
        )}
        {props.nft && (
          <Image
            src={[props.nft.entity?.preview, "best"]}
            style={{ width: 167, height: 167, borderRadius: 6 }}
          />
        )}
        <RegularButton
          style={{ marginTop: 40, width: "100%" }}
          onClick={() => {
            props.modal.close();
          }}
        >
          {i18n.ok()}
        </RegularButton>
      </VStack>
    </CenterModal>
  );
}

const Title = styled.div`
  font-weight: 700;
  font-size: 18px;
  color: white;
`;

const CurrencyIcon = styled.img`
  width: 50px;
  height: 50px;
`;

const CurrencyAmount = styled.div`
  font-weight: 700;
  font-size: 48px;
  color: white;
  text-shadow: 0 0 15px #ffffffcc;
`;
