export enum CircleLinkStatus {
  // 已关联token,但未付款
  Pending = 1,
  // 已付款, 订单处理中
  InProgress = 2,
  // 订单完成, 关联成功
  Complete = 3,
  // 订单失败, 关联失败
  Failed = 4,
}
