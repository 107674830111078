import React, { useMemo } from "react";
import { useToDaMoon, useWebHost } from "../../hooks/useBridge";
import { useHopper } from "../../hooks/useHopper";
import { WidgetRoot } from "../../components/WidgetRoot";
import { Spring, VSpace, VStack } from "../../components/VStack";
import { useStringSearchParam } from "../../hooks/useTypedParam";
import { z } from "zod";
import { getFittestRes, Media } from "../../proto/Media";
import { decodeTPS } from "../../bridge/Bridge";
import styled from "styled-components";
import { Image } from "../../components/Image";
import { zStatic } from "../../utils/zodUtils";
import { ButtonColor, RegularButton } from "../../components/Buttons";
import { useSWR } from "../../hooks/swr/useSWR";
import { AdTag, MoonHotProject } from "./MoonHotProject";
import { andLog } from "../../components/handleError";
import { TokenProjectHot } from "../../proto/TokenProject";
import { shrink_on_pressed } from "../../components/CommonStyles";
import { LoadStateView } from "../../components/LoadStateView";
import { AllDoneWithLoadingFirst } from "../../hooks/LoadState";
import { useHopHost } from "../../hooks/useHopState";
import { isSpongeKit } from "../../utils/isSpongeKit";

const RedirectInfo = z.object({
  icon: Media.optional(),
  title: z.string().optional(),
  content: z.string().optional(),
  button: z.string().optional(),
  link: z.string().optional(),
  background: Media.optional(),
});

const RedirectCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-radius: 12px;
  border: 1px solid #ffd704;
  box-sizing: border-box;
  padding: 30px;
  box-shadow: 0 0 8px 1px rgba(255, 199, 0, 0.41);
  margin: 10px 20px;

  min-height: calc(100vw - 40px);
  ${shrink_on_pressed};
`;

const RedirectTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  text-align: center;

  color: var(--color-text00);
`;
const RedirectDesc = styled.div`
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: var(--color-text00);
`;

function Redirect(props: { info: zStatic<typeof RedirectInfo> }) {
  const hopper = useHopper();
  const webHost = useWebHost();
  const bgUrl = useMemo(() => {
    return props.info.background
      ? getFittestRes(props.info.background, { width: 400, height: 400 }).url
      : undefined;
  }, [props.info.background]);

  return (
    <RedirectCard
      style={{
        background: `url(${bgUrl}) center/cover no-repeat`,
      }}
      onClick={() => {
        if (props.info.link) {
          webHost.openInWebBrowser(props.info.link).catch(andLog);
        }
      }}
    >
      <Image
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: 20,
        }}
        width={102}
        height={102}
        src={[props.info.icon, 102]}
      />
      <Spring />
      <RedirectTitle>{props.info.title}</RedirectTitle>
      <VSpace height={8} />
      <RedirectDesc>{props.info.content}</RedirectDesc>
      <RegularButton
        $baseColor={ButtonColor.greenish}
        style={{
          marginTop: 20,
          fontWeight: 600,
          fontSize: 17,
          minWidth: "70%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {props.info.button}
      </RegularButton>
      <AdTag>AD</AdTag>
    </RedirectCard>
  );
}

class HotProjectNotFound extends Error {
  constructor() {
    super("Hot Token project not found"); // call the parent constructor
    this.name = "HotProjectNotFound"; // set the name property
  }
}

export function MoonCardWidget() {
  const hopper = useHopper();
  const webHost = useWebHost();
  const toDaMoon = useToDaMoon();
  const redirectParam = useStringSearchParam("redirect", "");

  const redirectInfoSWR = useSWR("redirectInfo", () =>
    RedirectInfo.parseAsync(decodeTPS(redirectParam)),
  );

  const projectHotSWR = useSWR("toDaMoonHotTokenProject", async () => {
    const payload = await toDaMoon.getMoonHotProject().run();
    const project = payload.project;
    if (project) {
      return {
        project: project,
        url: payload.url,
        amount: payload.amount,
      } as Required<TokenProjectHot>;
    } else {
      throw new HotProjectNotFound();
    }
  });

  const loadState = useMemo(
    () =>
      AllDoneWithLoadingFirst(
        [redirectInfoSWR.loadState, projectHotSWR.loadState],
        (e: unknown) => !(e instanceof HotProjectNotFound),
      ),
    [redirectInfoSWR.loadState, projectHotSWR.loadState],
  );

  const hopHost = useHopHost();

  const freshProjectHot = useMemo(() => {
    const expiryDuration = isSpongeKit() ? 60 * 1000 : 24 * 60 * 60 * 1000;
    if (
      projectHotSWR.content &&
      hopHost.hopCreatedTime - projectHotSWR.meta.updatedAt < expiryDuration
    ) {
      return projectHotSWR.content;
    } else {
      return undefined;
    }
  }, [projectHotSWR.content, projectHotSWR.meta, hopHost.hopCreatedTime]);

  return (
    <WidgetRoot onClick={() => {}}>
      {!freshProjectHot && redirectInfoSWR.content && (
        <Redirect info={redirectInfoSWR.content} />
      )}
      {freshProjectHot && <MoonHotProject projectHot={freshProjectHot} />}
      {(loadState?.kind === "loadFailed" || loadState?.kind === "loading") && (
        <VStack
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            height: "100vw",
            justifyContent: "center",
          }}
        >
          <LoadStateView
            loadState={loadState}
            onClickRetry={() => {
              redirectInfoSWR.load().catch(andLog);
              projectHotSWR.load().catch(andLog);
            }}
          ></LoadStateView>
        </VStack>
      )}
    </WidgetRoot>
  );
}
