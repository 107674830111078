import {
  AssetOrder,
  AssetOrderStatus,
  TxCategory,
} from "../../../proto/AssetOrder";
import { HStack, Spring, VSpace, VStack } from "../../../components/VStack";
import { Image } from "../../../components/Image";
import {
  formatMoney,
  getCurrencyAmountFromString,
  getCurrencyIcon,
  getCurrencyIconByCurrencyType,
  getCurrencyName,
  getCurrencyNameByCurrencyType,
} from "../../../proto/Currency";
import React, { useState } from "react";
import { MainCurrencyAmount, MainCurrencyName } from "./TopUpOrderDetail";
import { StatusBar } from "./GiftOrderListPage";
import { useI18n } from "../../../hooks/useI18n";
import { CellDivider, ContentGroup } from "../../../components/ContentGroup";
import { OrderInfoContent, OrderInfoLabel } from "./GiftOrderDetail";
import { formatDateWithOptions } from "../../../utils/DateTimeUtils";

export function SendTokenOrderDetail(props: { assetOrder: AssetOrder }) {
  const i18n = useI18n();

  function getStatus() {
    switch (props.assetOrder.orderStatus) {
      case AssetOrderStatus.Done:
        return { text: i18n.web3_v0_completed(), color: "#00FF94" };
      case AssetOrderStatus.Pending:
      case AssetOrderStatus.Submitted: {
        return { text: i18n.web3_v0_pending(), color: "#34A6FFFF" };
      }
      case AssetOrderStatus.Failed: {
        return { text: i18n.circle_anmt_failed(), color: "#EF3537FF" };
      }
      case AssetOrderStatus.Rejected: {
        return { text: i18n.circle_anmt_rejected(), color: "#EF3537FF" };
      }
      default:
        return { text: "", color: "#FFFFFF66" };
    }
  }

  const sentCurrency = props.assetOrder.totalDeductionList?.at(0);

  return (
    <VStack style={{ width: "100%", alignItems: "center" }}>
      <HStack style={{ gap: 7, marginTop: 47 }}>
        <Image
          src={getCurrencyIcon(sentCurrency)}
          style={{ width: 36, height: 36, borderRadius: 4 }}
        />
        <HStack style={{ gap: 7, alignItems: "baseline" }}>
          <MainCurrencyAmount>
            {formatMoney("long", sentCurrency)}
          </MainCurrencyAmount>
          <MainCurrencyName>
            {getCurrencyName(sentCurrency, i18n)}
          </MainCurrencyName>
        </HStack>
      </HStack>
      <VSpace height={8} />
      <StatusBar
        statusColor={getStatus().color}
        statusText={getStatus().text}
      />
      <ContentGroup style={{ width: "100%", gap: 20, marginTop: 32 }}>
        <HStack>
          <OrderInfoLabel>{i18n.web3_v0_service_fee()}</OrderInfoLabel>
          <Spring />
          <OrderInfoContent>
            {`${formatMoney("long", props.assetOrder.gasFee)} ${getCurrencyName(props.assetOrder.gasFee, i18n)}`}
          </OrderInfoContent>
        </HStack>
        <CellDivider />
        <HStack>
          <OrderInfoLabel>{i18n.clover_total_deduction()}</OrderInfoLabel>
          <Spring />
          <VStack style={{ alignItems: "end" }}>
            {props.assetOrder.totalDeductionList?.map((currency) => (
              <OrderInfoContent>{`${formatMoney("long", currency)} ${getCurrencyName(currency, i18n)}`}</OrderInfoContent>
            ))}
          </VStack>
        </HStack>
        <CellDivider />
        <HStack>
          <OrderInfoLabel>{i18n.web3_v0_source()}</OrderInfoLabel>
          <Spring />
          <OrderInfoContent>{i18n.web3_v0_default_account()}</OrderInfoContent>
        </HStack>
        <CellDivider />
        <HStack>
          <OrderInfoLabel>{i18n.march_impr_2023_destination()}</OrderInfoLabel>
          <Spring />
          <OrderInfoContent>{props.assetOrder.toChainAddress}</OrderInfoContent>
        </HStack>
        <CellDivider />
        <HStack>
          <OrderInfoLabel>{i18n.web3_v0_created_time()}</OrderInfoLabel>
          <Spring />
          <OrderInfoContent>
            {formatDateWithOptions(props.assetOrder.createdTime || 0, {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })}
          </OrderInfoContent>
        </HStack>
        <CellDivider />
        <HStack>
          <OrderInfoLabel>{i18n.web3_v0_transaction_order_id()}</OrderInfoLabel>
          <Spring />
          <OrderInfoContent>
            {props.assetOrder.orderId.toString()}
          </OrderInfoContent>
        </HStack>
      </ContentGroup>
    </VStack>
  );
}
