import { string, z } from "zod";
import { Media } from "./Media";
import { zBigInt, zStatic } from "../utils/zodUtils";
import { Chat } from "./Chat";
import { Post } from "./Post";
import { CircleFolder } from "./CircleFolder";

export const CircleFolderItem = z.object({
  id: zBigInt,
  circleId: zBigInt,
  objectId: zBigInt,
  status: z.number().optional(),
  objectType: z.number(),
  createdTime: z.number().optional(),
  updatedTime: z.string().optional(),
  objectName: z.string().optional(),
  folder: CircleFolder.optional(),
  chat: Chat.optional(),
  blog: Post.optional(),
  link: z.string().optional(),
});
export type CircleFolderItem = zStatic<typeof CircleFolderItem>;
