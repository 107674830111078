import { Chat } from "../../../proto/Chat";
import { Image } from "../../../components/Image";
import styled from "styled-components";
import { ChatThreadType } from "../../../proto/ChatThreadType";
import { useMyUid } from "../../../service/AuthSessionService";
import React, { useMemo } from "react";
import { isValidMedia } from "../../../proto/Media";
import { ChatAlert, ChatMemberStatus } from "../../../proto/ChatMember";

function SingleChatIcon(props: { chat: Chat }) {
  const myUid = useMyUid();
  const icon = props.chat.membersSummary?.find(
    (user) => user.uid !== myUid,
  )?.icon;
  return (
    <Image
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        left: 0,
      }}
      src={[icon, "smallest"]}
    />
  );
}

const GroupSingleIcon = styled.div`
  position: absolute;
  width: 60%;
  height: 60%;
  border-radius: 50%;
  overflow: clip;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
`;

const GroupEmptyIcon = styled.div`
  position: absolute;
  width: 60%;
  height: 60%;
  border-radius: 50%;
  overflow: clip;

  border-width: 1px;
  border-style: dashed;
  border-color: rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
`;

function GroupChatIcon(props: { chat: Chat }) {
  const groupUserCount = props.chat.membersSummary?.length ?? 0;
  const emptyUserCount = Math.max(4 - groupUserCount, 0);
  return (
    <>
      {emptyUserCount > 0 && <GroupEmptyIcon style={{ left: 0, bottom: 0 }} />}
      {emptyUserCount > 1 && <GroupEmptyIcon style={{ right: 0, bottom: 0 }} />}
      {emptyUserCount > 2 && <GroupEmptyIcon style={{ right: 0, top: 0 }} />}
      {emptyUserCount > 3 && <GroupEmptyIcon style={{ left: 0, top: 0 }} />}
      {groupUserCount > 3 && (
        <GroupSingleIcon style={{ left: 0, bottom: 0 }}>
          <Image
            src={[props.chat.membersSummary?.[3].icon, "smallest"]}
            style={{ width: "100%", height: "100%" }}
          />
        </GroupSingleIcon>
      )}
      {groupUserCount > 2 && (
        <GroupSingleIcon style={{ right: 0, bottom: 0 }}>
          <Image
            src={[props.chat.membersSummary?.[2].icon, "smallest"]}
            style={{ width: "100%", height: "100%" }}
          />
        </GroupSingleIcon>
      )}
      {groupUserCount > 1 && (
        <GroupSingleIcon style={{ right: 0, top: 0 }}>
          <Image
            src={[props.chat.membersSummary?.[1].icon, "smallest"]}
            style={{ width: "100%", height: "100%" }}
          />
        </GroupSingleIcon>
      )}
      {groupUserCount > 0 && (
        <GroupSingleIcon style={{ left: 0, top: 0 }}>
          <Image
            src={[props.chat.membersSummary?.[0].icon, "smallest"]}
            style={{ width: "100%", height: "100%" }}
          />
        </GroupSingleIcon>
      )}
    </>
  );
}

function PartyChatIcon(props: { chat: Chat }) {
  return (
    <Image
      style={{
        width: "calc(100% - 2px)",
        height: "75%",
        position: "absolute",
        top: "12.5%",
        left: 0,
        borderRadius: "5%",
        border: "1px solid rgba(255, 255, 255, 0.40)",
      }}
      src={[props.chat.icon, "smallest"]}
    ></Image>
  );
}

export default function ChatThreadIcon(props: { chat: Chat }) {
  const isParty =
    props.chat.type === ChatThreadType.public ||
    (props.chat.type === ChatThreadType.group && isValidMedia(props.chat.icon));
  const shouldUseGroupIcon =
    props.chat.type === ChatThreadType.group && !isValidMedia(props.chat.icon);

  const borderRadius = isParty ? "3%" : shouldUseGroupIcon ? "0" : "50%";
  const border =
    shouldUseGroupIcon || isParty
      ? "none"
      : "1px solid rgba(255, 255, 255, 0.40)";

  const isJoined =
    props.chat.currentMemberInfo?.chatMemberStatusV2 ===
    ChatMemberStatus.Active;
  const isAlertOn = props.chat.currentMemberInfo?.alertOption === ChatAlert.On;

  const hasUnreadSmallNote = useMemo(() => {
    if (props.chat.smallNoteList && props.chat.smallNoteList.length > 0) {
      const firstNote = props.chat.smallNoteList[0];
      return !(firstNote.readTime && firstNote.readTime > 0);
    }
    return false;
  }, [props.chat]);

  const hasNewMsg =
    (props.chat.latestMessageId ?? 0) >
    (props.chat.currentMemberInfo?.lastReadMessageId ?? 0);
  const hasMention = props.chat.mentionedBy !== undefined;

  return (
    <div style={{ position: "relative", overflow: "visible", padding: "2px" }}>
      <div
        style={{
          position: "relative",
          width: "calc(100% - 2px)",
          paddingTop: "calc(100% - 2px)",
          borderRadius: borderRadius,
          border: border,
          overflow: "clip",
        }}
      >
        {isParty && <PartyChatIcon chat={props.chat} />}
        {shouldUseGroupIcon && <GroupChatIcon chat={props.chat} />}
        {!isParty && !shouldUseGroupIcon && (
          <SingleChatIcon chat={props.chat} />
        )}
      </div>
      {((isJoined && hasNewMsg) || hasUnreadSmallNote || hasMention) && (
        <div
          style={{
            backgroundColor: isAlertOn ? "red" : "white",
            width: "10px",
            height: "10px",
            position: "absolute",
            borderRadius: "50%",
            right: isParty ? "0" : "4%",
            top: shouldUseGroupIcon ? "2%" : "12%",
          }}
        ></div>
      )}
    </div>
  );
}
