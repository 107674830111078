import { z } from "zod";
import { zBigInt, zEnum, zStatic } from "../utils/zodUtils";
import { Currency } from "./Currency";
import { StoreOrder } from "./Order";
import { TransferOrder } from "./TransferOrder";
import { AssetOrder, PurchaseInfo } from "./AssetOrder";
import { Coupon } from "./Coupon";
import { NFT } from "./NFT";

export enum TransactionReason {
  InAppPurchase = 1, // 充值
  PayForOrder = 2, // 支付订单
  CreateTransferOrder = 3, // 创建Transfer
  ClaimTransferOrder = 4, // 接收Transfer
  ReturnTransferOrder = 5, // 自动退回Transfer
  TopUp = 6, // 入金
  WithDraw = 7, // 提现
  MintNFT = 8,
  SwapFrom = 9, // 从...交换
  SwapTo = 10, // 交换至...
  InjectForPurchase = 11,
  InjectForInit = 12,
  InjectForTopUp = 13,
  InjectForCreatingNFT = 14,
  ListMerch = 15,
  DeListMerch = 16,
  WithDrawStore = 17, // 从商店提现...
  InternalManagement = 18,
  GasFee = 19,
  TxReasonReturn = 20, // 退款
  InternalAirdrop = 21,
  TxReasonStoreOrderServiceFee = 22, // 购买商店物品service fee
  TxReasonWithdrawServiceFee = 23, // 体现service fee
  ExternalAccountTx = 24,
  NftBought = 25,
  InjectForUserMintNFT = 34,
  BurnUserProtoNFT = 35,
  UserMintNFTServiceFee = 36,
  UseMintCoupon = 40,
  GiftOrderServiceFee = 43,
  TxReasonNFTCreatorFee = 44,
  Redeem = 46,
  UseCouponPayForOrder = 48,
  MintSpeedUpFee = 49,
  ZECMintNFT = 50,
  UserBuyAIImageQuotaPackage = 51,
  CashSwapFrom = 52,
  CashTransferOut = 53,
  CashTransferOutServiceFee = 54,
  CashTransferOutServiceFeeReturn = 55,
  TxReasonUserBuyAIChatQuotaPackage = 56,
  TxReasonUseCouponPayForAIImage = 57,
  TxReasonUseCouponPayForAIChat = 58,
  ThirdPartyPurchase = 59,
  TransferOrderServiceFee = 60,
  ReturnTransferOrderServiceFee = 61,
  UsePrimeMembershipCoupon = 62,
  TransferChainAsset = 63,
  TRADE_FROM = 66,
  TRADE_TO = 67,
  GET_MEMBERSHIP_CARD = 69,
  REDEEM_MEMBERSHIP_CARD = 70,
  TOKEN_CREATION_FEE = 71,
  StakeMembershipCard = 72,
  TransferAdminCommission = 73,
  SendAdminMembershipCard = 76,
}

export enum TransactionAmountType {
  ADD = 1,
  MINUS = 2,
}

export const TransactionLog = z.object({
  txId: zBigInt.optional(),
  fromAccountId: zBigInt.optional(),
  toAccountId: zBigInt.optional(),
  nftId: zBigInt.optional(),
  newExpiresTime: z.number().optional(),
  nft: NFT.optional(),
  createdTime: z.number().optional(),
  amount: z.string().optional(),
  txReason: zEnum(TransactionReason).optional(),
  interval: z.number().optional(),
  vendorType: z.number().optional(),
  currencyType: z.number().optional(),
  currency: Currency.optional(),
  txAmountType: zEnum(TransactionAmountType).optional(),
  gasFee: Currency.optional(),
  txStatus: z.number().optional(),
  coupon: Coupon.optional(),
  storeOrder: StoreOrder.optional(),
  assetOrder: AssetOrder.optional(),
  transferOrder: TransferOrder.optional(),
  externalRelatedId: z.string().optional(),
  toChainAddress: z.string().optional(),
  fromChainAddress: z.string().optional(),
  purchaseInfo: PurchaseInfo.optional(),
});

export type TransactionLog = zStatic<typeof TransactionLog>;
