import { ZodType } from "zod/lib/types";
import { TokenProject, TokenProjectHot } from "../proto/TokenProject";
import { PagedList, PageParam } from "../proto/PagedList";
import { Circle, CircleMemberCard, CircleTokenPool } from "../proto/Circle";
import { CircleGridType } from "../pages/circle/CircleGridPage";
import { z } from "zod";
import { Account, Auth, AuthBody, SupportedSMS } from "../proto/Auth";
import { Post, PostAndAnnouncement } from "../proto/Post";
import { CircleFolderItem } from "../proto/CircleFolderItem";
import { MyMoodsList, User, UserExtensions, UserMood } from "../proto/User";
import { CircleFolder } from "../proto/CircleFolder";
import { Category } from "../proto/Category";
import { BackendClient, EndPoint } from "./EndPoint";
import { Chat, ChatListCategory, ChatListResponse } from "../proto/Chat";
import { OperationPage } from "../proto/OperationPage";
import { BareComment, Comment } from "../proto/Comment";
import { BackgroundMedia } from "../proto/BackgroundMedia";
import {
  JoinCircleBody,
  JoinCircleBodyQuestion,
  JoinRequestListInfo,
  JoinRequestStatus,
} from "../pages/circle/JoinCircleRequest";
import {
  CurrencyHolder,
  GasFeeTxType,
  UserProps,
  Wallet,
  WalletAccount,
} from "../proto/Wallet";
import { CircleMemberTitle } from "../proto/CircleMemberTitle";
import { CircleAttendData } from "../proto/CircleAttendData";
import { ChatMessage } from "../proto/ChatMessage";
import { Alert, AlertGroup } from "../proto/Alert";
import { CheckInInfo } from "../proto/CheckInInfo";
import { StickerPackage } from "../proto/Reaction";
import { InviterInfo } from "../proto/InviterInfo";
import { Dice } from "../proto/Dice";
import { ChatAlert } from "../proto/ChatMember";
import { Blocking } from "../proto/Blocking";
import { PostListType } from "../pages/post/PostListPage";
import { Bubble } from "../proto/Bubble";
import { UrlQuery } from "../utils/UrlUtil";
import { SocialLinksResp } from "../proto/SocialLinksResp";
import { CreateCircleData } from "../pages/circle/compose/ComposeCircleContext";
import { ObjectSpec, ObjectType } from "../proto/ObjectSpec";
import { LinkModel } from "../proto/LinkModel";
import { ChatData } from "../pages/chat/compose/CreateChatPage";
import { Interest } from "../proto/Interest";
import { TransactionBody } from "../pages/wallet/payment/TransactionBody";
import { ChainType } from "../proto/ChainType";
import { ChainInfo, Currency, CurrencyType } from "../proto/Currency";
import { TransactionResult } from "../pages/wallet/payment/TransactionResult";
import { GasFeeInfo } from "../proto/GasFeeInfo";
import {
  CircleToken,
  CircleTokenHolder,
  CircleTokenQueryType,
} from "../proto/CircleFin";
import { StoreOrder } from "../proto/Order";
import { GiftBox } from "../proto/GiftBox";
import { AccountNFT } from "../proto/AccountNFT";
import { AssetOrder } from "../proto/AssetOrder";
import { TransactionLog } from "../proto/TransactionLog";
import { MarkOrderReadReq } from "../proto/MarkOrderReadReq";
import { AssetAccount } from "../proto/AssetAccount";
import { TransferOrder } from "../proto/TransferOrder";
import { CircleUpgradeRecord } from "../proto/CircleUpgradeRecrd";
import { LaunchTokenResult } from "../pages/wallet/payment/LaunchTokenResult";
import { CreateTokenPresets } from "../pages/wallet/payment/CreateTokenPresets";
import { CurrencyDetail } from "../proto/CurrencyDetail";
import { NFT } from "../proto/NFT";
import { AdminEarningInfo } from "../proto/AdminEarningInfo";
import { CircleCommissionLog } from "../proto/CircleCommissionLog";
import { UserPropsPackage } from "../proto/UserPropsPackage";
import { Merch } from "../proto/Merch";
import { LivePricingData } from "../proto/GemPrice";
import { CircleMemberGroupPreview } from "../proto/CircleMember";
import { VisitLinkResp } from "../proto/VisitLinkResp";
import { WrappedCircleToken } from "../proto/WrappedCircleToken";
import { AlertCounts } from "../proto/AlertCounts";
import { BreadcrumbBody } from "../proto/Breadcrumb";
import { RewardedInfo } from "../proto/RewardedInfo";
import { FlagBody } from "../proto/Flag";
import { ThirdPartyPayment } from "../proto/ThirdPartyPayment";
import { SolRecommend } from "../proto/SolRecommend";
import { ThirdPartyPurchaseResponse } from "../proto/ThirdPartyPurchaseResponse";
import { CircleMembershipCard } from "../proto/CircleMembershipCard";
import { CircleCardStatus } from "../proto/CircleCardStatus";
import { LinkTokenReq } from "../proto/LinkTokenReq";
import { Tag } from "../proto/Tag";
import { CreateTokenReq } from "../proto/CreateTokenReq";
import { MintTokenResult } from "../pages/wallet/payment/MintTokenResult";
import { UpdateTokenProjectRequest } from "../proto/UpdateTokenProjectRequest";

export class Backend {
  constructor(private readonly client: BackendClient) {}

  private GET<S extends ZodType<any, any, any>>(
    responseScheme: S,
    url: string,
    query?: UrlQuery,
  ) {
    return new EndPoint(
      this.client,
      responseScheme,
      "GET",
      url,
      query,
      undefined,
    );
  }

  private DELETE<S extends ZodType<any, any, any>>(
    responseScheme: S,
    url: string,
    query?: UrlQuery,
  ) {
    return new EndPoint(
      this.client,
      responseScheme,
      "DELETE",
      url,
      query,
      undefined,
    );
  }

  private DELETEWithBody<S extends ZodType<any, any, any>>(
    responseScheme: S,
    url: string,
    body?: object,
  ) {
    return new EndPoint(
      this.client,
      responseScheme,
      "DELETE",
      url,
      undefined,
      body,
    );
  }

  private POST<S extends ZodType<any, any, any>>(
    responseScheme: S,
    url: string,
    body?: object,
  ) {
    return new EndPoint(
      this.client,
      responseScheme,
      "POST",
      url,
      undefined,
      body,
    );
  }

  private POSTWithQuery<S extends ZodType<any, any, any>>(
    responseScheme: S,
    url: string,
    query?: UrlQuery,
  ) {
    return new EndPoint(
      this.client,
      responseScheme,
      "POST",
      url,
      query,
      undefined,
    );
  }

  getCircles = (type: CircleGridType, categoryId?: bigint) =>
    this.GET(PagedList(Circle), `/v1/circles`, {
      type: type,
      categoryId: categoryId,
    });

  searchCircles = (word: string) =>
    this.GET(PagedList(Circle), `/v1/search/circles`, {
      word: word,
    });

  getCircle = (circleId: bigint) => this.GET(Circle, `/v1/circles/${circleId}`);

  getVisibleCircles = (uid: bigint, query: any) =>
    this.GET(
      PagedList(CircleMemberCard),
      `/v1/users/visible-circles/${uid}`,
      query,
    );

  setVisibleCircle = (circleId: bigint, query: any) =>
    this.POST(z.custom(), `/v1/users/visible-circle/${circleId}`, query);

  removeCircleCard = (requestId: bigint) =>
    this.POST(
      z.custom(),
      `/v1/circle-join-requests/${requestId}/step-completed`,
    );

  getCirclePopularPosts = (circleId: bigint) =>
    this.GET(PagedList(Post), `/v1/circles/${circleId}/popular-blogs`);

  getCircleMemberTitles = (circleId: bigint) =>
    this.GET(
      z.object({ list: z.array(CircleMemberTitle) }),
      `/v1/circles/${circleId}/member-titles`,
    );

  getUserNameCards = () => this.GET(PagedList(User), `/v1/users/namecards`);

  getHotThreads = () => this.GET(PagedList(Chat), `/v1/chat/hot-threads`);

  getCircleActiveMembers = (circleId: bigint) =>
    this.GET(PagedList(User), `/v1/circles/${circleId}/active-members`);

  getCircleFolderItems = (circleId: bigint, folderId: bigint) =>
    this.GET(
      PagedList(CircleFolderItem),
      `/v1/circles/${circleId}/folders/${folderId}/items`,
    );

  getCircleLiveParites = (circleId: bigint) =>
    this.GET(PagedList(Chat), `/v1/circles/${circleId}/live-parties`);

  getCircleFolder = (
    circleId: bigint,
    folderId: bigint,
    displayScene?: string,
  ) =>
    this.GET(CircleFolder, `/v1/circles/${circleId}/folders/${folderId}`, {
      displayScene,
    });

  updateCircleFolder = (
    circleId: bigint,
    folderId: bigint,
    folder: CircleFolder,
  ) =>
    this.POST(
      CircleFolder,
      `/v1/circles/${circleId}/folders/${folderId}`,
      folder,
    );

  sendLocalMedia = this.client.sendLocalMedia;

  getMoonHotProject = () =>
    this.GET(TokenProjectHot, `/v1/recommend-hot-project`);

  getSupportedSmsCC = () =>
    this.GET(
      z.object({ list: z.array(SupportedSMS) }),
      `/v1/auth/supported-sms-cc`,
    );

  getCategories = (type: number) =>
    this.GET(z.object({ list: z.array(Category) }), `/v1/categories`, {
      objectType: type,
    });

  getOperationPage = (
    contentRegion: number,
    parentId: bigint,
    contentType: number,
    pageType: number,
  ) =>
    this.GET(OperationPage, `/v1/operation-page`, {
      contentRegion: contentRegion,
      parentId: parentId,
      contentType: contentType,
      pageType: pageType,
    });

  logIn = (auth: AuthBody) => this.POST(Auth, `/v1/auth/login`, auth);
  logOut = () => this.POST(z.custom(), `/v1/auth/logout`);

  getPosts = (type: string, label?: string) =>
    this.GET(PagedList(Post), `/v1/blogs`, {
      type: type,
      rcmdBlogLabel: label,
    });
  getUserBlogs = (targetUid: bigint) =>
    this.GET(PagedList(Post), `/v1/blogs`, { type: "user", targetUid });
  getChatAnnouncements = (threadId: bigint) =>
    this.GET(PagedList(Post), `/v1/blogs`, { type: "chat", threadId });

  getUser = (uid: bigint) => this.GET(User, `/v1/users/profile/${uid}`);

  followUser = (uid: bigint) =>
    this.POST(z.custom(), `/v1/users/membership/${uid}`);

  unfollowUser = (uid: bigint) =>
    this.DELETE(z.custom(), `/v1/users/membership/${uid}`);

  leaveCircle = (circleId: bigint) =>
    this.DELETE(Circle, `/v1/circles/${circleId}/members`);

  deleteCircle = (circleId: bigint) =>
    this.DELETE(z.custom(), `/v1/circles/${circleId}`);

  updateCircle = (circleId: bigint, params: Partial<Circle>) =>
    this.POST(Circle, `/v1/circles/${circleId}`, params);

  updateMembershipCard = (
    circleId: bigint,
    cardId: bigint,
    params: Partial<CircleMembershipCard>,
  ) =>
    this.POST(
      z.custom(),
      `/v1/circles/${circleId}/membership-cards/${cardId}`,
      params,
    );

  getMembershipCard = (circleId: bigint, cardId: bigint) =>
    this.GET(
      CircleMembershipCard,
      `/v1/circles/${circleId}/membership-cards/${cardId}`,
    );

  getAdminMembershipCard = (circleId: bigint) =>
    this.GET(NFT, `/v1/circles/${circleId}/admin-membership-card`);

  linkTokenWithCircle = (circleId: bigint, params: LinkTokenReq) =>
    this.POST(z.custom(), `/v1/circles/${circleId}/token/draft-link`, params);

  updateMembershipCardStatus = (
    circleId: bigint,
    cardId: bigint,
    params: Partial<CircleMembershipCard>,
  ) =>
    this.POST(
      z.custom(),
      `/v1/circles/${circleId}/membership-cards/${cardId}/status`,
      params,
    );

  getJoinedThreads = (type: ChatListCategory) =>
    this.GET(ChatListResponse, `/v1/chat/joined-threads`, {
      type,
    });

  getThreadInfo = (threadId: bigint, fillChatBubble: boolean = false) =>
    this.GET(Chat, `/v1/chat/threads/${threadId}`, { fillChatBubble });

  getThreadOnlineMembers = (threadId: bigint, size: number) =>
    this.GET(PagedList(User), `/v1/chat/threads/${threadId}/online-members`, {
      size,
    });

  getThreadMessages = (threadId: bigint, withUserList: boolean) =>
    this.GET(PagedList(ChatMessage), `/v1/chat/threads/${threadId}/messages`, {
      withUserList,
    });

  getComments = (parentId: bigint, parentType: number, replyId: bigint | 0) =>
    this.GET(PagedList(Comment), `/v1/comments`, {
      parentId,
      parentType,
      replyId,
    });

  getCircleStatsInfo = (circleId: bigint) =>
    this.GET(CircleAttendData, `/v1/circles/${circleId}/stats-info`);

  getDirectChat = (uid: bigint) =>
    this.GET(Chat, `/v1/chat/one-on-one-chat/${uid}`);

  getDefaultBackgroundList = () =>
    this.GET(
      z.array(BackgroundMedia),
      `/v1/media/default-background-media-info-list`,
      {
        mediaFrom: 0,
      },
    );

  getCircleCardsList = (circleId: bigint, cardStatus?: CircleCardStatus) =>
    this.GET(PagedList(NFT), `/v1/circles/${circleId}/membership-cards`, {
      cardStatus: cardStatus,
    });

  createChat = (chat: Chat) => this.POST(Chat, `/v1/chat/threads`, chat);

  joinCircle = (circleId: bigint, body: JoinCircleBody) =>
    this.POST(Circle, `/v1/circles/${circleId}/members`, body);

  joinCircleInQuestion = (circleId: bigint, body: JoinCircleBodyQuestion) =>
    this.POST(z.custom(), `/v1/circles/${circleId}/join-requests`, body);

  getCircleJoinRequest = (circleId: bigint, statusList: JoinRequestStatus) =>
    this.GET(
      PagedList(JoinRequestListInfo),
      `/v1/circles/${circleId}/join-requests`,
      { statusList },
    );

  getUserJoinRequestLogs = (circleId: bigint, requestId: bigint) =>
    this.GET(
      PagedList(JoinRequestListInfo),
      `/v1/circles/${circleId}/join-requests-logs/${requestId}`,
      {},
    );

  linkPathFrom = (link: string) =>
    this.POST(LinkModel, `/v1/links/path`, { link: link });

  handleCircleJoinRequest = (
    circleId: bigint,
    requestId: bigint,
    operation: number,
    block: boolean,
  ) =>
    this.POST(
      z.custom(),
      `/v1/circles/${circleId}/join-requests/${requestId}`,
      {
        operation: operation,
        block: block,
      },
    );

  checkIdentity = (auth: AuthBody) =>
    this.POST(z.custom(), `/v1/auth/account-identity-check`, auth);

  performTransfer = (accountId: bigint, body: TransactionBody) =>
    this.POST(
      TransactionResult,
      `/biz/v1/wallet/0/asset-accounts/${accountId}/transfer`,
      body,
    );

  createTokenPresets = (circleId: bigint) =>
    this.GET(CreateTokenPresets, `/v1/token-projects/presets`, {
      circleId: circleId,
    });

  performCreateTokenProject = (body: CreateTokenReq) =>
    this.POST(LaunchTokenResult, `/v1/token-projects`, body);

  mintTokenProject = (body: TransactionBody) =>
    this.POST(MintTokenResult, `/biz/v1/wallet/mint-token`, body);

  performPurchaseCircleCard = (body: TransactionBody) =>
    this.POST(
      TransactionResult,
      `/biz/v1/wallet/0/membership-card-purchase`,
      body,
    );

  performRedeem = (body: TransactionBody) =>
    this.POST(
      TransactionResult,
      `/biz/v1/wallet/0/membership-card-redeem`,
      body,
    );

  performTopUp = (body: TransactionBody) =>
    this.POST(TransactionResult, `/biz/v1/wallet/0/chain-purchase`, body);

  performSwap = (accountId: bigint, body: TransactionBody) =>
    this.POST(
      TransactionResult,
      `/biz/v1/wallet/0/asset-accounts/${accountId}/swap`,
      body,
    );

  sendGiftBox = (body: Partial<GiftBox>) =>
    this.POST(GiftBox, `/biz/v1/gift-boxes`, body);

  getPost = (blogId: bigint, objectType: ObjectType) =>
    this.GET(Post, `/v1/blogs/${blogId}`, { objectType: objectType });

  getCircleAnnouncement = (announcementId: bigint) =>
    this.GET(Post, `/v1/circle-announcements/${announcementId}`);

  getGasFee = (
    amount: string,
    toChainAddress: string,
    chainType: ChainType,
    txCurrencyType: CurrencyType,
    txCurrencyId: bigint,
    txType: GasFeeTxType,
  ) =>
    this.GET(GasFeeInfo, `/biz/v1/wallet/gas-fee`, {
      amount,
      toChainAddress,
      chainType,
      txCurrencyType,
      txCurrencyId,
      txType,
    });

  createCircle = (body: CreateCircleData) =>
    this.POST(Circle, `/v2/circles`, body);

  createParty = (body: ChatData) => this.POST(Chat, `/v1/chat/threads`, body);

  addReaction = (objectId: bigint, objectType: number, stickerId: bigint) =>
    this.POST(z.custom(), `/v1/reactions`, { objectId, objectType, stickerId });

  deleteReaction = (objectId: bigint, objectType: number, stickerId: bigint) =>
    this.DELETE(z.custom(), `/v1/reactions`, {
      objectId,
      objectType,
      stickerId,
    });

  requestSecurityCode = (auth: AuthBody) =>
    this.POST(z.custom(), `/v1/auth/request-security-validation`, auth);

  checkSecurityCode = (auth: AuthBody) =>
    this.POST(z.custom(), `/v1/auth/check-security-validation`, auth);

  getWallet = () => this.GET(Wallet, `/biz/v1/wallet`);

  getCircleToken = (circleId: bigint) =>
    this.GET(
      z.union([CircleToken, z.object({})]),
      `/v1/circles/${circleId}/token`,
    );

  getTokenPool = (tokenProjectId: bigint) =>
    this.GET(
      CircleTokenPool,
      `/v1/token-projects/${tokenProjectId}/liquidity`,
    ).run();

  getTokenPoolNoRun = (tokenProjectId: bigint) =>
    this.GET(CircleTokenPool, `/v1/token-projects/${tokenProjectId}/liquidity`);

  getTokenProject = (tokenProjectId: bigint) =>
    this.GET(WrappedCircleToken, `/v1/token-projects/${tokenProjectId}`);

  searchTokenProjects = (word?: string) =>
    this.GET(
      PagedList(TokenProject),
      `/v1/token-projects`,
      word
        ? {
            word: word,
          }
        : undefined,
    );

  getWalletAccount = (accountId: bigint) =>
    this.GET(WalletAccount, `/biz/v1/wallet/0/wallet-accounts/${accountId}`);

  getAccountNFTList = (accountId: bigint) =>
    this.GET(
      PagedList(AccountNFT),
      `/biz/v1/wallet/0/wallet-accounts/${accountId}/nft-list`,
    );

  getNFT = (nftId: bigint) => this.GET(NFT, `/biz/v1/nft/${nftId}`);

  getMembershipCardNFT = (cardId: bigint) =>
    this.GET(NFT, `/biz/v1/nfts/circle-membership-cards/${cardId}`);

  getMerch = (storeId: bigint, merchId: bigint) =>
    this.GET(Merch, `/biz/v1/stores/${storeId}/merch/${merchId}`);

  getCurrencyList = (accountId: bigint) =>
    this.GET(
      z.object({ list: z.array(CurrencyHolder) }),
      `/biz/v1/wallet/0/wallet-accounts/${accountId}/currency-list`,
    );

  getAssetAccounts = (accountId: bigint) =>
    this.GET(
      z.object({ list: z.array(AssetAccount) }),
      `/biz/v1/wallet/0/wallet-accounts/${accountId}/asset-account-list`,
    );

  resetPassword = (auth: AuthBody) =>
    this.POST(z.custom(), `/v1/auth/reset-password`, auth);

  markOrderAsRead = (body: MarkOrderReadReq) =>
    this.POST(z.custom(), `/biz/v1/wallet/0/mark-as-read`, body);

  getRewardedInfo = () =>
    this.GET(RewardedInfo, `/biz/v1/wallet/0/rewarded-info`);

  changePassword = (auth: AuthBody) =>
    this.POST(Auth, `/v1/auth/change-password`, auth);

  getSocialUsers = (
    userId: bigint | number,
    type?: string,
    threadId?: bigint,
    circleId?: bigint,
    word?: string,
    sortType?: number,
  ) =>
    this.GET(PagedList(User), `/v1/users/membership/${userId}`, {
      type,
      threadId,
      circleId,
      word,
      sortType,
    });

  getAlerts = (groupId: AlertGroup) =>
    this.GET(PagedList(Alert), `/v1/alerts`, { groupId: groupId });

  getStickers = (type: string) =>
    this.GET(StickerPackage, "/v1/stickers", { type: type });

  getCheckInLogList = () =>
    this.GET(
      z.object({ list: z.array(CheckInInfo) }),
      `/v1/users/check-in-log-list`,
    );

  checkIn = () => this.POST(CheckInInfo, `/v1/users/check-in`);

  checkInvitationCode = (auth: AuthBody) =>
    this.POST(InviterInfo, `/v1/auth/check-invitation-code`, auth);

  getDices = () => this.GET(z.object({ diceList: z.array(Dice) }), `/v1/dices`);

  blockUser = (userId: bigint) =>
    this.POST(z.custom(), `/v1/users/block/${userId}`);

  unblockUser = (userId: bigint) =>
    this.DELETE(z.custom(), `/v1/users/block/${userId}`);

  pollVote = (vote: { objectId: bigint; objectType: number }) =>
    this.POST(z.custom(), `/v1/votes`, vote);

  deleteVote = (vote: { objectId: bigint; objectType: number }) =>
    this.DELETE(z.custom(), `/v1/votes`, vote);

  updateProfilePrivacy = (uid: bigint, visibility: UserExtensions) =>
    this.POST(User, `/v1/users/profile/${uid}/update-profile`, visibility);

  subscribeObject = (body: any) =>
    this.POST(z.custom(), `/v1/notifications/subscribe`, body);

  updateRemark = (body: any) => this.POST(z.custom(), `/v1/remark`, body);

  removeFavourites = (uid: bigint) =>
    this.DELETE(z.custom(), `/v1/users/membership/${uid}/favorites`);

  addToFavourites = (body: any) =>
    this.POST(z.custom(), `/v1/users/membership/favorites`, body);

  updateProfile = (user: Partial<User>) =>
    this.POST(User, `/v1/users/profile/${user.uid}/update-profile`, user);

  updateChatNotification = (alert: ChatAlert, threadId: bigint) =>
    this.POST(z.custom(), `/v1/chat/threads/${threadId}/alert-option/${alert}`);

  updateChatPin = (pin: boolean, threadId: bigint) => {
    if (pin) {
      return this.POST(z.custom(), `/v1/chat/threads/${threadId}/pin`);
    } else {
      return this.POST(z.custom(), `/v1/chat/threads/${threadId}/unpin`);
    }
  };

  getBlocking = () => this.GET(Blocking, `/v1/users/block-uids`);

  getBlockedUsers = () => this.GET(PagedList(User), `/v1/users/block`);

  getCircleRooms = (circleId: bigint) =>
    this.GET(PagedList(Chat), `/v1/circles/${circleId}/rooms`);

  getCircleAnnouncements = (circleId: bigint) =>
    this.GET(PagedList(Post), `/v1/circles/${circleId}/announcements`);

  getChatroomAnnouncements = (threadId: bigint) =>
    this.GET(PagedList(Post), `/v1/blogs`, {
      type: PostListType.CHAT,
      threadId: threadId,
    });

  getGlobalAnnouncements = () =>
    this.GET(PagedList(Post), `/v1/alerts/global-announcement`);

  checkDeletePreconditions = () =>
    this.GET(z.custom(), `/v1/users/check-delete-preconditions`);

  markDeleteAccount = (auth: AuthBody) =>
    this.POST(z.custom(), `/v1/auth/mark-account-delete`, auth);

  updatePhoneNumber = (auth: AuthBody) =>
    this.POST(z.custom(), `/v1/auth/update-phone-number`, auth);

  updateEmail = (auth: AuthBody) =>
    this.POST(z.custom(), `/v1/auth/update-email`, auth);

  getChatManageTeam = (
    threadId: bigint,
    onlyInvited: boolean,
    onlyCoHost: boolean,
  ) =>
    this.GET(
      z.object({ list: z.array(User) }),
      `/v1/chat/threads/${threadId}/management-team`,
      { size: 50 },
    );

  getChatMembers = (threadId: bigint) =>
    this.GET(PagedList(User), `/v1/chat/threads/${threadId}/members`);

  account = () => this.GET(Account, `/v1/auth/account`);

  registerCheck = (auth: AuthBody) =>
    this.POST(z.custom(), `/v1/auth/register-check`, auth);

  getNFTBubbles = () =>
    this.GET(PagedList(Bubble), `/biz/v1/nfts/0/chat-bubbles`);

  searchBlogs = (word: string, circleId?: bigint) =>
    this.GET(PagedList(PostAndAnnouncement), `/v2/search/blogs`, {
      word: word,
      circleId: circleId,
    });

  searchChats = (word: string, circleId?: bigint) =>
    this.GET(PagedList(Chat), `/v1/search/chat`, {
      word: word,
      circleId: circleId,
    });

  searchUsers = (word: string, circleId?: bigint) =>
    this.GET(PagedList(User), `/v1/search/users`, {
      word: word,
      circleId: circleId,
    });

  searchJoinedThreads = (word: string) =>
    this.GET(ChatListResponse, `/v1/chat/joined-threads`, {
      word: word,
    });

  getSocialLinks = (uid: bigint) =>
    this.GET(SocialLinksResp, `/v1/users/profile/${uid}/social-links`);

  toggleChatSenderAllowList = (threadId: bigint, enableList: boolean) => {
    if (enableList) {
      return this.POST(
        z.custom(),
        `/v1/chat/threads/${threadId}/sender-allow-list/enable`,
      );
    } else {
      return this.POST(
        z.custom(),
        `/v1/chat/threads/${threadId}/sender-allow-list/enable`,
      );
    }
  };

  getChatSenderAllowList = (threadId: bigint) =>
    this.GET(PagedList(User), `/v1/chat/threads/${threadId}/sender-allow-list`);

  addToFollowingFavourites = (body: { targetUids: bigint[] }) =>
    this.POST(z.custom(), `/v1/users/membership/favorites`, body);

  createComment = (comment: Partial<BareComment>) =>
    this.POST(Comment, `/v1/comments`, comment);

  getMyMoodsList = () => this.GET(MyMoodsList, `/v1/moods`);

  inviteUsersToChat = (threadId: bigint, users: bigint[]) =>
    this.POST(z.custom(), `/v1/chat/threads/${threadId}/members-invite`, {
      invitedUids: users,
    });

  tagObjects = (
    type: ObjectType,
    responseType: ZodType<any, any, any>,
    tagId: string,
    tagName?: string,
  ) =>
    this.GET(PagedList(responseType), `/v1/tags/objects`, {
      type: type,
      tagId: tagId,
      tagName: tagName,
    });

  getTag = (tagId?: string, tagName?: string) =>
    this.GET(Tag, `/v1/tags`, { tagId: tagId, tagName: tagName });

  getSuggestTags = (word: string) =>
    this.GET(PagedList(z.string()), `/v1/tags/suggest`, { word: word });

  getShareLink = (path: string) =>
    this.POST(LinkModel, `/v1/links/share`, {
      path: path,
    });

  parseShareLink = (link: string) =>
    this.POST(LinkModel, `/f/v1/parse-share-link`, {
      link,
    });

  createMood = (mood: UserMood) => this.POST(UserMood, "/v1/moods", mood);

  registerProfileCheck = (auth: AuthBody) =>
    this.POST(z.custom(), `/v1/auth/register-profile-check`, auth);

  register = (auth: AuthBody) => this.POST(Auth, `/v1/auth/register`, auth);

  getInterests = () =>
    this.GET(
      z.object({ list: z.array(Interest) }),
      `/v1/personae/interest-template`,
    );

  chooseInterests = (interests: bigint[]) =>
    this.POST(z.custom(), `/v1/personae/interest`, {
      list: interests,
    });

  verifyCaptcha = (captchaValue: string, scenario?: string) =>
    this.POST(z.custom(), `/f/v1/risk/verify-captcha`, {
      captchaValue,
      scenario,
    });

  verifyLink = (
    link: string | undefined,
    parentId: bigint | undefined,
    parentType: ObjectType | undefined,
  ) =>
    this.GET(VisitLinkResp, `/f/v1/risk/verify-link`, {
      link,
      parentId,
      parentType,
    });

  getMembershipList = (uid: bigint, word: string) =>
    this.GET(PagedList(User), `/v1/users/membership/${uid}`, {
      word: word,
    });

  migrate2Clover = () => this.POST(z.custom(), "/v1/users/upgrade2clover");

  getTransferOrderList = () =>
    this.GET(PagedList(TransferOrder), `/biz/v2/transfer-orders`);

  getUserProps = () => this.GET(UserProps, `/v1/user-props/available-units`);

  getTransactionLogs = (
    accountId: bigint,
    query: {
      currencyTypeList?: string;
      currencyIdList?: string;
      queryType?: number;
    },
  ) =>
    this.GET(
      PagedList(TransactionLog),
      `/biz/v1/asset-accounts/${accountId}/transaction-logs`,
      query,
    );

  getMarketOrderList = (
    queryType: number,
    storeId?: bigint,
    merchId?: bigint,
  ) =>
    this.GET(PagedList(StoreOrder), `/biz/v1/store-orders`, {
      queryType: queryType,
      storeId: storeId,
      merchId: merchId,
    });

  getOtherOrderList = () =>
    this.GET(PagedList(AssetOrder), `/biz/v1/asset-orders`);

  getCircleTokenHolders = (
    circleId: bigint,
    type: CircleTokenQueryType,
    pageParam: PageParam,
  ) =>
    this.GET(
      z
        .object({
          currentUserHolder: CircleTokenHolder.optional(),
        })
        .merge(PagedList(CircleTokenHolder)),
      `/v1/circles/${circleId}/token/holders`,
      {
        queryType: type,
        ...pageParam,
      },
    );

  claimMintOrder = (orderId: bigint) =>
    this.POST(z.custom(), `/biz/v1/asset-orders/${orderId}/claim`);

  claimNFT = (orderId: bigint) =>
    this.POST(z.custom(), `/biz/v3/transfer-orders/${orderId}/claim`);

  claimGiftBox = (boxId: bigint) =>
    this.POST(TransferOrder, `/biz/v2/gift-boxes/${boxId}/claim`);

  withdrawnGiftBox = (boxId: bigint) =>
    this.POST(z.custom(), `/biz/v1/gift-boxes/${boxId}/withdrawn`);

  transferStoreCurrency = (body: TransactionBody) =>
    this.POST(
      TransactionResult,
      `/biz/v1/wallet/0/transfer-store-currency`,
      body,
    );

  getTransferOrder = (orderId: bigint) =>
    this.GET(TransferOrder, `/biz/v1/transfer-orders/${orderId}`);

  getOtherOrder = (orderId: bigint) =>
    this.GET(AssetOrder, `/biz/v1/asset-orders/${orderId}`);

  getStoreOrder = (orderId: bigint) =>
    this.GET(StoreOrder, `/biz/v1/store-orders/${orderId}`);

  getGiftBoxClaimedList = (boxId: bigint) =>
    this.GET(
      PagedList(TransferOrder),
      `/biz/v1/gift-boxes/${boxId}/transfer-order-list`,
    );
  getCurrentContributeRecord = (circleId: bigint) =>
    this.GET(CircleUpgradeRecord, `/v1/circles/${circleId}/upgrade-info`);

  getTokenChainInfo = (circleId: bigint) =>
    this.GET(ChainInfo, `/v1/circles/${circleId}/token-chain-network`);

  updateToken = (circleId: bigint, tokenId: bigint, currency: object) =>
    this.POST(z.custom(), `/v1/circles/${circleId}/token/${tokenId}`, currency);

  getWalletTransactionLogs = (
    walletAccountId: bigint,
    queryType?: number,
    currencyTypeList?: string,
    currencyIdList?: string,
    queryMembershipCard?: boolean,
  ) =>
    this.GET(
      PagedList(TransactionLog),
      `/biz/v1/wallet-accounts/${walletAccountId}/transaction-logs`,
      {
        currencyTypeList: currencyTypeList,
        currencyIdList: currencyIdList,
        queryType: queryType,
        queryMembershipCard: queryMembershipCard,
      },
    );

  reorderCircleRooms = (circleId: bigint, threadIds: bigint[]) =>
    this.POST(z.custom(), `/v1/circles/${circleId}/rooms/reorder`, {
      threadIds,
    });

  deleteCircleRoom = (threadId: bigint) =>
    this.DELETE(z.custom(), `/v1/chat/threads/${threadId}`);

  markTokenCreatedAnimShown = (circleId: bigint) =>
    this.POST(z.custom(), `/v1/circles/${circleId}/token/mark-anim-shown`);

  addUserTreasure = (addTreasureList: ObjectSpec[]) =>
    this.POST(z.custom(), `/v1/users/0/treasure`, addTreasureList);

  getWalletCurrencyInfo = (walletAccountId: bigint, currencyId: bigint) =>
    this.GET(
      CurrencyDetail,
      `/biz/v1/wallet/0/wallet-accounts/${walletAccountId}/currency-list/${currencyId}`,
    );

  getCircleMembers = (circleId: bigint, membershipLevel?: number) =>
    this.GET(PagedList(User), `/v1/circles/${circleId}/members`, {
      membershipLevel: membershipLevel,
    });

  getCircleTitles = (circleId: bigint) =>
    this.GET(
      z.object({ list: z.array(CircleMemberTitle).default([]) }),
      `/v1/circles/${circleId}/member-titles`,
    );

  getAdminEarningInfo = (circleId: bigint) =>
    this.GET(AdminEarningInfo, `/v1/circles/${circleId}/admin-earning-info`);

  getAdminEarningList = (circleId: bigint) =>
    this.GET(
      PagedList(CircleCommissionLog),
      `/v1/circles/${circleId}/admin-earning-list`,
    );
  transferAdminCommission = (circleId: bigint, body: TransactionBody) =>
    this.POST(
      AssetOrder,
      `/v1/circles/${circleId}/transfer-admin-commission`,
      body,
    );

  getUserPropsPackages = (propsType: number) =>
    this.GET(PagedList(UserPropsPackage), `/v1/user-props/packages`, {
      propsType,
    });

  getLivePricingData = () =>
    this.GET(LivePricingData, `/biz/v1/wallet/gems-per-sol`);

  getSOLUSDPrice = () => this.GET(LivePricingData, `/biz/v1/sol/usd-price`);

  getPurchaseProducts = (
    cType: CurrencyType,
    productType: CurrencyType,
    vType?: number,
  ) =>
    this.GET(
      PagedList(UserPropsPackage),
      `/biz/v1/wallet/0/in-app-product-list`,
      {
        cType: cType,
        productType: productType,
        vType,
      },
    );

  purchaseUserProps = (packageId: bigint, body: TransactionBody) =>
    this.POST(
      StoreOrder,
      `/v1/user-props/packages/${packageId}/purchase`,
      body,
    );

  getVisibleTitles = (circleId: bigint) =>
    this.GET(
      PagedList(CircleMemberTitle),
      `/v1/circles/${circleId}/visible-member-titles`,
    );
  getCircleTitleUsers = (circleId: bigint, memberTitleId: bigint) =>
    this.GET(PagedList(User), `/v1/circles/${circleId}/members`, {
      type: "normal",
      memberTitleId,
    });

  authBind = (authInfo: AuthBody) =>
    this.POST(z.custom(), "/v1/auth/oauth", authInfo);

  authUnbind = (authInfo: Omit<AuthBody, "icon">) =>
    this.DELETEWithBody(z.custom(), "/v1/auth/oauth", authInfo);

  getMemberGroupPreview = (
    circleId: bigint,
    groupType: "memberTitle" | "membershipLevel",
  ) =>
    this.GET(
      PagedList(CircleMemberGroupPreview),
      `/v1/circles/${circleId}/members-group-preview`,
      {
        groupType,
      },
    );

  updateCircleQuestion = (circleId: bigint, question?: string) =>
    this.POST(z.custom(), `/v1/circles/${circleId}/join-question`, {
      question: question,
    });

  getNFTList = (nftType: number, containerId: bigint) =>
    this.GET(PagedList(NFT), `/biz/v1/wallet/nft-list`, {
      nftType,
      containerId,
    });

  getNFTCardEarningHistory = (cardId: bigint) =>
    this.GET(
      PagedList(CircleCommissionLog),
      `/biz/v1/nfts/circle-membership-cards/${cardId}/earning-history`,
    );

  getAlertCounts = (expId?: string) =>
    this.GET(AlertCounts, "/v1/alerts/check");

  markAlertAsRead = (groupId: number) =>
    this.POSTWithQuery(z.custom(), "/v1/alerts/mark-as-read", {
      groupId: groupId,
    });

  manageCircle = (
    circleId: bigint,
    userId: bigint,
    body: { type?: string; removeContent?: boolean; note?: string },
  ) => this.POST(z.custom(), `/v1/circles/${circleId}/members/${userId}`, body);

  getCircleMemberCard = (circleId: bigint, uid: bigint) =>
    this.GET(CircleMemberCard, `/v1/circles/${circleId}/member-card/${uid}`);

  getBreadcrumbs = () => this.GET(BreadcrumbBody, "/v1/blogs/breadcrumbs");

  getGiftBox = (boxId: bigint) =>
    this.GET(GiftBox, `/biz/v1/gift-boxes/${boxId}`);

  createFlag = (flagParams: FlagBody) =>
    this.POST(z.custom(), "/v1/flags", flagParams);

  getSupportedThirdPartyPaymentMethods = () =>
    this.GET(
      z.object({ list: z.array(ThirdPartyPayment).optional() }),
      "/biz/v1/wallet/supported-third-party-payment-methods",
      { flavor: "website" },
    );

  getSolRecommendList = () =>
    this.GET(PagedList(SolRecommend), `/biz/v1/wallet/sol-recommend`);

  startThirdPartyPurchase = (productId: string) =>
    this.GET(
      ThirdPartyPurchaseResponse,
      `/biz/v1/wallet/0/third-party-purchase-link`,
      {
        productId,
      },
    );

  createThirdPartyPassword = (authInfo: AuthBody) =>
    this.POST(Auth, "/v1/auth/set-third-party-password", authInfo);

  getGemsAmount = (accountId: bigint) =>
    this.GET(Currency, `/biz/v1/wallet/${accountId}/convert-gems-amount`);

  convertGems = (accountId: bigint) =>
    this.POST(TransactionResult, `/biz/v1/wallet/${accountId}/convert-gems`);

  returnGems = (accountId: bigint) =>
    this.POST(z.custom(), `/biz/v1/wallet/${accountId}/return-convert-gems`);

  createPost = (post: Partial<Post>) => this.POST(Post, `/v1/blogs`, post);

  updateTokenProject = (
    tokenProjectId: bigint,
    body: UpdateTokenProjectRequest,
  ) =>
    this.POST(WrappedCircleToken, `/v1/token-projects/${tokenProjectId}`, body);

  deleteTokenProject = (circleId: bigint) =>
    this.DELETE(z.custom(), `/v1/circles/${circleId}/token/draft-link`);

  deleteSmallNote = (noteId: bigint) =>
    this.DELETE(z.custom(), `/v1/smallnotes/${noteId}`);

  markSmallNoteAsRead = (noteId: bigint) =>
    this.POST(z.custom(), `/v1/smallnotes/${noteId}/mark-as-read`);

  acceptToBeCoAdmin = (threadId: bigint) =>
    this.POST(z.custom(), `/v1/chat/threads/${threadId}/accept-as-co-host`);

  rejectToBeCoAdmin = (threadId: bigint) =>
    this.DELETE(z.custom(), `/v1/chat/threads/${threadId}/co-host`);

  acceptChatInvitation = (threadId: bigint) =>
    this.POST(z.custom(), `/v1/chat/threads/${threadId}/accept-invitation`);

  leaveChatThread = (threadId: bigint) =>
    this.DELETE(z.custom(), `/v1/chat/threads/${threadId}/members`);

  rejectToBeAdmin = (threadId: bigint) =>
    this.DELETE(z.custom(), `/v1/chat/threads/${threadId}/host`);

  acceptToBeAdmin = (threadId: bigint) =>
    this.POST(z.custom(), `/v1/chat/threads/${threadId}/accept-as-host`);

  takeOverAdmin = (threadId: bigint) =>
    this.POST(z.custom(), `/v1/chat/threads/${threadId}/take-over`);

  deleteComment = (commentId: bigint) =>
    this.DELETE(z.custom(), `/v1/comments/${commentId}`);
}
