import { Page } from "../../../components/Page";
import { useBigIntParam } from "../../../hooks/useTypedParam";
import { useSWR } from "../../../hooks/swr/useSWR";
import { I18n, useI18n } from "../../../hooks/useI18n";
import { useHopper } from "../../../hooks/useHopper";
import { useBackend } from "../../../service/APIService";
import { JoinRequestListInfo, JoinRequestStatus } from "../JoinCircleRequest";
import { HStack, Spring, VStack } from "../../../components/VStack";
import { UserAvatarView } from "../../../components/views/UserAvatarView";
import { UserNameView } from "../../../components/views/UserNameView";
import { useSWRArray } from "../../../hooks/swr/useSWRArray";
import { timeIntervalString } from "../../../utils/DateTimeUtils";
import styled from "styled-components";
import { OverFlowTextStyle } from "../../../components/Text";
import { NavMiddle } from "../../../components/NavBar";
import React from "react";

function CircleJoinRequestsPage() {
  const circleId = useBigIntParam("circleId");
  const backend = useBackend();
  const listSWR = useSWRArray(
    backend.getCircleJoinRequest(circleId, JoinRequestStatus.unprocess),
  );

  const i18n = useI18n();
  const hopper = useHopper();

  return (
    <Page pageData={listSWR}>
      <NavMiddle>{i18n.oct12_text_join_requests()}</NavMiddle>
      {listSWR.content.map((info) => (
        <JoinRequestListCell
          key={info.uid}
          item={info}
          i18n={i18n}
          onClick={() =>
            info.requestId &&
            hopper.push(`circle/${circleId}/join-request/${info.requestId}`)
          }
        />
      ))}
    </Page>
  );
}

export default CircleJoinRequestsPage;

const RequestContent = styled.div`
  width: 100%;
  font-weight: 300;
  color: #d8d8d8;
  font-size: 13px;
  ${OverFlowTextStyle};
  -webkit-line-clamp: 1;
`;

const TimeContent = styled.div`
  font-weight: 300;
  color: rgba(255, 255, 255, 0.4);
  font-size: 11px;
  ${OverFlowTextStyle};
  -webkit-line-clamp: 1;
`;

export function JoinRequestListCell(props: {
  item: JoinRequestListInfo;
  i18n: I18n;
  onClick: () => void;
}) {
  let time = props.item.createdTime ?? 0;
  let date = new Date(time * 1000);

  return (
    <div onClick={props.onClick}>
      <HStack style={{ width: "100%", gap: 14, display: "flex" }}>
        <UserAvatarView user={props.item.user} iconSize={45}></UserAvatarView>
        <VStack style={{ flexShrink: 2 }}>
          <UserNameView user={props.item.user}></UserNameView>
          <RequestContent>{props.item.answer}</RequestContent>
        </VStack>

        <Spring />
        <TimeContent>{timeIntervalString(date, props.i18n)}</TimeContent>
      </HStack>
      <div
        style={{
          backgroundColor: "rgba(255, 255, 255, 0.16)",
          marginInlineEnd: -20,
          marginInlineStart: 60,
          height: 0.5,
          marginTop: 12,
        }}
      ></div>
    </div>
  );
}
