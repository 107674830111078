import { Page } from "../../../components/Page";
import styled from "styled-components";
import { useI18n } from "../../../hooks/useI18n";
import SettingCell from "../../../components/SettingCell";
import { Image } from "../../../components/Image";
import CircleThemeColorPreview from "../settings/CircleThemeColorPreview";
import { useCreateCircleResult } from "./CreateCirclePage";
import { FilePicker } from "../../../components/FilePicker";
import { Media } from "../../../proto/Media";
import { useHopState } from "../../../hooks/useHopState";
import { isLocalMedia, LocalMedia } from "../../../bridge/LocalMedia";
import { useHopper } from "../../../hooks/useHopper";
import { NavEnd, NavItem, NavMiddle } from "../../../components/NavBar";
import { HStack } from "../../../components/VStack";
import icCreateBack from "../../../res/images/icon_create_circle_back.png";
import { RegularButton } from "../../../components/Buttons";
import { PageFooter } from "../../../components/PageHeaderFooter";
import { useBackend } from "../../../service/APIService";
import { useLoadState } from "../../../hooks/LoadState";
import { andLog, useErrorHandler } from "../../../components/handleError";
import { useNativePage } from "../../../hooks/useBridge";
import { Spin } from "../../../components/Spin";
import {
  BackgroundPickerResult,
  getBackgroundValue,
} from "../../common/BackgroundPickerPage";
import { useV2ConsumeResult } from "../../../hooks/useResult";
import CircleBackgroundFrame from "../CircleBackgroundFrame";
import {
  CircleBackground,
  DisplayModeEnum,
} from "../../../proto/CircleBackground";
import icEmptyPlaceholder from "../../../res/images/empty_background_icon.png";

function CreateCircleAppearancePage() {
  const i18n = useI18n();
  const hopper = useHopper();

  const editingCircle = useCreateCircleResult();

  const [coverMedia, setCoverMedia] = useHopState<
    Media | LocalMedia | undefined
  >("pendingCover");
  const backend = useBackend();
  const loadTask = useLoadState();
  const handleError = useErrorHandler();
  const nativePage = useNativePage();

  const backgroundConsumer = useV2ConsumeResult(BackgroundPickerResult);

  async function onPreClick() {
    hopper.back();
  }

  async function onNextClick() {
    let serverMedia: Media;
    console.log(coverMedia);
    console.log(
      "editingCircle.content.cover !== undefined:",
      editingCircle.content.cover !== undefined,
    );
    if (coverMedia || editingCircle.content.cover !== undefined) {
      if (coverMedia) {
        if (isLocalMedia(coverMedia)) {
          const r = await loadTask.run(async () => {
            serverMedia = await backend.sendLocalMedia(
              coverMedia,
              "nft_thumbnail",
              (a, b) => {},
            );
            setCoverMedia(serverMedia);
          });
          if (!r.success) {
            handleError(r.error);
            return;
          }
        } else {
          serverMedia = coverMedia;
        }

        await editingCircle.fill((prev) => ({
          ...prev,
          cover: serverMedia,
        }));
      }
      const backgroundMedia = backgroundConsumer.result?.media;
      const backgroundLocal =
        backgroundConsumer.result?.local ?? backgroundConsumer.result?.blob;
      if (backgroundMedia) {
        editingCircle
          .fill((prev) => ({
            ...prev,
            circleBackground: CircleBackground.parse({
              displayMode: DisplayModeEnum.FILL,
              backgroundImage: backgroundMedia,
            }),
          }))
          .catch(andLog);
      } else if (backgroundLocal) {
        const r = await loadTask.run(async () => {
          serverMedia = await backend.sendLocalMedia(
            backgroundLocal,
            "nft_thumbnail",
            (a, b) => {},
          );
          await editingCircle.fill((prev) => ({
            ...prev,
            circleBackground: CircleBackground.parse({
              displayMode: DisplayModeEnum.FILL,
              backgroundImage: serverMedia,
            }),
          }));
        });
        if (!r.success) {
          handleError(r.error);
          return;
        }
      }
      backgroundConsumer.clear();
      hopper.push("create-circle-permission");
    } else {
      nativePage
        .infoHud(i18n.circle2_1_create_check_cover_toast())
        .catch(andLog);
    }
  }

  const getUnderlay = () => {
    if (backgroundConsumer.result) {
      if (backgroundConsumer.result.media) {
        return (
          <CircleBackgroundFrame
            circleBackground={{
              displayMode: DisplayModeEnum.FILL,
              backgroundImage: backgroundConsumer.result.media,
            }}
          />
        );
      } else {
        return (
          <CircleBackgroundFrame
            circleBackground={
              backgroundConsumer.result.blob || backgroundConsumer.result.local
            }
          />
        );
      }
    }
    if (editingCircle.content.circleBackground) {
      return (
        <CircleBackgroundFrame
          circleBackground={editingCircle.content.circleBackground}
        />
      );
    }
    return <></>;
  };

  return (
    <Page pageData={undefined} underlay={getUnderlay()}>
      <NavMiddle>{i18n.circle_create_title()}</NavMiddle>
      <NavEnd>
        {NavItem.text(i18n.help(), () => hopper.push("feedback"))}
      </NavEnd>
      <ContentBackground>
        <FilePicker accept={"image/*"} onPick={setCoverMedia}>
          <SettingCell
            title={i18n.cover_image()}
            endValue={
              <Image
                style={{
                  borderRadius: 4,
                  border: "1px solid rgba(255, 255, 255, 0.4)",
                }}
                src={
                  coverMedia || editingCircle.content.cover
                    ? [coverMedia ?? editingCircle.content.cover, "best"]
                    : icEmptyPlaceholder
                }
                width={30}
                height={44}
              />
            }
          />
        </FilePicker>

        <SettingCell
          title={i18n.circle2_1_circle_background()}
          endValue={
            <Image
              style={{
                borderRadius: 4,
                border: "1px solid rgba(255, 255, 255, 0.4)",
              }}
              src={[
                getBackgroundValue(backgroundConsumer.result) ||
                  editingCircle.content.circleBackground?.backgroundImage,
                "best",
              ]}
              width={30}
              height={44}
            />
          }
          onClick={() => {
            if (backgroundConsumer.result) {
              backgroundConsumer.fill(backgroundConsumer.result);
            } else if (
              editingCircle.content.circleBackground?.backgroundImage
            ) {
              backgroundConsumer.fill({
                media: editingCircle.content.circleBackground.backgroundImage,
              });
            }
            hopper.layer("background-picker");
          }}
        />
        <SettingCell
          title={i18n.circle2_1_theme_color()}
          showDivider={false}
          endValue={
            <CircleThemeColorPreview
              width={30}
              height={30}
              themeColor={editingCircle.content?.themeColor}
            />
          }
          onClick={() => {
            hopper.modal("nyi");
          }}
        />
      </ContentBackground>

      <PageFooter obscuredZoneKey={"CreateCircleBottom"}>
        <HStack
          style={{
            gap: 10,
            display: "flex",
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 20,
          }}
        >
          <div onClick={onPreClick}>
            <Image src={icCreateBack} width={60} height={60} />
          </div>
          <RegularButton style={{ height: 60, flex: 1 }} onClick={onNextClick}>
            <Spin state={loadTask.state}>
              {i18n.meet_now_next_button(2, 3)}
            </Spin>
          </RegularButton>
        </HStack>
      </PageFooter>
    </Page>
  );
}

const StrongTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 8px;
  color: var(--color-text00);
`;

const ContentBackground = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
`;

export default CreateCircleAppearancePage;
