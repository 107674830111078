import { HStack, HStackMixin, VStack } from "../VStack";
import styled, { css, RuleSet } from "styled-components";
import genderMan from "../../res/images/icon_gender_man.svg";
import genderWoman from "../../res/images/icon_gender_woman.svg";
import { Image } from "../Image";
import { MembershipStatus, User } from "../../proto/User";
import { OverFlowTextStyle } from "../Text";
import { Gender } from "../../proto/UserEnums";
import { useHopper } from "../../hooks/useHopper";
import { CircleMembershipLevelIcon } from "../../pages/circle/membership/MembershipLevelPicker";
import primeIcon from "../../res/images/prime_icon.png";

export function getGenderIcon(gender?: Gender) {
  switch (gender) {
    case Gender.man:
      return genderMan;
    case Gender.woman:
      return genderWoman;
    default:
      return undefined;
  }
}

export function UserNameView(props: {
  user?: User;
  style?: RuleSet<Object>;
  nameStyle?: RuleSet<Object>;
  clickToProfile?: boolean;
  showMembershipLevel?: boolean;
  showGender?: boolean;
}) {
  let genderIcon = getGenderIcon(props.user?.gender);
  const hopper = useHopper();
  const user = props.user;

  const isPrime =
    !!user?.membershipStatus && user.membershipStatus !== MembershipStatus.Off;
  const primeColor = user?.extensions?.nicknameColor;
  const primeStyle = isPrime
    ? !!primeColor
      ? css({ color: primeColor })
      : Prime
    : undefined;

  return (
    <Container
      mixin={props.style}
      onClick={(event) => {
        if (props.clickToProfile) {
          event.stopPropagation();
          if ((props.user?.uid || 0) < 1000) {
            hopper.push(`user-official`);
          } else {
            hopper.push(`user/${props.user?.uid}`);
          }
        }
      }}
    >
      <UserName mixin={props.nameStyle} primeStyle={primeStyle}>
        {props.user?.nickname ?? "-"}
      </UserName>
      {genderIcon && props.showGender === true && (
        <Image
          src={genderIcon}
          style={{
            width: 16,
            height: 16,
            marginInlineStart: 3,
          }}
        />
      )}
      {isPrime && (
        <Image
          src={primeIcon}
          width={16}
          height={16}
          style={{ margin: "0 4px" }}
        />
      )}
      {user &&
        props.showMembershipLevel === true &&
        user.circleMembershipLevel !== undefined && (
          <CircleMembershipLevelIcon
            level={user.circleMembershipLevel}
            width={28}
            style={{
              display: "flex",
              height: 30,
            }}
            hideAdminLevel={true}
          />
        )}
    </Container>
  );
}

const Prime = css`
  background: linear-gradient(to bottom, #ffd12d, #fffdd4, #ffd12d);
  -webkit-background-clip: text;
  color: transparent;
`;

export const UserName = styled.div<{
  mixin?: RuleSet<Object>;
  primeStyle?: RuleSet<Object>;
}>`
  font-weight: 500;
  font-size: 16px;
  flex-shrink: 1;
  ${OverFlowTextStyle};
  -webkit-line-clamp: 1;
  ${(p) => p.primeStyle}
  ${(p) => p.mixin}
`;
// background: linear-gradient(to bottom, #ffd12d, #fffdd4, #ffd12d);
// -webkit-background-clip: text;
// color: transparent;

const Container = styled.div<{ mixin?: RuleSet<Object> }>`
  ${HStackMixin}
`;
