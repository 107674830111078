import { Page } from "../../components/Page";
import { useSWR } from "../../hooks/swr/useSWR";
import { useBackend } from "../../service/APIService";
import ChatGridCell from "../../components/ChatGridCell";
import GridLayout from "../../components/GridLayout";

export function CircleChatLinkPage(props: { threadId: bigint }) {
  const backend = useBackend();

  const threadSWR = useSWR(backend.getThreadInfo(props.threadId));

  return (
    <Page pageData={threadSWR}>
      <GridLayout
        spanCount={2}
        items={[
          <ChatGridCell
            key={threadSWR.content?.threadId}
            chat={threadSWR.content}
          />,
        ]}
      />
    </Page>
  );
}
