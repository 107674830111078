import { CircleMemberTitle, TitleType } from "../proto/CircleMemberTitle";
import React from "react";
import iconAdmin from "../res/images/ic_admin.svg";
import { HStack, HStackMixin } from "./VStack";
import { Image } from "./Image";
import styled, { css } from "styled-components";
import { genBorder } from "./Buttons";
import { linear_gradient_border } from "./vscroll/SVGUtils";
import { OverFlowTextStyle } from "./Text";
import bgAdmin from "../res/images/bg_admin.png";

function MemberTitle(props: {
  memberTitle: CircleMemberTitle;
  style?: React.CSSProperties;
}) {
  switch (props.memberTitle.type) {
    case TitleType.admin:
      return (
        <MemberTitleAdminView
          memberTitle={props.memberTitle}
          style={props.style}
        />
      );
    case TitleType.coAdmin:
      return (
        <MemberTitleCoAdminView
          memberTitle={props.memberTitle}
          style={props.style}
        />
      );
    case TitleType.customized:
      return (
        <CustomizedTitle memberTitle={props.memberTitle} style={props.style} />
      );
    default:
      return (
        <BuiltInTitle memberTitle={props.memberTitle} style={props.style} />
      );
  }
}

export function MemberTitleAdminView(props: {
  memberTitle: CircleMemberTitle;
  style?: React.CSSProperties;
}) {
  return (
    <HStack
      mixin={css`
        border-radius: 4px;
        padding: 3px 4px;
        gap: 1px;
      `}
      style={{
        ...props.style,
      }}
    >
      <AdminBackground src={bgAdmin} />
      <Image width={12} height={12} src={iconAdmin} />
      <AdminTitleName>{props.memberTitle.title}</AdminTitleName>
    </HStack>
  );
}

export function MemberTitleCoAdminView(props: {
  memberTitle: CircleMemberTitle;
  style?: React.CSSProperties;
}) {
  return (
    <HStack
      style={{
        borderRadius: "4px",
        padding: "3px 5px",
        background: "#00CFA5",
        ...props.style,
      }}
    >
      <TitleName>{props.memberTitle.title}</TitleName>
    </HStack>
  );
}

export function BuiltInTitle(props: {
  memberTitle: CircleMemberTitle;
  style?: React.CSSProperties;
}) {
  return (
    <HStack
      style={{
        borderRadius: "4px",
        background: `${props.memberTitle.color}`,
        ...props.style,
      }}
    >
      <TitleName
        style={{ marginLeft: 4, marginTop: 4, marginBottom: 4, marginRight: 4 }}
      >
        {props.memberTitle.title}
      </TitleName>
    </HStack>
  );
}

export function CustomizedTitle(props: {
  memberTitle: CircleMemberTitle;
  style?: React.CSSProperties;
}) {
  return (
    <TitleFrame
      $baseColor={props.memberTitle.color}
      style={{
        borderRadius: "4px",
        border: `1px solid ${props.memberTitle.color}`,
        ...props.style,
      }}
    >
      {props.memberTitle.title}
    </TitleFrame>
  );
}

const TitleFrame = styled.div<{ $baseColor: string }>`
  position: relative;
  padding: 3px 5px;
  text-overflow: ellipsis;
  background: ${({ $baseColor }) =>
    `rgba(${parseInt($baseColor.substring(1, 3), 16)}, ${parseInt($baseColor.substring(3, 5), 16)}, ${parseInt($baseColor.substring(5, 7), 16)}, 0.20)`};
  color: ${({ $baseColor }) => `${$baseColor}`};
  font-size: 10px;
  font-weight: 700;
  height: 12px;
  overflow: hidden;
  line-height: 12px;
  z-index: 1;
  display: inline-block; // 改为 inline-block 来适应 text-overflow
  white-space: nowrap; // 防止换行
  max-width: calc(100% - 10px); // 设置最大宽度为父容器的宽度
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background: rgba(0, 0, 0, 0.5); // 50% 黑色蒙层
    border-radius: 4px;
    pointer-events: none; // 让蒙层不影响点击事件
  }
`;

const TitleName = styled.div`
  color: #111111;
  font-size: 10px;
  font-weight: 500;
`;
const AdminTitleName = styled.div`
  color: #fff;
  font-size: 10px;
  font-weight: 700;
  ${OverFlowTextStyle};
  line-clamp: 1;
  -webkit-line-clamp: 1;
`;
const AdminBackground = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

export default MemberTitle;
