import { Page } from "../../../components/Page";
import { NavMiddle } from "../../../components/NavBar";
import { useI18n } from "../../../hooks/useI18n";
import { useBackend } from "../../../service/APIService";
import { useBigIntParam } from "../../../hooks/useTypedParam";
import { useSWR } from "../../../hooks/swr/useSWR";
import { useNativePage } from "../../../hooks/useBridge";
import { useHopper } from "../../../hooks/useHopper";
import { SpeedTitle } from "../../../components/SpeedTitle";
import { RegularButton } from "../../../components/Buttons";
import React, { useMemo } from "react";
import CircleCardsPreviewCell, { CardStyle } from "./CircleCardsPreviewCell";
import styled from "styled-components";
import { BottomSheet, ModalTitle, useModal } from "../../../components/Modal";
import icDropDown from "../../../res/images/ic_drop_down.svg";
import icMiniDown from "../../../res/images/icon_mini_down.png";
import SelectPaymentMethodModal from "./SelectPaymentMethodModal";
import {
  CurrencyType,
  getCurrencyIconByCurrencyType,
  getCurrencyNameByCurrencyType,
} from "../../../proto/Currency";
import {
  CenterChild,
  HStack,
  VSpace,
  VStack,
} from "../../../components/VStack";
import { Image } from "../../../components/Image";
import ViewPager, { PagedItem } from "../../../components/ViewPager";
import { CellDivider } from "../../../components/ContentGroup";
import { useHopId, useHopState } from "../../../hooks/useHopState";
import { NFT } from "../../../proto/NFT";
import { CircleMembershipCardBenefitsView } from "../../nft/components/CircleMembershipCardBenefitsView";
import { SingleLineUnspecifiedWidth } from "../../../components/CommonViews";
import { isLiteApp } from "../../../bridge/ExternalAppShellData";
import { CircleCardStatus } from "../../../proto/CircleCardStatus";

function CircleCardPickerPage() {
  const i18n = useI18n();
  const backend = useBackend();
  const circleId = useBigIntParam("circleId");
  const circleSWR = useSWR(backend.getCircle(circleId));
  const cardsSWR = useSWR(
    backend.getCircleCardsList(circleId, CircleCardStatus.Enable),
  );
  const nativePage = useNativePage();
  const tokenSWR = useSWR(backend.getCircleToken(circleId));
  const hopper = useHopper();
  const [paymentCurrencyType, setPaymentCurrencyType] =
    useHopState<CurrencyType>(
      "payment-currency-type",
      isLiteApp() ? CurrencyType.GEMS : CurrencyType.SOL,
    );
  const paymentMethodMenu = useModal("payment-method-menu");
  const pageId = useHopId();
  const [chosenTabIndex, setChosenTabIndex] = useHopState(
    `child-view-pager-index-${pageId}`,
    0,
  );
  const items = useMemo(() => {
    return cardsSWR.content?.list
      ?.filter(
        (nft: NFT) =>
          Number(nft.circleMembershipCard?.membershipLevel ?? "0") > 0,
      )
      ?.map((card, index) => {
        const pagedItem: PagedItem = {
          index: index,
          title: "Card Name",
          page: (
            <CircleCardsPreviewCell
              circle={circleSWR.content}
              card={card.circleMembershipCard}
              cardStyle={CardStyle.large}
              includesPrice={true}
              displayCurrencyType={paymentCurrencyType}
              onClick={() => {
                if (chosenTabIndex !== index) {
                  setChosenTabIndex(index);
                } else {
                  hopper.push(`nft-circle-card/${card.nftId}`, {
                    currencyType: paymentCurrencyType,
                  });
                }
              }}
            />
          ),
        };
        return pagedItem;
      })
      ?.slice(0, 10);
  }, [cardsSWR.content, chosenTabIndex, paymentCurrencyType]);

  const chosenCard = useMemo(() => {
    return cardsSWR.content?.list?.at(chosenTabIndex);
  }, [cardsSWR.content, chosenTabIndex]);

  return (
    <Page
      pageData={[circleSWR, cardsSWR, tokenSWR]}
      underlay={
        <PageHeaderContainer>
          <Image
            src={[circleSWR.content?.circleIcon, "best"]}
            style={{ flex: 1 }}
          />
          <PageHeaderOverlay />
        </PageHeaderContainer>
      }
    >
      <NavMiddle>{i18n.clover_membership_cards()}</NavMiddle>
      <HStack style={{ gap: 8 }}>
        <Image
          src={[circleSWR.content?.circleIcon, 46]}
          alt={"circleIcon"}
          style={{
            borderRadius: 4,
            border: `1px solid ${circleSWR.content?.themeColor}`,
          }}
          width={46}
          height={46}
        />
        <VStack style={{ gap: 8, flex: 1 }}>
          <CircleName>{circleSWR.content?.name}</CircleName>
          <CircleId>{`c/${circleSWR.content?.socialId}`}</CircleId>
        </VStack>
      </HStack>
      <VSpace height={8} />
      {!isLiteApp() && (
        <HStack
          onClick={() => {
            paymentMethodMenu.open();
          }}
          style={{
            gap: 5,
            padding: 6,
            backgroundColor: "rgba(255, 255, 255, 0.1)",
            marginInlineStart: "auto",
            borderRadius: 4,
          }}
        >
          <Image
            src={getCurrencyIconByCurrencyType(paymentCurrencyType)}
            width={20}
            height={20}
          />
          <PaymentCurrency>
            {getCurrencyNameByCurrencyType(paymentCurrencyType, i18n)}
          </PaymentCurrency>
          <Image src={icDropDown} width={16} height={16} />
        </HStack>
      )}

      <VSpace height={12} />
      <CenterChild>
        <Image src={icMiniDown} width={10} height={5} />
      </CenterChild>
      <VSpace height={8} />

      {items && (
        <div style={{ marginInlineEnd: -20, marginInlineStart: -20 }}>
          <ViewPager
            adapter={{ pagedItems: items }}
            currentIndex={chosenTabIndex}
            showShoulder={false}
            scaleSideItems={true}
            slidesPerView={1.4} // 每次显示 1.2 个 slide
            centeredSlides={true} // 居中当前 slide
            showTabsLayout={false}
            preventInteractionOnTransition={true}
            nested={true}
            onSlideChange={(activeIndex: number) => {
              setChosenTabIndex(activeIndex);
            }}
          />
        </div>
      )}

      <RegularButton
        onClick={() => {
          if (chosenCard) {
            hopper.layer(
              `circle/${circleId}/card/${chosenCard.nftId}/purchase`,
              {
                currencyType: paymentCurrencyType,
              },
            );
          }
        }}
        style={{
          marginInlineStart: 60,
          marginInlineEnd: 60,
          marginTop: 30,
          fontWeight: 400,
          fontSize: 14,
          marginBottom: 20,
        }}
      >
        {i18n.web3_v0_purchase()}
      </RegularButton>

      {!isLiteApp() && (
        <UnderlinedWalletText
          onClick={() => {
            if (chosenCard) {
              hopper.modal(`circle/${circleId}/stake-info`, {
                cardId: chosenCard.nftId,
              });
            }
          }}
        >
          {i18n.clover_get_membership_card_by_staking()}
        </UnderlinedWalletText>
      )}
      <VSpace height={30} />
      <CellDivider />

      <VSpace height={10} />

      {chosenCard && <CircleMembershipCardBenefitsView nft={chosenCard} />}
      {/*<BenefitContainer>*/}
      {/*  <BenefitIconLayout></BenefitIconLayout>*/}
      {/*  <VStack style={{ gap: 8, flex: 1 }}>*/}
      {/*    <BenefitTitle>*/}
      {/*      {i18n.clover_access_exclusive_membership_content()}*/}
      {/*    </BenefitTitle>*/}
      {/*    <HStack style={{ gap: 4 }}>*/}
      {/*      <Image src={iconWarning} width={16} height={16} />*/}
      {/*      <BenefitWarning>*/}
      {/*        {i18n.clover_after_joining_this_circle()}*/}
      {/*      </BenefitWarning>*/}
      {/*    </HStack>*/}
      {/*  </VStack>*/}
      {/*</BenefitContainer>*/}

      {/*<BenefitContainer>*/}
      {/*  <BenefitIconLayout></BenefitIconLayout>*/}
      {/*  <BenefitTitle>{i18n.clover_free_token_rewards()}</BenefitTitle>*/}
      {/*</BenefitContainer>*/}

      {/*<BenefitContainer>*/}
      {/*  <BenefitIconLayout></BenefitIconLayout>*/}
      {/*  <BenefitTitle>{i18n.clover_earn_extra_circles_tokens()}</BenefitTitle>*/}
      {/*</BenefitContainer>*/}

      <VSpace height={10} />
      <SpeedTitle>{i18n.circle_become_membership()}</SpeedTitle>
      <VSpace height={12} />
      <BecomeMembershipDesc>
        {i18n.clover_you_multiple_cards_highest_level()}
      </BecomeMembershipDesc>
      <VSpace height={30} />

      <BottomSheet modal={paymentMethodMenu}>
        <ModalTitle>{i18n.clover_select_currency()}</ModalTitle>
        <SelectPaymentMethodModal
          onChosen={(currencyId, currencyType) => {
            paymentMethodMenu.close(() => {
              setPaymentCurrencyType(currencyType);
            });
          }}
        />
      </BottomSheet>
    </Page>
  );
}

const PageHeaderContainer = styled.div`
  display: flex;
  position: relative;
  height: 40%;
  mask-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
  mask-size: 100% 100%;
  mask-repeat: no-repeat;
`;

const UnderlinedWalletText = styled.span`
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  color: #28c5f3;
  text-decoration-color: #28c5f3;
  text-decoration: underline;
`;

const PageHeaderOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
`;

const BenefitContainer = styled.div`
  padding-top: 18px;
  display: flex;
  gap: 10px;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.06);
  flex-direction: row;
  align-items: center;
  padding-bottom: 18px;
`;

const BenefitIconLayout = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.06);
`;

const BenefitTitle = styled.div`
  color: var(--color-text00);
  display: flex;
  flex: 1;
  font-weight: 400;
  font-size: 16px;
`;

const BenefitWarning = styled.div`
  color: var(--color-text20);
  display: flex;
  flex: 1;
  font-weight: 400;
  font-size: 12px;
`;

const BecomeMembershipDesc = styled.div`
  color: var(--color-text10);
  display: flex;
  flex: 1;
  font-weight: 500;
  font-size: 14px;
`;

const PaymentCurrency = styled.div`
  color: var(--color-text00);
  font-weight: 400;
  font-size: 12px;
`;

const CircleName = styled.div`
  color: var(--color-text00);
  ${SingleLineUnspecifiedWidth};
  font-weight: 500;
  font-size: 16px;
`;

const CircleId = styled.div`
  color: var(--color-text10);
  font-weight: 400;
  font-size: 10px;
`;

export default CircleCardPickerPage;
