import styled from "styled-components";
import {
  Currency,
  formatMoney,
  getCurrencyIcon,
  getCurrencyIconByCurrencyType,
  getCurrencyName,
  getCurrencyNameByCurrencyType,
} from "../../proto/Currency";
import { HStack, VStack } from "../../components/VStack";
import { Image } from "../../components/Image";
import iconSOL from "../../res/images/icon_sol.svg";
import { useI18n } from "../../hooks/useI18n";
import BigNumber from "bignumber.js";

export interface WalletBalanceCellProps {
  currency: Currency;
  showUsdPrice: boolean;
}

function WalletBalanceCell(props: WalletBalanceCellProps) {
  const i18n = useI18n();
  return (
    <HLine>
      <HStack style={{ gap: 8 }}>
        <Image
          src={getCurrencyIcon(props.currency)}
          width={26}
          height={26}
          style={{ borderRadius: 4 }}
        />
        <Title>{getCurrencyName(props.currency, i18n)}</Title>
      </HStack>

      <VStack style={{ alignItems: "end" }}>
        <Balance>{formatMoney("short", props.currency)}</Balance>
        {props.showUsdPrice && (
          <BalanceUSD>{`$${formatMoney("short", BigNumber(props?.currency?.usdPrice ?? "0"))} USD`}</BalanceUSD>
        )}
      </VStack>
    </HLine>
  );
}

const Title = styled.div`
  color: var(--color-text00);
  font-weight: 500;
  font-size: 16px;
`;

const HLine = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const Balance = styled.div`
  color: var(--color-text00);
  font-weight: 500;
  font-size: 18px;
`;

const BalanceUSD = styled.div`
  color: var(--color-text20);
  font-weight: 500;
  font-size: 10px;
`;

export default WalletBalanceCell;
