import { bigint, number, string, z } from "zod";
import { zBigInt, zEnum, zStatic } from "../../utils/zodUtils";
import { User } from "../../proto/User";

export const JoinCircleBodyQuestion = z.object({
  answer: z.string().optional(),
  questionId: zBigInt,
  receiveCircleUpdate: z.boolean().optional(),
});
export type JoinCircleBodyQuestion = zStatic<typeof JoinCircleBodyQuestion>;

export enum JoinCircleMethod {
  OPEN = 1,
  USEPASSCODE = 2,
  USEINVITELINK = 3,
  USEJOINREQUEST = 4,
  BEINVITED = 5,
}

export const JoinCircleBody = z.object({
  joinMethod: zEnum(JoinCircleMethod),
  joinToken: z.string().optional(),
});
export type JoinCircleBody = zStatic<typeof JoinCircleBody>;

export enum CircleJoinPermission {
  LOCKED = 0, // 0
  OPEN = 1 << 0, // 1
  USE_PASSCODE = 1 << 1, // 2
  ACCEPT_JOIN_REQUEST = 1 << 2, // 4
  ALLOW_MEMBER_INVITE = 1 << 3, // 8
}

export const Question = z.object({
  questionId: zBigInt.optional(),
  content: z.string().optional(),
});

export enum JoinRequestStatus {
  unprocess = 0,
  approved = 1,
  rejected = 2,
}

export const JoinRequestListInfo = z.object({
  answer: z.string().optional(),
  circleId: zBigInt.optional(),
  question: Question.optional(),
  questionId: zBigInt.optional(),
  requestId: zBigInt.optional(),
  uid: zBigInt.optional(),
  requestLogId: zBigInt.optional(),
  user: User.optional(),
  status: zEnum(JoinRequestStatus).optional(),
  operator: User.optional(),
  createdTime: z.number().optional(),
});

export type JoinRequestListInfo = zStatic<typeof JoinRequestListInfo>;
